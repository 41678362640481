import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
        configurationTileContainer: {
            padding: '20px'
        },
        configurationTileContent: {
            minHeight: '160px'
        },
        patientForms: {
            border: '2px solid #CACACE',
            height: '240px',
            borderRadius: '8px',
            width: '450px',
            textAlign: 'center',
            float: 'left',
            padding: '25px',
            marginLeft: '20px'
        },
        sectionHeading: {
            fontSize: '20px',
            lineHeight: '45px',
            fontWeight: 600
        },
        linkTypo: {
            color: '#0F7BBD',
            marginLeft: 'auto',
            boxShadow: 'none',
            display: 'flex'
        },
        ulList: {
            fontSize: '16px',
            paddingLeft: '16px'
        },
        liStyle: {
            display: 'list-item',
            listStyle: 'disc',
            padding: '0'
        },
        infoText: {
            color: '#AB201F',
            fontSize: '14px',
            fontStyle: 'italic'
        },
        flagIcon: {
            height: '10px',
            width: '11px',
            marginRight:'7px'
        }
}));