import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, SIZING,COLORS} from "theme";

export const useStyles = makeStyles(() => ({
    header: {
        padding: `${SIZING.scale600} 0`,
        background: "#F0F0F2",
        boxShadow: "inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
    },
    flexWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `0 ${SIZING.scale800}`
    },
    search: {
        marginBottom: SIZING.scale400
    },
    input: {
        borderRadius: BORDER_RADIUS.MEDIUM,
        marginBottom: SIZING.scale400
    },
    content: {
        maxHeight: SIZING.scale15000,
        overflow: 'auto',
        padding: SIZING.scale800,
        background: COLORS.MODAL_BG,
        "& ::-webkit-scrollbar": {
            width: SIZING.scale50,
            height: SIZING.scale100,
            cursor: "pointer",
        },

        "& ::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0)",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0)"
        },

        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: COLORS.COMPOSE_SCROLL_BAR
        },
        "& input":{
            color: COLORS.LIST_SUBHEADER_TEXT,
            fontSize:SIZING.scale400,
        },
        "& textarea":{
            padding:`${SIZING.scale100} ${SIZING.scale300}`,
            fontSize:SIZING.scale400,
            color: COLORS.LIST_SUBHEADER_TEXT,
            textIndent:'unset'
        },
        "& .messageInput textarea": {
            minHeight: SIZING.scale7000
        }
    },
    close: {
        cursor: 'pointer'
    },
    send: {
        /* Mui-disabled was overriding pointer and click handler important needed to prevent this */
        cursor: 'pointer !important' as any,
        pointerEvents: 'all !important' as any,
        marginLeft:'auto',
        background:COLORS.PRIMARY_BTN_TEXT,
        padding: SIZING.scale300,
        color: COLORS.WHITE

    },
    REText: {
        marginBottom: SIZING.scale400,
        marginRight: SIZING.scale100
    },
    ellipsis: {
        height: SIZING.scale1200,
        width: SIZING.scale1200,
        "& path": {
            fill: COLORS.ITEM_BADGE_BG
        }
    },
    errorClass:{
        marginBottom:SIZING.scale400,
    }
}));