import { HighlightedTextSegment, HighlightedTextProps } from "./types";

const MATCH_SEPARATOR = "~!~";

export const getHighlightedTextSegments = (text: string, highlightText: string): HighlightedTextSegment[] => {
    if (!highlightText || !text)
        return [
            {
                text: text,
                highlighted: false,
            },
        ];

    const matchRegex = new RegExp(highlightText, "gi");

    const textSegments = text
        .replace(matchRegex, function (match: string) {
            return `${MATCH_SEPARATOR}${match}${MATCH_SEPARATOR}`;
        })
        .split(MATCH_SEPARATOR);

    if (textSegments.length) {
        const textToMatch = highlightText.trim().toLowerCase();

        return textSegments.map((segment: string) => ({
            text: segment,
            highlighted: textToMatch === segment.trim().toLowerCase(),
        }));
    }

    return [
        {
            text: text,
            highlighted: false,
        },
    ];
};

const HighlightedText = ({
    highlightedClassName = "highlighted",
    notHighlightedClassName = "notHighlighted",
    text,
    textToHighligh,
}: HighlightedTextProps) => {
    const highlightedTextSegments = getHighlightedTextSegments(text, textToHighligh);

    return (
        <>
            {highlightedTextSegments.map((textSegment, index) => (
                <span
                    key={`${textSegment.text}-${index}`}
                    className={textSegment.highlighted ? highlightedClassName : notHighlightedClassName}
                >
                    {textSegment.text}
                </span>
            ))}
        </>
    );
};

export default HighlightedText;
