import React,{ useMemo } from "react";
import Modal from "../../Modal";
import {MODAL_TYPES, USER_TYPE} from "constant";
import {Box, DialogContent, DialogTitle} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {useStyles} from "./styles";
import {setActiveModal} from "store/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "store/reducerTypes";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { getParticipantAdditionalInfo } from "components/messaging/utils";
import { getParticipantIcon, getParticipantName } from "utils";
import {COLORS, ColorValue} from "theme";
import  { ReactComponent as Envelope } from "icon-library/SVG/eMail_Closed-Envelope.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ComposeModalType } from "./types"
import { ConversationInfoItem,ConversationParticipant } from "store/chat-types"
// ConversationParticipantList renders all participants in a conversation along with some additional information
const ConversationParticipantList = () => {
    const {openConversationSid, conversationsInfo} = useSelector((state: AppReduxStore) => state.chat)
    const {currentProfileId} = useSelector((state: AppReduxStore) => state.user)
    const info:ConversationInfoItem | undefined  = conversationsInfo[openConversationSid]

    const dispatch = useDispatch()
    const classes = useStyles()

    const handleComposeMessage = (participant:ConversationParticipant)=>{
        const {userType, patientProfile, providerProfile,profileId,identity} = participant
        let type:ComposeModalType ='patient'
        let participantDetail:any = null
        switch (userType) {
            case USER_TYPE.PATIENT:
            case USER_TYPE.SUPPORT_CONTACT:
                type = 'patient'
                participantDetail=patientProfile
                break;
            case USER_TYPE.PROVIDER:
                type = 'colleague'
                participantDetail=providerProfile
                break;
            default:
                type = 'colleague'
                participantDetail=providerProfile
                break;
        }
        dispatch(setActiveModal(MODAL_TYPES.PROVIDER.COMPOSE_NEW_MESSAGE, {type,participantDetail:{ ...participantDetail,identity,profileId,userType}}));
    }

    const filteredParticipant = useMemo(() => {
        return (info?.participants || []).filter((p) => p.isEnabled)
    }, [info?.participants])

    const ParticipantRow = ({participant}:{ participant:ConversationParticipant }) => {
        const {userType, profileId,providerProfile} = participant
        const Icon = getParticipantIcon(participant)

        let iconColor: ColorValue = COLORS.USER_LIST_ICON
        let textColor: ColorValue = COLORS.LIST_ITEM_TEXT
        let altText = ""
        let altDescText = ""
        if (userType === USER_TYPE.PATIENT) {
            iconColor = COLORS.PATIENT_LIST_ICON
            textColor = COLORS.LIST_ITEM_TEXT_BLUE
            altText = getParticipantAdditionalInfo(participant);
        } else if (userType === USER_TYPE.PROVIDER) {
            altText = userType.toLowerCase()
            altDescText = (providerProfile?.practiceName||'').toLowerCase()
        }

        return (
            <Box className={classes.participantRow} id="QA_Participant">
                <Icon fill={iconColor} className={classes.userIcon} />
                <Box  width={1} style={{color: textColor}}>
                    <Box width={1} display="flex" justifyContent="space-between">
                        <CustomTypography variant="descriptionBold">
                            {getParticipantName(participant, true)}
                        </CustomTypography>
                        <CustomTypography className={classes.description} variant="light">
                            {altText}
                        </CustomTypography>
                    </Box>
                    <Box width={1} display="flex" justifyContent="space-between">
                        <CustomTypography variant="light">
                            {participant.userType.replace(/_/g, ' ')}
                        </CustomTypography>
                        <CustomTypography className={classes.description} variant="light">
                            {altDescText}
                        </CustomTypography>
                    </Box>                   
                </Box>
              {profileId !== currentProfileId &&<SVGIcon
                    trim={false}
                    className={classes.quickMessage}
                    svg={Envelope}
                    size="scale1200"
                    onClick={e=>handleComposeMessage(participant)}
                /> }
            </Box>
        )
    }

    return (
        <Modal classes={{paper: classes.modal}} id={MODAL_TYPES.PROVIDER.CONVERSATION_PARTICIPANTS}>
            <DialogTitle>
                <Box className={classes.flexWrapper}>
                    <CustomTypography variant="modalHeader">
                        <FormattedMessage id="ConversationParticipantList.title" values={{count: filteredParticipant.length}}/>
                    </CustomTypography>
                    <Close id="QA_Close_Button" className={classes.close} onClick={() => dispatch(setActiveModal(""))} />
                </Box>
            </DialogTitle>
            <DialogContent>
                {filteredParticipant.map(p => <ParticipantRow  key={p.identity} participant={p}/>)}
            </DialogContent>
        </Modal>
    )
}

export default ConversationParticipantList