import React from "react";
import {useStyles} from "./styles";
import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import CustomTypography from "common/components/CustomTypography/CustomTypography";

const Insurance = ({ selectedPatient }: any) => {
    const classes = useStyles()
    const { ssn, mrn, ehrPatientId, insuranceName, insuranceType, insuranceMemberId } = selectedPatient

    return (
        <Box className={classes.insuranceContainer} id="QA_Insurance">
            <CustomTypography className={classes.patientSummaryInsuranceTitle} variant="patientSummaryInsuranceTitle">
                <FormattedMessage id={"People.summary.insurance"} />
            </CustomTypography>
            <Box className={classes.insuranceRow} id="QA_Insurance_SSN">
                <CustomTypography className={classes.patientSummaryInsuranceLabel} variant="patientSummaryInsuranceLabel">
                    <FormattedMessage id="People.summary.label.ssn" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryInsuranceValue} variant="patientSummaryInsuranceValue">
                {ssn ? `***-**-${ssn}` : ""}
                </CustomTypography>
            </Box>
            <Box className={classes.insuranceRow} id="QA_Insurance_Medical_ID">
                <CustomTypography className={classes.patientSummaryInsuranceLabel} variant="patientSummaryInsuranceLabel">
                    <FormattedMessage id="People.summary.label.medicalId" />
                </CustomTypography>
                <Box>
                    <Box className={classes.nestedRow} id="QA_Insurance_MR">
                    <CustomTypography className={classes.patientSummaryInsuranceSecondary} variant="patientSummaryInsuranceSecondary">
                        <FormattedMessage id="People.summary.label.mr" />
                    </CustomTypography>
                    <CustomTypography className={classes.patientSummaryInsuranceValue} variant="patientSummaryInsuranceValue">
                        {mrn}
                    </CustomTypography>
                    </Box>
                    <Box className={classes.nestedRow} id="QA_Insurance_EHR_ID">
                    <CustomTypography className={classes.patientSummaryInsuranceSecondary} variant="patientSummaryInsuranceSecondary">
                        <FormattedMessage id="People.summary.label.ehrid" />
                    </CustomTypography>
                    <CustomTypography className={classes.patientSummaryInsuranceValue} variant="patientSummaryInsuranceValue">
                        {ehrPatientId}
                    </CustomTypography>
                    </Box>
                    <Box className={classes.nestedRow} id="QA_Insurance_NIST">
                    <CustomTypography className={classes.patientSummaryInsuranceSecondary} variant="patientSummaryInsuranceSecondary">
                        <FormattedMessage id="People.summary.label.nist" />
                    </CustomTypography>
                    <CustomTypography className={classes.patientSummaryInsuranceValue} variant="patientSummaryInsuranceValue">
                        -
                    </CustomTypography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.insuranceRow} id="QA_Insurance_Primary_Coverage">
                <CustomTypography className={classes.patientSummaryInsuranceLabel} variant="patientSummaryInsuranceLabel">
                    <FormattedMessage id="People.summary.label.primaryCoverage" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryInsuranceValue} variant="patientSummaryInsuranceValue">
                {insuranceName}
                </CustomTypography>
            </Box>
            <Box className={classes.insuranceRow} id="QA_Insurance_EHR_ID">
                <CustomTypography className={classes.patientSummaryInsuranceLabel} variant="patientSummaryInsuranceLabel">
                    <FormattedMessage id="People.summary.label.planNameType" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryInsuranceValue} variant="patientSummaryInsuranceValue">
                {insuranceType}
                </CustomTypography>
            </Box>
            <Box className={classes.insuranceRow} id="QA_Insurance_Member_ID">
                <CustomTypography className={classes.patientSummaryInsuranceLabel} variant="patientSummaryInsuranceLabel">
                    <FormattedMessage id="People.summary.label.memberID" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryInsuranceValue} variant="patientSummaryInsuranceValue">
                {insuranceMemberId}
                </CustomTypography>
            </Box>
            <Box className={classes.insuranceRow} id="QA_Insurance_Effective_Dates">
                <CustomTypography className={classes.patientSummaryInsuranceLabel} variant="patientSummaryInsuranceLabel">
                    <FormattedMessage id="People.summary.label.effectiveDates" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryInsuranceValue} variant="patientSummaryInsuranceValue">
                N/A
                </CustomTypography>
            </Box>
        </Box>
    )
}

export default Insurance