import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid, CardContent, Card, Typography, Button, Box
} from "@mui/material";
import SnackbarContent from "../../SnackBars/SnackbarContent";
import { useStyles } from './styles';
import { FormattedMessage, useIntl } from "react-intl";
import Controls from 'admin/components/Controls/Controls';
import { AppReduxStore } from "admin/store/reducerTypes";
import { useSnackbar } from "notistack";
import { DropzoneAreaBase, FileObject } from "mui-file-dropzone";
import { validateRequired } from 'admin/common/utils';
import { useForm } from '../../useForm';
import { saveRegistrationEmails, storeRegistrationEmailsPatientData } from "admin/store/actions";
import { ReactComponent as ArrowUpCircle } from "icon-library/SVG/Arrow_Up-Circle.svg";
import { createSvgIcon } from "../../../../utils";
import { ADMIN_WEB_CACHE } from "admin/constant";
import { updateWebAdminCachePractice } from "admin/store/actions";
import { getAppIdToken } from "authentication/appAuth";
const ArrowUpCircleIcon = createSvgIcon(ArrowUpCircle);

const { ENABLE_API_URL } = (window as any).env

const EditRegistrationPatients = forwardRef((props, ref) => {
  const classes = useStyles();
  const { admin, auth, configuration } = useSelector((state: AppReduxStore) => state)
  const { adminWebCachePractice } = admin
  const dispatch = useDispatch();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar()
  const [files, setFiles] = useState<FileObject[]>([]);

  const validate = (fieldValues = values) => {
    const err = { ...errors };

    if ('body' in fieldValues) {
      err.body = validateRequired(fieldValues.body, intl)
    }
    if ('signature' in fieldValues) {
      err.signature = validateRequired(fieldValues.signature, intl)
    }
    if ('closing' in fieldValues) {
      err.closing = validateRequired(fieldValues.closing, intl)
    }
    setErrors({
      ...err
    })
    if (fieldValues === values)
      return Object.values(err).every(x => x === "")
  }
  const { subject, greeting, body, closing, signature, type, logoUrl } = configuration.sRegistrationEmailPatientsA
  const EditRegistrationPatientsInitialState = {
    subject: subject ? subject : intl.formatMessage({ id: "RegistrationEdit.Text.Subject" }),
    greeting: greeting ? greeting : intl.formatMessage({ id: "RegistrationEdit.Text.Greeting" }),
    body: body ? body : intl.formatMessage({ id: "RegistrationEdit.Text.Body" }),
    closing: closing ? closing : "",
    signature: signature ? signature : (auth.currentPractice ? auth.currentPractice.name : admin.configurePracticeName),
    type: type ? type : intl.formatMessage({ id: "RegistrationEdit.Text.PatientRegistration" }),
    logoUrl: logoUrl ? logoUrl : configuration?.waitingRoom?.logoUrl
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(EditRegistrationPatientsInitialState, true, validate);


  useEffect(() => {
    if (admin.configurePracticeId) {
      const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData?.patientEmail
      if (tempFormData) {
        values.subject = tempFormData?.subject
        values.greeting = tempFormData?.greeting
        values.body = tempFormData?.body
        values.closing = tempFormData?.closing
        values.signature = tempFormData?.signature
        values.type = tempFormData?.type
        values.logoUrl = tempFormData?.logoUrl
      }
    }
  }, [admin.configurePracticeId])

  useImperativeHandle(
    ref,
    () => ({
      submitForm(e: any) {
        return handleSubmit(e);
      },
      saveFormData(e: any) {
        return handleStoreEditRegistrationData(e);
      },
      submitFormDataPatient(e: any) {
        return dispatch(saveRegistrationEmails(values));
      },
      saveAndContinueLater(index: number) {
        return saveAndContinue(index);
      }

    }),
  )

  const handleAddFile = (attachedFiles: any[]) => {
    values.logoUrl = attachedFiles[0].data
    values.logoFileObject = attachedFiles[0]
    setFiles(attachedFiles);
  };

  const handleRemoveFile = (deletedFileObject: FileObject, index: number) => {
    values.logoUrl = ""
    values.logoFileObject = ""
    files.splice(index, 1);
    setFiles(files);
  };

  const displayErrorAlert = () => {
    enqueueSnackbar(null, {
      preventDuplicate: true,
      content: (key: any) => (
        <div role="alert">
          <SnackbarContent
            snackbarKey={key}
            type={'error'}
            message={intl.formatMessage({ id: 'Error uploading file' })}
          />
        </div>
      )
    });
  };

  const handleLogoUploadError = () => {
    displayErrorAlert();
    setFiles([]);
  };

  const handleStoreEditRegistrationData = (e: any) => {
    e.preventDefault();
    if (validate()) {
      dispatch(storeRegistrationEmailsPatientData(values))
      resetForm()
      return true
    }

    enqueueSnackbar(null, {
      preventDuplicate: true, content: (key: any) => (<div role="alert"><SnackbarContent snackbarKey={key} type={'error'}
        message={intl.formatMessage({ id: "Invalid" })} desc={intl.formatMessage({ id: "RegistrationEdit.Error.FormValidationError" })} /></div>
      )
    })
    return false;
  };

  const saveAndContinue = (index: number) => {
    const formData = adminWebCachePractice?.formData || {};
    formData[admin?.configurePracticeId] = {
      currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
      parentOrgName: auth.currentOrg?.name,
      tempFormData: {
        name: admin.configurePracticeName,
        configurePractice: true,
        patientEmail: {
          subject: values.subject,
          greeting: values.greeting,
          body: values.body,
          closing: values.closing ? values.closing : '',
          signature: values.signature,
          type: values.type
        },
        selectedStep: index,
      }
    }
    dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (validate()) {
      const axios = require('axios');
      const formData = new FormData();
      const imagedata = values?.logoFileObject;

      if ((auth && auth.currentPractice && auth.currentPractice.id && imagedata) || (admin && admin.configurePracticeId && imagedata)) {
        const presignedPutUrl =
            ENABLE_API_URL +
            `/api/admin/v1/practice/configuration/logo?practiceId=${
                auth.currentPractice ? auth.currentPractice.id : admin.configurePracticeId
            }`;
        formData.append("image", imagedata.file);
        const headerConfig = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: 'Bearer ' + getAppIdToken()
          }
        }

        axios.put(presignedPutUrl, formData, headerConfig)
          .then((res: { data: any; }) => {
            //console.log(res.data);
          })
          .catch((error: any) => {
            //console.log(error)
            handleLogoUploadError();
          });
      }
      values.closing = values.closing ? values.closing : '';
      dispatch(saveRegistrationEmails(values))
      resetForm()
      return true
    }
    enqueueSnackbar(null, {
      preventDuplicate: true, content: (key: any) => (<div role="alert"><SnackbarContent snackbarKey={key} type={'error'}
        message={intl.formatMessage({ id: "Invalid Input" })} desc={intl.formatMessage({ id: "RegistrationEdit.Error.FormValidationError" })} /></div>
      ),
    })
    return false
  };

  const restoreDefault = () => {
    values.subject = intl.formatMessage({ id: "RegistrationEdit.Text.Subject" })
    values.greeting = intl.formatMessage({ id: "RegistrationEdit.Text.Greeting" })
    values.body = intl.formatMessage({ id: "RegistrationEdit.Text.Body" })
    values.closing = intl.formatMessage({ id: "RegistrationEdit.Text.Closing" })
    values.signature = auth.currentPractice ? auth.currentPractice.name : admin.configurePracticeName
    values.type = intl.formatMessage({ id: "RegistrationEdit.Text.PatientRegistration" })
    validate()
  }

  return (
    <Grid container className={classes.flexInline}>
      <Grid item xs={6} style={{ paddingRight: "25px", backgroundColor: "#F6F8FA" }}>
        <Grid container className={classes.flexInline}>
          <Grid item xs>
            <Typography variant={"h4"}>Edit</Typography>
          </Grid>
          <Grid item xs={"auto"}>
            {(values.subject !== EditRegistrationPatientsInitialState.subject || values.greeting !== EditRegistrationPatientsInitialState.greeting || values.body !== EditRegistrationPatientsInitialState.body || values.closing !== EditRegistrationPatientsInitialState.closing || values.signature) && <Button href="#" style={{ backgroundColor: "#F6F8FA" }} className={classes.nav} onClick={() => { restoreDefault() }}><Typography component="span" variant={"h4"}>Restore Default</Typography></Button>}
          </Grid>
        </Grid>
        <div className={classes.labelCtrl} style={{ paddingTop: "10px", whiteSpace: 'pre-wrap' }}>
          <Controls.Input
            name="subject"
            label="Subject Line *"
            multiline={true}
            rows={2}
            placeholder="You have been invited to Altais eNable by your practice."
            value={values.subject}
            onChange={handleInputChange}
          />
          <Typography variant="h6" style={{ textAlign: 'left', color: '#393948', paddingTop: "10px" }}>
            <FormattedMessage id={"RegistrationEdit.Label.AddLogo"} />
          </Typography>
          <DropzoneAreaBase Icon={ArrowUpCircleIcon as any}
            acceptedFiles={[".jpg,.jpeg,.png,.gif"]}
            onAdd={handleAddFile}
            onDelete={handleRemoveFile}
            filesLimit={1}
            maxFileSize={2097152}
            showAlerts={true}
            fileObjects={files}
            dropzoneClass={classes.dropzoneClass}
            dropzoneText="Add file or drop files to upload"
            useChipsForPreview={true}
            previewGridClasses={{ container: classes.previewGridClasses }}
            showPreviews={true}
            showPreviewsInDropzone={false}
            previewText=""
          />
        </div>
        <Grid container className={classes.flexInline}>
          <Grid item xs={6} className={classes.paddingTop10}>
            <Controls.Select
              name="greeting"
              label="Greetings *"
              value={values.greeting ? values.greeting : 'Greetings'}
              className={classes.formControl}
              onChange={handleInputChange}
              options={[{
                id: "Greetings",
                title: "Greetings"
              },
              {
                id: "Welcome",
                title: "Welcome"
              }, {
                id: "Hello",
                title: "Hello"
              }, {
                id: "Dear",
                title: "Dear"
              }]}
            />
          </Grid>
          <Grid item xs={12} className={classes.paddingTop10}>
            <Controls.Input
              className={classes.textarea}
              name="body"
              label="Body*"
              value={values.body}
              multiline={true}
              rows={3}
              onChange={handleInputChange}
              maxLength="5000"
              error={errors.body}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.flexInline}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} className={classes.paddingTop10}>
            <Controls.Select
              name="closing"
              label="Closing *"
              value={values.closing ? values.closing : ""}
              className={classes.formControl}
              onChange={handleInputChange}
              error={errors.closing}
              options={[{
                id: "Warm Regards",
                title: "Warm Regards"
              },
              {
                id: "Sincerely",
                title: "Sincerely"
              }, {
                id: "Thank you",
                title: "Thank you"
              }]}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.flexInline}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} className={classes.paddingTop10}>
            <Controls.Input
              name="signature"
              label="Signature *"
              value={(values.signature === "$practiceName") ? auth.currentPractice?.name : values.signature}
              onChange={handleInputChange}
              maxLength="100"
              error={errors.signature}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "25px", backgroundColor: "#F6F8FA" }}>
        <Typography variant={"h4"}>Preview</Typography>
        <CardContent>
          <div className={classes.labelCtrl} style={{ paddingTop: "8px" }}></div>
          <Typography component='div' style={{ wordWrap: "break-word" }}>
            <Box sx={{ fontWeight: 'bold' }} display='inline'>Subject Line: </Box>
            {values.subject ? values.subject : 'You have been invited to Altais eNable by your practice.'}
          </Typography>
        </CardContent>
        <Card>
          <CardContent>
            {values.logoUrl ?
              <Grid container className={classes.logoContent}>
                <img src={values.logoUrl} alt="Logo" style={{ maxWidth: "180px", maxHeight: "150px" }} crossOrigin="anonymous" />
              </Grid> :
              <div className={classes.logo}>
                Logo goes here
              </div>
            }

            <Typography className={classes.signText}>
              {values.greeting ? values.greeting : 'Greetings'} {'<FirstName>,'}
            </Typography>
            <Typography className={classes.body1}>
              {values.body ? values.body : '<Body>'}
            </Typography>
            <div className={classes.before}>
              <Grid container className={classes.flexInline}>
                <Typography variant={"h3"} style={{ fontWeight: 300 }}>{intl.formatMessage({ id: "RegistrationEdit.Actions.Instructions" })}</Typography>
                <ol type="1" style={{ fontWeight: 300 }}>
                  <li>{intl.formatMessage({ id: "RegistrationEdit.Actions.Download" })} <a href="#DownloadAppLink">{intl.formatMessage({ id: "RegistrationEdit.Actions.Here" })}</a></li>
                  <li>{intl.formatMessage({ id: "RegistrationEdit.Actions.AfterDownloading" })} <a href="#RegistrationLink"> {intl.formatMessage({ id: "RegistrationEdit.Actions.Link" })} </a> {intl.formatMessage({ id: "RegistrationEdit.Actions.Device" })}</li>
                  <li>{intl.formatMessage({ id: "RegistrationEdit.Actions.InputMsg" })}</li>
                  <li>{intl.formatMessage({ id: "RegistrationEdit.Actions.Follow" })}</li>
                </ol>
              </Grid>
            </div>

            <div className={classes.rightText}>
              <Typography variant={"h4"} className={classes.signText}>{values.closing ? `${values.closing},` : ""}</Typography>
              <Typography variant={"h4"} className={classes.signText} style={{ textAlign: "right" }}>{(values.signature === "$practiceName") ? auth.currentPractice?.name : values.signature}</Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
})

export default EditRegistrationPatients