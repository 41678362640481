import { BORDER_RADIUS, COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";

const variants = {
    light: {
        background: COLORS.OPTION_MENU_BG_LIGHT,
        color: COLORS.OPTION_MENU_ITEM_TEXT_LIGHT,
        selectedBackground: COLORS.LIGHT_BLUE,
        dangerSelectedBackground: COLORS.REMOVE_BTN,
        dangerText: COLORS.REMOVE_BTN,
        dangerSelectedText: COLORS.OPTION_MENU_ITEM_TEXT,
        titleColor: COLORS.OPTION_MENU_TITLE_TEXT_LIGHT,
        menuItemText: COLORS.OPTION_MENU_ITEM_TEXT_LIGHT,
        menuItemSelectedText: COLORS.OPTION_MENU_ITEM_TEXT,
    },
    dark: {
        background: COLORS.OPTION_MENU_BG,
        color: COLORS.OPTION_MENU_ITEM_TEXT,
        selectedBackground: COLORS.LIGHT_BLUE,
        titleColor: COLORS.OPTION_MENU_TITLE_TEXT,
        menuItemText: COLORS.OPTION_MENU_ITEM_TEXT,
    },
} as const;

export const useStyles = makeStyles(() => ({
    titleWrapper: {
        paddingTop: SIZING.scale400,
        paddingLeft: SIZING.scale400,
        paddingRight: SIZING.scale400,
        color: variants.light.titleColor,
        ".optionMenu--dark &": {
            color: variants.dark.titleColor,
        },
    },
    menuList: {},
    menuItemWrapper: {
        width: "100%",
        padding: `0 ${SIZING.scale200} 0`,
        // Coloring logic -->
        // It uses CSS variables to make CSS rules much easier
        "--item-content-color": variants.light.menuItemText,
        "--item-background-color": variants.light.background,
        ".optionMenu--dark &": {
            "--item-content-color": variants.dark.menuItemText,
            "--item-background-color": variants.dark.background,
        },
        "&.optionMenu__menuItemWrapper--danger": {
            "--item-content-color": variants.light.dangerText,
        },
        "&:hover, &.optionMenu__menuItemWrapper--selected": {
            "--item-content-color": variants.light.menuItemSelectedText,
            "--item-background-color": variants.light.selectedBackground,
            ".optionMenu--dark &": {
                "--item-content-color": variants.dark.menuItemText,
                "--item-background-color": variants.dark.selectedBackground,
            },
            "&.optionMenu__menuItemWrapper--danger": {
                "--item-content-color": variants.light.dangerSelectedText,
                "--item-background-color": variants.light.dangerSelectedBackground,
            },
        },
        // <-- Coloring logic
        "& .menuItem": {
            display: "flex",
            width: "100%",
            alignItems: "center",
            background: "var(--item-background-color)",
            borderRadius: BORDER_RADIUS.SMALL,
            padding: `${SIZING.scale100} ${SIZING.scale200}`,
            color: "var(--item-content-color)",
            "& .menuItemIcon": {
                height: SIZING.scale800,
                width: SIZING.scale800,
                marginRight: SIZING.scale100,
                "& g, & path": {
                    fill: "var(--item-content-color)",
                },
            },
        },
    },
    optionDividerWrapper: {
        width: "100%",
        paddingLeft: SIZING.scale200,
    },
    optionDivider: {
        width: "100%",
        height: "1px",
        marginTop: SIZING.scale200,
        marginBottom: SIZING.scale200,
        borderRadius: BORDER_RADIUS.PILL,
        background: COLORS.OPTION_DIVIDER_BG,
    },
}));
