import React from "react";
import { useSelector} from "react-redux";
import {AppReduxStore} from "store/reducerTypes";
import Overview from "./Overview/Overview";
import Insurance from "./Insurance/Insurance";
import IncorrectInfo from "./IncorrectInfo/IncorrectInfo";
import { Grid, Box} from "@mui/material";
import {SIZING} from "theme";

const PatientSummary = () => {
    const { selectedPatient } = useSelector((state: AppReduxStore) => state.patient)

    if (!selectedPatient) return null

    return (
        <Box padding={`${SIZING.scale400} ${SIZING.scale700} ${SIZING.scale400} ${SIZING.scale400}`} id="QA_patientSummary">
            <Grid container spacing={3}>
                <Grid item xs>
                <IncorrectInfo />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs>
                <Overview selectedPatient={selectedPatient}/>
                </Grid>
                <Grid item xs>
                <Insurance selectedPatient={selectedPatient}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PatientSummary