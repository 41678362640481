import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { FormHelperText, Tooltip, Select, MenuItem, Divider, TextField, Grid, Box, Typography, FormControl, Button, IconButton, SelectChangeEvent } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch } from "react-redux";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { IQuestion, IAnswer, IFormInput } from "admin/views/viewsTypes";
import { FormattedMessage, useIntl } from "react-intl";
import { createSvgIcon } from "utils";
import {ReactComponent as Info} from "icon-library/SVG/Info-Light_Circle.svg";
import {ReactComponent as Add} from "icon-library/SVG/Plus_Circle.svg";
import {COLORS} from "admin/theme";
import { useForm, useFieldArray, FormProvider, Controller, SubmitHandler } from "react-hook-form";
import AnswerOptions from "./AnswerOptions";
import { saveFormQuestions } from "admin/store/actions/configuration";

interface IKeyValue {
  key: string;
  value: string;
}

type Props = {
  currentForm: IFormInput;
  readOnlyState: boolean;
  ref: any;
};

const AdditionalQuestions: React.FC<Props> = forwardRef((props: Props, ref: any) => {
  const currentForm = props.currentForm;
  const readOnlyState = props.readOnlyState;
  const maxQuestions: number = currentForm?.configurable ? 0 : 5;
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const AddIcon = createSvgIcon(Add);
  const InfoIcon = createSvgIcon(Info);
  const categoryName = "Additional Questions"; // currentForm?.configurable ? "Questions" : "Additional Questions";
  const additionalQuestions = currentForm?.questions?.filter((e: any) => e.categoryName === categoryName);
  
  const questionTypes: IKeyValue[] = [
    { key: "Checkbox", value: "Multiple choice" },
    { key: "Dropdown", value: "Drop down" },
    { key: "TextField", value: "Text input" },
    { key: "RadioButton", value: "Single choice" }, //MultipleChoice is legacy name
    { key: "Number", value: "Number input" }
  ];

  const handleAnswerTypeChange = (event: SelectChangeEvent, index: number) => {
    const answerTypeVal: string = event.target.value as string;
    methods.setValue(`questions.${index}.questionType`, answerTypeVal);
    methods.clearErrors(`questions.${index}.questionType`);
    methods.clearErrors(`questions.${index}.answerOption`);
    validateQuestionType();
    return answerTypeVal;
  };

  // const defaultValues = additionalQuestions.map((question: IQuestion) => {
  //   return {
  //     answerOption: question.answerOption,
  //     questionText: question.questionText,
  //     questionType: question.questionType,
  //     id: question.id
  //   }
  // });

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: { questions: additionalQuestions }
  });

  const { register, control, handleSubmit, reset, formState } = methods;
  const { isSubmitSuccessful, errors } = formState;
  const { fields, append } = useFieldArray({ control, name: "questions" });

  useEffect(() => {
    if (isSubmitSuccessful) { /*
      reset({
        answerOption: [],
        questionText: "",
        questionType: ""
      });
    */ }
  }, [isSubmitSuccessful, reset])

  const validateAllQuestions = () => {
    const questionTextStatus = validateQuestionText();
    const questionTypeStatus = validateQuestionType();
    const answerOptionStatus = validateAnswerOption();
    return (questionTextStatus && questionTypeStatus && answerOptionStatus);
  };

  const validateQuestionText = () => {
    const allQuestions = methods.getValues("questions");
    allQuestions.forEach((item: any, index: number) => {
      const question = methods.getValues(`questions.${index}`);
      if (question?.questionText?.trim() === "") {
        methods.setError(`questions.${index}.questionText`, {
          type: "validate",
          message: intl.formatMessage({
            id: "PatientFormConfig.Required.QuestionText"
          })
        });
      } else {
        methods.clearErrors(`questions.${index}.questionText`);
      }
    });
    if (errors.questions && errors.questions.length > 0)
      return false;
    else
      return true;
  };

  const validateQuestionType = () => {
    const allQuestions = methods.getValues("questions");
    allQuestions.forEach((item: any, index: number) => {
      const question = methods.getValues(`questions.${index}`);
      if (question.questionType === '') {
        methods.setError(`questions.${index}.questionType`, {
          type: "validate",
          message: intl.formatMessage({
            id: "PatientFormConfig.Required.QuestionType"
          })
        });
      } else {
        methods.clearErrors(`questions.${index}.questionType`);
      }
    });
    if (errors.questions && errors.questions.length > 0)
      return false;
    else
      return true;
  };

  const validateAnswerOption = () => {
    const allQuestions = methods.getValues("questions");
    allQuestions.forEach((item: any, index: number) => {
      const question = methods.getValues(`questions.${index}`);
      const questionTypeVal = question.questionType;
      let answerOptions: IAnswer[] = question.answerOption || [];
      answerOptions = answerOptions.filter((f: IAnswer) => f.answerText.trim() !== '');
      const minAnswerOptionsRequired =
          questionTypeVal === "RadioButton" || questionTypeVal === "Dropdown"
              ? 2
              : questionTypeVal === "Checkbox"
              ? 1
              : 0;
      if (answerOptions.length < minAnswerOptionsRequired && minAnswerOptionsRequired > 0) {
        methods.setError(`questions.${index}.answerOption`, {
          type: "validate",
          message: intl.formatMessage({
            id: "PatientFormConfig.Required.AnswerOption"
          }, {
            minAnswerOptionsRequired: minAnswerOptionsRequired
          })
        });
      } else {
        methods.clearErrors(`questions.${index}.answerOption`);
      }
    });
    if (errors.questions && errors.questions.length > 0)
      return false;
    else
      return true;
  };

  const getHighestOrderIndex = () => {
    const allQuestions = methods.getValues("questions");
    let highestOrderIndex = 0;
    allQuestions.forEach((item: any, index: number) => {
      if (item.orderIndex > highestOrderIndex) {
        highestOrderIndex = item.orderIndex;
      }
    });
    return highestOrderIndex;
  };

  useImperativeHandle(ref, () => ({
    saveFormQuestions() {
      const data = methods.getValues();
      if (data && data.questions && data.questions.length > 0 && validateAllQuestions()) {
        dispatch(saveFormQuestions(currentForm, data));
      }
    },
    validateFormQuestions() {
      return validateAllQuestions();
    }
  }));

  const onSubmit: SubmitHandler<any> = (data: any) => {
    if (data && data.questions && data.questions.length > 0 && validateAllQuestions()) {
      dispatch(saveFormQuestions(currentForm, data));
    }
  };

  const handleAddEmptyQuestions = () => {
    if (!currentForm?.configurable && currentForm?.name.indexOf("-MODIFIED") === -1) {
      dispatch(setActiveModal(MODAL_TYPES.CREATE_NEW_FORM));
    } else {
      const newQuestion = {
        id: "",
        answerOption: [],
        questionText: "",
        editable: true,
        isEnabled: true,
        questionType: "",
        categoryName: categoryName,
        description: "",
        groupIndex: null,
        orderIndex: getHighestOrderIndex() + 1
      };
      if (validateAllQuestions())
        append(newQuestion);
    }
  };

  const getQuestionIndexOnForm = (questionOrderIndex: number) => {
    const allQuestions = methods.getValues("questions");
    let index = -1;
    allQuestions.forEach((question: IQuestion, idx: number) => {
      if (question.orderIndex === questionOrderIndex) 
        index = idx;
    })
    return index;
  };

  return (
    <div className={classes.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box>
                  <Typography variant="h3">
                    {intl.formatMessage({ id: "PatientFormConfig.Label.AdditionalQuestions" })}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip arrow={true} title="By adding custom questions, you will create a modified standard form. Both, the original and the modified forms will be stored in your library. You may add up to 5 questions.">
                    <IconButton color="primary" size="large">
                      <InfoIcon fill={COLORS.DEFAULT_BTN} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
            {fields?.sort((a: any, b: any) => { return (a.orderIndex - b.orderIndex) })
              .map((question: any) => {
                return (
                  (currentForm?.name.indexOf("-MODIFIED") !== -1 || currentForm?.name.indexOf("customForm") !== -1) &&
                  <Grid key={question.orderIndex + "-" + question.id} container item xs={12} spacing={1} alignItems="flex-start">
                    <Grid item xs={9}>
                      <Box display="flex" flexDirection="row" alignItems="flex-start">
                        <Box mt={1} mr={1}>
                          <label htmlFor={"questionText"}>Q.</label>
                        </Box>
                        <Box flexGrow={1}>
                          <Controller
                            name={`questions.${getQuestionIndexOnForm(question.orderIndex)}.questionText`}
                            control={control}
                            rules={{ required: intl.formatMessage({ id: "PatientFormConfig.Required.QuestionText" }) }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                {...register(`questions.${getQuestionIndexOnForm(question.orderIndex)}.questionText`)}
                                key={question.id}
                                variant="outlined"
                                id="questionText"
                                placeholder={intl.formatMessage({ id: "PatientFormConfig.Placeholder.QuestionText" })}
                                disabled={readOnlyState}
                                onChange={(event: any) => { onChange(event); validateQuestionText(); }}
                                error={!!errors?.questions?.[getQuestionIndexOnForm(question.orderIndex)]?.questionText}
                                helperText={errors ? errors?.questions?.[getQuestionIndexOnForm(question.orderIndex)]?.questionText?.message : null}
                                fullWidth
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl error={!!errors?.questions?.[getQuestionIndexOnForm(question.orderIndex)]?.questionType}>
                        <Controller
                          name={`questions.${getQuestionIndexOnForm(question.orderIndex)}.questionType`}
                          control={control}
                          rules={{ required: intl.formatMessage({ id: "PatientFormConfig.Required.QuestionType" }) }}               
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Select
                              {...register(`questions.${getQuestionIndexOnForm(question.orderIndex)}.questionType`)}
                              defaultValue={methods.getValues(`questions.${getQuestionIndexOnForm(question.orderIndex)}.questionType`)}
                              key={question.id}
                              variant="outlined"
                              displayEmpty
                              disabled={readOnlyState}
                              onChange={(event: SelectChangeEvent) => { onChange(handleAnswerTypeChange(event, getQuestionIndexOnForm(question.orderIndex))) }}
                              error={!!errors?.questions?.[getQuestionIndexOnForm(question.orderIndex)]?.questionType}
                              fullWidth
                            >
                              <MenuItem key={`questions.${getQuestionIndexOnForm(question.orderIndex)}.empty`} value="">
                                {intl.formatMessage({id: "PatientFormConfig.Placeholder.QuestionType"})}
                              </MenuItem>
                              {questionTypes?.map((questionType: IKeyValue) => (
                                <MenuItem key={`questions.${getQuestionIndexOnForm(question.orderIndex)}.${questionType.key}`} value={questionType.key}>
                                  {questionType.value}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <FormHelperText error={true}>
                          {errors ? errors?.questions?.[getQuestionIndexOnForm(question.orderIndex)]?.questionType?.message : null}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <AnswerOptions question={question} disabled={readOnlyState} {...register(`questions.${getQuestionIndexOnForm(question.orderIndex)}.answerOption`)} {...{ control }} />
                    <Grid item xs={12}>
                      <Divider style={{ marginBottom: ".75rem", marginTop: ".5rem" }} />
                    </Grid>
                  </Grid>
                )
              })}
          </Grid>
          <Grid container item xs={12}>
            {currentForm?.configurable &&
              <Button onClick={handleAddEmptyQuestions} disabled={readOnlyState} size="medium" color="primary" startIcon={<AddIcon fill={(readOnlyState ? COLORS.DISABLED_BTN : COLORS.DEFAULT_BTN)} />}>
                <FormattedMessage id="PatientFormConfig.Label.AddCustomQuestions"></FormattedMessage>
              </Button>
            }
            {!currentForm?.configurable &&
              <Button onClick={handleAddEmptyQuestions} disabled={(maxQuestions <= fields.length) || readOnlyState} size="medium" color="primary" startIcon={<AddIcon fill={(readOnlyState ? COLORS.DISABLED_BTN : COLORS.DEFAULT_BTN)} />}>
                <FormattedMessage id="PatientFormConfig.Label.AddQuestions" values={{ count: maxQuestions - fields.length }}></FormattedMessage>
              </Button>
            }
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
})

export default AdditionalQuestions