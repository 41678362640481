import { Box } from "@mui/material";
import classNames from "classnames";
import { ReactComponent as Lock } from "icon-library/SVG/Lock.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { useDateFormatters } from "common/utils/use-date-formatter";
import { LinkTab, TabContainer } from "common/components/Tabs";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { RequestActions } from "./request-actions";
import ReferrerOrAssigneeInfo from "./referrer-or-assignee-info";
import DetailsHeaderSubject from "./details-header-subject";
import { useRequestInfo } from "./use-request-info";
import { useStyles } from "./styles";
import { EConsultFolderName, EConsultRequest } from "./types";

interface EConsultRequestDetailsHeaderProps extends EConsultRequest {
    folderName: EConsultFolderName;
    eConsultRequestPath: string;
}

const DETAILS_TABS = {
    received: [
        { name: "notes", to: "", labelId: "EConsult.newRequestModal.notes" },
        { name: "patient", to: "/patient", labelId: "EConsult.newRequestModal.patient" },
        { name: "attachments", to: "/attachments", labelId: "EConsult.newRequestModal.attachments" },
        { name: "messages", to: "/messages", labelId: "EConsult.request.content.messages", exact: false },
       /*{ name: "referrer", to: "/referrer", labelId: "EConsult.request.content.referrer" },*/ //removed according to https://altais.atlassian.net/browse/APOLLO-975
    ],
    sent: [
        { name: "notes", to: "", labelId: "EConsult.newRequestModal.notes" },
        { name: "patient", to: "/patient", labelId: "EConsult.newRequestModal.patient" },
        { name: "attachments", to: "/attachments", labelId: "EConsult.newRequestModal.attachments" },
        { name: "messages", to: "/messages", labelId: "EConsult.request.content.messages", exact: false },
        /*{ name: "recipients", to: "/recipients", labelId: "EConsult.request.content.recipients" },*/ //removed according to https://altais.atlassian.net/browse/APOLLO-975
    ],
};

const idPrefix = "QA_EConsultDetails_header";
const EConsultRequestDetailsHeader = ({
    folderName,
    eConsultRequest,
    eConsultRequestPath,
}: EConsultRequestDetailsHeaderProps) => {
    const classes = useStyles();
    const dateFormatters = useDateFormatters();
    const { status, recipientStatus, assignedByOther } = useRequestInfo(eConsultRequest);

    const getTimeFromSubmit = () => {
        if (!eConsultRequest.submittedDate) {
            return null;
        }
        return dateFormatters["nicetime"](eConsultRequest.submittedDate);
    };

    const showPadlock =
        status === "REJECTED" ||
        status === "CANCELLED" ||
        status === "CLOSED" ||
        recipientStatus === "REJECTED" ||
        assignedByOther;

    return (
        <div className={classes.header}>
            <Box display="flex" justifyContent="space-between">
                {showPadlock && <SVGIcon className="eConsult__details__header__lockIcon" svg={Lock} size="scale1500" />}
                <div className={classes.title}>
                    <DetailsHeaderSubject idPrefix={idPrefix} eConsultRequest={eConsultRequest} />
                    <div className={classNames(classes.secondInfo, `eConsult__details__header--${folderName}`)}>
                        <Box display="flex" justifyContent="space-between" flexGrow={1}>
                            <ReferrerOrAssigneeInfo eConsultRequest={eConsultRequest} />
                            <CustomTypography
                                id={`${idPrefix}createTime`}
                                className="eConsult__details__header__createTime"
                                variant="eConsultHeaderSubTitle"
                                title={
                                    eConsultRequest.submittedDate
                                        ? `Created: ${dateFormatters["MMMM DD, YYYY, hh:mm aa"](
                                              eConsultRequest.submittedDate
                                          )}`
                                        : null
                                }
                            >
                                {getTimeFromSubmit()}
                            </CustomTypography>
                        </Box>
                    </div>
                </div>
                <Box display="flex" alignItems="center">
                    <RequestActions idPrefix={idPrefix} eConsultRequest={eConsultRequest} />
                </Box>
            </Box>

            <TabContainer className={classes.tabContainer}>
                {DETAILS_TABS[folderName].map((tab) => {
                    return (
                        <LinkTab
                            key={tab.labelId}
                            name={tab.name}
                            labelId={tab.labelId}
                            downArrow
                            to={`${eConsultRequestPath.replace(":requestId", eConsultRequest.id)}${tab.to}`}
                            exact={tab.exact ?? true}
                        />
                    );
                })}
            </TabContainer>
        </div>
    );
};

export default EConsultRequestDetailsHeader;
