import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() =>({
    titleWrapper: {
        padding: SIZING.scale600,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: COLORS.LEARNING_CENTER_HEADER_BG,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
    },
    close: {
        cursor: 'pointer'
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: SIZING.scale800,
        background: COLORS.LEARNING_CENTER_BG
    },
    xlButton: {
        padding: SIZING.scale700
    },
    actions: {
        paddingLeft: SIZING.scale600,
        background: COLORS.LEARNING_CENTER_BG,
        justifyContent: "flex-start"
    }
}));