import createReducer from '../createReducer'
import {MeetingState} from '../reducerTypes'

const initialState: MeetingState = {
    incoming: {
        from: null,
        fromName: null,
        meetingId: null,
        pin: null,
        toName: null
    },
    meetingId: null,
    isHost: null,
    pin: null,
    loading: false,
    calling: false,
    to: null,
    from: null,
    toName: null,
    fromName: null,
    declined: false,
    ended: false,
    joinAgainStatus:false,
    isEvistMeeting:false,
    profile: null,
    currentEvisitDetails:null,
    error:false,
    waiting:false
}

// action types with SYS_ prefix are only triggered by the handleSystemMessage action

export default createReducer(initialState, {
    'SYS_CALL_INCOMING': (state: MeetingState, payload: MeetingState) => {
      return {
          ...state,
          incoming: payload,
      }
    },
    'SYS_CALL_ACCEPTED': (state: MeetingState) => {
      return {
          ...state,
          calling: false
      }
    },
    'SYS_CALL_JOINED': (state: MeetingState) => {
        return {
            ...state,
            calling: false,
            waiting: false
        }
    },
    'CALL_JOIN_AGAIN': (state: MeetingState) => {
        return {
            ...state,
            isHost:true,
            joinAgainStatus:true
        }
    },
    'SYS_CALL_DECLINED': (state: MeetingState) => {
        return {
            ...state,
            declined: true,
            calling: false
        }
    },
    'UPDATE_EVIST_MEETING_STATUS': (state: MeetingState,payload: MeetingState) => {
        return {
            ...state,
            ...payload
        }
    },
    'CALL_OUTGOING': (state: MeetingState, payload: MeetingState) => {
        return {
            ...state,
            ...payload
        }
    },
    'CALL_ACCEPTED': (state: MeetingState) => {
        return {
            ...state,
            ...state.incoming,
            waiting: true,
            incoming: initialState.incoming,
            ended: false,
            isHost: false,
            calling: false
        }
    },
    'CALL_DECLINED': (state: MeetingState) => {
        return {
            ...state,
            incoming: initialState.incoming
        }
    },
    'CALL_ENDED': (state: MeetingState) => {
        return {
            ...state,
            ended: true,
            joinAgainStatus:false,
            isEvistMeeting: false,
            meetingId: null
        }
    },
    'CREATE_MEETING': (state: MeetingState) => {
        return {
            ...state,
            ended: false,
            loading: true
        }
    },
    'CREATE_MEETING_SUCCESS': (state: MeetingState, payload: MeetingState) => {
        return {
            ...state,
            ...payload,
            isHost: true,
            calling: true,
            loading: false,
            error:false
        }
    },
    'CREATE_MEETING_FAILURE': (state: MeetingState) => {
        return {
            ...state,
            loading: false,
            isHost: true,
            error: true
        }
    },
    'UPDATE_MEETING_ERROR': (state: MeetingState) => {
        return {
            ...state,
            error: true
        }
    },
    'CLOSE_MEETING': (state: MeetingState) => {
        return {
            ...initialState,
            incoming:state.incoming
        }
    }
})