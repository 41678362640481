import React, { createContext, useState, useCallback, useEffect } from "react"
import { debounce } from "underscore";
import { getParticipantName } from "store/actions/waitingRoom"
import { useReduxDispatch } from "store/utils"

export interface ParticipantDetails {
  displayName: string
  identity: string
  userType: 'PROVIDER' | 'PATIENT'
}


type ReactNode = React.ReactNode
export type ParticipantDetailsMap = {
    [id: string]: ParticipantDetails;
};

interface IContextProps {
  contextState: ParticipantDetailsMap;
  updateContextStateById: (participantId: string) => void;
  updateContextState: (details: ParticipantDetailsMap) => void
}

export const ParticipantContext = createContext({} as IContextProps);

export const ParticipantDetailsContextWrapper = ({ children }: { children: ReactNode }) => {

  const [contextState, handleContextState] = useState<ParticipantDetailsMap>({});
  const customDispatch = useReduxDispatch()
  const participantLength = Object.keys(contextState).length;

  const getParticipantDetails = useCallback(debounce(() => {

    const participantId = Object.keys(contextState).filter((k: string) => Object.keys(contextState[k]).length === 0);

    participantId.length &&
        customDispatch(getParticipantName(participantId)).then((result: any) => {
            const contextStateCopy: ParticipantDetailsMap = Object.assign(
                {},
                contextState,
                result.reduce((contextStateObj: ParticipantDetailsMap, participantDetails: ParticipantDetails) => {
                    contextStateObj[participantDetails.identity] = participantDetails;
                    return contextStateObj;
                }, {})
            );
            handleContextState(contextStateCopy);
        });
  }, 1000), [contextState])

  useEffect(() => {
    getParticipantDetails()
  }, [participantLength])

  const updateContextStateById = (participantId: string) => {
    handleContextState(prevState => ({
      ...prevState,
      [participantId]: {
        ...(prevState[participantId] || '')
      }
    }))
  }

  return (
    <ParticipantContext.Provider value={{ contextState, updateContextStateById, updateContextState: handleContextState }}>
      {children}
    </ParticipantContext.Provider>
  );
};
