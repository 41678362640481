import { defaultFeatureFlags, FeatureFlagSet } from "./types";
import { getEnabledFeatureFlagNames } from "./feature-flag-utils";
import { Service } from "lib/types";
import * as BrowserWrapper from "lib/browser-wrapper";
import EventListenerManager from "lib/event-listener-manager";

export type FeatureFlagEventListener = (enabledFeatureFlagNames: string[]) => void;
export type SecurityServiceConfig = {
    user?: {
        key: string;
    };
};

export interface ISecurityService extends Service<SecurityServiceConfig> {
    addFeatureFlagListener: (listener: FeatureFlagEventListener) => void;
    featureFlags: string[];
    removeFeatureFlagListener: (listener: FeatureFlagEventListener) => void;
    requestFeatureFlagUpdate: () => void;
}

const readCookies = (documentCookies: string) =>
    documentCookies.split(";").reduce((cookies, cookie) => {
        cookies[cookie.substring(0, cookie.indexOf("="))] = cookie.substring(cookie.indexOf("=") + 1);
        return cookies;
    }, {} as Record<string, string>);

class SecurityService implements ISecurityService {
    private _featureFlagListeners = new EventListenerManager<FeatureFlagEventListener, string[]>();
    protected _featureFlags: FeatureFlagSet = defaultFeatureFlags;

    protected updateFeatureFlags = (featureFlagSet: FeatureFlagSet) => {
        let testFeatureFlags = this._featureFlags;
        try {
            const cookiesContent = readCookies(BrowserWrapper.getCookie());
            const cookieContent = cookiesContent["FEATURE_SWITCHES"];
            testFeatureFlags = cookieContent ? JSON.parse(cookieContent) : null;
        } catch (error) {
            // TODO: Reporting to BE would be needed
        }
        this._featureFlags = {
            ...this._featureFlags,
            ...featureFlagSet,
            ...testFeatureFlags,
        };
        const enabledFeatureFlagNames = getEnabledFeatureFlagNames(this._featureFlags);
        this._featureFlagListeners.notifyEventListeners(enabledFeatureFlagNames);
    };

    /** Initializes Security Service.
     *  This is a mandatory call before using Security Service.
     *  @param config Optional configuration object.
     *                It can provide a user/key for LanuchDarkly, if specific user or group related feature flag set is needed.
     */
    async initialize(config?: SecurityServiceConfig): Promise<void> {
        this.updateFeatureFlags({} as FeatureFlagSet);
        // User might be hardcoded, if there is no user segmentation
        // await FeatureFlagProviderWrapper.initialize(config?.user?.key ?? "aa0ceb");
        this.requestFeatureFlagUpdate();
        Promise.resolve();
    }

    async destroy() {
        return Promise.resolve();
        // return FeatureFlagProviderWrapper.destroy();
    }

    /** Retrieves an updated set of feature flags from Launch Darkly and updates the feature flag store.
     */
    requestFeatureFlagUpdate() {
        // const featureFlagSet = FeatureFlagProviderWrapper.getFeatureFlags();
        // this.updateFeatureFlags(featureFlagSet);
    }

    addFeatureFlagListener = this._featureFlagListeners.addEventListener;

    removeFeatureFlagListener = this._featureFlagListeners.removeEventListener;

    get featureFlags(): string[] {
        return getEnabledFeatureFlagNames(this._featureFlags);
    }
}

export default SecurityService;
