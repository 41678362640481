import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, Button, DialogContent } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { MODAL_TYPES } from "constant";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "components/Modal/Modal";
import { useStyles } from "./styles";
import MultiSelectItem from "components/MultiSelectPopoverInput/MultiSelectItem";
import MultiSelectPopoverInput from "components/MultiSelectPopoverInput/MultiSelectPopoverInput";
import { debounce } from "underscore";
import { PATIENT_SEARCH } from "views/People/constants";
import { setActiveModal, searchPatientBySearchString } from "store/actions";
import { useForm } from "common/components/FormValidator/FormValidator";
import ErrorMessage from "common/components/ErrorMessage/ErrorMessage";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import 'moment-timezone';
import InputWithTranscription from "common/components/InputWithTranscription/InputWithTranscription";



const SaveWaitingRoomDetails: React.FC<any> = () => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const [searchedPatientRecipients, updateSearchedPatientRecipients] = useState([])
    const [searchTextPatient, setSearchTextPatient] = useState("")
    const [pinnedItemsPatient, setPinnedItemsPatient] = useState<any>(null)
    const [option, updateOptions] = useState<Array<string>>([])
    const [searchLoader, updateSearchLoader] = useState<Boolean>(false)
    const [commonLoader, updateCommonLoader] = useState<Boolean>(false)
    const [currentProvider, setcurrentProvider] = useState<Boolean>(false)
    const classes = useStyles({ currentProvider })
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")

    const {
        renderMessage,
        resetForm
    } = useForm()

    const searchPatient = useMemo(() => debounce((searchStr: string) => {
        if (searchStr.length >= 2) {
            updateSearchLoader(true)
            searchPatientBySearchString(searchStr, PATIENT_SEARCH.MESSAGES).then((data: any) => {
                updateSearchLoader(false)
                updateSearchedPatientRecipients(data)
            })
        }
    }, 250), [])


    useEffect(() => {
        searchPatient(encodeURIComponent(searchTextPatient.trim()))
    }, [searchPatient, searchTextPatient])

    useEffect(() => {
        resetForm()
    }, [option,currentProvider])

    const clearFields = () => {
        updateSearchedPatientRecipients([])
        setSearchTextPatient('')
        setPinnedItemsPatient(null)
        updateOptions([])
        updateSearchLoader(false)
        updateCommonLoader(false)
        resetForm(true)
        setcurrentProvider(false)
    }

    const onClose = () => {
        dispatch(setActiveModal(''))
        clearFields()
    }


    const renderItem = (item: any, changePinnedItems: any, pinnedItemsDetails: any, isMultiSelect: boolean, searchString: string) => {
        const selected = !!pinnedItemsDetails && (pinnedItemsDetails.id ? pinnedItemsDetails.id === item.id : pinnedItemsDetails.profileId === item.profileId)
        return (
            <MultiSelectItem
                item={item}
                selected={selected}
                key={item.id || item.profileId}
                onChange={() => changePinnedItems(item)}
                highlightText={searchString}
                themeColor="compose"
            />
        )
    }


    const onChange = (event: any, onValueChange: any) => {
        onValueChange(event.target.value)
    }



    const patientInput = renderMessage("patient", "required", pinnedItemsPatient);

    return (
        <Modal modalWidth="900px" id={MODAL_TYPES.PROVIDER.SAVE_WAITING_ROOM_DETAILS}>
            {commonLoader && <ComponentLoader />}
            <Box data-testid="invite-patient-modal-wrapper" className={classes.headerWrapper}>
                <div className={classes.titleWrapper}>
                    <div>
                        <Typography variant="body1" >
                            <FormattedMessage id="Save_Waiting_Room_Title" />
                        </Typography>
                    </div>
                    <Close className={classes.close} onClick={onClose} />
                </div>
                <div className={classes.titleWrapperSection}>
                    <div>
                        <div className={classes.saveDuration}>
                            <Typography variant="body2" className={classes.titleWrapperText} >
                                DURATION
                            </Typography>
                            <Typography variant="body2"  >
                                5m 47s
                            </Typography>
                        </div>
                       
                        <div className={classes.saveDuration}>
                            <Typography variant="body2" className={classes.titleWrapperText} >
                                SEEN BY
                            </Typography>
                            <Typography variant="body2"  >
                                Dr Brian Miller and Tracy McDonnald MA.
                            </Typography>
                        </div>
                    </div>
                <Button variant="contained"
                    data-testid="sendNotes"
                    color="secondary" className={classes.send}>
                    <Typography variant="button">
                        <FormattedMessage id="Save_Send_Notes" />
                    </Typography>
                </Button>
                </div>
                
            </Box>
            <DialogContent dividers className={classes.contentWrapper}>
                <div className={classes.contentChild}>
                    <Typography variant="h6" className={classes.patientTitle}>
                        PATIENT
                    </Typography>
                    <div className={classes.searchWrapSection}>
                        <Button variant="contained"
                            data-testid="sendNotes"
                            color="secondary" className={pinnedItemsPatient ?classes.findBtnPinned: classes.findBtn}>
                            <Typography variant="button">
                                Find "Sarah Brown"
                            </Typography>
                        </Button>
                       <div className={classes.inputWrapper}>
                        <MultiSelectPopoverInput
                            data={searchedPatientRecipients}
                            value={searchTextPatient}
                            onChange={(event: any) => onChange(event, setSearchTextPatient)}
                            qaid="QA_includePatientDetails"
                            placeholder={intl.formatMessage({
                                id: `Save_waiting_room_find_patient`,
                            })}
                            title="Find patient"
                            pinnedItems={pinnedItemsPatient}
                            setPinnedItems={setPinnedItemsPatient}
                            renderItem={renderItem}
                            loader={searchLoader}
                            isMultiSelect={false}
                            themeColor="compose"
                        />
                        <ErrorMessage message={patientInput} />
                    </div>
                    </div>
                    <div className={classes.descriptionBox}>
                    <Typography variant="h6" className={classes.inputLabel}>
                      <FormattedMessage id="Save_waiting_room_reason_label" />
                    </Typography>
                    <InputWithTranscription id="subjectLine"
                        onChange={(content: string) => setSubject(content)}
                        placeholder={intl.formatMessage({ id: `Save_waiting_room_reason` })}
                        value={subject}
                    />
                </div>

                <div className={classes.descriptionBox}>
                    <Typography variant="h6" className={classes.inputLabel}>
                        <FormattedMessage id="Save_waiting_room_description_label" />
                    </Typography>
                    <InputWithTranscription id="message"
                        className="messageInput"
                        multiline
                        onChange={(content: string) => setMessage(content)}
                        placeholder={intl.formatMessage({ id: `Save_waiting_room_description` })}
                        value={message}
                    />
                </div>
                </div>
              


                
            </DialogContent>
        </Modal>
    )
};

export default SaveWaitingRoomDetails