import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Close } from "assets/Close.svg";
import { ReactComponent as People } from "assets/People.svg";
import { useStyles } from "./styles";
import { ClippedItemProps } from "./types";

export default function ClippedItem(props: ClippedItemProps) {
  const { name, remove } = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <People className={classes.icon} />
      <Typography variant="h6" className={classes.text} noWrap>
        {name}
      </Typography>
      <Close
        className={classes.icon}
        onClick={remove}
        style={{ cursor: "pointer" }}
      />
    </Box>
  );
}
