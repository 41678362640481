import LayoutExtr from 'admin/components/Layout/LayoutExtr';
import LoadingIndicator from "admin/components/LoadingIndicator/LoadingIndicator";
import { useIntl } from "react-intl";
import LoginContent from "../LoginContent/azure-login-content";

export const Login = (props: any) => {
    const intl = useIntl()

    return (
        <LayoutExtr heading={``} subHeading={intl.formatMessage({ id: "Login.subHeading" })}>
            <LoginContent signIn={props.signIn} />
            <LoadingIndicator />
        </LayoutExtr>
    )
}
