import DialogModal from "components/Modals/dialog-modal/dialog-modal";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import * as CSS from "./class-names";
import { ValidationErrorObject } from "lib/validation/types";

type SubmitErrorDialogProps = {
    contentKey: string;
    errors: ValidationErrorObject[];
    idPrefix: string;
    onClose: () => void;
    titleKey: string;
};

const SubmitErrorDialog = ({ contentKey, errors, idPrefix, onClose, titleKey }: SubmitErrorDialogProps) => {
    const intl = useIntl();
    const styles = useStyles();

    return (
        <DialogModal
            idPrefix={idPrefix}
            title={intl.formatMessage({ id: titleKey })}
            onClose={onClose}
            className={`${styles[CSS.ErrorListDialog]} ${CSS.ErrorListDialog}`}
        >
            <span>{intl.formatMessage({ id: contentKey })}</span>
            <ul className={CSS.ErrorListDialogErrorList}>
                {errors.map((errorObj) => (
                    <li key={errorObj.id || errorObj.nameKey}>
                        <span className={CSS.ErrorListDialogErrorName}>
                            {intl.formatMessage({ id: errorObj.nameKey })}
                        </span>
                        <span className={CSS.ErrorListDialogErrorDetails}>
                            {intl.formatMessage({ id: errorObj.detailsKey })}
                        </span>
                    </li>
                ))}
            </ul>
        </DialogModal>
    );
};

export default SubmitErrorDialog;
