import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "../../../theme";

export const useStyles = makeStyles(() => ({
    wrapper:{
        padding:SIZING.scale300,
        background: COLORS.ALERT_HEADER_BG,
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
    },
    titleWrapper:{
        display:'flex',
        alignItems:'center'
    },
    backIcon:{
        height:SIZING.scale800,
        width:SIZING.scale800,
        cursor:'pointer',
        marginRight:SIZING.scale300,
    },
    desc:{
        color: COLORS.GREY,
        fontWeight:400,
        lineHeight:2,
        fontSize: SIZING.scale400,
    },
    exportBtn: {
        flexShrink: 0,
        background: "white",
        color: COLORS.PRIMARY_BTN_TEXT,
        height: SIZING.scale1000,
        "&:hover": {
            backgroundColor: "white",
        },
        marginLeft:SIZING.scale1200
    },
    dFlex:{
        display:'flex',
        alignItems:'center'
    }
}));