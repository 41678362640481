import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING, COLORS } from "theme";

export const useStyles = makeStyles({
    header: {
        paddingTop: SIZING.scale600,
        paddingBottom: SIZING.scale400,
        background: COLORS.DGREY,
        boxShadow: "inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
    },
    flexWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `0 ${SIZING.scale800}`
    },
    close: {
        cursor: 'pointer'
    },
    send: {
        /* Mui-disabled was overriding pointer and click handler important needed to prevent this */
        cursor: 'pointer !important' as any,
        pointerEvents: 'all !important' as any,
        marginLeft: 'auto',
        background: COLORS.LBLUE,
        padding: SIZING.scale600,
        "&:hover": {
            backgroundColor: COLORS.LBLUE,
        },
    },
    headText: {
        fontSize: SIZING.scale450,
        color: COLORS.CONVERSATION_TEXT,
        paddingBottom: SIZING.scale100
    },
    desc: {
        fontSize: SIZING.scale400,
        color: COLORS.GREY,
        paddingBottom: SIZING.scale100,
        fontWeight: 400
    },
    content: {
        minHeight: SIZING.PATIENT_DETAILS_INFO_WIDTH,
        width: SIZING.ENROLL_PATIENT_MODAL_WIDTH,
        overflow: 'auto',
        padding: `${SIZING.scale400} ${SIZING.scale800}`,
        background: COLORS.MODAL_BG,
        "& ::-webkit-scrollbar": {
            width: SIZING.scale50,
            height: SIZING.scale100,
            cursor: "pointer",
        },

        "& ::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0)",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0)"
        },

        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: COLORS.COMPOSE_SCROLL_BAR
        },
    },
    dlgAction: {
        padding: '5px 32px',
        marginTop: SIZING.scale400,
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: COLORS.WHITE,
            backgroundColor: COLORS.PRIMARY_BTN_TEXT,
            borderRadius: BORDER_RADIUS.LARGE,
            margin: "0 4px"
        },
        "& .MuiTabs-indicator": {
            background: "none !important",
        },
        "& .MuiTab-textColorPrimary": {
            color: COLORS.PRIMARY_BTN_TEXT,
            backgroundColor: COLORS.WHITE,
            borderRadius: BORDER_RADIUS.LARGE,
            margin: "0 4px",
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)"
        },
        "& .MuiTab-wrapper": {
            flexDirection: "row"
        }
    },
    ellipseIcon: {
        height: SIZING.scale1200,
        width: SIZING.scale1200,
        background: COLORS.WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: SIZING.scale1600,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)"
    },
    contentText: {
        color: COLORS.MIDDLE_GRAY,
        fontSize: SIZING.scale350,
        paddingBottom: SIZING.scale200,
    },
    inputWrapper: {
        "& .MuiInput-input": {
            color: COLORS.BLACK,
            "&::placeholder": {
                color: COLORS.MULTI_SELECT_BG,
            },
        },
    },
    searchIcon: {
        "& .custom-multiselect-input-wrapper": {
            "& svg path": {
                fill: COLORS.LGRAY
            }
        }
    },
    usersIcon: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        "& svg path": {
            fill: COLORS.LGRAY
        }
    },
    contentTextIcon: {
        color: COLORS.LGRAY,
        fontSize: SIZING.scale550,
        fontWeight: 700
    },
    patientDesc: {
        width: '60%',
        textAlign: 'center',
        marginTop: SIZING.scale600
    },
    contentTextPatient: {
        color: COLORS.LGRAY,
        fontSize: SIZING.scale450,
        fontWeight: 400
    },
    devicesWrapper: {
        display: "flex",
        flexWrap: "wrap"
    },
    deviceBox: {
        width: SIZING.RPM_DEVICE_BOX,
        height: '3.375rem',
        paddingLeft: '.3rem',
        background: COLORS.WHITE,
        margin: SIZING.scale200,
        marginLeft: 0,
        boxShadow: "inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: BORDER_RADIUS.SMALL,
        display: "flex",
        alignItems: "center",
        "& .MuiIconButton-label": {
            color: COLORS.PRIMARY_BTN_TEXT,
        },
        fontSize: SIZING.scale400
    },
    deviceCount: {
        height: SIZING.scale600,
        width: SIZING.scale750,
        color: COLORS.WHITE,
        background: COLORS.PRIMARY_BTN_TEXT,
        borderRadius: SIZING.scale1600,
        fontSize: SIZING.scale400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    tabTitle: {
        minHeight: '1.875rem',
        padding:0,
        position:'relative',
        "& span": {
            fontSize: SIZING.scale400
        },
        "&.Mui-selected":{
            overflow:'visible',
            "&::before":{
                borderStyle:'solid',
                borderWidth:'10px 10px 0',
                borderColor:`${COLORS.PRIMARY_BTN_TEXT} transparent`,
                zIndex:1,
                content:'""',
                position:'absolute',
                top:'100%',
                left:'50%',
                transform:'translateX(-50%)'
            }   
        }
    },
    tabWrapper: {
        minHeight: '2.5rem'
    },
    deviceTab: {
        width: "100%",
        display: "flex",
        alignItems: "center"
    },
    deviceCountLabel: {
        width: "20%",
        display: "flex",
        justifyContent: "end",
        marginRight:'.2rem'
    },
    deviceCountLabelone: {
        width: "80%",
        marginLeft: SIZING.scale1200
    }
});
