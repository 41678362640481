import React from "react";
import { List } from "@mui/material";
import classNames from "classnames";
import SubListSeparator from "./SubListSeparator";
import { isSeparatorItem, SubListProps } from "./types";
import { useStyles } from "./styles";

const SubList = ({ items, open, NavItemComponent }: SubListProps) => {
    const classes = useStyles({ open });
    const rootClasses = classNames(classes.subListRoot, {
        [classes.subListRootIconOnly]: !open,
    });

    const subListItems = Object.entries(items).map(([key, item], index, itemEntries) => {
        return isSeparatorItem(item) ? (
            // Do not show a separator if there is no item before or after
            index > 0 && index < (itemEntries.length-1) ? (
                <SubListSeparator open={open} key={key} />
            ) : null
        ) : (
            <NavItemComponent item={item} key={key} open={open} isSubItem={true} />
        );
    });

    return <List classes={{ root: rootClasses }}>{subListItems}</List>;
};

export default SubList;
