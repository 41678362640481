export const TABS = {
    SUMMARY: "SUMMARY",
    ALERTS: "ALERTS",
    FORMS: "FORMS",
    APPOINTMENTS: "APPOINTMENTS",
    RPM_MONITOR: "MONITOR_DATA"
} as const

export const PATIENT_SEARCH = {
    MESSAGES: "Messaging",
    PEOPLE: "People"
} as const
