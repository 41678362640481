import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F6F6F7',
    color: '#6E6E79',
    borderRadius: '8px',
  },
  listText: {
    color: '#393948',
    fontSize: '16px',
    fontWeight: "bold"
  },
  menuTitle: {
    color: '#6E6E79',
    fontSize: '14px',
    padding: '8px 16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '-0.41px'
  },
  menuContent: {
    backgroundColor: '#F6F6F7',
    color: '#393948',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3)), drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2))',
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      content: '""',
      position: "fixed",
      marginRight: "0.91em",
      top: (props: any) => props.y,
      right: 48,
      width: 24,
      height: 24,
      background: '#F6F6F7',
      boxShadow: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3)), drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2))',
      transform: "translate(-50%, 50%) rotate(45deg)",
      clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },
}));