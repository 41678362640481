import React, { FC, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { useIntl } from 'react-intl';
import { IQuestion, IAnswer } from "admin/views/viewsTypes";
import { TextField, Grid, Box, FormHelperText } from "@mui/material";
import { useStyles } from "./styles";
import { useFormContext } from "react-hook-form";

type Props = {
    question: IQuestion;
    disabled: boolean;
    control: any;
};

const AnswerOptions: FC<Props> = ({ question, disabled, control }) => {
  const classes = useStyles();
  const intl = useIntl();
  const methods = useFormContext();
  const questionOrderIndex: number = question.orderIndex;

  const getQuestionIndexOnForm = () => {
    const allQuestions = methods.getValues("questions");
    let index = -1;
    allQuestions.forEach((question: IQuestion, idx: number) => {
      if (question.orderIndex === questionOrderIndex) 
        index = idx;
    })
    return index;
  };

  const formQuestionIndex: number = getQuestionIndexOnForm();

  const { fields, replace } = useFieldArray({
    control,
    name: `questions.${formQuestionIndex}.answerOption`,
    shouldUnregister: false
  });

  const validateAnswerOption = () => {
    const questionTypeVal = methods.getValues(`questions.${formQuestionIndex}.questionType`);
    let answerOptions: IAnswer[] = methods.getValues(`questions.${formQuestionIndex}.answerOption`);
    const minAnswerOptionsRequired =
        questionTypeVal === "RadioButton" || questionTypeVal === "Dropdown"
            ? 2
            : questionTypeVal === "Checkbox"
            ? 1
            : 0;
    answerOptions = answerOptions.filter((f: IAnswer) => f.answerText.trim() !== '');
    if (answerOptions.length < minAnswerOptionsRequired && minAnswerOptionsRequired > 0) {
      methods.setError(`questions.${formQuestionIndex}.answerOption`, {
        type: "validate",
        message: intl.formatMessage({
          id: "PatientFormConfig.Required.AnswerOption"
        }, {
          minAnswerOptionsRequired: minAnswerOptionsRequired
        })
      });
      return false;
    } else {
      methods.clearErrors(`questions.${formQuestionIndex}.answerOption`);
      return true;
    }
  };

  const createAnswerInputs = (size: number) => {
    if (size === 0) return [];
    const answerInputs: IAnswer[] = [];
    for (let index = 0; index < size; index++) {
      answerInputs.push({
        answerText: "",
        orderIndex: index + 1,
        score: 0
      });
    }
    const currentAnswerOptionValues = methods.getValues(`questions.${formQuestionIndex}.answerOption`);
    if (currentAnswerOptionValues && currentAnswerOptionValues.length > 0) {
      const maxAnswerOptions = (currentAnswerOptionValues.length <= size) ? currentAnswerOptionValues.length : currentAnswerOptionValues.length - size;
      for (let index = 0; index < maxAnswerOptions; index++) {
        const orderIndex = currentAnswerOptionValues[index].orderIndex;
        answerInputs[orderIndex - 1] = currentAnswerOptionValues[index];
      }
    }
    return answerInputs;
  };

  const getNumberOfInputs = (questionType: string) => {
    let numberOfInputs: number = 0;
    if (questionType && (questionType === "Checkbox" || questionType === "RadioButton")) {
      numberOfInputs = 8;
    } else if (questionType && questionType === "Dropdown") {
      numberOfInputs = 12;
    } else {
      numberOfInputs = 0;
    }
    return numberOfInputs;
  }

  const currentQuestion = methods.getValues(`questions.${formQuestionIndex}`)
  const currentQuestionType = currentQuestion?.questionType;

  useEffect(() => {
    const handleAnswerTypeChange = () => {
      replace(createAnswerInputs(getNumberOfInputs(currentQuestionType)));
    };
    handleAnswerTypeChange();
  }, [currentQuestionType]);

  return (
    <div className={classes.root}>
      <Grid container spacing={1} style={{ paddingTop: "1rem", paddingBottom: ".5rem" }}>
        {fields.map((answerOption, index) => {
          return (
            <Grid item xs={3} key={answerOption.id}>
              <Box display="flex" alignItems="center">
                <Box width={45}>
                  <label htmlFor={answerOption.id}>A{index + 1}.</label>
                </Box>
                <Box>
                  <Controller
                    name={`questions.${formQuestionIndex}.answerOption.${index}.answerText`}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        {...methods.register(`questions.${formQuestionIndex}.answerOption.${index}.answerText`)}
                        variant="outlined"
                        id={`${index}.id`}
                        disabled={disabled}
                        placeholder={intl.formatMessage({ id: "PatientFormConfig.Placeholder.AnswerOption" })}
                        onChange={(e) => { onChange(e); validateAnswerOption(); }}
                        error={!!methods.formState.errors?.questions?.[formQuestionIndex]?.answerOption}
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <FormHelperText error={true}>
        {(methods.formState.errors?.questions?.[formQuestionIndex]?.answerOption) ? (methods.formState.errors?.questions?.[formQuestionIndex]?.answerOption.message) : null}
      </FormHelperText>
    </div>
  );
}
export default AnswerOptions;