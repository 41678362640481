import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, IconButton, MenuItem, TextField, Typography, Badge } from "@mui/material";
import { each } from "underscore"
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import LayoutExtr from "admin/components/Layout/LayoutExtr";
import { useStyles } from "./styles";
import { USER_ROLES } from "admin/constant";
import { getCurrentUser, getProfiles, selectProfile } from "store/actions";
import { AppReduxStore } from "store/reducerTypes";
import { useOktaAuth } from "@okta/okta-react";
import Spinner from "common/components/Spinner/Spinner";
import { useHistory } from 'react-router-dom';
import { completeRegistration, completeRegistrationPostLoginMethod, sendEvent, eventRequestProps } from 'admin/store/actions';
import { SESSION_STORAGE_DEFAULT_PROFILE_REDIRECT, SESSION_STORAGE_REGISTRATION_ID } from 'constant';
import LoadingIndicator from 'admin/components/LoadingIndicator/LoadingIndicator';
import moment from 'moment';
import { ReactComponent as AdminAltaisStar } from "icon-library/SVG/Admin-Altais-Star.svg";
import { ReactComponent as OrgAdminBuilding } from "icon-library/SVG/Admin-Building_Wide.svg";
import { ReactComponent as PracticeAdminBuildings } from "icon-library/SVG/Admin-Buildings.svg";
import { ReactComponent as Stethoscope } from "icon-library/SVG/Stethoscope.svg";

import { createSvgIcon, getTitleByUserLevelName } from "utils";
const AdminAltaisStarIcon = createSvgIcon(AdminAltaisStar);
const OrgAdminBuildingIcon = createSvgIcon(OrgAdminBuilding);
const PracticeAdminBuildingsIcon = createSvgIcon(PracticeAdminBuildings);
const DoctorIcon = createSvgIcon(Stethoscope);
type FormattedProfile = {
    id: any,
    type: any,
    title: string,
    icon: JSX.Element,
    practice: any,
    level: any,
    lastLoginDate?: any | null,
    validationRequired?: boolean
    status: string
}

const PRACTICE_ADMIN = "PRACTICE_ADMIN"

const ProfileSelector = () => {
    const { oktaAuth, authState } = useOktaAuth()
    const intl = useIntl()
    const dispatch = useDispatch()
    const classes = useStyles()
    const { profiles, loading } = useSelector((store: AppReduxStore) => store.user)
    const [profile, setProfile] = useState<any>({})
    const [profilePreference, setProfilePreference] = useState<any>("none")
    const history = useHistory();

    const getEventParams = (): eventRequestProps => {
        const claims = authState?.idToken?.claims as any;
        const params: eventRequestProps = {
            argument: claims?.preferred_username,
            contextPath: '/webapp',
            eventType: 'LOGIN',
            eventUrl: authState?.idToken?.authorizeUrl || "",
            profileRoleJoinId: claims?.current_profile || "",
            userId: claims?.sub || ""
        };
        return params;
    };

    useEffect(() => {
        const registrationId = sessionStorage.getItem(SESSION_STORAGE_REGISTRATION_ID)
        if (registrationId && registrationId.length > 0) {
            dispatch(completeRegistration(oktaAuth))
        } else {
            const claims = authState?.idToken?.claims as any
            dispatch(getCurrentUser(claims?.current_profile, true, getEventParams()))
            dispatch(getProfiles())
        }
        try {
            const defaultRedirect = sessionStorage.getItem(SESSION_STORAGE_DEFAULT_PROFILE_REDIRECT)
            if (defaultRedirect && defaultRedirect === "YES") {
                sessionStorage.removeItem(SESSION_STORAGE_DEFAULT_PROFILE_REDIRECT)
                const claims = authState?.idToken?.claims as any
                const p = claims?.enable_profiles.find((p: any) => p.id === profile?.id ? profile?.id : claims?.current_profile)
                if (p) {
                    dispatch(selectProfile(p, null, null))
                }
            }
        } catch (err) { }
    }, [authState?.idToken?.claims, dispatch, oktaAuth])

    const setAdminProfile = (profile: any, icon: any) => {
        const names = []
        profile.organization && names.push(profile.organization.name)
        profile.practice && names.push(profile.practice.name)
        return {
            id: profile.id,
            type: USER_ROLES.ADMIN,
            title: profile.level.description,
            icon: icon,
            practice: names.join(" - "),
            level: profile?.level?.name,
            lastLoginDate: profile?.lastLoginDate,
            validationRequired: profile?.validationRequired,
            status: profile?.status
        }
    }

    const formattedProfiles: FormattedProfile[] = []
    each(profiles, (profile) => {
        let icon = null
        switch (profile.type) {
            case USER_ROLES.ADMIN:
                if (profile.level.name.indexOf("ALTAIS_ADMIN") !== -1) {
                    profile.level.description = getTitleByUserLevelName(profile.level.name)
                    icon = <AdminAltaisStarIcon className={classes.profileLevelIcon} />
                }
                else if (profile.level.name.indexOf("ORG_ADMIN") !== - 1) {
                    profile.level.description = getTitleByUserLevelName(profile.level.name)
                    icon = <OrgAdminBuildingIcon className={classes.profileLevelIcon} />
                }
                formattedProfiles.push(setAdminProfile(profile, icon))
                break
            case USER_ROLES.PROVIDER:
                formattedProfiles.push({
                    id: profile.id,
                    type: USER_ROLES.PROVIDER,
                    title: intl.formatMessage({ id: "ProfileSelector.providerPortalTitle" }),
                    icon: <DoctorIcon className={classes.profileLevelIcon} />,
                    practice: profile.practice.name,
                    level: profile?.level?.name,
                    lastLoginDate: profile?.lastLoginDate,
                    validationRequired: profile?.validationRequired,
                    status: profile?.status
                })

                if (profile.level.name === PRACTICE_ADMIN) {
                    icon = <PracticeAdminBuildingsIcon className={classes.profileLevelIcon} opacity={null} />
                    profile.level.description = getTitleByUserLevelName(profile.level.name)
                    formattedProfiles.push(setAdminProfile(profile, icon))
                }
                break
        }

    })

    const handleProfile = (p: any) => {
        setProfile(p)
    }

    const handleSubmit = async () => {
        if (!profile?.validationRequired && profile?.status === 'PENDING') {
            // history && history.push(`/verify-profile?prjId=${profile.id}&type=${profile?.level === "PRACTICE_ADMIN" ? "PROVIDER" : profile?.type}`)
            dispatch(completeRegistrationPostLoginMethod(oktaAuth, profile.id, profile?.level === "PRACTICE_ADMIN" ? "PROVIDER" : profile?.type))
        }
        else if (profile) {
            let duration = null
            switch (profilePreference) {
                case "day":
                    duration = moment().format('MM/DD/YYYY')
                    break
                case "week":
                    duration = moment().endOf('week').format('MM/DD/YYYY')
                    break
                default:
                    break
            }
            dispatch(selectProfile(profile, history, profile.level === PRACTICE_ADMIN ? null : duration))
            dispatch(sendEvent(getEventParams()));
        }
    }

    if (loading) {
        return (
            <LayoutExtr>
                <Spinner />
            </LayoutExtr>
        )
    }
    const handleProfilePreferenceChange = (event: any) => {
        setProfilePreference(event.target.value)
    }
    return (
        <LayoutExtr heading={intl.formatMessage({ id: "ProfileSelector.heading" })} subHeading={intl.formatMessage({ id: "ProfileSelector.subHeading" })}>
            <FormControl hiddenLabel>
                <label className={classes.labelCtrl}>{intl.formatMessage({ id: "ProfileSelector.Duration.Label" })}</label>
                <TextField
                    variant={"outlined"}
                    select
                    id={`ddlProfilePreference`}
                    name={"ddlProfilePreference"}
                    value={profilePreference}
                    placeholder={""}
                    className={classes.textField}
                    onChange={handleProfilePreferenceChange}
                    style={{ textAlign: "left", width: 300 }}>
                    <MenuItem value="none">{intl.formatMessage({ id: "ProfileSelector.Duration.UntilLoggedOut" })}</MenuItem>
                    <MenuItem value="day">{intl.formatMessage({ id: "ProfileSelector.Duration.Day" })}</MenuItem>
                    <MenuItem value="week">{intl.formatMessage({ id: "ProfileSelector.Duration.Week" })}</MenuItem>
                </TextField>
            </FormControl>
            {formattedProfiles.map((p) => {
                return (
                    <>
                        <Box className={classes.mainWrapper}>
                            {
                                (!p.lastLoginDate && !p.validationRequired)
                                    ? <Box className={classes.newProfileIndicatorWrapper}>
                                        <Badge className={classes.newProfileIndicator} badgeContent=" " />
                                    </Box>
                                    : <Box className={classes.emptyBox}></Box>
                            }
                            <Box id={"profile_" + p.id + "_" + p.type} key={p.title} display="flex" className={classes.listWrapper} style={{ background: p.id === profile.id && p.type === profile.type ? "#FEF7E6" : '#FFFFFF' }} onClick={() => { handleProfile(p) }}>
                                <Box>
                                    <IconButton size="large" className={classes.iconButton}>
                                        {p.icon}
                                    </IconButton>
                                </Box>
                                <Box ml={2}>
                                    <Typography align="left" className={classes.listItemTitle}>
                                        {p.title}
                                    </Typography>
                                    <Typography align="left" className={classes.listItemText}>
                                        {p.practice}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {
                            (p.status === 'PENDING') &&
                            <Box className={classes.profileTextWrapper}>
                                <Typography align="center" className={classes.newProfileText}>
                                    {intl.formatMessage({ id: "ProfileSelector.newProfileDetected" })}
                                </Typography>
                            </Box>
                        }
                    </>
                );
            })}
            <Box>
                <Button id="btnContinue" disabled={!profile.id} variant="contained" color="primary" className={classes.btnContinue} onClick={handleSubmit}>
                    Continue
                </Button>
            </Box>
            <LoadingIndicator />
        </LayoutExtr>
    );
}

export default ProfileSelector