import React, { useState } from "react";
import classNames from "classnames";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { Provider } from "./types";
import { ProviderDetailsPopover } from "./provider-details-popover";
import { ReactComponent as EmptyIcon } from "icon-library/SVG/User_Doctor-Stethoscope-Shoulder.svg";
import { TableListItem, TableListItemProps } from "lib/ui-components/table-list";
import * as TableListCSS from "lib/ui-components/table-list/class-names";

export type ProviderListItemProps = {
    portalEl: HTMLElement | null;
    provider: Provider;
    role?: string;
    showSpecialties?: boolean;
    specialtiesClassname?: string;
} & Omit<TableListItemProps, "id">;

export const ProviderListItem: React.FC<ProviderListItemProps> = ({
    children,
    idPrefix,
    provider,
    role,
    showSpecialties,
    className,
    portalEl,
    ...tableListItemProps
}) => {
    const { id, displayName, specialties, subSpecialties } = provider;
    const [details, setDetails] = useState<{ open: boolean; target?: Element }>({ open: false });
    const onNameClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        setDetails({ open: true, target: event.target as Element });

    return (
        <TableListItem id={id} idPrefix={idPrefix} className={classNames(className)} {...tableListItemProps}>
            <td>
                <div className={TableListCSS.TableListPersonItemAvatarCell}>
                    <div className={TableListCSS.TableListPersonItemAvatar} id={`${idPrefix}_item_avatar_${id}`}>
                        {/* TODO: make this icon customizable */}
                        <EmptyIcon />
                    </div>
                </div>
            </td>
            <td className={TableListCSS.TableListItemTDExpanded}>
                <div
                    className={classNames(TableListCSS.TableListPersonItemNameInfoCell, {
                        [TableListCSS.TableListPersonItemNameInfoCellClickable]: !!onNameClick,
                    })}
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        onNameClick?.(event);
                    }}
                >
                    <span
                        className={TableListCSS.TableListPersonItemName}
                        id={`${idPrefix}_item_name_${id}`}
                        data-testid={`${idPrefix}_item_name_${id}`}
                        title={displayName}
                    >
                        {displayName}
                    </span>
                    {role && (
                        <span
                            className={TableListCSS.TableListPersonItemRole}
                            id={`${idPrefix}_item_role_${id}`}
                            data-testid={`${idPrefix}_item_role_${id}`}
                            title={role}
                        >
                            {role}
                        </span>
                    )}
                    {/* TODO: implement info */}
                </div>
            </td>
            {showSpecialties && (
                <td>
                    <div className={TableListCSS.TableListPersonItemSpecialtiesCell}>
                        <CustomTypography
                            variant="smallBold"
                            component="span"
                            id={`${idPrefix}_item_specialties_${id}`}
                        >
                            {specialties?.join(", ")}
                            {specialties?.length && subSpecialties?.length && "; "}
                        </CustomTypography>
                        <CustomTypography
                            variant="small"
                            component="span"
                            id={`${idPrefix}_item_sub_specialties_${id}`}
                        >
                            {subSpecialties?.join(", ")}
                        </CustomTypography>
                    </div>
                </td>
            )}
            <ProviderDetailsPopover
                provider={provider}
                open={details.open}
                anchorEl={details.target}
                portalEl={portalEl}
                onClose={() => setDetails({ open: false })}
            />
        </TableListItem>
    );
};
