import { useState, useEffect } from "react";
import { useApi, FetchStates } from "common/utils/use-api";
import {
    createEConsultRequestValidationObject
} from "./mappers";
import { isValidRequest } from "./validations";
import {
    EConsultChangeStatusRequest,
    EConsultRequestDetails,
    EConsultRequestStatuses,
    EConsultRequestCreateState,
} from "../types";
import { useUserNotification } from "common/utils/use-user-notification";
import { useBusyState } from "common/utils/progress/progress-manager-hooks";
import { EConsultUpdatingBusyState } from "./constants";

export const useSubmitRequest = (
    state: Omit<EConsultRequestCreateState, "status">,
    onSubmitted: () => void
) => {
    const { enqueueError } = useUserNotification();
    const { busyState: isUpdating } = useBusyState(EConsultUpdatingBusyState);
    const [showErrors, setShowErrors] = useState(false);
    const [delayingSubmit, setDelayingSubmit] = useState(false);

    const stateWithStatus = {
        status: EConsultRequestStatuses.SUBMITTED,
        ...state,
    };

    const validationErrors = isValidRequest(createEConsultRequestValidationObject(stateWithStatus));
    const isUploading = Boolean(stateWithStatus.attachments.find((a) => a.status === "UPLOADING"));

    const {fetchInfo: submitFetchStatus, requestFn: submit} = useApi<
        EConsultChangeStatusRequest | null,
        EConsultRequestDetails,
        EConsultRequestDetails | null
    >(
        {
            id: "submitEConsultRequest",
            abortOnNewRequest: true,
            defaultContent: null,
            endpoint: `/econsult/v1/requests/${state.id}/status`,
            method: "PUT",
            requestDecisionMaker: (params) => Boolean(params),
        },
        null
    );

    const performRequest = async () => {
        try {
            await submit({ version: stateWithStatus.version, status: stateWithStatus.status });
            onSubmitted();
        } catch (err) {
            if ((err as Error).name !== "AbortError") {
                console.error(err);
                enqueueError("EConsult.newRequestModal.submit.error.network");
            }
        }
    };

    const clearDelayedRequest = () => {
        if (delayingSubmit) {
            setDelayingSubmit(false);
        }
    };

    const performRequestOrDelay = () => {
        if (isUploading || isUpdating) {
            setDelayingSubmit(true);
        } else {
            performRequest();
        }
    };

    const validateAndSubmit = () => {
        if (Object.keys(validationErrors).length === 0) {
            performRequestOrDelay();
        } else {
            // if has validation errors and there's an pending request, cancel it
            // it may happen if we are waiting for uploads
            // and one of them fails
            clearDelayedRequest();
        }
    };

    const handleSubmit = () => {
        // start showing errors only after user submits
        setShowErrors(true);
        validateAndSubmit();
    };

    // if we are waiting for upload or update, try to submit as soon as we can
    useEffect(() => {
        if (delayingSubmit && !isUploading && !isUpdating) {
            setDelayingSubmit(false);
            handleSubmit();
        }
    }, [delayingSubmit, isUploading, isUpdating]);

    return {
        validationErrors: showErrors ? validationErrors : {},
        isUploading,
        submit: handleSubmit,
        submitting: delayingSubmit || submitFetchStatus.state === FetchStates.LOADING,
    } as const;
};
