import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
    threadsWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "600px",
        height: "100%",
        background: COLORS.MESSAGE_LIST_BG,
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.05), inset -1px 0px 0px rgba(0, 0, 0, 0.05)",
    },
    innerWrapper: {
        padding: `0 ${SIZING.scale500} 0 ${SIZING.scale800}`,
        height: "100%",
        overflowY: "scroll"
    },
    categoryTitle: {
        color: COLORS.LIST_SUBHEADER_TEXT,
        paddingTop: SIZING.scale500,
        backgroundColor: COLORS.MESSAGE_LIST_BG,
        "& + div": {
            borderTop: "none"
        }
    },
    threads: {
        listStyle: "none",
        paddingLeft: 0
    },
    subtitle: {
        color: COLORS.EMPTY_LIST_TEXT
    },
    subtitleIcon: {
        "& path": {
            fill: COLORS.EMPTY_LIST_TEXT
        }
    }
}));