import React from "react";
import { Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useStyles } from "./styles";
import {createSvgIcon, getAge} from "utils"
import {ReactComponent as CheckboxFilledSvg} from "icon-library/SVG/Checkbox_Square.svg"
import {ReactComponent as CheckboxSvg} from "icon-library/SVG/Checkbox-Light_Square.svg"

const CheckboxFilledIcon = createSvgIcon(CheckboxFilledSvg)
const CheckboxIcon = createSvgIcon(CheckboxSvg)

const MultiSelectItem = ({ item, selected, onChange, highlightText, themeColor }: any) => {
  const { firstName,lastName, userType, genderCode, mrn, dateOfBirth, registeredUserId, id } = item;
  const name = `${firstName} ${lastName}`;
  const key = registeredUserId ? registeredUserId : id;
  const classes = useStyles({checked:selected,themeColor});

  const getHighlightText = (value: string, key: string) => {
    if (!highlightText || !value) return value;

    const matchRegex = new RegExp(highlightText, "ig");
    const res = value
      .replace(matchRegex, function (m: string) {
        return `~${m}~`;
      })
      .split("~");
    if (res.length) {
      return res.map((item: string, index: number) =>
        highlightText.trim().toLowerCase() === item.trim().toLowerCase() ? (
          <span key={key + '-' + index.toString()} className="match">{item}</span>
        ) : (
          item
        )
      );
    }
  };



  return (
    <FormControlLabel
      className={classes.formControl}
      key={key}
      classes={{ label: classes.formControlLabel }}
      control={
        <Checkbox
          checked={selected}
          onChange={onChange}
          name={name}
          icon={<CheckboxIcon className={classes.checkBoxIcon} />}
          checkedIcon={<CheckboxFilledIcon className={classes.checkBoxIconSelected} />}
          color="primary"
          id="QA_check_box"
        />
      }
      label={
        <>
         {/* TODO: need to add patient and colleague details */}
          <Box width="55%">
            <Typography className="name label" variant="body1" noWrap>
              {getHighlightText(name, key)}
            </Typography>
          </Box>
          <Box width="45%" textAlign="right">
            <Typography variant="h5" className="type label" noWrap>
            {/* TODO need to add provider details but not available in API */}
            {userType==='PROVIDER'?'':  `${getAge(dateOfBirth)} ${genderCode ?genderCode :'' }`}
            </Typography>
            <Typography
              variant="h5"
              className="hospitalName label"
              noWrap
            >
            {/* TODO need to add provider details but not available in API */}
            {userType==='PROVIDER'?'': getHighlightText(mrn?mrn:'', key)}
            </Typography>
          </Box>
        </>
      }
    />
  );
};

export default MultiSelectItem;
