import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_help_row: {
        width: '100%',
        minWidth: '1440px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '140%',
        color: '#000000',
        userSelect: 'none',
        marginBottom: '20px'
    },
    pep_help_container: {
        width: '100%',
        maxWidth: '1440px',
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex'
    },
    pep_help_top: {
        width: '100%'
    },
    pep_help_left: {
        marginRight: '10px',
        width: '1065px',
        paddingBottom: '30px'
    },
    pep_help_banner: {
        width: '1065px',
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 32px',
        gap: '4px',
        height: '80px',
        background: 'linear-gradient(90deg, #F6F6F6 66.29%, rgba(246, 246, 246, 0) 100%)',
        borderRadius: '4px'
    },
    pep_help_banner_top: {
        fontFamily: 'BentonSansCond',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '32px',
        lineHeight: '140%',
        color: '#6E1E80'
    },
    pep_help_banner_bottom: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#6E1E80'
    },
    pep_help_description: {
        marginBottom: '20px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#404040'
    },
    pep_help_bottom: {
        marginTop: '20px',
        marginBottom: '20px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#404040'
    },
    pep_help_right: {
        marginLeft: '20px',
        marginRight: '20px',
        width: '100%'
    },
    pep_help_right_additional_resources: {
        fontFamily: 'BentonSansCond',
        marginTop: '20px',
        marginBottom: '10px',
        paddingTop: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px',
        lineHeight: '140%',
        letterSpacing: '0.02em',
        color: '#6E1E80'
    },
    pep_help_right_links: {
        marginTop: '15px',
        marginBottom: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#0084D5'
    },
    pep_help_right_fee_schedules_right_links: {
        marginTop: '15px',
        marginBottom: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#0084D5'
    },
    pep_help_right_icons: {
        verticalAlign: 'middle',
        marginRight: '10px',
        width: '22px',
        height: '22px',
        fill: '#6E1E80'
    }
}));