import {
    TextField, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { ReactComponent as WarningIcon } from "admin/assets/common/ExclamationWarning.svg";
import { AppReduxStore } from "admin/store/reducerTypes";
import { isNaN, map } from "underscore";
import { FormattedMessage, useIntl } from "react-intl";
import React, { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { deletePracticeProductLicense, upsertPracticeProductLicense } from "admin/store/actions/license";
import Controls from "admin/components/Controls/Controls";
import { Permissions } from "types";
import { showNotification } from "admin/store/actions/snackbar";

interface Data {
    id: string
    productId: string
    name: string
    description: string
    practiceId: string
    parentId: any
    licenseCount: number,
    unusedLicenseCount: number
    usedLicenseCount: number,
    availableLicenseCount: number,
    originalTotal: any,
    newTotal: any,
    active: boolean,
    disabledSwitch: boolean,
    lessThanUsedCount: boolean
}

const PracticeLicensesListing = forwardRef((props, ref) => {
    const { auth, license, practice } = useSelector((state: AppReduxStore) => state)
    const { sPracticeLicenses, allProducts, orgLicenses } = license
    const [products, setProducts] = useState([] as any)
    const [isCoreProductOff, setCoreProductOff] = useState(false)
    const classes = useStyles()
    const intl = useIntl()
    const dispatch = useDispatch()
    useEffect(() => {
        if (allProducts || sPracticeLicenses) {
            setProducts(allProducts && map([...allProducts], (product, key) => {
                const p = { ...product };
                const pLicense = sPracticeLicenses && sPracticeLicenses.find((o: any) => o.product.id === p.id);
                p["active"] = (pLicense && (pLicense.licenseCount > 0 || pLicense?.product?.unlimited)) ? true : false
                p["practiceProductId"] = pLicense?.id
                p["productId"] = p?.id
                p["practiceId"] = pLicense ? pLicense.practiceId : practice?.sPractice?.id
                p["licenseCount"] = (p?.unlimited) ? "Unlimited" : (pLicense?.licenseCount)
                p["usedLicenseCount"] = (p?.unlimited) ? "Unlimited" : (pLicense?.usedLicenseCount)
                p["unusedLicenseCount"] = (p?.unlimited) ? "Unlimited" : (isNaN(pLicense?.licenseCount - pLicense?.usedLicenseCount) ? "" :
                    (pLicense?.licenseCount - pLicense?.usedLicenseCount))
                p["availableLicenseCount"] = (p?.unlimited) ? "Unlimited" : (pLicense?.availableLicenseCount)
                p["originalTotal"] = (p?.unlimited) ? "Unlimited" : (pLicense?.licenseCount)
                p["newTotal"] = (p?.unlimited) ? "Unlimited" : (pLicense?.licenseCount)
                p["lessThanUsedCount"] = false
                return p
            }))
            // checkBillingSchedule();
        }
    }, [allProducts, practice?.sPractice?.id, sPracticeLicenses, orgLicenses])

    useImperativeHandle(
        ref,
        () => ({
            saveProducts(e: any) {
                return updateProducts();
            }
        }),
    );
    const onNewTotalChange = (newTotal: number, row: any) => {
        const productsCopy = [...products]
        for (let i = 0; i < productsCopy.length; i++) {
            if (productsCopy[i].id === row.productId) {
                productsCopy[i]["newTotal"] = newTotal
                productsCopy[i]["lessThanUsedCount"] = false
                if (row.usedLicenseCount && row.usedLicenseCount > 0 && parseInt(newTotal.toString()) < row.usedLicenseCount) {
                    productsCopy[i]["lessThanUsedCount"] = true
                }
                productsCopy[i]["active"] = parseInt(String(newTotal)) > 0
            }
        }
        setProducts(productsCopy)
        validateProducts(productsCopy, false)
    }
    const handleToggleSwitchChange = (checked: boolean, row: any) => {
        const productsCopy = [...products]
        for (let i = 0; i < productsCopy.length; i++) {
            if (productsCopy[i].productId === row.productId) {
                productsCopy[i]["active"] = checked
                break
            }
        }
        setProducts(productsCopy)
        validateProducts(productsCopy, false)
    }
    const updateProducts = () => {
        if (!validateProducts(products, true)) {
            return { success: false, coreLicenseOn: null }
        }
        let coreLicenseOn = null
        const practiceLicenses = []
        const deletePracticeLicenses = []
        for (let i = 0; i < products.length; i++) {
            if (products[i]["active"]) {
                if (products[i]["code"] === "CORE" && Number(products[i].newTotal) > 0) {
                    if (!products[i].licenseCount || Number(products[i].licenseCount) === 0) {
                        coreLicenseOn = true
                    }
                }
                practiceLicenses.push({ newTotalLicenseCount: products[i].newTotal === "Unlimited" ? null : products[i].newTotal, practiceId: products[i].practiceId, practiceProductId: products[i].practiceProductId, productId: products[i].productId })
            } else {
                if (products[i].practiceProductId) {
                    if (products[i]["code"] === "CORE") {
                        coreLicenseOn = false
                    }
                    deletePracticeLicenses.push(products[i].practiceProductId)
                }
            }
        }
        if (deletePracticeLicenses.length > 0) {
            dispatch(deletePracticeProductLicense(deletePracticeLicenses))
        }
        if (practiceLicenses.length > 0) {
            dispatch(upsertPracticeProductLicense(practiceLicenses))
        }
        // updateBillingGroups();
        return { success: true, coreLicenseOn: coreLicenseOn }
    }
    const validateProducts = (updatedProducts: Data[], isSaveAction: boolean): boolean => {
        let validProducts = true
        const coreProduct = updatedProducts && updatedProducts.find((p: any) => p.code === "CORE") as Data
        const digitalFrontDoorProduct = updatedProducts && updatedProducts.find((p: any) => p.code === "FRONT_DOOR") as Data
        const eTranscriptionProduct = updatedProducts && updatedProducts.find((p: any) => p.code === Permissions.ETRANSCRIPT) as Data
        const careTeamProduct = updatedProducts && updatedProducts.find((p: any) => p.code === "CARE_TEAM") as Data

        setCoreProductOff(!(coreProduct.active && parseInt(coreProduct.newTotal) > 0))

        // KEPLER-954 License Rules for Org/Practice
        // If Core Platform if not assigned, then Digital Front Door, Care Team Communications, and eTranscription are Disabled
        if (coreProduct && !coreProduct.active) {
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.active = false
                digitalFrontDoorProduct.disabledSwitch = true
            }
            if (careTeamProduct) {
                careTeamProduct.active = false
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.active = false
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // When Core Platform is assigned, then Digital Front Door is also enabled and assigned
        if (coreProduct && coreProduct.active) {
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.active = true
                digitalFrontDoorProduct.disabledSwitch = false
            }

        }
        // When Core Platform is assigned, then Care Team Communications and eTranscription become enabled, but NOT assigned
        if (coreProduct && coreProduct.active) {
            if (careTeamProduct) {
                careTeamProduct.disabledSwitch = false
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.disabledSwitch = false
            }

        }
        // If either Core Platform OR Digital Front Door are unassigned, 
        // then Care Team Communications and eTranscriptions become unassigned, 0 licenses, and disabled
        if ((coreProduct && !coreProduct.active) || (digitalFrontDoorProduct && !digitalFrontDoorProduct.active)) {
            if (careTeamProduct) {
                careTeamProduct["active"] = false
                careTeamProduct["newTotal"] = 0
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct["active"] = false
                eTranscriptionProduct["newTotal"] = 0
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // If Core Platform is enabled, it must have a license count of greater than 0
        if (isSaveAction && coreProduct && coreProduct.active && coreProduct.newTotal !== "Unlimited" && parseInt(coreProduct.newTotal ? coreProduct.newTotal.toString() : "0") === 0) {
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "OrgProductsEdit.CoreProductCountGreaterThanZero" })));
            validProducts = false
        }
        setProducts(updatedProducts)
        return validProducts
    }
    const isCurrentProfilePractice = auth.currentProfileRoleJoinInfo?.practice?.id === practice?.sPractice?.id

/*
    type BillingGroupStateType = {
        [key: string]: boolean
    }

    const [selectedBillingGroups, setSelectedBillingGroups] = useState<BillingGroupStateType>({});
    const hasBillingSchedule = products.some((product: any) => (product.code === 'BILLING_SCHEDULE'));
    const checkBillingSchedule = (): void => {
        const orgId = auth.currentProfileRoleJoinInfo?.organization?.id;
        const practiceId = practice?.sPractice?.id || auth.currentProfileRoleJoinInfo?.practice?.id;
        if (orgId && hasBillingSchedule) {
            dispatch(getAvailableFeeScheduleGroups(orgId, auth.currentProfileRoleJoinInfo?.id));
            dispatch(getFeeScheduleGroups(practiceId, auth.currentProfileRoleJoinInfo?.id));
        }
    };
 
    const handleBillingGroupToggleSwitchChange = (checked: boolean, row: any) => {
        const newBillingGroups = Object.assign({}, selectedBillingGroups);
        newBillingGroups[row.id] = checked;
        setSelectedBillingGroups(newBillingGroups);
    }

    const updateBillingGroups = (): void => {
        if (hasBillingSchedule) {
            const addBillingGroupIds = Object.entries(selectedBillingGroups).filter((selectedBillingGroup) => {
                return (selectedBillingGroup[1] === true) &&
                    !(feeScheduleGroups.some((feeScheduleGroup: any) => feeScheduleGroup?.billingGroupInfo?.id === selectedBillingGroup[0]))
            }).map((selectedBillingGroup) => { return selectedBillingGroup[0] });
            const removeBillingGroupIds = feeScheduleGroups.filter((feeScheduleGroup: any) => {
                return !(selectedBillingGroups[feeScheduleGroup?.billingGroupInfo?.id])
            }).map((feeScheduleGroup: any) => { return feeScheduleGroup.id });
            const profileRoleJoinId = auth.currentProfileRoleJoinInfo?.id;
            if (addBillingGroupIds.length > 0) {
                dispatch(setFeeScheduleGroups(addBillingGroupIds, profileRoleJoinId));
            }
            if (removeBillingGroupIds.length > 0) {
                dispatch(deleteFeeScheduleGroups(removeBillingGroupIds));
            }
        }
    }

    useEffect(() => {
        if (feeScheduleGroups && feeScheduleGroups.length > 0) {
            const newBillingGroups: any = Object.assign({}, selectedBillingGroups);
            feeScheduleGroups.forEach((feeScheduleGroup: any) => {
                newBillingGroups[feeScheduleGroup?.billingGroupInfo?.id] = true;
            });
            setSelectedBillingGroups(newBillingGroups);
        }
    }, [feeScheduleGroups]);
*/
    return (<>
        {products?.length > 0 && <Box>
            {isCoreProductOff && <Box style={{ textAlign: 'center', marginTop: '17px' }}>
                <Typography variant="body2" className={classes.coreLicenseError}>
                    <FormattedMessage id={"PracticeLicensesListing.Core.Off.Error"} />
                </Typography>
            </Box>}
            <Paper className={classes.paper}>
                <TableContainer style={{ marginTop: '32px' }}>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell key={"active"} align={'left'} padding="normal">
                                    {'Add'}
                                </TableCell>
                                <TableCell key={"name"} align={'left'} padding="normal">
                                    {'Products'}
                                </TableCell>
                                <TableCell
                                    key={"availableLicenseCount"}
                                    align={'left'}
                                    padding="normal">
                                    {'Available to Add'}
                                </TableCell>
                                <TableCell
                                    key={"unusedLicenseCount"}
                                    align={'left'}
                                    padding="normal">
                                    {'Unused Licenses'}
                                </TableCell>
                                <TableCell
                                    key={"usedLicenseCount"}
                                    align={'left'}
                                    padding="normal">
                                    {'Used Licenses'}
                                </TableCell>
                                <TableCell
                                    key={"totalLicences"}
                                    align={'left'}
                                    padding="normal">
                                    {'Total Licenses'}
                                </TableCell>
                                <TableCell
                                    key={"newTotal"}
                                    style={{ textAlign: "right" }}
                                    padding="normal">
                                    {'New Total'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products && products.map((row: Data, index: number) => {
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell style={{ paddingLeft: (row.parentId ? "60px" : "17px") }}>
                                            <Box>
                                                <Controls.ToggleSwitch
                                                    label={""}
                                                    checked={row.active}
                                                    onChange={(e: any) => { handleToggleSwitchChange(e.target.checked, row) }}
                                                    value={""}
                                                    disabled={row.disabledSwitch || isCurrentProfilePractice}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.name}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.availableLicenseCount}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box flexDirection="row">
                                                {(row.unusedLicenseCount) === 0 && <WarningIcon style={{ top: '5px', position: 'relative' }} />}
                                                {row.unusedLicenseCount}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.usedLicenseCount}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.licenseCount}</Box>
                                        </TableCell>
                                        <TableCell style={{ textAlign: "right" }}>
                                            <Box>
                                                {parseInt(row?.newTotal) > 0 && ((row.licenseCount - parseInt(row?.newTotal)) > row.unusedLicenseCount) &&
                                                    <Tooltip arrow title={intl.formatMessage({ id: "PracticeLicensesListing.Tooltip.RemoveLicenses" })}>
                                                        {<WarningIcon style={{ marginTop: '5px' }} />}
                                                    </Tooltip>
                                                }
                                                {parseInt(row?.newTotal) > 0 && ((parseInt(row?.newTotal) - row.licenseCount) > row.availableLicenseCount) &&
                                                    <Tooltip arrow title={intl.formatMessage({ id: "PracticeLicensesListing.Tooltip.NotEnoughLicenses" })}>
                                                        {<WarningIcon style={{ marginTop: '5px' }} />}
                                                    </Tooltip>
                                                }
                                                {row.lessThanUsedCount && row.lessThanUsedCount &&
                                                    <Tooltip arrow title={intl.formatMessage({ id: "PracticeLicensesListing.Tooltip.LessLicenseCount" })}>
                                                        {<WarningIcon style={{ marginTop: '5px' }} />}
                                                    </Tooltip>
                                                }

                                                {(row?.newTotal === "Unlimited") ?
                                                    <TextField style={{ width: "100px" }}
                                                        variant="outlined"
                                                        name="productCount"
                                                        value={row?.newTotal}
                                                        type="text"
                                                        disabled={isCurrentProfilePractice}
                                                    /> :
                                                    <TextField style={{ width: "80px" }}
                                                        variant="outlined"
                                                        name="productCount"
                                                        onChange={(e: any) => { onNewTotalChange(e.target.value, row) }}
                                                        value={row?.newTotal}
                                                        type="number"
                                                        disabled={isCurrentProfilePractice}
                                                        inputProps={{
                                                            autoComplete: "",
                                                            min: 0
                                                        }}
                                                    />}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* hasBillingSchedule && availableFeeScheduleGroups && availableFeeScheduleGroups.map((row: Data, index: number) => {
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell style={{ paddingLeft: "60px" }}>
                                            <Box>
                                                <Controls.ToggleSwitch
                                                    label={""}
                                                    checked={selectedBillingGroups[row.id]}
                                                    onChange={(e: any) => { handleBillingGroupToggleSwitchChange(e.target.checked, row) }}
                                                    value={""}
                                                    disabled={row.disabledSwitch || isCurrentProfilePractice}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.name}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {`Unlimited`}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {`Unlimited`}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {`Unlimited`}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {`Unlimited`}</Box>
                                        </TableCell>
                                        <TableCell style={{ textAlign: "right" }}>
                                            <Box>
                                                <TextField style={{ width: "100px" }}
                                                    variant="outlined"
                                                    name="productCount"
                                                    value={`Unlimited`}
                                                    type="text"
                                                    disabled={isCurrentProfilePractice}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>}
    </>
    )
})
export default PracticeLicensesListing