import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        resultsWrapper: {
            display: "flex",
            flexDirection: "column",
            width: "20rem",
            minWidth: "20rem",
            height: "100%",
            background: COLORS.MESSAGE_LIST_BG,
            boxShadow: "1px 0 8px rgba(0, 0, 0, 0.05), inset -1px 0 0 rgba(0, 0, 0, 0.05)"
        },
        searchHeader: {
            width: "100%",
            padding: SIZING.scale400,
            paddingBottom: SIZING.scale1200, // a temporary solution
            background: COLORS.SIDE_NAV_HEADER_BG,
            boxShadow: "inset -1px 0 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.1)"
        },
        result: {
            cursor: "pointer",
            display: "flex",
            color: "inherit !important",
            justifyContent: "space-between",
            padding: `${SIZING.scale300} ${SIZING.scale400} ${SIZING.scale400} ${SIZING.scale300}`,
            borderRadius: BORDER_RADIUS.MEDIUM,
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
        },
        selectedResult: {
            color: "#FFF !important",
            background: COLORS.DEFAULT_BTN,
            boxShadow: "0 1px 4px rgba(0, 0, 0, 0.05)"
        },
        noPatientSelected: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
                fill: COLORS.COMPOSE_SCROLL_BAR,
                display: "none"
            }
        },
        nameContainer: {
            display: "flex",
            "& :first-child": {
                textTransform: "uppercase",
                marginRight: SIZING.scale100
            },
            "& .m-0":{
                margin:'0 !important'
            }
        },
        personIcon: {
            marginRight: SIZING.scale300,
            "& svg": {
                fill: COLORS.MULTI_SELECT_BG
            }
        },
        selectedPersonIcon: {
            "& svg": {
                fill: COLORS.SELECTED_PERSON_ICON
            }
        },
        alertsIcon: {
            color: COLORS.ALERTS_ICON
        },
        iconHidden: {
            display: "none"
        },
        iconWrapper:{
            display:'flex',
            "& svg:first-child":{
                marginLeft:'auto'
            }
        },
        patientSearch : {
            padding: SIZING.scale400,
            overflow:"auto"
        }
}));