import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING, COLORS } from "admin/theme";

export const useStyles = makeStyles(() => ({
        validationWrapper: {
            marginTop: SIZING.scale400,
            padding: SIZING.scale600,
            borderRadius: BORDER_RADIUS.MEDLARGE,
            background: COLORS.PW_VALIDATION_BG
        },
        header: {
            color: COLORS.PW_VALIDATION_TEXT,
            align: "left"
        },
        validationRow: {
            display: "flex",
            alignItems: "center",
            marginBottom: SIZING.scale300
        },
        icon: {
            height: SIZING.scale1200,
            width: SIZING.scale1200,
            marginRight: SIZING.scale300
        }
}));