import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
        registrationWrapper: {
            marginRight: "1rem",
            "& > form button[type=submit]": {
                marginTop: '2em'
            }
        },
        modal: {
            minWidth: "100%"
        }
}));