import React, { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useUserNotification } from "common/utils/use-user-notification";
import { FilteredSearchInput, Filter } from "lib/ui-components/filtered-search-input";
import { useProviderFilterAutocomplete, useFilterTypes } from "./provider-search-hooks";
import { knownFilterNames } from "./types";
import { ProviderSearchOptions } from "../types";

const MIN_SEARCH_LENGTH = 2;

export type ProviderSearchFiltersProps = {
    error?: boolean;
    /** Change happened in the collection of filters */
    onFiltersChanged: (filters: Filter[]) => void;
    placeholder?: string;
    options?: ProviderSearchOptions;
};

export const ProviderSearchFilters = ({
    error,
    onFiltersChanged,
    placeholder,
    options,
}: ProviderSearchFiltersProps) => {
    const [currentFilter, setCurrentFilter] = useState<Filter>();
    const { enqueueError } = useUserNotification();
    const intl = useIntl();

    const filterTypeTranslator = useCallback(
        (filterType: string) =>
            intl.formatMessage({ id: `ProviderSearch.filterType.${filterType}`, defaultMessage: filterType }),
        [intl]
    );

    const { filterTypes, loadingFilterTypes, error: filterTypesError } = useFilterTypes();
    const { autocompleteValues, loadingAutocomplete } = useProviderFilterAutocomplete({
        filter: currentFilter,
        minSearchLength: MIN_SEARCH_LENGTH,
        options,
    });

    useEffect(() => {
        if (filterTypesError) {
            enqueueError("ProviderSearch.filters.errorMessage");
        }
    }, [filterTypesError]);

    return (
        <FilteredSearchInput
            autocompleteValues={autocompleteValues}
            defaultFilterType={knownFilterNames.SPECIALTY}
            error={error}
            filterTypes={filterTypes}
            getFilterTypeTranslation={filterTypeTranslator}
            loadingAutocomplete={loadingAutocomplete}
            loadingFilterTypes={loadingFilterTypes}
            onFilterChanged={setCurrentFilter}
            onFiltersChanged={onFiltersChanged}
            placeholder={placeholder}
            minSearchLength={MIN_SEARCH_LENGTH}
        />
    );
};
