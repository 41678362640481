import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { CONVERSATION_TYPE } from "constant";
import { ConversationInfoItem } from "store/chat-types";
import { useHistory } from "react-router-dom";
import { createSvgIcon } from "utils";
import { ReactComponent as Circle_Full } from "icon-library/SVG/Circle_Full.svg";
import { DecorationComponentProps } from "../types";

const NotificationIcon = createSvgIcon(Circle_Full);

const MessagesDecoration = ({ color, classes, open }: DecorationComponentProps) => {
    const isComposeMessagePageVisitedRef = useRef(false);
    const [shouldShowNotification, setShouldShowNotification] = useState(false);

    const { conversationsInfo } = useSelector((store: AppReduxStore) => store.chat);
    const history = useHistory();
    const {
        location: { pathname },
    } = history;

    const isConversationVisibleInMessages = (conversationsInfo: ConversationInfoItem): boolean => {
        return (
            conversationsInfo.conversationType === CONVERSATION_TYPE.CARETEAM ||
            conversationsInfo.conversationType === CONVERSATION_TYPE.EVISIT ||
            conversationsInfo.conversationType === CONVERSATION_TYPE.PATIENT
        );
    };

    const count = useMemo(
        () =>
            Object.values(conversationsInfo).reduce(
                (count, info) => (info && isConversationVisibleInMessages(info) && info.unreadCount ? 1 + count : count),
                0
            ),
        [conversationsInfo]
    );

    const checkNotificationVisibility = useCallback(() => {
        if (!count) {
            return false;
        }
        if (pathname === "/provider/messages/inbox" || isComposeMessagePageVisitedRef.current) {
            isComposeMessagePageVisitedRef.current = true;
            return false;
        }
        return true;
    }, [count, pathname]);

    useEffect(() => {
        setShouldShowNotification(checkNotificationVisibility());
    }, [checkNotificationVisibility]);

    return (
        <Box display="flex" alignItems="center">
            {count && open ? (
                <Typography variant="h6" style={{ color }} id="QA_messageCount">
                    {count}
                </Typography>
            ) : (
                ""
            )}
            {shouldShowNotification && <NotificationIcon className={classes.notifIcon} id="QA_newMessageAlertIcon"/>}
        </Box>
    );
};

export default MessagesDecoration;
