import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
    flexWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    modal: {
        width: "464px",
        minHeight: "200px",
        background: "#F6F6F7",
        borderRadius: BORDER_RADIUS.XL
    },
    participantRow: {
        display: "flex",
        alignItems: "center",
        padding: SIZING.scale300,
        marginBottom: SIZING.scale200,
        background: "#FFFFFF",
        borderRadius: BORDER_RADIUS.SMALL,
        cursor: "pointer"
    },
    userIcon: {
        height: SIZING.scale800,
        width: SIZING.scale800,
        marginRight: SIZING.scale400
    },
    close: {
        cursor: 'pointer'
    },
}));