import React from "react";
import { Paper, Box, Typography, IconButton, Button } from "@mui/material";
import { useIntl, FormattedMessage} from "react-intl";

import CancelIcon from "@mui/icons-material/Cancel"
import { useStyles } from "./styles";

const PopoverContent = ({ onSend, close }: any) => {
  const intl=useIntl();
  const classes = useStyles();

  return (
    <Paper className={classes.root} classes={{ root: classes.paper }}>
      <Box display="flex" flexDirection="column" p={1}>
        <Typography className="title" variant="h4" component="div">
          {intl.formatMessage({ id: "PopoverContent.title"})}
        </Typography>
        <Typography className="content" component="div">
        {intl.formatMessage({ id: "PopoverContent.content" })}
        </Typography>
        <div className="send-container">
        <Button id="QA_send_instruction" variant="contained" color="primary" type="submit" className={classes.btnSendInstruction} onClick={()=>{onSend(); close(); }}>
            <FormattedMessage id={"PopoverContent.sendInstruction"} />
        </Button>
        </div>
      </Box>
      <IconButton id="QA_cancel_button" className="closeIcon" onClick={close} size="large">
        <CancelIcon style={{ color: "#E32B29" }} />
      </IconButton>
    </Paper>
  );
}

export default PopoverContent