import { Box, Button, FormControl, Typography } from "@mui/material";
import FormField from "common/components/FormField/FormField";
import { useOktaAuth } from '@okta/okta-react';
import { useSnackbar } from "notistack";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "admin/store/actions/loading";
import SnackbarContent from "admin/components/SnackBars/SnackbarContent";
import { Form, useForm } from 'admin/components/useForm';
import { useStyles } from "./styles";
import { AppReduxStore } from "admin/store/reducerTypes";
import { EMAIL_PATTERN, SESSION_STORAGE_REGISTRATION_ID } from "constant";
import Controls from "admin/components/Controls/Controls";
import { createGUIDString } from "admin/common/utils";
import { signInApp } from "authentication/appAuth";

// @ts-ignore
const { ENABLE_API_URL } = window.env

const initialFValues = {
    email: "",
    password: "",
    secretAnswer: ""
}
const Login = forwardRef(({ headingId = "Login.heading", subheadingId = "Login.subHeading", resetViewHandler = '', resetPasswordView = false, secretQuestionEnabled = false, setSecretQuestion }: any, ref) => {
    const { oktaAuth } = useOktaAuth();
    const dispatch = useDispatch()
    const intl = useIntl()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { email: savedEmail, regToken } = useSelector((store: AppReduxStore) => store.registration)
    const [resetPasswordValidation, setresetPasswordValidation] = useState(true)
    const [loader, updateLoader] = useState(false)
    const [recoveryQuestion, setRecoveryQuestion] = useState("")
    const [accountLocked, setAccountLocked] = useState(false)
    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e)
            }
        })
    )

    const validate = (fieldValues = values) => {
        const err: any = { ...errors };
        if ('email' in fieldValues) {
            err.email = fieldValues.email.trim() ? "" : "This is a required field"
            if (fieldValues.email.trim() !== "" && !(EMAIL_PATTERN).test(fieldValues.email?.trim())) {
                err.email = "Email is not valid."
            }
        }
        if ('password' in fieldValues)
            err.password = fieldValues.password?.trim() ? "" : "This is a required field"
        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }
    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm({
        ...initialFValues,
        email: savedEmail
    }, false, validate)

    const onChange = (key: string, val: string) => {
        handleInputChange({ target: { name: key, value: val.trim() } })
    }


    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (validate()) {
            setErrors({ password: "" })
            const guid = createGUIDString()
            dispatch(showLoading(guid, "Login.Auth.LoadingTitle", "Login.Auth.LoadingDescription"))
            if (regToken && regToken.length > 0) {
                //await dispatch(associateAccount(values.email?.trim(), values.password.trim()))
            }
            else {
                sessionStorage.removeItem(SESSION_STORAGE_REGISTRATION_ID)
            }
            await signInApp({ username: values.email?.trim(), password: values.password?.trim() })
                .then((res: any) => {
                    if (res?.status === "LOCKED_OUT") {
                        setAccountLocked(true)
                        setErrors({ password: intl.formatMessage({ id: "Login.AccountLocked" }) })
                        dispatch(hideLoading(guid))
                    }
                    else {
                        const sessionToken = res?.sessionToken
                        setAccountLocked(false)
                        resetForm()
                        oktaAuth.setOriginalUri(window.location.origin + '/sprofile')
                        oktaAuth.token.getWithRedirect({
                            sessionToken: sessionToken,
                            responseType: 'token'
                        });
                    }
                })
                .catch((err: any) => {
                    console.log('Found an error', JSON.stringify(err));
                    setAccountLocked(false)
                    dispatch(hideLoading(guid))
                    notifySnackMessage("alert", 'error', err?.errorSummary, "")
                    return false
                })
            return true
        }
        return false
    }

    useEffect(() => {
        const listener = (e: any) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                handleSubmit(e)
            }
        };
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    })

    const handleAccountUnlock = (event: any) => {
        const apiUrl = `/api/admin/v1/user/recoveryQuestion?email=${encodeURIComponent(values.email.trim())}`;
        const axios = require('axios');
        axios.get(ENABLE_API_URL + apiUrl, {})
            .then((response: any) => {
                if (response?.data) {
                    setRecoveryQuestion(response.data)
                    setSecretQuestion(true)
                }
                else {
                    notifySnackMessage("alert", 'error', intl.formatMessage({ id: "Login.lnkUnlockAccountError" }), intl.formatMessage({ id: "UnlockAccount.NoSecurityQuestion" }))
                }
            })
            .catch((error: any) => {
                notifySnackMessage("alert", 'error', intl.formatMessage({ id: "Login.lnkRecoveryQuestionError" }), error?.response?.data?.message)
            });
    }

    const handleAccountUnlockSelfServive = (event: any) => {
        const apiUrl = `/api/admin/v1/user/unlockAccountWithAnswer`;
        const secretAnswer = btoa(values.secretAnswer.trim());
        const axios = require('axios');
        axios.post(ENABLE_API_URL + apiUrl, {
            platform: "WEB",
            secretAnswer: secretAnswer,
            username: values.email.trim()
        }).then((response: any) => {
            setSecretQuestion(false)
            setAccountLocked(false)
            setErrors({ password: "" })
            notifySnackMessage("alert", 'success', response.data, "")
        }).catch((error: any) => {
            notifySnackMessage("alert", 'error', intl.formatMessage({ id: "Login.lnkUnlockAccountError" }), error?.response?.data?.message)
        });
    }

    type alertType = 'success' | 'error' | 'warning'
    const notifySnackMessage = (role: string, type: alertType, message: string, desc: string) => {
        enqueueSnackbar(null, {
            preventDuplicate: true, content: (key) => (<div role={role ?? "alert"}><SnackbarContent snackbarKey={key} type={type}
                message={message} desc={desc} /></div>
            ), autoHideDuration: (type === 'error') ? null : 10000
        })
    }

    const handleResetPassword = (event: any, secretQuestionAPI: boolean = false) => {
        event.preventDefault()

        const validEmail = values.email.trim() !== "" && (EMAIL_PATTERN).test(values.email?.trim())
        if (validEmail && !loader) {
            setresetPasswordValidation(true)
            updateLoader(true)
            let apiUrl = ""
            let data = {}
            const username = values.email.trim();
            const secretAnswer = btoa(values.secretAnswer.trim());

            if (secretQuestionAPI === true) {
                apiUrl = `/api/admin/v1/user/verify-secret-answer`
                data = { platform: "WEB", username, secretAnswer }
            } else {
                apiUrl = `/api/admin/v1/user/forgotPassword`
                data = { platform: "WEB", username }
            }

            const axios = require('axios');
            axios.post(ENABLE_API_URL + apiUrl, data)
                .then((response: { status: any, data?: any, message?: any }) => {
                    if (response.status === 204) {
                        if (resetViewHandler) {
                            resetViewHandler(true)
                        } else {
                            enqueueSnackbar(null, {
                                preventDuplicate: true, content: (key) => (<div role="alert"><SnackbarContent snackbarKey={key} type={'success'}
                                    message={intl.formatMessage({ id: "Login.lnkResetPasswordSuccess" })} desc={response.data} /></div>
                                )
                            })
                        }
                    } else if (response.status === 200) {
                        if (secretQuestionAPI === true) {
                            if (resetViewHandler) {
                                resetViewHandler(true)
                            }
                        } else {
                            setRecoveryQuestion(response.data)
                            setSecretQuestion(true)
                        }
                    }
                    updateLoader(false)
                })
                .catch((error: any) => {
                    updateLoader(false)
                    enqueueSnackbar(null, {
                        preventDuplicate: true,
                        content: (key) => (
                            <div role="alert">
                                <SnackbarContent
                                    snackbarKey={key}
                                    type={'error'}
                                    message={intl.formatMessage({ id: "Login.lnkResetPasswordError" })}
                                    desc={(error?.response?.data?.status === 404) ? intl.formatMessage({ id: "Login.AccountInformationNotFound" }) : error?.response?.data?.message}
                                />
                            </div>
                        ),
                        autoHideDuration: null
                    });
                });
            updateLoader(false)
        } else {
            setresetPasswordValidation(false)
            setSecretQuestion(false)
        }
    }

    //  if reset password API success we need to switch view
    if (resetPasswordView) {
        return null
    }

    return (
        <Form onSubmit={handleSubmit}>
            {(secretQuestionEnabled) ?
                <>
                    <Typography variant="h6" className={classes.secretQuestionHeading}>
                        <FormattedMessage id={"ForgotPassword.SecurityQuestion"} />
                    </Typography>
                    <Typography variant="h6" className={classes.secretQuestion}>
                        {recoveryQuestion}
                    </Typography><br />
                    <FormControl>
                        <Controls.Input
                            name="secretAnswer"
                            label="Answer"
                            placeholder="Enter Here"
                            value={values.secretAnswer}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    {accountLocked ? <Button id="btnUnlockOktaAccount" disabled={loader} type="submit" variant="outlined" color="primary" className={classes.btnLogin} onClick={handleAccountUnlockSelfServive}>
                        <FormattedMessage id={"Login.RequestAccountUnlock"} /></Button> :
                        <Button id="btnSecretReset" disabled={loader} type="submit" variant="outlined" color="primary" className={classes.btnLogin} onClick={(event) => handleResetPassword(event, true)}>
                            <FormattedMessage id={"Login.RequestReset"} />
                        </Button>}
                </>
                :
                <>
                    <FormField labelId="Login.lblEmail" errorId={(errors?.email ? errors?.email : (!resetPasswordValidation ? "Email is not valid" : ''))} disabled={savedEmail !== ""} value={values.email} onChange={(val: any) => onChange('email', val.trim())} type="text" />
                    <FormField labelId="Login.lblPassword" errorId={errors?.password || ''} autoFocus={savedEmail !== ""} altLabel={accountLocked ? "Login.lnkUnlockAccount" : "Login.lnkResetPassword"} altFn={accountLocked ? handleAccountUnlock : handleResetPassword} value={values.password} onChange={(val: any) => onChange('password', val.trim())} type="password" />
                    <Box className={classes.loginBtnWrapper}>
                        <Button id="btnLogin" disabled={loader} type="submit" variant="contained" color="primary" className={classes.btnLogin} onClick={handleSubmit}>
                            <FormattedMessage id={"Login.btnLogin"} />
                        </Button>
                    </Box>
                    <br />
                    <Typography variant="h6" className={classes.loginInformationText}>
                        <FormattedMessage id={"Login.lbl.LoginInformation"} />
                        <br />
                        Brown & Toland Service Desk at 
                        <br />
                        <a href="mailto:support@btmg.com?subject=Provider%20Report%20Login/Access%20Request" target="_top">support@btmg.com.</a>
                    </Typography>
                    <br />
                </>
            }
        </Form>
    )
})

export default Login