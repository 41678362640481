import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";
import * as CSS from "./class-names";

export const useStyles = makeStyles({
    [CSS.ModalHeader]: {
        padding: SIZING.scale600,
        display: "flex",
        flexDirection: "column",
        background: COLORS.MODAL_HEADER_BG,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
        [`& .${CSS.ModalHeaderTitleContainer}`]: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
        },
        [`& .${CSS.ModalHeaderTabsContainer}`]: {
            marginTop: SIZING.scale300,
        },
    },
});
