import { makeStyles } from "@mui/styles";
import { SIZING,COLORS } from "theme"

export const useStyles = makeStyles(() => ({
  paper: {
    //border: "1px solid #d3d4d5",
    borderRadius: SIZING.scale400,
    overflow: "visible",

    "& .MuiPaper-root": {
      zIndex: "101",
    },

    "& .arrow": {
      width: "0px",
      height: "0px",
      borderLeft: `${SIZING.scale400} solid transparent`,
      borderRight: `${SIZING.scale400} solid transparent`,
      borderBottom: `${SIZING.scale400} solid ${COLORS.INPUT_BG}`,
      position: "absolute",
      top: `-${SIZING.scale400}`,
      left: "50%",
      marginLeft: `-${SIZING.scale400}`,
      zIndex: "100",
      content: "''",
    },
    "& .left": {
      left: `calc( ${SIZING.scale1000} )`,
    },
    "& .right": {
      left: `calc( 100% - ${SIZING.scale700} )`,
    },

    "& .subright": {
      left: `calc( 100% - ${SIZING.scale900} )`,
    },
    "& .dir-bottom":{
      bottom: `-${SIZING.scale350}`,
      top:'unset',
      transform:'rotate(180deg)'
    }
  },
  backdrop:{
    opacity: `0 !important`
  },
}));