import { useEffect } from "react";
import { Box } from "@mui/material";
import { useApi } from "common/utils/use-api";
import Spinner from "common/components/Spinner/Spinner";
import { NOTIFICATION_TYPE } from "constant";
import useNotificationUpdate from "components/NotificationDrawer/use-notification-update";
import { NotificationItem } from "store/notification-types";
import EConsultRequestDetailsContent from "./e-consult-request-details-content";
import EConsultRequestDetailsHeader from "./e-consult-request-details-header";
import { useRequestContext } from "./request-context";
import { EConsultFolderName, EConsultRequestModel } from "./types";
import { useStyles } from "./styles";

interface EConsultRequestId {
    folderName: EConsultFolderName;
    folderPath: string;
    onRequestOpened: (requestId: string | undefined) => void;
    requestId: string;
}

const EConsultRequestDetails = ({ folderName, onRequestOpened, folderPath, requestId }: EConsultRequestId) => {
    const classes = useStyles();
    const { requestChanges } = useRequestContext();
    const { setNotificationReadStatusByPredicate } = useNotificationUpdate();
    const {
        content: eConsultRequest,
        fetchInfo: { hasResult, state: fetchState },
    } = useApi<null, EConsultRequestModel, EConsultRequestModel | null>(
        {
            id: "EConsult_request_details_view",
            abortOnNewRequest: true,
            autoRequest: true,
            defaultContent: null,
            endpoint: `/econsult/v1/requests/${requestId}`,
            method: "GET",
            key: requestChanges[requestId] ? requestChanges[requestId].newStage : "",
            responseTransformer: (response: EConsultRequestModel): EConsultRequestModel => {
                return {
                    ...response,
                    rejectedByAll: !response?.recipients?.find((recipient) => recipient.status !== "REJECTED"),
                };
            },
        },
        null
    );

    const requestFound = !!(
        eConsultRequest &&
        // does not show loading if we are loading the same resource we already have, better user experience
        (hasResult || (fetchState === "loading" && eConsultRequest.id === requestId))
    );

    useEffect(() => {
        if (eConsultRequest) {
            setNotificationReadStatusByPredicate(
                (notification: NotificationItem) =>
                    notification.type === NOTIFICATION_TYPE.NEW_ECONSULT_REQUEST &&
                    notification.data.requestId === eConsultRequest?.id
            );
        }
    }, [eConsultRequest, setNotificationReadStatusByPredicate]);

    useEffect(() => {
        onRequestOpened(requestFound ? eConsultRequest.id : undefined);
    }, [eConsultRequest, onRequestOpened, requestFound]);

    return (
        <>
            {requestFound ? (
                <div className={classes.eConsult__requestDetailsPane}>
                    <EConsultRequestDetailsHeader
                        eConsultRequest={eConsultRequest}
                        eConsultRequestPath={folderPath}
                        folderName={folderName}
                    />
                    <div className={classes.eConsult__requestDetailsContent}>
                        <EConsultRequestDetailsContent
                            eConsultRequest={eConsultRequest}
                            eConsultRequestPath={folderPath}
                            folderName={folderName}
                        />
                    </div>
                </div>
            ) : (
                <Box display="flex" justifyContent="center" height="100%" flex="1 1 0">
                    <Spinner />
                </Box>
            )}
        </>
    );
};
export default EConsultRequestDetails;
