import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Typography, Button } from "@mui/material"
import { useStyles } from "./styles"
import PMG from '../../assets/images/PMG Logo 1.png'
import { useSelector } from "react-redux";
import { AppReduxStore } from "png/store/reducerTypes"
import Popover from "views/Settings/Popover/Popover";
import PopoverContent from "views/WaitingRoom/PopoverContent/PopoverContent";

export default function HeaderController({ onEndMeeting }:any) {

    const { currentRoomObject,pngDetails } = useSelector((store: AppReduxStore) => store.pngMobile)

    const classes = useStyles({ isHideButton:currentRoomObject!==null })

    const { providerProfileInfo } = pngDetails || { providerProfileInfo: {} };

    const providerName = `${providerProfileInfo.firstName || ""} ${providerProfileInfo.lastName || ""}`;

    return (
        <div className={classes.root}>
            <Box className={classes.initialCard}>
                <img src={PMG} alt="PMG Logo" />
                <Typography className={classes.practiceName}>
                    {currentRoomObject ?
                        <FormattedMessage
                            id="PatientWaitingRoom.label.providerName"
                            values={{ name: providerName }} />
                        :
                        <FormattedMessage id="PatientWaitingRoom.label.practiceName" />}
                </Typography>
                {!currentRoomObject&&<div className={classes.buttonWrapper}>< Popover
                    arrowPosition="right"
                    Button={({ onClick, close }: any) => (
                        <Button 
                        className={classes.joinbutton}
                            onClick={onClick}
                            data-testid="end-call-mobile-header"
                        >
                            <FormattedMessage id="PatientWaitingRoom.label.endVideoCall" />
                        </Button>
                    )}
                    Content={({ close }: any) => (
                        <PopoverContent
                            close={close}
                            Buttonid="Patient_End_Call"
                            handleSubmit={()=>{close();onEndMeeting()}}
                        />
                    )}
                /></div>}
            </Box>

        </div>
    )
}