import BulkUploadGuide from "admin/assets/documents/Bulk-Upload-Guide.pdf"
export const BULK_UPLOAD_GUIDE = BulkUploadGuide//"https://assets.altais.health/eNable+Bulk+Upload+Guide+(Athena+Health).pdf"

export const ADMIN_WEB_CACHE = {
    ADD_ORGANIZATION_WIZARD_CACHE: "ADD_ORGANIZATION_WIZARD_CACHE",
    ADD_PRACTICE_WIZARD_CACHE: "ADD_PRACTICE_WIZARD_CACHE"
}

export const MODAL_TYPES = {
    ADD_PATIENT: "ADD_PATIENT",
    EDIT_PATIENT: "EDIT_PATIENT",
    DELETE_PATIENT_CONFIRMATION_MODAL: "DELETE_PATIENT_CONFIRMATION_MODAL",
    DELETE_FEE_SCHEDULE_CONFIRMATION_MODAL: "DELETE_FEE_SCHEDULE_CONFIRMATION_MODAL",
    FEE_SCHEDULES_VIEWING_MODAL: "FEE_SCHEDULES_VIEWING_MODAL",
    FEE_SCHEDULES_LOCATIONS: "FEE_SCHEDULES_LOCATIONS",
    FEE_SCHEDULES_ADD_GROUP: "FEE_SCHEDULES_ADD_GROUP",
    FEE_SCHEDULES_LOCATION_EDIT: "FEE_SCHEDULES_LOCATION_EDIT",
    OFFBOARD_ORGANIZATION_CONFIRMATION_MODAL: "OFFBOARD_ORGANIZATION_CONFIRMATION_MODAL",
    EDIT_USER: "EDIT_USER",
    ADD_USER: "ADD_USER",
    PROVIDER_PORTAL: "PROVIDER_PORTAL",
    EDIT_PRACTICE: "EDIT_PRACTICE",
    ADD_PRACTICE: "ADD_PRACTICE",
    EDIT_LOCATION: "EDIT_LOCATION",
    ADD_LOCATION: "ADD_LOCATION",
    EDIT_USER_PRODUCTS: "EDIT_USER_PRODUCTS",
    DELETE_USER_CONFIRMATION_MODAL: "DELETE_USER_CONFIRMATION_MODAL",
    UPDATE_USER_LEVEL_MODAL: "UPDATE_USER_LEVEL_MODAL",
    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PATIENT_PASSWORD: "RESET_PATIENT_PASSWORD",
    EDIT_ORG_PRODUCTS: "EDIT_ORG_PRODUCTS",
    EDIT_PRACTICE_PRODUCTS: "EDIT_PRACTICE_PRODUCTS",
    EDIT_EVISIT_TYPES: "EDIT_EVISIT_TYPES",
    EDIT_CANCELLATION_TYPES: "EDIT_CANCELLATION_TYPES",
    EDIT_WAITING_ROOM: "EDIT_WAITING_ROOM",
    EDIT_ORGANIZATION: "EDIT_ORGANIZATION",
    UNDER18_INVITE_CONSENT: "UNDER18_INVITE_CONSENT",
    EDIT_REGISTRATION: "EDIT_REGISTRATION",
    CREATE_NEW_FORM: "CREATE_NEW_FORM",
    WELCOME_CONFIGURATION_MODAL: "WELCOME_CONFIGURATION_MODAL",
    ADD_ENTITY: "ADD_ENTITY",
    CONFIGURE_PRACTICE: "CONFIGURE_PRACTICE",
    PRACTICE_CONFIGURATION: "PRACTICE_CONFIGURATION",
    APP_BANNER_MODAL: "APP_BANNER_MODAL",
    CHANGE_STATUS: "EDIT_STATUS",
    SEND_FOR_REVIEW: "SEND_FOR_REVIEW",
    APPROVAL_MODAL: "APPROVAL_MODAL",
    REJECT_MODAL: "REJECT_MODAL"
}

export const USER_ROLES = {
    PROVIDER: "PROVIDER",
    ADMIN: "ADMIN",
    PATIENT: "PATIENT"
}
export const REGEX = {
    RESTRICT_SPECIAL_CHARACTERS: /^[a-zA-Z0-9-'\s]*$/,
    EMAIL: /$^|.+@.+..+/,
    NUMBERS_ONLY: /^[0-9]*$/,
    US_ZIP_CODE: /^[0-9]{5}(?:-[0-9]{4})?$/,
    URL: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
}
export const USER_LEVELS = {
    ALTAIS_ADMIN_1: "ALTAIS_ADMIN_1",
    ALTAIS_ADMIN_2: "ALTAIS_ADMIN_2",
    ALTAIS_ADMIN_3: "ALTAIS_ADMIN_3",
    ALTAIS_ADMIN_4: "ALTAIS_ADMIN_4",
    ORG_ADMIN_1: "ORG_ADMIN_1",
    ORG_ADMIN_2: "ORG_ADMIN_2",
    PRACTICE_ADMIN: "PRACTICE_ADMIN",
    BILLING_ADMIN: "BILLING_ADMIN"
}
export const US_STATES = [
    { id: 'AK', title: 'Alaska' },
    { id: 'AL', title: 'Alabama' },
    { id: 'AR', title: 'Arkansas' },
    { id: 'AZ', title: 'Arizona' },
    { id: 'CA', title: 'California' },
    { id: 'CO', title: 'Colorado' },
    { id: 'CT', title: 'Connecticut' },
    { id: 'DC', title: 'District of Columbia' },
    { id: 'DE', title: 'Delaware' },
    { id: 'FL', title: 'Florida' },
    { id: 'GA', title: 'Georgia' },
    { id: 'HI', title: 'Hawaii' },
    { id: 'IA', title: 'Iowa' },
    { id: 'ID', title: 'Idaho' },
    { id: 'IL', title: 'Illinois' },
    { id: 'IN', title: 'Indiana' },
    { id: 'KS', title: 'Kansas' },
    { id: 'KY', title: 'Kentucky' },
    { id: 'LA', title: 'Louisiana' },
    { id: 'MA', title: 'Massachusetts' },
    { id: 'MD', title: 'Maryland' },
    { id: 'ME', title: 'Maine' },
    { id: 'MI', title: 'Michigan' },
    { id: 'MN', title: 'Minnesota' },
    { id: 'MO', title: 'Missouri' },
    { id: 'MS', title: 'Mississippi' },
    { id: 'MT', title: 'Montana' },
    { id: 'NC', title: 'North Carolina' },
    { id: 'ND', title: 'North Dakota' },
    { id: 'NE', title: 'Nebraska' },
    { id: 'NH', title: 'New Hampshire' },
    { id: 'NJ', title: 'New Jersey' },
    { id: 'NM', title: 'New Mexico' },
    { id: 'NV', title: 'Nevada' },
    { id: 'NY', title: 'New York' },
    { id: 'OH', title: 'Ohio' },
    { id: 'OK', title: 'Oklahoma' },
    { id: 'OR', title: 'Oregon' },
    { id: 'PA', title: 'Pennsylvania' },
    { id: 'RI', title: 'Rhode Island' },
    { id: 'SC', title: 'South Carolina' },
    { id: 'SD', title: 'South Dakota' },
    { id: 'TN', title: 'Tennessee' },
    { id: 'TX', title: 'Texas' },
    { id: 'UT', title: 'Utah' },
    { id: 'VA', title: 'Virginia' },
    { id: 'VT', title: 'Vermont' },
    { id: 'WA', title: 'Washington' },
    { id: 'WI', title: 'Wisconsin' },
    { id: 'WV', title: 'West Virginia' },
    { id: 'WY', title: 'Wyoming' }
]

export const secretQuestions = [
    { id: "What's the name of the city where you were born?", title: "What's the name of the city where you were born?" },
    { id: "What was your childhood nickname?", title: "What was your childhood nickname?" },
    { id: "What was the model of your first car?", title: "What was the model of your first car?" },
    { id: "What was the name of your elementary school?", title: "What was the name of your elementary school?" },
    { id: "What was the name of your first pet?", title: "What was the name of your first pet?" },
    { id: "What was your favorite food as a child?", title: "What was your favorite food as a child?" },
    { id: "What's the name of the city where your parents met?", title: "What's the name of the city where your parents met?" },
    { id: "What was the first concert you attended?", title: "What was the first concert you attended?" }
]