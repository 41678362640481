import { Box, Button, Grid, Typography } from "@mui/material";
import Controls from "admin/components/Controls/Controls";
import { FeeScheduleGroupsRegion } from "admin/components/Modals/FeeSchedulesLocationEdit/fee-schedule-groups-region";
import { FeeScheduleGroupsTable } from "admin/components/Modals/FeeSchedulesLocationEdit/fee-schedule-groups-table";
import { BillingGroupStateType } from "admin/components/Modals/FeeSchedulesLocationEdit/types";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import { FeeScheduleRegionStateType } from "admin/views/FeeSchedules/types";
import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";

const LocationDetail = () => {
    const { sLocationA } = useSelector((state: AppReduxStore) => state).location
    const { currentProfileRoleJoinInfo } = useSelector((state: AppReduxStore) => state).auth;
    const orgId = currentProfileRoleJoinInfo?.practice?.organizationId;
    const dispatch = useDispatch()
    const classes = useStyles();
    const intl = useIntl()
    const history = useHistory();
    const [billingGroups, setBillingGroups] = useState<BillingGroupStateType>({});
    const [regionsState, setRegionsState] = useState<FeeScheduleRegionStateType>({});

    useEffect(() => {
        if (!sLocationA.id)
            history.push('/admin/locations');
    }, [history, sLocationA.id]);


    const handleEditLocation = () => {
        dispatch(setActiveModal(MODAL_TYPES.EDIT_LOCATION))
    };

    const handleEditFeeGroups = () => {
        dispatch(setActiveModal(MODAL_TYPES.FEE_SCHEDULES_LOCATION_EDIT));
    };

    return (
        <Fragment>
            <Box className={classes.usersWrapper} style={{ position: "relative" }}>
                <Grid container className={classes.usersTopActionWrapper}>
                    <Grid item xs>
                        <Typography color="textPrimary" className={classes.userName}>
                            {sLocationA && `${sLocationA?.name}`}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Button id="btnEditLocation" variant="contained" color="primary" style={{ float: 'right' }} onClick={handleEditLocation}>
                            {`Edit Location Info`}
                        </Button>
                        <Button id="btnEditFeeGroup" variant="outlined" color="primary" style={{ float: 'right', marginRight: '20px' }} onClick={handleEditFeeGroups}>
                            {`Edit Fee Groups`}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.heading}>
                            {intl.formatMessage({ id: "LocationDetail.Label.LocationInfo" })}</Typography>
                    </Grid>
                </Grid>
                <Box className={classes.contolsWrapper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                name="name"
                                label="Location Name"
                                value={sLocationA.name}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                name="ehrLocationId"
                                label="EHR Location ID"
                                value={sLocationA.ehrLocationId}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: '16px' }} spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                name="phone"
                                label="Phone Number"
                                value={sLocationA.phone}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                name="fax"
                                label="Fax Number"
                                value={sLocationA.fax}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                name="email"
                                label="Email"
                                value={sLocationA.email}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '16px' }} spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                name="address1"
                                label="Address 1 *"
                                placeholder="Street Name"
                                value={sLocationA.address1}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                name="address2"
                                label="Address 2"
                                value={sLocationA.address2}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Input
                                name="city"
                                label="City"
                                value={sLocationA.city}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Input
                                label="State"
                                value={sLocationA.state}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Input
                                label="Zip Code"
                                value={sLocationA.postalCode}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item>
                        <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '24px', lineHeight: '140%', color: '#393948', margin: '0px' }}>
                            {`Fee Schedule Groups`}
                        </p>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '320px', marginTop: '10px' }}>
                                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', margin: '0px' }}>
                                    {`Region`}
                                </p>
                                <FeeScheduleGroupsRegion orgId={orgId} regionsState={regionsState} onRegionsChange={setRegionsState} disabled={true} />
                            </div>
                        </div>
                        <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '140%', color: '#393948', margin: '0px', marginTop: '30px' }}>
                            {`Fee Schedule Groups`}
                        </p>
                        <FeeScheduleGroupsTable orgId={orgId} billingGroups={billingGroups} onBillingGroupsChange={setBillingGroups} disabled={true} />
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )
}

export default LocationDetail