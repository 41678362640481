import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    breadcrumb: {
        display: "flex",
        height: SIZING.scale1200,
        alignItems: "center",
        gap: SIZING.scale250,
        paddingLeft: SIZING.scale250,
        background: COLORS.PAGE_MAIN_HEAD_BG,
        boxShadow: "1px 0px 8px rgb(0 0 0 / 10%), inset 0px -1px 0px rgb(0 0 0 / 10%)",
        fontSize: SIZING.scale425,
        "& svg": {
            cursor: "pointer",
            margin: SIZING.scale250,
        },
        "& g, & path": {
            fill: COLORS.PAGINATION_SELECTED_PAGE_NUMBER,
        },
        "& .breadcrumb__link": {
            color: COLORS.PAGINATION_SELECTED_PAGE_NUMBER,
            cursor: "pointer",
            fontWeight: 700,
        },
        "& .breadcrumb__dot": {
            color: "rgba(0,0,0,.2)",
            fontSize: SIZING.scale800,
            fontWeight: 900,
        },
    },
}));
