import { ReactComponent as StarIcon } from "icon-library/SVG/Star.svg";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import classNames from "classnames";

type RateInfoProps = {
    rating: number | null;
    variant?: 'golden' | 'greyscale';
}

const RateInfo: React.FC<RateInfoProps> = ({rating, variant = 'golden'}) => {
    const classes = useStyles();
    const intl = useIntl();
    const starRatingValue = rating ? Math.round(rating) : 0;

    return (
        <div
            className={classNames(classes.rateInfo, variant, 'rateInfo')}
            title={intl.formatMessage({ id: rating ? "RateInfo.rated" : "RateInfo.notRated" }, { rating })}
        >
            {[1,2,3,4,5].map((item) => {
                return (
                    <StarIcon key={`starIcon${item}`}
                        className={classNames({
                            filled: starRatingValue >= item
                        })}
                    />
                );
            })}
        </div>
    );
}

export default RateInfo;
