import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, COLORS } from "admin/theme";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    secretQuestionContainer: {
        display: "block",
        background: "transparent",
        borderRadius: BORDER_RADIUS.MEDLARGE,
        height: "auto",
        width: "auto",
        maxWidth: "400px",
        textAlign: "left",
        [theme.breakpoints.down('md')]: {
            position: "relative",
            margin: "2rem auto",
            height: "auto",
            width: "100%",
        },
        [theme.breakpoints.between('md', 'xl')]: {
            position: "absolute",
            left: "4rem",
            top: "6rem",
        },
        [theme.breakpoints.up('lg')]: {
            position: "absolute",
            left: "6rem",
            top: "18rem",
            maxWidth: "600px",
        }
    },
    header: {
        color: COLORS.PW_VALIDATION_TEXT,
        align: "left",
        "&.MuiTypography-body1": {
            [theme.breakpoints.down('md')]: {
                display: "none"
            },
        }
    },
    paddingTop: {
        paddingTop: "20px"
    }
}));