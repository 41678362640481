import { getAppIdToken } from "authentication/appAuth";
import { Service } from "lib/types";

class UserService implements Service<undefined> {
    initialize = () => Promise.resolve();
    getAuthToken = (): string | undefined => {
        return getAppIdToken();
    };
}

export default UserService;
