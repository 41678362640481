import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_clickable_card: {
        cursor: 'pointer',
        margin: '25px',
        width: '656px',
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '24px',
        gap: '16px',
        background: 'linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)',
        border: '1px solid #6E1E80',
        borderRadius: '16px',
        "&:hover": {
            background: 'linear-gradient(180deg, #CEE8EF 0%, rgba(246, 246, 246, 0.1) 100%)',
            border: '2px solid #0084D5',
            borderRadius: '16px',
        }
    },
    pep_clickable_card_tall: {
        cursor: 'pointer',
        margin: '25px',
        width: '656px',
        height: '170px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '25px',
        gap: '16px',
        background: 'linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)',
        border: '1px solid #6E1E80',
        borderRadius: '16px',
        "&:hover": {
            background: 'linear-gradient(180deg, #CEE8EF 0%, rgba(246, 246, 246, 0.1) 100%)',
            border: '2px solid #0084D5',
            borderRadius: '16px',
        }
    },
    pep_clickable_card_icon: {
        height: '60px',
        width: '60px',
        "& path": {
            fill: '#0084D5'
        }
    },
    pep_additional_resources_header: {
        fontFamily: 'BentonSansCond',
        marginTop: '20px',
        marginBottom: '10px',
        paddingTop: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px',
        lineHeight: '140%',
        letterSpacing: '0.02em',
        color: '#6E1E80'
    },
    pep_additional_resources_link: {
        marginTop: '15px',
        marginBottom: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#0084D5'
    },
    pep_additional_resources_link_icons: {
        verticalAlign: 'middle',
        marginRight: '10px',
        width: '22px',
        height: '22px',
        "& path": {
            fill: '#6E1E80'
        }
    }
}))