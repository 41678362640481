import { Typography, Grid } from "@mui/material";
import { AppReduxStore } from 'admin/store/reducerTypes';
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { map } from "underscore";
import { getObjectValuesByPath, validateNoSpecialCharacters, validateRequired } from "admin/common/utils";
import Controls from "../Controls/Controls";
import { PracticeEHRConnectionProps } from "./types";
import { MODAL_TYPES } from "admin/constant";

const PracticeEHRConnection = forwardRef((props: PracticeEHRConnectionProps, ref) => {
    const { isCoreProductSelected, isSelectedPractice } = props;
    const { admin, practice, modalAdmin } = useSelector((state: AppReduxStore) => state);
    const { ehrSourceSystems, adminWebCachePractice } = admin
    const sPractice = isSelectedPractice ? practice.sPractice : {}
    const { ehrPracticeId: ehrPId } = sPractice?.practiceEhrSettingInfo || {}
    const { practiceIntegrationProperties: ehrProps } = sPractice?.practiceEhrSettingInfo || {}
    const classes = useStyles();
    const intl = useIntl()
    const [ehrSourceSystemId, setEHRSourceSystemId] = useState(sPractice?.ehrSourceSystemId ? sPractice?.ehrSourceSystemId : "");
    const [ehrPracticeId, setEHRPracticeId] = useState(ehrPId ? ehrPId : "");
    const [ehrIntegrationProps, setEHRIntegrationProps] = useState({});
    const [ehrFormValues, setEHRFormValues] = useState({} as any);
    const [errors, setErrors] = useState({} as any);
    const pendingPracticeTemporaryId = modalAdmin?.modalProps?.pendingPracticeTemporaryId ? modalAdmin.modalProps.pendingPracticeTemporaryId : ""

    useEffect(() => {
        if (modalAdmin.activeModal === MODAL_TYPES.ADD_PRACTICE) {
            if (pendingPracticeTemporaryId) {
                const tempFormData = adminWebCachePractice?.formData[pendingPracticeTemporaryId]?.tempFormData
                if (tempFormData?.ehrInfo) {
                    setEHRSourceSystemId(tempFormData?.ehrInfo?.ehrSourceSystemId)
                    setEHRPracticeId(tempFormData?.ehrInfo?.ehrPracticeId)
                    setEHRFormValues(tempFormData?.ehrInfo?.practiceIntegrationProperties)
                    if (tempFormData?.ehrInfo?.ehrSourceSystemId && isCoreProductSelected) {
                        changeEHRSourceSystem(tempFormData?.ehrInfo?.ehrSourceSystemId, tempFormData?.ehrInfo?.ehrPracticeId, tempFormData?.ehrInfo?.practiceIntegrationProperties)
                    }
                }
            }
        }
    }, [adminWebCachePractice?.formData, modalAdmin, pendingPracticeTemporaryId, isCoreProductSelected]);

    useEffect(() => {
        if (sPractice?.ehrSourceSystemId && isCoreProductSelected) {
            changeEHRSourceSystem(sPractice?.ehrSourceSystemId, ehrPId, ehrProps)
        }
    }, [sPractice?.ehrSourceSystemId, isCoreProductSelected]);

    useImperativeHandle(
        ref,
        () => ({
            getEHRConnectionValues() {
                const vResult = validatePracticeEHRConnection(ehrFormValues);
                return {
                    ehrSourceSystemId: ehrSourceSystemId,
                    ehrPracticeId: ehrPracticeId,
                    practiceIntegrationProperties: ehrFormValues,
                    valid: vResult
                }
            }
        }),
    )

    const validatePracticeEHRConnection = (fieldValues = ehrFormValues, ehrPId = ehrPracticeId) => {
        const err = { ...errors };
        const ehrProp = { ...ehrSourceSystems.find((ehr: any) => ehr.id === ehrSourceSystemId)?.ehrIntegrationProperties }
        const propGpObj = ehrProp && getObjectValuesByPath(ehrProp, ['propertyGroups'])

        propGpObj && map(propGpObj, (gpValue: any, gkey) => {
            err[gkey] = {}
            gpValue && map(gpValue.properties, (pValue: any, pkey) => {
                if (pValue.display && typeof fieldValues[gkey][pkey] !== "boolean") {
                    if (pValue.required) {
                        err[gkey][pkey] = validateRequired(fieldValues[gkey][pkey], intl)
                    }
                    if (pValue.validation && fieldValues[gkey][pkey]) {
                        const regex = new RegExp(pValue.validation);
                        err[gkey][pkey] = regex.test(fieldValues[gkey][pkey]) ? "" : "Invalid"
                    }
                }
            })
        })
        if (ehrSourceSystemId) {
            err.ehrPracticeId = ehrSourceSystemId ? validateRequired(ehrPId, intl) : ""
            if (err.ehrPracticeId === "") err.ehrPracticeId = validateNoSpecialCharacters(ehrPId, intl)
        }
        setErrors({
            ...err
        })
        if (JSON.stringify(fieldValues) === JSON.stringify(ehrFormValues))
            return Object.values(err).every((x: any) =>
                x === "" || (x && Object.values(x).every(v => v === ""))
            )
    }
    const handleEHRSourceSystemChanged = (e: any) => {
        const { value } = e.target;
        changeEHRSourceSystem(value)
    }
    const changeEHRSourceSystem = (ehrSourceSystemKey: string, ehrId?: string, ehrValues?: any) => {
        setEHRSourceSystemId(ehrSourceSystemKey)
        if (ehrSourceSystemKey === "") {
            setEHRIntegrationProps({})
            setEHRPracticeId("")
            setEHRFormValues({})
        }
        else {
            const ehrProp = { ...ehrSourceSystems.find((ehr: any) => ehr.id === ehrSourceSystemKey)?.ehrIntegrationProperties }
            const propGpObj = ehrProp && getObjectValuesByPath(ehrProp, ['propertyGroups'])
            const defaultFormValues = {} as any;
            propGpObj && map(propGpObj, (gpValue: any, gkey) => {
                defaultFormValues[gkey] = {}
                gpValue && map(gpValue.properties, (pValue: any, pkey) => {
                    defaultFormValues[gkey][pkey] = isSelectedPractice && ehrProps && sPractice?.ehrSourceSystemId === ehrSourceSystemKey ? ehrProps?.[gkey]?.[pkey] : pValue.defaultValue
                })
            })
            if (ehrId) {
                setEHRPracticeId(ehrId)
            }
            setEHRFormValues(typeof (ehrValues) === "object" && Object.keys(ehrValues).length > 0 ? ehrValues : defaultFormValues)
            setEHRIntegrationProps(ehrProp);
        }
    }

    const handleEHRPropsInputChanged = (parentKey: string, e: any) => {
        const { name, value } = e.target
        const ehrValues = { ...ehrFormValues };
        ehrValues[parentKey][name] = value
        setEHRFormValues({ ...ehrValues })
        validatePracticeEHRConnection(ehrValues)
    }
    const handleEHRPracticeIdInputChange = (e: any) => {
        const { value } = e.target;
        setEHRPracticeId(value)
        validatePracticeEHRConnection(ehrFormValues, value)
    }
    return (
        <Grid container className={classes.addressSectionField}>
            <Grid container direction="row" item spacing={2}>
                <Grid item xs={6}>
                    <Controls.Select
                        name="ehrSourceSystemId"
                        label="EHR"
                        value={ehrSourceSystemId}
                        onChange={handleEHRSourceSystemChanged}
                        options={ehrSourceSystems && ehrSourceSystems.map((e: any) => {
                            return { id: e.id, title: e.name }
                        })}
                        disabled={(isCoreProductSelected === true) ? false : true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        name="ehrPracticeId"
                        label={`EHR ID ${ehrSourceSystemId ? "*" : ""}`}
                        value={ehrPracticeId}
                        onChange={handleEHRPracticeIdInputChange}
                        error={errors.ehrPracticeId}
                        disabled={ehrSourceSystemId ? false : true}
                    />
                </Grid>
            </Grid>
            <Grid item className={classes.sectionHeader} style={{ display: ((ehrSourceSystemId) ? "flex" : "none") }}>
                <Typography className={classes.sectionHeaderText}>
                    {intl.formatMessage({ id: "AddPracticeForm.Heading.EHRInformation" })}
                </Typography>
            </Grid>
            {ehrIntegrationProps &&
                map(ehrIntegrationProps, (v: any, key) => {
                    return (<>
                        {key === "title" && <Grid item className={classes.sectionHeader}>
                            <Typography className={classes.sectionHeaderText}>
                                {v}
                            </Typography>
                        </Grid>
                        }
                        {key === "propertyGroups" && v && map(v, (v1: any, key1) => {
                            return (<>
                                {v1 && map(v1, (v2: any, key2) => {
                                    return (<>
                                        {key2 === "name" && v1.display && v2}
                                        <Grid container direction="row" item spacing={2}>
                                            {key2 === "properties" && map(v2, (vForm: any, fkey) => {
                                                return (<>
                                                    {vForm?.type === "boolean" && vForm?.display
                                                        &&
                                                        <Grid item xs={6}>
                                                            <Controls.Checkbox
                                                                name={fkey}
                                                                label={` ${vForm?.name}`}
                                                                placeholder="Enter Here"
                                                                value={ehrFormValues[key1][fkey]}
                                                                onChange={(e: any) => handleEHRPropsInputChanged(key1, e)}
                                                                error={errors[key1] && errors[key1][fkey]}
                                                            />
                                                        </Grid>}

                                                    {
                                                        (vForm?.type === "string" || vForm?.type === "password") && vForm?.display
                                                        &&
                                                        <Grid item xs={6}>
                                                            <Controls.Input
                                                                name={fkey}
                                                                type={vForm?.type === "password" ? "password" : "text"}
                                                                label={vForm?.required ? `${vForm?.name} *` : `${vForm?.name}`}
                                                                placeholder="Enter Here"
                                                                value={ehrFormValues[key1][fkey]}
                                                                onChange={(e: any) => handleEHRPropsInputChanged(key1, e)}
                                                                error={errors[key1] && errors[key1][fkey]}
                                                            />
                                                        </Grid>}
                                                </>)
                                            })
                                            }
                                        </Grid>
                                    </>)
                                })
                                }
                            </>)
                        })
                        }
                    </>)
                })
            }
        </Grid>
    )
})
export default PracticeEHRConnection