import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme"

export const useStyles = makeStyles(() => ({
    callSectionText: {
        color: COLORS.WAITING_ROOM_CALL_TEXT,
        fontSize: SIZING.scale1000,
        marginBottom: SIZING.scale500,
        textAlign:"center",
        lineHeight:1
    },
    statusSection:{
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
        position:'absolute',
        top:'40%'
    },
    statusSecondSection:{
        marginBottom: SIZING.scale1200,
        display:"flex",
        alignItems:"center",
        flexDirection:"column"
    },
    callSectionDesc: {
        color: COLORS.WAITING_ROOM_CALL_TEXT,
        fontSize: SIZING.scale600,
        textAlign:"center"
    },
    icon2: {
        height: SIZING.scale3200,
        width: SIZING.scale3200,
        marginBottom: SIZING.scale800,
        "& path": {
            fill: COLORS.WAITING_ROOM_CALL_TEXT
        }
    },
    buttonSection:{
        display:"flex",
        "& button":{
            width:SIZING.EMPTY_LIST_ICON,
            borderRadius:SIZING.scale200,
            color:COLORS.WHITE,
            height:'auto',
            minHeight:'4rem',
            padding: '0.313rem',
            fontSize:"1rem",
        }
    },
    waitingStsBtn:{
        background:COLORS.PRIORITY_BG,
        margin:SIZING.scale300,
      
        fontWeight:600,
        "&:hover": {
            backgroundColor: COLORS.PRIORITY_BG,
        },
    },
    waitingStsBtn1:{
        background:COLORS.START_CALL_BG,
        fontWeight:600,
        "&:hover": {
            backgroundColor: COLORS.START_CALL_BG,
        },
        margin:SIZING.scale300
    },
    saveButton:{
        width:SIZING.RPM_CHART_CARD,
        height:SIZING.scale1600,
        background:COLORS.LBLUE,
        color:COLORS.WHITE,
        fontSize:SIZING.scale450,
        borderRadius:SIZING.scale200,
        marginTop:SIZING.scale1200,
        border:"1px solid rgba(0, 0, 0, 0.1)"
    }
}));