import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
    rateInfo: {
        whiteSpace: "nowrap",
        "& svg": {
            width: SIZING.scale500,
            "& g, & path": {
                fill: COLORS.LGRAY
            }
        },
        "&.greyscale svg.filled": {
            "& g, & path": {
                fill: COLORS.BLACK
            }
        }
    }
}));
