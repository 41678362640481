import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    display: "inline-block",
  },
  baMenu: {
    backgroundColor: COLORS.GRAY_2,
    marginTop: `-${SIZING.scale375}`,
    color: COLORS.LIST_SUBHEADER_TEXT,
    fontSize: SIZING.scale400,
    zIndex: 3,
    right: `${SIZING.scale750} !important`,
    top: `${SIZING.scale2000} !important`,
    width: SIZING.EMPTY_LIST_ICON,
    position: 'absolute',
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2))',
    backdropFilter: 'blur(10px)',
    borderRadius: SIZING.scale400,
    "& .MuiListItem-gutters": {
      paddingLeft: `${SIZING.scale250} !important`
    },
    "& hr":{
      margin: `0 ${SIZING.scale250}`
    },
    "& .MuiListItemIcon-root": {
      minWidth: `${SIZING.scale800} !important`
    }
  },
  listText: {
    fontSize: SIZING.scale400,
    fontWeight: 'bold',
    position: "relative",
    display: "inline-block"
  },
  traingle: {
    height: SIZING.scale500,
    width: SIZING.scale800,
    position: "absolute",
    right: SIZING.scale875,
    top: SIZING.scale1050,
    zIndex: 4,
    background: COLORS.GRAY_2,
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.02)',
    transform: "translate(-50%, 50%) rotate(360deg)",
    clipPath: "polygon(50% 0, 100% 100%, 0 100%)"
  },
  carebaMenu: {
    backgroundColor: COLORS.GRAY_2,
    marginTop: `-${SIZING.scale375}`,
    color: COLORS.GREY,
    fontSize: SIZING.scale400,
    zIndex: 3,
    right: `${SIZING.scale250} !important`,
    top: `${SIZING.scale2000} !important`,
    width: SIZING.scale7000,
    position: 'absolute',
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2))',
    backdropFilter: 'blur(10px)',
    borderRadius: SIZING.scale400,
    "& .MuiListItem-gutters": {
      paddingLeft: `${SIZING.scale750} !important`
    }
  },
  caretraingle: {
    height: SIZING.scale500,
    width: SIZING.scale800,
    position: "absolute",
    right: SIZING.scale200,
    top: SIZING.scale1050,
    zIndex: 4,
    background: COLORS.GRAY_2,
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.02)',
    transform: "translate(-50%, 50%) rotate(360deg)",
    clipPath: "polygon(50% 0, 100% 100%, 0 100%)"
  },
  chevronIcon: {
    height: SIZING.scale600,
    width: SIZING.scale600
  },
  tempbaMenu: {
    backgroundColor: COLORS.GRAY_2,
    marginTop: `-${SIZING.scale375}`,
    color: COLORS.GREY,
    fontSize: SIZING.scale400,
    zIndex: 5,
    right: `${SIZING.scale7000} !important`,
    top: `${SIZING.scale4000} !important`,
    width: SIZING.TRANSCRIBE_MODAL_HEIGHT,
    position: 'absolute',
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2))',
    backdropFilter: 'blur(10px)',
    borderRadius: SIZING.scale400,
    "& .MuiListItem-gutters": {
      paddingLeft: `${SIZING.scale50} !important`
    },
    padding: SIZING.scale400
  },
  tamplateTitle: {
    color: COLORS.MULTI_SELECT_BG,
    fontSize: SIZING.scale400
  }
}));