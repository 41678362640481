import React from "react";
import { Paper, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useStyles } from "./styles";
import { IFormInput } from "admin/views/viewsTypes";
import { FormattedMessage } from "react-intl";

type Props = {
  currentForm: IFormInput;
};

const MCHAT: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.InstructionsForUse"} />
          </Typography>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.MCHAT.Body1"} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.ScoringRules"} />
          </Typography>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.MCHAT.Body1"} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.ScoringAlgorithm"} />
          </Typography>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.MCHAT.Body2"} />
            <FormattedMessage id={"RulesAndLogic.MCHAT.Body3"} />
          </Typography>
          <Typography variant="body1" component="ol">
          <ol>
            <li>
              <FormattedMessage id={"RulesAndLogic.MCHAT.Guideline1"} />
            </li>
            <li>
              <FormattedMessage id={"RulesAndLogic.MCHAT.Guideline2"} />
            </li>
            <li>
              <FormattedMessage id={"RulesAndLogic.MCHAT.Guideline3"} />
            </li>
            <li>
              <FormattedMessage id={"RulesAndLogic.MCHAT.Guideline4"} />
            </li>
          </ol>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.ScoringRules"} />
          </Typography>
            <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.MCHAT.Body2"} />
            </Typography>
            <TableContainer className={classes.scoringTable} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
            <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScale1"} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScale2"} /><br/>
              <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScaleLevel2"} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScale3"} /><br/>
              <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScaleLevel3"} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScale4"} /><br/>
              <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScaleLevel4"} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="h6">
                  <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScale5"} />
                </Typography>
              </TableCell>
              <TableCell>
                <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScale6"} />
                </TableCell>
              <TableCell>
                <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScale7"} />
              </TableCell>
              <TableCell>
                <FormattedMessage id={"RulesAndLogic.MCHAT.ScoreScale8"} />
              </TableCell>
            </TableRow>
        </TableBody>
        </Table>
        </TableContainer>
        </Grid>
        </Grid>
    </div>
  );
}

export default MCHAT