import React from "react";
import { DialogTitle } from "@mui/material";
import { ModalHeaderProps } from "./types";
import { useStyles } from "./styles";

/**
 * Deprecated. Use the new, advanced ModalHeader instead.
 */
const ModalHeader = ({ children, ...props }: ModalHeaderProps) => {
    const classes = useStyles();
    const propClassName = props.className || "";
    return (
        <DialogTitle
            {...props}
            className={`${classes.header} ${propClassName}`}
        >
            {children}
        </DialogTitle>
    );
};

export default ModalHeader;
