import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
        submitButton: {
            // override global registration margins
            marginTop: "0 !important",
            marginBottom:"24px",
            color:'#FFFFFF',
            backgroundColor:'#0F7BBD',
            "&:hover": {
             backgroundColor:'#0F7BBD',
            }
        },
        alreadyText:{
            marginTop:"40px",
            color: "#0F7BBD",
            fontSize:'14px',
            cursor:'pointer'
        }
}));