import React, { Fragment } from "react";
import { Paper, Box, Divider, TextField, Grid, RadioGroup, Radio, FormControlLabel, Typography, Checkbox, FormControl, FormGroup, GridSize, MenuItem, Select, List, ListItem, ListItemText  } from "@mui/material";
import { useStyles } from "./styles";
import { IAnswer, IQuestion } from "admin/views/viewsTypes";
import { groupQuestions } from 'admin/store/actions/configuration';

type Props = {
  questions: IQuestion[];
};

const Questions: React.FC<Props> = ({questions}) => {
  const classes = useStyles();
  let formQuestions = Object.assign([], questions);
  const categoryName = "Additional Questions"; 
  formQuestions = formQuestions?.filter((e: any) => e.categoryName !== categoryName);
  const nonGrouped = formQuestions.every((f: IQuestion) => f.groupIndex === null);
  //Transform nonGrouped questions to same structure as grouped to avoid needing downstream logic changes
  const groupedQuestions = nonGrouped ? [formQuestions?.sort((a: IQuestion, b: IQuestion) => { return (a.orderIndex - b.orderIndex) }).map((f: IQuestion) => [f] )] : groupQuestions(formQuestions);
  return (
    <div className={classes.root}>
      {groupedQuestions?.map((categoryGroup: any[], categoryGroupIndex: number) => {
          return categoryGroup?.map((questionGroup: [], questionGroupIndex: number) => {
            return questionGroup?.map((question: any, index: number, questionsArray: any[]) => {
              const answerSize: number = question?.answerOption?.length ? question?.answerOption?.length * 2 - 1 : 1;
              const questionSize: number = 12 - answerSize;
              return (
              <Fragment key={`${categoryGroupIndex}-${questionGroupIndex}-${index}`}>
              <Grid container wrap="wrap" spacing={1}>
                {questionGroupIndex === 0 &&
                <Grid item xs={12}>
                  <Typography variant="h3">{question.categoryName}</Typography>
                </Grid>
                }
                { /* Non-Grouped Questions */ }
                {questionsArray.length === 1 &&
                <>
                  {(question.questionType === 'TextField') &&
                  <>
                  <Grid item xs={12} className={classes.question}>
                  <Box display="flex" flexDirection="row" alignItems="top">
                    <Box className={classes.questionNumber}>
                    {questionGroupIndex + 1}.  
                    </Box>
                    <Box flexGrow={1} pl={1}>
                    {question.questionText}  
                    </Box>
                  </Box>
                  </Grid>
                  <Grid item xs={6} style={{ marginLeft: '2.25rem' }}>
                    <TextField variant={"outlined"} disabled />
                  </Grid>
                  </>
                  }
                  {(question.questionType === 'RadioButton' || question.questionType === 'MultipleChoice' || question.questionType === 'Checkbox' || question.questionType === 'Dropdown') &&
                  <>
                  <Grid item xs={12} className={classes.question}>
                  <Box display="flex" flexDirection="row" alignItems="top">
                    <Box className={classes.questionNumber}>
                    {questionGroupIndex + 1}.  
                    </Box>
                    <Box flexGrow={1} pl={1}>
                    {question.questionText}  
                    </Box>
                  </Box>
                  </Grid>
                  {question.questionType === 'Dropdown' &&
                  <Grid item xs={6} className={classes.questionInputField}>
                    <FormControl component="fieldset">
                      <Select variant={"outlined"} value={""} disabled displayEmpty>
                        <MenuItem value="">
                          Select one
                        </MenuItem>
                    </Select>
                    <Paper elevation={3}>
                    <List>
                      {question && question?.answerOption?.sort((a: IAnswer, b: IAnswer) => { return (a.orderIndex - b.orderIndex) })?.map((answer: any, innerIndex: number) => (
                        <ListItem key={`${innerIndex}-${index}-${questionGroupIndex}`}>
                          <ListItemText primary={answer.answerText} />
                        </ListItem>
                      ))}
                    </List>
                    </Paper> 
                    </FormControl>
                  </Grid>
                  }
                  <Grid item xs={12} style={{ marginLeft: '2.25rem' }}>
                  {(question.questionType === 'RadioButton' || question.questionType === 'MultipleChoice') &&
                  <RadioGroup row aria-label="answer" name={`answer-${index}`}>
                    {question && question?.answerOption?.sort((a: IAnswer, b: IAnswer) => { return (a.orderIndex - b.orderIndex) })?.map((answer: any, innerIndex: number) => (
                      <FormControlLabel className={classes.radioButtonLabel} value={answer.answerText === 'Yes' ? 1 : 0 } key={`${innerIndex}-${index}-${questionGroupIndex}`} control={<Radio color="primary" disabled />} label={answer.answerText} />
                    ))}
                  </RadioGroup>
                  }
                  {question.questionType === 'Checkbox' &&
                  <FormControl component="fieldset">
                  <FormGroup row>
                    {question && question?.answerOption?.sort((a: IAnswer, b: IAnswer) => { return (a.orderIndex - b.orderIndex) })?.map((answer: any, innerIndex: number) => (
                      <FormControlLabel className={classes.checkBoxLabel} value={answer.answerText === 'Yes' ? 1 : 0 } key={`${innerIndex}-${index}-${questionGroupIndex}`} control={<Checkbox color="primary" disabled />} label={answer.answerText} />
                    ))}
                  </FormGroup>
                  </FormControl>
                  }
                  </Grid>
                  </>
                  }
                  {(question.questionType === 'Number') &&
                  <>
                  <Grid item xs={11} className={classes.question}>
                  <Box display="flex" flexDirection="row" alignItems="top">
                    <Box className={classes.questionNumber}>
                    {questionGroupIndex + 1}.  
                    </Box>
                    <Box flexGrow={1} pl={1}>
                    {question.questionText}  
                    </Box>
                  </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <TextField variant={"outlined"} type="number" defaultValue="0" inputProps={{ min: 0 }} disabled />
                  </Grid>
                  </>
                  }
                </>
                }
                { /* Grouped Questions, may have sub questions */ }
                {questionsArray.length > 1 && 
                <>
                  {(question.questionType === 'TextField' || question.questionType === 'Number') && index === 0 &&
                  <>
                  <Grid item xs={'auto'} className={classes.questionNumber}>
                    {questionGroupIndex + 1}.
                  </Grid>
                  <Grid item xs={11} className={classes.questionDescription}>
                    {question.description}
                  </Grid>
                  </>
                  }
                  {(question.questionType === 'TextField' || question.questionType === 'Number') &&
                  <>
                  <Grid item xs={6} className={classes.groupedTextQuestions}>
                    {question.questionText}
                    {question.questionType === 'TextField' &&
                      <TextField variant={"outlined"} disabled />
                    }
                    {question.questionType === 'Number' &&
                      <TextField variant={"outlined"} type="number" defaultValue="0" inputProps={{ min: 0 }} disabled />
                    }
                  </Grid>
                  </>
                  }
                  {(question.questionType === 'MultipleChoice' || question.questionType === 'RadioButton' || question.questionType === 'Checkbox') && index === 0 &&
                  <>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {question.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={questionSize as GridSize}>
                    Please select one answer for each question.
                  </Grid>
                  <Grid item xs={answerSize as GridSize}>
                    {(question.questionType === 'MultipleChoice' || question.questionType === 'RadioButton' || question.questionType === 'Checkbox') &&
                    <Grid container>
                      {question && question?.answerOption?.map((answer: any, innerIndex: number) => (
                        <Grid item xs key={`${innerIndex}-${index}-${questionGroupIndex}`} className={classes.groupedAnswerLabel}>
                          {answer.answerText}
                        </Grid>
                      ))}
                    </Grid>
                    }
                  </Grid>
                  </>
                  }
                  {(question.questionType === 'MultipleChoice' || question.questionType === 'RadioButton' || question.questionType === 'Checkbox') &&
                  <>
                  <Grid item xs={questionSize as GridSize}>
                    <Grid container style={{ marginLeft: '1rem' }}>
                      <Grid item xs={'auto'} className={classes.questionNumber}>
                        {index + 1}.
                      </Grid>
                      <Grid item xs={11} className={classes.questionDescription}>
                      {question.questionText}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={answerSize as GridSize}>
                  {(question.questionType === 'RadioButton' || question.questionType === 'MultipleChoice') &&
                  <RadioGroup row aria-label="answer" name={`answer-${index}`}>
                    {question && question?.answerOption?.map((answer: any, innerIndex: number) => (
                      <FormControlLabel className={classes.groupedRadioButtonLabel} value={answer.answerText === 'Yes' ? 1 : 0 } key={`${innerIndex}-${index}-${questionGroupIndex}`} control={<Radio color="primary" disabled />} label="" />
                    ))}
                  </RadioGroup>
                  }
                  {question.questionType === 'Checkbox' &&
                  <FormControl component="fieldset">
                  <FormGroup row>
                    {question && question?.answerOption?.map((answer: any, innerIndex: number) => (
                      <FormControlLabel className={classes.groupedCheckBoxLabel} value={answer.answerText === 'Yes' ? 1 : 0 } key={`${innerIndex}-${index}-${questionGroupIndex}`} control={<Checkbox color="primary" disabled />} label="" />
                    ))}
                  </FormGroup>
                  </FormControl>
                  }
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{ margin: '.5rem 0' }} />
                  </Grid>
                  </>
                  }
                </>
                }
              </Grid>
              </Fragment>
              );
          });
        });
      })}
  </div>
  );
}

export default Questions
