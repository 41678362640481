import { Service } from "./types";
import ApiService, { IApiService }  from "./api/api-service";
import SecurityService, { ISecurityService } from "./security/security-service";
import UserService from "./user/user-service";

let serviceInstances: Record<string, Service<any>> = {};
let serviceClasses = {
    api: ApiService,
    security: SecurityService,
    user: UserService,
};

const getService = <ServiceItem extends Service<any>>(name: string): ServiceItem => {
    if (!serviceInstances.hasOwnProperty(name)) {
        // @ts-expect-error ts2351 - serviceClazz is not constructable
        serviceInstances[name] = new serviceClasses[name]();
    }
    return serviceInstances[name] as ServiceItem;
};

export const getApiService = (): IApiService => {
    return getService<IApiService>("api");
};
export const getSecurityService = (): ISecurityService => {
    return getService<ISecurityService>("security");
};
export const getUserService = (): UserService => {
    return getService<UserService>("user");
};

/** For testing purposes only */
export const reinitialize = (customServices: Record<string, any>) => {
    serviceClasses = {
        ...serviceClasses,
        ...customServices,
    };
    serviceInstances = {};
};
