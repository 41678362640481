import React, { useState } from "react";
import { Box } from "@mui/material";
import { EConsultFolderName } from "./types";
import { useRouteMatch } from 'react-router';
import EConsultFolderViewSearch from "./e-consult-folder-view-search";
import EConsultRequestDetails from "./e-consult-request-details";
import { RequestContextProvider } from "./request-context";

type EConsultFolderViewProps = {
    folderName: EConsultFolderName | undefined;
}

const folders: {folderName: EConsultFolderName, path: string}[] = [
    {folderName: "received", path: "/provider/econsult/received/:requestId"},
    {folderName: "sent", path: "/provider/econsult/sent/:requestId"}
];

const EConsultFolderView = ({ folderName = folders[0].folderName }: EConsultFolderViewProps) => {
    const match = useRouteMatch<{ folderName: EConsultFolderName, requestId: string | undefined }>("/provider/econsult/:folderName/:requestId") ?? {
        params: { requestId: undefined }
    };
    const { requestId } = match.params;
    const [openedRequestId, setOpenedRequestId] = useState<string | undefined>(requestId);
    const folderPath = (folders.find((folder) => folder.folderName === folderName) ?? folders[0]).path;

    return (
        <RequestContextProvider>
            <Box display="flex" width="100%" height="100vh">
                <Box position="relative" display="flex" flexDirection="column" height="100%">
                    <EConsultFolderViewSearch
                        // We set key here to remount the component when folderName changes
                        // this ensures we clear all state
                        key={folderName}
                        folderName={folderName}
                        openedRequestId={openedRequestId}
                    />
                </Box>
                {requestId && <EConsultRequestDetails
                    folderName={folderName}
                    folderPath={folderPath}
                    onRequestOpened={setOpenedRequestId}
                    requestId={requestId}
                />}
            </Box>
        </RequestContextProvider>
    );
};

export default EConsultFolderView;
