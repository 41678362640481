import { signOutApp } from 'authentication/appAuth';
import { AnyMenuItem } from "common/components/OptionMenu/types";
import { ReactComponent as Logout } from 'icon-library/SVG/Doorway-Out.svg';
import { ReactComponent as HomeIcon } from 'icon-library/SVG/Home-Light.svg';
import { useRef, useState } from "react";
import { Profile } from "store/reducerTypes";
import NavigationMenu from "views/PEPHome/components/navigation-menu/navigation-menu";
import { useStyles } from "./styles";
import { ConfigProps, LinkProps, TopNavLinksProps } from "./types";

export const PEPNavigation = ({ providerConfig: config, permissions, onSelect, profile, currentSelection }: { providerConfig: ConfigProps, permissions: string[], onSelect?: (menuItem: string) => void, profile: Profile | null, currentSelection?: string }) => {
    const classes = useStyles();
    const navLinks: TopNavLinksProps = config.navigations.header.links;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const menuRef = useRef(null);
    const providerProfile = profile?.providerProfile;
    const practice = profile?.practice;
    const prefix = providerProfile?.prefix ? `${providerProfile?.prefix} ` : ``;
    const middleName = providerProfile?.middleName ? ` ${providerProfile.middleName} ` : ` `;
    const fullName = `${prefix}${providerProfile?.firstName}${middleName}${providerProfile?.lastName}`;
    const fullNameStr = (providerProfile?.firstName && providerProfile?.lastName) ? fullName : "";
    const practiceName = practice?.name || "";

    async function handleLogout() {
        const postLogoutRedirectUri = window.location.origin + "/login";
        await signOutApp(postLogoutRedirectUri);
    };

    const hasPermission = (requiredPermissionsList: string[], permissionPolicy?: string): boolean => {
        if ((!permissionPolicy) || (permissionPolicy === "all")) {
            return requiredPermissionsList.every((permission: string) => permissions.includes(permission));
        } else {
            return requiredPermissionsList.some((permission: string) => permissions.includes(permission));
        }
    };

    const getVisibleLinks = (direction: "left" | "right"): LinkProps[] => {
        const links: LinkProps[] = (direction === "left") ? navLinks.left : navLinks.right;
        return links.filter((item: LinkProps) => (!!item.permissions) ? (hasPermission(item.permissions, item.permissionPolicy) ? item : null) : item);
    };

    const getNavigationLinks = (direction: "left" | "right"): JSX.Element => {
        const links: LinkProps[] = (direction === "left") ? getVisibleLinks("left") : getVisibleLinks("right");
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: direction,
                    alignItems: 'center'
                }}
            >
                {links.map((item: LinkProps, index: number) => {
                    return (
                        <a
                            key={`${direction}_${index}_${item.name}`}
                            id="navigation_top_item"
                            className={item.selected ? classes.pep_navigation_top_item_selected : classes.pep_navigation_top_item}
                            href={item.href}
                            target={item.target ? item.target : "_self"}
                            rel="noopener noreferrer"
                            ref={(item.id === 'my_account') ? menuRef : null}
                            onClick={(event) => {
                                if (onSelect && !item.href) {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    if (item.id === 'my_account')
                                        setMenuOpen(!menuOpen);
                                    else
                                        onSelect(item.id);
                                }
                            }}
                        >
                            {item.name}
                        </a>
                    )
                })}
            </div>
        )
    }

    const menuConfig: AnyMenuItem[] = [
        {
            id: "pep_navigation_logout",
            labelKey: "pep.navigation.top.my.account.menu.logout",
            icon: Logout,
            onClick: handleLogout
        }
    ];

    return (
        <div id="navigation_row" className={classes.pep_navigation_row}>
            <div id="navigation_container" className={classes.pep_navigation_container}>
                <div style={{ display: 'inline-block', width: '4%' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <HomeIcon id="navigation_home"
                            className={(currentSelection === "") ? classes.pep_navigation_top_home_selected : classes.pep_navigation_top_home}
                            onClick={(event) => {
                                if (onSelect) {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    onSelect("");
                                }
                            }}
                        />
                    </div>
                </div>
                <div style={{ display: 'inline-block', width: '70%' }}>{getNavigationLinks("left")}</div>
                <div style={{ display: 'inline-block', width: '6%' }} />
                <div style={{ display: 'inline-block', width: '20%' }}>{getNavigationLinks("right")}</div>
                <NavigationMenu
                    handleClose={() => setMenuOpen(false)}
                    variant="light"
                    placement="bottom-end"
                    menuConfig={menuConfig}
                    fullName={fullNameStr}
                    practiceName={practiceName}
                    anchorEl={menuRef}
                    open={menuOpen}
                />
            </div>
        </div>
    );
}

