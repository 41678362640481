import React, { useEffect, useState } from "react"
import { Box, Typography, Button } from "@mui/material"
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import { useStyles } from "./styles";
import PNG_LOGO from "png/assets/images/PNG_LOGO.svg"
import Altais_Logo from "png/assets/images/Altais_Logo.svg"
import Input from "common/components/Input/Input"
import { validatePngLink, enterWaitingRoom } from "png/store/actions"
import { notifyProviders } from "store/actions";
import { useSelector,useDispatch } from "react-redux";
import { useParams } from "react-router-dom"
import { useReduxDispatch } from "png/store/utils"
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader"
import { AppReduxStore } from "png/store/reducerTypes"
import { useForm } from "common/components/FormValidator/FormValidator"
import ErrorMessage from "common/components/ErrorMessage/ErrorMessage"
import { useHistory } from "react-router-dom"
import moment from "moment"
function PatientDetails() {
    
    const classes = useStyles();
    const customDispatch = useReduxDispatch()
    const { id, providerId } = useParams<any>()
    const [loader, setloader] = useState(false)
    const [isRegisteredUser, setisRegisteredUser] = useState(true)
    const [userName, setuserName] = useState(null)
    const dispatch = useDispatch()
    const { pngDetails,isInvalidInvite } = useSelector((state: AppReduxStore) => state.pngMobile)
    const history = useHistory()
    const {
        validateAll,
        renderMessage,
        resetForm
    } = useForm()

    useEffect(() => {
        resetForm()
    }, [isRegisteredUser])

    const handleInput = (event: any) => {
        const {
            target: { value },
        } = event;
        setuserName(value)
    }

    useEffect(() => {
        if (isInvalidInvite) {
            history.push('/wr-status')
        }
    }, [isInvalidInvite])

    useEffect(() => {
        setloader(true)
        customDispatch(validatePngLink(id,providerId)).then((data: any) => {
            const { patientProfileId } = data;
            if (patientProfileId) {
                setisRegisteredUser(true)
            }else{
                setisRegisteredUser(false)
            }
            setloader(false)
        }).catch((e) => {
            setloader(false)
        })
    }, [])

    const getParcticeId = (link:string)=>{
        const splittedLink = link.split("/");
        if(splittedLink.length>0){
            const inviteId = splittedLink.pop() || "";
            return window.atob(inviteId).split("@#@")[1]
        }
        return ''
    }

    const handleSubmit = () => {
        if (validateAll()) {
            setloader(true)
            let extraParams = null
            let token = null
            let type = ''
            if(providerId){
                type='open'
                extraParams={providerId,patientName:userName}
                token = pngDetails?.inviteToken
            }else{
                extraParams={providerId:pngDetails?.providerProfileId,patientName:pngDetails?.patientName}
                token=id
                type='scheduled'
            }
            dispatch({ type:'SET_NAE_INVITE_TYPE',payload:type, })
            customDispatch(enterWaitingRoom(token,extraParams,type,getParcticeId(id))).then(() => {
                history.push({ pathname: '/wr' })
                setloader(false)
            }).catch(()=>{
                setloader(false)
            })
            dispatch(notifyProviders(providerId,"WAITINGROOM",getParcticeId(id),pngDetails?.visitReminderId || ''))  
        }
    }

    const userNameError = !isRegisteredUser && renderMessage("name:3", "required|minLength", userName);
    //@ts-ignore
    const startIn = pngDetails?.startTime ? Math.floor((moment.utc(pngDetails?.startTime).local().valueOf() - Date.now()) / 1000) : ''

    const { isUserRestricted = false } = pngDetails || {};

    return (
        <Box className={classes.base} >
          
            {loader ? <ComponentLoader /> : <>  <div className={classes.contentWrapper}>
                <img src={PNG_LOGO} alt="Main Logo" className={classes.mainLogo} />
                <Typography className={classes.docName}>
                    <FormattedMessage id="PatientDetails.label.doc" values={{ providerName: pngDetails?.providerProfileInfo?.lastName }} />
                </Typography>
               {!isUserRestricted ? <>
                {startIn&&<Typography className={classes.desc}>
                    <FormattedMessage id="PatientDetails.label.desc" />  <FormattedRelativeTime updateIntervalInSeconds={5} style={`long`} value={startIn} />
                </Typography>}

                {pngDetails && (!isRegisteredUser ? <div className={classes.input}>
                    <label >
                        <FormattedMessage id="PatientDetails.label.fullName" />
                    </label>
                    <Input
                        placeholder="Type your full name"
                        value={userName}
                        onChange={handleInput}
                    />
                    <ErrorMessage message={userNameError} />
                </div> :
                    <Typography className={classes.patientName}>
                        <FormattedMessage id="PatientDetails.label.hello" data-testid="patientName" values={{ name: pngDetails.patientName }} />
                    </Typography>)}

                <Button className={classes.waitingRoomBtn} data-testid="pmg-waiting-room-submit-button" onClick={handleSubmit} >
                    <FormattedMessage id="PatientDetails.label.enterWaitingRoom" />
                </Button>
                </>:
                <>  
                 <Typography className={classes.desc}>
                    <FormattedMessage id="PatientDetails.label.startsOn" />  
                </Typography>
                 <Typography className={classes.date}>
                    {moment.utc(pngDetails?.startTime).local().format("dddd, MMMM DD, YYYY hh:mm A")}
                </Typography>
                </>
               }   
            </div>
                <div className={classes.footer}>
                    <img alt="Altais Logo" src={Altais_Logo} />
                    <Typography >
                        <FormattedMessage id="PatientDetails.label.footerText" />
                    </Typography>
                </div></>}

        </Box>
    )
}

export default PatientDetails
