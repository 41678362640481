import { DialogContent, Box, Button, DialogActions, IconButton, Paper, Tab, Tabs, Typography, Dialog, DialogTitle, DialogContentText } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import AddUserForm from "./AddUserForm";
import { AppReduxStore } from "admin/store/reducerTypes";
import AddUserBulk from "./AddUserBulk";
import Spinner from "common/components/Spinner/Spinner";
import { appendEhrSystemKeyTemplatePath } from "admin/common/utils";
const { PROVIDER_BULK_UPLOAD_CSV_TEMPLATE } = (window as any).env;

const AddUser = () => {
    const { auth, practice, admin } = useSelector((state: AppReduxStore) => state);
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [isBulkUploadDisabled, setIsBulkUploadDisabled] = useState(true);
    const [isCloseConfirmation, setCloseConfirmation] = useState(false);
    const [isUnsaved, setUnsaved] = useState(false);
    const [isUploadNotSuccess, setUploadNotSuccess] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const hideUploadUI = !auth.currentPractice
    //&& (auth.currentProfileRoleJoinInfo?.level?.name?.indexOf('ALTAIS_ADMIN') !== -1 || auth.currentProfileRoleJoinInfo?.level?.name?.indexOf('ORG_ADMIN') !== -1)
    const [tab, setTab] = useState(hideUploadUI ? 1 : 0);
    const addUserFormRef = useRef<any>();
    const addBulkUploadRef = useRef<any>();

    useEffect(() => {
        setTab(hideUploadUI ? 1 : 0)
    }, [hideUploadUI])

    const handleClose = () => {
        if (isUnsaved) {
            setCloseConfirmation(true)
        } else {
            dispatch(setActiveModal(""));
            setIsBulkUploadDisabled(true);
            setUploadNotSuccess(false)
        }
    };
    const handleCloseModal = () => {
        setUnsaved(false)
        dispatch(setActiveModal(""));
        setIsBulkUploadDisabled(true);
        setCloseConfirmation(false)
    }
    const handleCancelCloseModal = () => {
        setCloseConfirmation(false)
    }
    const handleChangeTab = (event: any, newTab: any) => {
        setTab(newTab);
    };

    const handleSaveUser = (event: any) => {
        const result = addUserFormRef.current.submitForm(event, true);
        if (result)
            handleClose();
    };

    const handleSaveAddUser = (event: any) => {
        addUserFormRef.current.submitForm(event, false);
    };

    const modalClasses = {
        paper: classes.wrapper
    };
    const confirmModalClasses = {
        paper: classes.wrapperAuto
    };

    const handleBulkUpload = () => {
        addBulkUploadRef.current.submitBulkUpload()
    }

    const handleAddedFile = () => {
        setIsBulkUploadDisabled(false);
    };
    const handleRemovedFile = () => {
        setIsBulkUploadDisabled(true);
    };
    const onAddFormChanged = (dataChanged: boolean) => {
        setUnsaved(dataChanged)
    }
    const handleLoading = (flag: boolean) => {
        setIsUploading(flag)
    }
    
    const ehrInfo = admin.ehrSourceSystems?.find((e: any) => e.id === practice?.sPractice?.ehrSourceSystemId)
    return <>
        <Modal onClose={handleClose} classes={modalClasses} id={MODAL_TYPES.ADD_USER}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px', paddingBottom: '30px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"AddUser.Tab.AddUser"} />
                    </Typography>
                </Box>
                <Box>
                    {tab === 0 && <IconButton id="btnCloseUserModal" style={{ padding: '0px' }} size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>}
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    {tab === 0 && ehrInfo?.hasTemplate &&
                        <Button id="btnDownloadTemplate" variant="outlined"
                            aria-haspopup="true" href={appendEhrSystemKeyTemplatePath(PROVIDER_BULK_UPLOAD_CSV_TEMPLATE,
                                auth.currentPractice?.ehrSourceSystemId || practice?.sPractice?.ehrSourceSystemId)}>
                            {intl.formatMessage({ id: "AddUser.DowloadTemplate" })}
                        </Button>
                    }
                </Box>
                <Box>
                    {tab === 1 ? <Fragment>
                        <Button id="btnCancel" variant="outlined"
                            aria-haspopup="true" onClick={handleClose}>
                            {intl.formatMessage({ id: "AddUser.Action.Cancel" })}
                        </Button>
                        <Button id="btnSaveAndAddAnother" variant="contained" color="primary" style={{ zIndex: 100, marginLeft: '10px' }}
                            aria-haspopup="true" onClick={handleSaveAddUser} disabled={!isUnsaved}>
                            {intl.formatMessage({ id: "AddUser.Action.SaveAndAddAnother" })}
                        </Button>
                        <Button id="btnSaveAndFinish" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveUser}
                            disabled={!isUnsaved}>
                            {intl.formatMessage({ id: "AddUser.Action.SaveAndFinish" })}
                        </Button>
                    </Fragment> :
                        isUploading ? <Spinner /> : <Button id="btnBulkUplpad" variant="contained" color="primary" disabled={isBulkUploadDisabled || isUploadNotSuccess}
                            aria-haspopup="true" onClick={handleBulkUpload}>
                            Upload
                        </Button>}
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '28px' }}>
                <Box width="100%">
                    {!hideUploadUI && <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        onChange={handleChangeTab}
                        aria-label="Add User tab">
                        <Tab style={{ marginRight: '4px' }} label={intl.formatMessage({ id: "AddUser.BulkUpload" })} />
                        <Tab style={{ marginLeft: '4px' }} label={intl.formatMessage({ id: "AddUser.Tab.AddUser" })} />
                    </Tabs>}
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper square elevation={0}>
                    {tab === 1 ? <Box className={classes.tabContentWrapper}>
                        <AddUserForm ref={addUserFormRef} onFormValueChanged={onAddFormChanged} />

                    </Box> :
                        <Box className={classes.tabContentWrapper}>
                            <AddUserBulk ref={addBulkUploadRef} handleAddedFile={handleAddedFile} handleRemovedFile={handleRemovedFile} handleClose={handleClose} handleLoading={handleLoading}></AddUserBulk>
                        </Box>
                    }
                </Paper>
            </DialogContent>
        </Modal>
        <Dialog maxWidth="xs" classes={confirmModalClasses}
            onClose={handleCloseModal} aria-labelledby="close-confirmation" open={isCloseConfirmation}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1}>
                    <DialogTitle id="close-confirmation">
                        <Typography variant="body2" className={classes.dialogTitle}>
                            <FormattedMessage id={"Close User Modal"} />
                        </Typography>
                    </DialogTitle>
                </Box>
            </DialogActions>
            <DialogContent>
                <DialogContentText style={{ paddingLeft: '32px' }}>
                    <FormattedMessage id={"AddUserForm.CloseWithoutSave.Text"} />
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dlgAction}>
                <Button id="btnSaveAndAddAnother" variant="contained" color="primary" style={{ zIndex: 100 }}
                    aria-haspopup="true" onClick={handleCloseModal}>
                    YES
                </Button>
                <Button id="btnSaveAndFinish" variant="contained" style={{ marginLeft: '10px' }} onClick={handleCancelCloseModal}>
                    NO
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

export default AddUser;