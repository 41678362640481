import React from "react";
import { ReactComponent as ChevronLeft } from "icon-library/SVG/Chevron_Left.svg";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { Typography } from "@mui/material";
import {useSelector} from "react-redux";
import { AppReduxStore } from 'store/reducerTypes';
import moment from "moment"
import {FormattedRelativeTime} from "react-intl";
import { useHistory } from "react-router-dom";
import { ALERT_TYPE } from "views/People/Alerts/utils"
import { AlertHeaderProps } from "./types";

const AlertHeader = ({clearSelectedAlertId}: AlertHeaderProps) => {

    const classes = useStyles();

    const ChevronLeftIcon = createSvgIcon(ChevronLeft);

    const history = useHistory()

    const { currentAlertDetails,selectedPatientId } = useSelector((state: AppReduxStore) => state.patient)
    
    const { adtEventType, visitDateTime, visitLocationFacility } = currentAlertDetails || {};

    const time = Math.floor((new Date(visitDateTime).getTime() - Date.now()) / 1000)

    const getTitle = (type:string = '') =>{
        switch (type) {
            case ALERT_TYPE.DISCHARGE:
                return 'DISCHARGED' 
            case ALERT_TYPE.TRANSFERRED:
                return  "TRANSFERRED"
            case ALERT_TYPE.ADMITTED:
                return 'ADMITTED'
            default:
                return '-'
        }
    }

    const handleBackClick = () => {
        if (clearSelectedAlertId) {
            clearSelectedAlertId();
        } else {
            history.push(`/provider/people/patients/${selectedPatientId}/alerts`);
        }
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>
                <ChevronLeftIcon className={classes.backIcon} onClick={handleBackClick} />
                <div>
                    <Typography variant="h4" >
                        {getTitle(adtEventType)}
                    </Typography>
                    <Typography variant="body1" className={classes.desc}>
                         {visitLocationFacility}-{visitDateTime ? moment(visitDateTime).format('MMM DD,YYYY hh:mm A') : '-'} (<FormattedRelativeTime updateIntervalInSeconds={5} style={`short`} value={time} />)
                    </Typography>
                </div>
            </div>
            {/* <div className={classes.dFlex}>
                <Button className={classes.exportBtn} >
                    <Typography variant="button">
                        <FormattedMessage id="People.forms.details.export" />
                    </Typography>
                </Button>
            </div> */}

        </div>
    )
}

export default AlertHeader
