import { Patient } from "./types";
import { TableListItem, TableListItemProps } from "lib/ui-components/table-list";
import * as TableListCSS from "lib/ui-components/table-list/class-names";

export type PatientListItemProps = {
    patient: Patient;
    onSelect?: (select: boolean) => void;
    className?: string;
} & Omit<TableListItemProps, "id" | "onSelectChange">;

export const PatientListItem: React.FC<PatientListItemProps> = ({
    idPrefix,
    onSelect,
    patient,
    children,
    className,
    ...tableListItemProps
}) => {
    const { age, displayName, genderCode, id, mrn } = patient;
    const ageGender = [age, genderCode].filter(Boolean).join(" ");
    const patientDetails = `(${ageGender}) ${mrn}`;
    const onSelectChange = onSelect ? (_: string, selected: boolean) => onSelect(selected) : undefined;

    return (
        <TableListItem
            id={id}
            idPrefix={idPrefix}
            className={className}
            onSelectChange={onSelectChange}
            {...tableListItemProps}
        >
            <td className={TableListCSS.TableListItemTDExpanded}>
                <div className={TableListCSS.TableListPersonItemNameInfoCell}>
                    <span
                        className={TableListCSS.TableListPersonItemName}
                        id={`${idPrefix}_item_name_${id}`}
                        data-testid={`${idPrefix}_item_name_${id}`}
                        title={displayName}
                    >
                        {displayName}
                    </span>
                    <span
                        className={TableListCSS.TableListPersonItemInfo}
                        id={`${idPrefix}_item_details_${id}`}
                        data-testid={`${idPrefix}_item_details_${id}`}
                    >
                        {patientDetails}
                    </span>
                </div>
            </td>
            {children}
        </TableListItem>
    );
};
