import createReducer from '../createReducer'
import { NotificationState } from '../reducerTypes'

const initialState: NotificationState = {
    key: '',
    message: '',
    description: '',
    type: 'success',
    dismissed: true,
    options: {}
}

export default createReducer(initialState, {
    'SHOW_NOTIFICATION': (state: NotificationState, payload: NotificationState): NotificationState => {
        const { message, description, type } = payload
        return {
            ...state,
            dismissed: false,
            message,
            description,
            type
        }
    },
    'HIDE_NOTIFICATION': (state: NotificationState, payload: NotificationState): NotificationState => {
        return {
            ...state,
            dismissed: true
        }
    }
})
