import { makeStyles } from "@mui/styles";
import { SIZING} from "theme";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme : Theme) => ({
    header: {
        padding: `${SIZING.scale200} 0`,
        background: "#F0F0F2",
        boxShadow: "inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
    },
    flexWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `20px ${SIZING.scale500} 0px`
    },    
    close: {
        cursor: 'pointer'
    },
    modal: {
        minWidth: ({ modalWidth }:any) =>modalWidth? modalWidth:"900px",
        minHeight: "200px",
        background: "linear-gradient(0deg, #E6EAF1, #E6EAF1)",
        borderRadius: SIZING.scale800,
        [theme.breakpoints.down('sm')]: {
            minWidth: "600px",
            maxWidth: "90vw"
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: "420px"
        }
    },
    last: {
        backgroundColor: '#ededef'
    },
    content: {
        padding: '1em 1.3em 2.2rem 1.3em',
        backgroundColor: '#f6f8fa'
    },
    number: {
        cursor: "pointer", 
        color: "#2596be", 
        fontWeight: "bold"
    }
}))