import React from "react";
import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl"

import {EmptyContentMessageProps} from "./types";
import {useStyles} from "./styles";
import CustomTypography from "../CustomTypography/CustomTypography";

// EmptyContentMessage is used to show the user there is no content to be shown, such as lists of message threads or
// collections of forms. A custom Icon should be passed in that relates to the content that is missing, as well as a
// optional title and subtitle. The subtitle can also be replaced by children should there need to be content that can
// not be rendered using FormattedMessage
const EmptyContentMessage = ({Icon, title, iconColor, subtitle, children}: EmptyContentMessageProps) => {
    const classes = useStyles({iconColor})

    const subtitleContent = subtitle ? (
        <CustomTypography className={classes.subtitle} variant="contentSubtitle">
            <FormattedMessage id={subtitle}/>
        </CustomTypography>
    ) : null
    const content = children || subtitleContent

    return (
        <Box className={classes.contentWrapper}>
            <Box className={classes.content} >
                <Icon className={classes.icon}/>
                {title ? (
                    <CustomTypography className={classes.title} variant="contentTitle" id={"QA_"+title}>
                        <FormattedMessage id={title}/>
                    </CustomTypography>
                ) : null}
                {content}
            </Box>
        </Box>
    )
}

export default EmptyContentMessage