import React from "react";
import {useDispatch} from "react-redux";
import {Box, DialogContent} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {MODAL_TYPES} from "constant";
import {ReactComponent as Close} from "admin/assets/common/Close.svg";
import {setActiveModal} from "store/actions";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import Modal from "components/Modal/Modal";
import {useStyles} from "./styles";

const TOSModal: React.FC<any> = ({isViewed, updateIsViewed}) =>{
    const dispatch = useDispatch()
    const intl = useIntl()
    const classes = useStyles()
    const iframeTitle = intl.formatMessage({id: "TOSModal.content"})

    const onScrollFrame= (e:any) => {
        // do calulations only if user hasn't scrolled to bottom yet
        if (!isViewed) {
            const {clientHeight, scrollHeight, scrollTop} = e.target
            const bottom = scrollHeight - scrollTop;
            // rough bottom scroll precision due to responsive height changes or box corner radius
            if ( (bottom - clientHeight) < 10 ) {
                updateIsViewed(true)
            }
        }
    }

    const onClose = () => {
        dispatch(setActiveModal(''))
    }

    return (
        <Modal id={MODAL_TYPES.PATIENT.TOS_MODAL}>
            <Box className={classes.titleWrapper}>
                <CustomTypography variant="modalHeader">
                    <FormattedMessage id="TOSModal.title" />
                </CustomTypography>
                <Close className={classes.close} onClick={onClose} />
            </Box>
            <DialogContent className={classes.content} onScroll={onScrollFrame} id="dialogCon">
                <iframe className={classes.contentFrame}
                        src="/tos.html" scrolling="no"
                        title={iframeTitle} width="100%" height="100%" frameBorder="0"></iframe>
            </DialogContent>
        </Modal>
    )
};

export default TOSModal