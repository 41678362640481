import React, { Fragment } from "react";
import { useIntl } from 'react-intl';
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as UserSingleDisableIcon } from 'icon-library/SVG/User_Single-Disable.svg';
import { ReactComponent as KeyIcon } from 'icon-library/SVG/Key.svg';
import { ReactComponent as EnvelopeIcon } from 'icon-library/SVG/eMail_Closed-Envelope.svg';
import { ReactComponent as RadioCircleIcon } from 'icon-library/SVG/Radio_Circle.svg';
import { ReactComponent as PenIcon } from 'icon-library/SVG/Pen.svg';
import { GridContextMenuContentProps } from 'admin/components/GridContextMenuContent/types';
import { useStyles } from './styles';

const PatientGridContextMenu = ({ callback, handleClose, showActiveAccount, showDeActiveAccount, showResetPassword, showSendInvite }: GridContextMenuContentProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Fragment>
            {showSendInvite && <ListItem button>
                <ListItemIcon>
                    <EnvelopeIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.SendInvite" })} classes={{ primary: classes.listText }} onClick={() => { callback("SendInvite"); handleClose() }} />
            </ListItem>}
            <ListItem button>
                <ListItemIcon>
                    <PenIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liEditUser" primary={intl.formatMessage({ id: "BulkActionMenu.EditInfo" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditPatient"); handleClose() }} />
            </ListItem>
            {showResetPassword && <ListItem button>
                <ListItemIcon>
                    <KeyIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.ResetPassword" })} classes={{ primary: classes.listText }} onClick={() => { callback("ResetPassword"); handleClose() }} />
            </ListItem>}
            {showActiveAccount && <ListItem button>
                <ListItemIcon>
                    <RadioCircleIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.ActivateAccount" })} classes={{ primary: classes.listText }} onClick={() => { callback("Activate"); handleClose() }} />
            </ListItem>}
            {showDeActiveAccount && <ListItem button>
                <ListItemIcon>
                    <UserSingleDisableIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.DeactivateAccount" })} classes={{ primary: classes.listText }} onClick={() => { callback("Deactivate"); handleClose() }} />
            </ListItem>}
            {/* <ListItem button>
                <ListItemIcon>
                    <CrossCircleIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liDeleteUser" primary={intl.formatMessage({ id: "BulkActionMenu.DeletePatient" })} classes={{ primary: classes.listText }} onClick={() => { callback("DeletePatient"); handleClose() }} />
            </ListItem> */}
        </Fragment>
    );
}

export default PatientGridContextMenu;
