import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        borderRadius: '24px',
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.12), inset 0px 1px 1px rgba(0, 0, 0, 0.04)'
    },
    input: {
        flex: 1,
    },
    paper: {
        width: '100%',
        marginTop: '16px'
    },
    table: {
        minWidth: 750,
    },
    eVisits: {
        padding: '3px 0px',
        margin: '0px'
    },
    waitingRoomTitle: {
        padding: '20px 0px',
        margin: '0px'
    },
    card: {
        width: '210px',
        minHeight: '300px',
        left: '0px',
        top: '0px',
        background: '#FFFFFF',
        boxShadow: '0.554187px 0px 4.4335px rgba(0, 0, 0, 0.05), inset -0.554187px 0px 0px rgba(0, 0, 0, 0.05)'
    },
    cardContent: {
        padding: '15px',
        textAlign: "center",
        width: "100%"
    },
    content: {
        width: '180px',
        minHeight: '84px',
        background: '#E8F5FE',
        border: '1px dashed #0B5D8F',
        boxSizing: 'border-box',
        borderRadius: '4px',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '84px'
    },
    logoContent: {
        width: '180px',
        minHeight: '84px',
        background: '#E8F5FE',
        border: '1px dashed #0B5D8F',
        boxSizing: 'border-box',
        borderRadius: '4px',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    evisitsStartingContent: {
        width: '180px',
        minHeight: '84px',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '84px'
    },
    textAlignRight: {
        textAlign: 'right'
    },
    bigIcon: {
        height: '50px',
        width: '50px',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '85px'
    },
    infoIcon: {
        width: '17px',
        height: '17px',
        cursor: 'pointer'
    },
    toggleSwitchText: {
        fontWeight: 600,
        fontSize: '20px'
    },
    infoText: {
        fontWeight: 600,
        fontSize: '14px',
        color: '#393948'
    },
    infoTooltipText: {
        fontSize: '14px',
        textAlign: 'left'
    },
    flagIcon: {
        height: '16px',
        width: '16px',
        marginRight: '7px'
    },
    infoMessage: {
        fontStyle: 'italic',
        fontSize: '14px',
        color: '#AB201F'
    }
}));