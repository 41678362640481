import React from "react";
import { Card, CardContent, CardActions, Typography, List, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { ConfigurationTileProps } from "./types";
import { useDispatch } from "react-redux";
import { setActiveModal, setNavBreadcrumbText } from "admin/store/actions";
import { showNotification } from "admin/store/actions/snackbar";
import { createSvgIcon } from "utils";
import { ReactComponent as ChevronRight } from "icon-library/SVG/Chevron-Light_Right-Circle.svg";
import { COLORS } from "admin/theme";
import { ReactComponent as FlagSvg } from "icon-library/SVG/Flag.svg";
const FlagIcon = createSvgIcon(FlagSvg);

const ConfigurationTile = ({ name, content, link, linkText, info }: ConfigurationTileProps) => {
    const classes = useStyles();
    const ChevronRightIcon = createSvgIcon(ChevronRight);
    const dispatch = useDispatch();

    const handleFormNameClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, fullName: any, link: any) => {
        if (!link) {
            dispatch(showNotification("TODO", "error", "Not Implemented"))
        }
        dispatch(setActiveModal(""))
        dispatch(setNavBreadcrumbText(fullName))
    };

    return (
        <Card className={classes.configurationTileContainer}>
            <CardContent className={classes.configurationTileContent}>
                <Typography noWrap variant={"h2"} className={classes.sectionHeading}>{name}</Typography>
                {info && <Typography noWrap variant={"h2"} className={classes.infoText}><FlagIcon fill={"#FF0000"} className={classes.flagIcon} />{info}</Typography>}
                <List className={classes.ulList}>
                    {content.map((contentItem: any, index: number) => (
                        <ListItem key={index} className={classes.liStyle}>
                            {contentItem}
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <CardActions>
                <Link to={link} className={classes.linkTypo} onClick={(e) => handleFormNameClick(e, name, link)}>
                    <Typography noWrap variant="body1">{linkText}</Typography>
                    <ChevronRightIcon fill={COLORS.LINK_TEXT}></ChevronRightIcon>
                </Link>
            </CardActions>
        </Card>
    )
}

export default ConfigurationTile