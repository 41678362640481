import { deepCopy } from "common/utils/object-utils";
import {
    IApiPromise,
    ApiRequest,
    ApiCommunicationContext,
    ApiChainFunction,
    ApiStatusCode,
    DEFAULT_API_TIMEOUT,
} from "./types";
import { Service } from "lib/types";
import { isAsyncTimeoutError, withAsyncTimeout } from "common/utils/use-api-utils";
import abortOnNewRequest from "./chain-functions/abort-on-new-request";
import ApiPromise from "./api-promise";
import appendAuthHeader from "./chain-functions/append-auth-header";
import appendDomain from "./chain-functions/append-domain";
import prepareUrlParams from "./chain-functions/prepare-url-params";
import fetchViaBrowser from "./chain-functions/fetch-via-browser";
import transformResponse from "./chain-functions/transform-response";

export interface IApiService extends Service<undefined> {
    request<TRequest, TResponse, TContent = TResponse>(
        req: ApiRequest<TRequest, TResponse, TContent>
    ): IApiPromise<TContent>;
}

const runApiChain = <TRequest, TResponse, TContent>(
    chainSteps: ApiChainFunction<TRequest, TResponse, TContent>[],
    initialCtx: ApiCommunicationContext<TRequest, TResponse, TContent>,
    relatedPromise: ApiPromise<TContent>
): Promise<ApiCommunicationContext<TRequest, TResponse, TContent>> => {
    let lastKnownStatus = ApiStatusCode.NOT_SENT; // Default is not sent
    return chainSteps.reduce(async (nextStepCtxPromise, nextStep) => {
        const nextStepCtx = await nextStepCtxPromise;
        const ctx =
            relatedPromise.aborted || nextStepCtx.status === ApiStatusCode.ABORTED
                ? {
                      ...nextStepCtx,
                      status: ApiStatusCode.ABORTED,
                      response: {
                          ...nextStepCtx.response,
                          error: nextStepCtx.response?.error ?? "Aborted",
                      },
                  }
                : await nextStep(nextStepCtx);

        if (lastKnownStatus !== ctx.status) {
            lastKnownStatus = ctx.status;
            relatedPromise.updateFetchStatus(lastKnownStatus);
        }

        return deepCopy(ctx);
    }, Promise.resolve(initialCtx));
};

class ApiService implements IApiService {
    private _apiChain: ApiChainFunction<any, any, any>[] = [
        abortOnNewRequest, // At initialization
        appendDomain,
        appendAuthHeader,
        prepareUrlParams,
        fetchViaBrowser,
        transformResponse,
        abortOnNewRequest, // Closing cleanup
    ];
    constructor(apiChain?: ApiChainFunction<any, any, any>[]) {
        if (apiChain) {
            this._apiChain = apiChain;
        }
    }
    initialize() {
        return Promise.resolve();
    }
    request<TRequest, TResponse, TContent = TResponse>(
        req: ApiRequest<TRequest, TResponse, TContent>
    ): IApiPromise<TContent> {
        let abortListenerFn;
        const apiCommunicationContext: ApiCommunicationContext<TRequest, TResponse, TContent> = {
            abort: null,
            request: req,
            response: null,
            registerAbortListener: (listener: () => void) => {
                abortListenerFn = listener;
            },
            status: ApiStatusCode.NOT_SENT,
            storage: {},
            updateFetchStatus: null,
        };

        const apiPromise = new ApiPromise<TContent>();
        apiCommunicationContext.abort = apiPromise.abort;
        apiCommunicationContext.updateFetchStatus = apiPromise.updateFetchStatus;
        apiPromise.initPromise(
            withAsyncTimeout<TContent>(
                new Promise(async (resolve, reject) => {
                    const finalCtx = await runApiChain(this._apiChain, apiCommunicationContext, apiPromise);
                    if ([ApiStatusCode.CREATED, ApiStatusCode.NO_CONTENT, ApiStatusCode.OK].includes(finalCtx.status)) {
                        resolve(finalCtx.response?.content as TContent);
                    } else {
                        reject(finalCtx.response?.content ?? finalCtx.response?.error);
                    }
                }),
                req.timeout ?? DEFAULT_API_TIMEOUT
            ).catch((error) => {
                if (isAsyncTimeoutError(error)) {
                    apiPromise.updateFetchStatus(ApiStatusCode.CLIENT_TIMEOUT);
                }
                throw error;
            }),
            abortListenerFn
        );
        return apiPromise;
    }
}

export default ApiService;
