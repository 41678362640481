import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Typography, DialogContent, Grid } from "@mui/material";
import { useStyles } from "./styles";
import VisitTypesListing from "../../VisitTypes/VisitTypesListing";
import { ReactComponent as CirclesHexagonGrid } from "icon-library/SVG/Circles_Hexagon-Grid.svg";
import { createSvgIcon } from "utils";
const CirclesHexagonGridIcon = createSvgIcon(CirclesHexagonGrid);

const EvisitTypes = forwardRef((props, ref) => {
    const intl = useIntl();
    const classes = useStyles();
    const addVisitTypeFormRef = useRef<any>();

    useImperativeHandle(
        ref,
        () => ({
            saveAction(e: any) {
                return addVisitTypeFormRef.current.submitForm(e);
            },
            saveAndContinueLaterAction(index: number) {
                return addVisitTypeFormRef.current.saveAndContinueLater(index);
            }
        }),
    )

    return (<>
        <Grid item className={classes.productsPageHeader}>
            <Typography className={classes.pageHeaderText}>
                <CirclesHexagonGridIcon fill="#0F7BBD" className={classes.productsIcon} />
                <FormattedMessage id="Practice.Configuration.Products.Heading" />
            </Typography>
            <Typography className={classes.pageSubHeaderText}>
                <FormattedMessage id={"Practice.Configuration.Products.SubHeading"} />
            </Typography>
        </Grid>
        <DialogContent className={classes.contentDialog}>
            <Box mb={1} className={classes.formGpHeader}>
                {intl.formatMessage({ id: "VisitTypes.Text.VisitTypes" })}
            </Box>
            <Box mb={1} className={classes.formGpSubHeader}>
                {intl.formatMessage({ id: "VisitTypes.Text.VisitTypeId" })}
            </Box>
            <VisitTypesListing ref={addVisitTypeFormRef} />
        </DialogContent>
    </>);
})

export default EvisitTypes