import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { showNotification } from './snackbar'
import { AppReduxStore } from 'admin/store/reducerTypes'
import { getChildOrganizationSummaries } from './organization'
import { makeApiCall } from 'admin/common/utils'
// import { call } from "admin/store/api";

export const getAllProducts = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        //Get  All Products
        return makeApiCall(dispatch, "GET", `/admin/v1/license/products`).then((payload) => {
            dispatch({ type: 'SET_ALL_PRODUCTS', payload: { allProducts: payload } })
        }).catch(err => {
            dispatch({ type: 'SET_ALL_PRODUCTS', payload: { allProducts: [] } })
            dispatch(showNotification("Get All Products", "error", String(err && err.message)))
        });
    }
}

export const getProductsForOrganization = (organizationId: string, isSelectedOrganization?: boolean) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        if (isSelectedOrganization) {
            dispatch({ type: 'SET_SELECTED_ORGANIZATION_LICENSES', payload: { sOrgLicenses: null } })
        } else {
            dispatch({ type: 'SET_ORGANIZATION_LICENSES', payload: { orgLicenses: null } })
        }
        return makeApiCall(dispatch, "GET", "/admin/v1/license/products/organization/" + organizationId)
            .then((payload) => {
                if (isSelectedOrganization) {
                    dispatch({ type: 'SET_SELECTED_ORGANIZATION_LICENSES', payload: { sOrgLicenses: payload } })
                }
                else {
                    dispatch({ type: 'SET_ORGANIZATION_LICENSES', payload: { orgLicenses: payload } })
                }

            })
            .catch((err) => {
                dispatch(showNotification("Get Organization Products", "error", err.message))
            })
    }
}
export const getProductsForPractice = (practiceId: string, isSelectedPratice?: boolean) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        if (isSelectedPratice) {
            dispatch({ type: 'SET_SELECTED_PRACTICE_LICENSES', payload: { sPracticeLicenses: null } })
        }
        else {
            dispatch({ type: 'SET_PRACTICE_LICENSES', payload: { practiceLicenses: null } })
        }
        return await makeApiCall(dispatch, "GET", "/admin/v1/license/products/practice/" + practiceId)
            .then((payload) => {
                if (isSelectedPratice) {
                    dispatch({ type: 'SET_SELECTED_PRACTICE_LICENSES', payload: { sPracticeLicenses: payload } })
                }
                else {
                    dispatch({ type: 'SET_PRACTICE_LICENSES', payload: { practiceLicenses: payload } })
                }
            })
            .catch((err) => {
                dispatch(showNotification("Get Practice Products", "error", err.message))
            })
    }
}
export const setProductsForUser = (userLicenses: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'SET_USER_LICENSES', payload: { userLicenses } })
    }
}
export const getProfileRoleJoinProduct = (productId: string, profileRoleJoinId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return makeApiCall(dispatch, "GET", "/admin/v1/license/product/profileRoleJoinProduct", { productId, profileRoleJoinId })
            .then((payload) => {
                dispatch({ type: 'SET_SELECTED_USER_LICENSE', payload: { sUserLicense: payload } })
            })
            .catch((err) => {
                dispatch(showNotification("Get Practice Products", "error", err.message))
            })
    }
}
export const getProductsForOrganizationCount = (organizationId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return makeApiCall(dispatch, "GET", "/admin/v1/license/products/count/organization/" + organizationId)
            .then((payload) => {
                dispatch({ type: 'SET_ORGANIZATION_LICENSES_COUNT', payload: { orgLicensesCount: payload } })
            })
            .catch((err) => {
                dispatch(showNotification("Get Organization Products Count", "error", err.message))
            })
    }
}

export const getProductsForPracticeCount = (practiceId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return makeApiCall(dispatch, "GET", "/admin/v1/license/products/count/practice/" + practiceId)
            .then((payload) => {
                dispatch({ type: 'SET_PRACTICE_LICENSES_COUNT', payload: { practiceLicensesCount: payload } })
            })
            .catch((err) => {
                dispatch(showNotification("Get Practice Products Count", "error", err.message))
            })
    }
}

export const getOrganizationProductDetails = (productId: string, organizationId?: string, practiceId?: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return makeApiCall(dispatch, "GET", "/admin/v1/license/product/organization", { productId, organizationId, practiceId })
            .then((payload) => {
                dispatch({ type: 'SET_ORGANIZATION_LICENSE_DETAILS', payload: { orgLicenseDetail: payload } })
            })
            .catch((err) => {
                dispatch(showNotification("Get Organization Products Count", "error", err.message))
            })
    }
}

export const getPracticeProductDetails = (productId: string, organizationId?: string, practiceId?: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return makeApiCall(dispatch, "GET", "/admin/v1/license/product/practice", { productId, organizationId, practiceId })
            .then((payload) => {
                dispatch({ type: 'SET_PRACTICE_LICENSE_DEATILS', payload: { practiceLicenseDetail: payload } })
            })
            .catch((err) => {
                dispatch(showNotification("Get Practice Products Count", "error", err.message))
            })
    }
}

/*
export const getAvailableFeeScheduleGroups = (orgId?: string, profileRoleJoinId?: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { auth } = getState();
        const prjId = profileRoleJoinId || auth.currentProfileRoleJoinInfo?.id;
        return makeApiCall(dispatch, "GET", `/admin/v1/billing/group/available/profilerolejoin/${prjId}`)
            .then((payload) => {
                dispatch({ type: 'SET_AVAILABLE_FEE_SCHEDULE_GROUPS', payload: { availableFeeScheduleGroups: payload[`${orgId}`] } });
            })
            .catch((err) => {
                dispatch(showNotification("Get available Fee Schedule Groups", "error", err.message));
            })
    }
}

export const getFeeScheduleGroups = (practiceId?: string, profileRoleJoinId?: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { auth } = getState();
        const prjId = profileRoleJoinId || auth.currentProfileRoleJoinInfo?.id;
        const optionalHeaders: {} | undefined = practiceId ? { "x-application-practiceid": practiceId } : undefined;
        return call("GET", `/admin/v1/billing/group/profilerolejoin/${prjId}`, {}, optionalHeaders)
            // return makeApiCall(dispatch, "GET", `/admin/v1/billing/group/profilerolejoin/${profileRoleJoinId}`)
            .then((payload) => {
                dispatch({ type: 'SET_FEE_SCHEDULE_GROUPS', payload: { feeScheduleGroups: payload } });
            })
            .catch((err) => {
                dispatch(showNotification("Get Fee Schedule Groups", "error", err.message));
            })
    }
}

export const setFeeScheduleGroups = (billingGroupIds: any[], id?: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
        const { auth } = getState();
        const profileRoleJoinId = id || auth.currentProfileRoleJoinInfo?.id;
        const request = {
            "billingGroupIds": billingGroupIds,
            "profileRoleJoinId": profileRoleJoinId
        }
        return makeApiCall(dispatch, "POST", `/admin/v1/billing/group/profilerolejoin`, request)
            .then((payload) => {
                dispatch(showNotification("Fee Schedule Groups updated successfully.", "success"));
            })
            .catch((err) => {
                dispatch(showNotification("Error", "error", err.message))
            })
    }
}

export const deleteFeeScheduleGroups = (prjBillingGroupId: string[]) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        await prjBillingGroupId.forEach(async (prjBillingGroupId) => {
            return await makeApiCall(dispatch, "DELETE", `/admin/v1/billing/group/profileRoleJoin/${prjBillingGroupId}`)
                .then((payload) => {
                    dispatch(showNotification("Fee Schedule Group deleted successfully.", "success"));
                })
                .catch((err) => {
                    dispatch(showNotification("Delete Fee Schedule Group", "error", err.message));
                })
        });
    }
}
*/

type OrganizationProductLicense = {
    newTotalLicenseCount: number
    organizationId: string
    organizationProductId: string
    productId: string
}
export const upsertOrganizationProductLicense = (OrganizationProductLicenses: OrganizationProductLicense[]) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
        const { auth } = getSate()
        let error = false
        const sOrganizationId = OrganizationProductLicenses[0].organizationId
        await makeApiCall(dispatch, "POST", "/admin/v1/license/products/organization", OrganizationProductLicenses)
            .then((payload) => {
            })
            .catch((err) => {
                error = true
                dispatch(showNotification("Error", "error", err.message))
            })
        if (!error) {
            dispatch(showNotification(`Organization Product updated successfully.`, "success"))
        }
        dispatch(getProductsForOrganization(sOrganizationId, true))
        dispatch(getChildOrganizationSummaries(auth.currentOrg?.id));
        dispatch(getProductsForOrganization(auth.currentOrg?.id))
    }
}
export const deleteOrganizationProductLicense = (organizationProductId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return await makeApiCall(dispatch, "DELETE", "/admin/v1/license/product/organization/" + organizationProductId)
            .then((payload) => {
                dispatch(showNotification(payload, "success"))
            })
            .catch((err) => {
                dispatch(showNotification("Error", "error", err.message))
            })
    }
}
type PracticeProductLicense = {
    newTotalLicenseCount: number
    practiceId: string
    practiceProductId: string
    productId: string
}
export const upsertPracticeProductLicense = (practiceLicenses: PracticeProductLicense[]) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>, getSate: () => AppReduxStore) => {
        const { auth } = getSate()
        let error = false
        const sPracticeId = practiceLicenses[0]?.practiceId
        await makeApiCall(dispatch, "POST", "/admin/v1/license/products/practice", practiceLicenses)
            .then((payload) => {
            })
            .catch((err) => {
                error = true
                dispatch(showNotification("Error", "error", err.message))
            })
        if (!error) {
            dispatch(showNotification(`Practice Product updated successfully.`, "success"))
        }
        dispatch(getProductsForPractice(sPracticeId, true))
        dispatch(getChildOrganizationSummaries(auth.currentOrg?.id));
        dispatch(getProductsForOrganization(auth.currentOrg?.id))
    }
}
export const deletePracticeProductLicense = (practiceProductIds: string[]) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        for (let i = 0; i < practiceProductIds.length; i++) {
            await makeApiCall(dispatch, "DELETE", "/admin/v1/license/product/practice-product/" + practiceProductIds[i])
                .then((payload) => {
                    dispatch(showNotification(payload, "success"))
                })
                .catch((err) => {
                    dispatch(showNotification("Error", "error", err?.message))
                })
        }
    }
}
export const upsertProfileRoleJoinProductLicense = (productId: string, profileRoleJoinId: string, onUpsertUserProducts?: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
        const { admin, auth } = getState();
        const { isBulkEditUserProducts } = admin;
        return makeApiCall(dispatch, "POST", "/admin/v1/license/product/profileRoleJoin", { productId, profileRoleJoinId })
            .then((payload) => {
                dispatch(showNotification("User Product updated successfully.", "success"))
                if (auth.currentOrg?.id) {
                    dispatch(getProductsForOrganization(auth.currentOrg?.id))
                }
                if (auth.currentPractice?.id && !isBulkEditUserProducts) {
                    dispatch(getProductsForPractice(auth.currentPractice?.id))
                }
                if (typeof onUpsertUserProducts === 'function') {
                    onUpsertUserProducts();
                }
            })
            .catch((err) => {
                dispatch(showNotification("Error", "error", err.message))
            })

    }
}

export const deleteProfileRoleJoinProductLicense = (productId: string, profileRoleJoinId: string, onDeleteUserProducts?: any) => {

    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        const profileRoleJoinProductLicense = await makeApiCall(dispatch, "GET", "/admin/v1/license/product/profileRoleJoinProduct",
            { productId, profileRoleJoinId })
            .catch(err => {
                dispatch(showNotification("Error", "error", err.message))
            });
        return makeApiCall(dispatch, "DELETE", "/admin/v1/license/product/profileRoleJoin-product/" + profileRoleJoinProductLicense?.id)
            .then((payload) => {
                if (typeof onDeleteUserProducts === 'function') {
                    onDeleteUserProducts();
                }
                dispatch(showNotification(payload, "success"))
            })
            .catch((err) => {
                //dispatch(showNotification("Error", "error", err.message))
            })

    }
}
