import { AppReduxStore } from "store/reducerTypes";
import { Button, DialogContent, Grid } from "@mui/material";
import { call } from "store/api";
import { EM_DASH } from "constant";
import { FormattedMessage, useIntl } from "react-intl";
import { getAppointments, setActiveModal } from "store/actions";
import { FormStatus, HpiFormsApiGetResponse } from "./types";
import { PatientDetails } from "store/patient-types";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useDateFormatters } from "../../../common/utils/use-date-formatter";
import { useStyles } from "./styles";
import { useUserNotification } from "common/utils/use-user-notification";
import classNames from "classnames";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import InputWithTranscription from "common/components/InputWithTranscription/InputWithTranscription";
import Spinner from "common/components/Spinner/Spinner";
import DialogModal from "components/Modals/dialog-modal/dialog-modal";

type DisplayMode = "edit" | "view";
type FormMeta = {
    [key: string]: unknown;
    lastModifiedBy: string | null;
    lastModifiedDate: string | null; // ISO8601 date
    loadedHpiFormId: string | null; // UUID
    selectedHpiFormId: string | null; // UUID
    status: FormStatus;
    version: number | null;
}
type FormContent = Record<string, string | null>;
type FormData = {
    content: FormContent;
    metainfo: FormMeta;
}
export type HpiFormProps = {
    visitId: string,
    hpiFormId?: string
}

const FORM_CONTENT_FIELDS = ["location", "quality", "severity", "duration", "context", "associatedSymptoms", "notes"];
const FORM_META_FIELDS = ["status", "lastModifiedDate", "version"];
const DEFAULT_FORM_DATA: FormData = {
    content: {
        location: "",
        quality: "",
        severity: "",
        duration: "",
        context: "",
        associatedSymptoms: "",
        notes: ""
    },
    metainfo: {
        selectedHpiFormId: null, // ID of the HPI form which is selected when form is opened
        loadedHpiFormId: null, // ID of the HPI form which is loaded into the open form
        status: "DRAFT",
        lastModifiedBy: null,
        lastModifiedDate: "",
        version: 0
    }
};

const HpiForm = () => {
    const classes = useStyles();
    const dateFormatters = useDateFormatters();
    const dispatch = useDispatch();
    const { enqueueError, enqueueSuccess } = useUserNotification();
    const intl = useIntl();
    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    const [loading, setLoading] = useState<boolean>(true);
    const [editable, setEditable] = useState<boolean>(false);
    const [showSubmitDialog, setShowSubmitDialog] = useState<boolean>(false);
    const [mode, setMode] = useState<DisplayMode>("view");
    const { hpiFormId, visitId, selectedPatient } = useSelector((state: AppReduxStore) => {
        const modalProps = (state.modal.activeModalProps as HpiFormProps);
        return {
            hpiFormId: modalProps?.hpiFormId ?? null,
            visitId: modalProps?.visitId,
            selectedPatient: state.patient?.selectedPatient || {} as PatientDetails
        }
    });
    const { lastName, firstName, age, genderCode, mrn } = selectedPatient;

    useEffect(() => {
        if (hpiFormId !== formData.metainfo.selectedHpiFormId) {
            setFormData(DEFAULT_FORM_DATA);
            if (hpiFormId) {
                call('GET', `/patient/v1/hpi-forms/${hpiFormId}`)
                    .then((response: HpiFormsApiGetResponse) => {
                        setFormData((prevFormData) => ({
                            content: {
                                ...prevFormData.content,
                                ...(FORM_CONTENT_FIELDS.reduce<FormContent>((newFormContent, key) => {
                                    newFormContent[key] = response[key] as string;
                                    return newFormContent;
                                }, {}))
                            },
                            metainfo: {
                                ...prevFormData.metainfo,
                                loadedHpiFormId: hpiFormId,
                                selectedHpiFormId: hpiFormId,
                                ...(FORM_META_FIELDS.reduce<Partial<FormMeta>>((newFormContent, key) => {
                                    newFormContent[key] = response[key];
                                    return newFormContent;
                                }, {})),
                                lastModifiedBy: response.lastModifiedBy?.name
                            }
                        }));
                        setEditable(response.status !== "SENT_TO_EMR");
                        setLoading(false);
                    })
                    .catch(() => {
                        setFormData({
                            content: { ...DEFAULT_FORM_DATA.content },
                            metainfo: {
                                ...DEFAULT_FORM_DATA.metainfo,
                                selectedHpiFormId: hpiFormId
                            }
                        });
                        setEditable(false);
                        setLoading(false);
                    });
            } else {
                setFormData({
                    content: { ...DEFAULT_FORM_DATA.content },
                    metainfo: {
                        ...DEFAULT_FORM_DATA.metainfo,
                        selectedHpiFormId: hpiFormId
                    }
                });
                setEditable(true);
                setLoading(false);
            }
        }
        if (hpiFormId === '') {
            setMode('edit');
            setLoading(false);
        }
    }, [hpiFormId, formData]);

    const onClose = () => {
        dispatch(setActiveModal(""));
    };

    const validateFormContent = (formContent: FormData): boolean => {
        const valid = FORM_CONTENT_FIELDS.some((fieldName) => (formContent.content[fieldName] ?? '').trim().length > 0);
        if (!valid) {
            enqueueError("HpiForm.fieldValidationFailed");
        }
        return valid;
    }

    const sendFormContent = (asDraft: boolean = false): Promise<void> => {
        if (!validateFormContent(formData)) {
            return Promise.reject();
        }
        const data = {
            visitId,
            ...(FORM_CONTENT_FIELDS.reduce<Record<string, any>>((newContent, fieldName) => {
                newContent[fieldName] = formData.content[fieldName];
                return newContent;
            }, {})),
            // Hardcoded, there are no fields for that
            onset: "",
            // Hardcoded, there are no fields for that
            alleviatingFactors: "",
            // Hardcoded, there are no fields for that
            aggravatingFactors: "",
            status: asDraft ? "DRAFT" : "SENT_TO_EMR",
            version: formData.metainfo.version
        };
        const requestConfig = formData.metainfo.loadedHpiFormId
            ? {
                isUpdate: true,
                endpoint: `/patient/v1/hpi-forms/${hpiFormId}`,
                method: 'PUT'
            }
            : {
                endpoint: `/patient/v1/hpi-forms`,
                method: 'POST'
            };
        return call(requestConfig.method, requestConfig.endpoint, data)
            .then(() => {
                /* Reload appointments only if HPI Form is created
                 * (reload not needed if already existing form is edited)
                 */
                if (!requestConfig.isUpdate) {
                    dispatch(getAppointments());
                }
                enqueueSuccess("HpiForm.savingSuccess");
            })
            .catch(() => {
                const message = intl.formatMessage({ id: "HpiForm.savingFailed" });
                enqueueError("HpiForm.savingFailed");
                throw new Error(message);
            });
    }
    const onEdit = () => {
        if (editable) {
            setMode("edit");
        }
    }
    const onSaveAndPush = () => {
        if (editable) {
            sendFormContent(false)
                .then(onClose)
                .catch(() => {
                    enqueueError("HpiForm.savingFailed");
                });
        }
    }
    const onSaveDraft = () => {
        if (editable) {
            sendFormContent(true)
                .then(onClose)
                .catch(() => {
                    enqueueError("HpiForm.savingFailed");
                });
        }
    }

    const createContentItem = ({name, xSize = 12}: { name: string, xSize?: number}): JSX.Element => {
        const specialClass = classNames((classes as any)[name], 'hpiForm__contentItem');
        return (
            <Grid item xs={xSize as any} className={specialClass}>
                {mode === "edit" && <InputWithTranscription id={`QA_hpi_${name}_field`}
                    label={intl.formatMessage({id: `HpiForm.${name}`})}
                    maxLength={4096}
                    multiline={name === 'notes'}
                    onChange={(content) => {
                        setFormData((prevFormData) => ({
                            content: {
                                ...prevFormData.content,
                                [name]: content
                            },
                            metainfo: prevFormData.metainfo
                        }));
                    }}
                    value={formData.content[name] as string}
                />}
                {mode === "view" && <div id={`QA_hpi_${name}_field`}>
                    <span className="hpiForm__contentItem__label">{intl.formatMessage({id: `HpiForm.${name}`})}</span>
                    <span className="hpiForm__contentItem__text">{formData.content[name] as string || EM_DASH}</span>
                </div>}
            </Grid>
        );
    }

    const createEditButton = () => (
        <>
            {editable && <Button id="QA_hpi_edit_button"
                data-testid="QA_hpi_edit_button"
                onClick={onEdit}
                variant="contained"
                color="inherit"
            >
                {intl.formatMessage({id:'HpiForm.edit'})}
            </Button>}
        </>
    );
    const createSaveButtons = () => (
        <>
            <Button id="QA_hpi_save_push_button"
                data-testid="QA_hpi_save_push_button"
                variant="contained"
                color="inherit"
                onClick={() => {
                    if (validateFormContent(formData)) {
                        setShowSubmitDialog(true);
                    }
                }}
            >
                {intl.formatMessage({id:'HpiForm.saveAndPush'})}
            </Button>
            <Button id="QA_hpi_save_draft_button"
                data-testid="QA_hpi_save_draft_button"
                className={classes.saveDraft}
                variant="contained"
                color="inherit"
                onClick={onSaveDraft}
            >
                {intl.formatMessage({id:'HpiForm.saveDraft'})}
            </Button>
        </>
    );

    const showConfirmationDialog = () => (
        <DialogModal
            idPrefix="QA_hpi_save_and_push_dialog"
            title={intl.formatMessage({ id: "HpiForm.submitDialog.title" })}
            className={classes.hpiSaveDialog}
            primaryAction={{
                label: intl.formatMessage({ id: "HpiForm.submitDialog.save" }),
                onClick: () => {
                    setShowSubmitDialog(false);
                    onSaveAndPush();
                }
            }}
            secondaryAction={{
                label: intl.formatMessage({ id: "HpiForm.submitDialog.close" }),
                onClick: () => setShowSubmitDialog(false)
            }}>
            <span className="body">
                <FormattedMessage id="HpiForm.submitDialog.body" values={{br: <br />}} />
            </span>
        </DialogModal>
    );

    if (!visitId || !selectedPatient.mrn) {
        return null;
    }

    return (
        <>
            <div className={classes.hpiForm__header} data-form-id={hpiFormId}>
                <div className="headerCell">
                    <CustomTypography variant="modalHeader">
                        <FormattedMessage id="HpiForm.title" />
                    </CustomTypography>
                    <div className="patientDetails">
                        <span className="fullName">{lastName}, {firstName}</span>
                        <span className="details">({age} {genderCode})</span>
                        <span>{mrn}</span>
                    </div>
                </div>
                <div className="headerCell">
                    <Close id="QA_learning_center_close_button" className={classes.close} onClick={onClose} />
                </div>
                <div className="headerCell statusDetails">
                    <span className={classNames("pushedState", {
                        "pushedState--single": !formData.metainfo.lastModifiedBy && !formData.metainfo.lastModifiedDate
                    })}>
                        {!loading && (editable ? <FormattedMessage id="HpiForm.notPushed" /> : <FormattedMessage id="HpiForm.pushed" />)}
                    </span>
                    {formData.metainfo.lastModifiedBy && <span className="lastModifiedBy" title={formData.metainfo.lastModifiedBy}><FormattedMessage id="HpiForm.editor" />&nbsp;{formData.metainfo.lastModifiedBy}</span>}
                    {formData.metainfo.lastModifiedDate && <span id="hpiForm__lastModifiedDate" className="lastModifiedDate" title={dateFormatters["MMM DD, YYYY, hh:mm aa"](formData.metainfo.lastModifiedDate)}>({dateFormatters["nicetime"](formData.metainfo.lastModifiedDate)})</span>}
                </div>
                <div className="headerCell buttonBar">
                    {mode === "view" ? createEditButton() : createSaveButtons()}
                </div>
            </div>
            <DialogContent className={classNames(classes.hpiForm__content, {
                'hpiForm__content--readonly': mode === 'view',
                'hpiForm__content--loading': loading
            })}>
                {loading ? <Spinner /> : (
                        <Grid container spacing={3} className="hpiForm__content__container">
                            { createContentItem({ name: 'location' }) }
                            { createContentItem({ name: 'quality' }) }
                            { createContentItem({ name: 'severity', xSize: 6 }) }
                            { createContentItem({ name: 'duration', xSize: 6 }) }
                            { createContentItem({ name: 'context' }) }
                            { createContentItem({ name: 'associatedSymptoms' }) }
                            { createContentItem({ name: 'notes' }) }
                        </Grid>
                )}
            </DialogContent>
            {showSubmitDialog && showConfirmationDialog()}
        </>
    );
}

export default HpiForm;
