import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => {
    return {
        rootWrapper:{
            background: COLORS.GRAY_2,
            width:'460px',
            height:'100%',
            borderRadius:'0 1.25rem 1.25rem 0',
            display:'flex',
            flexDirection:'column'
        },
        patientList:{
            width:'100%',
            padding:`${SIZING.scale200} ${SIZING.scale400}`,
            flex:1,
            boxShadow:'inset -1px 0px 0px rgba(0, 0, 0, 0.1)',
        }
    }
})
