/** This registry is designed to avoid specific code leaking in the module.
 *  If a message has specific features or content, then it should be displayed
 *  on a specific way. This kind of views can be added to the messages without
 *  messaging should know them directly.
 * 
 *  This follows the _inverse dependency direction_ principle to decouple shared
 *  and specific modules.
 */
import { ConversationInfoItem } from "store/chat-types";
import { Message } from "@twilio/conversations";
import { MessageView, MessageViewProvider } from "./types";
import DefaultMessageView from "./chat-history/default-message-view";

const viewProviders: Map<string, MessageViewProvider> = new Map<string, MessageViewProvider>();
const DEFAULT_VIEW_PROVIDER: MessageViewProvider = {
    id: "default",
    applyCheckerFunction: () => true,
    view: DefaultMessageView,
};

export const addMessageViewProvider = (viewProvider: MessageViewProvider): void => {
    if (!viewProviders.has(viewProvider.id)) {
        viewProviders.set(viewProvider.id, viewProvider);
    }
};

export const removeMessageViewProvider = (id: string): void => {
    viewProviders.delete(id);
};

export const searchMessageView = (message: Message, conversationInfo: ConversationInfoItem): MessageView => {
    const foundViewProvider =
        Array.from(viewProviders.values()).find((viewProvider) =>
            viewProvider.applyCheckerFunction(message, conversationInfo)
        ) ?? DEFAULT_VIEW_PROVIDER;
    return foundViewProvider.view;
};
