import React from "react";
import { AppReduxStore } from "store/reducerTypes";
import { Button, Typography } from "@mui/material";
import { FeatureFlagNames } from "lib/security/types";
import { FormattedMessage } from "react-intl";
import { MODAL_TYPES } from "constant";
import { Permissions } from "types";
import { Phone, MicrophoneWithoutOpacity } from 'assets';
import { setActiveModal } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useFeatureFlag } from "lib/security/feature-flag-hooks";
import { usePermission } from "lib/security/permission-hooks";
import { useStyles } from "./styles";

// @ts-ignore
const { SUKI_URL } = window.env

const ActionButtons = ({ handleCallback }: { handleCallback: () => void }) => {
    const dispatch = useDispatch();

    const { profile, currentEvisitDetails } = useSelector((state: AppReduxStore) => state.meeting);
    const ehrPatientId = currentEvisitDetails?.ehrPatientId ?? profile?.ehrPatientId;
    const visitId = currentEvisitDetails?.visitKey;

    const eTranscriptionPermission = usePermission(Permissions.ETRANSCRIPT);
    const hpiFormFeatureFlag = useFeatureFlag(FeatureFlagNames.HPI_FORM);
    const isETranscriptionAccessible = !!visitId;
    const isSukiAccessible = !!ehrPatientId;

    const classes = useStyles({ isEvisit:!!currentEvisitDetails  });

    const handleSuki = () => {
        ehrPatientId && window.open(`${SUKI_URL}&athena_patient_id=${ehrPatientId}`);
    };
    const openHpiForm = () => {
        if (visitId) {
            dispatch(setActiveModal(MODAL_TYPES.PROVIDER.HPI_FORM, {
                visitId,
            }));
        }
    }

    return (
        <>
            <Button
                color="primary"
                className={classes.callBackbutton}
                onClick={handleCallback}
                startIcon={<Phone className={classes.endButtonIcon}
                data-testid="QA_active_zoom_meeting_callback_button"
                />}
            >
                <Typography>
                    <FormattedMessage id={`ActiveZoomMeeting.button.${currentEvisitDetails ? 'callBackPatientButton':'callBackButton'}`} />
                </Typography>
            </Button>
            {!hpiFormFeatureFlag && eTranscriptionPermission && isSukiAccessible &&
                <Button
                    color="primary"
                    onClick={handleSuki}
                    className={classes.startETranscriptionButton}
                    startIcon={<MicrophoneWithoutOpacity className={classes.startETranscription}
                    />}
                >
                    <Typography  >
                        <FormattedMessage id="ActiveZoomMeeting.button.startEtranscript" />
                    </Typography>
                </Button>
            }
            {hpiFormFeatureFlag && eTranscriptionPermission && isETranscriptionAccessible &&
                <Button id="QA_active_zoom_meeting_etranscription_button"
                    color="primary"
                    onClick={openHpiForm}
                    className={classes.startETranscriptionButton}
                    startIcon={<MicrophoneWithoutOpacity className={classes.startETranscription}
                    data-testid="QA_active_zoom_meeting_etranscription_button"
                    />}
                >
                    <Typography  >
                        <FormattedMessage id="ActiveZoomMeeting.button.startEtranscript" />
                    </Typography>
                </Button>
            }
        </>
    )
}

export default ActionButtons;
