import { getAppIdToken } from 'authentication/appAuth'
import { keys, map } from 'underscore'
import { fetch } from 'whatwg-fetch'

// @ts-ignore
const { ENABLE_API_URL } = window.env

// API is a tool to abstract sending calls to external servers, where the params and returns are unknown.
// disable no-explicit-any typing for this file since most data doesn't have a known type.

export const queryStringParams = (params: object): string => {
    if (keys(params).length > 0) {
        return (
            '?' +
            map(params, (val: any, key: string) => {
                if (val instanceof Array) {
                    return map(val, (v: any) => {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
                    }).join('&')
                } else {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
                }
            }).join('&')
        )
    }
    return ''
}

export const jsonFetch = (endpoint: string, fetchObj: any): Promise<any> => {
    return new Promise((cb, cbErr) => {
        return fetch(endpoint, fetchObj)
            .then((response: any) => {
                if (response.status === 204) {
                    return Promise.resolve()
                }
                if (response.status < 200 || response.status >= 400) {
                    return Promise.reject(response)
                }
                return response.json().then(
                    (data: object) => {
                        if (response.status >= 200 && response.status < 400) {
                            return data
                        } else {
                            return Promise.reject(new Error(response.response))
                        }
                    },
                    (error: any) => {
                        return Promise.reject(new Error(error.error))
                    }
                )
            })
            .then(cb, cbErr)
    })
}

export const nonAuthCall = (method: string, endpoint: string, params = {}): Promise<any> => {
    return callWithHeaders(method, endpoint, {}, params)
}

export const call = (method: string, endpoint: string, params = {}, headers = {}): Promise<any> => {
    const headersAuth: { Authorization?: string } = { ...headers }
    if (getAppIdToken()) {
        headersAuth.Authorization = 'Bearer ' + getAppIdToken()
    }
    return callWithHeaders(method, endpoint, headersAuth, params)
}
const callWithHeaders = (method: string, endpoint: string, headers = {}, params = {}) => {
    const fetchObj: any = {
        method,
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...headers
        }
    };

    method = (method || 'GET').toUpperCase()

    if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
        if (params instanceof window.FormData) {
            fetchObj.body = params
        } else {
            fetchObj.body = JSON.stringify(params)
        }
    } else {
        endpoint += queryStringParams(params)
    }
    return fetch(`${ENABLE_API_URL}/api${endpoint}`, fetchObj)
        .then((response: any) => {
            if (response.status === 204) {
                return Promise.resolve()
            }
            return response.text().then(
                (data: any) => {
                    try {
                        data = JSON.parse(data);
                    } catch (error) {

                    }
                    if (response.status === 401 && data.code === 'SESSION_EXPIRED') {
                        return Promise.reject(new Error('session expired'))
                    } else if (response.status >= 200 && response.status < 400) {
                        return data
                    } else {
                        return Promise.reject({ status: response.status, message: `${data.message}` })
                    }
                },
                (err: any) => {
                    return Promise.reject({
                        status: response.status,
                        error: err
                    })
                }
            )
        })
        .catch((err: any) => {
            return Promise.reject({
                status: err.status,
                message: err.message
            })
        })

}