import { call } from "admin/store/api";
import { FeeScheduleDetailsProps } from "admin/views/FeeSchedules/types";
import { handleDownload } from "views/PEPHome/components/file-download/file-download";

export const handleDownloadAttachment = async (currentProfileRoleJoin: any, organizationId?: string, scheduleId?: string, fileName?: string, errorCallback?: (error: any) => void) => {
    let queryFileName: string | undefined;
    if (scheduleId) {
        const response: FeeScheduleDetailsProps = await call("GET", `/admin/v1/billing/schedule/${scheduleId}`, {});
        queryFileName = response.fileName;
    }
    const requestFileName: string = fileName ? fileName : queryFileName ? queryFileName : "";
    const practiceId = currentProfileRoleJoin?.practice?.id;
    const orgId = organizationId || currentProfileRoleJoin?.practice?.organizationId || currentProfileRoleJoin?.organization?.id;

    const fileRequest = {
        "fileName": requestFileName,
        "fileType": "FEE_SCHEDULE",
        "practiceId": practiceId,
        "orgId": orgId
    };
    const endpointURL = `/admin/v1/provider/fee-schedule/download`;

    await handleDownload(endpointURL, fileRequest).catch((error: any) => {
        if (errorCallback) {
            errorCallback(error);
        }
    });
};