import React, { useCallback, useEffect, useState } from "react";
import { useStyles } from "../styles";
import { debounce, map } from "underscore";
import { useDispatch, useSelector } from "react-redux";
import { searchPatients } from "store/actions";
import { Box, Typography } from "@mui/material";
import { AppReduxStore } from "store/reducerTypes";
import EmptyContentMessage from "common/components/EmptyContentMessage/EmptyContentMessage"
import Spinner from "common/components/Spinner/Spinner"
import { SearchResultProps } from "./types"
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { ReactComponent as Person } from "icon-library/SVG/User_Single.svg";
import { ReactComponent as Persons } from "icon-library/SVG/User_Users.svg";
import { ReactComponent as Alert } from "icon-library/SVG/Warning_Triangle.svg";
import { ReactComponent as InZoom } from "icon-library/SVG/Live.svg";
import { ReactComponent as InWR } from "icon-library/SVG/Bell.svg";
import { PATIENT_SEARCH } from "views/People/constants";
import { createSvgIcon } from "utils";
import { NavLink } from "react-router-dom"
import { getAge } from "utils";
import SearchHeader from "./SearchHeader";

const PersonIcon = createSvgIcon(Person)

const AlertIcon = createSvgIcon(Alert)
const InZoomIcon = createSvgIcon(InZoom)
const InWRIcon = createSvgIcon(InWR)

const SearchResults = ({ searchTerm }: { searchTerm: string }) => {
    const classes = useStyles()
    const { loadingSearch, patients, selectedPatientId } = useSelector((state: AppReduxStore) => state.patient)
    // removing search result from UI if search string is empty
    if (loadingSearch&&searchTerm) {
        return <Spinner />
    } else if (patients&&searchTerm) {
        return (
            <Box className={classes.patientSearch}>
                {map(patients, (patient, idx) => {
                    const isSelected = selectedPatientId === patient.id
                    return <SearchResult key={idx} isSelected={isSelected} patient={patient} />
                })}
            </Box>
        )
    }


    return <EmptyContentMessage Icon={Persons} title="People.search.empty.title" subtitle="People.search.empty.subtitle" />
}

const SearchResult = ({ patient, isSelected }: SearchResultProps) => {
    const classes = useStyles()
    const { firstName, lastName, mrn, dateOfBirth, genderCode, id, hasNewAlerts, status } = patient

    const age = getAge(dateOfBirth)

    const iconStyle = isSelected ? `${classes.personIcon} ${classes.selectedPersonIcon}` : classes.personIcon

    return (
        <NavLink to={`/provider/people/patients/${id}`} className={classes.result} activeClassName={classes.selectedResult}  id="QA_patient_select">
            <Box display="flex">
                <PersonIcon className={iconStyle} fill={isSelected ? 'white' : 'grey'} />
                <Box>
                    <Box className={classes.nameContainer} id="QA_patient_name">
                        <CustomTypography variant="desktopPatientDetailsBaseBold">
                            {lastName},
                        </CustomTypography>
                        <CustomTypography variant="desktopPatientDetailsBaseBold">
                            {firstName}
                        </CustomTypography>
                    </Box>
                    <Typography variant="h6">
                        {mrn}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Box className={classes.nameContainer} >
                    <Typography variant="h6" className="m-0" id="QA_patient_age_gendercode">
                        ({age} {genderCode})
                    </Typography>
                </Box>
                <div className={classes.iconWrapper}>
                    {status === 'ZOOMCALL' && <InZoomIcon className={classes.alertsIcon} fill={isSelected ? 'white' : 'red'} />}
                    {status === 'WAITINGROOM' && <InWRIcon className={classes.alertsIcon} fill={isSelected ? 'white' : 'red'} />}
                    {hasNewAlerts && <AlertIcon className={classes.alertsIcon} fill={isSelected ? 'white' : 'red'} />}
                </div>
            </Box>

        </NavLink>
    )
}

const PeopleSearch = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");

    // ESLint disabling reason: debounce hides its parameter function content, therefore it cannot be checked by eslint
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearchPatients = useCallback(debounce((searchTerm: string) => {
        if (searchTerm.length > 1) dispatch(searchPatients(searchTerm, PATIENT_SEARCH.PEOPLE))
    }, 250),[dispatch]);

    useEffect(() => {
        debouncedSearchPatients(searchTerm);
    }, [debouncedSearchPatients, dispatch, searchTerm]);

    return (
        <Box className={classes.resultsWrapper}>
            <SearchHeader searchTerm={searchTerm} onSearchTermChange={setSearchTerm} />
            <SearchResults searchTerm={searchTerm} />
        </Box>
    )
}

export default PeopleSearch;
