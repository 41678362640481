import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import * as FileClassNames from "./class-names";
import Preview from "./preview/preview";
import { useFiles } from "./file-grid-view/use-files";
import { useThumbnails } from "./file-grid-view/use-thumbnails";
import { FileMetadataDetails } from "./types";

export type FileGridViewProps = {
    className?: string;
    /**
     * Array of full file metadatas to be displayed.
     * */
    files?: FileMetadataDetails[];
    /**
     * Array of file IDs
     * If you give an array of file IDs, this component will fetch the metadata from
     * the file service.
     * If used alongside with `files` this property will be ignored.
     * */
    fileIds?: string[];
};

const FileGridView = ({ files, fileIds, className }: FileGridViewProps) => {
    const classes = useStyles();

    const { filesMetadata, loading } = useFiles({ files, fileIds });
    const { thumbnailLocations, isThumbnailLoading } = useThumbnails({ files: filesMetadata });

    const hasFiles = Boolean(filesMetadata.length);

    if (!files?.length && !fileIds?.length) {
        return (
            <div className={classes.attachmentsViewEmpty}>
                <span>
                    <FormattedMessage id="files.fileGridView.emptyMessage" />
                </span>
            </div>
        );
    }
    return (
        <div
            className={classNames(
                classes.Files__PreviewGrid,
                FileClassNames.PreviewGrid,
                FileClassNames.FileGridView,
                className
            )}
        >
            {hasFiles &&
                filesMetadata.map((file: FileMetadataDetails) => (
                    <Preview
                        key={file.id}
                        fileMetadataDetails={file}
                        previewUrl={thumbnailLocations?.[file.id]}
                        loading={isThumbnailLoading(file.id)}
                    />
                ))}
            {!hasFiles && fileIds?.length && fileIds.map((id) => <Preview key={id} loading={loading} />)}
        </div>
    );
};

export default FileGridView;
