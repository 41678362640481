import classNames from "classnames";
import { useStyles } from "./styles";
import * as CSS from "./class-names";

export type TableListProps = {
    id: string;
    testId?: string;
    className?: string;
    variant?: "default" | "alternated";
    size?: "small" | "big";
};

/**
 * This component is a list built using a table so it can easily retain alignment between it's items
 * Do not use this component if what you want is a traditional table, since this is not build to support
 * header or other kinds of table configuration
 */
export const TableList: React.FC<TableListProps> = ({
    children,
    testId,
    id,
    className,
    variant = "default",
    size = "small",
}) => {
    const classes = useStyles();

    return (
        <table
            id={id}
            data-testid={testId || id}
            className={classNames(classes.TableList, CSS.TableList, className, {
                [CSS.TableListDefault]: variant === "default",
                [CSS.TableListAlternated]: variant === "alternated",
                [CSS.TableListBig]: size === "big",
            })}
        >
            <tbody>{children}</tbody>
        </table>
    );
};
