import { Grid } from "@mui/material";
import ConfigurationTile from "admin/components/ConfigurationTile/ConfigurationTile";
import { setNavBreadcrumbText } from "admin/store/actions";
//import { getProductsForPractice } from "admin/store/actions/license";
import { AppReduxStore } from "admin/store/reducerTypes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ConfigurationListing = ({ fromWelcomeModal = false }) => {
    const { auth, license, configuration } = useSelector((state: AppReduxStore) => state)
    const dispatch = useDispatch()
    const { practiceLicenses } = license
    const eVisitOK = configuration.visitTypes?.length > 0 &&
        configuration.cancellationTypes?.length > 0

    const patientFormsOK = configuration.patientForms?.length > 0

    const registrationEmailsOK = Object.keys(configuration.registrationEmailPatients)?.length > 0 && configuration.registrationEmailPatients?.version > 0 &&
        Object.keys(configuration.registrationEmailProvider)?.length > 0 && configuration.registrationEmailProvider?.version > 0
    let configurations = [{
        name: "eVisit",
        content: [
            "Set visit and cancellation types for your practice",
            "Configure your practices virtual waiting room"
        ],
        linkText: "Configure eVisit Settings",
        link: "/admin/configurations/evisit",
        info: eVisitOK ? null : "Required before using"
    }, {
        name: "Patient Forms",
        content: [
            "Enable/Disable digital forms for your patients",
            "Configure form settings",
            "Customize selected forms"
        ],
        linkText: "Configure Forms",
        link: "/admin/configurations/patientforms",
        info: patientFormsOK ? null : "Required configuration before using"
    }, {
        name: "Registration emails",
        content: [
            "Configure the emails sent to new users",
            "Add logos and customize the included text"
        ],
        linkText: "Configure Emails",
        link: "/admin/configurations/registrations",
        info: registrationEmailsOK ? null : "Required before adding users"
    }]


    useEffect(() => {
        if (auth.currentPractice?.id) {
            //dispatch(getProductsForPractice(auth.currentPractice?.id));
            dispatch(setNavBreadcrumbText(''));
        }
    }, [auth.currentPractice?.id, dispatch])

    useEffect(() => {
    }, [license.allProducts, configuration])

    const careTeamCounts = practiceLicenses && practiceLicenses.length > 0 &&
        practiceLicenses.find((core: any) => core.product.code === "CARE_TEAM")?.licenseCount

    const coreCounts = practiceLicenses && practiceLicenses.length > 0 &&
        practiceLicenses.find((core: any) => core.product.code === "CORE")?.licenseCount

    if (careTeamCounts === 0) {
        const eVisitHideData = configurations.filter(config => config.name !== 'eVisit')
        configurations = eVisitHideData
    }
    if (!practiceLicenses || practiceLicenses?.length === 0 || coreCounts === 0) {
        const registrationEmails = configurations.filter(config => config.name === 'Registration emails')
        configurations = registrationEmails
    }
    const configurationList = (fromWelcomeModal ? configurations.filter(config => config.info) : configurations)
    return (
        <Grid container spacing={2}>
            {configurationList && configurationList.map((configuration: any, index: number) => (
                <Grid item xs={12} md={6} key={index}>
                    <ConfigurationTile name={configuration.name} content={configuration.content} linkText={configuration.linkText} link={configuration.link} info={configuration.info} />
                </Grid>
            ))}
        </Grid>
    )
}
export default ConfigurationListing