import { PromiseFunctions } from "types";

export const debounce = (functionToDebounce: any, delay: number) => {
    const EMPTY_DEBOUNCED_PROMISE = {
        promise: null,
        resolve: undefined,
        reject: undefined,
    };
    let timer: ReturnType<typeof setTimeout> | undefined;
    let debouncedPromise: {
        promise: Promise<ReturnType<typeof functionToDebounce>> | null;
        resolve: PromiseFunctions["resolve"] | undefined;
        reject: PromiseFunctions["reject"] | undefined;
    } = {
        ...EMPTY_DEBOUNCED_PROMISE,
    };
    const debouncedFunction = (...args: any[]): Promise<ReturnType<typeof functionToDebounce>> => {
        clearTimeout(timer as ReturnType<typeof setTimeout>);
        timer = setTimeout(() => {
            try {
                const result = functionToDebounce.call(this, ...args);
                debouncedPromise.resolve?.(result);
            } catch (error) {
                debouncedPromise.reject?.(error);
            } finally {
                debouncedPromise = { ...EMPTY_DEBOUNCED_PROMISE };
            }
        }, delay);
        if (debouncedPromise.promise === null) {
            debouncedPromise.promise = new Promise((resolve, reject) => {
                debouncedPromise.resolve = resolve;
                debouncedPromise.reject = reject;
            });
        }
        return debouncedPromise.promise;
    };
    debouncedFunction.cancel = () => {
        clearTimeout(timer as ReturnType<typeof setTimeout>);
    };
    return debouncedFunction;
};
