import React, { FC } from "react";
import { useIntl } from 'react-intl';
import { Typography } from "@mui/material";
import { ErrorViewProps } from './types';
import { useStyles } from './styles';

export const ErrorView: FC<ErrorViewProps> = ({
    titleId,
    descriptionId,
    code,
    children
}) => {
    const classes = useStyles();
    const intl = useIntl();
    
    return (
        <div className={classes.pageWrapper}>
            <div className={classes.contentContainer}>
                <Typography noWrap variant={"h1"} className={classes.title}>
                    {intl.formatMessage({ id: titleId })}
                </Typography>
                <div className={classes.ribbon}>
                    <label>{code}</label>
                </div>
                <Typography variant={"body2"}>
                    <b>{intl.formatMessage({id: descriptionId})}</b>
                </Typography>
                <Typography variant={"body2"}>
                    {intl.formatMessage({id: 'errorView.genericDescription'})}
                </Typography>
                {children}
            </div>
        </div>
    );
}