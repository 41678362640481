import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    header: {
        width: '50%', 
        height: '145px', 
        borderRadius: '8px', 
        marginBottom: '25px',
        overflow: 'auto',
        scrollbarTrack: '#c4c4c4 !important',
        scrollbarThumb: '#fefefe !important'
    },
    content: {
        width: '50%', 
        height: '45px', 
        backgroundColor: '#41aef0', 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        justifyContent: "space-between",
        borderRadius: '8px 8px 0 0', 
        padding: '0 30px 0 21px'
    },
    title1: {
        color: 'white', 
        flexGrow: 1, 
        fontSize: 18,
        fontWeight: 'bolder'
    },
    title2: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bolder'
    },
    list: {
        fontSize: 14, 
        fontWeight: 'bolder' 
    },
    items: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '10px 0 10px 0'
    },
    items1: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        alignItems: 'center'
    },
    items2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    items3: {
        marginLeft: '5px'
    },
    items4: {
        marginRight: '100px'
    },
    text1: {
        color: '#565662', 
        fontSize: '17px', 
        fontWeight: 900
    },
    text2: {
        color: '#0F7BBD', 
        fontSize: '17px', 
        fontWeight: 900
    },
    text3: {
        color: '#565662', 
        fontSize: '17px', 
        fontWeight: 900,
    },
    content1: {
        paddingTop: '0px',
        paddingBottom: '0px !important'
    }
}));