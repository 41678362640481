import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  listText: {
    color: '#393948',
    fontSize: '16px',
    fontWeight: "bold"
  },
  listTextRed: {
    color: '#E32B29',
    fontSize: '16px',
    fontWeight: "bold"
  },
  deleteIconRed: {
    "& path": {
      fill: '#E32B29'
    }
  }
}));