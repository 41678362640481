import React, { forwardRef, useEffect, useImperativeHandle } from "react"
import { Grid, Typography } from "@mui/material";
import Controls from "../../../components/Controls/Controls";
import { Form, useForm } from '../../useForm';
import { AppReduxStore } from "../../../../admin/store/reducerTypes";
import { useDispatch, useSelector } from 'react-redux';
import { editOrganization } from '../../../../admin/store/actions'
import { useIntl } from "react-intl";
import { validateNoSpecialCharacters, validatePostalCode, validateRequired } from 'admin/common/utils';
import { US_STATES } from "admin/constant";
import { useStyles } from './styles';
import { createSvgIcon } from "utils";
import { ReactComponent as Buildings } from "icon-library/SVG/Buildings.svg";

const { NUNA_ROLE } = (window as any).env;

const EditOrganizationForm = forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const intl = useIntl()
    const nunaRoleArray = [{ id: NUNA_ROLE, title: NUNA_ROLE }]
    const BuildingIcon = createSvgIcon(Buildings);
    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            }
        }),
    )

    const { organization } = useSelector((state: AppReduxStore) => state);
    const { sOrganization } = organization;
    // sOrganization.nunaRole = NUNA_ROLE

    useEffect(() => {
        resetValues(sOrganization)
    }, [sOrganization]);

    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('name' in fieldValues) {
            err.name = validateRequired(fieldValues.name, intl)
            if (err.name === "") err.name = validateNoSpecialCharacters(fieldValues.name, intl)
        }
        if ('city' in fieldValues) {
            err.city = validateRequired(fieldValues.city, intl)
        }
        if ('state' in fieldValues) {
            err.state = validateRequired(fieldValues.state, intl)
        }
        if ('postalCode' in fieldValues) {
            err.postalCode = ""
            if (fieldValues.city.trim().length > 0 && fieldValues.state.trim().length > 0) {
                err.postalCode = validateRequired(fieldValues.postalCode, intl)
            }
            if (err.postalCode === "") err.postalCode = validatePostalCode(fieldValues.postalCode, intl)
        }
        setErrors({
            ...err
        })
        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }
    const {
        values,
        resetValues,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(sOrganization, false, validate);

    const handleSubmit = (e: any) => {
        if (validate(values)) {
            dispatch((editOrganization(values, organization)))
        }
    };

    return (
        <>
            <Form onsubmit={handleSubmit}>
                <Grid container>
                    <Grid item className={classes.pageHeader}>
                        <Typography className={classes.pageHeaderText}>
                            <BuildingIcon fill="#0F7BBD" className={classes.editIcon} />
                            {intl.formatMessage({ id: "EditOrganization.info" })}
                        </Typography>
                        <Typography>
                            {intl.formatMessage({ id: "EditOrgInfo.Header.OrganizationInfoDesc" })}
                        </Typography>
                    </Grid>
                    <Grid container direction="column">
                        <Grid item className={classes.sectionHeader}>
                            <Typography className={classes.sectionHeaderText}>
                                {intl.formatMessage({ id: "EditOrgInfo.Header.OrganizationName" })}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                            <Grid item xs={4}>
                                <Controls.Input
                                    name="name"
                                    label="Name *"
                                    onChange={handleInputChange}
                                    placeholder="Enter name here"
                                    value={values.name}
                                    error={errors.name}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid item className={classes.sectionHeader}>
                            <Typography className={classes.sectionHeaderText}>
                                {intl.formatMessage({ id: "EditOrgInfo.Header.OrganizationAddress" })}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                            <Grid item spacing={2} xs={4}>
                                <Controls.Input
                                    name="address1"
                                    label="Address 1"
                                    onChange={handleInputChange}
                                    placeholder="Enter here"
                                    value={values.address1}
                                />
                            </Grid>
                            <Grid item spacing={2} xs={4}>
                                <Controls.Input
                                    name="address2"
                                    label="Address 2"
                                    onChange={handleInputChange}
                                    placeholder="Enter here"
                                    value={values.address2}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                            <Grid item xs={4}>
                                <Controls.Input
                                    name="city"
                                    label="City *"
                                    onChange={handleInputChange}
                                    placeholder="Enter here"
                                    value={values.city}
                                    error={errors.city}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Controls.Select
                                    name="state"
                                    label="State *"
                                    onChange={handleInputChange}
                                    placeholder="Select"
                                    options={US_STATES}
                                    value={values.state}
                                    error={errors.state}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Controls.Input
                                    name="postalCode"
                                    label="Zip Code"
                                    onChange={handleInputChange}
                                    value={values.postalCode}
                                    maxLength={10}
                                    error={errors.postalCode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid item className={classes.sectionHeader}>
                            <Typography className={classes.sectionHeaderText}>
                                {intl.formatMessage({ id: "EditOrgInfo.Header.OrganizationOtherInfo" })}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                            <Grid item xs={4}>
                                <Controls.Select
                                    name="nunaRole"
                                    label="Nuna Role"
                                    onChange={handleInputChange}
                                    options={nunaRoleArray}
                                    value={values.nunaRole}
                                    error={errors.nunaRole}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controls.Input
                                    name="absorbDepartmentId"
                                    label={intl.formatMessage({ id: "EditOrgInfo.Lebel.AbsorbDepartmentID" })}
                                    placeholder="Enter Here"
                                    value={values.absorbDepartmentId}
                                    onChange={handleInputChange}
                                    error={errors.absorbDepartmentId}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </>
    )
})

export default EditOrganizationForm