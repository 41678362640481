import React from "react";
import { Box } from "@mui/material";
import { FormattedDate } from "react-intl";
import { useStyles } from "./styles";
import { LabelRenderProps } from "common/components/OptionMenu/types";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import classNames from "classnames";

const DurationOptionLabel = ({ className, labelText, labelValues, selected }: LabelRenderProps) => {
    const classes = useStyles();

    return (
        <Box display="flex" justifyContent="space-between" flexGrow={1}>
            <CustomTypography className={className} variant="optionMenuItem">
                {labelText}
            </CustomTypography>
            <span className={classNames(className, classes.durationOptionLabel, { selected })}>
                {labelValues?.date && (
                    <FormattedDate value={labelValues?.date} year="numeric" day="2-digit" month="short" />
                )}
            </span>
        </Box>
    );
};

export const renderDurationOption = (props: LabelRenderProps) => <DurationOptionLabel {...props} />;

export default DurationOptionLabel;
