import { useParams } from "react-router-dom";
import { getConfig } from "./pep-config";
import { PEPTiles } from "./pep-tiles";
import { useStyles } from "./styles";
import { ConfigProps, LinkProps, ProviderParams } from "./types";

export const PEPMain = ({ providerConfig: config, permissions: grantedPermissions, onSelect: handleLinkAction }: { providerConfig: ConfigProps, permissions: string[], onSelect?: (linkAction: LinkProps) => void }) => {
    const { providerName } = useParams<ProviderParams>();
    const providerConfig: ConfigProps = getConfig(providerName);
    const wpRoot = providerConfig.wpRoot;
    const pep_top_src = wpRoot + "/pep-top/#content_details";
    const pep_bottom_src = wpRoot + "/pep-bottom/#"
    const classes = useStyles();

    return (<>
        {wpRoot && <iframe id="wp_pep_home_top" title="PEP Content - Top" className={classes.wp_pep_home_top} src={pep_top_src} />}
        <PEPTiles providerConfig={config} permissions={grantedPermissions} onSelect={handleLinkAction} />
        {wpRoot && <iframe id="wp_pep_home_bottom" title="PEP Content - Bottom" className={classes.wp_pep_home_bottom} src={pep_bottom_src} />}
    </>)
}
