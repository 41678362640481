import PreviewBase from "./preview-base";
import { ReactComponent as ArrowDownIcon } from "icon-library/SVG/Arrow_Down-Square.svg";
import { call } from "store/api";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { useUserNotification } from "common/utils/use-user-notification";
import { FileMetadataDetails } from "../types";
import { useStyles } from "../styles";
import React, { useState } from "react";

type DownloadPreviewProps = {
    loading?: boolean;
    fileMetadataDetails: FileMetadataDetails;
    previewUrl?: string;
};

const DownloadPreview = ({
    fileMetadataDetails: { name, id, mimeType },
    previewUrl,
    loading,
}: DownloadPreviewProps) => {
    const classes = useStyles();
    const { enqueueError } = useUserNotification();
    const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

    const handleDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            setLoadingDownloadUrl(true);
            const downloadLocation = await call("GET", `/file/v1/files/${id}/location`);
            const anchor = document.createElement("a");
            anchor.href = downloadLocation.url;
            anchor.download = name;
            anchor.target = "_blank";
            anchor.click();
            anchor.remove();
        } catch (error) {
            enqueueError("files.downloadPreview.download.error", (error as Error).message);
        }
        setLoadingDownloadUrl(false);
    };

    const preview = (
        <PreviewBase previewUrl={previewUrl} title={name} mimeType={mimeType} loading={loadingDownloadUrl || loading} />
    );

    return (
        <div id={`QA_download_preview_${id}`} className={classes.downloadPreview}>
            <button onClick={handleDownload}>
                <SVGIcon className="downloadPreview__icon" svg={ArrowDownIcon} size="scale800" />
                {preview}
            </button>
        </div>
    );
};

export default DownloadPreview;
