import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AppReduxStore } from "admin/store/reducerTypes"
import { showNotification } from './snackbar'
import { makeApiCall } from 'admin/common/utils'

export const fetchLocations = (practiceId?: null | string, searchString?: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = auth.currentPractice ? auth.currentPractice.id : admin?.configurePracticeId;
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/locations/`, searchString && searchString.trim() !== "" ? { practiceId, name: searchString } : { practiceId },
		"LocationListing.Actions.FetchLocations", "LocationListing.Actions.FetchLocationsDesc").then((payload) => {
			const locations = payload ? payload.map((p: any) => {
				return p
			}) : []
			dispatch({ type: 'SET_LOCATIONS', payload: { locations } })
		}).catch(err => {
			dispatch(showNotification("Get All Locations", "error", String(err && err.message)))
		});
	}
}
export const insertLocation = (data: any, onLocationInsert: any) => {

	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		data["practiceId"] = practiceId
		return makeApiCall(dispatch, "POST", `/admin/v1/practice/location`, data).then((payload) => {
			if (typeof onLocationInsert === 'function') {
				onLocationInsert(true);
			}
			dispatch(showNotification(payload, "success", ""))
			dispatch(fetchLocations())
			return payload;
		}).catch(err => {
			if (typeof onLocationInsert === 'function') {
				onLocationInsert(false);
			}
			dispatch(showNotification("Error", "error", String(err && err.message)))
		});
	}
}
export const editLocation = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		return makeApiCall(dispatch, "PUT", `/admin/v1/practice/location`, data).then((payload) => {
			dispatch(showNotification(payload, "success", ""))
			dispatch(fetchLocations())
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
		});
	}
}

export const setSelectedLocationId = (locationId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { locations } = getState().location
		const location = locations && locations.find((p: any) => p.id === locationId)
		dispatch({ type: 'SET_SELECTED_LOCATION', payload: { sLocationA: location } })
	}
}
export const filterLocations = (searchText: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { locations } = getState().location
		dispatch({ type: 'SET_LOCATIONS', payload: { locations: locations } })
	}
}