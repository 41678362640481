import { AnyAction } from "redux";
import { ThunkDispatch,ThunkAction } from "redux-thunk";
import {call} from "store/api";
import {AppReduxStore} from "../reducerTypes";
import {MODAL_TYPES, SYS_MESSAGES} from "constant";
import {setActiveModal} from "store/actions";
import {setError} from "./error";

// createMeeting makes a request to create a zoom meeting and saves the id and password
export const createMeeting = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { zoomUsername, twilioUsername, firstName, lastName } = getState().user
        try {
            dispatch({type: 'CREATE_MEETING'})
            // should we make a type for the zoom api response?
            const { id, password } = await call("POST", `/conference/v1/meetings?identity=${zoomUsername}`)
            dispatch({type: 'CREATE_MEETING_SUCCESS', payload: {
                meetingId: id,
                pin: password,
                from: twilioUsername,
                fromName:`${firstName} ${lastName}`
            }})
        } catch (e: any) {
            console.error(e)
            dispatch({type: 'CREATE_MEETING_FAILURE'})
        }
    }
}

export const createEvisitMeeting = (to: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { user, patient, auth } = getState()
        const { selectedPatient } = patient
        const { currentProfileId } = auth
        const {
            evistDetails = { visitKey: "", status: "", providerProfileId: "" },
            firstName,
            ehrPatientId,
        } = selectedPatient || {};

        const visitKey = evistDetails?.visitKey || "";
        const patientProfileId = selectedPatient?.id;
        const providerProfileId = currentProfileId;

        try {

            dispatch({ type: 'CREATE_MEETING' })

            dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

            let pushNotificationPayload: any = {
                from: user.twilioUsername,
                toName: firstName,
                fromName: `${user.firstName} ${user.lastName}`,
                visitKey
            }

            if (evistDetails?.status === 'ZOOMCALL') {

                const params = {
                    visitKey,
                    patientProfileId,
                    providerProfileId
                }

                const { meetingId, pin } = await call("POST", `/conference/v1/meetings/evisit/join`,params)

                dispatch({ type: 'CREATE_MEETING_SUCCESS', payload: { meetingId, pin, from: user.twilioUsername } })


                pushNotificationPayload = {
                    ...pushNotificationPayload,
                    meetingId,
                    pin
                }

            } else if (evistDetails?.status === 'WAITINGROOM') {

                const params = {
                    identity: user.zoomUsername,
                    status: 'ZOOMCALL',
                    visitKey
                }

                const { meetingId, pin } = await call("POST", `/conference/v1/meetings/evisit`, params)

                pushNotificationPayload = {
                    ...pushNotificationPayload,
                    meetingId,
                    pin
                }

                dispatch({ type: 'CREATE_MEETING_SUCCESS', payload: { meetingId, pin, from: user.twilioUsername } })

                // @ts-ignore
                await dispatch(pushSystemMessage(to, null, SYS_MESSAGES.SYS_EVISIT_CALL_INCOMING, user, null, null, pushNotificationPayload))

                dispatch({type: 'UPDATE_PATIENT_EVIST_DETAILS',payload:{ evistDetails:{ ...selectedPatient?.evistDetails,status:'ZOOMCALL' } }})

            }

            // updating evist status
            dispatch({ type: 'UPDATE_EVIST_MEETING_STATUS', payload: { isEvistMeeting: true, declined:false, currentEvisitDetails: { visitKey,ehrPatientId, patientProfileId, patientFirstName:firstName,sheduledProviderId:evistDetails?.providerProfileId,patientTwilioId:to }, calling: false, waiting: false } })

            dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })


        } catch (e: any) {
            console.error(e)
            dispatch({ type: 'CREATE_MEETING_FAILURE' })
            dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
            dispatch(setError("Error.message.joinEvist"))
            throw e
        }
    }
}

export const inviteColleagueToEvisit = (participant: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            const { meeting, user } = getState()

            const { currentEvisitDetails, meetingId, pin } = meeting

            const { visitKey = "" } = currentEvisitDetails || {};


            try {

                const message = {
                    meetingId,
                    pin,
                    currentEvisitDetails
                }

                const params = {
                    providerProfileRequests: participant.map((participantDetails: any) => ({ twilioUsername: participantDetails.twilioUsername, profileID: participantDetails.profileId })),
                    identity: user.twilioUsername,
                    visitKey,
                    message:message
                }

                await call("POST", `/conference/v1/meetings/evisit/invite`, params)

                resolve()

            } catch (e: any) {
                console.error(e)
                reject(e)
                dispatch(setError("Error.message.failedInviteColleague"))
            }
        })
    }
}

export const joinEvisitFromInviteMessage = (details: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

        const { meetingId, pin, currentEvisitDetails } = JSON.parse(details);

        try {

            const params = {
                visitKey: currentEvisitDetails.visitKey
            }

            const response = await call("GET", `/conference/v1/visit`, params);

            if (response.status === 'ZOOMCALL') {

                const payload = {
                    isEvistMeeting: true,
                    currentEvisitDetails,
                    isHost: false,
                    loading: false,
                    calling: false,
                    waiting: false,
                    declined: false,
                    ended: false,
                    joinAgainStatus: false,
                    meetingId,
                    pin
                }

                // updating evist status
                dispatch({ type: 'UPDATE_EVIST_MEETING_STATUS', payload })

                await dispatch(setActiveModal(MODAL_TYPES.PROVIDER.ACTIVE_ZOOM_MEETING))
            }
            else {
                dispatch(setError("Error.message.meetingAlreadyClosed"))
            }
        } catch (e: any) {
            console.error(e)
            dispatch(setError("Error.message.failedcloseEvistMeeting"))
            throw e
        }
    }
}

export const evisitCallBack = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {

        const { user, meeting } = getState()
        const { currentEvisitDetails, meetingId, pin } = meeting;
        const { patientFirstName, visitKey, patientTwilioId } = currentEvisitDetails ?? {};

        try {
            if (patientTwilioId) {
                const pushNotificationPayload: any = {
                    from: user.twilioUsername,
                    toName: patientFirstName,
                    fromName: `${user.firstName} ${user.lastName}`,
                    visitKey,
                    meetingId,
                    pin
                }
                dispatch({
                    type: 'UPDATE_EVIST_MEETING_STATUS',
                    payload: { isEvistMeeting: true, ended: false, calling: false, waiting: false }
                });
                await dispatch(
                    pushSystemMessage(patientTwilioId, null, SYS_MESSAGES.SYS_EVISIT_CALL_INCOMING, user, null, null, pushNotificationPayload)
                );
            } else {
                throw new Error("Patient Twilio ID is undefined.");
            }
        } catch (e: any) {
            console.error(e)
            dispatch(setError("Error.message.failedcloseEvistMeeting"))
            throw e
        }
    }
}

export const closeEvistMeeting = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { meeting,auth,patient } = getState()
        const {currentProfileId} = auth
        const { currentEvisitDetails } = meeting || {};
        const { selectedPatient } = patient

        try {
            const params = {
                patientProfileId:currentEvisitDetails?.patientProfileId,
                providerProfileId:currentProfileId,
                status:'COMPLETE',
                visitKey:currentEvisitDetails?.visitKey || ''
            }

            await call("PUT", `/conference/v1/meetings/evisit`,params)
            if (currentEvisitDetails?.patientProfileId === selectedPatient?.id) {
                // reset patient evist details
                dispatch({ type: 'UPDATE_PATIENT_EVIST_DETAILS', payload: { evistDetails: {} } })
            }


        } catch (e: any) {
            console.error(e)
            dispatch(setError("Error.message.failedcloseEvistMeeting"))
            throw e
        }
    }
}

export const joinEvistAgain = (to: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            // @ts-ignore
            await dispatch(createEvisitMeeting(to))

            await dispatch({type: 'CALL_OUTGOING', payload: {to}})

            // TODO SYS_CALL_ACCEPTED need to remove after demo. this should emit from mobile app
            dispatch({type: 'SYS_CALL_ACCEPTED'})

            dispatch({type: 'CALL_JOIN_AGAIN'})

            // @ts-ignore
            // SYS_CALL_JOIN_AGAIN
            // await dispatch(pushSystemMessage(to, to, SYS_MESSAGES.SYS_CALL_JOIN_AGAIN, user, meetingId, pin))

            // @ts-ignore
            await dispatch(setActiveModal(MODAL_TYPES.PROVIDER.ACTIVE_ZOOM_MEETING))
        } catch (e: any) {
            console.error(e)
        }
    }
}

// outgoingCall creates a meeting and sends a sys message to the other participant
export const outgoingCall = (to: string, toName: string = to, type: string = 'adhoc', profile?: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        try {
            // @ts-ignore
            await dispatch(type === 'adhoc' ? createMeeting() : createEvisitMeeting(to))

            const { user, meeting: { meetingId, pin } } = getState()

            await dispatch({type: 'CALL_OUTGOING', payload: {isEvistMeeting:type !== 'adhoc',to,declined:false, toName, profile:{ ...(profile?profile:''),to }}})

            // @ts-ignore
            await dispatch(pushSystemMessage(to, toName, SYS_MESSAGES.SYS_CALL_INCOMING, user, meetingId, pin))

            // @ts-ignore
            await dispatch(setActiveModal(MODAL_TYPES.PROVIDER.ACTIVE_ZOOM_MEETING))
        } catch (e: any) {
            console.error(e)
        }
    }
}

export const endCalling = (to:string,toName:string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        try {

            const { user, meeting: { meetingId, pin } } = getState()

            // @ts-ignore
            await dispatch(pushSystemMessage(to, toName, SYS_MESSAGES.SYS_CALL_END, user, meetingId, pin))
            
            // @ts-ignore
            await dispatch(setActiveModal(''))
        } catch (e: any) {
            console.error(e)
        }
    }
}

export const startEvistMeeting = (to: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            // @ts-ignore
            await dispatch(createEvisitMeeting(to))

            await dispatch({type: 'CALL_OUTGOING', payload: {to}})

            // @ts-ignore
            await dispatch(setActiveModal(MODAL_TYPES.PROVIDER.ACTIVE_ZOOM_MEETING))
        } catch (e: any) {
            console.error(e)
        }
    }
}

// acceptCall sends system message to inform the other participant that the current user has accepted the call
export const acceptCall = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { user, meeting: {incoming} } = getState()
        const { meetingId, pin, from, fromName } = incoming
        // Clear any active modal
        dispatch(setActiveModal(''))
        try {
            // @ts-ignore
            await dispatch(pushSystemMessage(from, fromName, SYS_MESSAGES.SYS_CALL_ACCEPTED, user, meetingId, pin))
            dispatch({type: 'CALL_ACCEPTED'})
            // @ts-ignore
            dispatch(setActiveModal(MODAL_TYPES.PROVIDER.ACTIVE_ZOOM_MEETING))
        } catch (e: any) {
            console.error(e)
        }
    }
}

// denyCall sends system message to inform the other participant that the current user has denied the call
export const denyCall = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { user, meeting: { incoming } } = getState()

        const { meetingId, pin, from, fromName } = incoming || {}

        try {
            // @ts-ignore
            await dispatch(pushSystemMessage(from, fromName, SYS_MESSAGES.SYS_CALL_DECLINED, user, meetingId, pin))
            dispatch({type: 'CALL_DECLINED'})
        } catch (e: any) {
            console.error(e)
        }
    }
}

// joinCall sends a system message to inform the other participant that the current user has joined the call
export const joinCall = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const {user, meeting: {meetingId, pin, isHost, toName,to,isEvistMeeting,currentEvisitDetails,fromName}} = getState()
        try {

            const { patientFirstName = "" } = currentEvisitDetails || {};

            const patientName = patientFirstName ? patientFirstName : isHost ? toName : fromName;

            const type = isEvistMeeting ? SYS_MESSAGES.SYS_EVISIT_CALL_JOINED : SYS_MESSAGES.SYS_CALL_JOINED;
            // @ts-ignore
            await dispatch(pushSystemMessage(to, patientName, type, user, meetingId, pin))
        } catch (e: any) {
            console.error(e)
        }
    }
}

// pushSystemMessage makes a request to the backend to send a message to a system conversation with some payload
// TODO: add before success and failure actions
export const pushSystemMessage = (to: string | null, toName: string | null, type: string, user: any, meetingId: string | null, pin: string | null, body: any | null) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            return await call("POST", '/messaging/v1/system-message', {
                to,
                from: user.twilioUsername,
                payload: {
                    type,
                    body: body ? body : {
                        meetingId,
                        pin,
                        from: user.twilioUsername,
                        toName,
                        fromName: `${user.firstName} ${user.lastName}`,
                    }
                }
            })
        } catch (e: any) {
            dispatch(setError("Error.message.systemMessageFailed"))
        }
    }
}

// closeMeeting resets meeting store
export const closeMeeting = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({type: 'CLOSE_MEETING'})
    }
}
