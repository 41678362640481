import { PopperPlacementType } from "@mui/material";
import React from "react";

type AbstractMenuItem = {
    /** Unique identifier of menu item */
    id: string;
};

interface SeparatorMenuItem extends AbstractMenuItem {
    isSeparator: boolean;
}

interface SimpleMenuItem extends AbstractMenuItem {
    /** Label config of menu item, either i18n or fix value */
    labelKey?: string;
    disabled?: boolean;
    labelValues?: Record<string, string | number | Date | null>;
    labelText?: string;
    /** Rendering style. Menu item will be rendered dangerous, for destructive or not undoable actions. */
    danger?: boolean;
    onClick: () => void;
}

export interface SelectableMenuItem extends SimpleMenuItem {
    icon:
        // FIXME: we have to revisit this type, at runtime it wont be possible to distinguish between a render function
        // and the React functional component.
        // Plus the title attribute doesn't work on SVGs
        | ((props: any) => JSX.Element)
        | React.FC<React.SVGProps<SVGSVGElement> & { title?: string }>;
    /** Highlights the menu item as selected. */
    selected?: boolean;
}

export interface CheckableMenuItem extends SimpleMenuItem {
    checked: boolean;
}

export type FunctionalMenuItem = CheckableMenuItem | SelectableMenuItem;
export type AnyMenuItem = FunctionalMenuItem | SeparatorMenuItem;

export function isSeparatorItem(menuItem: AbstractMenuItem): menuItem is SeparatorMenuItem {
    return "isSeparator" in menuItem;
}
export function isSelectableMenuItem(menuItem: AbstractMenuItem): menuItem is SelectableMenuItem {
    return "icon" in menuItem || "selected" in menuItem;
}
export function isCheckableMenuItem(menuItem: AbstractMenuItem): menuItem is CheckableMenuItem {
    return "checked" in menuItem;
}

export type MenuItemViewProps = {
    menuItem: AnyMenuItem;
    labelRenderer: LabelRenderer;
};

export type LabelRenderProps = {
    className: string,
    labelKey?: string,
    labelText?: string,
    labelValues?: any,
    selected?: boolean,
};

export type LabelRenderer = (props: LabelRenderProps) => JSX.Element;

export type OptionMenuProps = {
    /** Identifier of the OptionMenu. It is rendered into id and data-testid attributes of root component */
    id?: string,
    /** Additional CSS classes */
    className?: string,
    open: boolean,
    anchorEl: any,
    containerEl?: any,
    titleKey?: string,
    placement?: PopperPlacementType,
    menuConfig: AnyMenuItem[],
    handleClose: () => void,
    offset?: [number, number],
    variant?: "light" | "dark";
    renderOptionLabel?: LabelRenderer
}
