import { FeatureFlagNameValues, FeatureFlagSet } from "./types";

export function getEnabledFeatureFlagNames(availableFeatureFlags: FeatureFlagSet): string[] {
    return (Object.keys(availableFeatureFlags) as FeatureFlagNameValues[]).reduce(
        (enabledSwitchesArray: string[], featureName: FeatureFlagNameValues) => {
            const featureFlagValue = availableFeatureFlags[featureName] as boolean;
            if (featureFlagValue) {
                enabledSwitchesArray.push(featureName);
            }
            return enabledSwitchesArray;
        },
        []
    );
}
