import React, {useRef, useState} from "react";
import {Box, Button} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {find} from "underscore";
import ItemCount from "components/ItemCount/ItemCount";
import {useIntl} from "react-intl";
import {COLORS, SIZING} from "theme";
import {outgoingCall, setActiveModal} from "store/actions";
import {AppReduxStore} from "store/reducerTypes";
import OptionMenu from "common/components/OptionMenu/OptionMenu";
import {ReactComponent as VideoCameraSvg} from "icon-library/SVG/Camera_Video.svg";
import {ReactComponent as UsersSvg} from "icon-library/SVG/User_Users.svg";

import {useStyles} from "./styles";
import {MODAL_TYPES, USER_PROFILE, USER_TYPE} from "constant";
import { createSvgIcon, getAge } from "utils";
import { getParticipantFullName } from "components/messaging/utils";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import {ReactComponent as PatientSvg} from "icon-library/SVG/User_Single.svg"
import {ReactComponent as EllipsisSvg} from "icon-library/SVG/Ellipsis-Horizontal_Circle.svg";
import ParticipantName from "common/components/ParticipantName/PN";
import { AnyMenuItem } from "common/components/OptionMenu/types";
import { useHistory } from "react-router-dom";

const MAX_SHOWN_PARTICIPANTS = 3

const Patient = createSvgIcon(PatientSvg)
const VideoCamera = createSvgIcon(VideoCameraSvg)
const Users = createSvgIcon(UsersSvg)
const Ellipsis = createSvgIcon(EllipsisSvg)

// ChatHeader displays a conversations subject, participants and handles additional functionality like initiating adhoc
// calls
const ChatHeader = () => {
    const {openConversationSid, conversationsInfo} = useSelector((state: AppReduxStore) => state.chat)
    const { twilioUsername } = useSelector((state: AppReduxStore) => state.user)
    // TODO: for now he have the "!" as a way to tell typescript that we know a conversationInfo will exist at this point
    // it is the same behavior as before, when the type for ConversationInfoMap assumed ConversationInfo existed for any 
    // given id.
    // But the better future behavior is to check if the conversation exists or not and show and empty page in case it 
    // is not found.
    const {subject, participants = [], patient, conversationType} = conversationsInfo[openConversationSid]!
    const [menuOpen, setMenuOpen] = useState(false)
    const menuRef = useRef(null)
    const classes = useStyles()
    const intl = useIntl()
    const dispatch = useDispatch()
    const {firstName, lastName, id, mrn, dateOfBirth, genderCode} = patient || {}
    const age = getAge(dateOfBirth) || {}
    const filterParticipant = participants.filter((p) => p.isEnabled);
    const history = useHistory()

    // startCall initializes an adhoc call if there is only one other participant in the conversation, otherwise a modal
    // with the list of participants is shown where the user can then choose a participant to start an adhoc call
    const startCall = async () => {
        if (filterParticipant.length <= 2) {
            const to = find(filterParticipant, (participant) => participant.identity !== twilioUsername)
            if (to) {
                let patientProfile = null
                if (to.userType === USER_TYPE.PATIENT) {
                    patientProfile = (to as any)[USER_PROFILE[to.userType]]
                }
                dispatch(outgoingCall(to.identity, getParticipantFullName(to), "adhoc", patientProfile));
            }
        } else {
            dispatch(setActiveModal(MODAL_TYPES.PROVIDER.EVISIT_PARTICIPANTS))
        }
    }

    const menuConfig: AnyMenuItem[] = [
        {
            id: "QA_ChatHeader.option.startEVisit",
            labelKey: "ChatHeader.option.startEVisit",
            icon: VideoCamera,
            onClick: startCall,
        },
        {
            id: "QA_ChatHeader.option.separator.1",
            isSeparator: true,
        },
        {
            id:
            filterParticipant.length <= 2
                    ? "QA_ChatHeader.option.listAllParticipants"
                    : "QA_ChatHeader.option.listAllXParticipants",
            labelKey:
            filterParticipant.length <= 2
                    ? "ChatHeader.option.listAllParticipants"
                    : "ChatHeader.option.listAllXParticipants",
            labelValues: { count: filterParticipant.length },
            icon: Users,
            onClick: () => dispatch(setActiveModal(MODAL_TYPES.PROVIDER.CONVERSATION_PARTICIPANTS)),
        },
    ];

    const navigation = () => {
        id && history.push(`/provider/people/patients/${id}`);
    }
    
    const PatientItem = ({selectedPatient}: any) => {
        if (selectedPatient && conversationType === 'CARETEAM') {
            return (
                <Box className={classes.detailsHeader}>
                     <Box width="100%" display="grid" overflow="hidden" paddingRight={SIZING.scale400}>
                        <CustomTypography className={classes.patientDetailsHeader} variant="conversationPatientDetailsHeader">
                            {intl.formatMessage({id: "ChatHeader.label.PatientDetails"})}
                        </CustomTypography>
                        <Box className={classes.patientDetails} onClick={navigation}>
                            <Patient className={classes.icon}/>
                            <CustomTypography className={classes.patient} variant="conversationPatientName">
                                {lastName}, {firstName}
                            </CustomTypography>
                            <CustomTypography className={classes.patient} variant="conversationPatientDetails">
                                ({age} {genderCode}, {mrn})
                            </CustomTypography>
                        </Box>
                     </Box>
                </Box>
            )
        } else {
            return null
        }
    }

    // If the number of participants is greater then the max number of participants allowed, show a badge for all other
    // participants instead
    let shownParticipants = filterParticipant
    let participantCount = 0
    if (filterParticipant.length > MAX_SHOWN_PARTICIPANTS) {
    shownParticipants = filterParticipant.slice(0,MAX_SHOWN_PARTICIPANTS)
    participantCount = filterParticipant.length - MAX_SHOWN_PARTICIPANTS
    }

    return (
        <Box>
            <Box className={classes.headerWrapper}>
                <Box width="100%" display="grid" overflow="hidden" paddingRight={SIZING.scale400}>
                    <CustomTypography className={classes.subject} variant="conversationHeader" id="QA_message_subject">
                        {subject}
                    </CustomTypography>
                    <Box className={classes.participantList}>
                        {shownParticipants.map((p: any, key) => <ParticipantName key={key} participant={p}/>)}
                        <ItemCount
                            onClick={() => dispatch(setActiveModal(MODAL_TYPES.PROVIDER.CONVERSATION_PARTICIPANTS))}
                            bgColor={COLORS.ITEM_BADGE_BG}
                            count={participantCount}
                            endLabel={intl.formatMessage({id: "ChatHeader.label.more"})}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <Button color="primary" variant="text" className={classes.ellipsis} ref={menuRef} onClick={() => setMenuOpen(!menuOpen)} id="QA_btnEllipsis">
                        <Ellipsis/>
                    </Button>
                    <OptionMenu
                        handleClose={() => setMenuOpen(false)}
                        variant="dark"
                        menuConfig={menuConfig}
                        titleKey="ChatHeader.menu.options"
                        anchorEl={menuRef}
                        open={menuOpen}
                    />
                </Box>
            </Box>
            <PatientItem selectedPatient={patient} />
        </Box>
    )
}

export default ChatHeader;
