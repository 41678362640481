import {Button, Typography} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "admin/store/reducerTypes";
import {Form} from "admin/components/useForm";
import FormField from "common/components/FormField/FormField";
import {setRegistrationValues, verifyOTPFactor, resendOTP, setRegistrationStep} from "admin/store/actions";
import React, { useEffect } from "react";
import {REGISTRATION_STEP} from "constant";
import {useSnackbar} from "notistack";
import SnackbarContent from "admin/components/SnackBars/SnackbarContent";
import {useStyles} from "./styles";

const OTPRegistration = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {enqueueSnackbar} = useSnackbar()
    const intl = useIntl()
    const {otpCode, otpValid, emailVerified} = useSelector((store:AppReduxStore) => store.registration)

    useEffect(() => {
        if (otpValid) {
            if (emailVerified) { //emailVerified -> verify type: (email | SMS)
                dispatch(setRegistrationStep(REGISTRATION_STEP.PATIENT.SET_PASSWORD))
            }
            // TODO: what if emailVerified false?
            // After validating otp reset value
            dispatch(setRegistrationValues({otpValid: false}))
        }
    }, [dispatch, emailVerified, otpValid])

    const onSubmit = (e: any) => {
        e.preventDefault()
        dispatch(verifyOTPFactor())
    }

    const onOTPSend = () => {
        const message = intl.formatMessage({id: "OTPConfirmation.message.resend"})
        enqueueSnackbar(null, {
            preventDuplicate: true, content: (key: number) => (
                <div role="alert">
                    <SnackbarContent snackbarKey={key} type="success" message={message}  />
                </div>
            ),
        })
    }

    const resend = () => dispatch(resendOTP(onOTPSend))

    const onChange = (code: string) => {
        if (/^\d*$/.test(code)) {
            dispatch(setRegistrationValues({otpCode: code}))
        }
    }
    return (
        <Form onSubmit={onSubmit}>
            <FormField
                className={classes.numberField}
                labelId={`OTPConfirmation.label.codeFrom.${emailVerified ? "email" : "sms"}`}
                altLabel="OTPConfirmation.label.resend"
                value={otpCode}
                onChange={onChange}
                altFn={resend}
            />
            <Button type="submit" disabled={!otpCode} variant="outlined" color="primary" fullWidth>
                <Typography variant="button">
                    <FormattedMessage id="PatientRegistration.button.confirm"/>
                </Typography>
            </Button>
        </Form>
    )
}

export default OTPRegistration