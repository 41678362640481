
export const TAB_PRIMARY_ONLY_ID = "QA_careTeam_search_tabs_primaryOnly";
export const TAB_ALL_ID = "QA_careTeam_search_tabs_all";
export const careTeamFilterTabs = [
    {
        id: TAB_ALL_ID,
        labelId: "careTeam.search.tabs.all",
    },
    {
        id: TAB_PRIMARY_ONLY_ID,
        labelId: "careTeam.search.tabs.primaryOnly",
    },
];