import { COLORS, SIZING, toRem } from "theme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
        width: toRem(500),
        borderRadius: SIZING.scale800,
        backgroundColor: COLORS.DIALOG_CONTENT_BG,
        overflow: "hidden",
        boxShadow: `0 ${SIZING.scale100} ${SIZING.scale400} rgba(0, 0, 0, .35)`,
        "& .dialog__header": {
            display: "flex",
            padding: `${SIZING.scale500} ${SIZING.scale500} ${SIZING.scale500} ${SIZING.scale600}`,
            backgroundColor: COLORS.GREY,
            "& button": {
                height: SIZING.scale750,
                fontSize: SIZING.scale400,
                lineHeight: toRem(22.4)
            },
            "& button + button": {
                marginLeft: SIZING.scale200
            }
        },
        "& .dialog__dangerAction": {
            "&:hover":{
                background:COLORS.DANGER
              },
              background:COLORS.DANGER,
        },
        "& .dialog__title": {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            color: COLORS.WHITE,
            fontSize: toRem(17),
            fontWeight: 700
        },
        "& .dialog__body": {
            position: "relative",
            color: COLORS.WHITE,
            padding: SIZING.scale600,
            wordBreak: "break-word"
        },
        "& .dialog__closeIcon": {
            cursor: "pointer"
        },
        "& .dialog__loaderRow": {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backdropFilter: "Blur(1.5px)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            "& svg": {
                "& g, & path": {
                    fill: COLORS.WHITE
                }
            }
        },
        "&.dialog--loading .dialog__content": {
            opacity: 0.2
        },
        "& .dialog__error": {
            backgroundColor: COLORS.ERROR_BANNER_BG,
            color: COLORS.WHITE,
            padding: SIZING.scale400,
            fontWeight: 700,
        }
    },
}));