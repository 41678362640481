import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() =>({
    contentWrapper:{
        boxShadow: "0 1px 4px rgba(0, 0, 0, .05)",
        padding: `${SIZING.scale400}`,
        border: `1.5px solid ${COLORS.PRIORITY_BG}`,
        backgroundColor: "rgba(255, 0, 0, 0.04)",
        display: 'flex',
        borderRadius: BORDER_RADIUS.MEDIUM
    },
    dismissBtn: {
        background: '#FFF',
        boxShadow: '0 0 2px rgba(0, 0, 0, .25), 0 2px 2px rgba(33, 33, 49, .24)',
        borderRadius: '64px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#0B5D8F',
        height: 'unset',
        minWidth: 'unset'
    },
    details:{
        marginLeft: SIZING.scale100,
        display: 'flex',
        "& .main-details-wrapper": {
            width: "100%"
        },
        "& .title": {
            fontSize: "14px",
            color: COLORS.NOTIFICATION_TEXT
        },
        "& .alert-heading": {
            fontWeight: 700
        },
        "& .alert-section-details": {
            fontSize: SIZING.scale400,
            fontWeight: 600,
            textTransform: 'uppercase',
            color: COLORS.NOTIFICATION_TEXT
        },
        "& .alert-field-details": {
            fontSize: SIZING.scale400,
            marginLeft: SIZING.scale400,
            color: "#000",
            fontWeight: 'unset',
            display:'flex',
            alignItems:'center'
        },
        "& .iconProp": {
            display: 'flex',
            alignSelf: 'flex-start'
        },
        flex: 1
    }
}));