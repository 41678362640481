import React from "react";
import MessageThreads from "./MessageThreads/MessageThreads";
import {Box} from "@mui/material";
import Chat from "./Chat/Chat";

// Messages renders the message threads along with the chat messages for the currently open conversation
const Messages = () => {
    return (
        <Box marginRight="10px" display="flex" width="100%" height="100vh">
            <MessageThreads />
            <Box width="100%" marginRight="10px">
                <Chat />
            </Box>
        </Box>
    )
}

export default Messages