import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";
import * as CSS from "./class-names";
import * as MessageContainerCSS from "lib/ui-components/message-container/class-names";
import * as SearchListCSS from "lib/ui-components/search-list/class-names";

export const useStyles = makeStyles(() => ({
    [CSS.CareTeam]: {
        gridTemplateRows: "8rem auto",
        gridTemplateColumns: "27.25rem auto",
        [`& .${CSS.CareTeamSearchHeader}`]: {
            display: "grid",
            gridTemplateAreas: `"search add"
                "tabs tabs"`,
            gridTemplateColumns: `auto ${SIZING.scale1200}`,
            gap: SIZING.scale400,
        },
        [`& .${CSS.CareTeamSearchHeaderSearchField}`]: {
            gridArea: "search",
            [`& .${CSS.CareTeamSearchHeaderSearchFieldStartIcon}`]: {
                paddingLeft: SIZING.scale400,
                margin: 0,
                "& path": {
                    fill: COLORS.GRAY_50,
                },
            },
            [`& .${CSS.CareTeamSearchHeaderSearchFieldEndIcon}`]: {
                cursor: "pointer",
                paddingRight: SIZING.scale100,
                "& path": {
                    fill: COLORS.LGRAY,
                },
            },
            "& input": {
                textIndent: 0,
            },
        },
        [`& .${CSS.CareTeamSearchHeaderAdd}`]: {
            gridArea: "add",
            padding: 0,
            width: SIZING.scale1200,
            "& svg": {
                height: SIZING.scale800,
            },
        },
        [`& .${CSS.CareTeamSearchHeaderTabs}`]: {
            gridArea: "tabs",
            // TODO Revise Is it really needed here? We might need a custom Tab component?
            "& .tab": {
                padding: SIZING.scale175,
                height: SIZING.scale750,
                "& p": {
                    flexGrow: 1,
                },
            },
            "& .tabSelected": {
                border: `${SIZING.scale50} solid`,
                borderColor: COLORS.SEARCH_FILTER_TAB_BG,
                color: "black",
                "& .count": {
                    background: "black",
                },
            },
            "& .count": {
                height: SIZING.scale400,
                width: SIZING.scale600,
                marginTop: "1px",
                marginLeft: 0,
                "& span": {
                    display: "flex",
                    fontSize: SIZING.scale275,
                    fontWeight: 700,
                },
            },
        },
        [`& .${CSS.CareTeamSearchList}`]: {
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            [`& .${CSS.CareTeamSearchListItem}`]: {
                display: "flex",
                flexDirection: "column",
                fontSize: "18px",
                fontWeight: 400,
                gap: SIZING.scale300,
                margin: SIZING.scale100,
                "& > div": {
                    display: "flex",
                    justifyContent: "space-between",
                },
                "& .name": {
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "350px",
                },
            },
            [`& .${SearchListCSS.SearchListItem}:last-child`]: {
                display: "block",
                marginBottom: SIZING.scale800,
            },
            [`& .${CSS.CareTeamSearchListEmptyMessage}`]: {
                margin: "0 12%",
            },
            "& .component-loader-wrapper": {
                position: "inherit",
                "&:after": {
                    content: "unset",
                },
            },
        },
        [`& .${CSS.CareTeamDetailsHeader}`]: {
            [`& .${CSS.CareTeamDetailsHeaderTitle}`]: {
                color: COLORS.GREY,
                padding: `${SIZING.scale100} 0`,
            },
            [`& .${CSS.CareTeamDetailsHeaderSubTitle}`]: {
                color: COLORS.GRAY_50,
                marginBottom: SIZING.scale300,
            },
        },
        [`& .${CSS.CareTeamDetailsContent}`]: {
            display: "flex",
            flexDirection: "column",
            padding: `${SIZING.scale400} ${SIZING.scale800}`,
        },
        [`& .${CSS.CareTeamDetailsContentSearchField}`]: {
            marginBottom: SIZING.scale500,
            [`& .${CSS.CareTeamDetailsContentSearchFieldStartIcon}`]: {
                paddingLeft: SIZING.scale400,
                margin: 0,
                opacity: 0.5,
                "& path": {
                    fill: COLORS.DARK_ICON,
                },
            },
            "& input": {
                textIndent: SIZING.scale200,
                "&::placeholder": {
                    color: COLORS.DARK_ICON,
                    opacity: 0.5,
                },
            },
        },
        [`& .${CSS.CareTeamDetailsContentMembersEmptyMessage}`]: {
            margin: "auto",
        },
    },
    [CSS.CareTeamEditor]: {
        "& [role=\"dialog\"]": {
            height: "calc(100% - 64px)",
            maxHeight: "860px",
        },
        [`& .${CSS.CareTeamEditorHeaderPlaceholder}`]: {
            display: "flex",
            gap: SIZING.scale400,
            marginTop: SIZING.scale700,
            "& .teamNameInput": {
                "& .inputWithTranscription__input": {
                    marginBottom: 0,
                },
                "&__mic svg": {
                    marginBottom: "-.5rem",
                },
            },
            "& .createButton": {
                padding: `${SIZING.scale400} ${SIZING.scale1000}`,
                whiteSpace: "nowrap",
            },
            [`& .${MessageContainerCSS.MessageContainerMessage}`]: {
                marginLeft: SIZING.scale360,
            },
        },
        [`& .${CSS.CareTeamEditorContent}`]: {
            padding: 0,
            width: "860px",
            display: "flex",
            [`& .${CSS.CareTeamEditorPatientsSearchList}`]: {
                marginTop: SIZING.scale400,
            },
            [`& .${CSS.CareTeamEditorPatientsEmptyMessage}`]: {
                height: "100%",
            },
        },
        [`& .${CSS.CareTeamEditorProviderSearchEmptyMessage}`]: {
            height: "100%",
        },
    },
}));
