import { EventKeys, EventMap } from "lib/domain/events";

import { IEventProvider, IListener } from "./types";
export class EventProvider implements IEventProvider {
    addEventListener<K extends EventKeys>(
        eventType: K,
        listener: IListener<K>
    ): void {
        document.addEventListener(eventType, listener);
    }

    removeEventListener<K extends EventKeys>(
        eventType: K,
        listener: IListener<K>
    ): void {
        document.removeEventListener(eventType, listener);
    }

    // Defined as property function for convenience, this way we don't need to bind it
    dispatchEvent = <K extends EventKeys>(
        eventType: K,
        detail: EventMap[K]
    ): void => {
        const event = new CustomEvent<EventMap[K]>(eventType, { detail });
        document.dispatchEvent(event);
    }
}
