import { Box, Typography } from "@mui/material";
import { ReactComponent as WaitThickSvg } from "icon-library/SVG/Wait_Thick.svg";
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useStyles } from "./styles";
import { LoadingIndicatorProps } from "./types";
import { useIntl } from "react-intl";
import { createSvgIcon } from "../../../utils";
import moment from "moment";
const WaitThickIcon = createSvgIcon(WaitThickSvg);
const LoadingIndicator = ({ open }: LoadingIndicatorProps) => {
    const classes = useStyles();
    const { loadingMessages } = useSelector((state: AppReduxStore) => state).loading
    const intl = useIntl()
    const loadingMessage = loadingMessages?.length > 0 && loadingMessages.find(m => m.title) as any
    const timeDiff = moment().diff(moment(loadingMessage?.timestamp), 'seconds')
    return (<>
        {loadingMessages?.length > 0 && timeDiff >= 0 &&
            <Box className={classes.overlay}>
                <Box className={classes.overlayInner}>
                    <Box className={classes.overlayContent}>
                        <Box>
                            <WaitThickIcon className={classes.loadingIndicatorIcon} />
                        </Box>
                        <Box className={classes.loadingTextWrapper}>
                            <Typography color="inherit" variant="h2" className={classes.loaderTitle}>
                                {loadingMessage?.title ? intl.formatMessage({ id: `${loadingMessage?.title}` }) : intl.formatMessage({ id: "LoadingIndicator.defaultTitle" })}
                            </Typography>
                            < Typography color="inherit" variant="subtitle1" className={classes.loaderDesc}>
                                {loadingMessage?.description ? intl.formatMessage({ id: `${loadingMessage?.description}` }) : intl.formatMessage({ id: "LoadingIndicator.defaultDescription" })}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>}
    </>)
}

LoadingIndicator.defaultProps = {
    open: false,
}

export default LoadingIndicator