import {Button, Typography} from "@mui/material";
import {Form} from "admin/components/useForm";
import FormField from "common/components/FormField/FormField";
import {FormattedMessage} from "react-intl";
import {useSelector, useDispatch} from "react-redux";
import React, { useEffect, useState } from "react";
import {AppReduxStore} from "admin/store/reducerTypes";
import {validatePasswords} from "../utils";
import {setRegistrationValues, completeRegistrationPatient} from "admin/store/actions";
import PasswordValidation from "../../Registration/PasswordValidation/PasswordValidation";
import {useOktaAuth} from "@okta/okta-react";
import {setError} from "store/actions/error";
import { signInApp } from "authentication/appAuth";

const SetPassword = () => {
    const dispatch = useDispatch()
    const registration = useSelector((store: AppReduxStore) => store.registration)
    const {password, passwordConfirm, regToken, complete, email} = registration
    const [loading, setLoading] = useState(false)
    const {oktaAuth} = useOktaAuth()

    const onChange = (key: string) => {
        return (value: any) => {
            dispatch(setRegistrationValues({[key]: value}))
        }
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        dispatch(completeRegistrationPatient())
    }

    // Once the email and password have been set, log the user in and route to the verify info page
    useEffect(() => {
        if (complete) {
            setLoading(true)
            // get session token using email and password
            signInApp({ username: email, password })
                .then((res: any) => {
                    const sessionToken = res.sessionToken;
                    // route to verify patient info page
                    const verifyURI = "/patient/verify/" + regToken
                    oktaAuth.setOriginalUri(verifyURI)
                    oktaAuth.token.getWithRedirect({
                        sessionToken: sessionToken,
                        responseType: 'token'
                    }).then(() => {
                        setLoading(false)
                    }).catch((err: any) => {
                        setLoading(false)
                        dispatch(setError("",err.errorSummary))
                    });
                })
                .catch((err: any) => {
                    setLoading(false)
                    dispatch(setError("",err.errorSummary))
                });
        }
    }, [complete, dispatch, email, oktaAuth, password, regToken])

    const {valid: validPasswords} = validatePasswords(registration)
    const passwordConfirmErrorId = password === passwordConfirm ? "" : "Registration.error.passwordMatch"
    const submitDisabled = loading || !validPasswords

    return (
        <Form onSubmit={onSubmit} name="setPasswordForm">
            <FormField labelId="PatientRegistration.label.password" value={password} onChange={onChange("password")} type="password" />
            <FormField errorId={passwordConfirmErrorId} labelId="PatientRegistration.label.passwordConfirm"
                       value={passwordConfirm} onChange={onChange("passwordConfirm")} type="password" />

            <PasswordValidation />

            <Button type="submit" disabled={submitDisabled} variant="outlined" color="primary" fullWidth>
                <Typography variant="button">
                    <FormattedMessage id="PatientRegistration.button.next" />
                </Typography>
            </Button>

        </Form>
    )
}

export default SetPassword