import React, { } from "react"
import { useStyles } from "./styles"
import SideNav from "./side-nav/side-nav"
import PatientDetails from "./patient-details/patient-details"

const RemoteMonitoring = () => {

    const classes = useStyles()

    return (
        <div className={classes.rootWrapper}>
            <SideNav />
            <PatientDetails />
        </div>
    )
}

export default RemoteMonitoring