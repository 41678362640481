import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Box, Typography, Button, Grid } from "@mui/material";
import moment from 'moment';
import { AppReduxStore } from 'admin/store/reducerTypes';
import Controls from 'admin/components/Controls/Controls';
import { useStyles } from './styles';
import { changeAccountStatus, setActiveModal, setSelectedPatientsInfo } from 'admin/store/actions';
import { MODAL_TYPES } from 'admin/constant';
import { ReactComponent as FlagSvg } from "icon-library/SVG/Flag.svg";
import { createSvgIcon } from "../../../../utils";
const FlagIcon = createSvgIcon(FlagSvg);

const PatientDetails = () => {
    const { selectedPatient } = useSelector((state: AppReduxStore) => state).admin;
    const [accountStatus, setAccountStatus] = useState(selectedPatient?.status === "ACTIVE")
    const dispatch = useDispatch();
    const classes = useStyles();
    const intl = useIntl();
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 1000);
        }
    }, [pathname, hash, key]); // do this on route change

    const borders = {
        Active: '2px solid #00A875',
        Pending: '2px solid #F5A800',
        Inactive: '2px solid #E32B29',
    };

    let border = '';
    switch (selectedPatient.status) {
        case "ACTIVE": border = borders['Active']; break;
        case "PENDING": border = borders['Pending']; break;
        default: border = borders['Inactive']; break;
    }

    const handleToggleChange = (e: any) => {
        setAccountStatus(e.target.checked)
        dispatch(changeAccountStatus([selectedPatient.profileRoleId], e.target.checked ? "ACTIVE" : "INACTIVE"));

    };

    const handleResetPassword = () => {
        dispatch(setSelectedPatientsInfo([selectedPatient.profileId]));
        dispatch(setActiveModal(MODAL_TYPES.RESET_PATIENT_PASSWORD));
    };
    const handleEditPatient = () => {
        dispatch(setActiveModal(MODAL_TYPES.EDIT_PATIENT));
    }
    return (
        <Fragment>
            <Box className={classes.usersWrapper} style={{ position: "relative" }}>
                <Grid container className={classes.usersTopActionWrapper}>
                    <Typography color="textPrimary" className={classes.userName}>
                        {selectedPatient && `${selectedPatient.firstName} ${selectedPatient.lastName}`}
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xs>
                        <Typography color="textPrimary" className={classes.heading}>
                            {intl.formatMessage({ id: "PatientDetails.Label.AccountManagement" })}
                        </Typography>
                    </Grid>
                    <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
                        <Box style={{ border: border, padding: '4px 8px', fontSize: '14px', fontWeight: 600, borderRadius: '4px', width: '100px', textAlign: 'center' }}>
                            {selectedPatient.status ?? "INACTIVE"}
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Button id="btnResetPassword" variant="outlined" color="primary" style={{ float: 'right' }} onClick={handleResetPassword}>
                            Reset Password
                        </Button>
                    </Grid>
                </Grid>
                <Grid container className={classes.controlContainer}>
                    <Grid item xs={3} className={classes.toggleSwitch}>
                        <Controls.ToggleSwitch
                            label="Account Status"
                            checked={accountStatus}
                            onChange={handleToggleChange}
                            value={accountStatus ? 'Active' : 'Inactive'}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            label="Last Log In"
                            value={selectedPatient.lastLoginTime && moment(selectedPatient.lastLoginTime).format("MM/DD/YYYY HH:MM")}
                            readOnlyLabel={true}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.patientDetailsTopHeading}>
                            {intl.formatMessage({ id: "PatientDetails.Label.Personal" })}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Button id="btnEditInfo" variant="outlined" color="primary" style={{ float: 'right' }} onClick={handleEditPatient}>
                            {intl.formatMessage({ id: "PatientDetails.Button.EditInfo" })}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.patientDetailsTypography}>
                            {intl.formatMessage({ id: "PatientDetails.Label.Information" })}</Typography>
                    </Grid>
                </Grid>
                <Box className={classes.contolsWrapper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="First Name"
                                value={selectedPatient?.firstName}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Middle Name"
                                value={selectedPatient?.middleName}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Last Name"
                                value={selectedPatient?.lastName}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Box>{(selectedPatient?.age < 18) && <FlagIcon fill={"#FF0000"} className={classes.flagIcon} />}
                                <Controls.Input
                                    label="Date of Birth"
                                    value={selectedPatient.dateOfBirth}
                                    readOnlyLabel={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="SSN"
                                value={selectedPatient?.ssn}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="MRN"
                                value={selectedPatient?.ehrPatientId ?? selectedPatient?.mrn}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Primary Language"
                                value={selectedPatient.primaryLanguage}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Primary provider"
                                value={selectedPatient?.primaryProvider?.firstName + " " + selectedPatient?.primaryProvider?.lastName}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.patientDetailsTypography}>
                            {intl.formatMessage({ id: "PatientDetails.Label.Gender" })}</Typography>
                    </Grid>
                </Grid>
                <Box className={classes.contolsWrapper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Sex at Birth"
                                value={selectedPatient?.sex}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Legal Sex"
                                value={selectedPatient?.legalSex}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Gender Identity"
                                value={selectedPatient?.genderIdentity}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Sex Orientation"
                                value={selectedPatient?.sexualOrientation}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Pronouns"
                                value={selectedPatient?.preferredPronouns}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.patientDetailsTypography}>
                            {intl.formatMessage({ id: "PatientDetails.Label.Contact" })}</Typography>
                    </Grid>
                </Grid>
                <Box className={classes.contolsWrapper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Mobile Phone"
                                value={selectedPatient?.cellPhoneNumber}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Secondary Phone"
                                value={selectedPatient?.homePhoneNumber}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Email"
                                value={selectedPatient?.patientEmails?.length > 0 ? selectedPatient?.patientEmails[0]?.email : selectedPatient?.emailAddress}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.patientDetailsTypography}>
                            {intl.formatMessage({ id: "PatientDetails.Label.Address" })}</Typography>
                    </Grid>
                </Grid>
                <Box className={classes.contolsWrapper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Address 1"
                                value={selectedPatient?.address}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Address 2"
                                value={selectedPatient?.address2}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <Controls.Input
                                label="City"
                                value={selectedPatient?.city}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Input
                                label="State"
                                value={selectedPatient?.state}
                                readOnlyLabel={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Input
                                label="Zip"
                                value={selectedPatient?.zipcode}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {selectedPatient?.contacts?.length > 0 && <><Grid container>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.patientDetailsTopHeading}>
                            {intl.formatMessage({ id: "PatientDetails.Label.Contacts" })}</Typography>
                    </Grid>
                </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography color="textPrimary" className={classes.patientDetailsTypography}>
                                {intl.formatMessage({ id: "PatientDetails.Label.Emergency" })}</Typography>
                        </Grid>
                    </Grid>
                </>
                }
                {selectedPatient?.contacts && selectedPatient?.contacts.map((contact: any, index: number) =>
                    <Box className={classes.contolsWrapper}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="First Name"
                                    value={contact?.firstName}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Last Name"
                                    value={contact?.lastName}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Relation to Patient"
                                    value={contact?.relationship}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Primary Phone"
                                    value={contact?.mobilePhone}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Secondary Phone"
                                    value={contact?.homePhone}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Email"
                                    value={contact?.email}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {
                    selectedPatient?.supportContacts?.length > 0 && <Grid container>
                        <Grid item id="supportNetwork">
                            <Typography color="textPrimary" className={classes.patientDetailsTypography}>
                                {intl.formatMessage({ id: "PatientDetails.Label.PatientSupportNetwork" })}</Typography>
                        </Grid>
                    </Grid>
                }
                {selectedPatient?.supportContacts && selectedPatient?.supportContacts.map((supportContact: any, index: number) =>
                    <Box className={classes.contolsWrapper}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="First Name"
                                    value={supportContact?.firstName}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Last Name"
                                    value={supportContact?.lastName}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Relation to Patient"
                                    value={supportContact?.contactRelation?.contactRelationName}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Primary Phone"
                                    value={supportContact?.primaryPhone}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Secondary Phone"
                                    value={supportContact?.secondaryPhone}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Email"
                                    value={supportContact?.primaryEmail}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label="Altais Status"
                                    value={supportContact?.status}
                                    readOnlyLabel={true}
                                />
                            </Grid>
                        </Grid>
                        <br />
                    </Box>
                )}
                <Grid container>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.patientDetailsTopHeading}>
                            {intl.formatMessage({ id: "PatientDetails.Label.Insurance" })}</Typography>
                    </Grid>
                </Grid>
                {/* {selectedPatient?.insurances && selectedPatient?.insurances.filter((insurance: { priority: number; }) => insurance?.priority == 1).map((insurance: any, index: number) => */}
                {selectedPatient?.insurances && selectedPatient?.insurances.sort((a: { priority: number; }, b: { priority: number; }) => (a.priority > b.priority) ? 1 : -1).map((insurance: any, index: number) =>
                    <>
                        <Grid container>
                            <Grid item>
                                <Typography color="textPrimary" className={classes.patientDetailsTypography}>
                                    {insurance?.priority === "1" &&
                                        intl.formatMessage({ id: "PatientDetails.Label.Primary" })}
                                    {insurance?.priority === "2" &&
                                        intl.formatMessage({ id: "PatientDetails.Label.Secondary" })}
                                    {insurance?.priority === "3" &&
                                        intl.formatMessage({ id: "PatientDetails.Label.Tertiary" })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box className={classes.contolsWrapper}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Controls.Input
                                        label="Plan Name"
                                        value={insurance?.planName}
                                        readOnlyLabel={true} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controls.Input
                                        label="Plan Type"
                                        value={insurance?.planType}
                                        readOnlyLabel={true} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controls.Input
                                        label="Plan ID"
                                        value={insurance?.planId}
                                        readOnlyLabel={true} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Controls.Input
                                        label="Plan ID type"
                                        value={insurance?.planIdType}
                                        readOnlyLabel={true} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controls.Input
                                        label="Member ID"
                                        value={insurance?.memberNumber}
                                        readOnlyLabel={true} />
                                </Grid>
                            </Grid>
                        </Box></>)}
            </Box>
        </Fragment>
    );
}

export default PatientDetails;
