import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "png/theme";

export const useStyles = makeStyles(() =>({
    root: {
        height: '100vh',
        width: '100%',
        position: 'relative',
       "& p":{
           color:COLORS.WHITE,
           zIndex:2,
           fontSize:SIZING.scale500,
       }
    },
    contentWrapper:{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:'column',
        padding:SIZING.scale1000,
        textAlign:'center',
        "& .close_browser":{
            marginTop:SIZING.scale300
        }
    },
    mainContentWrapper:{
        padding:SIZING.scale600,
        zIndex:2,
    },
    linkExpired:{
        padding:SIZING.scale400,
        zIndex:2,
        "& p":{
            marginBottom:SIZING.scale300
        }
    },
    bg2:{
        background: 'radial-gradient(96% 85% at 45% 60%, rgba(0, 0, 0, 0) 0, #000 79%)',
        filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, .25))',
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    bg: {
        height: '100%',
        width: '100%',
        background: 'radial-gradient(141% 58% at 53% 34%, rgba(0, 0, 0, 0) 0, #000 104%)',
        filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, .25))',
        position: 'absolute'
    }
}));