import React, { useState } from "react";
import classNames from "classnames";
import { Input } from "@mui/material";
import { ReactComponent as Microphone } from "icon-library/SVG/Microphone.svg";
import { ChangeEvent, Permissions } from "types";
import TranscribeMedicalModal from "components/Modals/TranscribeMedical/TranscribeMedicalModal";
import { FeatureFlagNames } from "lib/security/types";
import { useFeatureFlag } from "lib/security/feature-flag-hooks";
import { usePermission } from "lib/security/permission-hooks";
import { useStyles } from "./styles";

type injectableClassNames = "root" | "inputRow" | "input" | "microphoneRow" | "microphoneIcon";

type InputWithTranscriptionProps = {
    /** Id attribute of the input tag. */
    id: string;
    className?: string;
    classes?: Partial<Record<injectableClassNames, string>>;
    disabled?: boolean;
    label?: string;
    maxLength?: number;
    multiline?: boolean;
    onChange: (content: string) => void;
    onKeyPress?: (event: any) => void;
    placeholder?: string;
    value?: string;
    error?: boolean;
};

const InputWithTranscription = ({
    id,
    label,
    classes: classesProp,
    className,
    disabled = false,
    maxLength,
    multiline = true,
    onChange,
    onKeyPress,
    placeholder = "",
    value = "",
    error,
}: InputWithTranscriptionProps) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const transcribeServiceFlag = useFeatureFlag(FeatureFlagNames.E_TRANSCRIPTION);
    const transcribeServicePermission = usePermission(Permissions.ETRANSCRIPT);
    const isTranscribeEnabled = transcribeServiceFlag && transcribeServicePermission;
    const handleModal = (): void => {
        if (!disabled) {
            setOpen((prevOpen) => !prevOpen);
        }
    };
    const onContentChange = (event: ChangeEvent): void => {
        onChange(event.target.value);
    };
    const onTranscriptionChange = (content: string): void => {
        value = maxLength ? content.substr(0, maxLength) : content;
        onChange(value);
        setOpen(false);
    };

    return (
        <div className={classNames(classes.inputWithTranscription, className, classesProp?.root, {
            'inputWithTranscription--transcribe-enabled': isTranscribeEnabled
        })}>
            {label && (
                <label className={classes.inputLabel} htmlFor={id}>
                    {label}
                </label>
            )}
            <div className={classNames(classes.inputRow, classesProp?.inputRow)}>
                <Input
                    id={id}
                    classes={{ root: classes.input }}
                    className={classNames("inputWithTranscription__input", classesProp?.input)}
                    disabled={disabled}
                    disableUnderline
                    multiline={multiline}
                    onChange={onContentChange}
                    onKeyPress={onKeyPress}
                    placeholder={placeholder}
                    value={value}
                    error={error}
                    inputProps={{
                        maxLength,
                    }}
                />
                {isTranscribeEnabled && <div className={classNames(classesProp?.microphoneRow)}>
                    <Microphone id={`${id}_microphone`}
                        data-testid={`${id}_microphone`}
                        onClick={handleModal}
                        className={classNames(classes.microphone, { active: open, disabled }, classesProp?.microphoneIcon)}
                    />
                </div>}
            </div>
            {open && isTranscribeEnabled && (
                <TranscribeMedicalModal initialValue={value} onChange={onTranscriptionChange} multiline={multiline} />
            )}
        </div>
    );
};

export default InputWithTranscription;
