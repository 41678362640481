import React, { useMemo, useEffect, useState } from "react";
import { debounce, map } from "underscore";
import Modal from "components/Modal/Modal";
import { MODAL_TYPES } from "constant";
import {
    Box,
    Button,
    Typography
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { composeNewMessage, setActiveModal, searchRecipientsBySearchString, searchPatients } from "store/actions";
import { SIZING } from "theme"
import { AppReduxStore } from "store/reducerTypes";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import MultiSelectPopoverInput from "components/MultiSelectPopoverInput/MultiSelectPopoverInput";
import MultiSelectItem from "components/MultiSelectPopoverInput/MultiSelectItem";
import { ListItem } from "../../MultiSelectPopoverInput/types";
import { PATIENT_SEARCH } from "views/People/constants";
import { useForm } from "common/components/FormValidator/FormValidator";
import ErrorMessage from "common/components/ErrorMessage/ErrorMessage";
import { Props } from "./types";
import InputWithTranscription from "common/components/InputWithTranscription/InputWithTranscription";

const ComposeNewMessage = () => {
    const { recipients } = useSelector((state: AppReduxStore) => state.chat)
    const { username } = useSelector((state: AppReduxStore) => state.user)
    const { activeModalProps } = useSelector((store: AppReduxStore) => store.modal);
    const { patients } = useSelector((store: AppReduxStore) => store.patient)
    const type = (activeModalProps as Props)?.type;
    const participantDetail = (activeModalProps as Props)?.participantDetail;
    const [searchText, setSearchText] = useState("")
    const [searchTextPatient, setSearchTextPatient] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [pinnedItems, setPinnedItems] = useState<Array<ListItem> | null>(null)
    const [pinnedItemsPatient, setPinnedItemsPatient] = useState<ListItem | null>(null)
    const [searchedRecipients, updateSearchedRecipients] = useState<Array<ListItem>>([])
    const [searchedPatientRecipients, updateSearchedPatientRecipients] = useState<Array<ListItem>>([])
    const [searchLoader, updateSearchLoader] = useState<Boolean>(false)
    const isColleagueCompose = type === "colleague"
    const classes = useStyles()
    const intl = useIntl()
    const dispatch = useDispatch()

    const {
        validateAll,
        renderMessage,
        resetForm
    } = useForm()

    useEffect(()=>{
        if(participantDetail){
            if(type==='patient'){
                setPinnedItems(participantDetail)
            }else{
                setPinnedItems([participantDetail])
            }
        }
    },[participantDetail,type])

    const searchRecipients = useMemo(() => debounce((searchStr: string, type: string) => {
        if (searchStr.length >= 2) {
            updateSearchLoader(true)
            searchRecipientsBySearchString(searchStr, username, type).then((data: any) => {
                updateSearchLoader(false)
                updateSearchedRecipients(data)
            }).catch(() => {
                updateSearchedRecipients([])
            })
        }
    }, 250), [username])

    const searchPatient = useMemo(() => debounce((searchStr: string) => {
        dispatch(searchPatients(searchStr, PATIENT_SEARCH.MESSAGES))
    }, 250), [dispatch])

    useEffect(() => {
        searchRecipients(searchText.trim(), isColleagueCompose ? "CARETEAM" : "PATIENT")
    }, [searchText, searchRecipients, isColleagueCompose])

    useEffect(() => {
        searchPatient(searchTextPatient.trim())
    }, [searchPatient, searchTextPatient])

    useEffect(() => {
        if (pinnedItemsPatient !== null) {
            setSearchTextPatient("")
        }
    }, [pinnedItemsPatient])

    useEffect(() => {
        setSearchText("")
    }, [pinnedItems])

    useEffect(() => {
        return updateSearchedPatientRecipients(map(patients as any, (patient) => {
            return {
                ...patient,
                name: `${patient.firstName} ${patient.lastName}`,
                twilioUsername: patient.twilioIdentity ? patient.twilioIdentity : patient.id
            }
        }))
    }, [patients])

    const clearFields = () => {
        setSearchText("")
        setSubject("")
        setMessage("")
        setPinnedItems(null)
        setPinnedItemsPatient(null)
        updateSearchedRecipients([])
        updateSearchedPatientRecipients([])
        setSearchTextPatient("")
        resetForm(true)
    }
    const onClose = () => {
        if(pinnedItems || subject || message){
         const result = window.confirm("Are you sure you want to discard the message?");
         if(result){
            onCloseCompose()
         }
        }else{
            onCloseCompose()
        }
    }

    const onCloseCompose = () => {
        dispatch(setActiveModal(""))
        clearFields()
    }

    const onChange = (event: any, onValueChange: any) => {
        onValueChange(event.target.value)
    }

    const onRecipientsChange = (text: "") => {
        setSearchText(text)
    }

    const onComposeNewMessage = () => {
        if (validateAll()) {
            const patientProfileId = pinnedItemsPatient?.id;
            dispatch(composeNewMessage(subject.trim(), message.trim(), pinnedItems, patientProfileId, type))
            onCloseCompose()
        }
    }

    const renderedSearchList = searchedRecipients.filter((sr) => {
        return !recipients[sr.profileId]
    })

    const renderHeader = () => (
        <Box className={classes.header}>
            <Box marginBottom={SIZING.scale400} className={classes.flexWrapper}>
                <Typography variant="body1" id={`QA_ComposeNewMessage${type}`}>
                    <FormattedMessage id={`ComposeNewMessage.${type}.title.composeANewMessage`} />
                </Typography>
                <Close className={classes.close} onClick={onClose} id="QA_btnClose" />
            </Box>
            <Box className={classes.flexWrapper}>
                <Button onClick={onComposeNewMessage}
                  id={`QA_btnSendTo${type}`} className={classes.send}>
                    <Typography variant="button">
                        <FormattedMessage id={`ComposeNewMessage.${type}.button.send`} />
                    </Typography>
                </Button>
            </Box>
        </Box>
    )

    const renderItem = (item: any, changePinnedItems: any, pinnedItemsDetails: any, isMultiSelect: boolean, searchString: string) => {
        const selected = isMultiSelect ?
            pinnedItemsDetails.findIndex((result: any) => result.twilioUsername === item.twilioUsername) !== -1 :
            !!pinnedItemsDetails && pinnedItemsDetails.twilioUsername === item.twilioUsername
        return (
            <MultiSelectItem
                item={item}
                selected={selected}
                key={item.id || item.profileId}
                onChange={() => changePinnedItems(item)}
                highlightText={searchString}
                themeColor="compose"
            />
        )
    }

    if (!type) {
        return ""
    }

    const recipientError = renderMessage('Recipient', (isColleagueCompose ? 'validateEmpty' : 'required'), pinnedItems)

    const subjectLineError = renderMessage('subject line:1600', 'stringRequired|maxLength', subject)

    const messageError = renderMessage('message:1600', 'stringRequired|maxLength', message)

    return (
        <Modal onClose={clearFields} id={MODAL_TYPES.PROVIDER.COMPOSE_NEW_MESSAGE}>
            {renderHeader()}
            <div className={classes.content}>
                <MultiSelectPopoverInput
                    data={isColleagueCompose ? searchedRecipients : renderedSearchList}
                    id="recipients"
                    qaid={`QA_select${type}`}
                    value={searchText}
                    onChange={(event: any) => onChange(event, onRecipientsChange)}
                    placeholder={intl.formatMessage({
                        id: `ComposeNewMessage.${type}.input.addRecipients`,
                    })}
                    title={intl.formatMessage({
                        id: `ComposeNewMessage.${type}.multi.title`,
                    })}
                    pinnedItems={isColleagueCompose ? (pinnedItems || []) : pinnedItems}
                    setPinnedItems={setPinnedItems}
                    renderItem={renderItem}
                    loader={searchLoader}
                    isMultiSelect={isColleagueCompose}
                    themeColor="compose"
                    resetSuggestion={() => {
                        updateSearchedRecipients([])
                    }}
                />
                <ErrorMessage message={recipientError} className={classes.errorClass} />

                {isColleagueCompose && (
                    <Box display="flex" alignItems="center">
                        <Typography variant="h4" className={classes.REText} component="div">
                            <FormattedMessage id={`ComposeNewMessage.${type}.input.searchPatient.label`} />
                        </Typography>
                        <MultiSelectPopoverInput
                            data={searchedPatientRecipients}
                            value={searchTextPatient}
                            // errorId={sendAttempted && patientError ? patientError : "" }
                            onChange={(event: any) => onChange(event, setSearchTextPatient)}
                            qaid="QA_includePatientDetails"
                            placeholder={intl.formatMessage({
                                id: `ComposeNewMessage.${type}.input.searchPatient`,
                            })}
                            title={intl.formatMessage({
                                id: `ComposeNewMessage.patient.input.addRecipients`,
                            })}
                            pinnedItems={pinnedItemsPatient}
                            setPinnedItems={setPinnedItemsPatient}
                            renderItem={renderItem}
                            loader={searchLoader}
                            isMultiSelect={false}
                            themeColor="compose"
                        />
                    </Box>
                )}
                <InputWithTranscription id="subjectLine"
                    onChange={(content: string) => setSubject(content)}
                    placeholder={intl.formatMessage({ id: `ComposeNewMessage.${type}.input.subject` })}
                    value={subject}
                />
                <ErrorMessage message={subjectLineError} className={classes.errorClass} />
                <InputWithTranscription id="message"
                    className="messageInput"
                    multiline
                    onChange={(content: string) => setMessage(content)}
                    placeholder={intl.formatMessage({ id: `ComposeNewMessage.${type}.input.composeYourMessage` })}
                    value={message}
                />
                <ErrorMessage message={messageError} className={classes.errorClass} />
            </div>
        </Modal>
    )
}

export default ComposeNewMessage;
