import {
    Box, Button, Grid, IconButton, InputBase, Paper, Table, TableBody,
    TableCell, TableContainer, TableFooter, TableHead, TableRow,
    TableSortLabel, Typography
} from "@mui/material";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import GridContextMenu from 'admin/components/GridContextMenu/GridContextMenu';
import FeeScheduleGroupsGridContextMenu from 'admin/components/GridContextMenuContent/FeeScheduleGroupsGridContextMenu/FeeScheduleGroupsGridContextMenu';
import { MODAL_TYPES, USER_LEVELS } from 'admin/constant';
import { setActiveModal } from 'admin/store/actions';
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from "admin/store/reducerTypes";
import { ReactComponent as EllipsisV } from "icon-library/SVG/Ellipsis-Vertical.svg";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSvgIcon } from "utils";
import { useStyles } from './styles';

const EllipsisVIcon = createSvgIcon(EllipsisV);

type Order = 'asc' | 'desc';

type BillingGroupProps = {
    billingGroupId: string,
    billingGroupName: string,
    feeSchedulefileCount: number,
    regions: any
};

const useFeeScheduleGroupsListing = (orgId: string, orderBy: keyof BillingGroupProps, order: Order, name?: string) => {
    const { content, fetchInfo, request } = useApi<{}, BillingGroupProps[]>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: [],
        url: `/admin/v1/billing/group/organization/${orgId}?sortedBy=${orderBy}&sortAsc=${(order === 'asc') ? true : false}${name ? `&name=${name}` : ""}`,
        method: HttpMethod.GET
    });

    useEffect(() => {
        request({});
    }, [request, orgId, orderBy, order, name]);

    return {
        feeScheduleGroups: content,
        hasResult: fetchInfo.hasResult,
        request,
        error: fetchInfo.error,
    };
};

const FeeScheduleGroupsListing = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { organization, auth } = useSelector((state: AppReduxStore) => state);
    const { currentPractice, currentProfileRoleJoinInfo } = auth;
    const orgId = organization?.sOrganization?.id || currentProfileRoleJoinInfo?.organization?.id || currentPractice?.organizationId;
    const [orderBy, setOrderBy] = useState<keyof BillingGroupProps>('billingGroupName');
    const [order, setOrder] = useState<Order>('asc');
    const [searchStr, setSearchStr] = useState<string>();
    const [contextMenuData, setContextMenuData] = useState({} as any);
    const searchInput = useRef<any>();
    const { feeScheduleGroups, hasResult, request } = useFeeScheduleGroupsListing(orgId, orderBy, order, searchStr);

    const handleSearch = (event: any) => {
        if (orgId) {
            const searchValue = event.target.value.trim();
            if (searchValue !== "") {
                setSearchStr(searchValue);
            } else {
                setSearchStr(undefined);
            }
        }
    };

    const handleRowAction = (type: string, rowData: BillingGroupProps) => {
        const billingGroupId = rowData.billingGroupId;
        const billingGroupName = rowData.billingGroupName;
        switch (type) {
            case 'ViewEditLocations':
                if (billingGroupName) {
                    dispatch(setActiveModal(MODAL_TYPES.FEE_SCHEDULES_LOCATIONS, { "view-edit-locations-billingGroupId": billingGroupId, "view-edit-locations-billingGroupName": billingGroupName, orgId: orgId }));
                }
                break;
            case 'ViewFeeSchedule':
                if (billingGroupId) {
                    dispatch(setActiveModal(MODAL_TYPES.FEE_SCHEDULES_VIEWING_MODAL, { "view-fee-schedule-billingGroupId": billingGroupId, "view-fee-schedule-billingGroupName": billingGroupName }));
                }
                break;
            default:
                dispatch(showNotification("TODO", "error", "Action Not Implemented"));
        }
    };

    const handleAddFeeSchedulesGroup = () => {
        dispatch(setActiveModal(MODAL_TYPES.FEE_SCHEDULES_ADD_GROUP, {
            "fee-schedule-add-group-orgId": orgId,
            onFinishedAddingGroup: () => {
                request({});
            }
        }));
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof BillingGroupProps) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: keyof BillingGroupProps) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const handleEllipsesActionClick = (event: any, row: any, index: number) => {
        event.preventDefault();
        setContextMenuData({ mouseX: event.clientX - 194, mouseY: event.clientY - 25, row, index })
    };

    const handleRowContextMenuClose = (event: any) => {
        setContextMenuData({ mouseX: 0, mouseY: 0, row: null, index: null })
    };

    const RowContextMenu = () => {
        const row = contextMenuData?.row
        if (!row) return
        return (<GridContextMenu mouseX={contextMenuData?.mouseX} mouseY={contextMenuData?.mouseY} onClose={handleRowContextMenuClose}
            callback={(type: any) => {
                handleRowAction(type, row);
            }}
            formNumber={""}
            formTitle={""}
            handleFormRow={null}
            gridContextMenuContent={FeeScheduleGroupsGridContextMenu}
            index={contextMenuData?.index}
            hideContextMenuHeader={true} />
        )
    }

    const getRegionNames = (content: any): string => {
        const regions: string[] = content.substring(1, content.length - 1).split(",");
        const billingGroups = regions.map((region: string, index: number) => {
            if ((region.charAt(0) === '"') && (region.charAt(region.length - 1) === '"')) {
                return region.substring(1, region.length - 1);
            } else {
                return region;
            }
        }).join(", ");
        return (regions.length === 0) ? "" : billingGroups;
    };

    const [addFeeScheduleGroupDisabled, setAddFeeScheduleGroupDisabled] = useState<boolean>(false);

    const checkFeeScheduleGroupButton = useCallback(() => {
        const levelName = currentProfileRoleJoinInfo?.level?.name;
        const ALTAIS_ADMINS = [USER_LEVELS.ALTAIS_ADMIN_1, USER_LEVELS.ALTAIS_ADMIN_2, USER_LEVELS.ALTAIS_ADMIN_3];
        const ORG_ADMINS = [USER_LEVELS.ORG_ADMIN_1, USER_LEVELS.ORG_ADMIN_2];
        const BILLING_ADMINS = [USER_LEVELS.BILLING_ADMIN];
        const addFeeScheduleAllowed = (ALTAIS_ADMINS.includes(levelName) || ORG_ADMINS.includes(levelName) || BILLING_ADMINS.includes(levelName));
        if (!addFeeScheduleAllowed) {
            setAddFeeScheduleGroupDisabled(true);
        }
    }, []);

    useEffect(() => {
        checkFeeScheduleGroupButton();
    }, [orgId])

    return (<>
        <Box className={classes.usersWrapper}>
            <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '32px', lineHeight: '140%', color: '#393948', marginTop: '0px' }}>Fee Schedule Groups</p>
            <Grid container>
                <Grid item xs>
                    <Paper component="form" className={classes.root}>
                        <IconButton id="btnSerachFeeSchedules" className={classes.iconButton} aria-label="search" size="large">
                            <SearchIcon />
                        </IconButton>
                        <InputBase id="txtSearchFeeSchedules"
                            type="search" ref={searchInput}
                            className={classes.input}
                            onChange={handleSearch}
                            onKeyPress={(ev: any) => { if (ev.key === 'Enter') { ev.preventDefault() } }}
                            placeholder="Search groups by name"
                            inputProps={{ 'aria-label': 'Search groups by name' }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Button id="btnAddFeeSchedule" disabled={addFeeScheduleGroupDisabled} variant="contained" color="primary" style={{ float: 'right', marginRight: '20px' }} onClick={handleAddFeeSchedulesGroup}>
                        {'Add a Group'}
                    </Button>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <TableContainer className={classes.stickyHeader}>
                    <Table id="tableFeeSchedulesListing"
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width={'50%'}
                                    key={"billingGroupName"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "billingGroupName" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "billingGroupName"}
                                        direction={orderBy === "billingGroupName" ? order : 'asc'}
                                        onClick={createSortHandler("billingGroupName")}>
                                        <strong style={{ marginLeft: "41px" }}>
                                            {'Group Name'}
                                        </strong>
                                        {orderBy === "billingGroupName" ?
                                            (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    width={'25%'}
                                    key={"regions"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "regions" ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === "regions"}
                                        direction={orderBy === "regions" ? order : 'asc'}
                                        onClick={createSortHandler("regions")}
                                    >
                                        <strong>{'Region'}</strong>
                                        {orderBy === "regions" ?
                                            (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    width={'20%'}
                                    key={"feeSchedulefileCount"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "feeSchedulefileCount" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "feeSchedulefileCount"}
                                        direction={orderBy === "feeSchedulefileCount" ? order : 'desc'}
                                        onClick={createSortHandler("feeSchedulefileCount")}
                                    >
                                        <strong>{'Fee Schedule Files'}</strong>
                                        {orderBy === "feeSchedulefileCount" ?
                                            (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted ascending' : 'sorted descending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width={'5%'} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                feeScheduleGroups.map((row: BillingGroupProps, index: number) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (<>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell id={labelId} scope="row" padding="none" width={'50%'}>
                                                <Grid style={{ display: "flex", alignItems: "center", flexBasis: 'none' }}>
                                                    <Typography noWrap variant="body1" style={{ marginLeft: "41px", color: '#393948', width: '250px', fontSize: '14px', fontWeight: 600 }}> {row.billingGroupName}</Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="left" width={'25%'}>{getRegionNames(row.regions)}</TableCell>
                                            <TableCell align="left" width={'20%'}>{`${row.feeSchedulefileCount} ${(row.feeSchedulefileCount < 2) ? ` File` : ` Files`}`}</TableCell>
                                            <TableCell align="right" width={'5%'} className={classes.stickyLastColumn}>
                                                <IconButton id={"btnRowActionContextMenu" + index} style={{ padding: '10px' }} onClick={(e) => handleEllipsesActionClick(e, row, index)} size="large">
                                                    <EllipsisVIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                    );
                                })
                            }
                            {contextMenuData?.row && RowContextMenu()}
                        </TableBody>
                        <TableFooter>
                            {hasResult && feeScheduleGroups && feeScheduleGroups.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No Records
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    </>
    );
}

export default FeeScheduleGroupsListing;