import React from "react";
import { useIntl } from 'react-intl';
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as UserSingleDisableIcon } from 'icon-library/SVG/User_Single-Disable.svg';
import { ReactComponent as KeyIcon } from 'icon-library/SVG/Key.svg';
import { ReactComponent as EnvelopeIcon } from 'icon-library/SVG/eMail_Closed-Envelope.svg';
import { ReactComponent as RadioCircleIcon } from 'icon-library/SVG/Radio_Circle.svg';
import { BulkActionContentMenuProps } from 'admin/components/BulkActionMenuContent/types';
import { useStyles } from '../../BulkActionMenu/styles';

const PatientBulkActionMenu = ({ callback, handleClose }: BulkActionContentMenuProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <List className={classes.baMenu}><ListItem />
            <ListItem button>
                <ListItemIcon>
                    <UserSingleDisableIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baDeactivateAccount" primary={intl.formatMessage({ id: "BulkActionMenu.DeactivatePatients" })} classes={{ primary: classes.listText }} onClick={() => { callback("Deactivate"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <KeyIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baResetPasswords" primary={intl.formatMessage({ id: "BulkActionMenu.ResetPasswords" })} classes={{ primary: classes.listText }} onClick={() => { callback("ResetPasswords"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <EnvelopeIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baSendInvites" primary={intl.formatMessage({ id: "BulkActionMenu.SendInvites" })} classes={{ primary: classes.listText }} onClick={() => { callback("SendInvites"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <RadioCircleIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baActivateAccount" primary={intl.formatMessage({ id: "BulkActionMenu.ActivatePatients" })} classes={{ primary: classes.listText }} onClick={() => { callback("Activate"); handleClose() }} />
            </ListItem>
        </List>
    );
}

export default PatientBulkActionMenu;
