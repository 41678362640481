import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { NotificationItem } from "store/notification-types";
import { updateNotificationReadStatus, updateNotificationReadStatusByPredicate } from "store/actions";

const useNotificationUpdate = () => {
    const dispatch = useDispatch();

    const setNotificationReadStatusById = useCallback(
        (notificationRecipientId: string) => {
            dispatch(updateNotificationReadStatus(notificationRecipientId));
        },
        [dispatch]
    );

    const setNotificationReadStatusByPredicate = useCallback(
        (predicate: (notification: NotificationItem) => boolean) => {
            dispatch(updateNotificationReadStatusByPredicate(predicate));
        },
        [dispatch]
    );

    return {
        setNotificationReadStatusById,
        setNotificationReadStatusByPredicate,
    };
};

export default useNotificationUpdate;
