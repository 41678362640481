import { makeStyles } from "@mui/styles";
import { COLORS } from "admin/theme";
export const useStyles = makeStyles(() => ({
  bulkUploadInstructionHeading: {
    color: COLORS.LIST_SUBHEADER_TEXT,
    fontSize: '20px',
    fontWeight: 600,
    marginRight: '22px'
  },
  bulkUploadInstructionSubHeading: {
    color: COLORS.LIST_SUBHEADER_TEXT,
    fontSize: '16px',
  },
  links: {
    textDecoration: 'underline !important',
    color: '#0f7bbd'
  }
}));