import { List, Menu, Box } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import { GridContextMenuProps } from "./types";
import { useDispatch } from "react-redux";
import { setActiveModal } from 'admin/store/actions';


const GridContextMenu = ({ mouseX = 0, mouseY = 0, onClose, handleFormRow, formNumber, formTitle, callback, gridContextMenuContent, index, showActiveAccount, showDeActiveAccount, showEnable, showDisable, showEditProducts, showResetPassword, showUnlockAccount, showSendInvite, showSuspendAccount, showRestoreAccount, hideContextMenuHeader }: GridContextMenuProps) => {
    const GridContextMenuContent = gridContextMenuContent;
    const dispatch = useDispatch();
    const initialState = {
        mouseX: mouseX,
        mouseY: mouseY
    };
    const [state, setState] = useState(initialState);
    const classes = useStyles({ x: state.mouseX, y: state.mouseY });
    useEffect(() => {
        setState(initialState)
    }, [mouseX, mouseY])
    const handleModalClick = () => {
        handleFormRow(formNumber, formTitle);
        dispatch(setActiveModal("EDIT_STATUS"));
        setState(initialState);
    }
    const handleClose = () => {
        setState({
            mouseX: 0,
            mouseY: 0
        });
    };

    return (
        <Fragment>
            <Menu
                PopoverClasses={{ paper: classes.menuContent }}
                elevation={0}
                keepMounted
                open={state.mouseY !== 0}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <List className={classes.root}>
                    {!hideContextMenuHeader && (<Box className={classes.menuTitle}>
                        <FormattedMessage id={'GridContextMenu.Header'} />
                    </Box>)}
                    <GridContextMenuContent handleModalClick={handleModalClick} callback={(type: any) => { callback(type) }} handleClose={() => { handleClose() }}
                        showActiveAccount={showActiveAccount} showDeActiveAccount={showDeActiveAccount} showEnable={showEnable}
                        showDisable={showDisable} showEditProducts={showEditProducts} showResetPassword={showResetPassword} showUnlockAccount={showUnlockAccount} showSendInvite={showSendInvite} showSuspendAccount={showSuspendAccount} showRestoreAccount={showRestoreAccount} />

                </List>
            </Menu>
        </Fragment>
    );
}

export default GridContextMenu;
