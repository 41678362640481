import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
    listItemWrapper: {
        background: '#F6F6F7',
        marginTop: '8px',
        cursor: "pointer",
        padding: '5px 0px',
        '&:hover': {
            boxShadow: '0 0 2px rgba(0, 0, 0, .25), 0 1px 4px rgba(33, 33, 49, .1)',
            background: "#E4E4E6"
        }
    },
    listItemWrapperActive: {
        background: '#E4E4E6',
        marginTop: '8px',
        cursor: "pointer",
        padding: '5px',
        boxShadow: '0 0 2px rgba(0, 0, 0, .25), 0 1px 4px rgba(33, 33, 49, .1)',
    },
    popoverRoot: {
        background: '#F6F6F7',
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        padding: '8px',
        overflowX: 'unset',
        overflowY: 'unset',
        "&::before": {
            content: '""',
            position: "absolute",
            marginRight: "-0.71em",
            top: -23,
            left: '50px',
            width: 24,
            height: 24,
            background: '#F6F6F7',
            transform: "translate(-50%, 50%) rotate(315deg)",
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 8px rgba(0, 0, 0, 0.2)',
            clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
        }
    },
    popoverIcon: {
        display: 'flex',
        alignItems: 'center',
        flexBasis: 'none',
        paddingRight: '25px'
    },
    chevronUpDownIcon: {
        "& path": {
            fill: '#000000'
        },
        height: '16px',
        width: '16px',
    }
}))