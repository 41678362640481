import { Box, Button, DialogActions, DialogContent, IconButton, Paper, Typography } from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { makeApiCall } from 'admin/common/utils';
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from "admin/store/reducerTypes";
import Locations from "admin/views/FeeSchedules/components/locations/locations";
import { AddGroupResponse, AssignedPractice } from "admin/views/FeeSchedules/components/locations/types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";

/*
type BillingGroupInfoProps = {
    billingSchedule?: string
    description: string
    id: string
    name: string
    organizationId: string
    practiceId?: string
    regionInfos?: string
    status: string
};

type PracticeLocationBillingGroupProps = {
    billingGroupInfo: BillingGroupInfoProps[]
    id: string,
    locationId: string,
    practiceId: string
}

const getAllSelectedLocations = async (practiceLocationBillingGroups: PracticeLocationBillingGroupProps[]) => {
    const allSelectedLocations: any[] = [];
    await Promise.all(practiceLocationBillingGroups.map(async (practiceLocationBillingGroup: PracticeLocationBillingGroupProps) => (
        await call("GET", `/admin/v1/practice/${practiceLocationBillingGroup.practiceId}`).then(async (practice) => {
            await call("GET", `/admin/v1/region/region-practice-location/practice/${practiceLocationBillingGroup.practiceId}/location/${practiceLocationBillingGroup.locationId}`).then((regionPracticeLocation: RegionPracticeLocationProps) => {
                allSelectedLocations.push({
                    locations: regionPracticeLocation.locationInfo,
                    practice: practice,
                    regionPracticeLocation: regionPracticeLocation
                })
            })
        })
    )))
    return allSelectedLocations;
}
*/

const FeeSchedulesLocations = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { modalAdmin } = useSelector((state: AppReduxStore) => state);
    const billingGroupId = modalAdmin?.modalProps?.["view-edit-locations-billingGroupId"];
    const billingGroupName = modalAdmin?.modalProps?.["view-edit-locations-billingGroupName"];
    const orgId = modalAdmin?.modalProps?.orgId;

    const onClose = async () => {
        setSelectedPractices([]);
        dispatch(setActiveModal(""));
    }

    const [selectedPractices, setSelectedPractices] = useState<any[]>([]);
    const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
    const [isRegionReady, setIsRegionReady] = useState<boolean>(false);
    const [groupName, setGroupName] = useState<string>("");

    const handleSaveChanges = async () => {
        const selectedRegionsList: string[] = selectedRegions.map((region: any) => { return region.id; });
        const selectedPracticesList: string[] = selectedPractices.map((practice: AssignedPractice) => { return practice.practice.id; });

        const params: any = {
            id: billingGroupId,
            name: groupName,
            orgId: orgId,
            regionIds: selectedRegionsList,
            assignedPracticeIds: selectedPracticesList
        };
        await makeApiCall(dispatch, "POST", `/admin/v1/billing/group`, params).then((payload: AddGroupResponse) => {
            dispatch(showNotification(`Billing group ${payload.name} has been modified successfully.`, "success", `Billing group ${payload.name} has been modified successfully.`));
        }).catch((error: any) => {
            dispatch(showNotification("Error modifing billing group.", "error", error?.message));
        });
    }

    const handleSaveExit = () => {
        handleSaveChanges();
        onClose();
    }

    const fetchSelectedRegions = useCallback(async (billingGroupId: string) => {
        await makeApiCall(dispatch, "GET", `/admin/v1/billing/group/${billingGroupId}`).then((payload: any) => {
            const regionInfos = payload.regionInfos;
            const practiceInfos = payload.practiceInfos || [];
            const feeScheduleGroupName = payload.name;
            setSelectedRegions(regionInfos);
            const allPractices: AssignedPractice[] = [];
            for (const assignedPractice of practiceInfos) {
                allPractices.push({
                    practice: {
                        id: assignedPractice.id,
                        name: assignedPractice.name
                    },
                    locations: {
                        id: "",
                        name: "",
                        practiceId: assignedPractice.id,
                        address1: assignedPractice.address1 || "",
                        address2: assignedPractice.address2 || "",
                        city: assignedPractice.city || "",
                        state: assignedPractice.state || "",
                        postalCode: assignedPractice.postalCode || "",
                        country: ""
                    }
                });
            }
            setSelectedPractices(allPractices);
            setGroupName(feeScheduleGroupName);
            setIsRegionReady(true);
        });
    }, [])

    useEffect(() => {
        if (billingGroupId) {
            setIsRegionReady(false);
            fetchSelectedRegions(billingGroupId);
        }
    }, [billingGroupId])

    return (
        <Modal onClose={onClose} classes={classes.wrapper} id={MODAL_TYPES.FEE_SCHEDULES_LOCATIONS}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {`Assigned Practices - ${billingGroupName}`}
                    </Typography>
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {`Add or remove assigned practices to/from the fee schedule group`}
                    </Typography>
                </Box>
                <Box>
                    <IconButton size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Button id="btnDiscardChanges" variant="contained" color="primary" style={{ marginLeft: '10px', marginBottom: '10px' }} onClick={handleSaveChanges}>
                        {`Save Changes`}
                    </Button>
                    <Button id="btnSaveLocation" variant="contained" color="primary" style={{ marginLeft: '20px', marginRight: '40px', marginBottom: '10px' }} onClick={handleSaveExit}>
                        {`Save & Exit`}
                    </Button>
                </Box>
            </DialogActions>
            <DialogContent style={{ padding: '0px' }} dividers={true} >
                <Paper style={{ width: '950px', height: '500px' }} >
                    {isRegionReady && <Locations
                        orgId={orgId}
                        selectedRegions={selectedRegions}
                        selectedPractices={selectedPractices}
                        onChange={setSelectedPractices}
                    />}
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default FeeSchedulesLocations