import { getUserService } from "lib/service-manager";
import { ApiCommunicationContext } from "../types";

const appendAuthHeader = async <TRequest, TResponse, TContent>(
    ctx: ApiCommunicationContext<TRequest, TResponse, TContent>
): Promise<ApiCommunicationContext<TRequest, TResponse, TContent>> => {
    // Skip appending authentication header
    if (ctx.request.flags?.skipAuth) {
        return ctx;
    }
    const originalHeaders = ctx.request.headers ?? {};
    const idToken = getUserService().getAuthToken();
    if (idToken) {
        originalHeaders["Authorization"] = `Bearer ${idToken}`;
    }
    ctx.request.headers = originalHeaders;
    return ctx;
};

export default appendAuthHeader;
