import createReducer from '../createReducer'
import { ConfigurationState } from '../reducerTypes'

const initialState: ConfigurationState = {
	patientForms: [],
	filteredForms: [],
	sPatientFormA: {},
	sPatientFormAAppointmentTypes: [],
	sPatientFormATriggers: [],
	visitTypes: [],
	rules: [],
	selectedVisitType: {},
	cancellationTypes: [],
	notificationTriggers: [],
	questionnaireNotificationTriggers: [],
	waitingRoom: {},
	registrationEmailPatients: {},
	registrationEmailProvider: {},
	sRegistrationEmailPatientsA: {},
	sRegistrationEmailProviderA: {},
	configSetupFlagActive: false,
	isFormReadOnly: true,
	patientFormHeaders: [],
	patientFormInstructions: [],
	patientFormInstructionsUpdates: {},
	formErrors: {},
	allFormVersioning: [],
	approverList: [],
	questionnaireApproval: {}
}

export default createReducer(initialState, {
	'SET_PATIENT_FORMS': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { patientForms } = payload;
		state.filteredForms = patientForms;
		return {
			...state,
			...payload,
			patientForms
		}
	},

	'SET_SELECTED_PATIENT_FORM': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { sPatientFormA } = payload
		return {
			...state,
			...payload,
			sPatientFormA
		}
	},

	'SET_SELECTED_PATIENT_FORM_APPOINTMENT_TYPES': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { sPatientFormAAppointmentTypes } = payload
		return {
			...state,
			...payload,
			sPatientFormAAppointmentTypes
		}
	},

	'SET_SELECTED_PATIENT_FORM_TRIGGERS': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { sPatientFormATriggers } = payload
		return {
			...state,
			...payload,
			sPatientFormATriggers
		}
	},

	'SET_SELECTED_PATIENT_FORM_READ_ONLY': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { isFormReadOnly } = payload
		return {
			...state,
			...payload,
			isFormReadOnly
		}
	},

	'SET_PATIENT_FORM_HEADERS': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { patientFormHeaders } = payload
		return {
			...state,
			...payload,
			patientFormHeaders
		}
	},

	'SET_PATIENT_FORM_INSTRUCTIONS': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { patientFormInstructions } = payload
		return {
			...state,
			...payload,
			patientFormInstructions
		}
	},

	'SET_PATIENT_FORM_INSTRUCTIONS_UPDATES': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { patientFormInstructionsUpdates } = payload
		return {
			...state,
			...payload,
			patientFormInstructionsUpdates
		}
	},

	'SET_VISIT_TYPES': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { visitTypes } = payload
		return {
			...state,
			...payload,
			visitTypes
		}
	},

	'SET_RULES': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { rules } = payload
		return {
			...state,
			...payload,
			rules
		}
	},

	'SET_FORM_ERRORS': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { formErrors } = payload
		return {
			...state,
			...payload,
			formErrors
		}
	},

	'SET_SELECTED_VISIT_TYPE': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { selectedVisitType } = payload
		return {
			...state,
			...payload,
			selectedVisitType
		}
	},

	'SET_CANCELLATION_TYPES': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { cancellationTypes } = payload
		return {
			...state,
			...payload,
			cancellationTypes
		}
	},

	'SET_NOTIFICATION_TRIGGERS': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { notificationTriggers } = payload
		return {
			...state,
			...payload,
			notificationTriggers
		}
	},

	'SET_QUESTIONNAIRE_NOTIFICATION_TRIGGERS': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { questionnaireNotificationTriggers } = payload
		return {
			...state,
			...payload,
			questionnaireNotificationTriggers
		}
	},

	'SET_QUESTIONNAIRE_APPOINTMENT_TYPES': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { visitTypes } = payload
		return {
			...state,
			...payload,
			visitTypes
		}
	},

	'SET_WAITING_ROOM': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { waitingRoom } = payload
		return {
			...state,
			...payload,
			waitingRoom
		}
	},
	'SET_REGISTRATION_EMAIL_PATIENT': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { registrationEmailPatients } = payload
		return {
			...state,
			...payload,
			registrationEmailPatients
		}
	},
	'SET_REGISTRATION_EMAIL_CLINICAL_STAFF': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { registrationEmailProvider } = payload
		return {
			...state,
			...payload,
			registrationEmailProvider
		}
	},
	'SET_SELECTED_REGISTRATION_EMAIL_PATIENT': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { sRegistrationEmailPatientsA } = payload
		return {
			...state,
			...payload,
			sRegistrationEmailPatientsA
		}
	},
	'SET_SELECTED_REGISTRATION_EMAIL_CLINICAL_STAFF': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { sRegistrationEmailProviderA } = payload
		return {
			...state,
			...payload,
			sRegistrationEmailProviderA
		}
	},
	'SET_CONFIG_SETUP_FLAG_ACTIVE': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState => {
		const { configSetupFlagActive } = payload
		return {
			...state,
			...payload,
			configSetupFlagActive
		}
	},
	'SET_NEW_VERSION_NUMBER': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState =>{
		const { sPatientFormA } = payload;
		return {
			...state,
			...payload,
			sPatientFormA
		}
	},
	'GET_ALL_FORM_VERSIONING': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState =>{
		const {allFormVersioning} = payload;
		return {
			...state,
			...payload,
			allFormVersioning
		}
	},
	'GET_APPROVER_LIST': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState =>{
		const {approverList} = payload;
		return {
			...state,
			...payload,
			approverList
		}
	},
	'SET_QUESTIONNAIRE_APPROVAL': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState =>{
		const {questionnaireApproval} = payload;
		state.sPatientFormA.formStatus = questionnaireApproval.approvalStatus;
		return {
			...state,
			...payload,
			questionnaireApproval
		}
	},
	'SET_FORM_PUBLISHED': (state: ConfigurationState, payload: ConfigurationState): ConfigurationState =>{
		const { sPatientFormA } = payload;
		return {
			...state,
			...payload,
			sPatientFormA
		}
	}
})