import { Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { editPractice } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import Controls from "../../Controls/Controls";
import SnackbarContent from "../../SnackBars/SnackbarContent";
import { Form, useForm } from '../../useForm';
import { useStyles } from "./styles";
import { US_STATES } from 'admin/constant';
import { validateNoSpecialCharacters, validatePostalCode, validateRequired } from 'admin/common/utils';
import { createSvgIcon } from "utils";
import { ReactComponent as Building_Wide } from "icon-library/SVG/Building_Wide.svg";
import { TinMask } from 'admin/components/Controls/InputMasked';
import PracticeEHRConnection from "admin/components/PracticeEHRConnection/PracticeEHRConnection";
import { getProductsForPractice } from "admin/store/actions/license";

const EditPracticeForm = forwardRef((props: any, ref) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { practice, license } = useSelector((state: AppReduxStore) => state)
    const { sPracticeLicenses } = license
    const coreLicenseCount = sPracticeLicenses && sPracticeLicenses.find((core: any) => core.product.code === "CORE")?.licenseCount
    const hasCoreLicense = coreLicenseCount ? coreLicenseCount > 0 : false
    const { sPractice } = practice
    const [states,] = useState(US_STATES);
    const [isLicensesFetched, SetLicensesFetched] = useState(false);
    const BuildingIcon = createSvgIcon(Building_Wide);
    const refEHRConnection = useRef<any>()
    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            }
        }),
    );
    useEffect(() => {
        if (practice.sPractice?.id && !isLicensesFetched) {
            dispatch(getProductsForPractice(practice.sPractice?.id, true))
            SetLicensesFetched(true)
        }
    }, [dispatch, practice.sPractice?.id])
    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('name' in fieldValues) {
            err.name = validateRequired(fieldValues.name, intl)
            if (err.name === "") err.name = validateNoSpecialCharacters(fieldValues.name, intl)
        }

        if ('postalCode' in fieldValues) {
            err.postalCode = ""
            if (fieldValues.city.trim().length > 0 && fieldValues.state.trim().length > 0) {
                err.postalCode = validateRequired(fieldValues.postalCode, intl)
            }
            if (err.postalCode === "") err.postalCode = validatePostalCode(fieldValues.postalCode, intl)
        }

        if ('secretCode' in fieldValues) {
            err.secretCode = validateRequired(fieldValues.secretCode, intl)
        }

        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    };

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm({
        "address1": sPractice?.address1,
        "address2": sPractice?.address2,
        "city": sPractice?.city,
        "ehrPracticeId": sPractice?.practiceEhrSettingInfo?.ehrPracticeId,
        "ehrSourceSystemId": sPractice?.ehrSourceSystemId,
        "id": sPractice?.id,
        "name": sPractice?.name,
        "organizationId": sPractice?.organizationId,
        "postalCode": sPractice?.postalCode,
        "patientPortalUrl": sPractice?.patientPortalUrl,
        "practiceIntegrationProperties": sPractice?.practiceEhrSettingInfo?.practiceIntegrationProperties,
        "redoxDestinationId": null,
        "redoxSourceId": null,
        "schema": "",
        "state": sPractice?.state,
        "secretCode": (sPractice?.secretCode) ? Buffer.from(sPractice?.secretCode, "base64").toString("ascii") : "",
        "tin": sPractice?.tin,
        "absorbDepartmentId": sPractice?.absorbDepartmentId
    }, false, validate);

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (validate()) {
            let ehrValid = false
            let ehrInfo = null
            if (refEHRConnection.current) {
                ehrInfo = refEHRConnection.current.getEHRConnectionValues()
                ehrValid = ehrInfo ? ehrInfo.valid : true
                if (ehrValid) {
                    values["practiceIntegrationProperties"] = ehrInfo?.practiceIntegrationProperties
                    values["ehrSourceSystemId"] = ehrInfo?.ehrSourceSystemId ? ehrInfo?.ehrSourceSystemId : ""
                    values["ehrPracticeId"] = ehrInfo?.ehrPracticeId ? ehrInfo?.ehrPracticeId : null
                } else {
                    return false
                }
            }
            dispatch(editPractice(values))
            resetForm()
            return true
        }

        enqueueSnackbar(null, {
            preventDuplicate: true, content: (key) => (<div role="alert"><SnackbarContent snackbarKey={key} type={'error'}
                message={intl.formatMessage({ id: "Error" })} desc="MISSING REQUIRED FIELD. PLEASE COMPLETE BEFORE SAVING" /></div>
            ),
        })
        return false
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item className={classes.pageHeader}>
                    <Typography className={classes.pageHeaderText}>
                        <BuildingIcon fill="#0F7BBD" className={classes.editIcon} />
                        {intl.formatMessage({ id: "EditPracticeForm.Heading.PracticeInfo" })}
                    </Typography>
                    <Typography>
                        {intl.formatMessage({ id: "EditPractice.Heading.PracticeDesc" })}
                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            {intl.formatMessage({ id: "EditPractice.Heading.PracticeInfo" })}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="name"
                                label="Name *"
                                value={values.name}
                                onChange={handleInputChange}
                                error={errors.name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            {intl.formatMessage({ id: "EditPractice.Heading.PracticeAddress" })}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item spacing={2} xs={4}>
                            <Controls.Input
                                name="address1"
                                label="Address 1"
                                value={values.address1}
                                onChange={handleInputChange}
                                error={errors.address1}
                            />
                        </Grid>
                        <Grid item spacing={2} xs={4}>
                            <Controls.Input
                                name="address2"
                                label="Address 2"
                                placeholder="Additional"
                                value={values.address2}
                                onChange={handleInputChange}
                                error={errors.address2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="city"
                                label="City"
                                placeholder="City"
                                value={values.city}
                                onChange={handleInputChange}
                                error={errors.city}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Select
                                name="state"
                                label="State"
                                placeholder="Select"
                                value={values.state}
                                onChange={handleInputChange}
                                options={states}
                                error={errors.state}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Input
                                name="postalCode"
                                label="Zip Code"
                                value={values.postalCode}
                                onChange={handleInputChange}
                                error={errors.postalCode}
                                maxLength={10}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            {intl.formatMessage({ id: "EditPractice.Heading.PracticeSecretCode" })}
                        </Typography>
                        <Typography>
                            {intl.formatMessage({ id: "EditPractice.Heading.PracticeSecretCodeDesc" })}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="secretCode"
                                label="Secret Code *"
                                placeholder="Enter Code"
                                value={values.secretCode}
                                onChange={handleInputChange}
                                error={errors.secretCode}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            {intl.formatMessage({ id: "EditPractice.Heading.PracticeEHR" })}
                        </Typography>
                        <Typography>
                            {intl.formatMessage({ id: "EditPractice.Heading.PracticeEHRDesc" })}
                        </Typography>
                    </Grid>
                    <PracticeEHRConnection ref={refEHRConnection} isCoreProductSelected={hasCoreLicense} isSelectedPractice={true} />
                </Grid>

                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            {intl.formatMessage({ id: "EditOrgInfo.Header.OrganizationOtherInfo" })}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="patientPortalUrl"
                                label="Patient Portal URL"
                                value={values.patientPortalUrl}
                                onChange={handleInputChange}
                                error={errors.patientPortalUrl}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="tin"
                                label="Tax ID Number (TIN)"
                                placeholder="Enter Here"
                                value={values.tin}
                                onChange={handleInputChange}
                                error={errors.tin}
                                inputComponent={TinMask}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="absorbDepartmentId"
                                label="Absorb Department ID"
                                placeholder="Enter Here"
                                value={values.absorbDepartmentId}
                                onChange={handleInputChange}
                                error={errors.absorbDepartmentId}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    )
})

export default EditPracticeForm;
