import { REGEX } from "admin/constant"
import moment from "moment"
import { IntlShape } from "react-intl"
import { ReduxDispatch } from "admin/store/reducerTypes"
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "admin/store/actions/loading";
import { call } from "admin/store/api";
export function useReduxDispatch(): ReduxDispatch {
    return useDispatch<ReduxDispatch>();
}
// Use index for array values at index e.g. ['a', 'b', 0, 'key']
export const getObjectValuesByPath = (obj: any, traversalPathArr: any[]) => {
    return traversalPathArr.reduce((o, key) => (o && o[key] !== 'undefined') ? o[key] : undefined, obj);
}

export const createGUIDString = () => {
    const S4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    const guid = `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
    return guid.toLowerCase();
}
export const validateRequired = (fieldValue: string | null, intl: IntlShape) => {
    return fieldValue && fieldValue.trim() !== "" ? "" : intl.formatMessage({ id: "FormFieldValidation.Required" })
}
export const validateNoSpecialCharacters = (fieldValue: string | null, intl: IntlShape) => {
    return fieldValue && fieldValue.trim() !== "" && !fieldValue.match(REGEX.RESTRICT_SPECIAL_CHARACTERS) ? intl.formatMessage({ id: "FormFieldValidation.NoSpecialCharactersAllowed" }) : ""
}
export const validatePostalCode = (fieldValue: string | null, intl: IntlShape) => {
    return fieldValue && fieldValue.trim() !== "" && !fieldValue.match(REGEX.US_ZIP_CODE) ? intl.formatMessage({ id: "FormFieldValidation.InvalidPostalCode" }) : ""
}

export const validateEmail = (fieldValue: string | null, intl: IntlShape) => {
    return fieldValue && fieldValue.trim() !== "" && !fieldValue.match(REGEX.EMAIL) ? intl.formatMessage({ id: "FormFieldValidation.InvalidEmail" }) : ""
}
export const validateNumbersOnly = (fieldValue: string | null, intl: IntlShape) => {
    return fieldValue && fieldValue.trim() !== "" && !fieldValue.match(REGEX.NUMBERS_ONLY) ? intl.formatMessage({ id: "FormFieldValidation.NumbersOnly" }) : ""
}
export const validateDate = (fieldValue: string | null, intl: IntlShape) => {
    return fieldValue && fieldValue.trim() !== "" && !moment(fieldValue.trim()).isValid() ? intl.formatMessage({ id: "FormFieldValidation.InvalidDate" }) : ""
}
export const validateURL = (fieldValue: string, intl: IntlShape) => {
    const res = fieldValue && fieldValue.match(REGEX.URL);
    return fieldValue && fieldValue.trim() !== "" && (res == null) ? intl.formatMessage({ id: "FormFieldValidation.InvalidURL" }) : ""
}
// Nav Breadcrumb
export const getBreadcrumbName = (key: string) => {
    let value = '';
    switch (key) {
        case 'entities':
            value = 'Entities';
            break;
        case 'home':
            value = 'Home';
            break;
        case 'users':
            value = 'Users';
            break;
        case 'details':
            value = 'Details';
            break;
        case 'practice-details':
            value = 'Details';
            break;
        case 'patients':
            value = 'Patients';
            break;
        case 'feeschedules':
            value = 'Fee Schedules';
            break;
        case 'locations':
            value = 'Locations';
            break;
        case 'configurations':
            value = 'Configurations';
            break;
        case 'evisit':
            value = 'eVisit';
            break;
        case 'registrations':
            value = 'Registration Emails';
            break;
        case 'patientforms':
            value = 'Patient Forms';
            break;
        case 'settings':
            value = 'Settings';
            break;
        default:
            value = key;
    }
    return value;
};

export const configurePracticeStepDesc = (intl: IntlShape, step: number, hasCoreLicense: boolean) => {
    let content: string;
    if (!hasCoreLicense) {
        switch (step) {
            case 1:
            case 2:
            case 3:
                step = 4
                break;
            case 5:
                step = 6
                break;
            case 10:
                step = 9
                break;
        }
    }

    switch (step) {
        case 1:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.EVisitTypes" })
            break;
        case 2:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.EVisitCancellationTypes" })
            break;
        case 3:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.ConfigureWaitingRoom" })
            break;
        case 4:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.UserEmail" })
            break;
        case 5:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.PatientEmail" })
            break;
        case 6:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.AddUsers" })
            break;
        case 7:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.AssignProducts" })
            break;
        case 8:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.InviteUsers" })
            break;
        case 9:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.AddLocations" })
            break;
        case 10:
            content = intl.formatMessage({ id: "Practice.Configuration.SaveAndContinueLater.StepDesc.AddPatients" })
            break;
        default:
            content = ""
    }

    return content;
};
export const appendEhrSystemKeyTemplatePath = (path: string, ehrSystemKey: string | null) => {
    if (!ehrSystemKey) {
        return path;
    }
    const tempPath = path.substring(0, path.lastIndexOf("/") + 1);
    const tempFileName = path.substring(path.lastIndexOf("/") + 1, path.length);
    return tempPath + 'documentation/' + ehrSystemKey + '/' + tempFileName
}
export const makeApiCall = (dispatch: any, method: string, endpoint: string, params: Object = {}, title: string = '', description: string = ''): Promise<any> => {
    const guid = createGUIDString()
    dispatch?.(showLoading(guid, title, description))
    return new Promise(async (resolve, reject) => {
        return call(method, endpoint, params).then((payload) => {
            resolve(payload)
        }).catch(err => {
            reject(err)
        }).finally(() => dispatch?.(hideLoading(guid)));
    })
}