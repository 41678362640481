import { adaptV4Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import OpenSans from 'fonts/OpenSans-Regular.ttf'
export const sideNavWidth = "17.25rem"

export const SIZING = {
    scale25: ".1rem",
    scale50: ".125rem",
    scale100: ".25rem",
    scale200: ".5rem",
    scale300: ".75rem",
    scale400: "1rem",
    scale500: "1.25rem",
    scale600: "1.5rem",
    scale700: "1.75rem",
    scale800: "2rem",
    scale1000: "2.5rem",
    scale1200: "3rem",
    scale1500: "4rem",
    EMPTY_LIST_ICON: "12rem",
    SIDE_NAV_OPEN_WIDTH: "300px",
    SIDE_NAV_CLOSED_WIDTH: "80px",
    SIDE_NAV_COLLAPSE_BTN_BOTTOM: "2.3125rem"
}

export const BORDER_RADIUS = {
    SMALL: SIZING.scale100,
    MEDIUM: SIZING.scale200,
    MEDLARGE: SIZING.scale400,
    LARGE: SIZING.scale600,
    XL: SIZING.scale500,
    PILL: "30rem",
    FULL: "100%",
    MODAL: SIZING.scale800
}

export const PADDING = {
    SIDE_NAV_ITEM_PADDING: "2px",
    SIDE_NAV_WRAPPER_PADDING: "16px"
}

export const COLORS = {
    //Common
    WHITE: "#FFFFFF",
    LBLUE: '#0F7BBD',
    LGREY: '#FAFBFB',
    GREY: '#393948',
    DGREY: '#EDEDEF',
    DBLACK: '#393948',
    BLACK: '#000000',
    LGREEN: '#00A875',
    RED: '#C40000',
    YELLOW: '#F6E71C',

    // Backgrounds
    SIDE_NAV_BG: "#EDEDEF",
    UNOPENED_THREAD_BG: "#FFF",
    INPUT_BG: "#FFF",
    INPUT_ERROR_BG: "#FFF4F4",
    OPTION_MENU_BG: "rgba(37, 45, 59, .7)",
    MODAL_BG: "#F6F6F7",
    PW_VALIDATION_BG: "#EBF4FF",
    ERROR_BG: "#E32B29",
    WARNING_BG: "#FFC240",
    SUCCESS_BG: "#00875E",
    DEFAULT_BG: "#0F7BBD",

    // Buttons
    DEFAULT_BTN: "#0F7BBD",
    SECONDARY_BTN: "#00875E",
    PRIMARY_BTN: "#FFF",
    TOOLBAR_BTN: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(86, 86, 98, .05) 100%), #FFF",
    SELECTED_NAV_LIST_ITEM_BTN: "#E4E4E6",
    SELECTED_NAV_ITEM_BTN: "#FFF",
    SIDE_NAV_COLLAPSE_BTN: "linear-gradient(180deg, rgba(255, 255, 255, .6) 0%, rgba(230, 234, 241, 0) 100%), #E6EAF1",
    DISABLED_BTN: "#CACACE",
    DISABLED_BTN_TEXT: "#565662",
    LINK_COLOR: "#0F7BBD",

    // Text
    SNACKBAR_WARNING_TEXT: "#393948",
    SELECTED_NAV_TEXT: "#0F7BBD",
    NAV_TEXT: "#4C535F",
    SELECTED_SUB_NAV_TEXT: "#7E90B3",
    SUB_NAV_TEXT: "#252D3B",
    PARTICIPANT_TEXT: "#007AFF",
    SUBTITLE_TEXT: "rgba(0, 0, 0, .4)",
    BODY_TEXT: "#3C3C43",
    ITEM_COUNT_TEXT: "#FFF",
    OPTION_MENU_TITLE_TEXT: "#9DAAC5",
    OPTION_MENU_ITEM_TEXT: "#FFF",
    PW_VALIDATION_TEXT: "#FFF",
    LIST_SUBHEADER_TEXT: "#565662",
    DEFAULT_BUTTON_TEXT: "#FFF",
    PRIMARY_BTN_TEXT: "#0B5D8F",
    DIVIDER_TEXT: "#0F7BBD",
    NOTIFICATION_TEXT: "#E32B29",
    ERROR_TEXT: "#D72C0D",
    EMPTY_LIST_TEXT: "rgba(57, 57, 72, .4)",
    LINK_TEXT: "#0F7BBD",
    MODAL_TITLE_TEXT: "#565662",
    MODAL_SUBTITLE_TEXT: "#393948",

    // Borders
    INPUT_ERROR_BORDER: "#D82C0D",
    BOX_BORDER: "#e5e5e5",
    BOX_BORDER_DARK: "#C7C7CB",

    // Icons
    DEFAULT_ICON: "#6E6E79",
    BUTTON_ICON: "#FFFFFF",
    PATIENT_LIST_ICON: "rgba(15, 123, 189, 0.4)",
    USER_LIST_ICON: "rgba(57, 57, 72, 0.4)",
    EMPTY_LIST_ICON: "rgba(57, 57, 72, .1)",
    USER_ICON: "rgba(86, 86, 98, 0.4)",
    SELECTED_PERSON_ICON: "rgba(255, 255, 255, 0.6)",
    DRAWER_ICON: "#CACACE",
    DARK_ICON: "#565662",
    ALERTS_ICON: "#E82C2A",
    PASS_ICON: "#32D74B",
    FAIL_ICON: "#E32B29",
    DISABLE_ICON: "#E32B29",
    ENABLE_ICON: "#00875E",

    // Admin
    SELECTED_SUB_NAV_ITEM_BTN: "linear-gradient(0deg, #FFF, #FFF)",
    //Tooltip
    TOOLTIP_BACKGROUND: "#073D5E",
    TOOLTIP_FOREGROUND: "#FFF"
}

const openSans = {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontDisplay: "swap",
    src: `
        local('OpenSans'),
        url(${OpenSans}), format('truetype')
    `
}

// TODO Added theme styles
export default createTheme(adaptV4Theme({
    typography: {
        fontFamily: 'OpenSans',
        h1: {
            weight: 500,
            fontSize: "40px"
        },
        h3: {
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "normal"
        },
        h4: {
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "25px"
        },
        h5: {
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 700
        },
        h6: {
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "20px"
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "20px",
            color: COLORS.SUBTITLE_TEXT
        },
        body1: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "22px"
        },
        body2: {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "22px",
            color: COLORS.BODY_TEXT
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px"
        },
        caption: {
            fontWeight: 'normal',
            fontSize: "14px"
        },
        button: {
            fontWeight: 600,
            fontSize: "20px",
            textTransform: "capitalize"
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [openSans]
            }
        },
        MuiButton: {
            root: {
                lineHeight: "28px",
                textTransform: 'none',
                borderRadius: 64,
                "&:hover": {
                    backgroundColor: "#139AED"
                },
                boxShadow: '0 0 2px rgba(0, 0, 0, .25), 0 2px 2px rgba(33, 33, 49, .24)'
            },
            text: {
                boxShadow: "none"
            },
            outlinedPrimary: {
                color: "#0B5D8F",
                padding: '.4rem 1.2rem',
                backgroundColor: "#FFF",
                "&:hover": {
                    backgroundColor: "#FFF"
                },
                "&:focus": {
                    border: "2px solid #FFF"
                },
                "&.Mui-disabled": {
                    backgroundColor: "#FFF",
                    color: "rgba(0, 0, 0, .26)"
                },
                "&:disabled, &[disabled]": {
                    backgroundColor: "#FFF",
                    color: "rgba(0, 0, 0, .26)",
                    "> .MuiButton-label": {
                        color: 'inherit'
                    }
                }
            },
            textPrimary: {
                background: COLORS.PRIMARY_BTN,
                color: COLORS.PRIMARY_BTN_TEXT,
                "&:disabled": {
                    background: COLORS.DISABLED_BTN,
                    color: COLORS.DISABLED_BTN_TEXT
                },
                "&:hover": {
                    backgroundColor: COLORS.PRIMARY_BTN,
                    boxShadow: "0 0 2px rgba(0, 0, 0, .25), 0 4px 8px rgba(0, 0, 0, .25)"
                }
            },
            /*
            label: {
                margin: '0 10px',
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '140%'
            },
            */
            outlined: {
                //padding: '.4rem 1.2rem',
                background: "#FFF",
                color: "#0B5D8F",
                "&:hover": {
                    background: "#FFF"
                },
            },
            iconSizeMedium: {
                "&>*:first-child": {
                    fontSize: '24px'
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: '#3C3C43',
                fontSize: '16px',
                fontWeight: '700 !important'
            }
        },
        //MuiInputLabel: {
        //    shrink: {
        //        transform: 'translate(0, 1.5px) scale(1)'
        //    }
        //},
        MuiInputBase: {
            input: {
                "&::placeholder": {
                    color: "#6E6E79",
                    opacity: .8
                },
                color: '#3C3C43',
                fontSize: '16px',
                padding: '4px 6px !important' 
            }
        },
        MuiTablePagination: {
            selectLabel: {
                fontSize: "12px"
            },
            displayedRows: {
                fontSize: "12px"
            }
        },
        MuiTableCell: {
            root: {
                padding: '10px 5px',
                fontSize: '14px',
                lineHeight: '150%',
                borderBottom: '1px solid #E4E4E6',
                color: '#393948'
            },
            head: {
                fontWeight: 600
            },
            stickyHeader: {
                backgroundColor: '#EDEDEF',
                color: '#393948',
                fontWeight: 600,
                fontSize: '14px'
            },
            paddingCheckbox: {
                padding: '0 10px 0 4px'
            }
        },
        MuiTableSortLabel: {
            icon: {
                marginLeft: '80px'
            }
        },
        MuiListItemIcon:
        {
            root: {
                minWidth: '46px'
            }
        },
        MuiDialogActions: {
            root: {
                background: '#EDEDEF'
            }
        },
        MuiDialog: {
            paperFullWidth: {
                background: '#FBFBFB',
                borderRadius: '32px'
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: 'transparent',
                height: '30px',
                "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: '50%',
                    width: 12,
                    height: 12,
                    background: '#0B5D8F',
                    boxShadow: 'drop-shadow(0 0 1px rgba(0, 0, 0, .3)), drop-shadow(0 2px 8px rgba(0, 0, 0, .2))',
                    transform: "translate(-50%, 50%) rotate(135deg)",
                    clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))"
                }
            }
        },
        MuiTab: {
            root: {
                backgroundColor: "#FFF",
                color: "#0B5D8F",
                borderRadius: "24px",
                boxShadow: "0 0 2px rgba(0, 0, 0, .25)",
                fontWeight: 600,
                fontSize: '16px',
                minHeight: '30px',
                padding: '1px 12px',
                marginRight: '.75rem',
                "&.Mui-selected": {
                    backgroundColor: "#0B5D8F",
                    color: '#FFF'
                },
                "&.Mui-disabled": {
                    backgroundColor: "#E4E4E6"
                }
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '4px 6px'
            },
            root: {
                padding: '4px 6px',
                background: '#FFF',
                "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#139AED"
                    }
                }
            }
        },
        MuiFormControl: {
            root: {
                width: '100%'
            }
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: '0'
            }
        },
        MuiDialogContent: {
            root: {
                background: '#F6F8FA'
            },
            dividers: {
                padding: '32px'
            }
        },
        MuiTooltip: {
            arrow: {
                color: COLORS.TOOLTIP_BACKGROUND
            },
            tooltip: {
                color: COLORS.TOOLTIP_FOREGROUND,
                backgroundColor: COLORS.TOOLTIP_BACKGROUND,
                fontSize: '16px',
                borderRadius: '8px',
                padding: '8px 16px'
            }
        },
        /*
        MuiDropzoneArea: {
            root: {
                border: '2px dashed #0F7BBD',
                borderColor: '#0F7BBD'
            }
        },
        */
        MuiCheckbox: {
            root: {
                color: "#139AED",
                "&.Mui-checked": {
                    color: "#139AED"
                }
            }
        },
        MuiRadio: {
            root: {
                color: "#139AED",
                "&.Mui-checked": {
                    color: "#139AED"
                }
            }
        }
    }
}))