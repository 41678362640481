import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        personIcon: {
            marginRight: SIZING.scale400,
            "& svg": {
                fill: COLORS.DEFAULT_BTN,
                width: "44px",
                height: "44px",
            }
        },
        patientName: {
            display: "flex",
            color: COLORS.SELECTED_NAV_TEXT,
            "& :first-child": {
                textTransform: "uppercase",
                marginRight: SIZING.scale100
            }
        },
        patientSubtitle: {
            display: "flex",
            color: COLORS.LIST_SUBHEADER_TEXT,
            "& :first-child": {
                marginRight: SIZING.scale200
            }
        },
}));