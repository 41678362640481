export const DropzoneLayout = "Files__FileDropzoneInput__DropzoneLayout";
export const DropzoneLayoutActive = "Files__FileDropzoneInput__DropzoneLayout--active";
export const DropzoneLayoutEmptyMessage = "Files__FileDropzoneInput__DropzoneLayout__EmptyMessage";
export const FileDropzoneInput = "Files__FileDropzoneInput";
export const FileDropzoneInputSmall = "Files__FileDropzoneInput--small";
export const FileGridView = "Files__FileGridView";
export const Preview = "Files__Preview";
export const PreviewContainer = "Files__Preview__Container";
export const PreviewError = "Files__Preview--error";
export const PreviewGrid = "Files__PreviewGrid";
export const PreviewIcon = "Files__Preview__Icon";
export const PreviewImg = "Files__Preview__Img";
export const PreviewImgVisible = "Files__Preview__Img--visible";
export const PreviewLoading = "Files__Preview__Loading";
export const PreviewSize = "Files__Preview__Size";
export const PreviewTextLine = "Files__Preview__TextLine";
