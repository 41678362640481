import { makeStyles } from "@mui/styles";
import {COLORS} from "theme";


export const useStyles = makeStyles(() => ({
    wrapper:{
        height:'100%',
        width:'100%',
        position:'absolute',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        top:0,
        bottom:0,
        right:0,
        left:0,
        "& svg":{
            "& g > path":{
                fill: COLORS.LBLUE
            },
            position:'relative',
            zIndex:10
        },
        "&:not(.disableBackdrop)::after":{
            opacity:0.5,
            content:"''",
            height:'100%',
            width:'100%',
            position:'absolute',
            background: COLORS.INPUT_BG,
            zIndex:8
        },
        "&.light svg g > path": {
            fill: COLORS.WHITE,
        },
    },
}));
