import React from "react";
import { formatName } from "lib/domain/names";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import NotificationItem, { NotificationItemInfo } from "./notification-item";
import { NewEconsultRequestNotification } from "store/notification-types";

const messages = defineMessages({
    regular: {
        id: "Notification.content.newEconsultRequest"
    },
    withPatient: {
        id: "Notification.content.newEconsultRequest.withPatient"
    }
});

const NewEconsultRequest = ({ createdDate, data: NewEconsultRequestDTO }: NewEconsultRequestNotification) => {
    const { patient, referrer, requestId} = NewEconsultRequestDTO
    const history = useHistory();
    const intl = useIntl();

    const handleClick = () => {
        history.push(`/provider/econsult/received/${requestId}`);
    };

    const contentMessage = patient ? messages.withPatient : messages.regular;

    const patientName = formatName(patient || {});
    const referrerName = formatName(referrer);

    return (
        <NotificationItem
            title={intl.formatMessage({ id: "Notification.title.newEconsultRequest" })}
            createdDate={createdDate}
            onClick={handleClick}
        >
            <NotificationItemInfo>
                <FormattedMessage { ...contentMessage } values={{ referrerName, patientName }} />
            </NotificationItemInfo>
        </NotificationItem>
    );
};

export default NewEconsultRequest;
