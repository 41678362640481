import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { NOTIFICATION_TYPE } from "constant";
import NotificationItem, { NotificationItemName, NotificationItemInfo } from "./notification-item";
import { WaitingRoomNotification, NRUWaitingRoomNotification } from "store/notification-types";

const WaitingRoom = ({ createdDate, data, type }: WaitingRoomNotification | NRUWaitingRoomNotification) => {
    const {duration, patientId, patientName, scheduledStartTime} = data;
    const history = useHistory();
    const intl = useIntl();

    const handleClick = () => {
        // on click the user will be taken to the patients profile where they can join an eVisit
        if (type === NOTIFICATION_TYPE.WAITING_ROOM) {
            history.push(`/provider/people/patients/${patientId}`);
        } else {
            history.push("/provider/waiting-room");
        }
    };

    return (
        <NotificationItem
            title={intl.formatMessage({ id: "Notification.title.patientInWaitingRoom" })}
            createdDate={createdDate}
            onClick={handleClick}
        >
            <NotificationItemName>{patientName}</NotificationItemName>
            <NotificationItemInfo>{`eVisit - ${scheduledStartTime} ${duration}`}</NotificationItemInfo>
        </NotificationItem>
    );
};

export default WaitingRoom;
