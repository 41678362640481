import React from "react";
import { Typography, Grid } from "@mui/material";
import { useStyles } from "./styles";
import { IFormInput } from "admin/views/viewsTypes";
import { FormattedMessage } from "react-intl";

type Props = {
  currentForm: IFormInput;
};

const CRAFFTN: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
        <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.InstructionsForUseAndScoring"} />
          </Typography>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.CRAFFTN.Body1"} />
          </Typography>
          <a href="https://crafft.org/wp-content/uploads/2021/10/CRAFFT_2.1_Provider-Manual_2021.10.28.pdf">https://crafft.org/wp-content/uploads/2021/10/CRAFFT_2.1_Provider-Manual_2021.10.28.pdf</a>
        </Grid>
        <Grid item xs={12}>  
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.FormLogic"} />
          </Typography>
          <Typography variant="body1" component="p">
          <FormattedMessage id={"RulesAndLogic.CRAFFTN.Body2"} />
          </Typography>
          <Typography variant="body1" component="p">
          <FormattedMessage id={"RulesAndLogic.CRAFFTN.Body3"} />
          </Typography>
        </Grid>
        </Grid>
    </div>
  );
}

export default CRAFFTN