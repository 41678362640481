import {
    Box, IconButton, Button, DialogActions, DialogContent,
    Typography, Grid, Paper
} from "@mui/material";
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Modal from 'admin/components/Modal/Modal';
import { MODAL_TYPES } from 'admin/constant';
import { setActiveModal } from 'admin/store/actions';
import { useStyles } from './styles';
import React, { useRef } from "react";
import EditOrganizationForm from './EditOrganizationForm'
import { createSvgIcon } from "utils";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross_Circle.svg";

const EditOrganization = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editOrganizationRef = useRef<any>()
    const CrossIcon = createSvgIcon(CrossSvg)

    const modalClasses = {
        paper: classes.wrapper
    };

    const onClose = () => {
        dispatch(setActiveModal(""));
    };

    const handleSaveOrganization = (event: any) => {

        const result = editOrganizationRef.current.submitForm(event);
        if (result) {
            setTimeout(() => {
                onClose();
            }, 1000)

        }
    };

    return (
        <Modal id={MODAL_TYPES.EDIT_ORGANIZATION} classes={modalClasses} onClose={onClose}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1}>
                    <Typography className={classes.dialogHeaderTitle}>
                        <FormattedMessage id={"EditOrganization.Header"} />
                    </Typography>
                </Box>
                <Box>
                    <IconButton onClick={onClose} size="large">
                        <CrossIcon className={classes.closeButtonIcon} />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ root: classes.dialogContentRoot, dividers: classes.dialogContentDividers }}>
                <Paper square elevation={0}>
                    <Box className={classes.contentWrapper}>
                        <Box className={classes.contentArea}>
                            <Box className={classes.leftPaneWrapper}></Box>
                            <Box className={classes.rightContent}>
                                <EditOrganizationForm ref={editOrganizationRef} />
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </DialogContent>

            <DialogActions className={classes.dialogAction}>
                <Box className={classes.dialogActionBar}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: "right" }}>
                        <Button
                            id="saveEditOrgChangesBtn"
                            variant="outlined"
                            color="primary"
                            aria-haspopup="true"
                            className={classes.dialogHeaderSaveButton}
                            onClick={handleSaveOrganization}
                        >
                            <FormattedMessage id={"EditOrganization.UpdateButtonText"} />
                        </Button>
                    </Grid>
                </Box>
            </DialogActions>
        </Modal>
    );
}

export default EditOrganization;
