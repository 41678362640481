import { makeStyles } from "@mui/styles";
import { SIZING, COLORS, BORDER_RADIUS } from "../../../theme";

export const useStyles = makeStyles(() => ({
    wrapper: {
        padding: SIZING.scale500,
        background: COLORS.DGREY,
        display: 'flex',
        alignItems: 'center',
        margin: SIZING.scale300,
        flexDirection: 'column',
        borderRadius: BORDER_RADIUS.MEDIUM
    },
    desc: {
        fontWeight: 400
    },
    contentWrapper: {
        "&>div": {
            marginBottom: SIZING.scale600
        }
    },
    leftSideWrapper: {
        "& div": {
            textAlign: 'left'
        },
        "&>div": {
            marginBottom: SIZING.scale400
        }
    },
    rightSideWrapper: {
        "& div": {
            textAlign: 'right'
        },
        "&>div": {
            marginBottom: SIZING.scale600,
            marginTop: SIZING.scale800
        }
    },
    admitDetails: {
        display: 'flex',
        justifyContent: 'flex-end',
        "&>div:nth-child(2)": {
            marginLeft: SIZING.scale1000
        },
    },
    reasonSection: {
        display: "flex",
        alignItems: "end",
        flexDirection: "column"
    },
    reasonTxt: {
        textAlign: "end"
    },
    patientDetails: {
        display: "flex",
        flexDirection: 'column',
        "& .patient-name": {
            display: "flex",
        }
    },
    ageGender: {
        marginLeft: SIZING.scale200,
        fontWeight: 400
    },
    facilityDetails: {
        display: "flex",
        marginBottom: SIZING.scale100
    },
    facilityDetailsDec: {
        fontWeight: 400,
    },
    facilityDetailsHead: {
        width: SIZING.scale2900,
        flexShrink: 0,
    },
    dischargeDetails: {
        display: "flex"
    },
    icon: {
        height: SIZING.scale1600,
        width: SIZING.scale1600,
        marginRight: SIZING.scale600,
        "& path:first-child": {
            fill: COLORS.BLACK
        }
    },
    transferadmitDetails: {
        display: 'flex',
        justifyContent: 'flex-start',
        "&>div:nth-child(2)": {
            marginLeft: SIZING.scale1000
        },
        marginLeft: `-${SIZING.scale400}`
    },

}));