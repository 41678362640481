import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100vh',
        padding: SIZING.scale1200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    doctorName: {
        fontSize: SIZING.scale800,
        color: COLORS.LBLUE,
        fontWeight: 300
    },
    desc: {
        color: COLORS.DBLACK,
        fontSize: SIZING.scale500,
        fontWeight: 300,
        marginTop: SIZING.scale800,
        lineHeight: SIZING.scale800,
    },
    reportContainer: {
        height: '700px',
        width: '1200px'
    }
}));