import React from "react";
import { useStyles } from "./styles";

type Props = {
  currentForm: any;
};

const PanelMetrics: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
        Panel Metrics
    </div>
  );
}

export default PanelMetrics