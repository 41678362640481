import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

type Info = {
    textId: string;
    value?: string;
    className?: string;
}

const PatientBaseInfo: React.FC<Info> = ({ textId, value, className }) => {
    return (
        <Box display="flex" className={className}>
            <div className="text">
                <FormattedMessage id={textId}/>
            </div>
            <div className="value">
                {value}
            </div>
        </Box>
    );
}

export default PatientBaseInfo;