import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    numberField: {
        '& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            'margin': 0
        },
        '& [type=number]': {
            '-webkit-appearance': 'textfield',
            '-moz-appearance': 'textfield'
        }
    }
}));