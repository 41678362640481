import { useStyles } from "./styles";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import TemModal from "./TemModal";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Link,
    styled
} from "@mui/material";
import { SIZING } from "theme";
import { FormattedMessage } from "react-intl";
import { useSelector } from 'react-redux';
import { AppReduxStore } from 'admin/store/reducerTypes';

const Versioning = (props : any) =>{
    const {configuration} = useSelector((state: AppReduxStore) => state);
    const allFormVersion = configuration.allFormVersioning;
    console.log(allFormVersion)
    const StyledTableCell = styled(TableCell)(( theme : any) => ({
        head: {
          backgroundColor: '#ededef',
          color: '#454553',
        },
        body: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)((theme : any) => ({

      }));

    const classes = useStyles({});

    const renderHeader = () => (
        <Box className={classes.header}>
            <Box marginBottom={SIZING.scale50} className={classes.flexWrapper}>
                <Typography variant="body1" id={`Versioning`} style={{color: "#565662"}} variantMapping={{h6: 'h6'}}>
                    <FormattedMessage 
                    id={"Version History"} 
                    defaultMessage={"<b>Version History</b>"} 
                    values={{
                        b: (chunks: any) => <b>{chunks}</b>
                    }}/>
                    <br />
                    <FormattedMessage id={props.title} />
                </Typography>
                <Close className={classes.close} onClick={props.onCloseClick} id="QA_btnClose" />
            </Box>
        </Box>
    )

    return(
        <TemModal id={"VERSIONING_NUMBER"} open={props.open} onClose={props.onCloseClick}>
            <div>
                {renderHeader()}
                <div className={classes.content}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell className={classes.last}><Link style={{color: "#4d4d5b"}} underline="none">V #</Link></StyledTableCell>
                                <StyledTableCell className={classes.last} align="left">Status, MM/DD/YYYY</StyledTableCell>
                                <StyledTableCell className={classes.last} align="left">Availability</StyledTableCell>
                                <StyledTableCell className={classes.last} align="left">Updated By</StyledTableCell>
                                <StyledTableCell className={classes.last} align="left">Creation Date</StyledTableCell>
                                <StyledTableCell className={classes.last} align="left">Reviewed By</StyledTableCell>
                                <StyledTableCell className={classes.last} align="left">Comments</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {allFormVersion.length !== 0 && allFormVersion.map((version : any) => {
                                const questionnaire = version.questionnaireApprovals;
                                return <StyledTableRow key={version.formVersion}>
                                <StyledTableCell component="th" scope="row">
                                    <Link underline="hover" className={classes.number}>{version.formVersion}</Link>
                                </StyledTableCell>
                                <StyledTableCell align="left">{version.formStatus[0] + version.formStatus.slice(1, version.formStatus.length).toLowerCase() + ", " + new Date(version.lastModifiedDate).toLocaleDateString()}</StyledTableCell>
                                <StyledTableCell align="left"><Box width={70} border={2} borderColor={version.isEnabled ? "#0F7BBD" : "#909098"} borderRadius="4px" textAlign="center" p={0}>{version.isEnabled ? "Enabled" : "Disabled"}</Box></StyledTableCell>
                                <StyledTableCell align="left">{questionnaire.length !== 0 ? questionnaire[0].requesterFirstName + " " + questionnaire[0].requesterLastName : 'N/A'}</StyledTableCell>
                                <StyledTableCell align="left">{new Date(version.createdDate).toLocaleDateString()}</StyledTableCell>
                                <StyledTableCell align="left">{questionnaire.length !== 0 ? questionnaire[0].approverFirstName + " " + questionnaire[0].approverLastName : 'N/A'}</StyledTableCell>
                                <StyledTableCell align="left">{questionnaire.length !== 0 ? questionnaire[0].reviewComments : "N/A"}</StyledTableCell>
                                </StyledTableRow>
                            })}
                            <TableRow>
                                <StyledTableCell className={classes.last}></StyledTableCell>
                                <StyledTableCell className={classes.last}></StyledTableCell>
                                <StyledTableCell className={classes.last}></StyledTableCell>
                                <StyledTableCell className={classes.last}></StyledTableCell>
                                <StyledTableCell className={classes.last}></StyledTableCell>
                                <StyledTableCell className={classes.last}></StyledTableCell>
                                <StyledTableCell className={classes.last}></StyledTableCell>
                            </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </TemModal>
    );
}

export default Versioning;