import { DialogContent, Box, Button, DialogActions, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { ReactComponent as AddUser } from "@altais/icon-library/legacy/SVG/User_Single.svg";
import { createSvgIcon } from "utils";
import AddUserForm from "../../AddUser/AddUserForm";
import AddUserBulk from "../../AddUser/AddUserBulk";
import { appendEhrSystemKeyTemplatePath } from "admin/common/utils";
import Spinner from "common/components/Spinner/Spinner";
const { PROVIDER_BULK_UPLOAD_CSV_TEMPLATE } = (window as any).env;
const AddUserIcon = createSvgIcon(AddUser);

const AddUserConfigure = forwardRef((props: any, ref) => {
    const { auth, admin, practice } = useSelector((state: AppReduxStore) => state);
    const classes = useStyles();
    const intl = useIntl();
    const [isBulkUploadDisabled, setIsBulkUploadDisabled] = useState(true);
    const [isUnsaved, setUnsaved] = useState(false);
    const [isUploadNotSuccess, setUploadNotSuccess] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const hideUploadUI = auth.currentPractice
    const [tab, setTab] = useState(hideUploadUI ? 1 : 0);
    const addUserFormRef = useRef<any>();
    const addBulkUploadRef = useRef<any>();

    useImperativeHandle(
        ref,
        () => ({
            saveAndContinueLaterAction(index: number) {
                return addUserFormRef.current ? addUserFormRef.current.saveAndContinueLater(index) :
                    addBulkUploadRef.current.saveAndContinueLater(index);
            }
        }),
    )

    useEffect(() => {
        setTab(hideUploadUI ? 1 : 0)
    }, [hideUploadUI])

    const handleClose = () => {
        setIsBulkUploadDisabled(true);
        setUploadNotSuccess(false)
    }
    const handleChangeTab = (event: any, newTab: any) => {
        setTab(newTab);
    }

    const handleSaveUser = (event: any) => {
        const result = addUserFormRef.current.submitForm(event, false);
        if (result) {
            handleClose();
            return result
        }
    }

    const handleBulkUpload = () => {
        const result = addBulkUploadRef.current.submitBulkUpload();
        if (result) {
            handleClose()
        }
    }

    const handleAddedFile = () => {
        setIsBulkUploadDisabled(false);
    }

    const handleRemovedFile = () => {
        setIsBulkUploadDisabled(true);
    }

    const onAddFormChanged = (dataChanged: boolean) => {
        setUnsaved(dataChanged)
    }

    const handleLoading = (flag: boolean) => {
        setIsUploading(flag)
    }
    
    const ehrInfo = admin.ehrSourceSystems?.find((e: any) => e.id === practice?.sPractice?.ehrSourceSystemId)
    return (<>
        <Grid item className={classes.productsPageHeader}>
            <Typography className={classes.pageHeaderText}>
                <AddUserIcon fill="#0F7BBD" className={classes.productsIcon} />
                <FormattedMessage id="AddUsers.Text.Config.Adduser" />
            </Typography>
            <Typography className={classes.pageSubHeaderText}>
                <FormattedMessage id={"AddUsers.Text.Config.Adduser.SubText"} />
            </Typography>
        </Grid>
        <DialogActions className={classes.dlgAction}>
            <Box width="100%">
                {!hideUploadUI && <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    onChange={handleChangeTab}
                    aria-label="Add User tab">
                    <Tab style={{ marginRight: '4px' }} label={intl.formatMessage({ id: "AddUser.BulkUpload" })} />
                    <Tab style={{ marginLeft: '4px' }} label={intl.formatMessage({ id: "AddUser.Tab.Manually" })} />
                </Tabs>}
            </Box>
        </DialogActions>
        <DialogContent>
            <Paper square elevation={0}>
                {tab === 1 ? <Box className={classes.tabContentWrapper}>
                    <AddUserForm ref={addUserFormRef} onFormValueChanged={onAddFormChanged} />
                </Box> :
                    <Box className={classes.tabContentWrapper}>
                        <AddUserBulk ref={addBulkUploadRef} handleAddedFile={handleAddedFile} handleRemovedFile={handleRemovedFile} handleClose={handleClose} handleLoading={handleLoading}></AddUserBulk>
                    </Box>}
            </Paper>

        </DialogContent>
        <DialogActions className={classes.dlgAction}>
            <Box flexGrow={1} >
                {tab === 0 && ehrInfo?.hasTemplate &&
                    <Button id="btnDownloadTemplate" variant="outlined"
                        aria-haspopup="true" href={appendEhrSystemKeyTemplatePath(PROVIDER_BULK_UPLOAD_CSV_TEMPLATE,
                            auth.currentPractice?.ehrSourceSystemId || practice?.sPractice?.ehrSourceSystemId)}>
                        {intl.formatMessage({ id: "AddUser.DowloadTemplate" })}
                    </Button>
                }
            </Box>
            <Box>
                {tab === 1 ? <Fragment>
                    <Button id="btnSaveAndFinish" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveUser}
                        disabled={!isUnsaved}>
                        {intl.formatMessage({ id: "AddUser.Tab.AddUser" })}
                    </Button>
                </Fragment> : isUploading ? <Spinner /> :
                    <Button id="btnBulkUplpad" variant="contained" color="primary" disabled={isBulkUploadDisabled || isUploadNotSuccess}
                        aria-haspopup="true" onClick={handleBulkUpload}>
                        Upload
                    </Button>}
            </Box>
        </DialogActions>
    </>)
})

export default AddUserConfigure;