import {Button, Typography } from "@mui/material";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {AppReduxStore} from "admin/store/reducerTypes";
import {fetchUserInfo} from "admin/store/actions";
import React, { useEffect } from "react";
import Spinner from "common/components/Spinner/Spinner";
import {useStyles} from "./styles";
import LayoutExtr from "admin/components/Layout/LayoutExtr";
import {MatchParams} from "./types";
import {useParams} from "react-router-dom";

import {ReactComponent as Rocket} from "icon-library/SVG/Rocket.svg";
import { createSvgIcon } from "utils";
const RocketIcon = createSvgIcon(Rocket)

const Feedback = () => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const {regToken} = useParams<MatchParams>()
    const classes = useStyles()

    const UserInfoResults = () => {
        const {userInfo, loading} = useSelector((store: AppReduxStore) => store.registration)

        if (loading) {
            return <Spinner/>
        } else if (userInfo) {
            return (
                <>
                    <RocketIcon  className={classes.rocketIcon} />
                </>
            )
        }
        return <Spinner/>
    }

    useEffect(() => {
        dispatch(fetchUserInfo(regToken))
    }, [dispatch, regToken])

    return (
        <LayoutExtr heading={intl.formatMessage({ id: "Feedback.heading.feedback" })}
                    subHeading={intl.formatMessage({ id: "Feedback.subheading.feedback" })}>

                <UserInfoResults />
                <Button className={classes.navBtn} variant="outlined" color="primary" fullWidth href="/patient/download-app">
                    <Typography variant="button">
                        <FormattedMessage id="Feedback.button.next"/>
                    </Typography>
                </Button>
        </LayoutExtr>
    )
}

export default Feedback