import { Button } from "@mui/material";
import { ReactComponent as Cross } from "icon-library/SVG/Cross.svg";
import { ReactComponent as PaperClip } from "icon-library/SVG/Paper-Clip.svg";
import classNames from "classnames";

type AttachmentButtonProps = {
    onClick: () => void;
    open: boolean;
    disabled?: boolean;
};

const AttachmentButton = ({ onClick, open, disabled }: AttachmentButtonProps) => {
    return (
        <Button
            id="QA_composeMessage_attachmentButton"
            data-testid="QA_composeMessage_attachmentButton"
            disabled={disabled}
            className={classNames("ComposeMessage__AttachmentSection__AttachmentButton", {
                "ComposeMessage__AttachmentSection__AttachmentButton--open": open,
            })}
            onClick={onClick}
            variant="contained"
            color="primary"
        >
            {open ? <Cross /> : <PaperClip />}
        </Button>
    );
};

export default AttachmentButton;
