import createReducer from '../createReducer'
import { Png } from '../reducerTypes'

const initialState: Png = {
    currentRoom: null,
    previewTracks: null,
}

export default createReducer(initialState, {
    'UPDATE_CURRENT_ROOM': (state: Png, payload: Png): Png => {
        return {
            ...state,
            currentRoom: payload
        }
    }
})