import createReducer from '../createReducer'
import { pngMobile,pngDetails } from '../reducerTypes'

const initialState: pngMobile = {
	pngDetails: null,
	twilioToken:null,
	twilioClient:null,
	twilioVideoToken:'',
	twilioRoomId:'',
	isCallDroped:false,
	currentRoomObject:null,
	isCallEnded:false,
	isInvalidInvite:false,
	inviteType:'',
	isMeetingPaused:false,
	isMeetingTimeout: false,
	isMediaAccessError: '',
	quickMessage:'',
	messageFrom: ''
}

export default createReducer(initialState, {
	'SET_PNG_MOBILE_DETAILS': (state: pngMobile, payload: pngDetails): pngMobile => {
		return {
			...state,
			pngDetails: payload
		}
	},
	'UPDATE_NAE_DETAILS': (state: pngMobile, payload: pngDetails): pngMobile => {
		return {
			...state,
			pngDetails: {
				...state.pngDetails,
				...payload
			}
		}
	},
	'SET_PNG_TWILIO_TOKEN': (state: pngMobile, payload: string): pngMobile => {
		return {
			...state,
			twilioToken: payload
		}
	},
	'SET_NAE_TWILIO_CLIENT': (state: pngMobile, payload: any): pngMobile => {
		return {
			...state,
			twilioClient: payload
		}
	},
	'SET_NAE_TWILIO_VIDEO_TOKEN': (state: pngMobile, payload: any): pngMobile => {
		return {
			...state,
			twilioVideoToken: payload
		}
	},
	'SET_NAE_TWILIO_VIDEO_ROOM_ID': (state: pngMobile, payload: string): pngMobile => {
		return {
			...state,
			twilioRoomId: payload
		}
	},
	'SET_NAE_DROP_USER': (state: pngMobile): pngMobile => {
		return {
			...state,
			isCallDroped:true
		}
	},
	'SYS_NRU_EVISIT_PAUSED': (state: pngMobile,payload: boolean): pngMobile => {
		return {
			...state,
			isMeetingPaused:payload
		}
	},
	'SET_NAE_CALL_ENDED': (state: pngMobile): pngMobile => {
		return {
			...state,
			isCallEnded:true
		}
	},
	'SET_NAE_INVALID_INVITE': (state: pngMobile): pngMobile => {
		return {
			...state,
			isInvalidInvite: true
		}
	},
	'SET_NAE_ROOM_OBJ': (state: pngMobile,payload: any): pngMobile => {
		return {
			...state,
			currentRoomObject: payload
		}
	},
	'SET_NAE_INVITE_TYPE': (state: pngMobile,payload: string): pngMobile => {
		return {
			...state,
			inviteType: payload
		}
	},
	'SET_NAE_MEETING_TIMEOUT': (state: pngMobile): pngMobile => {
		return {
			...state,
			isMeetingTimeout: true
		}
	},
	'SET_MEDIA_UPDATE_ERROR': (state: pngMobile,payload:string): pngMobile => {
		return {
			...state,
			isMediaAccessError: payload
		}
	},
	'RESET_NAE_STORE': (state: pngMobile): pngMobile => {
		return {
			...state,
			...initialState
		}
	},    
	'SET_QUICK_MESSAGE': (state: pngMobile,payload: string): pngMobile => {
        return {
            ...state,
            quickMessage: payload
        }
    },
	'SET_MESSAGE_FROM': (state: pngMobile,payload: string): pngMobile => {
        return {
            ...state,
            messageFrom: payload
        }
    }

})