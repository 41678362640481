import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING } from "admin/theme";

export const useStyles = makeStyles(() => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        borderRadius: '24px',
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    usersWrapper: {
        display: "flex",
        flex: '0 1 auto',
        flexDirection: "column",
        position: "absolute",
        //left: `-${SIZING.scale300}`,
        width: "100%",
        height: "100%",
        borderRadius: `0 ${BORDER_RADIUS.LARGE} ${BORDER_RADIUS.LARGE} 0`,
    },
    usersTopActionWrapper: {
        padding: '10px 0px',
    },
    categoryTitle: {
        color: "rgba(126, 144, 179, 0.8)"
    },
    category: {
        marginBottom: SIZING.scale500
    },
    grdHeadercell: {
        backgroundColor: '#EDEDEF'
    },
    userName: {
        color: '#393948',
        fontWeight: 600,
        fontSize: '40px',
        lineHeight: '140%'
    },
    heading: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948'
    },
    contolsWrapper: {
        margin: '20px 0px 0px 15px'
    },
    toggleSwitch: {
        marginLeft: '20px'
    },
    controlContainer: {
        marginTop: '20px'
    },
    patientDetailsTypography: {
        marginTop: '30px', fontSize: '24px'
    },
    patientDetailsHeading: {
        marginTop: '30px'
    },
    patientDetailsTopHeading: {
        marginTop: '50px',
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948'
    },
    flagIcon: {
        height: '15px',
        width: '15px',
        verticalAlign: 'bottom',
        position: 'relative',
        top: '19px',
        right: '-125px'
    },
    fee_schedule_config_dropzone: {
        height: '340px',
        marginRight: '20px'
    },
    fee_schedule_config_calendar_wrapper:{
        width: '360px',
        padding:`0 ${SIZING.scale400}`,
        "& .MuiInput-input":{
            color:"#000000",
            "&::placeholder": {
                color: "#6E6E79",
            },
        },
    }


    /* Fee Schedule Delete Confirmation
    delete_confirmation_dlg_wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    delete_confirmation_dlg_dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    delete_confirmation_dlg_dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    delete_confirmation_dlg_tabContentWrapper: {
        padding: '20px',
        textAlign: 'center',
        minWidth: '800px'
    },
    delete_confirmation_dlg_label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    delete_confirmation_dlg_formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    delete_confirmation_dlg_dlgAction: {
        padding: '16px 32px'
    },
    delete_confirmation_dlg_confirmationBody: {
        color: '#E32B29',
        fontSize: '24px',
        fontWeight: 600
    }
*/
}));