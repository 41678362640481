import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, COLORS, SIZING} from "theme";
import Image from "assets/Right.png";

export const useStyles = makeStyles((theme: Theme) => ({
        twilioWrapper: {
            width: "100%",
            height:'calc(100% - 60px)',
            background: "#26282a",
            position:'relative',
            [theme.breakpoints.down('lg')]: {
                height:'100%'
            },
        },
        localVideo:{
            position:'absolute',
            bottom: 0,
            width:'100%',
            [theme.breakpoints.down('lg')]: {
                bottom:'3.125rem',
            },
            "& .participant-container":{
                height:'200px !important',
                width:'240px',
                overflow:'hidden',
                boxShadow:'1px 0px 8px rgb(0 0 0 / 10%), inset 0px -1px 0px rgb(0 0 0 / 10%)',
                border:`2px solid ${COLORS.WHITE}`,
                [theme.breakpoints.down('lg')]: {
                    width:'125px',
                    top:'unset',
                    left:0,
                    right:0,
                    margin:'0 auto',
                    height:'200px',
                    borderRadius:BORDER_RADIUS.MEDIUM,
                },
                borderRadius:BORDER_RADIUS.LARGE,
                "& video":{
                    width: "100%",
                    height:'100%',
                    objectFit:'cover'
                },
            }
        },
        localVideoContentWrapper:{
            height:'100%',
            width:'100%',
            position:'relative',
            display:'flex',
            margin:`${SIZING.scale200} 0`,
            [theme.breakpoints.down('lg')]: {
            position:'absolute',
            height:'auto !important',
            bottom:0,
            zIndex:0
            },
           "& .swiper-container":{
                width:'100%',
                "& .swiper-button-disabled":{
                    display:'none'
                },
                "& .swiper-button-next":{
                    background: "linear-gradient(270deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
                    height: "100%",
                    top: '0',
                    marginTop: 'unset',
                    width: '4rem',
                    right:0,
                    "&::after":{
                        backgroundImage: `url(${Image})`,
                        backgroundRepeat:'no-repeat',
                        backgroundSize:"contain",
                        backgroundPosition:'center',
                        content:"''",
                        height:'1.75rem',
                        width:'1.75rem'
                    }
                },
                "& .swiper-button-prev":{
                    background: "linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
                    height: "100%",
                    top: '0',
                    marginTop: 'unset',
                    width: '4rem',
                    left:0,
                    "&::after":{
                        backgroundImage: `url(${Image})`,
                        backgroundRepeat:'no-repeat',
                        backgroundSize:"contain",
                        backgroundPosition:'center',
                        content:"''",
                        height:'1.75rem',
                        width:'1.75rem',
                        transform:'rotate(180deg)'
                    }
                },
                "& .swiper-wrapper":{
                [theme.breakpoints.down('lg')]: {
                    width:'100% !important',
                },
                width:'100%',
                margin:'0 auto',
                paddingLeft:"1.625rem"
                },
                "& .swiper-slide":{
                    width:'auto !important'
                }
            }   
        },
        localVideoTextWrapper:{
            height:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            zIndex:9,
            top:0,
            width:'100%',
            color:COLORS.WHITE,
            position:'absolute'
        },
        remoteVideo:{
            width: "100%",
            height:'100%',
            [theme.breakpoints.down('lg')]: {
                height:'100% !important',
            },
            "& video":{
                width: "100%",
                height:'100%',
            },
        },
        twilioMainWrapper:{
            width: "100%",
            height:'100%',
            position:'relative'
        },
        footer:{
            height:'80px',
            width: "100%",
            background: "#393948",
        },
        placeHolderName:{
            color:COLORS.WHITE,
            position:'absolute',
            top: '50%',
            left: 0,
            right: 0,
            margin: 'auto',
            transform: 'translateY(-50%)',
            background: "#26282a",
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            fontSize:SIZING.scale600,
            width: "100%",
            height:'100%',
            zIndex:1
        },
        patientWaitingLoader: {
            display: 'flex',
            flexDirection: 'column',
            "& .MuiCircularProgress-colorPrimary": {
                color: COLORS.WHITE,
                marginTop: SIZING.scale800
            }

        },
        errorMessage:{
            whiteSpace:'pre-line',
            textAlign:'center',
            lineHeight:'1.5'
        },
        dNone:{
            display:'none'
        }
}));