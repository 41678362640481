import { useIntl } from "react-intl";
import { createPortal } from "react-dom";
import { DetailsPopover, DetailsPopoverItem } from "lib/ui-components/details-popover";
import { useProviderDetails } from "./provider-search-hooks";
import { Location, Provider } from "./types";

export type ProviderDetailsPopoverProps = {
    provider: Provider;
    onClose: () => void;
    anchorEl?: Element;
    portalEl?: HTMLElement | null;
    open?: boolean;
};


const LocationItem = ({ location }: { location: Location }) => {
    const stateAndPostalCode = [location.state, location.postalCode].filter(s => Boolean(s)).join(" ");
    return (
        <>
            <div>{location.name}</div>
            <div className="locationAddress">
                {[location.address, location.city, stateAndPostalCode].filter(s => Boolean(s)).join(", ")}
            </div>
        </>
    );
};

const LocationsList = ({ items }: { items?: Location[] }) => {
    if (!items?.length) {
        return <>-</>;
    }
    if (items.length === 1) {
        return <LocationItem location={items[0]} />;
    }
    return (
        <ul>
            {items.map((item, index) => (
                <li key={index} className="location">
                    <LocationItem location={item} />
                </li>
            ))}
        </ul>
    );
};

export const ProviderDetailsPopover = ({ provider, open, onClose, anchorEl, portalEl }: ProviderDetailsPopoverProps) => {
    const intl = useIntl();

    const { result, hasResult } = useProviderDetails(open ? { id: provider.id } : {});

    if (open) console.log({ provider, anchorEl, portalEl });
    if (!open || !anchorEl || !portalEl) {
        return null;
    }

    const providerView = hasResult && result ? result : provider;

    return createPortal(
        <DetailsPopover
            id={`QA_ProviderDetailsPopover_${provider.id}`}
            testId={`QA_ProviderDetailsPopover_${provider.id}`}
            onClose={onClose}
            anchorEl={anchorEl}
            loading={!hasResult}
            title={provider.providerName}
        >
            <DetailsPopoverItem labelText={intl.formatMessage({ id: "ProviderSearch.provider.details.specialties" })}>
                <div>
                    <span className="specialties">
                        {providerView.specialties?.join(", ")}
                        {providerView.specialties?.length && providerView.subSpecialties?.length && "; "}
                    </span>
                    <span className="subspecialties">{providerView.subSpecialties?.join(", ")}</span>
                </div>
            </DetailsPopoverItem>
            <DetailsPopoverItem labelText={intl.formatMessage({ id: "ProviderSearch.provider.details.experience" })}>
                -
            </DetailsPopoverItem>
            <DetailsPopoverItem separator />
            <DetailsPopoverItem labelText={intl.formatMessage({ id: "ProviderSearch.provider.details.insurance" })}>
                {providerView.insurances.join(", ")}
            </DetailsPopoverItem>
            <DetailsPopoverItem labelText={intl.formatMessage({ id: "ProviderSearch.provider.details.networks" })}>
                {providerView.networks.join(", ")}
            </DetailsPopoverItem>
            <DetailsPopoverItem separator />
            <DetailsPopoverItem labelText={intl.formatMessage({ id: "ProviderSearch.provider.details.locations" })}>
                {/** TODO this filter is probably only necessary due to an issue in the api, once fixed we can remove it */}
                <LocationsList items={providerView.locations?.filter(({ name }) => Boolean(name))} />
            </DetailsPopoverItem>
        </DetailsPopover>,
        portalEl
    );
};
