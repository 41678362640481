import { makeStyles } from "@mui/styles";
import {SIZING, COLORS} from "theme";

export const useStyles = makeStyles(() => ({
        pauseDuration: {
            color: COLORS.PRIORITY_BG,
            fontSize: SIZING.scale350,
            fontWeight: 700
        },
}));
