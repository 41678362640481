import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    Box, Typography, DialogActions,
    DialogContent, IconButton, Grid
} from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import ConfigurationListing from "../ConfigurationListing/ConfigurationListing";
const WelcomeConfigurationModal = () => {
    const intl = useIntl();
    const classes = useStyles();
    const modalClasses = {
        paper: classes.wrapper
    };
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(setActiveModal(""))
    };
    return (
        <Modal onClose={handleClose} classes={modalClasses} id={MODAL_TYPES.WELCOME_CONFIGURATION_MODAL}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"WelcomeConfiguration.ModalHeading"} />
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnCloseVisitTypesModal" size="large" onClick={handleClose} >
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogContent className={classes.contentDialog}>
                <Box mb={1} className={classes.formGpHeader}>
                    {intl.formatMessage({ id: "WelcomeConfiguration.WelcomeHeading" })}
                </Box>
                <Box mb={1} className={classes.formGpSubHeader}>
                    {intl.formatMessage({ id: "WelcomeConfiguration.WelcomeText" })}
                </Box>

                <Grid container spacing={2}>
                    <ConfigurationListing fromWelcomeModal={true} />
                </Grid>
            </DialogContent>
        </Modal>
    );
}

export default WelcomeConfigurationModal