import { IconButton, List, ListItem, ListItemText, Box, Tooltip } from "@mui/material";
import { ReactComponent as Help } from "admin/assets/common/Help.svg";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { withStyles } from "@mui/styles";
import { useStyles } from "./styles";
import { UserLevelDetailsMenuProps } from "./types";

const UserLevelDetailsMenu = ({ userLevels }: UserLevelDetailsMenuProps) => {
    const classes = useStyles()
    const UserLevelsTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#F6F6F7',
            color: '#393948',
            minWidth: '850px',
        },
        arrow: {
            color: "#F6F6F7"
        },
    }))(Tooltip);
    return (
        userLevels &&
        <Fragment>
            <UserLevelsTooltip arrow title={
                <Box className={classes.menuContent}>
                    <Box className={classes.headerText}>
                        <FormattedMessage id={'UserLevelDetailsMenu.Header'} />
                    </Box>
                    <List classes={{ padding: classes.listText }}>
                        {
                            userLevels?.filter((ul: any) => ul.type !== "PATIENT").map((status: any) => {
                                return (
                                    <ListItem key={status.value} role={undefined} dense button style={{ flex: "0 !important" }}>
                                        <ListItemText classes={{ primary: classes.listDesc }} >
                                            <b>{status?.description}</b>{(status?.remark ? ' - ' + status?.remark : '')}
                                        </ListItemText>
                                    </ListItem>
                                );
                            })}
                    </List>
                </Box>
            } >
                <IconButton id="btnToggleUserLevelDetails" size="large" style={{ padding: '0px' }}>
                    <Help aria-controls={'menu-list-grow'} aria-haspopup="true" />
                </IconButton>
            </UserLevelsTooltip>

        </Fragment>
    );
}

export default UserLevelDetailsMenu