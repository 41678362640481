import {
    Button, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography
} from "@mui/material";
import { ADMIN_WEB_CACHE } from "admin/constant";
import { saveVisitTypes, updateWebAdminCachePractice } from "admin/store/actions";
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from "admin/store/reducerTypes";
import { ReactComponent as PlusSquare } from "icon-library/SVG/Plus_Square.svg";
import { ReactComponent as Delete } from "icon-library/SVG/Trash.svg";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { createSvgIcon } from "../../../../utils";
import Controls from "../../Controls/Controls";
import { useStyles } from "./styles";

interface Data {
    id: string
    name: string
    displayName: string
    description: string
    durationMinutes: any
    ehrVisitTypeId: string
    uiId: string
}

const VisitTypesListing = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const classes = useStyles();
    const { configuration, auth, admin } = useSelector((state: AppReduxStore) => state)
    const { adminWebCachePractice } = admin
    const visitTypesList = configuration && configuration.visitTypes ? configuration.visitTypes : []

    const DeleteIcon = createSvgIcon(Delete);
    const PlusSquareIcon = createSvgIcon(PlusSquare);

    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            },
            saveAndContinueLater(index: number) {
                return saveAndContinue(index)
            }
        }),
    )

    const [visitTypeData, setVisitTypeDataState] = useState(visitTypesList.map((row: Data) => (
        {
            id: row.id,
            name: row.name,
            displayName: row.displayName,
            description: row.description,
            durationMinutes: row.durationMinutes,
            ehrVisitTypeId: row.ehrVisitTypeId,
            uiId: row.id
        }
    )));

    useEffect(() => {
        const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData
        if (admin.configurePracticeId) {
            tempFormData?.eVisitData && setVisitTypeDataState(tempFormData?.eVisitData);
        }
    }, [admin.configurePracticeId])

    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {};
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgName: auth.currentOrg?.name,
            tempFormData: {
                name: admin.configurePracticeName,
                configurePractice: true,
                eVisitData: [...visitTypeData],
                selectedStep: index
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }

    const validate = () => {
        if (!visitTypeData || visitTypeData?.length === 0) {
            dispatch(showNotification(intl.formatMessage({ id: "VisitTypes.Error.VisitTypeContentErrorHeading" }), "error", intl.formatMessage({ id: "VisitTypes.Error.VisitTypeContentPresentError" })))
            return false
        }

        const emptyCellExists = visitTypeData.some(
            (vt: any) => vt?.ehrVisitTypeId?.trim() === "" || vt?.name?.trim() === ""
        );

        if (emptyCellExists) {
            dispatch(showNotification(intl.formatMessage({ id: "Invalid" }), "error", intl.formatMessage({ id: "VisitTypes.Error.FormValidationError" })))
            return false
        }
        const ehrVisitTypeIdArr = visitTypeData.map((e: any) => e?.ehrVisitTypeId)
        const hasDuplicateIds = ehrVisitTypeIdArr.some((x: any) => ehrVisitTypeIdArr.indexOf(x) !== ehrVisitTypeIdArr.lastIndexOf(x))
        if (hasDuplicateIds) {
            dispatch(showNotification(intl.formatMessage({ id: "VisitTypes.Error.DuplicateVisitTypeID" }), "error", intl.formatMessage({ id: "VisitTypes.Error.DuplicateVisitTypeIDDesc" })))
            return false
        }
        return true
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (validate()) {
            dispatch(saveVisitTypes(visitTypeData.map((row: Data) => ({
                id: row.id,
                name: row.name,
                displayName: row.displayName,
                description: row.description,
                durationMinutes: row.durationMinutes,
                ehrVisitTypeId: row.ehrVisitTypeId
            }))))
            return true
        }
        return false
    }

    const onRowValueChange = (e: any, selectedRow: Data) => {
        const fieldValue = e.target.value;
        const fieldName = e.target.name;
        const tempDataArray = visitTypeData.map((row: Data) => {
            if (row.uiId === selectedRow.uiId) {
                return { ...row, [fieldName]: fieldValue };
            }
            return row;
        });
        setVisitTypeDataState(tempDataArray);
    }

    const handleDeleteRowAction = (visitTypeFormId: any) => {
        const tempDataArray = [...visitTypeData]
        visitTypeData && visitTypeData.find((obj: any, index: number) => {
            return (obj.uiId === visitTypeFormId) ? tempDataArray.splice(index, 1) : false;
        })
        setVisitTypeDataState(tempDataArray)
    }

    const handleAddBlankRow = () => {
        setVisitTypeDataState([{
            id: "",
            name: "",
            displayName: "",
            description: "",
            durationMinutes: 0,
            ehrVisitTypeId: "",
            uiId: Math.random().toString()
        }, ...visitTypeData]);
    }

    return (
        <Grid container>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    key={"ehrVisitTypeId"}
                                    align={'left'}
                                    padding="normal"
                                    style={{ paddingLeft: '48px', width: '300px' }}>
                                    <strong>
                                        {'EHR Visit Type ID'}
                                    </strong>
                                </TableCell>
                                <TableCell style={{ paddingLeft: '48px', width: '400px' }}
                                    key={"name"}
                                    align={'left'}
                                    padding={'none'}>
                                    <strong>
                                        {'EHR Visit Name'}
                                    </strong>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} style={{ backgroundColor: "#0F7BBD" }}>
                                    <Link onClick={handleAddBlankRow} style={{ textDecoration: 'none' }}>
                                        <Typography className={classes.lblAddAnotherEntry}>
                                            <PlusSquareIcon fill="#FFFFFF" className={classes.noIcon} />
                                            <FormattedMessage id={"VisitTypes.Action.AddAnotherEntry"} />
                                        </Typography>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visitTypeData
                                .map((row: Data) => {
                                    return (
                                        <TableRow key={row.uiId}>
                                            <Controls.EditableTableCell
                                                name="ehrVisitTypeId"
                                                row={row}
                                                placeholder="Enter Type ID here"
                                                value={row.ehrVisitTypeId}
                                                onChange={(e: any) => onRowValueChange(e, row)} />
                                            <Controls.EditableTableCell
                                                name="name"
                                                row={row}
                                                placeholder="Enter Name here"
                                                value={row.name}
                                                onChange={(e: any) => onRowValueChange(e, row)} />
                                            <TableCell align="right">
                                                <Button className={classes.iconBtn} onClick={() => { handleDeleteRowAction(row.uiId) }}><DeleteIcon fill="#E32B29" className={classes.noIcon} /></Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    );
})

export default VisitTypesListing