import { DialogContent, Box, Button, DialogActions, IconButton, Typography } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { Fragment } from "react";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import { showNotification } from "admin/store/actions/snackbar";
import { offboardOrganization } from "admin/store/actions/organization";

const OffboardOrganizationConfirmation = () => {
    const dispatch = useDispatch()
    const { modalAdmin } = useSelector((state: AppReduxStore) => state)
    const intl = useIntl()
    const classes = useStyles()
    const onClose = () => {
        dispatch(setActiveModal(""))
    }

    const onConfirm = (event: any) => {
        event.preventDefault()
        if (modalAdmin.modalProps?.orgId) {
            dispatch(offboardOrganization(modalAdmin.modalProps?.orgId))
        }
        else {
            dispatch(showNotification("Offboard Organization Error", "error", "OrganizationId not found"))
        }
        onClose()
    };
    const modalClasses = {
        paper: classes.wrapper
    }
    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.OFFBOARD_ORGANIZATION_CONFIRMATION_MODAL} small={true}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "OffboardOrganizationConfirmation.ConfirmationModal.Title" })}
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnCloseConfirmationModal" size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined" style={{ zIndex: 100 }}
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "OffboardOrganizationConfirmation.ConfirmationModal.CancelButton" })}
                        </Button>
                        <Button id="btnConfirm" variant="outlined" color="primary" style={{ marginLeft: '10px', background: '#E32B29', color: '#fff' }} onClick={onConfirm}>
                            {intl.formatMessage({ id: "OffboardOrganizationConfirmation.ConfirmationModal.DeactivateButton" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Box style={{ padding: '32px 100px' }}>
                    <Typography variant="body2" className={classes.confirmationBody}>
                        {intl.formatMessage({ id: "OffboardOrganizationConfirmation.ConfirmationModal.Body" }, { orgName: modalAdmin.modalProps?.orgName })}
                    </Typography>
                    <Typography variant="body2" className={classes.confirmationDesc}>
                        {intl.formatMessage({ id: "OffboardOrganizationConfirmation.ConfirmationModal.Description" }, { orgName: modalAdmin.modalProps?.orgName })}
                    </Typography>
                </Box>
            </DialogContent>
        </Modal>
    )
}

export default OffboardOrganizationConfirmation