import React, { useRef, useState } from "react";
import { AnyMenuItem } from "common/components/OptionMenu/types";
import { FormattedMessage } from "react-intl";
import { Button, Typography } from "@mui/material";
import { ReactComponent as ChevronDown } from "icon-library/SVG/Chevron_Down.svg";
import { ReactComponent as RadioCircle } from "icon-library/SVG/Radio_Circle.svg";
import { ReactComponent as Trash } from "icon-library/SVG/Trash.svg";
import { call } from "store/api";
import { useRequestContext } from "./request-context";
import OptionMenu from "common/components/OptionMenu/OptionMenu";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { useRequestInfo } from "./use-request-info";
import ConfirmDialog from "./confirm-dialog";
import { RequestActionsProps } from "./types";
import { useStyles } from "./styles";
import { useUserNotification } from "common/utils/use-user-notification";

export const RequestActions = ({ idPrefix, eConsultRequest }: RequestActionsProps) => {
    const anchorEl = useRef(null);
    const classes = useStyles();
    const { enqueueSuccess } = useUserNotification();
    const [open, setOpen] = useState(false);
    const [confirmClaim, setConfirmClaim] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [confirmReject, setConfirmReject] = useState(false);
    const { status, recipientStatus, isSentRequest } = useRequestInfo(eConsultRequest);
    const { changeRequestStage } = useRequestContext();

    const showClaimAction = !isSentRequest && status === "SUBMITTED" && recipientStatus !== "REJECTED";

    const showRejectAction =
        !isSentRequest && status === "SUBMITTED" && recipientStatus !== "ACCEPTED" && recipientStatus !== "REJECTED";

    const showCloseAction = isSentRequest && status === "ASSIGNED";

    const showCancelAction = isSentRequest && status === "SUBMITTED";

    const actions: AnyMenuItem[] = [];

    if (showClaimAction) {
        actions.push({
            id: "QA_EConsult_request_action_claim",
            labelKey: "EConsult.request.content.actions.claim",
            icon: ({ className }: { className: string }) => (
                <SVGIcon svg={RadioCircle} size="scale800" className={className} />
            ),
            onClick: () => setConfirmClaim(true),
        });
    }
    if (showRejectAction) {
        actions.push({
            id: "QA_EConsult_request_action_reject",
            labelKey: "EConsult.request.content.actions.reject",
            danger: true,
            icon: ({ className }: { className: string }) => (
                <SVGIcon svg={Trash} size="scale800" className={className} />
            ),
            onClick: () => setConfirmReject(true),
        });
    }
    if (showCloseAction) {
        actions.push({
            id: "QA_EConsult_request_action_close",
            labelKey: `EConsult.request.content.actions.close`,
            danger: true,
            icon: ({ className }: { className: string }) => (
                <SVGIcon svg={Trash} size="scale800" className={className} />
            ),
            onClick: () => setConfirmClose(true),
        });
    }
    if (showCancelAction) {
        actions.push({
            id: "QA_EConsult_request_action_cancel",
            labelKey: `EConsult.request.content.actions.cancel`,
            danger: true,
            icon: ({ className }: { className: string }) => (
                <SVGIcon svg={Trash} size="scale800" className={className} />
            ),
            onClick: () => setConfirmCancel(true),
        });
    }

    return (
        <>
            <Button
                id={`${idPrefix}_action`}
                color="primary"
                disabled={actions.length === 0}
                variant="contained"
                onClick={() => setOpen(true)}
                endIcon={<SVGIcon svg={ChevronDown} ref={anchorEl} size="scale700" />}
            >
                <Typography variant="button">
                    <FormattedMessage id="EConsult.request.content.actions" />
                </Typography>
            </Button>
            <OptionMenu
                id={`${idPrefix}_options`}
                className={classes.requestActions}
                anchorEl={anchorEl}
                handleClose={() => setOpen(false)}
                variant="light"
                menuConfig={actions}
                open={open}
                placement="bottom-end"
                offset={[24, 12]}
            />
            {confirmClaim && (
                <ConfirmDialog
                    idPrefix="QA_econsult_claim_request_dialog"
                    titleId="EConsult.request.content.actions.confirm.claim.title"
                    messageId="EConsult.request.content.actions.confirm.claim.message"
                    errorMessageId="EConsult.request.content.actions.confirm.claim.error"
                    onConfirm={async () => {
                        await call("POST", `/econsult/v1/requests/${eConsultRequest.id}/response`, {
                            status: "ACCEPT",
                        });
                        enqueueSuccess("EConsult.request.content.actions.confirm.claim.success");
                        changeRequestStage(eConsultRequest.id, "CLAIMED");
                    }}
                    onClose={() => setConfirmClaim(false)}
                />
            )}
            {confirmReject && (
                <ConfirmDialog
                    danger
                    idPrefix="QA_econsult_reject_request_dialog"
                    titleId="EConsult.request.content.actions.confirm.reject.title"
                    messageId="EConsult.request.content.actions.confirm.reject.message"
                    descriptionId="EConsult.request.content.actions.confirm.reject.description"
                    errorMessageId="EConsult.request.content.actions.confirm.reject.error"
                    onConfirm={async () => {
                        await call("POST", `/econsult/v1/requests/${eConsultRequest.id}/response`, {
                            status: "REJECT",
                        });
                        enqueueSuccess("EConsult.request.content.actions.confirm.reject.success");
                        changeRequestStage(eConsultRequest.id, "CLOSED");
                    }}
                    onClose={() => setConfirmReject(false)}
                />
            )}
            {confirmClose && (
                <ConfirmDialog
                    danger
                    idPrefix="QA_econsult_close_request_dialog"
                    titleId="EConsult.request.content.actions.confirm.close.title"
                    messageId="EConsult.request.content.actions.confirm.close.message"
                    descriptionId="EConsult.request.content.actions.confirm.close.description"
                    errorMessageId="EConsult.request.content.actions.confirm.close.error"
                    onConfirm={async () => {
                        await call("PUT", `/econsult/v1/requests/${eConsultRequest.id}/status`, {
                            version: eConsultRequest.version,
                            status: "CLOSED",
                        });
                        enqueueSuccess("EConsult.request.content.actions.confirm.close.success");
                        changeRequestStage(eConsultRequest.id, "CLOSED");
                    }}
                    onClose={() => setConfirmClose(false)}
                />
            )}
            {confirmCancel && (
                <ConfirmDialog
                    danger
                    idPrefix="QA_econsult_cancel_request_dialog"
                    titleId="EConsult.request.content.actions.confirm.cancel.title"
                    messageId="EConsult.request.content.actions.confirm.cancel.message"
                    descriptionId="EConsult.request.content.actions.confirm.cancel.description"
                    errorMessageId="EConsult.request.content.actions.confirm.cancel.error"
                    onConfirm={async () => {
                        await call("PUT", `/econsult/v1/requests/${eConsultRequest.id}/status`, {
                            version: eConsultRequest.version,
                            status: "CANCELLED",
                        });
                        enqueueSuccess("EConsult.request.content.actions.confirm.cancel.success");
                        changeRequestStage(eConsultRequest.id, "CLOSED");
                    }}
                    onClose={() => setConfirmCancel(false)}
                />
            )}
        </>
    );
};
