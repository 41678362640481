import { DialogContent, Box, Button, DialogActions, Grid, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { ReactComponent as AddUser } from "icon-library/SVG/User_Single.svg";
import { createSvgIcon } from "utils";
import UserLicensesListing from "../../UserProductsEdit/Licenses/UserLicensesListing";
import { deleteProfileRoleJoinProductLicense, getProductsForPractice, upsertProfileRoleJoinProductLicense } from "admin/store/actions/license";
import { map } from 'underscore'
import { getUsersByOrgOrPractice, updateWebAdminCachePractice } from "admin/store/actions";
import { ADMIN_WEB_CACHE } from "admin/constant";
const AddUserIcon = createSvgIcon(AddUser);

const AssignProductsConfigure = forwardRef((props: any, ref) => {
    const { admin, auth } = useSelector((state: AppReduxStore) => state);
    const { userList, adminWebCachePractice } = admin
    const classes = useStyles();
    const dispatch = useDispatch()
    const buttonDisabled = userList?.length <= 0
    const [editedProducts, setEditProducts] = useState({} as any)

    useImperativeHandle(
        ref,
        () => ({
            saveAndContinueLaterAction(index: number) {
                return saveAndContinue(index)
            }
        }),
    )

    useEffect(() => {
        if (admin.configurePracticeId) {
            dispatch(getProductsForPractice(admin.configurePracticeId))
            dispatch(getUsersByOrgOrPractice())
            const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData?.userProductsDetails
            if (tempFormData) {
                setEditProducts(tempFormData)
            }
        }
    }, [admin.configurePracticeId])

    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {};
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgName: auth.currentOrg?.name,
            tempFormData: {
                name: admin.configurePracticeName,
                configurePractice: true,
                userProductsDetails: editedProducts,
                selectedStep: index
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }
    const handleSaveProducts = (event: any) => {
        if (admin.configurePracticeId) {
            map(editedProducts, (products: any, profileId: string) => {
                const u = userList && userList.find((u: any) => (u.profileId === profileId))
                const profileRoleId = u?.profileRoleId
                updateProductByProfileRoleId(profileRoleId, products)
            })
        };
    }
    const updateProductByProfileRoleId = (profileRoleId: string, products: any) => {
        const productsUpdated = products.filter((p: any) => p.activeInitial !== p.activeNew)
        productsUpdated && productsUpdated.map((p: any, index: number) => {
            if (p.activeNew) {
                dispatch(upsertProfileRoleJoinProductLicense(p.productId, profileRoleId, null))
            } else {
                dispatch(deleteProfileRoleJoinProductLicense(p.productId, profileRoleId, null))
            }
            return p
        })
    }
    const handleOnProductSelectionChanged = (profileId: string, productsCombination: any) => {
        const updatedProducts = { ...editedProducts };
        updatedProducts[profileId] = productsCombination
        setEditProducts(updatedProducts)
    }
    return (<><Grid item className={classes.productsPageHeader}>
        <Typography className={classes.pageHeaderText}>
            <AddUserIcon fill="#0F7BBD" className={classes.productsIcon} />
            <FormattedMessage id="AddUsers.Text.Config.AddProducts" />
        </Typography>
        <Typography className={classes.pageSubHeaderText}>
            <FormattedMessage id={"AddUsers.Text.Config.AddProducts.SubText"} />
        </Typography>
    </Grid>
        <DialogActions className={classes.dlgAction}>
            <Box>
                <Button id="btnSaveAndFinish" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveProducts} disabled={buttonDisabled}>
                    Save
                </Button>
            </Box>
        </DialogActions>
        {
            userList?.map((user: any) => {
                return (<>
                    <Typography className={classes.pageHeaderText}>
                        {user.fullName}
                    </Typography>
                    <DialogContent>
                        <UserLicensesListing profileId={user.profileId} onProductSelectionChanged={handleOnProductSelectionChanged} />
                    </DialogContent>
                </>
                )
            })
        }
    </>)
})
export default AssignProductsConfigure;