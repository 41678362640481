import React, { useState } from "react";
import usePublications from "../hooksHelper/usePublication";
import { Participant as ParticipantType } from 'twilio-video';
import Publication from "../HelperComponents/Publication";
import Reconnect from "../HelperComponents/Reconnect/Reconnect";
import useParticipantName from "../hooksHelper/useParticipantName";
import { ReactComponent as VideoDis } from "icon-library/SVG/Camera_Video-Disabled.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { useStyles } from "./styles";
import { FormattedMessage } from "react-intl"
type mainVideoPropsType = {
    data: ParticipantType,
    isPatient: boolean,
    updateVideoStatus: (status:boolean)=>void
}

const MainVideo = ({ data,isPatient,updateVideoStatus }:mainVideoPropsType) => {
    const publications = usePublications(data);
    const { getPaticipantName } = useParticipantName()
    const classes = useStyles()
    const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(false)

    const updateVideoEnabled = (status:boolean) =>{
        setIsVideoEnabled(status)
        updateVideoStatus(status)
    }

    const name = getPaticipantName(data.identity, false);
 
    return (
        <div style={{ height: '100%', width: '100%',position:'relative' }} id="domain-speaker-wrapper"  title={name.trim()}>
                <Reconnect data={data}/>
                {!isVideoEnabled&&<div className={classes.participantNameFull}>
                  <SVGIcon  svg={VideoDis} size="scale3200" />
                  <FormattedMessage id='Mainvideo.blockedVideo' values={{ name }} />   
                    </div>}
               {publications.map(publication => (
                <Publication
                    key={publication.kind}
                    publication={publication}
                    videoOnly={true}
                    videoStyle={isPatient?'contain':'auto'}
                    updateVideoState={updateVideoEnabled}
                />
            ))}
        </div>
    )
}
export default MainVideo