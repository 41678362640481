import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";
import { DefaultMessageView } from "./class-names";

export const useStyles = makeStyles(() => ({
    chatHistory: {
        rowGap: SIZING.scale500,
        display: "flex",
        flexDirection: "column",
        flex: "1",
        overflowY: "scroll",
        padding: SIZING.scale700,
        position: "relative",
        "& .typingIndicator": {
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            color: COLORS.PATIENT_DETAIL_LABEL_TEXT,
        },
        "& .keyboard": {
            width: SIZING.scale800,
            marginRight: SIZING.scale200,
        },
        "& .newMessageDivider__divider::before, .newMessageDivider__divider::after": {
            borderColor: COLORS.LBLUE,
        },
        "& .newMessageDivider ~ .Message .Message__body p": {
            fontWeight: '900'
        },
        [`& .${DefaultMessageView}`]: {
            "& hr": {
                color: COLORS.BLACK,
                opacity: 0.1,
                margin: `${SIZING.scale400} 0`
            }
        }
    },
    composeMessage: {
        // TODO Compose has three different cases how it could be displayed.
        // The easiest way could be to support these could be a grid
        display: "grid",
        gridTemplateAreas: `
            "dropzone dropzone"
            "attachmentButton messageSection"`,
        gridTemplateColumns: "auto 1fr",

        "& .ComposeMessage__AttachmentSection": {
            "&__Dropzone": {
                gridArea: "dropzone",
                marginBottom: SIZING.scale400,
            },
            "&__AttachmentButton": {
                gridArea: "attachmentButton",
                height: SIZING.scale1200,
                width: SIZING.scale1200,
                marginRight: SIZING.scale400,
                padding: 0,
                "& svg": {
                    height: SIZING.scale800,
                    width: SIZING.scale800,
                },
            },
        },
        "& .ComposeMessage__MessageSection": {
            display: "flex",
            gap: SIZING.scale600,
            gridArea: "messageSection"
        },
    },
    subHeader: {
        color: "#333333",
        textAlign: "center"
    },
    hr: {
        color: "#333333"
    },
}));
