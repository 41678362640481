import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import classNames from "classnames";
import { ReactComponent as SearchIcon } from "icon-library/SVG/Search.svg";
import { useApi } from "common/utils/use-api";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import Spinner from "common/components/Spinner/Spinner";
import Dropdown from "common/components/dropdown/dropdown";
import { Pagination } from "lib/ui-components/pagination/pagination";
import { Sort, SortDirect, PageRequest } from "lib/domain/service-types";
import EmptyMessage from "common/components/empty-message/empty-message";
import {
    Filter,
    SearchFilter,
    SearchParameters,
    ProviderSearchContent,
    ProviderSearchResponse,
} from "views/EConsult/types";
import CircleOfContacts from "./circle-of-contacts";
import ProvidersList from "./provider-list/provider-list";
import { useStyles } from "./styles";
import { ProvidersSearchProps } from "./types";
import { ProviderSearchFilters } from "lib/business-components/provider-search";

const initPage = {
    number: 0,
    size: 25,
};

// APOLLO-220: network is not supported by BE
const sortableFields = ['last-name'];

const mapFilters = (filters: Filter[]): SearchFilter[] => {
    const filterMap = filters.reduce<Record<string, string[]>>((map, filter) => {
        if (!map[filter.type]) {
            map[filter.type] = [];
        }
        map[filter.type].push(filter.id);
        return map;
    }, {});

    return Object.entries(filterMap).map(([type, values]) => ({ type, values }));
};

const ProviderSearch = ({ onSelect, onRemove, selectedProviders, disabledMessage, selectDisabled, error }: ProvidersSearchProps) => {
    const classes = useStyles();
    const [filters, setFilters] = useState<Filter[] | null>(null);
    const [sorting, setSorting] = useState<Sort>({ direction: SortDirect.ASC, field: 'last-name'});
    const [pageDto, setPageDto] = useState<PageRequest>(initPage);
    const [search, setSearch] = useState<ProviderSearchContent | null>(null);

    const intl = useIntl();

    const params = filters && filters.length ? { filters: mapFilters(filters), page: pageDto, sort: [sorting] } : null;

    const { content: searchResponse, fetchInfo: searchFetchState } = useApi<
        SearchParameters | null,
        ProviderSearchResponse,
        ProviderSearchContent
    >(
        {
            autoRequest: true,
            abortOnNewRequest: true,
            defaultContent: { data: [], page: { size: 0, number: 0, totalElements: 0, totalPages: 0 } },
            endpoint: "/econsult/v1/specialists/search",
            id: "providerSelectionSearchPost",
            method: "POST",
            requestDecisionMaker: (requestParams) => Boolean(requestParams),
            responseTransformer: (result: ProviderSearchResponse): ProviderSearchContent => ({
                page: result.page,
                data: result.data.map(
                    ({ insurances, hospitals, networks, specialties, subSpecialties, ...everythingElse }) => ({
                        ...everythingElse,
                        hospitals: hospitals.map((item) => item.value),
                        insurances: insurances.map((item) => item.value),
                        networks: networks.map((item) => item.value),
                        specialties: specialties.map((item) => item.value),
                        subSpecialties: subSpecialties.map((item) => item.value),
                    })
                ),
            }),
        },
        params
    );

    useEffect(() => {
        if (searchResponse.data.length && (!filters || filters.length === 0)) {
            setSearch(null);
        } else {
            setSearch(searchResponse);
        }
    }, [filters, searchResponse]);

    const onFilterChange = (filters: Filter[]): void => {
        setFilters(filters);
        setPageDto(initPage);
    };
    const onPageChange = (value: number): void => {
        const currentPage = {
            number: value,
            size: pageDto.size,
        };
        setPageDto(currentPage);
    };
    const onSortChange = (sortField: string): void => {
        setSorting({
            direction: SortDirect.ASC,
            field: sortField
        });
    };

    const content = (
        <>
            <ProviderSearchFilters
                onFiltersChanged={onFilterChange}
                error={error}
                placeholder={intl.formatMessage({ id: "EConsult.filterInput.placeholder" })}
            />

            {(!filters || filters.length === 0) && <CircleOfContacts />}

            {filters && filters.length > 0 && (
                <>
                    <Box flexShrink={1} className={classes.resultsHeader}>
                        <CustomTypography variant="modalSectionHeader" className="panelHeader">
                            <SearchIcon />
                            <FormattedMessage id="EConsult.newRequestModal.providerSearch.header" />
                        </CustomTypography>
                        <Dropdown
                            idPrefix="QA_econsult_provider_result_sorting"
                            data-sort-field={sorting.field}
                            data-sort-direction={sorting.direction}
                            className={classes.resultSortSelector}
                            labelId="EConsult.sortSelector.label"
                            onChange={onSortChange}
                            options={sortableFields.map((fieldName) => ({
                                id: fieldName,
                                text: intl.formatMessage({ id: `EConsult.filterType.${fieldName}` }),
                                selected: sorting.field === fieldName,
                            }))}
                            titleId="EConsult.sortSelector.title"
                        />
                    </Box>
                    {!searchFetchState.hasResult ? (
                        <Spinner />
                    ) : !search ? (
                        <EmptyMessage
                            primaryMessage={{ id: "EConsult.newRequestModal.recipients.emptyResultsMessage" }}
                        />
                    ) : (
                        <>
                            <div className="providerListScrollContainer">
                                <ProvidersList
                                    id="QA_provider_search_results"
                                    itemIdPrefix="QA_provider"
                                    disabledMessage={disabledMessage}
                                    onRemove={onRemove}
                                    onSelect={onSelect}
                                    providers={search.data}
                                    selectDisabled={selectDisabled}
                                    selectedProviders={selectedProviders}
                                    showSpecialties
                                />
                            </div>
                            <Box className={classes.footer}>
                                <Pagination
                                    pageNumber={pageDto.number}
                                    pageCount={search.page.totalPages}
                                    onChange={onPageChange}
                                    totalElements={search.page.totalElements}
                                    showTotalCount={true}
                                    totalCountKey="EConsult.newRequestModal.providerSearch.totalCount"
                                />
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    );

    return (
        <Box
            className={classNames(classes.panel, classes.providersSearchBox)}
            display="flex"
            flexDirection="column"
            justifyContent="center"
        >
            {content}
        </Box>
    );
};

export default ProviderSearch;
