import { Box, Grid, Typography, Button, IconButton } from "@mui/material";
import { MODAL_TYPES } from "admin/constant";
import { getTotalUsersCountPieData, setActiveModal, setNavBreadcrumbText } from "admin/store/actions";
import { getProductsForPractice } from "admin/store/actions/license";
import { AppReduxStore } from "admin/store/reducerTypes";
import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { ReactComponent as Edit } from "icon-library/SVG/Pen-Light.svg";
import { canCreateOrgsAndSubOrgs } from "admin/Accessibility";
import PieChart from "admin/components/PieChart/PieChart";
import { LicenseWidgets } from "admin/components/LicenseWidgets/LicenseWidgets";

export const PracticeDetail = () => {
    const { practice, license, admin } = useSelector((state: AppReduxStore) => state)
    const isHomePage = window.location.pathname.startsWith("/admin/home")
    const { sPractice } = practice
    const { practiceLicenses } = license
    const dispatch = useDispatch()
    const classes = useStyles();
    const intl = useIntl()
    const EditIcon = createSvgIcon(Edit);
    const [hideSecret, setHideSecret] = useState(false);
    const [showAllLicenses] = useState(false)
    useEffect(() => {
        if (sPractice?.id) {
            dispatch(getProductsForPractice(sPractice?.id))
            dispatch(setNavBreadcrumbText(sPractice?.name))
            dispatch(getTotalUsersCountPieData(null, sPractice?.id, ["PROVIDER"]))
            dispatch(getTotalUsersCountPieData(null, sPractice?.id, ["PATIENT"]))
        }
    }, [dispatch, sPractice?.id, sPractice?.name]);

    const handleEditPractice = () => {
        dispatch(setActiveModal(MODAL_TYPES.EDIT_PRACTICE))
    };
    // const handleShowAllLicenses = () => {
    //     setShowAllLicenses(!showAllLicenses)
    // }
    const handleHideShowSecret = () => {
        setHideSecret(!hideSecret)
    };
    const handleEditProducts = (e: any) => {
        dispatch(getProductsForPractice(sPractice?.id, true))
        dispatch(setActiveModal(MODAL_TYPES.EDIT_PRACTICE_PRODUCTS))
    };
    const canCreateOrgAndPractice = canCreateOrgsAndSubOrgs()
    return (
        <Fragment>
            <Box className={classes.usersWrapper} style={{ position: "relative" }}>
                <Typography color="textPrimary" className={classes.heading}>
                    {sPractice?.name}
                </Typography>
                <Grid container spacing={2} className={classes.usersTopActionWrapper}>
                    <Grid item xs={6}>
                        <Box display="flex">
                            <Typography color="textPrimary" className={classes.practiceDetailsInfoText} flexGrow={1}>
                                {intl.formatMessage({ id: "PracticeDetail.Label.PracticeInfo" })}
                            </Typography>
                            {canCreateOrgAndPractice && <IconButton onClick={handleEditPractice} size="large" className={classes.iconButtonEdit}><EditIcon fill="#0F7BBD" className={classes.editIcon} /></IconButton>}
                        </Box>
                        <Box className={classes.whiteBackGroundWrapper}>
                            <Typography color="textPrimary" className={classes.practiceDetailsTextSize}>
                                {sPractice?.address1}
                            </Typography>
                            <Typography color="textPrimary" className={classes.practiceDetailsTextSize}>
                                {sPractice?.address2}
                            </Typography>
                            <Typography color="textPrimary" className={classes.practiceDetailsTextSize} style={{ marginBottom: '40px' }}>
                                {sPractice?.city} {sPractice?.state} {sPractice?.postalCode}
                            </Typography>
                            {hideSecret ?
                                !isHomePage && <Typography color="textPrimary" className={classes.practiceDetailsTextSize} style={{ marginBottom: '30px' }}>
                                    {intl.formatMessage({ id: "PracticeDetail.Label.SecretCode" })} {(sPractice?.secretCode) ? Buffer.from(sPractice?.secretCode, "base64").toString("ascii") : ""} &nbsp;&nbsp;
                                    <Button href="#" color="primary" onClick={handleHideShowSecret}>{intl.formatMessage({ id: "PracticeDetail.Label.Hide" })}</Button>
                                </Typography>
                                :
                                !isHomePage && <Typography color="textPrimary" className={classes.practiceDetailsTextSize} style={{ marginBottom: '30px' }}>
                                    {intl.formatMessage({ id: "PracticeDetail.Label.SecretCode" })}
                                    <Button href="#" color="primary" onClick={handleHideShowSecret}>{intl.formatMessage({ id: "PracticeDetail.Label.Show" })}</Button>
                                </Typography>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Box>
                            <Typography color="textPrimary" className={classes.practiceDetailsInfoText}>
                                {intl.formatMessage({ id: "PracticeDetail.Label.PatientsPieHeading" })}
                            </Typography>
                        </Box>
                        <Box className={classes.whiteBackGroundWrapper}>
                            <PieChart data={admin.totalPatientsCountPieData} showLabel={false} heading="Total Patients" />
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box>
                            <Typography color="textPrimary" className={classes.practiceDetailsInfoText}>
                                {intl.formatMessage({ id: "PracticeDetail.Label.UsersPieHeading" })}
                            </Typography>
                        </Box>
                        <Box className={classes.whiteBackGroundWrapper}>
                            <PieChart data={admin.totalUsersCountPieData} showLabel={false} heading="Total Users" />
                        </Box>
                    </Grid>
                </Grid>
                {practiceLicenses && practiceLicenses?.length > 0 && canCreateOrgAndPractice && <>
                    <Grid item xs>
                        <Button id="btnEditProducts" variant="contained" color="primary" style={{ float: 'right', margin: '10px 0px' }} onClick={handleEditProducts}>
                            {intl.formatMessage({ id: "PracticeDetail.Button.EditProducts" })}
                        </Button>
                    </Grid>
                </>}
                {practiceLicenses && <LicenseWidgets detailedView={showAllLicenses} products={practiceLicenses} />}
            </Box>
        </Fragment>
    );
}