import React from "react";
import { useStyles } from "./styles";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import EvisitActionButton from "./EvisitActionButton/EvisitActionButton"
import { EvisitLiveEvent } from "assets"
import { formatDateWithTimeType2 } from "utils"
const EvisitStatusBar = () => {

    const classes = useStyles()

    const { selectedPatient } = useSelector((state: AppReduxStore) => state.patient)

    if (!selectedPatient) return null

    const { evistDetails,twilioIdentity } = selectedPatient

    // @ts-ignore
    if (Object.keys(evistDetails).length === 0) return null

    // @ts-ignore
    const { visitDuration, status, providerName, scheduleStartTime } = evistDetails;

  const isEvistAlreadyStart = status!=='WAITINGROOM'


    return (
        <Box className={`${classes.detailsHeader} ${isEvistAlreadyStart?'started':''}`}>
            <div className={classes.iconWrapper}>
            {isEvistAlreadyStart ? <EvisitLiveEvent /> : <NotificationsIcon />}
            </div>
            <div>
                <Typography variant="h6" className={classes.textCapitalize}>
                    <FormattedMessage id={isEvistAlreadyStart? "evistStatusBar.titleJoinAgain":"evistStatusBar.title"} />
                </Typography>
                <Typography variant="h6">
                    <span><FormattedMessage id="evistStatusBar.appointment" /> {formatDateWithTimeType2(scheduleStartTime)} {visitDuration}
                    <FormattedMessage id="evistStatusBar.with" /> {providerName}</span>
                </Typography>
            </div>
            <EvisitActionButton  status={status} patientTwilioId={twilioIdentity}/>
        </Box>
    )
}

export default EvisitStatusBar