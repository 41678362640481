import { useEffect, useState } from "react";
import { Participant} from 'twilio-video';

export default function useParticipantReconnecting(participant: Participant) {
  const [isReconnecting, setReconnecting] = useState<boolean>(false);

  useEffect(() => {

    const reconnecting = ()=>setReconnecting(true)
    const reconnected = ()=>setReconnecting(false)

    participant.on('reconnecting', reconnecting);
    participant.on('reconnected', reconnected);
    return () => {
      participant.off('reconnecting', reconnecting);
      participant.off('reconnected', reconnected);
    };
  }, [participant]);
  
  useEffect(()=>{
    isReconnecting&&setReconnecting(false)
  },[participant.identity])

  useEffect(()=>{
    participant.state==='connected'&&setReconnecting(false)
  },[participant.state])

  return isReconnecting;
}
