import { makeStyles } from "@mui/styles";
import { COLORS, SIZING, TYPOGRAPHY, toRem } from "theme";

export const useStyles = makeStyles(() => ({
    eConsult__requestDetailsPane: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    eConsult__requestDetailsContent: {
        flex: "1 1 0",
        overflowY: "auto",
        marginRight: SIZING.scale500,
        "& .eConsult__requestDetailsContent__attachments": {
            padding: SIZING.scale600
        }
    },
    patientDetails: {
        marginTop: SIZING.scale625,
        marginLeft: SIZING.scale625,
        display: 'flex',
        "&.withoutPatient": {
            alignItems: 'center',
            color: COLORS.LGRAY,
            fontSize: SIZING.scale550,
            fontWeight: 700,
            justifyContent: 'center',
            height: "100%",
            margin: "unset"

        }
    },
    eConsult_caseNote_infoMessage: {
        marginTop: SIZING.scale500,
        marginBottom: SIZING.scale100,
        marginLeft: SIZING.scale500,
        marginRight: SIZING.scale500,
        "& .dangerInfo": {
            border: "0.031rem solid rgba(86, 86, 98, 0.2)",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
            borderRadius: SIZING.scale200,
            wordBreak: "break-word",
            whiteSpace: 'pre-wrap',
            backgroundColor: COLORS.NOTIFICATION_TEXT,
            padding: `${SIZING.scale400} ${SIZING.scale475}`,
            textAlign: "center",
            color: COLORS.WHITE,
            ...TYPOGRAPHY.bigInfoMessage
        }
    },
    message: {
        marginTop: SIZING.scale500,
        marginRight: SIZING.scale500,
        marginBottom: SIZING.scale800,
        marginLeft: SIZING.scale500,
        backgroundColor: COLORS.WHITE,
        border: '0.031rem solid rgba(86, 86, 98, 0.2)',
        borderRadius: SIZING.scale200,
        boxShadow: '0 0.063rem 0.25rem rgba(0, 0, 0, 0.05)',
        padding: `${SIZING.scale450} ${SIZING.scale475} ${SIZING.scale825} ${SIZING.scale550}`,
        wordBreak: "break-word",
        color: COLORS.CONVERSATION_TEXT,
        whiteSpace: 'pre-wrap',
    },
    additionalInfo: {
        marginLeft: SIZING.scale800,
        fontSize: SIZING.scale400,
        '& .urgent': {
            fontWeight: 600,
            color: COLORS.NOTIFICATION_TEXT,
            marginBottom: SIZING.scale625
        },
        '& .dueDateText': {
            fontWeight: 600,
            color: COLORS.CONVERSATION_TEXT
        },
        '& .dueDate': {
            fontWeight: 700,
            color: COLORS.GREY
        },
        '& .lastEdited': {
            marginTop: SIZING.scale625
        }
    },
    header: {
        paddingTop: SIZING.scale450,
        paddingRight: SIZING.scale900,
        paddingBottom: SIZING.scale375,
        display: "flex",
        flexDirection: 'column',
        background: COLORS.PAGE_MAIN_HEAD_BG,
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
        "& .eConsult__details__header__referrerOrAssigneeInfo p": {
            display: "inline-block"
        },
        "& .eConsult__details__header__createTime": {
            whiteSpace: "nowrap"
        },
        "& .eConsult__details__header__lockIcon": {
            "& g, & path": {
                fill: COLORS.NOTIFICATION_TEXT,
            },
        }
    },
    urgentIcon: {
        marginRight: SIZING.scale150,
        "& g, & path": {
            fill: COLORS.NOTIFICATION_TEXT,
        },
    },
    title: {
        paddingLeft: SIZING.scale525,
        paddingRight: SIZING.scale450,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        ".eConsult__details__header__lockIcon + &": {
            paddingLeft: SIZING.scale300
        }
    },
    secondInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        minHeight: SIZING.scale550,
        marginTop: SIZING.scale50,
    },
    subject: {
        display: 'flex',
        minHeight: SIZING.scale800,
        alignItems: 'center',
        "& .eConsult__details__header__subjectWarning": {
            color: COLORS.NOTIFICATION_TEXT,
            paddingRight: SIZING.scale325,
        },
        "& .eConsult__details__header__urgent": {
            display: 'flex',
        }
    },
    subTitle: {
        marginRight: SIZING.scale325,
    },
    actionButton: {
        padding: `0 ${SIZING.scale500}`,
        marginRight: SIZING.scale175,
        "& .MuiButton-endIcon": {
            marginLeft: SIZING.scale525,
        },
        "& svg": {
            transform: 'scale(1.3)'
        },
        "& g, & path": {
            stroke: COLORS.PRIMARY_BTN_TEXT,
            transform: 'translate(0%, 3%)',
        },
    },
    tabContainer: {
        marginTop: SIZING.scale500,
        marginLeft: SIZING.scale325,
    },

    /*search-area*/
    searchArea: {
        width: SIZING.ECONSULT_REQUEST_SEARCH_AREA_WIDTH,
        boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
        borderTopRightRadius: '1.25rem',
        position: "inherit",
        background: COLORS.MODAL_BG,
        zIndex: 1
    },
    searchAreaBackground: {
        width: SIZING.ECONSULT_REQUEST_SEARCH_AREA_WIDTH,
        height: SIZING.scale1000,
        background: COLORS.PAGE_MAIN_HEAD_BG,
        position: "absolute",
    },

    searchInputArea: {
        paddingTop: SIZING.scale400,
        paddingRight: SIZING.scale500,
        paddingBottom: SIZING.scale350,
        paddingLeft: SIZING.scale400,
    },
    searchIcon: {
        marginLeft: SIZING.scale375,
        marginRight: `-${SIZING.scale100}`,
        "& g, & path": {
            fill: COLORS.OUTLINED_INPUT_DISABLED_COLOR,
        },
    },
    searchInput: {
        height: SIZING.scale1200,
        "& input": {
            fontSize: SIZING.scale450,
            lineHeight: SIZING.scale525,
            fontWeight: 600,
            color: COLORS.PATIENT_DETAIL_LABEL_TEXT,
            paddingBottom: '1px',
            textIndent: 0,
        },
    },
    filterTabRootArea: {
        padding: `0 ${SIZING.scale400} ${SIZING.scale400}`,
    },
    filterTabRoot: {
        "& .tab": {
            padding: SIZING.scale175,
            height: SIZING.scale750,
            "& p": {
                flexGrow: 1
            }
        },
        "& .tabSelected": {
            border: `${SIZING.scale50} solid`,
            borderColor: COLORS.SEARCH_FILTER_TAB_BG,
            color: 'black',
            "& .count": {
                background: 'black',
            }
        },
        "& .count": {
            height: SIZING.scale400,
            width: SIZING.scale600,
            marginTop: '1px',
            marginLeft: 0,
            "& span": {
                display: "flex",
                fontSize: SIZING.scale275,
                fontWeight: 700
            }
        }
    },
    searchItemArea: {
        display: "flex",
        flexDirection: "column",
        width: SIZING.ECONSULT_REQUEST_SEARCH_AREA_WIDTH,
        height: "100%",
        boxShadow: "1px 0 8px rgba(0, 0, 0, 0.05), inset -1px 0 0 rgba(0, 0, 0, 0.05)",
        overflowY: 'auto',
        paddingBottom: SIZING.scale400,
        flex: "1 1 0",
        "& .emptyMessage": {
            padding: `0 ${SIZING.scale500}`,
        },
        "&.eConsult__itemList--sent": {
            "& .eConsult__folderItem": {
                gridTemplateAreas:
                    `"title icon"
                    "content time"
                    "expiry expiry"
                    "delimiter delimiter"
                    "notification notification"`,
                gridTemplateRows: `${SIZING.scale800}`,
            },
            "& .eConsult__folderItem__time": {
                lineHeight: SIZING.scale300
            },
            "& .eConsult__folderItem__referrerName": {
                display: "none"
            }
        },
        "&.eConsult__itemList--received": {
            "& .eConsult__folderItem": {
                gridTemplateAreas:
                    `"title icon"
                    "referrerName time"
                    "content content"
                    "expiry expiry"
                    "notification notification"`,
                gridTemplateRows: `${SIZING.scale800} ${SIZING.scale775}`,
            },
        }

    },

    /*search-item*/
    eConsult__folderItem: {
        "&.eConsult__folderItem": {
            boxSizing: 'border-box',
            color: COLORS.LIST_ITEM_TEXT,
            display: 'grid',
            fontSize: SIZING.scale300,
            "& p, & span": {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            "& .eConsult__folderItem__title": {
                gridArea: "title",
                color: COLORS.GREY,
                fontSize: SIZING.scale400,
                fontWeight: "bold",
            },
            "& .eConsult__folderItem__icon": {
                gridArea: "icon",
                textAlign: "right",
            },
            "& .eConsult__folderItem__reject": {
                display: "flex",
                alignItems: "center",
                height: SIZING.scale350,
                marginLeft: `-${SIZING.scale75}`,
                "& span": {
                    marginLeft: SIZING.scale100,
                }
            },
            "& .eConsult__folderItem__referrerName": {
                gridArea: "referrerName",
                color: COLORS.GREY,
                fontSize: SIZING.scale350,
                fontWeight: 600,
            },
            "& .eConsult__folderItem__time": {
                color: COLORS.SUBTITLE_INFO_TEXT,
                fontSize: SIZING.scale300,
                gridArea: "time",
                textAlign: "right"
            },
            "& .eConsult__folderItem--warning": {
                color: COLORS.NOTIFICATION_TEXT,
                "& g, & path": {
                    fill: COLORS.NOTIFICATION_TEXT
                }
            },
            "& .eConsult__folderItem__content": {
                gridArea: "content",
                lineHeight: SIZING.scale300
            },
            "& .eConsult__folderItem__expiry": {
                gridArea: "expiry"
            },
            "& .eConsult__folderItem__notification": {
                "&:empty": {
                    display: "none"
                },
                color: COLORS.GREY,
                gridArea: "notification",
                borderTop: "1px solid rgba(0,0,0,.1)",
                marginTop: SIZING.scale400,
                paddingTop: SIZING.scale325
            },
        },
    },
    requestActions: {
        minWidth: toRem(230)
    },
    // TODO: BEM should be used instead of this
    searchItemActive: {
        "& .eConsult__folderItem": {
            border: `1px solid ${COLORS.LIGHT_BLUE}`,
            background: COLORS.PAGE_MAIN_HEAD_BG,
        }
    },
    confirmDialog: {
        "& .confirmDialog__content": {
            fontWeight: 800,
        },
        "& .confirmDialog__contentDescription": {
            fontWeight: 400,
            textTransform: "uppercase"
        }
    },
}));
