import { ProtectedRoutes } from './types';

export const useProtectedRoutes = (protectedRoutes: ProtectedRoutes): { unauthorizedPaths: string[] } => {
    const unauthorizedPaths = Object.entries(protectedRoutes)
        .filter(([_path, authorized]) => !authorized)
        .map(([path]) => path);

    return {
        unauthorizedPaths,
    };
};
