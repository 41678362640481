import { makeStyles } from "@mui/styles";
import { COLORS, SIZING, toRem } from "theme";

export const useStyles = makeStyles(() => ({
    EConsult__messages: {
        backgroundColor: COLORS.MESSAGE_LIST_BG,
        height: "100%",
        "& .EConsult__messages__recipientSelector": {
            display: "flex",
            gap: SIZING.scale800,
            padding: SIZING.scale800
        },
        "& .EConsult__messages__recipientSelector__item": {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "200px"
        },
        "& .EConsult__messages__recipientSelector__item__badge": {
            marginBottom: SIZING.scale500,
            height: toRem(128),
            width: toRem(128),
            "& .MuiBadge-badge": {
                backgroundColor: COLORS.NOTIFICATION_TEXT,
                borderRadius: SIZING.scale400,
                fontSize: SIZING.scale500,
                fontWeight: 700,
                outline: `3px solid ${COLORS.MESSAGE_LIST_BG}`,
                padding: `${SIZING.scale400} ${SIZING.scale275}`,
                right: SIZING.scale400,
                top: SIZING.scale400,
                width: "fit-content",
            },
            "& svg": {
                transform: "scale(1.45)"
            },
            "& g, & path": {
                fill: "rgba(0, 0, 0, .6)"
            },
            "& path[clip-rule]": {
                fill: "rgba(0, 0, 0)"
            }
        },
        "& .EConsult__messages__recipientSelector__item__name": {
            color: COLORS.LIGHT_BLUE,
            cursor: "pointer",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        },
        "& .EConsult__messages__recipientSelector__item__messageInfo": {
            color: COLORS.LIST_SUBHEADER_TEXT,
            fontSize: SIZING.scale300,
            fontWeight: 600,
            "&--highlighted": {
                color: COLORS.NOTIFICATION_TEXT
            }
        },
        "& .EConsult__messages__chat": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
        },
        "& .EConsult__messages__chat__activitySection": {
            padding: SIZING.scale400,
            background: COLORS.PAGE_MAIN_HEAD_BG,
            boxShadow: "1px 0 8px rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 10%)",
            "& .RequestStatusInfoBox": {
                border: "0.5px solid rgba(86, 86, 98, 0.2)",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
                borderRadius: SIZING.scale200,
                padding: SIZING.scale200,
                marginBottom: SIZING.scale500,
                textAlign: "center",
                "&--claimed": {
                    backgroundColor: COLORS.LGREEN,
                    color: COLORS.WHITE
                },
                "&--closed": {
                    backgroundColor: COLORS.NOTIFICATION_TEXT,
                    color: COLORS.WHITE
                },
                "&--unclaimed": {
                    backgroundColor: COLORS.WARNING
                },
                "& p": {
                    margin: 0
                }
            }
        },
    }
}));
