import { useStyles } from "./styles";
import * as CSS from "./class-names";
import classNames from "classnames";

export type MessageContainerVariants = "error" | "info";
type MessageContainerProps = {
    icon: JSX.Element | undefined;
    id?: string;
    message: string | undefined;
    variant?: MessageContainerVariants;
};

const MessageContainer: React.FC<MessageContainerProps> = ({ children, icon, id, message, variant }) => {
    const styles = useStyles();
    return (
        <div className={`${styles.MessageContainer} ${CSS.MessageContainer}`}>
            {children}
            <div
                id={id}
                data-testid={id}
                className={classNames(CSS.MessageContainerMessage, {
                    [`${CSS.MessageContainerMessage}--${variant}`]: variant,
                })}
            >
                {message || icon ? (
                    <>
                        {icon}
                        <span>{message}</span>
                    </>
                ) : (
                    <>&nbsp;</>
                )}
            </div>
        </div>
    );
};

export default MessageContainer;
