import React, {useState} from "react"
import {useDispatch} from "react-redux";
import {Box, Button, Checkbox, DialogActions, DialogContent} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {MODAL_TYPES} from "constant";
import {ReactComponent as MortarboardSvg} from "icon-library/SVG/Mortarboard.svg";
import {ReactComponent as Close} from "admin/assets/common/Close.svg";
import {setActiveModal, updateRegisteredUserProfile} from "store/actions";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import {createSvgIcon} from "utils";
import {COLORS, SIZING} from "theme";
import Modal from "components/Modal/Modal";

import {useStyles} from "./styles";

// @ts-ignore
const {LMS_URL} = window.env

const Mortarboard = createSvgIcon(MortarboardSvg)

// LearningCenter renders an info modal with a button link to the Altais Learning Center website. Users can choose to
// opt out of seeing this modal in the future
const LearningCenter = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [checked, setChecked] = useState(false)

    const onCheck = (e: any) => {
        setChecked(e.target.checked)
        dispatch(updateRegisteredUserProfile(checked))
    }

    const onClose = () => dispatch(setActiveModal(''))

    // onAccessClick opens a new tab in the users browser to the Learning Center website and closes the modal
    const onAccessClick = () => {
        window.open(LMS_URL, "_blank")
        onClose()
    }

    const Content = () => (
        <DialogContent className={classes.content}>
            <Box mb={SIZING.scale400}>
                <CustomTypography variant="descriptionBold">
                    <FormattedMessage id="LearningCenter.description.accessTutorials"/>
                </CustomTypography>
            </Box>
            <Box display="flex">
                <CustomTypography variant="descriptionLight" id="QA_access_learning_center_button" >
                    <FormattedMessage id="LearningCenter.description.clickButton1"/>
                    <span style={{fontStyle: "italic"}}>
                            <FormattedMessage id="LearningCenter.description.clickButton2"/>
                        </span>
                    <FormattedMessage id="LearningCenter.description.clickButton3"/>
                </CustomTypography>
            </Box>
            <Mortarboard size="256px" fill={COLORS.BADGE_BG} />
            <Button onClick={onAccessClick} className={classes.xlButton}>
                <CustomTypography variant="xlButton">
                    <FormattedMessage id="LearningCenter.button.accessLearningCenter" />
                </CustomTypography>
            </Button>
        </DialogContent>
    )

    return (
        <Modal maxWidth="xs" id={MODAL_TYPES.PROVIDER.LEARNING_CENTER}>
            <Box className={classes.titleWrapper}>
                <CustomTypography variant="modalHeader">
                    <FormattedMessage id="LearningCenter.title" />
                </CustomTypography>
                <Close className={classes.close} onClick={onClose} id="QA_learning_center_close_button" />
            </Box>
            <Content />
            <DialogActions classes={{root: classes.actions}}>
                <Checkbox color="primary" disableRipple disableFocusRipple checked={checked} onChange={onCheck} id="QA_learning_center_check_box" />
                <CustomTypography variant="description">
                    <FormattedMessage id="LearningCenter.checkbox.dontShowScreen" />
                </CustomTypography>
            </DialogActions>
        </Modal>
    )
}

export default LearningCenter