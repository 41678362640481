import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, COLORS, SIZING} from "../../../theme";


export const useStyles = makeStyles(() => ({
    dialogRoot: {
        padding: SIZING.scale400,
        overflow:'visible',
        paddingTop:'0'
    },
    wrapper: {
        minWidth: "640px",
        minHeight: "500px",
        background: "#393948",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.XL,
        overflow:'visible',
        position:'relative',
        "& .MuiDialogTitle-root":{
            padding:SIZING.scale400
        },
        "& .component-loader-wrapper":{
            zIndex:9,
            background:COLORS.INPUT_BG,
            opacity:0.7,
            borderRadius:BORDER_RADIUS.XL
        }
    },
    iframe: {
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: BORDER_RADIUS.XL,
        border: "1px solid rgba(255, 255, 255, 0.3)",
        backgroundColor: COLORS.PRIMARY_BTN_TEXT
    },
    closeButton:{
        padding: '0 !important',
        minWidth: SIZING.scale800,
        height: SIZING.scale800,
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(230, 234, 241, 0) 100%), rgba(255, 255, 255, 0.1)",
        "&:hover":{
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(230, 234, 241, 0) 100%), rgba(255, 255, 255, 0.1) !important",
        }
    },
    endButton: {
        height: SIZING.scale800,
        marginRight: SIZING.scale200,
        paddingLeft: SIZING.scale100,
        background: "#FF453A"
    },
    endIcon: {
        width: SIZING.scale800,
        height: SIZING.scale800,
        "& path": {
            fill: COLORS.BUTTON_ICON
        }
    },
    videoIcon: {
        width: SIZING.scale1000,
        height: SIZING.scale1000,
        "& path": {
            fill: COLORS.BUTTON_ICON
        }
    },
    icon: {
        width: SIZING.scale700,
        height: SIZING.scale700,
        "& path": {
            fill: COLORS.BUTTON_ICON
        }
    },
    iframeWrapper:{
        height: '550px',
        width: '640px',
        position:'relative',
        borderRadius: BORDER_RADIUS.XL,
        "& >.MuiTypography-root":{
            position:'absolute'
        }
    },
    patientInfoSection:{
        alignSelf:'flex-start',
        fontSize:`${SIZING.scale300} !important`
    }
}));