import React, { Fragment } from "react";
import { useIntl } from 'react-intl';
import { ListItem, ListItemIcon, ListItemText, Link } from "@mui/material";
import { GridContextMenuContentProps } from 'admin/components/GridContextMenuContent/types';
import { useStyles } from './styles';
import { createSvgIcon } from "utils";
import {ReactComponent as Edit} from "icon-library/SVG/Pen.svg";
import {ReactComponent as User} from "icon-library/SVG/User_Users.svg";
import {ReactComponent as Enable} from "icon-library/SVG/Radio-Light_Circle.svg";
import {ReactComponent as Disable} from "icon-library/SVG/Cross-Light_Circle.svg";

const PatientFormGridContextMenu = ({ callback, handleClose, showEnable, showDisable, handleModalClick }: GridContextMenuContentProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const EditIcon = createSvgIcon(Edit);
    const UserIcon = createSvgIcon(User);
    const EnableIcon = createSvgIcon(Enable);
    const DisableIcon = createSvgIcon(Disable);

    return (
        <Fragment>
            <ListItem button>
                <ListItemIcon>
                    <EditIcon className={classes.noIcon} />
                </ListItemIcon>
                <Link onClick={() => { callback("EditForm"); handleClose() }}>
                    <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.EditForm" })} classes={{ primary: classes.listText }} />
                </Link>
            </ListItem>
            <ListItem button disabled>
                <ListItemIcon>
                    <UserIcon className={classes.noIcon} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.ViewAsPatient" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditForm"); handleClose() }} />
            </ListItem>
            { showEnable && <ListItem button>
                <ListItemIcon>
                    <EnableIcon className={classes.noIcon} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.EnableForm" })} classes={{ primary: classes.listText }} onClick={() => { callback("EnableForm"); handleClose() }} />
            </ListItem>}
            { showDisable && <ListItem button>
                <ListItemIcon>
                    <DisableIcon className={classes.noIcon} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.DisableForm" })} classes={{ primary: classes.listText }} onClick={handleModalClick} />
            </ListItem>}
        </Fragment>
    );
}

export default PatientFormGridContextMenu;