import { COLORS, SIZING, toRem } from "theme";
import { makeStyles } from "@mui/styles";
import * as CSS from "./class-names";

export const useStyles = makeStyles(() => ({
    [CSS.ModalSidePanelLayout]: {
        display: "flex",
        width: "100%",
        flexGrow: 1,
        "& > *": {
            display: "flex",
            flexDirection: "column",
            padding: SIZING.scale400,
        },
        [`& .${CSS.ModalSidePanelLayout__LeftPanel}`]: {
            flexGrow: 1,
            minWidth: toRem(260),
            maxWidth: toRem(260),
            backgroundColor: COLORS.MODAL_CONTENT_LBLUE_BG,
        },
        [`& .${CSS.ModalSidePanelLayout__RightPanel}`]: {
            flexGrow: 3,
            backgroundColor: COLORS.WHITE,
        }
    }
}));
