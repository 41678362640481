import { useState, useEffect } from "react";
import { Filter } from "lib/ui-components/filtered-search-input";
import { useApi, FetchStates } from "common/utils/use-api";
import { ProviderFilterAutocompleteRequest } from "./types";
import { ProviderSearchOptions } from "../types";

type useProviderFilterAutocompleteProps = {
    filter?: Filter;
    options?: ProviderSearchOptions;
    minSearchLength: number;
};

export const useProviderFilterAutocomplete = ({
    filter = { text: "", type: "", id: "" },
    options,
    minSearchLength,
}: useProviderFilterAutocompleteProps) => {
    const [values, setValues] = useState<string[] | null>(null);

    // clean suggestions is filter text is cleared
    useEffect(() => {
        if (filter.text.length <= minSearchLength) {
            setValues(null);
        }
    }, [filter, minSearchLength]);

    const {
        content: autocompleteValues,
        fetchInfo: { state },
    } = useApi<ProviderFilterAutocompleteRequest, string[] | null>(
        {
            autoRequest: true,
            abortOnNewRequest: true,
            defaultContent: null,
            id: "providerFiltersAutocompletePost",
            method: "POST",
            endpoint: "/econsult/v1/specialists/filters/autocomplete",
            requestDecisionMaker: (requestParams) => requestParams.text.length > minSearchLength,
        },
        {
            limit: 10,
            text: filter.text,
            type: filter.type.toUpperCase(),
            options: options || null,
        }
    );

    useEffect(() => {
        if (autocompleteValues && state === FetchStates.SUCCESS && filter.text.length > minSearchLength) {
            setValues(autocompleteValues);
        }
    }, [state, autocompleteValues, filter]);

    return {
        autocompleteValues: values,
        loadingAutocomplete: state === FetchStates.LOADING,
    };
};

export const useFilterTypes = () => {
    const {
        content: filterTypes,
        fetchInfo: { hasResult, error },
    } = useApi<null, string[], string[]>(
        {
            autoRequest: true,
            defaultContent: [],
            endpoint: "/econsult/v1/specialists/filters",
            method: "GET",
            responseTransformer: (response: string[]) => response.map((item) => item.toLowerCase()),
        },
        null
    );

    return {
        filterTypes,
        loadingFilterTypes: !hasResult,
        error
    };
};
