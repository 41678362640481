import React from "react"
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import SwiperCore, { Navigation } from 'swiper';
const { Swiper, SwiperSlide } = require('swiper/react')
SwiperCore.use([Navigation]);

function SwiperWrapper({ children }: any) {
    return <Swiper navigation={true} slidesPerView={'auto'} spaceBetween={0} className="mySwiper">
        {React.Children.map(children, child => (<SwiperSlide>
            {React.cloneElement(child)}
        </SwiperSlide>
        ))}
    </Swiper>
}

export default SwiperWrapper
