import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pageHeader: {
        margin: '10px'
    },
    pageHeaderText: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
    },
    sectionHeader: {
        margin: '10px',
    },
    sectionHeaderText: {
        fontSize: '22px'
    },
    addressSectionField: {
        margin: '5px 5px 5px 5px'
    },
    editIcon: {
        height: '35px',
        width: '35px',
        verticalAlign: 'middle'
    }
}));