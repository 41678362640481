import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "admin/theme";


export const useStyles = makeStyles(() => ({
    wrapperAuto: {
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.XL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 'bold',
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        background: '#F6F8FA',
        textAlign: 'center',
        minWidth: '800px'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    dlgAction: {
        padding: '5px 32px',
        background:"none"
    },
    previewGridClasses: {
        marginTop: '10px'
    },
    productsPageHeader: {
        marginBottom: '20px'
    },
    pageHeaderTextProduct: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948',
    },
    pageSubHeaderText: {
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#565662'
    },
    productsIcon: {
        fontSize: '42px',
        verticalAlign: 'middle',
        marginRight: '12px'
    },

    pageHeaderText: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
    },
    flexInline: {
        display: "inline-flex",
        lineHeight: "31px"
    },

    logoContent: {
        width: "180px",
        border: "1px dashed #0B5D8F",
        background: "#E8F5FE",
        boxSizing: "border-box",
        minHeight: "84px",
        textAlign: "center",
        alignItems: "center",
        borderRadius: "4px",
        justifyContent: "center",
        margin:"auto"
    },
    logo:{
        height:'82.88px',
        width: '220px',
        backgroundColor:"#F6F6F7",
        margin:"auto",
        color:"#000000",
        border:'1px solid black',
        borderStyle:'dotted',
        paddingTop: '30px',
        textAlign:"center"
    },
    signText:{
        fontSize: "17px",
        fontFamily: "OpenSans",
        fontWeight: "normal",
        lineHeight: "40px",
    },
    body1: {
        fontSize: "17px",
        fontFamily: "OpenSans",
        fontWeight: "normal",
        lineHeight: "20px",
        height:"100px",
        overflow:"auto"
    },
    begin:{
        marginTop:"100px",
        marginBottom:"100px"
    },
    rightText:{
        float:'right'
    },
}));