import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        headerWrapper: {
            width: "100%",
            height: SIZING.HEADER_HEIGHT,
            padding: SIZING.scale400,
            background: COLORS.SIDE_NAV_HEADER_BG,
            boxShadow: "inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
            "& .inputRow": {
                display: 'flex',
                alignItems: 'center',
                marginBottom: SIZING.scale400
            }
        },
        button: {
            marginLeft: SIZING.scale300,
            "&:hover, &:active":{
                backgroundColor:'inherit !important'
            }
        },
        tabsRoot: {
            background: "rgba(0, 0, 0, 0.05)",
            borderRadius: BORDER_RADIUS.PILL,
            minHeight: "0"
        },
        tabRoot: {
            minHeight: "0",
            padding: SIZING.scale200
        },
        indicator: {
            display: "none"
        },
        tabSelected: {
            background: COLORS.SELECTED_TAB_BG,
            borderRadius: BORDER_RADIUS.PILL
        },
        composeDropdown:{
            background:COLORS.ITEM_BADGE_BG,
            color:COLORS.INPUT_BG,
            padding:SIZING.scale300,
            borderRadius:BORDER_RADIUS.MEDIUM,
            "& h6":{
                paddingBottom:SIZING.scale300
            }
        },
        "cursorPointer":{
            cursor:'pointer'
        },
        startIconWrapper: {
          display: "flex",
          alignItems: "center",
          height: SIZING.scale1200
        },
        startIcon: {
            height: SIZING.scale800,
            width: SIZING.scale800,
        }
}));