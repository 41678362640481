import React, { useState, useRef, useEffect } from "react";
// @ts-ignore
import Video from 'twilio-video';
import { useStyles } from "./styles";
import Footer from "./Footer/Footer";
import { Typography, Box } from "@mui/material";
import { TwilioSDKProps } from "./types";
import Spinner from "common/components/Spinner/Spinner";
import { FormattedMessage } from "react-intl";
import { useLocalTracks } from "./hooksHelper/useLocalTracks";
import { isPermissionDenied } from "./utils";
import SwiperWrapper from "common/components/SwiperWrapper/SwiperWrapper";
import Participants from "./Participant/Participant";
import MainVideo from "./MainVideo/MainVideo";
import Timer from "./Timer/Timer";
import { Participant as ParticipantPropType } from 'twilio-video';
import { ParticipantDetailsContextWrapper } from "./hooksHelper/participantNameContext";

export default function Twilio({ roomId, twilioVideoToken, roomCallback, onEndMeeting, peerName, participantType, onPauseMeeting, onParticipantUpdate, onLeaveMeeting,patientIdentity='',timeoutHandler,showInstrcutions }: TwilioSDKProps) {

    const [identity, updateIndentity] = useState<string>('')
    const [currentRoom, updateCurrentRoom] = useState<any>(null)
    const [peerIdentity, updatePeerIdentity] = useState({})
    const [dominantSpeaker, updateDomainSpeaker] = useState(null)
    const [isDomainSpeakerVideoEnabled, updateDomainSpeakerStatus] = useState(false)
    const [participantJoined,updateParticipantJoined] = useState<Boolean>(false)
    const [pinnedParticipant,updatePinnedParticipant] = useState<string>('')
    const [error, updateError] = useState<string>('')
    const [JWT, updateJwt] = useState<string>('')
    const localVideo = useRef<HTMLDivElement>(null)
    const remoteMedia = useRef<HTMLDivElement>(null)
    const isPatient = participantType === "PATIENT";
    const classes = useStyles({ isMobile: isPatient })
    const remoteParticipant: any = Array.from(currentRoom?.participants.values() ?? []);
    const remoteParticipantLength = remoteParticipant.length;

    const {
        localTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        getAudioAndVideoTracks,
    } = useLocalTracks();

    useEffect(() => {
        getTwillioToken()
    }, [])

    useEffect(() => {
        if(!participantJoined&&remoteParticipant.length>0){
            updateParticipantJoined(true)
        }
    }, [remoteParticipantLength,participantJoined])

    useEffect(()=>{ 
        let waitingTimeout:ReturnType<typeof setTimeout>
        if(remoteParticipantLength<=0&&!participantJoined){
            waitingTimeout = setTimeout(() => {
                timeoutHandler()
            }, 60000);
        }
        return ()=>{
            waitingTimeout&&clearTimeout(waitingTimeout)
        }
    },[remoteParticipantLength,participantJoined])

    useEffect(() => {
        onParticipantUpdate && onParticipantUpdate(peerIdentity)
    }, [peerIdentity])

    const handleAudioRefresh = async () => {
        const isMicrophonePermissionDenied = await isPermissionDenied('microphone');
        
        if (!isMicrophonePermissionDenied) {
            joinRoom()
            updateError('')
        }else{
            showInstrcutions&&showInstrcutions('mic|camera')
        }
    }

    const updateDomainSpeakerVideoStatus = (status:boolean) =>{
        updateDomainSpeakerStatus(status)
    }

    const showVideoError = async () => {
        const isCameraPermissionDenied = await isPermissionDenied('camera');
         joinRoom()
        if (isCameraPermissionDenied) {
            updateError('cam')
            showInstrcutions&&showInstrcutions('camera')
            setTimeout(() => {
                updateError('')
            }, 2000);
        }
    }

    useEffect(() => {
        return () => {
            cleanUp()
        }
    }, [currentRoom])

    useEffect(() => {
        JWT && joinRoom()
    }, [JWT])

    const cleanUp = () => {
        removeLocalVideoTrack()
        removeLocalAudioTrack()
    }

    const getTwillioToken = () => {
        updateJwt(twilioVideoToken)
    }

    const addPeerParticipant = (identity: string, property: any = {}) => {
        updatePeerIdentity(prevDetails => ({
            ...prevDetails,
            [identity]: {
                video: true,
                audio: true,
                isLeft: false,
                ...property
            }
        }))
    }

    const deletePeerParticipant = (identity: string,) =>{
        updatePeerIdentity(prevDetails => {
            const temp = { ...prevDetails };
            //@ts-ignore
            delete temp[identity]
            return temp
        })
    }

    const roomJoined = (room: any) => {
        roomCallback(room)
        updateCurrentRoom(room)
        const localIdentity = room?.localParticipant?.identity;
        updateIndentity(localIdentity)

        addPeerParticipant(localIdentity)

        // Attach the Tracks of the Room's Participants.
        room.participants.forEach((participant: any) => {
            addPeerParticipant(participant.identity)
        });
        room.on('dominantSpeakerChanged', (data: any) => {
            data && isPatient && updateDomainSpeaker(data.identity)
        })

        // When a Participant joins the Room, log the event.
        room.on('participantConnected', (participant: any) => {
            addPeerParticipant(participant.identity)
        });

        // When a Participant leaves the Room, detach its Tracks.
        room.on('participantDisconnected', (participant: any) => {
            deletePeerParticipant(participant.identity)
        });
    }


    const getFilterTracks = (tracks: any) => {
        const { audio, video } = tracks;
        const finalTracks = [audio];
        video && finalTracks.push(video)
        return finalTracks
    }

    const getDomainSpeaker = (pinIdentity:string) => {
        const participants: ParticipantPropType[] = Array.from(currentRoom?.participants.values() ?? []);
        const dominantSpeakerVideo = participants.find(
            (p: any) => p.identity === (isPatient ? dominantSpeaker : pinIdentity)
        );

        if (!dominantSpeakerVideo || participants.length === 1) {
            const firstParticipant: ParticipantPropType = participants[0];
            !isPatient && !pinIdentity &&firstParticipant&&(updatePinnedParticipant(firstParticipant.identity))
            return firstParticipant
        }
        return dominantSpeakerVideo
    }

    const joinRoom = async () => {

        getAudioAndVideoTracks().then((tracks: any) => {
            const connectOptions: any = {
                name: roomId,
                video: { height: 720, frameRate: 24, width: 1280 },
                bandwidthProfile: {
                  video: {
                    mode: 'collaboration'
                  }
                },
                preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
                networkQuality: {local:1, remote: 1}
            };
            connectOptions.tracks = getFilterTracks(tracks)
            connectOptions.dominantSpeaker = true

            // Join the Room with the token from the server and the
            // LocalParticipant's Tracks.
            Video.connect(JWT, connectOptions).then(roomJoined, (error: any) => {
                console.error(error, 'error')
            });
        }).catch(error => {
                console.error(error,'error')
                if (error === 'NotAllowedError') {
                    updateError('camera and mic')
                    showInstrcutions&&showInstrcutions('camera|mic')
                } else if (error === 'MicrophonePermissionsDenied') {
                    showInstrcutions&&showInstrcutions('mic')
                }

        })
    }

    const isPatientLeft = ()=>{
       return  !remoteParticipant.find((paticipant:ParticipantPropType )=>paticipant.identity ===patientIdentity)
    }

    const getPeerVideoStatus = () => {

        if (error) {
            return <Typography variant="body1" noWrap className={`${classes.placeHolderName} ${classes.errorMessage}`} >
                  <FormattedMessage id="Twilio.label.errorLabel" values={{ type: error }} />
                </Typography>
        }

        const peerIdentityDetails = Object.keys(peerIdentity).filter((i) => i !== identity);

        if (!participantJoined) {
            return <Typography variant="body1" noWrap className={`${classes.patientWaitingLoader} ${classes.placeHolderName}`} >
                <FormattedMessage id="Twilio.loader.label" values={{ type: isPatient ? 'provider' : 'patient' }} />
                <Box >
                    <Spinner />
                </Box>
            </Typography>
        }

        if ((!isPatient && isPatientLeft()) || remoteParticipant.length < 1) {
            return <Typography variant="body1" noWrap className={classes.placeHolderName} >
                <FormattedMessage id="Twilio.label.participantLeft" values={{ type: isPatient ? 'Provider' : 'Patient' }} />
            </Typography>
        }

        //@ts-ignore
        if (peerIdentityDetails && peerIdentity[peerIdentityDetails]?.video === true) {
            return null
        }
        return <div />
    }

    const checkParticipantCardVisiblity = (identity:string) =>{
        if(remoteParticipant.length <= 1){
            return false
        }else {
            return !isPatient ? !(pinnedParticipant === identity)  : true 
        }
    }

    //@ts-ignore
    const mainVideo: ParticipantPropType = getDomainSpeaker(pinnedParticipant);

    return (
        <div className={classes.twilioMainWrapper} data-testid="twilio-wrapper">
            <ParticipantDetailsContextWrapper>
            <div className={`${classes.twilioWrapper} twilio-wrapper-root`}>
                {
                    !isPatient &&
                    <Timer shouldStartTimer={remoteParticipant.length >= 1} />
                }
                <div ref={remoteMedia} className={classes.remoteVideo}>
                    {getPeerVideoStatus()}
                    {currentRoom?.localParticipant && mainVideo && <MainVideo updateVideoStatus={updateDomainSpeakerVideoStatus} isPatient={isPatient} data={mainVideo} />}
                </div>
                <div className={classes.localVideo} >
                    <div ref={localVideo} className={classes.localVideoContentWrapper}>
                        <SwiperWrapper>
                            {currentRoom?.localParticipant ? <Participants participantType={participantType} shouldDisplayName={false} data={currentRoom?.localParticipant} /> : <div></div>}
                                {remoteParticipant.map((data: any) => <Participants
                                    participants={remoteParticipant}
                                    updatePinnedParticipant={updatePinnedParticipant}
                                    pinnedParticipant={pinnedParticipant}
                                    className={checkParticipantCardVisiblity(data.identity) ? '' : classes.dNone }
                                    data={data}
                                    participantType={participantType}
                                     />)}
                        </SwiperWrapper>
                    </div>
                </div>
            </div>
            <Footer
                room={currentRoom}
                onEndMeeting={onEndMeeting}
                videoAccess={!!localTracks.video}
                showVideoError={showVideoError}
                handleAudio={handleAudioRefresh}
                audioAccess={!!localTracks.audio}
                onPauseMeeting={onPauseMeeting}
                participants={remoteParticipant}
                onLeaveMeeting={onLeaveMeeting}
                participantType={participantType}
                isDomainSpeakerVideoEnabled={isDomainSpeakerVideoEnabled}
            />
            </ParticipantDetailsContextWrapper>
        </div>
    )
}
