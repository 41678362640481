import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() => ({
    searchSection: {
        width: "100%",
        height: SIZING.scale1600,
        background: COLORS.DGREY,
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    searchBox: {
        height: SIZING.scale1000,
        width: "93%",
        border: "1px solid #909098",
        boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)",
        borderRadius: SIZING.scale200,
        background: COLORS.WHITE,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "space-between",
        padding: `0px ${SIZING.scale250}`,
    },
    searchTextSection: {
        display: "flex"
    },
    searchName: {
        marginLeft: SIZING.scale400,
        fontWeight: 400
    },
    baMenu: {
        background: "linear-gradient(0deg, rgba(232, 245, 254, 0.15), rgba(232, 245, 254, 0.15)), #FFFFFF",
        marginTop: `-${SIZING.scale375}`,
        color: COLORS.LIST_SUBHEADER_TEXT,
        fontSize: SIZING.scale400,
        overflow:'hidden',
        zIndex: 3,
        left: `${SIZING.scale200} !important`,
        top: `4.05rem !important`,
        width: "34rem",
        position: 'absolute',
        boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
        filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2))',
        backdropFilter: 'blur(10px)',
        borderRadius: SIZING.scale400,
        "& .MuiListItem-gutters": {
            paddingLeft: `${SIZING.scale250} !important`,
            display: "contents"
        },
        height: "22rem",
        "& hr": {
            margin: `0 ${SIZING.scale250}`
        },
        "& .MuiListItemIcon-root": {
            minWidth: `${SIZING.scale800} !important`
        },
        "& ::-webkit-scrollbar": {
            cursor: "pointer !important",
            background: COLORS.COMPOSE_SCROLL_BAR
        }
    },
    listText: {
        fontSize: SIZING.scale400,
        fontWeight: 'bold',
        position: "relative",
        display: "inline-block"
    },
    traingle: {
        height: SIZING.scale500,
        width: SIZING.scale800,
        position: "absolute",
        left: SIZING.scale1600,
        top: "1.425rem",
        zIndex: 4,
        background: "linear-gradient(0deg, rgba(232, 245, 254, 0.15), rgba(232, 245, 254, 0.15)), #FFFFFF",
        boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.02)',
        transform: "translate(-50%, 50%) rotate(360deg)",
        clipPath: "polygon(50% 0, 100% 100%, 0 100%)"
    },
    searchBoxWrap: {
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center"
    },
    selectWaitingwrap: {
        display: "flex",
        justifyContent: "space-between",
        padding: SIZING.scale250,
        color: COLORS.BLACK
    },
    crossIcon: {
        "& path": {
            fill: COLORS.PRIORITY_BG
        },
        cursor: "pointer"
    },
    inputRoot: {
        padding: `${SIZING.scale50} ${SIZING.scale100}`,
        display: 'flex',
        alignItems: 'center',
        width: "95%",
        borderRadius: SIZING.scale800,
        background: COLORS.WHITE,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
        margin: `0px ${SIZING.scale325}`,
        "& .MuiInputBase-root": {
            border: "none !important",
            marginLeft: `-${SIZING.scale250}`,
            "&::placeholder": {
                color: "rgba(86, 86, 98, 0.8)"
            }
        },
        "& .MuiInputBase-input": {
            "&::placeholder": {
                color: "rgba(86, 86, 98, 0.8)"
            }
        }
    },
    iconButton: {
        padding: 10,
        "& svg path": {
            fill: "rgba(86, 86, 98, .5)"
        }
    },
    input: {
        flex: 1,
        "& input": {
            textIndent: SIZING.scale200,
            color: "rgba(86, 86, 98, .5)",
            minHeight: 'unset',
            height: '2.625rem !important'
        }
    },
    deviceBox: {
        width: "94%",
        height: '3.375rem',
        background: COLORS.SIDE_NAV_HEADER_BG,
        margin: SIZING.scale300,
        marginBottom:0,
        padding: SIZING.scale75,
        "&:last-child":{
            marginBottom:SIZING.scale300,
        },
        borderRadius: SIZING.scale200,
        display: "flex",
        alignItems: "center",
        color: COLORS.SELECTED_BTN,
        "& .MuiIconButton-label": {
            color: COLORS.PRIMARY_BTN_TEXT,
        },
        fontSize: SIZING.scale400,
        "& ::-webkit-scrollbar": {
            cursor: "pointer !important",
            background: COLORS.COMPOSE_SCROLL_BAR
        },
        "&.checked": {
            background: COLORS.LBLUE,
            color: COLORS.WHITE,
            "& .MuiIconButton-label": {
                color: COLORS.WHITE,
            },
        }
    },
    searchProvidersWrap: {
        height: SIZING.SIDE_NAV_OPEN_WIDTH,
        overflowY: "auto",
        "& ::-webkit-scrollbar": {
            cursor: "pointer !important",
            background: COLORS.COMPOSE_SCROLL_BAR
        }
    },
    checkBox: {
        color: COLORS.SELECTED_BTN,
        "&.checked": {
            color: COLORS.WHITE,
            "& svg path": {
                fill: COLORS.WHITE
            },
        }
    },
    checkBoxTextSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: SIZING.scale325
    },
    checkBoxDesc: {
        display: "flex",
        justifyContent: "flex-end",
        width: "52%",
        fontSize: SIZING.scale300,
        fontWeight: 700
    },
    checkBoxcount: {
        background: COLORS.PRIMARY_BTN_TEXT,
        height: SIZING.scale500,
        width: SIZING.scale750,
        borderRadius: SIZING.scale1200,
        color: COLORS.WHITE,
        textAlign: "center",
        fontSize: SIZING.scale350,
        marginLeft: SIZING.scale400,
        "&.checked": {
            background: COLORS.WHITE,
            color: COLORS.PRIMARY_BTN_TEXT,
        }
    },
    listWrapper:{
        position:"relative",
        flexDirection:'column',
        padding: '0 !important'
    },
    loaderWrapper:{
        height: '13rem',
        width:'100%',
        position: 'relative'
    },
    customLabel:{
        display:'flex',
        flex:1,
        margin: 0,
        "&> span:nth-child(2)":{
            flex:1
        }
    },
    divider:{
        color:COLORS.SELECTED_NAV_LIST_ITEM_BTN,
        marginTop:`${SIZING.scale300} !important`,
        width:"94%"
    },
    downIcon :{
        "& path": {
            fill: COLORS.GREY
        }
    },
    providerNotFound: {
        display:"flex",
        height:SIZING.scale5000,
        width:"100%", 
        justifyContent:"center",
        flexDirection:"column",
        alignItems:"center"
    },
    searchIcon :{
        height:SIZING.scale3600,
        width:SIZING.scale3600,
        "& path": {
            fill: COLORS.WAITING_ROOM_CALL_TEXT
        },

    },
    providerNotFoundText: {
        fontSize: SIZING.scale600,
        color: COLORS.WAITING_ROOM_CALL_TEXT
    }
}))