import { makeStyles } from "@mui/styles";
import { SIZING, COLORS, TYPOGRAPHY } from "theme";
import { DropzoneLayout, FileDropzoneInput} from "common/components/files/class-names";

export const useStyles = makeStyles(() => ({
        tabContainer: {
            marginTop: SIZING.scale700,
        },
        header: {
            paddingBottom: SIZING.scale300,
            "& .title": {
                marginBottom: SIZING.scale125,
            },
        },
        content: {
            padding: 0,
            height: SIZING.scale15000,
            width: "860px",
            display: "flex",
            "& .panelHeader": {
                padding: `calc((${SIZING.scale950} - ${TYPOGRAPHY.modalSectionHeader.lineHeight}) / 2) 0`,
                color: COLORS.E_CONSULT_TEXT,
                cursor: "default",
                "& svg": {
                    width: "26px",
                    verticalAlign: "middle",
                    marginTop: "-3px",
                    marginRight: "3px",
                },
                "& g, & path": {
                    fill: COLORS.CREATE_E_CONSULT_REQUEST_ICON,
                },
            },
            "& > div": {
                flexGrow: 1
            },
            "& .hideTab": {
                display: "none"
            },
            [`& .${FileDropzoneInput}`]: {
                padding: SIZING.scale400,
            },
            [`& .${DropzoneLayout}`]: {
                borderBottomLeftRadius: SIZING.scale500,
                borderBottomRightRadius: SIZING.scale500,
            }
        },
}));
