import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import Controls from "admin/components/Controls/Controls";
import { PhoneMask } from "admin/components/Controls/InputMasked";
import { useStyles } from './styles';
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Shield } from "icon-library/SVG/Shield.svg";
import { createSvgIcon } from "../../../../utils";
import { useEffect } from "react";
import { useState } from "react";
import UserLevelDetailsMenu from "admin/components/UserLevelDetailsMenu/UserLevelDetailsMenu";
const ShieldIcon = createSvgIcon(Shield);
const AddOrgAdmin = (props: any) => {
    const { handleOrgAdminInputChange, orgAdminFormData, errorsAdminInfo, isInputDisabled, handleInputBlur, haveOrgParent } = props;
    const classes = useStyles();
    const [adminUserLevels, setAdminUserlevels] = useState([] as any)
    const { admin, auth } = useSelector((state: AppReduxStore) => state)
    const intl = useIntl()
    useEffect(() => {
        const allAdminUsersLevels = admin.allUserLevels && admin.allUserLevels.filter((u: any) => u.type !== "PATIENT" && u.type !== "PROVIDER" && u.type !== "SUPPORT_CONTACT").map((ul: any) => {
            return { ...ul, title: ul.description }
        });
        let usersLevels = admin.userLevels && admin.userLevels.filter((u: any) => u.type !== "PATIENT" && u.type !== "PROVIDER").map((ul: any) => {
            return { ...ul, title: ul.description }
        });
        if (auth.currentOrg?.id) {
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.type === "ADMIN")
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.name?.indexOf('ALTAIS_ADMIN') === -1)
            const hasOrgAdmin1 = usersLevels && usersLevels.find((u: any) => u.name === "ORG_ADMIN_1");
            const hasOrgAdmin2 = usersLevels && usersLevels.find((u: any) => u.name === "ORG_ADMIN_2");
            if (auth.currentProfileRoleJoinInfo?.level?.name === "ORG_ADMIN_1") {
                if (!hasOrgAdmin1) {
                    usersLevels.push(allAdminUsersLevels && allAdminUsersLevels.find((u: any) => u.name === "ORG_ADMIN_1"))
                }
                if (!hasOrgAdmin2) {
                    usersLevels.push(allAdminUsersLevels && allAdminUsersLevels.find((u: any) => u.name === "ORG_ADMIN_2"))
                }

            }
            else if (auth.currentProfileRoleJoinInfo?.level?.name === "ORG_ADMIN_2") {
                if (!hasOrgAdmin2) {
                    usersLevels.push(allAdminUsersLevels && allAdminUsersLevels.find((u: any) => u.name === "ORG_ADMIN_2"))
                }
            } else {
                if (!hasOrgAdmin1) {
                    usersLevels.push(allAdminUsersLevels && allAdminUsersLevels.find((u: any) => u.name === "ORG_ADMIN_1"))
                }
                if (!hasOrgAdmin2) {
                    usersLevels.push(allAdminUsersLevels && allAdminUsersLevels.find((u: any) => u.name === "ORG_ADMIN_2"))
                }

            }
            setAdminUserlevels(usersLevels)
        }

    }, [auth.currentOrg, admin.userLevels, admin.allUserLevels, auth.currentProfileRoleJoinInfo?.level?.name]);



    if (orgAdminFormData && !orgAdminFormData.adminLevelId) {
        const orgAdmin1 = adminUserLevels.find((u: any) => u.name === "ORG_ADMIN_1")
        const orgAdmin2 = adminUserLevels.find((u: any) => u.name === "ORG_ADMIN_2")
        if (orgAdmin1) {
            orgAdminFormData.adminLevelId = adminUserLevels && adminUserLevels.find((u: any) => u.name === "ORG_ADMIN_1")?.id
        }
        else if (orgAdmin2) {
            orgAdminFormData.adminLevelId = adminUserLevels && adminUserLevels.find((u: any) => u.name === "ORG_ADMIN_2")?.id
        }
    }
    if (isInputDisabled) {
        orgAdminFormData.primaryPhone = ""
        orgAdminFormData.mobilePhone = ""
        orgAdminFormData.prefix = ""
        orgAdminFormData.firstName = ""
        orgAdminFormData.middleName = ""
        orgAdminFormData.lastName = ""
    }
    const handleMakeMeAdministrator = (e: any) => {
        e && e.preventDefault();
        handleOrgAdminInputChange({
            target: {
                name: 'email',
                value: auth.currentProfileRoleJoinInfo?.profileEmail
            }
        })
        handleInputBlur({
            target: {
                name: 'email',
                value: auth.currentProfileRoleJoinInfo?.profileEmail
            }
        })
    }
    const handleClearInfo = (e: any) => {
        orgAdminFormData.email = ""
        orgAdminFormData.primaryPhone = ""
        orgAdminFormData.mobilePhone = ""
        orgAdminFormData.prefix = ""
        orgAdminFormData.firstName = ""
        orgAdminFormData.middleName = ""
        orgAdminFormData.lastName = ""
        handleInputBlur({
            target: {
                name: 'email',
                value: ""
            }
        })
    }
    return (
        <>
            <Grid item className={classes.pageHeader}>
                <Typography className={classes.pageHeaderText}>
                    <ShieldIcon fill="#0F7BBD" className={classes.administratorIcon} />
                    <FormattedMessage id={haveOrgParent ? "AddSubOrgAdminForm.Heading.Text" : "AddOrgAdminForm.Heading.Text"} />
                </Typography>
                <Typography className={classes.pageSubHeaderText}>
                    <FormattedMessage id={haveOrgParent ? "AddSubOrgAdminForm.SubHeading.Text" : "AddOrgAdminForm.SubHeading.Text"} />
                </Typography>
            </Grid>

            <Grid item className={classes.sectionHeader2}>
                <Typography className={classes.sectionHeaderText}>
                    Contact
                    {haveOrgParent && <><Button id="btnMakeMeAdministrator" variant="outlined" className={classes.btnMakemeAdministrator}
                        onClick={handleMakeMeAdministrator}>
                        <FormattedMessage id="AddOrgAdminForm.Button.MakeMeAdministrator.Text" />
                    </Button>
                        {isInputDisabled && <Button id="btnClearFields" variant="outlined" className={classes.btnMakemeAdministrator}
                            onClick={handleClearInfo}>
                            <FormattedMessage id="AddOrgAdminForm.Button.Clear.Text" />
                        </Button>}</>
                    }
                </Typography>
            </Grid>
            <Grid>

            </Grid>
            <Grid
                container
                direction="row"
                item
                className={classes.sectionFieldRow}>
                <Grid item xs={3} className={classes.marginRight10}>
                    <div>
                        <Controls.Input
                            name="email"
                            label="Email *"
                            value={orgAdminFormData.email}
                            onChange={handleOrgAdminInputChange}
                            onBlur={handleInputBlur}
                            error={errorsAdminInfo.email}
                            maxLength={255}
                            placeholder="Enter Here"
                        />
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.marginRight10}>
                    <Controls.Input
                        name="primaryPhone"
                        label="Primary Phone"
                        onChange={handleOrgAdminInputChange}
                        placeholder="(###) ### - ####"
                        value={orgAdminFormData.primaryPhone}
                        error={errorsAdminInfo.primaryPhone}
                        inputComponent={PhoneMask}
                        disabled={isInputDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controls.Input
                        name="mobilePhone"
                        label="Mobile Phone *"
                        onChange={handleOrgAdminInputChange}
                        placeholder="(###) ### - ####"
                        value={orgAdminFormData.mobilePhone}
                        error={errorsAdminInfo.mobilePhone}
                        inputComponent={PhoneMask}
                        disabled={isInputDisabled}
                    />
                </Grid>
            </Grid>
            {
                isInputDisabled ?
                    <Grid
                        container
                        direction="row"
                        item
                        className={classes.userExistsMsg}>
                        {intl.formatMessage({ id: "AddOrgAdminForm.UserAlreadyExist.Text" })}
                    </Grid> : " "
            }
            <Grid container direction="column">
                <Grid item className={classes.sectionHeader}>
                    <Typography className={classes.sectionHeaderText}>
                        Personal
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    item
                    justifyContent="flex-start"
                    className={classes.sectionFieldRow}
                >
                    <Grid item xs={3} className={classes.marginRight10}>
                        <Controls.Select
                            name="prefix"
                            label="Prefix"
                            options={admin.prefixes}
                            placeholder="Enter Here"
                            onChange={handleOrgAdminInputChange}
                            value={orgAdminFormData.prefix}
                            disabled={isInputDisabled}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    item
                    justifyContent="flex-start"
                    className={classes.sectionFieldRow}
                >
                    <Grid item xs={3} className={classes.marginRight10}>
                        <Controls.Input
                            name="firstName"
                            label="First Name *"
                            onChange={handleOrgAdminInputChange}
                            placeholder="Enter Here"
                            value={orgAdminFormData.firstName}
                            error={errorsAdminInfo.firstName}
                            disabled={isInputDisabled}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.marginRight10}>
                        <Controls.Input
                            name="middleName"
                            label="Middle Name"
                            onChange={handleOrgAdminInputChange}
                            placeholder="Enter Here"
                            value={orgAdminFormData.middleName}
                            disabled={isInputDisabled}

                        />
                    </Grid>
                    <Grid item xs={3} className={classes.marginRight10}>
                        <Controls.Input
                            name="lastName"
                            label="Last Name *"
                            onChange={handleOrgAdminInputChange}
                            placeholder="Enter Here"
                            value={orgAdminFormData.lastName}
                            error={errorsAdminInfo.lastName}
                            disabled={isInputDisabled}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            Professional
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        item
                        justifyContent="flex-start"
                        className={classes.sectionFieldRow}
                    >
                        <Grid item xs={3} className={classes.marginRight10}>
                            <Controls.Select
                                name="adminLevelId"
                                label="User Level *"
                                value={orgAdminFormData.adminLevelId}
                                onChange={handleOrgAdminInputChange}
                                options={adminUserLevels}
                                error={errorsAdminInfo.adminLevelId}
                                altContent={adminUserLevels ? <UserLevelDetailsMenu userLevels={adminUserLevels} /> : null}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AddOrgAdmin;