import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
        meetDuration: {
            color: COLORS.WHITE,
            textAlign: "center",
            position: 'absolute',
            top: '4rem',
            left: '1.2rem',
            padding: `0 ${SIZING.scale300}`,
            zIndex: 6,
        },
}));