import React from "react"
import { useStyles } from "./styles"
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { AppReduxStore } from "png/store/reducerTypes"

export default function PNGMessages() {

    const classes = useStyles()
    const { isInvalidInvite, isCallEnded,pngDetails,isMeetingTimeout } = useSelector((state: AppReduxStore) => state.pngMobile)
     
    const renderMessage = () => {

        if (isInvalidInvite) {
            return (
                <div className={classes.linkExpired}>
                    <Typography >
                        <FormattedMessage id="PatientDetails.label.invalid" />
                    </Typography>
                    <Typography >
                        <FormattedMessage id="PatientDetails.label.contactDoctor" />
                    </Typography>
                </div>
            )
        }
        if (isMeetingTimeout) {
            return (
                <div className={classes.linkExpired}>
                    <Typography >
                        <FormattedMessage id="PatientDetails.label.noResponse" />
                    </Typography>
                    <Typography >
                        <FormattedMessage id="PatientDetails.label.tryRejoin" />
                    </Typography>
                </div>
            )
        }

        if(isCallEnded){
            return (
                <div className={classes.mainContentWrapper}>
                    <Typography >
                        <FormattedMessage id="PatientDetails.label.callEnded" values={{ name:pngDetails?.providerProfileInfo?.lastName  }}/>
                    </Typography>
                </div>
            )
        }

        return (
            <div className={classes.mainContentWrapper}>
                <Typography >
                    <FormattedMessage id="PatientDetails.label.LeaveMeeting" />
                </Typography>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.bg} />
            <div className={classes.bg2} />
            <div className={classes.contentWrapper}>
                {renderMessage()}
                <Typography className="close_browser">
                    <FormattedMessage id="PatientDetails.label.closeWindow" />
                </Typography>
            </div>
        </div>
    )
}
