import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import {
  CardContent, Card, Button, Typography, Box
} from "@mui/material";
import { useStyles } from './styles';
import { ProviderRegistrationProps } from './types';
import { FormattedMessage } from "react-intl";

const EditRegistrationPreview = ({ subject, body, closing, greeting, logoUrl, signature }: ProviderRegistrationProps) => {
  const classes = useStyles();
  const { auth } = useSelector((state: AppReduxStore) => state)

  return (
    <Grid container className={classes.flexInline} style={{ paddingLeft: "25px", backgroundColor: "#F6F8FA" }}>
      <Typography variant={"h4"}> <FormattedMessage id={"RegistrationEdit.Preview.Heading"} /></Typography>
      <CardContent>
        <div className={classes.labelCtrl} style={{ paddingTop: "8px" }}></div>
        <Typography component='div' style={{ wordWrap: "break-word" }}>
          <Box sx={{ fontWeight: 'bold' }} display='inline'>
            <FormattedMessage id={"RegistrationEdit.Preview.Subject"} /></Box>
          {subject ? subject : <FormattedMessage id={"RegistrationEdit.Preview.Subject.Default"} />}
        </Typography>
      </CardContent>

      <Card>
        <CardContent>
          {logoUrl ?
            <Grid container className={classes.logoContent}>
              <img src={logoUrl} alt="Logo" style={{ maxWidth: "180px", maxHeight: "150px" }} crossOrigin="anonymous" />
            </Grid> :
            <div className={classes.logo}>
              <FormattedMessage id="RegistrationEdit.Preview.Logo.Default" />
            </div>
          }
          <Typography className={classes.signText}>
            {greeting ? greeting : <FormattedMessage id={"RegistrationEdit.Preview.Greetings.Default"} />} {'<FirstName>,'}
          </Typography>

          <Typography className={classes.body1}>
            {body ? body : '<Body>'}
          </Typography>
          <Typography className={classes.begin}>
            <Grid container className={classes.flexInline}>
              <Grid item xs={4}><FormattedMessage id={"RegistrationEdit.Preview.LinkButton.Text"} /></Grid>
              <Grid item xs={6} md={8}><Button variant="contained" color="primary">
                <FormattedMessage id={"RegistrationEdit.Preview.Button.Text"} />
              </Button>
              </Grid>
            </Grid>
          </Typography>
          <Typography className={classes.rightText}>
            <Typography variant={"h4"} className={classes.signText}>{closing ? `${closing},` : ""}
            </Typography>
            <Typography variant={"h4"} className={classes.signText} style={{ textAlign: "right" }}>
              {(signature === "$practiceName") ? auth.currentPractice?.name : signature}
            </Typography>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
export default EditRegistrationPreview;