import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
    emptyIcon: {
        "& svg": {
            margin: "auto",
        },
    },
    emptyMessage: {
        pointerEvents: "none",
        "& p": {
            maxWidth: SIZING.scale10000,
            color: COLORS.E_CONSULT_TEXT,
            textAlign: "center",
        },
        "& p + p": {
            marginTop: SIZING.scale400,
        },
        "&.emptyMessage--fluid p": {
            maxWidth: "unset",
        },
        "&.variant-content": {
            maxWidth: "85%",
            textAlign: "center",
            "& svg": {
                width: "12rem",
                height: "12rem",
                opacity: 0.2,
            },
            "& p": {
                color: COLORS.EMPTY_LIST_TEXT,
            },
        },
    },
}));
