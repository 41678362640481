import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Input, InputAdornment, Typography, ButtonBase } from "@mui/material";
import { ReactComponent as SearchIcon } from "icon-library/SVG/Search.svg";
import { DropdownOptionSet } from "common/components/dropdown/types";
import Dropdown from "common/components/dropdown/dropdown";
import HighlightedText from "common/components/highlighted-text/highlighted-text";
import PopoverContent from "common/components/PopoverContent/PopoverContent";
import SVGIcon from "common/components/svg-icon/svg-icon";
import * as CSS from "../class-names";
import { useStyles } from "../styles";
import { Filter, FilterTypeSet, GetFilterTypeTranslation, FilterAutocompleteProps } from "../types";

type FilterInputProps = {
    /** The filter type that will be selected by default. If not set, first filterType will be used  */
    defaultFilterType?: string;
    error?: boolean;
    /** Filter type array, could be set asynchronously */
    filterTypes?: FilterTypeSet;
    getFilterTypeTranslation: GetFilterTypeTranslation;
    /** Prefix for internal IDs */
    idPrefix: string;
    loadingFilterTypes?: boolean;
    /** callback for when a new filter is created, meaning it should be added to the list of filters */
    onFilterCreated: (filter: Filter) => void;
    placeholder?: string;
} & FilterAutocompleteProps;

const createFilter = (type: string = ""): Filter => {
    return {
        type,
        text: "",
        id: Date.now().toString(),
    };
};

const mapFilterTypes = (
    filterTypes: string[],
    selectedFilterType: string,
    getFilterTypeTranslation: GetFilterTypeTranslation
) =>
    filterTypes.map((type) => {
        return {
            id: type,
            text: getFilterTypeTranslation(type),
            selected: type === selectedFilterType,
        };
    });

const FilterInput = ({
    autocompleteValues,
    defaultFilterType,
    error,
    filterTypes = [],
    getFilterTypeTranslation,
    idPrefix,
    loadingAutocomplete,
    loadingFilterTypes,
    onFilterChanged = () => {},
    onFilterCreated,
    placeholder,
    minSearchLength = 2
}: FilterInputProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState(createFilter(defaultFilterType || filterTypes[0]));
    const [filterTypeOptions, setFilterTypeOptions] = useState<DropdownOptionSet>(
        mapFilterTypes(filterTypes, filter.type, getFilterTypeTranslation)
    );

    const inputEl = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setFilterTypeOptions(mapFilterTypes(filterTypes, filter.type, getFilterTypeTranslation));
    }, [filterTypes, filter, getFilterTypeTranslation]);

    // set autocomplete popup open whenever it starts loading or we get some content
    useEffect(() => {
        if ((loadingAutocomplete || autocompleteValues) && filter.text.length > minSearchLength) {
            setOpen(true);
        }
    }, [autocompleteValues, loadingAutocomplete, filter, minSearchLength]);

    useEffect(() => {
        if (filter.text.length <= minSearchLength) {
            setOpen(false);
        }
    }, [filter, minSearchLength]);

    const saveAndCreateNewFilter = (id: string, text: string): void => {
        if (text.trim().length > 0) {
            onFilterCreated({
                ...filter,
                id,
                text,
            });
            const newFilter = createFilter(filter.type);
            onFilterChanged(newFilter);
            setFilter(newFilter);
            inputEl.current!.value = newFilter.text;
            setOpen(false);
        }
    };

    const handleAutocompleteClose = () => {
        setOpen(false);
    };

    const handleFilterTypeChange = (selectedId: string): void => {
        const newFilter = {
            ...filter,
            type: selectedId,
        };
        setFilterTypeOptions(
            filterTypeOptions.map((filterItem) => ({
                ...filterItem,
                selected: newFilter.type === filterItem.id,
            }))
        );
        setFilter(newFilter);
        onFilterChanged(newFilter);
    };

    const handleFilterValueChange = (event: any): void => {
        const newFilter = {
            ...filter,
            text: event.target.value,
        };
        setFilter(newFilter);
        onFilterChanged(newFilter);
    };

    const handleFilterValueSelected = (selectedId: string): void => {
        const selectedFilter = autocompleteValues?.find((item) => item === selectedId);
        if (selectedFilter) {
            saveAndCreateNewFilter(selectedFilter, selectedFilter);
        }
    };

    const handleFilterSubmit = () => {
        // TODO: refactor saveAndCreateNewFilter, we don't need id as before
        saveAndCreateNewFilter(filter.text, filter.text);
    }

    const handleEnterKey: React.EventHandler<React.KeyboardEvent> = (e) => {
        const code = e.keyCode ? e.keyCode : e.which;
        if (code === 13) {
            e.preventDefault();
            handleFilterSubmit();
        }
    };

    return (
        <div className="filterInputContainer">
            <Input
                id={`${idPrefix}_textfield`}
                autoComplete="off"
                className={classes.FilteredSearchInput__FilterInput}
                defaultValue={filter.text}
                onKeyUp={handleFilterValueChange}
                onKeyPress={handleEnterKey}
                inputRef={inputEl}
                placeholder={placeholder}
                error={error}
                startAdornment={
                    <InputAdornment position="start">
                        <Dropdown
                            className={classes.filterTypeDropdown}
                            idPrefix="QA_filter_type_selector"
                            onChange={handleFilterTypeChange}
                            options={filterTypeOptions}
                            titleId="FilteredSearchInput.filterTypeSelector.title"
                            loadingOptions={loadingFilterTypes}
                        />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <ButtonBase
                            disableRipple
                            disableTouchRipple
                            className={CSS.FilteredSearchInput__FilterInput__SearchButton}
                            onClick={handleFilterSubmit}
                        >
                            <SVGIcon svg={SearchIcon} size="scale400" color="WHITE" />
                        </ButtonBase>
                    </InputAdornment>
                }
                disableUnderline={true}
            />
            {open && (
                <PopoverContent
                    idPrefix={`${idPrefix}_popover`}
                    onChange={handleFilterValueSelected}
                    onClose={handleAutocompleteClose}
                    className={classes.filterPopover}
                    title={filterTypeOptions.find((fType) => fType.id === filter.type)?.text ?? filter.type}
                    emptyMessage={
                        <p className="emptyContent">
                            {intl.formatMessage({ id: "FilteredSearchInput.filter.notFound" })}
                        </p>
                    }
                    list={autocompleteValues?.map((item) => ({
                        id: item,
                        text: item,
                        content: (
                            <Typography className="name label" variant="body1" noWrap>
                                <HighlightedText text={item} textToHighligh={filter.text} />
                            </Typography>
                        ),
                    }))}
                    loader={loadingAutocomplete}
                />
            )}
        </div>
    );
};

export default FilterInput;
