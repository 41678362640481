import React, { useEffect, useState } from "react";
import { ConversationInfoItem } from "store/chat-types";
import { Message, Participant } from "@twilio/conversations";
import { searchMessageView } from "components/messaging/message-view-registry";
import { useStyles } from "./styles";
import classNames from "classnames";
// import MessageDate from "common/components/MessageDate/MessageDate";
import ParticipantName from "common/components/ParticipantName/PN";
import { FormattedMessage, FormattedTime } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { MODAL_TYPES } from "constant";
import { setActiveModal } from "store/actions";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as Ellipsis } from "icon-library/SVG/Ellipsis-Vertical.svg"
import { ReactComponent as Eye } from "icon-library/SVG/Eye.svg"
import { ReactComponent as Eye_Disabled } from "icon-library/SVG/Eye_Disabled.svg"
import CustomPopover from "components/Popover/Popover"

type ChatMessageProps = {
    conversationInfo: ConversationInfoItem | undefined;
    message: Message;
    read: boolean;
    ownMessage: boolean;
};

// ChatMessage renders an individual message with the authors name, the content of the message and when it was sent
const ChatMessage = ({ conversationInfo, message, read, ownMessage }: ChatMessageProps) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [messageReadStatus, updatemessageReadStatus] = useState({} as any);
    const { openConversationSid, conversationsInfo } = useSelector((state: AppReduxStore) => state.chat)
    const { participants = [] } = conversationsInfo[openConversationSid]!
    const participant = conversationInfo?.participants.find((p) => p.identity === message.author);

    const getStatus = (recipients: Participant[], message: Message) => {
        const readarr: any = [];
        const unread: any = [];
        const statusObj: any = {};
        recipients.forEach(function (recipient: Participant) {
            const participantDetails = participants.find((zitem) => zitem.identity === recipient.identity);
            const readStatusDetails = {
                // FIXME Typo + type should be created
                recepientsDetails: participantDetails,
                lastReadTime: recipient.lastReadTimestamp,
                lastReadIndex: recipient.lastReadMessageIndex,
            }
            if (recipient.lastReadMessageIndex != null && recipient.lastReadMessageIndex! >= message.index) {
                readarr.push(readStatusDetails)
            } else {
                unread.push(readStatusDetails)
            }
        })
        statusObj.read = readarr
        statusObj.unRead = unread
        statusObj.participants = participants
        updatemessageReadStatus(statusObj)
    }

    const handleMessageInfo = async (message: Message) => {
        const chatParticipants = await message.conversation?.getParticipants();
        const recipients = chatParticipants.filter((item) => item.identity !== participant?.identity);
        getStatus(recipients, message);
    };

    useEffect(() => {
        handleMessageInfo(message)
    }, [message])

    if (!conversationInfo) {
        return null;
    }

    const View = searchMessageView(message, conversationInfo);


    const handleReadModal = (messageDetails: any) => {
        dispatch(setActiveModal(MODAL_TYPES.PROVIDER.MESSAGE_READ_STATUS, { messageDetails }));
    }

    const handleMarkUnread = async (message: any) => {
        const { conversation } = message;
        await conversation.updateLastReadMessageIndex(message.index - 1);
    }

    const button = (handleClick: any) => {
        return <SVGIcon svg={Ellipsis} size="scale400" color="LGREY1" className={classes.ellipsisIcon} onClick={() => handleClick(anchorRef.current)} />
    }

    const content = (closePopup: () => void) => {
        return (<div className={classes.readPopup}>
            {
                ownMessage ?
                    <div className={classes.statusInfo} onClick={() => { closePopup(); handleReadModal(messageReadStatus) }}>
                        <SVGIcon svg={Eye} size="scale800" className={classes.eyeIcon} />
                        <FormattedMessage id="Message_Read_Status" />
                    </div> : <div className={classes.statusInfo} onClick={() => { closePopup(); handleMarkUnread(message) }}>
                        <SVGIcon svg={Eye_Disabled} size="scale800" color="GREY" className={classes.eyeIcon} />
                        <FormattedMessage id="Message_Mark_UnRead_Status" />
                    </div>
            }

        </div>)
    }

    return (
        <>
            <div
                className={classNames(classes.message, "Message", {
                    "Message--own": ownMessage,
                    "Message--read": read,
                })}
                id="QA_conversation_dashboard"
            >
                <ParticipantName
                    className="Message__name"
                    participant={participant}
                    displayFullInfo={false}
                    hyperlink={false}
                />
                <div className="Message__addition__info">
                    <FormattedTime value={message.dateCreated?.toString()} />
                    {/* <MessageDate className="Message__date" date={message.dateCreated} /> */}
                    <div ref={anchorRef} className={classes.root} >
                        <CustomPopover
                            renderElement={button}
                            renderContent={content}
                            arrowPosition='subright'
                            anchorOrigin={{
                                vertical: 35,
                                horizontal: -90,
                            }}
                            arrowDirection='top'
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                        />
                    </div>
                </div>

                <div className="Message__body" id="QA_messageBody">
                    <View conversationInfo={conversationInfo} message={message} />
                </div>
            </div>
        </>
    );
};

export default ChatMessage;
