import { FC } from "react";
import classNames from "classnames";
import * as CSS from "./class-names";
import { TestProps } from "../types";

export type PageMainBodyProps = {
    className?: string;
} & TestProps;
export const PageMainBody: FC<PageMainBodyProps> = ({ className, children, id, testId }) => {
    return (
        <div id={id} data-testid={testId || id} className={classNames(className, CSS.PageMainBody)}>
            {children}
        </div>
    );
};
