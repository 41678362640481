import { Theme } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
        card: {
            width: '210px',
            height: '300px',
            left: '0px',
            top: '0px',
            background: '#F6F8FA',
            boxShadow: '0.554187px 0px 4.4335px rgba(0, 0, 0, 0.05), inset -0.554187px 0px 0px rgba(0, 0, 0, 0.05)'
        },
        cardContent: {
            padding: '0px 10px 10px 0px'
        },
        paddingBottomDiv: {
            paddingBottom: '10px'
        },
        heading: {
            width: '207.82px',
            height: '48.77px',
            background: '#F6F6F7',
            boxShadow: '0px 0.277094px 0px rgba(0, 0, 0, 0.3)',
            textAlign: "center",
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '48.77px'
        },
        content: {
            textAlign: "left",
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '10px',
            paddingLeft: '10px',
            left: '0px',
            top: '48.77px'
        },
        content2: {
            fontSize: '10px',
            fontWeight: 'normal',
            paddingBottom: '10px'
        },
        text10: {
            fontSize: '10px',
            fontWeight: 'normal'
        },
        text12: {
            fontSize: '12px'
        },
        linkTypo: {
            color: '#0F7BBD',
            textAlign: 'right',
            fontSize: '10px',
            height: '48.77px',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '48.77px',
            cursor: 'none'
        },
        waitingroomforms: {
            height: '48.77px',
            background: '#FFFFFF',
            boxShadow: '0px 0.277094px 0px rgba(0, 0, 0, 0.3)',
            margin: '5px 0px'
        },
        waitingroomformsleftsection: {
            margin: '5px'
        }
}));