import {
    Box, Button, Grid, IconButton, InputBase, Paper,
    Table, TableBody, TableCell, TableContainer,
    TableFooter, TableHead,
    TablePagination, TableRow, TableSortLabel, Typography
} from "@mui/material";
import { ReactComponent as EditPencil } from "admin/assets/common/EditPencil.svg";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import TablePaginationActions from "admin/components/TablePaginationActions/TablePaginationActions";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal, setNavBreadcrumbText } from "admin/store/actions";
import { fetchLocations, setSelectedLocationId } from "admin/store/actions/location";
import { AppReduxStore } from "admin/store/reducerTypes";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

interface Data {
    id: string
    name: string
    ehrLocationId: string
    phone: string
    fax: string
    email: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    practiceId: string
    action: string
}

const LocationListing = () => {
    const intl = useIntl()
    const { auth, location } = useSelector((state: AppReduxStore) => state)
    const locations = location && location.locations ? location.locations : []
    const classes = useStyles();
    const dispatch = useDispatch()
    useEffect(() => {
        if (auth.currentOrg || auth.currentPractice) {
            dispatch(fetchLocations())
        }

    }, [auth.currentOrg, auth.currentPractice, dispatch, intl]);
    const [orderBy, setOrderBy] = useState<keyof Data>('name');
    const [order, setOrder] = useState<Order>('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const handleAddLocation = () => {
        dispatch(setActiveModal(MODAL_TYPES.ADD_LOCATION))
    };

    const handleLocationClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, locationId: any, locationName: any) => {
        dispatch(setSelectedLocationId(locationId))
        dispatch(setNavBreadcrumbText(locationName))
    }
    const handleSearch = (event: any) => {
        const searchStr = event.target.value.toLowerCase();
        dispatch(fetchLocations(null, searchStr))
    };
    const handleRowAction = (locationId: any) => {
        dispatch(setSelectedLocationId(locationId))
        dispatch(setActiveModal(MODAL_TYPES.EDIT_LOCATION))

    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        const fa = typeof a[orderBy] === "string" ? (a[orderBy] as any).toLowerCase() : a[orderBy],
            fb = typeof b[orderBy] === "string" ? (b[orderBy] as any).toLowerCase() : b[orderBy];
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    }

    type Order = 'asc' | 'desc';

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const Pagination = ({ data }: any) => {
        return (
            <>
                {locations && locations.length > 0 && <TablePagination className={classes.tableFooterRow}
                    rowsPerPageOptions={[25, 50, 100]}
                    classes={{ selectIcon: classes.footerIcon, select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
                    count={locations.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Per Page"
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions} />
                }
            </>)
    }
    return (
        <Box>
            <Grid container>
                <Grid item xs>
                </Grid>
                <Grid item xs>
                    <Paper component="form" className={classes.root}>
                        <IconButton id="btnSearchLocations" className={classes.iconButton} aria-label="search" size="large">
                            <SearchIcon />
                        </IconButton>
                        <InputBase type="search" id="txtSearchLocations"
                            className={classes.input} onChange={handleSearch}
                            onKeyPress={(ev) => { if (ev.key === 'Enter') { ev.preventDefault() } }}
                            placeholder="Search to filter the table below"
                            inputProps={{ 'aria-label': 'Search to filter the table below' }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Button id="btnAddLocation" variant="contained" color="primary" style={{ float: 'right' }} onClick={handleAddLocation}>
                        Add Location
                    </Button>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <TableContainer className={classes.stickyHeader}>
                    <Table id="tableLocations"
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ paddingLeft: '48px' }}
                                    key={"name"}
                                    align={'left'}
                                    padding={'none'}
                                    sortDirection={orderBy === "name" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "name"}
                                        direction={orderBy === "name" ? order : 'asc'}
                                        onClick={createSortHandler("name")}>
                                        {'Location Name'}
                                        {orderBy === "name" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"ehrLocationId"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "ehrLocationId" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "ehrLocationId"}
                                        direction={orderBy === "ehrLocationId" ? order : 'asc'}
                                        onClick={createSortHandler("ehrLocationId")}>
                                        <strong>
                                            {'Location ID'}
                                        </strong>
                                        {orderBy === "ehrLocationId" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"address1"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "address1" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "address1"}
                                        direction={orderBy === "address1" ? order : 'asc'}>
                                        <strong onClick={createSortHandler("address1")}>
                                            {'Address'}
                                        </strong>
                                        {orderBy === "address1" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"phone"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "phone" ? order : false}>
                                    <TableSortLabel
                                    // active={orderBy === "phone"}
                                    // direction={orderBy === "phone" ? order : 'asc'}
                                    // onClick={createSortHandler("phone")}
                                    >
                                        {'Phone Number'}
                                        {/* {orderBy === "phone" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null} */}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(locations, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow hover key={index}>
                                            <TableCell align="left" style={{ paddingLeft: '48px' }}>
                                                <Link to="/admin/locations/details" onClick={(e) => handleLocationClick(e, row.id, row.name)}>
                                                    <Typography noWrap variant="body1" style={{ color: '#0F7BBD' }}>{row.name}</Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Box> {row.ehrLocationId}</Box>
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <Link to="/admin/locations/details" onClick={(e) => handleLocationClick(e, row.id, row.name)}>
                                                    <Typography noWrap variant="body1" style={{ color: '#393948', fontSize: '14px' }}> {row.address1}</Typography>
                                                    <Box display="flex" justifyContent="space-between">
                                                        <Typography noWrap variant="body1" style={{ color: '#393948', fontSize: '13px', fontWeight: 'normal' }}> {`${row.city}, ${row.state}`}</Typography>
                                                    </Box>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Box> {row.phone}</Box>
                                            </TableCell>
                                            <TableCell align="right" className={classes.stickyLastColumn}>
                                                <IconButton id="btnEditLocation" onClick={() => handleRowAction(row.id)} size="large">
                                                    <EditPencil />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                        </TableBody>
                        <TableFooter>
                            {
                                locations && locations.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                ) : <TableRow>
                                    <Pagination />
                                </TableRow>
                            }
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}
export default LocationListing