import { ApiStatusCode, IApiPromise } from "./types";

const UNINITIALIZED_MESSAGE = "ApiPromise is uninitialized";

class ApiPromise<TContent> implements IApiPromise<TContent> {
    private internalPromise: Promise<TContent> | undefined;
    private _fetchStatus = ApiStatusCode.NOT_SENT;
    private _onFetchStateChangeListener: Set<((fetchStatus: ApiStatusCode) => void)> = new Set();
    private _onAbortListener: (() => void) | undefined;

    updateFetchStatus(fetchStatus: ApiStatusCode) {
        this._fetchStatus = fetchStatus;
        this._onFetchStateChangeListener.forEach(stateChangeListener => {
            stateChangeListener(fetchStatus);
        });
    }
    initPromise(
        executorPromise: Promise<TContent>,
        onAbort?: () => void
    ) {
        this.internalPromise = executorPromise;
        this._onAbortListener = onAbort;
        this._fetchStatus = ApiStatusCode.SENT;
    }
    get aborted(): boolean {
        return this._fetchStatus === ApiStatusCode.ABORTED;
    }
    get fetchStatus(): ApiStatusCode {
        return this._fetchStatus;
    }
    onFetchStatusChange(listener: (fetchStatus: ApiStatusCode) => void): ApiStatusCode {
        this._onFetchStateChangeListener.add(listener);
        return this._fetchStatus;
    }
    abort(): void {
        if (this._fetchStatus !== ApiStatusCode.ABORTED) {
            this._fetchStatus = ApiStatusCode.ABORTED;
            this._onAbortListener?.();
        }
    }
    catch<TResult = never>(
        onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | null
    ): Promise<TContent | TResult> {
        if (!this.internalPromise) {
            throw new Error(UNINITIALIZED_MESSAGE);
        }
        return this.internalPromise
            .catch((reason) => {
                return reason;
            })
            .then(onrejected);
    }
    finally(onfinally?: (() => void) | null): Promise<TContent> {
        if (!this.internalPromise) {
            throw new Error(UNINITIALIZED_MESSAGE);
        }
        return this.internalPromise.finally(onfinally);
    }
    then<TResult1 = TContent, TResult2 = never>(
        onfulfilled?: ((value: TContent) => TResult1 | PromiseLike<TResult1>) | null,
        onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null
    ): Promise<TResult1 | TResult2> {
        if (!this.internalPromise) {
            throw new Error(UNINITIALIZED_MESSAGE);
        }
        return this.internalPromise
            .then((result) => {
                return result;
            })
            .then(onfulfilled)
            .catch(onrejected);
    }
    [Symbol.toStringTag]: string = "ApiPromise";
}

export default ApiPromise;
