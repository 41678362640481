import React from "react";
import { Box } from "@mui/material";

import {ItemCountProps} from "./types";
import {useStyles} from "./styles";
import {FormattedMessage, useIntl} from "react-intl";

// ItemCount displays a count badge that caps at 99 and can display a unit after the number
const ItemCount = ({bgColor, count, endLabel, className, onClick = null}:ItemCountProps) => {
    const classes = useStyles({bgColor})
    const intl = useIntl()
    // If the count is 0 hide the badge
    if (!count) {
        return null
    }

    const countText = count > 99 ? intl.formatMessage({id:"ItemCount.99+"}) : count
    const text = endLabel ? <FormattedMessage id="ItemCount.withEndLabel" values={{endLabel, countText}} /> : countText

    return (
        <Box onClick={onClick} className={`${classes.countWrapper} ${className}`}>
            <span>{text}</span>
        </Box>
    )
}

export default ItemCount