import { ApiCommunicationContext } from "../types";

const runningRequestsMap = new Map<string, ApiCommunicationContext<any, any, any>>();

const abortOnNewRequest = async <TRequest, TResponse, TContent>(
    ctx: ApiCommunicationContext<TRequest, TResponse, TContent>
): Promise<ApiCommunicationContext<TRequest, TResponse, TContent>> => {
    if (ctx.request.flags?.abortOnNewRequest) {
        if (!ctx.response) {
            const requestKey = ctx.storage.originalUrl ?? ctx.request.url;
            if (runningRequestsMap.has(requestKey)) {
                // Abort if exists and save new context
                const contextToAbort = runningRequestsMap.get(requestKey);
                contextToAbort!.abort?.();
            }
            runningRequestsMap.set(requestKey, ctx);
            ctx.storage.originalUrl = ctx.request.url;
        } else {
            // Cleanup
            runningRequestsMap.delete(ctx.storage.originalUrl ?? ctx.request.url);
        }
    }
    return ctx;
};

export default abortOnNewRequest;
