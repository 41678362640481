import { AttachmentChangeEvent } from "./compose-message-form/types";
import { FileUploadData } from "common/components/files/types";
import { useEffect, useState, useRef, useCallback } from "react";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import AttachmentSection, { AttachmentSectionRef } from "./compose-message-form/attachment-section";
import classNames from "classnames";
import MessageSection from "./compose-message-form/message-section";
import UploadingDialog from "common/components/files/uploading-dialog";
import { PromiseFunctions } from "types";

type ComposeMessageFormProps = {
    allowAttachments?: boolean;
    /** Returns with a feedback about submit success. */
    onSubmit: (message: string, attachmentIds: string[] | undefined) => Promise<void>;
    onTyping: () => void;
};

const ComposeMessageForm = ({ allowAttachments = false, onSubmit, onTyping }: ComposeMessageFormProps) => {
    const classes = useStyles();
    const intl = useIntl();
    const attachmentSessionRef = useRef<AttachmentSectionRef>();
    const [attachmentInfo, setAttachmentInfo] = useState<{
        status: FileUploadData["status"];
        attachmentIds: string[] | undefined;
    }>({ status: "UPLOADED", attachmentIds: undefined });
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [message, setMessage] = useState("");
    const [showUploadingDialog, setShowUploadingDialog] = useState(false);
    const [submitFeedbackFuncs, setSubmitFeedbackFuncs] = useState<PromiseFunctions | null>(null);

    // we keep the `onSubmit` on a ref and keep up up to date
    // so in case we are given a function that keeps changing it's reference
    // we are protected against triggering our effect multiple times.
    const onSubmitRef = useRef(onSubmit);
    onSubmitRef.current = onSubmit;

    const onSubmitForm = useCallback((newMessage: string) => {
        return new Promise<void>((resolve, reject) => {
            setMessage(newMessage);
            setSubmitFeedbackFuncs({ resolve, reject });
        });
    }, [setMessage, setSubmitFeedbackFuncs]);

    const onAttachmentChange = useCallback((attachments: AttachmentChangeEvent) => {
        setAttachmentInfo({
            status: attachments.cumulativeStatus,
            attachmentIds: attachments.attachmentIds,
        });
    }, [setAttachmentInfo]);

    useEffect(() => {
        if (submitFeedbackFuncs) {
            if (attachmentInfo.status === "UPLOADED") {
                onSubmitRef.current(message, attachmentInfo.attachmentIds)
                    .then(() => {
                        attachmentSessionRef.current?.closeWithoutConfirmation();
                        setSubmitFeedbackFuncs((currentSubmitFeedbackFuncs) => {
                            currentSubmitFeedbackFuncs?.resolve();
                            return null;
                        });
                    })
                    .catch(() => {
                        setSubmitFeedbackFuncs((currentSubmitFeedbackFuncs) => {
                            currentSubmitFeedbackFuncs?.reject();
                            return null;
                        });
                    })
                    .finally(() => {
                        setShowUploadingDialog(false);
                    });
            } else if (attachmentInfo.status === "ERROR") {
                setSubmitFeedbackFuncs((currentSubmitFeedbackFuncs) => {
                    currentSubmitFeedbackFuncs?.reject();
                    return null;
                });
                setShowUploadingDialog(false);
            } else {
                // Waiting for uploading all attachments
                setShowUploadingDialog(true);
            }
        }
    }, [attachmentInfo, message, submitFeedbackFuncs]);

    return (
        <div
            className={classNames(classes.composeMessage, "ComposeMessage", {
                "ComposeMessage--withAttachments": allowAttachments,
            })}
        >
            {allowAttachments && (
                <AttachmentSection
                    ref={attachmentSessionRef}
                    onAttachmentChange={onAttachmentChange}
                    onUploadPercentageChange={setUploadPercentage}
                />
            )}
            <MessageSection
                inputPlaceholder={intl.formatMessage({ id: "NewMessage.input.composeYourReply" })}
                onSubmit={onSubmitForm}
                onTyping={onTyping}
            />
            {allowAttachments && showUploadingDialog && <UploadingDialog percentage={uploadPercentage} />}
        </div>
    );
};

export default ComposeMessageForm;
