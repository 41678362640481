import { Typography } from "@mui/material";
import { AppReduxStore } from "admin/store/reducerTypes";
import Card from "components/Dashboard/Card/Card";
import { ReactComponent as FeeScheduleIcon } from "icon-library/SVG/Circles_3x3.svg";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";

const FeeSchedulesDashboard = () => {
    const { auth } = useSelector((state: AppReduxStore) => state);
    const orgName = auth?.currentProfileRoleJoinInfo?.organization?.name;
    const isPCRStatus = false;
    const classes = useStyles({ isPCRStatus });

    const cardConfig = {
        btnText: "Go To Fee Schedules",
        description: "Manage fee schedule documents and groups for your organization.",
        icon: FeeScheduleIcon,
        route: "/admin/feeschedules",
        themeColor: "#0F7BBD",
        title: "Fee Schedules",
        type: "nonPCR"
    }
    return (
        <div className={classes.root}>
            <div>
                <Typography style={{ fontFamily: 'OpenSans', fontStyle: 'normal', fontWeight: '600', fontSize: '32px', lineHeight: '140%', color: '#393948', marginBottom: '30px' }} >
                    <FormattedMessage id="billing.admin.homepage.welcome.label" values={{ name: orgName }} />
                </Typography>
            </div>
            <Card
                key={module}
                {...cardConfig}
                isPCRStatus={isPCRStatus}
            />
        </div >
    )
}

export default FeeSchedulesDashboard