import { HttpMethod } from "lib/api/types";
import { useApi } from "lib/api/api-service-hooks";
import { useDebounce } from "common/utils/use-debounce";
import { useEffect, useState } from "react";
import { ValidationResult } from "lib/validation/types";
import { ValueOf } from "lib/util-types";

type UseCareTeamNameValidatorObject = {
    clear: () => void;
    result: ValueOf<typeof ValidationResult>;
};

const isCareTeamNameFilled = (careTeamName: string) => careTeamName.trim().length > 0;

const useCareTeamNameValidator = (careTeamName: string): UseCareTeamNameValidatorObject => {
    const [validationResult, setValidationResult] = useState<ValueOf<typeof ValidationResult>>(ValidationResult.UNSET);
    const { content, fetchInfo, request } = useApi<{ teamName: string }, boolean>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: false,
        method: HttpMethod.GET,
        url: "/provider/v1/careteam/teams?name=:teamName",
    });

    const debouncedRequest = useDebounce(request, 500);

    useEffect(() => {
        if (isCareTeamNameFilled(careTeamName)) {
            debouncedRequest({ teamName: encodeURIComponent(careTeamName) });
        }
    }, [careTeamName, debouncedRequest]);

    useEffect(() => {
        let vResult: ValueOf<typeof ValidationResult> = ValidationResult.UNSET;
        // This can be removed if new ApiService will be used to fetch team name checks
        if (isCareTeamNameFilled(careTeamName)) {
            if (fetchInfo.hasResult) {
                vResult =
                    !content && isCareTeamNameFilled(careTeamName) ? ValidationResult.VALID : ValidationResult.INVALID;
            } else {
                vResult = ValidationResult.RUNNING;
            }
        }
        setValidationResult(vResult);
    }, [careTeamName, content, fetchInfo]);

    return {
        clear: () => {
            setValidationResult(ValidationResult.UNSET);
        },
        result: validationResult,
    };
};

export default useCareTeamNameValidator;
