import {
    Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import { AppReduxStore } from "admin/store/reducerTypes";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { map } from "underscore";
import { useStyles } from "./styles";

interface Data {
    id: string
    productId: string
    name: string
    description: string
    organizationId: string
    licenseCount: number
    usedLicenseCount: number
    availableLicenseCount: number,
}

const ProductsView = () => {
    const intl = useIntl()
    const { license } = useSelector((state: AppReduxStore) => state)
    const products = license?.sOrgLicenses
    const formattedOrgProducts = map(products, (p, key) => {
        p["productId"] = p.product?.id
        p["name"] = p.product?.name
        p["description"] = p.product?.description
        return p
    })
    const classes = useStyles();
    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch, intl]);

    return (
        <Box>
            {formattedOrgProducts && <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell key={"name"} align={'left'} padding="normal">
                                    {'Products'}
                                </TableCell>
                                <TableCell
                                    key={"availableLicenseCount"}
                                    align={'right'}
                                    padding="normal">
                                    {'Licenses Available'}
                                </TableCell>
                                <TableCell key={"usedLicenseCount"} align={'right'} padding={'none'}>
                                    {'Licenses in Use'}
                                </TableCell>

                                <TableCell
                                    key={"licenseCount"}
                                    align={'right'}
                                    padding="normal"
                                >
                                    {'Total Licenses'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formattedOrgProducts && formattedOrgProducts.map((row: Data, index: number) => {
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell>
                                            <Box> {row.name}</Box>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <Box> {(row.usedLicenseCount === null && row.licenseCount === null) ? "Unlimited" : (row.licenseCount - row.usedLicenseCount)}</Box>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <Box> {(row.usedLicenseCount === null && row.licenseCount === null) ? "Unlimited" : row.usedLicenseCount}</Box>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <Box> {(row.usedLicenseCount === null && row.licenseCount === null) ? "Unlimited" : row.licenseCount}</Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>}
        </Box>
    )
}
export default ProductsView