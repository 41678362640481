import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import { WaitingRoomTileProps } from "./types";
import { useDispatch } from "react-redux";
import { showNotification } from "admin/store/actions/snackbar";

const WaitingRoomTile = ({ name, practiceMessage, waitingMessage, waitingRoomForms }: WaitingRoomTileProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleFormNameClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: any ) => {
        if (!link) {
            dispatch(showNotification("TODO", "error", "Not Implemented"))
        }
    };

    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Typography noWrap variant={"h6"} className={classes.heading}>{name}</Typography>
                <Grid container className={classes.content}>
                    <Typography variant={"h6"} className={classes.paddingBottomDiv}>{practiceMessage}</Typography>
                    <Typography variant={"caption"} className={classes.content2}>{waitingMessage}</Typography>
                    {waitingRoomForms.map((waitingroom: any, index: number) => (
                    <Grid key={index} className={classes.waitingroomforms} container>
                        <Grid item xs={8} className={classes.waitingroomformsleftsection}>
                            <Typography noWrap variant={"h6"} className={classes.text12}>{waitingroom.title}</Typography>
                            <Typography noWrap variant={"h6"} className={classes.text10}>{waitingroom.dueDate}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={waitingroom.link}  onClick={(e) => handleFormNameClick(e,waitingroom.link)}>
                                <Typography noWrap variant="body1" className={classes.linkTypo}>{'Begin >'}</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default WaitingRoomTile