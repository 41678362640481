import { Button, Typography } from "@mui/material";
import { Form } from "admin/components/useForm";
import { addSecretQuestion, setRegistrationStep, setRegistrationValues, updateUsername } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import FormField from "common/components/FormField/FormField";
import Spinner from "common/components/Spinner/Spinner";
import { validateEmails, validateFirstEmail, validatePasswords, validateSpecialCharacter } from "common/views/Registration/utils";
import { REGISTRATION_STEP } from "constant";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PasswordValidation from "../PasswordValidation/PasswordValidation";

// CreateProfile is used to update the new users email address and set a password.
const CreateProfile = () => {
    const dispatch = useDispatch()
    const registration = useSelector((store: AppReduxStore) => store.registration)
    const {email, emailConfirm, password, passwordConfirm, emailVerified, passwordVerified} = registration
    const [loading, setLoading] = useState(false)

    const onChange = (key: string) => {
        return (value: any) => {
            dispatch(setRegistrationValues({[key]: value}))
        }
    }

    // Once the email and password have been set, log the user in  and route to the profile page
    useEffect(() => {
        if (passwordVerified) {
            const nextStep = emailVerified ? REGISTRATION_STEP.PROVIDER.ADD_SECRET_QUESTIONS : REGISTRATION_STEP.PROVIDER.LOG_IN_OR_CREATE
            dispatch(setRegistrationStep(nextStep))
        }
    }, [dispatch, emailVerified, passwordVerified])

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (emailVerified) {
            setLoading(true)
            dispatch(addSecretQuestion())
        } else {
            dispatch(updateUsername())
        }
    }
    
  
    const validEmails = validateEmails(email, emailConfirm)
    const validatePasswordCharacter = validateSpecialCharacter(password)
    const passwordErrorId = (validatePasswordCharacter) ? "" : "Registration.label.passwordError"
    const {valid: validPasswords} = validatePasswords(registration)
    const buttonText = "submit"
    const emailConfirmErrorId = !emailConfirm || email.trim().toUpperCase() === emailConfirm.trim().toUpperCase() ? "" : "Registration.error.emailMatch"
    const passwordConfirmErrorId = password === passwordConfirm ? "" : "Registration.error.passwordMatch"
    const submitDisabled = loading || emailVerified ? !validPasswords : !validEmails
    const emailFormatErrorId = validateFirstEmail(email)
    return (
        <Form onSubmit={onSubmit}>
            <FormField disabled={emailVerified} errorId={emailFormatErrorId} labelId="Registration.label.emailAddress" value={email} onChange={onChange("email")} type="email" />
            {emailVerified ? (
                <>
                    <FormField labelId="Registration.label.password" errorId={password && passwordErrorId} value={password} onChange={onChange("password")} type="password" />
                    <FormField errorId={passwordConfirmErrorId} labelId="Registration.label.passwordConfirm" value={passwordConfirm} onChange={onChange("passwordConfirm")} type="password" />
                </>

            ):(
                <FormField errorId={emailConfirmErrorId} labelId="Registration.label.emailConfirm" value={emailConfirm} onChange={onChange("emailConfirm")} type="email" />
            )}

            { emailVerified && <PasswordValidation /> }
            {loading && <Spinner />}
            <Button type="submit" disabled={submitDisabled} variant="outlined" color="primary" fullWidth style={{"background": (submitDisabled) ? "#CACACE": "#0F7BBD", "color": (submitDisabled) ? "#565662": "#FFF"}}>
                <Typography variant="button">
                    <FormattedMessage id={`Registration.button.${buttonText}`} />
                </Typography>
            </Button>
        </Form>
    )
}

export default CreateProfile