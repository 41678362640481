import React, { useEffect, useRef, useState } from "react"
import { useStyles } from "./styles"
import Spinner from "common/components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { AppReduxStore } from "png/store/reducerTypes"
import { Box, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl";
import { ReactComponent as  Chat } from "icon-library/SVG/Chat.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { pingProvider } from "png/store/actions"
import { useDispatch } from "react-redux";


type WaitingRoomLoaderType = {
    isPaused: boolean
}

export default function WaitingRoomLoader({ isPaused }: WaitingRoomLoaderType) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const { pngDetails, quickMessage, messageFrom } = useSelector((store: AppReduxStore) => store.pngMobile)
    const video = useRef<HTMLVideoElement>(null)
    const [stream, setstream] = useState<any>(null)
    
    useEffect(() => {
        !stream && initialBackground()
        return () => {
            const tracks = stream?.getTracks();
            tracks && tracks.forEach(function (track: any) {
                track.stop();
            });
        }
    }, [stream])
// TODO need to revisit 
    useEffect(()=>{
        let interval:ReturnType<typeof setInterval> | null  = null 
        if(!isPaused){
            interval= setInterval(()=>{
                dispatch(pingProvider())
            },900000)
        }
        return ()=>{
            interval&&clearInterval(interval)
        }
    },[isPaused])

    const initialBackground = () => {
        const facingMode = "user";
        const constraints = {
            audio: false,
            video: {
                facingMode: facingMode
            }
        };

        navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
            if (video && video.current) {
                setstream(stream)
                video.current.srcObject = stream
            }
        })
    }

    const { providerProfileInfo } = pngDetails || { providerProfileInfo: {} };

    const providerName = `${providerProfileInfo.lastName || ""}`;

    return (
        <div className={classes.assetsWrapper}>
            <video ref={video} autoPlay playsInline></video>
            <Box className={classes.firstLogo}>
                <Box className={classes.waitingRoomSpinner}>
                    <Spinner />
                </Box>
                {isPaused ? <><Typography className={`${classes.secondaryText} ${classes.mainContent}`}>
                    <FormattedMessage id="PatientWaitingRoom.label.backInWaitingRoom" />
                </Typography>
                    <Typography className={`${classes.secondaryText} ${classes.mainContent}`} >
                        <FormattedMessage id="PatientWaitingRoom.label.doctorResumeCall" />
                    </Typography></> :
                    <Typography className={`${classes.secondaryText} ${classes.mainContent}`} >
                        <FormattedMessage id="PatientWaitingRoom.label.videoCallText" values={{ name: providerName }} />
                    </Typography>}
                <Typography className={classes.secondaryText} >
                    <FormattedMessage id="PatientWaitingRoom.label.PleaseWait" />
                </Typography>
                {
                    quickMessage &&
                    <Box className={classes.quickChatSection}>
                        <SVGIcon className={classes.chatIcon} svg={Chat} />
                        <Box className={classes.chatMessageSection}>
                            <Typography className={classes.quickChatFrom}>
                                <FormattedMessage id="PatientWaitingRoom.label.Quick_Chat_From" values={{ name: messageFrom }} />
                            </Typography>
                            <Typography className={classes.quickChatMessage}>
                                “{quickMessage}”
                            </Typography>
                        </Box>
                    </Box>
                }
                
            </Box>
        </div>
    )
}
