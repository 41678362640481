import React from "react";
import classNames from "classnames";
import { TabContentProps } from "./types";
import { useStyles } from "./styles";

const TabContent: React.FC<TabContentProps> = ({ selected, children }) => {
    const classes = useStyles();

    return (
        <div className={classNames(classes.tabContent, { "hideTab": !selected })}>
            {children}
        </div>
    );
};

export default TabContent;
