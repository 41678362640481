import { Box, RadioGroup, Radio, Typography, Link } from "@mui/material";
import { map } from "underscore"
import CustomTypography from "../CustomTypography/CustomTypography";
import { ReactComponent as BuildingsSVg } from "icon-library/SVG/Buildings.svg";
import { ReactComponent as BuildingWideSvg } from "icon-library/SVG/Building_Wide.svg";
import { ReactComponent as StarSvg } from "icon-library/SVG/Star.svg";
import { createSvgIcon, getAddress } from "utils";
import { useStyles } from "./styles";
import { COLORS, SIZING } from "theme";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { cancelDefaultProfileDuration } from "store/actions/user";
import { completeRegistrationPostLoginMethod } from "admin/store/actions";
import { useOktaAuth } from "@okta/okta-react";
const BuildingWide = createSvgIcon(BuildingWideSvg)
const Buildings = createSvgIcon(BuildingsSVg)
const StarIcon = createSvgIcon(StarSvg)
// ProfileGroupSelector is used to render a list of radio buttons with information on the practice and profile types.
// Profiles are grouped by practice location
const ProfileGroupSelector = ({ location, profiles, currentProfileRoleJoinId, onProfileSelect, useCurrentPrj, expireCurrentPrj, verifyProfileText, isPractice }: any) => {
    const { name } = location
    const classes = useStyles()
    const dispatch = useDispatch()
    const { oktaAuth } = useOktaAuth()
    const cancelDefaultProfilePreference = (profile: any) => {
        dispatch(cancelDefaultProfileDuration(profile.id))
    }
    const handleVerifyProfile = (profile: any) => {
        const prjId = profile.id?.indexOf('_provider') > -1 ? profile.id?.replace('_provider', '') : profile.id
        // history && history.push(`/verify-profile?prjId=${prjId}&type=${profile?.level.type}`)
        dispatch(completeRegistrationPostLoginMethod(oktaAuth, prjId, profile?.level.type))
    }
    // renders an individual profile radio button. If this matches the currentProfileRoleJoinId the button is highlighted
    const renderProfileSelect = (profile: any) => {
        return (<>
            <Box display="flex" alignItems="center" key={profile.id}>
                <Radio id={"qa_radio_" + profile.id} disabled={(profile?.status === 'PENDING')} onChange={onProfileSelect} value={profile.id} disableRipple disableFocusRipple checked={currentProfileRoleJoinId === profile.id} />
                <Box>
                    <CustomTypography variant="radioText" id={"qa_" + profile.id}>
                        {profile.level.description}
                        {useCurrentPrj && expireCurrentPrj && currentProfileRoleJoinId === profile.id && moment(expireCurrentPrj).diff(moment(), "days") >= 0 && < Typography variant="body1" className={classes.preferenceText}>
                            <StarIcon fill={COLORS.LINK_BLUE} className={classes.starIcon} /> {`Default profile until end of ${moment(expireCurrentPrj).isSame(moment(), 'day') ? "today" : "week"}`}
                            <Link color="primary" className={classes.cancelDefaultProfilePreference} onClick={() => cancelDefaultProfilePreference(profile)}>
                                Cancel
                            </Link>
                        </Typography>}
                    </CustomTypography>
                    {
                        (profile.status === 'PENDING') &&
                        <CustomTypography component="span" onClick={() => handleVerifyProfile(profile)} className={classes.verifyProfileText}>{verifyProfileText}</CustomTypography>
                    }
                </Box>
            </Box>
        </>)
    }

    return (
        <Box paddingTop={SIZING.scale200} width={1} display="flex">
            {isPractice ? <BuildingWide fill={COLORS.DARK_ICON} className={classes.icon} /> :
                <Buildings fill={COLORS.DARK_ICON} className={classes.icon} />}
            <Box>
                <CustomTypography variant="titleSubheader" id="qa_profiles_practice_name">
                    {name}
                </CustomTypography>
                <CustomTypography variant="extraLight" id="qa_profiles_practice_address">
                    {getAddress(location)}
                </CustomTypography>
                <RadioGroup>
                    {map(profiles, renderProfileSelect)}
                </RadioGroup>
            </Box>
        </Box>
    )
}

export default ProfileGroupSelector