import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        formsOverviewBody: {
            padding: `${SIZING.scale600} ${SIZING.scale400} 0 ${SIZING.scale400}`,
            height: `calc(100% - ${SIZING.PATIENT_SUMMARY_HEADER_HEIGHT}px)`,
            overflow: "auto "
        },
        container: {
            display: "flex",
            flexWrap: "wrap",
        },
        formOverview: {
            display: "flex",
            justifyContent: "space-between",
            width: "555px",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
            border: "0.5px solid rgba(86, 86, 98, 0.2)",
            borderRadius: SIZING.scale200,
            padding: `${SIZING.scale300} ${SIZING.scale400} ${SIZING.scale400} ${SIZING.scale300}`,
            marginRight: SIZING.scale600,
            marginBottom: SIZING.scale600,
            cursor: "pointer"
        },
        incomplete: {
            color: COLORS.NOTIFICATION_TEXT
        },
        pushForm: {
            fill: COLORS.DISABLED_BTN_TEXT
        },
        hidden: {
            display: "none"
        }
}));