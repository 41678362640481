import { Switch } from "react-router-dom";
import { SecureRoute } from "authentication/app-secure-route";
import CaseNotes from "./case-notes";
import PatientDetailsItem from "../patient/patient-details-item";
import { EConsultFolderName, EConsultRequest } from "./types";
import { useEConsultParticipants } from "./utils";
import { useStyles } from "./styles";
import FileGridView from "common/components/files/file-grid-view";
import DetailsBanner from "./details-banner";
import MessagesView from "../messages/messages-view";

interface EConsultRequestDetailsContentProps extends EConsultRequest {
    eConsultRequestPath: string;
    folderName: EConsultFolderName;
}

const EConsultRequestDetailsContent = ({
    eConsultRequest,
    eConsultRequestPath,
    folderName,
}: EConsultRequestDetailsContentProps) => {
    const classes = useStyles();
    const {sender, recipients} = useEConsultParticipants(eConsultRequest);
    const isSentMenuOption = folderName === "sent";

    return (
        <Switch>
            <SecureRoute exact path={eConsultRequestPath}>
                <CaseNotes
                    message={eConsultRequest.notes}
                    dueDate={eConsultRequest.deadlineDate}
                    lastEdited={isSentMenuOption ? undefined : eConsultRequest.lastModifiedDate}
                    isUrgent={eConsultRequest.urgent}
                >
                    <DetailsBanner eConsultRequest={eConsultRequest} />
                </CaseNotes>
            </SecureRoute>
            <SecureRoute path={`${eConsultRequestPath}/attachments`}>
                <FileGridView files={eConsultRequest.attachments} className="eConsult__requestDetailsContent__attachments" />
            </SecureRoute>
            <SecureRoute exact={false} path={`${eConsultRequestPath}/messages`}>
                <MessagesView
                    eConsultRequestId={eConsultRequest.id}
                    sender={sender}
                    recipients={recipients}
                    requestStatus={eConsultRequest.status}
                />
            </SecureRoute>
            <SecureRoute path={`${eConsultRequestPath}/patient`}>
                <PatientDetailsItem patient={eConsultRequest.patient} className={classes.patientDetails} />
            </SecureRoute>
        </Switch>
    );
};

export default EConsultRequestDetailsContent;
