import { makeStyles } from "@mui/styles";
import { COLORS, SIZING} from "../../../theme";


export const useStyles = makeStyles(() =>({
    callBackbutton:{
        background: COLORS.SECONDARY_BUTTON,
        color: COLORS.WHITE,
        marginBottom: SIZING.scale700,
        //@ts-ignore
        width: ({ isEvisit}) => isEvisit ? '18.75rem':'auto',
        //@ts-ignore
        paddingRight:({ isEvisit}) => isEvisit ? '0':SIZING.scale800,
        "& .MuiButton-label":{
            display:'flex',
            justifyContent:'flex-start'
        },
        "&:hover":{
        background: COLORS.SECONDARY_BUTTON
        },
        "& .MuiTypography-root":{
            fontSize:SIZING.scale500,
            fontWeight: 600 
        }
    },
    startETranscriptionButton:{
        color: COLORS.LBLUE,
        width: '18.75rem',
        "& .MuiTypography-root":{
            fontSize:SIZING.scale500,
            fontWeight: 600,
            textTransform:'none'
        },
        "& .MuiButton-label":{
            display:'flex',
            justifyContent:'flex-start'
        },
        "&:hover":{
            background: COLORS.WHITE
            },
    },
    endButtonIcon:{
        marginRight:SIZING.scale200,
        marginLeft:SIZING.scale200,
        "& path":{
            fill: COLORS.WHITE
        }
    },
    startETranscription:{
        fontSize: `${SIZING.scale800} !important`,
        marginRight:SIZING.scale200,
        marginLeft:SIZING.scale200,
        width:'1.375rem',

    }
}));