import { AttachmentChangeEvent } from "./types";
import FileDropzoneInput, { FileDropzoneInputRef } from "common/components/files/file-dropzone-input";
import FileDropzoneInputConfirmClose from "common/components/files/file-dropzone-input-confirm-close";
import { FileUploadData } from "common/components/files/types";
import { useBusyState } from "common/utils/progress/progress-manager-hooks";
import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { messagingConversationFetchingBusyStateName } from "../constants";
import AttachmentButton from "./attachment-button";

type AttachmentSectionProps = {
    onAttachmentChange: (attachments: AttachmentChangeEvent) => void;
    onUploadPercentageChange: (percentage: number) => void;
};

export type AttachmentSectionRef = {
    closeWithoutConfirmation: () => void
} | undefined;

const hasAttachmentStatus = (attachments: FileUploadData[], statusInfo: FileUploadData["status"]): boolean => {
    return attachments.some((attachment) => attachment.status === statusInfo);
};

const AttachmentSection = forwardRef<AttachmentSectionRef, AttachmentSectionProps>(({ onAttachmentChange, onUploadPercentageChange }, ref) => {
    const [showDropzone, setShowDropzone] = useState(false);
    const fileDropzoneInputRef = useRef<FileDropzoneInputRef>();
    const { busyState } = useBusyState(messagingConversationFetchingBusyStateName);

    const onDropzoneChange = (attachments: FileUploadData[]) => {
        const cumulativeStatus = hasAttachmentStatus(attachments, "ERROR")
            ? "ERROR"
            : hasAttachmentStatus(attachments, "UPLOADING")
            ? "UPLOADING"
            : "UPLOADED";
        const attachmentIds = attachments.map((attachment) => attachment.id);
        onAttachmentChange({ cumulativeStatus, attachmentIds });
    };

    useImperativeHandle(ref, () => ({
        closeWithoutConfirmation: () => {
            fileDropzoneInputRef.current?.clearState();
            setShowDropzone(false);
        }
    }), []);

    return (
        <>
            <FileDropzoneInputConfirmClose
                show={showDropzone}
                onChange={setShowDropzone}
                messageId="messaging.attachments.removeAll"
            >
                <FileDropzoneInput
                    ref={fileDropzoneInputRef}
                    className="ComposeMessage__AttachmentSection__Dropzone"
                    disabled={busyState}
                    onChange={onDropzoneChange}
                    onPercentageChange={onUploadPercentageChange}
                    size="small"
                />
            </FileDropzoneInputConfirmClose>

            <AttachmentButton
                onClick={() => {
                    setShowDropzone((prevShowUpload) => !prevShowUpload);
                }}
                disabled={busyState}
                open={showDropzone}
            />
        </>
    );
});

export default AttachmentSection;
