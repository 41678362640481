import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        tabsContainer: {
            display: "flex",
            "& div": {
                marginRight: SIZING.scale200
            },
            "& a": {
                marginRight: SIZING.scale200
            },
            "& div:last-child": {
                marginRight: "0",
            }
        },
        tab: {
            display: "flex",
            flexDirection: "column" as "column", // https://github.com/frenic/csstype/issues/84
            alignItems: "center",
        },
        tabButton: {
            background: "white",
            height:  "30px",
            width: "154px",
            color: COLORS.PRIMARY_BTN_TEXT,
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            borderRadius: "16px",
            "&:hover": {
                backgroundColor: "white",
            }
        },
        selectedTabButton: {
            "& button": {
                background: COLORS.PRIMARY_BTN_TEXT,
                boxShadow: "none",
                color: "white",
                "&:hover": {
                    background: COLORS.PRIMARY_BTN_TEXT,
                    boxShadow: "none",
                }
            },
            "& $arrowDown": {
                display: "block"
            }
        },
        arrowDown: {
            display: "none",
            width: "0",
            height: "0",
            borderLeft: `${SIZING.scale200} solid transparent`,
            borderRight: `${SIZING.scale200} solid transparent`,
            borderTop: `${SIZING.scale200} solid ${COLORS.PRIMARY_BTN_TEXT}`,
        }
}));