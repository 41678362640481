import { ChevronLeft } from "assets";
import { useStyles } from "./styles";

type BreadcrumbProps = {
    backLinkTitle: string,
    currentTitle: string,
    idPrefix: string,
    onBackLinkClick: () => void,

}

const Breadcrumb = ({ backLinkTitle, currentTitle, idPrefix, onBackLinkClick }: BreadcrumbProps) => {
    const classes = useStyles();
    return (
        <nav className={classes.breadcrumb}>
            <ChevronLeft
                onClick={onBackLinkClick}
            />
            <span id={`${idPrefix}__link`}
                className="breadcrumb__link"
                onClick={onBackLinkClick}
            >{backLinkTitle}</span>
            <span className="breadcrumb__dot" dangerouslySetInnerHTML={{__html:"&centerdot;"}}></span>
            <span className="breadcrumb__title">{currentTitle}</span>
        </nav>
    );
}

export default Breadcrumb;
