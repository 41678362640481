import React from "react";
import { Box, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import { useStyles } from "./styles";
import { TablePaginationActionsProps } from "./types";

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) => {
    const classes = useStyles()

    const handleFirstPageButtonClick = (event: any) => {
        onPageChange(event, 0)
    };
    const handleBackButtonClick = (event: any) => {
        onPageChange(event, page - 1)
    };
    const handleNextButtonClick = (event: any) => {
        onPageChange(event, page + 1)
    };
    const handleLastPageButtonClick = (event: any) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    };
    const handlePageSelectChange = (event: any) => {
        onPageChange(event, (parseInt(event.target.value) - 1))
    };

    return (
        <Box ml={2.5} display="flex" flex={1}>
            <Box flex={1}></Box>
            <Box display="inline-flex" alignItems="center">
                <IconButton style={{ padding: 0 }}
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                    size="large"
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton style={{ padding: 0 }}
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                    size="large"
                >
                    <KeyboardArrowLeft />
                </IconButton>

                <Typography style={{ fontSize: '12px', color: '#393948' }}>
                    {`Page `}
                </Typography>
                <TextField id="ddlPageNumber" label="" value={page + 1} classes={{ root: classes.root }}
                    style={{ paddingRight: '0px' }}
                    variant="standard"
                    InputProps={{ disableUnderline: true, classes: classes }}
                    onChange={handlePageSelectChange} select>
                    {Array.from(Array(Math.max(0, Math.ceil(count / rowsPerPage))).keys()).map((p: number, index: number) => (
                        <MenuItem key={index} value={p + 1}>{p + 1}</MenuItem>
                    ))}
                </TextField>
                <Typography style={{ fontSize: '12px', color: '#393948' }}>
                    {`of ${Math.ceil(count / rowsPerPage)}`}
                </Typography>
                <IconButton style={{ padding: 0 }}
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                    size="large"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton style={{ padding: 0 }}
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                    size="large"
                >
                    <LastPageIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default TablePaginationActions