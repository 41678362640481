import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as BlankFile } from "icon-library/SVG/File-Light_Blank.svg";
import { useStyles } from "./styles";

type CardLinkProps = {
    id: string,
    name?: string,
    href?: string,
    target?: string,
    permissions?: string[]
}

export type CardProps = {
    name: string,
    description?: string | JSX.Element,
    action: CardLinkProps,
    comment?: string,
    permissions?: string[],
    icon: React.FC<React.SVGProps<SVGSVGElement> & { title?: string }>,
    enabled: boolean
}

export const ClickableCard = ({ config, onSelect: handleSelection, tall }: { config?: CardProps, onSelect?: (menuItem: string) => void, tall?: boolean }) => {
    const classes = useStyles();
    const dummyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac sollicitudin pharetra tellus dis. Laoreet turpis sed blandit faucibus sagittis, nibh orci, scelerisque.`;
    const card: CardProps = config ? config : {
        name: "Clickable Card",
        action: {
            id: "practice_management_resources",
        },
        icon: BlankFile,
        enabled: true
    }

    return (card.enabled ? (
        <div id="pep_clickable_card" className={tall ? classes.pep_clickable_card_tall : classes.pep_clickable_card}>
            <a style={{ display: 'flex', height: '100%', width: '100%' }} href={card.action.href} target={card.action.target ? card.action.target : "_self"} onClick={(event) => {
                if (handleSelection && !card.action.href) {
                    event.preventDefault();
                    event.stopPropagation();
                    handleSelection(card.action.id);
                }
            }}>
                <div style={{ width: '10%', marginRight: '10px' }}>
                    <SVGIcon svg={card.icon} className={classes.pep_clickable_card_icon} />
                </div>
                <div style={{ width: '90%', height: '100%' }}>
                    <p style={{ fontFamily: 'BentonSansCond', fontWeight: '500', fontSize: '20px', lineHeight: '140%', letterSpacing: '0.02em', color: '#6E1E80', margin: '0px 5px' }}>
                        {card.name}
                    </p>
                    <p style={{ fontWeight: '400', fontSize: '16px', lineHeight: '140%', color: '#404040', margin: '5px' }}>
                        {card.description ? card.description : dummyText}
                    </p>
                </div>
            </a>
        </div>
    ) : <></>)
}

export default ClickableCard