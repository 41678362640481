import React from "react";
import { useIntl } from 'react-intl';
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as UserSingleDisableIcon } from 'icon-library/SVG/User_Single-Disable.svg';
import { ReactComponent as PenIcon } from 'icon-library/SVG/Pen.svg';
import { ReactComponent as EnvelopeIcon } from 'icon-library/SVG/eMail_Closed-Envelope.svg';
import { ReactComponent as RadioCircleIcon } from 'icon-library/SVG/Radio_Circle.svg';
import { ReactComponent as AltaisStarIcon } from 'icon-library/SVG/Altais-Star.svg';
import { BulkActionContentMenuProps } from 'admin/components/BulkActionMenuContent/types';
import { useStyles } from '../../BulkActionMenu/styles';

const UserBulkActionMenu = ({ callback, handleClose }: BulkActionContentMenuProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <List className={classes.baMenu}><ListItem />
            <ListItem button>
                <ListItemIcon>
                    <PenIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baEditUserLevel" primary={intl.formatMessage({ id: "BulkActionMenu.EditUserLevel" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditUserLevel"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <UserSingleDisableIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baDeactivateAccount" primary={intl.formatMessage({ id: "BulkActionMenu.DeactivateUsers" })} classes={{ primary: classes.listText }} onClick={() => { callback("Deactivate"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <EnvelopeIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baSendInvites" primary={intl.formatMessage({ id: "BulkActionMenu.SendInvites" })} classes={{ primary: classes.listText }} onClick={() => { callback("SendInvites"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <RadioCircleIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baActivateAccount" primary={intl.formatMessage({ id: "BulkActionMenu.ActivateUsers" })} classes={{ primary: classes.listText }} onClick={() => { callback("Activate"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <AltaisStarIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baEditProducts" primary={intl.formatMessage({ id: "BulkActionMenu.EditProducts" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditProducts"); handleClose() }} />
            </ListItem>
        </List>
    );
}

export default UserBulkActionMenu;
