import createReducer from '../createReducer'
import { LocationState } from '../reducerTypes'

const initialState: LocationState = {
	locations: [],
	sLocationA: {},
	states: []
}

export default createReducer(initialState, {
	'SET_LOCATIONS': (state: LocationState, payload: LocationState): LocationState => {
		const { locations } = payload
		return {
			...state,
			...payload,
			locations
		}
	},

	'SET_SELECTED_LOCATION': (state: LocationState, payload: LocationState): LocationState => {
		const { sLocationA } = payload
		return {
			...state,
			...payload,
			sLocationA
		}
	},

	'SET_STATES': (state: LocationState, payload: LocationState): LocationState => {
		const { states } = payload
		return {
			...state,
			...payload,
			states
		}
	},
})