import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>({
    base: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100vh',
        flexDirection:'column',
        "& .media-error-root-wrapper ~ div":{
            height:'calc(100% - 64px)'
        }
    },
    baseContainer: {
        height: '100%',
        width: '100%',
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: 'relative'
    }
}));