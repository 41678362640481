import React from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { ReactComponent as EmptyIcon } from "../../tempIcons/Circles.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import EmptyMessage from "../../../../common/components/empty-message/empty-message";
import { useStyles } from "./styles";

const CircleOfContacts = () => {
    const classes = useStyles();

    // TODO: only the empty case, for now
    return (
        <Box
            className={classes.circleOfContacts}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flexGrow={1}
        >
            <Box flexShrink={1}>
                <CustomTypography variant="modalSectionHeader" className="panelHeader">
                    <EmptyIcon />
                    <FormattedMessage id="EConsult.newRequestModal.circleOfContacts.header" />
                </CustomTypography>
            </Box>
            <EmptyMessage
                className="emptyMessage"
                Icon={<SVGIcon svg={EmptyIcon} size="scale2500" color="CREATE_E_CONSULT_REQUEST_ICON"/>}
                primaryMessage={{
                    id: "EConsult.newRequestModal.circleOfContacts.emptyMessage"
                }}
                secondaryMessage={{
                    id: "EConsult.newRequestModal.circleOfContacts.howToSearch"
                }}
            />
        </Box>
    );
};

export default CircleOfContacts;
