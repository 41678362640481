import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

import {AppReduxStore} from "store/reducerTypes";
import {setOpenConversationSid} from "store/actions";
import { useStyles } from "./styles";
import ChatHeader from "./ChatHeader/ChatHeader";
import ChatHistory from "components/messaging/chat-history";
import NewMessage from "./NewMessage/NewMessage";

// Chat is a wrapper component for the chat header, chat history and new message components. It also handles unsetting
// the open conversation when the user leaves the messages page
const Chat = () => {
    const classes = useStyles();
    const {openConversationSid, conversations} = useSelector((state: AppReduxStore) => state.chat)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {dispatch(setOpenConversationSid(""))}
    }, [dispatch])

    if (!openConversationSid || !conversations[openConversationSid]) {
        return null
    }

    return (
        <Box height="100%" position="relative" className={classes.chat}>
            <Box height="100%" display="flex" flexDirection="column">
                <ChatHeader />
                <ChatHistory conversationId={openConversationSid}/>
                <NewMessage />
            </Box>
        </Box>
    )
}

export default Chat