import React, { useState } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { ReactComponent as ImageIcon } from "icon-library/SVG/Image_Landscape.svg";
// TODO: to be replaced once icon-lib deploy is fixed
import { ReactComponent as PdfIcon } from "views/EConsult/tempIcons/File_PDF.svg";
// TODO: to be replaced once icon-lib deploy is fixed
import { ReactComponent as FileIcon } from "views/EConsult/tempIcons/File_Blank.svg";
// TODO: to be replaced once icon-lib deploy is fixed
import { ReactComponent as VideoIcon } from "views/EConsult/tempIcons/Camera_Video.svg";
import { ReactComponent as WaitRing } from "icon-library/SVG/Wait_Ring-Thick.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ValueOf } from "types";
import * as FileClassNames from "../class-names";
import { useStyles } from "../styles";

const ICONS = {
    PDF: { type: "pdf", component: PdfIcon },
    IMAGE: { type: "image", component: ImageIcon },
    VIDEO: { type: "video", component: VideoIcon },
    OTHER: { type: "other", component: FileIcon },
} as const;

const getFileNameParts = (fileName: string) => {
    const extIndex = fileName.lastIndexOf(".");

    return {
        baseName: extIndex > 0 ? fileName.substring(0, extIndex) : fileName,
        ext: extIndex > 0 ? fileName.substring(extIndex + 1) : "",
    };
};

const iconTypeFromMimeType = (mimeType?: string): ValueOf<typeof ICONS> => {
    if (mimeType === "application/pdf") {
        return ICONS.PDF;
    } else if (mimeType?.startsWith("image/")) {
        return ICONS.IMAGE;
    } else if (mimeType?.startsWith("video/")) {
        return ICONS.VIDEO;
    }
    return ICONS.OTHER;
};

type PreviewProps = {
    title?: string;
    mimeType?: string;
    previewUrl?: string;
    error?: boolean;
    loading?: boolean;
};

const PreviewBase = ({ previewUrl, title, mimeType, loading, error }: PreviewProps) => {
    const classes = useStyles();
    const [previewVisible, setPreviewVisible] = useState(false);

    const { baseName, ext } = getFileNameParts(title || "");

    const iconType = iconTypeFromMimeType(mimeType);

    const Icon = iconType.component;

    return (
        <div className={classNames(classes.Files__Preview, FileClassNames.Preview, {[FileClassNames.PreviewError]: error})}>
            <div
                className={classNames(FileClassNames.PreviewContainer, classes.Files__Preview__Size, {
                    [iconType.type]: true,
                    error,
                })}
            >
                {!mimeType && loading ? null : <Icon className={FileClassNames.PreviewIcon} />}
                {previewUrl && (
                    <img
                        src={previewUrl}
                        alt={title}
                        title={title}
                        className={classNames(FileClassNames.PreviewImg, classes.Files__Preview__Size, {
                            [FileClassNames.PreviewImgVisible]: previewVisible,
                        })}
                        draggable="false"
                        crossOrigin="anonymous"
                        onLoad={() => {
                            setPreviewVisible(true);
                        }}
                        onError={() => {
                            setPreviewVisible(false);
                        }}
                    />
                )}
                {loading && (
                    <div className={FileClassNames.PreviewLoading}>
                        <SVGIcon svg={WaitRing} size="scale1600" color="WHITE"/>
                    </div>
                )}
            </div>
            {title && <div className={classNames(FileClassNames.PreviewTextLine)}>
                <span className="preview__baseName" title={title}>
                    {ext ? `${baseName}.` : baseName}
                </span>
                <span className="preview__extension" title={title}>
                    {ext}
                </span>
            </div>}
            {error && (
                <div className={FileClassNames.PreviewTextLine}>
                    <FormattedMessage id="files.preview.previewBase.failedUpload" />
                </div>
            )}
        </div>
    );
};

export default PreviewBase;
