import React from "react";
import { Paper, Box, Typography } from "@mui/material";

import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useStyles } from "./styles";
import { PopoverContentProps } from "./types";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
export default function PopoverContent(props: PopoverContentProps) {
  const { close, list, title, renderItem, changePinnedItems, className, loader,pinnedItems,isMultiSelect,searchString,themeColor,dropDownPosition } = props;
  //@ts-ignore
  const classes = useStyles({ themeColor,dropDownPosition});

  return (
    <Paper className={`${className} ${classes.popupTrinagle} custom-dropdown-wrapper`} data-testid="multi-select-dropdown" classes={{ root: classes.paper }}>
      <Typography className="title" variant="h4" component="div">
        {title}
      </Typography>
      {loader ? <ComponentLoader /> : <Box className="itemWrapper" id="QA_message_compose_search_results">
        {list.map((item: any) => renderItem(item, changePinnedItems,pinnedItems,isMultiSelect,searchString))}
      </Box>}

      <Close className="closeIcon" onClick={close} id="QA_message_compose_close_button" />
    </Paper>
  );
}
