import { Box, Divider, Button, TextField, Paper, ClickAwayListener } from "@mui/material";
import React, { useState } from "react"
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as CrossCircle } from "icon-library/SVG/Cross_Circle.svg";
import { OptionsType } from "./types"
import { sendQuickChat } from "store/actions";
import { useSnackbar } from "notistack";
import { useReduxDispatch } from "store/utils";
import { useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import ErrorMessage from "common/components/ErrorMessage/ErrorMessage";
import { useForm } from "common/components/FormValidator/FormValidator";

const QuickChat: React.FC<OptionsType> = ({ handleClose, cardDetails, currentMeetingDetails, status, allSelected}) => {
    const { user } = useSelector((store: AppReduxStore) => store)
    const isMergePresent = currentMeetingDetails !== null ? true : false
    const classes = useStyles({isMergePresent,allSelected})
    const customDispatch = useReduxDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [commonLoader, updateCommonLoader] = useState<Boolean>(false)
    const intl = useIntl()
    const [quickChatMessage, updateQuickChatMessage] = useState<string>("")

    const {
        validateAll,
        renderMessage
    } = useForm()

    const handleQuickOption =(id:string) =>{
        sendMessage(id)
    }

    const hanldeInputChange =(e:any)=>{
        updateQuickChatMessage(e.target.value)
    }

    const sendMessage = (message:any) =>{
        updateCommonLoader(true)
        customDispatch(sendQuickChat(cardDetails,message,user.lastName)).then(() => {
            updateCommonLoader(false)
            enqueueSnackbar(intl.formatMessage({
                id: "Waiting_Room_QuicK_Message_Sent",
            }), {
                variant: "success",
            })
            handleClose()
            updateQuickChatMessage("")
        }).catch(() => updateCommonLoader(false))
    }


    const handleSend = () => {
        if (validateAll() && quickChatMessage.trim().length !== 0 ){
            sendMessage(quickChatMessage)
        }  
    }
    const quickChatError = renderMessage("message", "required|message", quickChatMessage);

    return (
        <ClickAwayListener onClickAway={() => handleClose()}>
            <Paper className={classes.paperWrapper} >
                {commonLoader && <ComponentLoader />}
                <Box className={classes.quickChatWrap}>
                    <CustomTypography className={classes.quickChatTitle} >
                        <FormattedMessage id="Waiting_Room_Provider_Quick_Message" />
                    </CustomTypography>
                    <SVGIcon trim={true} svg={CrossCircle} className={classes.crossIcon} size="scale400" onClick={handleClose} />
                </Box>
                <Box className={classes.quickChatSection}>
                    <Box className={classes.quickMessageContent} onClick={() => handleQuickOption(intl.formatMessage({
                        id: "Waiting_Room_Provider_Quick_Message_One",
                    }))}>
                        <CustomTypography>
                            “<FormattedMessage id="Waiting_Room_Provider_Quick_Message_One" />”
                        </CustomTypography>
                    </Box>
                    <Box className={classes.quickMessageContent} onClick={() => handleQuickOption(intl.formatMessage({
                        id: "Waiting_Room_Provider_Quick_Message_Two",
                    }))}>
                        <CustomTypography>
                            “<FormattedMessage id="Waiting_Room_Provider_Quick_Message_Two" />”
                        </CustomTypography>
                    </Box>
                </Box>
                <Divider className={classes.divider} />
                <Box >
                <TextField
                    className={classes.textfield}
                    placeholder={intl.formatMessage({
                        id: "Waiting_Room_Provider_Quick_Chat_Placeholder",
                    })}
                    value={quickChatMessage} onChange={(e) => hanldeInputChange(e)}
                    inputProps={{
                        maxLength: 100
                    }}
                />
                    <ErrorMessage message={quickChatError} />
                </Box>
                <Box className={classes.btnSection}>
                    <Box className={classes.chatLengthText}>
                        {quickChatMessage.trim().length} / 100
                    </Box>
                   <Button className={classes.sendBtn} onClick={handleSend}>
                       Send
                   </Button>
                </Box>
            </Paper>
        </ClickAwayListener>
    )
}

export default QuickChat