import { CareTeamSearchItem } from "./types";
import { Route } from "react-router-dom";
import { ReactComponent as HandUserUsersSvg } from "icon-library/SVG/Hand_User_Users.svg";
import { useCallback, useState } from "react";
import { useStyles } from "./styles";
import * as CSS from "./class-names";
import careTeamItemRenderer from "./components/care-team-item-renderer";
import EmptyMessage from "common/components/empty-message/empty-message";
import { Page, PageLeftBody, PageLeftHead } from "lib/ui-components/page";
import SearchHeader from "./components/search-header";
import SearchList from "../../lib/ui-components/search-list/search-list";
import useSearchCareTeams from "./components/use-search-care-teams";
import { CareTeamDetails } from "./care-team-details";

import * as Routes from "./routes";
import { TAB_PRIMARY_ONLY_ID, TAB_ALL_ID } from "./constants";

const CareTeam = () => {
    const styles = useStyles();

    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedTab, setSelectedTab] = useState<string>(TAB_ALL_ID);
    const { items, loading } = useSearchCareTeams(searchValue, selectedTab === TAB_PRIMARY_ONLY_ID);

    const getCareTemDetailsLink = useCallback(({ id }: CareTeamSearchItem) => Routes.careTeamDetails({ id }), []);
    const isEmptyBySearch = items?.length === 0 && searchValue.trim().length !== 0;

    return (
        <Page className={styles.CareTeam}>
            <PageLeftHead>
                <SearchHeader
                    onSearchValueChanged={setSearchValue}
                    onTabSelected={setSelectedTab}
                    searchValue={searchValue}
                    selectedTab={selectedTab}
                />
            </PageLeftHead>
            <PageLeftBody>
                <SearchList<CareTeamSearchItem>
                    className={`${CSS.CareTeamSearchList}`}
                    emptyMessage={
                        <EmptyMessage
                            className={CSS.CareTeamSearchListEmptyMessage}
                            variant="content"
                            Icon={<HandUserUsersSvg />}
                            primaryMessage={{
                                id: isEmptyBySearch ? "careTeam.search.notfound.title" : "careTeam.search.empty.title",
                            }}
                            secondaryMessage={{
                                id: isEmptyBySearch
                                    ? "careTeam.search.notfound.subtitle"
                                    : "careTeam.search.empty.subtitle",
                            }}
                        />
                    }
                    items={items}
                    itemRenderer={careTeamItemRenderer}
                    itemLinkGetter={getCareTemDetailsLink}
                    loading={loading}
                />
            </PageLeftBody>
            <Route path={Routes.careTeamDetails()} component={CareTeamDetails} />
        </Page>
    );
};

export default CareTeam;
