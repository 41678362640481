import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { PieChartProps } from "./types";
import { ReactComponent as AltaisStar } from 'icon-library/SVG/Altais-Star.svg';
import { createSvgIcon } from "utils";
const AltaisStarIcon = createSvgIcon(AltaisStar);
const PieChart = ({ data, showLabel = false, heading }: PieChartProps) => {
    const pieData = [
        { legend: 'Active', color: '#139AED', value: data?.activeUserCount },
        { legend: 'Invited', color: '#0B5D8F', value: data?.invitedUserCount },
        { legend: 'Deactivated', color: '#565662', value: data?.deactivateUserCount },
        { legend: 'Suspended', color: '#E82C2A', value: data?.suspendedUserCount ?? 0 },
        { legend: 'Locked', color: '#909098', value: data?.lockedUserCount },
        { legend: 'Pending Invite', color: '#CACACE', value: data?.pendingInviteUserCount ?? 0 },
    ]

    const classes = useStyles();
    const colors = pieData?.map(p => p.color);
    const values = pieData?.map(p => p.value);
    const getAnglePoint = (startAngle: any, endAngle: any, radius: any, x: number, y: number) => {
        const x1 = x + radius * Math.cos(Math.PI * startAngle / 180);
        const y1 = y + radius * Math.sin(Math.PI * startAngle / 180);
        const x2 = x + radius * Math.cos(Math.PI * endAngle / 180);
        const y2 = y + radius * Math.sin(Math.PI * endAngle / 180);

        return { x1, y1, x2, y2 };
    }
    const Pie = (props: any) => {
        const colors = props.colors,
            colorsLength = colors.length,
            labels = props.labels,
            hole = props.hole,
            radius = props.radius,
            diameter = radius * 2;
        let startAngle: number;

        const sum = props.data.reduce(function (carry: any, current: any) { return carry + current }, 0);
        startAngle = 0;
        return (
            <svg width={diameter} height={diameter} viewBox={'0 0 ' + diameter + ' ' + diameter} xmlns="http://www.w3.org/2000/svg" version="1.1">
                {props.data.map(function (slice: any, sliceIndex: any) {
                    const nextAngle = startAngle;
                    const angle = (slice / sum) * 360;
                    const percent = (slice / sum) * 100;
                    startAngle += angle;

                    return <Slice
                        key={sliceIndex}
                        value={slice}
                        percent={props.percent}
                        percentValue={percent.toFixed(1)}
                        startAngle={nextAngle}
                        angle={angle}
                        radius={radius}
                        hole={radius - hole}
                        trueHole={hole}
                        showLabel={labels}
                        fill={colors[sliceIndex % colorsLength]}
                        stroke={props.stroke}
                        strokeWidth={props.strokeWidth}
                    />
                })}
            </svg>
        );
    }
    const Slice = (props: any) => {
        const [stateXY, setStateXY] = useState({
            x: 0,
            y: 0
        } as any)
        const [statePath, setStatePath] = useState({
            path: ''
        } as any)

        useEffect(() => {
            setStatePath({ path: '' });
            draw(0)
        }, []);
        const draw = (s: any) => {
            const p = props, path = [];

            const step = p.angle / (37.5 / 2);

            if (s + step > p.angle) {
                s = p.angle;
            }
            const a = getAnglePoint(p.startAngle, p.startAngle + s, p.radius, p.radius, p.radius);
            const b = getAnglePoint(p.startAngle, p.startAngle + s, p.radius - p.hole, p.radius, p.radius);
            path.push('M' + a.x1 + ',' + a.y1);
            path.push('A' + p.radius + ',' + p.radius + ' 0 ' + (s > 180 ? 1 : 0) + ',1 ' + a.x2 + ',' + Math.floor(a.y2 * 100) / 100);
            path.push('L' + b.x2 + ',' + b.y2);
            path.push('A' + (p.radius - p.hole) + ',' + (p.radius - p.hole) + ' 0 ' + (s > 180 ? 1 : 0) + ',0 ' + b.x1 + ',' + b.y1);
            path.push('Z');

            setStatePath({ path: path.join(' ') });

            if (s < p.angle) {
                setTimeout(function () { draw(s + step) }, 16);
            } else if (p.showLabel) {
                const c = getAnglePoint(p.startAngle, p.startAngle + (p.angle / 2), (p.radius / 2 + p.trueHole / 2), p.radius, p.radius);

                setStateXY({
                    x: c.x2,
                    y: c.y2
                });
            }
        }
        return (
            <g overflow="hidden">
                <path
                    d={statePath.path}
                    fill={props.fill}
                    stroke={props.stroke}
                    strokeWidth={props.strokeWidth ? props.strokeWidth : 3}
                />
                {props.showLabel && props.percentValue > 5 ?
                    <text x={stateXY.x} y={stateXY.y} fill="#fff" textAnchor="middle" fontSize={12}>
                        {props.percent ? props.percentValue + '%' : props.value}
                    </text>
                    : null}
            </g>
        );

    }
    return (
        <Box className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography color="textPrimary" className={classes.totalUsersText}>
                        <IconButton style={{ padding: 0 }}>
                            <AltaisStarIcon size={26} fill="#0F7BBD" />
                        </IconButton>
                        {heading}
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Typography color="textPrimary" className={classes.totalUserCountText}>
                        {data?.totalUserCount}
                    </Typography>
                </Grid>
            </Grid>
            <Box className={classes.pieContainer}>
                <Pie
                    data={values}
                    radius={100}
                    hole={0}
                    colors={colors}
                    strokeWidth={1}
                    stroke={'rgba(255, 255, 255, .5)'}
                    labels={showLabel}
                />
                <Box className={classes.legendContainer}>
                    {pieData.filter(x => x.value > 0).map((d, index) => {
                        return (
                            <Box className={classes.entry} key={index}>
                                <Box style={{ backgroundColor: d.color }} className={classes.entryColor}></Box>
                                <Box className={classes.entryTextwrapper}>
                                    <Typography color="textPrimary" className={classes.entryText}>
                                        {d.value} {d.legend}</Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    )
}

export default PieChart;
