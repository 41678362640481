import {
    Box, Button, DialogActions, DialogContent, Paper,
    Grid,
    Typography
} from "@mui/material";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useStyles } from './styles';
import AddPatientForm from "../../AddPatient/AddPatientForm";
import Spinner from "common/components/Spinner/Spinner";
import { createSvgIcon } from "utils";
import { ReactComponent as AddPatient } from "icon-library/SVG/User_Single.svg";
import AddPatientBulk from '../../AddPatient/AddPatientBulk';
import { AppReduxStore } from 'admin/store/reducerTypes';
import { useSelector } from 'react-redux';
import { appendEhrSystemKeyTemplatePath } from "admin/common/utils";
const { PATIENT_BULK_UPLOAD_CSV_TEMPLATE } = (window as any).env;
const AddPatientIcon = createSvgIcon(AddPatient);

const AddPatientsConfigure = forwardRef((props, ref) => {
    const classes = useStyles();
    const [tab/*, setTab*/] = useState(0);
    const [isBulkUploadDisabled, setIsBulkUploadDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const { auth, practice, admin } = useSelector((state: AppReduxStore) => state);
    const addPatientFormRef = useRef<any>();
    const addPatientBulkRef = useRef<any>();
    const intl = useIntl()

    useImperativeHandle(
        ref,
        () => ({
            saveAndContinueLaterAction(index: number) {
                return addPatientFormRef.current ? addPatientFormRef.current.saveAndContinueLaterAction(index) :
                    addPatientBulkRef.current.saveAndContinueLaterAction(index)
            }
        }),
    )

    const handleClose = () => {
        setIsBulkUploadDisabled(true);
    };

    /*
    const handleChangeTab = (event: any, newTab: any) => {
        setTab(newTab);
    };
    */

    /*
    const handleSavePatient = (event: any) => {
        let result = addPatientFormRef.current.submitForm(event);
        if (result)
            handleClose();
    };

    const handleSaveAddPatient = (event: any) => {
        addPatientFormRef.current.submitForm(event);
    };
    */

    const handleBulkUpload = () => {
        const result = addPatientBulkRef.current.submitBulkUpload();
        if (result) {
            handleClose()
        }
    }

    const handleAddedFile = () => {
        setIsBulkUploadDisabled(false);
    };
    const handleRemovedFile = () => {
        setIsBulkUploadDisabled(true);
    };

    const handleLoading = (event: any) => {
        setLoading(event)
    }

    const ehrInfo = admin.ehrSourceSystems?.find((e: any) => e.id === practice?.sPractice?.ehrSourceSystemId)

    return (
        <>
            <Grid item className={classes.productsPageHeader}>
                <Typography className={classes.pageHeaderText}>
                    <AddPatientIcon fill="#0F7BBD" className={classes.productsIcon} />
                    <FormattedMessage id="Configure.Text.Config.AddPatient" />
                </Typography>
                <Typography className={classes.pageSubHeaderText}>
                    <FormattedMessage id={"Configure.Text.Config.AddPatient.SubText"} />
                </Typography>
            </Grid>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '16px' }}>
                <Box>
                    <Typography className={classes.pageSubHeaderText}>
                        <FormattedMessage id={"Configure.Text.Config.AddPatient.SubTextSecond"} />
                    </Typography>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '16px' }}>
                <Box flexGrow={1}>
                    {tab === 0 && ehrInfo?.hasTemplate &&
                        <Button variant="contained"
                            aria-haspopup="true" href={appendEhrSystemKeyTemplatePath(PATIENT_BULK_UPLOAD_CSV_TEMPLATE,
                                auth.currentPractice?.ehrSourceSystemId || practice?.sPractice?.ehrSourceSystemId)}>
                            {intl.formatMessage({ id: "AddPatient.DowloadTemplate" })}
                        </Button>}
                </Box>
                <Box>
                    {/* {tab === 1 ? <Fragment>
                        <Button id="btnSaveAndFinish" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSavePatient}>
                            {intl.formatMessage({ id: "AddPatient.Action.SaveAndFinish" })}
                        </Button>
                    </Fragment> : */}
                    {isLoading ? <Spinner /> :
                        <Button id="btnBulkUpload" variant="outlined" color="primary" aria-haspopup="true" onClick={handleBulkUpload} disabled={isBulkUploadDisabled}>
                            Upload
                        </Button>}
                    {/* } */}
                </Box>
            </DialogActions>
            {/* <DialogActions className={classes.dlgAction}>
                <Box width="100%">
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        onChange={handleChangeTab}
                        aria-label="Add Patient tab">
                        <Tab label={intl.formatMessage({ id: "AddPatient.BulkUpload" })} />
                        <Tab label={intl.formatMessage({ id: "AddPatient.Tab.AddPatient" })} />
                    </Tabs>
                </Box>
            </DialogActions> */}
            <DialogContent dividers={true} classes={tab === 1 ? { dividers: classes.dialodContentDividers } : {}}>
                <Paper square elevation={0}>
                    {tab === 1 ? <Box className={classes.tabContentWrapper}>
                        <AddPatientForm ref={addPatientFormRef} />
                    </Box> :
                        <Box className={classes.tabContentWrapper}>
                            <AddPatientBulk ref={addPatientBulkRef} handleAddedFile={handleAddedFile} handleRemovedFile={handleRemovedFile} handleLoading={handleLoading} handleClose={handleClose}></AddPatientBulk>
                        </Box>
                    }
                </Paper>
            </DialogContent>
        </>
    )
})

export default AddPatientsConfigure;
