import React from "react";
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ErrorView } from './ErrorView';

const NotFound = () => {
    const intl = useIntl();

    return (
        <ErrorView
            code="404"
            titleId="errorView.notFound.title"
            descriptionId="errorView.notFound.description"
        >
            <ul>
                <li><Link to="/login">{intl.formatMessage({ id: 'errorView.returnToLoginPage' })}</Link></li>
            </ul>
        </ErrorView>
    );
};

export default NotFound
