import createRootReducer from './reducers'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'

import { createMemoryHistory, createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

//Need to centralize the history object and conditionalize it for tests
//https://blog.logrocket.com/testing-the-react-router-usehistory-hook-with-react-testing-library/
const isTest = process.env.NODE_ENV === "test";
export const history = isTest
	? createMemoryHistory({ initialEntries: ['/'] })
	: createBrowserHistory();

const rootReducer = createRootReducer(history)

const titleFormatter = (action: any, time: any, took: any) => {
	const app = window.location.hash
	return `action @ ${time} ${action.type} (in ${took.toFixed(2)} ms) on ${app}`
}

const logger = createLogger({
	titleFormatter: titleFormatter,
})

const configureStore = (initialState: any) => {
	if (process.env.NODE_ENV === 'production') {
		const store = createStore(
			rootReducer,
			initialState,
			applyMiddleware(routerMiddleware(history), thunk, logger)
		)
		return store
	} else if (process.env.NODE_ENV === 'test') {
		const store = createStore(
			rootReducer,
			initialState,
			applyMiddleware(routerMiddleware(history), thunk)
		)
		return store
	} else {
		const store = createStore(
			rootReducer,
			initialState,
			applyMiddleware(routerMiddleware(history), thunk, logger)
		)
		if (module.hot) {
			module.hot.accept('./reducers', () => {
				const nextRootReducer = require('./reducers')
				store.replaceReducer(nextRootReducer)
			})
		}
		return store
	}
}

export default configureStore
