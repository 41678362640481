import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Box, FormControl, Grid, IconButton, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from 'react-redux';
import { AppReduxStore } from 'admin/store/reducerTypes';
import { IFormInput } from "admin/views/viewsTypes";
import { savePatientForm } from 'admin/store/actions/configuration';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import DynamicFields from './DynamicFields/DynamicFields';
import Instructions from './Instructions/Instructions';
import Questions from './Questions/Questions';
import AdditionalQuestions from './AdditionalQuestions/AdditionalQuestions';
import {ReactComponent as Info} from "icon-library/SVG/Info-Light_Circle.svg";
import { getFormConfigurable, getFormConfigurableString } from '../../utils';
import { createSvgIcon } from "utils";

type Props = {
  currentForm: any;
};

interface IInputProps {
  disableUnderline?: boolean,
  disabled: boolean,
  readOnly: boolean
};

const Overview: React.FC<Props> = ({currentForm}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const overviewRef = useRef<any>()
  const InfoIcon = createSvgIcon(Info);
  const { configuration } = useSelector((state: AppReduxStore) => state);
  const formConfigurableType: string = getFormConfigurableString(currentForm);
  const isFormReadOnly: boolean = configuration && configuration.isFormReadOnly;
  const isTextFieldDisabled: boolean = (isFormReadOnly || (formConfigurableType !== 'Configurable'));
  const textFieldVariant: string = isTextFieldDisabled ? 'standard' as 'standard' : 'outlined' as 'outlined';
  const inputProps: IInputProps = isTextFieldDisabled
  ? { disableUnderline: true, disabled: isTextFieldDisabled, readOnly: isTextFieldDisabled }
  : { disabled: isTextFieldDisabled, readOnly: isTextFieldDisabled };

  const { control, handleSubmit, setValue } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (overviewRef && overviewRef.current) {
      if (overviewRef.current.validateFormQuestions()) {
        currentForm.description = data.description;
        currentForm.type = data.type;
        currentForm.formType = data.type;
        overviewRef.current.saveFormQuestions();
        dispatch(savePatientForm(currentForm));
      }
    }
  };

  useEffect(() => {
    if (currentForm && Object.keys(currentForm).length > 0) {
      setValue('description', currentForm.description);
      setValue('type', currentForm.formType);
    }
  }, [currentForm, setValue]);

  useEffect(() => {
    setCurrentTypeDropdownDisabled(isTextFieldDisabled);
  }, [isFormReadOnly]);

  const getConfigurabilityTooltipText = () => {
    const value = formConfigurableType;
    switch (value) {
        case "Standard":
            return intl.formatMessage({ id: "PatientFormConfig.Label.Tooltip.Configurability.Standard" });
        case "Modified.Standard":
            return intl.formatMessage({ id: "PatientFormConfig.Label.Tooltip.Configurability.Modified.Standard" });
        case "Configurable":
            return intl.formatMessage({ id: "PatientFormConfig.Label.Tooltip.Configurability.Configurable" });
        default:
            return value;
    }
  }

  const typeTextList = (value: string) => {
    switch (value) {
        case "Clinical":
            return intl.formatMessage({ id: "PatientFormConfig.Label.Tooltip.Type.Clinical" });
        case "Non-Clinical":
            return intl.formatMessage({ id: "PatientFormConfig.Label.Tooltip.Type.NonClinical" });
        case "":
            return intl.formatMessage({ id: "PatientFormConfig.Label.Tooltip.Type.SelectOne" });
        default:
            return value;
    }
  }
  const [currentType, setCurrentType] = useState<string>(currentForm.formType);
  const [currentTypeTooltip, setCurrentTypeTooltip] = useState<string>(typeTextList(currentForm.formType));
  const [currentTypeDropdownDisabled, setCurrentTypeDropdownDisabled] = useState<boolean>(isTextFieldDisabled);

  const handleOnChange = (event:any) => {
    const value = event.target.value;
    setValue('type', value);
    setCurrentType(value);
    setCurrentTypeTooltip(typeTextList(value));
    return value;
  };

  return (
    <div className={classes.root}>
      <Box mb={2}>
        <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" alignItems="top" my={1}>
                <Box alignSelf="center">
                  <label className={classes.label} htmlFor={"form-overview-description-txtField"}>
                    {intl.formatMessage({ id: "PatientFormConfig.Label.Description" })}
                  </label>
                </Box>
                <Box flexGrow={1} ml={2}>
                  <Controller
                    name="description"
                    defaultValue=""
                    control={control}
                    render={({ field }) => <TextField variant={textFieldVariant as any} id={"form-overview-description-txtField"} minRows={1} maxRows={2} multiline fullWidth margin='dense' InputProps={inputProps} {...field} placeholder={intl.formatMessage({ id: "PatientFormConfig.Placeholder.Description" })} />}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="row" my={1}>
                <Box alignSelf="center" pr={7}>
                  <label className={classes.label} htmlFor={"form-overview-formType-txtField"}>
                    {intl.formatMessage({ id: "PatientFormConfig.Label.Type" })}
                  </label>
                </Box>
                <Box alignSelf="center" mx={2}>
                  {(isTextFieldDisabled || (formConfigurableType === 'Configurable')) ? currentType : (<Controller
                    name="type"
                    defaultValue=""
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <Select displayEmpty fullWidth
                          defaultValue={currentForm.formType}
                          disabled={currentTypeDropdownDisabled}
                          id={"form-overview-formType-txtField"}
                          onChange={(event: any) => { onChange(handleOnChange(event)); }}
                          value={value}
                          variant={"outlined"} >
                          <MenuItem value="">{intl.formatMessage({ id: "PatientFormConfig.Label.Tooltip.Type.SelectOne" })}</MenuItem>
                          <MenuItem value="Clinical">{intl.formatMessage({ id: "PatientFormConfig.Label.Type.Clinical" })}</MenuItem>
                          <MenuItem value="Non-Clinical">{intl.formatMessage({ id: "PatientFormConfig.Label.Type.NonClinical" })}</MenuItem>
                        </Select>
                      </FormControl>)}
                  />)}
                </Box>
                <Box>
                  <Tooltip arrow={true} title={currentTypeTooltip} >
                    <IconButton color="primary" size="large">
                      <InfoIcon fill={"#0F7BBD"} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="row" my={1}>
                <Box alignSelf="center">
                  <label className={classes.label}>
                    {intl.formatMessage({ id: "PatientFormConfig.Label.Configurability" })}
                  </label>
                </Box>
                <Box alignSelf="center" ml={4}>
                  {getFormConfigurable(currentForm)}
                </Box>
                <Box alignSelf="center" ml={4}>
                  <Tooltip arrow={true} title={getConfigurabilityTooltipText()} >
                    <IconButton color="primary" size="large">
                      <InfoIcon fill={"#0F7BBD"} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Grid container>
        <Grid item xs={9} style={{ background: '#FFF', border: '1px solid #D3D3D6' }}>
          <Box p={2}>
            <DynamicFields></DynamicFields>
            {currentForm && currentForm.questions &&
              <>
                <Questions questions={currentForm.questions}></Questions>
                <AdditionalQuestions ref={overviewRef} readOnlyState={isFormReadOnly} currentForm={currentForm}></AdditionalQuestions>
              </>
            }
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box p={2}>
            <Instructions></Instructions>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Overview
