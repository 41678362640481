import React, { useState } from "react"
import { useStyles } from "./styles"
import Header from "components/RemoteMonitoring/header/header"
import PatientCard from "components/RemoteMonitoring/patient-card/patient-card"
import { tabTypes } from "./types"
const SideNav: React.FC = () => {

    const [currentTab,updateCurrentTab] = useState<tabTypes>('review')
    const classes = useStyles()

    const handleTabs = (tabKey:tabTypes) =>{
        updateCurrentTab(tabKey)
    }

    return (
        <div className={classes.rootWrapper}>
            <Header currentTab={currentTab} updateCurrentTab={handleTabs}/>
            <div className={classes.patientList}>
            {/* <PatientListEmptyMessage /> */}
            <PatientCard />
            <PatientCard />
            <PatientCard />
            </div>
        </div>
    )
}

export default SideNav