import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    titleWrapper: {
        padding: SIZING.scale600,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: COLORS.LEARNING_CENTER_HEADER_BG,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
    },
    close: {
        cursor: 'pointer'
    },
    content: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        minHeight: "60vh",
        height: "60vh",
        padding: "0",
        overflowX: "hidden",
    },
    contentFrame: {
        background: COLORS.MODAL_BACKDROP_BG,
        height: "7200px", // Scroller is on at the modal wrapper's content box
        width: "100%",
        overflow: "hidden", // Do not allow the iframe to scroll
        [theme.breakpoints.down('md')]: {
            height: "7200px",
        },
        [theme.breakpoints.down('sm')]: {
            height: "10200px",
        },
        [theme.breakpoints.up('md')]: {
            height: "6800px",
        }
    }
}));