import { FeatureFlagNameValues } from "./types";
import { getSecurityService } from "lib/service-manager";
import { useCallback, useEffect, useState } from "react";

/** Checks that a feature switch is enabled or not.
 * @param featureFlagName feature switch name
 * @returns boolean the feature switch value
 */
export function useFeatureFlag(featureFlagName: FeatureFlagNameValues): boolean {
    return useFeatureFlags([featureFlagName]);
}

/** Checks that a set of feature switches are enabled on not.
 * @param featureFlagNames array of feature switch names
 * @returns boolean false if at least one feature switch is false, true if all feature switches are true
 */
export function useFeatureFlags(featureFlagNames: FeatureFlagNameValues[]): boolean {
    const availableFeatureFlags = useEnabledFeatureFlagNames();

    return featureFlagNames.every((flagName) => availableFeatureFlags?.includes(flagName));
}

/** Provides all enabled feature switch names.
 * @returns string[] Enabled feature switch names or empty array.
 */
export function useEnabledFeatureFlagNames(): null | string[] {
    const [enabledFeatureFlagNames, setEnabledFeatureFlagNames] = useState<null | string[]>(null);

    const onFeatureFlagChange = useCallback((featureFlagNames: string[]) => {
        setEnabledFeatureFlagNames(featureFlagNames);
    }, []);

    useEffect(() => {
        const securityService = getSecurityService();
        securityService.addFeatureFlagListener(onFeatureFlagChange);
        onFeatureFlagChange(securityService.featureFlags);
        return () => {
            securityService.removeFeatureFlagListener(onFeatureFlagChange);
        };
    }, [onFeatureFlagChange]);

    return enabledFeatureFlagNames;
}
