import { Box, Button, DialogActions, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Typography } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { ADMIN_WEB_CACHE, MODAL_TYPES } from "admin/constant";
import { setActiveModal, setConfigurePractice, updateWebAdminCacheOrganization, updateWebAdminCachePractice } from "admin/store/actions";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as TrashSvg } from "icon-library/SVG/Trash.svg";
import AddOrg from "admin/views/Organizations/AddOrg/AddOrg";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useReduxDispatch } from "admin/common/utils";
import TablePaginationActions from "admin/components/TablePaginationActions/TablePaginationActions";
const AppBannerModal = () => {
    const { admin, modalAdmin } = useSelector((state: AppReduxStore) => state);
    const { modalProps } = modalAdmin
    const { adminWebCacheOrganization, adminWebCachePractice } = admin
    const dispatch = useReduxDispatch()
    const classes = useStyles()
    const CrossIcon = createSvgIcon(CrossSvg)
    const TrashIcon = createSvgIcon(TrashSvg)
    const [isAddOrgWizardOpen, setIsAddOrgWizardOpen] = useState(false);
    const [pendingOrgTemporaryId, setPendingOrgTemporaryId] = useState(undefined as any)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        setPage(0)
        setRowsPerPage(5)
    }, [modalProps, adminWebCachePractice?.formData, adminWebCacheOrganization?.formData])
    const handlePendingOrgContinueBtnClick = (key: string) => {
        setPendingOrgTemporaryId(key)
        setIsAddOrgWizardOpen(true)
    };
    const handlePendingPracticeContinueBtnClick = (key: string, practiceData: any) => {
        if (practiceData?.tempFormData?.configurePractice) {
            dispatch(setConfigurePractice(key, practiceData?.tempFormData?.name)).then(() => {
                dispatch(setActiveModal(MODAL_TYPES.PRACTICE_CONFIGURATION))
            }).catch(() => {
            })
        }
        else {
            dispatch(setActiveModal(MODAL_TYPES.ADD_PRACTICE, { pendingPracticeTemporaryId: key }))
        }
    };
    const onClose = () => {
        dispatch(setActiveModal(""))
        setPendingOrgTemporaryId(undefined)
    }
    const handleClose = () => {
        onClose();
    };
    const closeAddOrgModal = () => {
        setIsAddOrgWizardOpen(false);
    };
    const handleDeletePendingOrgDetails = (key: string) => {
        const formData = adminWebCacheOrganization?.formData;
        delete formData[key]
        dispatch(updateWebAdminCacheOrganization(ADMIN_WEB_CACHE.ADD_ORGANIZATION_WIZARD_CACHE, formData))
    }
    const handleDeletePendingPracticeDetails = (key: string) => {
        const formData = adminWebCachePractice?.formData;
        delete formData[key]
        dispatch(setConfigurePractice(null, null))
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const Pagination = ({ data }: any) => {
        return (
            <>
                {data.length > 0 && <TablePagination className={classes.tableFooterRow}
                    rowsPerPageOptions={[5, 10, 20]}
                    classes={{ selectIcon: classes.footerIcon, select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Per Page"
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions} />
                }
            </>)
    }

    const modalClasses = {
        paper: classes.wrapper
    }
    const pendingOrgsLength = Object.keys(adminWebCacheOrganization?.formData || {}).length
    const pendingPracticeLength = Object.keys(adminWebCachePractice?.formData || {}).length
    return (<>
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.APP_BANNER_MODAL}>
            <DialogActions className={classes.dialogAction}>
                <Box className={classes.dialogHeader}>
                    <Box className={classes.dialogHeaderTop}>
                        <Typography className={classes.dialogHeaderTitle}>
                            <FormattedMessage id={"AppBannerModal.Action.Heading"} />
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <CrossIcon onClick={handleClose} className={classes.closeButtonIcon} />
                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ root: classes.dialogContentRoot, dividers: classes.dialogContentDividers }}>
                <Box className={classes.modalContent}>
                    <Box className={classes.modalContainer} >
                        <FormattedMessage id={"AppBannerModal.Content.Title"} />
                    </Box>
                    <Box style={{ padding: '60px' }}>
                        {modalProps?.orgBanner && pendingOrgsLength > 0 && <Paper className={classes.paper}>
                            <TableContainer>
                                <Table id="tablePendingEntities"
                                    stickyHeader
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="30%">Name</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Parent Org</TableCell>
                                            <TableCell>Incomplete Steps</TableCell>
                                            <TableCell width="200px">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(adminWebCacheOrganization?.formData).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((key, index) => {
                                                const orgData = adminWebCacheOrganization?.formData[key];
                                                return (<TableRow key={orgData?.tempFormData?.orgAdminFormData?.name}>
                                                    <TableCell width="30%" component="th" scope="row" className={classes.tableCellName}>
                                                        {orgData?.tempFormData?.orgInfoFormData?.organizationName}
                                                    </TableCell>
                                                    <TableCell>{orgData?.haveOrgParent ? "Suborganization" : "Organization"}</TableCell>
                                                    <TableCell>{orgData?.parentOrgName}</TableCell>
                                                    <TableCell>{["Info", "Admin", "Products"]?.slice(3 - orgData?.tempFormData?.pendingSteps)?.join(", ")}</TableCell>
                                                    <TableCell width="200px">
                                                        <Button id="btnContinue" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => handlePendingOrgContinueBtnClick(key)}>
                                                            <FormattedMessage id="AppBanner.Button.Continue" />
                                                        </Button>
                                                        <TrashIcon fill="#0F7BBD" onClick={() => handleDeletePendingOrgDetails(key)} className={classes.trashButtonIcon} size={32} />
                                                    </TableCell>
                                                </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                    <TableFooter>
                                        {
                                            Object.keys(adminWebCacheOrganization?.formData)?.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        No Records
                                                    </TableCell>
                                                </TableRow>
                                            ) : <TableRow>
                                                <Pagination data={Object.keys(adminWebCacheOrganization?.formData)} />
                                            </TableRow>
                                        }
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>}
                        {modalProps?.practiceBanner && pendingPracticeLength > 0 && <Paper className={classes.paper}>
                            <TableContainer>
                                <Table id="tablePendingEntities"
                                    stickyHeader
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="30%">Name</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Parent Org</TableCell>
                                            <TableCell>Incomplete Steps</TableCell>
                                            <TableCell width="200px">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(adminWebCachePractice?.formData).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((key, index) => {
                                                const practiceData = adminWebCachePractice?.formData[key];
                                                // {map(adminWebCachePractice?.formData, (practiceData: any, key: string) => (
                                                return (<TableRow key={index}>
                                                    <TableCell width="30%" component="th" scope="row" className={classes.tableCellName}>
                                                        {practiceData?.tempFormData?.configurePractice ? practiceData?.tempFormData?.name : (practiceData?.tempFormData?.practiceInfoFormData?.name)}
                                                    </TableCell>
                                                    <TableCell>{"Practice"}</TableCell>
                                                    <TableCell>{practiceData?.parentOrgName}</TableCell>
                                                    <TableCell>{practiceData?.tempFormData?.configurePractice ? "Configure Practice" : ["Info", "Admin", "Products"]?.slice(3 - practiceData?.tempFormData?.pendingSteps)?.join(", ")}</TableCell>
                                                    <TableCell width="200px">
                                                        <Button id="btnContinue" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => handlePendingPracticeContinueBtnClick(key, practiceData)}>
                                                            <FormattedMessage id="AppBanner.Button.Continue" />
                                                        </Button>
                                                        <TrashIcon fill="#0F7BBD" onClick={() => handleDeletePendingPracticeDetails(key)} className={classes.trashButtonIcon} size={32} />
                                                    </TableCell>
                                                </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                    <TableFooter>
                                        {
                                            Object.keys(adminWebCachePractice?.formData)?.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        No Records
                                                    </TableCell>
                                                </TableRow>
                                            ) : <TableRow>
                                                <Pagination data={Object.keys(adminWebCachePractice?.formData)} />
                                            </TableRow>
                                        }
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Paper>}
                    </Box>
                </Box>
            </DialogContent>
        </Modal>
        {
            isAddOrgWizardOpen &&
            <AddOrg isAddOrgWizardOpen={isAddOrgWizardOpen} closeAddOrgModal={closeAddOrgModal} pendingOrgTemporaryId={pendingOrgTemporaryId} />
        }
    </>)
}
export default AppBannerModal