import { debounce } from "common/utils/function-utils";
import { useCallback, useEffect, useRef } from "react";

export const useDebounce = (functionToDebounce: Function, delay: number) => {
    const functionRef = useRef(debounce(functionToDebounce, delay));
    useEffect(() => {
        functionRef.current = debounce(functionToDebounce, delay);
        return () => {
            functionRef.current.cancel();
        };
    }, [functionToDebounce, delay]);
    const fn = useCallback((...args) => {
        functionRef.current(...args);
    }, []);
    return fn;
};
