import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import React from "react";

type OnClick = {
    onClick: (event: React.ChangeEvent<unknown>) => void;
}

const RemovePatientDetails: React.FC<OnClick> = ({ onClick }) => {
    const classes = useStyles();

    return (
        <div className={classes.removePatient} onClick={onClick}>
            <FormattedMessage id="EConsult.newRequestModal.patient.removeDetails"/>
        </div>
    );
}

export default RemovePatientDetails;