import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
    Box, Typography,Paper, 
    DialogContent, Grid
} from "@mui/material";
import { useStyles } from "./styles";
import { ReactComponent as CirclesHexagonGrid } from "icon-library/SVG/Circles_Hexagon-Grid.svg";
import { createSvgIcon } from "utils";
import WaitingRoomAddEdit from "../../WaitingRoom/WaitingRoomAddEdit";
const CirclesHexagonGridIcon = createSvgIcon(CirclesHexagonGrid);

const ConfigureWaitingRoom = forwardRef((props, ref) => {
    const intl = useIntl();
    const classes = useStyles();
    const editWaitingRoom = useRef<any>();

    useImperativeHandle(
        ref,
        () => ({
            saveAction(e: any) {
                return editWaitingRoom.current.submitForm(e);
            },
            saveAndContinueLaterAction(index: number) {
                return editWaitingRoom.current.saveAndContinueLater(index);
            }
        }),
    )
    return (
        <>

            <Grid item className={classes.productsPageHeader}>
                <Typography className={classes.pageHeaderText}>
                    <CirclesHexagonGridIcon fill="#0F7BBD" className={classes.productsIcon} />
                    <FormattedMessage id="Practice.Configuration.Products.Heading" />
                </Typography>
                <Typography className={classes.pageSubHeaderText}>
                    <FormattedMessage id={"Practice.Configuration.Products.SubHeading"} />
                </Typography>
            </Grid>

            <DialogContent className={classes.contentDialog}>
                <Box mb={1} className={classes.formGpHeader}>
                    {intl.formatMessage({ id: "VisitTypes.Text.Waiting.VisitTypes" })}
                </Box>
                <Box mb={1} className={classes.formGpHeader}>
                    {intl.formatMessage({ id: "VisitTypes.subText.Waiting.VisitTypes" })}
                </Box>
                <Box mb={1} className={classes.formGpSubHeader}>
                    {intl.formatMessage({ id: "VisitTypes.Text.Waiting.VisitTypeId" })}
                </Box>

                <Paper className={classes.waitingRoomModal}>
                    <WaitingRoomAddEdit ref={editWaitingRoom} />
                </Paper>
            </DialogContent>
        </>
    );
})

export default ConfigureWaitingRoom