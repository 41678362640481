import { InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";
import { AccountInfo, AuthenticationResult } from "@azure/msal-common";
import { msalInstance } from "../../index";
import { b2cPolicies, loginRequest } from "./authConfig";

export const getToken = async (): Promise<AuthenticationResult | undefined> => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
        const silentRequest: SilentRequest = {
            scopes: [],
            account: msalInstance.getActiveAccount() || accounts[0],
        };

        try {
            return await msalInstance.acquireTokenSilent(silentRequest);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    await msalInstance.acquireTokenRedirect(loginRequest);
                } catch (error) {
                    return undefined;
                }
            } else {
                return undefined;
            }
        }
    }
};

export const getIdToken = async (): Promise<string | undefined> => {
    return await getToken().then((response: AuthenticationResult | undefined) => {
        return response?.idToken;
    });
};

export const getUserAccount = async (): Promise<AccountInfo | null | undefined> => {
    return await getToken().then((response: AuthenticationResult | undefined) => {
        if (response) {
            const { account } = response;
            const userAccount: AccountInfo | null = account;
            return userAccount;
        }
    });
};

export const resetPassword = async (): Promise<void> => {
    const resetPasswordRequest = {
        authority: b2cPolicies.authorities.forgotPassword.authority,
        scopes: [],
    };
    msalInstance.loginPopup(resetPasswordRequest).catch(() => {
        ;
    });
}