import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => {
    return {
        rootWrapper:{
            width:"100%",
            display:"flex !important"
        }
    }
})
