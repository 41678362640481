import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        detailsHeader: {
            height: SIZING.PATIENT_SUMMARY_HEADER_HEIGHT,
            padding: `${SIZING.scale400} ${SIZING.scale700} ${SIZING.scale400} ${SIZING.scale400}`,
            background: COLORS.SIDE_NAV_HEADER_BG,
            boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
        },
}));