import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { SIZING, COLORS} from "theme";

export const useStyles = makeStyles((theme: Theme) => ({
        root:{
            height:'100vh',
            padding:SIZING.scale1200,
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            overflowY:'scroll'
        },
        doctorName:{
            fontSize: SIZING.scale800,
            color: COLORS.LBLUE,
            fontWeight: 300
        },
        desc:{
            color: COLORS.DBLACK,
            fontSize: SIZING.scale500,
            fontWeight: 300,
            marginTop: SIZING.scale800,
            lineHeight: SIZING.scale800,
        },
        cardWrapper:{
            width:({isPCRStatus}:any)=>isPCRStatus?'100%':'80%',
            //@ts-ignore   
            [theme.breakpoints.up('xl')]: {
                width:'80%'
            },
            margin:'0 auto',
            marginTop: SIZING.scale1600,
            flex:1
        }
}));