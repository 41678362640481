import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pageHeader: {
        margin: '10px'
    },
    pageHeaderText: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948',
    },
    pageSubHeaderText: {
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#565662'
    },
    sectionHeader: {
        margin: '20px 10px 0px'
    },
    sectionHeaderText: {
        fontSize: '22px'
    },
    sectionHeader2: {
        margin: '35px 10px 0px'
    },
    sectionFieldRow: {
        marginLeft: '15px',
        marginTop: "10px"
    },
    marginRight10: {
        marginRight: "10px"
    },
    labelCtrl: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: 600,
        lineHeight: "26px"
    },
    input: {
        width: '100%',
        '& input': {
            borderRadius: 4,
            border: '1px solid #909098',
            padding: '8px 12px',
            background: '#FFFFFF',
            boxSizing: 'border-box',
            font: 'inherit',
            color: '#0B5D8F',
            width: '100%'
        }
    },
    listbox: {
        width: 200,
        margin: 0,
        padding: 0,
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: 'white',
        overflow: 'auto',
        maxHeight: 200,
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#4a8df6',
            color: 'white',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#2977f5',
            color: 'white',
        },
    },
    userExistsMsg: {
        textAlign: "left",
        fontSize: "14px",
        marginLeft: "15px"
    },
    administratorIcon: {
        fontSize: '42px',
        verticalAlign: 'middle',
        marginRight: '12px'
    },
    btnMakemeAdministrator: {
        marginLeft: '16px',
        height: '30px',
        '& .MuiButton-label': {
            fontSize: '16px',
        }
    }
}));