import { Box, Grid } from "@mui/material";
import { validateEmail, validateNoSpecialCharacters, validatePostalCode, validateRequired } from 'admin/common/utils';
import { PhoneMask } from 'admin/components/Controls/InputMasked';
import { US_STATES } from 'admin/constant';
import { editLocation, fetchLocations } from "admin/store/actions";
import { showNotification } from 'admin/store/actions/snackbar';
import { AppReduxStore } from "admin/store/reducerTypes";
import React, { forwardRef, useImperativeHandle } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../Controls/Controls";
import { Form, useForm } from '../../useForm';
import { useStyles } from "./styles";

const LocationEditForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const intl = useIntl()
    const { location } = useSelector((state: AppReduxStore) => state)
    const { sLocationA } = location
    const dispatch = useDispatch()
    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            }
        }),
    )
    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('name' in fieldValues) {
            err.name = validateRequired(fieldValues.name, intl)
            if (err.name === "") err.name = validateNoSpecialCharacters(fieldValues.name, intl)
        }

        if ('ehrLocationId' in fieldValues) {
            err.ehrLocationId = validateRequired(fieldValues.ehrLocationId, intl)
            if (err.ehrLocationId === "") err.ehrLocationId = validateNoSpecialCharacters(fieldValues.ehrLocationId, intl)
        }
        if ('address1' in fieldValues) {
            err.address1 = validateRequired(fieldValues.address1, intl)
            if (err.address1 === "") err.address1 = validateNoSpecialCharacters(fieldValues.address1, intl)
        }
        if ('address2' in fieldValues) {
            err.address2 = validateNoSpecialCharacters(fieldValues.address2, intl)
        }
        if ('city' in fieldValues) {
            err.city = validateRequired(fieldValues.city, intl)
            if (err.city === "") err.city = validateNoSpecialCharacters(fieldValues.city, intl)
        }

        if ('state' in fieldValues) {
            err.state = validateRequired(fieldValues.state, intl)
            if (err.state === "") err.state = validateNoSpecialCharacters(fieldValues.state, intl)
        }
        if ('postalCode' in fieldValues) {
            err.postalCode = validateRequired(fieldValues.postalCode, intl)
            if (err.postalCode === "") err.postalCode = validatePostalCode(fieldValues.postalCode, intl)
        }
        if ('email' in fieldValues) {
            err.email = ""
            if (err.email === "") err.email = validateEmail(fieldValues.email, intl)
        }

        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(sLocationA, false, validate);
    const handleSubmit = (e: any) => {

        e.preventDefault()
        if (validate()) {
            dispatch(editLocation(values))
            dispatch(fetchLocations())
            resetForm()
            return true
        }
        dispatch(showNotification("Invalid", "error", ""))
        return false
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Box mb={1} className={classes.formGpHeader}>
                {intl.formatMessage({ id: "EditLocationForm.Heading.LocationInfo" })}
            </Box>
            <Grid container>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="name"
                            label="Location Name *"
                            placeholder="Enter name here"
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="ehrLocationId"
                            label="EHR Location ID *"
                            value={values.ehrLocationId}
                            onChange={handleInputChange}
                            error={errors.ehrLocationId}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '32px' }} spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="phone"
                            label="Phone Number"
                            value={values.phone}
                            onChange={handleInputChange}
                            error={errors.phone}
                            inputComponent={PhoneMask}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="fax"
                            label="Fax Number"
                            value={values.fax}
                            onChange={handleInputChange}
                            error={errors.fax}
                            inputComponent={PhoneMask}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '32px' }} spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="address1"
                            label="Address 1 *"
                            value={values.address1}
                            onChange={handleInputChange}
                            error={errors.address1}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="address2"
                            label="Address 2"
                            placeholder="Additional"
                            value={values.address2}
                            onChange={handleInputChange}
                            error={errors.address2}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controls.Input
                            name="city"
                            label="City *"
                            placeholder="City"
                            value={values.city}
                            onChange={handleInputChange}
                            error={errors.city}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controls.Select
                            name="state"
                            label="State *"
                            placeholder="Select"
                            value={values.state}
                            onChange={handleInputChange}
                            options={US_STATES}
                            error={errors.state}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controls.Input
                            name="postalCode"
                            label="Zip Code *"
                            value={values.postalCode}
                            onChange={handleInputChange}
                            error={errors.postalCode}
                            maxLength={10}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    )
})
export default LocationEditForm