import { ValueOf } from "lib/util-types";

export const FetchStates = {
    NOT_SENT: "not_sent",
    SUCCESS: "success",
    FAILED: "failed",
    LOADING: "loading",
} as const;

export type FetchState = ValueOf<typeof FetchStates>;

export const FetchStateUtils = {
    hasResult: (fetchState: FetchState): boolean =>
        ([FetchStates.SUCCESS, FetchStates.FAILED] as FetchState[]).includes(fetchState),
};
