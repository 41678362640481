import { mapToCareTeamDetails } from "./mappers";
import { CareTeamDetails } from "../types";
import { HttpMethod } from "lib/api/types";
import { useApi } from "lib/api/api-service-hooks";
import { useEffect } from "react";

export type useCareTeamDetailsArgs = {
    id: string;
};

export const useCareTeamDetails = ({ id }: useCareTeamDetailsArgs) => {
    const { content, fetchInfo, request } = useApi<{ id: string }, CareTeamDetails | null>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: null,
        url: `/provider/v1/careteam/teams/:id`,
        method: HttpMethod.GET,
        responseTransformer: (response) => (response ? mapToCareTeamDetails(response) : response),
    });

    useEffect(() => {
        request({
            id,
        });
    }, [id, request]);
    return {
        careTeam: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};
