import {
    TextField, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip
} from "@mui/material";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./Licenses/styles";
import { map } from "underscore";
import Controls from "admin/components/Controls/Controls";
import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { deleteOrganizationProductLicense, upsertOrganizationProductLicense } from "admin/store/actions/license";
import { FormattedMessage, useIntl } from "react-intl";
import { Permissions } from "types";
import { ReactComponent as WarningIcon } from "admin/assets/common/ExclamationWarning.svg";
import { showNotification } from "admin/store/actions/snackbar";

interface Data {
    organizationProductId: string
    productId: string
    name: string
    description: string
    organizationId: string
    licenseCount: number
    usedLicenseCount: number
    availableLicenseCount: number
    newTotal: any,
    originalTotal: any,
    active: boolean,
    disabledSwitch: boolean,
    lessThanUsedCount: boolean,
    parentId: any
}

const OrgProductsListing = forwardRef((props, ref) => {
    const { auth, license, organization } = useSelector((state: AppReduxStore) => state)
    const { sOrgLicenses, allProducts } = license
    const [products, setProducts] = useState([] as any)

    const classes = useStyles()
    const dispatch = useDispatch()
    const intl = useIntl()

    useEffect(() => {
        if (allProducts || sOrgLicenses) {
            setProducts(allProducts && map([...allProducts], (product, key) => {
                const p = { ...product };
                const org = sOrgLicenses && sOrgLicenses.find((o: any) => o.product.id === p.id);
                p["active"] = org ? true : false
                p["organizationProductId"] = org?.id
                p["productId"] = p?.id
                p["organizationId"] = org ? org.organizationId : organization?.sOrganization?.id
                p["licenseCount"] = (p?.unlimited) ? "Unlimited" : (org?.licenseCount)
                p["usedLicenseCount"] = (p?.unlimited) ? "Unlimited" : (org?.usedLicenseCount)
                p["availableLicenseCount"] = (p?.unlimited) ? "Unlimited" : (org?.availableLicenseCount)
                p["originalTotal"] = (p?.unlimited) ? "Unlimited" : (p.licenseCount)
                p["newTotal"] = (p?.unlimited) ? "Unlimited" : (p.licenseCount)
                p["lessThanUsedCount"] = false
                p["disabledSwitch"] = false
                return p
            }))
        }
    }, [allProducts, sOrgLicenses, organization?.sOrganization?.id])

    useImperativeHandle(
        ref,
        () => ({
            saveProducts(e: any) {
                return updateProducts();
            }
        }),
    );
    const updateProducts = () => {
        if (!validateProducts(products, true)) {
            return
        }
        const organizationLicenses = [];
        for (let i = 0; i < products.length; i++) {
            if (products[i]["active"]) {
                //if ((products[i]["originalTotal"] !== products[i]["newTotal"]) && (products[i]?.unlimited || !products[i]["lessThanUsedCount"]))
                organizationLicenses.push({ newTotalLicenseCount: products[i].newTotal === "Unlimited" ? null : products[i].newTotal, organizationId: products[i].organizationId, organizationProductId: products[i].organizationProductId, productId: products[i].productId })
            } else {
                if (products[i].organizationProductId) {
                    dispatch(deleteOrganizationProductLicense(products[i].organizationProductId))
                }
            }
        }
        if (organizationLicenses.length > 0) {
            dispatch(upsertOrganizationProductLicense(organizationLicenses))
        }
        return false
    }
    const onNewTotalChange = (newTotal: any, row: any) => {
        const productsCopy = [...products];
        for (let i = 0; i < productsCopy.length; i++) {
            if (productsCopy[i].productId === row.productId) {
                productsCopy[i]["newTotal"] = newTotal
                productsCopy[i]["lessThanUsedCount"] = false
                productsCopy[i]["active"] = parseInt(newTotal) > 0
                if (row.newTotal !== 'Unlimited' && row.usedLicenseCount && row.usedLicenseCount > 0 && parseInt(newTotal) < row.usedLicenseCount) {
                    productsCopy[i]["lessThanUsedCount"] = true
                }
                break
            }
        }
        setProducts(productsCopy)
        validateProducts(productsCopy, false)
    }
    const handleToggleSwitchChange = (checked: boolean, row: any) => {
        const productsCopy = [...products];
        for (let i = 0; i < productsCopy.length; i++) {
            if (productsCopy[i].productId === row.productId) {
                productsCopy[i]["active"] = checked
                break
            }
        }
        setProducts(productsCopy)
        validateProducts(productsCopy, false)
    }
    const validateProducts = (updatedProducts: Data[], isSaveAction: boolean): boolean => {
        let validProducts = true
        const coreProduct = updatedProducts && (updatedProducts.find((p: any) => p.code === "CORE") as Data);
        const digitalFrontDoorProduct =
            updatedProducts && (updatedProducts.find((p: any) => p.code === "FRONT_DOOR") as Data);
        const eTranscriptionProduct =
            updatedProducts && (updatedProducts.find((p: any) => p.code === Permissions.ETRANSCRIPT) as Data);
        const careTeamProduct = updatedProducts && (updatedProducts.find((p: any) => p.code === "CARE_TEAM") as Data);
        // KEPLER-954 License Rules for Org/Practice
        // If Core Platform if not assigned, then Digital Front Door, Care Team Communications, and eTranscription are Disabled
        if (coreProduct && !coreProduct.active) {
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.active = false
                digitalFrontDoorProduct.disabledSwitch = true
            }
            if (careTeamProduct) {
                careTeamProduct.active = false
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.active = false
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // When Core Platform is assigned, then Digital Front Door is also enabled and assigned
        if (coreProduct && coreProduct.active) {
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.active = true
                digitalFrontDoorProduct.disabledSwitch = false
            }

        }
        // When Core Platform is assigned, then Care Team Communications and eTranscription become enabled, but NOT assigned
        if (coreProduct && coreProduct.active) {
            if (careTeamProduct) {
                careTeamProduct.disabledSwitch = false
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.disabledSwitch = false
            }

        }
        // If either Core Platform OR Digital Front Door are unassigned, 
        // then Care Team Communications and eTranscriptions become unassigned, 0 licenses, and disabled
        if ((coreProduct && !coreProduct.active) || (digitalFrontDoorProduct && !digitalFrontDoorProduct.active)) {
            if (careTeamProduct) {
                careTeamProduct["active"] = false
                careTeamProduct["newTotal"] = 0
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct["active"] = false
                eTranscriptionProduct["newTotal"] = 0
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // If Core Platform is enabled, it must have a license count of greater than 0
        if (isSaveAction && coreProduct && coreProduct.active && coreProduct.newTotal !== "Unlimited" && parseInt(coreProduct.newTotal ? coreProduct.newTotal.toString() : "0") === 0) {
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "OrgProductsEdit.CoreProductCountGreaterThanZero" })));
            validProducts = false
        }
        setProducts(updatedProducts)
        return validProducts
    }
    const isCurrentProfileOrg = auth.currentProfileRoleJoinInfo?.organization?.id === organization?.sOrganization?.id
    return (<>
        <Box>
            <Box className={classes.productsHeading}>
                <FormattedMessage id="OrgProducts.Products" />
            </Box>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell key={"active"} align={'left'} padding="normal">
                                    {'Add'}
                                </TableCell>
                                <TableCell key={"name"} align={'left'} padding="normal">
                                    {'Product Catalog'}
                                </TableCell>
                                <TableCell key={"availableLicenseCount"} align={'left'} padding="normal">
                                    {'Available to Add'}
                                </TableCell>
                                <TableCell key={"usedLicenseCount"} align={'left'} padding="normal">
                                    {'Used Licenses'}
                                </TableCell>
                                <TableCell
                                    key={"totalLicences"}
                                    align={'left'}
                                    padding="normal">
                                    {'Total Licenses'}
                                </TableCell>
                                <TableCell
                                    key={"action"}
                                    align={'right'}
                                    padding="normal">
                                    {'Total Licenses'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products && products.map((row: Data, index: number) => {
                                return (
                                    <TableRow hover key={index} >
                                        <TableCell style={{ paddingLeft: (row.parentId ? "60px" : "") }}>
                                            <Box>
                                                <Controls.ToggleSwitch
                                                    label={""}
                                                    checked={row.active}
                                                    onChange={(e: any) => { handleToggleSwitchChange(e.target.checked, row) }}
                                                    value={""}
                                                    disabled={row.disabledSwitch ||
                                                        (isCurrentProfileOrg)}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.name}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.availableLicenseCount ?? 0}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.usedLicenseCount ?? 0}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.licenseCount}</Box>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            {row.lessThanUsedCount && row.lessThanUsedCount &&
                                                <Tooltip arrow title={intl.formatMessage({ id: "OrgProductsEdit.LessLicenseCount" })}>
                                                    {<WarningIcon style={{ marginTop: '5px' }} />}
                                                </Tooltip>
                                            }
                                            {(row?.newTotal === "Unlimited") ?
                                                <TextField style={{ width: "100px" }}
                                                    variant="outlined"
                                                    name="productCount"
                                                    value={row?.newTotal}
                                                    type="text"
                                                    disabled={isCurrentProfileOrg}
                                                /> :
                                                <TextField style={{ width: "80px" }}
                                                    variant="outlined"
                                                    name="productCount"
                                                    onChange={(e: any) => { onNewTotalChange(e.target.value, row) }}
                                                    value={row?.newTotal}
                                                    type="number"
                                                    disabled={isCurrentProfileOrg}
                                                    inputProps={{
                                                        autocomplete: "",
                                                        min: 0
                                                    }}
                                                />
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    </>
    )
})
export default OrgProductsListing