export const TableList = "TableList";
export const TableListAlternated = `${TableList}--alternated`;
export const TableListBig = `${TableList}--big`;
export const TableListDefault = `${TableList}--default`;
export const TableListItem = `${TableList}Item`;
export const TableListItemBigHorizontalPadding = `${TableListItem}--bigHorizontalMargin`;
export const TableListItemCheckbox = `${TableListItem}__Checkbox`;
export const TableListItemContextMenu = `${TableListItem}__ContextMenu`;
export const TableListItemContextMenuCell = `${TableListItem}__ContextMenuCell`;
export const TableListItemSelected = `${TableListItem}--selected`;
export const TableListItemTag = `${TableListItem}__Tag`;
export const TableListItemTDExpanded = `${TableListItem}__TD--expanded`;
export const TableListPersonItem = `${TableList}PersonItem`;
export const TableListPersonItemAvatar = `${TableListPersonItem}__Avatar`;
export const TableListPersonItemAvatarCell = `${TableListPersonItem}__AvatarCell`;
export const TableListPersonItemInfo = `${TableListPersonItem}__Info`;
export const TableListPersonItemLastUpdate = `${TableListPersonItem}__LastUpdate`;
export const TableListPersonItemName = `${TableListPersonItem}__Name`;
export const TableListPersonItemNameInfoCell = `${TableListPersonItem}__NameInfoCell`;
export const TableListPersonItemNameInfoCellClickable = `${TableListPersonItem}__NameInfoCell--clickable`;
export const TableListPersonItemRole = `${TableListPersonItem}__Role`;
export const TableListPersonItemSpecialtiesCell = `${TableListPersonItem}__SpecialtiesCell`;
