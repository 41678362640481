import { CareTeam, CareTeamState, SelectedPatient, SelectedProvider } from "../types";
import { Dispatch, SetStateAction, useState } from "react";
import useCurrentUser from "common/utils/use-current-user";
import { mapCurrentUserToSelectedProvider } from "./mappers";
import { UserState } from "store/reducerTypes";

type CareTeamHookReturnObject = {
    setName: (name: string) => void;
    setSelectedPatients: (selectedPatients: SelectedPatient[]) => void;
    setSelectedProviders: Dispatch<SetStateAction<SelectedProvider[]>>;
    careTeamState: CareTeamState;
};

const getInitialSelectedProviders = (currentUser: UserState) => {
    const primary = mapCurrentUserToSelectedProvider(currentUser);
    if (primary) {
        primary.primary = true;
        primary.admin = true;
        return [primary];
    }
    return [];
};

const useCareTeamState = (careTeamData: CareTeam = {} as CareTeam): CareTeamHookReturnObject => {
    const currentUser = useCurrentUser();
    const [teamName, setTeamName] = useState(careTeamData?.name ?? "");
    const [selectedPatients, setSelectedPatients] = useState<SelectedPatient[]>([]); //careTeamData?.patients ??
    const [selectedProviders, setSelectedProviders] = useState<SelectedProvider[]>(() =>
        getInitialSelectedProviders(currentUser)
    );
    const [version] = useState(careTeamData?.version ?? 0);

    return {
        setName: setTeamName,
        setSelectedPatients,
        setSelectedProviders,
        careTeamState: {
            name: teamName,
            selectedProviders,
            selectedPatients,
            version,
        },
    };
};

export default useCareTeamState;
