import React, { useState, useEffect } from "react"
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import { FormattedMessage } from "react-intl";
import { LateLabel } from "./types"
import {COLORS} from "theme";
import moment from "moment"
const LateStatus = ({ date, status }: LateLabel) => {
    const inCall =  status === 'INCALL' ? true : false
    const classes = useStyles()
    const [isLate, updateIsLate] = useState(false)
    const isValidStatus = status === 'WAITINGROOM' || status === 'INCALL'
    useEffect(() => {
        let timeInterval: any = null
        if (isValidStatus && !isLate) {
            timeInterval = setInterval(() => {
                updateIsLate(moment.utc(date).local().isBefore(moment()))
            }, 1000);
        } else {
            clearInterval(timeInterval)
        }
        return () => {
            clearInterval(timeInterval)
        }
    }, [date, status, isLate])
 
    if (!isValidStatus || !isLate) return null

    return (
        <CustomTypography className={classes.root} style={{color:`${inCall?COLORS.WHITE:COLORS.PRIORITY_BG}`}}>
            <FormattedMessage id="waitingRoom.invite.lateLabel" />
        </CustomTypography>
    )
}
export default LateStatus