import Typography from '@mui/material/Typography';
import { getAppCurrentProfileId } from 'authentication/appAuth';
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as BTPMarketplace } from "icon-library/SVG/Briefcase-Light_ALT.svg";
import { ReactComponent as PracticeManagementResources } from "icon-library/SVG/Building_Hospital-Plus.svg";
import { ReactComponent as PatientCareManagement } from "icon-library/SVG/Chart-Light_Line.svg";
import { ReactComponent as RequestAccess } from "icon-library/SVG/Checkbox_Square.svg";
import { ReactComponent as HealthPlanPolicies } from "icon-library/SVG/File-Light_Blank-Multiple.svg";
import { ReactComponent as EpicEHRImplementation } from "icon-library/SVG/Laptop_EMR.svg";
import { ReactComponent as ProviderDirectory } from "icon-library/SVG/Stethoscope.svg";
import { ReactComponent as Forms } from "icon-library/SVG/Tasks-1of3.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import PageImage from '../assests/images/pep-practice-resources.jpg';
import { ClickableCard } from '../components/clickable-card';
import { useStyles } from "./styles";

export const PEPPracticeResources = ({ onSelect: handleSelection }: { onSelect?: (menuItem: string) => void }) => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueError } = useUserNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("pep.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    const config_left = [{
        name: `Practice Management Resources`,
        description: "Find the Practice Operations Manual, products, services, and other resources that can help you manage your practice.",
        action: {
            id: "practice_management_resources"
        },
        icon: PracticeManagementResources,
        enabled: true
    },
    {
        name: `Forms`,
        description: "All the forms that you need for practice management, authorization, referrals, patient care, claims, dispute resolution and more.",
        action: {
            id: "pep-forms"
        },
        icon: Forms,
        enabled: true
    },
    {
        name: `Patient Care Resources`,
        description: "Information about patient care programs, Care Management, mental health resources, reference guides, authorization lists, after-hours clinics, and more.",
        action: {
            id: "pep-patient-care-management"
        },
        icon: PatientCareManagement,
        enabled: true
    },
    {
        name: `Epic EHR and EpicLink Information and Training`,
        description: "Read about the implementation progress and latest system updates.",
        action: {
            id: "epic_ehr_implementation"
        },
        icon: EpicEHRImplementation,
        enabled: true
    }];

    const config_right = [{
        name: `BTP Marketplace`,
        description: "Learn about the new framework for enhanced services BTP has designed to improve practice operations, reduce physician burnout, create cost savings and increase the quality of care.",
        action: {
            id: "pep-btp-provider-marketplace"
        },
        icon: BTPMarketplace,
        enabled: true
    },
    {
        name: `Provider Directory - Find a Doctor`,
        description: "Find doctors by availability, location and specialty",
        action: {
            id: "provider_directory",
            href: "https://www.brownandtoland.com/find-a-doctor/",
            // href: "https://pr72-z706ghu9o7pmbnt8mo2w31n04ynopkzj.tugboatqa.com/find-a-doctor/",
            target: "_blank"
        },
        icon: ProviderDirectory,
        enabled: true
    },
    {
        name: `Health Plan Information`,
        description: "Find information about BTP contracted plans and links to important resources.",
        action: {
            id: "pep-health-plan-policies"
        },
        icon: HealthPlanPolicies,
        enabled: true
    },
    {
        name: `New Epic User Access Request`,
        description: "Submit this form to request access to the provider tools for your practice physicians and staff.",
        action: {
            id: "request_access_to_provider_tools",
            href: window.location.origin + "/newuser",
            target: "_blank"
        },
        icon: RequestAccess,
        enabled: true
    }];

    return (
        isLoading ? <Spinner /> : (
            <div id="practice_resources_row" className={classes.pep_practice_resources_row}>
                <div id="practice_resources_container" className={classes.pep_practice_resources_container}>
                    <div id="practice_resources_top" className={classes.pep_practice_resources_top}>
                        <div id="practice_resources_banner" className={classes.pep_practice_resources_banner}>
                            <Typography id="practice_resources_banner_top" className={classes.pep_practice_resources_banner_top}>
                                {`Practice Resources`}
                            </Typography>
                        </div>
                        <div style={{ width: '100%', height: '360px', marginBottom: '20px' }}>
                            <img src={PageImage} alt='Practice Resources Logo' />
                        </div>
                        <Typography id="practice_resources_description" className={classes.pep_practice_resources_description}>
                            {`Brown & Toland is your partner for successful practice management.  Search this section for the tools and reference materials you will need to efficiently manage your practice and patients.`}
                        </Typography>
                    </div>
                    <div id="practice_resources_middle" style={{ display: 'flex', marginTop: '20px' }}>
                        <div id="practice_resources_left" className={classes.pep_practice_resources_left}>
                            {config_left.map((card, index: number) => {
                                return <ClickableCard key={`clickable_card_left_${index}`} config={card} onSelect={handleSelection} />
                            })}
                        </div>
                        <div id="practice_resources_right" className={classes.pep_practice_resources_right}>
                            {config_right.map((card, index: number) => {
                                return <ClickableCard key={`clickable_card_right_${index}`} config={card} onSelect={handleSelection} />
                            })}
                        </div>
                    </div>


                </div>
            </div>)
    )
}