import { ValueOf } from "types";

export const MODAL_TYPES = {
    ADMIN: {
        ADD_USER: "ADD_USER",
        EDIT_USER: "EDIT_USER",
        PROVIDER_PORTAL: "PROVIDER_PORTAL"
    },
    PROVIDER: {
        ACTIVE_ZOOM_MEETING: "ACTIVE_ZOOM_MEETING",
        CARE_TEAM_EDITOR: "CARE_TEAM_EDITOR",
        COMPOSE_NEW_MESSAGE: "COMPOSE_NEW_MESSAGE",
        CONTACT: "CONTACT",
        CONVERSATION_PARTICIPANTS: "CONVERSATION_PARTICIPANTS",
        EVISIT_PARTICIPANTS: "EVISIT_PARTICIPANTS",
        HELP_CENTER: "HELP_CENTER",
        HPI_FORM: "HPI_FORM",
        LEARNING_CENTER: "LEARNING_CENTER",
        LOGOUT_USER: "LOGOUT_USER",
        PATIENT_INFO: "PATIENT_INFO",
        PNG_INVITE: "PNG_INVITE",
        TRANSCRIBE_MEDICAL: "TRANSCRIBE_MEDICAL",
        CREATE_E_CONSULT_REQUEST: "CREATE_E_CONSULT_REQUEST",
        ECONSULT_SUBMIT_ERROR: "ECONSULT_SUBMIT_ERROR",
        RPM_ENROLL: "RPM_ENROLL",
        GENERIC_DIALOG: "GENERIC_DIALOG",
        SAVE_WAITING_ROOM_DETAILS: "SAVE_WAITING_ROOM_DETAILS",
        MESSAGE_READ_STATUS:"MESSAGE_READ_STATUS"
    },
    PATIENT: {
        PASSWORD_TIP_MODAL: "PASSWORD_TIP_MODAL",
        TOS_MODAL: "TOS_MODAL",
    }
} as const;

export const SYS_MESSAGES = {
    SYS_CALL_INCOMING: "SYS_CALL_INCOMING",
    SYS_CALL_ACCEPTED: "SYS_CALL_ACCEPTED",
    SYS_CALL_DECLINED: "SYS_CALL_DECLINED",
    SYS_CALL_JOINED: "SYS_CALL_JOINED",
    SYS_CALL_END: "SYS_CALL_END",
    SYS_EVISIT_CALL_JOINED: "SYS_EVISIT_CALL_JOINED",
    SYS_EVISIT_CALL_INCOMING: "SYS_EVISIT_CALL_INCOMING",
    SYS_EVISIT_CALL_ACCEPTED: 'SYS_EVISIT_CALL_ACCEPTED',
    SYS_NOTIFICATION: "SYS_NOTIFICATION",
    SYS_NRU_EVISIT_CALL_INCOMING: "SYS_NRU_EVISIT_CALL_INCOMING",
    SYS_NRU_EVISIT_REFRESH: "SYS_NRU_EVISIT_REFRESH",
    SYS_NRU_EVISIT_CALL_PAUSED: "SYS_NRU_EVISIT_CALL_PAUSED",
    SYS_NRU_EVISIT_CALL_BACK_TO_WAITINGROOM: "SYS_NRU_EVISIT_CALL_BACK_TO_WAITINGROOM",
    SYS_NRU_EVISIT_DROP: "SYS_NRU_EVISIT_DROP",
    SYS_NRU_EVISIT_END: "SYS_NRU_EVISIT_END",
    SYS_NRU_EVISIT_PING: "SYS_NRU_EVISIT_PING",
    SYS_NRU_EVISIT_QUICK_CHAT: "SYS_NRU_EVISIT_QUICK_CHAT"
}

export const ZOOM_IFRAME_MESSAGES = {
    INIT: "INIT",
    MEETING_JOINED: "MEETING_JOINED",
    MEETING_ERROR: "MEETING_ERROR",
    MEETING_ENDED: "MEETING_ENDED",
    MEETING_END_FOR_ALL: "MEETING_END_FOR_ALL"
}

export const REGISTRATION_STEP = {
    PROVIDER: {
        VERIFY_IDENTITY: "VERIFY_IDENTITY",
        LOG_IN_OR_CREATE: "LOG_IN_OR_CREATE",
        OTP_CONFIRMATION: "OTP_CONFIRMATION",
        CREATE_PROFILE: "CREATE_PROFILE",
        ADD_SECRET_QUESTIONS: "ADD_SECRET_QUESTIONS",
        EXISTING_USER_LOGIN: "EXISTING_USER_LOGIN"
    },
    PATIENT: {
        VERIFY_IDENTITY: "VERIFY_IDENTITY",
        ACCEPT_TOS: "ACCEPT_TOS",
        ADD_EMAIL: "ADD_EMAIL",
        OTP_CONFIRMATION_PATIENT: "OTP_CONFIRMATION_PATIENT",
        SET_PASSWORD: "SET_PASSWORD"
    },
    PROREGISTARTION: {
        VERIFY_IDENTITY: "VERIFY_IDENTITY",
        LOG_IN_OR_CREATE: "LOG_IN_OR_CREATE",
        CREATE_PROFILE: "CREATE_PROFILE",
        EXISTING_USER_LOGIN: "EXISTING_USER_LOGIN",
        ADD_SECRET_QUESTIONS: "ADD_SECRET_QUESTIONS"
    }
}

export const PASSWORD_VALIDATION_TYPES = {
    HAS_MIN_CHARACTERS: "HAS_MIN_CHARACTERS",
    HAS_NUMBER: "HAS_NUMBER",
    HAS_SYMBOL: "HAS_SYMBOL",
    HAS_UPPERCASE: "HAS_UPPERCASE",
    HAS_LOWERCASE: "HAS_LOWERCASE"
}

export const PASSWORD_VALIDATION_DESCRIPTIONS = {
    [PASSWORD_VALIDATION_TYPES.HAS_MIN_CHARACTERS]: "PasswordValidation.description.hasMinCharacters",
    [PASSWORD_VALIDATION_TYPES.HAS_NUMBER]: "PasswordValidation.description.hasNumber",
    [PASSWORD_VALIDATION_TYPES.HAS_SYMBOL]: "PasswordValidation.description.hasSymbol",
    [PASSWORD_VALIDATION_TYPES.HAS_UPPERCASE]: "PasswordValidation.description.hasUppercase",
    [PASSWORD_VALIDATION_TYPES.HAS_LOWERCASE]: "PasswordValidation.description.hasLowercase"
}

export const PASSWORD_VALIDATIONS = {
    [PASSWORD_VALIDATION_TYPES.HAS_MIN_CHARACTERS]: /^(?=.{8,})/,
    [PASSWORD_VALIDATION_TYPES.HAS_NUMBER]: /^(?=.*[0-9])/,
    [PASSWORD_VALIDATION_TYPES.HAS_SYMBOL]: /^(?=.*[@$#!%^*&()+=_-])/,
    [PASSWORD_VALIDATION_TYPES.HAS_UPPERCASE]: /^(?=.*[A-Z])/,
    [PASSWORD_VALIDATION_TYPES.HAS_LOWERCASE]: /^(?=.*[a-z])/
}

export const EMAIL_PATTERN = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

// check for mm/dd/yyyy
export const DOB_PATTERN = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;

export const VERIFICATION_TYPE = {
    SMS: "SMS",
    EMAIL: "EMAIL"
}

export const USER_TYPE = {
    ADMIN: "ADMIN",
    PATIENT: "PATIENT",
    PROVIDER: "PROVIDER",
    SUPPORT_CONTACT:"SUPPORT_CONTACT"
}

export const USER_LEVEL = {
    ALTAIS_ADMIN_1: "ALTAIS_ADMIN_1",
    ALTAIS_ADMIN_2: "ALTAIS_ADMIN_2",
    ALTAIS_ADMIN_3: "ALTAIS_ADMIN_3",
    ALTAIS_ADMIN_4: "ALTAIS_ADMIN_4",
    ORG_ADMIN_1: "ORG_ADMIN_1",
    ORG_ADMIN_2: "ORG_ADMIN_2",
    PRACTICE_ADMIN: "PRACTICE_ADMIN",
    BILLING_ADMIN: "BILLING_ADMIN",
    STAFF_1: "STAFF_1",
    STAFF_2: "STAFF_2",
    STAFF_3: "STAFF_3",
    STAFF_4: "STAFF_4"
}

export const USER_PROFILE = {
    [USER_TYPE.ADMIN]: "adminProfile",
    [USER_TYPE.PATIENT]: "patientProfile",
    [USER_TYPE.PROVIDER]: "providerProfile",
    [USER_TYPE.SUPPORT_CONTACT]: "supportContactProfile",

} as const;

export const NOTIFICATION_TYPE = {
    WAITING_ROOM: "WAITING_ROOM",
    ADT_ALERTS: "ADT_ALERTS",
    NRU_WAITING_ROOM: "NRU_WAITING_ROOM",
    NEW_ECONSULT_REQUEST: "NEW_ECONSULT_REQUEST"
} as const;

export const CONVERSATION_TYPE = {
    CARETEAM: "CARETEAM",
    ECONSULT: "ECONSULT",
    EVISIT: "EVISIT",
    PATIENT: "PATIENT",
} as const;
export type ConversationType = ValueOf<typeof CONVERSATION_TYPE>;

export const CONVERSATION_CATEGORY = {
    CARETEAM: "CARETEAM",
    ECONSULT: "ECONSULT",
    PATIENT: "PATIENT",
} as const;
export type ConversationCategory = ValueOf<typeof CONVERSATION_CATEGORY>;

export const conversationCategoryToConversationTypeMapping = {
    [CONVERSATION_TYPE.CARETEAM]: CONVERSATION_CATEGORY.CARETEAM,
    [CONVERSATION_TYPE.ECONSULT]: CONVERSATION_CATEGORY.ECONSULT,
    [CONVERSATION_TYPE.EVISIT]: CONVERSATION_CATEGORY.CARETEAM,
    [CONVERSATION_TYPE.PATIENT]: CONVERSATION_TYPE.PATIENT,
} as const;

export const USER_EXISTS_ERROR = "User with that username already exists."

export const THREAD_PAGE_SIZE = 15

export const QUESTIONNAIRE_TYPES = {
    M_CHAT: "M_CHAT",
    PHQ_2: "PHQ-2",
    PHQ_9: "PHQ-9",
    TOS: "TOS"
}

export const EVISIT_TYPE = {
    INAPP_EVISIT: 'INAPP_EVISIT',
    NRU_EVISIT: 'NRU_EVISIT'
}
export const SESSION_STORAGE_REGISTRATION_ID = "regId"
export const SESSION_STORAGE_DEFAULT_PROFILE_REDIRECT = "profileRedirectDefault"

export const CONTACT_US_CUSTOMER_TYPE = {
    INDEPENDENT_PHYSICIAN: 'Independent physician',
    PRACTICE_MANAGER: 'Practice Manager',
    IPA: 'IPA',
    MEDICAL_GROUP: 'Medical Group',
    PAYER: 'Payer',
    OTHER: 'Other'
} as const

export const EM_DASH = "—";
