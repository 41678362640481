import React from "react";
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ErrorView } from './ErrorView';

const Unauthorized = () => {
    const intl = useIntl();

    return (
        <ErrorView
            code="401"
            titleId="errorView.unauthorized.title"
            descriptionId="errorView.unauthorized.description"
        >
            <ul>
                <li><Link to="/login">{intl.formatMessage({id: 'errorView.returnToLoginPage'})}</Link></li>
            </ul>
        </ErrorView>
    );
};

export default Unauthorized