import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, SIZING, COLORS} from "theme";

export const useStyles = makeStyles(() => ({
        threadWrapper: {
            display: "flex",
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "5px 0",
        },
        content: {
            border: "1px solid transparent",
            color: COLORS.READ_THREAD_TEXT,
            cursor: "pointer",
            display: "block",
            justifyContent: "space-between",
            flexDirection: "column",
            flex: "1 1 0",
            minWidth: 0,
            padding: "1em",
            borderRadius: BORDER_RADIUS.MEDIUM,
            "&:hover, &:focus": {
                border: "1px solid #0F7BBD",
                background: COLORS.HOVER_THREAD_BG,
            },
            "&.new": {
                color: COLORS.UNREAD_THREAD_TEXT,
                "& $threadBody p":{
                    fontWeight:'900 !important'
                }
            },
            "&.new $statusBar, &.new $threadInfo": {
                display: "flex"
            }
        },
        selectedThread: {
            color: COLORS.WHITE,
            "& $content, &:hover $content": {
                background: COLORS.SELECTED_THREAD_BG,
                border: "1px solid #0F7BBD",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
                color: COLORS.WHITE,
            },
            "& $statusBar": {
                background: COLORS.WHITE
            },
            "& $countWrapper": {
                background: COLORS.WHITE,
                color: COLORS.SELECTED_THREAD_BG,
            }
        },
        countWrapper: {
            // don't delete, this className is needed to style hover via parent class "content:hover"
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: SIZING.scale300
        },
        threadInfo: {
            display: "none",
            alignItems: "center"
        },
        dateUpdated: {
            whiteSpace: "nowrap",
            marginLeft: SIZING.scale300
        },
        threadBody: {
            display: "flex",
            flexDirection: "column"
        },
        participants: {
            display: "flex",
            width: "90%",
            boxSizing: "border-box",
            "& p": {
                maxWidth: "98%",
                textAlign: "left",
                width: "98%",
            }
        },
        statusBar: {
            display: "none",
            width: SIZING.scale400,
            height: SIZING.scale400,
            borderRadius: BORDER_RADIUS.FULL,
            marginRight: SIZING.scale200,
            background: "#0F7BBD"
        }
}));