import { Box, Grid, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography, Link } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { createPatient, updateWebAdminCachePractice } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import Controls from "../../Controls/Controls";
import { Form, useForm } from '../../useForm';
import { useStyles } from "./styles";
import { createSvgIcon } from "../../../../utils";
import { showNotification } from 'admin/store/actions/snackbar';
import { PhoneMask, DateMask } from 'admin/components/Controls/InputMasked';
import { validateDate, validateEmail, validateNoSpecialCharacters, validateRequired } from 'admin/common/utils';
import { ReactComponent as UserSingle } from "icon-library/SVG/User_Single.svg";
import { ReactComponent as Users } from "icon-library/SVG/User_Users.svg";
import { ReactComponent as Shield } from "icon-library/SVG/Shield.svg";
import { ADMIN_WEB_CACHE, US_STATES } from 'admin/constant';
import moment from 'moment';
const UserIcon = createSvgIcon(UserSingle);
const UsersIcon = createSvgIcon(Users);
const ShieldIcon = createSvgIcon(Shield);
const initialFValues = {
    address: "",
    address2: "",
    cellPhoneNumber: "",
    city: "",
    contacts: [
        {
            email: "",
            firstName: "",
            lastName: "",
            mobilePhone: "",
            relationship: "",
            secondaryPhone: "",
            type: "Emergency"
        },
        {
            email: "",
            firstName: "",
            lastName: "",
            mobilePhone: "",
            relationship: "",
            secondaryPhone: "",
            type: "Family"
        }
    ],
    dateOfBirth: "",
    ehrPatientId: "",
    emailAddress: "",
    firstName: "",
    homePhone: "",
    insuranceId: "",
    insuranceMemberId: "",
    insuranceName: "",
    insuranceType: "",
    lastName: "",
    mrn: "",
    homePhoneNumber: "",
    practiceId: "",
    ssn: "",
    state: "",
    userLevelId: "",
    zipCode: ""
}

const trimFields = (values: any) => {
    values.cellPhoneNumber = values.cellPhoneNumber.replace(/\D/g, '')
    values.homePhoneNumber = values.homePhoneNumber.replace(/\D/g, '')

    return values
}

const AddPatientForm = forwardRef((props, ref) => {
    const { auth, admin } = useSelector((state: AppReduxStore) => state);
    const { adminWebCachePractice } = admin
    const [isAutoInvite, setAutoInvite] = useState(true);
    const [selectedAddPatientStepIndex, setSelectedAddPatientStepIndex] = useState(0);
    const classes = useStyles();
    const intl = useIntl()
    const dispatch = useDispatch()

    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            },
            saveAndContinueLaterAction(index: number) {
                return saveAndContinue(index)
            }
        }),

    )
    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {};
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgName: auth.currentOrg?.name,
            tempFormData: {
                name: admin.configurePracticeName,
                configurePractice: true,
                addPatientsDetails: null,
                selectedStep: index,
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }
    const handleAddPatientStepClick = (event: any, index: any) => {
        setSelectedAddPatientStepIndex(index);
    };

    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('firstName' in fieldValues) {
            err.firstName = validateRequired(fieldValues.firstName, intl)
            if (err.firstName === "") err.firstName = validateNoSpecialCharacters(fieldValues.firstName, intl)
        }
        if ('middleName' in fieldValues) {
            err.middleName = validateNoSpecialCharacters(fieldValues.middleName, intl)
        }
        if ('lastName' in fieldValues) {
            err.lastName = validateRequired(fieldValues.lastName, intl)
            if (err.lastName === "") err.lastName = validateNoSpecialCharacters(fieldValues.lastName, intl)
        }
        if ('dateOfBirth' in fieldValues) {
            err.dateOfBirth = validateRequired(fieldValues.dateOfBirth, intl)
            if (err.dateOfBirth === "") err.dateOfBirth = validateDate(fieldValues.dateOfBirth, intl)
        }
        if ('cellPhoneNumber' in fieldValues) {
            err.cellPhoneNumber = validateRequired(fieldValues.cellPhoneNumber, intl)
        }
        if ('mrn' in fieldValues) {
            err.mrn = validateRequired(fieldValues.mrn, intl)
        }
        if ('emailAddress' in fieldValues) {
            err.emailAddress = validateRequired(fieldValues.emailAddress, intl)
            if (err.emailAddress === "") err.emailAddress = validateEmail(fieldValues.emailAddress, intl)
        }
        if ('contacts[0].email' in fieldValues) {
            err['contacts[0].email'] = validateEmail(fieldValues['contacts[0].email'], intl)
        }
        if ('contacts[1].email' in fieldValues) {
            err['contacts[1].email'] = validateEmail(fieldValues['contacts[1].email'], intl)
        }

        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }

    const {
        values,
        resetValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        trimValues
    } = useForm(initialFValues, false, validate);

    const handleContactInputChange = (e: any) => {
        const { name, value } = e.target
        const fieldName = name.split('.')[1]
        if (name.includes("contacts[0]")) {
            const contacts = [...values.contacts];
            contacts[0][fieldName] = value
            resetValues({
                ...values,
                // eslint-disable-next-line no-useless-computed-key
                ["contacts"]: contacts
            })
        }
        else {
            const contacts = [...values.contacts];
            contacts[1][fieldName] = value
            resetValues({
                ...values,
                // eslint-disable-next-line no-useless-computed-key
                ["contacts"]: contacts
            })
        }

        validate({ [name]: value })
    }

    const handleSubmit = (e: any) => {
        trimValues()
        e.preventDefault()
        if (validate()) {
            const usersLevel = admin.userLevels && admin.userLevels.find((u: any) => u.type === "PATIENT")
            values["practiceId"] = auth.currentPractice?.id
            values["userLevelId"] = usersLevel?.id
            values["mrn"] = values.ehrPatientId
            dispatch(createPatient(trimFields(values)))
            resetForm()
            resetValues(initialFValues)
            return true
        }
        dispatch(showNotification("Invalid", "error", 'Please fill the required fields'))
        return false
    }

    const onAutoInviteCheck = (e: any) => {
        setAutoInvite(e.target.checked)
    }

    return (
        <Box className={classes.contentArea}>
            <Box className={classes.leftPaneWrapper}>
                <List style={{ position: 'fixed' }}>
                    <Link href="#personalInfo">
                        <ListItem
                            disableGutters
                            classes={{
                                root: classes.sideNavOption,
                                selected: classes.selected
                            }}
                            selected={selectedAddPatientStepIndex === 0}
                            onClick={(event) => handleAddPatientStepClick(event, 0)}
                        >
                            <ListItemIcon>
                                <UserIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h4" >
                                    <FormattedMessage id="Personal" />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link href="#contactInfo">
                        <ListItem
                            disableGutters
                            classes={{
                                root: classes.sideNavOption,
                                selected: classes.selected
                            }}
                            selected={selectedAddPatientStepIndex === 1}
                            onClick={(event) => handleAddPatientStepClick(event, 1)}
                        >
                            <ListItemIcon>
                                <UsersIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h4" >
                                    <FormattedMessage id="Contacts" />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link href="#insuranceInfo">
                        <ListItem
                            disableGutters
                            classes={{
                                root: classes.sideNavOption,
                                selected: classes.selected
                            }}
                            selected={selectedAddPatientStepIndex === 2}
                            onClick={(event) => handleAddPatientStepClick(event, 2)}
                        >
                            <ListItemIcon>
                                <ShieldIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h4" >
                                    <FormattedMessage id="Insurance" />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                </List>
            </Box>
            <Box className={classes.rightContent}>
                <Form onSubmit={handleSubmit}>
                    <Box className={classes.autoInviteText}>
                        <Checkbox color="primary" disableRipple disableFocusRipple checked={isAutoInvite}
                            onChange={onAutoInviteCheck} id="chkAddUserAutoInvite" />
                        {intl.formatMessage({ id: "AddPatientForm.SendAutoinvite.Text" })}
                    </Box>
                    <Box id="personalInfo" mb={1} className={classes.formGpHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.Heading.Personal" })}
                    </Box>
                    <Box mb={1} pt={2} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.SubHeading.Info" })}
                    </Box>

                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="firstName"
                                label="First Name *"
                                value={values.firstName}
                                onChange={handleInputChange}
                                error={errors.firstName}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="middleName"
                                label="Middle Name"
                                value={values.middleName}
                                onChange={handleInputChange}
                                error={errors.middleName}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="lastName"
                                label="Last Name *"
                                value={values.lastName}
                                onChange={handleInputChange}
                                error={errors.lastName}
                                maxLength={50}
                            />

                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="dateOfBirth"
                                label="Date of Birth *"
                                value={moment(values.dateOfBirth).isValid() ?
                                    moment(values.dateOfBirth).format('MM/DD/YYYY') :
                                    values.dateOfBirth}
                                onChange={handleInputChange}
                                error={errors.dateOfBirth}
                                placeholder="MM/DD/YYYY"
                                inputComponent={DateMask}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="ssn"
                                label="SSN"
                                value={values.ssn}
                                onChange={handleInputChange}
                                error={errors.ssn}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="mrn"
                                label="MRN / EHR ID *"
                                value={values.mrn}
                                onChange={handleInputChange}
                                error={errors.mrn}
                                maxLength={50}
                            />

                        </Grid>
                    </Grid>
                    <Box mt={4} mb={1} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.SubHeading.Contact" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="cellPhoneNumber"
                                label="Mobile Phone *"
                                value={values.cellPhoneNumber}
                                onChange={handleInputChange}
                                error={errors.cellPhoneNumber}
                                maxLength={50}
                                inputComponent={PhoneMask}

                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="homePhoneNumber"
                                label="Secondary Phone"
                                value={values.homePhoneNumber}
                                onChange={handleInputChange}
                                error={errors.homePhoneNumber}
                                maxLength={50}
                                inputComponent={PhoneMask}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="emailAddress"
                                label="Email *"
                                value={values.emailAddress}
                                onChange={handleInputChange}
                                error={errors.emailAddress}
                                maxLength={255}
                            />
                        </Grid>

                    </Grid>
                    <Box mt={4} mb={1} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.SubHeading.Address" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="address"
                                label="Address 1"
                                value={values.address}
                                onChange={handleInputChange}
                                error={errors.address}
                                maxLength={10}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="address2"
                                label="Address 2"
                                value={values.address2}
                                onChange={handleInputChange}
                                error={errors.address2}
                                maxLength={255}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="city"
                                label="City"
                                value={values.city}
                                onChange={handleInputChange}
                                error={errors.city}
                                maxLength={255}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Select
                                name="state"
                                label="State"
                                placeholder="Select"
                                value={values.state}
                                onChange={handleInputChange}
                                options={US_STATES}
                                error={errors.state}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="zipCode"
                                label="Zip"
                                value={values.zipCode}
                                onChange={handleInputChange}
                                error={errors.zipCode}
                                maxLength={255}
                            />
                        </Grid>
                    </Grid>
                    <Box id="contactInfo" mt={4} mb={1} className={classes.formGpHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.Heading.Contacts" })}
                    </Box>
                    <Box mb={1} pt={2} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.SubHeading.Emergency" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="contacts[0].firstName"
                                label="First Name"
                                value={values.contacts[0].firstName}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="contacts[0].lastName"
                                label="Last Name"
                                value={values.contacts[0].lastName}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="contacts[0].mobilePhone"
                                label="Mobile Phone"
                                value={values.contacts[0].mobilePhone}
                                onChange={handleContactInputChange}
                                maxLength={50}
                                inputComponent={PhoneMask}

                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[0].secondaryPhone"
                                label="Secondary Phone"
                                value={values.contacts[0].secondaryPhone}
                                onChange={handleContactInputChange}
                                maxLength={50}
                                inputComponent={PhoneMask}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[0].email"
                                label="Email"
                                value={values.contacts[0].email}
                                onChange={handleContactInputChange}
                                error={errors["contacts[0].email"]}
                                maxLength={255}
                            />
                        </Grid>
                    </Grid>
                    <Box mb={1} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.SubHeading.Family" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].firstName"
                                label="First Name"
                                value={values.contacts[1].firstName}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].lastName"
                                label="Last Name"
                                value={values.contacts[1].lastName}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].relationship"
                                label="Relationship"
                                value={values.contacts[1].relationship}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].mobilePhone"
                                label="Mobile Phone"
                                value={values.contacts[1].mobilePhone}
                                onChange={handleContactInputChange}
                                maxLength={50}
                                inputComponent={PhoneMask}

                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].secondaryPhone"
                                label="Secondary Phone"
                                value={values.contacts[1].secondaryPhone}
                                onChange={handleContactInputChange}
                                maxLength={50}
                                inputComponent={PhoneMask}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].email"
                                label="Email"
                                value={values.contacts[1].email}
                                onChange={handleContactInputChange}
                                error={errors["contacts[1].email"]}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                    <Box id="insuranceInfo" mt={4} mb={1} className={classes.formGpHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.Heading.Insurance" })}
                    </Box>
                    <Box mb={1} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "AddPatientForm.SubHeading.Info" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="insuranceName"
                                label="Plan Name"
                                value={values.insuranceName}
                                onChange={handleInputChange}
                                error={errors.insuranceName}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="insuranceType"
                                label="Plan Type"
                                value={values.insuranceType}
                                onChange={handleInputChange}
                                error={errors.insuranceType}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="insuranceId"
                                label="Plan ID"
                                value={values.insuranceId}
                                onChange={handleInputChange}
                                error={errors.insuranceId}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="insuranceMemberId"
                                label="Member ID"
                                value={values.insuranceMemberId}
                                onChange={handleInputChange}
                                error={errors.insuranceMemberId}
                                maxLength={255}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Box>
        </Box>
    )
})
export default AddPatientForm