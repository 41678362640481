import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
        providerBox: {
            flexGrow: 1,
            maxWidth: "100%",
            "& .providersRightPanel": {
                display: "flex",
            },
            "& .providersLeftPanel": {
                minWidth: "260px",
                maxWidth: "260px",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column"
            }
        },
        panel: {
            padding: SIZING.scale400,
            flexGrow: 1,
        },
        selectedProvidersBox: {
            width: "100%",
            backgroundColor: COLORS.MODAL_CONTENT_LBLUE_BG,
            overflow: "hidden",
            "& .providerListScrollContainer": {
                overflowY: "auto",
                marginRight: `-${SIZING.scale400}`,
                flexGrow: 1,
            }
        },
        circleOfContacts: {
            "& .emptyMessage": {
                paddingBottom: "70px"
            }
        },
        providersSearchBox: {
            backgroundColor: COLORS.WHITE,
            display: "flex",
            justifyContent: "flex-start",
            "& .providerListScrollContainer": {
                overflowY: "scroll",
                marginRight: `-${SIZING.scale400}`,
                flexGrow: 1
            }
        },
        footer: {
            justifyContent: "space-between",
            display: "flex",
            marginTop: SIZING.scale175
        },
        resultSortSelector: {
            alignItems: 'center',
            backgroundColor: COLORS.LGREY,
            border: '.5px solid rgba(0, 0, 0, 0.05)',
            borderRadius: SIZING.scale100,
            display: 'flex',
            fontSize: SIZING.scale250,
            padding: `0 ${SIZING.scale200}`,
            visibility: 'hidden',
            "& .label": {
                fontWeight: 'bold',
            },
            "& span": {
                marginRight: SIZING.scale200
            },
            "& svg": {
                width: SIZING.scale275
            }
        },
        resultsHeader: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
        }
}));