import React from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { ReactComponent as EmptyIcon } from "../../tempIcons/User_Doctor-Stethoscope-Shoulder.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import EmptyMessage from "common/components/empty-message/empty-message";
import ProvidersList from "./provider-list/provider-list";
import { SelectedProvidersProps } from "./types";
import { useStyles } from "./styles";

const SelectedProviders = ({ selectedProviders, onRemove }: SelectedProvidersProps) => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classNames(classes.panel, classes.selectedProvidersBox)}
        >
            <Box flexShrink={1}>
                <CustomTypography variant="modalSectionHeaderBold" className="panelHeader">
                    <FormattedMessage id="EConsult.newRequestModal.selectedRecipients" />
                </CustomTypography>
            </Box>

            {selectedProviders.length === 0 ? (
                <EmptyMessage
                    className="emptyMessage"
                    Icon={<SVGIcon svg={EmptyIcon} size="scale2500" color="CREATE_E_CONSULT_REQUEST_ICON"/>}
                    primaryMessage={{ id:"EConsult.newRequestModal.recipients.emptyMessage" }}
                    secondaryMessage={{ id:"EConsult.newRequestModal.recipients.maxMessage" }}
                />
            ) : (
                <div className="providerListScrollContainer">
                    <ProvidersList id="QA_selected_providers"
                        itemIdPrefix="QA_recipient"
                        providers={selectedProviders}
                        onRemove={onRemove}
                    />
                </div>
            )}
        </Box>
    );
};

export default SelectedProviders;
