import moment from "moment";
import { useIntl } from "react-intl";

// Nice time formats (APOLLO-560)
moment.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "now",
        ss: "%d s",
        m: "%d m",
        mm: "%d m",
        h: "1 h",
        hh: "%d h",
        d: "1 d",
        dd: "%d d",
        w: "1 w",
        ww: "%d w",
        M: "1 mo",
        MM: "%d mo",
        y: "1 y",
        yy: "%d y",
    },
});

export type DateFormatterNames = "MMM DD, YYYY, hh:mm aa" | "MMMM DD, YYYY" | "MMMM DD, YYYY, hh:mm aa" | "nicetime";

export function useDateFormatters(): Record<DateFormatterNames, (date: string) => string> {
    const intl = useIntl();

    return {
        "MMM DD, YYYY, hh:mm aa": (date: string) => {
            return intl.formatDate(date, { dateStyle: "medium", timeStyle: "short" });
        },
        "MMMM DD, YYYY": (date: string) =>
            intl.formatDate(date, {
                month: "long",
                year: "numeric",
                day: "numeric",
            }),
        "MMMM DD, YYYY, hh:mm aa": (date: string) =>
            intl.formatDate(date, {
                month: "long",
                year: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            }),
        nicetime: (date: string) => moment(date).fromNow(true),
    };
}
