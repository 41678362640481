export const UNFINISHED_STATES = [
    "uploading",
    "started",
    "preparing",
    "ready",
    "getting_upload_params",
    "restarted",
    "headers_received",
];

export const PERCENTAGE_CALC_STATES = [...UNFINISHED_STATES, "done"];

export const ERROR_STATES = [
    "error_validation",
    "error_file_size",
    "rejected_file_type",
    "rejected_max_files",
    "error_upload_params",
    "exception_upload",
    "error_upload"
];
