import React from "react";
import { NOTIFICATION_TYPE } from "constant";
import { NotificationItem } from "store/notification-types";

import WaitingRoom from "./waiting-room";
import PatientADTAlerts from "./patient-adt-alerts";
import NewEconsultRequest from "./new-econsult-request";

const NotificationByType = (props: NotificationItem) => {
    switch (props.type) {
        case NOTIFICATION_TYPE.WAITING_ROOM:
        case NOTIFICATION_TYPE.NRU_WAITING_ROOM:
            return <WaitingRoom {...props}/>;
        case NOTIFICATION_TYPE.ADT_ALERTS:
            return <PatientADTAlerts {...props}/>;
        case NOTIFICATION_TYPE.NEW_ECONSULT_REQUEST:
            return <NewEconsultRequest {...props}/>;
    }
};

export default NotificationByType;
