import { AppReduxStore } from "store/reducerTypes";
import { Box, Button, Typography } from "@mui/material";
import { Calendar } from "assets"
import { FeatureFlagNames } from "lib/security/types";
import { FormattedMessage ,FormattedRelativeTime} from "react-intl";
import { getAppointments, setActiveModal } from "store/actions";
import { MODAL_TYPES } from "constant";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useFeatureFlag } from "lib/security/feature-flag-hooks";
import { usePermission } from "lib/security/permission-hooks";
import { useStyles } from "./styles";
import {createSvgIcon,formatDateWithTime} from "utils";
import { Permissions } from "types";
import {ReactComponent as CalendarEmptySvg} from "icon-library/SVG/Calendar.svg";
import EmptyContentMessage from "common/components/EmptyContentMessage/EmptyContentMessage";
import Spinner from "common/components/Spinner/Spinner";

const CalendarEmpty = createSvgIcon(CalendarEmptySvg)

const Appointments = () => {
    const dispatch = useDispatch()
    const { appointmentDetails, loadingAppointments, selectedPatient } = useSelector((state: AppReduxStore) => state.patient);
    const hpiFormFeatureFlag = useFeatureFlag(FeatureFlagNames.HPI_FORM);
    const eTranscriptionPermission = usePermission(Permissions.ETRANSCRIPT);
    const details = selectedPatient?.evistDetails || {};

    const classes = useStyles(Object.keys(details).length!==0)
    const currentEpoch = Date.now();

    useEffect(() => {
        dispatch(getAppointments())
    }, [dispatch])

    const openHpiForm = (visitId: string, hpiFormId: string) => {
        dispatch(setActiveModal(MODAL_TYPES.PROVIDER.HPI_FORM, {
            visitId,
            hpiFormId
        }));
    }

    if (loadingAppointments) return <Spinner />

    if (!appointmentDetails.length) {
        return (
            <div className={classes.empty}>
                <EmptyContentMessage Icon={CalendarEmpty} title="Appointments.noAppointments" />
            </div>
        )
    }

    let createCounter = 0;
    let viewCounter = 0;
    return (
        <Box className={classes.wrapper}>
            {appointmentDetails.map(({ title, withName, titlePrefix,scheduleStartTime, visitId, hpiFormId = ''}) => {
                const isExpired = titlePrefix === 'OLD';
                const formId = hpiFormId ?? '';
                const isFormExisting = !!formId;
                const buttonClassName = isFormExisting ? 'view' : 'create';
                /* Aligned with QA to provide a more proper button ID, which is indexed by the count of the occurence of
                 * that particular button. It requires some additional calculation.
                 */
                const buttonId = `QA_e-transcription-item-${buttonClassName}-${isFormExisting ? viewCounter : createCounter}`;
                if (isExpired) {
                    if (isFormExisting) {
                        viewCounter++;
                    } else {
                        createCounter++;
                    }
                }
                const startIn = (new Date(scheduleStartTime).getTime() - currentEpoch) / 1000;
                const hasETranscriptionData = Boolean(visitId);

                return (<Box key={`${scheduleStartTime}-${title}-${withName}`} className={`${classes.contentWrapper}`}>
                    <Calendar />
                    <div className={classes.details}>
                        <div className={`${isExpired ? 'expired' : ''}`}>
                            <Typography variant="body1" className="title">
                                {titlePrefix &&`${titlePrefix} : `}{title}
                            </Typography>
                            <Typography variant="h6">
                                {formatDateWithTime(scheduleStartTime)}
                            </Typography>
                            <Typography className="provider-details">
                                With {withName}
                            </Typography>

                        </div>
                        {/* showing  only for upcoming events in 15 min  */}
                        {startIn>=0 && startIn<=900  && <Typography variant="h6" className="startTime">

                            { <FormattedRelativeTime style={`short`} value={startIn} updateIntervalInSeconds={5}>
                            {(parts :any) => {
                                //while updating time, hidding past time details
                                if(parts.indexOf('ago')!==-1){
                                    return null;
                                }
                                return   <><FormattedMessage id="Appointments.statIn" />  {parts} </>
                            }}

                            </FormattedRelativeTime> }
                        </Typography>}
                        {hpiFormFeatureFlag && eTranscriptionPermission && hasETranscriptionData && isExpired && (
                            <div className="buttonBar">
                                <Button id={buttonId}
                                    className={buttonClassName}
                                    size="small"
                                    onClick={() => {
                                        openHpiForm(visitId as string, formId);
                                    }}
                                    variant="contained"
                                    color="inherit"
                                    data-form-id={formId}
                                >
                                    <FormattedMessage id={isFormExisting?'Appointments.viewHpiForm':'Appointments.createHpiForm'} />
                                </Button>
                            </div>
                        )}
                    </div>
                </Box>)
            })}
        </Box>
    )
}

export default Appointments;
