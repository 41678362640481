import React from "react";
import { useStyles } from "./styles";
import { NavLink } from "react-router-dom";
import { Tab } from "./Tab";
import { LinkTabProps } from "./types";

export const LinkTab = ({ exact = false, to, ...tab }: LinkTabProps) => {
    const classes = useStyles();
    const Button = <Tab {...tab} key={tab.name} />;

    if (tab.disabled) {
        return Button;
    }

    return (
        <NavLink key={tab.name} className={classes.linkTab} activeClassName={classes.selectedTabButton} exact={exact} to={to}>
            {Button}
        </NavLink>
    );
};
