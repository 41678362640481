import _ from 'underscore'
import requireContext from 'require-context.macro'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

const context = requireContext('.', true, /^\.\/[^.]+(?!spec)\.ts$/)
const reducers: {
	children: any
	items: any
} = {
	children: {},
	items: {},
}

context.keys().forEach(function(key) {
	const incl = context(key)
	const pathParts = key.split('/').slice(1)

	let exportObj = reducers
	for (let i = 0; i < pathParts.length - 1; i++) {
		let child = exportObj.children[pathParts[i]]
		if (!child) {
			child = {
				name: pathParts[i],
				children: [],
				items: {},
			}
			exportObj.children[pathParts[i]] = child
		}
		exportObj = child
	}

	try {
		if (incl.default) {
			const component = key.match(/([^/]+)\.ts$/i)
			if (component) {
				const componentName = component[1]
				exportObj.items[componentName] = incl.default
			}
		}
	} catch (error) {
		;
	}
})

export const buildReducer = (obj: any) => {
	const childReducers: any = _.object(
		_.map(obj.children, (val, key) => {
			return [key, buildReducer(val)]
		})
	)
	return combineReducers({
		...obj.items,
		...childReducers,
	})
}

const configureStore = (history: History) => {
	reducers.items.router = connectRouter(history)
	return buildReducer(reducers)
}

export const getPngMobileReducer = () => reducers

export default configureStore
