import { useRef } from "react";
import isEqual from "lodash/isEqual";

/**
 * This hook helps you have a single reference for a object you need to create during render.
 */
export const useStableObjectReference = <T extends {}>(value: T): T => {
    const lastValueRef = useRef(value);

    if (!isEqual(lastValueRef.current, value)) {
        lastValueRef.current = value;
    }

    return lastValueRef.current;
};
