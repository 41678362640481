import { ExtendedRecipientDetails } from "./types";
import { ReactComponent as DocIcon } from "../../tempIcons/User_Doctor_Circle_Stethoscope.svg";
import { FormattedMessage } from "react-intl";
import { Badge } from "@mui/material";
import { messagingConversationFetchingBusyStateName } from "components/messaging/constants";
import { useBusyState } from "common/utils/progress/progress-manager-hooks";
import classNames from "classnames";
import Spinner from "common/components/Spinner/Spinner";

type RecipientItemProps = {
    idPrefix: string;
    onClick: (recipientId: string) => void;
    recipient: ExtendedRecipientDetails;
};
type RecipientSelectorViewProps = {
    recipients: ExtendedRecipientDetails[];
    onSelect: (recipientId: string) => void;
};

const getMessageKey = (messagesCount: number, unreadMessagesCount: number) => {
    if (unreadMessagesCount > 0) {
        return "EConsult.messages.unreadMessages";
    }
    if (messagesCount > 0) {
        return "EConsult.messages.readMessages";
    }
    return "EConsult.messages.noMessages";
};

const RecipientItem = ({ idPrefix, onClick, recipient }: RecipientItemProps) => {
    const messagesCount = recipient.messagesCount;
    const unreadMessagesCount = recipient.unreadMessagesCount;
    const messageInfoKey = getMessageKey(messagesCount, unreadMessagesCount);

    return (
        <div className="EConsult__messages__recipientSelector__item" onClick={() => onClick(recipient.id)}>
            <Badge
                badgeContent={unreadMessagesCount}
                invisible={unreadMessagesCount === 0}
                color="primary"
                className="EConsult__messages__recipientSelector__item__badge"
            >
                <DocIcon />
            </Badge>
            <span id={`${idPrefix}__name`} className="EConsult__messages__recipientSelector__item__name">
                {recipient.fullName}
            </span>
            <span
                id={`${idPrefix}__messageInfo`}
                className={classNames("EConsult__messages__recipientSelector__item__messageInfo", {
                    "EConsult__messages__recipientSelector__item__messageInfo--highlighted": unreadMessagesCount > 0,
                })}
            >
                <FormattedMessage id={messageInfoKey} values={{ messagesCount, unreadMessagesCount }} />
            </span>
        </div>
    );
};

const RecipientSelectorView = ({ onSelect, recipients }: RecipientSelectorViewProps) => {
    const { busyState } = useBusyState(messagingConversationFetchingBusyStateName);
    return busyState ? (
        <Spinner />
    ) : (
        <div className="EConsult__messages__recipientSelector">
            {!!recipients?.length &&
                recipients.map((recipient, index) => (
                    <RecipientItem key={recipient.id}
                        idPrefix={`EConsult__messages__recipientSelector__item-${index}`}
                        recipient={recipient}
                        onClick={() => onSelect(recipient.id)}
                    />
                ))}
        </div>
    );
};

export default RecipientSelectorView;
