import { Checkbox } from "@mui/material";
import { feeScheduleRegionProps, FeeScheduleRegionStateType } from "admin/views/FeeSchedules/types";
import Spinner from "common/components/Spinner/Spinner";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import { useEffect } from "react";

const useRegionsByOrganizationId = (orgId: string) => {
    const { content, fetchInfo, request } = useApi<{ orgId: string }, feeScheduleRegionProps[]>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: [],
        url: `/admin/v1/region/Organization/${orgId}`,
        method: HttpMethod.GET
    });

    useEffect(() => {
        if (orgId) {
            request({ orgId });
        }
    }, [orgId, request]);

    return {
        regionList: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};

export const FeeScheduleGroupsRegion = ({ orgId, regionsState, onRegionsChange: setRegionsState, disabled }: { orgId: string, regionsState: FeeScheduleRegionStateType, onRegionsChange: (newRegionsState: FeeScheduleRegionStateType) => void, disabled?: boolean }) => {

    const initialRegionState = () => {
        const newRegionsState: FeeScheduleRegionStateType = {};
        if (hasResult && regionList?.length > 0) {
            regionList.forEach((region: feeScheduleRegionProps) => {
                newRegionsState[region.id] = true;
            });
        }
        return newRegionsState;
    };

    const { regionList, hasResult } = useRegionsByOrganizationId(orgId);

    useEffect(() => {
        setRegionsState(initialRegionState());
    }, [])

    return (<>
        {hasResult ? ((regionList?.length > 0) ? (
            <ul style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0', margin: '0px' }}>
                {regionList.map((region: feeScheduleRegionProps, index: number) => {
                    return (<li key={`fee_schedule_region_${index}`}
                        style={{ listStyle: 'none', flex: '0 0 33.333333%', marginTop: '5px', marginBottom: '5px', fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%' }}>
                        <Checkbox
                            disabled={!!disabled}
                            color="primary" disableRipple disableFocusRipple
                            checked={regionsState[region.id] || false}
                            onChange={(event) => {
                                const newValue: boolean = Boolean(event.target.checked);
                                const newRegionsState = Object.assign({}, regionsState);
                                newRegionsState[region.id] = newValue;
                                setRegionsState(newRegionsState);
                            }}
                            id={`fee_schedule_region_checkbox_${index}`} />
                        {region.name}
                    </li>);
                })}
            </ul>
        ) : (<p>{`There is no region associated with this organization`}</p>)) : <Spinner />}
    </>)
}