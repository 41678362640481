import React from "react";
import { Box } from "@mui/material";
import {SIZING} from "theme";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import {
    PatientHeader,
    ToolsMenu,
    EllipsisMenu,
    SummaryTabs,
} from "views/People/PatientDetailsHeader";
import { PatientInfoHeaderProps } from "./types";
import { useStyles } from "./styles";

const PatientInfoHeader = ({ onClose, loading, ...summaryTabsProps }: PatientInfoHeaderProps) => {
    const classes = useStyles();
    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                marginBottom={SIZING.scale500}
            >
                <PatientHeader />
                <Box display="flex" flexDirection="column" flexGrow="1">
                    <Box display="flex" justifyContent="flex-end" marginBottom={SIZING.scale500}>
                        <Close
                            className={classes.close}
                            onClick={onClose}
                            id="QA_waiting_room_patient_info_close_button"
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <ToolsMenu />
                        <EllipsisMenu />
                    </Box>
                </Box>
            </Box>
            { !loading && <SummaryTabs {...summaryTabsProps} />}
        </Box>
    );
};

export default PatientInfoHeader;
