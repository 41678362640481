import { DeprecatedThemeOptions, adaptV4Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import OpenSans from 'fonts/OpenSans-Regular.ttf';
import { ValueOf } from "types";

export const BASE_FONT_SIZE = 16 as const;

export const sideNavWidth = "17.25rem"

export type ColorValue = ValueOf<typeof COLORS>;

export const SIZING = {
    scale25: "0.1rem", // 1.6
    scale50: "0.125rem", // 2
    scale75: "0.188rem", // 3
    scale100: "0.25rem", // 4
    scale120: ".3rem", // 4.8
    scale125: ".3125rem", // 5
    scale150: "0.375rem", // 6
    /** 7px */
    scale175: ".4375rem",
    scale200: "0.5rem", // 8
    /** 10 px */
    scale250: ".625rem",
    /** 11px */
    scale275: ".6875rem",
    scale300: "0.75rem", // 12
    /** 13px */
    scale325: ".8125rem",
    /** 14px */
    scale350: ".875rem",
    scale360: ".9rem",
    scale375: "0.938rem", // 15
    scale400: "1rem", // 16
    /** 17px */
    scale425: "1.0625rem",
    scale450: "1.125rem", // 18
    scale475: "1.188rem", // 19
    scale500: "1.25rem", // 20
    scale525: "1.313rem", // 21
    scale550: "1.375rem", // 22
    scale600: "1.5rem", // 24
    scale625: "1.563rem", // 25
    scale700: "1.75rem", // 28
    scale750: "1.875rem", // 30
    /** 31px */
    scale775: "1.9375rem",
    scale800: "2rem", // 32
    scale825: "2.063rem", // 33
    scale875: "2.188rem", // 35
    scale900: "2.25rem", // 36
    scale950: "2.375rem", // 38
    scale1000: "2.5rem", // 40
    scale1050: "2.625rem", // 42
    scale1200: "3rem", // 48
    /** 60 */
    scale1500: "3.75rem",
    scale1600:"4rem",//64
    scale2000:"5.25rem",//84
    scale2500:"6.25rem",//100
    scale2600:"6.5rem",//104
    scale2725: "6.813rem", // 109
    scale2800: "7rem", // 112
    scale2900:"7.75rem",//124
    scale2950: "8rem", //128
    scale3200:"8.85rem",//140
    scale3600:"9rem",// 144
    scale4000:"10rem",// 160
    scale5000:"12.5rem",// 200
    /** 256px */
    scale6400:"16rem",
    /** 280px */
    scale7000:"17.5rem",
    scale10000:"25rem",// 400
    /** 600px */
    scale15000:"37.5rem",
    EMPTY_LIST_ICON: "12rem", // 192
    SIDE_NAV_OPEN_WIDTH: "18.75rem", // 300
    RPM_CHART_CARD:"314px",//314px
    SIDE_NAV_CLOSED_WIDTH: "5rem", // 80
    WAITING_ROOM_SECTION:"435px",
    WAITING_ROOM_PATIENT_CARD:"404px",
    HEADER_HEIGHT: "128px",
    FORM_DETAILS_HEADER_HEIGHT: "4.5rem",
    ZOOM_MEETING_MODAL_HEIGHT:'31.25rem',
    TRANSCRIBE_MODAL_HEIGHT:"14.375rem",//230
    TRANSCRIBE_MODAL_WIDTH:"40rem", //640,
    QUICK_CHAT_BOX:"20.375rem",//326px
    ENROLL_PATIENT_MODAL_WIDTH:"53.75rem", //860,

    //EConsult
    ECONSULT_REQUEST_SEARCH_AREA_WIDTH: "24.75rem", //396

    RPM_DEVICE_BOX:"390px",
    ECONSULT_SPECIALIST_DETAILS_TOOLTIP_WIDTH: "30rem",

    //Patient
    PATIENT_DETAILS_INFO_WIDTH: "34.688rem",
    PATIENT_DETAILS_REMOVE_REMOVE_WIDTH: "16.563rem",
    PATIENT_SUMMARY_EVISIT_BAR_HEIGHT: 75,
    PATIENT_SUMMARY_HEADER_HEIGHT: 145,

} as const;

export const BORDER_RADIUS = {
    SMALL: SIZING.scale100,
    MEDIUM: SIZING.scale200,
    MED_LARGE: SIZING.scale400,
    LARGE: SIZING.scale600,
    XL: SIZING.scale500,
    PILL: "30rem",
    FULL: "100%",
    MULTISELECT_RADIUS_RIGHT:'1.385rem',
    MULTISELECT_RADIUS_LEFT:'1.3rem'
}

export const PADDING = {
    SIDE_NAV_ITEM_PADDING: "2px",
    SIDE_NAV_WRAPPER_PADDING: "16px"
}

export const COLORS = {

    //Common
    WHITE: "#FFFFFF",
    LBLUE:'#0F7BBD',
    /** Name as in design. #FBFBFB */
    GRAY_2: "#FBFBFB",
    GRAY_4: "#F6F6F7",
    GRAY_50: "#909098",
    LGREY: '#FAFBFB',
    LGREY1:'#6E6E79',
    GREY:'#393948',
    DGREY:'#EDEDEF',
    // Duplication of GREY
    DBLACK:'#393948',
    BLACK:'#000000',
    LGREEN:'#00A875',
    LRED:'#FFDEDE',
    RED:'#C40000',
    YELLOW: '#F6E71C',
    LYELLOW: '#FEF7E6',
    MIDDLE_GRAY: "#999999",
    DANGER: "#E82C2A",
    WARNING: "#FFC240",

    // Backgrounds
    // nav, menu, tab
    OPTION_MENU_BG: "#393948",
    OPTION_MENU_BG_LIGHT: "#FFFFFF",
    OPTION_DIVIDER_BG: "#9DAAC5",
    SELECTED_TAB_BG: "#FFFFFF",
    SIDE_NAV_BG: "#EDEDEF",
    SIDE_NAV_HEADER_BG: "#F6F6F7",
    SIDE_NAV_EXPANDED_ITEM_BG: "#0B5D8F",
    LEARNING_CENTER_HEADER_BG: "#EDEDEF",
    LEARNING_CENTER_BG: "#F6F8FA",
    LIST_ITEM_BG: "#FFFFFF",
    MODAL_HEADER_BG: "#EDEDEF",
    MODAL_CONTENT_LBLUE_BG: "#F6F8FA",
    PAGE_MAIN_HEAD_BG: "#E8F5FE",
    PAGE_MAIN_BODY_BG: "#E5E5E5",
    TAB_ERROR_BG: "#E82C2A",
    SEARCH_FILTER_TAB_BG: "#E9E9EA",
    DIALOG_CONTENT_BG: "#909098",
    DRAG_N_DROP_ON_DRAG_OVER_BG: "#FFFED1",
    // Admin nav
    SELECTED_SUB_NAV_ITEM_BTN: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",

    // messages & chats
    BADGE_BG: "#0F7BBD",
    CHAT_HEADER_BG: "#F9F9FB",
    ITEM_COUNT_BG: "#007AFF",
    ITEM_BADGE_BG: "#393948",
    UNREAD_MESSAGE_ICON_BG: "#0F7BBD",
    MESSAGE_LIST_BG: "#F6F8FA",
    MESSAGE_BG: "#FFFFFF",
    HOVER_THREAD_BG: "#E8F5FE",
    SELECTED_THREAD_BG: "#0F7BBD",
    OPENED_THREAD_BG: "#E8F5FE",
    PRIORITY_BG: "#E32B29",
    OWN_MESSAGE_BG: "#E6F7F2",

    // input
    INPUT_BG: "#FFFFFF",
    INPUT_ERROR_BG: "#FFF4F4",
    INPUT_ERROR_BORDER_COLOR: "#E32B29",
    MULTI_SELECT_BG:'#6E6E79',
    OUTLINED_INPUT_COLOR: "#212131",
    OUTLINED_INPUT_DISABLED_COLOR: "#909098",

    // other
    CALL_STATUS_BG: "#393948",
    MODAL_BG: "#F6F6F7",
    DIVIDER_GREY: "rgba(0, 0, 0, 0.1)",
    COMPOSE_SCROLL_BAR:'#CACACE',
    LGRAY:'#CACACE',
    START_CALL_BG:"#00872A",
    EVIST_INVITE_BG:'#073D5E',
    CHECKBOX_ICON_BG:'#a8a8af',
    MODAL_BACKDROP_BG: 'rgba(0,0,0,0.25)',
    E_CONSULT_AVATAR_BACKGROUND: "#999999",
    LINK_BLUE_CHECKBOX_HOVER: "#007AFF0A",
    PAGINATION_UNSELECTED_PAGE_NUMBER: "#F6F8FA",
    PAGINATION_SELECTED_PAGE_NUMBER: "#1391ED",
    SIDENAV_SUB_LIST_SEPARATOR: "#EBEBEB",
    FILE_PREVIEW_BORDER_COLOR: "#E9E7E7",
    ERROR_BANNER_BG: "#AB201F",


    // Buttons
    DEFAULT_BTN: "#0F7BBD",
    PRIMARY_BTN: "#FFFFFF",
    TOOLBAR_BTN: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(86, 86, 98, 0.05) 100%), #FFFFFF",
    SELECTED_NAV_LIST_ITEM_BTN: "#E4E4E6",
    SELECTED_NAV_ITEM_BTN: "#FFFFFF",
    SIDE_NAV_COLLAPSE_BTN: "linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(230, 234, 241, 0) 100%), #E6EAF1",
    SECONDARY_BUTTON:'#00875E',
    DISABLED_BTN: "#CACACE",
    SELECTED_BTN: "#073D5E",
    REMOVE_BTN: "#E32B29",

    // Text
    LINK_BLUE: "#007AFF",
    SELECTED_NAV_TEXT: "#0F7BBD",
    NAV_TEXT: "#4C535F",
    SELECTED_SUB_NAV_TEXT: "#7E90B3",
    SUB_NAV_TEXT: "#252D3B",
    SIDE_NAV_EXPANDED_ITEM_TEXT: "#FFFFFF",
    PARTICIPANT_TEXT: "#007AFF",
    SUBTITLE_TEXT: "rgba(0, 0, 0, 0.4)",
    BODY_TEXT: "#3C3C43",
    ITEM_COUNT_TEXT: "#FFFFFF",
    OPTION_MENU_TITLE_TEXT: "#FFFFFF",
    OPTION_MENU_TITLE_TEXT_LIGHT: "#393948",
    OPTION_MENU_ITEM_TEXT: "#FFFFFF",
    OPTION_MENU_ITEM_TEXT_LIGHT: "#393948",
    LIST_SUBHEADER_TEXT: "#565662",
    DEFAULT_BUTTON_TEXT: "#FFFFFF",
    PRIMARY_BTN_TEXT: "#0B5D8F",
    DIVIDER_TEXT: "#0F7BBD",
    NOTIFICATION_TEXT: "#E32B29",
    ERROR_TEXT: "#D72C0D",
    FORM_SCORE_RED: "#AB201F",
    FORM_SCORE_GREEN:'#00875E',
    EMPTY_LIST_TEXT: "rgba(57, 57, 72, 0.4)",
    PATIENT_DETAIL_VALUE_TEXT: "#393948",
    PATIENT_DETAIL_LABEL_TEXT: "#909098",
    PATIENT_DETAIL_HEADER_TEXT: "#212131",
    LIGHT_BLUE:'#139AED',
    CONVERSATION_TEXT: "#565662",
    OPENED_THREAD_TEXT: "#565662",
    UNREAD_THREAD_TEXT: "#212131",
    READ_THREAD_TEXT: "rgba(86, 86, 98, 0.6)",
    LIST_ITEM_TEXT: "#393948",
    LIST_ITEM_TEXT_BLUE: "#0F7BBD",
    PATIENT_TEXT: "#0F7BBD",
    SUBTITLE_INFO_TEXT: "#6E6E79",
    DISABLED_BTN_TEXT: "#565662",
    WAITING_ROOM_CALL_TEXT:"#DBDBDE",
    NAV_DIVIDER_COLOR: "#DBDBDE",
    MODAL_TITLE_TEXT:"#565662",
    E_CONSULT_PATIENT_INPUT_TEXT:"#565662",
    E_CONSULT_PATIENT_INPUT_TEXT_HALF_OPACITY: "rgba(86, 86, 98, 0.5)",
    MODAL_SUBTITLE_TEXT:"#393948",
    E_CONSULT_TEXT: "#999999",

    // Borders
    INPUT_ERROR_BORDER: "#D82C0D",
    NOTIFICATION_BORDER: "#FF5C5A",

    // Icons
    DEFAULT_ICON: "#6E6E79",
    BUTTON_ICON: "#FFFFFF",
    PATIENT_LIST_ICON: "rgba(15, 123, 189, 0.4)",
    USER_LIST_ICON: "rgba(57, 57, 72, 0.4)",
    EMPTY_LIST_ICON: "#EBEBEB",
    USER_ICON: "rgba(86, 86, 98, 0.4)",
    SELECTED_PERSON_ICON: "rgba(255, 255, 255, 0.6)",
    DRAWER_ICON: "#CACACE",
    DARK_ICON: "#565662",
    ALERTS_ICON: "#E82C2A",
    PASS_ICON: "#32D74B",
    FAIL_ICON: "#E32B29",
    CREATE_E_CONSULT_REQUEST_ICON: "#949596", // deprecate
    DARK_EMPTY_MESSAGE_ICON: "#949596",
    PDF_ICON: "#E32B29",
    UNKNOWN_FILE_ICON: "#909098",
    VIDEO_ICON: "#0F7BBD",
    IMAGE_ICON: "#00A875",

    //Alert Details
    ALERT_HEADER_BG:"#E8F5FE",

    // PNG
    PNG_MODAL_BG:'#F6F6F6'

} as const;

export const LAYERS = {
    MODAL: 1300,
    TOOLTIP_OVER_MODAL: 1400
};

// Used for custom typography in the CustomTypography as a variant
export const TYPOGRAPHY = {
    extraLight: {
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "13px"
    },
    light: {
        fontWeight: 400,
        fontSize: "11px"
    },
    subtitle1: {
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "16.8px"
    },
    subtitle1Bold: {
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "16.8px"
    },
    subtitle2: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16.8px"
    },
    conversationHeader: {
        fontWeight: 700,
        fontSize: "17px"
    },
    conversationPatientDetailsHeader: {
        fontWeight: 700,
        fontSize: "14px"
    },
    conversationPatientName: {
        fontWeight: 600,
        fontSize: "14px"
    },
    conversationPatientDetails: {
        fontWeight: 400,
        fontSize: "14px"
    },
    participantName: {
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "19.6px"
    },
    modalSectionHeader: {
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "19.6px"
    },
    modalSectionHeaderBold: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "19.6px"
    },
    contentTitle: {
        fontWeight: "bold",
        fontSize: "22px"
    },
    contentSubtitle: {
        fontWeight: 400,
        fontSize: "18px"
    },
    contentSubtitleBold: {
        fontWeight: 700,
        fontSize: "18px"
    },
    desktopPatientDetailsHeader4: {
        fontWeight: 700,
        fontSize: "17px",
        lineHeight: "24px"
    },
    desktopPatientDetailsBase: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px"
    },
    desktopPatientDetailsBaseBold: {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "22px"
    },
    optionMenuHeader: {
        fontWeight: 800,
        fontSize: "13px",
        textTransform: "uppercase"
    },
    optionMenuItem: {
        fontWeight: 700,
        fontSize: "16px"
    },
    modalHeader: {
        fontWeight: 700,
        fontSize: "17px",
        color: COLORS.MODAL_TITLE_TEXT
    },
    modalSubHeader: {
        fontWeight: 600,
        fontSize: "16px",
        color: COLORS.MODAL_SUBTITLE_TEXT
    },
    eConsultHeaderSubTitle: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px",
        color: COLORS.MODAL_TITLE_TEXT,
    },
    largeButton: {
        fontWeight: 700,
        fontSize: "15px"
    },
    xlButton: {
        fontWeight: 600,
        fontSize: "20px"
    },
    callHeader: {
        fontWeight: 700,
        fontSize: "16px"
    },
    typingIndicator: {
        fontWeight: 600,
        fontSize: "14px"
    },
    notificationTitle: {
        fontWeight: 700,
        fontSize: "14px"
    },
    patientSummaryOverviewTitle: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px"
    },
    patientSummaryOverviewLabel: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "22px"
    },
    patientSummaryOverviewValue: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px"
    },
    descriptionBold: {
        fontWeight: 700,
        fontSize: "16px"
    },
    description: {
        fontWeight: 600,
        fontSize: "14px"
    },
    descriptionLight: {
        fontWeight: 400,
        fontSize: "16px"
    },
    heading: {
        fontWeight: 600,
        fontSize: "24px"
    },
    largeHeader: {
        fontWeight: 600,
        fontSize: SIZING.scale800,
        lineHeight: "45px"
    },
    largeSubheader: {
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "28px"
    },
    titleHeader: {
        fontWeight: 700,
        fontSize: "17px",
        lineHeight: "24px"
    },
    titleHeaderLight: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22.4px"
    },
    titleSubheader: {
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "16px"
    },
    titleDescription: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "17px"
    },
    radioText: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "20px"
    },
    dialogBoldDescription: {
        fontWeight: 800,
        fontSize: "18px",
    },
    dialogDescription: {
        fontWeight: 600,
        fontSize: "18px",
    },
    bigInfoMessage: {
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "25px"
    },
    small: {
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "14px"
    },
    smallBold: {
        fontWeight: 700,
        fontSize: "10px",
        lineHeight: "14px"
    },
    tabCounter: {
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "14px"
    },
    ageAndSex: {
        fontWeight: 700,
        fontSize: "11px",
        lineHeight: "14.98px"
    },
    mrn: {
        fontWeight: 400,
        fontSize: "11px",
        lineHeight: "14.98px"
    },
    emptyEConsultListBigPrimary: {
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "34px"
    },
    emptyMessageBigPrimary: {
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "22px"
    },
    emptyMessageBigSecondary: {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "22px"
    }
} as const;

const openSans = {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontDisplay: "swap",
    src: `
        local('OpenSans'),
        url(${OpenSans}), format('truetype')
    `
} as const;

export default createTheme(adaptV4Theme({
    // TODO: casting here because the BreakpointOptions does not contains xxs and xxl
    // we have to check if they can be actually used or if they can be safely removed.
    breakpoints: {
        values: {
          xxs: 0,
          xs: 400,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
          xxl:2000
        }
      } as DeprecatedThemeOptions["breakpoints"],
    typography: {
        fontFamily: 'OpenSans',
        h1: {
          weight: 500,
          fontSize: "40px"
        },
        h3: {
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "normal"
        },
        h4: {
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "25px"
        },
        h5: {
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 700
        },
        h6: {
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "20px"
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "20px",
            color: COLORS.SUBTITLE_TEXT
        },
        body1: {
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "22px"
        },
        body2: {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "22px",
            color: COLORS.BODY_TEXT
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px"
        },
        button: {
            fontWeight: 600,
            fontSize: "20px",
            textTransform: "none"
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [openSans]
            }
        },
        MuiButton: {
            root: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: SIZING.scale1200,
                height: SIZING.scale1200,
                backgroundColor: COLORS.DEFAULT_BTN,
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
                borderRadius: BORDER_RADIUS.PILL,
                color: COLORS.DEFAULT_BUTTON_TEXT,
                "&:hover": {
                    backgroundColor: COLORS.DEFAULT_BTN,
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.25)"
                },
                "&:disabled": {
                    backgroundColor: COLORS.DISABLED_BTN,
                    color: COLORS.DISABLED_BTN_TEXT,
                    boxShadow: "none",
                    cursor: "not-allowed"
                },
                "&:active": {
                    backgroundColor: COLORS.SELECTED_BTN
                },
                "& .MuiTouchRipple-root": {
                    display: "none"
                }
            },
            textPrimary: {
                backgroundColor: COLORS.PRIMARY_BTN,
                color: COLORS.PRIMARY_BTN_TEXT
            },
            containedPrimary: {
                backgroundColor: COLORS.PRIMARY_BTN,
                color: COLORS.PRIMARY_BTN_TEXT,
                "&:hover": {
                    backgroundColor: COLORS.PRIMARY_BTN,
                    color: COLORS.PRIMARY_BTN_TEXT,
                },
                "& svg": {
                    "& g, & path": {
                        fill: COLORS.PRIMARY_BTN_TEXT
                    }
                }
            },
            containedSecondary: {
                backgroundColor: COLORS.SECONDARY_BUTTON,
                "&:hover": {
                    backgroundColor: COLORS.SECONDARY_BUTTON
                }
            },
            startIcon: {
                "& path": {
                    fill: COLORS.PRIMARY_BTN_TEXT
                }
            },
            text: {
                padding: `0 ${SIZING.scale400}`
            }
        },
        MuiInputBase: {
            root: {
                "& input::-webkit-input-placeholder, input::-ms-input-placeholder, & input::placeholder": {
                    color: "rgba(0, 0, 0, 0.4)",
                },
                width: "100%",
                background: COLORS.INPUT_BG,
                border: "1px solid rgba(0, 0, 0, 0.2)",
                borderRadius: BORDER_RADIUS.PILL,
                color: "rgba(0, 0, 0, 0.4)",
                fontSize: "18px",
                textTransform: "initial",
                "&.Mui-error": {
                    borderColor: COLORS.INPUT_ERROR_BORDER,
                    background: COLORS.INPUT_ERROR_BG
                }
            },
            input: {
                textIndent: SIZING.scale400,
                minHeight: SIZING.scale1200,
                padding: 0
            }
        },
        MuiOutlinedInput: {
            multiline: {
                backgroundColor: COLORS.WHITE
            },
            input: {
                minHeight: "22px",
                padding: "11px 8px",
                borderRadius: "4px",
                color: COLORS.OUTLINED_INPUT_COLOR,
                "&.Mui-disabled": {
                    color: COLORS.OUTLINED_INPUT_DISABLED_COLOR
                }
            },
            inputMultiline: {
                textIndent: "unset",
                paddingLeft: "1rem"
            }
        },
        MuiFormControl: {
            root: {
                "&.normal-input": {
                    "& .MuiInputBase-input": {
                        minHeight: `${SIZING.scale400} !important`,
                        padding: `${SIZING.scale200} ${SIZING.scale100}`
                    }
                },
                "&.width-100": {
                    width: '100%'
                }
            }
        },
        MuiDialog: {
            paper: {
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2), inset 0px 0px 0px rgba(0, 0, 0, 0.1)"
            },
        },

        MuiBackdrop: {
            root: {
                // unsetting the transition because on chrome it causes the backdrop-filter to not work
                // we require important because the transition is added as a style attribute
                transition: "unset !important",
                backgroundColor: COLORS.MODAL_BACKDROP_BG,
                backdropFilter: "blur(1.5px)"
            }
        },
        MuiRadio: {
            root: {
                paddingLeft: 0,
                "&:hover": {
                    "backgroundColor": "transparent"
                }
            },
            colorSecondary: {
                color: COLORS.MULTI_SELECT_BG,
                "&$checked": {
                    color: COLORS.LIGHT_BLUE,
                    "&:hover": {
                        "backgroundColor": "transparent"
                    }
                }
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: 'transparent',
                height: '30px',
                bottom:'1px',
                "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: '50%',
                    width: 12,
                    height: 12,
                    background: '#0B5D8F',
                    boxShadow: 'drop-shadow(0 0 1px rgba(0, 0, 0, .3)), drop-shadow(0 2px 8px rgba(0, 0, 0, .2))',
                    transform: "translate(-50%, 50%) rotate(135deg)",
                    clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))"
                }
            }
        },
        MuiTab: {
            root: {
                fontWeight: 600,
                fontSize: '16px',
                minHeight: '30px',
                padding: '1px 12px',
                marginRight: '.75rem'
            },
            textColorPrimary: {
                background: '#FFF',
                color: '#0B5D8F',
                borderRadius: "24px",
                boxShadow: '0 0 2px rgba(0, 0, 0, .25)',
                "&$disabled": {
                    backgroundColor: "#E4E4E6"
                },
                "&$selected": {
                    backgroundColor: "#0B5D8F",
                    color: '#FFF'
                }
            }
        }
     }
}))

/**
 *
 * @param pixelValue value in pixels to be converted to rem
 * @returns rem units, containing the "rem" suffix
 */
export const toRem = (pixelValue: number) => {
    return `${pixelValue / BASE_FONT_SIZE}rem`;
}
