import { ApiStatusCode, HttpMethod } from "lib/api/types";
import { CareTeamSearchItem, MemberRole } from "../types";
import { useApi } from "lib/api/api-service-hooks";
import { useCallback, useEffect, useRef } from "react";
import { useUserNotification } from "common/utils/use-user-notification";
import { useStableObjectReference } from "common/utils/use-stable-reference";
import debounce from "lodash/debounce";
import { useEventListener } from "lib/events";

type UseSearchCareTeamsHook = { items: CareTeamSearchItem[] | null; loading: boolean };
type CareTeamSearchRequestObject = {
    roles: MemberRole[];
    text: string;
    page: { size: number };
};

export const useSearchCareTeams = (searchValue: string, asPrimary: boolean): UseSearchCareTeamsHook => {
    const { enqueueError } = useUserNotification();
    const { content, fetchInfo, request } = useApi<
        CareTeamSearchRequestObject,
        { data: CareTeamSearchItem[] },
        CareTeamSearchItem[] | null
    >({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: null,
        url: "/provider/v1/careteam/teams/search",
        method: HttpMethod.POST,
        responseTransformer: (response) => response.data ?? null,
    });

    const params: CareTeamSearchRequestObject = useStableObjectReference({
        roles: asPrimary ? ["PRIMARY"] : ["PRIMARY", "ADMIN", "SPECIALIST"],
        text: searchValue,
        page: { size: 50 },
    });

    const performSearch = async (params: CareTeamSearchRequestObject) => {
        try {
            await request(params);
        } catch (err) {
            if ((err as Error).name !== "AbortError") {
                console.error(err);
                enqueueError("careTeam.search.errors.retrieving");
            }
        }
    };

    // request function from useApi is not stable,
    // putting it inside a ref makes it easy to work in conjunction with useEffect and useCallback
    const performSearchRef = useRef(performSearch);
    performSearchRef.current = performSearch;

    // normally we would use useCallback for this, but eslint/exaustive-deps is not happy
    // when we don't pass an inline function to it, and recommended solutions of using useMemo
    // are not good because react may drop memoed values to free memory.
    const debouncedSearchRef = useRef(
        debounce((data) => {
            performSearchRef.current(data);
        }, 500)
    );

    const refreshSearch = useCallback(() => {
        performSearchRef.current(params);
    }, [params]);

    useEventListener("careTeam:created", refreshSearch);

    useEffect(() => {
        debouncedSearchRef.current(params);
        const cancel = debouncedSearchRef.current.cancel;
        return () => cancel();
    }, [params]);

    return {
        items: content,
        loading: fetchInfo.state === ApiStatusCode.SENT,
    };
};

export default useSearchCareTeams;
