import { DialogContent, Box, Button, DialogActions, Paper, Typography, IconButton } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { MODAL_TYPES } from "admin/constant";
import { Fragment } from "react";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import { clonePatientForm } from 'admin/store/actions/configuration';
import { useRouteMatch } from "react-router-dom";

const CreateNewFormModal = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const matchId: any = useRouteMatch("/admin/configurations/patientforms/:id");
    const formId = matchId?.params?.id ? matchId?.params?.id : null;  
    const { configuration } = useSelector((state: AppReduxStore) => state);
    const currentForm: any = configuration && configuration.sPatientFormA ? configuration.sPatientFormA : null;
    const classes = useStyles();
    const onClose = () => {
        dispatch(setActiveModal(''));
    }

    const onCreate = (event: any) => {
        event.preventDefault();
        dispatch(clonePatientForm(formId));
        onClose();
    };
    const modalClasses = {
        paper: classes.wrapper
    };
    return (
        <Modal id={MODAL_TYPES.CREATE_NEW_FORM} onClose={onClose} classes={modalClasses} small={true}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "CreateNewFormModal.Title" })}
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {currentForm?.number && `# ${currentForm?.number} - `}
                        {`${currentForm?.title}?`}
                    </Typography>
                </Box>
                <Box>
                <IconButton onClick={onClose} size="large">
                    <Close />
                </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined"
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "CreateNewFormModal.CancelButton" })}
                        </Button>
                        <Button id="btnCreate" variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={onCreate}>
                            {intl.formatMessage({ id: "CreateNewFormModal.CreateButton" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper square elevation={0}>
                    <Typography variant="h3" gutterBottom>
                        {intl.formatMessage({ id: "CreateNewFormModal.Header" })}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {intl.formatMessage({ id: "CreateNewFormModal.Body" })}
                    </Typography>
                    <Typography variant="h6" className={classes.footer}>
                        {intl.formatMessage({ id: "CreateNewFormModal.Footer" })}
                    </Typography>
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default CreateNewFormModal