import { Box, FormControl, FormHelperText, MenuItem, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { ReactComponent as WarningIcon } from "admin/assets/common/ExclamationWarning.svg";

export default function Select(props: any) {
    const useStyles = makeStyles(() => ({
        labelCtrl: {
            fontWeight: 600,
            color: '#393948',
            fontSize: '14px',
            textAlign: 'left',
            paddingBottom: '4px',
            flexGrow: 1
        },
        error: {
            color: '#E32B29',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '5px'
        },
        exclamationIcon: {
            marginTop: '3px'
        }
    }));

    const classes = useStyles();
    const { name, placeholder, label, value, error = null, onChange, options, disabled, altContent } = props;
    const [isOnfocus, setOnFocus] = useState(true)
    return (
        <FormControl hiddenLabel>
            <Box display="flex" alignItems="center">
                <label className={classes.labelCtrl}>{label}</label>
                <label>{altContent}</label>
            </Box>
            <TextField
                variant={disabled ? "filled" : "outlined"}
                select
                id={`ddl${name}`}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                style={{ textAlign: "left", height: "32px" }}
                InputProps={{
                    disabled: disabled
                }}
                {...(error && { error: true, helperText: '' })}
                onFocus={(event: any) => {
                    if (isOnfocus) {
                        event.preventDefault()
                        const ke = new KeyboardEvent('keydown', {
                            bubbles: true, cancelable: true, keyCode: 13
                        });
                        event.target.dispatchEvent(ke);
                        setOnFocus(false)
                    }
                }}
                onBlur={() => { setOnFocus(true) }}>
                <MenuItem value="">None</MenuItem>
                {
                    options && options.map(
                        (item: any) => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                    )
                }

            </TextField>
            {error && <Box display="inline-flex">
                <WarningIcon className={classes.exclamationIcon} />
                <FormHelperText className={classes.error}>{error}</FormHelperText>
            </Box>}
        </FormControl>
    )
}
