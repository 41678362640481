import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    display: "inline-block"
  },
  baMenu: {
    backgroundColor: '#FBFBFB',
    marginTop: '-15px',
    color: '#565662',
    fontSize: '16px',
    zIndex: 3,
    width: '234px',
    position: 'absolute',
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2))',
    backdropFilter: 'blur(10px)',
    borderRadius: '8px 8px 16px 16px',
  },
  listText: {
    fontSize: '16px',
    fontWeight: 'bold',
    position: "relative",
    display: "inline-block"
  }
}));