import { getAppCurrentProfileId } from 'authentication/appAuth';
import { useUserNotification } from "common/utils/use-user-notification";
import { useEnabledPermissions } from "lib/security/permission-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentUser } from "store/actions";
import { AppReduxStore } from "store/reducerTypes";
import { handleDownload } from './components/file-download/file-download';
import LoadingIndicator from './components/loading-indicator/LoadingIndicator';
import { LoadingMessageProps } from './components/loading-indicator/types';
import { PEPBillingClaimsResources } from "./pep-billing-claims-resources/pep-billing-claims-resources";
import { getConfig } from "./pep-config";
import { PEPCopyright } from "./pep-copyright";
import { PEPCozevaTrainingVideo } from "./pep-cozeva-training-video/pep-cozeva-training-video";
import { PEPEducation } from "./pep-education/pep-education";
import { PEPFeeSchedules } from "./pep-fee-schedules/pep-fee-schedules";
import { PEPFooter } from "./pep-footer";
import { PEPHeader } from "./pep-header";
import { PEPHelp } from "./pep-help/pep-help";
import { PEPIOAForms } from "./pep-ioa-forms/pep-ioa-forms";
import { PEPMain } from "./pep-main";
import { PEPMembershipReports } from "./pep-membership-reports/pep-membership-reports";
import { PEPNavigation } from "./pep-navigation";
import { PEPPerformanceReports } from "./pep-performance-reports/pep-performance-reports";
import { PEPPracticeResources } from "./pep-practice-resources/pep-practice-resources";
import { PEPReports } from "./pep-reports/pep-reports";
import { PEPWPContent } from "./pep-wp-content";
import { ConfigProps, LinkProps, ProviderParams } from "./types";

type NavItemsProps = {
    item: string,
    parameters?: any
}

const PEPHome = () => {
    const { providerName } = useParams<ProviderParams>();
    const { user } = useSelector((store: AppReduxStore) => store);
    const { currentProfileRoleJoin } = user;
    const isBSCPage = (providerName.toLowerCase() === "bsc");
    const practiceId = currentProfileRoleJoin?.practice?.id;
    const orgId = currentProfileRoleJoin?.practice?.organizationId || currentProfileRoleJoin?.organization?.id;
    const [menuItem, setMenuItem] = useState<NavItemsProps>({ item: "" });
    const providerConfig: ConfigProps = getConfig(providerName);
    const grantedPermissions: string[] = useEnabledPermissions();
    const { enqueueError } = useUserNotification();
    const dispatch = useDispatch();

    const handleSelection = (menuItem: string) => {
        setMenuItem({ item: menuItem });
    };

    const handleLinkAction = (linkAction: LinkProps) => {
        if (!!linkAction.fileName) {
            if (linkAction.type === "bsc_download") {
                handleBSCDocumentDownload(linkAction.fileName);
            } else if (linkAction.type === "wp_download") {
                handleWPDocumentDownload(linkAction.fileName);
            }
        } else {
            setMenuItem({ item: linkAction.id });
        }
    }

    const getWpConfig = (key: string): { url: string, category?: string, height?: number } => {
        const wpRoot = providerConfig.wpRoot;
        switch (key) {
            case "practice_management_resources":
                return { url: wpRoot + "/practice-management-resources/", category: "practice_resources", height: 700 };
            case "pep-forms":
                return { url: wpRoot + "/forms/", category: "practice_resources", height: 1000 };
            case "pep-patient-care-management":
                return { url: wpRoot + "/patient-care-resources/", category: "practice_resources", height: 1200 };
            case "pep-epic-training":
            case "epic_ehr_implementation":
                return { url: wpRoot + "/epic-ehr-and-epiclink-info/", category: "practice_resources", height: 1200 };
            case "pep-btp-provider-marketplace":
                return { url: wpRoot + "/btp-marketplace/", category: "practice_resources", height: 1200 };
            case "pep-health-plan-policies":
                return { url: wpRoot + "/health-plan-information/", category: "practice_resources", height: 2000 };
            case "fee_schedules":
                return { url: "", category: "billing_claims_resolution" };
            case "pep-claims-billing-resources":
                return { url: wpRoot + "/tools-and-resources/", category: "billing_claims_resolution", height: 700 };
            case "pep-dispute-resolution-information":
                return { url: wpRoot + "/dispute-resolution-information/", category: "billing_claims_resolution", height: 1200 };
            case "pep-compliance-fraud-waste":
                return { url: wpRoot + "/compliance-fwa-training/", category: "education", height: 1800 };
            case "pep-population-health":
                return { url: wpRoot + "/pop-health-training/", category: "education", height: 1600 };
            case "cozeva_video_training":
                return { url: "", category: "education" };
            case "pep-btp-events":
                return { url: wpRoot + "/btp-events/", height: 700 };
            case "performance_reports":
                return { url: "", category: "all_reports" };
            case "membership_reports":
                return { url: "", category: "all_reports" };
            case "ioa_forms":
                return { url: "", category: "all_reports" };
            default:
            case "pep-latest-news":
                return { url: wpRoot + "/btp-network-news/", height: 700 };
        }
    };

    const updateNavigationSelection = () => {
        const newConfig: ConfigProps = Object.assign({}, providerConfig);
        const leftNavLinks: LinkProps[] = newConfig.navigations.header.links.left;
        const rightNavLinks: LinkProps[] = newConfig.navigations.header.links.right;

        for (const navLinks of [leftNavLinks, rightNavLinks]) {
            navLinks.map((link: LinkProps) => {
                const selectedLinkConfig = getWpConfig(menuItem?.parameters ? menuItem.parameters : menuItem.item);
                const selectedLinkCategory = selectedLinkConfig?.category;
                if ((link.id === menuItem.item) || (link.id === selectedLinkCategory))
                    link.selected = true;
                return link;
            });
        }
        return newConfig;
    };
    const config = updateNavigationSelection();

    const getContent = () => {
        const key = menuItem?.parameters || menuItem.item;
        switch (key) {
            case "practice_resources":
                return (<PEPPracticeResources onSelect={handleSelection} />);
            case "billing_claims_resolution":
                return (<PEPBillingClaimsResources onSelect={handleSelection} />);
            case "all_reports":
                return (<PEPReports onSelect={handleSelection} permissions={grantedPermissions} />);
            case "ioa_forms":
                return (<PEPIOAForms onSelect={handleSelection} />)
            case "membership_reports":
                return (<PEPMembershipReports />)
            case "performance_reports":
                return (<PEPPerformanceReports />);
            case "education":
                return (<PEPEducation onSelect={handleSelection} />);
            case "fee_schedules":
                return (<PEPFeeSchedules />);
            case "":
                return (<PEPMain providerConfig={config} permissions={grantedPermissions} onSelect={handleLinkAction} />);
            case "help":
                return (<PEPHelp />);
            case "cozeva_video_training":
                return (<PEPCozevaTrainingVideo />);
            default:
            case "pep_wp_content":
                const wpConfig = getWpConfig(menuItem?.parameters ? menuItem.parameters : menuItem.item);
                const wpURL = wpConfig.url;
                const wpHeight = wpConfig.height;
                return (<PEPWPContent src={wpURL} height={wpHeight} />);
        }
    };

    const handleWPLinkClick = (target: string) => {
        switch (target) {
            case "pep-fee-schedules":
                setMenuItem({ item: "fee_schedules" });
                break;
            case "pep-provider-directory-fad":
                window.open('https://www.brownandtoland.com/find-a-doctor/', '_blank', 'noopener,noreferrer');
                break;
            case "pep-platform-access-request":
                const url = window.location.origin + "/newuser";
                window.open(url, '_blank', 'noopener,noreferrer');
                break;
            case "pep-practice-operations-manual":
                window.open('https://www.brownandtoland.com/wp-content/uploads/2021/08/522.-BTHS-Provider-Dispute-Resolution-10.2020.pdf', '_blank', 'noopener,noreferrer');
                break;
            case "pep-training-platform":
                window.open('https://browntoland.csod.com/ui/lms-learner-home/home?tab_page_id=-200300006&tab_id=-3', '_blank', 'noopener,noreferrer');
                break;
            case "pep-patient-care-management":
            default:
                setMenuItem({ item: "pep_wp_content", parameters: target })
                break;
        }
    };

    /*
    const handleWPDocumentsListing = async (requestFileName?: string) => {
        const fileStr = requestFileName ? `&documentName=${requestFileName}` : "";
        const documentsListingURL = `/admin/v1/document/practice?practiceId=${practiceId}&documentType=DOCUMENT${fileStr}`;
        await call("GET", documentsListingURL).then((response) => {
            ;
        })
    };

    const handleWPDocumentInsert = async (requestFileName: string) => {
        const insertFileRequest = {
            "documentName": requestFileName,
            "documentType": "DOCUMENT",
            "practiceId": practiceId,
        };
        const updateFileURL = "/admin/v1/document/";
        await call("POST", updateFileURL, insertFileRequest).then((response) => {
            ;
        });
    };
    */

    const [loadingMessage, setLoadingMessage] = useState<LoadingMessageProps>();
    const [loadingProgress, setLoadingProgress] = useState<number>();

    const handleWPDocumentDownload = async (requestFileName: string) => {
        const fileRequest = {
            "fileName": requestFileName,
            "fileType": "DOCUMENT",
            "practiceId": practiceId,
            "orgId": orgId
        }
        const endpointURL = "/admin/v1/provider/document/download";
        await handleFileDownload(fileRequest, endpointURL);
    };

    const handleBSCDocumentDownload = async (requestFileName: string) => {
        const fileRequest = {
            "fileName": requestFileName,
            "fileType": "COMPLEX-CARE",
            "practiceId": practiceId,
            "orgId": orgId
        }
        const endpointURL = "/admin/v1/bsc/complex-care/report/download";
        await handleFileDownload(fileRequest, endpointURL);
    };

    const handleFileDownload = async (fileRequest: any, endpointURL: string) => {
        setLoadingMessage({
            title: 'File Download',
            description: 'Preparing your download...',
            description_inProgress: 'Downloading file...'
        });
        await handleDownload(endpointURL, fileRequest, handleProgressUpdate).catch(() => {
            enqueueError("pep.wp.download.error");
        }).finally(() => {
            setLoadingMessage(undefined);
            setLoadingProgress(undefined);
        })
    }

    const handleProgressUpdate = (progress: number) => {
        if (progress) {
            setLoadingProgress(progress);
        }
    }

    const handleResizeWPContent = (size: string) => {
        if (size) {
            const pepContentDiv = document.getElementById("pep_wp_content");
            if (pepContentDiv)
                pepContentDiv.style.height = size;
        }
    };

    window.onmessage = function (event) {
        const eventData = event.data;
        if (eventData === 'pep-top-add-alert') {
            const alertHeight = '710px';
            const pepTopDiv = document.getElementById("wp_pep_home_top");
            if (pepTopDiv)
                pepTopDiv.style.height = alertHeight;
        } else if (eventData === 'pep-top-remove-alert') {
            const alertHeight = '600px';
            const pepTopDiv = document.getElementById("wp_pep_home_top");
            if (pepTopDiv)
                pepTopDiv.style.height = alertHeight;
        } else if (eventData?.eventType && eventData?.source && eventData?.value) {
            if (eventData.eventType === 'pep-resize-content') {
                handleResizeWPContent(eventData.value);
            } else if (eventData.eventType === 'pep-document-download') {
                handleWPDocumentDownload(eventData.value);
            } else {
                handleWPLinkClick(eventData.value)
            }
        }
    };

    useEffect(() => {
        const getUser = async () => {
            dispatch(getCurrentUser(getAppCurrentProfileId()));
        }
        if (!user.username) {
            getUser();
        }
    }, [dispatch, user.username])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [menuItem])

    return (<>
        <PEPHeader providerConfig={config} onSelect={handleSelection} useAltaisLogo={isBSCPage} />
        <PEPNavigation providerConfig={config} permissions={grantedPermissions} onSelect={handleSelection} profile={currentProfileRoleJoin} currentSelection={menuItem.item} />
        {getContent()}
        <PEPFooter providerConfig={config} onSelect={handleSelection} />
        <PEPCopyright useAltaisName={isBSCPage} />
        <LoadingIndicator loadingMessage={loadingMessage} loadingProgress={loadingProgress} />
    </>)
}

export default PEPHome
