import React from "react";
import {Form} from "admin/components/useForm";
import FormField from "common/components/FormField/FormField";
import {Box, Button, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "admin/store/reducerTypes";
import {setRegistrationValues, updateUsernamePatient} from "admin/store/actions";
import {EMAIL_PATTERN} from "constant";
import {useStyles} from "./styles";
import {createSvgIcon} from "utils";
import {ReactComponent as phoneSVG} from "icon-library/SVG/Mobile_iPhone-Old.svg";


const AddEmail = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {email, loading, error} = useSelector((store: AppReduxStore) => store.registration)
    const PhoneIcon = createSvgIcon(phoneSVG)

    const onChange = (key: string) => {
        return (value: any) => {
            dispatch(setRegistrationValues({[key]: value, error: null}))
        }
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (!emailInvalid) {
            dispatch(updateUsernamePatient())
        }
    }

    const getEmailErrorId = () => {
        // Only show error if not empty - do not be so agressive
        if (!email || email === "") {
            return ""
        }
        if (!(EMAIL_PATTERN).test(email)) {
            return "PatientRegistration.error.invalidEmailInput"
        }
    }

    const emailInvalid = !email || (email.trim() === '') || !(EMAIL_PATTERN).test(email)
    const submitDisabled = loading || emailInvalid
    const emailErrorId = getEmailErrorId()

    return (
        <Form onSubmit={onSubmit} name="patientEmailForm" className={classes.patientEmailForm}>
            <FormField labelId="PatientRegistration.label.emailAddress" value={email} onChange={onChange("email")}
                       type="email" errorId={emailErrorId} />
            {(error!==null) &&
                <section className={classes.warning}>
                    <Box className={classes.warningIcon}>
                        <PhoneIcon className={classes.phoneIcon} />
                    </Box>
                    <Box className={classes.warningContent}>
                        <Typography variant="h3">
                            <FormattedMessage id="AddEmail.message.emailRegistered" />
                        </Typography>
                        <br/>
                        <Typography variant="body1">
                            <b><FormattedMessage id="AddEmail.desc.emailRegistered" /></b>
                        </Typography>
                        <br/>
                        <Button variant="contained" href="/patient/download-app">
                            <Typography variant="button">
                                <FormattedMessage id="AddEmail.btn.emailRegistered" />
                            </Typography>
                        </Button>
                    </Box>
                </section>
            }

            <Button type="submit" disabled={submitDisabled || (!!error)} variant="outlined" color="primary" fullWidth>
                <Typography variant="button">
                    <FormattedMessage id="PatientRegistration.button.next" />
                </Typography>
            </Button>
        </Form>
    )
}

export default AddEmail