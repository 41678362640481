/**
 * Page DTO is used in both requests and responses of paginated resources
 */
export type PageRequest = {
    /**
     * Number of the page to get
     */
    number: number;
    /**
     * How many elements into one page
     */
    size: number;
};

export type PageResponse = PageRequest & {
    totalElements: number | null;
    totalPages: number;
};

export interface PageableResponse {
    page: PageResponse;
}

export interface PageableRequest {
    page: PageRequest | null;
}

export const DEFAULT_PAGINATION_SIZE = 25;
/**
 * Creates an empty page response object. Item number can be set for testing purposes.
 * @param itemNumber Number If 0, then totalElements would be null.
 * @returns PageResponse
 */
export const createEmptyPageResponse = (itemNumber: number = 0): PageResponse => ({
    number: 0,
    size: DEFAULT_PAGINATION_SIZE,
    totalElements: itemNumber ? itemNumber : null,
    totalPages: itemNumber ? Math.ceil(itemNumber / DEFAULT_PAGINATION_SIZE) : 1,
});
/** Returns the initial (first page related) paging object. */
export const getInitialPage = (): PageRequest => ({
    number: 0,
    size: DEFAULT_PAGINATION_SIZE,
});
