import React from "react";
import { Form } from "admin/components/useForm";
import { Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import FormField from "common/components/FormField/FormField";
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { setRegistrationValues, verifyProviderIdentity, verifyProviderIdentityPostLogin } from "admin/store/actions";
import { useStyles } from "./styles";
import { useOktaAuth } from "@okta/okta-react";

// VerifyIdentity is used to verify the identity of a new user or user adding a new profile. Providers are identified by
// first and last names and confirmed using a phone number and an otp code
const VerifyIdentity = () => {
    const { firstName, lastName, practiceSecret, loading, profileRoleJoinId } = useSelector((store: AppReduxStore) => store.registration)
    const dispatch = useDispatch()
    const classes = useStyles()
    const { oktaAuth } = useOktaAuth()
    // onChange returns a function to set a value by the given jey
    const onChange = (key: string) => {
        return (value: any) => {
            dispatch(setRegistrationValues({ [key]: value }))
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault()
        await profileRoleJoinId?.length > 0 ? dispatch(verifyProviderIdentityPostLogin(oktaAuth)) : dispatch(verifyProviderIdentity())
    }

    // Validate all fields with trimming
    const firstNameEmpty = !firstName || firstName.trim() === "";
    const lastNameEmpty = !lastName || lastName.trim() === "";
    const secretCodeEmpty = !practiceSecret || practiceSecret.trim() === "";
    const isSubmitDisabled = loading || firstNameEmpty || lastNameEmpty || secretCodeEmpty;


    return (
        <Form onSubmit={onSubmit}>
            <FormField labelId="Registration.label.firstName" value={firstName} onChange={onChange("firstName")} />
            <FormField labelId="Registration.label.lastName" value={lastName} onChange={onChange("lastName")} />
            <FormField labelId="Registration.label.secretCode" value={practiceSecret} onChange={onChange("practiceSecret")}></FormField>
            <Button type="submit" className={classes.submitButton} disabled={isSubmitDisabled} variant="outlined" color="primary" fullWidth>
                <Typography variant="button">
                    <FormattedMessage id="Registration.button.verify" />
                </Typography>
            </Button>
        </Form>
    )
}

export default VerifyIdentity