import { Theme } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    flexGrow: 1,
  },
  input: {
    display: 'none'
  },

  fileUpload: {
    position: 'absolute',
    overflow: 'hidden',
    display: 'inline-block',
  },
  uploadBtn: {
    height: '100px',
    width: '500px',
    backgroundColor: "#E8F5FE",
    color: "#000000",
    border: '1px solid black',
    borderStyle: 'dotted',
    textAlign: "center"
  },
  nav: {
    textAlign: 'right'
  },

  logo: {
    height: '82.88px',
    width: '180px',
    backgroundColor: "#F6F6F7",
    margin: "auto",
    color: "#000000",
    border: '1px solid black',
    borderStyle: 'dotted',
    paddingTop: '30px',
    textAlign: "center",
    marginBottom: "20px"
  },
  logoContent: {
    width: "180px",
    border: "1px dashed #0B5D8F",
    background: "#E8F5FE",
    boxSizing: "border-box",
    minHeight: "84px",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "4px",
    justifyContent: "center",
    margin: "auto"
  },
  saveBtn: {
    backgroundColor: '#0F7BBD',
    border: '1px solid white',
    borderRadius: '25px',
    color: 'white',
    width: 'fit-content',
    height: '40px',
    Width: '300px'
  },
  discardBtn: {
    backgroundColor: '#E32B29',
    border: '1px solid white',
    borderRadius: '25px',
    marginLeft: '10px',
    color: 'white',
    width: 'fit-content',
    height: '40px',
    paddingRight: '10px'
  },
  tabBtn: {
    backgroundColor: '#0B5D8F',
    width: 'fit-content',
    color: 'white',
  },
  body: {
    height: '67px',
    backgroundColor: "#F6F6F7",
    width: '500px',
    color: "#000000",
    border: '1px solid black',
    borderStyle: 'dotted',
    // textAlign:"left",
    // padding:'50px',
  },
  instruction: {
    height: '127px',
    backgroundColor: "#F6F6F7",

    color: "#000000",
    border: '1px solid black',
    borderStyle: 'dotted',
    textAlign: "center",
    padding: '50px'
  },
  begin: {
    marginTop: "100px",
    marginBottom: "100px"
  },
  before: {
    marginTop: "25px",
  },
  rightText: {
    float: 'right'
  },
  emailBtn: {
    height: '50px',
    Width: '250px',
    float: 'right',
    backgroundColor: '#1978bd',
    border: '1px solid white',
    borderRadius: "25px",
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer'
  },
  close: {
    borderRadius: '50%',
    height: '32px',
    width: '32px',
    fontSize: '12px',
    float: 'right',
    backgroundColor: '#656966',
    color: 'white',
  },
  save: {
    height: '48px',
    Width: '85px',
    margin: '16px',
    backgroundColor: '#1978bd',
    border: '1px solid white',
    borderRadius: "64px",
    color: 'white',
    fontSize: '20px',
    float: 'right',
  },
  discard: {
    height: '48px',
    Width: '20%',
    backgroundColor: '#E32B29',
    border: '1px solid white',
    borderRadius: "64px",
    color: 'white',
    fontSize: '20px',
    float: 'right',
  },
  dialogBox: {
    width: '100%'
  },
  body1: {
    fontSize: "17px",
    fontFamily: "OpenSans",
    fontWeight: "normal",
    lineHeight: "40px"
  },
  labelCtrl: {
    lineHeight: "31px",
    fontSize: "15px"
  },
  infoText: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#393948'
  },
  infoTooltipText: {
    fontSize: '14px',
    textAlign: 'left'
  },
  flagIcon: {
    height: '16px',
    width: '16px',
    marginRight: '7px'
  },
  infoMessage: {
    fontStyle: 'italic',
    fontSize: '14px',
    color: '#AB201F'
  },
  registration: {
    padding: '3px 0px',
    margin: '0px'
  },
  textAlignRight: {
    textAlign: 'right'
  },
  infoIcon: {
    width: '17px',
    height: '17px',
    cursor: 'pointer'
  },
  toggleSwitchText: {
    fontWeight: 600,
    fontSize: '20px'
  },
}));