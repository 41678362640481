import { ReduxDispatch } from "./reducerTypes"
import { useDispatch } from "react-redux";
import { ListItem  } from "components/MultiSelectPopoverInput/types";


export const getTimeDiff = (date: Date) => {
    const now = new Date().getTime()
    const then = new Date(date).getTime()

    return -Math.abs(now - then) / 1000
}

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export const validateZoomHandshake = (currentMeetingId: number | null, handShakeDetails: any) => {
    const { meetingId = null } = handShakeDetails || {};
    return currentMeetingId && currentMeetingId === meetingId;
}

export const validateWaitingRoomHandshake = ( providerFilterIds: ListItem[] | null, body: any, participants: any) => { // TODO need to add validation for handshake

    const { providerId, visitReminderId: handakeId } = body;

    const status = participants.find(({ visitReminderId = "" }) => visitReminderId === handakeId)?.status;

    if (status) {
        return status === 'ENDED' ? false : true
    }

    if (providerFilterIds?.length === 0) { // all filter = null
        return true
    }
    const providerIndex = providerFilterIds?.findIndex(({ id }) => id === providerId);

    if (providerIndex !== -1) {
        return true
    }

    return false
}

export const validateTwilioHandshake = (currentMeetingId: string | null, handShakeDetails: any) => {
    const { inviteToken = null } = handShakeDetails || {};
    return currentMeetingId && currentMeetingId === inviteToken;
}
