import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, ClickAwayListener, List, ListItem, Checkbox, IconButton, InputBase, Paper,FormControlLabel, Divider } from "@mui/material";
import { ReactComponent as ChevronDown } from "icon-library/SVG/Chevron_Down.svg";
import { ReactComponent as CrossCircle } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as SearchInput } from "icon-library/SVG/Search.svg";
import { createSvgIcon } from "utils";
import { ProviderFilterPropsType,FilterProviderDetails } from "./types"
import { FormattedMessage } from "react-intl";
import { useStyles } from "./style"
import { debounce } from "underscore";
import { providerFilterList   } from "store/actions/waitingRoom"
import { setError } from "store/actions/error";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import { AppReduxStore } from "store/reducerTypes";
import { useDispatch, useSelector } from "react-redux";
const DownIcon = createSvgIcon(ChevronDown);
const CrossIcon = createSvgIcon(CrossCircle);
const SearchIcon = createSvgIcon(SearchInput);

const ProviderFilter: React.FC<ProviderFilterPropsType> = () => {

    const [isOpen, updateDropdownStaus] = useState<boolean>()
    const [searchLoader, updateSearchLoader] = useState<Boolean>(false);

    const [searchedRecipients, updateSearchedRecipients] = useState<Array<FilterProviderDetails>>([]);
    const [searchText, updateSearchText] = useState('')
    const [searchNotFound, updateSearchNotFound] = useState(false)
    const { providerFilterIds } = useSelector(((state: AppReduxStore) => state.waitingRoom))
    const { currentProfileRoleJoin } = useSelector(((state: AppReduxStore) => state.user))
    const currentProviderDetails = currentProfileRoleJoin?.providerProfile || {}
    const dispatch = useDispatch()
    const classes = useStyles()
    const allSelected = providerFilterIds?.length === 0;
  
    const isChecked = (listId:string)=>allSelected ? false :providerFilterIds?.findIndex(({ id }:FilterProviderDetails['providerProfileInfo'])=>id===listId)!==-1
     

    const search = useCallback(debounce((searchStr: string) => {
        updateSearchLoader(true)
        providerFilterList(searchStr).then((data: any) => {
            updateSearchLoader(false)
            updateSearchedRecipients(data)
            if(searchStr && data.length === 0){
                updateSearchNotFound(true)
            }else{
                updateSearchNotFound(false)
            }
        }).catch(e => {
            if (e.status !== 400) {
                dispatch(setError("Error.message.fetchingParticipants"))
            }
        })
    }, 250), [])

    useEffect(() => {
        const searchString = searchText.trim();
         search(searchString)
    }, [search, searchText])

    useEffect(() => {
        isOpen ? search('') : updateSearchText('')
    }, [isOpen])

    const handleDropdown = ({ target:{ value,checked  } }: React.ChangeEvent<HTMLInputElement>,details:FilterProviderDetails['providerProfileInfo'] |'all') => {
        const filter = providerFilterIds?.slice(0) || [];
        let newFilter: any

        if(details==='all'){
            newFilter = []
        }else if(checked){
            filter.push(details)
            newFilter = filter
        }else{
            const index = filter.findIndex(({ id }: FilterProviderDetails["providerProfileInfo"]) => id === value);
            index!==-1&&(filter.splice(index,1))
            newFilter = filter
        }

        dispatch({ type:'UPDATE_PROVIDER_FILTER_DETAILS',payload:newFilter })
    }

    const handleInput = ({ target:{ value } }: React.ChangeEvent<HTMLInputElement>)=>{
        updateSearchText(value)
    }

    const getSelectedLabel = () =>{
        const filterLength = providerFilterIds?.length;
        if(filterLength===1){
            const [{ firstName = "", lastName = "", prefix = "" } = {}] = providerFilterIds || [];
            return  `${prefix?prefix:''} ${firstName} ${lastName}`
        }else{
            return filterLength ===0 ? 'ALL' : `${filterLength} Providers`
        }
    }

    const allRecipientsCount = searchedRecipients?.map((item:any)=>item.count).reduce(
        (previousValue, currentValue) => previousValue + currentValue,0);

     const isCurrentProviderSelected =  isChecked(currentProviderDetails.id)

     const getCurrentProviderCount = () =>{
         return searchedRecipients.find(({providerProfileInfo})=>providerProfileInfo.id===currentProviderDetails.id)?.count || 0
     }

    return (
        <Box className={classes.searchSection}>
            <ClickAwayListener onClickAway={() => updateDropdownStaus(false)}>
                <Box className={classes.searchBoxWrap}>
                    <Box className={classes.searchBox} onClick={() => updateDropdownStaus(true)}>
                        <Box className={classes.searchTextSection}>
                            <Typography variant="body1" >
                                WAITING ROOM
                            </Typography>
                            <Typography className={classes.searchName}>
                               {getSelectedLabel()}
                            </Typography>
                        </Box>
                        <DownIcon className={classes.downIcon} />
                    </Box>
                    {isOpen &&
                        <Box>
                            <List className={classes.baMenu} id="QA_Waitingroom_Filter_DropDown">
                                <Box className={classes.selectWaitingwrap}>
                                    <Typography variant="h6">
                                        <FormattedMessage id="Waiting_Room_Provider_Waiting_title" />
                                    </Typography>
                                    <CrossIcon onClick={() => updateDropdownStaus(false)} className={classes.crossIcon} id="QA_Waitingroom_CloseIcon" />
                                </Box>
                                <Box className={classes.searchProvidersWrap}>
                                    <Paper component="form" className={classes.inputRoot} id="QA_Waitingroom_Search_Part">
                                        <IconButton id="btnSearchColleague" className={classes.iconButton} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                        <InputBase id="QA_Waitingroom_Search_Colleague"
                                            type="search"
                                            className={classes.input}
                                            placeholder="Colleague..."
                                            onChange={handleInput}
                                            inputProps={{ 'aria-label': 'Search to filter the table below' }}
                                        />
                                    </Paper>
                                    <ListItem  disableGutters={true} className={classes.listWrapper}>
                                        {searchLoader ? <Box className={classes.loaderWrapper}><ComponentLoader /></Box> : <>
                                        {!searchText &&
                                        <>
                                            <Box className={`${classes.deviceBox} ${allSelected ? 'checked' : ''}`}>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={allSelected}
                                                        onChange={e => handleDropdown(e, 'all')}
                                                        name={"ALL"}
                                                        value={'all'}
                                                        className={`${classes.checkBox} ${allSelected ? 'checked' : ''}`}
                                                        id="QA_Waitingroom_CheckBox"

                                                    />
                                                }
                                                    className={classes.customLabel}
                                                    label={
                                                        <Box className={classes.checkBoxTextSection}>
                                                            <Box>
                                                                <Typography>All</Typography>
                                                            </Box>
                                                            <Box className={classes.checkBoxDesc}>
                                                                <FormattedMessage id="Waiting_Room_Provider_Waiting_ALL_title" />
                                                                <Box className={`${classes.checkBoxcount} ${allSelected ? 'checked' : ''}`} >
                                                                    {allRecipientsCount}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    }
                                                />
                                            </Box>
                                            <Box className={`${classes.deviceBox} ${isCurrentProviderSelected ? 'checked' : ''}`}>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={isCurrentProviderSelected}
                                                        onChange={e=>handleDropdown(e,currentProviderDetails)}
                                                        name={currentProviderDetails.firstName}
                                                        value={currentProviderDetails.id}
                                                        className={`${classes.checkBox} ${isCurrentProviderSelected ? 'checked' : ''}`}
                                                        id="QA_Waitingroom_CheckBox"

                                                    />
                                                }
                                                    className={classes.customLabel}
                                                    label={
                                                        <Box className={classes.checkBoxTextSection}>
                                                            <Box>
                                                                <Typography>{`${currentProviderDetails.prefix ? currentProviderDetails.prefix : ''} ${currentProviderDetails.firstName} ${currentProviderDetails.lastName}`} </Typography>
                                                            </Box>
                                                            <Box className={classes.checkBoxDesc}>
                                                                
                                                                <Box className={`${classes.checkBoxcount} ${isCurrentProviderSelected ? 'checked' : ''}`} >
                                                                    {getCurrentProviderCount()}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    }
                                                />
                                            </Box>
                                            </>
                                        }
                                        {
                                            !searchNotFound ?
                                                <>
                                                    <Divider variant="middle" className={classes.divider} />
                                                    {searchedRecipients?.map(({ count, providerProfileInfo }: FilterProviderDetails) => {
                                                        const { prefix, firstName, lastName, id } = providerProfileInfo;
                                                        if (id === currentProviderDetails.id && !searchText) {
                                                            return null
                                                        }
                                                        const isCheckedStatus = isChecked(id);
                                                        return (
                                                            <Box key={id} className={`${classes.deviceBox} ${isCheckedStatus ? 'checked' : ''}`} >
                                                                <FormControlLabel control={
                                                                    <Checkbox
                                                                        checked={isCheckedStatus}
                                                                        onChange={e => handleDropdown(e, providerProfileInfo)}
                                                                        name={firstName}
                                                                        value={id}
                                                                        className={`${classes.checkBox} ${isCheckedStatus ? 'checked' : ''}`}
                                                                        id="QA_Waitingroom_CheckBox"

                                                                    />
                                                                }
                                                                    className={classes.customLabel}

                                                                    label={
                                                                        <Box className={classes.checkBoxTextSection}>
                                                                            <Box>
                                                                                <Typography>
                                                                                    {`${prefix ? prefix : ''} ${firstName} ${lastName}`}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className={classes.checkBoxDesc}>
                                                                                {/* {item.desc} */}
                                                                                <Box className={`${classes.checkBoxcount} ${isCheckedStatus ? 'checked' : ''}`}>
                                                                                    {count}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </Box>
                                                        )
                                                    })
                                                    }

                                                </> : <Box className={classes.providerNotFound}>
                                                    <SearchIcon className={classes.searchIcon} />
                                                    <Typography className={classes.providerNotFoundText}>
                                                        <FormattedMessage id="Waiting_Room_Provider_Not_Found" />
                                                    </Typography>
                                                </Box>
                                        }

                                        </>}
                                    </ListItem>
                                </Box>
                            </List>
                            <Box className={classes.traingle} />
                        </Box>}
                </Box>
            </ClickAwayListener>
        </Box>
    )
}

export default ProviderFilter