import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING } from "../../../theme";

export const useStyles = makeStyles(() => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    wrapper: {
        minWidth: "1145px",
        minHeight: "650px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    contentArea: {
        display: 'flex',
        flex: '1 1 auto'
    },
    leftPaneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        maxWidth: '300px',
        minHeight: '494px',
        zIndex: 2,
        position: "relative",
        background: "#E4E4E6",
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.05), inset -1px 0px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: `0 0 0 0`,
        width: SIZING.SIDE_NAV_OPEN_WIDTH,
    },
    rightContent: {
        flexGrow: 1,
        overflowY: 'hidden',
        padding: '32px 32px 32px 28px',
        background: '#F6F8FA'
    },
    dialogTitle: {
        minHeight: '68px',
        padding: '5px 32px',
        justifyContent: "flex-start"
    },
    dialogAction: {
        minHeight: '84px',
        padding: '5px 32px',
        justifyContent: "center"
    },
    dialogContentRoot: {
        flex: '1 1 auto',
        overflowY: 'auto',
    },
    dialogContentDividers: {
        padding: '0 0 0 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    dialogHeader: {
        width: '1145px',
        display: 'flex',
        flexDirection: 'column'
    },
    dialogActionBar: {
        width: '1145px',
        display: 'flex'
    },
    dialogHeaderTop: {
        display: 'flex'
    },
    dialogHeaderTitle: {
        flex: 1,
        fontSize: '17px',
        color: '#565662',
        fontWeight: 700
    },
    dialogHeaderText: {
        color: '#565662',
        fontWeight: 600,
        fontSize: '16px'
    },
    orgIcon: {
        height: '24px',
        width: '24px',
        verticalAlign: 'middle',
        marginLeft: '-5px',
        marginRight: '5px'
    },
    chevRightIcon: {
        height: '24px',
        width: '24px',
        verticalAlign: 'middle',
        marginLeft: '10px',
        marginRight: '10px'
    },
    dialogHeaderSaveButton: {
        marginLeft: '10px'
    },
    sideNavOption: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: SIZING.scale1200,
        minWidth: SIZING.scale1200,
        padding: SIZING.scale300,
        margin: "10px",
        borderRadius: BORDER_RADIUS.PILL,
        width: 'auto'
    },
    selected: {
        background: 'none !important',
        color: '#0F7BBD'
    },
    circleNumber: {
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: 400,
        letterSpacing: "0.03333em",
        textDecoration: "inherit",
        textTransform: "inherit",
        color: "white",
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        marginRight: "8px",
        background: "#565662",
        "&:selected": {
            backgroundColor: '#0F7BBD'
        },
        "&:hover": {
            backgroundColor: '#0F7BBD'
        }
    },
    verticalLine: {
        borderLeft: '1px solid #0F7BBD',
        left: "9.5%",
        content: "",
        position: 'absolute',
        height: "25px",
        top: "40px"
    },
    closeButtonIcon: {
        height: '45px',
        width: '45px',
        verticalAlign: 'middle',
    },
    stepperRoot: {
        padding: '5px 32px',
        backgroundColor: '#E4E4E6',
        "& .MuiStepIcon-completed": {
            color: '#0F7BBD !important'
        },
        "& .MuiStepIcon-active": {
            color: '#0F7BBD !important'
        },
        "& .MuiStepConnector-lineVertical": {
            minHeight: '42px',
            borderLeftWidth: "2px"
        },
        "& .MuiStepConnector-vertical": {
            padding: 0
        },
        "& .MuiStepConnector-completed span": {
            borderColor: '#0F7BBD'
        },
        "& .MuiStep-vertical span": {
            padding: 0
        }
    },
    stepperIconCompleted: {
        color: '#0F7BBD !important',
    },
    stepperLabel: {
        paddingLeft: '10px'
    }
}));