import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export const setActiveModal = (modalId: string, modalProps?: any) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'ADMIN_SET_ACTIVE_MODAL', payload: { activeModal: modalId, modalProps } })
    }
}
export const setWelcomeModalActive = (isWelcomeModalActive: boolean) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'ADMIN_SET_WELCOME_MODAL_ACTIVE', payload: { isWelcomeModalActive } })
    }
}