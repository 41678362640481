export const CareTeam = "CareTeam";
export const CareTeamDetailsContent = `${CareTeam}__DetailsContent`;
export const CareTeamDetailsContentMembersEmptyMessage = `${CareTeamDetailsContent}__MembersEmptyMessage`;
export const CareTeamDetailsContentSearchField = `${CareTeamDetailsContent}__SearchField`;
export const CareTeamDetailsContentSearchFieldStartIcon = `${CareTeamDetailsContentSearchField}__StartIcon`;
export const CareTeamDetailsHeader = `${CareTeam}__DetailsHeader`;
export const CareTeamDetailsHeaderSubTitle = `${CareTeamDetailsHeader}__SubTitle`;
export const CareTeamDetailsHeaderTitle = `${CareTeamDetailsHeader}__Title`;
export const CareTeamEditor = `${CareTeam}__Editor`;
export const CareTeamEditorContent = `${CareTeamEditor}__Content`;
export const CareTeamEditorHeader = `${CareTeamEditor}__Header`;
export const CareTeamEditorHeaderPlaceholder = `${CareTeamEditorHeader}__Placeholder`;
export const CareTeamEditorPatients = `${CareTeamEditor}__Patients`;
export const CareTeamEditorPatientsEmptyMessage = `${CareTeamEditorPatients}__EmptyMessage`;
export const CareTeamEditorPatientsSearchList = `${CareTeamEditorPatients}__SearchList`;
export const CareTeamEditorProviderSearchEmptyMessage = `${CareTeamEditor}__ProviderSearch__EmptyMessage`;
export const CareTeamEditorResultListPagination = `${CareTeamEditor}__ResultList__Pagination`;
export const CareTeamSearchHeader = `${CareTeam}__SearchHeader`;
export const CareTeamSearchHeaderAdd = `${CareTeamSearchHeader}__Add`;
export const CareTeamSearchHeaderSearchField = `${CareTeamSearchHeader}__SearchField`;
export const CareTeamSearchHeaderSearchFieldEndIcon = `${CareTeamSearchHeaderSearchField}__EndIcon`;
export const CareTeamSearchHeaderSearchFieldStartIcon = `${CareTeamSearchHeaderSearchField}__StartIcon`;
export const CareTeamSearchHeaderTabs = `${CareTeamSearchHeader}__Tabs`;
export const CareTeamSearchList = `${CareTeam}__SearchList`;
export const CareTeamSearchListEmptyMessage = `${CareTeamSearchList}__EmptyMessage`;
export const CareTeamSearchListItem = `${CareTeamSearchList}__Item`;
