import { useStyles } from "./styles";
import classNames from "classnames";
import React from "react";

type PageProps = {
    className?: string;
};

export const Page: React.FC<PageProps> = ({ children, className }) => {
    const styles = useStyles();
    return <div className={classNames(styles.Page, className)}>{children}</div>;
};
