import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { ReactComponent as WarningDiamond } from "icon-library/SVG/Warning_Diamond.svg";
import { ReactComponent as Lock } from "icon-library/SVG/Lock.svg";
import { useDateFormatters } from "common/utils/use-date-formatter";
import { useIntl } from "react-intl";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { getDaysLeft } from "./utils";
import { EConsultRequestPreviewDto } from "./types";
import { useStyles } from "./styles";

export interface SearchItemProps extends EConsultRequestPreviewDto {
    closed?: boolean;
    idPrefix: string;
}

const SearchItem: React.FC<SearchItemProps> = ({
    deadlineDate,
    idPrefix,
    lastModifiedDate,
    notes,
    children,
    referrerFullName,
    subject,
    urgent,
    closed,
    overdue,
}) => {
    const classes = useStyles();
    const dateFormatters = useDateFormatters();
    const daysLeft = getDaysLeft(deadlineDate);
    const intl = useIntl();

    return (
        <div className={classNames(classes.eConsult__folderItem, "eConsult__folderItem")}>
            <span id={`${idPrefix}_subject`} className="eConsult__folderItem__title">
                {subject}
            </span>
            <div className="eConsult__folderItem__icon eConsult__folderItem--warning">
                {closed && <SVGIcon svg={Lock} size="scale600" id={`${idPrefix}_closed`} />}
                {urgent && !closed && <SVGIcon svg={WarningDiamond} size="scale600" id={`${idPrefix}_urgent`} />}
            </div>
            <span id={`${idPrefix}_referrer`} className="eConsult__folderItem__referrerName">
                {referrerFullName}
            </span>
            <span
                id={`${idPrefix}_timeSinceSending`}
                className="eConsult__folderItem__time"
                title={`${intl.formatMessage({ id: "EConsult.request.content.lastModified" })} ${dateFormatters[
                    "MMM DD, YYYY, hh:mm aa"
                ](lastModifiedDate)}`}
            >
                {dateFormatters["nicetime"](lastModifiedDate)}
            </span>
            <span id={`${idPrefix}_notes`} className="eConsult__folderItem__content">
                {notes}
            </span>
            {daysLeft !== null && !overdue && !closed && (
                <span
                    id={`${idPrefix}_deadline`}
                    className={classNames("eConsult__folderItem__expiry", {
                        "eConsult__folderItem--warning": daysLeft === 0,
                    })}
                >
                    <FormattedMessage id="EConsult.request.item.diffInDays" values={{ diffInDays: daysLeft }} />
                </span>
            )}
            {overdue && !closed && (
                <span id={`${idPrefix}_overdue`} className="eConsult__folderItem__expiry eConsult__folderItem--warning fontWeight--bold">
                    <FormattedMessage id="EConsult.request.item.overdue" />
                </span>
            )}
            {children}
        </div>
    );
};

export default SearchItem;
