import { DialogContent, Box, Button, DialogActions, Typography, TextField, IconButton } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { MODAL_TYPES } from "admin/constant";
import { Fragment, useState } from "react";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
// import { useRouteMatch } from "react-router-dom";
import { updateQuestionnaireApprovalReview } from "admin/store/actions";

const RejectFormModal = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    // const matchId: any = useRouteMatch("/admin/configurations/patientforms/:id");
    // const formId = matchId?.params?.id ? matchId?.params?.id : null;  
    const { auth, configuration } = useSelector((state: AppReduxStore) => state);
    const currentForm: any = configuration && configuration.sPatientFormA ? configuration.sPatientFormA : null;
    const classes = useStyles();
    const [text, setText] = useState('');
    const approverProfileId = auth && auth.currentProfiles !== null ? auth.currentProfiles[0].providerProfile !== null ? auth.currentProfiles[0].providerProfile?.id : "" : "";
    const userId = auth && auth.userDetails !== null ? auth.userDetails.profileRoleJoinInfos[0].providerProfile !== null ? auth.userDetails.profileRoleJoinInfos[0].providerProfile.id : "" : "";
    const allFormVersioning = configuration.allFormVersioning;

    const onClose = () => {
        dispatch(setActiveModal(''));
    }

    const onCreate = (event: any) => {
        event.preventDefault();
        const formApprovalId: any =
            allFormVersioning.length !== 0
                ? allFormVersioning.find((ele: any) => ele.formStatus === "PENDING_APPROVAL")?.questionnaireApprovals[0]
                      ?.id
                : "";
        const questionnaireApproval = {
            "id": formApprovalId,
            "approvalStatus": "REJECTED",
            "approverProfileId": approverProfileId,   //- profile id
            "createdBy": userId, //currently logged in user id
            "questionnaireId": currentForm.id, //required form id
            "reviewComments": text//optional but nice to have You do not need to supply the name fields.
        }
        dispatch(updateQuestionnaireApprovalReview(questionnaireApproval))
        setText("");
        onClose();
    };

    const modalClasses = {
        paper: classes.wrapper
    };

    return (
        <Modal id={MODAL_TYPES.REJECT_MODAL} onClose={onClose} classes={modalClasses} small={true}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "RejectFormModal.Title" })}
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {currentForm?.number && `# ${currentForm?.number} - `}
                        {`${currentForm?.title}?`}
                    </Typography>
                </Box>
                <IconButton onClick={onClose} size="large">
                    <Close/>
                </IconButton>
            </DialogActions>
            <DialogActions className={classes.dlgAction2}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCreate" variant="contained" disabled={text!==''? false : true}
                            aria-haspopup="true" onClick={onCreate}>
                            {intl.formatMessage({ id: "RejectFormModal.RejectButton" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                    <Typography variant="h6" gutterBottom>
                        {intl.formatMessage({ id: "RejectFormModal.Header" })}
                    </Typography>
                    <Typography variant="h6" gutterBottom className={classes.header2}>
                        {intl.formatMessage({ id: "RejectFormModal.Comment" })}
                    </Typography>
                    <TextField rows={3} multiline fullWidth id="comments" value={text} onChange={(e: any) => setText(e.target.value)} placeholder="Enter reasons for rejecttion" variant="outlined" />
                    <Typography variant="h6" gutterBottom className={classes.footer}>
                        {intl.formatMessage({ id: "RejectFormModal.Footer" })}
                    </Typography>
            </DialogContent>
        </Modal>
    )
}

export default RejectFormModal;