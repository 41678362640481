import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from "@mui/material";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import { ReactComponent as FlagSvg } from "icon-library/SVG/Flag.svg";
import { createSvgIcon } from "../../../../utils";
import { Under18PatientConsentProps } from "./types";
const FlagIcon = createSvgIcon(FlagSvg);

const Under18PatientConsent = ({ isUnder18ConsentModalOpen, isBulkSendInviteAction, onIConfirmSendInvite, onSendInvitesAbove18Only, onUnder18ConsentModalClose }: Under18PatientConsentProps) => {
    const classes = useStyles()
    const handleClose = (event: Event, reason: string) => {
        if (reason !== 'backdropClick') {
            onUnder18ConsentModalClose();
        }
    };
    return (
        <Dialog maxWidth={"sm"} open={isUnder18ConsentModalOpen} onClose={handleClose} classes={{ ...classes, paper: classes?.wrapper }}>
            <DialogActions>
                <Box flexGrow={1} style={{ paddingLeft: '32px' }}>
                    <Typography variant="body2" className={classes.dlgTitle}>
                        <FormattedMessage id={"Under18PatientConsent.title"} />
                    </Typography>

                </Box>
                <Box>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} className={classes.dlgContent}>
                <Box display="flex">
                    <Typography variant="h3" className={classes.dlgHeading}>
                        <FormattedMessage id={"Under18PatientConsent.Heading"} />
                    </Typography>
                    <FlagIcon fill={"#FF0000"} className={classes.flagIcon} />
                </Box>
                <Typography variant="h3" className={classes.dlgText}>
                    <FormattedMessage id={"Under18PatientConsent.BodyTextLine1"} />
                </Typography>
                <Typography variant="h3" className={classes.dlgText}>
                    ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id={"Under18PatientConsent.BodyTextLine2"} />
                </Typography>
                <Typography variant="h3" className={classes.dlgText}>
                    ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id={"Under18PatientConsent.BodyTextLine3"} />
                </Typography>
                <Box className={classes.bottomActions}>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined" color="primary" style={{ marginLeft: '10px', backgroundColor: "#E32B29" }} onClick={(event: any) => { handleClose(event, 'Cancel') }}>
                            Cancel
                        </Button>
                        <Button id="btnIConfirm" variant="outlined" color={isBulkSendInviteAction ? "primary": "secondary" } style={{ marginLeft: '10px', backgroundColor: isBulkSendInviteAction ? "#FFFFFF" : "#0F7BBD" }} onClick={onIConfirmSendInvite}>
                            I Confirm
                        </Button>
                        {isBulkSendInviteAction && <Button id="btnOnlyAbove18" variant="outlined" color={isBulkSendInviteAction ? "secondary": "primary" } style={{ marginLeft: '10px' }} onClick={onSendInvitesAbove18Only}>
                            Only send to &gt;18
                        </Button>}
                    </Fragment>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default Under18PatientConsent