import { makeStyles } from "@mui/styles";
import { SIZING } from "admin/theme";
import { Theme } from "@mui/material";

export const useStyles = makeStyles<Theme>(theme=>({
    landingArea: {
        width: "100%",
        height: "100%",
        display: "flex",
        minHeight: "100vh"
    },
    boxLeft: {
        display: "flex",
        alignItems:"center",
        flex: 1,
        minHeight: "100vh",
        paddingLeft: SIZING.scale1500,
        clipPath: "polygon(0 0, 100% 0, calc(100% - 10vh) 100vmax, 0 100vmax)",
        background: 'linear-gradient(96deg, #0F7BBD 40% ,#0B5D8F 84.8%, transparent 85%)',
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    },
    boxRight: {
        display: "flex",
        justifyContent:"center",
        alignItems:"center",
        flex: 1,
        padding: ".5rem 1rem"
    },
    boxRightInner: {
        width: '430px',
        margin: 'auto',
        textAlign: 'center'
    },
    heading: {
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '2rem',
        whiteSpace:'pre-line'
    },
    subHeading: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '.5rem',
        marginBottom: '2rem',
    }
}))