import { Button, Grid, Typography } from "@mui/material";
import Controls from "admin/components/Controls/Controls";
import { PhoneMask } from "admin/components/Controls/InputMasked";
import { useStyles } from './styles';
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as AdminBuilding } from "@altais/icon-library/legacy/SVG/Admin-Building_Wide.svg";
import { createSvgIcon } from "../../../../utils";
import UserLevelDetailsMenu from "admin/components/UserLevelDetailsMenu/UserLevelDetailsMenu";
import React, { useEffect, useState } from "react";

const AdminBuildingIcon = createSvgIcon(AdminBuilding);
const AddPracticeAdmin = (props: any) => {
    const { handlePracticeAdminInputChange, practiceAdminFormData, errorsPracticeAdmin, isInputDisabled, handleInputBlur } = props;
    const classes = useStyles();
    const [adminUserLevels, setAdminUserlevels] = useState([] as any)
    const { admin, auth } = useSelector((state: AppReduxStore) => state)
    const intl = useIntl()
    useEffect(() => {
        const allAdminUsersLevels = admin.allUserLevels && admin.allUserLevels.filter((u: any) => u.type !== "PATIENT" && u.type !== "SUPPORT_CONTACT" && u.name === "PRACTICE_ADMIN").map((ul: any) => {
            return { ...ul, title: ul.description }
        });
        let usersLevels = admin.userLevels && admin.userLevels.filter((u: any) => u.type !== "PATIENT" && u.type !== "ADMIN" && u.name === "PRACTICE_ADMIN").map((ul: any) => {
            return { ...ul, title: ul.description }
        });
        if (auth.currentOrg?.id) {
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.name?.indexOf('ALTAIS_ADMIN') === -1)
            const hasPracticeAdmin = usersLevels && usersLevels.find((u: any) => u.name === "PRACTICE_ADMIN");
            if (auth.currentProfileRoleJoinInfo?.level?.name === "PRACTICE_ADMIN") {
                if (!hasPracticeAdmin) {
                    usersLevels.push(allAdminUsersLevels && allAdminUsersLevels.find((u: any) => u.name === "PRACTICE_ADMIN"))
                }
            }

            setAdminUserlevels(usersLevels)
        }

    }, [auth.currentOrg, admin.userLevels, admin.allUserLevels, auth.currentProfileRoleJoinInfo?.level?.name]);

    if (practiceAdminFormData && !practiceAdminFormData.levelId) {
        const hasPracticeAdmin = adminUserLevels.find((u: any) => u.name === "PRACTICE_ADMIN");

        if (hasPracticeAdmin) {
            practiceAdminFormData.levelId = adminUserLevels && adminUserLevels.find((u: any) => u.name === "PRACTICE_ADMIN")?.id
            practiceAdminFormData.userType = adminUserLevels && adminUserLevels.find((u: any) => u.name === "PRACTICE_ADMIN")?.type
        }
    }
    const handleMakeMeAdministrator = (e: any) => {
        e && e.preventDefault();
        handlePracticeAdminInputChange({
            target: {
                name: 'email',
                value: auth.currentProfileRoleJoinInfo?.profileEmail
            }
        })
        handleInputBlur({
            target: {
                name: 'email',
                value: auth.currentProfileRoleJoinInfo?.profileEmail
            }
        })
    }
    const handleClearInfo = (e: any) => {
        practiceAdminFormData.email = ""
        practiceAdminFormData.primaryPhone = ""
        practiceAdminFormData.mobilePhone = ""
        practiceAdminFormData.firstName = ""
        practiceAdminFormData.middleName = ""
        practiceAdminFormData.lastName = ""
        practiceAdminFormData.npi = ""
        practiceAdminFormData.ehrUsername = ""
        practiceAdminFormData.levelId = ""
        handleInputBlur({
            target: {
                name: 'email',
                value: ""
            }
        })
    }
    return (
        <>
            <Grid item className={classes.pageHeader}>
                <Typography className={classes.pageHeaderText}>
                    <AdminBuildingIcon fill="#0F7BBD" className={classes.administratorIcon} />
                    <FormattedMessage id="AddPracticeAdminForm.Heading.Text" />
                </Typography>
                <Typography className={classes.pageSubHeaderText}>
                    <FormattedMessage id="AddPracticeAdminForm.SubHeading.Text" />
                </Typography>
            </Grid>

            <Grid item className={classes.sectionHeader2}>
                <Typography className={classes.sectionHeaderText}>
                    Contact
                    {!isInputDisabled && <Button id="btnMakeMeAdministrator" variant="outlined" className={classes.btnMakemeAdministrator}
                        onClick={handleMakeMeAdministrator}>
                        <FormattedMessage id="AddPracticeAdminForm.Button.MakeMeAdminstrator.Text" />
                    </Button>}
                    {isInputDisabled && <Button id="btnClearFields" variant="outlined" className={classes.btnMakemeAdministrator}
                        onClick={handleClearInfo}>
                        <FormattedMessage id="AddPracticeAdminForm.Button.Clear.Text" />
                    </Button>}
                </Typography>
            </Grid>
            <Grid>

            </Grid>
            <Grid
                container
                direction="row"
                item
                className={classes.sectionFieldRow}>
                <Grid item xs={3} className={classes.marginRight10}>
                    <div>
                        <Controls.Input
                            name="email"
                            label="Email *"
                            value={practiceAdminFormData.email}
                            onChange={handlePracticeAdminInputChange}
                            onBlur={handleInputBlur}
                            error={errorsPracticeAdmin.email}
                            maxLength={255}
                            placeholder="Enter Here"
                        />
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.marginRight10}>
                    <Controls.Input
                        name="primaryPhone"
                        label="Primary Phone"
                        onChange={handlePracticeAdminInputChange}
                        placeholder="(###) ### - ####"
                        value={practiceAdminFormData.primaryPhone}
                        error={errorsPracticeAdmin.primaryPhone}
                        inputComponent={PhoneMask}
                        disabled={isInputDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controls.Input
                        name="mobilePhone"
                        label="Mobile Phone"
                        onChange={handlePracticeAdminInputChange}
                        placeholder="(###) ### - ####"
                        value={practiceAdminFormData.mobilePhone}
                        error={errorsPracticeAdmin.mobilePhone}
                        inputComponent={PhoneMask}
                        disabled={isInputDisabled}
                    />
                </Grid>
            </Grid>
            {
                isInputDisabled ?
                    <Grid
                        container
                        direction="row"
                        item
                        className={classes.userExistsMsg}>
                        {intl.formatMessage({ id: "AddPracticeAdminForm.UserAlreadyExist.Text" })}
                    </Grid> : " "
            }
            <Grid container direction="column">
                <Grid item className={classes.sectionHeader}>
                    <Typography className={classes.sectionHeaderText}>
                        Personal
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    item
                    justifyContent="flex-start"
                    className={classes.sectionFieldRow}
                >
                    <Grid item xs={3} className={classes.marginRight10}>
                        <Controls.Input
                            name="firstName"
                            label="First Name *"
                            onChange={handlePracticeAdminInputChange}
                            placeholder="Enter Here"
                            value={practiceAdminFormData.firstName}
                            error={errorsPracticeAdmin.firstName}
                            disabled={isInputDisabled}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.marginRight10}>
                        <Controls.Input
                            name="middleName"
                            label="Middle Name"
                            onChange={handlePracticeAdminInputChange}
                            placeholder="Enter Here"
                            value={practiceAdminFormData.middleName}
                            disabled={isInputDisabled}

                        />
                    </Grid>
                    <Grid item xs={3} className={classes.marginRight10}>
                        <Controls.Input
                            name="lastName"
                            label="Last Name *"
                            onChange={handlePracticeAdminInputChange}
                            placeholder="Enter Here"
                            value={practiceAdminFormData.lastName}
                            error={errorsPracticeAdmin.lastName}
                            disabled={isInputDisabled}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            Professional
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        item
                        justifyContent="flex-start"
                        className={classes.sectionFieldRow}
                    >
                        <Grid item xs={3} className={classes.marginRight10}>
                            <Controls.Input
                                name="npi"
                                label="NPI"
                                value={practiceAdminFormData.npi}
                                onChange={handlePracticeAdminInputChange}
                                error={errorsPracticeAdmin.npi}
                                maxLength={10}
                                placeholder="Enter Here" />
                        </Grid>
                        <Grid item xs={3} className={classes.marginRight10}>
                            <Controls.Input
                                name="ehrUsername"
                                label="EHR User Name"
                                value={practiceAdminFormData.ehrUsername}
                                onChange={handlePracticeAdminInputChange}
                                error={errorsPracticeAdmin.ehrUsername}
                                maxLength={255}
                                placeholder="Enter Here" />
                        </Grid>
                        <Grid item xs={3} className={classes.marginRight10}>
                            <Controls.Select
                                name="levelId"
                                label="User Level *"
                                value={practiceAdminFormData.levelId}
                                onChange={handlePracticeAdminInputChange}
                                options={adminUserLevels}
                                error={errorsPracticeAdmin.levelId}
                                altContent={adminUserLevels ? <UserLevelDetailsMenu userLevels={adminUserLevels} /> : null}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AddPracticeAdmin;