import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import * as CSS from "./class-names";

type SearchListItemProps = {
    className?: string;
    id: string;
    onClick: () => void;
    selected?: boolean;
    href?: string;
};

const SearchListItem: React.FC<SearchListItemProps> = ({ children, className, id, onClick, selected, href }) => {
    const rootProps = {
        id,
        className: classNames(CSS.SearchListItem, className, {
            [CSS.SearchListItemSelected]: selected,
        }),
        onClick,
    };

    if (href) {
        return (
            <NavLink
                to={href}
                {...rootProps}
                className={(isActive) => classNames(rootProps.className, { [CSS.SearchListItemSelected]: isActive })}
            >
                {children}
            </NavLink>
        );
    }

    return <div {...rootProps}>{children}</div>;
};

export default SearchListItem;
