import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as FilePreviewIcon } from 'icon-library/SVG/File_Preview.svg';
import { ReactComponent as PenIcon } from 'icon-library/SVG/Pen.svg';
import { ReactComponent as TrashIcon } from 'icon-library/SVG/Trash.svg';
import { Fragment } from "react";
import { useIntl } from 'react-intl';
import { useStyles } from './styles';

export type FeeSchedulesGridContextMenuContentProps = {
    callback: any,
    handleClose: any
}

const FeeSchedulesGridContextMenu = ({ callback, handleClose }: FeeSchedulesGridContextMenuContentProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Fragment>
            <ListItem button>
                <ListItemIcon>
                    <FilePreviewIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "fee.schedule.listing.context.menu.preview.document" })} classes={{ primary: classes.listText }} onClick={() => { callback("PreviewFeeScheduleDocument"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <PenIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "fee.schedule.listing.context.menu.view.edit" })} classes={{ primary: classes.listText }} onClick={() => { callback("ViewEditFeeSchedule"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <TrashIcon width={24} className={classes.deleteIconRed} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "fee.schedule.listing.context.menu.delete" })} classes={{ primary: classes.listTextRed }} onClick={() => { callback("DeleteFeeSchedule"); handleClose() }} />
            </ListItem>
        </Fragment>
    );
}

export default FeeSchedulesGridContextMenu;
