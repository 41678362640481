import {
    Box, IconButton, Button, DialogActions, DialogContent,
    List, ListItem, ListItemIcon, ListItemText, Paper, Typography
} from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { ADMIN_WEB_CACHE, MODAL_TYPES } from "admin/constant";
import { insertPractice, setActiveModal, updateWebAdminCachePractice } from "admin/store/actions";
import React, { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AddPracticeForm from "./AddPracticeForm";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { useSnackbar } from "notistack";
import SnackbarContent from "../../SnackBars/SnackbarContent";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as CheckMark } from "icon-library/SVG/Checkmark.svg";
import { ReactComponent as Buildings } from "@altais/icon-library/legacy/SVG/Buildings.svg";
import { ReactComponent as BuildingWide } from "@altais/icon-library/legacy/SVG/Building_Wide.svg";
import { ReactComponent as ChevronRight } from "@altais/icon-library/legacy/SVG/Chevron_Right.svg";
import { ReactComponent as EllipsisHorizontal } from "@altais/icon-library/legacy/SVG/Ellipsis-Horizontal.svg";
import { createGUIDString, validateNoSpecialCharacters, validatePostalCode, validateRequired, validateEmail } from "admin/common/utils";
import AddPracticeProducts from "./AddPracticeProducts";
import AddPracticeAdmin from "./AddPracticeAdmin";
import AddPracticeEHRConnection from "./AddPracticeEHRConnection";
import { showNotification } from "admin/store/actions/snackbar";
import { call } from "admin/store/api";
import { AppReduxStore } from "admin/store/reducerTypes";
import { Permissions } from "types";

const initialPracticeInfoVal = {
    address1: "",
    address2: "",
    city: "",
    id: "",
    name: "",
    organizationId: "",
    postalCode: "",
    patientPortalUrl: "",
    schema: "",
    state: "",
    secretCode: "",
    tin: "",
    absorbDepartmentId: ""
}
const initialPracticeAdminVal = {
    firstName: "",
    middleName: "",
    lastName: "",
    mobilePhone: "",
    primaryPhone: "",
    email: "",
    levelId: "",
    npi: "",
    ehrUsername: "",
    ehrProviderId: "",
    userType: ""
}

const AddPractice = () => {
    const [selectedAddPracticeStepIndex, setSelectedAddPracticeStepIndex] = useState(1);
    const [skipPracticeAdmin, setSkipPracticeAdmin] = useState(false);
    const { auth, modalAdmin, license } = useSelector((state: AppReduxStore) => state);
    const { admin, navigation } = useSelector((state: AppReduxStore) => state);
    const { adminWebCachePractice } = admin
    const [practiceInfoFormData, setPracticeInfoFormData] = useState(initialPracticeInfoVal);
    const [practiceAdminFormData, setPracticeAdminFormData] = useState(initialPracticeAdminVal);
    const [errorsPracticeInfo, setErrorsPracticeInfo] = useState({} as any);
    const [errorsPracticeAdmin, setErrorsPracticeAdmin] = useState({} as any);
    const refAddPracticeEHRConnection = useRef<any>()
    const dispatch = useDispatch()
    const intl = useIntl()
    const classes = useStyles(selectedAddPracticeStepIndex === 1)
    const CrossIcon = createSvgIcon(CrossSvg)
    const CheckIcon = createSvgIcon(CheckMark)
    const BuildingIcon = createSvgIcon(Buildings);
    const BuildingWideIcon = createSvgIcon(BuildingWide);
    const ChevronRightIcon = createSvgIcon(ChevronRight);
    const { enqueueSnackbar } = useSnackbar()
    const { allProducts, orgLicenses } = license

    const [isInputDisabled, setInputDisabled] = useState(false);
    const emailDataObj: any = {}
    const [existingUserEmailObj, setExistingUserEmailObj] = useState(emailDataObj);
    const [isCoreProductSelected, setCoreProductSelected] = useState(false);

    const productsPractice = [...allProducts].map((p: any) => {
        const org = orgLicenses && orgLicenses.find((o: any) => o.product?.id === p.id);
        return {
            id: p.id,
            name: p.name,
            code: p.code,
            parentId: p.parentId,
            licenseCount: p.unlimited ? "Unlimited" : 0,
            isChecked: false,
            disabledSwitch: false,
            availableLicenseCount: p.unlimited ? "Unlimited" : org?.licenseCount - org?.usedLicenseCount,
        };
    });

    const [practiceProductsFormData, setPracticeProductsFormData] = useState(productsPractice);
    const pendingPracticeTemporaryId = modalAdmin?.modalProps?.pendingPracticeTemporaryId ? modalAdmin.modalProps.pendingPracticeTemporaryId : ""

    const onClose = () => {
        dispatch(setActiveModal(""))
        clearAllForms()
    }
    const handleClose = () => {
        onClose();
    };
    useEffect(() => {
        if (auth.currentOrg && modalAdmin.activeModal === MODAL_TYPES.ADD_PRACTICE) {
            if (pendingPracticeTemporaryId) {
                const tempFormData = adminWebCachePractice?.formData[pendingPracticeTemporaryId]?.tempFormData
                setPracticeInfoFormData(tempFormData?.practiceInfoFormData)
                setPracticeAdminFormData(tempFormData?.practiceAdminFormData)
                setPracticeProductsFormData(tempFormData?.practiceProductsFormData)
                setSelectedAddPracticeStepIndex(4 - tempFormData?.pendingSteps);
                if (tempFormData?.practiceProductsFormData) {
                    validateProducts(tempFormData?.practiceProductsFormData, false)
                }
            } else {
                setPracticeProductsFormData(productsPractice)
            }
        }
    }, [auth.currentOrg, adminWebCachePractice?.formData, modalAdmin, dispatch, pendingPracticeTemporaryId]);

    const handleAddPracticeStepClick = (event: any, index: any, skipStep?: boolean) => {
        if (skipStep) {
            setErrorsPracticeAdmin({})
        }
        if (index === 2) {
            setSkipPracticeAdmin(false);
            const invalidOrgInfo = !validatePracticeInfo(practiceInfoFormData)
            if (invalidOrgInfo) {
                dispatch(showNotification("Error", "error", intl.formatMessage({ id: "AddOrg.RequiredField.Missing" })));
                return
            }
        }
        else if (index === 3) {
            if (!(skipStep || skipPracticeAdmin)) {
                const invalidAdminInfo = !validatePracticeAdmin(practiceAdminFormData)
                if (invalidAdminInfo) {
                    dispatch(showNotification("Error", "error", intl.formatMessage({ id: "AddOrg.RequiredField.Missing" })));
                    return
                }
            }
        }
        else if (index > 3) {
            const invalidProductInfo = !validateProducts(practiceProductsFormData, false)
            if (invalidProductInfo) {
                dispatch(showNotification("Error", "error", intl.formatMessage({ id: "OrgProductsEdit.InvalidProductCombination" })));
                return
            }
        }

        setSelectedAddPracticeStepIndex(index);
    };

    const clearAllForms = () => {
        setPracticeInfoFormData(initialPracticeInfoVal);
        setPracticeAdminFormData(initialPracticeAdminVal);
        setErrorsPracticeInfo({})
        setErrorsPracticeAdmin({})
        setSelectedAddPracticeStepIndex(1)
        setInputDisabled(false)
        setSkipPracticeAdmin(false)
    };

    const onPracticeAdd = (updated: boolean) => {
        if (updated) {
            const formData = adminWebCachePractice?.formData;
            delete formData[pendingPracticeTemporaryId]
            dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
            clearAllForms()
            dispatch(setActiveModal(""))
            dispatch(setActiveModal(MODAL_TYPES.CONFIGURE_PRACTICE))
        }
    }

    const handleSavePractice = (e: any) => {
        e.preventDefault()
        if (isInputDisabled) {
            practiceAdminFormData.primaryPhone = existingUserEmailObj.primaryPhone
            practiceAdminFormData.mobilePhone = existingUserEmailObj.mobilePhone
            practiceAdminFormData.firstName = existingUserEmailObj.firstName
            practiceAdminFormData.middleName = existingUserEmailObj.middleName
            practiceAdminFormData.lastName = existingUserEmailObj.lastName
            practiceAdminFormData.ehrProviderId = existingUserEmailObj.id
        }

        let ehrValid = true
        let ehrInfo = null
        if (refAddPracticeEHRConnection.current) {
            ehrInfo = refAddPracticeEHRConnection.current.getEHRConnectionValues()
            ehrValid = ehrInfo ? ehrInfo.valid : true
        }
        const newPracticeObj: any = {};
        if (validatePracticeInfo() && (skipPracticeAdmin ? true : validatePracticeAdmin()) && ehrValid) {
            const Buffer = require('buffer/').Buffer
            const newPracticedetails = {
                address1: practiceInfoFormData.address1,
                address2: practiceInfoFormData.address2,
                city: practiceInfoFormData.city,
                id: practiceInfoFormData.id,
                name: practiceInfoFormData.name,
                organizationId: practiceInfoFormData.organizationId || auth.currentOrg?.id,
                postalCode: practiceInfoFormData.postalCode,
                patientPortalUrl: practiceInfoFormData.patientPortalUrl,
                schema: practiceInfoFormData.schema,
                state: practiceInfoFormData.state,
                secretCode: practiceInfoFormData.secretCode ? Buffer.from(practiceInfoFormData.secretCode).toString("base64") : "",
                tin: practiceInfoFormData.tin,
                absorbDepartmentId: practiceInfoFormData.absorbDepartmentId,
                ehrPracticeId: ehrInfo?.ehrPracticeId ? ehrInfo?.ehrPracticeId : null,
                ehrSourceSystemId: ehrInfo?.ehrSourceSystemId ? ehrInfo?.ehrSourceSystemId : "",
                practiceIntegrationProperties: ehrInfo?.practiceIntegrationProperties,
                redoxDestinationId: null,
                redoxSourceId: null
            }

            const newProviderUserRequest = {
                firstName: practiceAdminFormData.firstName,
                middleName: practiceAdminFormData.middleName,
                lastName: practiceAdminFormData.lastName,
                mobilePhone: practiceAdminFormData.mobilePhone,
                primaryPhone: practiceAdminFormData.primaryPhone,
                email: practiceAdminFormData.email,
                levelId: practiceAdminFormData.levelId,
                npi: practiceAdminFormData.npi,
                ehrUsername: practiceAdminFormData.ehrUsername,
                ehrProviderId: practiceAdminFormData.ehrProviderId,
                userType: practiceAdminFormData.userType,
                isAutoInvite: true
            }
            const products = [] as any;
            practiceProductsFormData && practiceProductsFormData.filter((element: any) => element.isChecked)
                .map((p: any) => {
                    products.push({ "productId": p.id, "licenseCount": parseInt(p.licenseCount ? p.licenseCount : "0") })
                    return p
                });
            if (Object.keys(products).length > 0) {
                (newPracticedetails as any)["newPracticeProducts"] = products
            }
            newPracticeObj["createPracticeRequest"] = newPracticedetails
            if (!skipPracticeAdmin) {
                newPracticeObj["createProviderUserRequest"] = newProviderUserRequest
            }

            dispatch(insertPractice(newPracticeObj, onPracticeAdd))
            return false
        }
        enqueueSnackbar(null, {
            preventDuplicate: true, content: (key) => (<div role="alert"><SnackbarContent snackbarKey={key} type={'error'}
                message={intl.formatMessage({ id: "Error" })} desc="MISSING REQUIRED FIELD. PLEASE COMPLETE BEFORE SAVING" /></div>
            ),
        })
        return false
    };
    const handlePracticeInfoInputChange = (event: any) => {
        const { name, value } = event.target;

        setPracticeInfoFormData(state => ({
            ...state,
            [name]: value
        }));
        const fieldValue = {} as any;
        fieldValue[name] = value
        validatePracticeInfo(fieldValue)

    }
    const validatePracticeInfo = (fieldValues = practiceInfoFormData) => {
        const err = { ...errorsPracticeInfo };
        if ('name' in fieldValues) {
            err.name = validateRequired(fieldValues.name, intl)
            if (err.name === "") err.name = validateNoSpecialCharacters(fieldValues.name, intl)
        }
        if ('postalCode' in fieldValues) {
            err.postalCode = ""
            if (fieldValues.city?.trim().length > 0 && fieldValues.state?.trim().length > 0) {
                err.postalCode = validateRequired(fieldValues.postalCode, intl)
            }
            if (err.postalCode === "") err.postalCode = validatePostalCode(fieldValues.postalCode, intl)
        }
        if ('secretCode' in fieldValues) {
            err.secretCode = validateRequired(fieldValues.secretCode, intl)
        }

        setErrorsPracticeInfo({
            ...err
        })

        if (fieldValues === practiceInfoFormData)
            return Object.values(err).every(x => x === "")
    }

    const handlePracticeAdminInputChange = (event: any) => {
        const { name, value } = event.target;

        setPracticeAdminFormData(state => ({
            ...state,
            [name]: value
        }));
        const fieldValue = {} as any;
        fieldValue[name] = value
        validatePracticeAdmin(fieldValue)

    }
    const validatePracticeAdmin = (fieldValues = practiceAdminFormData) => {
        const err = { ...errorsPracticeAdmin };
        if (!skipPracticeAdmin) {
            if ('firstName' in fieldValues) {
                err.firstName = validateRequired(fieldValues.firstName, intl)
            }
            if ('lastName' in fieldValues) {
                err.lastName = validateRequired(fieldValues.lastName, intl)
            }
            if ('email' in fieldValues) {
                err.email = validateRequired(fieldValues.email, intl)
                if (err.email === "") err.email = validateEmail(fieldValues.email, intl)
            }
            if ('levelId' in fieldValues) {
                err.levelId = validateRequired(fieldValues.levelId, intl)
            }
        }
        setErrorsPracticeAdmin({
            ...err
        })

        if (fieldValues === practiceAdminFormData)
            return Object.values(err).every(x => x === "")
    }

    const handlePracticeProductsInputChange = (event: any, index: number) => {
        const { name, value } = event.target
        const newPracticeProductsFormData = [...practiceProductsFormData];
        if (name === 'productSwitch') {
            newPracticeProductsFormData[index].isChecked = !newPracticeProductsFormData[index].isChecked
        } else {
            newPracticeProductsFormData[index].licenseCount = value;
            newPracticeProductsFormData[index].isChecked = parseInt(value) > 0
        }

        setPracticeProductsFormData(newPracticeProductsFormData);
        validateProducts(newPracticeProductsFormData, false)
    };

    const validateProducts = (updatedProducts: any[], isSaveAction: boolean): boolean => {
        let validProducts = true
        const coreProduct = updatedProducts && (updatedProducts.find((p: any) => p.code === "CORE") as any);
        const digitalFrontDoorProduct =
            updatedProducts && (updatedProducts.find((p: any) => p.code === "FRONT_DOOR") as any);
        const eTranscriptionProduct =
            updatedProducts && (updatedProducts.find((p: any) => p.code === Permissions.ETRANSCRIPT) as any);
        const careTeamProduct = updatedProducts && (updatedProducts.find((p: any) => p.code === "CARE_TEAM") as any);
        // KEPLER-954 License Rules for Org/Practice
        // If Core Platform if not assigned, then Digital Front Door, Care Team Communications, and eTranscription are Disabled
        if (coreProduct && !coreProduct.isChecked) {
            setCoreProductSelected(false)
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.isChecked = false
                digitalFrontDoorProduct.disabledSwitch = true
            }
            if (careTeamProduct) {
                careTeamProduct.isChecked = false
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.isChecked = false
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // When Core Platform is assigned, then Digital Front Door is also enabled and assigned
        if (coreProduct && coreProduct.isChecked) {
            setCoreProductSelected(true)
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.isChecked = true
                digitalFrontDoorProduct.disabledSwitch = false
            }

        }
        // When Core Platform is assigned, then Care Team Communications and eTranscription become enabled, but NOT assigned
        if (coreProduct && coreProduct.isChecked) {
            if (careTeamProduct) {
                careTeamProduct.disabledSwitch = false
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.disabledSwitch = false
            }

        }
        // If either Core Platform OR Digital Front Door are unassigned, 
        // then Care Team Communications and eTranscriptions become unassigned, 0 licenses, and disabled
        if ((coreProduct && !coreProduct.isChecked) || (digitalFrontDoorProduct && !digitalFrontDoorProduct.isChecked)) {
            if (careTeamProduct) {
                careTeamProduct["isChecked"] = false
                careTeamProduct["licenseCount"] = 0
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct["isChecked"] = false
                eTranscriptionProduct["licenseCount"] = 0
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // If Core Platform is enabled, it must have a license count of greater than 0
        if (isSaveAction && coreProduct && coreProduct.isChecked && coreProduct.licenseCount !== "Unlimited" && parseInt(coreProduct.licenseCount ? coreProduct.licenseCount.toString() : "0") === 0) {
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "OrgProductsEdit.CoreProductCountGreaterThanZero" })));
            validProducts = false
        }
        updatedProducts?.forEach(function (p, index) {
            if ((parseInt(p?.licenseCount) < 0) || (p?.isChecked && p?.licenseCount !== "Unlimited" && (p.availableLicenseCount < parseInt(p?.licenseCount)))) {
                validProducts = false
                dispatch(showNotification("Error", "error", intl.formatMessage({ id: "OrgProductsEdit.InvalidProductCombination" })));
                return;
            }
        });
        setPracticeProductsFormData(updatedProducts)
        return validProducts
    }



    const handleSaveAndContinueLaterClick = (event: any, index: any) => {
        if (!practiceInfoFormData?.name || practiceInfoFormData?.name?.trim() === "") {
            const fieldValue = {} as any;
            fieldValue["name"] = practiceInfoFormData?.name?.trim()
            validatePracticeInfo(fieldValue)
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "AddOrg.RequiredField.Missing" })));
            return
        }
        let ehrInfo;
        if (refAddPracticeEHRConnection.current) {
            ehrInfo = refAddPracticeEHRConnection.current.getEHRConnectionValues()
        }
        const formData = adminWebCachePractice?.formData || {};
        formData[pendingPracticeTemporaryId ? pendingPracticeTemporaryId : createGUIDString()] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgId: auth.currentOrg?.id,
            parentOrgName: auth.currentOrg?.name,
            parentPracticeId: auth.currentPractice?.id,
            tempFormData: {
                practiceInfoFormData,
                practiceAdminFormData,
                practiceProductsFormData,
                ehrInfo,
                pendingSteps: (4 - index) === 0 ? 0 : (4 - index)
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
        handleClose()
    };

    const handleSkipPracticeAdministratorStep = (event: any, step: number) => {
        setSkipPracticeAdmin(true);
        handleAddPracticeStepClick(event, selectedAddPracticeStepIndex + 1, true)
    }

    const AddPracticeStepContent = (step: number): ReactElement => {
        let content: ReactElement;

        switch (step) {
            case 1:
                content = <AddPracticeForm handlePracticeInfoInputChange={handlePracticeInfoInputChange} practiceInfoFormData={practiceInfoFormData} errorsPracticeInfo={errorsPracticeInfo} />
                break;
            case 2:
                content = <AddPracticeAdmin handlePracticeAdminInputChange={handlePracticeAdminInputChange} practiceAdminFormData={practiceAdminFormData} errorsPracticeAdmin={errorsPracticeAdmin} isInputDisabled={isInputDisabled} handleInputBlur={handleInputBlur} />
                break;
            case 3:
                content = <AddPracticeProducts handlePracticeProductsInputChange={handlePracticeProductsInputChange} practiceProductsFormData={practiceProductsFormData} showAvailableToAdd={auth.currentOrg?.parent != null} />
                break;
            case 4:
                content = <AddPracticeEHRConnection ref={refAddPracticeEHRConnection} isCoreProductSelected={isCoreProductSelected} />
                break;
            default:
                content = <div>Not Implemented</div>
        }

        return content;
    };

    const modalClasses = {
        paper: classes.wrapper
    }

    const handleInputBlur = async (event: any) => {
        const { value } = event.target
        if (auth && value.trim() !== "") {
            const presignedPutUrl = "/admin/v1/admin/email/" + value;
            const existingEmailDetails = await call("GET", presignedPutUrl, {})
                .catch((error: any) => {
                    // setLoading(false)
                });
            if (existingEmailDetails && existingEmailDetails.email !== null) {
                setExistingUserEmailObj(existingEmailDetails)
                setPracticeAdminFormData({
                    levelId: existingEmailDetails.levelId,
                    email: existingEmailDetails.email,
                    primaryPhone: existingEmailDetails.primaryPhone,
                    mobilePhone: existingEmailDetails.mobilePhone,
                    firstName: existingEmailDetails.firstName,
                    middleName: existingEmailDetails.middleName,
                    lastName: existingEmailDetails.lastName,
                    npi: existingEmailDetails.npi,
                    ehrUsername: existingEmailDetails.ehrUsername,
                    ehrProviderId: existingEmailDetails.id,
                    userType: existingEmailDetails.userType
                })
                setInputDisabled(true)
            } else {
                setInputDisabled(false)
                setExistingUserEmailObj({})
            }
        } else { setInputDisabled(false); setExistingUserEmailObj({}) }
    }

    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.ADD_PRACTICE}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    <Typography className={classes.dialogHeaderTitle}>
                        <FormattedMessage id={"AddPractice.ModalHeading"} />
                    </Typography>
                    <Typography className={classes.dialogHeaderText}>
                        {navigation.breadCrumbEntityHierarchy?.length > 0 && navigation.breadCrumbEntityHierarchy.map((org: any, index: number) => {
                            if (!org) return null
                            return (<Fragment key={index}><BuildingIcon className={classes.orgIcon} />{org.organizationName}{index < navigation.breadCrumbEntityHierarchy?.length - 1 && <ChevronRightIcon color="#909098" className={classes.chevRightIcon} />}</Fragment>)
                        })}
                        {selectedAddPracticeStepIndex > 1 && practiceInfoFormData?.name?.trim() && <><ChevronRightIcon color="#909098" className={classes.chevRightIcon} />
                            <BuildingWideIcon className={classes.orgIcon} />{practiceInfoFormData?.name?.trim()} </>}

                    </Typography>
                </Box>
                <Box>
                    <IconButton onClick={handleClose} size="large">
                        <CrossIcon className={classes.closeButtonIcon} />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dialogAction}>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Button id="btnSaveAndContinueLater" variant="outlined" style={{ marginLeft: '10px' }}
                        aria-haspopup="true" onClick={(event) => handleSaveAndContinueLaterClick(event, selectedAddPracticeStepIndex)}>
                        {intl.formatMessage({ id: "AddPractice.Action.SaveAndContinueLater" })}
                    </Button>
                    {selectedAddPracticeStepIndex === 2 && <Button id="btnSkipPracticeAdminStep" variant="outlined" style={{ marginLeft: '10px' }}
                        aria-haspopup="true" onClick={(event) => handleSkipPracticeAdministratorStep(event, selectedAddPracticeStepIndex)}>
                        {intl.formatMessage({ id: "AddPractice.Action.SkipPracticeAdmin" })}
                    </Button>}
                    {(selectedAddPracticeStepIndex === 1 || selectedAddPracticeStepIndex === 2
                        || (selectedAddPracticeStepIndex === 3 && isCoreProductSelected === true)) ?
                        <Button
                            id="nextStepPracticeBtn"
                            variant="contained"
                            color="primary"
                            aria-haspopup="true"
                            onClick={(event) => handleAddPracticeStepClick(event, selectedAddPracticeStepIndex + 1)}
                            className={classes.dialogHeaderSaveButton}
                        >
                            {intl.formatMessage({ id: "AddPractice.Action.Next" })}
                        </Button>
                        :
                        <Button
                            id="saveNewPracticeBtn"
                            variant="contained"
                            color="primary"
                            aria-haspopup="true"
                            onClick={handleSavePractice}
                            className={classes.dialogHeaderSaveButton}
                        >
                            {intl.formatMessage({ id: "AddPractice.Action.AddPractice" })}
                        </Button>
                    }

                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ root: classes.dialogContentRoot, dividers: classes.dialogContentDividers }}>
                <Paper square elevation={0}>
                    <Box className={classes.contentWrapper}>
                        <Box className={classes.contentArea}>
                            <Box className={classes.leftPaneWrapper}>
                                <List>
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddPracticeStepIndex === 1}
                                        onClick={(event) => handleAddPracticeStepClick(event, 1)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddPracticeStepIndex === 1 || selectedAddPracticeStepIndex === 2
                                                || selectedAddPracticeStepIndex === 3 || selectedAddPracticeStepIndex === 4) ?
                                                <div style={{ background: "#0F7BBD" }} className={classes.circleNumber}>
                                                    {(selectedAddPracticeStepIndex === 2 || selectedAddPracticeStepIndex === 3 || selectedAddPracticeStepIndex === 4) ? <CheckIcon /> : 1}
                                                </div>
                                                : <div className={classes.circleNumber}>1</div>
                                            }
                                            {(selectedAddPracticeStepIndex > 1) ?
                                                <div className={classes.verticalLine}></div>
                                                : <div className={classes.verticalLineGrey}></div>
                                            }
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" style={{ color: "#0F7BBD" }}>
                                                <FormattedMessage id="Information" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddPracticeStepIndex === 2}
                                        onClick={(event) => handleAddPracticeStepClick(event, 2)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddPracticeStepIndex === 2 || selectedAddPracticeStepIndex === 3 || selectedAddPracticeStepIndex === 4) && !skipPracticeAdmin ?
                                                <div style={{ background: "#0F7BBD" }} className={classes.circleNumber}>
                                                    {(selectedAddPracticeStepIndex === 3 || selectedAddPracticeStepIndex === 4) ? <CheckIcon /> : 2}
                                                </div>
                                                : <div className={classes.circleNumber}>{skipPracticeAdmin ? <EllipsisHorizontal width={16} height={16} /> : 2}</div>
                                            }
                                            {(selectedAddPracticeStepIndex > 2) ?
                                                <div className={classes.verticalLine}></div>
                                                : <div className={classes.verticalLineGrey}></div>
                                            }
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" style={{ color: (selectedAddPracticeStepIndex !== 1 && !skipPracticeAdmin) ? "#0F7BBD" : "#000000" }}>
                                                <FormattedMessage id="Administrator" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddPracticeStepIndex === 3 || selectedAddPracticeStepIndex === 4}
                                        onClick={(event) => handleAddPracticeStepClick(event, 3)}
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddPracticeStepIndex === 3 || selectedAddPracticeStepIndex === 4) ?
                                                <div style={{ background: "#0F7BBD" }} className={classes.circleNumber}>3</div>
                                                : <div className={classes.circleNumber}>3</div>
                                            }
                                            {(selectedAddPracticeStepIndex > 3) ?
                                                <div className={classes.verticalLine} style={{ height: "15px", display: (isCoreProductSelected === true) ? "flex" : "none" }}></div>
                                                : <div className={classes.verticalLineGrey} style={{ height: "15px", display: (isCoreProductSelected === true) ? "flex" : "none" }}></div>
                                            }
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" style={{ color: (selectedAddPracticeStepIndex === 3 || selectedAddPracticeStepIndex === 4) ? "#0F7BBD" : "#000000" }}>
                                                <FormattedMessage id="Products" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddPracticeStepIndex === 4}
                                        onClick={(event) => handleAddPracticeStepClick(event, 3)}
                                        style={{ marginTop: "0px", paddingTop: "0rem", marginLeft: "13px", display: (isCoreProductSelected === true) ? "flex" : "none" }}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddPracticeStepIndex === 4) ?
                                                <div style={{ background: "#0F7BBD", width: "23px", height: "23px" }} className={classes.circleNumber}>a</div>
                                                : <div style={{ width: "23px", height: "23px" }} className={classes.circleNumber}>a</div>
                                            }
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h6" style={{ color: (selectedAddPracticeStepIndex === 4) ? "#0F7BBD" : "#000000" }}>
                                                <FormattedMessage id="EHR Connection" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                            <Box className={classes.rightContent}>
                                {AddPracticeStepContent(selectedAddPracticeStepIndex)}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default AddPractice