import React from "react";
import { Accordion, AccordionSummary, AccordionDetails }  from "@mui/material";
import { CustomAccordionProps } from "./types"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
 const CustomAccordion = ({ id, children, classes, renderTitle,isOpen=false,onChange }: CustomAccordionProps) => {
    return (
        <Accordion className={classes} expanded={!!isOpen} onChange={onChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className="accordion-icon"/>}
                aria-controls="panel1a-content"
                id={id}
            >
                {renderTitle()}
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default CustomAccordion