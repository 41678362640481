import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SVGIcon from "common/components/svg-icon/svg-icon";
import { useState } from "react";
import { useStyles } from "./styles";
import { ConfigProps, LinkProps, TileProps } from "./types";

const contentDefault = {
    height: '30px',
    paddingTop: '20px',
    paddingBottom: '20px'
};

export const PEPTiles = ({ providerConfig: config, permissions, onSelect }: { providerConfig: ConfigProps, permissions: string[], onSelect?: (linkAction: LinkProps) => void }) => {
    const tiles: TileProps[] = config.tiles;
    const classes = useStyles();

    const hasPermission = (requiredPermissionsList: string[]): boolean => {
        return requiredPermissionsList.every((permission: string) => permissions.includes(permission));
    };

    const visibleTiles: TileProps[] = tiles.filter((tile: TileProps) => {
        return (!!tile.permissions) ? (hasPermission(tile.permissions) ? tile : null) : tile;
    }).filter((tile: TileProps, index: number) => {
        return ((!!tile.enabled) && (index < 3)) ? tile : null;
    });

    const tileStyleLeft = '16px 16px 16px 0px';
    const tileStyleRight = '16px 0px 16px 16px';
    const tileStyleOthers = '16px';
    const [hoverStates, setHoverStates] = useState<boolean[]>([...new Array(visibleTiles.length)].map(() => false));

    return (
        <div id="tiles_row" className={classes.pep_tiles_row}>
            <div id="tiles_container" className={classes.pep_tiles_container}>
                {visibleTiles.map((tile: TileProps, index: number) => (
                    <div key={`${index}_${tile.name}`} style={{
                        display: 'inline-block', width: `${(100 / visibleTiles.length)}%`, maxWidth: '470px',
                        padding: `${(visibleTiles.length === 1) ? tileStyleOthers : ((index === 0) ? tileStyleLeft : ((index === (visibleTiles.length - 1)) ? tileStyleRight : tileStyleOthers))}`
                    }} >
                        <Card
                            onMouseOver={() => {
                                const newHoverStates: boolean[] = Object.assign([], hoverStates);
                                newHoverStates[index] = true;
                                setHoverStates(newHoverStates);
                            }}
                            onMouseOut={() => {
                                const newHoverStates: boolean[] = Object.assign([], hoverStates);
                                newHoverStates[index] = false;
                                setHoverStates(newHoverStates);
                            }}
                            sx={{ height: '360px', display: 'flex', flexDirection: 'column', alienItems: 'center', padding: '24px 18px 32px', gap: '16px', background: '#FFFFFF', border: `${hoverStates[index] ? '2px solid #6E1E80' : '1px solid #6E1E80'}`, borderRadius: '16px' }}
                        >
                            <CardContent sx={{ maxHeight: '230px', overflow: 'hidden', flexGrow: 1, padding: '8px' }}>
                                <SVGIcon svg={tile.icon} className={classes.pep_tile_icon} />
                                <Typography className={classes.pep_tile_name}>
                                    {tile.name}
                                </Typography>
                                <Typography className={classes.pep_tile_description}>
                                    {tile.description}
                                </Typography>
                            </CardContent>
                            <Box
                                m={1}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={contentDefault}
                            >
                                <CardActions>
                                    <Button
                                        className={classes.pep_tile_button}
                                        href={tile.action.href}
                                        target={tile.action.target ? tile.action.target : "_self"}
                                        variant="contained"
                                        color="primary"
                                        onClick={(event) => {
                                            if (onSelect && !tile.action.href) {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                onSelect(tile.action);
                                            }
                                        }}
                                        sx={{ height: 40 }}>
                                        {tile.action.name}
                                    </Button>
                                </CardActions>
                            </Box>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
}


