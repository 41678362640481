import { useIntl,FormattedMessage } from "react-intl";
import LayoutExtr from 'admin/components/Layout/LayoutExtr';
import FormField from "common/components/FormField/FormField";
import {Button, Typography} from "@mui/material";
import {Form,useForm} from "admin/components/useForm";
import PasswordValidation from "./PasswordValidation/PasswordValidation";
import { validatePasswords,validateSpecialCharacter} from "./utils";
import { EMAIL_PATTERN } from "constant";
import {  useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { showNotification } from "admin/store/actions/snackbar";
import { useDispatch } from "react-redux";
import AddSecretQuestions from "./AddSecretQuestions/AddSecretQuestions";

// @ts-ignore
const {ENABLE_API_URL} = window.env

const initialFValues = {
    email: '',
    password: '',
    passwordConfirm:''
}

const ResetPassword = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const params = new URLSearchParams(window.location.search) 
    const passwordResetToken = params.get("passwordResetToken");
    const [loader, updateLoader] = useState(false)
    const [addSecretQuestionState, setAddSecretQuestionState] = useState(false)
    const [userName, setUserName] = useState("")

    if(!passwordResetToken){
        history.push('/login')
    }
    const { enqueueSnackbar } = useSnackbar()

    const validate = (fieldValues = values) => {
        const err: any = { ...errors };

        const requiredFieldError = intl.formatMessage({ id: "ResetPasswordValidation.error.requiredField" });

        if ('email' in fieldValues) {
            err.email = fieldValues.email ? "" : requiredFieldError
            if (fieldValues.email !== "" && !(EMAIL_PATTERN).test(fieldValues.email?.trim())) {
                err.email = intl.formatMessage({ id: "ResetPasswordValidation.error.invalidMail" })
            }
        }
        if ('password' in fieldValues)
            err.passwordConfirm = fieldValues.password?.trim() ? "" : requiredFieldError
        setErrors({
            ...err
        })

        if ('passwordConfirm' in fieldValues)
            err.passwordConfirm = fieldValues.passwordConfirm?.trim() ? "" : requiredFieldError
        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(
        initialFValues, false, validate);

    const intl = useIntl()

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (validate()) {
            updateLoader(true)

            const axios = require('axios');
            axios.post(ENABLE_API_URL + `/api/admin/v1/user/resetPassword`, { newPassword:values.password, pwResetToken:passwordResetToken})
            .then ((response: { status: any, data?: any, message?: any }) => {
                if (response.status === 204) {
                    setAddSecretQuestionState(true)
                } else if (response.status === 200) {
                    if (response?.data === true) {
                        setAddSecretQuestionState(true)
                        setUserName(values.email)
                    } else {
                        setAddSecretQuestionState(false)
                        enqueueSnackbar(intl.formatMessage({ id: "ResetPasswordValidation.successMessage" }), {
                            variant: "success",
                        })

                        history.push('/login')
                    }
                }
                updateLoader(false)
            })
            .catch((error: any) => {
                updateLoader(false)
                dispatch(showNotification("Reset Password Error", "error", error.message))
            });
        }
    }

    const onChange = (name: string) =>{
        return (value: string) =>{
            handleInputChange({ target:{ value,name } })
        }
    }

    const {valid: validPasswords} = validatePasswords({  email:values.email,password:values.password,passwordConfirm:values.passwordConfirm  })
    const validatePasswordCharacter = validateSpecialCharacter(values.password)
    const passwordErrorId = (validatePasswordCharacter) ? "" : "Registration.label.passwordError"

    return (
        (addSecretQuestionState === true) ? 
            <AddSecretQuestions userName={userName} /> :
            <LayoutExtr additionalContent={<PasswordValidation email={values.email} password={values.password} passwordConfirm={values.passwordConfirm} />} heading={intl.formatMessage({ id: "ResetPasswordValidation.title" })} subHeading={intl.formatMessage({ id: "ResetPasswordValidation.subHeading" })}>
                <Form onSubmit={onSubmit}>
                    <FormField errorId={(errors as any).email?"ResetPasswordValidation.error.invalidMail":''} labelId="Registration.label.emailAddress" value={values.email} onChange={onChange('email')} />
                        <FormField errorId={values.password && passwordErrorId} labelId="Registration.label.password" value={values.password} onChange={onChange('password')} type="password" />
                        <FormField labelId="Registration.label.passwordConfirm" value={values.passwordConfirm} onChange={onChange('passwordConfirm')}  type="password" />
                    <Button type="submit" disabled={!validPasswords || loader || !validatePasswordCharacter} variant="outlined" color="primary" fullWidth>
                        <Typography variant="button">
                            <FormattedMessage id="ResetPasswordValidation.label.button" />
                        </Typography>
                    </Button>
                </Form>
            </LayoutExtr>
    )
}

export default ResetPassword