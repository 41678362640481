import React from "react";
import { AppReduxStore } from "store/reducerTypes";
import { useSelector } from "react-redux";
import ComposeMessageForm from "components/messaging/compose-message-form";

// NewMessage handles writing messages to a conversation
const NewMessage = () => {
    const { openConversationSid, conversations } = useSelector((store: AppReduxStore) => store.chat)
    
    const onTyping = () => {
        conversations[openConversationSid].typing();
    }

    const onSend = async (message: string) => {
        if (message) {
            await conversations[openConversationSid].sendMessage(message);
        }
    }

    return (
        <ComposeMessageForm
            onSubmit={onSend}
            onTyping={onTyping}
        />
    )
}

export default NewMessage;
