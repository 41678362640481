// TODO This is an intermediate solution, a much better component structure should be created
import React from "react";
import { Paper, Box, Typography } from "@mui/material";
import classNames from "classnames";
import { PopoverItem, PopoverItemRenderer } from "./types";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useStyles } from "./styles";
import ComponentLoader from "../ComponentLoader/ComponentLoader";
import DefaultPopoverItem from "./DefaultPopoverItem";

type Variants = "light" | "dark";

export interface PopoverContentProps<T extends PopoverItem> {
    /** Identifier prefix of the list elements. */
    idPrefix: string;
    /** Called when id(s) is/are selected
     *  TODO string[] for multiselect (conditional types?)
     */
    onChange: (selectedId: string) => void;
    /** Called when user clicks on popover's close icon. */
    onClose: () => void;
    /** Tells which variant should be displayed. Available variants are in the type. Default is 'dark'. */
    variant?: Variants;
    list?: T[];
    title?: string;
    emptyMessage?: JSX.Element;
    renderItem?: PopoverItemRenderer<T>;
    className?: string;
    loader?: Boolean;
    dropDownPosition?: string;
    trianglePosition?: "left" | "center";
}

const PopoverContent = <T extends PopoverItem>(props: PopoverContentProps<T>) => {
    const {
        emptyMessage,
        idPrefix,
        onChange,
        onClose,
        variant = "dark",
        list = [],
        title,
        renderItem = DefaultPopoverItem,
        className,
        loader,
        dropDownPosition = "bottom",
        trianglePosition = "left",
    } = props;
    //@ts-ignore
    const themeColor = variant === "dark" ? "compose" : "somethingelse";
    const classes = useStyles({ themeColor, dropDownPosition });

    const onItemSelected = (selectedId: string, selected: boolean) => {
        onChange(selectedId as string);
    };

    const renderList = (list: T[]) =>
        list.map((item: T, index: number) =>
            renderItem?.({
                id: `${idPrefix}_${index}`,
                item,
                onChange: onItemSelected,
            })
        );

    return (
        <Paper
            className={classNames(className, variant, classes.popupTriangle, "custom-dropdown-wrapper", {
              "triangleLeft": trianglePosition === "left",
              "triangleCenter": trianglePosition === "center"
            })}
            data-testid="multi-select-dropdown"
            classes={{ root: classes.paper }}
        >
            <div className={classes.popoverHeader}>
                <Typography className="title" variant="h4" component="div">
                    {title}
                </Typography>
                <Close className="closeIcon" onClick={onClose} id="QA_popover_content_close_button" />
            </div>
            {loader ? (
                <Box className={`${classes.popoverLoaderWrapper} popover-loader-wrapper`}>
                    <ComponentLoader disableBackdrop light={variant === "dark"} />
                </Box>
            ) : (
                <Box className={classes.popoverBody} id="QA_popover_content_items">
                    {list.length ? renderList(list) : emptyMessage}
                </Box>
            )}
        </Paper>
    );
};

export default PopoverContent;
