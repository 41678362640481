import React, { Fragment } from "react";
import { useIntl } from 'react-intl';
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as EnvelopeIcon } from 'icon-library/SVG/eMail_Closed-Envelope.svg';
import { ReactComponent as KeyIcon } from 'icon-library/SVG/Key.svg';
import { ReactComponent as UnlockIcon } from 'icon-library/SVG/Unlock.svg';
import { ReactComponent as RadioCircleIcon } from 'icon-library/SVG/Radio_Circle.svg';
import { ReactComponent as CancelIcon } from 'icon-library/SVG/Cancel.svg';
import { ReactComponent as PenIcon } from 'icon-library/SVG/Pen.svg';
import { ReactComponent as WarningOctagonIcon } from 'icon-library/SVG/Warning_Octagon.svg';
import { ReactComponent as AltaisStarIcon } from 'icon-library/SVG/Altais-Star.svg';
import { GridContextMenuContentProps } from 'admin/components/GridContextMenuContent/types';
import { useStyles } from './styles';

const UserGridContextMenu = ({ callback, handleClose, showActiveAccount, showEditProducts, showResetPassword, showUnlockAccount, showSendInvite, showSuspendAccount, showRestoreAccount }: GridContextMenuContentProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Fragment>
            {showSendInvite && <ListItem button>
                <ListItemIcon>
                    <EnvelopeIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liSendInvite" primary={intl.formatMessage({ id: "GridContextMenu.SendInvite" })} classes={{ primary: classes.listText }} onClick={() => { callback("SendInvite"); handleClose() }} />
            </ListItem>}
            <ListItem button>
                <ListItemIcon>
                    <PenIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liEditUser" primary={intl.formatMessage({ id: "GridContextMenu.EditUser" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditUser"); handleClose() }} />
            </ListItem>
            {showEditProducts && <ListItem button>
                <ListItemIcon>
                    <AltaisStarIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liEditProducts" primary={intl.formatMessage({ id: "GridContextMenu.EditProducts" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditProducts"); handleClose() }} />
            </ListItem>}
            {showResetPassword && <ListItem button>
                <ListItemIcon>
                    <KeyIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liResetPassword" primary={intl.formatMessage({ id: "GridContextMenu.ResetPassword" })} classes={{ primary: classes.listText }} onClick={() => { callback("ResetPassword"); handleClose() }} />
            </ListItem>}
            <ListItem button>
                <ListItemIcon>
                    <CancelIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liDeactivateAccount" primary={intl.formatMessage({ id: "GridContextMenu.DeactivateAccount" })} classes={{ primary: classes.listText }} onClick={() => { callback("DisableAltais"); handleClose() }} />
            </ListItem>
            {showSuspendAccount && <ListItem button>
                <ListItemIcon>
                    <WarningOctagonIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liSuspendAccount" primary={intl.formatMessage({ id: "GridContextMenu.SuspendAccount" })} classes={{ primary: classes.listText }} onClick={() => { callback("SuspendAccount"); handleClose() }} />
            </ListItem>}
            {showUnlockAccount && <ListItem button>
                <ListItemIcon>
                    <UnlockIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liUnlockAccount" primary={intl.formatMessage({ id: "GridContextMenu.UnlockAccount" })} classes={{ primary: classes.listText }} onClick={() => { callback("UnlockAccount"); handleClose() }} />
            </ListItem>}
            {showActiveAccount && <ListItem button>
                <ListItemIcon>
                    <RadioCircleIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liActivateAccount" primary={intl.formatMessage({ id: "GridContextMenu.ActivateAccount" })} classes={{ primary: classes.listText }} onClick={() => { callback("EnableAccount"); handleClose() }} />
            </ListItem>}
            {showRestoreAccount && <ListItem button>
                <ListItemIcon>
                    <RadioCircleIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liRestoreAccount" primary={intl.formatMessage({ id: "GridContextMenu.RestoreAccount" })} classes={{ primary: classes.listText }} onClick={() => { callback("EnableAccount"); handleClose() }} />
            </ListItem>}
            {/* <ListItem button>
                <ListItemIcon>
                    <CrossCircleIcon width={24} />
                </ListItemIcon>
                <ListItemText id="liDeleteUser" primary={intl.formatMessage({ id: "GridContextMenu.DeleteUser" })} classes={{ primary: classes.listText }} onClick={() => { callback("DeleteUser"); handleClose() }} />
            </ListItem> */}
        </Fragment>
    );
}

export default UserGridContextMenu;
