import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { each, map } from "underscore";

import { createGUIDString } from "admin/common/utils";
import RichTooltip from "admin/components/RichToolTip/RichTooltip";
import { USER_LEVELS, USER_ROLES } from "admin/constant";
import { hideLoading, showLoading } from "admin/store/actions/loading";
import { signOutApp } from "authentication/appAuth";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import DropdownSelector from "common/components/DropdownSelector/DropdownSelector";
import ProfileGroupSelector from "common/components/ProfileGroupSelector/ProfileGroupSelector";
import AzureProfileGroupSelector from "authentication/azure/ProfileGroupSelector/azure-profile-group-selector";
import { APP_AUTH_TYPE, appMode } from 'authentication/config';
import Spinner from "common/components/Spinner/Spinner";
import { USER_TYPE } from "constant";
import { ReactComponent as BuildingWideSVG } from "icon-library/SVG/Building_Wide.svg";
import { ReactComponent as BuildingsSVG } from "icon-library/SVG/Buildings.svg";
import { ReactComponent as GetInfoSVG } from "icon-library/SVG/Chevron_Down.svg";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross.svg";
import { ReactComponent as ProfileSVG } from "icon-library/SVG/User_Circle.svg";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { selectProfile, updateUserTutorialOptions } from "store/actions";
import { AppReduxStore } from "store/reducerTypes";
import { COLORS, SIZING } from "theme";
import { createSvgIcon, getAddress, getTitleByUserLevelName } from "utils";
import { useStyles } from "./styles";
import { SideNavHeaderProps } from "./types";

const ProfileIcon = createSvgIcon(ProfileSVG)
const GetInfoIcon = createSvgIcon(GetInfoSVG)
const BuildingsSVGIcon = createSvgIcon(BuildingsSVG)
const BuildingWideSVGIcon = createSvgIcon(BuildingWideSVG)

const CrossIcon = createSvgIcon(CrossSvg);
// SideNavHeader shows information about the currently logged in user such as their name, role and practice. Also
// is profile selection and logout capabilities
const SideNavHeader = ({ open }: SideNavHeaderProps) => {
    const [toolTipOpen, setToolTipOpen] = useState(true);
    const [profileSelectorOpen, setProfileSelectorOpen] = useState(false)
    const [selectedProfileRoleJoinId, setSelectedProfileRoleJoinId] = useState<any>(null)
    const { currentProfileRoleJoin, profileRoleJoinInfos, currentProfileRoleJoinId, firstName: fName, lastName: lName, registeredUserProfileInfo, useCurrentPrj, expireCurrentPrj, loading } = useSelector((store: AppReduxStore) => store.user)
    const classes = useStyles({ open });
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const intl = useIntl()

    const groupProfiles = () => {
        const groups: any = {}
        each((profileRoleJoinInfos || []), (prj: any) => {
            if (prj.type === USER_ROLES.PATIENT) return
            if (prj.level?.name === USER_LEVELS.PRACTICE_ADMIN) {
                prj.level["description"] = intl.formatMessage({ id: "ProfileSelector.providerAdminPortalTitle" });
            }
            if (prj.practice) {
                if (groups[prj.practice.id]) {
                    groups[prj.practice.id].profiles.push(prj)
                } else {
                    groups[prj.practice.id] = {
                        location: prj.practice,
                        profiles: [prj]
                    }
                }
                if (prj.level?.name === USER_LEVELS.PRACTICE_ADMIN) {
                    groups[prj.practice.id].profiles.push({
                        ...prj, id: prj.id + "_provider", level: {
                            ...prj.level,
                            description: intl.formatMessage({ id: "ProfileSelector.providerPortalTitle" })
                        }
                    })
                }
            } else if (prj.organization) {
                prj.level["description"] = intl.formatMessage({ id: "ProfileSelector.providerAdminPortalTitle" });
                if (groups[prj.organization.id]) {
                    groups[prj.organization.id].profiles.push(prj)
                } else {
                    groups[prj.organization.id] = {
                        location: prj.organization,
                        profiles: [prj]
                    }
                }
            }
        })

        return map(groups, (group) => group)
    }

    useEffect(() => {
        if (profileRoleJoinInfos) {
            const [, mainRoute = ""] = pathname.split("/") || [];
            const profile = profileRoleJoinInfos && profileRoleJoinInfos.find((p: any) => p.id === currentProfileRoleJoinId)
            if (profile && profile.level?.name === USER_LEVELS.PRACTICE_ADMIN && mainRoute === 'provider') {
                setSelectedProfileRoleJoinId((currentProfileRoleJoinId + "_provider"))
            }
        }

    }, [currentProfileRoleJoinId, profileRoleJoinInfos, pathname])

    const handleTooltipClose = (e: any) => {
        e.preventDefault();
        setToolTipOpen(false);
    };
    const renderUserRow = () => {
        const { providerProfile, adminProfile, patientProfile, level } = currentProfileRoleJoin || {} as any
        const { prefix, firstName, lastName } = providerProfile || adminProfile || patientProfile || { firstName: fName, lastName: lName }
        const name = `${firstName} ${lastName}`
        const fullName = prefix ? `${prefix} ${name}` : name

        return (

            <Box className={classes.row} marginBottom={SIZING.scale200}>
                <Tooltip title={open ? "" : fullName}>
                    <Box margin={`0 ${SIZING.scale400}`} width={1} alignItems="center" display="flex">
                        <ProfileIcon fill={COLORS.SUBTITLE_INFO_TEXT} className={classes.icon} id="qa_profile_icon" /> { }
                        {open && (
                            <Box width={1} marginLeft={SIZING.scale100}>
                                <CustomTypography color={COLORS.SUBTITLE_INFO_TEXT} variant="titleHeader" id="qa_user_name">
                                    {`${firstName} ${lastName}`}
                                </CustomTypography>
                                <CustomTypography color={COLORS.SUBTITLE_INFO_TEXT} variant="titleDescription" id="qa_user_profile">
                                    {getTitleByUserLevelName(level?.name)}
                                </CustomTypography>
                            </Box>
                        )}
                    </Box>
                </Tooltip>
            </Box>
        )
    }

    const renderOrgRow = () => {
        const { organization, practice } = currentProfileRoleJoin || {}
        const { name = "" } = practice ? practice : organization || {}

        const logout = async () => {
            const guid = createGUIDString()
            dispatch(showLoading(guid, "Logout.Auth.LoadingTitle", "Logout.Auth.LoadingDescription"))
            const postLogoutRedirectUri = window.location.origin + "/login";
            await signOutApp(postLogoutRedirectUri);
            dispatch(hideLoading(guid))
        }

        const onProfileSelect = (e: any) => {
            const id = e?.target?.value || e
            setSelectedProfileRoleJoinId(id)
            // @ts-ignore
            const profile = profileRoleJoinInfos && profileRoleJoinInfos.find((p: any) => p.id === id.split('_')[0])
            const type =
                !id.includes("_provider") && profile?.level?.name === USER_LEVELS.PRACTICE_ADMIN
                    ? USER_TYPE.ADMIN
                    : profile?.type;
            dispatch(selectProfile({ ...profile, type }, history, null))
        }
        const profileGroupList = map(groupProfiles(), (group) => (
            (appMode === APP_AUTH_TYPE.AZURE) ?
                <AzureProfileGroupSelector isPractice={group?.location?.organizationId} {...group} currentProfileRoleJoinId={selectedProfileRoleJoinId || currentProfileRoleJoinId} onProfileSelect={onProfileSelect} useCurrentPrj={useCurrentPrj} expireCurrentPrj={expireCurrentPrj} profileRoleJoinInfos={profileRoleJoinInfos} verifyProfileText={intl.formatMessage({ id: "SideNavHeader.verifyProfile" })} /> :
                <ProfileGroupSelector isPractice={group?.location?.organizationId} {...group} currentProfileRoleJoinId={selectedProfileRoleJoinId || currentProfileRoleJoinId} onProfileSelect={onProfileSelect} useCurrentPrj={useCurrentPrj} expireCurrentPrj={expireCurrentPrj} profileRoleJoinInfos={profileRoleJoinInfos} verifyProfileText={intl.formatMessage({ id: "SideNavHeader.verifyProfile" })} />
        ))
        const menuOptions = [...profileGroupList, (
            <Box className={classes.logout} onClick={logout}>
                <CustomTypography variant="radioText" id="qa_logout">
                    Logout
                </CustomTypography>
            </Box>
        )]
        const handleShowAdminTutorial = () => {
            dispatch(updateUserTutorialOptions({ showAdminTutorial: false }))
        }
        if (loading) {
            return (
                <Box className={classes.row}>
                    <Spinner />
                </Box>
            )
        }
        return (
            <Box className={classes.row}>
                <Tooltip title={<Typography color="inherit">{open ? getAddress(practice ? practice : organization) : name}</Typography>}>
                    <Box width={1} marginLeft={SIZING.scale400} alignItems="flex-start" display="flex">
                        {practice ? <BuildingWideSVGIcon fill={COLORS.SUBTITLE_INFO_TEXT} className={classes.orgIcon} /> :
                            <BuildingsSVGIcon fill={COLORS.SUBTITLE_INFO_TEXT} className={classes.orgIcon} />}
                        {open && (
                            <Box width={1} className={classes.addressContainer} >
                                <Box width={1} display="flex" justifyContent="space-between">
                                    <CustomTypography color={COLORS.SUBTITLE_INFO_TEXT} variant="titleSubheader" id="qa_practice_name">
                                        {name}
                                    </CustomTypography>
                                    <GetInfoIcon id="qa_profiles_dd" onClick={() => setProfileSelectorOpen(!profileSelectorOpen)} fill={COLORS.DRAWER_ICON} className={classes.menuIcon} />
                                    {(registeredUserProfileInfo?.showAdminTutorial || registeredUserProfileInfo?.showAdminTutorial === null) && <RichTooltip content={
                                        <Box textAlign="right">
                                            <Box>
                                                <CrossIcon fill={"#FFFFFF"} className={classes.infoIcon} onClick={handleTooltipClose} />
                                            </Box>
                                            <Typography className={classes.infoTooltipText}>
                                                <span className={classes.infoTooltipTextDesc}>You are now logged into your new profile. To log out or select another profile click this <b>dropdown</b>.</span>
                                            </Typography>
                                            <Box textAlign="right">
                                                <Button style={{ display: 'inline-flex' }} variant="outlined" color="primary" className={classes.LetsGoBtn} onClick={handleShowAdminTutorial}>
                                                    Got it
                                                </Button>
                                            </Box>
                                        </Box>
                                    }
                                        open={toolTipOpen}
                                        onClose={() => setToolTipOpen(false)}
                                        placement="right-end"
                                        offset={[0, 20]}>
                                        <label></label>
                                    </RichTooltip>}
                                </Box>
                                <CustomTypography color={COLORS.SUBTITLE_INFO_TEXT} className={classes.addressText} variant="titleDescription" id="qa_practice_address">
                                    {getAddress(practice ? practice : organization)}
                                </CustomTypography>
                            </Box>
                        )}
                    </Box>
                </Tooltip>
                {open && (
                    <DropdownSelector
                        open={profileSelectorOpen}
                        title="SideNavHeader.menu.title"
                        options={menuOptions}
                        onClose={setProfileSelectorOpen}
                        id="qa_profile_selection_dd"
                    />)}
            </Box>
        )
    }

    return (
        <Box className={classes.headerWrapper}>
            <Box className={classes.header}>
                {renderUserRow()}
                {renderOrgRow()}
            </Box>
        </Box>
    )
}

export default SideNavHeader