import React, { useEffect } from "react";
import LayoutExtr from "admin/components/Layout/LayoutExtr";
import { Box } from "@mui/material";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "admin/store/reducerTypes";
import {getRegistrationStep, getRegistrationHeading, getRegistrationSubheading} from "common/views/Registration/utils";
import {useHistory} from "react-router";
import {setRegistrationToken} from "admin/store/actions";
import { useStyles } from "./styles";

// Registration wraps each step of the registration providing the heading, subheading and form content
const Registration = () => {
    const registration = useSelector((store: AppReduxStore) => store.registration)
    const intl = useIntl()
    const history = useHistory()
    const classes = useStyles();
    const dispatch = useDispatch()

    useEffect(() => {
        const params = new URLSearchParams(history.location.search)
        const regToken = params.get("regToken")

        if (regToken) {
            dispatch(setRegistrationToken(regToken))
        }
    }, [dispatch, history.location.search])

    const RegistrationStep = getRegistrationStep(registration)
    const heading = getRegistrationHeading(registration)
    const subheading = getRegistrationSubheading(registration)

    return (
        <LayoutExtr heading={intl.formatMessage({ id: `Registration.heading.${heading}` })} subHeading={intl.formatMessage({ id: `Registration.subheading.${subheading}` })}>
            <Box className={classes.registrationWrapper}>
                <RegistrationStep />
            </Box>
        </LayoutExtr>
    )
}

export default  Registration