import { Provider, ProviderSearchContent, ProviderSearchOptions } from "./types";
import { ProviderListItem } from "./provider-list-item";
import { TableList } from "lib/ui-components/table-list";
import { useEffect, useState } from "react";
import { useProviderSearch, useProviderSearchArgs } from "./provider-search-hooks";
import { useUserNotification } from "common/utils/use-user-notification";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";

export type ProviderSearchProps = {
    allowEmptySearch?: boolean;
    filters: useProviderSearchArgs["filters"];
    /** Selected providers change */
    onSelectionChange: (provider: Provider, selected: boolean) => void;
    onResult?: (result: ProviderSearchContent) => void;
    page: useProviderSearchArgs["page"];
    sorting: useProviderSearchArgs["sorting"];
    options?: ProviderSearchOptions;
    /** Selected providers */
    selectedProviders?: string[];
    emptyMessage?: JSX.Element;
    errorMessage?: JSX.Element;
};

export const ProviderSearch = ({
    allowEmptySearch,
    emptyMessage,
    errorMessage,
    filters,
    page,
    sorting,
    selectedProviders = [],
    options,
    onSelectionChange,
    onResult,
}: ProviderSearchProps) => {
    const { enqueueError } = useUserNotification();
    const [portalEl, setPortalEl] = useState<HTMLDivElement | null>(null);
    const { result, loading, error, success } = useProviderSearch({
        allowEmptySearch,
        filters,
        page,
        sorting,
        options: options || null,
    });

    useEffect(() => {
        if (success && onResult) {
            onResult(result);
        }
    }, [result, success, onResult]);

    useEffect(() => {
        if (error) {
            enqueueError("ProviderSearch.search.errorMessage");
        }
    }, [enqueueError, error]);

    if (loading) {
        return <ComponentLoader />;
    }

    if (error && errorMessage) {
        return errorMessage;
    }

    const providers = !error ? result.data : [];

    if (!providers.length && emptyMessage) {
        return emptyMessage;
    }

    return (
        <>
            <TableList id="QA_provider_search_results" testId="QA_provider_search_results">
                {providers.map((provider) => {
                    const handleSelect = onSelectionChange
                        ? (_id: string, selected: boolean) => onSelectionChange(provider, selected)
                        : undefined;
                    const selected = selectedProviders?.includes(provider.id);
                    return (
                        <ProviderListItem
                            key={provider.id}
                            idPrefix="QA_provider_search_results"
                            provider={provider}
                            onSelectChange={handleSelect}
                            selected={selected}
                            showSpecialties={true}
                            portalEl={portalEl}
                        />
                    );
                })}
            </TableList>
            <div ref={setPortalEl}></div>
        </>
    );
};
