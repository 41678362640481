import React, { useEffect, useState } from "react";
import { InviteProps } from "./types";
import { useStyles } from "./styles";
import ProviderSearch from "common/components/ProviderSearch/ProviderSearch";
import { Send } from "assets";
import { Typography,CircularProgress } from "@mui/material";
import { useReduxDispatch } from "store/utils";
import { createSvgIcon } from "utils";
import { ReactComponent as CloseIcon } from "icon-library/SVG/Cross_Circle.svg";
import { inviteColleagueToWaitingroom } from "store/actions";
import { useSnackbar } from 'notistack';
import {FormattedMessage, useIntl} from "react-intl";

const Invite = ({ isOpen,handleClose,updateInviteStatus }: InviteProps) => {

    const classes = useStyles({ theme: 'grey' })
    const Close = createSvgIcon(CloseIcon);
    const [pinnedDetails, updatePinnedDetails] = useState<any[]>([])
    const [loader, updateLoader] = useState<boolean>(false)
    const customDispatch = useReduxDispatch()
    const intl = useIntl()
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        !isOpen && cleanUp()
    }, [isOpen])

    const cleanUp = () => {
        updatePinnedDetails([])
        updateLoader(false)
    }
    const handleSubmit = () => {
        if(pinnedDetails.length>=1){
            updateLoader(true)
            customDispatch(inviteColleagueToWaitingroom(pinnedDetails)).then(()=>{
                enqueueSnackbar(intl.formatMessage({id: "waitingRoom.invite.success"}), {
                    variant: "success",
                  })
                  handleCloseIcon()
                  updateInviteStatus(true)
            }).finally(()=>{
                updateLoader(false)
            })
        } else {
            alert("Please select a colleague")
        }
       
    }

    if (!isOpen) {
        return null
    }

    const handleCloseIcon =()=>{
        !loader&&handleClose()
    }

    return (
        <div className={classes.wrapper}>
            <Typography variant="body1" className={classes.title} ><FormattedMessage id="waitingRoom.invite.title"/></Typography>
            <div className={classes.contentWrapper}>
                <ProviderSearch
                    value={pinnedDetails}
                    onChange={updatePinnedDetails}
                    placeHolder="waitingRoom.invite.placeHolder"
                />
                <div className={classes.sendIcon}>
                {loader ? <Typography className="loader-wrapper"> <CircularProgress color="inherit" /></Typography>:
                <Send
                    onClick={handleSubmit}
                    id="waitingroom_invite_sumbit"
                />}
                </div>
             
                <Close onClick={handleCloseIcon} className={classes.closeIcon}></Close>
            </div>
            <div className={classes.divider} />
        </div>

    )
}

export default Invite