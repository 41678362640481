import { Button } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { messagingConversationFetchingBusyStateName } from "../constants";
import { useBusyState } from "common/utils/progress/progress-manager-hooks";
import { useState } from "react";
import { useUserNotification } from "common/utils/use-user-notification";
import InputWithTranscription from "common/components/InputWithTranscription/InputWithTranscription";

type MessageSectionProps = {
    inputPlaceholder: string;
    /** Returns with a feedback about submitting success. */
    onSubmit: (message: string) => Promise<void>;
    onTyping: () => void;
}

const MessageSection = ({ inputPlaceholder, onSubmit, onTyping }: MessageSectionProps) => {
    const intl = useIntl();
    const [message, setMessage] = useState("");
    const { busyState } = useBusyState(messagingConversationFetchingBusyStateName);
    const { enqueueError } = useUserNotification();
    const onMessageChange = (content: string) => {
        setMessage(content);
        onTyping();
    };
    const onSendClick = () => {
        onSubmit(message).then(() => {
            setMessage("");
        }).catch(() => {
            enqueueError(intl.formatMessage({ id: "messaging.error.sending"}));
        });
    };
    const handleEnterKey = (e: any) => {
        const code = e.keyCode ? e.keyCode : e.which;
        const isShift = !!e.shiftKey;
        if (code === 13 && !isShift) {
            e.preventDefault();
            onSendClick();
        }
    };

    return (
        <div className="ComposeMessage__MessageSection">
            <InputWithTranscription
                id="QA_composeMessage"
                disabled={busyState}
                onKeyPress={handleEnterKey}
                onChange={onMessageChange}
                placeholder={inputPlaceholder}
                value={message}
            />
            <Button disableRipple disabled={busyState || !message} id="QA_sendButton" variant="contained" color="inherit" onClick={onSendClick}>
                <FormattedMessage id="NewMessage.button.send" />
            </Button>
        </div>
    );
}

export default MessageSection;
