import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { usePopper } from "react-popper";
import RateInfo from "common/components/rate-info/rate-info";
import { useOutsideClickHandler } from "common/utils/use-outside-click-handler";
import Spinner from "common/components/Spinner/Spinner";
import { ReactComponent as CirclesIcon } from "views/EConsult/tempIcons/Circles.svg";
import { SpecialistDto, Specialist, Location } from "views/EConsult/types";
import { useApi } from "common/utils/use-api";
import GridRow from "./grid-row";
import { useStyles } from "./styles";

export type ProviderListItemDetailsProps = {
    anchorEl: any;
    onClose: () => void;
    provider: Specialist;
};

const LocationItem = ({ location }: { location: Location }) => {
    const stateAndPostalCode = [location.state, location.postalCode].filter(s => Boolean(s)).join(" ");
    return (
        <>
            <div>{location.name}</div>
            <div className="locationAddress">
                {[location.address, location.city, stateAndPostalCode].filter(s => Boolean(s)).join(", ")}
            </div>
        </>
    );
};

const LocationsList = ({ items }: { items?: Location[] }) => {
    if (!items?.length) {
        return <>-</>;
    }
    if (items.length === 1) {
        return <LocationItem location={items[0]} />;
    }
    return (
        <ul>
            {items.map((item, index) => (
                <li key={index} className="location">
                    <LocationItem location={item} />
                </li>
            ))}
        </ul>
    );
};

const ProviderListItemDetails: React.FC<ProviderListItemDetailsProps> = ({ anchorEl, onClose, provider }) => {
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
    const classes = useStyles();
    const intl = useIntl();
    useOutsideClickHandler([anchorEl, popperElement], true, onClose);

    const modifiers = [
        { name: "preventOverflow", options: { boundary: "clippingParents" } },
        { name: "arrow", options: { element: arrowElement, padding: 16 } },
        { name: "flip", enabled: false },
        { name: "hide" },
    ];

    const { styles, attributes } = usePopper(anchorEl, popperElement, {
        modifiers,
        placement: "right",
        strategy: "fixed",
    });

    const isReferenceHidden = attributes?.popper?.["data-popper-reference-hidden"];

    useEffect(() => {
        if (isReferenceHidden) {
            onClose();
        }
    }, [isReferenceHidden, onClose]);

    const { content: providerDetails, fetchInfo: fetchState } = useApi<null, SpecialistDto, Specialist>(
        {
            abortOnNewRequest: true,
            autoRequest: true,
            defaultContent: provider,
            endpoint: `/econsult/v1/specialists/${provider.id}`,
            method: "GET",
            responseTransformer: (result: SpecialistDto): Specialist => {
                const { insurances, hospitals, networks, specialties, subSpecialties, ...everythingElse } = result;
                return {
                    ...everythingElse,
                    hospitals: hospitals.map((item) => item.value),
                    insurances: insurances.map((item) => item.value),
                    networks: networks.map((item) => item.value),
                    specialties: specialties.map((item) => item.value),
                    subSpecialties: subSpecialties.map((item) => item.value),
                };
            },
        },
        null
    );

    const loading = !fetchState.hasResult;
    const providerView =
        providerDetails.id === provider.id && !loading ? providerDetails : provider;

    return (
        <div
            key={providerView.id}
            id={`QA_econsult_provider_item_details_${providerView.id}`}
            className={classNames(classes.providerItemDetails, { loading })}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
        >
            <span className="arrow" ref={setArrowElement} style={styles.arrow} {...attributes.arrow} />
            {loading && (
                <div className="loadingWrapper">
                    <Spinner />
                </div>
            )}
            <div className="infoHeader hideOnLoading">
                <span className="providerName">{providerView.providerName}</span>
                <RateInfo rating={null} variant="greyscale" />
            </div>
            <div className="infoContent gridBody hideOnLoading">
                <GridRow labelText={intl.formatMessage({ id: "EConsult.newRequestModal.provider.specialties" })}>
                    <div>
                        <span className="specialties">
                            {providerView.specialties?.join(", ")}
                            {providerView.specialties?.length && providerView.subSpecialties?.length && "; "}
                        </span>
                        <span className="subspecialties">{providerView.subSpecialties?.join(", ")}</span>
                    </div>
                </GridRow>
                <GridRow labelText={intl.formatMessage({ id: "EConsult.newRequestModal.provider.responseTime" })}>
                    -
                </GridRow>
                <GridRow labelText={intl.formatMessage({ id: "EConsult.newRequestModal.provider.econsults" })}>
                    -
                </GridRow>
                <GridRow labelText={intl.formatMessage({ id: "EConsult.newRequestModal.provider.experience" })}>
                    -
                </GridRow>
                <GridRow separator></GridRow>
                <GridRow labelText={intl.formatMessage({ id: "EConsult.newRequestModal.provider.insurance" })}>
                    {providerView.insurances.join(", ")}
                </GridRow>
                <GridRow labelText={intl.formatMessage({ id: "EConsult.newRequestModal.provider.networks" })}>
                    {providerView.networks.join(", ")}
                </GridRow>
                <GridRow separator></GridRow>
                <GridRow labelText={intl.formatMessage({ id: "EConsult.newRequestModal.provider.locations" })}>
                    {/** TODO this filter is probably only necessary due to an issue in the api, once fixed we can remove it */}
                    <LocationsList items={providerView.locations?.filter(({ name }) => Boolean(name))} />
                </GridRow>
            </div>
            {/* Hiding this while Circles API is not ready */}
            {false && (
                <div className="infoFooter hideOnLoading">
                    <Button
                        id={`QA_provider_item_details_circles_add`}
                        className={classNames({
                            // FIXME: CircleOfContacts
                            remove: false,
                        })}
                    >
                        {intl.formatMessage({ id: "EConsult.newRequestModal.provider.details.add" })}
                    </Button>
                    <CirclesIcon
                        className={classNames({
                            add: true,
                        })}
                    />
                </div>
            )}
        </div>
    );
};

export default ProviderListItemDetails;
