import React from "react";
import { Box, Paper, Typography, FormControl, Button, Divider } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from 'admin/components/useForm';
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import Controls from "admin/components/Controls/Controls";
import { validateRequired } from 'admin/common/utils';
import { showNotification } from 'admin/store/actions/snackbar';
import { secretQuestions } from 'admin/constant';
import PasswordBox from "../PasswordBox/PasswordBox";
import { updateSecretQuestions } from "store/actions";
import { useSnackbar } from "notistack";

const initialFValues = {
  newRecoveryQuestion: "",
  newRecoveryAnswer: "",
  password: ""
}

export default function SecretQuestions(ref: any) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const validate = (fieldValues = values) => {
    const err = { ...errors };

    if ('password' in fieldValues) {
      err.password = validateRequired(fieldValues.password, intl)
    }
    if ('newRecoveryQuestion' in fieldValues) {
      err.newRecoveryQuestion = validateRequired(fieldValues.newRecoveryQuestion, intl)
    }
    if ('newRecoveryAnswer' in fieldValues) {
      err.newRecoveryAnswer = validateRequired(fieldValues.newRecoveryAnswer, intl)
    }

    setErrors({
        ...err
    })

    if (fieldValues === values)
        return Object.values(err).every(x => x === "")
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, false, validate);

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (validate()) {
      console.log("Handle Submit called")
      updateSecretQuestions(values).then((response: any) => {
        enqueueSnackbar(response.message, {
          variant: "success",
        })
        dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
      }).catch((response: any) => {
        dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
        enqueueSnackbar(response.message, {
          variant: "error",
          autoHideDuration: null
        })
      })
      resetForm()
      return true
    }
    dispatch(showNotification("Invalid", "error", 'Please fill the required fields'))
    return false
  }

  return (
    <Box pl={2} pt={2} className={classes.secretWidth}>
      <Typography variant="h4">{intl.formatMessage({ id: "SecretQuestions.title" })}</Typography><br />
      <Paper elevation={1} className={classes.formCtrl}>
        <Typography variant="h6">{intl.formatMessage({ id: "SecretQuestions.text1" })}</Typography>
        <Typography variant="h6">{intl.formatMessage({ id: "SecretQuestions.text2" })}</Typography>
        <Typography variant="h6">{intl.formatMessage({ id: "SecretQuestions.text3" })}</Typography><br />
        <Typography variant="h6">{intl.formatMessage({ id: "SecretQuestions.subtext1" })}</Typography>
        <Typography variant="h6">{intl.formatMessage({ id: "SecretQuestions.subtext2" })}</Typography>
        <form className={classes.formWrapper}>
          <Typography variant="h6">{intl.formatMessage({ id: "SecretQuestions.Password" })} *</Typography>
          <PasswordBox
            name="password"
            className="normal-input"
            value={values.password}
            onChange={handleInputChange}
            error={errors.password}
          />
          <Divider className={classes.divider} />
          <FormControl className={`${classes.paddingBottom15rem} width-100`} >
            <Controls.Select
              name="newRecoveryQuestion"
              label="Security Question *"
              placeholder="Select"
              value={values.newRecoveryQuestion}
              onChange={handleInputChange}
              options={secretQuestions}
              error={errors.newRecoveryQuestion}
            />
          </FormControl>
          <FormControl className={`${classes.paddingBottom15rem} width-100`}>
            <Typography variant="h6">{intl.formatMessage({ id: "SecretQuestions.Answer" })}</Typography>
            <Controls.Input
              name="newRecoveryAnswer"
              value={values.newRecoveryAnswer}
              onChange={handleInputChange}
              error={errors.newRecoveryAnswer}
            />
          </FormControl>
          <Box className={classes.buttonWrapper}>
            <Button variant="contained" disabled={(values.password !== "" && values.newRecoveryQuestion !== "" && values.newRecoveryAnswer?.trim().length > 3) ? false : true } color="secondary" type="submit" className={classes.btnUpdate} onClick={handleSubmit}>
              <FormattedMessage id={"SecretQuestions.updateSecretQuestion"} />
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  )
}
