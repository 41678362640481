import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, TextField, Typography } from "@mui/material";
import { useIntl } from 'react-intl';
import { useStyles } from "./styles";
import { AppReduxStore } from 'admin/store/reducerTypes';
import { IFormInput } from "admin/views/viewsTypes";
import { getQuestionnaireInstructionsByQuestionnaireId, processFormInstructions } from 'admin/store/actions/configuration';
import { getFormConfigurableString } from '../../../utils';

interface IPatientFormInstruction {
  id: string,
  providerAdministration: string,
  providerScoring: string,
  questionnaireId: string,
  questionnaireUse: string
}

interface IInputProps {
  disableUnderline?: boolean,
  disabled: boolean,
  readOnly: boolean
};

const Instructions = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { configuration } = useSelector((state: AppReduxStore) => state);
  const ID_FORM_USE = "instructions-form-use";
  const ID_INSTRUCTIONS = "instructions-instructions";
  const ID_SCORING = "instructions-scoring";
  const currentForm:IFormInput = configuration && configuration.sPatientFormA ? configuration.sPatientFormA : null;
  const patientFormInstructions:IPatientFormInstruction[] = configuration?.patientFormInstructions;
  const patientFormInstruction:IPatientFormInstruction = patientFormInstructions[0];
  const isPatientFormInstructionEmpty: boolean = (patientFormInstruction == null);
  const [currentPatientFormInstructionState, setCurrentPatientFormInstructionState] = useState<IPatientFormInstruction>(patientFormInstruction);
  const initialFormInstructions = Object.assign({}, patientFormInstruction);
  const formConfigurableType = getFormConfigurableString(currentForm);
  const isFormReadOnly: boolean = configuration && configuration.isFormReadOnly;
  const isTextFieldDisabled: boolean = (isFormReadOnly || (formConfigurableType !== 'Configurable'));
  const textFieldVariant: string = isTextFieldDisabled ? 'standard' as 'standard' : 'outlined' as 'outlined';
  const inputProps: IInputProps = isTextFieldDisabled
    ? { disableUnderline: true, disabled: isFormReadOnly, readOnly: isFormReadOnly }
    : { disabled: isFormReadOnly, readOnly: isFormReadOnly };

  const handleOnChange = (event:any) => {
    const name = event.target.id;
    const value = event.target.value;
    const newPatientFormInstructionState = Object.assign({}, currentPatientFormInstructionState);
    switch (name) {
        case ID_FORM_USE:
            newPatientFormInstructionState.questionnaireUse = value;
            break;
        case ID_INSTRUCTIONS:
            newPatientFormInstructionState.providerAdministration = value;
            break;
        case ID_SCORING:
            newPatientFormInstructionState.providerScoring = value;
            break;
    }
    setCurrentPatientFormInstructionState(newPatientFormInstructionState);
  };

  const handleOnBlur = (event:any) => {
    const name = event.target.id;
    const value = event.target.value;
    let initialValue = null;
    switch (name) {
        case ID_FORM_USE:
            initialValue = initialFormInstructions.questionnaireUse;
            break;
        case ID_INSTRUCTIONS:
            initialValue = initialFormInstructions.providerAdministration;
            break;
        case ID_SCORING:
            initialValue = initialFormInstructions.providerScoring;
            break;
    }
    let action = "noUpdate";
    if (initialValue !== value) {
        if (initialValue == null) {
            if (value === "") {
                action = "noUpdate";
            } else {
                action = "create";
            }
        } else {
            action = "modify";
        }
    }
    // Don't send update when there is no update
    if (action !== "noUpdate") {
        dispatch(processFormInstructions({
            instructionId: patientFormInstruction?.id? patientFormInstruction.id : null,
            formId: currentForm.id,
            action: action,
            name: name,
            value: value
        }));
    }
  };

  useEffect(() => {
    if (currentForm.id) {
        dispatch(getQuestionnaireInstructionsByQuestionnaireId(currentForm.id));
    } else {
        dispatch(getQuestionnaireInstructionsByQuestionnaireId(""));
    }
  }, [dispatch, currentForm.id]);

  useEffect(() => {
    setCurrentPatientFormInstructionState(patientFormInstruction);
  }, [patientFormInstruction]);

  return (
    <div className={classes.root}>
      { ((formConfigurableType === 'Configurable')? true : (isPatientFormInstructionEmpty)? false : true) && <>
        <Box mb={2}>
            <Typography variant="h6" component="span">{ intl.formatMessage({ id: "PatientFormConfig.Label.FormUse" }) }:</Typography>
            <TextField minRows={1} maxRows={2} multiline fullWidth margin='dense'
                variant={textFieldVariant as any}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                defaultValue={(patientFormInstruction && patientFormInstruction.questionnaireUse)? patientFormInstruction.questionnaireUse : ""}
                value={(currentPatientFormInstructionState && currentPatientFormInstructionState.questionnaireUse)? currentPatientFormInstructionState.questionnaireUse : ""}
                id={ID_FORM_USE}
                InputProps={inputProps} />
        </Box>
        <Box mb={2}>
            <Typography variant="h6" component="span">{ intl.formatMessage({ id: "PatientFormConfig.Label.Instructions" }) }:</Typography>
            <TextField minRows={1} maxRows={2} multiline fullWidth margin='dense'
                variant={textFieldVariant as any}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                defaultValue={(patientFormInstruction && patientFormInstruction.providerAdministration)? patientFormInstruction.providerAdministration : ""}
                value={(currentPatientFormInstructionState && currentPatientFormInstructionState.providerAdministration)? currentPatientFormInstructionState.providerAdministration : ""}
                id={ID_INSTRUCTIONS}
                InputProps={inputProps} />
        </Box>
        <Box mb={2}>
            <Typography variant="h6" component="span">{ intl.formatMessage({ id: "PatientFormConfig.Label.Scoring" }) }:</Typography>
            <TextField minRows={1} maxRows={2} multiline fullWidth margin='dense'
                variant={textFieldVariant as any}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                defaultValue={(patientFormInstruction && patientFormInstruction.providerScoring)? patientFormInstruction.providerScoring : ""}
                value={(currentPatientFormInstructionState && currentPatientFormInstructionState.providerScoring)? currentPatientFormInstructionState.providerScoring : ""}
                id={ID_SCORING}
                InputProps={inputProps} />
        </Box>
      </> }
  </div>
  );
}

export default Instructions