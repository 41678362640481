import React from "react";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import {SIZING} from "theme";
import {AppReduxStore} from "store/reducerTypes";
import ToolsMenu from "./ToolsMenu";
import EllipsisMenu from "./EllipsisMenu";
import PatientHeader from "./PatientHeader";
import SummaryTabs from "./SummaryTabs/SummaryTabs";
import {useStyles} from "./styles";


const PatientDetailsHeader = () => {
    const classes = useStyles();
    const { selectedPatient } = useSelector((state: AppReduxStore) => state.patient);
    if (!selectedPatient) return null;

    return (
        <Box className={classes.detailsHeader}>
            <Box display="flex" justifyContent="space-between" marginBottom={SIZING.scale500}>
                <PatientHeader />
                <Box display="flex">
                    <ToolsMenu />
                    <EllipsisMenu />
                </Box>
            </Box>
            <SummaryTabs/>
        </Box>
    )
}

export default PatientDetailsHeader