import { MemberRole, CareTeamMemberDetails, MemberRoles, CareTeamMember, CareTeamPatientDetails } from "../types";

/**
 * Returns true if Member has the given MemberRole, false otherwise
 */
const isRole = (member: CareTeamMember, role: MemberRole): boolean => member.roles.includes(role);

/**
 * Returns true if Member has PRIMARY  MemberRole, false otherwise
 */
const isPrimary = (member: CareTeamMember): boolean => isRole(member, MemberRoles.PRIMARY);

/**
 * Returns true if Member has ADMIN MemberRole, false otherwise
 */
const isAdmin = (member: CareTeamMember): boolean => isRole(member, MemberRoles.ADMIN);

/**
 * Creates a sort function for a given MemberRole
 */
const createRoleSort = (role: MemberRole) => (a: CareTeamMemberDetails, b: CareTeamMemberDetails) => {
    const roleA = isRole(a, role);
    const roleB = isRole(b, role);

    if (roleA && !roleB) {
        return -1;
    } else if (!roleA && roleB) {
        return 1;
    }
    return 0;
};

/**
 * Sorts members by Primary role. Primary goes first
 */
const primarySort = createRoleSort(MemberRoles.PRIMARY);
/**
 * Sorts members by Admin role. Admin goes first
 */
const adminSort = createRoleSort(MemberRoles.ADMIN);

const localeSort = (a: string, b: string) => a.localeCompare(b, "en", { sensitivity: "base" });

const memberSort = (a: CareTeamMemberDetails, b: CareTeamMemberDetails) => {
    const primarySortResult = primarySort(a, b);
    if (primarySortResult !== 0) return primarySortResult;

    const adminSortResult = adminSort(a, b);
    if (adminSortResult !== 0) return adminSortResult;

    return localeSort(a.sortName, b.sortName);
};

export const sortMembers = (members: CareTeamMemberDetails[]): CareTeamMemberDetails[] => {
    return [...members].sort(memberSort);
};

export const sortPatients = (patients: CareTeamPatientDetails[]): CareTeamPatientDetails[] => {
    return [...patients].sort((a, b) => localeSort(a.displayName, b.displayName));
};

export const getDisplayRole = (member: CareTeamMember): Exclude<MemberRole, "SPECIALIST"> | undefined => {
    if (isPrimary(member)) {
        return MemberRoles.PRIMARY;
    }
    if (isAdmin(member)) {
        return MemberRoles.ADMIN;
    }
};

const toRegex = (value: string): RegExp => {
    // to avoid confusion from the user, let's make sure they cannot write regex
    const escaped = value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    return new RegExp(escaped, "i");
};

export const filterMembers = (members: CareTeamMemberDetails[], search: string): CareTeamMemberDetails[] => {
    if (!search) return members;

    const searchRegex = toRegex(search);

    return members.filter(
        (member) => member.displayName.match(searchRegex) || (member.credentials || "").match(searchRegex)
    );
};

export const filterPatients = (patients: CareTeamPatientDetails[], search: string): CareTeamPatientDetails[] => {
    if (!search) return patients;

    const searchRegex = toRegex(search);

    return patients.filter((patient) => patient.displayName.match(searchRegex) || (patient.mrn || "").match(searchRegex));
};
