import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() => {
    return {
        rootWrapper:{
            background:COLORS.SIDE_NAV_HEADER_BG,
            boxShadow:'inset -1px 0 0 rgb(0 0 0 / 10%), inset 0 0px 0 rgb(0 0 0 / 10%), -1px 0px 8px rgb(0 0 0 / 10%), inset 0px -1px 0px rgb(0 0 0 / 10%)',
            padding:SIZING.scale500,
            width:'100%',
            height:'auto',
            borderTopRightRadius:BORDER_RADIUS.LARGE,
            overflow:'hidden',
           
        },
        searchWrapper:{
            display:'flex',
            alignItems:'center'
        },
        searchInput:{
            "& input":{
                textIndent:'0rem',
                minHeight:'2.813rem'
            },
            color:'rgba(0, 0, 0, 0.8)'
        },
        searchIcon:{
            marginLeft: SIZING.scale375,
            marginRight: `-${SIZING.scale100}`,
            "& g, & path": {
                fill: 'rgba(0, 0, 0, 0.8)',
            },
        },
        closeIcon:{
            cursor:'pointer',
            "& g, & path": {
                fill: COLORS.LGRAY,
            },
        },
        plusIcon:{
            cursor:'pointer',
            margin:`0rem 0.75rem 0 1.20rem`,
            "& g, & path": {
                fill: COLORS.BLACK,
            },
        },
        notificationLabel:{
            height:SIZING.scale400,
            width:SIZING.scale400,
            background: COLORS.DBLACK,
            color:COLORS.WHITE,
            borderRadius:'50%',
            padding:'10px',
            position:'absolute',
            right:'10px',
            top: '50%',
            transform: 'translateY(-50%)',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            fontSize: SIZING.scale300
        },
        rpmTab:{
            listStyleType:'none',
            display:'flex',
            alignItems:'center',
            background:'rgba(0, 0, 0, 0.05)',
            borderRadius:'50px',
            padding:SIZING.scale50,    
            justifyContent:'space-between',
            marginTop:SIZING.scale400,
            marginBottom:'0',
            "& li":{
                fontSize: SIZING.scale350,
                color:'rgba(144, 144, 152, 1)',
                padding:'0.313rem 2.5rem',
                cursor:'pointer',
                fontWeight:600,
                position:'relative',
                "&.active":{
                    color:'rgba(57, 57, 72, 1)',
                    fontWeight:900,
                    background:COLORS.WHITE,
                    boxShadow:'0px 1px 2px rgba(0, 0, 0, 0.05)',
                    borderRadius:'50px',
                }
                
            }
        }
    }
})
