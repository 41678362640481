import React from "react";
import { Box, FormHelperText, FormControl, FormControlLabel, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as WarningIcon } from "admin/assets/common/ExclamationWarning.svg";

export default function ToggleSwitch(props: any) {
    const useStyles = makeStyles((theme) => ({
        labelCtrl: {
            fontWeight: 600,
            color: '#393948',
            fontSize: '14px',
            textAlign: 'left'
        },
        error: {
            color: '#E32B29',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '5px'
        },
        exclamationIcon: {
            marginTop: '3px'
        },
        root: {
            width: 'auto'
        },
        IOSSwitch: {
            width: 42,
            height: 26,
            padding: 0,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: '#65C466',
                        opacity: 1,
                        border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color: theme.palette.grey[100],
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.7,
                    backgroundColor: '#E9E9EA'
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 22,
                height: 22,
            },
            '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: '#E9E9EA',
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                }),
            },
        }
    }));

    const classes = useStyles();
    const { name, label, value, checked, error = null, onChange, disabled = false, large = false } = props;
    return (
        <FormControl classes={{ root: classes.root }}>
            <label className={classes.labelCtrl}>{label}</label>
            <FormControlLabel classes={{ label: classes.labelCtrl }}
                control={<Switch checked={checked} name={name} disabled={disabled} className={large ? classes.IOSSwitch : ''}
                    onChange={onChange}
                    color="primary" />} label={value} />
            {error && <Box display="inline-flex">
                <WarningIcon className={classes.exclamationIcon} />
                <FormHelperText className={classes.error}>{error}</FormHelperText>
            </Box>}
        </FormControl >
    )
}
