import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    IconButton,
    Typography
} from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import UserLicensesListing from "./Licenses/UserLicensesListing"
import { useStyles } from "./styles";

const UserProductsEdit = () => {
    const userLicensesListingRef = useRef<any>()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { sUser, isBulkEditUserProducts, sProfileIds, isAllSelectedBulk, users } = useSelector((state: AppReduxStore) => state).admin

    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose()
    };

    const handleSaveProducts = (event: any) => {
        const result = userLicensesListingRef.current.saveProducts(event);
        if (result) {
            handleClose();
        }
    };
    const handleDiscardProducts = (event: any) => {
        handleClose();
    };

    return (
        <Modal onClose={onClose} id={MODAL_TYPES.EDIT_USER_PRODUCTS}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={isBulkEditUserProducts ? "UserProductsEdit.Title.Add" : "UserProductsEdit.Title"} values={isBulkEditUserProducts ? { name: `${isAllSelectedBulk ? users?.totalElements : sProfileIds.length} selected users` } : { name: `${sUser && `${sUser?.firstName ?? ''} ${sUser?.lastName ?? ''}`}` }} />
                    </Typography>
                </Box>
                <Box>
                    <IconButton size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnDiscardChanges" variant="outlined" color="secondary" style={{ marginLeft: '10px', background: '#E32B29', color: '#FFFFFF' }} onClick={handleDiscardProducts}>
                            <FormattedMessage id={"UserProductsEdit.DiscardButtonText"} />
                        </Button>
                        <Button id="btnSaveLocation" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveProducts}>
                            <FormattedMessage id={"UserProductsEdit.SaveButtonText"} />
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} className={classes.dlgContent}>
                <UserLicensesListing ref={userLicensesListingRef} />
            </DialogContent>
        </Modal>
    );
}

export default UserProductsEdit