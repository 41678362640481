import { Theme } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    loadingIndicatorIcon: {
        textAlign: 'center',
        marginRight: '30px',
        width: '96px',
        height: '96px',
        "& path": {
            fill: '#FFFFFF'
        },
    },
    loaderTitle: {
        fontWeight: 600,
        fontSize: '32px',
        color: '#FFFFFF',
        margin: '7px 0px'
    },
    loaderDesc: {
        fontSize: '18px',
        color: '#FFFFFF',
        marginTop: '10px',

    },
    overlay: {
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 999999
    },

    overlayInner: {
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    overlayContent: {
        textAlign: 'center',
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    loadingTextWrapper: {
        marginTop: '92px'
    }
}));