import React, { useEffect, useState } from "react";
import { Grid, CardContent, Card, Button, Typography, Box } from "@mui/material";
import { useStyles } from './styles'
import { fetchRegistrationEmails, setSelectedRegistrationEmails } from "admin/store/actions/configuration";
import Controls from 'admin/components/Controls/Controls';
import { useDispatch, useSelector } from "react-redux";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useIntl } from "react-intl";
import { ReactComponent as FlagSvg } from "icon-library/SVG/Flag.svg";
import { ReactComponent as InfoSvg } from "icon-library/SVG/Info_Circle.svg";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross.svg";
import { createSvgIcon } from "utils";
import RichTooltip from "admin/components/RichToolTip/RichTooltip";
const FlagIcon = createSvgIcon(FlagSvg);
const InfoIcon = createSvgIcon(InfoSvg);
const CrossIcon = createSvgIcon(CrossSvg);
export default function RegistrationList() {
  const intl = useIntl()
  const { auth, configuration, license } = useSelector((state: AppReduxStore) => state)
  const corePlatFormLicenseCount = license?.practiceLicenses && license?.practiceLicenses.find((core: any) => core.product.code === "CORE")?.licenseCount
  const hidePatientEmailUI = !corePlatFormLicenseCount || corePlatFormLicenseCount === 0
  const registrationEmailPatients = configuration && configuration.registrationEmailPatients ? configuration.registrationEmailPatients : []
  const registrationEmailProvider = configuration && configuration.registrationEmailProvider ? configuration.registrationEmailProvider : []
  const classes = useStyles();
  const dispatch = useDispatch()
  const [isReadyForUse, setReadyForUse] = useState(false)
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (auth.currentPractice) {
      dispatch(fetchRegistrationEmails("PATIENT_REGISTRATION"))
      dispatch(fetchRegistrationEmails("PROVIDER_REGISTRATION"))
    }
  }, [auth.currentPractice, dispatch, intl]);
  useEffect(() => {
    if (auth.currentPractice) {
      const configSetupFlag = hidePatientEmailUI ? (Object.keys(configuration.registrationEmailProvider)?.length > 0 &&
        configuration.registrationEmailProvider?.version > 0) : (Object.keys(configuration.registrationEmailPatients)?.length > 0 &&
          configuration.registrationEmailPatients?.version > 0 &&
          Object.keys(configuration.registrationEmailProvider)?.length > 0 &&
          configuration.registrationEmailProvider?.version > 0)
      setReadyForUse(configSetupFlag)
    }
  }, [auth.currentPractice, configuration.registrationEmailPatients, configuration.registrationEmailProvider, dispatch, intl]);
  const handleEditRegistration = () => {
    dispatch(setSelectedRegistrationEmails())
    dispatch(setActiveModal(MODAL_TYPES.EDIT_REGISTRATION))
  }
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const borders = {
    Active: '2px solid #00A875',
    Pending: '2px solid #F5A800',
    Inactive: '2px solid #E32B29',

  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={8}>
          <Typography noWrap variant={"h3"} className={classes.registration}>{intl.formatMessage({ id: "RegistrationEdit.InfoHeader.Heading" })}
            <label style={{ border: isReadyForUse ? borders["Active"] : borders["Inactive"], padding: '2px 4px', fontSize: '14px', fontWeight: 600, borderRadius: '4px', marginLeft: '32px', textAlign: 'center' }}> {isReadyForUse ? intl.formatMessage({ id: "RegistrationEdit.Label.ReadyForUse" }) : "Configuration Required"}</label>
          </Typography>
          <Typography noWrap variant={"h6"} className={classes.registration}>{intl.formatMessage({ id: "RegistrationEdit.InfoHeader.InfoText" })}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.textAlignRight}>
          <Box>
            <Box display={"inline-flex"} mb={1} mt={1} textAlign="right">
              <Typography noWrap variant={"h3"} className={classes.infoText} style={{ marginRight: '37px' }}>{intl.formatMessage({ id: "RegistrationEdit.InfoHeader.Heading" })}
              </Typography>

              <RichTooltip content={
                <Box textAlign="right">
                  <Box>
                    <CrossIcon fill={"#FFFFFF"} className={classes.infoIcon} onClick={handleTooltipClose} />
                  </Box>
                  <Typography className={classes.infoTooltipText}>
                    Please configure <b>Clinic Staff</b> and <b>Patients</b> email template before activating this product.
                  </Typography>
                  <Button id="btnEditRegistrationEmail" variant="contained" color="primary" style={{ marginTop: '10px' }} onClick={handleEditRegistration}>
                    {intl.formatMessage({ id: "RegistrationEdit.EditNow.ButtonText" })}
                  </Button>
                </Box>
              }
                open={open}
                onClose={() => setOpen(false)}
                placement="bottom">
                <label><InfoIcon fill={"#0F7BBD"} className={classes.infoIcon} onClick={handleTooltipOpen} /></label>
              </RichTooltip>
            </Box>
          </Box>
          <Box display="-webkit-inline-box" mb={1} mt={1}>
            <Controls.ToggleSwitch large={true}
              label={""}
              checked={isReadyForUse}
              value={""}
              disabled={!isReadyForUse}
            />
            <Typography noWrap variant={"h6"} className={classes.toggleSwitchText} style={{ color: isReadyForUse ? '#393948' : '#909098' }}>
              {intl.formatMessage({ id: "RegistrationEdit.Label.ReadyForUse" })}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant={"h3"}>Registration Emails</Typography>
          {(Object.keys(configuration.registrationEmailPatients)?.length === 0 || configuration.registrationEmailPatients?.version === 0 ||
            Object.keys(configuration.registrationEmailProvider)?.length === 0 || configuration.registrationEmailProvider?.version === 0) && <Typography noWrap variant={"h2"} className={classes.infoMessage}><FlagIcon fill={"#FF0000"} className={classes.flagIcon} />Required before adding users</Typography>}
        </Grid>
        <Grid item xs={6}>
          <Button id="btnAddUsers" variant="contained" color="primary" style={{ float: 'right' }} onClick={handleEditRegistration}>
            Edit Emails
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant={"h4"}>Clinical Staff</Typography>
          <div style={{ paddingTop: "10px" }}>
            <Controls.Input
              name="subject"
              label="Subject Line"
              placeholder="You have been invited to Altais eNable by your practice."
              readOnly={true}
              value={registrationEmailProvider.subject}
              className={classes.labelCtrl}
            />
          </div>
          <Card>
            <CardContent>
              {registrationEmailProvider.logoUrl ?
                <Grid container className={classes.logoContent}>
                  <img src={`${registrationEmailProvider.logoUrl}?${Date.now()}`} alt="Logo" style={{ maxWidth: "180px", maxHeight: "150px" }} crossOrigin="anonymous" />
                </Grid> :
                <div className={classes.logo}>
                  Logo goes here
                </div>
              }
              <Typography className={classes.body1}>
                {registrationEmailProvider.greeting ? registrationEmailProvider.greeting : intl.formatMessage({ id: "RegistrationEdit.Label.Greetings" })}
                &nbsp;{intl.formatMessage({ id: "RegistrationEdit.Label.FirstName" })}
              </Typography>

              <Typography className={classes.body1}>
                {registrationEmailProvider.body ? registrationEmailProvider.body : intl.formatMessage({ id: "RegistrationEdit.Label.Body" })}
              </Typography>

              <Typography className={classes.body1}>
                {intl.formatMessage({ id: "RegistrationEdit.Label.Instructions" })}
              </Typography>
              <Typography className={classes.instruction}>
                {intl.formatMessage({ id: "RegistrationEdit.Label.InstructionSection" })}
              </Typography>
              <Typography component="div" className={classes.rightText}>
                <Typography variant={"h4"} className={classes.body1}>{registrationEmailProvider.closing ? registrationEmailProvider.closing : intl.formatMessage({ id: "RegistrationEdit.Label.Closing" })},</Typography>
                <Typography variant={"h4"} className={classes.body1} style={{ textAlign: "right" }}>{(registrationEmailProvider.signature === "$practiceName") ? auth.currentPractice?.name : registrationEmailProvider.signature}</Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {
          !hidePatientEmailUI &&
          <Grid item xs={6}>
            <Typography variant={"h4"}>Patients</Typography>
            <div style={{ paddingTop: "10px" }}>
              <Controls.Input
                name="subject"
                label="Subject Line"
                placeholder="You have been invited to Altais eNable by your practice."
                readOnly={true}
                value={registrationEmailPatients.subject}
              />
            </div>
            <Card>
              <CardContent>
                {registrationEmailPatients.logoUrl ?
                  <Grid container className={classes.logoContent}>
                    <img src={`${registrationEmailPatients.logoUrl}?${Date.now()}`} alt="Logo" style={{ maxWidth: "180px", maxHeight: "150px" }} crossOrigin="anonymous" />
                  </Grid> :
                  <div className={classes.logo}>
                    Logo goes here
                  </div>
                }
                <Typography className={classes.body1}>
                  {registrationEmailPatients.greeting ? registrationEmailPatients.greeting : intl.formatMessage({ id: "RegistrationEdit.Label.Greetings" })}
                  &nbsp;{intl.formatMessage({ id: "RegistrationEdit.Label.FirstName" })}
                </Typography>

                <Typography className={classes.body1}>
                  {registrationEmailPatients.body ? registrationEmailPatients.body : intl.formatMessage({ id: "RegistrationEdit.Label.Body" })}
                </Typography>

                <Typography className={classes.body1}>
                  {intl.formatMessage({ id: "RegistrationEdit.Label.Instructions" })}
                </Typography>
                <Typography className={classes.instruction}>
                  {intl.formatMessage({ id: "RegistrationEdit.Label.InstructionSection" })}
                </Typography>
                <Typography component="div" className={classes.rightText}>
                  <Typography variant={"h4"} className={classes.body1}>{registrationEmailPatients.closing ? registrationEmailPatients.closing : intl.formatMessage({ id: "RegistrationEdit.Label.Closing" })},</Typography>
                  <Typography variant={"h4"} className={classes.body1} style={{ textAlign: "right" }}>{(registrationEmailPatients.signature === "$practiceName") ? auth.currentPractice?.name : registrationEmailPatients.signature}</Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        }
      </Grid>
    </div>
  );
}