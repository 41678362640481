import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() => ({
    contentImg:{
      display:"flex",
      justifyContent:"center",
      "& img":{
        border:"1px solid rgba(0, 0, 0, 0.2)"
      }
    },
    title1: {
      fontSize: '1.375rem',
      color:COLORS.LBLUE,
      fontWeight: 300
    },
    contentList:{
      marginLeft:SIZING.scale2000,
      marginTop:SIZING.scale800
    },
    listContent:{
      display:"flex"
    },
    sliderImage:{
      border:"solid 0.5px rgba(0, 0, 0, 0.1)",
      height:"450px",
      width:"800px",
      background:COLORS.DGREY
    },
    descript:{
      color:COLORS.GREY,
      fontSize:SIZING.scale400,
      fontWeight:300,
      marginLeft:SIZING.scale100
    },
    listTitle:{
      color:COLORS.GREY,
      fontSize:SIZING.scale400,
      fontWeight:600,
      marginRight:SIZING.scale100
    }
}));