import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { ModalTypesId, ModalProps } from "components/Modals/types";
import { ValueOf } from "types";

/**
 * Allow two different function signatures,
 *  either it has modalId and props, and in that case modalId has 
 *  to be a property of the type ModalProps
 *  and props has to be the type for that given key
 *  of the function has only one parameter: modalId and this has to be one 
 *  of the values of MODAL_TYPES that is not present in ModalProps
 */
interface setActiveModalFn {
    <K extends keyof ModalProps>(modalId: K, props: ModalProps[K]): ThunkAction<void, {}, {}, AnyAction>;
    (modalId: Exclude<ModalTypesId, keyof ModalProps>): ThunkAction<void, {}, {}, AnyAction>;
}

// setActiveModal updates the active modal that should be shown to the user
export const setActiveModal: setActiveModalFn = (
    modalId: ModalTypesId,
    activeModalProps: ValueOf<ModalProps> | null = null
    ) => {
    return (dispatch:ThunkDispatch<{},{},AnyAction>) => {
        dispatch({type: 'SET_ACTIVE_MODAL', payload: {activeModal: modalId, activeModalProps} });
    }
}
