import { useSelector } from "react-redux"
import { AppReduxStore } from "admin/store/reducerTypes"
import { USER_LEVELS } from "admin/constant"

export const canCreateOrgsAndSubOrgs = (): boolean => {
    return !HasUserLevels([USER_LEVELS.ALTAIS_ADMIN_3])
}
export const canCreatePractice = (): boolean => {
    return !HasUserLevels([USER_LEVELS.ALTAIS_ADMIN_3])
}
export const canEditUsers = (): boolean => {
    return !HasUserLevels([USER_LEVELS.ALTAIS_ADMIN_3])
}
const HasUserLevels = (userLevels: string[]): boolean => {
    const { currentProfileRoleJoinInfo } = useSelector((state: AppReduxStore) => state).auth
    const { level } = currentProfileRoleJoinInfo || {}
    return userLevels.includes(level?.name)
}