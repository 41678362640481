import { Box, Button, DialogActions, DialogContent, Paper, Typography } from "@mui/material";
import { makeApiCall } from 'admin/common/utils';
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from "admin/store/reducerTypes";
import { Fragment } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useStyles } from "./styles";

const FeeScheduleDeleteConfirmation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const classes = useStyles();
    const { modalAdmin } = useSelector((state: AppReduxStore) => state);
    const scheduleId = modalAdmin?.modalProps?.scheduleId;
    const onDone = modalAdmin?.modalProps?.onDone;

    const onClose = () => {
        dispatch(setActiveModal(""));
    }

    const onDelete = async (event: any) => {
        event.preventDefault();
        if (scheduleId) {
            await makeApiCall(dispatch, "DELETE", `/admin/v1/billing/schedule/${scheduleId}`).then(() => {
                onClose();
                history.push('/admin/feeschedules');
                if (!!onDone)
                    onDone();
                dispatch(showNotification("Fee Schedule deleted successfully.", "success", "Fee Schedule deleted successfully."));
            }).catch((error: any) => {
                dispatch(showNotification("Error deleting Fee Schedule", "error", "Error deleting Fee Schedule"));
            });
        } else {
            dispatch(showNotification("Error deleting Fee Schedule", "error", "Fee Schedule Id is missing."));
        }
    };

    return (
        <Modal onClose={onClose} classes={classes.wrapper} id={MODAL_TYPES.DELETE_FEE_SCHEDULE_CONFIRMATION_MODAL} small={true}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "fee.schedule.delete.confirmation.dialog.title" })}
                    </Typography>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined" style={{ zIndex: 100 }}
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "fee.schedule.delete.confirmation.button.cancel" })}
                        </Button>
                        <Button id="btnDelete" variant="outlined" color="primary" style={{ marginLeft: '10px', background: '#E32B29' }} onClick={onDelete}>
                            {intl.formatMessage({ id: "fee.schedule.delete.confirmation.button.delete" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper square elevation={0} style={{ background: '#F6F8FA' }}>
                    <Typography variant="h3" style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '24px', lineHeight: '140%', color: '#393948', marginBottom: '20px' }}>
                        {intl.formatMessage({ id: "fee.schedule.delete.confirmation.content.line1" })}
                    </Typography>
                    <Typography variant="h3" style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '18px', lineHeight: '140%', color: '#000000' }}>
                        {intl.formatMessage({ id: "fee.schedule.delete.confirmation.content.line1" })}
                    </Typography>
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default FeeScheduleDeleteConfirmation