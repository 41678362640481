import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        root: {
            position: "absolute",
            top: "0%",
            left:"0px",
            width: "100%",
            maxHeight: "calc(100vh - (75px))",
            overflow: "auto",
            background: COLORS.SIDE_NAV_HEADER_BG,
            boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.3), 0px 5px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            padding: SIZING.scale200,
            paddingRight: SIZING.scale400,
            borderBottom: `1px solid ${COLORS.LGRAY}`
        },
        listItem: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: `0 ${SIZING.scale200}`
        },
        menuIcon: {
            fontSize: SIZING.scale500,
            cursor: "pointer",

        }
}));