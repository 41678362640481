import React from "react"
import { useIntl } from "react-intl";
import LayoutExtr from 'admin/components/Layout/LayoutExtr';
import GooglePlay from "./assets/images/Google_Play_store.png";
import AppleStore from "./assets/images/applestore.png";
import { useStyles } from "./styles";
const PatientLogin = () => {
    const intl = useIntl()
    const classes = useStyles()

    const handleImageClick = (type:string) => {
        if(type === "IOS"){
            window.open("https://apps.apple.com/us/app/altais-enable/id1552803341")
        }else{
            window.open("https://play.google.com/store/apps/details?id=io.altaishealth.enable.production&hl=en&gl=US")
        }
    }
    return (
        <LayoutExtr heading={intl.formatMessage({ id:"Patient.Login.Error"})} subHeading={intl.formatMessage({ id: "Patient.Login.Error.Alternate" })}>
            <div className={classes.buttonSection}>
            <img className={classes.imageSection} src={AppleStore} onClick={()=>handleImageClick("IOS")} alt={intl.formatMessage({ id: "Patient.Login.Download.AppleStore" })}></img>
            <img className={classes.imageSection} src={GooglePlay} onClick={()=>handleImageClick("Android")} alt={intl.formatMessage({ id: "Patient.Login.Download.GooglePlay" })}></img>
            </div>
            
        </LayoutExtr>
    )
}

export default PatientLogin