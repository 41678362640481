import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import usePublications from "../hooksHelper/usePublication";
import Publication from "../HelperComponents/Publication";
import useParticipantName from "../hooksHelper/useParticipantName";
import Reconnect from "../HelperComponents/Reconnect/Reconnect";
import { ReactComponent as CameraVideoDisabledIcon } from "icon-library/SVG/Camera_Video-Disabled.svg";
import { createSvgIcon } from "utils";
import { ParticipantPropsType } from "./types"
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { pushSystemMessage } from "store/actions"
import {  notifyProviders,removeUserFromCall } from "store/actions";
import { SYS_MESSAGES } from "constant";
import { ReactComponent as crossIcon } from "icon-library/SVG/Cross.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as PinIcon } from "icon-library/SVG/Arrows_Up-Down-UP.svg";
const CameraDisabledIcon = createSvgIcon(CameraVideoDisabledIcon);

function Participant({ data, className = '', videoOnly = false, shouldDisplayName = true, participants = [],updatePinnedParticipant,participantType }: ParticipantPropsType) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const { waitingRoom,user } = useSelector((store: AppReduxStore) => store)
    const { currentProfileId } = user
    const publications = usePublications(data);
    const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(true)
    const { addParticipant, getPaticipantName } = useParticipantName()
    const isPatient = participantType === "PATIENT";
   
    useEffect(() => {
        data.identity && shouldDisplayName && addParticipant(data.identity)
    }, [])
    //@ts-ignore
    const { title, firstName, lastName } = getPaticipantName(data.identity, true);

    const handleParticipantPin = () =>{
        updatePinnedParticipant&&updatePinnedParticipant(data.identity)
    }

    const handleDropUser = (identity:string) => {
        const { currentMeetingDetails } = waitingRoom
        const { mergedParticipantsInfo=[] } = currentMeetingDetails
        const [mergedParticipant={}] = mergedParticipantsInfo || []

        if(mergedParticipant?.twilioUsername === identity){
            dispatch(removeUserFromCall(mergedParticipant))
            dispatch(notifyProviders(currentProfileId,"WAITINGROOM",null,currentMeetingDetails.visitReminderId))
        }
       dispatch(pushSystemMessage(identity, null, SYS_MESSAGES.SYS_NRU_EVISIT_CALL_BACK_TO_WAITINGROOM, user,  null, null,{inviteToken:currentMeetingDetails.inviteToken }))
    }

    return (
        <div className={`${classes.root}  ${className || ''}`}>
        <div className={classes.container}>
            {(data.videoTracks.size === 0 || (!isVideoEnabled && !shouldDisplayName)) && (
                <div className={classes.camDisabled}>
                    <CameraDisabledIcon fill="white" />
                </div>
            )}
            {shouldDisplayName && <p className={isVideoEnabled ? classes.participantName : classes.participantNameFull}><span>{title}&nbsp;</span><span className={`${lastName ? 'firstName':''}`}>{firstName}&nbsp;</span><span>{lastName}</span></p>}
            <Reconnect data={data} />
            {publications.map(publication => (
                <Publication
                    key={publication.kind}
                    publication={publication}
                    videoOnly={videoOnly}
                    videoStyle="cover"
                    updateVideoState={setIsVideoEnabled}
                />
            ))}
        </div >
        
            {participants.length > 1 &&!isPatient&& shouldDisplayName&& <>
                <div className={classes.closeIcon} onClick={()=>handleDropUser(data.identity)}>
                <SVGIcon svg={crossIcon} size="scale600" />
            </div>
            <div className={classes.pinIcon} onClick={handleParticipantPin}>
                <SVGIcon svg={PinIcon} size="scale600" />
            </div>
            </>}
        </div>
    )
}
export default Participant