import { COLORS, SIZING, TYPOGRAPHY } from "theme";
import { makeStyles } from "@mui/styles";
import * as CSS from "./class-names";
import { ModalLayoutsClassnames } from "lib/ui-components/modal-layouts";

export const useStyles = makeStyles(() => ({
    [CSS.ModalPanel]: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        width: "100%",
        // Min height 0 will prevent the panel to outgrown the Modal Layout. 
        minHeight: 0,
        [`& .${CSS.ModalPanel__Title}`]: {
            padding: `calc((${SIZING.scale950} - ${TYPOGRAPHY.modalSectionHeader.lineHeight}) / 2) 0`,
            color: COLORS.MIDDLE_GRAY,
            cursor: "default",
        },
        [`& .${CSS.ModalPanel__TitleArea}`]: {
            flexShrink: 1,
        },
        [`& .${CSS.ModalPanel__ContentArea}`]: {
            overflowY: "auto",
            flexGrow: 1,
            // make sure children with absolute positioning will appear inside
            position: "relative"
        },
    },
    "@global": {
        [`.${ModalLayoutsClassnames.ModalSidePanelLayout__LeftPanel}, .${ModalLayoutsClassnames.ModalSidePanelLayout__RightPanel}`]: {
            [`& > .${CSS.ModalPanel} .${CSS.ModalPanel__ContentArea}`]: {
                marginRight: `-${SIZING.scale400}`
            }
        }
    }
}));
