import { Button, Typography, Box, FormControl } from "@mui/material";
import LayoutExtr from "admin/components/Layout/LayoutExtr";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Form, useForm } from "admin/components/useForm";
import Controls from "admin/components/Controls/Controls";
import { useState, forwardRef } from "react";
import { useStyles } from "./styles";
import { secretQuestions } from 'admin/constant';
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { showNotification } from "admin/store/actions/snackbar";

// @ts-ignore
const { ENABLE_API_URL } = window.env

const initialFValues = {
    "recoveryAnswer": "",
    "recoveryQuestion": "",
}

// AddSecretQuestions is used to update the Secret Questions and answers to the user profile
const AddSecretQuestions = forwardRef((props: any, ref: any) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const intl = useIntl()
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar()
    const { userName } = props

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (validate()) {
            setLoading(true)

            const axios = require('axios');
            axios.post(ENABLE_API_URL + `/api/admin/v1/user/recoveryQuestion`, { "recoveryAnswer": btoa(values.recoveryAnswer?.trim()), "recoveryQuestion": values.recoveryQuestion, "userName": userName })
                .then((response: { status: any, data?: any, message?: any }) => {
                    if (response.status === 200) {
                        enqueueSnackbar(intl.formatMessage({ id: "ResetPasswordValidation.successMessage" }), {
                            variant: "success",
                        })

                        history.push('/login')
                    }
                    setLoading(false)
                })
                .catch((error: any) => {
                    setLoading(false)
                    dispatch(showNotification("Reset Password Error", "error", error.message))
                });
        }
    }

    const validate = (fieldValues = values) => {
        const err: any = { ...errors };

        const requiredFieldError = intl.formatMessage({ id: "ResetPasswordValidation.error.requiredField" });

        if ('recoveryAnswer' in fieldValues) {
            err.recoveryAnswer = fieldValues.recoveryAnswer ? "" : requiredFieldError
        }
        if ('recoveryQuestion' in fieldValues)
            err.recoveryQuestion = fieldValues.recoveryQuestion ? "" : requiredFieldError

        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(
        initialFValues, false, validate);

    const submitDisabled = loading || (values.recoveryQuestion && values.recoveryAnswer && values.recoveryAnswer.trim().length > 3) ? false : true
    return (
        <LayoutExtr heading={intl.formatMessage({ id: `AddSecretQuestion.heading.ChooseSecurityQuestion` })}
            subHeading={intl.formatMessage({ id: `AddSecretQuestion.subheading.ChooseSecurityQuestion` })}>
            <Box>
                <Form onSubmit={onSubmit}>
                    <FormControl className={classes.paddingTop}>
                        <Controls.Select
                            name="recoveryQuestion"
                            label="Security Question *"
                            placeholder="Select"
                            value={values.recoveryQuestion}
                            options={secretQuestions}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl className={classes.paddingTop}>
                        <Controls.Input
                            name="recoveryAnswer"
                            label="Answer"
                            placeholder="Enter Here"
                            value={values.recoveryAnswer}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <br /><br />
                    <Box className={classes.secretQuestionContainer}>
                        <CustomTypography className={classes.header} variant="largeHeader">
                            <FormattedMessage id="AddSecretQuestion.heading.SecurityAnswers" />
                        </CustomTypography>
                        <CustomTypography className={classes.header} variant="largeSubheader">
                            <FormattedMessage id="AddSecretQuestion.subheading.CaseSensitive" />
                        </CustomTypography>
                        <CustomTypography className={classes.header} variant="largeSubheader">
                            <FormattedMessage id="AddSecretQuestion.subheading.Characters" />
                        </CustomTypography>
                    </Box>

                    <Button type="submit" disabled={submitDisabled} variant="outlined" color="primary" fullWidth>
                        <Typography variant="button">
                            <FormattedMessage id={`AddSecretQuestion.button.Submit`} />
                        </Typography>
                    </Button>
                </Form>
            </Box>
        </LayoutExtr>
    )
})

export default AddSecretQuestions