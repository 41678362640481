import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, COLORS, SIZING } from "../../../theme";

export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: COLORS.BLACK,
        border: "1px solid rgba(57, 57, 72, .6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: COLORS.MODAL_SUBTITLE_TEXT,
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: COLORS.MODAL_TITLE_TEXT
    },
    dlgAction1: {
        padding: '28px 16px 16px 32px'
    },
    dlgAction2: {
        padding: '0px 32px 16px 1px'
    },
    footer: {
        color: COLORS.NOTIFICATION_TEXT,
        marginTop: SIZING.scale400
    },
    warning: {
        color: COLORS.ERROR_BG
    },
    title2: {
        fontWeight: 900,
        fontSize: '18px',
        lineHeight: '140%',
        color: '#393948'
    },
    title3: {
        color: '#3c3d4b'
    },
    placeholder: {
        color: "#aaa"
    }
}));