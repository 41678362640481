import React from "react";
import { Checkbox, Tooltip } from "@mui/material";
import { FunctionalMenuItem } from "common/components/OptionMenu/types";
import { MouseEvent as ReactMouseEvent } from "react";
import { ProviderListItemProps } from "./types";
import { ReactComponent as CheckboxChecked } from "icon-library/SVG/Checkbox_Square.svg";
import { ReactComponent as CheckboxUnchecked } from "icon-library/SVG/Checkbox-Light_Square.svg";
import { ReactComponent as CirclesIcon } from "../../../tempIcons/Circles.svg";
import { ReactComponent as EmptyIcon } from "../../../tempIcons/User_Doctor-Stethoscope-Shoulder.svg";
import { ReactComponent as RemoveIcon } from "icon-library/SVG/Cross_Circle.svg";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import classNames from "classnames";
import ContextMenu from "lib/ui-components/context-menu/context-menu";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import SVGIcon from "common/components/svg-icon/svg-icon";

const ProviderListItem = (props: ProviderListItemProps) => {
    const {
        onNameClicked,
        onRemove,
        onSelect,
        provider,
        provider: { id, providerName, specialties, subSpecialties },
        selected,
        showSpecialties,
        selectDisabled,
        disabledMessage = "",
        idPrefix,
    } = props;

    const classes = useStyles();
    const intl = useIntl();

    const handleSelect = () => {
        if (onSelect) {
            onSelect(provider);
        }
    };

    const handleRemove = () => onRemove(id);

    const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            handleSelect();
        } else {
            handleRemove();
        }
    };

    const showRemoveButton = !onSelect;

    const selectLabel = intl.formatMessage(
        { id: "EConsult.newRequestModal.providers.providerItem.select" },
        { name: providerName }
    );
    const unselectLabel = intl.formatMessage(
        { id: "EConsult.newRequestModal.providers.providerItem.unselect" },
        { name: providerName }
    );
    const removeLabel = intl.formatMessage(
        { id: "EConsult.newRequestModal.providers.providerItem.remove" },
        { name: providerName }
    );

    const contextMenu: FunctionalMenuItem[] = [];
    if (!onSelect) {
        contextMenu.push({
            id: `${idPrefix}_item_remove_${id}`,
            onClick: handleRemove,
            icon: () => <SVGIcon svg={RemoveIcon} />,
            labelText: removeLabel,
        });
    }

    return (
        <tr
            key={`providerListItemOf${id}`}
            className={classNames(classes.providerItem, { hasRemoveButton: showRemoveButton, selected })}
        >
            {onSelect && (
                <td>
                    <Tooltip title={selectDisabled && disabledMessage !== "" && !selected ? disabledMessage : ""}>
                        {/* this extra span is necessary, because tooltip will not appear when the inner element is disabled */}
                        <span>
                            <Checkbox
                                id={`${idPrefix}_item_checkbox_${id}`}
                                aria-label={selected ? unselectLabel : selectLabel}
                                checked={selected}
                                onChange={handleChange}
                                disabled={selectDisabled && !selected}
                                color="primary"
                                classes={{ root: "providerItem__checkbox" }}
                                disableRipple
                                disableFocusRipple
                                icon={<SVGIcon svg={CheckboxUnchecked} size="scale800" />}
                                checkedIcon={<SVGIcon svg={CheckboxChecked} size="scale800" />}
                            />
                        </span>
                    </Tooltip>
                </td>
            )}
            <td>
                <div className="avatarWrapper">
                    <div className="avatar" id={`${idPrefix}_item_avatar_${id}`}>
                        <EmptyIcon />
                    </div>
                </div>
            </td>
            <td className="expand">
                <div
                    className={classNames("nameTitleWrapper", {
                        isClickable: !!onNameClicked,
                    })}
                    onClick={(event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
                        onNameClicked?.(id, event.target as any);
                    }}
                >
                    <CustomTypography
                        variant="modalSectionHeader"
                        id={`${idPrefix}_item_name_${id}`}
                        data-testid={`${idPrefix}_item_name_${id}`}
                        title={providerName}
                    >
                        {providerName}
                    </CustomTypography>
                </div>
            </td>
            <td>
                <div className="circleRemoveWrapper">
                    {/* TODO: waiting for the api to start returning information about circle of contacts */}
                    {false && (
                        <SVGIcon
                            id={`${idPrefix}_item_circle_of_contacts_icon_${id}`}
                            svg={CirclesIcon}
                            height="scale300"
                            trim
                        />
                    )}
                    <ContextMenu menuItems={contextMenu} />
                </div>
            </td>
            {showSpecialties && (
                <td>
                    <div className="specialtiesWrapper">
                        <CustomTypography
                            variant="smallBold"
                            component="span"
                            id={`${idPrefix}_item_specialties_${id}`}
                        >
                            {specialties?.join(", ")}
                            {specialties?.length && subSpecialties?.length && "; "}
                        </CustomTypography>
                        <CustomTypography
                            variant="small"
                            component="span"
                            id={`${idPrefix}_item_sub_specialties_${id}`}
                        >
                            {subSpecialties?.join(", ")}
                        </CustomTypography>
                    </div>
                </td>
            )}
        </tr>
    );
};

export default ProviderListItem;
