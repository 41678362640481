import React, { useCallback, useEffect, useState } from "react";
import { Pagination as MaterialPagination } from "@mui/material";
import { TotalCount } from "./total-count";
import { useStyles } from "./styles";

export type PaginationProps = {
    onChange: (page: number) => void;
    pageNumber: number;
    pageCount: number;
    showTotalCount?: boolean;
    totalCountKey?: string;
    /** If it is null or undefined, then totalCount is not displayed. */
    totalElements: number | null;
};

export const Pagination: React.FC<PaginationProps> = ({
    onChange,
    pageNumber,
    pageCount,
    showTotalCount,
    totalCountKey,
    totalElements,
}) => {
    const classes = useStyles();
    const [siblingCount, setSiblingCount] = useState(0);

    const resizePageCounter = useCallback(() => {
        pageNumber >= 2 && pageNumber <= pageCount - 3 ? setSiblingCount(1) : setSiblingCount(0);
    }, [pageNumber, pageCount]);

    useEffect(() => {
        resizePageCounter();
    }, [resizePageCounter]);

    const handleChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
        onChange(pageNumber - 1);
    };

    return (
        <div className={classes.Pagination}>
            {showTotalCount && (
                <TotalCount count={totalElements} messageKey={totalCountKey ?? "pagination.totalCount"} />
            )}
            {pageCount > 1 && <MaterialPagination
                count={pageCount}
                shape="rounded"
                hidePrevButton
                hideNextButton
                boundaryCount={1}
                siblingCount={siblingCount}
                page={pageNumber + 1}
                onChange={handleChange}
                classes={{ ul: classes.ul }}
            />}
        </div>
    );
};
