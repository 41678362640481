import { default as DatePick } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerProps } from "./types"
import { useStyles } from "./styles";
import { ReactComponent as CalendarDay } from "icon-library/SVG/Calendar_Day.svg";
import { createSvgIcon } from "utils";
import moment from "moment"
import React, { useMemo, useState } from "react"

function DatePicker({ selected, onChange, placeholderText = '', minDate = '', minTime = '', maxTime = '', allowTime = true }: DatePickerProps) {

    const classes = useStyles()

    const CalendarDayIcon = createSvgIcon(CalendarDay);
    const [calendarShow, setCalendar] = useState(false)

    const calculateMinTime = (date: any) => {
        const isToday = moment(date).isSame(moment(), "day");
        if (isToday) {
            const nowAddOneHour = moment(new Date()).toDate();
            return nowAddOneHour;
        }
        return moment().startOf('day').toDate(); // set to 12:00 am today
    }

    const memoMinTime = useMemo(() => calculateMinTime(selected), [selected]);

    function showCal() {
        setCalendar(calendarShow => !calendarShow)
    }

    return (
        <div className={classes.datePickerWrapper}>
            {allowTime ?
                <DatePick
                    selected={selected}
                    showPopperArrow={true}
                    onChange={onChange}
                    placeholderText={placeholderText}
                    showTimeSelect
                    popperPlacement="top-end"
                    timeFormat="hh:mm aa"
                    timeIntervals={5}
                    minDate={minDate}
                    minTime={minTime || memoMinTime}
                    maxTime={maxTime || moment().endOf('day').toDate()}
                    dateFormat="MM/dd/yyyy, h:mm aa"
                    onClickOutside={() => setCalendar(false)}
                    open={calendarShow}
                    onFocus={() => setCalendar(true)}
                /> :
                <DatePick
                    selected={selected}
                    showPopperArrow={true}
                    onKeyDown={() => {
                        setCalendar(true);
                    }}
                    onChange={(date) => {
                        if (date) {
                            setCalendar(false);
                            onChange(date);
                        }
                    }}
                    placeholderText={placeholderText}
                    popperPlacement="top-end"
                    minDate={minDate}
                    dateFormat="MM/dd/yyyy"
                    onClickOutside={() => setCalendar(false)}
                    open={calendarShow}
                    onFocus={() => setCalendar(true)}
                />
            }
            <CalendarDayIcon onClick={showCal} className={classes.icon} />
        </div>
    )
}

export default DatePicker