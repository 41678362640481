import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    label: {
        fontWeight: 600
    },
    history: {
        fontSize: '0.8em',
        fontWeight: 'bold'
    },
    versionName: {
        fontSize: '0.7em'
    },
    row1:{
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row2: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#ededef',
        borderRadius: '15px 15px 0px 0px',
        height: '80px'
    },
    tittle:{
        width: '50%',
        height: '25%',
        paddingTop: '25px',
        paddingLeft: '25px',
        display: 'flex',
        flexDirection: 'column',
    },
    modal: {
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        height: '100%',
        backkgroundColor: '#c5c6c8 !important',
    },
    modalContent: {
        backgroundColor: '#f6f8fa',
        position: 'absolute',
        top: '10%',
        left: '5%',
        width: '70%',
        borderRadius: '15px',
    },
    closeBack: {
        width: '50px',
        height: '50px',
        padding: '25px 25px 0px 0px'
    },
    close: {
        backgroundColor: '#5b5b68',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20px',
        width: '20px',
        color: 'white',
        float: 'right',
        '&:hover': {
            color: 'gray',
            cursor: 'pointer'
        }
    },
    tableBack: {
        padding: '20px'
    },
    table: {
        minWidth: 300,
    },
    last: {
        backgroundColor: '#ededef'
    },
    version: {
        color: '#117bbd',
        cursor: 'pointer',
        textAlign: 'right'
    },
    bold: {
        fontWeight: 900,
        fontSize: '16px'
        // textShadow: '0px 1px, 0px 0px, 0px 0px'
    },
    timeSize: {
        fontSize: 'smaller',
    }
}));