import { ForwardedRef, useCallback } from "react";

/**
 * Combine multiple React refs into one, useful for when both forwarding ref and using a internal ref
 */
export const useCombinedRefs = <T>(refA?: ForwardedRef<T>, refB?: ForwardedRef<T> ) => {
    return useCallback((instance: T | null) => {
        [refA, refB].forEach(ref => {
            if (typeof ref === "function") {
                ref(instance);
            } else if (ref && typeof ref === "object" && ref.hasOwnProperty("current")) {
                ref.current = instance;
            }
        })
    }, [refA, refB]);
}
