import React from "react";
import { Popover } from "@mui/material";

import { useStyles } from "./styles";
import { Props } from "./types";


export default function SimplePopover(props: Props) {
  const { Button, Content, arrowPosition = "center",arrow=true, anchorOrigin='',transformOrigin='',...other } = props;
  const buttonRef = React.useRef(null);
 
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const handleClick = () => {
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles(arrow);
  return (
    <>
    <span ref={buttonRef}><Button aria-describedby={id} openPop={open}  onClick={handleClick} close={handleClose}/></span>
      <Popover
        classes={{ paper: classes.paper }}
        id={id}
        BackdropProps={{ className:classes.backdrop }}
        open={open}
        //@ts-ignore
        anchorEl={()=>buttonRef.current}
        onClose={handleClose}
        anchorOrigin={anchorOrigin || {
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={transformOrigin||{
          vertical: -10,
          horizontal: "center",
        }}
        {...other}
      >
        <Content close={handleClose}/>
        <span className={["arrow", arrowPosition].join(" ")}></span>
      </Popover>
    </>
  );
}
