import React from "react";
import { TableCell, Input } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as WarningIcon } from "admin/assets/common/ExclamationWarning.svg";

export default function EditableTableCell(props: any) {
    const useStyles = makeStyles(() => ({
        labelCtrl: {
            fontWeight: 600,
            color: '#393948',
            fontSize: '14px',
            textAlign: 'left'
        },
        error: {
            color: '#E32B29',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '5px'
        },
        exclamationIcon: {
            marginTop: '3px'
        },
        tableCell: {
          width: '75%'
        },
        input: {
          width: '75%'
        }
    }));

    const classes = useStyles();
    const { row, name, onChange, placeholder } = props;
    return (
        <TableCell align="left" style={{ paddingLeft: '48px' }}>
            <>
                <span>
                    <Input
                        disableUnderline={true}
                        value={row[name]}
                        name={name}
                        onChange={e => onChange(e, row)}
                        className={classes.input}
                        placeholder={placeholder}
                    />
                </span>
                {row[name]?.trim() === "" && <span className="error" title="Required"><WarningIcon className={classes.exclamationIcon} /></span>}
            </>
        </TableCell>
    );
}