import React from "react";
import {Typography} from "@mui/material";
import {TYPOGRAPHY} from "theme";

// CustomTypography is used to change the font styles of a piece of text. Can be used just like material ui Typography
// but allows for any number of variants to accommodate all font styles from design. Font color can also be passed in as
// a prop to reduce need to classes and the use of the style prop. Different font styles should be stored in the
// TYPOGRAPHY object in the theme file where the key is the 'variant' and the value is the font styles.
const CustomTypography = ({children, variant, color, style = {}, ...props}: any) => {
    // @ts-ignore
    const fontStyle = TYPOGRAPHY[variant]
    return (
        <Typography style={{...fontStyle, color: color ? color : fontStyle?.color ?? null, ...style}} {...props}>
            {children}
        </Typography>
    )
}

export default CustomTypography