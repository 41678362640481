import React, { Fragment, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Box, Button, Grid, IconButton, InputBase, Paper,
    Table, TableBody, TableCell, TableContainer,
    TableFooter, TableHead,
    TablePagination, TableRow, TableSortLabel, Typography, Collapse, Link
} from "@mui/material";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import OrgProductsDropdown from 'admin/components/OrgProductsDropdown/OrgProductsDropdown';
import { getWebAdminCacheOrganization, getWebAdminCachePractice, setActiveModal, setCurrentOrganizationById, setCurrentPracticeById, setNavBreadcrumbText, setSelectedPracticeId } from "admin/store/actions";
import { getProductsForOrganization, getProductsForPractice } from "admin/store/actions/license";
import { AppReduxStore } from "admin/store/reducerTypes";
import ProductsView from 'admin/components/ProductsView/ProductsView';
import PracticeProductsView from 'admin/components/PracticeProductsView/PracticeProductsView'
import { useStyles } from "./styles";
import GridContextMenu from "admin/components/GridContextMenu/GridContextMenu";
import OrganizationGridContextMenu from 'admin/components/GridContextMenuContent/OrganizationGridContextMenu/OrganizationGridContextMenu';
import { ADMIN_WEB_CACHE, MODAL_TYPES } from "admin/constant";
import { showNotification } from "admin/store/actions/snackbar";
import { ReactComponent as ChevronDown } from "icon-library/SVG/Chevron_Down.svg";
import { createSvgIcon } from "../../../../utils";
import { ReactComponent as Buildings } from "icon-library/SVG/Buildings.svg";
import { ReactComponent as BuildingNarrow } from "icon-library/SVG/Building_Narrow.svg";
import { getChildOrganizationSummaries, setSelectedOrganization } from "admin/store/actions/organization";
import AddOrg from "admin/views/Organizations/AddOrg/AddOrg";
import { ReactComponent as ChevronRight } from "icon-library/SVG/Chevron_Right.svg";
import TablePaginationActions from "admin/components/TablePaginationActions/TablePaginationActions";
import ProductsFilterMenu from "admin/components/ProductsFilterMenu/ProductsFilterMenu";
import RichTooltip from "admin/components/RichToolTip/RichTooltip";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross.svg";
import { canCreateOrgsAndSubOrgs } from "admin/Accessibility";
import { LicenseWidgets } from "admin/components/LicenseWidgets/LicenseWidgets";
import { ReactComponent as EllipsisV } from "icon-library/SVG/Ellipsis-Vertical.svg";
const EllipsisVIcon = createSvgIcon(EllipsisV);
const ChevronDownIcon = createSvgIcon(ChevronDown);
const BuildingsIcon = createSvgIcon(Buildings);
const BuildingNarrowIcon = createSvgIcon(BuildingNarrow);
const ChevronRightIcon = createSvgIcon(ChevronRight);
const CrossIcon = createSvgIcon(CrossSvg);
interface Data {
    organizationAddress: string
    organizationId: string
    organizationName: string
    parentOrgAddress: string
    parentOrgId: string
    parentOrgName: string
    practiceId: string
    products: any
}

const OrganizationListing = () => {
    type Order = 'asc' | 'desc';
    const history = useHistory();
    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { auth, organization, license } = useSelector((state: AppReduxStore) => state);
    const { orgLicenses } = license
    const { childOrganizationSummaries } = organization;
    const [orderBy, setOrderBy] = useState<keyof Data>('organizationName');
    const [order, setOrder] = useState<Order>('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isAddOrgWizardOpen, setIsAddOrgWizardOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [orgToggleState, setOrgToggleState] = useState({});
    const [entitiesTreeList, setEntitiesTreeList] = useState([] as any);
    const [filterProductsOptions, setFilterProductsOptions] = useState({ haveAnyProducts: true, productIds: [] });
    const [contextMenuData, setContextMenuData] = useState({} as any);
    const searchInput = useRef<any>();
    const [toolTipOpen, setToolTipOpen] = useState(false);

    useEffect(() => {
        if (auth.currentOrg?.id && auth.currentProfileRoleJoinInfo) {
            dispatch(getChildOrganizationSummaries(auth.currentOrg?.id));
            dispatch(getProductsForOrganization(auth.currentOrg?.id))
            dispatch(getWebAdminCacheOrganization(ADMIN_WEB_CACHE.ADD_ORGANIZATION_WIZARD_CACHE))
            dispatch(getWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE))
        }

    }, [auth.currentOrg, auth.currentProfileRoleJoinInfo, dispatch, intl]);

    if ((orgToggleState as any)["parentOrgs"] === undefined) {
        setOrgToggleState({ ...orgToggleState, parentOrgs: true });
    }

    useEffect(() => {
        if (searchString || (filterProductsOptions && filterProductsOptions.productIds.length > 0)) {
            setEntitiesTreeList([{ children: searchTree([...organization.entitiesTreeList], searchString), level: 0 }])
        } else {
            setOrgToggleState({ parentOrgs: true });
            setEntitiesTreeList([...organization.entitiesTreeList])
        }
    }, [searchString, filterProductsOptions, organization.entitiesTreeList]);

    useEffect(() => {
        if (entitiesTreeList && entitiesTreeList.length > 0 && entitiesTreeList[0].children?.length === 0) {
            setToolTipOpen(true)
        } else setToolTipOpen(false)
    }, [childOrganizationSummaries, entitiesTreeList])

    const closeAddOrgModal = () => {
        setIsAddOrgWizardOpen(false);
    };

    const handleAddBtnClick = (event: any) => {
        setToolTipOpen(false)
        if (auth.currentOrg?.parent) {
            dispatch(setActiveModal(MODAL_TYPES.ADD_ENTITY))

        } else {
            setIsAddOrgWizardOpen(true)
        }

    };
    const handleProductsAction = (row: any) => {
        if (row.organizationId) {
            dispatch(setSelectedOrganization(row.organizationId));
            dispatch(getProductsForOrganization(row.organizationId, true))
        }
        if (row.practiceId) {
            dispatch(setSelectedPracticeId(row.practiceId, row.organizationName))
            dispatch(getProductsForPractice(row.practiceId))
        }
    };

    const handleOrganizationClick = (event: any, orgName: string, orgId?: string, practiceId?: string) => {
        dispatch(setNavBreadcrumbText(orgName as string));
        if (orgId) {
            dispatch(setCurrentOrganizationById(orgId, history));
            dispatch(getChildOrganizationSummaries(orgId as string));
        }
        if (practiceId) {
            dispatch(setCurrentPracticeById(practiceId, history));
        }
    };
    const handleProductsCellClick = (orgId?: string, practiceId?: string) => {
        if (practiceId) {
            dispatch(getProductsForPractice(practiceId as string, true))
        } else {
            dispatch(getProductsForOrganization(orgId as string, true))
        }
    };
    const handleSearch = () => {
        setSearchString(searchInput.current?.lastChild?.value)
        setPage(0);
    };

    const searchTree = (tree: any, value: string, key = 'organizationName') => {
        const treeFiltered = [] as any;
        const orgCollapseState = {};
        const stack = [tree[0]]
        while (stack.length) {
            const node = stack['pop']() //shift
            node.highlight = false
            let productsFilterFlag = value.toLowerCase().length > 0 && node[key]?.toLowerCase().includes(value.toLowerCase())
            const productsIds = node?.products?.map((p: any) => p.id);
            if (filterProductsOptions?.productIds?.length > 0) {
                if (filterProductsOptions.haveAnyProducts) {
                    productsFilterFlag = filterProductsOptions?.productIds?.every((p: any) => productsIds?.includes(p))
                } else {
                    productsFilterFlag = filterProductsOptions?.productIds?.every((p: any) => !productsIds?.includes(p))
                }
            }

            if ((value.toLowerCase().length > 0 && node[key]?.toLowerCase().includes(value.toLowerCase())) || productsFilterFlag) {
                node.highlight = true
                let childNode = null as any
                const stackAncestorNode = [{ ...node }]

                while (stackAncestorNode.length > 0) {
                    childNode = stackAncestorNode.pop()

                    const nodeParent = findOrgInTreeData(tree, childNode.parentOrgId)
                    if (nodeParent !== null && childNode.parentOrgId !== tree[0].organizationId) {
                        (orgCollapseState as any)[childNode.parentOrgId] = true
                        stackAncestorNode.push({ ...nodeParent });
                    }
                    else {
                        if (childNode.children?.length > 0) {
                            (orgCollapseState as any)[childNode.parentOrgId] = true
                        }
                        break;
                    }
                }
                if (!treeFiltered.find((t: any) => t.organizationId === childNode?.organizationId))
                    treeFiltered.push(childNode)
            }
            node.children && stack.push(...node.children)
        }
        if (Object.keys(orgCollapseState).length > 0) setOrgToggleState(orgCollapseState)
        return treeFiltered
    }
    const findOrgInTreeData = (tree: any, orgId: string) => {
        if (!orgId) return null
        const stack = [];
        stack.push(tree[0]);
        while (stack.length > 0) {
            const node: any = stack.pop();
            if (node.organizationId === orgId) {
                return { ...node };
            } else if (node.children && node.children.length) {
                for (let ii = 0; ii < node.children.length; ii += 1) {
                    stack.push(node.children[ii]);
                }
            }
        }
        return null;
    }

    const openEditPraticeModal = () => {
        dispatch(setActiveModal(MODAL_TYPES.EDIT_PRACTICE))
    }
    const handleRowAction = (type: any, row: any) => {
        switch (type) {
            case "Deactivate":
                if (row.organizationId) {
                    dispatch(setActiveModal(MODAL_TYPES.OFFBOARD_ORGANIZATION_CONFIRMATION_MODAL, { orgId: row.organizationId, orgName: row.organizationName }))
                }
                else if (row.practiceId) {
                    //Offboard Practice
                }
                break;
            case "ViewDetails":
                dispatch(setNavBreadcrumbText(row.organizationName))
                if (row.organizationId) {
                    dispatch(getProductsForOrganization(row.organizationId, true))
                    dispatch(setSelectedOrganization(row.organizationId));
                    history.push('/admin/entities/details');
                }
                if (row.practiceId) {
                    dispatch(getProductsForPractice(row.practiceId as string))
                    dispatch(setSelectedPracticeId(row.practiceId as string, row.organizationName as string))
                    history.push('/admin/entities/practice-details');
                }
                break;
            case 'EditInfo':
                if (row.organizationId) {
                    dispatch(setSelectedOrganization(row.organizationId));
                    dispatch(setActiveModal(MODAL_TYPES.EDIT_ORGANIZATION));
                }
                else if (row.practiceId) {
                    dispatch(setSelectedPracticeId(row.practiceId, row.organizationName, openEditPraticeModal))
                }
                break;
            case 'EditProducts':
                if (!checkParentHasProducts(row.parentOrgId)) {
                    dispatch(showNotification("Edit Products", "error", intl.formatMessage(
                        {
                            id: row.practiceId ? "OrganizationListing.Action.EditProducts.PrcaticeNoParentProducts" :
                                "OrganizationListing.Action.EditProducts.OrgNoParentProducts"
                        },
                        { name: row.parentOrgName })
                    ))
                    return
                }
                if (row.organizationId) {
                    dispatch(setSelectedOrganization(row.organizationId));
                    dispatch(getProductsForOrganization(row.organizationId, true))
                    dispatch(setActiveModal(MODAL_TYPES.EDIT_ORG_PRODUCTS));
                }
                if (row.practiceId) {
                    dispatch(setSelectedPracticeId(row.practiceId, row.organizationName))
                    dispatch(getProductsForPractice(row.practiceId, true))
                    dispatch(setActiveModal(MODAL_TYPES.EDIT_PRACTICE_PRODUCTS));
                }

                break;
            default:
                dispatch(showNotification("TODO", "error", "Not Implemented"))
        }
    };
    const checkParentHasProducts = (parentId: string | number) => {
        if (parentId) {
            const parentOrg = childOrganizationSummaries && childOrganizationSummaries.find((org: Data) => org.organizationId === parentId)
            if (parentOrg.parentOrgId) {
                return parentOrg?.products?.length > 0
            } else {
                return true
            }
        }
    }
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        const fa = typeof a[orderBy] === "string" ? (a[orderBy] as any).toLowerCase() : a[orderBy],
            fb = typeof b[orderBy] === "string" ? (b[orderBy] as any).toLowerCase() : b[orderBy];
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    };

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const handleClick = (children: any, item: any) => {
        const collapseValue = !(orgToggleState as any)[item];

        if (children.length > 0) {
            children.forEach((child: any) => {
                collapseChildren(orgToggleState, child, collapseValue)
            });
        }
        setOrgToggleState({ ...orgToggleState, [item]: collapseValue })
    }
    const collapseChildren = (collapseState: any, child: any, collapaseValue: boolean) => {
        collapseState[child.organizationId ?? child.practiceId] = collapaseValue
        if (child.children.length > 0) {
            child.children.forEach((child2: any) => {
                collapseChildren(collapseState, child2, collapaseValue)
            });
        }
    }
    const handleEditProducts = (e: any) => {
        if (orgLicenses && orgLicenses?.length > 0) {
            dispatch(setSelectedOrganization(auth.currentOrg?.id));
            dispatch(getProductsForOrganization(auth.currentOrg?.id, true))
            dispatch(setActiveModal(MODAL_TYPES.EDIT_ORG_PRODUCTS))
        }
    }
    const handleProductsFilterAction = (e?: { haveAnyProducts: boolean, productIds: any }) => {
        e && setFilterProductsOptions(e)
    }
    const handleEllipsesActionClick = (event: any, row: any, index: number) => {
        event.preventDefault();
        setContextMenuData({ mouseX: event.clientX - 194, mouseY: event.clientY - 25, row, index })

    };
    const handleRowContextMenuClose = (event: any) => {
        setContextMenuData({ mouseX: 0, mouseY: 0, row: null, index: null })
    };
    const RowContextMenu = () => {
        const row = contextMenuData?.row
        if (!row) return
        return (<GridContextMenu mouseX={contextMenuData?.mouseX} mouseY={contextMenuData?.mouseY} onClose={handleRowContextMenuClose}
            callback={(type: any) => { handleRowAction(type, row) }}
            formNumber={""} formTitle={""} handleFormRow={null}
            gridContextMenuContent={OrganizationGridContextMenu}
            index={contextMenuData?.index} />)
    }
    const Pagination = () => {
        return (<>
            {entitiesTreeList && entitiesTreeList.length > 0 && <TablePagination className={classes.tableFooterRow}
                rowsPerPageOptions={[25, 50, 100]}
                classes={{ selectIcon: classes.footerIcon, select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
                count={entitiesTreeList[0].children?.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Per Page"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            }
        </>)
    }
    const borders = {
        Active: '2px solid #00A875',
        Pending: '2px solid #F5A800',
        Inactive: '2px solid #909098',
        Suspend: '2px solid #E82C2A'
    }
    const renderEntitiesRow = (entityTree: any, collapseId: string) => {
        return entityTree.children && (collapseId === "parentOrgs" ?
            stableSort(entityTree.children, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : entityTree.children).map((row: any, index: number) => {
                let border = '';
                switch (row.organizationStatus) {
                    case "ACTIVE": border = borders['Active']; break;
                    case "PENDING": border = borders['Pending']; break;
                    case "SUSPEND": border = borders['Suspend']; break;
                    default: border = borders['Inactive']; break;
                }
                return (
                    <Fragment key={index}>
                        <TableRow hover key={row.organizationId ?? row.practiceId} style={{ display: ((orgToggleState as any)[collapseId]) ? "table-row" : "none" }} className={row.highlight && searchString ? classes.highlight : ''}>
                            <TableCell component="td" scope="row" padding="normal">
                                <Collapse key={collapseId} in={(orgToggleState as any)[collapseId]} timeout="auto" unmountOnExit>
                                    <Grid container alignItems="center" style={{ marginLeft: collapseId === "parentOrgs" ? (row.children?.length > 0 ? '0px' : '40px') : 50 * row.level }}>
                                        <Grid style={{ display: "flex", alignItems: "center", flexBasis: 'none', paddingRight: '5px' }}>
                                            {row.children?.length > 0 ? (
                                                <IconButton
                                                    key={row.organizationId ?? row.practiceId}
                                                    onClick={() => handleClick(row.children, row.organizationId ?? row.practiceId)}
                                                    size="large"
                                                >
                                                    {(orgToggleState as any)[row.organizationId ?? row.practiceId] ? <ChevronDownIcon className={classes.tableChevron} /> : <ChevronRightIcon className={classes.tableChevron} />}
                                                </IconButton>
                                            ) : null}
                                            {row.organizationId ? <BuildingsIcon /> : <BuildingNarrowIcon />}
                                        </Grid>
                                        <Grid item xs>
                                            <Link style={{ cursor: 'pointer' }} onClick={(e) => handleOrganizationClick(e, row.organizationName as string, row.organizationId as string, row.practiceId as string)}>
                                                <Typography noWrap variant="body1" style={{ color: '#0F7BBD', fontSize: '14px' }}>{row.organizationName}</Typography>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography noWrap variant="body1" style={{ color: '#393948', fontSize: '13px', fontWeight: 'normal' }}> {`${row.organizationAddress ?? ""}`}</Typography>
                                                </Box>
                                            </Link>
                                        </Grid>
                                    </Grid>

                                </Collapse>
                            </TableCell>
                            <TableCell align="center" width="150px">
                                <Box className={classes.statusActiveBox} style={{ border: border, padding: '5px', borderRadius: '7px', textAlign: 'center', width: '100px' }}>
                                    {row.organizationStatus === "ACTIVE" ? "Active" :
                                        row.organizationStatus === "INACTIVE" ? "Inactive" :
                                            row.organizationStatus === "SUSPEND" ? "Suspended" :
                                                "None"
                                    }
                                </Box>
                            </TableCell>
                            <TableCell align="center" width="150px">
                                <Collapse key={collapseId} in={(orgToggleState as any)[collapseId]} timeout="auto" unmountOnExit>
                                    <Box onClick={(e) => handleProductsCellClick(row.organizationId as string, row.practiceId as string)}>
                                        {row.products ? <OrgProductsDropdown heading={`${(row.products as any).length} Products`} callback={(type: any) => { handleProductsAction(row) }} orgProductsDropdownContent={row.organizationId ? ProductsView : PracticeProductsView} /> : `${(row.products as any).length} Products`}
                                    </Box>
                                </Collapse>
                            </TableCell>
                            <TableCell align="right" width="120px" >
                                <Collapse key={collapseId} in={(orgToggleState as any)[collapseId]} timeout="auto" unmountOnExit>
                                    <Box>
                                        <Typography noWrap variant="body1" align="right" style={{ color: '0F7BBD', fontSize: '14px' }}>
                                            {`${(row.registeredUserCount)} / ${(row.totalUserCount)}`}
                                        </Typography>
                                    </Box>
                                </Collapse>
                            </TableCell>
                            <TableCell align="right" width="50px" className={classes.stickyLastColumn}>
                                <Collapse key={collapseId} in={(orgToggleState as any)[collapseId]} timeout="auto" unmountOnExit>
                                    <IconButton id={"btnRowActionContextMenu" + index} style={{ padding: '10px' }} onClick={(e) => handleEllipsesActionClick(e, row, index)} size="large">
                                        <EllipsisVIcon />
                                    </IconButton>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                        {renderEntitiesRow(row, row.organizationId ?? row.practiceId)}
                    </Fragment>
                )
            });
    }

    const handleTooltipClose = () => {
        setToolTipOpen(false);
    };
    const canCreateOrgAndPractice = canCreateOrgsAndSubOrgs()
    return (
        <Box>
            {/* TODO Removed "isPendingOrgAdd={false}" from AddOrg here because this prop does not exist on the type AddOrgProps and there is no usage of "isPendingOrgAdd" in AddOrg */}
            {isAddOrgWizardOpen &&
                <AddOrg isAddOrgWizardOpen={isAddOrgWizardOpen} closeAddOrgModal={closeAddOrgModal} />
            }
            {orgLicenses && orgLicenses?.length > 0 && auth.currentOrg.parent && <Grid container>
                <Grid item xs>
                    <Button id="btnEditProducts" variant="contained" color="primary" style={{ float: 'right', marginBottom: '10px' }} onClick={handleEditProducts}>
                        {intl.formatMessage({ id: "OrganizationDetail.Button.EditProducts" })}
                    </Button>
                </Grid>
            </Grid>}
            {orgLicenses && <LicenseWidgets products={orgLicenses} />}
            <Grid container justifyContent="space-between">
                <Grid item xs>
                    <Paper component="form" className={classes.root}>
                        <IconButton id="btnSearchOrganizations" className={classes.iconButton} aria-label="search" onClick={handleSearch} size="large">
                            <SearchIcon />
                        </IconButton>
                        <InputBase type="search" id="txtSearchOrganizations" ref={searchInput}
                            className={classes.input} onChange={(ev: any) => { if (ev.target.value === '') handleSearch() }}
                            onKeyPress={(ev: any) => { if (ev.key === 'Enter') { ev.preventDefault(); handleSearch() } }}
                            placeholder="Search to filter the table below"
                            inputProps={{ 'aria-label': 'Search to filter the table below' }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs>
                    <RichTooltip content={
                        <Box textAlign="right">
                            <Box>
                                <CrossIcon fill={"#FFFFFF"} className={classes.infoIcon} onClick={handleTooltipClose} />
                            </Box>
                            <Typography className={classes.infoTooltipText}>
                                <b>Add to your Entities</b> <br /><br />Start by adding a Suborganization or Practice.
                            </Typography>
                            <Button onClick={handleAddBtnClick} className={classes.addBtn} variant="outlined" color="primary"  >
                                Add
                            </Button>
                        </Box>
                    }

                        open={toolTipOpen && canCreateOrgAndPractice}
                        onClose={() => setToolTipOpen(false)}
                        placement="left"><>
                            {canCreateOrgAndPractice && <Button
                                id="btnAddOrganization"
                                variant="outlined"
                                color="primary"
                                style={{ float: 'right' }}
                                onClick={handleAddBtnClick}
                            >
                                Add
                            </Button>}
                        </>
                    </RichTooltip>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <TableContainer className={classes.stickyHeader}>
                    <Table id="tableOrganizations"
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ paddingLeft: '48px' }}
                                    key={"organizationName"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "organizationName" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "organizationName"}
                                        direction={orderBy === "organizationName" ? order : 'asc'}
                                        onClick={createSortHandler("organizationName")}>
                                        {'Name'}
                                        {orderBy === "organizationName" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ paddingRight: '70px' }}
                                    align={'center'}
                                    padding="normal"
                                    width="150px">
                                    {'Status'}
                                </TableCell>
                                <TableCell
                                    width="150px"
                                    key={"products"}
                                    align={'center'}
                                    padding="normal">
                                    {'Products'}
                                    <ProductsFilterMenu callback={handleProductsFilterAction} />
                                </TableCell>
                                <TableCell colSpan={2}
                                    align={'right'}
                                    padding="normal">
                                    {'Registered/Total Users'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{entitiesTreeList && entitiesTreeList.length > 0 &&
                            renderEntitiesRow(entitiesTreeList[0], "parentOrgs")}
                            {contextMenuData?.row && RowContextMenu()}
                        </TableBody>
                        <TableFooter>
                            {
                                (entitiesTreeList && entitiesTreeList.length > 0 && entitiesTreeList[0].children?.length === 0) ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            {intl.formatMessage({ id: "OrganizationListing.Action.NoRecords" })}
                                        </TableCell>
                                    </TableRow>
                                ) : <TableRow>
                                    <Pagination />
                                </TableRow>
                            }
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}

export default OrganizationListing;