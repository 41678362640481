import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => {
    return {
        rootWrapper: {
            display: 'flex',
            padding: SIZING.scale400,
            "& p": {
                color: 'rgba(110, 110, 121, 1)'
            },
            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
            "&:nth-of-type(1)": {
                borderTop: 'none'
            },
            "&.active": {
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
                borderRadius: SIZING.scale200,
                background: COLORS.LBLUE,
                "& p": {
                    color: COLORS.WHITE,
                    fontWeight: 900
                },
                "& svg >g": {
                    fill: COLORS.WHITE
                },
                "& svg >g, & svg > path ": {
                    fill: COLORS.WHITE
                },
                "& ~ div:nth-of-type(1)": {
                    borderTop: 'none'
                }
            }
        },
        userIcon: {
            marginRight: SIZING.scale300,
            "&> g": {
                fill: 'rgba(110, 110, 121, 1)'
            }
        },
        patientName: {
            fontSize: SIZING.scale400,
            fontWeight: 900,
            marginBottom: SIZING.scale125
        },
        patientId: {
            fontSize: SIZING.scale350,
            fontWeight: 400,
        },
        ageAndGender: {
            fontSize: SIZING.scale350,
            marginBottom: SIZING.scale125,
            fontWeight: 400,
        },
        alertIconWrapper: {
            display: 'flex',
            "&> svg:nth-of-type(1)": {
                marginLeft: 'auto'
            }
        },
        alertIcon: {
            "& g, & path": {
                fill: `${COLORS.ALERTS_ICON} !important`,
            }
        }
    }
})
