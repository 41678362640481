import React from "react";
import { Form } from "admin/components/useForm";
import { Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import FormField from "common/components/FormField/FormField";
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { setRegistrationValues, verifyAdminIdentityPostLogin, verifyIdentity } from "admin/store/actions";
import PhoneMask from "common/components/PhoneMask";


// VerifyIdentity is used to verify the identity of a new user or user adding a new profile. Providers are identified by
// first and last names and confirmed using a phone number and an otp code
const VerifyIdentity = () => {
    const { firstName, lastName, phoneNumber, loading, profileRoleJoinId } = useSelector((store: AppReduxStore) => store.registration)
    const dispatch = useDispatch()

    // onChange returns a function to set a value by the given jey
    const onChange = (key: string) => {
        return (value: any) => {
            dispatch(setRegistrationValues({ [key]: value }))
        }
    }

    const onSubmit = async (e: any) => {
        e.preventDefault()
        await profileRoleJoinId?.length > 0 ? dispatch(verifyAdminIdentityPostLogin()) : dispatch(verifyIdentity())
    }

    // Validate all fields with trimming
    const firstNameEmpty = !firstName || firstName.trim() === "";
    const lastNameEmpty = !lastName || lastName.trim() === "";
    const phoneEmpty = !phoneNumber || phoneNumber.trim() === "" || phoneNumber.trim() === "(___)___-____";
    const phoneInvalid = phoneNumber.replace(/[()_-]/g, "").length < 10;
    const isSubmitDisabled = loading || firstNameEmpty || lastNameEmpty || phoneEmpty || phoneInvalid;

    return (
        <Form onSubmit={onSubmit}>
            <FormField labelId="Registration.label.firstName" value={firstName} onChange={onChange("firstName")} />
            <FormField labelId="Registration.label.lastName" value={lastName} onChange={onChange("lastName")} />
            <FormField labelId="Registration.label.phone" value={phoneNumber} onChange={onChange("phoneNumber")}
                inputComponent={PhoneMask} />
            <Button type="submit" disabled={isSubmitDisabled} variant="contained" color="primary" fullWidth>
                <Typography variant="button">
                    <FormattedMessage id="Registration.button.confirm" />
                </Typography>
            </Button>
        </Form>
    )
}

export default VerifyIdentity
