import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, SIZING,COLORS} from "theme";

export const useStyles = makeStyles(() => ({
        wrapper: {
            flexGrow: 1,
            padding: SIZING.scale600,
            height: evisitBar=>`calc(100% - ${(SIZING.PATIENT_SUMMARY_HEADER_HEIGHT + (evisitBar ?SIZING.PATIENT_SUMMARY_EVISIT_BAR_HEIGHT :0))}px)`,
            overflow: "auto"
        },
        contentWrapper:{
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
            padding: `${SIZING.scale400}`,
            border:'0.5px solid rgba(86, 86, 98, 0.2)',
            display: 'flex',
            borderRadius: BORDER_RADIUS.MEDIUM,
            marginBottom: SIZING.scale600,
        },
        details:{
            marginLeft: SIZING.scale400,
            display:'flex',
            "& .expired":{
                opacity:'.5',
            },
            "& .title":{
                marginBottom:SIZING.scale400
            },
            "& .provider-details":{
                fontSize: SIZING.scale300,
                fontWeight:'unset'
            },
            "& .startTime":{
                marginLeft:'auto',
                color:COLORS.NOTIFICATION_TEXT,
                textTransform: 'uppercase',
                fontWeight:'normal'
            },
            flex:1,
            "& .buttonBar": {
                marginLeft: 'auto',
            },
            "& .view": {
                background: COLORS.LGREEN,
            },
            "& .MuiButton-sizeSmall": {
                height: '1.9rem',
                padding: '0 1rem',
                fontWeight: 'normal',
                fontSize: '1rem',
            }
        },
        empty: {
            height: evisitBar=>`calc(100% - ${(SIZING.PATIENT_SUMMARY_HEADER_HEIGHT + (evisitBar ?SIZING.PATIENT_SUMMARY_EVISIT_BAR_HEIGHT :0))}px)`,
        }
}));