import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useRef, useState } from 'react';
import { Route, RouteProps, useHistory, useRouteMatch } from 'react-router-dom';
import Spinner from "../common/components/Spinner/Spinner";
import { loginRequest } from "./azure/authConfig";

export const SecureRoute: React.FC<{
  errorComponent?: React.ComponentType<{ error: Error }>;
} & RouteProps> = ({ errorComponent, ...routeProps }) => {
  const match = useRouteMatch();
  const isPending = useRef(false);
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const { instance } = useMsal();

  useEffect(() => {
    const login = async () => {
      if (isPending.current) {
        return;
      }

      isPending.current = true;

      await instance.loginRedirect(loginRequest);
      history.push('/login');
    };

    // Only process logic if the route matches
    if (!match) {
      return;
    }

    if (!instance) {
      return;
    }

    if (isAuthenticated) {
      isPending.current = false;
      return;
    }

    if (!isAuthenticated) {
      login().catch(err => {
        setError(err);
      });
    }
  }, [instance, match, isPending, setError]);

  if (error && errorComponent) {
    const ErrorComponent = errorComponent;
    return <ErrorComponent error={error} />
  }

  if (!instance || !isAuthenticated) {
    return <Spinner />;
  }

  return (
    <Route
      {...routeProps}
    />
  );
};

export default SecureRoute;