import {Dialog} from "@mui/material";
import {ModalProps} from "./types";
import {useStyles} from "./styles";

// Modal renders a dialog box with some content passed in. The modal can also be draggable to allow the user to move the
// modal by clicking and dragging it across the screen
const TemModal = ({id, open, onClose, maxWidth, classes, modalWidth, children} : ModalProps) => {
    const styles = useStyles({modalWidth});

    const onHandleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick"){ 
            return;
        }
        if(onClose){
            onClose();
        }
    }

    return (
        <Dialog maxWidth={maxWidth} classes={{...classes, paper: classes?.paper ? classes.paper : styles.modal}} open={open} onClose={onHandleClose}>
            {children}
        </Dialog>
    )
}

export default TemModal;
