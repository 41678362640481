
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

export const setSideMenuOpen = (open: boolean) => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_OPEN_NAV', payload: { open } })
	}
}
export const setNavBreadcrumbText = (breadcrumbText: string) => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_BREADCRUMB_TEXT', payload: { breadcrumbText: breadcrumbText } })
	}
}
export const setNavBreadcrumbEntityHierarchy = (breadCrumbEntityHierarchy: any) => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_BREADCRUMB_ENTITY_HIERARCHY', payload: { breadCrumbEntityHierarchy: breadCrumbEntityHierarchy } })
	}
}