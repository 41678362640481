import React, { forwardRef, Fragment, useImperativeHandle, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, DialogActions, DialogContent, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { ReactComponent as MapLocate } from "@altais/icon-library/legacy/SVG/MapPin-Light_ALT.svg";
import LocationAddForm from "../../LocationAdd/LocationAddForm"
import LocationAddBulk from "../../LocationAdd/LocationAddBulk";
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { appendEhrSystemKeyTemplatePath } from "admin/common/utils";
import { COLORS } from "admin/theme";
import Spinner from "common/components/Spinner/Spinner";
const { LOCATION_BULK_UPLOAD_CSV_TEMPLATE } = (window as any).env;
const MapIcon = createSvgIcon(MapLocate);

const ConfigureLocation = forwardRef((props, ref) => {
    const intl = useIntl();
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [isBulkUploadDisabled, setIsBulkUploadDisabled] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const { auth, admin, practice } = useSelector((state: AppReduxStore) => state);
    const addLocationFormRef = useRef<any>();
    const addLocationBulkRef = useRef<any>();

    useImperativeHandle(
        ref,
        () => ({
            saveAndContinueLaterAction(index: number) {
                return addLocationFormRef.current ?
                    addLocationFormRef.current.saveAndContinueLaterAction(index) :
                    addLocationBulkRef.current.saveAndContinueLaterAction(index)
            }
        }),
    )

    const handleClose = () => {
        setIsBulkUploadDisabled(true);
    };

    const handleChangeTab = (event: any, newTab: any) => {
        setTab(newTab);
    };

    const handleSaveLocation = (event: any) => {
        const result = addLocationFormRef.current.submitForm(event);
        if (result)
            handleClose();
    };

    const handleAddedFile = () => {
        setIsBulkUploadDisabled(false);
    };

    const handleRemovedFile = () => {
        setIsBulkUploadDisabled(true);
    };

    const handleBulkUpload = () => {
        addLocationBulkRef.current.submitLocationAdd()
    }
    const handleLoading = (flag: boolean) => {
        setIsUploading(flag)
    }
    const ehrInfo = admin.ehrSourceSystems?.find((e: any) => e.id === practice?.sPractice?.ehrSourceSystemId)
    return (
        <>
            <Grid item>
                <Typography className={classes.pageHeaderText}>
                    <MapIcon fill="#0F7BBD" className={classes.productsIcon} />
                    <FormattedMessage id="Configure.Text.Config.AddLocation" />
                </Typography>
                <Typography className={classes.pageSubHeaderText}>
                    <FormattedMessage id={"Configure.Text.Config.Location.SubText"} />
                </Typography>
            </Grid>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '10px' }}>
                <Box flexGrow={1}>
                    {tab === 0 && ehrInfo?.hasTemplate && <Button id="btnDownloadTemplate" variant="outlined" style={{ color: COLORS.PRIMARY_BTN_TEXT }}
                        aria-haspopup="true" href={appendEhrSystemKeyTemplatePath(LOCATION_BULK_UPLOAD_CSV_TEMPLATE,
                            auth.currentPractice?.ehrSourceSystemId || practice?.sPractice?.ehrSourceSystemId)}>
                        {intl.formatMessage({ id: "LocationAdd.DowloadTemplate" })}
                    </Button>}
                </Box>
                <Box>
                    {tab === 1 ? <Fragment>
                        <Button id="btnSaveAndFinish" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveLocation}>
                            {intl.formatMessage({ id: "ConfigureLocation.Action.SaveAndFinish" })}
                        </Button>
                    </Fragment> :
                        isUploading ? <Spinner /> : <Button id="btnUploadCSV" variant="outlined" style={{ backgroundColor: '#C7C7CB' }}
                            aria-haspopup="true" onClick={handleBulkUpload} disabled={isBulkUploadDisabled}
                        >
                            Upload
                        </Button>}
                </Box>
            </DialogActions>
            <Box width="100%">
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    onChange={handleChangeTab}
                    aria-label="Add Location tab">
                    <Tab label={intl.formatMessage({ id: "LocationAdd.BulkUpload" })} />
                    <Tab label={intl.formatMessage({ id: "LocationAdd.Tab.AddLocation" })} />
                </Tabs>
            </Box>
            <DialogContent>
                {tab === 1 ? <Box className={classes.tabContentWrapper}>
                    <LocationAddForm ref={addLocationFormRef} />
                </Box> :
                    <Box className={classes.tabContentWrapper}>
                        <LocationAddBulk ref={addLocationBulkRef} handleAddedFile={handleAddedFile} handleRemovedFile={handleRemovedFile} handleClose={handleClose} handleLoading={handleLoading}></LocationAddBulk>
                    </Box>}
            </DialogContent>
        </>
    )
})

export default ConfigureLocation;
