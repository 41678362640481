import React from "react";
import { ILayoutProps } from "react-dropzone-uploader";
import classNames from "classnames";
import { ReactComponent as UploadIcon } from "icon-library/SVG/Upload.svg";
import EmptyMessage from "common/components/empty-message/empty-message";
import SVGIcon from "common/components/svg-icon/svg-icon";
import * as FileClassNames from "../class-names";
import { useStyles } from "../styles";

const DropzoneLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    const classes = useStyles();

    return (
        <div
            {...dropzoneProps}
            className={classNames(
                classes.Files__FileDropzoneInput__DropzoneLayout,
                dropzoneProps.className,
                FileClassNames.DropzoneLayout
            )}
        >
            <div className={classNames(classes.Files__PreviewGrid, FileClassNames.PreviewGrid)}>
                {previews}
                {files.length < maxFiles && input}
            </div>

            {!previews ||
                (previews.length === 0 && (
                    <EmptyMessage
                        className={FileClassNames.DropzoneLayoutEmptyMessage}
                        Icon={<SVGIcon svg={UploadIcon} size="scale5000" color="LGRAY" />}
                        iconTextSpacing="scale800"
                        primaryMessage={{
                            id: "files.fileDropzoneInput.dropzoneLayout.emptyMessage.message",
                            variant: "emptyMessageBigPrimary",
                            color: "LGRAY",
                        }}
                        secondaryMessage={{
                            id: "files.fileDropzoneInput.dropzoneLayout.emptyMessage.addFilesInstruction",
                            variant: "emptyMessageBigSecondary",
                            color: "LGRAY",
                        }}
                    />
                ))}
        </div>
    );
};

export default DropzoneLayout;
