import { AccessControlConfig } from "lib/security/types";
import { useStyles } from "./styles";

export type SideNavItemTypes = SideNavMenuItem | SideNavSeparatorItem;
export type SideNavItemCollection = {
    [id: string]: SideNavItemTypes;
}
export type SideNavConfig = {
    // routes can either be dropdown menus or links to another page
    routes: SideNavItemCollection,
    // adminRoutes can either be dropdown menus or links to admin pages
    adminRoutes: SideNavItemCollection,
    // actions are buttons that trigger some action, such as opening a modal
    actions: SideNavItemCollection
}

export type StyleProps = {
    open: boolean
}

export type DecorationComponentProps = {
    color: string,
    classes: ReturnType<typeof useStyles>,
    args?: any,
    callback?: (result: any) => void;
} & NavItemProps;

export type NavItem = {
    /** Nav item unique identifier */
    id?: string,
}
export interface SideNavMenuItem extends NavItem {
    label: string,                                                          // label: text id for the item
    /** URL route, where to go onclick */
    route: string,                                                          
    Icon: any,                                                              // Icon: icon to be displayed to the left of the label
    /* The default route when choosing a side nav item with a sublist of items */
    defaultRoute?: string,                                               
    DecorationComponent?: React.ComponentType<DecorationComponentProps>;
    /** It is triggered when clicked on an action button */
    callback?: (dispatch: any) => void;
    access?: AccessControlConfig;
    items?: SideNavItemCollection;                                                   // items: list of branch side nav items the user can route too
}
/** Set "separator" to a item on a sub list nav to create a separator */
export interface SideNavSeparatorItem extends NavItem {
    isSeparator: boolean;
};
export function isSeparatorItem(navItem: NavItem): navItem is SideNavSeparatorItem {
    return "isSeparator" in navItem;
}
export function isMenuItem(navItem: NavItem): navItem is SideNavMenuItem {
    return "label" in navItem;
}

export type SideNavItemCallback = (dispatch: any) => void

export type NavItemProps = {
    item: SideNavMenuItem,
    isSubItem?: boolean
} & StyleProps;

export type SubListProps = {
    items: SideNavItemCollection,
    NavItemComponent: React.ComponentType<NavItemProps>
} & StyleProps;
