import { BORDER_RADIUS, COLORS, LAYERS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";
import * as CSS from "./class-names";

const variants = {
    light: {
        background: COLORS.OPTION_MENU_BG_LIGHT,
        color: COLORS.OPTION_MENU_ITEM_TEXT_LIGHT,
    },
    dark: {
        background: COLORS.OPTION_MENU_BG,
        color: COLORS.OPTION_MENU_ITEM_TEXT,
    },
} as const;

export const useStyles = makeStyles({
    [CSS.Popover]: {
        zIndex: LAYERS.TOOLTIP_OVER_MODAL,
        [`& .${CSS.PopoverArrow}`]: {
            position: "absolute",
            width: SIZING.scale700,
            height: SIZING.scale400,
            overflow: "hidden",
            zIndex: 1,
            "&::before": {
                position: "absolute",
                left: `calc((${SIZING.scale700} / 2) - (${SIZING.scale500} / 2))`,
                content: '""',
                display: "block",
                width: SIZING.scale500,
                height: SIZING.scale500,
                borderRadius: SIZING.scale50,
                transform: "rotate(45deg)",
                backgroundColor: variants.light.background,
                ".Popover--dark&": {
                    backgroundColor: variants.dark.background,
                },
            },
        },
        [`&[data-popper-placement^='top'] .${CSS.PopoverArrow}`]: {
            bottom: `calc(1px - ${SIZING.scale400})`,
            "&::before": {
                bottom: `calc(${SIZING.scale400} - (${SIZING.scale500} / 2))`,
            },
        },
        [`&[data-popper-placement^='bottom'] .${CSS.PopoverArrow}`]: {
            top: `calc(1px - ${SIZING.scale400})`,
            "&::before": {
                top: `calc(${SIZING.scale400} - (${SIZING.scale500} / 2))`,
            },
        },
        [`&[data-popper-placement^='left'] .${CSS.PopoverArrow}, &[data-popper-placement^='right'] .${CSS.PopoverArrow}`]:
            {
                width: SIZING.scale400,
                height: SIZING.scale700,
                "&::before": {
                    top: `calc((${SIZING.scale700} / 2) - (${SIZING.scale500} / 2))`,
                },
            },
        [`&[data-popper-placement^='left'] .${CSS.PopoverArrow}`]: {
            right: `calc(1px - ${SIZING.scale400})`,
            "&::before": {
                right: `calc(${SIZING.scale400} - (${SIZING.scale500} / 2))`,
                left: "unset",
            },
        },
        [`&[data-popper-placement^='right'] .${CSS.PopoverArrow}`]: {
            left: `calc(1px - ${SIZING.scale400})`,
            "&::before": {
                left: `calc(${SIZING.scale400} - (${SIZING.scale500} / 2))`,
            },
        },
        [`& .${CSS.PopoverContent}`]: {
            borderRadius: BORDER_RADIUS.MED_LARGE,
            backgroundColor: variants.light.background,
            color: variants.light.color,
            filter: "drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.3))",
            ".Popover--dark&": {
                backgroundColor: variants.dark.background,
                color: variants.dark.color,
            },
        },
    },
});
