import React from "react";
import { Tab, Tabs } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import ItemCount from "../ItemCount/ItemCount";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import classNames from "classnames";
import { SearchFilterTabsProps } from "./types";

const SearchFilterTabs = ({ className, tab, setTab, tabList, onTabChangeText, classes: classesProp }: SearchFilterTabsProps) => {
    const classes = useStyles();

    const onTabChange = (e: any, newValue: string) => {
        setTab(newValue);
        if (onTabChangeText) onTabChangeText();
    }

    const tabsClasses = {
        root: classes.tabsRoot,
        indicator: classes.indicator
    }
    const tabClasses = {
        root: classes.tabRoot,
        selected: classNames("tabSelected", classes.tabSelected, classesProp?.selected)
    }

    return (
        <Tabs className={classNames(classesProp?.root, className)} classes={tabsClasses}
              variant="fullWidth"
              value={tab}
              onChange={onTabChange}>
            {tabList.map((filter) => {
                    return (
                        <Tab classes={tabClasses}
                             className={classNames("tab", classesProp?.tab)}
                             key={filter.id}
                             id={filter.id}
                             value={filter.id}
                             disableRipple
                             label={
                                 <>
                                     <CustomTypography variant="description">
                                         <FormattedMessage id={filter.labelId}/>
                                     </CustomTypography>
                                     <ItemCount
                                         className={classNames("count", classes.count, classesProp?.count)}
                                         count={filter.count} bgColor={"rgba(57, 57, 72, 0.6)"}/>
                                 </>
                             }/>
                    );
                }
            )}
        </Tabs>
    )
}

export default SearchFilterTabs