import { makeStyles } from "@mui/styles";
import {COLORS} from "admin/theme";
export const useStyles = makeStyles(() => ({
        contentWrapper:{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column",
            margin:"25px",
            padding:"50px"
        },
        image:{
           height:"150px",
           width:"150px"
        },
        practicename:{
            display:"flex",
            justifyContent:"space-around",
            width:"500px",
            alignItems:"center",
            "& button":{
                marginLeft:'auto'
            }
        },
        clickBtn:{
            background:COLORS.DEFAULT_BG,
            color:"#FFFFFF",
            "&:hover": {
                backgroundColor: COLORS.DEFAULT_BTN,
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.25)"
            },
            cursor:"pointer"
        }
}));