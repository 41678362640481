import {
    Box, IconButton, Paper,
    Table, TableBody, TableCell, TableContainer,
    TableFooter, TableHead,
    TablePagination, TableRow, TableSortLabel, Typography, Tooltip
} from "@mui/material";
import GridContextMenu from "admin/components/GridContextMenu/GridContextMenu";
import { fetchPatientForms, togglePatientFormStatus} from "admin/store/actions/configuration";
import { AppReduxStore } from "admin/store/reducerTypes";
import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { getFormConfigurable } from "./utils";
import { showNotification } from "admin/store/actions/snackbar";
import PatientFormGridContextMenu from 'admin/components/GridContextMenuContent/PatientFormGridContextMenu/PatientFormGridContextMenu';
import { setNavBreadcrumbText } from "admin/store/actions";
import { createSvgIcon } from "utils";
import { ReactComponent as Info} from "icon-library/SVG/Info-Light_Circle.svg";
import { debounce } from 'lodash';

import { Link, useHistory } from 'react-router-dom';
import TablePaginationActions from "admin/components/TablePaginationActions/TablePaginationActions";
import ChangeFormStatus from "admin/components/Modals/ChangeFormStatus/ChangeFormStatus";
import SearchForm from "admin/views/Configurations/PatientFormsListing/PatientFormConfig/SearchingForm/SearchForm";
import TopCard from './PatientFormConfig/Card/TopCard';
import { ReactComponent as EllipsisV } from "icon-library/SVG/Ellipsis-Vertical.svg";
import { COLORS } from "theme";

const EllipsisVIcon = createSvgIcon(EllipsisV);

interface Data {
    id: string
    number: string
    title: string
    description: string
    isEnabled: boolean
    configurable: boolean
    appointmentTriggers: any[]
    type: string
    lastModifiedDate: any,
    version: string,
    formStatus: string
}

const PatientFormsListing = () => {
    const intl = useIntl();
    const { auth, configuration, modalAdmin } = useSelector((state: AppReduxStore) => state);
    const patientForms = configuration && configuration.patientForms ? configuration.patientForms : [];
    const classes = useStyles();
    const dispatch = useDispatch();
    const InfoIcon = createSvgIcon(Info);

    const [orderBy, setOrderBy] = useState<keyof Data>('title');
    const [order, setOrder] = useState<Order>('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [formRow, setFormRow] = useState();
    const history = useHistory();
    const [filteredForms, setFilteredForms] = useState([]);
    const [contextMenuData, setContextMenuData] = useState({} as any);
    const delayFilter = useRef(debounce((text: any, patientForms: any) => searchFilteredForms(text, patientForms), 1000)).current;

    useEffect(() => {
        if (auth.currentOrg || auth.currentPractice) {
            dispatch(fetchPatientForms());
            dispatch(setNavBreadcrumbText(''));
        }
    }, [auth.currentOrg, auth.currentPractice, dispatch, intl]);

    useEffect(() => {
        setFilteredForms(patientForms);
    }, [patientForms])

    const onDebounceKeyUpChange = (e: any) => {
        const text = e.target.value.toLowerCase();
        delayFilter(text, patientForms);
    }

    const searchFilteredForms = (searchString: any, patientForms: any) => {
        if (searchString === "") {
            setFilteredForms(patientForms);
        } else {
            let newFilteredForms = Object.assign([], patientForms);
            newFilteredForms = newFilteredForms.filter((form: any) => {
                if (form['title'] !== null) {
                    return form['title'].toLowerCase().includes(searchString.toLowerCase())
                } else {
                    return null;
                }
            })
            setFilteredForms(newFilteredForms);
        }
    }

    const handleRowAction = (type: string, row: any) => {
        const patientFormId = row.id;
        switch (type) {
            case 'EditForm':
                history.push("patientforms/" + patientFormId);
                break;
            case 'DisableForm':
            case 'EnableForm':
                dispatch(togglePatientFormStatus(row));
                break;
            default:
                dispatch(showNotification("TODO", "error", "Not Implemented"))
        }
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleFormRow = (row: any) => {
        setFormRow(row)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const handleCreatNewForm = () => {
        history.push("patientforms/createNewForm");
    };

    // function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    //     let fa = typeof a[orderBy] === 'string' ? (a[orderBy] as any).toLowerCase() : a[orderBy],
    //         fb = typeof b[orderBy] === 'string' ? (b[orderBy] as any).toLowerCase() : b[orderBy]
    //     if (fa < fb) {
    //         return -1;
    //     }
    //     if (fa > fb) {
    //         return 1;
    //     }
    //     return 0;
    // }

    type Order = 'asc' | 'desc';

    // function getComparator<Key extends keyof any>(
    //     order: Order,
    //     orderBy: Key,
    // ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    //     return order === 'desc'
    //         ? (a, b) => descendingComparator(a, b, orderBy)
    //         : (a, b) => -descendingComparator(a, b, orderBy);
    // }

    // function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    //     const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    //     stabilizedThis.sort((a, b) => {
    //         const order = comparator(a[0], b[0]);
    //         if (order !== 0) return order;
    //         return a[1] - b[1];
    //     });
    //     return stabilizedThis.map((el) => el[0]);
    // }
    const handleEllipsesActionClick = (event: any, row: any, index: number) => {
        event.preventDefault();
        setContextMenuData({ mouseX: event.clientX - 194, mouseY: event.clientY - 25, row, index })

    };

    const handleRowContextMenuClose = (event: any) => {
        setContextMenuData({ mouseX: 0, mouseY: 0, row: null, index: null })
    };

    const RowContextMenu = () => {
        const row = contextMenuData?.row
        if (!row) return
        return (<GridContextMenu mouseX={contextMenuData?.mouseX} mouseY={contextMenuData?.mouseY} onClose={handleRowContextMenuClose}
            handleFormRow={() => handleFormRow(row)} formNumber={row.number} formTitle={row.title}
            callback={(type: any) => { handleRowAction(type, row) }}
            gridContextMenuContent={PatientFormGridContextMenu} index={contextMenuData?.index} showEnable={!row.isEnabled}
            showDisable={row.isEnabled} />
        )
    }
    const Pagination = () => {
        return (<>
            {filteredForms && filteredForms.length > 0 && <TablePagination className={classes.tableFooterRow}
                rowsPerPageOptions={[10, 20, 50, 100]}
                classes={{ select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
                count={filteredForms.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={intl.formatMessage({ id: "PatientFormsListing.Footer.Label.RowsPerPage" })}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            }</>)
    }

    return (
        <Box>
            <TopCard patientForms={patientForms}/>
            <SearchForm handleCreatNewForm={handleCreatNewForm} handleSearchChange={onDebounceKeyUpChange}/>
            {modalAdmin.activeModal === "EDIT_STATUS" && <ChangeFormStatus row={formRow} callback={handleRowAction}/>}
            <Paper className={classes.paper}>
                <TableContainer className={classes.stickyHeader}>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.sort1}
                                    key={"number"}
                                    sortDirection={orderBy === "number" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "number"}
                                        direction={orderBy === "number" ? order : 'asc'}
                                        onClick={createSortHandler("number")}>
                                        {intl.formatMessage({ id: "PatientFormsListing.Header.Label.Number" })}
                                        {orderBy === "number" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"title"}
                                    sortDirection={orderBy === "title" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "title"}
                                        direction={orderBy === "title" ? order : 'asc'}
                                        onClick={createSortHandler("title")}>
                                        {intl.formatMessage({ id: "PatientFormsListing.Header.Label.FormTitle" })}
                                        {orderBy === "title" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"type"}
                                    sortDirection={orderBy === "type" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "type"}
                                        direction={orderBy === "type" ? order : 'asc'}
                                        onClick={createSortHandler("type")}
                                        >
                                        {intl.formatMessage({ id: "PatientFormsListing.Header.Label.FormType" })}
                                        {orderBy === "type" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"configurable"}
                                    sortDirection={orderBy === "configurable" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "configurable"}
                                        direction={orderBy === "configurable" ? order : 'asc'}
                                        onClick={createSortHandler("configurable")}>
                                        {intl.formatMessage({ id: "PatientFormsListing.Header.Label.Configurability" })}
                                        {orderBy === "configurable" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    className={classes.sort2}
                                    key={"version"}
                                    sortDirection={orderBy === "version" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "version"}
                                        direction={orderBy === "version" ? order : 'asc'}
                                        onClick={createSortHandler("version")}>
                                            {intl.formatMessage({id: "PatientFormsListing.Header.Label.Version"})}
                                            {orderBy === "version" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    style={{width: '3000px'}}
                                    key={"formStatus"}
                                    sortDirection={orderBy === "formStatus" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "formStatus"}
                                        direction={orderBy === "formStatus" ? order : 'asc'}
                                        onClick={createSortHandler("formStatus")}>
                                            {intl.formatMessage({id: "PatientFormsListing.Header.Label.FormStatus"})}
                                            {orderBy === "formStatus" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                </TableCell>
                                {/* <TableCell
                                    key={"appointmentTriggers"}
                                    sortDirection={orderBy === "appointmentTriggers" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "appointmentTriggers"}
                                        direction={orderBy === "appointmentTriggers" ? order : 'asc'}
                                        onClick={createSortHandler("appointmentTriggers")}>
                                        Trigger Setup
                                        {orderBy === "appointmentTriggers" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell> */}
                                <TableCell
                                    key={"isEnabled"}
                                    sortDirection={orderBy === "isEnabled" ? order : false}>
                                    <TableSortLabel>
                                        {intl.formatMessage({ id: "PatientFormsListing.Header.Label.AvailabilityToPractice" })}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredForms.sort((a: any, b: any) => (a[orderBy] > b[orderBy]) ? (order === "asc" ? 1 : -1) : (order === 'asc' ? -1 : 1))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, index: number) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}>
                                            <TableCell className={classes.row}>
                                                {row.number}
                                            </TableCell>
                                            <TableCell>
                                                <Link to={`/admin/configurations/patientforms/${row.id}`}>
                                                    <Typography noWrap variant="body1" className={classes.title}>{row.title}
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {row.formType}
                                            </TableCell>
                                            <TableCell>
                                                {getFormConfigurable(row)}
                                            </TableCell>
                                            <TableCell className={classes.formVersion}>
                                                {row.formVersion}
                                            </TableCell>
                                            <TableCell className={classes.formStatus}>
                                                <div className={classes.comments}>
                                                <div>{row.formStatus + ', ' + new Date(row.lastModifiedDate).toLocaleDateString()}</div>
                                                {(row.formStatus === "APPROVED" || row.formStatus === "IN_PROGRESS") && <Tooltip title={"Comments\n"} className={classes.toolTip} arrow placement='top'>
                                                    <div><InfoIcon fill={COLORS.LINK_BLUE}/></div>
                                                </Tooltip>}
                                                </div>
                                            </TableCell>
                                            {/* <TableCell>
                                                {row?.appointmentTriggers === true && <CheckmarkIcon fill="#00875E"></CheckmarkIcon>}
                                                {row?.appointmentTriggers !== true && <EllipsisHorizontalIcon fill="#393948"></EllipsisHorizontalIcon>}
                                            </TableCell> */}
                                            <TableCell>
                                                <Box width={70} border={2} borderColor={row.isEnabled ? "#0F7BBD" : "#909098"} borderRadius="4px" textAlign="center" p={0}>{row.isEnabled ? "Enabled" : "Disabled"}</Box>
                                            </TableCell>
                                            <TableCell className={classes.stickyLastColumn}>
                                                <IconButton id={"btnRowActionContextMenu" + index} className={classes.icon} onClick={(e) => handleEllipsesActionClick(e, row, index)} size="large">
                                                    <EllipsisVIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {contextMenuData?.row && RowContextMenu()}
                        </TableBody>
                        <TableFooter>
                            {patientForms && patientForms.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No Records
                                    </TableCell>
                                </TableRow>
                            ) : <Pagination />}
                            {patientForms.length !== 0 && filteredForms && filteredForms.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        Can Not Find Forms
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

export default PatientFormsListing;
