import { formatName } from "lib/domain/names";
import { ProviderSearchResponse, ProviderSearchContent, ProviderDto, SearchFilter, Provider } from "./types";
import { Filter } from "lib/ui-components/filtered-search-input";

export const mapProviderDtoToProvider = ({
    hospitals,
    insurances,
    languages,
    licenseStates,
    networks,
    specialties,
    subSpecialties,
    ...everythingElse
}: ProviderDto): Provider => {
    const sortName = formatName({
        ...everythingElse,
        prefix: null
    });

    const displayName = formatName(everythingElse);
    return {
        ...everythingElse,
        sortName,
        displayName,
        hospitals: hospitals.map((item) => item.value),
        insurances: insurances.map((item) => item.value),
        languages: languages.map((item) => item.value),
        licenseStates: licenseStates.map((item) => item.value),
        networks: networks.map((item) => item.value),
        specialties: specialties.map((item) => item.value),
        subSpecialties: subSpecialties.map((item) => item.value),
    };
};

export const mapSearchResponseToContent = (response: ProviderSearchResponse): ProviderSearchContent => ({
    page: response.page,
    data: response.data.map(mapProviderDtoToProvider),
});

export const mapFiltersToSearchFilters = (filters: Filter[]): SearchFilter[] => {
    const filterMap = filters.reduce<Record<string, string[]>>((map, filter) => {
        if (!map[filter.type]) {
            map[filter.type] = [];
        }
        map[filter.type].push(filter.id);
        return map;
    }, {});

    return Object.entries(filterMap).map(([type, values]) => ({ type, values }));
};
