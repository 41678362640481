import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { BulkUploadInstructionsProps } from "./types";

const BulkUploadInstructions = ({ downloadTemplateLink, webInstructionsLink, page, ehrSourceSystemName }: BulkUploadInstructionsProps) => {
    const classes = useStyles()
    const intl = useIntl()
    const isEpicEhr = ehrSourceSystemName?.toLowerCase() === "epic"
    return (<>
        <Box textAlign="left">
            <ol className={classes.bulkUploadInstructionHeading}>
                <li>
                    <Typography variant="caption" className={classes.bulkUploadInstructionHeading}>
                        <FormattedMessage id={"BulkUploadInstructions.Heading1"}
                            values={{
                                page: page,
                                ehrSourceSystemName: ehrSourceSystemName ? ehrSourceSystemName : "EHR",
                            }} />
                    </Typography><br />
                    {!isEpicEhr && ehrSourceSystemName?.toLowerCase() === "athena" && <Typography variant="caption" className={classes.bulkUploadInstructionSubHeading}>
                        <FormattedMessage id={"BulkUploadInstructions.SubHeading1"}
                            values={{
                                WebInstructionLink: (
                                    <a href={webInstructionsLink} target="_blank" rel="noopener, noreferrer" className={classes.links}>
                                        <b>{intl.formatMessage({ id: "BulkUploadInstructions.SubHeading1.WebInstructionLink" })}</b>
                                    </a>
                                ),
                            }} />
                    </Typography>}
                </li>
                <li>
                    <Typography variant="caption" className={classes.bulkUploadInstructionHeading}>
                        {!isEpicEhr ? <FormattedMessage id={"BulkUploadInstructions.Heading2"} /> :
                            <FormattedMessage id={"BulkUploadInstructions.SubHeading2"}
                                values={{
                                    page: page,
                                    ehrSourceSystemName: ehrSourceSystemName,
                                    WebInstructionLink: (
                                        <a href={webInstructionsLink} target="_blank" rel="noopener, noreferrer" className={classes.links}>
                                            {intl.formatMessage({ id: "BulkUploadInstructions.SubHeading1.WebInstructionLink" })}
                                        </a>
                                    ),
                                }} />}
                    </Typography><br />

                    {!isEpicEhr && <>{ehrSourceSystemName ? <Typography variant="caption" className={classes.bulkUploadInstructionSubHeading}>
                        <FormattedMessage id={"BulkUploadInstructions.SubHeading2"}
                            values={{
                                page: page,
                                ehrSourceSystemName: ehrSourceSystemName,
                                WebInstructionLink: (
                                    <a href={webInstructionsLink} target="_blank" rel="noopener, noreferrer" className={classes.links}>
                                        {intl.formatMessage({ id: "BulkUploadInstructions.SubHeading1.WebInstructionLink" })}
                                    </a>
                                ),
                            }} />
                    </Typography> :
                        <Typography variant="caption" className={classes.bulkUploadInstructionSubHeading}>
                            <FormattedMessage id={"BulkUploadInstructions.NoEHR.SubHeading2"}
                                values={{
                                    page: page,
                                    DownloadTemplateLink: (
                                        <a href={downloadTemplateLink} className={classes.links}>
                                            <b>{intl.formatMessage({ id: "BulkUploadInstructions.SubHeading2.TempateLink" })}</b>
                                        </a>
                                    ),
                                }} />
                        </Typography>
                    }</>}
                </li>
            </ol>
        </Box></>)
}

export default BulkUploadInstructions