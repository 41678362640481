import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DialogModal from "components/Modals/dialog-modal/dialog-modal";
import { useFileDropzoneInputContext } from "./file-dropzone-input-context";
import { useStyles } from "../styles";

type RemoveDialogProps = {
    name: string;
    loading?: boolean;
    onRemove: () => void;
    onCancel: () => void;
};

const RemoveDialog = ({ onRemove, onCancel, loading, name }: RemoveDialogProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const { removeDialogProps } = useFileDropzoneInputContext();
    return (
        <DialogModal
            idPrefix="QA_files_remove_file_dialog"
            title={intl.formatMessage({
                id: removeDialogProps?.titleId || "files.fileDropzoneInput.dropzoneLayout.upload.remove.confirmation.title",
            })}
            className={classes.removeAttachmentDialog}
            primaryAction={{
                label: intl.formatMessage({
                    id: "files.confirm",
                }),
                danger: true,
                onClick: () => {
                    !loading && onRemove();
                },
            }}
            secondaryAction={{
                label: intl.formatMessage({ id: "files.cancel" }),
                onClick: () => {
                    !loading && onCancel();
                },
            }}
            loading={loading}
        >
            <div className="removeAttachmentDialog__content">
                <div className="removeAttachmentDialog__text">
                    <FormattedMessage
                        id={
                            removeDialogProps?.primaryMessageId ||
                            "files.fileDropzoneInput.dropzoneLayout.upload.remove.confirmation.primaryMessage"
                        }
                        values={{ name }}
                    />
                    {removeDialogProps?.secondaryMessageId && (
                        <div>
                            <FormattedMessage id={removeDialogProps.secondaryMessageId} />
                        </div>
                    )}
                </div>
            </div>
        </DialogModal>
    );
};

export default RemoveDialog;
