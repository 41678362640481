import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
    notificationItem: {
        display: "flex",
        width: "100%",
        padding: SIZING.scale300,
        "&:hover": {
            padding: `calc(${SIZING.scale300} - 0.5px)`,
        },
        "& .notificationItem__titleRow": {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: SIZING.scale100,
        },
        "& .notificationItem__title": {
            color: COLORS.LIGHT_BLUE,
        },
        "& .notificationItem__content": {
            paddingLeft: SIZING.scale700,
            whiteSpace: "normal"
        },
        "& .notificationItem__bellIcon": {
            marginRight: SIZING.scale100,
            "& path": {
                fill: COLORS.LIGHT_BLUE,
            },
        },
        "& .notificationItem__timePast": {
            color: COLORS.LIST_ITEM_TEXT,
            opacity: 0.4,
        },
        "& .notificationItem__name, & .notificationItem__info": {
            color: COLORS.SUBTITLE_INFO_TEXT,
        }
    },
}));
