import React, { SVGProps, ComponentType } from "react";
import classNames from "classnames";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import * as CSS from "./class-names";
import { useStyles } from "./styles";

const QAId = "QA_modalPanel";
const TitleAreaQAId = `${QAId}__titleArea`;
const ContentAreaQAId = `${QAId}__contentArea`;

export type ModalPanelProps = {
    className?: string;
    title?: string;
    bold?: boolean;
    icon?: ComponentType<SVGProps<SVGSVGElement>>;
    emptyMessage?: JSX.Element;
};

export const ModalPanel: React.FC<ModalPanelProps> = ({ children, className, title, icon, bold, emptyMessage }) => {
    const classes = useStyles();

    // TODO: add icon and it's styles
    return (
        <div data-testid={QAId} className={classNames(classes.ModalPanel, CSS.ModalPanel, className)}>
            {(title || icon) && (
                <div data-testid={TitleAreaQAId} className={CSS.ModalPanel__TitleArea}>
                    <CustomTypography
                        variant={bold ? "modalSectionHeaderBold" : "modalSectionHeader"}
                        className={CSS.ModalPanel__Title}
                    >
                        {title}
                    </CustomTypography>
                </div>
            )}

            {children && (
                <div data-testid={ContentAreaQAId} className={CSS.ModalPanel__ContentArea}>
                    {children}
                </div>
            )}
            {!children && emptyMessage}
        </div>
    );
};
