import React from "react";
import { useStyles } from "./styles";
import { Typography, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppReduxStore } from 'store/reducerTypes';
import { getAge, formatDateWithTime } from "utils";
import { ReactComponent as BuildingHospital } from "icon-library/SVG/Building_Hospital-H.svg";
import { createSvgIcon } from "utils";
import { ReactComponent as BuildingSNF } from "icon-library/SVG/Building-SNF.svg";
import { ALERT_TYPE } from "views/People/Alerts/utils"

const BuildingHospitalIcon = createSvgIcon(BuildingHospital)
const BuildingSNFIcon = createSvgIcon(BuildingSNF)

const PatientDetails = () => {

    const classes = useStyles();

    const { currentAlertDetails, selectedPatient } = useSelector((state: AppReduxStore) => state.patient)

    const {
        visitReason, visitPatientClass, previousVisitLocationAddress1, previousVisitLocationCountry,
        previousVisitLocationState, previousVisitLocationCity, previousVisitLocationFacility,
        previousVisitLocationType, previousVisitLocationDept, adtEventType, visitDateTime = '',
        visitLocationState = '-', visitLocationCountry = '-', visitLocationType = '-', visitLocationFacility = '-',
        visitLocationAddress1 = '-', visitLocationCity = '-', visitLocationDept = '-',
        dischargeDateTime = '',
    } = currentAlertDetails || {}

    const { firstName = "", lastName = "", dateOfBirth = "", genderCode } = selectedPatient || {};

    return (
        <div className={classes.wrapper}>
            <Grid container spacing={3}>
                <Grid item xs={6} className={classes.contentWrapper}>
                    <div className={classes.patientDetails}>
                        <div className="patient-name">
                            <Typography variant="body1" >
                                {firstName}, {lastName}
                            </Typography>
                            <Typography variant="body1" className={classes.ageGender}  >
                                ({getAge(dateOfBirth ? dateOfBirth : '')}, {genderCode})
                            </Typography>
                        </div>
                        <Typography variant="body1" className={classes.desc}>
                            {visitPatientClass}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} className={classes.contentWrapper}>
                    <div className={classes.reasonSection}>
                        <Typography variant="body1" className={classes.reasonTxt} >
                            <FormattedMessage id="ADTPatientDetails.title.reason" />
                        </Typography>
                        <Typography variant="body1" className={classes.desc}>
                            {visitReason}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                {adtEventType === ALERT_TYPE.TRANSFERRED  && <><Grid item xs={8} className={classes.leftSideWrapper}>
                    <div>
                        <Typography variant="body1" >
                            <FormattedMessage id="ADTPatientDetails.title.Discharge" />
                        </Typography>
                    </div>
                    <div className={classes.dischargeDetails}>
                        <BuildingHospitalIcon className={classes.icon} />
                        <div>
                            <div className={classes.facilityDetails}>
                                <Typography variant="body1" className={classes.facilityDetailsHead}>
                                    <FormattedMessage id="ADTPatientDetails.title.type" />
                                </Typography>
                                <Typography variant="body1" className={classes.facilityDetailsDec}>
                                    {previousVisitLocationType}
                                </Typography>
                            </div>
                            <div className={classes.facilityDetails}>
                                <Typography variant="body1" className={classes.facilityDetailsHead}>
                                    <FormattedMessage id="ADTPatientDetails.title.facility" />
                                </Typography>
                                <Typography variant="body1" className={classes.desc}>
                                    {previousVisitLocationFacility}
                                </Typography>
                            </div>
                            <div className={classes.facilityDetails}>
                                <Typography variant="body1" className={classes.facilityDetailsHead}>
                                    <FormattedMessage id="ADTPatientDetails.title.department" />
                                </Typography>
                                <Typography variant="body1" className={classes.desc}>
                                    {previousVisitLocationDept}
                                </Typography>
                            </div>
                            <div className={classes.facilityDetails}>
                                <Typography variant="body1" className={classes.facilityDetailsHead}>
                                    <FormattedMessage id="ADTPatientDetails.title.address" />
                                </Typography>
                                <Typography variant="body1" className={classes.desc}>
                                    {previousVisitLocationAddress1},{previousVisitLocationCity},{previousVisitLocationState},{previousVisitLocationCountry}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                    <Grid item xs={4} className={classes.rightSideWrapper}>
                        <div className={classes.admitDetails}>
                            <div>
                                <Typography variant="body1" >
                                    <FormattedMessage id="ADTPatientDetails.title.discharge" />
                                </Typography>
                                <Typography variant="body1" className={classes.desc}>
                                    {formatDateWithTime(dischargeDateTime)}
                                </Typography>
                            </div>
                        </div>

                    </Grid></>}
                <Grid item xs={8} className={classes.leftSideWrapper}>
                    <div>
                        <Typography variant="body1" >
                            <FormattedMessage id={`ADTPatientDetails.title.${adtEventType}`} />
                        </Typography>
                    </div>
                    <div className={classes.dischargeDetails}>
                        {adtEventType === ALERT_TYPE.ADMITTED ? <BuildingHospitalIcon className={classes.icon} /> : <BuildingSNFIcon className={classes.icon} />}
                        <div>
                            <div className={classes.facilityDetails}>
                                <Typography variant="body1" className={classes.facilityDetailsHead} >
                                    <FormattedMessage id="ADTPatientDetails.title.type" />
                                </Typography>
                                <Typography variant="body1" className={classes.facilityDetailsDec}>
                                    {visitLocationType}
                                </Typography>
                            </div>
                            <div className={classes.facilityDetails}>
                                <Typography variant="body1" className={classes.facilityDetailsHead}>
                                    <FormattedMessage id="ADTPatientDetails.title.facility" />
                                </Typography>
                                <Typography variant="body1" className={classes.desc}>
                                    {visitLocationFacility}
                                </Typography>
                            </div>
                            <div className={classes.facilityDetails}>
                                <Typography variant="body1" className={classes.facilityDetailsHead}>
                                    <FormattedMessage id="ADTPatientDetails.title.department" />
                                </Typography>
                                <Typography variant="body1" className={classes.desc}>
                                    {visitLocationDept}
                                </Typography>
                            </div>
                            <div className={classes.facilityDetails}>
                                <Typography variant="body1" className={classes.facilityDetailsHead}>
                                    <FormattedMessage id="ADTPatientDetails.title.address" />
                                </Typography>
                                <Typography variant="body1" className={classes.desc}>
                                    {visitLocationAddress1},{visitLocationCity},{visitLocationState},{visitLocationCountry}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.rightSideWrapper}>
                    <div className={classes.admitDetails}>
                        <div>
                            <Typography variant="body1" >
                                <FormattedMessage id="ADTPatientDetails.title.admit" />
                            </Typography>
                            <Typography variant="body1" className={classes.desc}>
                                {formatDateWithTime(visitDateTime)}
                            </Typography>
                        </div>
                        {adtEventType === ALERT_TYPE.DISCHARGE && <div>
                            <Typography variant="body1" >
                                <FormattedMessage id="ADTPatientDetails.title.discharge" />
                            </Typography>
                            <Typography variant="body1" className={classes.desc}>
                                {formatDateWithTime(dischargeDateTime)}
                            </Typography>
                        </div>}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default PatientDetails
