import createReducer from '../createReducer'
import {CommonState} from '../reducerTypes'

const initialState: CommonState = {
    commonLoader: false,
    isNavCollapse:false
}

export default createReducer(initialState, {
    'UPDATE_COMMON_LOADER': (state: CommonState, payload: boolean): CommonState => {
        return {
            ...state,
            commonLoader: payload
        }
    },
    'UPDATE_NAV_COLLAPSE_FLAG': (state: CommonState, payload: boolean): CommonState => {
        return {
            ...state,
            isNavCollapse: payload
        }
    }
})