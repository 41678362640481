import { makeStyles } from "@mui/styles";
import { COLORS, SIZING, toRem, TYPOGRAPHY } from "theme";
import * as CSS from "./class-names";

export const useStyles = makeStyles(() => ({
    [CSS.TableList]: {
        borderSpacing: 0,
        margin: 0,
        padding: 0,
        width: "100%",
        maxWidth: "100%",

        "& td": {
            height: "100%",
        },
        "& td > div": {
            height: "100%",
        },

        [`&.${CSS.TableListDefault}`]: {
            "& td": {
                paddingTop: SIZING.scale400,
                paddingBottom: SIZING.scale275,
            },
            "& tr:not(:first-child) > td:not(:first-child)": {
                borderTop: `solid 1px ${COLORS.DGREY}`,
            },
            [`& tr.${CSS.TableListItemSelected}:not(:last-child) > td`]: {
                borderBottom: `solid 1px ${COLORS.DGREY}`,
            },
            [`& tr.${CSS.TableListItemSelected}:not(:first-child) > td`]: {
                borderTop: `solid 1px ${COLORS.DGREY}`,
            },
            [`& tr.${CSS.TableListItemSelected} + tr:not(:first-child) > td`]: {
                borderTop: "unset",
            },
            [`& .${CSS.TableListItemSelected}`]: {
                backgroundColor: COLORS.MODAL_CONTENT_LBLUE_BG,
            },
        },
        [`&.${CSS.TableListAlternated}`]: {
            "& tr:nth-child(odd)": {
                backgroundColor: COLORS.WHITE
            },
            "& td": {
                paddingTop: SIZING.scale300,
                paddingBottom: SIZING.scale300
            },
            "& td:first-child": {
                paddingLeft: SIZING.scale200
            },
            "& td:last-child": {
                paddingRight: SIZING.scale200
            }
        },

        [`& .${CSS.TableListItemBigHorizontalPadding}, &.${CSS.TableListAlternated} .${CSS.TableListItemBigHorizontalPadding}`]: {
            "& td:first-child": {
                paddingLeft: SIZING.scale400
            },
            "& td:last-child": {
                paddingRight: SIZING.scale400
            }
        },

        [`& .${CSS.TableListPersonItemAvatarCell}`]: {
            boxSizing: "content-box",
        },
        [`& .${CSS.TableListPersonItemAvatar}`]: {
            display: "flex",
            overflow: "hidden",
            width: SIZING.scale800,
            height: SIZING.scale800,
            borderRadius: "50%",
            backgroundColor: COLORS.MIDDLE_GRAY,
            alignItems: "baseline",
            justifyContent: "center",
            marginRight: SIZING.scale200,
            "& svg": {
                opacity: 0.4,
                width: SIZING.scale1000,
                height: SIZING.scale1000,
                flexShrink: 0,
                marginTop: `-${SIZING.scale25}`,
                "& g, & path": {
                    fill: COLORS.WHITE,
                },
            },
            [`.${CSS.TableListBig}&`]: {
                marginRight: SIZING.scale400,
                width: toRem(56),
                height: toRem(56),
                "& svg": {
                    width: toRem(70),
                    height: toRem(70),
                }
            }
        },
        [`& .${CSS.TableListItemTDExpanded}`]: {
            // table hack to prevent cells for overgrowing,
            // with maxWidth 1px and width 100% it will take as much space as it can, without overflow
            maxWidth: "1px",
            width: "100%",
        },
        [`& .${CSS.TableListPersonItemNameInfoCell}`]: {
            verticalAlign: "text-bottom",
            flexGrow: 1,
            color: COLORS.LINK_BLUE,
            cursor: "default",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            [`&.${CSS.TableListPersonItemNameInfoCellClickable}`]: {
                cursor: "pointer",
            },
            [`& .${CSS.TableListPersonItemName}`]:{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "19.6px",
                [`.${CSS.TableListBig}&`]: {
                    fontWeight: 700,
                    fontSize: "17px",
                    lineHeight: "23.8px",
                }
            },
            [`& .${CSS.TableListPersonItemInfo}`]: {
                color: COLORS.BLACK,
                display: "block",
                fontWeight: 600,
                fontSize: SIZING.scale250,
                lineHeight: SIZING.scale350,
                [`.${CSS.TableListBig}&`]: {
                    color: COLORS.LGREY1,
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "22.4px",
                }
            },
            [`& .${CSS.TableListPersonItemRole}`]:{
                marginLeft: SIZING.scale400,
                color: COLORS.LGREY1,
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "19.6px",
                [`.${CSS.TableListBig}&`]: {
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "22.4px",
                }
            }
        },
        [`& .${CSS.TableListPersonItemSpecialtiesCell}`]: {
            lineHeight: TYPOGRAPHY.small.lineHeight,
            minWidth: "250px",
            paddingRight: SIZING.scale100,
            "& span": {
                verticalAlign: "middle",
            },
        },
        // right now this element is only used in the "BIG" variant and
        // there's no size defined for small variant.
        [`& .${CSS.TableListPersonItemLastUpdate}`]: {
            color: COLORS.LGREY1,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "22.4px",
            textAlign: "right"
        },
        [`& .${CSS.TableListItemCheckbox}`]: {
            padding: 0,
            paddingRight: SIZING.scale200,
            "&.MuiCheckbox-root:hover": {
                backgroundColor: "unset",
            },
            "& g, & path": {
                fill: COLORS.LINK_BLUE,
            },
            "&.Mui-disabled": {
                "& g, & path": {
                    fill: COLORS.LGRAY,
                },
            },
        },
        [`& .${CSS.TableListItemContextMenuCell}`]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginRight: SIZING.scale300,
        },
        [`& .${CSS.TableListItemContextMenu}`]: {
            "& > div": {
                display: "flex",
                flexDirection: "column"
            }
        },
        [`& .${CSS.TableListItemTag}`]: {
            marginTop: SIZING.scale125,
            fontWeight: 600,
            fontSize: "10px",
            lineHeight: "10px",
            color: COLORS.GRAY_50
        }
    },
}));
