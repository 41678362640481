import React, { Fragment } from "react";
import { useIntl } from 'react-intl';
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as KeyIcon } from 'icon-library/SVG/Key.svg';
import { GridContextMenuContentProps } from 'admin/components/GridContextMenuContent/types';
import { useStyles } from './styles';

const PatientSupportNetworkGridContextMenu = ({ callback, handleClose }: GridContextMenuContentProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Fragment>
            <ListItem button>
                <ListItemIcon>
                    <KeyIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "BulkActionMenu.ResetPassword" })} classes={{ primary: classes.listText }} onClick={() => { callback("ResetPassword"); handleClose() }} />
            </ListItem>
        </Fragment>
    );
}

export default PatientSupportNetworkGridContextMenu;
