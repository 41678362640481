import { Checkbox } from "@mui/material";
import { useFeeScheduleGroups } from "./use-fee-schedule-groups";
import { BillingGroupStateType, billingGroupProps } from "./types";

export const FeeScheduleGroupsTable = ({ orgId, billingGroups, onBillingGroupsChange: setBillingGroups, disabled, enabledRegions }: { orgId: string, billingGroups: BillingGroupStateType, onBillingGroupsChange: (newBillingGroups: BillingGroupStateType) => void, disabled?: boolean, enabledRegions?: string[] }) => {
    const { feeScheduleGroups } = useFeeScheduleGroups(orgId);
    // const [billingGroups, setBillingGroups] = useState<BillingGroupStateType>({});

    const getRegions = (regionIds: string): string[] => {
        if (!regionIds || regionIds === '') return [];
        return regionIds.substring(1, regionIds.length - 1).split(",");
    }

    const shouldBeDisabled = (feeScheduleGroup: billingGroupProps, disabled?: boolean, enabledRegions?: string[]): boolean => {
        const regionIds: string = feeScheduleGroup.regionIds;
        const currentRegions: string[] = getRegions(regionIds);

        if (!disabled && !enabledRegions) {
            return false;
        } else if (!!disabled && !enabledRegions) {
            return disabled;
        } else if (!!disabled && enabledRegions) {
            const isMatched = currentRegions.every((region) => enabledRegions.includes(region)); // && enabledRegions.every((region) => currentRegions.includes(region));
            return disabled && !isMatched;
        }
        return false;
    }

    return (
        <div style={{ width: '900px' }}>
            <ul style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0', margin: '0px' }}>
                {feeScheduleGroups && feeScheduleGroups.length > 0 && (feeScheduleGroups.map((feeScheduleGroup: billingGroupProps, index: number) => {
                    return (<li key={`fee_schedule_group_${index}`}
                        style={{ listStyle: 'none', flex: '0 0 33.333333%', marginTop: '5px', marginBottom: '5px', fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%' }}>
                        <Checkbox
                            disabled={shouldBeDisabled(feeScheduleGroup, disabled, enabledRegions)}
                            color="primary" disableRipple disableFocusRipple
                            checked={billingGroups[feeScheduleGroup.billingGroupId] || false}
                            onChange={(event) => {
                                const newValue: boolean = Boolean(event.target.checked);
                                const newBillingGroups = Object.assign({}, billingGroups);
                                newBillingGroups[feeScheduleGroup.billingGroupId] = newValue;
                                setBillingGroups(newBillingGroups);
                            }}
                            id={`billing_group_${feeScheduleGroup.billingGroupId}`} />
                        {feeScheduleGroup.billingGroupName}
                    </li>);
                }))}
            </ul>
        </div>
    )
}