import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING } from "admin/theme";

export const useStyles = makeStyles(() => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        borderRadius: '24px',
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    usersWrapper: {
        display: "flex",
        flex: '0 1 auto',
        flexDirection: "column",
        position: "absolute",
        //left: `-${SIZING.scale300}`,
        width: "100%",
        height: "100%",
        borderRadius: `0 ${BORDER_RADIUS.LARGE} ${BORDER_RADIUS.LARGE} 0`,


    },
    usersTopActionWrapper: {
        padding: '10px 0px',
    },
    categoryTitle: {
        color: "rgba(126, 144, 179, 0.8)"
    },
    category: {
        marginBottom: SIZING.scale500
    },
    grdHeadercell: {
        backgroundColor: '#EDEDEF'
    },
    userName: {
        color: '#393948',
        fontWeight: 600,
        fontSize: '40px',
        lineHeight: '140%'
    },
    heading: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948',
        marginBottom: '10px'
    },
    contolsWrapper: {
        margin: '20px 0px'
    },
    practiceDetailsInfoText: {
        fontSize: '17px',
        marginBottom: '10px',
        color: '#565662',
        fontWeight: 700
    },
    practiceDetailsTextSize: {
        fontSize: "16px"
    },
    editIcon: {
        height: '24px',
        width: '24px',
        verticalAlign: 'middle',
    },
    practiceProductsText: {
        marginBottom: '10px'
    },
    productLicense: {
        color: '#565662',
        fontWeight: '600',
        fontSize: '18px'
    },
    whiteBackGroundWrapper: {
        background: '#FFFFFF',
        borderRadius: '16px',
        padding: '16px',
    },
    iconButtonEdit: {
        padding: '0px',
        alignItems: 'flex-start'
    }
}));