import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "admin/theme";

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    dynamicFieldValues: {
        marginLeft: SIZING.scale200
    },
    checkBoxLabel: {
        width: '12.5rem'
    },
    radioButtonLabel: {
        width: '12.5rem'
    },
    groupedAnswerLabel: {
        textAlign: 'center'
    },
    groupedCheckBoxLabel: {
        minWidth: '5.75rem',
        margin: 'auto'
    },
    groupedRadioButtonLabel: {
        minWidth: '5.75rem',
        margin: 'auto',
        '& .MuiIconButton-label': {
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .MuiIconButton-root': {
            margin: 'auto'
        }
    },
    formHeaderEditables: {
        marginBottom: 10
    },
    label: {
        fontWeight: 600
    },
    toolTipIconStyle: {
        fill: COLORS.DEFAULT_BTN
    }
}));