import { useSelector } from "react-redux";
import { AppReduxStore, UserState } from "store/reducerTypes";

const useCurrentUser = () => {
    const user: UserState = useSelector((state: AppReduxStore) => state.user);
    return {
        ...user,
        currentUserId: user.currentProfileId
    };
}

export default useCurrentUser;
