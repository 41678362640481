import React, { useEffect } from "react"
import { useStyles } from "./styles"
import { PermissionCheckErrorMessageProps } from "./types"
import { useSelector } from "react-redux";
import { AppReduxStore } from "png/store/reducerTypes"
import { FormattedMessage } from "react-intl";
//TODO need to clean up
const PermissionCheckErrorMessage: React.FC<PermissionCheckErrorMessageProps> = ({ priority = 'both',type='patient' }) => {
    // const [isPermissionMicEnabled, udpateMicPermission] = useState<Boolean>(false)
    // const [isPermissionCamEnabled, udpateCamPermission] = useState<Boolean>(false)
    const { isMediaAccessError } = useSelector((store: AppReduxStore) => store.pngMobile)

    const classes = useStyles()
    useEffect(() => {
        // updatePermission()
    }, [])

    useEffect(() => {
        if (isMediaAccessError) {
            // udpateMicPermission(!isMediaAccessError.includes('mic'))
            // udpateCamPermission(!isMediaAccessError.includes('camera'))
        }
    }, [isMediaAccessError])

    /*
    const updatePermission = async () => {
        const mic = await isPermissionDenied('microphone');
        const camera = await isPermissionDenied('camera');
        udpateCamPermission(!camera)
        udpateMicPermission(!mic)
    }
    const getTitle = () => {
        let isBothDisabled = !isPermissionCamEnabled && !isPermissionMicEnabled
        return `PLEASE ENABLE YOUR ${!isPermissionCamEnabled ? 'CAMERA' : ''} ${isBothDisabled ? 'AND' : ''} ${!isPermissionMicEnabled ? 'AUDIO' : ''}`
    }

    const isRenderDisabled = () => {
        switch (priority) {
            case 'cam':
                return isPermissionCamEnabled
            case 'mic':
                return isPermissionMicEnabled
            default:
                return isPermissionMicEnabled && isPermissionCamEnabled
        }
    }
    */

    if (!isMediaAccessError) {
        return null
    }

    return (
        <div className={`${classes.wrapper} media-error-root-wrapper`}>
            <p><FormattedMessage id="videoCall.permission.errorMessage"/></p>
           {type ==='patient' && <p><FormattedMessage id="videoCall.permission.officeInfo"/></p>}
        </div>
    )

}

export default PermissionCheckErrorMessage