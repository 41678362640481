import React from "react";
import {Box, Typography, Tooltip} from "@mui/material"
import {SIZING} from "theme"
import {FormattedDate, FormattedRelativeTime, FormattedTime, FormattedMessage, FormatDateOptions, useIntl} from "react-intl";
import {DaysOfTheWeek, MS_PER_MINUTE, MS_PER_HOUR, MS_PER_THREE_HOURS} from "./constants";
import {compareDates, compareTimes, getMinutes, getHours} from "./utils"

const MessageDate = ({ className, date }: { className?: string; date?: Date | null }) => {
    const intl = useIntl();

    if (!date) {
        return null;
    }

    const today = new Date();
    const day = intl.formatMessage({ id: DaysOfTheWeek[date.getDay()] });
    const dateOptions: FormatDateOptions = { year: "numeric", month: "short", day: "2-digit" };
    const fullDateTimeStamp = new Intl.DateTimeFormat("en-US", { dateStyle: "long", timeStyle: "medium" }).format(date);
    let innerContent;

    switch (compareDates(date, today)) {
        case 0:
            const timeDiff = compareTimes(date, today);
            if (timeDiff <= MS_PER_MINUTE) {
                //Less than minute, now
                innerContent = <FormattedMessage id="MessageThreads.category.now" />;
            } else if (timeDiff <= MS_PER_HOUR) {
                //Less than hour, minutes
                innerContent = <FormattedRelativeTime value={getMinutes(date, today)} style={`long`} unit="minute" />;
            } else if (timeDiff <= MS_PER_THREE_HOURS) {
                //Less than 3 hours, hours ago
                innerContent = <FormattedRelativeTime value={getHours(date, today)} style={`long`} unit="hour" />;
            } else {
                innerContent = (
                    <>
                        <FormattedMessage id="MessageThreads.category.today" />,
                        <span style={{ marginLeft: SIZING.scale200 }}>
                            <FormattedTime value={date} />
                        </span>
                    </>
                );
            }
            break;
        case 1:
            innerContent = (
                <>
                    <FormattedMessage id="MessageThreads.category.yesterday" />,
                    <span style={{ marginLeft: SIZING.scale200 }}>
                        <FormattedTime value={date} />
                    </span>
                </>
            );
            break;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            innerContent = (
                <>
                    <FormattedMessage id={"MessageThreads.category.recent"} values={{ day }} />,
                    <span style={{ marginLeft: SIZING.scale200 }}>
                        <FormattedTime value={date} />
                    </span>
                </>
            );
            break;
        default:
            innerContent = <FormattedDate value={date} {...dateOptions} />;
    }
    return (
        <Tooltip title={fullDateTimeStamp}>
            <Box id="QA_messageTime" className={className}>
                <Typography variant="h6" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {innerContent}
                </Typography>
            </Box>
        </Tooltip>
    );
};

export default MessageDate