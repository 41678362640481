import { useEffect } from "react";
import { useApi } from "common/utils/use-api";
import { useUserNotification } from "common/utils/use-user-notification";
import {
    FileMetadataSearchRequest,
    FileMetadataSearch,
    FileMetadataDetails,
} from "../types";

export type useFilesParams = {
    files?: FileMetadataDetails[];
    fileIds?: string[];
};

export const useFiles = ({ files, fileIds }: useFilesParams) => {
    const { enqueueError } = useUserNotification();
    // TODO: in future we should have a virtualized view a proper pagination.
    // instead of pagination we can use the useBatchQueue to give us only 
    // N ids at a time, and we could add ids to the queue as they appear on the screen
    const {content: search, fetchInfo: searchFetchInfo} = useApi<FileMetadataSearchRequest, FileMetadataSearch | null>(
        {
            defaultContent: null,
            endpoint: "/file/v1/files/search",
            method: "POST",
            autoRequest: true,
            timeout: -1,
            requestDecisionMaker: (params) => Boolean(params.fileIds.length && !files?.length),
        },
        {
            fileIds: fileIds || [],
            page: {
                number: 0,
                size: fileIds?.length || 1,
            },
        }
    );

    const fetchState = searchFetchInfo.state;
    useEffect(() => {
        if (fetchState === "failed") {
            enqueueError("files.fileGridView.failedToLoadMetadata");
        }
    }, [fetchState, enqueueError]);

    const filesMetadata = files || search?.data;

    return { filesMetadata: filesMetadata || [], loading: searchFetchInfo.state !== "failed" };
};

