import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useStyles } from "./styles-main";
import { LayoutMainProps } from "./types";
import { FormattedMessage } from "react-intl";
import AppBreadcrumbs from "./AppBreadcrumbs/AppBreadcrumbs";
import AppBanners from "./AppBanners/AppBanners";
import { SideNav } from "views";

const LayoutMain = ({ leftNav, rightTopHeader, unauthorized, children, hideAppBreadcrumbs }: LayoutMainProps) => {
    const { navigation } = useSelector((state: AppReduxStore) => state)
    const { open } = navigation
    const classes = useStyles(open)

    return (<>{unauthorized ?
        <Box className={classes.contentWrapper} flexDirection="column">
            <Box>
                <Typography noWrap variant="body1" color="secondary"><FormattedMessage id={"RestrictedProfile"} /></Typography>
            </Box>
            <Box>
                <Link to="/sProfile">
                    <Typography noWrap variant="body1">Home</Typography>
                </Link>
            </Box>
        </Box> :
        <>{<Box className={classes.contentWrapper}>
            <Box className={classes.leftMenuArea}>
                <SideNav />
            </Box>
            <Box className={classes.contentArea} >
                <Box className={classes.rightHeader}>
                    {rightTopHeader}
                </Box>
                <Box>
                    <AppBanners />
                    <Box className={classes.rightContent}>
                        {!hideAppBreadcrumbs && <AppBreadcrumbs />}
                        {children}
                    </Box>
                </Box>
            </Box>
        </Box>}</>
    }</>)
}

export default LayoutMain
