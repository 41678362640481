import { ConversationParticipant, PatientProfile, ProviderProfile } from "store/chat-types";
import { getAge } from "utils";
import { USER_PROFILE } from "constant";

export const getParticipantFullName = (p: ConversationParticipant): string => {
    const profile = p[USER_PROFILE[p.userType]];
    if (profile) {
        const participantNameFragments = [];
        if (profile.prefix) {
            participantNameFragments.push(profile.prefix);
        }
        participantNameFragments.push(profile.firstName);
        if ((profile as PatientProfile | ProviderProfile).middleName) {
            participantNameFragments.push((profile as PatientProfile | ProviderProfile).middleName);
        }
        participantNameFragments.push(profile.lastName);
        return participantNameFragments.join(" ");
    }
    return p.identity;
};

export const getParticipantAdditionalInfo = (p: ConversationParticipant): string => {
    const profile = p.patientProfile;
    if (!profile) {
        return "";
    }
    return `${getAge(profile.dateOfBirth)} ${profile.genderCode}, ${profile.mrn}`;
};
