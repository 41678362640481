import React from "react";
import { useIntl } from 'react-intl';
import { List, ListItem, ListItemIcon, ListItemText, Box } from "@mui/material";
import { ReactComponent as Bell } from 'icon-library/SVG/Bell.svg';
import { ReactComponent as CallIcon } from 'icon-library/SVG/Phone_Answer-Circle.svg';
import { ReactComponent as EnvelopeIcon } from 'icon-library/SVG/eMail_Closed-Envelope.svg';
import { ReactComponent as UserGroupIcon } from 'icon-library/SVG/User_Group.svg';
import { useStyles } from './styles';

const ActionsDropDown = () => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Box>
        <List className={classes.baMenu}>
            <ListItem button>
                <ListItemIcon>
                    <EnvelopeIcon width={24} />
                </ListItemIcon>
                <ListItemText  primary={intl.formatMessage({ id: "RPM.Action.See.Message" })} classes={{ primary: classes.listText }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <UserGroupIcon width={24} />
                </ListItemIcon>
                <ListItemText  primary={intl.formatMessage({ id: "RPM.Action.Care.Team" })} classes={{ primary: classes.listText }} />
            </ListItem>
            <hr></hr>
            <ListItem button>
                <ListItemIcon>
                    <CallIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "RPM.Action.Call" })} classes={{ primary: classes.listText }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <Bell width={24} />
                </ListItemIcon>
                <ListItemText  primary={intl.formatMessage({ id: "RPM.Action.Prompt.Report" })} classes={{ primary: classes.listText }} />
            </ListItem>
        </List>
        <Box className={classes.traingle} />
        </Box>
    );
}

export default ActionsDropDown;
