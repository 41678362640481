import { ChatConversation } from "components/messaging/types";
import { ChatNotificationCode, ChatStatusInfo } from "./types";
import { EConsultRequestStatuses, EConsultRequestStatus, EConsultRecipientStatus } from "views/EConsult/types";

type StatusRule = {
    // Undefined means any status
    requestStatus: EConsultRequestStatus | undefined;
    // Undefined means any status
    conversationStatus: ChatConversation["status"] | undefined;
    // Undefined means any status
    senderStatus: EConsultRecipientStatus | undefined;
    // Undefined means any status
    recipientStatus: EConsultRecipientStatus | undefined;
    // Undefined means no message
    referrerNotificationCode: ChatNotificationCode;
    // Undefined means no message
    recipientNotificationCode: ChatNotificationCode;
    allowMessagingForReferrer: boolean;
    allowMessagingForRecipient: boolean;
};

// Rules are ordered by evaluation
const RULES: StatusRule[] = [
    {
        requestStatus: EConsultRequestStatuses.ASSIGNED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: "ACCEPTED",
        referrerNotificationCode: undefined,
        recipientNotificationCode: "claimed",
        allowMessagingForReferrer: true,
        allowMessagingForRecipient: true,
    },
    {
        requestStatus: EConsultRequestStatuses.ASSIGNED,
        conversationStatus: "active",
        senderStatus: "ACCEPTED",
        recipientStatus: undefined,
        referrerNotificationCode: undefined,
        recipientNotificationCode: "claimed",
        allowMessagingForReferrer: true,
        allowMessagingForRecipient: true,
    },
    {
        requestStatus: EConsultRequestStatuses.ASSIGNED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: undefined,
        referrerNotificationCode: "closed",
        recipientNotificationCode: "closed",
        allowMessagingForReferrer: false,
        allowMessagingForRecipient: false,
    },
    {
        requestStatus: EConsultRequestStatuses.CANCELLED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: undefined,
        referrerNotificationCode: "closed",
        recipientNotificationCode: "closed",
        allowMessagingForReferrer: false,
        allowMessagingForRecipient: false,
    },
    {
        requestStatus: EConsultRequestStatuses.CLOSED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: undefined,
        referrerNotificationCode: "closed",
        recipientNotificationCode: "closed",
        allowMessagingForReferrer: false,
        allowMessagingForRecipient: false,
    },
    {
        requestStatus: EConsultRequestStatuses.EXPIRED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: "ACCEPTED",
        referrerNotificationCode: undefined,
        recipientNotificationCode: "claimed",
        allowMessagingForReferrer: true,
        allowMessagingForRecipient: true,
    },
    {
        requestStatus: EConsultRequestStatuses.EXPIRED,
        conversationStatus: "active",
        senderStatus: "ACCEPTED",
        recipientStatus: undefined,
        referrerNotificationCode: undefined,
        recipientNotificationCode: "claimed",
        allowMessagingForReferrer: true,
        allowMessagingForRecipient: true,
    },
    {
        requestStatus: EConsultRequestStatuses.EXPIRED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: undefined,
        referrerNotificationCode: "closed",
        recipientNotificationCode: "closed",
        allowMessagingForReferrer: false,
        allowMessagingForRecipient: false,
    },
    {
        requestStatus: EConsultRequestStatuses.REJECTED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: undefined,
        referrerNotificationCode: "closed",
        recipientNotificationCode: "closed",
        allowMessagingForReferrer: false,
        allowMessagingForRecipient: false,
    },
    {
        requestStatus: EConsultRequestStatuses.SUBMITTED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: "REJECTED",
        referrerNotificationCode: "closed",
        recipientNotificationCode: "closed",
        allowMessagingForReferrer: false,
        allowMessagingForRecipient: false,
    },
    {
        requestStatus: EConsultRequestStatuses.SUBMITTED,
        conversationStatus: "active",
        senderStatus: "REJECTED",
        recipientStatus: undefined,
        referrerNotificationCode: "closed",
        recipientNotificationCode: "closed",
        allowMessagingForReferrer: false,
        allowMessagingForRecipient: false,
    },
    {
        requestStatus: EConsultRequestStatuses.SUBMITTED,
        conversationStatus: "active",
        senderStatus: undefined,
        recipientStatus: undefined,
        referrerNotificationCode: undefined,
        recipientNotificationCode: "unclaimed",
        allowMessagingForReferrer: true,
        allowMessagingForRecipient: true,
    },
    {
        requestStatus: undefined,
        conversationStatus: "inactive",
        senderStatus: undefined,
        recipientStatus: undefined,
        referrerNotificationCode: "closed",
        recipientNotificationCode: "closed",
        allowMessagingForReferrer: false,
        allowMessagingForRecipient: false,
    },
];

export const getChatStatusInfo = (
    requestStatus: EConsultRequestStatus,
    conversationStatus: ChatConversation["status"],
    senderStatus: EConsultRecipientStatus | undefined,
    recipientStatus: EConsultRecipientStatus | undefined,
    isSenderReferrer: boolean
): ChatStatusInfo => {
    const foundRule = RULES.find(
        (rule) =>
            (!rule.requestStatus || rule.requestStatus === requestStatus) &&
            (!rule.conversationStatus || rule.conversationStatus === conversationStatus) &&
            (!rule.recipientStatus || rule.recipientStatus === recipientStatus) &&
            (!rule.senderStatus || rule.senderStatus === senderStatus)
    );
    if (foundRule) {
        return {
            notificationCode: isSenderReferrer
                ? foundRule.referrerNotificationCode
                : foundRule.recipientNotificationCode,
            allowMessaging: isSenderReferrer
                ? foundRule.allowMessagingForReferrer
                : foundRule.allowMessagingForRecipient,
        };
    }
    return {
        notificationCode: undefined,
        allowMessaging: false,
    };
};
