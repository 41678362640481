import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "../../../theme";


export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "950px",
        minHeight: "600px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.XL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        padding: '20px',
        textAlign: 'center',
        minWidth: '300px'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    formGpSubHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '14px',
        color: '#393948',
        paddingBottom: '15px'
    },
    contentDialog: {
        padding: '20px'
    },
    dlgAction: {
        padding: '5px 16px'
    },
    waitingRoomModal: {
        boxShadow: 'none'
    },
    waitingRoomTitle: {
        padding: '20px 0px 0px',
        margin: '0px',
        color: '#393948'
    },
    card: {
        width: '210px',
        minHeight: '300px',
        left: '0px',
        top: '0px',
        background: '#FFFFFF',
        boxShadow: '0.554187px 0px 4.4335px rgba(0, 0, 0, 0.05), inset -0.554187px 0px 0px rgba(0, 0, 0, 0.05)'
    },
    cardContent: {
        padding: '15px',
        textAlign: "center",
        width: "100%"
    },
    content: {
        width: '180px',
        minHeight: '84px',
        background: '#E8F5FE',
        border: '1px dashed #0B5D8F',
        boxSizing: 'border-box',
        borderRadius: '4px',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '84px'
    },
    logoContent: {
        width: '180px',
        minHeight: '84px',
        background: '#E8F5FE',
        border: '1px dashed #0B5D8F',
        boxSizing: 'border-box',
        borderRadius: '4px',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    evisitsStartingContent: {
        width: '180px',
        minHeight: '84px',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '84px'
    },
    textAlignRight: {
        textAlign: 'right'
    },
    dropzoneClass: {
        background: '#EEF9FF',
        minHeight: "200px"
    },
    previewGridClasses: {
        marginTop: '10px'
    },
    bigIcon: {
        height: '50px',
        width: '50px',
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '85px'
    },
    restoreDefaultDiv: {
        right: 0,
        position: 'relative',
        top: '32px',
        textAlign: 'right',
        zIndex:1000
    },
    restoreDefault: {
        fontWeight: 600,
        color: '#0F7BBD',
        fontSize: '14px',
        cursor: "pointer",
        top: "-7px",
        "& span": {
            fontSize: "16px",
            textAlign: "right"
        }
    }

}));