import React from "react";
import {Box, Typography, Button} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {TABS} from "views/People/constants";
import {useStyles} from "./styles";
import {NavLink, useParams} from "react-router-dom";
import {MatchParams} from "views/People/types";
import {ButtonTabProps, SummaryTabsProps, Tab} from "./types";

const tabs: Tab[] = [
    {
        name: TABS.SUMMARY,
        label: "SummaryTabs.summary",
        disabled: false,
        path: "",
        exact:true
    },
    {
        name: TABS.ALERTS,
        label: "SummaryTabs.alerts",
        disabled: false,
        path: "alerts"
    },
    {
        name: TABS.FORMS,
        label: "SummaryTabs.forms",
        disabled: false,
        path: "forms"
    },
    {
        name: TABS.APPOINTMENTS,
        label: "SummaryTabs.appointments",
        disabled: false,
        path: "appointments"
    },
    {
        name: TABS.RPM_MONITOR,
        label: "SummaryTabs.rpmLabel",
        disabled: false,
        path: "rpm"
    },
];

const ButtonTab = ({name, label, disabled, isSelected, onTabSelected}: ButtonTabProps) => {
    const classes = useStyles();

    const handleClick = () => {
        if (!disabled && onTabSelected) {
            onTabSelected(name);
        }
    }

    return (
        <Box className={`${classes.tab} ${isSelected?classes.selectedTabButton:''}`} >
            <Button name={name}
                className={classes.tabButton}
                id={"QA_"+label} disabled={disabled}
                onClick={handleClick}
            >
                <Typography>
                    <FormattedMessage id={label} />
                </Typography>
            </Button>
            <div className={classes.arrowDown}/>
        </Box>
    );
}

const SummaryTabs = ({ selectedTab, onTabSelected }: SummaryTabsProps) => {
    const classes = useStyles();
    const {patientId} = useParams<MatchParams>();

    return (
        <Box className={classes.tabsContainer}>
            {tabs.map((tab) => {
                const { exact=false } = tab;
                const Button = <ButtonTab {...tab} key={tab.name} isSelected={selectedTab === tab.name} onTabSelected={onTabSelected}/>;
                
                if (tab.disabled || onTabSelected) {
                    return Button;
                }

                return (
                    <NavLink
                        key={tab.name}
                        activeClassName={classes.selectedTabButton}
                        exact={exact}
                        to={`/provider/people/patients/${patientId}/${tab.path}`}
                    >
                        {Button}
                    </NavLink>
                )
            })}
        </Box>
    );
}

export default SummaryTabs