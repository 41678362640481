/** Default request timeout. */
export const DEFAULT_API_TIMEOUT = 15;
/** API request statuses based on standard HTTP response codes with additional extensions. */
export enum ApiStatusCode {
    /** Initial state of an API request.
     *  Used by frontend only, custom HTTP code
     */
    NOT_SENT = 120,
    /** Set right after sending, it means that request is in progress. No result yet.
     *  Used by frontend only, custom HTTP code
     */
    SENT = 121,
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    /** If client aborts the request. 
     *  Used by frontend only, based on unofficial HTTP code (Client Closed Request)
     */
    ABORTED = 491,
    /** If API request timeout happened.
     *  Used by frontend only, custom HTTP code
     */
    CLIENT_TIMEOUT = 492,
    /** If process of the response fails. The response should be checked, if a transformer should run on that.
     *  Used by frontend only, custom HTTP code
     */
    BAD_RESPONSE = 493,
    SERVER_ERROR = 500,
    /** If unknown error is happening during the fetch (all navigator fetch errors).
     *  Used by frontend only, based on unofficial HTTP code (Network Connect Timeout Error)
     */
    NETWORK_ERROR = 599,
}
export enum HttpMethod {
    DELETE = "DELETE",
    GET = "GET",
    PATCH = "PATCH",
    POST = "POST",
    PUT = "PUT",
}
export interface ApiRequest<TRequest, TResponse, TContent = TResponse> {
    /** The URL what the request is sent to.
     *  This is used to identify an endpoint too. It MUST NOT contain any changing parameter. It can be parameterized
     *  using a colon based syntax (e.g. my/url/with/:id) and params will contains an `id` key. If the url is changing
     *  then it is not guaranteed that all ApiService functionality works as expected.
     */
    url: string;
    defaultContent: TContent;
    flags?: {
        /** Skip appending authentication header. Use for open API endpoints. */
        skipAuth?: boolean;
        /** Abort on new request to the same URL. */
        abortOnNewRequest?: boolean;
    };
    headers?: Record<string, string>;
    method?: HttpMethod;
    params?: TRequest;
    /** Transforms API response to content, if there is a difference between the response and the content what is 
     *  used in the code. This could be the good point to add generated content or temporary API compatibility 
     *  patches.
     * 
     *  If it fails with an Error, then BAD_RESPONSE is thrown by the service.
     */
    responseTransformer?: (response: TResponse) => TContent;
    /** Request timeouts after these seconds. Inifinity switches off this. Default is defined in DEFAULT_API_TIMEOUT.
     */
    timeout?: number;
}

export interface ApiResponse<TResponse, TContent> {
    /** The already parsed/processed response of API, converted to an internal model. */
    content?: TContent;
    /** If something went wrong during the request process. Object can contain the server response. */
    error?: Error | Object | string;
    /** The response received from the API. */
    rawContent?: TResponse | null;
}
export interface IApiPromise<TContent> extends Promise<TContent> {
    /** Is this promise aborted or not? */
    readonly aborted: boolean;
    /** Current status of the API request process behind this ApiPromise. */
    readonly fetchStatus: ApiStatusCode;
    /** If a request is aborted, then chain running is stopped and ApiPromise returns with an aborted response. */
    abort: () => void;
    /** A fetch status change listener can be registrated. It is not guarateed, that the fetch status change is _after_
     *  the content is resolved. So there could be cases, when the status is already OK, but the ApiPromise is not
     *  resolved yet.
     * 
     *  Returns the current status code.
     */
    onFetchStatusChange: (listener: (fetchStatus: ApiStatusCode) => void) => ApiStatusCode;
    /** For internal use only!
     *  Initializing the ApiPromise. It MUST to be called before any further use. ApiService does it.
     */
    initPromise: (executorPromise: Promise<TContent>, onAbort?: () => void) => void;
    /** For internal use only!
     *  This is an internal callback to provide proper status about the process.
     */
    updateFetchStatus: (fetchStatus: ApiStatusCode) => void;
}

export function isApiPromise<TContent>(promise: any): promise is IApiPromise<TContent> {
    return "onFetchStatusChange" in promise && "abort" in promise && typeof promise.abort === "function";
}

export interface ApiCommunicationContext<TRequest, TResponse, TContent> {
    /** This allows to forward abort function of the topmost ApiPromise. This can be used to abort the request even if
     *  the context itself is being processed by another chain function already.
     */
    abort: (() => void) | null;
    request: ApiRequest<TRequest, TResponse, TContent>;
    response: ApiResponse<TResponse, TContent> | null;
    /** Any handler can register an abort listener, what is used to get abort during the process within the handler.
     *  It is recommended to register a handler, if the step, what is running takes a larger amount of time or consumes
     *  more resources.
     */
    registerAbortListener: (listener: () => void) => void;
    /** It contains the status of the associated API request process. */
    status: ApiStatusCode;
    /** This can be used by different handlers to store and pass temporary information forward in the chain.
     *  The content of the storage is temporary, not guarateed it is defined or passed.
     */
    storage: Record<string, string>;
    /** This is for chaining ApiPromise's fetch status updating. This is called by ApiService automatically if the
     *  status changed during a chain step, but it can be called by the step itself, if it is changing more times in
     *  a long running step.
     */
    updateFetchStatus: ((fetchStatus: ApiStatusCode) => void) | null;
}
/** An ApiChainFunction is responsible to manage the content of the ApiCommunicationContext on a specific way.
 *  It retrieves the copy of the context and returns with a modified version. It should not take care of copying the
 *  context, it would happen before the chain function is called.
 *
 *  The order of ApiChainFuntions is defined in ApiService.
 *
 *  An ApiChainFunction has opportunities to influence the response and break the chain.
 *  - Implementing and register aborting listener is recommended. It can be used via `context.registerAbortListener()`.
 *  - Calling `context.updateFetchStatus()` will change the fetchStatus and it is communicated to the requestor
 *    immediately. If the context status is set, then it will be propagated automatically. This is for special purposes
 *    only.
 *
 */
export interface ApiChainFunction<TRequest, TResponse, TContent> {
    (ctx: ApiCommunicationContext<TRequest, TResponse, TContent>): Promise<ApiCommunicationContext<TRequest, TResponse, TContent>>;
}
