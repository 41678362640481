import { ApiCommunicationContext } from "../types";

const appendDomain = async <TRequest, TResponse, TContent>(
    ctx: ApiCommunicationContext<TRequest, TResponse, TContent>
): Promise<ApiCommunicationContext<TRequest, TResponse, TContent>> => {
    const url = ctx.request.url;
    if (url.startsWith("http")) {
        return ctx;
    }
    const serverUrl = window.env.ENABLE_API_URL;
    ctx.request.url = `${serverUrl}/api${url}`;
    return ctx;
};

export default appendDomain;
