import { getAppIdToken } from "authentication/appAuth";

type ObjectType = {
    [key: string]: any
}

const fileExtension = (fileName: string): string => {
    if (!fileName) return "";
    return fileName.substring(fileName.lastIndexOf('.') + 1);
}

const shouldOpenInNewWindow = (fileName: string): boolean => {
    const extension: string = fileExtension(fileName);
    if (extension?.toLowerCase() === "pdf") {
        return true;
    }
    return false;
}

const isFSAccessSupported = 'showSaveFilePicker' in window &&
    (() => {
        try {
            return window.self === window.top;
        } catch {
            return false;
        }
    })();

const getFileTypes = (fileName: string, contentType: string): ObjectType[] => {
    const extension: string = ("." + fileExtension(fileName));
    const description = (() => {
        switch (extension?.toLowerCase()) {
            case ".pdf":
                return "PDF file";
            case ".csv":
                return "Comma separated value file";
            case ".doc":
            case ".docx":
                return "Microsoft Word file";
            case ".xls":
                return "Microsoft Excel file";
            case ".xlsb":
                return "Excel binary workbook file";
            case ".xlsx":
                return "Microsoft Excel Open XML spreadsheet file";
            case ".mp4":
                return "MP4 video";
        }
    })();

    const fileType = {
        description: description,
        accept: {
            [contentType]: extension?.toLowerCase()
        }
    }
    return [fileType];
}

const downloadUsingFSAccess = async (fileName: string, blob: any, contentType: string) => {
    if (isFSAccessSupported) {
        try {
            const windowObj: ObjectType = window;
            const filePicker = windowObj["showSaveFilePicker"];
            const acceptObj: ObjectType = {};
            acceptObj[contentType] = [("." + fileExtension(fileName))];
            const saveFileConfig = {
                suggestedName: fileName,
                types: getFileTypes(fileName, contentType)
            }
            const handle = await filePicker(saveFileConfig);
            const writable = await handle.createWritable();
            await writable.write(blob);
            await writable.close();
            return;
        } catch (error: any) {
            if ((error.name === 'NotAllowedError') || (error.name === 'SecurityError')) {
                console.log("Browser does not allow prompting user where to save file before downloading. Fallback to use default download method.");
                downloadUsingFallback(fileName, blob);
            } else if (error.name !== 'AbortError') {
                return;
            }
        }
    }
}

const downloadUsingFallback = (fileName: string, blob: any) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export const handleDownload = async (endpointURL: string, fileRequest: any, onProgressUpdate?: (progress: number) => void) => {
    const fileName = fileRequest.fileName;
    const openInNewTab = shouldOpenInNewWindow(fileName);
    const axios = require('axios');
    const { ENABLE_API_URL } = (window as any).env
    const axiosURL = ENABLE_API_URL + "/api" + endpointURL;

    await axios.post(axiosURL, fileRequest,
        {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf,video/mp4',
                'Authorization': 'Bearer ' + getAppIdToken()
            },
            onDownloadProgress: (progressEvent: { total: any; loaded: any; }) => {
                const total = progressEvent.total;
                const current = progressEvent.loaded;
                const percentCompleted = Math.floor(current / total * 100);
                if (onProgressUpdate) {
                    onProgressUpdate(percentCompleted);
                }
            }
        }).then(async (response: { headers: any, data: any; }) => {
            if (response?.data) {
                const responseContentType = await (response?.headers["content-type"]);
                const contentType: string = responseContentType.split(";")[0] || ("application/pdf");
                const blobData = new Blob([response.data], { type: contentType });
                const url = window.URL.createObjectURL(blobData);
                if (openInNewTab) {
                    window.open(url, '_blank', 'noopener,noreferrer');
                } else if (isFSAccessSupported) {
                    await downloadUsingFSAccess(fileName, blobData, contentType);
                } else {
                    downloadUsingFallback(fileName, blobData);
                }
            }
        });
}