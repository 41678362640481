import React from  "react";
import MaskedInput from 'react-text-mask';
export default function InputMasked(props: any) {

    const { ref, mask, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref ? ref.inputElement : null}
            mask={mask}
            placeholderChar={'\u2000'}
            keepCharPositions
            showMask
        />
    );
}
export function PhoneMask(props: any) {
    return (<InputMasked {...props} mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />)
}
export function DateMask(props: any) {
    return (<InputMasked {...props} mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />)
}
export function TinMask(props: any) {
    return (<InputMasked {...props} mask={[/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} />)
}