import { makeStyles } from "@mui/styles";
import { SIZING } from "admin/theme";

export const useStyles = makeStyles(() => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        borderRadius: '24px',
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.12), inset 0px 1px 1px rgba(0, 0, 0, 0.04)'
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    innerWrapper: {
        padding: `${SIZING.scale500} ${SIZING.scale500} 0 ${SIZING.scale800}`,
        height: "100%",
        overflow: "auto",
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    categoryTitle: {
        color: "rgba(126, 144, 179, 0.8)"
    },
    category: {
        marginBottom: SIZING.scale500
    },

    paper: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableFooterRow: {
        background: '#EDEDEF',
        border: '1px solid #E4E4E6',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px -1px 0px #E7E7ED',
        borderRadius: '0px 0px 8px 8px'
    },
    footerCaption: {
        fontSize: '12px',
        fontWeight: 'normal'
    }

}));