import { Box, Grid, Typography, Button, IconButton, Card, styled, Tooltip } from "@mui/material";
import { ReactComponent as AltaisStar } from 'icon-library/SVG/Altais-Star.svg';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useStyles } from "./styles";
import { useState } from "react";
import { withStyles } from "@mui/styles";
import { LicenseWidgetProps } from "./types";
import { useIntl } from "react-intl";
import { createSvgIcon } from "utils";
import { ReactComponent as ChevronUp } from "icon-library/SVG/Chevron_Up.svg";
import { ReactComponent as ChevronDown } from "icon-library/SVG/Chevron_Down.svg";
const AltaisStarIcon = createSvgIcon(AltaisStar);
export const LicenseWidgets = ({ detailedView = false, products, showTotalBar = true }: LicenseWidgetProps) => {
    const classes = useStyles();
    const intl = useIntl()
    const ChevronUpIcon = createSvgIcon(ChevronUp);
    const ChevronDownIcon = createSvgIcon(ChevronDown);
    const [showAllLicenses, setShowAllLicenses] = useState(detailedView)
    const handleShowAllLicenses = () => {
        setShowAllLicenses(!showAllLicenses)
    }
    const BarChart = styled(LinearProgress)(({ theme }) => ({
        borderRadius: 0,
        marginTop: '0px',
        [`&.${linearProgressClasses.colorPrimary}`]: {
            background: '#D3D3D6'
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 0,
            backgroundColor: theme.palette.mode === 'light' ? '#139AED' : '#D3D3D6',
        },
    }));
    const LicenseTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#F6F6F7',
            color: '#393948',
        },
        arrow: {
            color: "#F6F6F7"
        },
    }))(Tooltip);

    const Licenses = ({ data, barHeight = 4, barMargin = 16, iconSize = 24 }: any) => {
        const normalise = (value: any, MAX: any) => ((value - 1) * 100) / (MAX - 1);
        const isUnlimited = data?.product?.unlimited || data?.unlimited
        return (<>
            <Grid spacing={1} container>
                <Grid xs item>
                    <LicenseTooltip arrow title={data?.product?.description} placement="right-start">
                        <Typography color="textPrimary" className={classes.licenseName}>
                            <IconButton classes={{ root: classes.iconBtn }} >
                                <AltaisStarIcon size={iconSize} fill="#0F7BBD" />
                            </IconButton>
                            {data?.product?.name || data?.name}
                        </Typography>
                    </LicenseTooltip>
                </Grid>
                <Grid xs item textAlign="right">
                    <Typography color="textPrimary" className={classes.licenseCount}>
                        {(data?.product?.unlimited || data?.unlimited) ? "Unlimited" : data?.licenseCount}
                    </Typography>
                </Grid>
            </Grid>
            <Grid spacing={1} container>
                <Grid xs item>
                    <BarChart variant="determinate" value={(data?.product?.unlimited || data?.unlimited) ? 0 : normalise(data?.usedLicenseCount, data?.licenseCount)} style={{
                        height: barHeight, margin: `${barMargin}px 0px`
                    }} />
                </Grid>
            </Grid>
            {!isUnlimited && <Grid spacing={1} container>
                <Grid xs item>
                    <Typography color="textPrimary" className={classes.allocatedVsAvailable}>
                        {data?.usedLicenseCount ? `${data?.usedLicenseCount} Allocated` : ""}
                    </Typography>
                </Grid>
                <Grid xs item textAlign="right">
                    <Typography color="textPrimary" className={classes.allocatedVsAvailable}>
                        {!isUnlimited && data?.zeroAvalilableProduct && < LicenseTooltip arrow title={intl.formatMessage({ id: "LicenseWidgets.NoLicensesLeftTooltipText" }, { productName: data?.zeroAvalilableProduct })} placement="left-start">
                            <Box component="span" className={classes.noLicensesLeft}>
                            </Box>
                        </LicenseTooltip>}
                        {data?.availableLicenseCount ? `${data?.licenseCount - data?.usedLicenseCount} Available` : ""}
                    </Typography>
                </Grid>
            </Grid>}
        </>)
    }
    const groupProducts = (data: any, pid = null) => {
        if (!Array.isArray(data)) return null
        return data?.reduce((r: any, e: any) => {
            if (e?.product?.parentId === pid || e?.parentId === pid) {
                const obj = { ...e };
                const children = groupProducts(data, (e?.product?.id || e?.id));
                if (children.length) obj.children = children;
                r.push(obj);
            }
            return r;
        }, [])
    }
    const groupTotals = (data: any, key: string) => {
        if (!Array.isArray(data)) return null;
        return data.reduce((result, dataItem) => {
            return result + dataItem[key];
        }, 0);
    };
    const groupNotAvailableProduct = (data: any) => {
        let result = ''
        if (!Array.isArray(data)) return ''
        const p = data?.find((d: any) => !(d?.product?.unlimited || d?.unlimited) && (d?.licenseCount - d?.usedLicenseCount) === 0)
        if (p) {
            result = p.product ? p.product.name : p.name
        }
        return result
    }
    const groupedProducts = groupProducts(products).filter((p: any) => !(p?.parentId || p?.product?.parentId))
    const totalProducts = {
        product: {
            name: "Total Licenses",
            description: "Total Licenses",
            unlimited: false
        },
        licenseCount: groupTotals(products, 'licenseCount'),
        usedLicenseCount: groupTotals(products, 'usedLicenseCount'),
        availableLicenseCount: groupTotals(products, 'availableLicenseCount'),
        zeroAvalilableProduct: groupNotAvailableProduct(products)
    }
    return (<>
        <Grid container className={classes.productsText}>
            <Grid item>
                <Typography color="textPrimary" className={classes.heading} style={{ fontSize: '24px' }}>
                    {intl.formatMessage({ id: "OrganizationDetail.Label.AvailableLicenses" })}</Typography>
            </Grid>
            <Grid item xs>
                <Button disableRipple disableFocusRipple color="primary" style={{ float: 'right', fontSize: '14px', background: 'transparent' }} onClick={handleShowAllLicenses}
                    endIcon={showAllLicenses ? <ChevronUpIcon /> : <ChevronDownIcon />}>
                    {showAllLicenses ? "Hide Licenses" : 'Show All Licenses'}
                </Button>
            </Grid>
        </Grid>
        <Card className={classes.totalLicenses}>
            {showTotalBar && <Box>
                <Licenses barHeight={12} iconSize={32} barMargin={16} data={totalProducts} />
            </Box>}
            {showAllLicenses && <Box className={classes.subLicenses}>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Card className={classes.subCard}>
                            {groupedProducts && groupedProducts.filter((p: any) => p.children)?.map((license: any) => <>
                                <Licenses data={license} barHeight={8} barMargin={8} />
                                <Box className={classes.subLicenses}>
                                    {license?.children && license?.children.map((sublicense: any) =>
                                        <Card className={classes.dynamicCard}>
                                            <Licenses data={sublicense} barHeight={4} barMargin={6} />
                                        </Card>
                                    )}
                                </Box>
                            </>)}
                        </Card>
                    </Grid>
                    <Grid item xs>
                        {groupedProducts && groupedProducts?.filter((p: any) => !p?.children)?.map((license: any) =>
                            <Card className={classes.dynamicListCard}>
                                <Licenses data={license} barHeight={8} barMargin={8} />
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </Box>}
        </Card>
    </>)
}