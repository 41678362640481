import React, { useEffect, useState } from "react";
import { Box, Drawer as MaterialDrawer, List, ListItem } from "@mui/material";
import { useSelector } from "react-redux";

import { COLORS } from "theme";
import { createSvgIcon } from "utils";
import { ReactComponent as ChevronLeftSvg } from "icon-library/SVG/Chevron_Left.svg";
import { ReactComponent as ChevronRightSvg } from "icon-library/SVG/Chevron_Right.svg";
import { ReactComponent as BellSvg } from "icon-library/SVG/Bell.svg";
import EmptyContentMessage from "common/components/EmptyContentMessage/EmptyContentMessage";
import { useStyles } from "./styles";
import { AppReduxStore } from "store/reducerTypes";
import { NotificationItem } from "store/notification-types";
import NotificationByType from "./Notifications/notification-by-type";
import useNotificationUpdate from "./use-notification-update";
import { bellSound } from "./utils";

const ChevronLeft = createSvgIcon(ChevronLeftSvg);
const ChevronRight = createSvgIcon(ChevronRightSvg);
const Bell = createSvgIcon(BellSvg);

// NotificationDrawer displays the list of notifications to the user along with a bell sounds and animation to show the
// user that a new notification has been triggered
const NotificationDrawer = () => {
    const classes = useStyles();
    const { items } = useSelector((state: AppReduxStore) => state.notification);
    const [notifications, setNotifications] = useState(items);
    const [open, toggleDrawer] = useState(false);
    const [ringing, setRinging] = useState(false);
    const [ringTimeoutId, setRingTimeoutId] = useState<ReturnType<typeof setTimeout>>();
    const drawerClass = open ? classes.drawerOpen : classes.drawerClose;
    const [showRing, updateShowRingStatus] = useState(false);
    const { setNotificationReadStatusById } = useNotificationUpdate();

    // use local state to compare new set of notifications with old set
    // TODO Notifications should be updated with "read" flag to avoid this check
    useEffect(() => {
        if (items.length > notifications.length) {
            setRinging(true);
            if (bellSound.paused) {
                bellSound.play();
            } else {
                bellSound.currentTime = 0;
            }

            updateShowRingStatus(true);

            setRingTimeoutId(
                setTimeout(() => {
                    setRinging(false);
                    bellSound.pause();
                }, 4000)
            );
        }
        setNotifications(items);
        return () => {
            bellSound.pause();
            clearTimeout(ringTimeoutId as ReturnType<typeof setTimeout>);
        };
    }, [items, ringTimeoutId, notifications.length]);

    useEffect(() => {
        updateShowRingStatus(false);
    }, [open]);

    const updateReadStatus = (id: string) => {
        toggleDrawer(!open);
        setNotificationReadStatusById(id);
    };

    const DrawerToggle = () => {
        const toggleClasses = showRing ? classes.notification : classes.toggle;
        const drawerIcon = showRing ? (
            <Bell id="QA_notification_bell" className={`${classes.drawerToggleIcon} ${classes.bellIcon}`} />
        ) : open ? (
            <ChevronRight id="QA_notification_collapse" className={classes.drawerToggleIcon} />
        ) : (
            <ChevronLeft id="QA_notification_open" className={classes.drawerToggleIcon} />
        );
        return (
            <Box id="QA_notification_drawer" className={classes.toggleWrapper}>
                <Box
                    id="QA_notification_drawer_icon"
                    className={`${classes.drawerToggle} ${toggleClasses}`}
                    onClick={() => toggleDrawer(!open)}
                >
                    {ringing && showRing ? (
                        <>
                            <Box className={classes.ring} style={{ animationDelay: "0.2s" }} />
                            <Box className={classes.ring} style={{ animationDelay: "0.32s" }} />
                            <Box className={classes.ring} style={{ animationDelay: "0.44s" }} />
                            <Box className={classes.ring} style={{ animationDelay: "0.56s" }} />
                        </>
                    ) : null}
                    {drawerIcon}
                </Box>
            </Box>
        );
    };

    const renderItems = () => {
        return (
            <List dense className={classes.list}>
                {notifications.map((item: NotificationItem) => {
                    return (
                        <ListItem
                            id="QA_notificaion_items"
                            onClick={() => updateReadStatus(item.notificationRecipientId)}
                            key={item.notificationRecipientId}
                            className={classes.listItem}
                            disableGutters
                        >
                            <NotificationByType {...item}/>
                        </ListItem>
                    );
                })}
            </List>
        );
    };

    return (
        <Box position="absolute">
            <MaterialDrawer
                className={`${classes.drawerWrapper} ${drawerClass}`}
                classes={{ paper: `${classes.drawer} ${drawerClass}` }}
                variant="permanent"
                anchor="right"
            >
                <DrawerToggle />
                {notifications && notifications.length !== 0 ? (
                    renderItems()
                ) : (
                    <EmptyContentMessage
                        Icon={Bell}
                        iconColor={COLORS.LGRAY}
                        subtitle="Drawer.message.noNotifications"
                    />
                )}
            </MaterialDrawer>
        </Box>
    );
};

export default NotificationDrawer;
