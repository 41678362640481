import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    paper: {
        width: '100%',
        marginTop: '16px'
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableFooterRow: {
        background: '#EDEDEF',
        border: '1px solid #E4E4E6',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px -1px 0px #E7E7ED',
        borderRadius: '0px 0px 8px 8px',
        position: "sticky",
        bottom: 0,
        zIndex: 2
    },
    footerCaption: {
        fontSize: '12px',
        fontWeight: 'normal'
    },
    paginationSpacer: {
        flex: 0
    },
    footerIcon: {
        right: -15
    },
    stickyHeader: {
        overflowX: "initial",
        zIndex: 2
    },
    stickyLastColumn: {
        position: "sticky",
        zIndex: 1,
        right: 0,
        background: "#ffffff"
    },
    toolTip: {
        marginLeft: '20px'
    },
    formVersion: {
        width: '100px'
    },
    formStatus: {
        width: '300px'
    },
    title: {
        color: '#0F7BBD', 
        fontSize: '14px', 
        fontWeight: 600
    },
    row: {
        paddingLeft: '48px'
    },
    icon: {
        padding: '10px'
    },
    sort1: {
        paddingLeft: '48px'
    },
    sort2: {
        width: '100px'
    },
    comments: {
        display: 'flex', 
        flexDirection: 'row'
    }
}));