import React from "react";
import {useStyles} from "./styles";
import {Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import ActionButtons from "../ActionButtons/ActionButtons";

 const CallFailed = ({ handleCallback,name }: { handleCallback:()=> void,name:string  }) => {

    const classes = useStyles()

    const firstName = name.split(" ")[0] || "";

    return (
        <div className={`${classes.callFailedWrapper}`}>
            <Typography className={classes.evisitFailedTitle} >  <FormattedMessage id="ActiveZoomMeeting.text.unableToCall" /></Typography>
            <Typography className={`${classes.evisitFailedDesc} w-75`} >  <FormattedMessage id="ActiveZoomMeeting.text.somethingWentWrong" values={{name:firstName}} /></Typography>
            <Typography className={classes.evisitFailedDesc} >  <FormattedMessage id="ActiveZoomMeeting.status.callEndedDesc" /></Typography>
            <ActionButtons  handleCallback={handleCallback}/>        
        </div>
    )
}

export default CallFailed
