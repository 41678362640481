import createReducer from '../createReducer'
import {WaitingRoom} from '../reducerTypes'

const initialState: WaitingRoom = {
    participants:[],
    currentMeetingDetails:null,
    currentMeetingParticipants:[],
    previousMeetingDetails:null,
    meetingPingDetails:{},
    providerFilterIds:null
}

export default createReducer(initialState, {
    'SET_WAITING_ROOM_PATIENT_DETAILS': (state: WaitingRoom, payload: WaitingRoom) => {
        return {
            ...state,
            participants:payload,
        }
    },
    'SET_CURRENT_WAITING_ROOM_DETAILS': (state: WaitingRoom, payload: any) => {
        return {
            ...state,
            currentMeetingDetails:payload,
            currentMeetingParticipants:[]
        }
    },
    'SET_CURRENT_WAITING_ROOM_PARTICIPANTS': (state: WaitingRoom, payload: string[]) => {
        return {
            ...state,
            currentMeetingParticipants:payload,
        }
    },
    'SET_PREVIOUS_MEETING_DETAILS': (state: WaitingRoom, payload: any) => {
        return {
            ...state,
            previousMeetingDetails:payload,
        }
    },
    'UPDATE_CURRENT_MEETING_MERGED_PARTICIPANTS': (state: WaitingRoom, payload: any) => {
        return {
            ...state,
            currentMeetingDetails:{
                ...state.currentMeetingDetails,
                mergedParticipantsInfo:payload
            },
        }
    },
    'UPDATE_MEETING_PING_DETAILS': (state: WaitingRoom, payload: any) => {
        return {
            ...state,
            meetingPingDetails:payload,
        }
    },
    'UPDATE_PROVIDER_FILTER_DETAILS': (state: WaitingRoom, payload: any) => {
        return {
            ...state,
            providerFilterIds:payload,
        }
    },
    'SYS_NRU_EVISIT_END': (state: WaitingRoom, payload: any) => {
        return {
            ...state,
            currentMeetingParticipants:[],
            currentMeetingDetails:null,
            ...((payload===state.previousMeetingDetails?.inviteToken)?{ previousMeetingDetails:null}:'')
        }
    },
})
