import React, { useState, useCallback, useEffect } from "react";
import MultiSelectPopoverInput from "components/MultiSelectPopoverInput/MultiSelectPopoverInput";
import MultiSelectItem from "components/MultiSelectPopoverInput/MultiSelectItem";
import { debounce } from "underscore";
import { useIntl } from "react-intl";
import { AppReduxStore } from "store/reducerTypes";
import { useDispatch, useSelector } from "react-redux";
import { searchRecipientsBySearchString } from "store/actions";
import { ListItem } from "components/MultiSelectPopoverInput/types";
import { setError } from "store/actions/error";
import { ProviderSearchProps } from "./types";
// TODO need to implement single select option
const ProviderSearch = ({ onChange, placeHolder, title, themeColor = 'evisit', value }: ProviderSearchProps) => {
    const [searchText, updateSearchText] = useState('')
    const [searchLoader, updateSearchLoader] = useState<Boolean>(false);
    const [searchedRecipients, updateSearchedRecipients] = useState<Array<ListItem>>([]);
    const { username } = useSelector(((state: AppReduxStore) => state.user))

    const dispatch = useDispatch()
    const intl = useIntl()

    const search = useCallback(debounce((searchStr: string, username: string) => {
        updateSearchLoader(true)
        searchRecipientsBySearchString(searchStr, username, 'CARETEAM').then((data: any) => {
            updateSearchLoader(false)
            updateSearchedRecipients(data)
        }).catch(e => {
            if (e.status !== 400) {
                dispatch(setError("Error.message.fetchingParticipants"))
            }
        })
    }, 250), [])

    useEffect(() => {
        const searchString = searchText.trim();
        searchString && search(searchString, username)
    }, [search, searchText, username])

    useEffect(() => {
        updateSearchText('')
    }, [value])

    const renderItem = (item: any, changePinnedItems: any, pinnedItemsDetails: any, isMultiSelect: boolean, searchString: string) => (
        <MultiSelectItem
            item={item}
            selected={isMultiSelect ? pinnedItemsDetails.findIndex((result: any) => result.twilioUsername === item.twilioUsername) !== -1 : !!pinnedItemsDetails && pinnedItemsDetails.twilioUsername === item.twilioUsername}
            key={item.twilioUsername}
            onChange={() => changePinnedItems(item)}
            highlightText={searchString}
            themeColor='evisit'
        />
    );

    const handleSearch = ({ target: { value } }: any) => {
        updateSearchText(value)
    }

    const updatePinnedDetails = (providerDetails: any) => {
        onChange(providerDetails)
    }

    return <MultiSelectPopoverInput
        data={searchedRecipients}
        value={searchText}
        dropDownPosition="top"
        onChange={handleSearch}
        placeholder={intl.formatMessage({
            id: placeHolder || "ActiveZoomMeeting.inviteColleague.placeholder",
        })}
        title={intl.formatMessage({
            id: title || "ActiveZoomMeeting.inviteColleague.title",
        })}
        pinnedItems={value}
        setPinnedItems={updatePinnedDetails}
        renderItem={renderItem}
        loader={searchLoader}
        themeColor={themeColor}
    />

}

export default ProviderSearch