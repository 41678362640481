import { useCallback, useEffect, MouseEvent as ReactMouseEvent } from "react";

function isClickedInElement(referenceElement: Element | null, targetElement: Element | null): boolean {
    if (targetElement === null || referenceElement === null) {
        return false;
    }
    if (targetElement === referenceElement) {
        return true;
    }
    return referenceElement.contains(targetElement);
}

export function useOutsideClickHandler(
    /** The elements listed here are taken as inner elements (including their childrens, of course).
     *  List items being null or undefined are supported.
     */
    insideElements: (Element | null)[],
    /** Is the component using this hook is visible or hidden?
     *  This click handler is active for visible elements only.
     */
    visible: boolean,
    onOutsideClick: () => void
) {
    const onClickOutside = useCallback((e: MouseEvent | ReactMouseEvent) => {
        const domEvent: MouseEvent = (e as ReactMouseEvent)?.nativeEvent || e; // Get the original event from React's synthetic event
        const isInnerClick = insideElements.filter(Boolean).some((element) => {
            return isClickedInElement(element, domEvent.target as Element | null);
        });
        if (!isInnerClick) {
            onOutsideClick();
        }
    }, [insideElements, onOutsideClick]);


    useEffect(() => {
        if (visible) {
            document.addEventListener('mousedown', onClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        }
    }, [visible, onClickOutside]);
}
