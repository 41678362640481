import { Box, Input } from "@mui/material";
import { SIZING } from "theme";
import { useIntl } from "react-intl";
import { useStyles } from "../styles";
import React from "react";

interface SearchHeaderProps {
    searchTerm: string;
    onSearchTermChange: (content: string) => void;
};

const SearchHeader: React.FC<SearchHeaderProps> = ({ searchTerm, onSearchTermChange }) => {
    const classes = useStyles();
    const intl = useIntl();

    // TODO: Three boxes? One or zero would be enough.
    return (
        <Box className={classes.searchHeader}>
            <Box position="relative" height="100%">
                <Box display="flex" alignItems="center" marginBottom={SIZING.scale400}>
                    <Input id="QA_findPatient"
                        disableUnderline
                        placeholder={intl.formatMessage({ id: "People.search.input" })}
                        onChange={(e: any) => onSearchTermChange(e.target.value)}
                        value={searchTerm}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default SearchHeader;
