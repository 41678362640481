import { ApiStatusCode, HttpMethod } from "lib/api/types";
import { CareTeam } from "../types";
import { useApi } from "lib/api/api-service-hooks";

const useCreateCareTeam = () => {
    const { fetchInfo, request } = useApi<CareTeam, CareTeam | null>({
        defaultContent: null,
        method: HttpMethod.POST,
        url: "/provider/v1/careteam/teams",
    });

    const handleSubmit = (careTeamData: CareTeam): Promise<CareTeam | null> => {
        if (careTeamData) {
            const apiPromise = request(careTeamData);
            return apiPromise.catch((errorContent) => {
                if (typeof errorContent === "object") {
                    throw Object.assign(errorContent, {
                        httpStatus: apiPromise.fetchStatus,
                    });
                }
                throw errorContent;
            });
        }
        return Promise.reject();
    };

    return {
        submit: handleSubmit,
        submitting: fetchInfo.state === ApiStatusCode.SENT,
    };
};

export default useCreateCareTeam;
