import Typography from '@mui/material/Typography';
import { getAppCurrentProfileId } from "authentication/appAuth";
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as BrownTolandPhysicians } from "icon-library/SVG/Book.svg";
import { ReactComponent as DisputeResolution } from "icon-library/SVG/Caduceus.svg";
import { ReactComponent as FeeSchedules } from "icon-library/SVG/Circles_3x3.svg";
import { ReactComponent as ClaimsBillingResources } from "icon-library/SVG/File-Light_Blank-Multiple.svg";
import { useEnabledPermissions } from "lib/security/permission-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import PageImage from '../assests/images/pep-billing-resources.jpg';
import { ClickableCard } from '../components/clickable-card';
import { useStyles } from "./styles";

export const PEPBillingClaimsResources = ({ onSelect: handleSelection }: { onSelect?: (menuItem: string) => void }) => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueError } = useUserNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const grantedPermissions = useEnabledPermissions();
    const licenseOptionName = "BILLING_SCHEDULE"
    const requiredPermissionsList = [licenseOptionName];
    const hasPermission = () => {
        return requiredPermissionsList.every((permission: any) => grantedPermissions.includes(permission));
    };

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("pep.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    const config_left = [{
        name: `Fee Schedules`,
        description: "Find fee schedules for BTP contracted providers. Fee schedules require authorization to view.",
        action: {
            id: "fee_schedules"
        },
        icon: FeeSchedules,
        enabled: hasPermission()
    },
    {
        name: `Tools and Resources`,
        description: "Tools and reference materials to help you successfully submit claims for reimbursement.",
        action: {
            id: "pep-claims-billing-resources"
        },
        icon: ClaimsBillingResources,
        enabled: true
    }];

    const config_right = [{
        name: `Dispute Resolution Information`,
        description: "Learn about dispute resolution guidelines and procedures, and find related forms.",
        action: {
            id: "pep-dispute-resolution-information"
        },
        icon: DisputeResolution,
        enabled: true
    },
    {
        name: `Brown & Toland Physicians Policies`,
        action: {
            id: "brown_toland_physicians_policies"
        },
        icon: BrownTolandPhysicians,
        enabled: false
    }];

    return (
        isLoading ? <Spinner /> : (
            <div id="billing_claims_resources_row" className={classes.pep_billing_claims_resources_row}>
                <div id="billing_claims_resources_container" className={classes.pep_billing_claims_resources_container}>
                    <div id="billing_claims_resources_top" className={classes.pep_billing_claims_resources_top}>
                        <div id="billing_claims_resources_banner" className={classes.pep_billing_claims_resources_banner}>
                            <Typography id="billing_claims_resources_banner_top" className={classes.pep_billing_claims_resources_banner_top}>
                                {`Billing and Claims`}
                            </Typography>
                        </div>
                        <div style={{ width: '100%', height: '360px', marginBottom: '20px' }}>
                            <img src={PageImage} alt='Billing & Claims Resources Logo' />
                        </div>
                        <Typography id="billing_claims_resources_description" className={classes.pep_billing_claims_resources_description}>
                            {`We serve as the single point of service for claims. Here you will find the tools and resources you need to help manage your practice’s submission of claims and receipt of payments. Search this section for fee schedules, claims information and links to billing tools.`}
                        </Typography>
                    </div>
                    <div id="billing_claims_resources_middle" style={{ display: 'flex', marginTop: '20px' }}>
                        <div id="billing_claims_resources_left" className={classes.pep_billing_claims_resources_left}>
                            {config_left.map((card, index: number) => {
                                return <ClickableCard key={`clickable_card_left_${index}`} config={card} onSelect={handleSelection} />
                            })}
                        </div>
                        <div id="billing_claims_resources_right" className={classes.pep_billing_claims_resources_right}>
                            {config_right.map((card, index: number) => {
                                return <ClickableCard key={`clickable_card_right_${index}`} config={card} onSelect={handleSelection} />
                            })}
                        </div>
                    </div>


                </div>
            </div>)
    )
}