import { ValueOf, NullableProperties } from "lib/util-types";
import { IPrefixNamed } from "./types";

/**
 * To make the naming function more flexible,
 * this type allows any field to be either undefined or null
 */
export type PossiblyNamed = Partial<NullableProperties<IPrefixNamed>>;

export const FormatNameVariants = {
    SIMPLE: "simple",
    BY_LAST_NAME: "by_last_name",
    BY_LAST_NAME_UPPERCASE: "by_last_name_uppercase",
} as const;

export type FormatNameVariant = ValueOf<typeof FormatNameVariants>;

export const simpleConcatName = (parts: (string | null | undefined)[]): string =>
    parts.filter((s) => Boolean(s?.trim())).join(" ");

const formatSimple = (named: PossiblyNamed): string =>
    simpleConcatName([named.prefix, named.firstName, named.middleName, named.lastName]);

const formatByLastName = (named: PossiblyNamed): string => {
    const lastName = named.lastName?.trim();
    const hasComma = lastName && (named.middleName || named.firstName);
    const lastNameAndComma = hasComma ? `${named.lastName},` : named.lastName;

    return simpleConcatName([named.prefix, lastNameAndComma, named.firstName, named.middleName]);
};

const formatByLastNameUppercase = (named: PossiblyNamed): string => {
    const lastName = named.lastName ? named.lastName.toLocaleUpperCase() : null;

    return formatByLastName({
        ...named,
        lastName,
    });
};
export const formatName = (named: PossiblyNamed, variant: FormatNameVariant = FormatNameVariants.SIMPLE): string => {
    switch (variant) {
        case FormatNameVariants.BY_LAST_NAME:
            return formatByLastName(named);
        case FormatNameVariants.BY_LAST_NAME_UPPERCASE:
            return formatByLastNameUppercase(named);
        default:
            return formatSimple(named);
    }
};
