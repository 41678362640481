import React from "react";
import {useStyles} from "./styles";
import {Box} from "@mui/material";
import {FormattedMessage, FormattedDate} from "react-intl";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import {formatPhoneNumber} from "utils";

const Overview = ({ selectedPatient }: any) => {
    const classes = useStyles();
    const { lastName, firstName, age, dateOfBirth, genderCode, address, city, state, zipcode, cellPhoneNumber, primaryLanguage, emailAddress } = selectedPatient;

    return (
        <Box className={classes.overviewContainer} id="QA_Overview">
            <CustomTypography className={classes.patientSummaryOverviewTitle} variant="patientSummaryOverviewTitle">
                <FormattedMessage id="People.summary.overview" />
            </CustomTypography>
            <Box className={classes.overviewRow} id="QA_Overview_Name">
                <CustomTypography className={classes.patientSummaryOverviewLabel} variant="patientSummaryOverviewLabel">
                    <FormattedMessage id="People.summary.label.name" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryOverviewValue} variant="patientSummaryOverviewValue">
                {lastName?.toUpperCase()}, {firstName}
                </CustomTypography>
            </Box>
            <Box className={classes.overviewRow} id="QA_Overview_Age">
                <CustomTypography className={classes.patientSummaryOverviewLabel} variant="patientSummaryOverviewLabel">
                    <FormattedMessage id="People.summary.label.dobAge" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryOverviewValue} variant="patientSummaryOverviewValue">
                <FormattedDate value={new Date(dateOfBirth)} year="numeric" month="short" day="numeric" /> / {age}
                </CustomTypography>
            </Box>
            <Box className={classes.overviewRow} id="QA_Overview_Gender_Code">
                <CustomTypography className={classes.patientSummaryOverviewLabel} variant="patientSummaryOverviewLabel">
                    <FormattedMessage id="People.summary.label.genderCode" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryOverviewValue} variant="patientSummaryOverviewValue">
                {genderCode}
                </CustomTypography>
            </Box>
            <Box className={classes.overviewRow} id="QA_Overview_Address">
                <CustomTypography className={classes.patientSummaryOverviewLabel} variant="patientSummaryOverviewLabel">
                    <FormattedMessage id="People.summary.label.address" />
                </CustomTypography>
                {address &&
                <CustomTypography className={classes.patientSummaryOverviewValue} variant="patientSummaryOverviewValue">
                {address}, {city}, {state} {zipcode}
                </CustomTypography>
                }
            </Box>
            <Box className={classes.overviewRow} id="QA_Overview_Phone">
                <CustomTypography className={classes.patientSummaryOverviewLabel} variant="patientSummaryOverviewLabel">
                    <FormattedMessage id="People.summary.label.phone" />
                </CustomTypography>
                <Box className={classes.nestedRow}>
                <CustomTypography className={classes.patientSummaryOverviewSecondary} variant="patientSummaryOverviewSecondary">
                    Prefered
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryOverviewValue} variant="patientSummaryOverviewValue">
                {formatPhoneNumber(cellPhoneNumber)}
                </CustomTypography>
                </Box>
            </Box>
            <Box className={classes.overviewRow} id="QA_Overview_Email_Address">
                <CustomTypography className={classes.patientSummaryOverviewLabel} variant="patientSummaryOverviewLabel">
                    <FormattedMessage id="People.summary.label.emailAddress" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryOverviewValue} variant="patientSummaryOverviewValue">
                {emailAddress}
                </CustomTypography>
            </Box>
            <Box className={classes.overviewRow} id="QA_Overview_Language">
                <CustomTypography className={classes.patientSummaryOverviewLabel} variant="patientSummaryOverviewLabel">
                    <FormattedMessage id="People.summary.label.language" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryOverviewValue} variant="patientSummaryOverviewValue">
                    { primaryLanguage ? primaryLanguage : 'English' }
                </CustomTypography>
            </Box>
            <Box className={classes.overviewRow} id="QA_Overview_Deceased">
                <CustomTypography className={classes.patientSummaryOverviewLabel} variant="patientSummaryOverviewLabel">
                    <FormattedMessage id="People.summary.label.deceased" />
                </CustomTypography>
                <CustomTypography className={classes.patientSummaryOverviewValue} variant="patientSummaryOverviewValue">
                No
                </CustomTypography>
            </Box>
        </Box>
    )
}

export default Overview