import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() =>({
    root: {
        display: 'flex',
        backgroundColor: COLORS.PATIENT_DETAIL_LABEL_TEXT,
        margin: `${SIZING.scale50} ${SIZING.scale50}`,
        borderRadius: BORDER_RADIUS.LARGE,
        alignItems: 'center',
        padding: `${SIZING.scale100} ${SIZING.scale100}`,
        minWidth: 'auto',
        maxHeight: SIZING.scale800
    },
    icon: {
        color: COLORS.INPUT_BG,
        height: SIZING.scale300
    },
    text: {
        color: COLORS.INPUT_BG,
    },
}));