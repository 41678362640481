import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AppReduxStore } from "admin/store/reducerTypes"
import { showNotification } from './snackbar'
import { makeApiCall } from 'admin/common/utils'

export const getOrganizations = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return makeApiCall(dispatch, "GET", `/admin/v1/organizations`, {},
            "OrganizationListing.Actions.FetchOrganizations", "OrganizationListing.Actions.FetchOrganizationDesc").then((payload) => {
                dispatch({ type: 'SET_ORGANIZATIONS', payload: { organizations: payload } })
            }).catch(err => {
                dispatch({ type: 'SET_ORGANIZATIONS', payload: { organizations: [] } })
                dispatch(showNotification("Get Organizations", "error", String(err && err.message)))
            });
    }
}
export const getChildOrganizations = (parentOrganizationId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return makeApiCall(dispatch, "GET", `/admin/v1/organization/hierarchy/${parentOrganizationId}`, {},
            "OrganizationListing.Actions.FetchOrganizations", "OrganizationListing.Actions.FetchOrganizationDesc").then((payload) => {
                dispatch({ type: 'SET_CHILD_ORGANIZATIONS', payload: { childOrganizations: payload } })
            }).catch(err => {
                dispatch({ type: 'SET_CHILD_ORGANIZATIONS', payload: { childOrganizations: [] } })
                dispatch(showNotification("Get Hirachical Organizations", "error", String(err && err.message)))
            });
    }
}
export const getChildOrganizationSummaries = (parentOrganizationId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { auth } = getState()
        return makeApiCall(dispatch, "GET", `/admin/v1/organization/hierarchy/summary/${parentOrganizationId}`, {},
            "OrganizationListing.Actions.FetchOrganizations", "OrganizationListing.Actions.FetchOrganizationDesc").then((payload) => {
                dispatch(toggleWelcomeTooltipSetting(!(payload?.length > 1)))
                dispatch({ type: 'SET_CHILD_ORGANIZATION_SUMMARIES', payload: { childOrganizationSummaries: payload } })
                if (auth.currentProfileRoleJoinInfo.organization && auth.currentProfileRoleJoinInfo.organization.id === parentOrganizationId) {
                    dispatch({ type: 'SET_PROFILE_ROOT_ORGANIZATION_SUMMARIES', payload: { profileRootOrganizationSummaries: payload } })
                }
            }).catch(err => {
                dispatch({ type: 'SET_CHILD_ORGANIZATION_SUMMARIES', payload: { childOrganizationSummaries: [] } })
                dispatch(showNotification("Get Hirachical Organizations Summaries", "error", String(err && err.message)))
            });
    }
}
export const getChildOrganizationSummariesByRootOrg = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { auth } = getState()
        await makeApiCall(dispatch, "GET", `/admin/v1/organization/hierarchy/summary/${auth.currentProfileRoleJoinInfo.organization.id}`, {},
            "OrganizationListing.Actions.FetchOrganizations", "OrganizationListing.Actions.FetchOrganizationDesc").then((payload) => {
                dispatch({ type: 'SET_PROFILE_ROOT_ORGANIZATION_SUMMARIES', payload: { profileRootOrganizationSummaries: payload } })
            }).catch(err => {
                dispatch({ type: 'SET_PROFILE_ROOT_ORGANIZATION_SUMMARIES', payload: { childOrganizationSummaries: [] } })
                dispatch(showNotification("Get Hirachical Organizations Summaries", "error", String(err && err.message)))
            });
    }
}

export const setSelectedOrganization = (orgId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        const organization = await makeApiCall(dispatch, "GET", `/admin/v1/organization/${orgId}`)
        dispatch({ type: 'SET_SELECTED_ORGANIZATION', payload: { sOrganization: organization } })
    }
}

export const getAdminProfiles = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        const { auth } = getState()
        const practiceId = auth.currentPractice?.id;
        return makeApiCall(dispatch, "GET", `/admin/v1/admin/email?practiceId=${practiceId}`, {},
            "OrganizationListing.Actions.FetchOrganizations", "OrganizationListing.Actions.FetchOrganizationDesc").then((payload) => {
                dispatch({ type: 'SET_ORGANIZATION_ADMIN_PROFILE_EMAILS', payload: { organizationProfileEmails: payload } })
            }).catch(err => {
                dispatch(showNotification("Get Organizations Admin Profile Emails", "error", String(err && err.message)))
            });
    }
}
export const toggleWelcomeTooltipSetting = (toggleWelcomeTooltip: boolean) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'TOGGLE_WELCOME_TOOLTIP', payload: { toggleWelcomeTooltip } })
    }
}
export const offboardOrganization = (organizationId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        await makeApiCall(dispatch, "PUT", `/admin/v1/organization/offboard/${organizationId}`, {}).then((payload) => {
            dispatch(showNotification("Organization offboarded successfully", "success", ""))
        }).catch(err => {
            dispatch(showNotification("Offboard organization", "error", String(err && err.message)))
        });
    }
}