import { Box, Grid, Checkbox } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import Controls from "../../Controls/Controls";
import { Form, useForm } from '../../useForm';
import { useStyles } from "./styles";
import { showNotification } from 'admin/store/actions/snackbar';
import { PhoneMask } from 'admin/components/Controls/InputMasked';
import { validateEmail, validateNoSpecialCharacters, validateNumbersOnly, validateRequired, validateURL } from 'admin/common/utils';
import UserLevelDetailsMenu from "admin/components/UserLevelDetailsMenu/UserLevelDetailsMenu";


const EditUserForm = forwardRef(({ onFormValueChanged }: any, ref) => {
    const [isAutoInvite, setAutoInvite] = useState(false);
    const classes = useStyles();
    const intl = useIntl()
    const { admin, auth } = useSelector((state: AppReduxStore) => state)
    const { sUser } = admin
    let isPracticeUser = false
    let usersLevels = admin.userLevels && admin.userLevels.filter((u: any) => u.type !== "PATIENT").map((ul: any) => {
        return { ...ul, title: ul.description }
    });
    if (auth.currentOrg?.id) {
        usersLevels = usersLevels && usersLevels.filter((u: any) => u.type === "ADMIN")
        if (auth.currentOrg?.parent) {
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.name.indexOf('ALTAIS_ADMIN') === -1)
        } else {
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.name.indexOf('ALTAIS_ADMIN') !== -1)
        }
    }
    if (auth.currentPractice?.id) {
        usersLevels = usersLevels && usersLevels.filter((u: any) => u.type === "PROVIDER")
        isPracticeUser = true
    }
    const dispatch = useDispatch()
    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            }
        }),
    )
    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('firstName' in fieldValues) {
            err.firstName = validateRequired(fieldValues.firstName, intl)
            if (err.firstName === "") err.firstName = validateNoSpecialCharacters(fieldValues.firstName, intl)
        }
        if ('middleName' in fieldValues) {
            err.middleName = validateNoSpecialCharacters(fieldValues.middleName, intl)
        }
        if ('lastName' in fieldValues) {
            err.lastName = validateRequired(fieldValues.lastName, intl)
            if (err.lastName === "") err.lastName = validateNoSpecialCharacters(fieldValues.lastName, intl)
        }

        if ('email' in fieldValues) {
            err.email = validateRequired(fieldValues.email, intl)
            if (err.email === "") err.email = validateEmail(fieldValues.email, intl)
        }
        if ('mobilePhone' in fieldValues) {
            err.mobilePhone = isPracticeUser ? "" : validateRequired(fieldValues.mobilePhone?.replace(/[_-]/g, ''), intl)
        }
        if ('levelId' in fieldValues)
            err.levelId = validateRequired(fieldValues.levelId, intl)

        if ('npi' in fieldValues) {
            err.npi = validateNumbersOnly(fieldValues.npi, intl)
        }
        if ('qualityiqUrl' in fieldValues) {
            err.qualityiqUrl = validateURL(fieldValues.qualityiqUrl, intl)
        }
        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        trimValues
    } = useForm(sUser, false, validate);

    const handleSubmit = (e: any) => {
        trimValues()
        e.preventDefault()
        if (validate()) {
            const user = admin.userLevels && admin.userLevels.find((ul: any) => ul.id === values.levelId);
            values['userType'] = user && user.type
            values['isAutoInvite'] = isAutoInvite
            values.primaryPhone = values.primaryPhone?.replace(/[_-]/g, '')?.trim()
            values.mobilePhone = values.mobilePhone?.replace(/[_-]/g, '')?.trim()
            dispatch(editUser(values))
            resetForm()
            return true
        }
        dispatch(showNotification("Invalid", "error", ""))
        return false
    }
    const onAutoInviteCheck = (e: any) => {
        setAutoInvite(e.target.checked)
    }
    onFormValueChanged(JSON.stringify(sUser) !== JSON.stringify(values))

    return (
        <Form onSubmit={handleSubmit}>
            <Box className={classes.autoInviteText}>
                <Checkbox color="primary" disableRipple disableFocusRipple checked={isAutoInvite} onChange={onAutoInviteCheck} id="chkAddUserAutoInvite" />
                {intl.formatMessage({ id: "AddUserForm.SendAutoinvite.Text" })}
            </Box>
            <Box mb={1} className={classes.formGpHeader}>
                {intl.formatMessage({ id: "EditUserForm.Heading.Personal" })}
            </Box>
            <Grid container>

                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Select
                            name="prefix"
                            label="Prefix"
                            value={values.prefix}
                            onChange={handleInputChange}
                            options={admin.prefixes}
                            error={errors.prefix}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="firstName"
                            label="First Name *"
                            value={values.firstName}
                            onChange={handleInputChange}
                            error={errors.firstName}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="middleName"
                            label="Middle Name"
                            value={values.middleName}
                            onChange={handleInputChange}
                            error={errors.middleName}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="lastName"
                            label="Last Name *"
                            value={values.lastName}
                            onChange={handleInputChange}
                            error={errors.lastName}
                            maxLength={50}
                        />
                    </Grid>
                </Grid>
                <Box mt={4} mb={1} className={classes.formGpHeader}>
                    {intl.formatMessage({ id: "EditUserForm.Heading.Contact" })}
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="primaryPhone"
                            label="Primary Phone"
                            value={values.primaryPhone}
                            onChange={handleInputChange}
                            error={errors.primaryPhone}
                            inputComponent={PhoneMask}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="mobilePhone"
                            label={`Mobile Phone ${isPracticeUser ? "" : "*"}`}
                            value={values.mobilePhone}
                            onChange={handleInputChange}
                            error={errors.mobilePhone}
                            inputComponent={PhoneMask}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="email"
                            label="Email *"
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                        />
                    </Grid>
                </Grid>
                <Box mt={4} mb={1} className={classes.formGpHeader}>
                    {intl.formatMessage({ id: "EditUserForm.Heading.Professional" })}
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="npi"
                            label="NPI"
                            value={values.npi}
                            onChange={handleInputChange}
                            error={errors.npi}
                            maxLength={10}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="ehrUsername"
                            label="EMR User Name"
                            value={values.ehrUsername}
                            onChange={handleInputChange}
                            error={errors.ehrUsername}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Select
                            name="levelId"
                            label="User Level *"
                            value={values.levelId}
                            onChange={handleInputChange}
                            options={usersLevels}
                            error={errors.levelId}
                            altContent={usersLevels ? <UserLevelDetailsMenu userLevels={usersLevels} /> : null}
                        />
                    </Grid>
                    {isPracticeUser && <Grid item xs={3}>
                        <Controls.Input
                            name="qualityiqUrl"
                            label="QualityIQ URL"
                            value={values.qualityiqUrl}
                            onChange={handleInputChange}
                            error={errors.qualityiqUrl}
                            maxLength={255}
                            placeholder="Enter Here" />
                    </Grid>}
                </Grid>
            </Grid>
        </Form>
    )
})
export default EditUserForm