import PreviewBase from "./preview-base";
import { FileMetadataDetails } from "../types";
import DownloadPreview from "./download-preview";

type PreviewProps = {
    loading?: boolean;
    fileMetadataDetails?: FileMetadataDetails;
    previewUrl?: string;
};

const Preview = ({ fileMetadataDetails, loading, previewUrl }: PreviewProps) => {
    if (fileMetadataDetails) {
        return <DownloadPreview fileMetadataDetails={fileMetadataDetails} loading={loading} previewUrl={previewUrl} />;
    }

    return <PreviewBase loading={loading} previewUrl={previewUrl} />;
};

export default Preview;
