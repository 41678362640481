import {
    Box, Button, Grid, IconButton, InputBase, Paper, Table, TableBody,
    TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,
    TableSortLabel, Tooltip, Typography
} from "@mui/material";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import GridContextMenu from 'admin/components/GridContextMenu/GridContextMenu';
import FeeSchedulesGridContextMenu from 'admin/components/GridContextMenuContent/FeeSchedulesGridContextMenu/FeeSchedulesGridContextMenu';
import TablePaginationActions from 'admin/components/TablePaginationActions/TablePaginationActions';
import { MODAL_TYPES, USER_LEVELS } from 'admin/constant';
import { setActiveModal, setNavBreadcrumbText } from 'admin/store/actions';
import { showNotification } from "admin/store/actions/snackbar";
import { call } from 'admin/store/api';
import { AppReduxStore } from "admin/store/reducerTypes";
import { handleDownloadAttachment } from "admin/views/FeeSchedules/components/files/download-view-attachments";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as NoResultIcon } from "icon-library/SVG/Circles-Light_3x3.svg";
import { ReactComponent as EllipsisV } from "icon-library/SVG/Ellipsis-Vertical.svg";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { createSvgIcon } from "utils";
import { useStyles } from './styles';

const EllipsisVIcon = createSvgIcon(EllipsisV);

type Order = 'asc' | 'desc';

type FeeScheduleProps = {
    billingGroupNames: any,
    billingScheduleId: string,
    effectiveDate: string,
    feeScheduleName: string,
    lastUpdatedDate: string
};

type PaginatedFeeScheduleSort = {
    empty: boolean,
    sorted: boolean,
    unsorted: boolean,
}

type PaginatedFeeSchedulePagable = {
    offset: number,
    pageNumber: number,
    pageSize: number,
    paged: boolean,
    sort: PaginatedFeeScheduleSort,
    unpaged: boolean
}

type PaginatedFeeScheduleProps = {
    content: FeeScheduleProps[],
    empty: boolean,
    first: boolean,
    last: boolean,
    number: number,
    numberOfElements: number,
    pageable: PaginatedFeeSchedulePagable,
    size: number,
    sort: PaginatedFeeScheduleSort,
    totalElements: number,
    totalPages: number,
}

const useFeeSchedulesListing = (practiceId: string, orgId: string, orderBy: keyof FeeScheduleProps, order: Order, rowsPerPage: number, page: number, searchStr?: string) => {
    const practiceEndpointURL = `/admin/v1/schedule/assigned/practice/${practiceId}?sortedBy=${orderBy}&sortAsc=${(order === 'asc') ? true : false}&pageSize=${rowsPerPage}&pageNumber=${page}${searchStr ? `&name=${searchStr}` : ""}`;
    const endpointURL = `/admin/v1/billing/schedule/organization/${orgId}?sortedBy=${orderBy}&sortAsc=${(order === 'asc') ? true : false}&pageSize=${rowsPerPage}&pageNumber=${page}${searchStr ? `&name=${searchStr}` : ""}`;

    const { content, fetchInfo, request } = useApi<{}, PaginatedFeeScheduleProps | null>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: null,
        url: practiceId ? practiceEndpointURL : endpointURL,
        method: HttpMethod.GET
    });

    useEffect(() => {
        request({});
    }, [request, orgId, orderBy, order, rowsPerPage, page, searchStr]);

    return {
        paginatedFeeScheduleListing: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
        request
    };
};

const getTopLevelOrgId = (org: any) => {
    let current = org;
    if (current === null || current?.parent === null)
        return null;

    while (current !== null) {
        if (current?.parent?.parent === null)
            return current?.id;
        current = current?.parent;
    }
} 

const FeeSchedulesListing = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueError } = useUserNotification();
    const { organization, auth } = useSelector((state: AppReduxStore) => state);
    const { currentPractice, currentProfileRoleJoinInfo } = auth;
    const isSubOrg: boolean = (organization?.sOrganization?.parent?.parent || currentPractice?.organizationInfo?.parent?.parent) ? true : false;
    const orgId = isSubOrg ? (getTopLevelOrgId(organization?.sOrganization) || getTopLevelOrgId(currentPractice?.organizationInfo)) : (organization?.sOrganization?.id || currentProfileRoleJoinInfo?.organization?.id || currentPractice?.organizationId);
    const practiceId = currentProfileRoleJoinInfo?.practice?.id || currentPractice?.id || null;
    const [orderBy, setOrderBy] = useState<keyof FeeScheduleProps>('feeScheduleName');
    const [order, setOrder] = useState<Order>('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchStr, setSearchStr] = useState<string>();
    const [contextMenuData, setContextMenuData] = useState({} as any);
    const searchInput = useRef<any>();
    const { paginatedFeeScheduleListing, hasResult, request } = useFeeSchedulesListing(practiceId, orgId, orderBy, order, rowsPerPage, page, searchStr);
    const feeSchedules = paginatedFeeScheduleListing?.content || [];
    const isLoading = !(hasResult && paginatedFeeScheduleListing?.content);

    const handleSearch = (event: any) => {
        if (orgId) {
            setPage(0);
            const searchValue = event.target.value.trim();
            if (searchValue !== "") {
                setSearchStr(searchValue);
            } else {
                setSearchStr(undefined);
            }
        }
    };

    const handleRowAction = async (type: string, billingScheduleId: string) => {
        switch (type) {
            case 'PreviewFeeScheduleDocument':
                if (billingScheduleId && currentProfileRoleJoinInfo) {
                    handleDownloadAttachment(currentProfileRoleJoinInfo, orgId, billingScheduleId, undefined, (error) => {
                        enqueueError("pep.fee.schedules.download.error");
                    })
                }
                break;
            case 'ViewEditFeeSchedule':
                if (billingScheduleId) {
                    history.push(`/admin/feeschedules/details/${billingScheduleId}`);
                }
                break;
            case 'DeleteFeeSchedule':
                if (billingScheduleId) {
                    dispatch(setActiveModal(MODAL_TYPES.DELETE_FEE_SCHEDULE_CONFIRMATION_MODAL, {
                        scheduleId: billingScheduleId,
                        onDone: () => {
                            request({});
                        }
                    }));
                }
                break;
            default:
                dispatch(showNotification("TODO", "error", "Action Not Implemented"));
        }
    };

    const handleAddFeeSchedule = () => {
        dispatch(setNavBreadcrumbText(`New Fee Schedule`));
    };

    const handleManageGroups = () => {
        dispatch(setNavBreadcrumbText(`Fee Schedule Groups`));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rowsperPage = parseInt(event.target.value)
        setRowsPerPage(rowsperPage);
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof FeeScheduleProps) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const createSortHandler = (property: keyof FeeScheduleProps) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const handleEllipsesActionClick = (event: any, row: any, index: number) => {
        event.preventDefault();
        setContextMenuData({ mouseX: event.clientX - 194, mouseY: event.clientY - 25, row, index })
    };

    const handleRowContextMenuClose = (event: any) => {
        setContextMenuData({ mouseX: 0, mouseY: 0, row: null, index: null })
    };

    const RowContextMenu = () => {
        const row = contextMenuData?.row
        if (!row) return
        return (<GridContextMenu mouseX={contextMenuData?.mouseX} mouseY={contextMenuData?.mouseY} onClose={handleRowContextMenuClose}
            callback={(type: any) => {
                handleRowAction(type, row.billingScheduleId)
            }}
            formNumber={""}
            formTitle={""}
            handleFormRow={null}
            gridContextMenuContent={FeeSchedulesGridContextMenu}
            index={contextMenuData?.index}
            hideContextMenuHeader={true} />
        )
    }

    const Pagination = () => {
        return (<>
            {feeSchedules && feeSchedules.length > 0 && <TablePagination className={classes.tableFooterRow}
                rowsPerPageOptions={[1, 2, 5, 10, 25, 50]}
                classes={{ selectIcon: classes.footerIcon, select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
                count={paginatedFeeScheduleListing?.totalElements || 0}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Per Page"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            }</>)
    }

    const getBillingGroupNames = (content: any): string => {
        const groups: string[] = content.substring(1, content.length - 1).split(",");
        const billingGroups = groups.map((group: string, index: number) => group.substring(1, group.length - 1)).join(", ");
        return (groups.length === 0) ? "" : billingGroups;
    };

    const [addFeeScheduleDisabled, setAddFeeScheduleDisabled] = useState<boolean>(false);
    
    const checkFeeScheduleButton = useCallback(async () => {
        const levelName = currentProfileRoleJoinInfo?.level?.name;
        const ALTAIS_ADMINS = [USER_LEVELS.ALTAIS_ADMIN_1, USER_LEVELS.ALTAIS_ADMIN_2, USER_LEVELS.ALTAIS_ADMIN_3];
        const ORG_ADMINS = [USER_LEVELS.ORG_ADMIN_1, USER_LEVELS.ORG_ADMIN_2];
        const BILLING_ADMINS = [USER_LEVELS.BILLING_ADMIN];
        const addFeeScheduleAllowed = (ALTAIS_ADMINS.includes(levelName) || ORG_ADMINS.includes(levelName) || BILLING_ADMINS.includes(levelName));
        if (!addFeeScheduleAllowed) {
            setAddFeeScheduleDisabled(true);
            Promise.resolve();
        } else {
            return await call("GET", `/admin/v1/billing/group/organization/${orgId}`).then((payload) => {
                const hasFeeScheduleGroups = (payload?.length > 0);
                setAddFeeScheduleDisabled(!hasFeeScheduleGroups);
            });
        }
    }, []);

    useEffect(() => {
        checkFeeScheduleButton();
    }, [orgId])

    const isEmpty = !isLoading && (paginatedFeeScheduleListing?.totalElements === 0);
    return (
        <><Box className={classes.usersWrapper}>
                {isEmpty ?
                    <p style={{ fontFamily: 'OpenSans', fontStyle: 'normal', fontWeight: '600', fontSize: '32px', lineHeight: '140%', color: '#393948', marginTop: '0px' }}>You Have Not Created Any Fee Schedules Yet!</p> :
                    <p style={{ fontFamily: 'OpenSans', fontStyle: 'normal', fontWeight: '600', fontSize: '32px', lineHeight: '140%', color: '#393948', marginTop: '0px' }}>Current Fee Schedules</p>
                }
                <Grid container> {!isEmpty &&
                    <Grid item xs>
                        <Paper component="form" className={classes.root}>
                            <IconButton id="btnSerachFeeSchedules" className={classes.iconButton} aria-label="search" size="large">
                                <SearchIcon />
                            </IconButton>
                            <InputBase id="txtSearchFeeSchedules"
                                type="search" ref={searchInput}
                                className={classes.input}
                                onChange={handleSearch}
                                onKeyPress={(ev: any) => { if (ev.key === 'Enter') { ev.preventDefault() } }}
                                placeholder="Search Fee Schedules"
                                inputProps={{ 'aria-label': 'Search Fee Schedules' }}
                            />
                        </Paper>
                    </Grid>}
                    <Grid item xs>
                        <Button id="btnManageGroups" disabled={isSubOrg} variant="outlined" color="inherit" style={isSubOrg ? { float: 'right', border: 0, boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, .12)' } : { float: 'right' }} onClick={handleManageGroups}>
                            <Link to={`/admin/feeschedules/groups`} style={isSubOrg ? { fontStyle: 'normal', fontWeight: '600', fontSize: '20px', lineHeight: '140%', color: '#FFFFFF' } : { fontStyle: 'normal', fontWeight: '600', fontSize: '20px', lineHeight: '140%' }}>
                                {'Manage Groups'}
                            </Link>
                        </Button>
                        <Button id="btnAddFeeSchedule" disabled={addFeeScheduleDisabled} variant="contained" color="primary" style={{ float: 'right', marginRight: '20px' }} onClick={handleAddFeeSchedule}>
                            <Link to={`/admin/feeschedules/config`} style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '20px', lineHeight: '140%', color: '#FFFFFF' }}>
                                {'Add a Fee Schedule'}
                            </Link>
                        </Button>
                    </Grid>
                </Grid>
                {isEmpty ? (
                    <div id="btnNoFeeSchedules" style={{ width: '100%', alignItems: 'center', textAlign: 'center', marginTop: '100px' }} >
                        <NoResultIcon className={classes.noResultIcon} />
                        <p style={{ fontFamily: 'OpenSans', fontStyle: 'normal', fontWeight: '600', fontSize: '24px', lineHeight: '140%', color: '#999999', margin: '0px' }}>No Fee Schedules</p>
                    </div>
                ) : (
                <Paper className={classes.paper}>
                    <TableContainer className={classes.stickyHeader}>
                        <Table id="tableFeeSchedulesListing"
                            stickyHeader
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        width={'30%'}
                                        key={"feeScheduleName"}
                                        align={'left'}
                                        padding="normal"
                                        sortDirection={orderBy === "feeScheduleName" ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === "feeScheduleName"}
                                            direction={orderBy === "feeScheduleName" ? order : 'asc'}
                                            onClick={createSortHandler("feeScheduleName")}>
                                            <strong style={{ marginLeft: "41px" }}>
                                                {'Fee Schedule Name'}
                                            </strong>
                                            {orderBy === "feeScheduleName" ?
                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        width={'15%'}
                                        key={"lastUpdatedDate"}
                                        align={'left'}
                                        padding="normal"
                                        sortDirection={orderBy === "lastUpdatedDate" ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === "lastUpdatedDate"}
                                            direction={orderBy === "lastUpdatedDate" ? order : 'asc'}
                                            onClick={createSortHandler("lastUpdatedDate")}
                                        >
                                            <strong>{'Update Date'}</strong>
                                            {orderBy === "lastUpdatedDate" ?
                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        width={'35%'}
                                        key={"billingGroupNames"}
                                        align={'left'}
                                        padding="normal"
                                        sortDirection={orderBy === "billingGroupNames" ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === "billingGroupNames"}
                                            direction={orderBy === "billingGroupNames" ? order : 'desc'}
                                            onClick={createSortHandler("billingGroupNames")}
                                        >
                                            <strong>{'Groups'}</strong>
                                            {orderBy === "billingGroupNames" ?
                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted ascending' : 'sorted descending'}</span>) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        width={'15%'}
                                        key={"effectiveDate"}
                                        align={'left'}
                                        padding="normal"
                                        sortDirection={orderBy === "effectiveDate" ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === "effectiveDate"}
                                            direction={orderBy === "effectiveDate" ? order : 'asc'}
                                            onClick={createSortHandler("effectiveDate")}
                                        >
                                            <strong>{'Effective Date'}</strong>
                                            {orderBy === "effectiveDate" ?
                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell width={'5%'} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    feeSchedules.map((row: FeeScheduleProps, index: number) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (<>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell id={labelId} scope="row" padding="none" width={'30%'}>
                                                    <Grid style={{ display: "flex", alignItems: "center", flexBasis: 'none' }}>
                                                        <Link style={{ marginLeft: "41px" }} to={`/admin/feeschedules/details/${row.billingScheduleId}`}>
                                                            <Tooltip arrow title={row.feeScheduleName ? row.feeScheduleName.toString() : ''}>
                                                                <Typography noWrap variant="body1" style={{ color: '#0F7BBD', width: '250px', fontSize: '14px', fontWeight: 600 }}>
                                                                    {row.feeScheduleName}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Link>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align="left" width={'15%'}>{row.lastUpdatedDate ? `${moment(row.lastUpdatedDate).format('MM/DD/YYYY')}` : ""}</TableCell>
                                                <TableCell align="left" width={'35%'}>{getBillingGroupNames(row.billingGroupNames)}</TableCell>
                                                <TableCell align="left" width={'15%'}>{row.effectiveDate ? `${moment(row.effectiveDate).format('MM/DD/YYYY')}` : ""}</TableCell>
                                                <TableCell align="right" width={'5%'} className={classes.stickyLastColumn}>
                                                    <IconButton id={"btnRowActionContextMenu" + index}
                                                        style={{ padding: '10px' }}
                                                        onClick={(e) => handleEllipsesActionClick(e, row, index)}
                                                        size="large">
                                                        <EllipsisVIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                        );
                                    })
                                }
                                {contextMenuData?.row && RowContextMenu()}
                            </TableBody>
                            <TableFooter>
                                {hasResult && feeSchedules && feeSchedules.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                ) : (<TableRow>
                                    <Pagination />
                                </TableRow>)}
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
                )}
            </Box>
    </>
    );
}

export default FeeSchedulesListing;