import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AppReduxStore } from "admin/store/reducerTypes";
import { showNotification } from './snackbar';
import { call } from '../api';
// import { resolve } from 'path';
import { makeApiCall } from 'admin/common/utils';

const resolve = () => {
	return Promise.resolve();
}

export const fetchPatientForms = (practiceId?: null | string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/pic-forms?practiceId=${practiceId}`, {},
			"PatientFormsListing.Actions.FetchPatientForms", "PatientFormsListing.Actions.FetchPatientFormsDesc").then((payload) => {
				const patientForms = payload ? payload.map((p: any) => {
					return p
				}) : [];
				dispatch({ type: 'SET_PATIENT_FORMS', payload: { patientForms } });
			}).catch(err => {
				dispatch({ type: 'SET_PATIENT_FORMS', payload: { patientForms: [] } });
				dispatch(showNotification("Get All Patient Forms", "error", String(err && err?.message)));
			});
	}
}

export const fetchPatientForm = (patientFormId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/pic-form/${patientFormId}/?practiceId=${practiceId}`, {},
			"PatientFormsListing.Actions.FetchPatientForms", "PatientFormsListing.Actions.FetchPatientFormsDesc").then((payload) => {
				dispatch(setSelectedPatientForm(payload));
			}).catch(err => {
				dispatch(showNotification("Get Patient Form", "error", String(err && err?.message)));
			});
	}
}

export const clonePatientForm = (patientFormId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		return makeApiCall(dispatch, "POST", `/admin/v1/practice/configuration/pic-form/${patientFormId}/clone?practiceId=${practiceId}`, {},
			"PatientFormsListing.Actions.FetchPatientForms", "PatientFormsListing.Actions.FetchPatientFormsDesc").then((payload) => {
				const newPath = `admin/configurations/patientforms/${payload?.id}`;
				window.history.replaceState(null, document.title, newPath);
				dispatch(setSelectedPatientForm(payload));
			}).catch(err => {
				dispatch(showNotification("Clone Patient Form", "error", String(err && err?.message)));
			});
	}
}

export const savePatientForm = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		if (data.id === '') {
			dispatch(addPatientForm(data));
		} else {
			dispatch(editPatientForm(data));
			dispatch(formInstructionsUpdates());
		}
	}
}

export const addPatientForm = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		if (data && data.title && data.title !== '') {
			const { auth } = getState();
			const practiceId = auth.currentPractice?.id;
			const newQuestions: any = { id: '', questions: data.questions.slice() };
			//TODO : remove when service fully updated and migrated
			data.questions = [];
			return makeApiCall(dispatch, "POST", `/admin/v1/practice/configuration/pic-form?practiceId=${practiceId}`, data).then((payload) => {
				const newPath = `/admin/configurations/patientforms/${payload?.id}`;
				window.history.replaceState({}, document.title, newPath);
				dispatch(showNotification("Patient Form Added successfully", "success", ""));
				data.id = payload.id;
				dispatch(setSelectedPatientForm(payload));
				newQuestions.id = payload.id;
				dispatch(createNewQuestionOptions(newQuestions));
			}).catch((error: any) => {
				dispatch(showNotification("Error creating new form", "error", String(error && error?.message)));
			});
		} else {
			dispatch(setFormErrors({
				type: "missingFormTitle",
				value: true
			}));
		}
	}
}

export const editPatientForm = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		const picFormInfo = Object.assign({}, data);
		const newQuestions: any = { id: '', questions: picFormInfo.questions.slice() };
		//TODO : remove when service fully updated and migrated
		picFormInfo.questions = [];
		return makeApiCall(dispatch, "PUT", `/admin/v1/practice/configuration/pic-form?practiceId=${practiceId}`, picFormInfo).then((payload) => {
			dispatch(showNotification("Patient Form Updated successfully", "success", ""));
			dispatch(setSelectedPatientForm(payload));
			newQuestions.id = payload.id;
			dispatch(createNewQuestionOptions(newQuestions));
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err?.message)));
		});
	}
}

export const togglePatientFormStatus = (data: any) => {
	data.isEnabled = !data.isEnabled;
	return editPatientForm(data);
}

export const getApproverList = (data : any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) =>{
		const [userId, userType, practiceId] = data.split(' ');
		return makeApiCall(dispatch, 'GET', `/admin/v1/users/levels/${userType}?levelId=${userId}&practiceId=${practiceId}`).then((payload: any) => {
			const approverList = [...payload].slice(0,5);
			dispatch({ type: 'GET_APPROVER_LIST', payload: { approverList } });
		}).catch(err =>{
			dispatch(showNotification("Error", "error", String(err && err?.message)));
		});
	}
}

export const createPicFormApproval = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) =>{
		return makeApiCall(dispatch, 'POST', `/admin/v1/practice/configuration/pic-form/approval`, data).then((payload: any) => {
			const questionnaireApproval = payload;
			dispatch({type: "SET_QUESTIONNAIRE_APPROVAL", payload: {questionnaireApproval}});
		}).catch(err =>{
			dispatch(showNotification("Error", 'error', String(err && err?.message)));
		})
	}
}

export const UpdatePicFormApproval = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) =>{
		return makeApiCall(dispatch, 'PATCH', `/admin/v1/practice/configuration/pic-form/approval/${data.id}`, data).then((payload: any) => {
			const questionnaireApproval = payload;
			dispatch({type: "SET_QUESTIONNAIRE_APPROVAL", payload: {questionnaireApproval}});
		}).catch(err =>{
			dispatch(showNotification("Error", 'error', String(err && err?.message)));
		})
	}
}

export const updateQuestionnaireApprovalReview = (data : any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) =>{
		return makeApiCall(dispatch, 'PATCH', `/admin/v1/practice/configuration/pic-form/approval/review/${data.id}`, data).then((payload: any) => {
			const questionnaireApproval = payload;
			const newPath = `/admin/configurations/patientforms/`;
			window.location.href = newPath;
			dispatch({type: "SET_QUESTIONNAIRE_APPROVAL", payload: {questionnaireApproval}});
		}).catch(err =>{
			dispatch(showNotification("Error", 'error', String(err && err?.message)));
		})
	}
}

export const setFormPublished = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) =>{
		return makeApiCall(dispatch, 'PATCH', `/admin/v1/practice/configuration/pic-form/${data.id}`, {formStatus: 'PUBLISHED'}).then((payload: any) => {
			const sPatientFormA = payload;
			dispatch({type: "SET_FORM_PUBLISHED", payload: {sPatientFormA}})
		}).catch(err =>{
			dispatch(showNotification("Error", 'error', String(err && err?.message)));
		})
	}
}

export const getFormApproverId = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) =>{
		return makeApiCall(dispatch, 'GET', `/admin/v1/practice/configuration/questionnaires/versions/approval/approver/${data.id}`).then((payload: any) => {
			
		}).catch(err =>{
			dispatch(showNotification("Error", 'error', String(err && err?.message)));
		})
	}
}

export const processFormInstructions = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { configuration } = getState();
		const patientFormInstructionsUpdates = configuration.patientFormInstructionsUpdates;
		let instructionObj = patientFormInstructionsUpdates[data.name];
		if ((instructionObj == null) ||
			(instructionObj && ((instructionObj.value !== data.value) || (instructionObj.action !== data.action)))) {
			instructionObj = { value: data.value, action: data.action, instructionId: data.instructionId, formId: data.formId };
			patientFormInstructionsUpdates[data.name] = instructionObj;
			dispatch({ type: 'SET_PATIENT_FORM_INSTRUCTIONS_UPDATES', payload: { patientFormInstructionsUpdates } });
		}
	}
}

export const formInstructionsUpdates = () => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { configuration } = getState();
		const formInstructionsUpdates = configuration.patientFormInstructionsUpdates;
		const ID_FORM_USE = "instructions-form-use";
		const ID_INSTRUCTIONS = "instructions-instructions";
		const ID_SCORING = "instructions-scoring";

		const formId = ((formInstructionsUpdates[ID_FORM_USE]?.formId) ||
			(formInstructionsUpdates[ID_INSTRUCTIONS]?.formId) ||
			(formInstructionsUpdates[ID_SCORING]?.formId));
		if (formId) {
			const instructionId = ((formInstructionsUpdates[ID_FORM_USE]?.instructionId) ||
				(formInstructionsUpdates[ID_INSTRUCTIONS]?.instructionId) ||
				(formInstructionsUpdates[ID_SCORING]?.instructionId))
			if (instructionId) {
				// Modify existing Instructions
				const questionnaireInstructionsEditRequest = {
					id: instructionId,
					questionnaireId: formId,
					questionnaireUse: formInstructionsUpdates[ID_FORM_USE]?.value,
					providerAdministration: formInstructionsUpdates[ID_INSTRUCTIONS]?.value,
					providerScoring: formInstructionsUpdates[ID_SCORING]?.value
				}
				if ((formInstructionsUpdates[ID_FORM_USE]?.action === "modify") ||
					(formInstructionsUpdates[ID_INSTRUCTIONS]?.action === "modify") ||
					(formInstructionsUpdates[ID_SCORING]?.action === "modify")) {
					// Send the request only when at least one item to update
					dispatch(updateQuestionnaireInstructions(questionnaireInstructionsEditRequest));
				}
			} else {
				// Create new Instructions
				const questionnaireInstructionsCreateRequest = {
					questionnaireId: formId,
					questionnaireUse: formInstructionsUpdates[ID_FORM_USE]?.value,
					providerAdministration: formInstructionsUpdates[ID_INSTRUCTIONS]?.value,
					providerScoring: formInstructionsUpdates[ID_SCORING]?.value
				}
				if ((formInstructionsUpdates[ID_FORM_USE]?.action === "create") ||
					(formInstructionsUpdates[ID_INSTRUCTIONS]?.action === "create") ||
					(formInstructionsUpdates[ID_SCORING]?.action === "create")) {
					// Send the request only when at least one item to create
					dispatch(createQuestionnaireInstructions(questionnaireInstructionsCreateRequest));
				}
			}
		}
	}
}

export const groupBy = (objectArray: any, property: string) => {
	return objectArray.reduce((acc: any, obj: any) => {
		const key = obj[property];
		if (!acc[key]) {
			acc[key] = [];
		}
		// Add object to list for given key's value
		acc[key].push(obj);
		return acc;
	}, {});
}
export const groupQuestions = (questions: any[]) => {
	let newQuestions = questions.slice();
	//Group by to Category and OrderIndex apply sorting to questions and answers
	newQuestions = newQuestions?.sort((a: any, b: any) => a.groupIndex - b.groupIndex)?.sort((a: any, b: any) => a.orderIndex - b.orderIndex || a.categoryName - b.categoryName);
	newQuestions.forEach((question: any, index: number) => {
		question.answerOption = question?.answerOption?.sort((a: any, b: any) => a.orderIndex - b.orderIndex);
	});
	newQuestions = groupBy(newQuestions, 'categoryName');
	newQuestions = Object.values(newQuestions);
	newQuestions.forEach((catergory: any, index: number, catergoryArray: any[]) => {
		catergoryArray[index] = Object.values(groupBy(catergory, 'groupIndex'));
	});
	return newQuestions;
}

export const setSelectedPatientForm = (patientForm: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		dispatch({ type: 'SET_SELECTED_PATIENT_FORM', payload: { sPatientFormA: patientForm } });
	}
}

export const setPatientFormReadOnly = (isReadOnly: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		dispatch({ type: 'SET_SELECTED_PATIENT_FORM_READ_ONLY', payload: { isFormReadOnly: isReadOnly } });
	}
}

export const fetchAllRules = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/pic-forms/rules/trigger-configurations?practiceId=${practiceId}`, {},
			"RulesListing.Actions.FetchEVisitsType", "RulesListing.Actions.FetchEVisitsTypeDesc").then((payload) => {
				let rules = payload ? payload.map((p: any) => {
					return p;
				}) : [];
				rules = rules.sort((a: any, b: any) => a.orderIndex - b.orderIndex);
				rules = groupBy(rules, 'ruleType');
				dispatch({ type: 'SET_RULES', payload: { rules: rules } });
			}).catch(err => {
				dispatch(showNotification("Rules Error", "error", String(err && err?.message)));
			});
	}
}

export const getQuestionnaireHeadersByQuestionnaireId = (questionnaireId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/${questionnaireId}/questionnaire-headers?practiceId=${practiceId}`).then((payload) => {
			dispatch({ type: 'SET_PATIENT_FORM_HEADERS', payload: { patientFormHeaders: payload } });
		}).catch((error: any) => {
			dispatch(showNotification("Get Questionnaire Headers Error", "error", String(error && error?.message)));
		});
	}
}

export const createQuestionnaireHeaders = (questionnaireHeaderCreateRequest: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		return makeApiCall(dispatch, "POST", "/admin/v1/practice/configuration/questionnaire-headers", questionnaireHeaderCreateRequest).then((payload) => {
			dispatch(showNotification("Update Questionnaire Headers", "success", ""));
		}).catch((error: any) => {
			dispatch(showNotification("Create Questionnaire Headers Error", "error", String(error && error?.message)));
		});
	}
}

export const updateQuestionnaireHeaders = (questionnaireHeaderEditRequest: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		return makeApiCall(dispatch, "PATCH", "/admin/v1/practice/configuration/questionnaire-headers", questionnaireHeaderEditRequest).then((payload) => {
			dispatch(showNotification("Update Questionnaire Headers", "success", ""));
		}).catch((error: any) => {
			dispatch(showNotification("Update Questionnaire Headers Error", "error", String(error && error?.message)));
		});
	}
}

export const getQuestionnaireInstructionsByQuestionnaireId = (questionnaireId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		if (questionnaireId) {
			return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/pic-forms/${questionnaireId}/questionnaire-instructions?practiceId=${practiceId}`).then((payload) => {
				dispatch({ type: 'SET_PATIENT_FORM_INSTRUCTIONS', payload: { patientFormInstructions: payload } });
			}).catch((error: any) => {
				dispatch(showNotification("Get Questionnaire Instructions Error", "error", String(error && error?.message)));
			});
		} else {
			dispatch({ type: 'SET_PATIENT_FORM_INSTRUCTIONS', payload: { patientFormInstructions: [] } });
		}
	}
}

export const createQuestionnaireInstructions = (questionnaireInstructionsCreateRequest: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		return makeApiCall(dispatch, "POST", "/admin/v1/practice/configuration/pic-forms/questionnaire-instructions", questionnaireInstructionsCreateRequest).then((payload) => {
			dispatch(showNotification("Update Questionnaire Instructions", "success", ""));
		}).catch((error: any) => {
			dispatch(showNotification("Create Questionnaire Instructions Error", "error", String(error && error?.message)));
		});
	}
}

export const updateQuestionnaireInstructions = (questionnaireInstructionsEditRequest: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		return makeApiCall(dispatch, "PATCH", "/admin/v1/practice/configuration/pic-forms/questionnaire-instructions", questionnaireInstructionsEditRequest).then((payload) => {
			dispatch(showNotification("Update Questionnaire Instructions", "success", ""));
		}).catch((error: any) => {
			dispatch(showNotification("Update Questionnaire Instructions Error", "error", String(error && error?.message)));
		});
	}
}

export const saveVisitTypes = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		return makeApiCall(dispatch, "PUT", `/admin/v1/practice/configuration/appointment-types?practiceId=${practiceId}`, data).then((payload) => {
			dispatch(showNotification("VisitTypes Updated successfully", "success", ""));
			dispatch(fetchAppointmentTypes());
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err?.message)));
		});
	}
}

export const deleteSelectedVisitTypes = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		return makeApiCall(dispatch, "PUT", `/admin/v1/practice/configuration/pic-form?practiceId=${practiceId}`, data).then((payload) => {
			dispatch(showNotification(payload, "success", ""));
			dispatch(fetchAppointmentTypes());
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err?.message)));
		});
	}
}

export const setSelectedVisitTypes = (visitTypeId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { visitTypes } = getState().configuration;
		const visitType = visitTypes && visitTypes.find((p: any) => p.id === visitTypeId);
		dispatch({ type: 'SET_SELECTED_VISIT_TYPE', payload: { selectedVisitType: visitType } });
	}
}

export const fetchCancellationTypes = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		dispatch({ type: 'SET_CANCELLATION_TYPES', payload: { cancellationTypes: [] } });
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/appointment-cancellation-reasons?practiceId=${practiceId}`, {},
			"CancellationTypeListing.Actions.FetchCancellationType", "CancellationTypeListing.Actions.FetchCancellationTypesDesc").then((payload) => {
				const cancellationTypes = payload ? payload.map((p: any) => {
					return p
				}) : [];
				dispatch({ type: 'SET_CANCELLATION_TYPES', payload: { cancellationTypes } });
			}).catch(err => {
				dispatch(showNotification("Get All Cancellation Type", "error", String(err && err?.message)));
			});
	}
}

export const saveCancellationTypes = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		return makeApiCall(dispatch, "PUT", `/admin/v1/practice/configuration/appointment-cancellation-reasons?practiceId=${practiceId}`, data).then((payload) => {
			dispatch(showNotification("CancellationTypes Updated successfully", "success", ""));
			dispatch(fetchCancellationTypes());
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err?.message)));
		});
	}
}

export const fetchWaitingRoom = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		dispatch({ type: 'SET_WAITING_ROOM', payload: { waitingRoom: {} } });
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/waiting-room?practiceId=${practiceId}`, {},
			"WaitingRoom.Actions.FetchWaitingRoom", "WaitingRoom.Actions.FetchWaitingRoomDesc").then((payload) => {
				const waitingRoom = payload;
				dispatch({ type: 'SET_WAITING_ROOM', payload: { waitingRoom } });
			}).catch(err => {
				dispatch(showNotification("Get Waiting Room Details", "error", String(err && err?.message)));
			});
	}
}

export const saveWaitingRoom = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		return makeApiCall(dispatch, "PUT", `/admin/v1/practice/configuration/waiting-room?practiceId=${practiceId}`, data).then((payload) => {
			dispatch(showNotification("Waiting Room Updated successfully", "success", ""));
			dispatch(fetchWaitingRoom());
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err?.message)));
		});
	}
}

export const setQuestionnaireAppointmentTypes = (appointmentTypes: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_SELECTED_PATIENT_FORM_APPOINTMENT_TYPES', payload: { sPatientFormAAppointmentTypes: appointmentTypes } });
	}
}

export const fetchQuestionnaireAppointmentTypes = (patientFormId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		dispatch(setQuestionnaireAppointmentTypes([]));
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/questionnaires/${patientFormId}/appointment-types?practiceId=${practiceId}`).then((payload) => {
			dispatch(setQuestionnaireAppointmentTypes(payload));
		}).catch(err => {
			dispatch(showNotification("Get Questionnaire Appointment Type", "error", String(err && err?.message)));
		});
	}
}

export const setQuestionnaireTriggers = (triggers: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_SELECTED_PATIENT_FORM_TRIGGERS', payload: { sPatientFormATriggers: triggers } });
	}
}

export const fetchQuestionnaireTriggers = (patientFormId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		dispatch(setQuestionnaireTriggers([]));
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/pic-forms/rules/trigger-configuration?questionnaireId=${patientFormId}&practiceId=${practiceId}`).then((payload) => {
			dispatch(setQuestionnaireTriggers(payload));
		}).catch(err => {
			dispatch(showNotification("Get Questionnaire Triggers Type", "error", String(err && err?.message)));
		});
	}
}

export const saveFormQuestions = (initialForm: any, currentForm: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const additionalQuestions: any[] = initialForm?.questions?.filter((question: any) => {
			return (question.categoryName === "Additional Questions");
		}) || [];
		const newQuestions: any[] = currentForm?.questions?.filter((question: any) => {
			return (question.categoryName === "Additional Questions");
		}) || [];

		const insertQuestions: any[] = [];
		const modifyQuestions: any[] = [];
		const deleteQuestions: any[] = [];

		// Creating new questions...
		newQuestions.filter((question: any) => {
			return ((question.id === '') || (question.id == null));
		}).forEach((question: any) => {
			insertQuestions.push(question);
		});

		additionalQuestions.forEach((additionalQuestion: any) => {
			let found = false;
			newQuestions.forEach((newQuestion: any) => {
				if (newQuestion.id === additionalQuestion.id) {
					found = true;
					// Modifying existing questions...
					modifyQuestions.push(newQuestion);
				}
			});
			if (!found) {
				// Deleting existing questions...
				deleteQuestions.push(additionalQuestion);
			}
		});

		if (insertQuestions.length > 0) {
			insertQuestions.forEach((question: any) => {
				dispatch(addQuestionnaireQuestions(question, initialForm.id));
			});
		}
		if (modifyQuestions.length > 0) {
			modifyQuestions.forEach((question: any) => {
				dispatch(updateQuestion(question, initialForm.id));
			});
		}
		if (deleteQuestions.length > 0) {
			deleteQuestions.forEach((question: any) => {
				if (question?.answerOption?.length > 0) {
					dispatch(deleteQuestionWithAnswerOptions(question));
				} else {
					dispatch(deleteQuestion(question.id));
				}
			});
		}
	}
}

export const versionPicForm = (patientFormId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return call("POST", `/admin/v1/practice/configuration/pic-form/${patientFormId}/version`).then((payload) => {
			resolve();
		}).catch((error: any) => {
			dispatch(showNotification("Error changing form status", "error", String(error && error?.message)));
		});
	}
}

export const versionStatusUpdatePicForm = (formStatus: string, patientFormId: string) => {
	const picFormInfo = { "formStatus": formStatus };
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return call("PATCH", `/admin/v1/practice/configuration/pic-form/${patientFormId}`, picFormInfo).then((payload) => {
			resolve();
		}).catch((error: any) => {
			dispatch(showNotification("Error changing form status", "error", String(error && error?.message)));
		});
	}
}

export const addQuestionnaireQuestions = (data: any, patientFormId: string) => {
	const questionRequest = Object.assign(data);
	questionRequest.questionnaireId = patientFormId;
	questionRequest.answerOption = questionRequest.answerOption?.filter((answerOption: any) => answerOption.answerText.trim() !== '');
	delete questionRequest.id;
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		return makeApiCall(dispatch, "POST", "/admin/v1/practice/configuration/pic-form/question", questionRequest).then(() => {
			resolve();
		}).catch((error: any) => {
			dispatch(showNotification("Error creating form question ", "error", String(error && error?.message)));
		});
	}
}

export const updateQuestion = (data: any, patientFormId: string) => {
	const questionRequest = Object.assign({}, data);
	questionRequest.questionnaireId = patientFormId;
	questionRequest.questionId = data.id;
	questionRequest.answerOption = questionRequest.answerOption?.filter((answerOption: any) => answerOption.answerText.trim() !== '');
	delete questionRequest.id;
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		return call("PATCH", "/admin/v1/practice/configuration/pic-form/question", questionRequest).then((payload) => {
			resolve();
		}).catch((error: any) => {
			dispatch(showNotification("Error updating form question", "error", String(error && error?.message)));
		});
	}
}

export const getQuestion = (questionId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		return call("GET", `/admin/v1/practice/configuration/pic-form/question/${questionId}?practiceId=${practiceId}`).then((payload) => {
			resolve();
		}).catch((error: any) => {
			dispatch(showNotification("Error getting form question", "error", String(error && error?.message)));
		});
	}
}

export const deleteQuestion = (questionId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		return makeApiCall(dispatch, "DELETE", `/admin/v1/practice/configuration/pic-form/question/${questionId}?practiceId=${practiceId}`).then(() => {
			resolve();
		}).catch((error: any) => {
			dispatch(showNotification("Error deleting form question", "error", String(error && error?.message)));
		});
	}
}

export const deleteAnswerOption = (answerOptionId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		return makeApiCall(dispatch, "DELETE", `/api/admin/v1/practice/configuration/pic-forms/answer-options/${answerOptionId}?practiceId=${practiceId}`).then(() => {
			resolve();
		}).catch((error: any) => {
			dispatch(showNotification("Error deleting form answer option", "error", String(error && error?.message)));
		});
	}
};

export const deleteQuestionWithAnswerOptions = (question: any) => {
	const answerOptions: any[] = question?.answerOption;
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		if (answerOptions.length > 0) {
			Promise.all(answerOptions.filter((answerOption: any) => {
				return (answerOption.id !== '');
			}).map((answerOption: any) => {
				dispatch(deleteAnswerOption(answerOption.id));
				return answerOption;
			})).then(() => {
				dispatch(deleteQuestion(question.id));
			}).catch((error: any) => {
				dispatch(showNotification("Error deleting question with answer options", "error", String(error && error?.message)));
			});
		}
	}
};

export const createNewQuestionOptions = (data: any) => {
	const newQuestions: any[] = data?.questions.filter(
        (question: any) => question.id === "" && question.questionText !== ""
    );
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		if (newQuestions.length > 0) {
			Promise.all(newQuestions.map((question: any) => {
				dispatch(addQuestionnaireQuestions(question, data?.id));
				dispatch(showNotification("Adding Question", "success", ""));
				return question;
			})).then(() => {
				//Reload form data after questions are added, currently service does not return enough data to just locally after adding
				dispatch(fetchPatientForm(data?.id));
			}).catch((error: any) => {
				dispatch(showNotification("Error Adding Question", "error", String(error && error?.message)));
			});
		}
	}
}

export const saveQuestionnaireAppointmentTypes = (data: any[]) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		if (data.length) {
			return makeApiCall(dispatch, "POST", "/admin/v1/practice/configuration/questionnaire-appointment-types", data).then((payload) => {
				dispatch(showNotification("Form Appointment Types Updated", "success", ""));
			}).catch(err => {
				dispatch(showNotification("Error", "error", String(err && err?.message)));
			});
		}
	}
}

export const saveQuestionnaireTriggers = (data: any[]) => {
	const inserts: any[] = data.filter((e: any) => !e.questionnaireRuleId);
    const updates: any[] = data.filter((e: any) => e.questionnaireRuleId);
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		if (inserts.length) { dispatch(addQuestionnaireTriggers(inserts)); }
		if (updates.length) { dispatch(editQuestionnaireTriggers(updates)); }
	};
}

export const setFormErrors = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { configuration } = getState();
		const currentFormErrors = configuration.formErrors;
		const type = data.type;
		const value = data.value;
		const newFormErrors = Object.assign({}, currentFormErrors);
		newFormErrors[type] = value;
		dispatch({ type: 'SET_FORM_ERRORS', payload: { formErrors: newFormErrors } });
	}
}

export const addQuestionnaireTriggers = (data: any[]) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { configuration } = getState();
		const sPatientFormATriggers = configuration.sPatientFormATriggers;
		return makeApiCall(dispatch, "POST", "/admin/v1/practice/configuration/pic-forms/rules/trigger-configuration", data).then((payload) => {
			dispatch(showNotification("Form Triggers Added", "success", ""));
			dispatch({ type: 'SET_SELECTED_PATIENT_FORM_TRIGGERS', payload: { sPatientFormATriggers: [...sPatientFormATriggers, ...payload] } });
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err?.message)));
		});
	}
}

export const setNewVersionNumber = (patientForm: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		dispatch({ type: 'SET_NEW_VERSION_NUMBER', payload: { sPatientFormA: patientForm } });
	}
}

export const createNewVersionNumber = (id: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		return makeApiCall(dispatch, "POST", `/admin/v1/practice/configuration/pic-form/${id}/version`).then((payload) => {
			const newPath = `/admin/configurations/patientforms/${payload?.id}`;
			window.history.replaceState(null, document.title, newPath);
			dispatch(showNotification("New Form Number Created", "success", ""));
			dispatch(setNewVersionNumber(payload));
		}).catch((error: any) => {
			dispatch(showNotification("Can Not Make Form Editable", "error", String(error && error?.message)));
		});
	}
}

export const getAllVersionOfForm = (formName: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/pic-forms/versions/${formName}`).then((payload) => {
			dispatch({ type: "GET_ALL_FORM_VERSIONING", payload: { allFormVersioning: payload } });
		}).catch((error: any) => {
			dispatch(showNotification("Can Not Get All Form Versions", "error", String(error && error?.message)));
		});
	}
}

export const editQuestionnaireTriggers = (data: any[]) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { configuration } = getState();
		const sPatientFormATriggers = configuration.sPatientFormATriggers;
		return makeApiCall(dispatch, "PATCH", "/admin/v1/practice/configuration/pic-forms/rules/trigger-configuration", data).then((payload) => {
			dispatch(showNotification("Form Triggers Updated", "success", ""));
			const merged = sPatientFormATriggers.concat(payload).reduce((acc: any, x: any) => {
				acc[x.id] = Object.assign(acc[x.id] || {}, x);
				return acc;
			}, {});
			dispatch({ type: 'SET_SELECTED_PATIENT_FORM_TRIGGERS', payload: { sPatientFormATriggers: Object.values(merged) } });
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err?.message)));
		});
	}
}

export const fetchAppointmentTypes = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/appointment-types?practiceId=${practiceId}`, {},
			"EVisitsTypeListing.Actions.FetchEVisitsType", "EVisitsTypeListing.Actions.FetchEVisitsTypeDesc").then((payload) => {
				dispatch({ type: 'SET_VISIT_TYPES', payload: { visitTypes: payload } });
			}).catch(err => {
				dispatch(showNotification("Get All eVisit Types", "error", String(err && err?.message)));
			});
	}
}

export const fetchAllNotificationTriggers = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/pic-forms/notification-triggers?practiceId=${practiceId}`, {},
			"AppointmentTypeListing.Actions.FetchFormNotification", "AppointmentTypeListing.Actions.FetchFormNotificationDesc").then((payload) => {
				dispatch({ type: 'SET_NOTIFICATION_TRIGGERS', payload: { notificationTriggers: payload } });
			}).catch(err => {
				dispatch(showNotification("Get All Notification Triggers", "error", String(err && err?.message)));
			});
	}
}

export const createQuestionnaireNotificationTriggers = (insertTriggers: any[]) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		return makeApiCall(dispatch, "POST", "/admin/v1/practice/configuration/pic-forms/notification-triggers", insertTriggers).then((payload) => {
			dispatch(showNotification("Form Triggers Created", "success", ""));
		}).catch(err => {
			dispatch(showNotification("Create Questionnaire Notification Triggers", "error", String(err && err?.message)));
		});
	}
}

export const updateQuestionnaireNotificationTriggers = (modifyTriggers: any[]) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		return makeApiCall(dispatch, "PUT", "/admin/v1/practice/configuration/pic-forms/notification-triggers", modifyTriggers).then((payload) => {
			dispatch(showNotification("Form Triggers Updated", "success", ""));
		}).catch(err => {
			dispatch(showNotification("Update Questionnaire Notification Triggers", "error", String(err && err?.message)));
		});
	}
}

export const deleteQuestionnaireNotificationTriggerById = (questionnaireNotificationTriggerId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		return makeApiCall(dispatch, "DELETE", `/admin/v1/practice/configuration/pic-forms/notification-triggers/${questionnaireNotificationTriggerId}`).then((payload) => {
			dispatch(showNotification("Form Triggers Deletion", "success", ""));
		}).catch(err => {
			dispatch(showNotification("Delete Questionnaire Notification Triggers", "error", String(err && err?.message)));
		});
	}
}

export const setQuestionnaireNotificationTriggers = (notificationTriggers: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_QUESTIONNAIRE_NOTIFICATION_TRIGGERS', payload: { questionnaireNotificationTriggers: notificationTriggers } });
	}
}

export const fetchQuestionnaireNotificationTriggers = (questionnaireId: string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = auth.currentPractice?.id;
		dispatch(setQuestionnaireNotificationTriggers([]));
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/pic-forms/notification-triggers/${questionnaireId}?practiceId=${practiceId}`).then((payload) => {
			dispatch(setQuestionnaireNotificationTriggers(payload));
		}).catch(err => {
			dispatch(showNotification("Get Questionnaire Notification Triggers", "error", String(err && err?.message)));
		});
	}
}

export const fetchRegistrationEmails = (type: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;
		switch (type) {
			case 'PATIENT_REGISTRATION':
				dispatch({ type: 'SET_REGISTRATION_EMAIL_PATIENT', payload: { registrationEmailPatients: {} } });
				break;
			case 'PROVIDER_REGISTRATION':
				dispatch({ type: 'SET_REGISTRATION_EMAIL_CLINICAL_STAFF', payload: { registrationEmailProvider: {} } });
				break;
		}
		return makeApiCall(dispatch, "GET", `/admin/v1/practice/configuration/registration?practiceId=${practiceId}&type=${type}`, {},
			"RegistrationEmails.Actions.FetchRegistrationEmails", "RegistrationEmails.Actions.FetchRegistrationEmailsDesc").then((payload) => {
				payload.logoUrl = payload.logoUrl + "?" + Date.now()
				switch (type) {
					case 'PATIENT_REGISTRATION':
						dispatch({ type: 'SET_REGISTRATION_EMAIL_PATIENT', payload: { registrationEmailPatients: payload } });
						break;
					case 'PROVIDER_REGISTRATION':
						dispatch({ type: 'SET_REGISTRATION_EMAIL_CLINICAL_STAFF', payload: { registrationEmailProvider: payload } });
						break;
				}
			}).catch(err => {
				dispatch(showNotification("Get Registration Emails", "error", String(err && err?.message)));
			});
	}
}

export const setSelectedRegistrationEmails = () => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { registrationEmailProvider } = getState().configuration
		const registrationProviderData = { ...registrationEmailProvider };
		dispatch({ type: 'SET_SELECTED_REGISTRATION_EMAIL_CLINICAL_STAFF', payload: { sRegistrationEmailProviderA: registrationProviderData } })

		const { registrationEmailPatients } = getState().configuration
		const registrationPatientsData = { ...registrationEmailPatients };
		dispatch({ type: 'SET_SELECTED_REGISTRATION_EMAIL_PATIENT', payload: { sRegistrationEmailPatientsA: registrationPatientsData } })
	}
}

export const storeRegistrationEmailsProviderData = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		dispatch({ type: 'SET_SELECTED_REGISTRATION_EMAIL_CLINICAL_STAFF', payload: { sRegistrationEmailProviderA: data } })
	}
}

export const storeRegistrationEmailsPatientData = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		dispatch({ type: 'SET_SELECTED_REGISTRATION_EMAIL_PATIENT', payload: { sRegistrationEmailPatientsA: data } })
	}
}

export const saveRegistrationEmails = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth, admin } = getState();
		const practiceId = admin.configurePracticeId ? admin.configurePracticeId : auth.currentPractice?.id;

		const dataToBeSubmitted: any = {};
		dataToBeSubmitted.body = data.body
		dataToBeSubmitted.greeting = data.greeting
		dataToBeSubmitted.closing = data.closing
		dataToBeSubmitted.signature = data.signature
		dataToBeSubmitted.subject = data.subject
		dataToBeSubmitted.type = data.type
		dataToBeSubmitted.emailFrom = data.emailFrom
		//type: PATIENT_REGISTRATION, PROVIDER_REGISTRATION
		return makeApiCall(dispatch, "PUT", `/admin/v1/practice/configuration/registration?practiceId=${practiceId}`, dataToBeSubmitted).then((payload) => {
			dispatch(showNotification("Registration Updated successfully", "success", ""))
			dispatch(fetchRegistrationEmails(dataToBeSubmitted.type))
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err?.message)))
		});
	}
}

export const setConfugrationSettings = () => {
	return async (dispatch: ThunkDispatch<{}, {}, any>) => {
		// Configuration
		dispatch(fetchAppointmentTypes());
		dispatch(fetchAllNotificationTriggers());
		dispatch(fetchCancellationTypes());
		dispatch(fetchWaitingRoom());
		dispatch(fetchPatientForms());
		dispatch(fetchAllRules());
		dispatch(fetchRegistrationEmails("PATIENT_REGISTRATION"));
		dispatch(fetchRegistrationEmails("PROVIDER_REGISTRATION"));
	}
}
export const setConfigSetupFlagActive = (isFlagActive: boolean) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_CONFIG_SETUP_FLAG_ACTIVE', payload: { configSetupFlagActive: isFlagActive } })
	}
}