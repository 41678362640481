/** Popover is designed to provide a MUI Popper with arrow.
 *  It is responsible for the placement (what is provided) and
 *  have a content area with background and text colors for
 *  dark and light variants.
 */
import { Popper, PopperProps } from "@mui/material";
import { useOutsideClickHandler } from "common/utils/use-outside-click-handler";
import { useState } from "react";
import { useStyles } from "./styles";
import * as CSS from "./class-names";
import classNames from "classnames";

interface PopoverProps extends PopperProps {
    id: string;
    /** This offset accounts for the size of the arrow.
     *  First number is how many pixels should it be shifted paralel with the side of the reference
     *  Second number is how many pixels we want it away from the reference
     */
    offset?: [number, number];
    onClose: () => void;
    variant?: "dark" | "light";
}

const Popover: React.FC<PopoverProps> = ({
    anchorEl,
    children,
    className,
    onClose,
    id,
    offset,
    open,
    placement,
    variant = "light",
}) => {
    const styles = useStyles();
    const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const popperModifiers = [
        { name: "preventOverflow", options: { boundary: "clippingParents" } },
        { name: "arrow", options: { element: arrowElement, padding: 16 } },
        { name: "offset", options: { offset: offset ?? [0, 6] } },
        { name: "flip" },
        { name: "hide" },
    ];

    useOutsideClickHandler([anchorEl as HTMLElement, arrowElement, popperElement], open, onClose);
    
    return (
        <Popper
            id={id}
            anchorEl={anchorEl}
            className={classNames(styles.Popover, className, { [`Popover--${variant}`]: variant })}
            data-testid={id}
            modifiers={popperModifiers}
            open={open}
            placement={placement}
            ref={setPopperElement}
        >
            <div className={CSS.PopoverArrow} ref={setArrowElement} />
            <div className={CSS.PopoverContent}>{children}</div>
        </Popper>
    );
};

export default Popover;
