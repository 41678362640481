import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { FormattedMessage } from "react-intl";
import { Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { AppReduxStore, Product } from "store/reducerTypes";
import Card from "components/Dashboard/Card/Card";
import { getConfig, ConfigType } from "./config";
import { isPCR, permission } from "views/SideNav/sideNavGuard";

// This section is copied here from sideNavGuard temporary -->
const userHasAccessByPermissions = (requiredPermissions: string[], availablePermissions: string[]) =>
    requiredPermissions.length === 0 ||
    requiredPermissions.every((permission) => availablePermissions.includes(permission));

/** TODO Replace this with an easier solution by removing calling and importing deprecated configurations */
const defineSideNav = (configs: ConfigType, products: Product[] = []): ConfigType => {
    const codes = products.map((product) => product.code);
    return Object.keys(configs).reduce((filteredNavItemCollection: ConfigType, navItemId: string) => {
        /* TODO Move all permissions to configuration and remove this legacy solution
         * Maybe it can be refactored for usePermission (usePermissions? should be created)
         */
        let hasPermissionLegacy =
            permission[navItemId] === "" || userHasAccessByPermissions(permission[navItemId], codes);
        if (navItemId === "education") {
            hasPermissionLegacy =
                permission[navItemId].length === 0 || permission[navItemId].some((p: any) => codes.includes(p));
        }

        if (hasPermissionLegacy) {
            filteredNavItemCollection[navItemId] = configs[navItemId];
        }
        return filteredNavItemCollection;
    }, {});
};
// <-- This section is copied here from sideNavGuard temporary

const Dashboard = () => {

    const { user } = useSelector((store: AppReduxStore) => store)
    const { currentProfileRoleJoin } = user
    const { providerProfile } = currentProfileRoleJoin as any
    const { firstName: name } = providerProfile || user
    const [configuration, setconfiguration] = useState<ConfigType | null>(null)
    const [isPCRStatus, setIsPcr] = useState<boolean>(false)
    const classes = useStyles({ isPCRStatus })
    
    useEffect(() => {
        const isPCRValue = isPCR(currentProfileRoleJoin?.products || []);
        setIsPcr(isPCRValue)
        const config = getConfig(isPCRValue)
        //@ts-ignore
        setconfiguration(defineSideNav(config, currentProfileRoleJoin?.products))
   
    }, [])

    return (
        <div className={classes.root}>
            <div>
                <Typography className={classes.doctorName} >
                    <FormattedMessage id="HomePage.label.hello" values={{ name }} />
                </Typography>
                <Typography className={classes.desc} >
                    <FormattedMessage id={`HomePage.label.${!isPCRStatus ?'desc':'descPCR'}`} /> 
                </Typography>
            </div>

            <div className={classes.cardWrapper}>
                <Grid container spacing={10} >
                    {configuration && Object.keys(configuration).map((module: keyof ConfigType) => {
                        return <Card
                            key={module}
                            {...configuration[module]}
                            isPCRStatus={isPCRStatus}
                        />
                    })}
                </Grid>
            </div >
        </div >
    )
}

export default Dashboard