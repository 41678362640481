import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "admin/theme";


export const useStyles = makeStyles(() =>({
    wrapperAuto: {
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    wrapper: {
        maxWidth: "640px",
        minWidth: "640px",
        minHeight: "150px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 'bold',
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    dlgAction: {
        padding: "25px 32px 15px 32px"
    },
    dlgAction2: {
        padding: "0px 25px 10px 25px"
    },
    button1:{
        marginRight: "8px"
    },
    cancel:{
        marginRight: "8px"
    },
    enableBtn:{
        color: "red"
    },
    disableBtn:{
        color: "#1976d2"
    },
    iconBtn:{
        padding: "0px"
    }
}));