import "./use-color.css";
import { COLORS } from "theme";
import classNames from "classnames";
import { ValueOf } from "types";

type ColorTokens = keyof typeof COLORS;

const CSS_CONSTANTS = {
    svgFillColor: {
        variable: "--useColor__svgFillColor",
        className: "useColor__svgFillColor",
    },
} as const;

type UseColorProps = {
    color?: ColorTokens | undefined,
    backgroundColor?: ColorTokens | undefined,
    svgFillColor?: ColorTokens | undefined
}

const getColorValue = (colorToken?: ColorTokens): ValueOf<typeof COLORS> | null  =>
    colorToken ? COLORS[colorToken] : null;

export const useColor = (colors: UseColorProps) => {
    const style = {
        color: getColorValue(colors.color),
        backgroundColor: getColorValue(colors.backgroundColor),
        [CSS_CONSTANTS.svgFillColor.variable]: getColorValue(colors.svgFillColor)
    };

    return {
        style: style as React.CSSProperties,
        className: classNames({
            [CSS_CONSTANTS.svgFillColor.className]: colors.svgFillColor
        }),
    };
};
