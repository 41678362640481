import React from "react";
import { Tooltip, Checkbox } from "@mui/material";
import { ReactComponent as CheckboxChecked } from "icon-library/SVG/Checkbox_Square.svg";
import { ReactComponent as CheckboxUnchecked } from "icon-library/SVG/Checkbox-Light_Square.svg";
import classNames from "classnames";
import SVGIcon from "common/components/svg-icon/svg-icon";
import ContextMenu, { ContextMenuProps } from "lib/ui-components/context-menu/context-menu";
import * as CSS from "./class-names";

export type TableListItemProps = {
    className?: string;
    contextMenuItems?: ContextMenuProps["menuItems"];
    disabledMessage?: boolean;
    id: string;
    idPrefix: string;
    onSelectChange?: (id: string, selected: boolean) => void;
    /**
     * selectDisabled only the select checkbox.
     * It is differs from a generic disabled
     * where it would disabled all interactions with a component
     * */
    selectDisabled?: boolean;
    selected?: boolean;
    selectLabel?: string;
    unselectLabel?: string;
    /**
     * Small text to be displayed under the context menu
     */
    tag?: string;
};

export const TableListItem: React.FC<TableListItemProps> = ({
    children,
    className,
    contextMenuItems,
    disabledMessage = "",
    id,
    idPrefix,
    onSelectChange,
    selectDisabled,
    selected,
    selectLabel,
    unselectLabel,
    tag,
}) => {
    const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (onSelectChange) {
            onSelectChange(id, checked);
        }
    };

    const rootClassName = classNames(className, CSS.TableListItem, {
        [CSS.TableListItemSelected]: selected,
    });
    const shouldShowDisabledMessage = selectDisabled && disabledMessage !== "";

    return (
        <tr className={rootClassName}>
            {onSelectChange && (
                <td>
                    <Tooltip title={shouldShowDisabledMessage ? disabledMessage : ""}>
                        {/* this extra span is necessary, because tooltip will not appear when the inner element is selectDisabled */}
                        <span>
                            <Checkbox
                                id={`${idPrefix}_item_checkbox_${id}`}
                                aria-label={selected ? unselectLabel || selectLabel : selectLabel}
                                checked={selected}
                                onChange={handleChange}
                                disabled={selectDisabled}
                                color="primary"
                                classes={{ root: CSS.TableListItemCheckbox }}
                                disableRipple
                                disableFocusRipple
                                icon={<SVGIcon svg={CheckboxUnchecked} size="scale800" />}
                                checkedIcon={<SVGIcon svg={CheckboxChecked} size="scale800" />}
                            />
                        </span>
                    </Tooltip>
                </td>
            )}
            {children}
            {contextMenuItems && (
                <td>
                    <div className={CSS.TableListItemContextMenuCell}>
                        <ContextMenu
                            id={`${idPrefix}_context_menu_${id}`}
                            className={CSS.TableListItemContextMenu}
                            menuItems={contextMenuItems}
                        />
                        { tag && <span className={CSS.TableListItemTag}>{tag}</span> }
                    </div>
                </td>
            )}
        </tr>
    );
};
