import React from "react";
import { Popover } from "@mui/material";

import { useStyles } from "./styles";
import { Props } from "./types";

/** @deprecated Please, use or replace with lib/ui-components/popover/popover */
export default function SimplePopover(props: Props) {
  const {
    renderElement = "",
    renderContent = "",
    arrowColor = "#fff",
    arrowPosition = "center",
    arrowDirection='top',
    ...other
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (target: any) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {/* <Element onClick={handleClick} close={handleClose}/> */}
      {!!renderElement && renderElement(handleClick, handleClose,open)}
      <Popover
        classes={{ paper: classes.paper }}
        id={id}
        BackdropProps={{ className:classes.backdrop}}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
        {...other}
      >
        {!!renderContent && renderContent(handleClose)}
        <span
          className={["arrow", arrowPosition,`dir-${arrowDirection}`].join(" ")}
          style={{ borderBottomColor: arrowColor }}
        ></span>
      </Popover>
    </>
  );
}
