import {sortBy} from "underscore"
import {AppThunk} from "../reducerTypes";
import {NotificationItem} from "../notification-types";
import {setError} from "./error";
import {call} from "../api";
import {NOTIFICATION_TYPE} from "constant";
import { updatePatientEvisitDetails,getWaitingRoomDetails } from "store/actions"
import { ListItem  } from "components/MultiSelectPopoverInput/types";

// getNotifications makes a request to fetch a list of notifications and filters out any unknown notifications
export const getNotifications = (initialLoad?:boolean): AppThunk => {
    return async (dispatch, getState) => {
        const {user:{ currentProfileId },patient:{ selectedPatient },waitingRoom:{ providerFilterIds }} = getState()
        try {
            const notifications: NotificationItem[] | null =  await call("GET", "/patient/v1/notifications/" + currentProfileId)

            const notificationsFiltered = notifications?.filter((item) => {
                // TODO need to remove item.readDate===null condition, it should be handle in backend
                return !!NOTIFICATION_TYPE[item.type] && item.readDate===null
            }) || [];

            // TODO items should be sorted on the backend
            const items = sortBy(notificationsFiltered, (item) => {
                return new Date(item.createdDate).getTime()
            }).reverse()
            
            //refresh waiting room banner of current patient details
            !initialLoad&&dispatch(refreshWaitingRoomDetails(items[0],selectedPatient))
     
            //refresh NRU waiting room details
            !initialLoad&&dispatch(refreshNRUWaitingRoomDetails(items[0],providerFilterIds))

            dispatch({type: "GET_NOTIFICATIONS", payload: {items}})
        } catch (e: any) {
            dispatch(setError("Error.message.searchConversations"))
        }
    }
}

export const updateNotificationReadStatus = (notificationRecipientId: string): AppThunk => {
    return async (dispatch, getState) => {
        try {
            await call("PUT", "/patient/v1/notification-recipient/" + notificationRecipientId);

            const { items } = getState().notification;

            const newItems = items.filter((notification) => notification.notificationRecipientId !== notificationRecipientId);

            dispatch({type: "GET_NOTIFICATIONS", payload: { items: newItems }});
        } catch (e: any) {
            dispatch(setError("Error.message.updateNotificationReadStatus"));
        }
    }
}

export const updateNotificationReadStatusByPredicate = (predicate: (notification: NotificationItem) => boolean): AppThunk => {
    return (dispatch, getState) => {
        const { items } = getState().notification;
        const notification = items.find(predicate);
        if (notification) {
            dispatch(updateNotificationReadStatus(notification.notificationRecipientId));
        }
    }
}

export const refreshWaitingRoomDetails = (notificationDetails: any, currentPatientDetails: any): AppThunk => {
    return async (dispatch) => {

        const { id = "", evisitDetails = {} } = currentPatientDetails || {};

        if (notificationDetails) {

            const { type = "", waitingRoomDTO = "" } = notificationDetails;

            const { patientId = "" } = waitingRoomDTO || {};

            if (type === NOTIFICATION_TYPE.WAITING_ROOM && patientId === id && Object.keys(evisitDetails || {}).length === 0) {

                const evisitDetails = await updatePatientEvisitDetails(patientId, dispatch);

                dispatch({ type: 'UPDATE_PATIENT_EVSIT_DETAILS', payload: evisitDetails })

            }

        }

    }
}
export const refreshNRUWaitingRoomDetails = (notificationDetails: any,providerFilterIds:ListItem[]|null): AppThunk => {
    return async (dispatch) => {

        if (notificationDetails) {

            const { type = "" } = notificationDetails;

            if (type === NOTIFICATION_TYPE.NRU_WAITING_ROOM ) {

                const result = await getWaitingRoomDetails(dispatch, providerFilterIds);

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })

            }

        }

    }
}