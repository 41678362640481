import createReducer from '../createReducer'
import { OrganizationState } from '../reducerTypes'

const initialState: OrganizationState = {
    organizations: [],
    childOrganizations: [],
    childOrganizationSummaries: [],
    profileRootOrganizationSummaries: [],
    entitiesTreeList: [],
    sOrganization: {},
    organizationProfileEmails: [],
    toggleWelcomeTooltip: false
}
const formatHierarchicalData = (listHierarchy: any[]) => {
    const list = [...listHierarchy] as any;
    const map = {} as any, rootEntities = [];
    for (let i = 0; i < list.length; i += 1) {
        map[list[i].organizationId ?? list[i].practiceId] = i
        list[i].children = []
    }
    for (let i = 0; i < list.length; i += 1) {
        const node = list[i];
        if ((node.parentOrgId !== null) && (map[node.parentOrgId] !== undefined)) {
            node.level = 0
            let childNode = null as any
            const stackAncestorNode = [{ ...node }]
            while (stackAncestorNode.length > 0) {
                childNode = stackAncestorNode.pop()
                const nodeParent = list[map[childNode.parentOrgId]]
                if (nodeParent !== undefined && nodeParent !== null) {
                    node.level = node.level + 1
                    stackAncestorNode.push({ ...nodeParent });
                }
            }
            list[map[node.parentOrgId]].children.push(node)
        } else {
            node.level = 0
            rootEntities.push(node);
        }
    }
    return rootEntities;
}
export default createReducer(initialState, {

    'SET_ORGANIZATIONS': (state: OrganizationState, payload: OrganizationState): OrganizationState => {
        const { organizations } = payload
        return {
            ...state,
            ...payload,
            organizations
        }
    },

    'SET_CHILD_ORGANIZATIONS': (state: OrganizationState, payload: OrganizationState): OrganizationState => {
        const { childOrganizations } = payload
        return {
            ...state,
            ...payload,
            childOrganizations
        }
    },
    'SET_CHILD_ORGANIZATION_SUMMARIES': (state: OrganizationState, payload: OrganizationState): OrganizationState => {
        const { childOrganizationSummaries } = payload
        return {
            ...state,
            ...payload,
            childOrganizationSummaries,
            entitiesTreeList: formatHierarchicalData(childOrganizationSummaries)
        }
    },
    'SET_PROFILE_ROOT_ORGANIZATION_SUMMARIES': (state: OrganizationState, payload: OrganizationState): OrganizationState => {
        const { profileRootOrganizationSummaries } = payload
        return {
            ...state,
            ...payload,
            profileRootOrganizationSummaries
        }
    },
    'SET_SELECTED_ORGANIZATION': (state: OrganizationState, payload: OrganizationState): OrganizationState => {
        const { sOrganization } = payload
        return {
            ...state,
            ...payload,
            sOrganization
        }
    },
    'SET_ORGANIZATION_ADMIN_PROFILE_EMAILS': (state: OrganizationState, payload: OrganizationState): OrganizationState => {
        const { organizationProfileEmails } = payload
        return {
            ...state,
            ...payload,
            organizationProfileEmails
        }
    },
    'TOGGLE_WELCOME_TOOLTIP': (state: OrganizationState, payload: OrganizationState): OrganizationState => {
        const { toggleWelcomeTooltip } = payload
        return {
            ...state,
            ...payload,
            toggleWelcomeTooltip
        }
    }
})