import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F6F6F7',
    color: '#6E6E79',
    borderRadius: '8px',
    boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    cursor: 'pointer',
    color: (props: any) => props.y !== 0 ? '#FFFFFF' : '#393948',
    background: (props: any) => props.y !== 0 ? '#073D5E' : 'transparent',
    fontSize: '14px',
    fontWeight: 'normal',
    border: '2px solid #0F7BBD',
    borderRadius: '4px',
    padding: '4px'
  },
  iconBtn: {
    padding: '2px'
  },
  listText: {
    color: '#393948',
    fontSize: '16px',
    fontWeight: "bold"
  },
  menuTitle: {
    color: '#6E6E79',
    fontSize: '14px',
    padding: '8px 16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '-0.41px'
  },
  menuContent: {
    padding: '16px',
    backgroundColor: '#F6F6F7',
    color: '#393948',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3)), drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2))',
    overflowX: "unset",
    overflowY: "unset"
  }
}));