import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    headerWrapper: {
        padding: SIZING.scale600,
        display: "flex",
        alignItems: "center",
        flexDirection: 'column',
        background: COLORS.LEARNING_CENTER_HEADER_BG,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
    },
    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: '100%'
    },
    desc: {
        fontSize: SIZING.scale400,
        fontWeight: 600,
        color: COLORS.GREY,
        marginTop: SIZING.scale200
    },
    close: {
        cursor: 'pointer'
    },
    titleWrapperSection: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        marginTop: SIZING.scale600,
    },
    titleWrapperText: {
        fontWeight: 600,
        fontSize: SIZING.scale400,
        marginBottom: SIZING.scale200,
        width: SIZING.scale2600

    },
    send: {
        background: COLORS.DEFAULT_BTN,
        "&:hover": {
            background: COLORS.DEFAULT_BTN + '!important',
        }
    },
    saveDuration: {
        display: "flex"
    },
    contentWrapper: {
        background: COLORS.PNG_MODAL_BG,
        minHeight: SIZING.scale15000,
        paddingLeft: '0',
        paddingRight: '0',
        "& .custom-multiselect-wrapper": {
            marginTop: SIZING.scale300,
        }
    },
    inputWrapper: {
        padding: `0 ${SIZING.scale400}`,
        width: "74%",
        "& .MuiInput-input": {
            color: COLORS.BLACK,
            "&::placeholder": {
                color: COLORS.LGREY1,
            },
        }
    },
    contentChild: {
        paddingLeft: SIZING.scale600,
        paddingRight: SIZING.scale400
    },
    patientTitle: {
        fontSize: SIZING.scale350,
        fontWeight: 600,
        color: COLORS.CONVERSATION_TEXT
    },
    searchWrapSection: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: SIZING.scale450
    },
    divider: {
        height: SIZING.scale25,
        width: '100%',
        background: COLORS.MIDDLE_GRAY,
        margin: `${SIZING.scale700} 0`,
    },
    inputElement: {
        width: '100%',
        "& .MuiOutlinedInput-input": {
            padding: 0,
            minHeight: SIZING.scale1000,
            color: COLORS.BLACK,
            "&::placeholder": {
                color: COLORS.MULTI_SELECT_BG,
            },

        }
    },
    providerSelectTitle: {
        marginTop: SIZING.scale100,
        opacity: ({ currentProvider }: any) => currentProvider ? '0.4' : 'unset'
    },
    dateSelectTitle: {
        marginTop: SIZING.scale700,
        marginBottom: SIZING.scale400,
    },
    checkBoxWrapper: {
        display: 'flex',
        marginTop: SIZING.scale300,
        alignItems: 'flex-start',
        "& .MuiFormControlLabel-root": {
            marginLeft: 'unset',
            width: '5.563rem',
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: COLORS.PNG_MODAL_BG,
            color: "currentColor"
        },
        "& .checkboxInput": {
            flex: 1
        }
    },
    preview: {
        fontWeight: 'normal',
        marginBottom: SIZING.scale300
    },
    error: {
        color: COLORS.ERROR_TEXT,
        marginLeft: SIZING.scale400,
        marginTop: SIZING.scale100,
        display: 'flex',
        alignItems: 'center',
        "& .MuiSvgIcon-root": {
            width: SIZING.scale400,
            marginRight: SIZING.scale100,
        }
    },
    searchIcon: {
        "& .custom-multiselect-input-wrapper": {
            "& svg path": {
                fill: COLORS.LBLUE
            }
        }
    },
    providerSearch: {
        opacity: ({ currentProvider }: any) => currentProvider ? '0.4' : 'unset',
        pointerEvents: ({ currentProvider }: any) => currentProvider ? 'none' : 'unset'
    },
    providerCheckbox: {
        marginTop: SIZING.scale700,
        "& span": {
            fontSize: SIZING.scale350
        }

    },
    findBtn: {
        background: COLORS.PRIMARY_BTN_TEXT,
        "&:hover": {
            background: COLORS.PRIMARY_BTN_TEXT + '!important',
        },
        width: SIZING.TRANSCRIBE_MODAL_HEIGHT
    },
    findBtnPinned: {
        background: COLORS.LBLUE,
        "&:hover": {
            background: COLORS.LBLUE + '!important',
        },
        width: SIZING.TRANSCRIBE_MODAL_HEIGHT
    },
    messageInput: {
        minHeight: SIZING.scale7000
    },
    descriptionBox: {
        "& input": {
            color: COLORS.LIST_SUBHEADER_TEXT,
            fontSize: SIZING.scale400,
        },
        "& textarea": {
            padding: `${SIZING.scale100} ${SIZING.scale300}`,
            fontSize: SIZING.scale400,
            color: COLORS.LIST_SUBHEADER_TEXT,
            textIndent: 'unset'
        },
        "& .messageInput textarea": {
            minHeight: SIZING.scale7000
        }
    },

    inputLabel: {
        fontSize: SIZING.scale350,
        fontWeight: 600,
        color: COLORS.CONVERSATION_TEXT,
        marginBottom: SIZING.scale300
    }
}));