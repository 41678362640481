import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    header: {
        padding: SIZING.scale600,
        display: "flex",
        flexDirection:'column',
        background: COLORS.MODAL_HEADER_BG,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
    },
}))