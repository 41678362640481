import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "../../../theme";


export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.XL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        padding: '20px',
        textAlign: 'center',
        minWidth: '800px'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    dlgAction: {
        padding: '16px 32px'
    },
    confirmationBody: {
        color: '#393948',
        fontSize: '24px',
        fontWeight: 600
    }
}));