import React from "react";
import { Typography, Grid } from "@mui/material";
import { useStyles } from "./styles";
import { IFormInput } from "admin/views/viewsTypes";
import { FormattedMessage } from "react-intl";

type Props = {
  currentForm: IFormInput;
};

const GAD7: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.ScoringRules"} />
          </Typography>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.GAD7.Body1"} />
          </Typography>
          <ul style={{ margin: "5px" }}>
            <li><FormattedMessage id={"RulesAndLogic.GAD7.ScoreScale1"} /></li>
            <li><FormattedMessage id={"RulesAndLogic.GAD7.ScoreScale2"} /></li>
            <li><FormattedMessage id={"RulesAndLogic.GAD7.ScoreScale3"} /></li>
            <li><FormattedMessage id={"RulesAndLogic.GAD7.ScoreScale4"} /></li>
          </ul>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.GAD7.Body2"} />
          </Typography>
          <ul style={{ margin: "5px" }}>
          <li><FormattedMessage id={"RulesAndLogic.GAD7.ScoreScale5"} /></li>
            <li><FormattedMessage id={"RulesAndLogic.GAD7.ScoreScale6"} /></li>
            <li><FormattedMessage id={"RulesAndLogic.GAD7.ScoreScale7"} /></li>
            <li><FormattedMessage id={"RulesAndLogic.GAD7.ScoreScale8"} /></li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.GAD7.Body3"} />
          </Typography>
        </Grid>
        </Grid>
    </div>
  );
}
export default GAD7