import React, {useRef, useState} from "react";
import { AnyMenuItem } from "common/components/OptionMenu/types";
import {Button, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {useSelector, useDispatch} from "react-redux";
import {createSvgIcon} from "utils";
import {SIZING} from "theme";
import OptionMenu from "common/components/OptionMenu/OptionMenu";
import {ReactComponent as ToolBox} from "icon-library/SVG/Briefcase_ALT.svg";
import {ReactComponent as MicrophoneSvg} from "icon-library/SVG/Microphone.svg";
import {ReactComponent as VideoCameraSvg} from "icon-library/SVG/Camera_Video-Circle.svg";
import {ReactComponent as newEConsultRequestIcon} from "icon-library/SVG/eMail_Closed-Envelope.svg"
import {outgoingCall} from "store/actions";
import {AppReduxStore} from "store/reducerTypes";
import {useStyles} from "./styles";
import { FeatureFlagNames } from "lib/security/types";
import { MODAL_TYPES } from "constant";
import { Permissions } from "types";
import { useFeatureFlag } from "lib/security/feature-flag-hooks";
import { usePermission } from "lib/security/permission-hooks";
import { setActiveModal } from "store/actions";

// @ts-ignore
const { SUKI_URL } = window.env

const ToolBoxIcon = createSvgIcon(ToolBox)
const Microphone = createSvgIcon(MicrophoneSvg)
const VideoCamera = createSvgIcon(VideoCameraSvg)

const ToolsMenu = () => {
    const [toolsMenuOpen, setToolsMenuOpen] = useState(false)
    const dispatch = useDispatch()
    const toolsMenuRef = useRef(null)
    const classes = useStyles()
    const { selectedPatient } = useSelector((state: AppReduxStore) => state.patient)
    const hpiFormFeatureFlag = useFeatureFlag(FeatureFlagNames.HPI_FORM);
    const eConsultFeatureFlag = useFeatureFlag(FeatureFlagNames.E_CONSULT);
    const eTranscriptionPermission = usePermission(Permissions.ETRANSCRIPT);

    if (!selectedPatient) return null

    const { lastName, firstName, twilioIdentity, ehrPatientId } = selectedPatient

    const toolsMenuConfig: AnyMenuItem[] = [];

    if (!hpiFormFeatureFlag && eTranscriptionPermission) {
        toolsMenuConfig.push({
            id: "QA_PatientDetailsHeader.option.eTranscription",
            labelKey: "PatientDetailsHeader.option.eTranscription",
            icon: Microphone,
            onClick: () => window.open(`${SUKI_URL}&athena_patient_id=${ehrPatientId}`),
        });
    }

    if (twilioIdentity) {
        if (toolsMenuConfig.length && toolsMenuConfig[toolsMenuConfig.length - 1].id.indexOf("separator") === -1) {
            toolsMenuConfig.push({
                id: "QA_PatientDetailsHeader.option.separator.1",
                isSeparator: true,
            });
        }
        toolsMenuConfig.push({
            id: "QA_PatientDetailsHeader.option.eVisit",
            labelKey: "PatientDetailsHeader.option.eVisit",
            icon: VideoCamera,
            onClick: () =>
                dispatch(outgoingCall(twilioIdentity, `${firstName} ${lastName}`, "adhoc", selectedPatient)),
        });
    }

    if (eConsultFeatureFlag) {
        if (toolsMenuConfig.length && toolsMenuConfig[toolsMenuConfig.length - 1].id.indexOf("separator") === -1) {
            toolsMenuConfig.push({
                id: "QA_PatientDetailsHeader.option.separator.2",
                isSeparator: true,
            });
        }
        toolsMenuConfig.push({
            id: "QA_toolsmenu_item_new_econsult_request",
            labelKey: "SideNav.subMenuItem.newEConsultRequest",
            icon: newEConsultRequestIcon,
            onClick: () => dispatch(setActiveModal(MODAL_TYPES.PROVIDER.CREATE_E_CONSULT_REQUEST)),
        });
    }

    if (!toolsMenuConfig.length) {
        return null;
    }

    return (
        <>
            <Button variant="contained" color="primary" onClick={() => setToolsMenuOpen(!toolsMenuOpen)} id="QA_Tools" style={{marginRight: SIZING.scale200}} ref={toolsMenuRef}>
                <ToolBoxIcon className={classes.toolsIcon} />
                <Typography variant="button">
                    <FormattedMessage id="People.details.header.tools"/>
                </Typography>
            </Button>
            <OptionMenu
                handleClose={() => setToolsMenuOpen(false)}
                menuConfig={toolsMenuConfig}
                anchorEl={toolsMenuRef}
                open={toolsMenuOpen}
                variant="light"
            />
        </>
    )
}

export default ToolsMenu;
