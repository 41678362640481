import { ChatConversation } from "components/messaging/types";
import { EConsultRequestStatus, RecipientDetails, SenderDetails } from "../requests/types";
import { getChatStatusInfo } from "./utils";
import { MESSAGE_ATTACHMENT_IDS } from "components/messaging/constants";
import { messagingConversationFetchingBusyStateName } from "components/messaging/constants";
import { ReactComponent as ChatIcon } from "icon-library/SVG/Chat.svg";
import { useApi } from "common/utils/use-api";
import { useBusyState } from "common/utils/progress/progress-manager-hooks";
import { useConversation } from "components/messaging/messaging-hooks";
import { useUserNotification } from "common/utils/use-user-notification";
import ChatHistory from "components/messaging/chat-history";
import ComposeMessageForm from "components/messaging/compose-message-form";
import EmptyMessage from "common/components/empty-message/empty-message";
import RequestStatusInfoBox from "./request-status-info-box";
import Spinner from "common/components/Spinner/Spinner";
import SVGIcon from "common/components/svg-icon/svg-icon";

type ChatViewProps = {
    conversationId: string;
    recipient: RecipientDetails;
    requestId: string;
    requestStatus: EConsultRequestStatus;
    sender: SenderDetails;
};
type CreateConversationRequestType = {
    recipientId: string;
    requestId: string;
    message: string;
    messageAttributes: Record<string, any>;
};
type CreateConversationResponseType = {
    id: string;
};

const ChatView = ({
    conversationId,
    recipient,
    requestId,
    requestStatus,
    sender,
}: ChatViewProps) => {
    const { busyState, setBusy } = useBusyState(messagingConversationFetchingBusyStateName);
    const { requestFn } = useApi<CreateConversationRequestType, CreateConversationResponseType>(
        {
            defaultContent: { id: "new" },
            endpoint: "/econsult/v1/requests/:requestId/recipients/:recipientId/conversation",
            method: "POST",
        },
        {
            recipientId: sender.isReferrer ? recipient.id : sender.id,
            requestId,
            message: "",
            messageAttributes: {},
        }
    );
    const { conversation, onSendMessage, onTyping } = useConversation(conversationId);
    const { enqueueError } = useUserNotification();
    const isNewConversation = conversationId === "new";
    const isClosedConversation = conversation?.state?.current === "inactive";
    const { allowMessaging, notificationCode } = getChatStatusInfo(
        requestStatus,
        (conversation?.state?.current ?? "active") as ChatConversation["status"],
        sender.status,
        recipient.status,
        sender.isReferrer
    );

    // TODO: ideally this should be wrapper by useCallback,
    // but current impl of useApi makes it tricky, so
    // we need to change useApi first to give us a stable request function
    const onSubmitForm = (message: string, attachmentIds: string[] | undefined) => {
        return new Promise<void>((resolve, reject) => {
            if (isClosedConversation) {
                reject();
                enqueueError("EConsult.messages.error.conversationClosed");
                return;
            } 
            const attributes = {
                [MESSAGE_ATTACHMENT_IDS]: attachmentIds,
            };
            if (isNewConversation) {
                setBusy(true);
                requestFn({
                    // We should send always the partner ID, referrer ID is not allowed
                    recipientId: sender.isReferrer ? recipient.id : sender.id,
                    requestId,
                    message,
                    messageAttributes: attributes,
                })
                    .then((result) => {
                        if (result.id) {
                            resolve();
                        }
                        reject();
                    })
                    .catch(() => {
                        setBusy(false);
                        reject();
                    });
            } else {
                onSendMessage(message, attachmentIds)
                    .then(() => resolve())
                    .catch(reject);
            }
        });
    };

    return (
        <>
            {busyState ? (
                <Spinner />
            ) : !isNewConversation ? (
                <ChatHistory conversationId={conversationId} />
            ) : (
                <EmptyMessage
                    fluid
                    opacity={0.4}
                    Icon={
                        <SVGIcon
                            svg={ChatIcon}
                            opacity={0.4}
                            size="scale6400"
                            color="BLACK"
                            className="EConsult__messages__chat__emptyIcon"
                        />
                    }
                    iconTextSpacing="scale800"
                    primaryMessage={{
                        id:
                            notificationCode !== "closed"
                                ? "EConsult.messages.chat.emptyMessage"
                                : "EConsult.messages.chat.closedEmptyMessage",
                        variant: "emptyEConsultListBigPrimary",
                    }}
                />
            )}
            <div className="EConsult__messages__chat__activitySection">
                <RequestStatusInfoBox status={notificationCode} />
                {allowMessaging && <ComposeMessageForm allowAttachments={true} onSubmit={onSubmitForm} onTyping={onTyping} />}
            </div>
        </>
    );
};

export default ChatView;
