import React, { useState } from "react"
import { useStyles } from "./styles"
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ClickAwayListener, Box, Button, Typography, IconButton, InputBase, Paper, Grid } from "@mui/material";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import { ReactComponent as Ellipsis } from "icon-library/SVG/Ellipsis-Horizontal_Circle.svg";
import { ReactComponent as ClipBoard } from "icon-library/SVG/Clipboard_Check.svg";
import { ReactComponent as EllipsisVertical } from "icon-library/SVG/Ellipsis-Vertical.svg";
import CarePlanDropDown from "views/RemoteMonitoring/patient-details/rpm-dropdown/careplan-dropDown";


const CarePlanTab: React.FC = () => {

    const classes = useStyles()
    const [carePlanDrop, setCarePlanDrop] = useState(false);
    const [editCareplan, setEditCarePlan] = useState(false)



    const carePlan = [
        {
            title: "BLOOD SUGAR BALANCE",
            level: "140 mg/dL",
            timeInterval: "Measure Twice Daily"
        },
        {
            title: "WEIGHT GOAL",
            level: "165 lbs",
            timeInterval: "Until Aug 20, 2022"
        },
        {
            title: "DAILY STEPS",
            level: "10000 Steps",
            timeInterval: "Until Aug 20, 2022"
        },
        {
            title: "MEDICINE INTAKE",
            level: "Linagliptin",
            timeInterval: "5mg Daily"
        },
        {
            title: "BLOOD PRESSURE BALANCE",
            level: "120/80 mmHg",
            timeInterval: "Measure once daily"
        },
        {
            title: "BODY TEMPERATURE",
            level: "98.6 F",
            timeInterval: "Measure once daily"
        },
        {
            title: "BLOOD OXYGEN",
            level: "95% - 100%",
            timeInterval: "Measure once daily"
        }
    ]


    const handleCarePlanClickAway = () => {
        setCarePlanDrop(false)
        setEditCarePlan(false)
    }
    // const handleEditCarePlanClickAway = () => {
    //     setEditCarePlan(false)
    // }

    return (
        <Box className={classes.rpmCarePlan}>
            <Box className={classes.rpmCarePlanHead}>
                <Paper component="form" className={classes.inputRoot}>
                    <IconButton id="btnSerachPatients" className={classes.iconButton} aria-label="search">
                        <SVGIcon svg={SearchIcon} size="scale400" />
                    </IconButton>
                    <InputBase id="txtSearchPatients"
                        type="search"
                        className={classes.input}
                        placeholder="Search specific care plan actions or assignee name..."
                        inputProps={{ 'aria-label': 'Search to filter the table below' }}
                    />
                </Paper>
                <Box className={classes.rpmCarePlanDesc}>
                    <Box marginLeft='auto'>
                        <Button className={classes.addCareButton}>
                            Add Care Action
                            <SVGIcon svg={ClipBoard} size="scale700" />
                        </Button>
                    </Box>
                    <ClickAwayListener onClickAway={handleCarePlanClickAway}>
                        <Box style={{ position: "relative" }}>
                            <Box className={classes.ellipseIcon} >
                                <SVGIcon svg={Ellipsis} className={classes.ellipseIconColor} size="scale800" onClick={() => setCarePlanDrop(!carePlanDrop)} />
                            </Box>
                            {carePlanDrop && <CarePlanDropDown />}
                        </Box>
                    </ClickAwayListener>
                </Box>
            </Box>
            <Grid container className={classes.carePlanContent}>
                {
                    carePlan?.map((item) => {
                        return (
                            <Grid item md={4} lg={4} xl={3} >
                                <Box className={classes.carePlanCards}>
                                    <Box className={classes.carePlanCardstitle}>
                                        <Typography variant="body1" >
                                            {item.title}
                                        </Typography>
                                        {/* <ClickAwayListener onClickAway={handleCarePlanClickAway}> */}
                                        <Box style={{ position: "relative" }}>
                                            <SVGIcon svg={EllipsisVertical} className={classes.ellipseIconColor} size="scale600" onClick={() => setEditCarePlan(!editCareplan)} />
                                        </Box>
                                        {/* {
                                                        editCareplan &&
                                                        <Box >
                                                            <List className={classes.carebaMenu}>
                                                                <ListItem button>
                                                                    <ListItemText primary={intl.formatMessage({ id: "RPM.Care.Plan.Save.Template" })} classes={{ primary: classes.listText }} />
                                                                </ListItem>
                                                                <ListItem button >
                                                                    <ListItemText primary={intl.formatMessage({ id: "RPM.Care.Plan.Load.Template" })} classes={{ primary: classes.listText }} />
                                                                </ListItem>
                                                            </List>
                                                            <Box className={classes.caretraingle} />
                                                        </Box>
                                                    } */}
                                        {/* </ClickAwayListener> */}
                                    </Box>
                                    <Box >
                                        <Typography variant="body1" className={classes.carePlanCardsDesc}>
                                            {item.level}
                                        </Typography>
                                        <Typography variant="caption" className={classes.carePlanCardsSubDesc}>
                                            {item.timeInterval}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    })
                }

            </Grid>
        </Box>
    )
}

export default CarePlanTab