import React from "react";
import MaskedInput from "react-text-mask"

// InputMask is an input that takes a regex to add a mask to the input.
const InputMask = (props: any) => {
    const { ref, mask, placeholder, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref ? ref.inputElement : null}
            mask={mask}
            placeholder={placeholder}
            keepCharPositions
        />
    );
}
export default InputMask