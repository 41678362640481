import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";


export const useStyles = makeStyles(() => ({
    contentWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    content: {
        display:"flex",
        flexDirection:"column",
        alignItems: "center",
        textAlign: "center",
        maxWidth:"82%"
    },
    icon: {
        height: SIZING.EMPTY_LIST_ICON,
        width: SIZING.EMPTY_LIST_ICON,
        "& path":{
            fill: COLORS.LGRAY
        }
    },
    title: {
        marginBottom: SIZING.scale300,
        color: COLORS.EMPTY_LIST_TEXT,
        whiteSpace: "nowrap"
    },
    subtitle: {
        color: COLORS.EMPTY_LIST_TEXT
    }
}));