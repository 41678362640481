import { FC } from "react";
import classNames from "classnames";
import * as CSS from "./class-names";
import { TestProps } from "../types";

export type PageLeftBodyProps = {
    className?: string;
} & TestProps;
export const PageLeftBody: FC<PageLeftBodyProps> = ({ className, children, id, testId }) => {
    return (
        <div id={id} data-testid={testId || id} className={classNames(className, CSS.PageLeftBody)}>
            {children}
        </div>
    );
};
