import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { usePopper } from "react-popper";
import { useOutsideClickHandler } from "common/utils/use-outside-click-handler";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import { useStyles } from "./styles";

export type DetailsPopoverProps = {
    anchorEl: Element;
    footerNode?: React.ReactNode;
    headerNode?: React.ReactNode;
    id?: string;
    loading?: boolean;
    onClose: () => void;
    testId?: string;
    title?: string;
};

// TODO: Consider using lib/ui-components/popover instead of react-popper
// what made me postpone that is the "close when not visible" that this component does,
// and we need more time to figure out how to do it with the Popover
// TODO: Change classNames to match BEM naming of this component
export const DetailsPopover: React.FC<DetailsPopoverProps> = ({
    anchorEl,
    children,
    footerNode,
    headerNode,
    id,
    loading,
    onClose,
    testId,
    title,
}) => {
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
    const classes = useStyles();
    useOutsideClickHandler([anchorEl, popperElement], true, onClose);

    const modifiers = [
        { name: "preventOverflow", options: { boundary: "clippingParents", altAxis: true } },
        { name: "arrow", options: { element: arrowElement, padding: 16 } },
        { name: "flip", enabled: false },
        { name: "hide" },
    ];

    const { styles, attributes } = usePopper(anchorEl, popperElement, {
        modifiers,
        placement: "right",
        strategy: "fixed",
    });

    const isReferenceHidden = attributes?.popper?.["data-popper-reference-hidden"];

    useEffect(() => {
        if (isReferenceHidden) {
            onClose();
        }
    }, [isReferenceHidden, onClose]);

    return (
        <div
            id={id}
            data-testid={testId || id}
            className={classNames(classes.providerItemDetails, { loading })}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
        >
            <span className="arrow" ref={setArrowElement} style={styles.arrow} {...attributes.arrow} />
            {loading && (
                <div className="loadingWrapper">
                    <ComponentLoader disableBackdrop />
                </div>
            )}
            <div className="infoHeader hideOnLoading">
                <span className="providerName">{title}</span>
                {headerNode}
            </div>
            <div className="infoContent gridBody hideOnLoading">{children}</div>
            {footerNode && <div className="infoFooter hideOnLoading">{footerNode}</div>}
        </div>
    );
};
