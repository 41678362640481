import { OktaAuth } from '@okta/okta-auth-js';

const { OKTA_CLIENT_ID, OKTA_ISSUER } = (window as any).env;

const Config = {
  oidc: {
    clientId: OKTA_CLIENT_ID,
    issuer: OKTA_ISSUER,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email','offline_access'],
    pkce: true,
    storage: 'sessionStorage',
    tokenManager: {
      storage: 'sessionStorage',
      autoRenew: true,
      expireEarlySeconds: 120 // 2 minutes early
    }
  }
};

export default Config;
//Singleton pattern single global instance that any file can import to use authentication object used in store/api.ts to inject Authorization header with Bearner token
export const oktaAuth = new OktaAuth(Config.oidc);