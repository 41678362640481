import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";
import * as CSS from "./class-names";

export const useStyles = makeStyles(() => ({
    filterSection: {
        "& .filterContainer": {
            padding: `${SIZING.scale400} 0 ${SIZING.scale200} 0`,
            "&:empty": {
                padding: `0 0 ${SIZING.scale400} 0`,
            },
            "& > div": {
                marginBottom: SIZING.scale200,
                marginRight: SIZING.scale200,
            },
        },
        "& .searchContainer": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .filterInputContainer": {
                width: `calc(100% - ${SIZING.scale1000})`,
            },
            "& svg.resetSearch": {
                width: SIZING.scale1000,
                cursor: "pointer",
                "& path, & g": {
                    fill: COLORS.RED,
                },
                "&.hidden": {
                    visibility: "hidden",
                },
            },
        },
        "& .popover-loader-wrapper": {
            height: "unset"
        },
        "& .component-loader-wrapper": {
            position: "inherit"
        }
    },
    [CSS.FilteredSearchInput__FilterItem]: {
        alignItems: "center",
        backgroundColor: COLORS.LBLUE,
        borderRadius: SIZING.scale300,
        color: COLORS.WHITE,
        display: "inline-flex",
        padding: `0 0 0 ${SIZING.scale300}`,
        "& svg": {
            cursor: "pointer",
            opacity: ".4",
            width: SIZING.scale600,
            "& path, &g": {
                fill: COLORS.WHITE,
            },
        },
        "& span": {
            marginRight: SIZING.scale300,
        },
        [`& .${CSS.FilteredSearchInput__FilterItem__Text}`]: {
            fontSize: SIZING.scale325,
            fontWeight: "bold",
            maxWidth: "393px", // Same size as filterinput width (incl. border)
            overflow: "hidden",
            textTransform: "lowercase",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        [`& .${CSS.FilteredSearchInput__FilterItem__Type}`]: {
            fontSize: SIZING.scale250,
            opacity: ".4",
            textTransform: "uppercase",
        },
    },
    [CSS.FilteredSearchInput__FilterInput]: {
        backgroundColor: COLORS.LGREY,
        color: COLORS.BLACK,
        fontSize: SIZING.scale350,
        fontWeight: 600,
        padding: `0 ${SIZING.scale200} 0 ${SIZING.scale200}`,
        position: "relative",
        "& input": {
            minHeight: SIZING.scale1000,
            textIndent: 0,
        },
        "& .optionMenu": {
            minWidth: SIZING.scale5000,
        },
        [`& .${CSS.FilteredSearchInput__FilterInput__SearchButton}`]: {
            width: SIZING.scale600,
            height: SIZING.scale600,
            borderRadius: "50%",
            backgroundColor: COLORS.LGREEN
        }
    },
    filterInputSearch: {
        "& svg": {
            cursor: "pointer",
            width: SIZING.scale1000,
            "& path, & g": {
                fill: COLORS.LGREEN,
            },
        },
    },
    filterPopover: {
        marginLeft: SIZING.scale2600,
        width: "350px",
        position: "absolute",
        "& .emptyContent": {
            color: COLORS.WHITE,
            fontSize: SIZING.scale400,
            textAlign: "center",
        },
    },
    filterTypeDropdown: {
        alignItems: "center",
        backgroundColor: COLORS.LBLUE,
        borderRadius: SIZING.scale300,
        color: COLORS.WHITE,
        cursor: "pointer",
        display: "inline-flex",
        fontWeight: 600,
        fontSize: SIZING.scale250,
        height: SIZING.scale600,
        padding: `${SIZING.scale25} ${SIZING.scale120} ${SIZING.scale25} ${SIZING.scale200}`,
        textTransform: "uppercase",
        "& svg": {
            marginLeft: SIZING.scale100,
            width: SIZING.scale400,
            "& path, & g": {
                fill: COLORS.WHITE,
            },
        },
        "& .optionMenu": {
            marginTop: SIZING.scale200,
        },
    },
}));
