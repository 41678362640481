import { makeStyles } from "@mui/styles";
import {SIZING, BORDER_RADIUS, COLORS} from "theme";

export const useStyles = makeStyles(() => ({
    countWrapper: {
        width: "fit-content",
        padding: `${SIZING.scale50} ${SIZING.scale300}`,
        color: COLORS.ITEM_COUNT_TEXT,
        borderRadius: BORDER_RADIUS.PILL,
        background: ({bgColor}: any) => bgColor ? bgColor : COLORS.BADGE_BG,
        cursor: "pointer"
    }
}));