import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    navBtn: {
        marginTop: "1rem"
    },
    rocketIcon: {
        width: "200px",
        height: "200px"
    }
}));