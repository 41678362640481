import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItem: "center",
    },
    rightSide: {
        marginLeft: "auto",
        display: "flex",
        flexDirection: "row",
    },
    switch: {
        color: "gray"
    },
    input: {
        border: "1px",
        borderRadius: '50px'
    }
}));