import createReducer from '../createReducer'
import { PracticeState } from '../reducerTypes'

const initialState: PracticeState = {
	practices: [],
	sPractice: {},
	ehrs: []
}

export default createReducer(initialState, {
	'SET_PRACTICES': (state: PracticeState, payload: PracticeState): PracticeState => {
		const { practices } = payload
		return {
			...state,
			...payload,
			practices
		}
	},

	'SET_SELECTED_PRACTICE': (state: PracticeState, payload: PracticeState): PracticeState => {
		const { sPractice } = payload
		return {
			...state,
			...payload,
			sPractice
		}
	},

	'SET_EHRS': (state: PracticeState, payload: PracticeState): PracticeState => {
		const { ehrs } = payload
		return {
			...state,
			...payload,
			ehrs
		}
	}
})