import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DialogModal from "components/Modals/dialog-modal/dialog-modal";
import { useStyles } from "./styles";

type ConfirmDialogProps = {
    danger?: boolean;
    descriptionId?: string;
    errorMessageId: string;
    idPrefix: string;
    messageId: string;
    onClose: () => void;
    onConfirm: () => Promise<void>;
    titleId: string;
};

const ConfirmDialog = ({
    danger,
    descriptionId,
    errorMessageId,
    idPrefix,
    messageId,
    onClose,
    onConfirm,
    titleId,
}: ConfirmDialogProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleClaim = async () => {
        setError("");
        setLoading(true);
        try {
            await onConfirm();
            onClose();
        } catch (error) {
            console.error(error);
            setError(intl.formatMessage({ id: errorMessageId }));
            setLoading(false);
        }
    };

    return (
        <DialogModal
            idPrefix={idPrefix}
            className={classes.confirmDialog}
            title={intl.formatMessage({ id: titleId })}
            loading={loading}
            primaryAction={{
                label: intl.formatMessage({ id: "EConsult.confirm" }),
                danger,
                onClick: handleClaim,
            }}
            secondaryAction={{
                label: intl.formatMessage({ id: "EConsult.cancel" }),
                onClick: onClose,
            }}
            errorMessage={error}
        >
            <div className="confirmDialog__content">
                <FormattedMessage id={messageId} />
                {descriptionId && (
                    <div className="confirmDialog__contentDescription">
                        <FormattedMessage id={descriptionId} />
                    </div>
                )}
            </div>
        </DialogModal>
    );
};

export default ConfirmDialog;
