import { FeatureFlagNames } from "lib/security/types";
import { SideNavConfig } from "./types";
import { ReactComponent as ChatSvg } from "icon-library/SVG/Chat.svg";
import { ReactComponent as Home } from "icon-library/SVG/Home.svg";
import { ReactComponent as UsersSvg } from "icon-library/SVG/User_Users.svg";
import { ReactComponent as GearSvg } from "icon-library/SVG/Gear.svg";
import { ReactComponent as Time_Clock } from "icon-library/SVG/Time_Clock.svg";
import { ReactComponent as MortarboardSvg } from "icon-library/SVG/Mortarboard.svg";
import { ReactComponent as Altais } from "icon-library/SVG/Altais-Star.svg";
import { ReactComponent as QuestionMark } from "icon-library/SVG/Question_Circle.svg";
import { ReactComponent as eConsultIcon } from "views/EConsult/tempIcons/Stethoscope_Circle-Light.svg";
import { ReactComponent as eConsultReceivedIcon } from "icon-library/SVG/Inbox_Down-Arrow.svg";
import { ReactComponent as eConsultSentIcon } from "icon-library/SVG/Inbox_Up-Arrow.svg";
import { ReactComponent as newEConsultRequestIcon } from "icon-library/SVG/eMail_Closed-Envelope.svg";
//Admin Nav Items Icons
import { ReactComponent as Buildings } from "icon-library/SVG/Buildings.svg";
import { ReactComponent as UserLightCircle } from "icon-library/SVG/User-Light_Circle.svg";
import { ReactComponent as FeeSchedulesSvg } from "icon-library/SVG/Circles_3x3.svg";
import { ReactComponent as MapPin } from "icon-library/SVG/MapPin-Light_ALT.svg";
import { ReactComponent as CirclesHexagonGrid } from "icon-library/SVG/Circles_Hexagon-Grid.svg";
import { ReactComponent as HandUserUsersSvg } from "icon-library/SVG/Hand_User_Users.svg";
import { ReactComponent as UserSingleSvg } from "icon-library/SVG/User_Single.svg";
import { ReactComponent as UserGroupSvg } from "icon-library/SVG/User_Group.svg";
import { createSvgIcon } from "utils";
import { setActiveModal, setOpenConversationSid } from "store/actions";
import { MODAL_TYPES } from "constant";

import MessagesDecorations from "./Decorations/MessagesDecorations";
import EntitiesDecorations from "./Decorations/EntitiesDecorations";
import ConfigurationsDecorations from "./Decorations/ConfigurationsDecorations";
import EducationMenuDecorations from "./Decorations/EducationMenuDecorations";

const { ENABLE_ONLINE_COMMUNITY_URL } = window.env;

// Side nav config for provider experience
const config: SideNavConfig = {
    routes: {
        home: {
            label: "SideNav.menuItem.home",
            Icon: createSvgIcon(Home),
            route: "/provider/home",
        },
        discover: {
            label: "SideNav.menuItem.discover",
            Icon: createSvgIcon(Altais),
            route: "/provider/discover-enable",
        },
        messages: {
            label: "SideNav.menuItem.messages",
            Icon: createSvgIcon(ChatSvg),
            route: "/provider/messages/inbox",
            DecorationComponent: MessagesDecorations,
            /* This is a temporary solution to hide any other messages (e.g. eConsult)
             * until messaging uses Redux in current form. The selected conversation id
             * should not be placed in Redux, because it can depend on the place, where
             * it is displayed.
             */
            callback: (dispatch) => dispatch(setOpenConversationSid("")),
            access: {
                permissions: ["CORE", "CARE_TEAM"]
            }
        },
        people: {
            label: "SideNav.provider.people",
            Icon: createSvgIcon(UsersSvg),
            route: "/provider/people",
            defaultRoute: "/provider/people/patients",
            access: {
                permissions: ["CORE", "CARE_TEAM"],
            },
            items: {
                patients: {
                    label: "SideNav.provider.people.patients",
                    Icon: createSvgIcon(UserSingleSvg),
                    route: "/provider/people/patients",
                },
                separator: {
                    isSeparator: true,
                },
                careTeam: {
                    label: "SideNav.provider.people.careTeam",
                    Icon: createSvgIcon(HandUserUsersSvg),
                    route: "/provider/people/careteam",
                    access: {
                        features: [FeatureFlagNames.E_RPM_CARETEAM],
                    },
                },
            },
        },
        // remoteMonitoring: {
        //     label: "SideNav.menuItem.remoteMonitoring",
        //     Icon: createSvgIcon(HomeMonitoring),
        //     route: "/provider/remote-monitoring"   // Removed RPM for temporarily
        // },
        waitingRoom: {
            label: "SideNav.menuItem.waitingRoom",
            Icon: createSvgIcon(Time_Clock),
            route: "/provider/waiting-room",
            access: {
                permissions: ["CORE", "CARE_TEAM"]
            }
        },
        eConsult: {
            label: "SideNav.menuItem.eConsult",
            Icon: createSvgIcon(eConsultIcon),
            route: "/provider/econsult",
            defaultRoute: "/provider/econsult/received",
            access: {
                permissions: ["CORE", "CARE_TEAM"],
                features: [FeatureFlagNames.E_CONSULT],
            },
            items: {
                received: {
                    label: "SideNav.subMenuItem.eConsultReceived",
                    Icon: createSvgIcon(eConsultReceivedIcon),
                    route: "/provider/econsult/received",
                },
                sent: {
                    label: "SideNav.subMenuItem.eConsultSent",
                    Icon: createSvgIcon(eConsultSentIcon),
                    route: "/provider/econsult/sent",
                },
                separator: {
                    isSeparator: true,
                },
                newRequest: {
                    label: "SideNav.subMenuItem.newEConsultRequest",
                    Icon: createSvgIcon(newEConsultRequestIcon),
                    route: "",
                    callback: (dispatch) => {
                        dispatch(setActiveModal(MODAL_TYPES.PROVIDER.CREATE_E_CONSULT_REQUEST))
                    }
                }
            }
        },
        settings: {
            label: "SideNav.menuItem.settings",
            Icon: createSvgIcon(GearSvg),
            route: "/provider/settings",
        }
    },
    adminRoutes: {
        home: {
            id: "lnkHome",
            label: "SideNav.admin.menuItem.home",
            Icon: createSvgIcon(Home),
            route: "/admin/home",
            DecorationComponent: EntitiesDecorations,
        },
        entities: {
            id: "lnkEntities",
            label: "SideNav.admin.menuItem.entities",
            Icon: createSvgIcon(Buildings),
            route: "/admin/entities",
        },
        users: {
            id: "lnkUsers",
            label: "SideNav.admin.menuItem.users",
            Icon: createSvgIcon(UserLightCircle),
            route: "/admin/users"
        },
        patients: {
            id: "lnkPatients",
            label: "SideNav.admin.menuItem.patients",
            Icon: createSvgIcon(UsersSvg),
            route: "/admin/patients"
        },
        feeschedules: {
            id: "lnkFeeSchedules",
            label: "SideNav.admin.menuItem.feeSchedules",
            Icon: createSvgIcon(FeeSchedulesSvg),
            route: "/admin/feeschedules",
            access: {
                permissions: ["BILLING_SCHEDULE"]
            }
        },
        locations: {
            id: "lnkLocations",
            label: "SideNav.admin.menuItem.locations",
            Icon: createSvgIcon(MapPin),
            route: "/admin/locations"
        },
        configurations: {
            id: "lnkConfigurations",
            label: "SideNav.admin.menuItem.configurations",
            Icon: createSvgIcon(CirclesHexagonGrid),
            route: "/admin/configurations",
            DecorationComponent: ConfigurationsDecorations
        },
        settings: {
            id: "lnkAdminSettings",
            label: "SideNav.admin.menuItem.settings",
            Icon: createSvgIcon(GearSvg),
            route: "/admin/settings"
        }
    },
    actions: {
        ahaReport: {
            id: "lnkAhaReport",
            label: "SideNav.menuItem.ahaReport",
            Icon: createSvgIcon(MortarboardSvg),
            route: "/admin/aha",
            access: {
                permissions: ["AHA_REPORT"],
            }
        },
        community: {
            id: "lnkCommunity",
            route: "",
            label: "SideNav.menuItem.community",
            Icon: createSvgIcon(UserGroupSvg),
            callback: () => {
                window.open(ENABLE_ONLINE_COMMUNITY_URL)
            },
            access: {
                permissions: ["COMMUNITY"]
            }
        },
        education: {
            id: "lnkEducation",
            route: "",
            label: "SideNav.menuItem.education",
            Icon: createSvgIcon(MortarboardSvg),
            DecorationComponent: EducationMenuDecorations,
            access: {
                permissions: ['QUALITYIQ', 'LMS'],
                policy: "one"
            }
        },
        helpCenter: {
            id: "lnkHelpCenter",
            route: "",
            label: "SideNav.menuItem.helpcenter",
            Icon: createSvgIcon(QuestionMark),
            callback: (dispatch) => {
                dispatch(setActiveModal(MODAL_TYPES.PROVIDER.HELP_CENTER))
            }
        }
    }
}
export default config;
