import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid, CardContent, Card, Box, Typography, Button
} from "@mui/material";
import { DropzoneAreaBase, FileObject } from "mui-file-dropzone";
import { useStyles } from "./styles";
import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { validateRequired } from 'admin/common/utils';
import { AppReduxStore } from "admin/store/reducerTypes";
import { useForm } from '../../useForm';
import { useSnackbar } from "notistack";
import SnackbarContent from "../../SnackBars/SnackbarContent";
import { saveWaitingRoom } from "admin/store/actions";
import Controls from "../../Controls/Controls";
import WaitingRoomTile from "admin/components/WaitingRoomTile/WaitingRoomTile";
import { ReactComponent as ArrowUpCircle } from "icon-library/SVG/Arrow_Up-Circle.svg";
import { createSvgIcon } from "../../../../utils";
import { ADMIN_WEB_CACHE } from "admin/constant";
import { updateWebAdminCachePractice } from "admin/store/actions";
import { getAppIdToken } from "authentication/appAuth";
const ArrowUpCircleIcon = createSvgIcon(ArrowUpCircle);

// @ts-ignore
const { ENABLE_API_URL } = window.env

const WaitingRoomAddEdit = forwardRef((props, ref) => {
    const { configuration, admin, auth } = useSelector((state: AppReduxStore) => state)
    const { adminWebCachePractice } = admin
    const dispatch = useDispatch();
    const intl = useIntl();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar()
    const [files, setFiles] = useState<FileObject[]>([]);

    const previewData = {
        name: "eVisit",
        waitingMessage: "While you wait, please take time to fill out the following form prior to the visit",
        waitingRoomForms: [{
            title: "Fill out PHQ-9 Form",
            dueDate: "Due today",
            link: "#",
            name: "PHQ-9"
        }, {
            title: "Set previsit Agenda",
            dueDate: "Due today",
            link: "#",
            name: "Previous Agenda"
        }]
    }

    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            },
            saveAndContinueLater(index: number) {
                return saveAndContinue(index);
            }
        }),
    )

    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('greeting' in fieldValues) {
            err.greeting = validateRequired(fieldValues.greeting, intl)
        }

        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }
    const waitingRoomData = configuration?.waitingRoom || {}
    const waitingRoomInitialState = {
        greeting: (waitingRoomData.greeting && waitingRoomData?.greeting !== "") ? waitingRoomData?.greeting : intl.formatMessage({ id: "WaitingRoom.Text.DefaultPracticeMessage" }),
        logoUrl: waitingRoomData?.logoUrl + "?" + Date.now(),
        logoFileObject: waitingRoomData?.logoFileObject
    };
    const {
        values,
        errors,
        handleInputChange,
        setErrors,
        resetForm,
        resetValues
    } = useForm(waitingRoomInitialState, true, validate);

    useEffect(() => {
        if (admin.configurePracticeId) {
            const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData
            if (tempFormData?.waitingRoomGreeting) {
                resetValues({ ...values, greeting: tempFormData?.waitingRoomGreeting })
            }
        }
    }, [admin.configurePracticeId])


    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {};
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgName: auth.currentOrg?.name,
            tempFormData: {
                name: admin.configurePracticeName,
                configurePractice: true,
                waitingRoomGreeting: values.greeting,
                selectedStep: index,
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }

    const handleAddFile = (attachedFiles: any[]) => {
        resetValues({
            greeting: values.greeting,
            logoUrl: attachedFiles[0].data,
            logoFileObject: attachedFiles[0]
        })
        setFiles(attachedFiles);
    };

    const handleRemoveFile = (deletedFileObject: FileObject, index: number) => {
        resetValues({
            greeting: values.greeting,
            logoUrl: "",
            logoFileObject: ""
        })
        files.splice(index, 1);
        setFiles(files);
    };

    const displayErrorAlert = () => {
        enqueueSnackbar(null, {
            preventDuplicate: true,
            content: (key) => (
                <div role="alert">
                    <SnackbarContent
                        snackbarKey={key}
                        type={'error'}
                        message={intl.formatMessage({ id: 'Error uploading file' })}
                    />
                </div>
            )
        });
    };

    const handleLogoUploadError = () => {
        displayErrorAlert();
        setFiles([]);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (validate()) {
            const axios = require('axios');
            const formData = new FormData();
            const imagedata = values.logoFileObject;

            if (auth && (auth.currentPractice?.id || admin.configurePracticeId) && imagedata) {
                const presignedPutUrl =
                    ENABLE_API_URL +
                    "/api/admin/v1/practice/configuration/logo?practiceId=" +
                    (auth.currentPractice ? auth.currentPractice.id : admin.configurePracticeId);
                formData.append("image", imagedata.file);
                const headerConfig = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        Authorization: 'Bearer ' + getAppIdToken()
                    }
                }

                await axios.put(presignedPutUrl, formData, headerConfig)
                    .then((res: { data: any; }) => {
                        resetValues({ ...values, logoFileObject: '' });
                        //console.log(res.data);
                    })
                    .catch((error: any) => {
                        //console.log(error)
                        handleLogoUploadError();
                    });
            }
            dispatch(saveWaitingRoom({ greeting: values.greeting }))
            resetForm()
            return true
        }
        enqueueSnackbar(null, {
            preventDuplicate: true, content: (key) => (<div role="alert"><SnackbarContent snackbarKey={key} type={'error'}
                message={intl.formatMessage({ id: "Invalid Input" })} desc={intl.formatMessage({ id: "WaitingRoom.Error.FormValidationError" })} /></div>
            ),
        })
        return false;
    };

    const restoreDefault = () => {
        const defaultGreeting = intl.formatMessage({ id: "WaitingRoom.Text.DefaultPracticeMessage" });
        resetValues({ ...values, greeting: defaultGreeting })
        validate({ greeting: defaultGreeting })
    }

    return (
        <Grid container>
            <Grid container>
                <Grid item xs={6}>
                    <Box mb={1} className={classes.formGpHeader} style={{ paddingLeft: "20px" }}>
                        <FormattedMessage id={"WaitingRoom.Text.Edit"} />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box mb={1} className={classes.formGpHeader}>
                        <FormattedMessage id={"WaitingRoom.Text.Preview"} />
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <form>
                        <Box className={classes.tabContentWrapper}>
                            <div>
                                <Typography variant="h6" style={{ textAlign: 'left', color: '#393948' }}>
                                    <FormattedMessage id={"WaitingRoom.Action.PracticeLogo"} />
                                </Typography>
                                <Typography style={{ textAlign: 'left', color: '#393948', fontSize: "10px" }}>
                                    <FormattedMessage id={"WaitingRoom.Text.SupportedFileTypes"} />
                                </Typography>
                                <DropzoneAreaBase Icon={ArrowUpCircleIcon as any}
                                    acceptedFiles={[".jpg,.jpeg,.png,.gif"]}
                                    onAdd={handleAddFile}
                                    onDelete={handleRemoveFile}
                                    filesLimit={1}
                                    maxFileSize={2097152}
                                    showAlerts={true}
                                    fileObjects={files}
                                    dropzoneClass={classes.dropzoneClass}
                                    dropzoneText="Add file or drop files to upload"
                                    useChipsForPreview={true}
                                    previewGridClasses={{ container: classes.previewGridClasses }}
                                    showPreviews={true}
                                    showPreviewsInDropzone={false}
                                    previewText=""
                                />
                            </div>
                        </Box>
                        <Grid item xs={12} className={classes.tabContentWrapper} style={{ paddingTop: "0px" }}>
                            <Box className={classes.restoreDefaultDiv}>
                                <Button href="#" onClick={() => { restoreDefault() }} className={classes.restoreDefault}>
                                    <FormattedMessage id={"WaitingRoom.Action.RestoreDefault"} />
                                </Button>
                            </Box>
                            <Controls.Input
                                name="greeting"
                                label="Practice Message *"
                                placeholder="Enter here"
                                value={values.greeting}
                                onChange={handleInputChange}
                                error={errors.greeting}
                                maxLength="100"
                            />
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={3}>
                    <Typography noWrap variant={"h6"} className={classes.waitingRoomTitle}><FormattedMessage id={"WaitingRoom.Heading.WaitingRoom"} /></Typography>
                    <Grid item xs={12}>
                        <WaitingRoomTile name={previewData.name} practiceMessage={values.greeting} waitingMessage={previewData.waitingMessage} waitingRoomForms={previewData.waitingRoomForms} />
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Typography noWrap variant={"h6"} className={classes.waitingRoomTitle}><FormattedMessage id={"WaitingRoom.Text.EVisitStarting"} /></Typography>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                {values.logoUrl ?
                                    <Grid container className={classes.logoContent}>
                                        <img src={values.logoUrl} alt="Logo" style={{ maxWidth: "178px", maxHeight: "150px" }} crossOrigin="anonymous" />
                                    </Grid> :
                                    <Grid container className={classes.content}>
                                        <Typography noWrap variant={"h6"}><FormattedMessage id={"WaitingRoom.Text.LogoGoesHere"} /></Typography>
                                    </Grid>
                                }
                            </CardContent>
                            <CardContent className={classes.cardContent}>
                                <Typography noWrap variant={"h4"} className={classes.evisitsStartingContent}><FormattedMessage id={"WaitingRoom.Text.EVisitStarting"} /></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
})

export default WaitingRoomAddEdit