import { ChatNotificationCode } from "./types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

type RequestStatusInfoBoxProps = {
    status: ChatNotificationCode;
};

const MessageDefinintions = {
    unclaimed: {
        primary: "EConsult.messages.statusInfo.unclaimed.primary",
        secondary: "EConsult.messages.statusInfo.unclaimed.secondary",
    },
    claimed: {
        primary: "EConsult.messages.statusInfo.claimed.primary",
        secondary: "EConsult.messages.statusInfo.claimed.secondary",
    },
    closed: {
        primary: "EConsult.messages.statusInfo.closed.primary",
        secondary: undefined,
    },
};

const RequestStatusInfoBox = ({ status }: RequestStatusInfoBoxProps) => {
    if (!status) {
        return null;
    }
    return (
        <div className={classNames("RequestStatusInfoBox", `RequestStatusInfoBox--${status}`)}>
            <p className="RequestStatusInfoBox__primaryMsg">
                <FormattedMessage id={MessageDefinintions[status].primary} />
            </p>
            {MessageDefinintions[status].secondary && (
                <p className="RequestStatusInfoBox__secondaryMsg">
                    <FormattedMessage id={MessageDefinintions[status].secondary} />
                </p>
            )}
        </div>
    );
};

export default RequestStatusInfoBox;
