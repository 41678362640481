import { DialogContent, Box, Button, DialogActions, Paper, Typography } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment } from "react";
import { deleteUser, setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import { showNotification } from "admin/store/actions/snackbar";

const ConfirmationModal = () => {
    const dispatch = useDispatch()
    const { sUser } = useSelector((state: AppReduxStore) => state).admin
    const intl = useIntl()
    const classes = useStyles()
    const onClose = () => {
        dispatch(setActiveModal(""))
    }

    const onDelete = (event: any) => {
        event.preventDefault()
        if (sUser.profileRoleId) {
            dispatch(deleteUser({ profileRoleJoinIds: sUser.profileRoleId }))
        }
        else {
            dispatch(showNotification("Delete User Error", "error", "profileRoleJoinId not found"))
        }
        onClose()
    };
    const modalClasses = {
        paper: classes.wrapper
    }
    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.DELETE_USER_CONFIRMATION_MODAL} small={true}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "ConfirmationModal.Title" })}
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {`Delete ${sUser.firstName} ${sUser.lastName}?`}
                    </Typography>
                </Box>
                <Box>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined" style={{ zIndex: 100 }}
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "ConfirmationModal.CancelButton" })}
                        </Button>
                        <Button id="btnDelete" variant="outlined" color="primary" style={{ marginLeft: '10px', background: '#E32B29' }} onClick={onDelete}>
                            {intl.formatMessage({ id: "ConfirmationModal.DeleteButton" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper square elevation={0}>
                    <Typography variant="body2" className={classes.confirmationBody}>
                        {intl.formatMessage({ id: "ConfirmationModal.Body" })}
                    </Typography>

                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default ConfirmationModal