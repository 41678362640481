import { Box, Button, DialogActions, DialogContent, Grid, IconButton, Paper, Typography } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import { FeeScheduleGroups } from "admin/views/FeeSchedules/components/fee-schedule-groups/fee-schedule-groups";
import { FeeScheduleRegionStateType } from "admin/views/FeeSchedules/types";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross_Circle.svg";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { createSvgIcon } from "utils";
import EditPracticeForm from "./EditPracticeForm";
import { useStyles } from "./styles";

const EditPractice = () => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const { practice } = useSelector((state: AppReduxStore) => state)
    const orgId: string = practice.sPractice?.organizationId;
    const practiceId: string = practice.sPractice?.id;
    const editPracticeFormRef = useRef<any>()
    const CrossIcon = createSvgIcon(CrossSvg)
    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose()
    };

    const [regionsState, setRegionsState] = useState<FeeScheduleRegionStateType>({});
    const handleUpdateFeeScheduleGroup = async () => {
        for (const [key] of Object.entries(regionsState)) {
            await call("GET", `/admin/v1/region/assign-practices/region/${key}`).then(async (practices) => {
                const assignedPractices = practices.map((practice: any) => { return practice?.id });
                const newPracticesList: string[] = assignedPractices;
                if (regionsState[key] && !assignedPractices.includes(practiceId)) {
                    newPracticesList.push(practiceId);
                } else if (!regionsState[key] && assignedPractices.includes(practiceId)) {
                    const index = newPracticesList.indexOf(practiceId);
                    if (index > -1) {
                        newPracticesList.splice(index, 1);
                    }
                }
                await call("POST", `/admin/v1/region/practice/region/${key}`, newPracticesList);
            });
        }
    }

    const handleUpdatePractice = (event: any) => {
        handleUpdateFeeScheduleGroup();
        const result = editPracticeFormRef.current.submitForm(event);
        if (result) {
            setTimeout(() => {
                handleClose();
            }, 1000)

        }
    };
    return (
        <Modal onClose={onClose} id={MODAL_TYPES.EDIT_PRACTICE}>
            <DialogActions className={classes.dialogAction}>
                <Box className={classes.dialogHeader} flexGrow={1}>
                    <Box className={classes.dialogHeaderTop}>
                        <Typography className={classes.dialogHeaderTitle}>
                            <FormattedMessage id={"EditPractice.Title"} values={{ name: practice.sPractice && practice.sPractice?.name }} />
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <IconButton onClick={handleClose} size="large">
                        <CrossIcon className={classes.closeButtonIcon} />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ root: classes.dialogContentRoot, dividers: classes.dialogContentDividers }}>
                <Paper square elevation={0}>
                    <Box className={classes.contentWrapper}>
                        <Box className={classes.contentArea}>
                            <Box className={classes.leftPaneWrapper}></Box>
                            <Box className={classes.rightContent}>
                                <EditPracticeForm ref={editPracticeFormRef} />
                                <FeeScheduleGroups orgId={orgId} practiceId={practiceId} regionsState={regionsState} onChange={setRegionsState} />
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Box className={classes.dialogActionBar}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: "right" }}>
                        <Button id="btnSavePractice" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleUpdatePractice}>
                            <FormattedMessage id={"EditPractice.UpdateButtonText"} />
                        </Button>
                    </Grid>
                </Box>
            </DialogActions>
        </Modal>
    );
}

export default EditPractice