import { useContext } from "react"
import { ParticipantContext } from "./participantNameContext"

const useParticipantName = () => {

    const { contextState,updateContextStateById } = useContext(ParticipantContext)

    const  capitalizeFirstLetter =(name:string) => {
        return name? name.charAt(0).toUpperCase() + name.toLowerCase().slice(1):''
      }

    const getPaticipantName =(participantId:string ,splitName?:boolean): any => {
        const name = (contextState[participantId] || { displayName: "" }).displayName;
         if(splitName){
            const splitedName = name?.split(" ") || [];
            const [a = "", b = "", c = ""] = splitedName;
            if(splitedName.length===3){
                return {
                    firstName:capitalizeFirstLetter(b),
                    lastName:capitalizeFirstLetter(c),
                    title:a
                }
            }
            return {
                firstName:capitalizeFirstLetter(a),
                lastName:capitalizeFirstLetter(b)
            }
          
         }
        return  name?.split(" ").reduce((acc,value)=>`${acc} ${capitalizeFirstLetter(value)}`,'') || ''
    } 

    return {        
        getPaticipantName,
        addParticipant: updateContextStateById
    }

}

export default useParticipantName