import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, COLORS, SIZING} from "theme";


export const useStyles = makeStyles(() => ({
    snackbarWrapper: {
        padding: SIZING.scale300,
        background: "linear-gradient(180deg, rgba(15, 123, 189, 0.55) 0%, rgba(0, 122, 255, 0) 100%), #393948",
        border: "2px solid rgba(255, 255, 255, 0.9)",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        borderRadius: BORDER_RADIUS.XL,
        color: "white"
    },
    joinCall: {
        marginRight: SIZING.scale200,
        borderRadius: BORDER_RADIUS.PILL,
        border: "none",
        backgroundColor: "green",
        minHeight: SIZING.scale800,
        height: SIZING.scale800,
        color: COLORS.BUTTON_ICON
    },
    endCall: {
        borderRadius: BORDER_RADIUS.FULL,
        border: "none",
        backgroundColor: "red",
        width: SIZING.scale800,
        minWidth: SIZING.scale800,
        minHeight: SIZING.scale800,
        height: SIZING.scale800
    },
    icon: {
        "& path": {
            fill: COLORS.BUTTON_ICON
        }
    }
}));