import React, {useRef, useEffect, useState, useCallback} from "react";
import { InputAdornment } from "@mui/material";
import Input from "common/components/Input/Input";
import { useStyles } from "./styles";
import { MultiSelectPopoverProps, ListItem } from "./types";
import PopoverContent from "./PopoverContent";
import PinnedItem from "components/PinnedItem/PinnedItem";
import {ReactComponent as SearchSvg} from "icon-library/SVG/Search.svg";
import {createSvgIcon} from "utils";
import { getAge } from "utils";
import { useOutsideClickHandler } from "common/utils/use-outside-click-handler";

const Search = createSvgIcon(SearchSvg)

const MultiSelectPopoverInput = (props: MultiSelectPopoverProps) => {
  const {
    data,
    errorId,
    id,
    qaid,
    value,
    onChange,
    placeholder,
    title,
    pinnedItems,
    setPinnedItems,
    renderItem,
    maxWidth = '',
    loader = false,
    isMultiSelect = true,
    themeColor = 'compose',
    renderPinnedComponent = true,
    dropDownPosition='bottom',
    resetSuggestion = null
  } = props

  //@ts-ignore
  const classes = useStyles({themeColor,renderPinnedComponent,dropDownPosition})
  const inputRef = useRef<null | HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const handleClickOutside = useCallback(() => {
    onChange({ target: { value:'', id } });
    setInputFocused(false);
  }, [id, onChange]);
  useOutsideClickHandler([wrapperRef?.current], inputFocused, handleClickOutside);

  const handlePopoverStatus = () => {
    onChange({ target: { value, id } })
    setInputFocused(false)
  }

  const onFocus = () => setInputFocused(true)

  useEffect(() => {
    if (!value && data.length ) {
      resetSuggestion?.()
    }
  }, [value, resetSuggestion, data.length])

  const onInputChange = (e: any) => {
    if (!inputFocused) {
      setInputFocused(true)
    }
    onChange(e)
  }

  const removePinnedItems = (details: ListItem) => {
    if (isMultiSelect && Array.isArray(pinnedItems)) {
      setPinnedItems(pinnedItems.filter((item: any) => item.registeredUserId !== details.registeredUserId))
    } else {
      setPinnedItems(null)
    }
  }

  const addPinnedItems = (details: ListItem) => {
    if (isMultiSelect && Array.isArray(pinnedItems)) {
      setPinnedItems([...pinnedItems, details])
    } else {
      setPinnedItems(details)
    }
  }

  const changePinnedItems = (details: ListItem) => {
    if (isMultiSelect && Array.isArray(pinnedItems)) {
      if (pinnedItems.findIndex(({ registeredUserId }:{ registeredUserId:string }) => details.registeredUserId === registeredUserId) !== -1) {
        removePinnedItems(details)
      } else {
        addPinnedItems(details)
        if (inputRef && inputRef?.current) {
          inputRef.current.scrollLeft =
            inputRef.current.scrollLeft + 145 * (pinnedItems.length + 1)
        }
      }
    } else {
      if (pinnedItems && pinnedItems.registeredUserId === details.registeredUserId) {
        removePinnedItems(details)
      } else {
        addPinnedItems(details)
        onChange({ target: { value:'', id } })
        setInputFocused(false)
      }
    }
  }

  const handleTabKey = (e:any) =>{
    const code = e.keyCode || e.which
    if (code === 13 || code === 9) {
      handlePopoverStatus()
    }
  }

  const handleInputOnClick = (pinnedItems : any) =>{
    removePinnedItems(pinnedItems)
    inputRef.current?.querySelector('input')?.focus()
  }

  const RenderPinnedItem = () => {

    if(!renderPinnedComponent){
      if (!isMultiSelect&&pinnedItems) {
        //@ts-ignore
        const { firstName = "", lastName = "", dateOfBirth = "", userType = "", genderCode = "" } = pinnedItems || {};

        const value =
            userType === "PROVIDER"
                ? `Dr ${firstName} ${lastName}`
                : `${firstName} ${lastName} (${getAge(dateOfBirth)}, ${genderCode})`;

        return <InputAdornment position="end"> <div data-testid="single-select-result" className={classes.customPinnedItems} onClick={e=>handleInputOnClick(pinnedItems)}>{value}</div></InputAdornment>
    }

    }

    return (
      <InputAdornment position="end">
        {(isMultiSelect ? pinnedItems : [pinnedItems]).map((item: any) => {
          if (!item || (Array.isArray(item) && item.length === 0)) return null
          const name = `${item.firstName} ${item.lastName}`.trim()
          const key = item.registeredUserId ? item.registeredUserId : item.id
          if (!name) return null
          return (
            <PinnedItem
              key={key}
              name={name}
              remove={(e: any) => {
                e.stopPropagation()
                removePinnedItems(item)
              }}
            />
          )
        })}
      </InputAdornment>
    )
  }

  return (
    <div className={`${classes.inputPopOverWrapper} custom-multiselect-wrapper`} ref={wrapperRef}>
      <Input
        errorId={errorId}
        ref={inputRef}
        value={value}
        id={qaid}
        onChange={onInputChange}
        onKeyDown={handleTabKey}
        isSearch
        onFocus={onFocus}
        placeholder={placeholder}
        className={`${classes.inputWrapper} custom-multiselect-input-wrapper`}
        startAdornment={<><RenderPinnedItem /><div className={classes.searchIcon}><Search /></div></>}
        style={{ maxWidth: maxWidth ? maxWidth : 'unset' }}
      />
      {inputFocused && value && <PopoverContent
        className={classes.root}
        close={handlePopoverStatus}
        list={data}
        changePinnedItems={changePinnedItems}
        title={title}
        renderItem={renderItem}
        pinnedItems={pinnedItems}
        loader={loader}
        searchString={value}
        isMultiSelect={isMultiSelect}
        themeColor={themeColor}
        dropDownPosition={dropDownPosition}
      />}
    </div>
  )
}

export default MultiSelectPopoverInput
