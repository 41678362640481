import { MODAL_TYPES } from "constant";
import CareTeamEditorContent from "modules/care-team/care-team-editor-content";
import Modal from "components/Modal/Modal";
import useActiveModal from "components/Modal/use-active-modal";
import { useStyles } from "modules/care-team/styles";
import * as CSS from "modules/care-team/class-names";

const CareTeamEditor = () => {
    const styles = useStyles();
    const { open, onClose } = useActiveModal(MODAL_TYPES.PROVIDER.CARE_TEAM_EDITOR);

    return (
        <Modal id={MODAL_TYPES.PROVIDER.CARE_TEAM_EDITOR} modalWidth="860px" className={styles[CSS.CareTeamEditor]}>
            {open && <CareTeamEditorContent onClose={onClose} />}
        </Modal>
    );
};

export default CareTeamEditor;
