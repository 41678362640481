import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, COLORS, SIZING } from 'admin/theme';

export const useStyles = makeStyles(() =>({
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        textAlign: 'center',
        minWidth: '800px'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '32px',
        color: '#393948'
    },
    formGpSubHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
        padding:'16px 0px'
    },
    dlgAction:{
        padding: '5px 32px'
    },
    dropzoneClass: {
        background: '#EEF9FF !important'
    },
    previewGridClasses: { 
        marginTop: '10px'
    },
    autoInviteText: {
        fontWeight: 600,
        fontSize: '14px',
        color: '#202223',
        float: 'right'
    },
    contentArea: {
        display: 'flex',
        flex: '1 1 auto'
    },
    leftPaneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        maxWidth: '300px',
        minHeight: '494px',
        zIndex: 2,
        position: "relative",
        background: COLORS.SIDE_NAV_BG,
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.05), inset -1px 0px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: `0 0 0 0`,
        width: open => open ? SIZING.SIDE_NAV_OPEN_WIDTH : SIZING.SIDE_NAV_CLOSED_WIDTH,
    },
    rightContent: {
        flexGrow: 1,
        padding: '32px 32px 0px 28px'
    },
    sideNavOption: {
        display: "flex",
        alignItems: "center",
        justifyContent:  "space-between" ,
        height: SIZING.scale1200,
        minWidth: SIZING.scale1200,
        padding: SIZING.scale300,
        margin: "10px",
        borderRadius: BORDER_RADIUS.PILL,
        width: 'auto',
    },
    selected: {
        "&$selected": {
            backgroundColor: '#ffffff',
            color: '#0F7BBD',
            "&:hover": {
                backgroundColor: '#ffffff'
            },
        },
    },
    dialodContentDividers: {
        padding:'0px'
    },
    bulkUploadHeading: {
        fontWeight: 600,
        fontSize: '14px',
        color: '#393948',
        textAlign: 'left'
    },
    dropzoneFailClass: {
        background: 'rgba(232, 44, 42, 0.1)',
        border: '2px dashed rgba(232, 44, 42, 0.1)',
        padding: '15px',
        borderRadius: "8px"
    },
    failContent: {
        color: '#E82C2A',
        fontSize: '24px',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'24px'
    },
    failSecondContent: {
        margin: '15px'
    },
    failureSecondText: {
        color: '#E82C2A',
        fontSize: '16px'
    },
    warningIcon: {
        height: '39px',
        width: '39px',
        marginRight: '10px',
        "& path": {
            fill: '#E82C2A',
        },
    },
    failFirstContent: {
        margin: '24px'
    },
    successText: {
        margin: '3px',
        color: '#00A875',
        fontSize: '17px',
        fontWeight: 'bold',
        display:'flex',
        alignItems:'center',
    },
    duplicatesText: {
        color: '#393948',
        margin: '3px',
        fontSize: '17px',
        fontWeight: 'bold',
        display:'flex',
        alignItems:'center'
    },
    failureText: {
        color: '#E82C2A',
        margin: '3px',
        fontSize: '17px',
        fontWeight: 'bold',
        display:'flex',
        alignItems:'center',
    },
    textTitle:{
        width:'50%',
        marginRight:'5px',
        textAlign:'end',
        color:'#212131',
        fontWeight:'normal'
    },
}));
