import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING, COLORS } from "theme";
import { StyleProps } from "./types";

export const useStyles = makeStyles(() => ({
    sideNav: {
        display: "flex",
        zIndex: 3,
        width: ({ open }: StyleProps) => open ? SIZING.SIDE_NAV_OPEN_WIDTH : SIZING.SIDE_NAV_CLOSED_WIDTH,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        minHeight: "100vh",
        background: COLORS.SIDE_NAV_BG,
        boxShadow: "1px 0 8px rgba(0, 0, 0, .05), inset -1px 0 0 rgba(0, 0, 0, .1)",
        borderRadius: `0 ${BORDER_RADIUS.XL} ${BORDER_RADIUS.XL} 0`
    },
    listRoot: {
        padding: SIZING.scale400,
        width: "100%",
        flex: "1 1 0",
        display: "flex",
        flexDirection: "column",
        "&.listRoot--grow": {
            overflowY: "auto",
            overflowX: "hidden",
            flexGrow: 100,
            "& .navItem": {
                marginBottom: SIZING.scale200,
            }
        }
    },
    actionContainer: {
        marginTop: "auto"
    },
    navDivider: {
        backgroundColor: COLORS.NAV_DIVIDER_COLOR
    },
    subListRoot: {
        padding: `0 ${SIZING.scale200} 0 ${SIZING.scale500}`,
        marginTop: SIZING.scale200
    },
    subListRootIconOnly: {
        padding: `0 ${SIZING.scale100}`
    },
    navItem: {
        minWidth: SIZING.scale1200,
        borderRadius: BORDER_RADIUS.XL,
        transition: "background-color .25s ease"
    },
    subNavItem: {
        minWidth: SIZING.scale1000,
        marginBottom: SIZING.scale200
    },
    subListTrigger: {
        transitionProperty: "background,color,fill",
        transitionDuration: ".25"
    },
    listItemActive: {
        marginBottom: SIZING.scale200,
        background: COLORS.SIDE_NAV_EXPANDED_ITEM_BG,
        borderRadius: BORDER_RADIUS.XL
    },
    itemActive: {
        marginBottom: SIZING.scale200,
        background: COLORS.SELECTED_NAV_ITEM_BTN,
        borderRadius: ({ open }: StyleProps) => open ? BORDER_RADIUS.PILL : BORDER_RADIUS.FULL
    },
    listItem: {
        fontSize: "17px",
        display: "flex",
        alignItems: "center",
        justifyContent: ({ open }: StyleProps) => open ? "space-between" : "center",
        height: SIZING.scale1200,
        minWidth: SIZING.scale1200,
        padding: ({ open }: StyleProps) => open ? SIZING.scale200 : 0,
        cursor: "pointer",
        "& .MuiTypography-body1": {
            fontSize: "17px"
        }
    },
    listItemIcon: {
        transition: "fill .25s ease",
        marginRight: ({ open }: StyleProps) => open ? SIZING.scale400 : 0
    },
    subItemRow: {
        padding: SIZING.scale100
    },
    subItem: {
        display: "flex",
        alignItems: "center"
    },
    subItemIcon: {
        width: SIZING.scale600,
        height: SIZING.scale600,
        marginRight: ({ open }: StyleProps) => open ? SIZING.scale300 : 0
    },
    subListItem: {
        fontSize: "17px",
        height: SIZING.scale1000,
        minWidth: SIZING.scale1000,
        borderRadius: BORDER_RADIUS.PILL,
        "& .MuiTypography-body2": {
            fontSize: "16px",
            fontWeight: "600"
        }
    },
    subListSeparator: {
        padding: 0,
        marginTop: SIZING.scale200,
        marginBottom: SIZING.scale200,
        marginLeft: SIZING.scale200,
        opacity: .4,
        border: `1px solid ${COLORS.SIDENAV_SUB_LIST_SEPARATOR}`
    },
    notifIcon: {
        position: ({ open }: StyleProps) => open ? "static" : "absolute",
        top: ({ open }: StyleProps) => open ? "auto" : "0",
        fontSize: SIZING.scale400,
        color: COLORS.NOTIFICATION_TEXT,
        marginLeft: SIZING.scale300,
        "& path": {
            fill: COLORS.NOTIFICATION_TEXT
        }
    },
    collapseButton: {
        position: "absolute",
        bottom: SIZING.scale400,
        right: 0,
        transform: "translateX(50%)",
        width: SIZING.scale600,
        height: SIZING.scale600,
        background: COLORS.SIDE_NAV_COLLAPSE_BTN,
        boxShadow: "0 1px 2px rgba(0, 0, 0, .1), inset 0 0 0 1px rgba(0, 0, 0, .15)",
        borderRadius: BORDER_RADIUS.FULL,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center"
    },
    collapseIcon: {
        fontSize: SIZING.scale500,
        width: SIZING.scale300,
        "& path": {
            fill: COLORS.GREY
        }
    },
    fullLogoWrapper: {
        width: ({ open }: StyleProps) => open ? "auto" : 0
    },
    fullLogo: {
        marginBottom: SIZING.scale1000
    },
    fullLogoRotated: {
        transformOrigin: "top left",
        transform: "rotate(-90deg) translate(0, -50%)",
        marginTop: "7em"
    },
    infoIcon: {
        width: "17px",
        height: "17px",
        cursor: "pointer"
    },
    infoTooltipText: {
        fontSize: "18px",
        textAlign: "left",
        marginLeft: "12px",
        marginRight: "4px"
    },
    infoTooltipTextDesc: {
        fontSize: "15px"
    },
    LetsGoBtn: {
        marginTop: "10px",
        marginRight: "8px",
        height: "32px",
        "& .MuiButton-label": {
            fontSize: "11px",
            margin: "0",
            lineHeight: "0"
        }
    }
}));
