import { Box, Button, DialogTitle, TextField } from "@mui/material";
import { createTranscriptionManager } from "./awsUtils";
import { useIntl } from "react-intl";
import React, { useRef, useState } from "react";
import { useStyles } from "./styles";
import CustomTypography from "common/components/CustomTypography/CustomTypography";

export type TranscribeMedicalProps = {
  multiline?: boolean;
  initialValue?: string;
  onChange: (content: string) => void;
};

const AudioState = {
  CONNECTING: 'connecting',
  FAILED: 'failed',
  LISTENING: 'listening',
  PAUSED: 'paused'
};

const TranscribeMedical = ({initialValue, onChange, multiline = true}: TranscribeMedicalProps) => {
  const transcriptionManager = useRef(createTranscriptionManager(appendTranscription));
  const inputRef = useRef(null);
  const classes = useStyles();
  const intl = useIntl();
  const [finalText, updateFinalText] = useState(initialValue ?? "");
  const [partialText, updatePartialText] = useState("");
  const [audioState, setAudioState] = useState(AudioState.PAUSED);

  const handleChange = (e: string) => {
    updateFinalText(e);
  }

  function appendTranscription(transcript: string, isPartial: boolean): void {
    transcript&&scrollDown()
        const separator = multiline ? '\n' : ' ';
        if (isPartial) {
          updatePartialText(`${(finalText) ? separator : ''}${transcript}`);
        }else{
          updateFinalText(previousFinalText =>`${previousFinalText}${(previousFinalText) ? separator : ''}${transcript}`);
          updatePartialText('');
        }
  }

  const scrollDown = () =>{
      if(inputRef.current){
        //@ts-ignore
        const ele = inputRef.current.querySelector("textarea");
        if(ele){
          ele.scrollTop = ele.scrollHeight;
        }
      }
  }

  function startAudio() {
    setAudioState(AudioState.CONNECTING);
    transcriptionManager.current.start()
      .then(() => setAudioState(AudioState.LISTENING))
      .catch((error: Error) => {
        console.log(error);
        setAudioState(AudioState.FAILED);
      });
  }
  function stopAudio() {
    setAudioState(AudioState.CONNECTING);
    transcriptionManager.current.stop()
      .then(() => setAudioState(AudioState.PAUSED));
  }

  function saveAndClose() {
    stopAudio();
    onChange(finalText);
    updateFinalText('');
  }

  return (
      <div className={classes.wrapper}>
        <DialogTitle className={classes.wrapperHeader}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <CustomTypography
                className={classes.headerText}
                variant="callHeader"
              >{intl.formatMessage({ id: "TranscribeMedical.title" })}</CustomTypography>
            </Box>

            <Box className={classes.buttonSection}>
              {[AudioState.CONNECTING, AudioState.FAILED, AudioState.PAUSED].includes(audioState) ?
                <Button id="QA_live_dictation_start_pause"
                  className={classes.pauseBtn}
                  onClick={startAudio}
                  disabled={audioState === AudioState.CONNECTING}
                >
                  {intl.formatMessage({id:"TranscribeMedical.startAudio"})}
                </Button> : ''
              }
              {audioState === AudioState.LISTENING ?
                <Button id="QA_live_dictation_start_pause"
                  className={classes.pauseBtn}
                  onClick={stopAudio}
                >
                  {intl.formatMessage({id:"TranscribeMedical.stopAudio"})}
                </Button> : ''
              }
              <Button id="QA_live_dictation_save_and_close"
                className={classes.close}
                onClick={saveAndClose}
              >{intl.formatMessage({id:"TranscribeMedical.saveAndClose"})}</Button>
            </Box>
          </Box>
        </DialogTitle>
        <TextField id="QA_live_dictation_content"
          className={classes.textField}
          variant="outlined"
          ref={inputRef}
          placeholder={finalText === "" ?intl.formatMessage({id:`TranscribeMedical.placeholder.${audioState}`}):""}
          multiline
          InputProps={{ classes: { input: classes.input } }}
          value={`${finalText}${partialText}`}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
  );
}

export default TranscribeMedical;
