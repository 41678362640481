import { makeStyles } from "@mui/styles";
import {COLORS, SIZING, BORDER_RADIUS} from "admin/theme";

export const useStyles = makeStyles(() => ({
    patientEmailForm: {
        position: "relative",
    },
    loginLink: {
        textAlign: "right",
        position: "absolute",
        right: "0",
        top: "-.3rem",
        "& a": {
            color: COLORS.LINK_COLOR,
            fontSize: ".9rem"
        }
    },
    warning: {
        display: "flex",
        background: COLORS.WARNING_BG,
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: BORDER_RADIUS.LARGE,
        border: '2px solid #FFFFFF',
        fontSize: '1rem',
        fontWeight: 400,
        color: COLORS.SNACKBAR_WARNING_TEXT,
        padding: "1rem",
        textAlign: "left",
        overflow: "hidden",
        "& a.MuiButton-contained": {
            background: "#ffffff",
            color: COLORS.LINK_COLOR,
            minHeight: "36px",
            height: "auto",
            float: "right",
            padding: "3px 6px",
            "& .MuiTypography-button": {
                fontSize: "1.1rem"
            }
        },
    },
    warningContent: {
        margin: ".4rem 0 .5rem 1rem",
        "& h3": {
            textTransform: "uppercase"
        }
    },
    warningIcon: {
        color: "COLORS.SNACKBAR_WARNING_TEXT",
        fontSize: "4rem",
        height: SIZING.scale1200,
        width: SIZING.scale1200,
        marginRight: SIZING.scale100
    },
    phoneIcon: {
        "& path": {
            fill: COLORS.SNACKBAR_WARNING_TEXT
        },
        fontSize: "inherit",
        width: "60px",
        height: "auto",
        marginTop: "-.5rem",
    }
}));