import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as LocationIcon } from 'icon-library/SVG/MapPin_ALT.svg';
import { ReactComponent as ViewScheduleIcon } from 'icon-library/SVG/File_Blank-Multiple.svg';
import { Fragment } from "react";
import { useIntl } from 'react-intl';
import { useStyles } from './styles';

export type FeeScheduleGroupsGridContextMenuContentProps = {
    callback: any,
    handleClose: any
}

const FeeScheduleGroupsGridContextMenu = ({ callback, handleClose }: FeeScheduleGroupsGridContextMenuContentProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Fragment>
            <ListItem button>
                <ListItemIcon>
                    <LocationIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "fee.schedule.groups.listing.context.menu.view.edit.locations" })} classes={{ primary: classes.listText }} onClick={() => { callback("ViewEditLocations"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <ViewScheduleIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "fee.schedule.groups.listing.context.menu.view.fee.schedules" })} classes={{ primary: classes.listText }} onClick={() => { callback("ViewFeeSchedule"); handleClose() }} />
            </ListItem>
        </Fragment>
    );
}

export default FeeScheduleGroupsGridContextMenu;
