import React from "react";
import { Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { MessageView } from "components/messaging/types";
import { useStyles } from "./styles";
import { VideoCallStart } from "assets";
import PersonIcon from "@mui/icons-material/Person";
import { joinEvisitFromInviteMessage } from "store/actions/meeting";
import { useDispatch } from "react-redux";
import { getAge } from "utils";
import { useHistory } from "react-router-dom";
import { EVISIT_TYPE } from "constant";

const EvisitInviteMessageView: MessageView = ({ conversationInfo, message }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const patientDetails = conversationInfo.patient;
    const evisitDetails = message.body;

    if (evisitDetails === null) {
        return null;
    }

    const { firstName = "", lastName = "", dateOfBirth, genderCode, mrn, id } = patientDetails || {};

    const { type, inviteToken, patientName: patient_name } = JSON.parse(evisitDetails);

    const isNRU_EVISIT = EVISIT_TYPE.NRU_EVISIT === type;

    const handleJoinMeeting = () => {
        !isNRU_EVISIT
            ? dispatch(joinEvisitFromInviteMessage(evisitDetails))
            : history.push(`/provider/waiting-room/${inviteToken}`);
    };

    const patientName = patientDetails ? `${firstName} ${lastName}` : patient_name;

    const navigation = () => {
        id && history.push(`/provider/people/patients/${id}`);
    };

    return (
        <div className={classes.root}>
            <>
                <Typography variant="body1" className={classes.unsetFontWeight}>
                    <FormattedMessage id="evisit.message.invite.evisitMessage" values={{ patientName }} />
                </Typography>
                <Button
                    color="primary"
                    className={classes.button}
                    onClick={handleJoinMeeting}
                    startIcon={<VideoCallStart />}
                >
                    <Typography variant="button" className={classes.startEvent} onClick={handleJoinMeeting}>
                        <FormattedMessage id="evisit.message.invite.joinButton" />
                    </Typography>
                </Button>
                <Typography variant="body1" className={classes.unsetFontWeight}>
                    <FormattedMessage id="evisit.message.invite.patientDetails" />
                </Typography>
                <Typography variant="h6" className={classes.patientDetails} onClick={navigation}>
                    <PersonIcon /> {patientName}
                    {patientDetails && ` (${getAge(dateOfBirth)} ${genderCode}, ${mrn})`}
                </Typography>
            </>
        </div>
    );
};

export default EvisitInviteMessageView;
