import { ApiRequest, ApiStatusCode, HttpMethod } from "lib/api/types";
import { createEmptyPageResponse, DEFAULT_PAGINATION_SIZE } from "lib/domain/service-types";
import { mapToPatientSearchResponseData } from "./mappers";
import { PatientSearchRequest, PatientSearchResponse } from "../types";
import { useApi } from "lib/api/api-service-hooks";
import { useDebounce } from "common/utils/use-debounce";
import { useEffect } from "react";

type UseCareTeamPatientSearchHookProps = {
    searchValue: string;
    page?: number;
};
type UseCareTeamPatientSearchHook = {
    (props: UseCareTeamPatientSearchHookProps): {
        error: Error | null;
        loading: boolean;
        result: PatientSearchResponse;
        success: boolean;
    };
};

const DEFAULT_CONTENT = { data: [], page: createEmptyPageResponse(0) };
const MIN_SEARCH_VALUE_LENGTH = 2;

export const useCareTeamPatientSearch: UseCareTeamPatientSearchHook = ({ page = 0, searchValue }) => {
    const { content, fetchInfo, request, reset } = useApi<PatientSearchRequest, PatientSearchResponse>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: DEFAULT_CONTENT,
        url: "/patient/v1/careteam/patients/search",
        method: HttpMethod.POST,
        responseTransformer: (response) =>
            ({
                data: mapToPatientSearchResponseData(response.data),
                page: response.page,
            } as PatientSearchResponse),
    } as ApiRequest<PatientSearchRequest, PatientSearchResponse>);

    const debouncedRequest = useDebounce(request, 250);

    useEffect(() => {
        if (searchValue.trim().length <= MIN_SEARCH_VALUE_LENGTH) {
            reset();
        } else {
            debouncedRequest({
                text: searchValue,
                page: {
                    number: Math.max(page, 0),
                    size: DEFAULT_PAGINATION_SIZE,
                },
            });
        }
    }, [debouncedRequest, page, reset, searchValue]);

    return {
        error: fetchInfo.error,
        loading: fetchInfo.state === ApiStatusCode.SENT,
        result: content,
        success: fetchInfo.state === ApiStatusCode.OK,
    };
};
