import { keys, map } from 'underscore'
import { fetch } from 'whatwg-fetch'
import { getAppIdToken } from 'authentication/appAuth'

// @ts-ignore
const {ENABLE_API_URL} = window.env

// API is a tool to abstract sending calls to external servers, where the params and returns are unknown.
// disable no-explicit-any typing for this file since most data doesn't have a known type.

    const queryStringParams = (params: object): string => {
    if (keys(params).length > 0) {
        return (
            '?' +
            map(params, (val: any, key: string) => {
                if (val instanceof Array) {
                    return map(val, (v: any) => {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
                    }).join('&')
                } else {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
                }
            }).join('&')
        )
    }
    return ''
}

export const jsonFetch = (endpoint: string, fetchObj: any): Promise<any> => {
    return new Promise((cb, cbErr) => {
        return fetch(endpoint, fetchObj)
            .then((response: any) => {
                if (response.status === 204) {
                    return Promise.resolve()
                }
                if (response.status < 200 || response.status >= 400) {
                    return Promise.reject(response)
                }
                return response.json().then(
                    (data: object) => {
                        if (response.status >= 200 && response.status < 400) {
                            return data
                        } else {
                            return Promise.reject(new Error(response.response))
                        }
                    },
                    (error: any) => {
                        return Promise.reject(new Error(error.error))
                    }
                )
            })
            .then(cb, cbErr)
    })
}

export const nonAuthCall = (method: string, endpoint: string, params = {}): Promise<any> => {
    return callWithHeaders(method, endpoint, {}, params)
}

export const call = (method: string, endpoint: string, params = {},signal?: any): Promise<any> => {
    const headers: {Authorization?: string} = {}
    if (getAppIdToken()) {
        headers.Authorization = 'Bearer ' + getAppIdToken()
    }
    return callWithHeaders(method, endpoint, headers, params,signal)
}

const callWithHeaders = (method: string, endpoint: string, headers = {}, params = {},signal?: any) => {
    const fetchObj: any = {
        method,
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        ...(signal?{signal}:'')
    };

    method = (method || 'GET').toUpperCase()

    if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
        if (params instanceof window.FormData) {
            fetchObj.body = params
        } else {
            fetchObj.body = JSON.stringify(params)
        }
    } else {
        endpoint += queryStringParams(params)
    }

    return fetch(`${ENABLE_API_URL}/api${endpoint}`, fetchObj)
        .then((response: any) => {
            if (response.status === 204) {
                return Promise.resolve()
            }

            const contentType = response.headers.map['content-type']

            if (contentType === "application/json") {
                return response.json().then(
                    (data: any) => {
                        if (response.status >= 200 && response.status < 400) {
                            return data
                        } else {
                            return Promise.reject(data)
                        }
                    },
                    (err: any) => {
                        return Promise.reject({
                            status: response.status,
                            error: err
                        })
                    }
                )
            }

            if (contentType === "application/pdf") {
                return response.blob()
            }
        })

        
}