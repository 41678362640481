import React, { Fragment, useState } from "react";
import { DialogContent, Box, Button, DialogActions, IconButton, Paper, Typography, TextField } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { forgotUserPasswordByAdmin, setActiveModal, verifySecretAnswer } from "admin/store/actions";
import { useStyles } from "./styles";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import { showNotification } from "admin/store/actions/snackbar";

const ResetPasswordModal = () => {
    const dispatch = useDispatch()
    const { admin } = useSelector((state: AppReduxStore) => state)
    const intl = useIntl()
    const classes = useStyles()
    const [secretQuestion, setSecretQuestion] = useState(null)
    const [secretAnswer, setSecretAnswer] = useState(null as any)
    const onClose = () => {
        dispatch(setActiveModal(""))
        setSecretQuestion(null)
        setSecretAnswer(null)
    }

    const onResetPassword = (event: any) => {
        event.preventDefault()
        forgotUserPasswordByAdmin(admin.sUser.username).then((response: any) => {
            if (response) {
                setSecretQuestion(response)
            }
            else {
                dispatch(showNotification("Reset Password Email Sent", "success"))
                onClose()
            }

        }).catch((response: any) => {
            dispatch(showNotification("Reset Password Error", "error", response.message))
        })
    };
    const onSecretAnswerChange = (event: any) => {
        setSecretAnswer(event.target.value)

    }
    const onVerifySecretAnswer = (e: any) => {
        e && e.preventDefault();
        verifySecretAnswer(admin.sUser.username, btoa(secretAnswer?.trim())).then((response: any) => {
            dispatch(showNotification("Reset Password Email Sent", "success", response))
            onClose()

        }).catch((response: any) => {
            dispatch(showNotification("Reset Password Error", "error", response.message))
        })
    }
    const modalClasses = {
        paper: classes.wrapper
    }

    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.RESET_PASSWORD} small={true}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "ResetPasswordModal.Title" })}
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {intl.formatMessage({ id: "ResetPasswordModal.Desc" })}
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnResetPasswordModal" size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancelUL" variant="outlined" style={{ zIndex: 100 }}
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "ResetPasswordModal.CancelButton" })}
                        </Button>
                        {secretQuestion ? <Button id="btnVerifySecretAnswer" variant="outlined" color="primary"
                            style={{ marginLeft: '10px' }} onClick={onVerifySecretAnswer}>
                            {intl.formatMessage({ id: "ResetPasswordModal.VerifySecretAnswerButton" })}
                        </Button> : <Button id="btnSaveUL" variant="outlined" color="primary" style={{ marginLeft: '10px', backgroundColor: '#E32B29' }} onClick={onResetPassword}>
                            {intl.formatMessage({ id: "ResetPasswordModal.ResetPasswordButton" })}
                        </Button>
                        }
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >

                {secretQuestion ? <Box>
                    <Typography variant="body2" className={classes.confirmationBody} style={{ color: '#393948' }}>
                        {secretQuestion}
                    </Typography>
                    <TextField value={secretAnswer} style={{ marginTop: '10px' }}
                        onChange={onSecretAnswerChange} id={"txtSecretAnswer"} name="txtSecretAnswer"
                        variant={"outlined"} autoFocus={true} />
                </Box> :
                    <Paper square elevation={0}>
                        <Typography variant="body2" className={classes.confirmationBody}>
                            {intl.formatMessage({ id: "ResetPasswordModal.Body" })}
                        </Typography>
                    </Paper>}
            </DialogContent>
        </Modal>
    )
}

export default ResetPasswordModal