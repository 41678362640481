import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import {useSnackbar} from "notistack";

import {AppReduxStore} from "store/reducerTypes";
import {acceptCall, denyCall} from "store/actions";
import {SIZING} from "theme";
import {createSvgIcon} from "utils";
import {useStyles} from "./styles";

import {ReactComponent as VideoCallSvg} from "icon-library/SVG/Camera_Video.svg"
import {ReactComponent as CloseSvg} from "icon-library/SVG/Cross.svg"
import React, {useEffect} from "react";

const VideoCallIcon = createSvgIcon(VideoCallSvg)
const CloseIcon = createSvgIcon(CloseSvg)

// 1000ms * 60s * 2 = 2 min
const CALL_TIMEOUT = 120000

// IncomingCall displays info (callers name and location) regarding an incoming adhoc call with the ability to accept
// or decline a call.
const IncomingCall = ({snackbarKey}: any) => {
    const {closeSnackbar} = useSnackbar()
    const { fromName } = useSelector((state: AppReduxStore) => state.meeting.incoming)
    const dispatch = useDispatch()
    const classes = useStyles()

    // initialize time out to close the snackbar if the user does not interact with it for 2 minutes
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            dispatch(denyCall())
            closeSnackbar(snackbarKey)
        }, CALL_TIMEOUT)
        return () => {
            clearTimeout(timeoutId)
        }
    }, [closeSnackbar, dispatch, snackbarKey])

    const handleAccept = () => {
        dispatch(acceptCall())
        closeSnackbar(snackbarKey)
    }
    const handleDecline = () => {
        dispatch(denyCall())
        closeSnackbar(snackbarKey)
    }

    return (
        <Box className={classes.snackbarWrapper}>
            <Typography variant="body1">INCOMING VIDEO CALL</Typography>
            <Box display="flex">
                <Box marginRight={SIZING.scale400}>
                    <Typography variant="subtitle1">{fromName}</Typography>
                    <Typography variant="subtitle1">Santana Row Medical Center</Typography>
                </Box>
                <Box display="flex" alignItems="flex-end">
                    <Button variant="contained" classes={{root:classes.joinCall}} onClick={handleAccept} startIcon={<VideoCallIcon className={classes.icon}/>}>
                        JOIN CALL
                    </Button>
                    <Button classes={{root:classes.endCall}} onClick={handleDecline}>
                        <CloseIcon className={classes.icon}/>
                    </Button>
                </Box>
            </Box>

        </Box>
    )
}

export default IncomingCall