import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";


export const useStyles = makeStyles(() => ({
    invitePatientButton: {
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(86, 86, 98, .05) 100%), #FFF",
        "&:hover": {
            background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(86, 86, 98, .05) 100%), #FFF !important"
        },
        margin: SIZING.scale500,
        width: 'auto',
        textAlign: "center",
        boxShadow: "0 1px 4px rgba(0, 0, 0, .1)",
        "& svg path": {
            fill: COLORS.GREY
        },
        marginLeft:'auto',
    },
    invitebuttonContent: {
        display: "flex",
        alignItems: "center"
    },
    invitebuttonText: {
        color: COLORS.GREY,
        fontSize: SIZING.scale400,
        fontWeight: 600,
        marginLeft: SIZING.scale300
    },
    icon1: {
        height: SIZING.scale800,
        width: SIZING.scale800,
        "& path": {
            fill: "#000"
        }
    },
    waitingRoom: {
        width: "100%",
        height: "100vh",
        display: "flex",
        "& *":{
            "&::-webkit-scrollbar-track":{
                backgroundColor: "transparent !important"
            },
            "&::-webkit-scrollbar-thumb":{
                background:`${COLORS.LGRAY} !important`,
                border:`${SIZING.scale75} solid transparent !important`
            },
            "&::-webkit-scrollbar":{
                width:`${SIZING.scale75} !important`
            }
        }
    },
    waitingRoomSection: {
        width: 'auto',
        minWidth: SIZING.WAITING_ROOM_SECTION,
        height: "100%",
        "& .waiting-room-inner-wrapper": {
            height: '100%',
            width: '28.313rem',
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: 2,
            right: `-${SIZING.scale450}`,
            boxShadow: "inset -1px 0px 0px rgb(0 0 0 / 10%)",
            borderTopRightRadius: SIZING.scale500,
            borderBottomRightRadius: SIZING.scale500,
        },
        position: 'relative'
    },
    inviteButtonSection: {
        marginRight: SIZING.scale300,
        display: "flex",
        width: "100%",
        height: SIZING.scale2000,
        background: COLORS.MODAL_BG,
        boxShadow: "1px 0px 8px rgb(0 0 0 / 10%), inset 0px -1px 0px rgb(0 0 0 / 10%)",
        borderTopRightRadius: SIZING.scale500
    },
    patientCard: {
        height: `calc(100% - 9.25rem)`,
        overflowY: "auto",
        background: COLORS.WHITE,
        padding: '14px',
        width: '100%',
        boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1)',
        borderBottomRightRadius: '1.25rem'
    },
    icon: {
        height: SIZING.scale800,
        width: SIZING.scale800,
        "& path": {
            fill: 'rgba(110, 110, 121, 0.6)',
            "&:first-child": {
                fill: COLORS.DEFAULT_ICON,
            }
        }
    },
    buttonText: {
        color: "#FFF",
        fontSize: SIZING.scale350,
        fontWeight: 700
    },
    buttonText3: {
        color: "#FFF",
        fontSize: SIZING.scale350,
        fontWeight: 700
    },
    waitingRoomCallSection: {
        width: "100%",
        height:'100%',
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: 'relative'
    },
    callSectionText: {
        color: COLORS.WAITING_ROOM_CALL_TEXT,
        fontSize: SIZING.scale1200
    },
    twilioVideoHeader: {
        height: '6.25rem',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 1,
        padding: '1.875rem',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)',
        left: 0,
        "& p": {
            fontSize: '1.063rem'
        },
        "& span": {
            fontSize: '1.063rem',
            fontWeight: 400,
            color: COLORS.WHITE
        }
    },
    additionalInfo : {
        paddingLeft:SIZING.scale150
    }
   
   
}));