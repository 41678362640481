import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
        registrationWrapper: {
            marginRight: "1rem",
            "& > form button[type=submit]": {
                marginTop: '2em'
            },
            "& > form .MuiFormControl-root": {
                width:'100%'
            },
            "& > form .MuiInputBase-formControl": {
                height:'38px'
            },
            "& > form .MuiInputBase-root": {
                color:'#3C3C43',
                font:'inherit',
                border:'none'
            },
            "& > form .MuiOutlinedInput-input": {
                padding:'unset',
                height:'38px',
            }
        },
        modal: {
            minWidth: "100%"
        }
}));