import React, {useEffect, useState, useCallback} from "react";
import { addMessageViewProvider } from "components/messaging/message-view-registry";
import { getSecurityService } from "lib/service-manager";
import { EConsultFolderName } from "views/EConsult/components/requests/types";
import { IsJsonString } from "utils";
import {Route, Switch, useHistory, Redirect} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {IntlProvider, useIntl} from 'react-intl';
import {CssBaseline} from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import {SnackbarProvider, useSnackbar} from "notistack";
import i18n from 'i18n';
import configureStore, {history} from 'store';
import { AhaHome, AhaReport, Dashboard, PEPHome, PEPNewUserRequest, Messages, SideNav, AppWrapper, Settings, People, checkSideNavPermission, WaitingRoom, DiscoverEnable,RemoteMonitoring } from "views";
import EConsultFolderView from "views/EConsult/components/requests/e-consult-folder-view";
import { Login, ProfileSelector, Registration, ProviderRegistration, PatientRegistration, VerifyAdditionalProfile, VerifyInfo, Feedback, ResetPassword, DownloadApp, Unauthorized, NotFound, RegisteredProfiles, PatientLogin } from "common/views";
import { map } from "underscore";
import * as modals from "./components/Modals";
import providerTheme from 'theme';
import adminTheme from 'admin/theme';
import {terminateClient,initClient} from "store/actions";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import { useProtectedRoutes } from 'common/components/Permission/useProtectedRoutes';
import AdminApp from "admin/AdminApp";
import CareTeam from "modules/care-team/care-team";
import EvisitInviteMessageView from "components/Evisit/EvisitInviteMessage/EvisitInviteMessage";
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import { SecureRoute } from "./authentication/app-secure-route";
import 'App.css';
import { oktaAuth } from 'OktaConfig';
import { AccessCheckRoute } from "lib/security/access-check-route";
import SnackbarContent from "./admin/components/SnackBars/SnackbarContent";
import NotificationDrawer from "components/NotificationDrawer/NotificationDrawer";
import PNGPatientDetails from "png/views/PatientDetails/PatientDetails";
import PNGMessages from "png/views/PNGMessages/PNGMessages";
import PNGMobileWaitingRoom from "png/views/WaitingRoom/WaitingRoom";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const store = configureStore((window as any).__INITIAL_STATE__);
/* Initialize modules and libraries
 * This is a temporary place to initialize different modules and their bindings
 */
const initializeApplication = () => {
    // LIB / Security Service
    getSecurityService().initialize();
    // MODULE / EVisit
    addMessageViewProvider({
        id: "evisit",
        applyCheckerFunction: (message, conversationInfo) =>
            conversationInfo.conversationType === "EVISIT" && message.body !== null && IsJsonString(message.body),
        view: EvisitInviteMessageView,
    });
}
const destroyApplication = () => {}

const Router = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const intl = useIntl();
    const {enqueueSnackbar} = useSnackbar();
    const {errorText, errorDesc, messageId, errorCount, errorType} = useSelector((store: any) => store.error);
    const oktaAuthHandler = () => history.push('/login');

    // handle error messaging ui
    useEffect(() => {
        if (errorCount) {
            const errorMessage =  errorText || intl.formatMessage({id: messageId});
            let errorMessage2 = errorDesc || null;
            //errorDesc can be either passed as e?.message from the server, or a local strings.json call
            if (errorMessage2 && errorMessage2.includes("error.desc") ) {
                errorMessage2 = intl.formatMessage({id: errorMessage2});
            }
            const snackbarType = errorType || 'error';
            console.log(errorMessage);
            enqueueSnackbar(null, {
                preventDuplicate: true, content: (key) => (
                    <div role="alert">
                        <SnackbarContent
                            snackbarKey={key}
                            type={snackbarType}
                            message={errorMessage} desc={errorMessage2}
                        />
                    </div>
                ),
                autoHideDuration: (snackbarType === 'error') ? null : 10000
            })
        }
    }, [enqueueSnackbar, intl, dispatch, errorText, messageId, errorCount, errorDesc, errorType]);

    const restoreOriginalUri = useCallback(async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    }, [history]);

    return (
        <Security oktaAuth={oktaAuth} onAuthRequired={oktaAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
                <Route path="/login/callback" component={LoginCallback}/>
                <Route path="/login" component={Login}/>
                <Route path="/registeredProfiles/:practiceId" component={RegisteredProfiles} />  {/* //TODO need to remove Demo purpose */}
                <Route path="/register" component={Registration}/>
                <Route path="/newuser" component={PEPNewUserRequest}/>
                <Route path="/forgotPassword" component={ResetPassword}/>
                <Route path="/patient/register" component={PatientRegistration}/>
                <Route path="/provider/register" component={ProviderRegistration}/>
                <Route path="/verify-profile" component={VerifyAdditionalProfile} />
                <Route path="/patient/download-app" component={DownloadApp}/>
                <Route path="/wr/:id" component={PNGPatientDetails}/>
                <Route path="/:providerId/wr/:id" component={PNGPatientDetails}/>
                <Route path="/wr-status" component={PNGMessages}/>
                <Route path="/patient" component={PatientLogin}/>
                <Route path="/wr" component={PNGMobileWaitingRoom}/>
                <SecureRoute path="/pep/:providerName" component={PEPHome}/>
                <SecureRoute path="/provider/aha" component={AhaHome}/>
                <SecureRoute path="/patient/verify/:regToken" exact={true} component={VerifyInfo}/>
                <SecureRoute path="/patient/verify/:regToken/feedback" exact={true} component={Feedback}/>
                <SecureRoute path="/admin" component={AdminApp}/>
                <SecureRoute path="/sprofile" component={ProfileSelector}/>
                <SecureRoute path="/provider" component={ProviderRoutes}/>
                <SecureRoute path="/ahareport" component={AhaReport} />
                {/* redirect to profile selector if route unknown for now */}
                <Route path="/" exact>
                    <Redirect to="/sprofile"/>
                </Route>
                <Route component={NotFound}/>
            </Switch>
        </Security>
    )
}

const ProviderRoutes = () => {
    const dispatch = useDispatch();
    const {commonLoader} = useSelector((store: any) => store.common);
    const {currentProfileRoleJoin,currentProfileId} = useSelector((store: any) => store.user);
    const {token, client} = useSelector((store: any) => store.chat);
    const products = currentProfileRoleJoin?.products;

    useEffect(() => {
        return () => { dispatch(terminateClient()); }
    }, [dispatch])

    // handle twilio client init and termination.
    useEffect(() => {
        if (token && !client && products && checkSideNavPermission('messages', products)) {
            dispatch(initClient());
        }

    }, [dispatch, token, client,products])

    const { unauthorizedPaths } = useProtectedRoutes({
        '/provider/messages': checkSideNavPermission('messages', products),
        '/provider/people': checkSideNavPermission('people', products),
        '/provider/settings': checkSideNavPermission('settings', products),
        '/provider/waiting-room': checkSideNavPermission('waitingRoom', products)
    });

    return (
        <Switch>
            {currentProfileRoleJoin && <Route path={unauthorizedPaths} component={Unauthorized}/>}
            <Route>
                <AppWrapper>
                    <SideNav/>
                    <NotificationDrawer/>
                    {/* there is been some delay to update currentProfileId in store so i have added in condition */}
                    {currentProfileRoleJoin && currentProfileId && <Switch>
                        <SecureRoute path="/provider/home" component={Dashboard} />
                        <SecureRoute path="/provider/discover-enable" component={DiscoverEnable} />
                        <SecureRoute path="/provider/calendar" component={Dashboard} />
                        <AccessCheckRoute
                            navItemKey="messages"
                            path="/provider/messages"
                            component={Messages}
                        />
                        <AccessCheckRoute
                            navItemKey="people"
                            path="/provider/people/patients"
                            component={People}
                        />
                        <AccessCheckRoute
                            navItemKey="careTeam"
                            path="/provider/people/careteam"
                            component={CareTeam}
                        />
                        <AccessCheckRoute
                            navItemKey="remoteMonitoring"
                            path="/provider/remote-monitoring"
                            component={RemoteMonitoring}
                        />
                        <AccessCheckRoute
                            navItemKey="settings"
                            path="/provider/settings"
                            component={Settings}
                        />
                        <AccessCheckRoute
                            navItemKey="waitingRoom"
                            path="/provider/waiting-room/:inviteToken?"
                            component={WaitingRoom}
                        />
                        <SecureRoute path="/provider/practiceAdmin" component={Dashboard} />
                        <AccessCheckRoute
                            navItemKey="eConsult"
                            path="/provider/econsult/:folderName"
                            render={({ match }) => (
                                <EConsultFolderView folderName={match.params.folderName as EConsultFolderName} />
                            )}
                        />
                    </Switch>}
                    {/*Object.entries(modals).map(([key, Modal]) => <Modal key={key}/>)*/}
                    {map(modals, (Modal: any, idx) => <Modal key={idx} />)}
                    {commonLoader && <ComponentLoader />}
                </AppWrapper>
            </Route>
        </Switch>
    )
}

// App Handles various Providers and overall routing
const App = () => {
    // TODO: remove isProviderSite and resolve the discrepancies between theme files and snackbar
    const [isProviderSite, setIsProviderSite] = useState(window.location.pathname.startsWith("/provider"));
    const snackbarProps: any = !isProviderSite ? { vertical: 'top', horizontal: 'right' } : { vertical: 'bottom', horizontal: 'left' };

    history.listen((location: any) => {
        setIsProviderSite(location.pathname.startsWith("/provider"));
    });

    useEffect(() => {
        initializeApplication();
        return () => {
            destroyApplication();
        }
    }, []);

    //TODO: resolve typing issue with i18n.en
    return (
        <Provider store={store}>
            {/*
            // @ts-ignore*/}
            <IntlProvider locale="en" messages={i18n.en}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={isProviderSite ? providerTheme : adminTheme}>
                        <SnackbarProvider maxSnack={4} anchorOrigin={snackbarProps}>
                            <ConnectedRouter history={history}>
                                <CssBaseline/>
                                <Router/>
                            </ConnectedRouter>
                        </SnackbarProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </IntlProvider>
        </Provider>
    );
}

export default App;
