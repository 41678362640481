import Typography from '@mui/material/Typography';
import { getAppCurrentProfileId } from 'authentication/appAuth';
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import { AdditionalResources } from "views/PEPHome/components/additional-resources";
import { useStyles } from "./styles";

export const PEPHelp = () => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueError } = useUserNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("pep.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    return (
        isLoading ? <Spinner /> : (
            <div id="help_row" className={classes.pep_help_row}>
                <div id="help_container" className={classes.pep_help_container}>
                    <div id="help_left" className={classes.pep_help_left}>
                        <div id="help_banner" className={classes.pep_help_banner}>
                            <Typography id="help_banner_top" className={classes.pep_help_banner_top}>
                                {`Help`}
                            </Typography>
                        </div>
                        <Typography id="help_description" className={classes.pep_help_description}>
                            {`If you need assistance with Provider Engagement Platform (PEP) or electronic tools, we’re here to help.`}
                        </Typography>
                        <Typography id="help_bottom" className={classes.pep_help_bottom}>
                            {`Call our IT Help Desk at (415) 972-6008 or email `}<a href={`mailto:servicedesk@btmg.com`} target="_top" style={{ color: '#0084D5' }}>servicedesk@btmg.com.</a>
                        </Typography>
                        <Typography id="help_bottom" className={classes.pep_help_bottom}>
                            {`If you need assistance with your reports, please contact Brown & Toland Service Desk at `}<a href={`mailto:support@btmg.com`} target="_top" style={{ color: '#0084D5' }}>support@btmg.com.</a>
                        </Typography>
                        <Typography id="help_bottom" variant="h4" style={{ fontFamily: 'BentonSansCond', fontWeight: '600' }} className={classes.pep_help_bottom}>
                            {`Access Requests`}
                        </Typography>
                        <Typography id="help_bottom" className={classes.pep_help_bottom}>
                            {`- To request PEP access for your practice staff, complete the New User Build Request Form.`}
                        </Typography>
                        <Typography id="help_bottom" className={classes.pep_help_bottom}>
                            <a href="https://epiclink.brownandtoland.com/EpicCareLink_PRD/common/account_request_main.asp" target="_blank" style={{ color: '#0084D5' }} rel="noopener, noreferrer">
                                {`- EpicLink access request `}
                            </a>
                        </Typography>
                        <Typography id="help_bottom" variant="h4" style={{ fontFamily: 'BentonSansCond', fontWeight: '600' }} className={classes.pep_help_bottom}>
                            {`Training Guides`}
                        </Typography>
                        <Typography id="help_bottom" className={classes.pep_help_bottom}>
                            {`Review our helpful training guides:`}
                        </Typography>
                        <Typography id="help_bottom" className={classes.pep_help_bottom}>
                            {`- Forgot password`}
                        </Typography>
                        <Typography id="help_bottom" className={classes.pep_help_bottom}>
                            {`- Understanding your Clinical Performance and Quality reports`}
                        </Typography>
                    </div>
                    <div id="help_right" className={classes.pep_help_right}>
                        <AdditionalResources />
                    </div>
                </div>
            </div >)
    )
}