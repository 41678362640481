import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "../../../theme";


export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "640px",
        minHeight: "600px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.XL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        padding: '20px',
        textAlign: 'center',
        minWidth: '800px'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    formGpSubHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '14px',
        color: '#393948',
        paddingBottom: '15px'
    },
    previewGridClasses: {
        marginTop: '10px'
    },
    contentDialog: {
        padding: '20px'
    },
    MuiFormControlLabel: {
        clear: 'both'
    },
    dlgAction: {
        padding: '5px 16px'
    },
    noIcon: {
        height: '25px',
        width: '25px',
        verticalAlign: 'middle',
        marginRight: '11px'
    },
    paper: {
        width: '100%',
        marginTop: '16px'
    },
    addButton: {
        border: '0px',
        boxShadow: 'none',
        padding: "0px",
        margin: "0px",
        alignItems: "left"

    },
    iconBtn: {
        boxShadow: 'none',
        borderRadius: 'unset',
        padding: 0,
        height: 'unset',
        minHeight: 'unset'
    },
    lblAddAnotherEntry: {
        fontWeight: 700,
        color: '#FFFFFF',
        fontSize: '17px',
        textAlign: 'center',
        cursor: 'pointer',
        textDecoration: 'none'
    }
}));
