import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Grid, Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { AppReduxStore } from 'admin/store/reducerTypes';
import { IFormInput } from "admin/views/viewsTypes";
import { getQuestionnaireHeadersByQuestionnaireId } from 'admin/store/actions/configuration';

interface IMetaData {
  name: string;
  description: string;
  type: string;
  isEnabled: boolean;
}

interface IPatientFormHeader {
  enabled: boolean,
  headerDisplayName: string,
  headerName: string,
  id: string,
  questionnaireId: string
}

const DynamicFields = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { configuration } = useSelector((state: AppReduxStore) => state);
  const currentForm:IFormInput = configuration && configuration.sPatientFormA ? configuration.sPatientFormA : null;
  const patientFormHeaders:IPatientFormHeader[] = configuration?.patientFormHeaders;

  useEffect(() => {
      if (currentForm.id) {
        dispatch(getQuestionnaireHeadersByQuestionnaireId(currentForm.id));
      }
  }, [dispatch, currentForm.id]);

  //TODO: connect service captured in user story 1478 that return instructions for a given form remove instructions when integrated
  let formIntroductionText: IMetaData[] = [{
    name: '',
    description: 'Please answer these questions about your how your child usually behaves. If you have seen your child do the behavior a few times, but he/she does not usually do it, please answer "No".',
    type: 'introductionText',
    isEnabled: true
  }];

  //TODO remove when integrated with services captured in user story 1478
  formIntroductionText = [];

  return (
    <div className={classes.root}>
    { patientFormHeaders?.length > 0 &&
      <Grid container spacing={1}>
      { patientFormHeaders?.filter((patientFormHeader:IPatientFormHeader) => {
        return (patientFormHeader.enabled === true)
      }).map((patientFormHeader:IPatientFormHeader) => (
        <Grid item xs={6}>
          <Typography variant="h6" component="span">{patientFormHeader.headerDisplayName}:</Typography>
          <Typography variant="body1" component="span" className={classes.dynamicFieldValues}>{ intl.formatMessage({ id: "PatientFormConfig.Label.DynamicFields.PlaceHolderText" }) }</Typography>
        </Grid>
      ))}
      </Grid>
    }
    {formIntroductionText?.length > 0 &&
      <Box my={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
          {formIntroductionText?.map((text: IMetaData) => (
          <Typography variant="body1" component="p" gutterBottom>
          {text.description}
          </Typography>
          ))}
          </Grid>
        </Grid>
      </Box>
    }
  </div>
  );
}

export default DynamicFields