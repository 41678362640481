import { makeStyles } from "@mui/styles";
import { SIZING } from "admin/theme";

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    dynamicFieldValues: {
        marginLeft: SIZING.scale200
    }
}));