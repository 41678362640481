import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
        participantNameFull: {
            margin: 0,
            position: 'absolute',
            bottom: 0,
            top: 0,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection:'column',
            display: 'flex',
            color: COLORS.WHITE,
            textAlign:'center',
            background: COLORS.LBLUE,
            fontWeight: 800,
            fontSize: SIZING.scale600,
            "& svg g":{
                fill: COLORS.WHITE
            }
        }
}));