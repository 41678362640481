import React from "react";
import { ProvidersListProps } from "./types";
import { Specialist } from "../../../types";
import { useState } from "react";
import { useStyles } from "./styles";
import ProviderListItem from "./provider-list-item";
import ProviderListItemDetails from "./provider-list-item-details";

const ProvidersList = (props: ProvidersListProps) => {
    const { id, providers, selectedProviders, itemIdPrefix, ...passThroughProps } = props;
    const classes = useStyles();
    const [providerDetailsOpen, setProviderDetailsOpen] = useState<{
        provider: Specialist;
        target: React.MutableRefObject<unknown>;
    } | null>(null);

    const onProviderDetailsClose = () => {
        setProviderDetailsOpen(null);
    };
    const onProviderItemClicked = (id: string, target: React.MutableRefObject<unknown>) => {
        const relatedProvider = providers.find((provider) => provider.id === id);
        const displayedProviderId = providerDetailsOpen?.provider.id;
        if (relatedProvider && id !== displayedProviderId) {
            setProviderDetailsOpen({
                provider: relatedProvider,
                target,
            });
        } else if (displayedProviderId) {
            setProviderDetailsOpen(null);
        }
    };

    return (
        <>
            <table id={id} className={classes.providerList}>
                <tbody>
                    {providers.map((provider) => (
                        <ProviderListItem
                            key={provider.id}
                            idPrefix={itemIdPrefix}
                            provider={provider}
                            selected={
                                selectedProviders
                                    ? Boolean(selectedProviders.find(({ id }) => id === provider.id))
                                    : false
                            }
                            onNameClicked={onProviderItemClicked}
                            {...passThroughProps}
                        />
                    ))}
                </tbody>
            </table>
            {providerDetailsOpen && (
                <ProviderListItemDetails
                    anchorEl={providerDetailsOpen.target}
                    onClose={onProviderDetailsClose}
                    provider={providerDetailsOpen.provider}
                />
            )}
        </>
    );
};

export default ProvidersList;
