import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
    contentWrapper: {
        display: 'flex',
        minHeight: '100%'
    },
    rightPaneWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    rightHeader: {
        background: '#F6F6F7',
        display: 'flex',
        boxShadow: '0 1px 0 #D8D8DA',
        padding: '32px 32px 28px 28px',
        justifyContent: 'center'
    },
    rightContent: {
        padding: '16px 32px 0 28px',
    },
    leftMenuArea: {
        display: 'flex',
        background: '#F6F6F7',
        boxShadow: '0 1px 0 #D8D8DA',
        flexDirection: 'column',
        flexShrink: 0,
        minHeight: '100%'
    },
    contentArea: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: '#F6F6F7'
    }
}));