import { makeStyles } from "@mui/styles";
import {COLORS, BORDER_RADIUS, SIZING} from "admin/theme";

export const useStyles = makeStyles(() => ({
    infoBox: {
        border: "1px solid " + COLORS.BOX_BORDER_DARK,
        borderRadius: BORDER_RADIUS.MEDIUM,
        marginTop: SIZING.scale400,
        marginBottom: SIZING.scale400,
        padding: ".25rem 1rem",
    },
    infoTable: {
        fontSize: SIZING.scale400,
        width: "100%",
        padding: "0 1rem",
        textAlign: "left",
        borderCollapse: "collapse",
        '& tbody tr': {
            borderTop: "1px solid " + COLORS.BOX_BORDER_DARK
        },
        '& th, & td': {
            verticalAlign: "text-top",
            padding: ".3rem 0"
        },
        '& thead tr th': {
            fontSize: SIZING.scale500,
            paddingBottom: ".5rem"
        },
        '& tbody th': {
            fontSize: ".9rem",
            width: "30%"
        }
    },
    noWrapCell: {
        whiteSpace: "nowrap"
    },
    navBtn: {
        marginTop: ".5rem"
    },
    flagLabel: {
        '& .MuiFormControlLabel-label': {
            display: "inline-block",
            maxWidth: "90%",
            wordBreak: "break-all"
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            position: 'relative',
            width: "100%",
            maxWidth: "100%",
            paddingRight: "30px"
        },
        '& .MuiCheckbox-colorSecondary': {
            color: "#139AED"
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#f50057'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked + span': {
            color: '#f50057'
        },
        '& .MuiButtonBase-root': {
            position: 'absolute',
            right: 0
        }

    }
}));