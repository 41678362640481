import React, { useCallback, useState } from "react";
import { IPreviewProps } from "react-dropzone-uploader";
// import { call } from "store/api";
import { useUserNotification } from "common/utils/use-user-notification";
import { ERROR_STATES, UNFINISHED_STATES } from "../constants";
import { useFileDropzoneInputContext } from "./file-dropzone-input-context";
import RemoveDialog from "./remove-dialog";
import PreviewAction from "../preview/preview-action";
import PreviewBase from "../preview/preview-base";
import { IMetaWithFileId } from "../types";
import { useStyles } from "../styles";

const UploadPreview = ({
    fileWithMeta: { cancel, remove, restart },
    meta,
    meta: { name = "", previewUrl, status, type },
}: IPreviewProps) => {
    const classes = useStyles();
    const { enqueueError } = useUserNotification();
    const { disabled } = useFileDropzoneInputContext();
    const [confirmRemove, setConfirmRemove] = useState(false);
    const [removing, setRemoving] = useState(false);

    const title = name || "?";

    const hasError = ERROR_STATES.includes(status);
    const isLoading = UNFINISHED_STATES.includes(status);
    const hasUploadingStatus = status === "uploading";

    const showRemoveButton = hasUploadingStatus || (status !== "preparing" && status !== "getting_upload_params");

    const handleRemoveConfirmation = useCallback(() => {
        const fileId = (meta as IMetaWithFileId).fileId;
        if (!fileId) {
            handleRemove();
        } else {
            setConfirmRemove(true);
        }
    }, [meta]);

    const handleRemove = async () => {
        const fileId = (meta as IMetaWithFileId).fileId;
        try {
            if (fileId) {
                setRemoving(true);
                // await call("DELETE", `/file/v1/files/${fileId}`);
            }
            cancel();
            remove();
        } catch (error) {
            // TODO: add this error to the dialog
            enqueueError("files.fileDropzoneInput.dropzoneLayout.upload.remove.error", (error as Error).message);
        } finally {
            setRemoving(false);
        }
    };

    if (status === "error_validation") {
        remove();
    }

    return (
        <div id={`QA_upload_preview_${title}`} className={classes.uploadPreview}>
            <PreviewBase previewUrl={previewUrl} title={title} mimeType={type} error={hasError} loading={isLoading} />
            <div className="uploadPreview__actions">
                {showRemoveButton && (
                    <PreviewAction
                        id={`QA_upload_preview_remove_${name}`}
                        disabled={disabled}
                        onClick={handleRemoveConfirmation}
                        variant="remove"
                    />
                )}
                {["error_upload_params", "exception_upload", "error_upload", "aborted", "ready"].includes(status) && (
                    <PreviewAction
                        id={`QA_upload_preview_refresh_${name}`}
                        disabled={disabled}
                        onClick={restart}
                        variant="restart"
                    />
                )}
            </div>
            {confirmRemove && (
                <RemoveDialog
                    name={name}
                    loading={removing}
                    onRemove={handleRemove}
                    onCancel={() => {
                        setConfirmRemove(false);
                    }}
                />
            )}
        </div>
    );
};

export default UploadPreview;
