import { Box } from "@mui/material";
import classNames from "classnames";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import EmptyMessage from "common/components/empty-message/empty-message";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as EmptyIcon } from "icon-library/SVG/MapPin-Light_ALT.svg";
import { FormattedMessage } from "react-intl";
import PracticesList from "./location-list/location-list";
import { useStyles } from "./styles";
import { SelectedPracticesProps } from "./types";

const SelectedPractices = ({ selectedPractices, onRemove }: SelectedPracticesProps) => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classNames(classes.panel, classes.selectedProvidersBox)}
        >
            <Box flexShrink={1}>
                <CustomTypography variant="modalSectionHeaderBold" className="panelHeader">
                    <FormattedMessage id="fee.schedules.location.modal.selected.locations.header" values={{ value: `${selectedPractices.length}` }} />
                </CustomTypography>
            </Box>

            {selectedPractices.length === 0 ? (
                <EmptyMessage
                    className="emptyMessage"
                    Icon={<SVGIcon svg={EmptyIcon} size="scale2500" color="CREATE_E_CONSULT_REQUEST_ICON" />}
                    primaryMessage={{ id: "fee.schedules.location.modal.empty.location.primary" }}
                />
            ) : (
                <div className="providerListScrollContainer">
                    <PracticesList id="QA_selected_providers"
                        itemIdPrefix="QA_recipient"
                        selectedPractices={selectedPractices}
                        onRemove={onRemove}
                    />
                </div>
            )}
        </Box>
    );
};

export default SelectedPractices;
