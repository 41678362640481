import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { showNotification } from "./snackbar";
import { find } from "underscore";
import { USER_LEVELS, USER_ROLES } from "admin/constant";
import { getAllProducts } from "./license";
import { setSelectedPracticeId } from "./practice";
import { getChildOrganizationSummaries, setSelectedOrganization } from "./organization";
import { getAllUserLevels, getEhrSourceSystems, getPracticeEhrManagedProperties, getPrefixes, getUserLevels } from "./admin";
import { setConfugrationSettings } from "./configuration";
import { setWelcomeModalActive } from "./modalAdmin";
import { makeApiCall } from "admin/common/utils";
import { signInAppWithRedirect } from "authentication/appAuth";

export const setLoggedInUser = (currentProfileId: string | undefined) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        let user: any = null
        let currentProfileRoleJoinId: string | undefined;
        await makeApiCall(dispatch, "GET", "/admin/v1/user/current").then((payload) => {
            user = payload
            currentProfileRoleJoinId = payload?.user?.currentProfileRoleJoinId;
        }).catch(err => {
            dispatch(showNotification("Get Current User", "error", String(err && err.message)))
        });
        return makeApiCall(dispatch, "GET", `/admin/v1/user/profiles`).then((payload) => {
            const currentProfileRoleJoin = getCurrentRoleJoin(payload, currentProfileRoleJoinId);
            dispatch({ type: 'SET_CURRENT_PROFILES', payload: { currentProfiles: payload } })
            dispatch({ type: 'SET_USER_DETAILS', payload: { userDetails: user?.user } })
            dispatch({ type: 'SET_CURRENT_PROFILE_ROLE_JOIN_INFO', payload: { currentProfileRoleJoinInfo: currentProfileRoleJoin } })
            // User Details
            dispatch({ type: 'SET_USER', payload: { ...user?.user, currentProfileRoleJoin } })
            dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } })

            const ALTAIS_ADMINS = [USER_LEVELS.ALTAIS_ADMIN_1, USER_LEVELS.ALTAIS_ADMIN_2, USER_LEVELS.ALTAIS_ADMIN_3]
            const ORG_ADMINS = [USER_LEVELS.ORG_ADMIN_1, USER_LEVELS.ORG_ADMIN_2]
            const PRACTICE_ADMINS = [USER_LEVELS.PRACTICE_ADMIN]
            const { level } = currentProfileRoleJoin || {}
            const { name } = level || {}
            const altaisAndOrgAdmins = (ALTAIS_ADMINS.includes(name) || ORG_ADMINS.includes(name))
            const practiceAdmin = PRACTICE_ADMINS.includes(name)
            const authorizedAdmins = (practiceAdmin || altaisAndOrgAdmins)
            if (!authorizedAdmins) return
            dispatch(getEhrSourceSystems())
            if (currentProfileRoleJoin?.organization) {
                dispatch(setCurrentOrganization(currentProfileRoleJoin?.organization))
                dispatch(getChildOrganizationSummaries(currentProfileRoleJoin?.organization?.id));
            }
            else if (currentProfileRoleJoin?.practice) {
                dispatch(setCurrentPractice(currentProfileRoleJoin?.practice))
            }
            dispatch(getAllUserLevels())
            dispatch(getUserLevels(currentProfileRoleJoin?.id))
            //  Get All Products
            dispatch(getAllProducts())
            dispatch(getPrefixes())

        }).catch(err => {
            dispatch(showNotification("Get User Details", "error", String(err && err.message)))
        });
    }
}
export const setCurrentProfile = (profile: any, isProvider: boolean) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await makeApiCall(dispatch, "PATCH", `/admin/v1/user/current-profile/${profile?.id}`)
        } catch (e: any) {
            dispatch(showNotification("Failed to set current profile", "error", String(e?.message)))
        }
        let originalUri = ""
        switch (profile?.type) {
            case USER_ROLES.ADMIN:
                originalUri = "/admin/home"
                break
            case USER_ROLES.PROVIDER:
                originalUri = isProvider ? "/provider/home" : "/admin/home"
                break
        }
        await signInAppWithRedirect({ originalUri });

    }
}

export type eventRequestProps = {
    argument: string,
    contextPath: string,
    eventType: string,
    eventUrl: string,
    profileRoleJoinId: string,
    userId: string
};

export const sendEvent = (params: eventRequestProps) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        return makeApiCall(dispatch, "POST", `/admin/v1/event-log/log`, params);
    }
}

export const setCurrentOrganizationById = (currentOrgId: any, history: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return await makeApiCall(dispatch, "GET", `/admin/v1/organization/${currentOrgId}`).then((payload) => {
            dispatch({ type: 'SET_CURRENT_ORGANIZATION', payload: { currentOrg: payload } })
            dispatch({ type: 'SET_CURRENT_PRACTICE', payload: { currentPractice: null } })
            dispatch(setSelectedOrganization(currentOrgId))
            history.push("/admin/home")
        }).catch(err => {
            dispatch(showNotification("Error", "error", String(err && err.message)))
        });
    }
}
export const setCurrentPracticeById = (currentPracticeId: any, history: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        return await makeApiCall(dispatch, "GET", `/admin/v1/practice/${currentPracticeId}`).then((payload) => {
            dispatch({ type: 'SET_CURRENT_PRACTICE', payload: { currentPractice: payload } })
            dispatch({ type: 'SET_CURRENT_ORGANIZATION', payload: { currentOrg: null } })
            dispatch(setSelectedPracticeId(currentPracticeId, ""))
            dispatch(getPracticeEhrManagedProperties(currentPracticeId))
            dispatch(setConfugrationSettings())
            dispatch(setWelcomeModalActive(false))
            history && history.push("/admin/home")
        }).catch(err => {
            dispatch(showNotification("Error", "error", String(err && err.message)))
        });
    }
}

export const setCurrentOrganization = (currentOrg: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({ type: 'SET_CURRENT_ORGANIZATION', payload: { currentOrg: currentOrg } })
        dispatch({ type: 'SET_CURRENT_PRACTICE', payload: { currentPractice: null } })
        dispatch(setSelectedOrganization(currentOrg.id))
    }
}
export const setCurrentPractice = (currentPractice: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, any>) => {
        dispatch({ type: 'SET_CURRENT_PRACTICE', payload: { currentPractice: currentPractice } })
        dispatch({ type: 'SET_CURRENT_ORGANIZATION', payload: { currentOrg: null } })
        dispatch(setSelectedPracticeId(currentPractice.id, currentPractice.name))
        dispatch(getPracticeEhrManagedProperties(currentPractice.id))
        dispatch(setConfugrationSettings())
        dispatch(setWelcomeModalActive(false))
    }
}

const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined) => {
    if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
    return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
}