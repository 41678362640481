import {
    Box, Button, Checkbox, DialogActions, DialogContent, IconButton, Input, Paper, Step, StepLabel, Stepper, Typography
} from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { makeApiCall } from 'admin/common/utils';
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from "admin/store/reducerTypes";
import Locations from "admin/views/FeeSchedules/components/locations/locations";
import { AddGroupResponse } from "admin/views/FeeSchedules/components/locations/types";
import { FeeScheduleRegionStateType, feeScheduleGroupRequestProps, feeScheduleRegionProps } from "admin/views/FeeSchedules/types";
import Spinner from "common/components/Spinner/Spinner";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useStyles } from "./styles";

const useRegionsByOrganizationId = (orgId: string) => {
    const { content, fetchInfo, request } = useApi<{ orgId: string }, feeScheduleRegionProps[]>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: [],
        url: `/admin/v1/region/Organization/${orgId}`,
        method: HttpMethod.GET
    });

    useEffect(() => {
        if (orgId) {
            request({ orgId });
        }
    }, [orgId, request]);

    return {
        regionList: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};

const FeeSchedulesAddGroup = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { modalAdmin } = useSelector((state: AppReduxStore) => state);
    const orgId = modalAdmin?.modalProps?.["fee-schedule-add-group-orgId"];
    const onDone = modalAdmin?.modalProps?.onFinishedAddingGroup;
    const { regionList, hasResult } = useRegionsByOrganizationId(orgId);

    const initialRegionState = () => {
        const newRegionsState: FeeScheduleRegionStateType = {};
        if (hasResult && regionList?.length > 0) {
            regionList.forEach((region: feeScheduleRegionProps) => {
                newRegionsState[region.id] = true;
            });
        }
        return newRegionsState;
    };

    const onClose = async () => {
        setGroupName("");
        setSelectedPractices([]);
        setSelectedStepIndex(0);
        setRegionsState({});
        dispatch(setActiveModal(""));
    }

    const [selectedStepIndex, setSelectedStepIndex] = useState(0);
    const [groupName, setGroupName] = useState<string>("");
    const [regionsState, setRegionsState] = useState<FeeScheduleRegionStateType>(initialRegionState());
    const [selectedPractices, setSelectedPractices] = useState<any[]>([]);
    
    const handleStepClick = ((event: any, index: number) => {
        setSelectedStepIndex(index);
    });

    const isStepCompleted = (index: number): boolean => {
        switch (index) {
            case 0:
                if (groupName && groupName !== "") {
                    let hasOneSelectedRegion = false;
                    for (const [key] of Object.entries(regionsState)) {
                        if (regionsState[key])
                            hasOneSelectedRegion = true;
                    }
                    return true && hasOneSelectedRegion;
                }
                else
                    return false;
            case 1:
                if (selectedPractices?.length > 0)
                    return true;
                else
                    return false;
        }
        return false;
    }

    const handleSaveGroup = async (event: any) => {
        event.preventDefault();
        const selectedRegionsList: string[] = [];
        for (const [key] of Object.entries(regionsState)) {
            if (regionsState[key])
                selectedRegionsList.push(key);
        }
        const selectedPracticesList: string[] = selectedPractices.map((practice) => { return practice.practice.id; });

        const params: feeScheduleGroupRequestProps = {
            name: groupName,
            orgId: orgId,
            regionIds: selectedRegionsList,
            assignedPracticeIds: selectedPracticesList
        };
        await makeApiCall(dispatch, "POST", `/admin/v1/billing/group`, params).then((payload: AddGroupResponse) => {
            onClose();
            history.push('/admin/feeschedules/groups');
            if (!!onDone)
                onDone();
            dispatch(showNotification(`Billing group ${payload.name} has been created successfully.`, "success", `Billing group ${payload.name} has been created successfully.`));
        }).catch((error: any) => {
            dispatch(showNotification("Error creating billing group.", "error", "Error creating billing group."));
        });
    }

    const selectedRegions: feeScheduleRegionProps[] = regionList.filter((region: feeScheduleRegionProps) => {
        return (regionsState[region.id]);
    });

    return (
        <Modal onClose={onClose} classes={classes.wrapper} id={MODAL_TYPES.FEE_SCHEDULES_ADD_GROUP}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {`New Fee Schedule Group`}
                    </Typography>
                </Box>
                <Box>
                    <IconButton size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Button id="btnNext" variant="contained" color="primary"
                        className={(selectedStepIndex === 0) ? classes.dlgButton : classes.dlgButtonHidden}
                        disabled={!isStepCompleted(0)}
                        onClick={() => {
                            setSelectedStepIndex(1);
                        }}>
                        {`Next`}
                    </Button>
                    <Button id="btnSaveGroup" variant="contained" color="primary"
                        className={(selectedStepIndex === 1) ? classes.dlgButton : classes.dlgButtonHidden}
                        disabled={!(isStepCompleted(0) && isStepCompleted(1))}
                        onClick={handleSaveGroup}>
                        {`Save Group`}
                    </Button>
                </Box>
            </DialogActions>
            <DialogContent style={{ padding: '0px' }} dividers={true} >
                <Paper style={{ width: '1150px', height: '500px', display: 'flex' }} >
                    <Box className={classes.leftPaneWrapper}>
                        <Stepper activeStep={selectedStepIndex} orientation="vertical"
                            classes={{ root: classes.stepperRoot }}>
                            <Step completed={isStepCompleted(0)} active={selectedStepIndex === 0}>
                                <StepLabel classes={{ completed: classes.stepperIconCompleted, active: classes.stepperIconCompleted }} onClick={(event) => handleStepClick(event, 0)}>
                                    <Typography variant="h4" className={classes.stepperLabel}>
                                        {`Group Properties`}
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step completed={isStepCompleted(1)} active={selectedStepIndex === 1}>
                                <StepLabel classes={{ completed: classes.stepperIconCompleted, active: classes.stepperIconCompleted }} onClick={(event) => handleStepClick(event, 1)}>
                                    <Typography variant="h4" className={classes.stepperLabel}>
                                        {`Practice Locations`}
                                    </Typography>
                                </StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                    <Box className={classes.rightContent}>
                        {(selectedStepIndex === 0) ?
                            (<div style={{ padding: '15px 25px' }}>
                                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948' }}>
                                    {`Group Name`}
                                </p>
                                <Input
                                    style={{ border: '1px solid #909098', borderRadius: '4px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '6px 12px', gap: '10px', width: '438px', height: '38px', background: '#FFFFFF', fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', marginBottom: '20px' }}
                                    disableUnderline={true}
                                    value={groupName}
                                    onChange={(event: any) => {
                                        const value = event.target.value;
                                        setGroupName(value);
                                    }}
                                    className={classes.feeScheduleAddGroupInput}
                                    placeholder={`Enter a group name`}
                                />
                                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948' }}>
                                    {`Region`}
                                </p>
                                {(hasResult ? ((regionList?.length > 0) ? (
                                    <ul style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0', margin: '0px' }}>
                                        {regionList.map((region: feeScheduleRegionProps, index: number) => {
                                            return (<li key={`fee_schedule_region_${index}`}
                                                style={{ listStyle: 'none', flex: '0 0 33.333333%', marginTop: '5px', marginBottom: '5px', fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%' }}>
                                                <Checkbox
                                                    color="primary" disableRipple disableFocusRipple
                                                    checked={regionsState[region.id] || false}
                                                    onChange={(event) => {
                                                        const newValue: boolean = Boolean(event.target.checked);
                                                        const newRegionsState = Object.assign({}, regionsState);
                                                        newRegionsState[region.id] = newValue;
                                                        setRegionsState(newRegionsState);
                                                        setSelectedPractices([]);
                                                    }}
                                                    id={`fee_schedule_region_checkbox_${index}`} />
                                                {region.name}
                                            </li>);
                                        })}
                                    </ul>
                                ) : (<p>{`There is no region associated with this organization`}</p>)) : <Spinner />)}
                            </div>) :
                            (<Locations
                                orgId={orgId}
                                selectedRegions={selectedRegions}
                                selectedPractices={selectedPractices}
                                onChange={setSelectedPractices}
                            />)
                        }
                    </Box>
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default FeeSchedulesAddGroup