import { makeStyles } from "@mui/styles";
import {COLORS} from "admin/theme";

export const useStyles = makeStyles(() => ({
    subject: {
        fontSize: "1.1rem",
        fontWeight: 400,
    },
    borderRow: {
        borderTop: "1px solid " + COLORS.BOX_BORDER,
        borderBottom: "1px solid " + COLORS.BOX_BORDER,
        fontSize: "1rem",
        padding: "1rem 0",
        margin: "1rem auto",
        "& .MuiButton-textPrimary": {
            minWidth: "140px",
            marginLeft: "1rem",
            "& .MuiTypography-button": {
                fontSize: ".9rem"
            }
        }
    },
    tosLink: {
        color: COLORS.LINK_COLOR,
        display: "inline-block",
        margin: "0 .5rem",
        textAlign: "left",
        width: "50%"
    },
    acceptBtn: {
        padding: ".4rem 1.6rem",
        "& .MuiTypography-button": {
            fontSize: ".9rem"
        }
    },
    acceptedBtn: {
        backgroundColor: COLORS.SECONDARY_BTN,
        color: "#ffffff",
        fontSize: ".9rem",
        "&.MuiButton-text, &.Mui-disabled": {
            color: "#ffffff",
            minWidth: "140px",
            marginLeft: "1rem",
            "& .MuiTypography-button": {
                fontSize: ".9rem"
            }
        }
    }
}));