import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as WarningDiamond } from "icon-library/SVG/Warning_Diamond.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { getDiffDuration } from "utils";
import { useRequestInfo } from "./use-request-info";
import { useStyles } from "./styles";
import { EConsultRequest } from "./types";

interface DetailsHeaderSubjectProps extends EConsultRequest {
    idPrefix: string;
}

const DetailsHeaderSubject = ({ idPrefix, eConsultRequest }: DetailsHeaderSubjectProps) => {
    const classes = useStyles();
    const intl = useIntl();
    const { status } = useRequestInfo(eConsultRequest);
    const expired = status === "EXPIRED";
    const showSubjectWarning = eConsultRequest.overdue || eConsultRequest.urgent;

    const getOverdueMessage = () => {
        return eConsultRequest.overdue ? intl.formatMessage({ id: "EConsult.request.content.overdue" }) : null;
    };

    const getTimeLeft = () => {
        if (!eConsultRequest.deadlineDate || expired) {
            return null;
        }
        const diffInDays = Math.floor(Math.abs(getDiffDuration(eConsultRequest.deadlineDate).asDays()));
        return intl.formatMessage({ id: "EConsult.request.content.diffInDays" }, { diffInDays });
    };

    const getUrgentTimeLeft = () => {
        const message = getOverdueMessage() || getTimeLeft();
        return message ? `(${message})` : null;
    };

    return (
        <div className={classes.subject}>
            {showSubjectWarning && (
                <CustomTypography
                    id={`${idPrefix}_subjectWarning`}
                    variant="titleHeader"
                    className="eConsult__details__header__subjectWarning"
                >
                    {eConsultRequest.urgent ? (
                        <span id={`${idPrefix}_urgent`} className="eConsult__details__header__urgent">
                            <SVGIcon className={classes.urgentIcon} svg={WarningDiamond} size="scale600" />
                            <FormattedMessage id={"EConsult.request.content.urgent"} />
                            &nbsp;{getUrgentTimeLeft()}
                        </span>
                    ) : (
                        getOverdueMessage()
                    )}
                </CustomTypography>
            )}
            <CustomTypography id={`${idPrefix}_subject`} variant="titleHeader">
                {eConsultRequest.subject}
            </CustomTypography>
        </div>
    );
};

export default DetailsHeaderSubject;
