import { makeStyles } from "@mui/styles";
import { SIZING, COLORS} from "theme";

export const useStyles = makeStyles(() => ({
        root:{
            height:'100vh',
            padding:SIZING.scale1200,
            display:'flex',
            flexDirection:'column',
            overflowY:'auto',
        },
        title:{
            fontSize: SIZING.scale800,
            color: COLORS.LBLUE,
            fontWeight: 200
        },
        desc:{
            color: COLORS.DBLACK,
            fontSize: SIZING.scale500,
            fontWeight: 300,
            marginTop: SIZING.scale800,
            lineHeight: SIZING.scale800,
        },
        cardWrapper:{  
            marginTop: SIZING.scale600,
            marginBottom: SIZING.scale600,
            
        },
        footer:{
           
            background:COLORS.DGREY,
            width:"100%",
            borderRadius:SIZING.scale1200,
            display:"flex",
            alignItems:"center",
            padding:SIZING.scale300,
            boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.05), inset 0px 0px 1px rgba(0, 0, 0, 0.4)"
        },
        footerBtn:{
           background:COLORS.WHITE,
           color:COLORS.LBLUE,
           height:"3.3rem",

           fontSize:"1.125rem !important",
           "&:hover":{
            background:"#FFFFFF"
          },
           marginRight:SIZING.scale300,
           "& svg":{
            fontSize:"2rem !important",
            "& path":{
              fill:COLORS.LBLUE
            }
          },
          "& .MuiButton-label":{
            paddingRight:SIZING.scale300
          }
        },
        footerBtn1:{
            background:COLORS.WHITE,
            color:COLORS.LBLUE,
            height:"3.3rem",
            width:"8.5rem",
            fontSize:"1.125rem !important",
            "&:hover":{
             background:COLORS.WHITE
           },
            marginRight:SIZING.scale100,
            "& svg":{
              fontSize:`${SIZING.scale800} !important`,
             "& path":{
               fill:COLORS.LBLUE,
             }
           },
           "& .MuiButton-label":{
            paddingRight:SIZING.scale200
          }
         },
        btnIcon:{
            marginRight:SIZING.scale200
        },
        swiperControlWrapper:{
          marginLeft:'auto',
          display:'flex',
          alignItems:'center',
        },
        swiperPaginationWrapper:{
          display:'flex',
          alignItems:'center',
          "& div":{
            marginRight: SIZING.scale300,
            height:SIZING.scale400,
            width:SIZING.scale400,  
            background: COLORS.LGRAY,
            cursor:'pointer',
            borderRadius:'50%',
            "&.active":{
              background: COLORS.LGREEN
            }
          }
        },
        nextButton:{
          fontSize:"1.125rem !important",
          background:COLORS.LGREEN,
          "&:hover":{
          background:COLORS.LGREEN,
          },
          height: '3.3rem',
          marginLeft:SIZING.scale400,
          padding:`${SIZING.scale500} ${SIZING.scale600}`,
          "& svg":{
            marginLeft: SIZING.scale500,
            fontSize:`${ SIZING.scale600} !important`,
            "& path": {
              fill: COLORS.WHITE,
            }
          }
        }
}));