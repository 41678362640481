import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {  SIZING,COLORS } from "theme"

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
      borderRadius: theme.spacing(2),
    },
    root: {
      width: theme.spacing(45),
      padding: theme.spacing(1),
      position: "relative",
      "& .title": {
        color: COLORS.ITEM_BADGE_BG,
        paddingBottom: theme.spacing(1),
      },
      "& .content": {
        fontSize: SIZING.scale300,
        color: COLORS.LIST_SUBHEADER_TEXT,
        fontWeight: 'normal',
        paddingBottom: "5%",
      },
      "& .send-container": {
        alignSelf: "center",
        "& button":{
            "&:hover":{
              backgroundColor:COLORS.DEFAULT_BTN
            }
        }
      },
      "& .closeIcon": {
        position: "absolute",
        right: 0,
        top: 0,
        height: theme.spacing(1),
        width: theme.spacing(1),
        margin: theme.spacing(1),
      },
    },
    btnSendInstruction:{
        backgroundColor:COLORS.DEFAULT_BTN,
        marginTop: theme.spacing(1),
    }
  }));