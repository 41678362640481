import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_TYPES } from "constant";
import { setActiveModal } from "store/actions";
import Spinner from "common/components/Spinner/Spinner";
import { Box } from "@mui/material";
import Modal from "components/Modal/Modal";
import ModalHeader from "components/Modal/ModalHeader";
import ModalContent from "components/Modal/ModalContent";
import { getPatientDetails, getFeedbackLoop } from "store/actions";
import { AppReduxStore } from "store/reducerTypes";
import Alerts from "views/People/Alerts/PatientAlerts";
import Forms from "views/People/Forms/Forms";
import Appointments from "views/People/Appointments/Appointments";
import PatientSummary from "views/People/PatientSummary/PatientSummary";
import RPM from "views/People/RPM/RPM";
import AlertDetails from "views/People/AlertDetails/AlertDetails";
import { TABS } from "views/People/constants";
import { Props, PatientDetailsBodyProps } from "./types";
import { TabNames } from "views/People/PatientDetailsHeader/SummaryTabs/types";
import { useStyles } from "./styles";
import PatientInfoHeader from "./PatientInfoHeader";

const PatientDetailsBody = ({
    onAlertSelected,
    selectedAlertId,
    selectedTab,
}: PatientDetailsBodyProps) => {
    switch (selectedTab) {
        case TABS.ALERTS:
            if (selectedAlertId) {
                return (
                    <AlertDetails
                        clearSelectedAlertId={() => {
                            onAlertSelected?.();
                        }}
                        selectedAlertId={selectedAlertId}
                    />
                );
            }
            return <Alerts onAlertSelected={onAlertSelected} />;
        case TABS.APPOINTMENTS:
            return <Appointments />;
        case TABS.FORMS:
            return <Forms />;
        case TABS.RPM_MONITOR:
            return <RPM />;
        case TABS.SUMMARY:
        default:
            return <PatientSummary />;
    }
};

const PatientInfo = () => {
    const dispatch = useDispatch();
    const { activeModalProps } = useSelector(
        (store: AppReduxStore) => store.modal
    );
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState<TabNames>(TABS.SUMMARY);
    const [selectedAlertId, setSelectedAlertId] = useState<string>();
    const patientProfileId = (activeModalProps as Props)?.patientProfileId;
    const { loadingDetails, selectedPatient } = useSelector(
        (state: AppReduxStore) => state.patient
    );

    const onClose = useCallback(() => {
        dispatch(setActiveModal(""));
        setSelectedTab(TABS.SUMMARY);
    }, [dispatch]);

    useEffect(() => {
        if (patientProfileId) {
            dispatch(getPatientDetails(patientProfileId));
            dispatch(getFeedbackLoop(patientProfileId));
        }
    }, [dispatch, patientProfileId]);

    return (
        <Modal maxWidth="md" id={MODAL_TYPES.PROVIDER.PATIENT_INFO}>
            <Box
                className={classes.wrapper}
                display="flex"
                flexDirection="column"
            >
                <ModalHeader className={classes.header}>
                    <PatientInfoHeader
                        loading={!selectedPatient}
                        onClose={onClose}
                        selectedTab={selectedTab}
                        onTabSelected={setSelectedTab}
                    />
                </ModalHeader>
                {loadingDetails ? (
                    <Spinner />
                ) : (
                    <Box className={classes.contentWrapper}>
                        <ModalContent className={classes.content}>
                            <PatientDetailsBody
                                selectedAlertId={selectedAlertId}
                                selectedTab={selectedTab}
                                onAlertSelected={setSelectedAlertId}
                            />
                        </ModalContent>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default PatientInfo;
