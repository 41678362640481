import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
    titleWrapper: {
        padding: SIZING.scale800,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: COLORS.LEARNING_CENTER_HEADER_BG,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
    },
    close: {
        cursor: 'pointer'
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: SIZING.scale1000,
        background: COLORS.LEARNING_CENTER_BG
    },
    insideBox: {
        background: "white",
        minWidth: "790px",
        boxShadow: "0px 1px 4px rgb(0 0 0 / 5%)",
        borderRadius: SIZING.scale200,
        minHeight: "383px",
        padding: SIZING.scale800,
        display: "flex",
        flexDirection: "column",
        fontWeight: "normal"
    },
    links: {
        color: COLORS.ITEM_COUNT_BG,
        cursor: "pointer",
        fontSize: SIZING.scale400,
        fontWeight:"normal"
    },
    links1: {
        color: COLORS.ITEM_COUNT_BG,
        cursor: "pointer",
        fontSize: SIZING.scale400
    },
    insideTextBox: {
        height: SIZING.scale2900
    },
    insideText: {
        marginLeft: SIZING.scale400,
        marginTop: SIZING.scale400,
        fontSize: SIZING.scale400
    },
    insideText1: {
        marginLeft: SIZING.scale400,
        fontSize: SIZING.scale400,
        width: "210px"
    },
    insideTextBox1: {
        height: SIZING.scale2900,
        marginTop:`-${SIZING.scale600}`
    },
    insideTextBox3: {
        height: SIZING.scale2900,
        marginTop:SIZING.scale600
    }

}));