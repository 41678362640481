import { Grid, Typography, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { ReactComponent as AddUser } from "icon-library/SVG/User_Single.svg";
import { createSvgIcon } from "utils";
import { sendInvites, updateWebAdminCachePractice } from "admin/store/actions";
import { ADMIN_WEB_CACHE } from "admin/constant";
import { setIsAllSelectedForBulk } from "admin/store/actions";
import EditRegistrationPreview from "../../EditRegistration/EditRegistrationPreview"
import { showNotification } from "admin/store/actions/snackbar";

const AddUserIcon = createSvgIcon(AddUser);

interface Data {
    userId: string
    profileRoleId: string
    profileId: string
    username: string
    firstName: string
    lastName: string
    fullName: string
    ehrUsername: string
    userLevel: string
    status: string
    lastLoginTime: string
    productCount: any
    action: string
}

const InviteUserConfigure = forwardRef((props: any, ref) => {
    const { admin, auth, configuration } = useSelector((state: AppReduxStore) => state);
    const { userList, adminWebCachePractice } = admin
    const { registrationEmailProvider: reg } = configuration
    const classes = useStyles();
    const dispatch = useDispatch()
    const intl = useIntl();


    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    type Order = 'asc' | 'desc';

    const [orderBy] = useState<keyof Data>('fullName');
    const [order] = useState<Order>('asc');
    const [selected, setSelected] = useState<string[]>([]);
    const [allSelectedCheked, setAllSelectedCheked] = useState(false);

    useImperativeHandle(
        ref,
        () => ({
            saveAndContinueLaterAction(index: number) {
                return saveAndContinue(index);
            },
            sendInvites(index: number) {
                return sendUserInvites(index);
            }
        }),
    )

    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {}
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgName: auth.currentOrg?.name,
            tempFormData: {
                name: admin.configurePracticeName,
                configurePractice: true,
                inviteUserDetails: null,
                selectedStep: index
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }

    const handleCheckboxClick = (event: React.MouseEvent<unknown>, profileId: string) => {
        const selectedIndex = selected.indexOf(profileId);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, profileId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    const handleSelectAllChanged = (isAllSelected: boolean) => {
        setAllSelectedCheked(isAllSelected)
        dispatch(setIsAllSelectedForBulk(isAllSelected))
        setSelected([]);
    }
    
    const sendUserInvites = (index: number) => {
        if (!allSelectedCheked && (!selected || selected.length === 0)) {
            dispatch(showNotification("Bulk Action", "error", "No User(s) Selected"))
            return
        }
        const sProfileJoinIdsAndEmails = selected && selected.map((sProfileId: any) => {
            const u = userList && userList.find((u: any) =>
                (u.profileId === sProfileId)
            )
            return { profileRoleId: u?.profileRoleId, email: u?.email }
        })
        if (allSelectedCheked) {
            dispatch(sendInvites([]))
        }
        else {
            const sProfileRoleIdsHaveEmails = sProfileJoinIdsAndEmails.filter(u => u.profileRoleId && u.email)
            if (sProfileRoleIdsHaveEmails && sProfileRoleIdsHaveEmails.length > 0) {
                dispatch(sendInvites(sProfileRoleIdsHaveEmails.map(u => u.profileRoleId)))
            }
            const sProfileRoleIdsDontHaveEmails = sProfileJoinIdsAndEmails.filter(u => !u.email || u.email?.trim() === "")
            if (sProfileRoleIdsDontHaveEmails?.length > 0) {
                dispatch(showNotification("Error", "error",
                    `${sProfileRoleIdsDontHaveEmails?.length} ${intl.formatMessage({ id: "Admin.SendInvite.MissingEmail" })}`
                ))
            }
        }
    }
    return (<>
        <Grid item className={classes.productsPageHeader}>
            <Typography className={classes.pageHeaderText}>
                <AddUserIcon fill="#0F7BBD" className={classes.productsIcon} />
                <FormattedMessage id="InviteUsers.Text.Config.Invite" />
            </Typography>
            <Typography className={classes.pageSubHeaderText}>
                <FormattedMessage id={"InviteUsers.Text.Config.Invite.SubText"} />
            </Typography>
        </Grid>
        <Grid container className={classes.flexInline}>
            <Grid item xs={6} style={{ paddingRight: "25px", backgroundColor: "#F6F8FA" }}>
                <TableContainer>
                    <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        onClick={() => handleSelectAllChanged(!allSelectedCheked)}
                                    />
                                </TableCell>
                                <TableCell
                                    key={"fullName"}
                                    align={'left'}
                                    padding={'none'}
                                    sortDirection={orderBy === "fullName" ? order : false}>
                                    <TableSortLabel>
                                        {'User'}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                userList.map((row: any, index: number) => {
                                    const isItemSelected = isSelected(row.profileId ? row.profileId.toString() : '');
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox color="primary" onClick={(event) => handleCheckboxClick(event, row.profileId ? row.profileId.toString() : '')}
                                                    checked={isItemSelected || allSelectedCheked} disabled={allSelectedCheked}
                                                    inputProps={{ 'aria-labelledby': labelId }} />
                                            </TableCell>
                                            <TableCell id={labelId} scope="row" padding="none" width={250}>
                                                <Tooltip arrow title={row.fullName ? row.fullName.toString() : ''}>
                                                    <Typography noWrap variant="body1" style={{ width: '250px', fontSize: '14px', fontWeight: 400 }}> {row.fullName}</Typography>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                
            </Grid>
            <Grid item xs={6} style={{ paddingRight: "25px", backgroundColor: "#F6F8FA" }}>
                <EditRegistrationPreview subject={reg?.subject} body={reg?.body}
                    closing={reg?.closing} greeting={reg?.greeting} logoUrl={reg?.logoUrl}
                    signature={reg?.signature} />
            </Grid>
        </Grid>
    </>)
})

export default InviteUserConfigure;