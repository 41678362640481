import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { FormattedMessage } from "react-intl";
import { Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import DiscoverCards from "components/DiscoverEnable/DiscoverCards";
import { config } from "./config";
import { ReactComponent as Altais } from "icon-library/SVG/Altais-Star.svg";
import { ReactComponent as ChevronRight } from "icon-library/SVG/Chevron_Right.svg";
import { ReactComponent as ReplayAgain } from "icon-library/SVG/Rotate_Left.svg";
import { ReactComponent as Envelop } from "icon-library/SVG/eMail_Closed-Envelope.svg";
import { createSvgIcon } from "utils";
import { MODAL_TYPES } from "constant";
import { setActiveModal } from "store/actions";
import { useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import 'swiper/swiper.min.css';
const { Swiper, SwiperSlide } = require('swiper/react');
const DiscoverEnable = () => {

    const dispatch = useDispatch();
    const { common } = useSelector((store: AppReduxStore) => store)
    const { isNavCollapse } = common
    const classes = useStyles()
    const AltIcon = createSvgIcon(Altais)
    const EnvIcon = createSvgIcon(Envelop)
    const ChevronRightIcon = createSvgIcon(ChevronRight)
    const ReplayAgainIcon = createSvgIcon(ReplayAgain)
    const [currentSlide, setcurrentSlide] = useState<any>(0)
    const [reRenderSlide, setReRenderSlide] = useState<any>(false)
    const [swiper, setSwiper] = useState<any>(null)

    const handleModal = () => {
        dispatch(setActiveModal(MODAL_TYPES.PROVIDER.CONTACT))
    }

    const handleSwiper = (index?: number | null) => {
        const nextSlide = currentSlide + 1;
        const finalIndex = index === null ? (nextSlide > config.length - 1 ? 0 : nextSlide) : index;
        setcurrentSlide(finalIndex)
        swiper?.slideTo(finalIndex)
    }
    useEffect(() => {
        setReRenderSlide(isNavCollapse)
    }, [isNavCollapse])

    const isLastSlide = config.length === currentSlide + 1;
    return (
        <div className={classes.root}>
            <div>
                <Typography className={classes.title} >
                    <FormattedMessage id="DiscoverPage.label.Title" />
                </Typography>
                <Typography className={classes.desc} >
                    <FormattedMessage id="DiscoverPage.label.desc" />
                </Typography>
            </div>
            {
                reRenderSlide ?

                    <div className={classes.cardWrapper}  key="slideOne">
                        <div >
                            <Swiper
                                onSwiper={setSwiper}
                                loop={false}
                            >
                                {config.map((module: any, index: number) => {
                                    return <SwiperSlide key={index}>
                                        <DiscoverCards
                                            key={module}
                                            {...module}
                                        /></SwiperSlide>
                                })}
                            </Swiper>
                        </div>
                    </div >
                    :
                    <div className={classes.cardWrapper}  key="slideTwo">
                        <div >
                            <Swiper
                                onSwiper={setSwiper}
                                loop={false}
                            >
                                {config.map((module: any, index: number) => {
                                    return <SwiperSlide key={index}>
                                        <DiscoverCards
                                            key={module}
                                            {...module}
                                        /></SwiperSlide>
                                })}
                            </Swiper>
                        </div>
                    </div >
            }
            <footer className={classes.footer}>
                <Button className={classes.footerBtn} onClick={handleModal}><AltIcon className={classes.btnIcon} />Contact Us</Button>
                <Button className={classes.footerBtn1}
                    href="mailto:?subject=Discover%20Altais%20eNable&body=I%20wanted%20to%20share%20the%20following%20webpage%20with%20you%20about%20Altais%20eNable...%0d%0a%0d%0ahttps://altais.com/enable-in-action/">
                    <EnvIcon className={classes.btnIcon} />Share
                </Button>
                <div className={classes.swiperControlWrapper}>
                    <div className={classes.swiperPaginationWrapper}>
                        {config.map((data, index) => <div onClick={e => handleSwiper(index)} className={index === currentSlide ? 'active' : ''} />)}
                    </div>
                    <div>
                        <Button
                            onClick={e => handleSwiper(null)}
                            className={classes.nextButton}
                            aria-haspopup="true" endIcon={isLastSlide ? <ReplayAgainIcon /> : <ChevronRightIcon />}>
                            <FormattedMessage id={`DiscoverPage.label.${isLastSlide ? 'replay' : 'next'}`} />
                        </Button>
                    </div>
                </div>
            </footer>
        </div >
    )
}

export default DiscoverEnable