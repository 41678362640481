import { ReactComponent as Logout } from "@altais/icon-library/legacy/SVG/Doorway-Out.svg";
import { Grid, Typography } from "@mui/material";
import { signOutApp } from "authentication/appAuth";
import { createSvgIcon } from "utils";
import BNTLogo from './assests/images/logo-bnt.png';
import { useStyles } from "./styles";

export const AhaHeader = ({ providerConfig: config }: any) => {
    const classes = useStyles();
    const LogoutIcon = createSvgIcon(Logout);
    async function handleLogout() {
        const postLogoutRedirectUri = window.location.origin + "/login";
        await signOutApp(postLogoutRedirectUri);
    };

    return (
        <Grid className={classes.pepHeader}>
            <Grid container>
                <Grid container direction="column" item xs={5}>
                    <Grid
                        item
                        xs
                        className={classes.outerColumn}
                        style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                        <img width="351" height="104" src={BNTLogo} alt="Logo" />
                    </Grid>
                </Grid>
                <Grid container direction="column" item xs={2}>
                    <Grid
                        item
                        container
                        className={classes.centerColumn}
                    >
                        <Typography></Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" item xs={5}>
                    <Grid
                        item
                        className={classes.outerColumn}
                        container
                        direction="column"
                        alignItems="flex-end"
                        onClick={handleLogout}
                        style={{ display: "flex", justifyContent: "flex-start", paddingTop: "20px", paddingRight: "10px" }}
                    >
                        <LogoutIcon className={classes.logoutIcon} />
                        <Typography style={{ cursor: "pointer" }}>Log out</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
