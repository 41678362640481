import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "admin/theme";

export const useStyles = makeStyles(() => ({
    inputWrapper: {
        marginBottom: SIZING.scale600,
        paddingRight: "0px"
    },
    labelWrapper: {
        textAlign: "initial"
    },
    altLabel: {
        color: COLORS.LINK_TEXT,
        cursor: "pointer"
    },
    showPassword: {
        color: COLORS.DEFAULT_BTN,
        cursor: "pointer",
        userSelect: "none"
    },
    warningIcon: {
        display: "inline-block",
        verticalAlign: "sub",
        marginRight: "8px",
        "& path": {
            fill: COLORS.FAIL_ICON
        }
    },
    // Input classes
    root: {
        "& input": {
            border: "none",
        },
        "& .MuiOutlinedInput-adornedEnd": {
            background: `${COLORS.INPUT_BG} !important`
        }
    }
}));