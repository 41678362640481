import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as PenIcon } from 'icon-library/SVG/Pen.svg';
import { ReactComponent as TrashIcon } from 'icon-library/SVG/Trash.svg';
import { Fragment } from "react";
import { useIntl } from 'react-intl';
import { useStyles } from './styles';

export type FeeScheduleDetailsGridContextMenuContentProps = {
    callback: any,
    handleClose: any
}

const FeeScheduleDetailsGridContextMenu = ({ callback, handleClose }: FeeScheduleDetailsGridContextMenuContentProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Fragment>
            <ListItem button>
                <ListItemIcon>
                    <PenIcon width={24} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "fee.schedule.details.context.menu.edit.fee.schedule" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditFeeSchedule"); handleClose() }} />
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <TrashIcon width={24} className={classes.deleteIconRed} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "fee.schedule.details.context.menu.delete.fee.schedule" })} classes={{ primary: classes.listTextRed }} onClick={() => { callback("DeleteFeeSchedule"); handleClose() }} />
            </ListItem>
        </Fragment>
    );
}

export default FeeScheduleDetailsGridContextMenu;
