import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        toolsIcon: {
            marginRight: SIZING.scale200,
            "& path": {
                fill: COLORS.BUTTON_ICON,
            }
        }
}));
