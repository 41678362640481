import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import ToggleSwitch from "./ToggleSwitch";
import InputMasked from "./InputMasked";
import EditableTableCell from "./EditableTableCell";


const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    ToggleSwitch,
    InputMasked,
    EditableTableCell
}

export default Controls;