import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment, ReactElement, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import PracticeLicensesListing from "./Licenses/PracticeLicensesListing"
import { useStyles } from "./styles";
import { ReactComponent as CirclesHexagonGrid } from "icon-library/SVG/Circles_Hexagon-Grid.svg";
import { createSvgIcon } from "../../../../utils";
import EditPracticeForm from "../EditPractice/EditPracticeForm";
import PracticeEHRConnection from "admin/components/PracticeEHRConnection/PracticeEHRConnection";
import { editPractice } from "admin/store/actions";
import { showNotification } from "admin/store/actions/snackbar";
const CirclesHexagonGridIcon = createSvgIcon(CirclesHexagonGrid);
const PracticeProductsEdit = () => {
    const editPracticeFormRef = useRef<any>()
    const classes = useStyles()
    const dispatch = useDispatch()
    const practiceLicensesListingRef = useRef<any>()
    const [selectedFormIndex, setSelectedFormIndex] = useState(1)
    const { auth, practice } = useSelector((state: AppReduxStore) => state)
    const { sPractice } = practice
    const refEHRConnection = useRef<any>()

    const onClose = () => {
        setSelectedFormIndex(1)
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose()
    };
    const handleSaveProducts = (event: any) => {
        const result = practiceLicensesListingRef.current.saveProducts(event);
        if (result?.success) {
            if (result?.coreLicenseOn === false) {
                setSelectedFormIndex(2)
            }
            else if (result?.coreLicenseOn === true) {
                setSelectedFormIndex(3)
            }
            else if (result?.success) {
                handleClose();
            }
        }

    };
    const handleUpdatePractice = (event: any) => {
        if (selectedFormIndex === 3) {
            if (refEHRConnection.current) {
                const ehrInfo = refEHRConnection.current.getEHRConnectionValues()
                const ehrValid = ehrInfo ? ehrInfo.valid : true
                if (ehrValid) {
                    const values = {
                        "address1": sPractice?.address1,
                        "address2": sPractice?.address2,
                        "city": sPractice?.city,
                        "ehrPracticeId": ehrInfo?.ehrPracticeId,
                        "ehrSourceSystemId": ehrInfo?.ehrSourceSystemId,
                        "id": sPractice?.id,
                        "name": sPractice?.name,
                        "organizationId": sPractice?.organizationId,
                        "postalCode": sPractice?.postalCode,
                        "patientPortalUrl": sPractice?.patientPortalUrl,
                        "practiceIntegrationProperties": ehrInfo?.practiceIntegrationProperties,
                        "redoxDestinationId": null,
                        "redoxSourceId": null,
                        "schema": "",
                        "state": sPractice?.state,
                        "secretCode": (sPractice?.secretCode) ? Buffer.from(sPractice?.secretCode, "base64").toString("ascii") : "",
                        "tin": sPractice?.tin,
                        "absorbDepartmentId": sPractice?.absorbDepartmentId
                    }
                    dispatch(editPractice(values))
                    handleClose();
                } else {
                    dispatch(showNotification("Invalid Values", "error", "Please fill correct values"))
                }
            }
        }
        else {
            const result = editPracticeFormRef.current.submitForm(event);
            if (result) {
                setTimeout(() => {
                    handleClose();
                }, 1000)

            }
        }
    };

    const renderContent = (step: number): ReactElement => {
        let content: ReactElement;

        switch (step) {
            case 1:
                content = <PracticeLicensesListing ref={practiceLicensesListingRef} />
                break;
            case 2:
                content = <EditPracticeForm ref={editPracticeFormRef} />
                break;
            case 3:
                content = <PracticeEHRConnection ref={refEHRConnection} isCoreProductSelected={true} isSelectedPractice={true} />
                break;
            default:
                content = <div>Not Implemented</div>
        }

        return content;
    };
    const isCurrentProfilePractice = auth.currentProfileRoleJoinInfo?.practice?.id === sPractice?.id
    const modalClasses = {
        paper: classes.wrapper
    }
    return (
        <Modal classes={modalClasses} onClose={onClose} id={MODAL_TYPES.EDIT_PRACTICE_PRODUCTS}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"PracticeProductsEdit.Title"} values={{
                            name: `${sPractice && sPractice?.name}`
                        }} />
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnClosePrcaticeProducts" size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            {!isCurrentProfilePractice && <DialogActions className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnUpdatePracticeProducts" variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={selectedFormIndex > 1 ? handleUpdatePractice : handleSaveProducts}>
                            <FormattedMessage id={selectedFormIndex === 1 ? "PracticeProductsEdit.SaveButtonText" : "PracticeProductsEdit.FinishButtonText"} />
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>}
            <DialogContent dividers={true} className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                {selectedFormIndex === 1 && <Grid item className={classes.productsPageHeader}>
                    <Typography className={classes.pageHeaderText}>
                        <CirclesHexagonGridIcon fill="#0F7BBD" className={classes.productsIcon} />
                        <FormattedMessage id="PracticeProductsEdit.Heading" />
                    </Typography>
                    <Typography className={classes.pageSubHeaderText}>
                        <FormattedMessage id={"PracticeProductsEdit.SubHeading"} />
                    </Typography>
                </Grid>}
                {renderContent(selectedFormIndex)}
            </DialogContent>
        </Modal>
    );
}

export default PracticeProductsEdit