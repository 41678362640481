import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        ellipsisButton: {
            width: SIZING.scale1200,
            "& svg": {
                fill: COLORS.LIST_SUBHEADER_TEXT
            }
        },
}));