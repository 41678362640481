import { useState } from "react";
import PatientSearch from "./patient-search";
import PatientDetails from "./patient-details";
import RemovePatientDetails from "./remove-patient-details";
import { useStyles } from "./styles";
import { PatientProps } from "./types";

const Patient = ({ selectedPatient, onChange }: PatientProps) => {
    const classes = useStyles();
    const [hasResult, setHasResult] = useState(false);

    return (
        <div className={classes.patientTab}>
            {selectedPatient ? (
                <>
                    <PatientDetails id={selectedPatient} onStateChange={setHasResult}/>
                    { hasResult && <RemovePatientDetails onClick={() => onChange(null)} /> }
                </>
            ) : (
                <PatientSearch idPrefix="QA_E_Consult_patient_search" onChange={onChange} />
            )}
        </div>
    );
};

export default Patient;
