import createReducer from '../createReducer'
import { LicenseState } from '../reducerTypes'

const initialState: LicenseState = {
    allProducts: [],
    orgLicenses: [],
    sOrgLicenses: [],
    orgLicensesCount: 0,
    orgLicenseDetail: null,
    practiceLicenses: [],
    sPracticeLicenses: [],
    practiceLicensesCount: 0,
    practiceLicenseDetail: null,
    feeScheduleGroups: [],
    availableFeeScheduleGroups: [],
    userLicenses: [],
    sUserLicense: {}
}

export default createReducer(initialState, {
    'SET_ALL_PRODUCTS': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { allProducts } = payload
        return {
            ...state,
            ...payload,
            allProducts
        }
    },
    'SET_ORGANIZATION_LICENSES': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { orgLicenses } = payload
        return {
            ...state,
            ...payload,
            orgLicenses
        }
    },
    'SET_SELECTED_ORGANIZATION_LICENSES': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { sOrgLicenses } = payload
        return {
            ...state,
            ...payload,
            sOrgLicenses
        }
    },
    'SET_PRACTICE_LICENSES': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { practiceLicenses } = payload
        return {
            ...state,
            ...payload,
            practiceLicenses
        }
    },
    'SET_SELECTED_PRACTICE_LICENSES': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { sPracticeLicenses } = payload
        return {
            ...state,
            ...payload,
            sPracticeLicenses
        }
    },
    'SET_USER_LICENSES': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { userLicenses } = payload
        return {
            ...state,
            ...payload,
            userLicenses
        }
    },

    'SET_SELECTED_USER_LICENSE': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { sUserLicense } = payload
        return {
            ...state,
            ...payload,
            sUserLicense
        }
    },

    'SET_ORGANIZATION_LICENSES_COUNT': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { orgLicenses } = payload
        return {
            ...state,
            ...payload,
            orgLicenses
        }
    },

    'SET_PRACTICE_LICENSES_COUNT': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { practiceLicensesCount } = payload
        return {
            ...state,
            ...payload,
            practiceLicensesCount
        }
    },

    'SET_ORGANIZATION_LICENSE_DETAILS': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { orgLicenseDetail } = payload
        return {
            ...state,
            ...payload,
            orgLicenseDetail
        }
    },

    'SET_PRACTICE_LICENSE_DEATILS': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { practiceLicenseDetail } = payload
        return {
            ...state,
            ...payload,
            practiceLicenseDetail
        }
    },

    'SET_FEE_SCHEDULE_GROUPS': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { feeScheduleGroups } = payload
        return {
            ...state,
            ...payload,
            feeScheduleGroups
        }
    },
    
    'SET_AVAILABLE_FEE_SCHEDULE_GROUPS': (state: LicenseState, payload: LicenseState): LicenseState => {
        const { availableFeeScheduleGroups } = payload
        return {
            ...state,
            ...payload,
            availableFeeScheduleGroups
        }
    }
})