import React from "react"
import { Button, Typography, Grid } from "@mui/material";
import { useStyles } from "./styles"
import { createSvgIcon } from "utils";
import { useHistory } from "react-router-dom"

export default function Card({ title = '', icon = '', themeColor = '', btnText = '', description = '', route = '', callback = '', incText = '', Image = '', incBackColor = '', incColor = '', type = '',isPCRStatus='' }: any) {

    const Icon = createSvgIcon(icon);
    const isNonPCR = !isPCRStatus;
    const classes = useStyles({ themeColor, incColor, incBackColor, isNonPCR })
    const history = useHistory()
    const handleNavigation = () => {
        callback && callback()
        route && history.push(route)
    }
    return (
        <Grid item lg={6}>
            <div className={classes.rootCard}>
                {
                   isNonPCR ?
                        <>
                            <Typography className={classes.title} >
                                {title}
                            </Typography>
                            <div className={classes.descWrapper}>
                                <Icon />
                                <Typography className={classes.description} >
                                    {description}
                                </Typography>
                            </div>
                        </>
                        :
                        <div className={classes.cardContainer}>
                            <div><img className={classes.imagePCR} alt="" src={Image}></img></div>
                            <div className={classes.cardContents}>
                                <div className={classes.descTitle}>
                                    <Typography className={classes.title1} >
                                        {title}
                                    </Typography>
                                    <button className={classes.includeButton}>{incText}</button>
                                </div>
                                <Typography className={`${classes.description} pcr-desc`} >
                                    {description}
                                </Typography>
                            </div>
                        </div>}
                <Button
                    color="primary"
                    className={classes.button}
                    onClick={handleNavigation}
                >
                    <Typography variant="button" >
                        {btnText}
                    </Typography>

                </Button>
            </div>
        </Grid>
    )
}
