import React, { useState, Fragment, useEffect } from "react";
import { Box, Typography, Button, Grid, IconButton } from "@mui/material"
import { useStyles } from "./styles";
import moment from "moment"
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useDispatch, useSelector } from "react-redux";
import { unlockAccountAdmin, changeAccountStatus, setActiveModal, setSelectedProfileRoleID } from "admin/store/actions";
import { MODAL_TYPES } from "admin/constant";
import Controls from "admin/components/Controls/Controls";
import { ReactComponent as Edit } from "icon-library/SVG/Pen-Light.svg";
import { createSvgIcon } from "utils";
import { unescape } from "underscore";
import { canEditUsers } from "admin/Accessibility";
import { LicenseWidgets } from "admin/components/LicenseWidgets/LicenseWidgets";
import { resetPassword } from "authentication/azure/azure-auth-utils";
import { APP_AUTH_TYPE, appMode } from "authentication/config";
const UserDetail = () => {
    const { admin, license, auth } = useSelector((state: AppReduxStore) => state)
    const { sUser } = admin
    const { orgLicenses, practiceLicenses, userLicenses } = license
    const dispatch = useDispatch()
    const classes = useStyles();
    const intl = useIntl()
    const [status, setStatus] = useState(sUser.status ?? "NONE");
    const [state, setState] = useState({
        checked: sUser.status === "ACTIVE",
    });
    const EditIcon = createSvgIcon(Edit)

    useEffect(() => {
    }, [sUser, orgLicenses, practiceLicenses, userLicenses]);

    const handleChange = (event: any) => {
        setState({ checked: event.target.checked });
        setStatus(event.target.checked ? "ACTIVE" : "INACTIVE")
        dispatch(changeAccountStatus([sUser.profileRoleId], event.target.checked ? "ACTIVE" : "INACTIVE"))
    };
    const handleEditUser = (e: any) => {
        dispatch(setActiveModal(MODAL_TYPES.EDIT_USER))
    };
    const handleResetPassword = (event: any) => {
        if (appMode === APP_AUTH_TYPE.AZURE) {
            resetPassword();
        } else {
            dispatch(setActiveModal(MODAL_TYPES.RESET_PASSWORD));
        }
    };
    const handleEditProducts = (e: any) => {
        dispatch(setActiveModal(MODAL_TYPES.EDIT_USER_PRODUCTS))
    };
    const onStatusChanged = (changed: boolean) => {
        if (changed) {
            sUser?.id && dispatch(setSelectedProfileRoleID(sUser?.id))
        }
    }
    const handleUnlockAccount = (event: any) => {
        dispatch(unlockAccountAdmin(sUser.email, onStatusChanged))
    }
    const borders = {
        Active: '2px solid #00A875',
        Pending: '2px solid #F5A800',
        Inactive: '2px solid #E32B29',

    }

    let border = '';
    switch (sUser?.status?.toLowerCase()) {
        case "active": border = borders['Active']; break;
        case "pending": border = borders['Pending']; break;
        default: border = borders['Inactive']; break;
    }
    const canEditUser = canEditUsers()
    return (
        <Fragment>
            <Box className={classes.usersWrapper} style={{ position: "relative" }}>
                {auth.currentOrg && orgLicenses && orgLicenses.length > 0 &&
                    <Grid container style={{ marginBottom: '56px' }}>
                        {orgLicenses && <LicenseWidgets products={orgLicenses} />}
                    </Grid>}
                {auth.currentPractice && practiceLicenses && practiceLicenses.length > 0 &&
                    <Grid container style={{ marginBottom: '56px' }}>
                        {practiceLicenses && <LicenseWidgets products={practiceLicenses} />}
                    </Grid>}
                <Grid container className={classes.usersTopActionWrapper}>
                    <Grid item xs>
                        <Typography color="textPrimary" className={classes.userName}>
                            {sUser && `${sUser.firstName} ${sUser.lastName}`}
                        </Typography>
                    </Grid>
                </Grid>
                {(auth.currentOrg?.parent || auth.currentPractice) && canEditUser && <Grid container>
                    <Grid item xs>
                        <Button id="btnEditProducts" variant="contained" color="primary" style={{ float: 'right', marginBottom: '10px' }} onClick={handleEditProducts}>
                            {intl.formatMessage({ id: "UserDetail.Button.EditProducts" })}
                        </Button>
                    </Grid>
                </Grid>}
                {userLicenses && userLicenses?.products && userLicenses?.products.length > 0 &&
                    <Grid container style={{ marginBottom: '56px' }}>
                        {userLicenses?.products && <LicenseWidgets products={userLicenses?.products} showTotalBar={false} />}
                    </Grid>}
                <Box className={classes.contolsWrapper}>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography color="textPrimary" className={classes.heading} style={{ fontSize: '24px' }}>
                                {intl.formatMessage({ id: "UserDetail.Label.AccountManagement" })}</Typography>
                        </Grid>
                        <Grid item xs>
                            {sUser.isAccountLocked ? <Box style={{ border: "2px solid #E32B29", padding: '4px 8px', fontSize: '14px', fontWeight: 600, borderRadius: '4px', width: '100px', textAlign: 'center' }}> Locked</Box> :
                                <Box style={{ border: border, padding: '4px 8px', fontSize: '14px', fontWeight: 600, borderRadius: '4px', width: '100px', textAlign: 'center' }}> {status}</Box>}
                        </Grid>
                        {canEditUser && <Grid item xs>
                            {
                                sUser.status && sUser.status.toLowerCase() === "pending" ?
                                    <Button id="btnSendInvite" variant="outlined" color="primary" style={{ float: 'right' }}>
                                        Send Invite
                                    </Button> :
                                    (sUser.isAccountLocked ?
                                        <Button id="btnUnlockAccount" variant="outlined" color="primary" style={{ float: 'right' }} onClick={handleUnlockAccount}>
                                            {intl.formatMessage({ id: "UserDetail.Button.UnlockAccount" })}
                                        </Button> :
                                        sUser.email?.toLowerCase() === auth.currentProfileRoleJoinInfo.profileEmail?.toLowerCase() &&
                                        <Button id="btnResetPassword" variant="outlined" color="primary" style={{ float: 'right' }} onClick={handleResetPassword}>
                                            {intl.formatMessage({ id: "UserDetail.Button.ResetPassword" })}
                                        </Button>)
                            }
                        </Grid>}
                    </Grid>

                </Box>
                <Box>
                    {sUser.status && sUser.status.toLowerCase() !== "pending" && <Grid container>
                        <Grid item xs={3}>
                            <Controls.ToggleSwitch
                                label="Account Status"
                                checked={state.checked}
                                onChange={canEditUser ? handleChange : null}
                                value={state.checked ? 'Active' : 'Inactive'}

                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Input
                                label="Last Log In"
                                value={sUser.lastLoginTime && moment(sUser.lastLoginTime).format("MM/DD/YYYY HH:MM")}
                                readOnlyLabel={true}
                            />
                        </Grid>
                    </Grid>}
                </Box>

                {canEditUser && <Grid container style={{ marginTop: '56px' }}>
                    <Grid item>
                        <Typography color="textPrimary" className={classes.heading}>
                            {intl.formatMessage({ id: "UserDetail.Label.UserInformation" })}
                            <IconButton size="large"><EditIcon fill="#0F7BBD" className={classes.editIcon} onClick={handleEditUser} /></IconButton>
                        </Typography>
                    </Grid>
                </Grid>}
                <Box className={classes.contolsWrapper}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Typography variant="caption" className={classes.lblName}>
                                Primary Phone:
                            </Typography>
                            <Typography variant="caption" className={classes.lblValue}>
                                {sUser.primaryPhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="caption" className={classes.lblName}>
                                Mobile Phone:
                            </Typography>
                            <Typography variant="caption" className={classes.lblValue}>
                                {sUser.mobilePhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="caption" className={classes.lblName}>
                                Email:
                            </Typography>
                            <Typography variant="caption" className={classes.lblValue}>
                                {sUser.email}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '16px' }} spacing={3}>
                        <Grid item xs={3}>
                            <Typography variant="caption" className={classes.lblName}>
                                User Role:
                            </Typography>
                            <Typography variant="caption" className={classes.lblValue}>
                                {sUser.practiceRole}
                            </Typography>

                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="caption" className={classes.lblName}>
                                User Level:
                            </Typography>
                            <Typography variant="caption" className={classes.lblValue}>
                                {sUser.userLevel}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="caption" className={classes.lblName}>
                                NPI:
                            </Typography>
                            <Typography variant="caption" className={classes.lblValue}>
                                {sUser.npi}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '16px' }} spacing={3}>
                        <Grid item xs={3}>
                            <Typography variant="caption" className={classes.lblName}>
                                EHR Username:
                            </Typography>
                            <Typography variant="caption" className={classes.lblValue}>
                                {sUser.ehrUsername}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="caption" className={classes.lblName}>
                                QualityIQ URL:
                            </Typography>
                            <Typography variant="caption" className={classes.lblValue}>
                                {unescape(sUser.qualityiqUrl)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </Fragment>
    );
}

export default UserDetail