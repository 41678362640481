import React from "react";
import { DecorationComponentProps } from "../types";
import { useSelector } from "react-redux";
import { AppReduxStore as AppReduxStoreAdmin } from "admin/store/reducerTypes";
import { ReactComponent as FlagSvg } from "icon-library/SVG/Flag.svg";
import { createSvgIcon } from "utils";

const NotificationIcon = createSvgIcon(FlagSvg);

const ConfigurationsDecorations = ({ classes }: DecorationComponentProps) => {
    const adminStore = useSelector((state: AppReduxStoreAdmin) => state);

    if (!adminStore.configuration.configSetupFlagActive) {
        return null;
    }

    return <NotificationIcon className={classes.notifIcon} />;
};

export default ConfigurationsDecorations;
