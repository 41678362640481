import React, { useEffect,useState } from "react";
import AlertHeader from "components/Alerts/AlertHeader/AlertHeader";
import PatientDetails from "components/Alerts/PatientDetails/PatientDetails";
import { useStyles } from "./styles";
import { useReduxDispatch } from "store/utils";
import { getAlertDetails } from "store/actions";
import { useParams } from "react-router-dom";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import { AlertDetailsProps} from './types';

const AlertDetails = ({clearSelectedAlertId, selectedAlertId}: AlertDetailsProps) => {  

    const classes = useStyles();
    const customDispatch = useReduxDispatch()
    const [loader, updateLoader] = useState(true)
    //@ts-ignore
    const { id } = useParams()

    useEffect(() => {
        const receivedAlertId = selectedAlertId ?? id;
        customDispatch(getAlertDetails(receivedAlertId)).then(()=>{
            updateLoader(false);
        });
    }, [selectedAlertId, id, customDispatch]);

    return (
        <div className={classes.MainWrapper}>
           {loader ? <ComponentLoader /> :
            <>
                <AlertHeader clearSelectedAlertId={clearSelectedAlertId}/>
                <PatientDetails />
            </>}
        </div>
    )
}

export default AlertDetails