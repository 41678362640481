import { makeStyles } from "@mui/styles";
import {SIZING, COLORS} from "theme";
import {USER_TYPE} from "constant";

export const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        marginRight: SIZING.scale400,
        cursor: ({type,hyperlink}: any) => type === USER_TYPE.PATIENT && hyperlink ? "pointer" : "text"
    },
    icon: {
        height: SIZING.scale500,
        width: SIZING.scale500,
        marginRight: SIZING.scale100,
        "& path": {
            fill: ({type,hyperlink}: any) => type === USER_TYPE.PATIENT && hyperlink? COLORS.PATIENT_TEXT : COLORS.USER_ICON
        }
    },
    name: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: ({type,hyperlink}: any) => hyperlink && type === USER_TYPE.PATIENT ? COLORS.PATIENT_TEXT : COLORS.CONVERSATION_TEXT
    }
}));