import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING } from '../../../theme';

export const useStyles = makeStyles(() => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    contentArea: {
        display: 'flex',
        flex: '1 1 auto'
    },
    leftPaneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        maxWidth: '300px',
        minHeight: '494px',
        zIndex: 2,
        position: "relative",
        background: "#F6F8FA",
        borderRadius: `0 0 0 0`,
        width: SIZING.SIDE_NAV_OPEN_WIDTH,
    },
    rightContent: {
        flexGrow: 1,
        overflowY: 'hidden',
        padding: '32px 32px 0px 28px',
        background: '#F6F8FA'
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    dlgAction:{
        padding: '5px 32px'
    },
    dropzoneClass: {
        background: '#EEF9FF'
    },
    previewGridClasses: { 
        marginTop: '10px'
    },
    dialogHeader: {
        minWidth: '1145px',
        display: 'flex',
        flexDirection: 'column'
    },
    dialogHeaderBottom: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    dialogHeaderTop: {
        display: 'flex'
    },
    dialogAction: {
        minHeight: '84px',
        padding: '5px 32px',
        justifyContent: "center"
    },
    dialogHeaderTitle: {
        flex: 1,
        color: '#000000'
    },
    dialogHeaderSaveButton: {
        marginLeft: '10px'
    },
    dialogActionBar: {
        width: '1145px',
        display: 'flex'
    },
    dialogContentRoot: {
        flex: '1 1 auto',
        overflowY: 'auto',
    },
    dialogContentDividers: {
        padding: '0 0 0 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    pageHeader: {
        margin: '10px'
    },
    pageHeaderText: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
    },
    sectionHeader: {
        margin: '10px',
    },
    sectionHeaderText: {
        fontSize: '22px'
    },
    addressSectionField: {
        margin: '5px 5px 5px 5px'
    },
    editIcon: {
        height: '35px',
        width: '35px',
        verticalAlign: 'middle'
    },
    closeButtonIcon: {
        height: '45px',
        width: '45px',
        verticalAlign: 'middle'
    }
}));