export const MS_PER_DAY = 86400000 // 1000 * 60 * 60 * 24
export const MS_PER_HOUR =  3600000 // 1000 * 60 * 60
export const MS_PER_THREE_HOURS = 10800000 // 1000 * 60 * 60 * 3
export const MS_PER_MINUTE =  60000 // 1000 * 60

export const DaysOfTheWeek = [
    "MessageThreads.day.sunday",
    "MessageThreads.day.monday",
    "MessageThreads.day.tuesday",
    "MessageThreads.day.wednesday",
    "MessageThreads.day.thursday",
    "MessageThreads.day.friday",
    "MessageThreads.day.saturday"
]
