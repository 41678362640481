import { isSelectableMenuItem, MenuItemViewProps } from "./types";

const SelectableMenuItemView = ({ labelRenderer, menuItem }: MenuItemViewProps) => {
    if (isSelectableMenuItem(menuItem)) {
        const Icon = menuItem.icon;
        return (
            <div className="menuItem" id={menuItem.id} data-testid={menuItem.id}>
                {Icon && <Icon className="menuItemIcon" />}
                {labelRenderer({
                    className: "menuItemText",
                    labelKey: menuItem.labelKey,
                    labelText: menuItem.labelText,
                    labelValues: menuItem.labelValues,
                    selected: menuItem.selected,
                })}
            </div>
        );
    }
    return null;
};

export default SelectableMenuItemView;
