import React, { useState,useEffect, useRef,useMemo } from "react"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { WaitingRoomCardProps } from "./types"
import { Box, Button, ClickAwayListener } from "@mui/material";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { setActiveModal,mergeNRUMeeting,notifyProviders,WaitingRoomDetails } from "store/actions";
import { MODAL_TYPES } from "constant";
import { ReactComponent as Patient } from "icon-library/SVG/User_Single.svg";
import { ReactComponent as Info } from "icon-library/SVG/Info_Circle.svg";
import { ReactComponent as Ellipsis } from "icon-library/SVG/Ellipsis-Horizontal.svg";
import { ReactComponent as Phone } from "icon-library/SVG/Phone_Answer.svg";
import { ReactComponent as Chat } from "icon-library/SVG/Chat.svg";
import { ReactComponent as InCall } from "icon-library/SVG/Phone_Answer.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as CrossCircle } from "icon-library/SVG/Cross_Circle.svg";
import { useStyles } from "./style"
import QuickChat from "./quick-chat/quick-chat";
import { FormattedMessage } from "react-intl";
import { getAge } from "utils"
import { useReduxDispatch } from "store/utils";
import LateStatus from "components/WaitingRoom/LateStatus/LateStatus"
import PauseDuration from 'common/components/PauseDuration/PauseDuration';
import CustomPopover from "components/Popover/Popover"

const WaitingRoomCard: React.FC<WaitingRoomCardProps> = ({ cardDetails, handleDropUser, handleStartMeeting }) => {

    const { visitReminderId } = cardDetails
    const { waitingRoom,user } = useSelector((store: AppReduxStore) => store)
    const { currentProfileId } = user
    const [isOpen, updateDropdownStaus] = useState<boolean>()
    const [endedClass, updateEndedClass] = useState<string>('')
    const [isQuickChatOpen, updateChatDropdownStaus] = useState<boolean>()
    const wrapperRef  = useRef<HTMLDivElement>(null)
    const { currentMeetingDetails,currentMeetingParticipants,providerFilterIds } = waitingRoom
    const dispatch = useDispatch()
    const customDispatch = useReduxDispatch()
    const allSelected = providerFilterIds ? providerFilterIds.length !== 1 : true;
    const classes = useStyles({ showProviderName: allSelected })
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const mergeAnchorRef = React.useRef<HTMLDivElement>(null);
    const [isPositionTop, updateisPositionTop] = useState<boolean>(true)
    const [isMergePositionTop, updateisMergePositionTop] = useState<boolean>(true)

    const { mergedParticipantsInfo = [] } = currentMeetingDetails || {};
    const { status = "", providerProfileInfo } = cardDetails || {};
    const [mergedParticipant = {}] = mergedParticipantsInfo;
    const isMergedCall = mergedParticipant?.visitReminderId === visitReminderId;

    const { firstName = "", lastName = "", prefix = "" } = providerProfileInfo || {};

    const isEnded = status === "ENDED";

    const { dateOfBirth, genderCode } = cardDetails.patientProfileInfo || { dateOfBirth: null, genderCode: "" };

    const isRegisteredPatient = !!cardDetails.patientProfileId;

    const isMergedPatientConnected = useMemo(()=>{
        if(mergedParticipantsInfo.length){
            const { twilioUsername: mergedPatientId } = mergedParticipantsInfo[0];
            return currentMeetingParticipants.indexOf(mergedPatientId)!==-1
        }
        return false
    },[currentMeetingParticipants,mergedParticipantsInfo])

    const handleAnimationEnd = () =>{
        updateEndedClass('hide')
        dispatch(WaitingRoomDetails())
    }

    useEffect(()=>{
        let timeoutInstance:ReturnType<typeof setTimeout>
        if(isEnded){
            timeoutInstance = setTimeout(()=>{
                updateEndedClass('removed')
            },30000)
            wrapperRef?.current?.addEventListener('animationend',handleAnimationEnd)
        }
     
        return ()=>{
            clearTimeout(timeoutInstance)
            wrapperRef?.current?.removeEventListener('animationend',handleAnimationEnd)
        }
    },[isEnded])

    const handleMergeCall = async () =>{
       await customDispatch(mergeNRUMeeting(cardDetails))
        dispatch(notifyProviders(currentProfileId,"MERGED",null,cardDetails.visitReminderId))
        updateDropdownStaus(false)
    }

    const checkActiveMeeting = (id: string) => {
        return id === currentMeetingDetails?.visitReminderId
    }

    const { patientProfileId = "" } = cardDetails || {};

    const openPatientInfoModal = () => {
        if (isRegisteredPatient) {
            dispatch(setActiveModal(MODAL_TYPES.PROVIDER.PATIENT_INFO, { patientProfileId: patientProfileId }));
        }
    }

    const isMergeCallVisible = () => currentMeetingDetails !== null&&currentMeetingDetails.visitReminderId!==visitReminderId&&mergedParticipantsInfo.length===0&&!currentMeetingDetails.isMA&&(status==='WAITINGROOM' || status ==='PAUSED')

    const isActive = checkActiveMeeting(visitReminderId);

    const getDetailsByStatus = (type: string) => {

        if(currentMeetingDetails?.visitReminderId  === visitReminderId || isMergedCall){
            return { patientCardStatus: 'incall' }
        }

        switch (type) {
            case 'PAUSED':
                return { patientCardStatus: 'paused' }
            case 'ENDED':
                return { patientCardStatus: 'ended' }
            case 'MERGED':
                return { patientCardStatus: 'merged' }
            default:
                return { patientCardStatus: 'waiting',  }
        }
    }

    const { patientCardStatus } = getDetailsByStatus(status);


    const handleClickAway = () => {
        updateChatDropdownStaus(false)
        updateDropdownStaus(false)
    }

    const handleDropCall =() =>{
        if(window.confirm("Are you sure you want to remove this person from waiting room?")){
             handleDropUser(cardDetails)
        }
    }
    const getPosition = (e:any,type:any) =>{
        if (type === "quickChat" && e.screenY > 550 ){
            updateisPositionTop(false)
        } else if (type === "mergeCall" && e.screenY > 720 ){
            updateisMergePositionTop(false)
        }
        else{
            updateisPositionTop(true)
            updateisMergePositionTop(true)
        }
    }
    const button = (handleClick: any) => {
        return <Box className={`ellipse-wrap ${isQuickChatOpen ? "icon-active-bg" : "icon-bg"} `} onClick={(e) => {handleClick(anchorRef.current);getPosition(e,"quickChat");updateChatDropdownStaus(true);updateDropdownStaus(false)}} id="QA_Quick_Chat">
        <SVGIcon trim={false} svg={Chat} size="scale600" />
    </Box>
    }

    const content = (closePopup: () => void) => {
        return (<QuickChat handleClose={() => {closePopup(); updateChatDropdownStaus(false)}} cardDetails={cardDetails} currentMeetingDetails={currentMeetingDetails} status={status} allSelected={allSelected}/>)
    }

    const mergeButton = (handleClick: any) => {
        return <Box className="ellipse-wrap icon-bg" onClick={(e) => {handleClick(mergeAnchorRef.current);getPosition(e,"mergeCall"); updateDropdownStaus(true); updateChatDropdownStaus(false) }}>
        <SVGIcon trim={false} svg={Ellipsis} size="scale600" />
    </Box>
    }

    const mergeContent = (closePopup: () => void) => {
        return (
            <ClickAwayListener onClickAway={() => {handleClickAway();closePopup()}}>
                <Box className={classes.mergeBox}>
                    <CustomTypography className={classes.callOptions} >
                        <FormattedMessage id="Waiting_Start_Call_Options" />
                    </CustomTypography>
                    <Box
                        className={classes.callOptionsMenu}
                        onClick={() =>{handleMergeCall();closePopup()}}
                    >
                        <FormattedMessage id="Waiting_Start_ Merge_Call" />
                    </Box>
                </Box>
            </ClickAwayListener>
        )
    }

    const getActionButtonLabel = () =>{
        switch (status) {
            case 'PAUSED':
                return 'Waiting_Resume_Call'
            case 'WAITINGROOM':
                return 'Waiting_Start_Call'
            case 'INCALL':
            case 'MERGED':
                return 'Waiting_Join_Call'
            default:
                return 'Waiting_Start_Call'
        }
    }

    return (
        <ClickAwayListener onClickAway={() => handleClickAway()}>
            <Box ref={wrapperRef} data-testid="patient-waiting-cards" className={`${classes.cardWrapper} ${endedClass}`}>
                <Box className={`${classes.patientDetailsSection} ${patientCardStatus} ${isQuickChatOpen || isOpen ? 'active' : ''}`}>
                    {status === "WAITINGROOM" &&
                        <SVGIcon trim={true} svg={CrossCircle} className={classes.dropIcon} size="scale600" onClick={e => handleDropCall()} />
                    }
                    {allSelected &&
                        <Box className={`${classes.providerName} ${patientCardStatus}`}>
                            {`${prefix?prefix:''} ${firstName} ${lastName}'s Patient`}
                        </Box>
                    }
                    <Box className={classes.patientCardDetails}>
                        <Box className={`${classes.cardSection} ${patientCardStatus}`}>
                            <SVGIcon trim={true} svg={Patient} className={classes.patientIcon} size="scale750" />
                            <Box className={classes.patientInfo} id="QA_Waiting_patientInfo">
                                <Box className="card-name-wrapper">
                                    <CustomTypography data-testid="patient-name" className={`${classes.patientName} ${patientCardStatus}`} >
                                        {cardDetails.patientName}
                                    </CustomTypography>
                                    <Box className={classes.patientadditionalinfo}>
                                        {
                                            (status === "INCALL" || status === "MERGED")&& currentMeetingDetails?.visitReminderId  !== visitReminderId ?
                                                <CustomTypography data-testid="patient-scheduled-time" className="helper-text" variant="conversationPatientDetails">
                                                    <span><SVGIcon trim={true} className="incall-icon" svg={InCall} size="scale350" /></span> <FormattedMessage id="Waiting_In_Call" />
                                                </CustomTypography> :
                                                <CustomTypography data-testid="patient-scheduled-time" className="helper-text" variant="conversationPatientDetails">
                                                    {
                                                        isRegisteredPatient ?
                                                        <FormattedMessage id="Waiting_Room_Scheduled_Time" values={{ time: moment.utc(cardDetails.startTime).local().format('LT') }} /> :
                                                        <FormattedMessage id="Waiting_Room_Unscheduled" />
                                                    }
                                                </CustomTypography>
                                        }

                                        {status === "WAITINGROOM" && isRegisteredPatient && <LateStatus status={status} date={cardDetails.startTime} />}
                                    </Box>
                                </Box>
                                <Box className="additional-info-wrap">
                                    {isRegisteredPatient ? <CustomTypography data-testid="patient-dob" className="helper-text patient-age" variant="conversationPatientDetails">
                                        ({getAge(dateOfBirth)} {genderCode})
                                    </CustomTypography>:<p style={{margin:"0.75rem"}} />}
                                    {!isActive && status === "PAUSED" && !isMergedCall && <PauseDuration value={cardDetails.lastModifiedDate}></PauseDuration>}

                                    {(status === "INCALL" || status === "WAITINGROOM") && !isMergedCall && (status !== "ENDED")&&<>
                                        {!isActive && <CustomTypography data-testid="patient-joined-time" className="helper-text" variant="conversationPatientDetails">
                                            {"JOINED: " + moment.utc(cardDetails.joinedAt).local().format('LT')}
                                        </CustomTypography>}</>
                                    }
                                    {isMergedCall && status !== "ENDED"&&<CustomTypography className={classes.mergedTitle}><FormattedMessage id={`${isMergedPatientConnected?'Waiting_Room_Merged_Call':'Waiting_Room_Merged_Call_Connecting'}`} /> </CustomTypography>  }
                                    {(status === "ENDED") && <>
                                        {!isActive && <CustomTypography data-testid="patient-joined-time" className="helper-text" variant="conversationPatientDetails">
                                            {"ENDED " + moment.utc(cardDetails.endTime).local().format('LT')}
                                        </CustomTypography>}</>
                                    }
                                </Box>
                            </Box>
                            <Box className="action-wrapper">
                                <Box className="start-call">
                                   {(status === "PAUSED" || status === "WAITINGROOM") &&<div ref={anchorRef} className={classes.root} >
                                        <CustomPopover
                                            renderElement={button}
                                            renderContent={content}
                                            arrowPosition='center'
                                            anchorOrigin={{
                                                vertical: isPositionTop? 50: -10,
                                                horizontal: 20,
                                            }}
                                            arrowDirection={isPositionTop?'top':'bottom'}
                                            transformOrigin={{
                                                vertical: `${isPositionTop?'top':'bottom'}`,
                                                horizontal: 'center'
                                            }}
                                        />
                                    </div>}
                                    {
                                        isRegisteredPatient &&
                                        <Box className="ellipse-wrap icon-bg" onClick={openPatientInfoModal} id="QA_Patient_Info">
                                            <SVGIcon trim={false} svg={Info} size="scale600" />
                                        </Box>
                                    }
                                    <Button className="button-wrap icon-bg"
                                        data-testid="start-meeting-button"
                                        disabled={!!currentMeetingDetails}
                                        onClick={() => handleStartMeeting(cardDetails)} >
                                        <SVGIcon trim={false} svg={Phone} size="scale600" />
                                        <FormattedMessage id={getActionButtonLabel()} />
                                    </Button>
                                    {isMergeCallVisible()&&
                                        <div ref={mergeAnchorRef} className={classes.root} >
                                            <CustomPopover
                                                renderElement={mergeButton}
                                                renderContent={mergeContent}
                                                arrowPosition='left'
                                                anchorOrigin={{
                                                    vertical: isMergePositionTop ? 55 : -10,
                                                    horizontal: 80,
                                                }}
                                                arrowDirection={isMergePositionTop ? 'top' : 'bottom'}
                                                transformOrigin={{
                                                    vertical: `${isMergePositionTop ? 'top' : 'bottom'}`,
                                                    horizontal: 'center'
                                                }}
                                            />
                                        </div>
                                    }
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>

            </Box>
        </ClickAwayListener>
    )

}

export default WaitingRoomCard