import EventListenerManager from "lib/event-listener-manager";

type ProgressEventListener = (busy: boolean) => void;

const progressRegistry: Set<string> = new Set();
const eventListenerRegistry: Map<string, EventListenerManager<ProgressEventListener, boolean>> = new Map();

const ProgressManager = {
    getBusy: (id: string): boolean => {
        return progressRegistry.has(id);
    },
    setBusy: (id: string, busy: boolean): void => {
        if (busy) {
            progressRegistry.add(id);
        } else {
            progressRegistry.delete(id);
        }
        if (eventListenerRegistry.has(id)) {
            eventListenerRegistry.get(id)?.notifyEventListeners(busy);
        }
    },
    addEventListener: (id: string, listener: ProgressEventListener) => {
        if (!eventListenerRegistry.has(id)) {
            eventListenerRegistry.set(id, new EventListenerManager<ProgressEventListener, boolean>());
        }
        eventListenerRegistry.get(id)?.addEventListener(listener);
    },
    removeEventListener: (id: string, listener: ProgressEventListener) => {
        if (eventListenerRegistry.has(id)) {
            eventListenerRegistry.get(id)?.removeEventListener(listener);
        }
    },
};

export default ProgressManager;
