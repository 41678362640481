import {Button, Typography, Box, Link} from "@mui/material";
import React, { useState } from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch} from "react-redux";
import {Form} from 'admin/components/useForm';
import {setRegistrationStep} from "admin/store/actions";
import {setActiveModal} from "store/actions";
import {REGISTRATION_STEP, MODAL_TYPES} from "constant";
import {useStyles} from "./styles";
import TOSModal from "components/Modals/TOSModal/TOSModal";
import { Checkmark } from "assets"

interface Props {
    isViewed?: boolean,
    updateIsViewed?: (arg: string) => void
}

const AcceptTOS = (props: Props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isViewed, setIsViewed] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);

    const updateIsViewed = (isViewed: boolean):void => {
        setIsViewed(true)
    }

    const displayTOSModal = (event: any) => {
        event.preventDefault()
        dispatch(setActiveModal(MODAL_TYPES.PATIENT.TOS_MODAL))
    }

    const acceptTOS = (event: any) => {
        event.preventDefault()
        setIsAccepted(true)
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (isViewed) {
            const nextStep = REGISTRATION_STEP.PATIENT.ADD_EMAIL
            dispatch(setRegistrationStep(nextStep))
        }
    }

    return (
        <Form onSubmit={onSubmit} name="patientTOSForm">
            <Box>
                <Typography variant="body2" className={classes.subject}>
                    <FormattedMessage id="AcceptTOS.label.subject"/>
                </Typography>
                <input type="hidden" name="acceptInput" value="false" />
                <Box display="flex" className={classes.borderRow}>
                    <Link className={classes.tosLink}
                          component="button"
                          variant="body2"
                          onClick={displayTOSModal}
                    >
                        <FormattedMessage id="AcceptTOS.label.link"/>
                    </Link>
                    {isAccepted ? (
                        <Button className={classes.acceptedBtn} disabled>
                            <Checkmark />
                            <Typography variant="button">
                                <FormattedMessage id="AcceptTOS.button.accepted"/>
                            </Typography>
                        </Button>
                    ):(
                        <Button className={classes.acceptBtn} disabled={!isViewed} color="primary" variant="outlined" onClick={acceptTOS}>
                            <Typography variant="button">
                                <FormattedMessage id="AcceptTOS.button.accept"/>
                            </Typography>
                        </Button>

                    )}
                </Box>
            </Box>
            <Button type="submit" disabled={!isAccepted} variant="outlined" color="primary" fullWidth>
                <Typography variant="button">
                    <FormattedMessage id="PatientRegistration.button.next"/>
                </Typography>
            </Button>
            <TOSModal isViewed={isViewed} updateIsViewed={updateIsViewed}></TOSModal>
        </Form>
    )
}

export default AcceptTOS