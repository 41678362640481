import PracticeListItem from "./location-list-item";
import { useStyles } from "./styles";
import { PracticesListProps } from "./types";

const PracticesList = (props: PracticesListProps) => {
    const { id, availablePractices, selectedPractices, itemIdPrefix, ...passThroughProps } = props;
    const classes = useStyles();
    const practiceItems = availablePractices || selectedPractices;

    return (
        <>
            <table id={id} className={classes.providerList}>
                <tbody>
                    {practiceItems?.sort((a: any, b: any) => {
                        if (a.practice.name < b.practice.name) {
                            return -1;
                        } else if (a.practice.name > b.practice.name) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }).map((practiceItem) => (
                        <PracticeListItem
                            onNameClicked={() => { }}
                            key={practiceItem.practice.id}
                            idPrefix={itemIdPrefix}
                            practice={practiceItem}
                            selected={selectedPractices
                                ? Boolean(selectedPractices.find(({ practice }) => practice?.id === practiceItem.practice.id))
                                : false}
                            {...passThroughProps} />
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default PracticesList;
