import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    IconButton,
    Typography
} from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import OrgProductsListing from "./OrgProductsListing"
import { useStyles } from "./styles";

const OrgProductsEdit = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const orgLicensesListingRef = useRef<any>()

    const { organization, auth } = useSelector((state: AppReduxStore) => state)
    const isAltaisAdmin = auth.currentProfileRoleJoinInfo?.level?.name?.includes("ALTAIS_ADMIN")

    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose()
    };

    const handleSaveProducts = (event: any) => {
        const result = orgLicensesListingRef.current.saveProducts(event);
        if (result) {
            handleClose();
        }
    };
    const handleDiscardProducts = (event: any) => {
        handleClose();
    };
    const isCurrentProfileOrg = auth.currentProfileRoleJoinInfo?.organization?.id === organization?.sOrganization?.id
    return (
        <Modal onClose={onClose} id={MODAL_TYPES.EDIT_ORG_PRODUCTS}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={isAltaisAdmin ? "OrgProductsEdit.Title" : "OrgProductsEdit.LicensesTitle"} values={{ name: `${organization.sOrganization.name ? organization.sOrganization.name : organization.sOrganization.organizationName}` }} />
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnCloseOrgProductsModal" size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            {!isCurrentProfileOrg && <DialogActions className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnDiscardChanges" variant="outlined" color="secondary" style={{ marginLeft: '10px', background: '#E32B29', color: '#FFFFFF' }} onClick={handleDiscardProducts}>
                            <FormattedMessage id={"OrgProductsEdit.DiscardButtonText"} />
                        </Button>
                        <Button id="btnSaveLocation" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveProducts}>
                            <FormattedMessage id={"OrgProductsEdit.SaveButtonText"} />
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>}
            <DialogContent dividers={true} className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <OrgProductsListing ref={orgLicensesListingRef} />
            </DialogContent>
        </Modal>
    )
}

export default OrgProductsEdit;
