import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Link } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import Controls from "../../Controls/Controls";
import { Form, useForm } from '../../useForm';
import { useStyles } from "./styles";
import { createSvgIcon } from "../../../../utils";
import { showNotification } from 'admin/store/actions/snackbar';
import { PhoneMask, DateMask } from 'admin/components/Controls/InputMasked';
import { validateDate, validateEmail, validateNoSpecialCharacters, validateRequired } from 'admin/common/utils';
import { ReactComponent as UserSingle } from "icon-library/SVG/User_Single.svg";
import { ReactComponent as Users } from "icon-library/SVG/User_Users.svg";
import { ReactComponent as Shield } from "icon-library/SVG/Shield.svg";
import { US_STATES } from 'admin/constant';
import { editPatient } from 'admin/store/actions';
import moment from 'moment';
const UserIcon = createSvgIcon(UserSingle);
const UsersIcon = createSvgIcon(Users);
const ShieldIcon = createSvgIcon(Shield);


const EditPatientForm = forwardRef((props, ref) => {

    const { admin, auth } = useSelector((state: AppReduxStore) => state);
    const isEHRConnectedPractice = auth.currentPractice && auth.currentPractice?.practiceEhrSettingId
    const { selectedPatient } = admin
    const { contacts } = selectedPatient
    const [selectedEditPatientStepIndex, setSelectedEditPatientStepIndex] = useState(0);

    const classes = useStyles();
    const intl = useIntl()
    const dispatch = useDispatch()
    const initialFValues = {
        "address": selectedPatient.address,
        "address2": selectedPatient.address2,
        "cellPhoneNumber": selectedPatient.cellPhoneNumber,
        "city": selectedPatient.city,
        "contacts": [
            {
                "address1": contacts && contacts.length > 0 ? contacts[0]?.address1 : null,
                "address2": contacts && contacts.length > 0 ? contacts[0]?.address2 : null,
                "city": contacts && contacts.length > 0 ? contacts[0]?.city : null,
                "country": contacts && contacts.length > 0 ? contacts[0]?.country : null,
                "county": contacts && contacts.length > 0 ? contacts[0]?.county : null,
                "dateOfBirth": contacts && contacts.length > 0 ? contacts[0]?.dateOfBirth : null,
                "dependent": contacts && contacts.length > 0 ? contacts[0]?.dependent : null,
                "email": contacts && contacts.length > 0 ? contacts[0]?.email : null,
                "firstName": contacts && contacts.length > 0 ? contacts[0]?.firstName : null,
                "homePhone": contacts && contacts.length > 0 ? contacts[0]?.homePhone : null,
                "lastName": contacts && contacts.length > 0 ? contacts[0]?.lastName : null,
                "middleName": contacts && contacts.length > 0 ? contacts[0]?.middleName : null,
                "mobilePhone": contacts && contacts.length > 0 ? contacts[0]?.mobilePhone : null,
                "patientContactEmailSet": contacts && contacts.length > 0 ? contacts[0]?.patientContactEmailSet : [],
                "patientContactRoleSet": contacts && contacts.length > 0 ? contacts[0]?.patientContactRoleSet : [],
                "patientProfileId": contacts && contacts.length > 0 ? contacts[0]?.patientProfileId : null,
                "primaryContact": contacts && contacts.length > 0 ? contacts[0]?.primaryContact : null,
                "relationship": contacts && contacts.length > 0 ? contacts[0]?.relationship : null,
                "secondaryPhone": contacts && contacts.length > 0 ? contacts[0]?.secondaryPhone : null,
                "state": contacts && contacts.length > 0 ? contacts[0]?.state : null,
                "type": contacts && contacts.length > 0 ? contacts[0]?.type : null,
                "workPhone": contacts && contacts.length > 0 ? contacts[0]?.workPhone : null,
                "zip": contacts && contacts.length > 0 ? contacts[0]?.zip : null,
            },
            {
                "address1": contacts && contacts.length > 1 ? contacts[1]?.address1 : null,
                "address2": contacts && contacts.length > 1 ? contacts[1]?.address2 : null,
                "city": contacts && contacts.length > 1 ? contacts[1]?.city : null,
                "country": contacts && contacts.length > 0 ? contacts[1]?.country : null,
                "county": contacts && contacts.length > 1 ? contacts[1]?.county : null,
                "dateOfBirth": contacts && contacts.length > 1 ? contacts[1]?.dateOfBirth : null,
                "dependent": contacts && contacts.length > 1 ? contacts[1]?.dependent : null,
                "email": contacts && contacts.length > 1 ? contacts[1]?.email : null,
                "firstName": contacts && contacts.length > 1 ? contacts[1]?.firstName : null,
                "homePhone": contacts && contacts.length > 1 ? contacts[1]?.homePhone : null,
                "lastName": contacts && contacts.length > 1 ? contacts[1]?.lastName : null,
                "middleName": contacts && contacts.length > 1 ? contacts[1]?.middleName : null,
                "mobilePhone": contacts && contacts.length > 1 ? contacts[1]?.mobilePhone : null,
                "patientContactEmailSet": contacts && contacts.length > 1 ? contacts[1]?.patientContactEmailSet : [],
                "patientContactRoleSet": contacts && contacts.length > 1 ? contacts[1]?.patientContactRoleSet : [],
                "patientProfileId": contacts && contacts.length > 1 ? contacts[1]?.patientProfileId : null,
                "primaryContact": contacts && contacts.length > 1 ? contacts[1]?.primaryContact : null,
                "relationship": contacts && contacts.length > 1 ? contacts[1]?.relationship : null,
                "secondaryPhone": contacts && contacts.length > 1 ? contacts[1]?.secondaryPhone : null,
                "state": contacts && contacts.length > 1 ? contacts[1]?.state : null,
                "type": contacts && contacts.length > 1 ? contacts[1]?.type : null,
                "workPhone": contacts && contacts.length > 1 ? contacts[1]?.workPhone : null,
                "zip": contacts && contacts.length > 1 ? contacts[1]?.zip : null,
            }
        ],
        "country": selectedPatient.country,
        "county": selectedPatient.county,
        "dateOfBirth": selectedPatient.dateOfBirth,
        "ehrPatientId": selectedPatient.ehrPatientId,
        "emailAddress": selectedPatient.emailAddress,
        "firstName": selectedPatient.firstName,
        "genderCode": selectedPatient.genderCode,
        "homePhoneNumber": selectedPatient.homePhoneNumber,
        "housingStatus": selectedPatient.housingStatus,
        "id": selectedPatient.id,
        "insuranceId": selectedPatient.insuranceId,
        "insuranceMemberId": selectedPatient.insuranceMemberId,
        "insuranceName": selectedPatient.insuranceName,
        "insuranceType": selectedPatient.insuranceType,
        "insurances": [],
        "lastName": selectedPatient.lastName,
        "literacyStatus": selectedPatient.literacyStatus,
        "locationId": selectedPatient.location?.id,
        "maritalStatus": selectedPatient.maritalStatus,
        "middleName": selectedPatient.middleName,
        "mrn": selectedPatient.mrn,
        "officePhoneNumber": selectedPatient.officePhoneNumber,
        "patientCitizenships": selectedPatient.patientCitizenships,
        "patientEmails": selectedPatient.patientEmails,
        "practiceId": selectedPatient?.practiceId,
        "preferredName": selectedPatient.preferredName,
        "preferredPronouns": selectedPatient.preferredPronouns,
        "prefix": selectedPatient.prefix,
        "primaryLanguage": selectedPatient.primaryLanguage,
        "primaryProviderId": selectedPatient.primaryProvider?.id,
        "profileRoleJoinId": selectedPatient.profileRoleJoinId,
        "race": selectedPatient.race,
        "sex": selectedPatient.sex,
        "sexualOrientation": selectedPatient.sexualOrientation,
        "smokingStatus": selectedPatient.smokingStatus,
        "ssn": selectedPatient.ssn,
        "state": selectedPatient.state,
        "status": selectedPatient.status,
        "suffix": selectedPatient.suffix,
        "userLevelId": selectedPatient.userLevelId,
        "veteranStatus": selectedPatient.veteranStatus,
        "zipCode": selectedPatient.zipcode,
        "supportContacts": selectedPatient.supportContacts
    }
    if (selectedPatient?.insurances && selectedPatient.insurances?.length > 0) {
        initialFValues["insurances"] = selectedPatient.insurances.map((ins: any) => {
            return {
                "agreementType": ins?.agreementType,
                "companyCity": ins?.companyCity,
                "companyCountry": ins?.companyCountry,
                "companyCounty": ins?.companyCounty,
                "companyId": ins?.companyId,
                "companyIdType": ins?.companyIdType,
                "companyName": ins?.companyName,
                "companyPhoneNumber": ins?.companyPhoneNumber,
                "companyState": ins?.companyState,
                "companyStreetAddress": ins?.companyStreetAddress,
                "companyZip": ins?.companyZip,
                "coverageType": ins?.coverageType,
                "effectiveDate": ins?.effectiveDate,
                "expirationDate": ins?.expirationDate,
                "groupName": ins?.groupName,
                "groupNumber": ins?.groupNumber,
                "insuredIdentifiers": ins?.insuredIdentifiers,
                "memberNumber": ins?.memberNumber,
                "planId": ins?.planId,
                "planIdType": ins?.planIdType,
                "planName": ins?.planName,
                "planType": ins?.planType,
                "policyNumber": ins?.policyNumber,
                "priority": ins?.priority,
                "relationship": ins?.relationship,
            }
        })
    }
    useEffect(() => {
        resetValues({
            ...initialFValues
        })
    }, [selectedPatient]);
    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            }
        }),
    )
    const handleEditPatientStepClick = (event: any, index: any) => {
        setSelectedEditPatientStepIndex(index);
    };

    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('firstName' in fieldValues) {
            err.firstName = validateRequired(fieldValues.firstName, intl)
            if (err.firstName === "") err.firstName = validateNoSpecialCharacters(fieldValues.firstName, intl)
        }
        if ('middleName' in fieldValues) {
            err.middleName = validateNoSpecialCharacters(fieldValues.middleName, intl)
        }
        if ('lastName' in fieldValues) {
            err.lastName = validateRequired(fieldValues.lastName, intl)
            if (err.lastName === "") err.lastName = validateNoSpecialCharacters(fieldValues.lastName, intl)
        }
        if ('dateOfBirth' in fieldValues) {
            err.dateOfBirth = validateRequired(fieldValues.dateOfBirth, intl)
            if (err.dateOfBirth === "") err.dateOfBirth = validateDate(fieldValues.dateOfBirth, intl)
        }
        if ('cellPhoneNumber' in fieldValues) {
            err.cellPhoneNumber = validateRequired(fieldValues.cellPhoneNumber, intl)
        }
        if ('mrn' in fieldValues) {
            err.mrn = validateRequired(fieldValues.mrn, intl)
        }
        if ('emailAddress' in fieldValues) {
            err.emailAddress = validateRequired(fieldValues.emailAddress, intl)
            if (err.emailAddress === "") err.emailAddress = validateEmail(fieldValues.emailAddress, intl)
        }

        if ('contacts[0].email' in fieldValues) {
            err['contacts[0].email'] = validateEmail(fieldValues['contacts[0].email'], intl)
        }
        if ('contacts[1].email' in fieldValues) {
            err['contacts[1].email'] = validateEmail(fieldValues['contacts[1].email'], intl)
        }

        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }

    const {
        values,
        resetValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        trimValues
    } = useForm(initialFValues, false, validate);
    const handleContactInputChange = (e: any) => {
        const { name, value } = e.target
        const fieldName = name.split('.')[1]
        if (name.includes("contacts[0]")) {
            const contacts = [...values.contacts];
            contacts[0][fieldName] = value
            resetValues({
                ...values,
                // eslint-disable-next-line no-useless-computed-key
                ["contacts"]: contacts
            })
        }
        else {
            const contacts = [...values.contacts];
            contacts[1][fieldName] = value
            resetValues({
                ...values,
                // eslint-disable-next-line no-useless-computed-key
                ["contacts"]: contacts
            })
        }

        validate({ [name]: value })
    }
    const trimFields = (values: any) => {
        values.cellPhoneNumber = values.cellPhoneNumber?.replace(/\D/g, '')
        values.homePhone = values.homePhone?.replace(/\D/g, '')
        return values
    }
    const handleSubmit = (e: any) => {
        trimValues()
        e.preventDefault()
        if (validate()) {
            const usersLevel = admin.userLevels && admin.userLevels.find((u: any) => u.type === "PATIENT")
            values["practiceId"] = auth.currentPractice?.id
            values["userLevelId"] = usersLevel?.id
            delete values.supportContacts
            dispatch(editPatient(trimFields(values)))
            resetForm()
            resetValues(initialFValues)
            return true
        }
        dispatch(showNotification("Invalid", "error", 'Please fill the required fields'))
        return false
    }
    const checkIfEHRField = (property: string) => {
        const ehrProperty = admin.ehrManagedProperties && admin.ehrManagedProperties.find((p: any) =>
            (p.property === property)
        )
        return (isEHRConnectedPractice && ehrProperty !== undefined)
    }
    return (
        <Box className={classes.contentArea}>
            <Box className={classes.leftPaneWrapper}>
                <List style={{ position: 'fixed' }}>
                    <Link href="#personalInfo">
                        <ListItem
                            disableGutters
                            classes={{
                                root: classes.sideNavOption,
                                selected: classes.selected
                            }}
                            selected={selectedEditPatientStepIndex === 0}
                            onClick={(event) => handleEditPatientStepClick(event, 0)}
                        >
                            <ListItemIcon>
                                <UserIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h4" >
                                    <FormattedMessage id="Personal" />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link href="#contactInfo">
                        <ListItem
                            disableGutters
                            classes={{
                                root: classes.sideNavOption,
                                selected: classes.selected
                            }}
                            selected={selectedEditPatientStepIndex === 1}
                            onClick={(event) => handleEditPatientStepClick(event, 1)}
                        >
                            <ListItemIcon>
                                <UsersIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h4" >
                                    <FormattedMessage id="Contacts" />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link href="#insuranceInfo">
                        <ListItem
                            disableGutters
                            classes={{
                                root: classes.sideNavOption,
                                selected: classes.selected
                            }}
                            selected={selectedEditPatientStepIndex === 2}
                            onClick={(event) => handleEditPatientStepClick(event, 2)}
                        >
                            <ListItemIcon>
                                <ShieldIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="h4" >
                                    <FormattedMessage id="Insurance" />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                </List>
            </Box>
            <Box className={classes.rightContent}>
                <Form onSubmit={handleSubmit}>
                    <Box id="personalInfo" mb={1} className={classes.formGpHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.Heading.Personal" })}
                    </Box>
                    <Box mb={1} pt={2} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.SubHeading.Info" })}
                    </Box>

                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="firstName"
                                label="First Name *"
                                value={values.firstName}
                                onChange={handleInputChange}
                                error={errors.firstName}
                                maxLength={50}
                                disabled={checkIfEHRField("firstName")}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="middleName"
                                label="Middle Name"
                                value={values.middleName}
                                onChange={handleInputChange}
                                error={errors.middleName}
                                maxLength={50}
                                disabled={checkIfEHRField("middleName")}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="lastName"
                                label="Last Name *"
                                value={values.lastName}
                                onChange={handleInputChange}
                                error={errors.lastName}
                                maxLength={50}
                                disabled={checkIfEHRField("lastName")}
                            />

                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="dateOfBirth"
                                label="Date of Birth *"
                                value={moment(values.dateOfBirth).isValid() ?
                                    moment(values.dateOfBirth).format('MM/DD/YYYY') :
                                    values.dateOfBirth}
                                placeholder="MM/DD/YYYY"
                                onChange={handleInputChange}
                                error={errors.dateOfBirth}
                                maxLength={50}
                                inputComponent={DateMask}
                                disabled={checkIfEHRField("dateOfBirth")}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="ssn"
                                label="SSN"
                                value={values.ssn}
                                onChange={handleInputChange}
                                error={errors.ssn}
                                maxLength={50}
                                disabled={checkIfEHRField("ssn")}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="mrn"
                                label="MRN *"
                                value={values.mrn}
                                onChange={handleInputChange}
                                error={errors.mrn}
                                maxLength={50}
                                disabled={checkIfEHRField("ehrPatientId")}
                            />

                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="sex"
                                label="Sex"
                                value={values.sex}
                                onChange={handleInputChange}
                                error={errors.sex}
                                maxLength={50}
                                disabled={checkIfEHRField("sex")}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={4} mb={1} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.SubHeading.Contact" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="cellPhoneNumber"
                                label="Mobile Phone *"
                                value={values.cellPhoneNumber}
                                onChange={handleInputChange}
                                error={errors.cellPhoneNumber}
                                maxLength={50}
                                inputComponent={PhoneMask}
                                disabled={checkIfEHRField("cellPhoneNumber")}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="homePhoneNumber"
                                label="Secondary Phone"
                                value={values.homePhoneNumber}
                                onChange={handleInputChange}
                                error={errors.homePhoneNumber}
                                maxLength={50}
                                inputComponent={PhoneMask}
                                disabled={checkIfEHRField("homePhoneNumber")}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="emailAddress"
                                label="Email *"
                                value={values.emailAddress}
                                onChange={handleInputChange}
                                error={errors.emailAddress}
                                maxLength={255}
                                disabled={checkIfEHRField("email")}
                            />
                        </Grid>

                    </Grid>
                    <Box mt={4} mb={1} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.SubHeading.Address" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="address"
                                label="Address 1"
                                value={values.address}
                                onChange={handleInputChange}
                                error={errors.address}
                                maxLength={10}
                                disabled={checkIfEHRField("address")}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="address2"
                                label="Address 2"
                                value={values.address2}
                                onChange={handleInputChange}
                                error={errors.address2}
                                maxLength={255}
                                disabled={checkIfEHRField("address2")}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="city"
                                label="City"
                                value={values.city}
                                onChange={handleInputChange}
                                error={errors.city}
                                maxLength={255}
                                disabled={checkIfEHRField("city")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controls.Select
                                name="state"
                                label="State"
                                placeholder="Select"
                                value={values.state}
                                onChange={handleInputChange}
                                options={US_STATES}
                                error={errors.state}
                                disabled={checkIfEHRField("state")}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="zipCode"
                                label="Zip"
                                value={values.zipCode}
                                onChange={handleInputChange}
                                error={errors.zipCode}
                                maxLength={255}
                                disabled={checkIfEHRField("zip")}
                            />
                        </Grid>
                    </Grid>
                    <Box id="contactInfo" mt={4} mb={1} className={classes.formGpHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.Heading.Contacts" })}
                    </Box>
                    <Box mb={1} pt={2} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.SubHeading.Emergency" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="contacts[0].firstName"
                                label="First Name"
                                value={values.contacts?.length > 0 && values.contacts[0].firstName}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="contacts[0].lastName"
                                label="Last Name"
                                value={values.contacts?.length > 0 && values.contacts[0].lastName}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="contacts[0].mobilePhone"
                                label="Mobile Phone"
                                value={values.contacts?.length > 0 ? values.contacts[0].mobilePhone : ""}
                                onChange={handleContactInputChange}
                                maxLength={50}
                                inputComponent={PhoneMask}

                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[0].secondaryPhone"
                                label="Secondary Phone"
                                value={values.contacts?.length > 0 ? values.contacts[0].secondaryPhone : ""}
                                onChange={handleContactInputChange}
                                maxLength={50}
                                inputComponent={PhoneMask}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[0].email"
                                label="Email"
                                value={values.contacts?.length > 0 && values.contacts[0].email}
                                onChange={handleContactInputChange}
                                error={errors["contacts[0].email"]}
                                maxLength={255}
                            />
                        </Grid>
                    </Grid>
                    <Box mb={1} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.SubHeading.Family" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].firstName"
                                label="First Name"
                                value={values.contacts?.length > 1 && values.contacts[1].firstName}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].lastName"
                                label="Last Name"
                                value={values.contacts?.length > 1 && values.contacts[1].lastName}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].relationship"
                                label="Relationship"
                                value={values.contacts?.length > 1 && values.contacts[1].relationship}
                                onChange={handleContactInputChange}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].mobilePhone"
                                label="Mobile Phone"
                                value={values.contacts?.length > 1 ? values.contacts[1].mobilePhone : ""}
                                onChange={handleContactInputChange}
                                maxLength={50}
                                inputComponent={PhoneMask}

                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].secondaryPhone"
                                label="Secondary Phone"
                                value={values.contacts?.length > 1 ? values.contacts[1].secondaryPhone : ""}
                                onChange={handleContactInputChange}
                                maxLength={50}
                                inputComponent={PhoneMask}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="contacts[1].email"
                                label="Email"
                                value={values.contacts?.length > 1 && values.contacts[1].email}
                                onChange={handleContactInputChange}
                                error={errors["contacts[1].email"]}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                    {
                        values?.supportContacts?.length > 0 && < Box mb={1} className={classes.formGpSubHeader}>
                            {intl.formatMessage({ id: "EditPatientForm.SubHeading.SupportNetwork" })}
                        </Box>
                    }
                    {values.supportContacts && values.supportContacts.map((supportContact: any, index: number) =>
                        <>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Controls.Input
                                        label="First Name"
                                        value={supportContact?.firstName}
                                        disabled={true}
                                        maxLength={50}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controls.Input
                                        label="Last Name"
                                        value={supportContact?.lastName}
                                        disabled={true}
                                        maxLength={50}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controls.Input
                                        label="Relation to Patient"
                                        value={supportContact?.contactRelation?.contactRelationName}
                                        disabled={true}
                                        maxLength={50}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Controls.Input
                                        label="Primary Phone"
                                        value={supportContact?.primaryPhone}
                                        disabled={true}
                                        maxLength={50}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controls.Input
                                        label="Secondary Phone"
                                        value={supportContact?.secondaryPhone}
                                        disabled={true}
                                        maxLength={50}
                                    />
                                </Grid>
                                <Grid item>
                                    <Controls.Input
                                        label="Email"
                                        value={supportContact?.primaryEmail}
                                        disabled={true}
                                        maxLength={50}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Controls.Input
                                        label="Altais Status"
                                        value={supportContact?.status}
                                        disabled={true}
                                        maxLength={50}
                                    />
                                </Grid>
                            </Grid>
                            <br /><br />
                        </>
                    )}

                    <Box id="insuranceInfo" mt={4} mb={1} className={classes.formGpHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.Heading.Insurance" })}
                    </Box>
                    <Box mb={1} className={classes.formGpSubHeader}>
                        {intl.formatMessage({ id: "EditPatientForm.SubHeading.Info" })}
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="insuranceName"
                                label="Plan Name"
                                value={values.insuranceName}
                                onChange={handleInputChange}
                                error={errors.insuranceName}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="insuranceType"
                                label="Plan Type"
                                value={values.insuranceType}
                                onChange={handleInputChange}
                                error={errors.insuranceType}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item>
                            <Controls.Input
                                name="insuranceId"
                                label="Plan ID"
                                value={values.insuranceId}
                                onChange={handleInputChange}
                                error={errors.insuranceId}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Controls.Input
                                name="insuranceMemberId"
                                label="Member ID"
                                value={values.insuranceMemberId}
                                onChange={handleInputChange}
                                error={errors.insuranceMemberId}
                                maxLength={255}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Box>
        </Box>
    )
})
export default EditPatientForm