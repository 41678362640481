import { FeeScheduleRegionStateType, feeScheduleRegionProps } from "admin/views/FeeSchedules/types";
import Spinner from "common/components/Spinner/Spinner";
import { ReactComponent as InfoCircle } from "icon-library/SVG/Info_Circle.svg";
import { ReactComponent as WarningTriangle } from "icon-library/SVG/Warning_Triangle.svg";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import { useEffect } from "react";
import { createSvgIcon } from "utils";

const InfoCircleIcon = createSvgIcon(InfoCircle);
const WarningTriangleIcon = createSvgIcon(WarningTriangle);

const initialFeeScheduleNote = () => {
    return (
        <div style={{ boxSizing: 'border-box', width: '830px', height: '96px', background: '#E8F5FE', border: '1px solid #139AED', borderRadius: '8px', display: 'flex' }}>
            <div style={{ padding: '10px' }}>
                <InfoCircleIcon fill={"#139AED"} style={{ width: '40px', height: '40px' }} />
            </div>
            <div>
                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '17px', lineHeight: '140%', color: '#0B5D8F', margin: '0px', marginTop: '10px', marginLeft: '10px' }}>
                    {`Note: Changing region selection after initial setup is not advised!`}
                </p>
                <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '140%', color: '#565662', margin: '0px', marginTop: '5px', marginLeft: '10px', marginRight: '30px' }}>
                    {`Changing selected region and/or groups may affect you users’ ability to access fee schedules.`}
                </p>
            </div>
        </div>
    )
};

const existingFeeScheduleNote = () => {
    return (
        <div style={{ boxSizing: 'border-box', width: '830px', height: '96px', background: '#FEF7E6', border: '1px solid #F5A800', borderRadius: '8px', display: 'flex' }}>
            <div style={{ padding: '10px' }}>
                <WarningTriangleIcon fill={"#F5A800"} style={{ width: '40px', height: '40px' }} />
            </div>
            <div><p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '17px', lineHeight: '140%', color: '#393948', margin: '0px', marginTop: '10px', marginLeft: '10px' }}>
                {`Note: Changing region selection after initial setup is not advised!`}
            </p>
                <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '140%', color: '#565662', margin: '0px', marginTop: '5px', marginLeft: '10px', marginRight: '30px' }}>
                    {`Changing selected region will affect you users’ ability to access fee schedules.`}
                </p>
            </div>
        </div>
    )
};

const useRegionsByOrganizationId = (orgId: string) => {
    const { content, fetchInfo, request } = useApi<{ orgId: string }, feeScheduleRegionProps[]>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: [],
        url: `/admin/v1/region/Organization/${orgId}`,
        method: HttpMethod.GET
    });

    useEffect(() => {
        if (orgId) {
            request({ orgId });
        }
    }, [orgId, request]);

    return {
        availableRegionList: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};

const useRegionsByPracticeId = (practiceId: string) => {
    const { content, fetchInfo, request } = useApi<{ practiceId: string }, feeScheduleRegionProps[]>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: [],
        url: `/admin/v1/region/assign-regions/practice/${practiceId}`,
        method: HttpMethod.GET
    });

    useEffect(() => {
        if (practiceId) {
            request({ practiceId });
        }
    }, [practiceId, request]);

    return {
        selectedRegionList: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};

export const FeeScheduleGroups = ({ orgId, practiceId, regionsState, onChange: setRegionsState, disabled }: { orgId: string, practiceId: string, regionsState: FeeScheduleRegionStateType, onChange: any, disabled?: boolean }) => {
    const { availableRegionList } = useRegionsByOrganizationId(orgId);
    const { selectedRegionList, hasResult } = useRegionsByPracticeId(practiceId);

    useEffect(() => {
        const newRegionsState: FeeScheduleRegionStateType = {};
        if (hasResult && selectedRegionList?.length > 0) {
            selectedRegionList.forEach((region: feeScheduleRegionProps) => {
                newRegionsState[region.id] = true;
            });
        }
        setRegionsState(newRegionsState);
    }, [selectedRegionList]);

    return (
        <div style={{ marginTop: '20px', marginBottom: '30px' }}>
            <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '24px', lineHeight: '140%', color: '#393948', margin: '0px 0px 10px 0px' }}>
                {`Fee Schedule Groups`}
            </p>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '320px', marginTop: '10px' }}>
                    <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', margin: '0px' }}>
                        {`Region`}
                    </p>
                    {hasResult ? ((availableRegionList?.length > 0) ? (
                        <ul style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0', margin: '0px' }}>
                            {availableRegionList.map((region: feeScheduleRegionProps, index: number) => {
                                return (<li key={`fee_schedule_region_${index}`}
                                    style={{ listStyle: 'none', margin: '10px', fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%' }}>
                                    <input type="radio" disabled={!!disabled}
                                        name={`fee_schedule_region_radio_selection`}
                                        checked={regionsState[region.id] || false}
                                        onChange={() => {
                                            const newRegionsState = Object.assign({}, regionsState);
                                            newRegionsState[region.id] = true;
                                            for (const [key] of Object.entries(newRegionsState)) {
                                                if (key !== region.id)
                                                    newRegionsState[key] = false;
                                            }
                                            setRegionsState(newRegionsState);
                                        }}
                                        id={`fee_schedule_region_checkbox_${index}`} />
                                    {region.name}
                                </li>);
                            })}
                        </ul>
                    ) : (<p>{`There is no region associated with this organization`}</p>)) : <Spinner />}
                </div>
                {false ? initialFeeScheduleNote() : existingFeeScheduleNote()}
            </div>
        </div>
    )
}
// })