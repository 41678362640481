import { SigninWithRedirectOptions } from "@okta/okta-auth-js";
import { msalInstance } from "../index";
import { loginRequest } from "./azure/authConfig";
import { getToken } from "./azure/azure-auth-utils";

export const getAppIdToken = (): string | undefined => {
    const account = msalInstance.getActiveAccount();
    if (account) {
        const homeAccountId = account?.homeAccountId;
        const cache: any = msalInstance.getTokenCache();
        const idToken = cache.storage.getIdToken(homeAccountId);
        return idToken?.secret;
    }
}

export const getAppCurrentProfileId = (): string | undefined => {
    const account = msalInstance.getActiveAccount();
    const profileId: string | undefined = account?.idTokenClaims?.['extension_current_profilerolejoin_id'] as string;
    return profileId;
}

export const signOutApp = async (postLogoutRedirectUri: string): Promise<void> => {
    await msalInstance.logoutRedirect({
        postLogoutRedirectUri: postLogoutRedirectUri
    });
}

export const signInApp = async (userInfo: any): Promise<any> => {
    await msalInstance.loginRedirect(loginRequest);
    return Promise.resolve();
}

export const signInAppWithRedirect = async (opts?: SigninWithRedirectOptions | undefined): Promise<void> => {
    await msalInstance.loginRedirect(loginRequest);
    return Promise.resolve();
}

export const renewAppTokens = async (): Promise<void> => {
    await getToken();
    return Promise.resolve();
}
