import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    IconButton,
    Typography
} from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import LocationEditForm from "./LocationEditForm";
import { useStyles } from "./styles";

const LocationEdit = () => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const { sLocationA } = useSelector((state: AppReduxStore) => state).location
    const editLocationFormRef = useRef<any>()
    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose()
    };

    const handleSaveLocation = (event: any) => {

        const result = editLocationFormRef.current.submitForm(event);
        if (result) {
            setTimeout(() => {
                handleClose();
            }, 1000)

        }
    };

    return (
        <Modal onClose={onClose} id={MODAL_TYPES.EDIT_LOCATION}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"EditLocation.Title"} values={{ name: sLocationA.name }} />
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnCloseLocationModal" size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnDiscardChanges" variant="outlined" style={{ marginLeft: '10px' }} onClick={handleClose}>
                            <FormattedMessage id={"EditLocation.CancelButtonText"} />
                        </Button>
                        <Button id="btnSaveLocation" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveLocation}>
                            <FormattedMessage id={"EditLocation.UpdateButtonText"} />
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <LocationEditForm ref={editLocationFormRef} />
            </DialogContent>
        </Modal>
    )
}

export default LocationEdit