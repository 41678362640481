import { FormattedMessage } from "react-intl";
import { useDateFormatters } from "common/utils/use-date-formatter";

type TimeMarkProps = {
    dateTime: string,
    id: string,
    messageId: string
}

const TimeMark = ({ dateTime, id, messageId }: TimeMarkProps) => {
    const dateFormatters = useDateFormatters();
    return (
        <>
            <span className='dueDateText'>
                <FormattedMessage id={messageId}/>
            </span>
            <span id={id} className='dueDate'>
                {dateFormatters["MMMM DD, YYYY, hh:mm aa"](dateTime)}
            </span>
        </>
    )
}
export default TimeMark;
