import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";
import { isGreyTheme } from "components/Twilio/Footer/styles"
export const useStyles = makeStyles(() => ({
        wrapper: {
            width: '100%',
            position: 'absolute',
            zIndex: 1,
            padding: `${SIZING.scale300} ${SIZING.scale400}`,
            top: '-122px',
            paddingLeft: SIZING.scale800,
            background: ({ theme }: any) => isGreyTheme(theme) ? COLORS.DGREY : COLORS.GREY,
            "& .MuiBox-root": {
                marginBottom: '0 !important'
            },
            "& .custom-multiselect-wrapper":{
                maxWidth:'calc(100% - 2.688rem)'
            },
            "& .MuiPaper-root": {
                width:SIZING.TRANSCRIBE_MODAL_WIDTH,
                height:SIZING.SIDE_NAV_OPEN_WIDTH
            },
            "& .loader-wrapper":{
                display:'flex',
                alignItems:'center',
                fontSize:SIZING.scale400,
                fontWeight:'normal',
                "& .MuiCircularProgress-root":{
                    height:`${SIZING.scale400} !important`,
                    width:`${SIZING.scale400} !important`,
                    marginRight: SIZING.scale300
                }
            }
        },
        title: {
            marginBottom: SIZING.scale200,
            color: COLORS.MULTI_SELECT_BG
        },
        contentWrapper: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center'
        },
        sendIcon:{
            position:'absolute',
            right:'3.8rem',
            top:SIZING.scale400,
            cursor: 'pointer',
        },
        closeIcon:{
             height:"2rem",
             width:"2rem",
             marginLeft:SIZING.scale200,
             marginRight:SIZING.scale200,
             cursor: 'pointer',
             "& path":{
                 fill: COLORS.ALERTS_ICON
             }
        },
        divider: {
            height: '1px',
            width: '100%',
            background: 'rgba(0, 0, 0, 0.1)',
            marginTop: SIZING.scale500
        }
}));
