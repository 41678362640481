import React from "react";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import {ReactComponent as Person} from "icon-library/SVG/User_Single.svg";
import {AppReduxStore} from "store/reducerTypes";
import {useStyles} from "./styles";

const PatientHeader = () => {
    const classes = useStyles()
    const { selectedPatient } = useSelector((state: AppReduxStore) => state.patient)

    if (!selectedPatient) return null

    const { lastName, firstName, age, genderCode, mrn } = selectedPatient

    return (
        <Box display="flex" alignItems="center">
            <Box className={classes.personIcon}><Person viewBox={"5 5 22 22"}/></Box>
            <Box>
                <Box className={classes.patientName} id="QA_patientName">
                    <CustomTypography variant="desktopPatientDetailsHeader4">
                        {lastName},
                    </CustomTypography>
                    <CustomTypography variant="desktopPatientDetailsHeader4">
                        {firstName}
                    </CustomTypography>
                </Box>
                <Box className={classes.patientSubtitle} id="QA_patient_Additional_info">
                    <CustomTypography variant="desktopPatientDetailsBaseBold">
                        {age} {genderCode}
                    </CustomTypography>
                    <CustomTypography variant="desktopPatientDetailsBase">
                        {mrn}
                    </CustomTypography>
                </Box>
            </Box>
        </Box>
    )
}

export default PatientHeader