import { makeStyles } from "@mui/styles";
import  { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
        root:{
            "&>div":{
                zIndex:1
            },
        },
        leaveButton:{
            marginLeft: 'auto',
            background: COLORS.PRIORITY_BG,
            marginRight: SIZING.scale400,
            paddingLeft: SIZING.scale600,
            paddingRight: SIZING.scale350,
            color: COLORS.WHITE,
            "&:hover": {
                background: COLORS.PRIORITY_BG,
            },
            border: `1.2px solid ${COLORS.PRIORITY_BG}`,
            fontSize: SIZING.scale350,
            height:SIZING.scale1000,
            "& svg":{
                marginLeft:'5px',
                "& path":{
                    fill:COLORS.WHITE
                }
            }
        },
        menuWrapper:{
            padding:SIZING.scale200,
            minWidth:'265px',
            "& .menu-items-leave-button":{
                color:COLORS.WHITE,
                borderRadius:'8px',
                display:'flex',
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%), #E32B29',
                padding: `8px ${SIZING.scale300}`,
                "&>svg ":{
                    "& path":{
                        fill: COLORS.WHITE,
                    },
                    fontSize:'32px',
                    marginRight:'10px'
                },
            marginBottom:SIZING.scale250,
            "&:last-child":{
                marginBottom:'unset'
            }

            },
            "& .menu_content_wrapper":{
                "& span":{
                    fontSize:'10px'
                }
            }
        },
        paperWrapper:{
            borderRadius:'16px'
        }
}));