import { useStyles } from "./styles";

const MenuSeparatorView = () => {
    const classes = useStyles();

    return (
        <div className={classes.optionDividerWrapper}>
            <div className={classes.optionDivider} />
        </div>
    );
};

export default MenuSeparatorView;
