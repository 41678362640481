import React from "react";
import {
    Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Typography, TextField, Tooltip, Grid
} from "@mui/material";
import { useStyles } from "./styles";
import { ReactComponent as WarningIcon } from "admin/assets/common/ExclamationWarning.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as CirclesHexagonGrid } from "icon-library/SVG/Circles_Hexagon-Grid.svg";
import { createSvgIcon } from "../../../../utils";
const CirclesHexagonGridIcon = createSvgIcon(CirclesHexagonGrid);
const AddPracticeProducts = (props: any) => {
    const { handlePracticeProductsInputChange, practiceProductsFormData, showAvailableToAdd } = props;
    const classes = useStyles();
    const intl = useIntl()
    return (<>
        <Grid item className={classes.productsPageHeader}>
            <Typography className={classes.pageHeaderTextProduct}>
                <CirclesHexagonGridIcon fill="#0F7BBD" className={classes.productsIcon} />
                <FormattedMessage id="AddOrgProductsForm.Heading.Text" />
            </Typography>
            <Typography className={classes.pageSubHeaderText}>
                <FormattedMessage id="AddPracticeProductsForm.SubHeading.Text" />
            </Typography>
        </Grid>
        <Paper>
            <TableContainer >
                <Table>
                    <TableHead className={classes.productTableHeader}>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    Add
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    Product Catalog
                                </Typography>
                            </TableCell>
                            {showAvailableToAdd && <TableCell>
                                <Typography>
                                    Available
                                </Typography>
                            </TableCell>}
                            <TableCell style={{ textAlign: "right" }}>
                                <Typography>
                                    Assigned
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {practiceProductsFormData && practiceProductsFormData.map((product: any, index: number) => {
                            return (
                                <TableRow>
                                    <TableCell style={{ paddingLeft: (product.parentId ? "60px" : "") }}>
                                        <Switch
                                            name="productSwitch"
                                            color="primary"
                                            checked={product?.isChecked}
                                            onChange={(event) => {
                                                handlePracticeProductsInputChange(event, index);
                                            }}
                                            disabled={product?.disabledSwitch}
                                        />
                                        {product?.disabledSwitch}
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {product?.name}
                                        </Typography>
                                    </TableCell>
                                    {showAvailableToAdd &&
                                        <TableCell>
                                            <Typography>
                                                {isNaN(product?.availableLicenseCount) ? "" : product?.availableLicenseCount}
                                            </Typography>
                                        </TableCell>}
                                    <TableCell style={{ textAlign: "right" }}>
                                        {showAvailableToAdd && parseInt(product?.licenseCount) > 0 && (product?.licenseCount > product.availableLicenseCount) &&
                                            <Tooltip arrow title={intl.formatMessage({ id: "PracticeLicensesListing.Tooltip.NotEnoughLicenses" })}>
                                                {<WarningIcon style={{ marginTop: '5px' }} />}
                                            </Tooltip>
                                        }

                                        {(product?.licenseCount === "Unlimited") ?
                                            <TextField style={{ width: "100px" }}
                                                variant="outlined"
                                                name="licenseCount"
                                                value={product?.licenseCount}
                                                type="text"
                                            /> :
                                            <TextField style={{ width: "80px" }}
                                                variant="outlined"
                                                name="licenseCount"
                                                onChange={(event: any) => {
                                                    handlePracticeProductsInputChange(event, index, product);
                                                }}
                                                value={product?.licenseCount}
                                                type="number"
                                                inputProps={{
                                                    autocomplete: "",
                                                    min: 0,
                                                    classes: { notranslate: { padding: 0 } }
                                                }}
                                            />}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </>);
};

export default AddPracticeProducts;
