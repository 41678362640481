import { makeStyles } from "@mui/styles";
import  { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
    crossIcon: {
        "& path": {
            fill: COLORS.PRIORITY_BG
        },
        cursor: "pointer"
    },
    quickChatWrap:{
        display:"flex",
        justifyContent:"space-between",
        "& path":{
            fill: COLORS.PRIORITY_BG
        }
    },
    quickChatTitle: {
        fontSize: SIZING.scale350,
        fontWeight: 700,
        color: COLORS.GREY,
    },
    quickMessageContent:{
        width:"100%",
        borderRadius:SIZING.scale1600,
        height:SIZING.scale1200,
        background:COLORS.LBLUE,
        boxShadow:"0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        color:COLORS.WHITE,
        marginBottom:SIZING.scale200,
        cursor:"pointer"
    },
    quickChatSection:{
        margin:`${SIZING.scale525} 0px`
    },
    textfield:{
        width:"100%",
        "& input":{
            background:COLORS.LEARNING_CENTER_BG,
            textIndent:0,
            border: `${COLORS.LGREY1} !important`,
            fontSize: SIZING.scale400,
            padding: SIZING.scale200,
            fontWeight:400,
            "&:focus": {
                outline: "none !important"
            },
        },
        "& .MuiInputBase-root": {
            border: `${COLORS.LGREY1} !important`,
        },
        "& .MuiInputBase-input": {
            "&::placeholder": {
                color:`${COLORS.LGREY1} !important`,
            }
        }
    },
    sendBtn:{
        background:COLORS.LBLUE,
        color:COLORS.WHITE,
        height:SIZING.scale900,
        width:SIZING.SIDE_NAV_CLOSED_WIDTH
    },
    btnSection:{
        marginTop:SIZING.scale300,
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },
    divider:{
        marginBottom:`${SIZING.scale525} !important`
    },
    paperWrapper:{
        width:SIZING.QUICK_CHAT_BOX,
        minHeight:SIZING.QUICK_CHAT_BOX,
        padding:SIZING.scale400,
        borderRadius:SIZING.scale400
    },
    chatLengthText :{
        fontSize:SIZING.scale300,
        color:COLORS.GRAY_50,
        fontWeight:600
    }

}));