import React, { useRef, useEffect } from "react";
import { SvgIcon } from "@mui/material";
import { ReactComponent as User } from "icon-library/SVG/User_Single.svg";
import { ReactComponent as Stethoscope } from "icon-library/SVG/Stethoscope.svg";
import { ReactComponent as Shield } from "icon-library/SVG/Shield.svg";
import { USER_LEVEL, USER_PROFILE, USER_TYPE } from "./constant";
import { COLORS } from "./theme";
import { ConversationParticipant } from "store/chat-types";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

export const programmaticDownload = (filename: string, blob: Blob) => {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()
    a.remove()
    URL.revokeObjectURL(url)
}

export const usePrevious = (value: any): any => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useStyles = makeStyles(() => ({
    size: {
        height: ({ size = "1em" }) => size,
        width: ({ size = "1em" }) => size
    },
    color: {
        "& path, & g": {
            fill: ({ fill = COLORS.DEFAULT_ICON }: any) => fill,
            fillOpacity: ({ opacity = "1" }: any) => opacity,
            opacity: ({ opacity = "1" }: any) => opacity
        }
    }
}));

export const createSvgIcon = (Svg: any) => {
    return (props: any) => {
        const classes = useStyles(props)
        return (
            <SvgIcon classes={{ root: classes.size }} viewBox="0 0 32 32" {...props}>
                <Svg className={classes.color} />
            </SvgIcon>
        )
    }
}

export const getParticipantName = (p: ConversationParticipant, reversed: boolean = false): string => {
    const profile = p[USER_PROFILE[p.userType]];
    if (profile) {
        const prefix = profile.prefix ? `${profile.prefix} ` : ''
        return reversed ? `${prefix}${profile.lastName.toUpperCase()}, ${profile.firstName}` : `${prefix}${profile.firstName} ${profile.lastName}`
    }
    return p.identity
}

export const getDiffDuration = (startDate: string) => {
    const start = moment(startDate);
    const diff = moment().diff(start);
    return moment.duration(diff);
}

export const getAge = (dateOfBirth: string | null) => {
    const age = moment().diff(moment(dateOfBirth, "MM-DD-YYYY"), "years")
    return isNaN(age) ? "" : age
}

export const getAgeWithFormat = (dateOfBirth: string | null, format: string) => {
    const age = moment().diff(moment(dateOfBirth, format), "years")
    return isNaN(age) ? "" : age
}

export const formatDate = (date: string) => moment(date).format("MMM D, Y")

export const getDateFromEpoch = (inputDate: any) => {
    return new Date(inputDate).toLocaleDateString("en-US");
}

export const formatDateType2 = (date: string) => moment(date).format('MMM DD, YYYY')

export const formatDateWithTime = (date: string) => moment(date).format('MMM DD, YYYY hh:mm A')

export const formatDateWithTimeType2 = (date: string) => moment(date).format('ddd MMM DD, YYYY hh:mm A')

export const formatPhoneNumber = (number: string | null) => {
    if (!number) return "-"
    const stripped = number.replace(/\D/g, '')
    switch (stripped.length) {
        case 10:
            return stripped.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        case 11:
            return stripped.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
        default:
            return "-"
    }
}

export const getParticipantIcon = (p: any) => {
    switch (p.userType) {
        case (USER_TYPE.ADMIN):
            return createSvgIcon(Shield)
        case (USER_TYPE.PROVIDER):
            return createSvgIcon(Stethoscope)
        case (USER_TYPE.PATIENT):
            return createSvgIcon(User)
        default:
            return createSvgIcon(User)
    }
}

export const IsJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e: any) {
        return false;
    }
    return true;
}

export const getAddress = (location: any = {}) => {
    const { address1, city, state } = location
    let address: any = ''
    if (address1) {
        address = `${address1}, `
    }
    if (city) {
        address += `${city}, `
    }
    if (state) {
        address += `${state}`
    }
    if (!address) {
        address = <FormattedMessage id="SideNavHeader.label.noAddress" />
    }
    return address
}

export const addMinuteToCurrentDate = (minutes: number) => {
    return new Date(new Date().getTime() + minutes * 60000)
}
export const getTitleByUserLevelName = (userLevel: string) => {
    switch (userLevel) {
        case (USER_LEVEL.ALTAIS_ADMIN_1):
        case (USER_LEVEL.ALTAIS_ADMIN_2):
        case (USER_LEVEL.ALTAIS_ADMIN_3):
        case (USER_LEVEL.ALTAIS_ADMIN_4):
            return <FormattedMessage id="ProfileSelector.altaisAdminTitle" />
        case (USER_LEVEL.ORG_ADMIN_1):
        case (USER_LEVEL.ORG_ADMIN_2):
            return <FormattedMessage id="ProfileSelector.orgAdminTitle" />
        case (USER_LEVEL.PRACTICE_ADMIN):
            return <FormattedMessage id="ProfileSelector.practiceAdminTitle" />
        case (USER_LEVEL.BILLING_ADMIN):
            return <FormattedMessage id="ProfileSelector.billingAdminTitle" />
        case (USER_LEVEL.STAFF_1):
            return <FormattedMessage id="ProfileSelector.staffITitle" />
        case (USER_LEVEL.STAFF_2):
            return <FormattedMessage id="ProfileSelector.staffIITitle" />
        case (USER_LEVEL.STAFF_3):
            return <FormattedMessage id="ProfileSelector.staffIIITitle" />
        case (USER_LEVEL.STAFF_4):
            return <FormattedMessage id="ProfileSelector.staffIVTitle" />
    }
}