import { Button, Checkbox, TextField } from "@mui/material";
import { makeApiCall } from 'admin/common/utils';
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from 'admin/store/reducerTypes';
import DatePicker from "common/components/DatePicker/DatePicker";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { addMinuteToCurrentDate } from "utils";
import { FeeScheduleFileDropZone } from "./fee-schedule-file-dropzone";
import { useStyles } from "./styles";
import { BillingGroupInfoProps, billingGroupProps, BillingGroupStateType, CalendarPickerStateType, FeeScheduleDetailsProps, FeeScheduleRequestProps } from "./types";
import { useFeeScheduleGroups } from "./use-fee-schedule-groups";

const useFeeScheduleDetails = (scheduleId: string) => {
    const { content, fetchInfo, request } = useApi<{ scheduleId: string }, FeeScheduleDetailsProps | null>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: null,
        url: `/admin/v1/billing/schedule/${scheduleId}`,
        method: HttpMethod.GET
    });

    useEffect(() => {
        if (scheduleId)
            request({ scheduleId });
    }, [scheduleId, request]);

    return {
        feeScheduleDetails: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};

const getTopLevelOrgId = (org: any) => {
    let current = org;
    if (current === null || current?.parent === null)
        return null;

    while (current !== null) {
        if (current?.parent?.parent === null)
            return current?.id;
        current = current?.parent;
    }
} 

const FeeScheduleConfig = () => {
    const { currentPractice, currentProfileRoleJoinInfo } = useSelector((state: AppReduxStore) => state).auth;
    const { organization } = useSelector((state: AppReduxStore) => state);
    const isSubOrg: boolean = (organization?.sOrganization?.parent?.parent || currentPractice?.organizationInfo?.parent?.parent) ? true : false;
    const orgId = isSubOrg ? (getTopLevelOrgId(organization?.sOrganization) || getTopLevelOrgId(currentPractice?.organizationInfo)) : (organization?.sOrganization?.id || currentProfileRoleJoinInfo?.organization?.id || currentPractice?.organizationId);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const matchId: any = useRouteMatch("/admin/feeschedules/config/:id");
    const scheduleId = matchId?.params?.id ? matchId?.params?.id : null;
    const { feeScheduleGroups } = useFeeScheduleGroups(orgId);
    const { feeScheduleDetails } = useFeeScheduleDetails(scheduleId);
    const [feeScheduleName, setFeeScheduleName] = useState<string>("");
    const [uploadResponse, setUploadResponse] = useState<any>();
    const [dates, setDates] = useState<CalendarPickerStateType>({
        effectiveDate: addMinuteToCurrentDate(5),
        expirationDate: null
    });
    const [billingGroups, setBillingGroups] = useState<BillingGroupStateType>({});

    const getFileSpecifications = () => {
        return (
            <div style={{ boxSizing: 'border-box', width: '75%', height: '240px', background: '#FEF7E6', padding: '10px', border: '1px solid #F5A800', borderRadius: '16px', marginLeft: '16px' }}>
                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '22px', letterSpacing: '-0.408px', color: '#393948' }}>{`File Specifications`}</p>
                <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%', color: '#393948' }}>{`1. Accepted file format: PDF.`}</p>
                <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%', color: '#393948' }}>{`2. Maximum file size: 50 MB`}</p>
                <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%', color: '#393948' }}>
                    {`3. File name:`}<br />
                    {` - 64 characters maximum `}<br />
                    {` - No special characters or spaces. Dashes (-) and underscores (_) are allowed`}<br />
                    {` - File extention is required (.xxx or .xxxx)`}<br />
                </p>
            </div>
        )
    }

    const getCalendar = (calendarType: "effectiveDate" | "expirationDate") => {
        const placeHolderText: string = (calendarType === "effectiveDate") ? "Select an effective date" : "Select an optional expiration date";
        return (
            <div className={classes.fee_schedule_config_calendar_wrapper}>
                <DatePicker
                    placeholderText={placeHolderText}
                    selected={dates[calendarType]}
                    onChange={(date) => {
                        const newDates = Object.assign({}, dates);
                        newDates[calendarType] = date;
                        setDates(newDates);
                    }}
                />
            </div>
        )
    }

    const getDisplayGroupsTable = () => {
        return (
            <div style={{ width: '900px' }}>
                <ul style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0', margin: '0px' }}>
                    {feeScheduleGroups && feeScheduleGroups.length > 0 && (feeScheduleGroups.map((feeScheduleGroup: billingGroupProps, index: number) => {
                        return (<li key={`fee_schedule_group_${index}`}
                            style={{ listStyle: 'none', flex: '0 0 33.333333%', marginTop: '5px', marginBottom: '5px', fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%' }}>
                            <Checkbox
                                color="primary" disableRipple disableFocusRipple
                                checked={billingGroups[feeScheduleGroup.billingGroupId] || false}
                                onChange={(event) => {
                                    const newValue: boolean = Boolean(event.target.checked);
                                    const newBillingGroups = Object.assign({}, billingGroups);
                                    newBillingGroups[feeScheduleGroup.billingGroupId] = newValue;
                                    setBillingGroups(newBillingGroups);
                                }}
                                id={`billing_group_${feeScheduleGroup.billingGroupId}`} />
                            {feeScheduleGroup.billingGroupName}
                        </li>);
                    }))}
                </ul>
            </div>
        )
    }

    const getFeeScheduleRequestObject = (): FeeScheduleRequestProps => {
        const selectedBillingGroups = [];
        for (const [key] of Object.entries(billingGroups)) {
            if (billingGroups[key])
                selectedBillingGroups.push(key);
        }
        return {
            effectiveDate: dates.effectiveDate?.toISOString(),
            expireDate: dates.expirationDate?.toISOString(),
            fileName: uploadResponse?.meta?.name,
            groupIds: selectedBillingGroups,
            name: feeScheduleName,
            orgId: orgId
        }
    }

    const handleUpdateFeeSchedule = async () => {
        const feeScheduleRequestObj = getFeeScheduleRequestObject();
        feeScheduleRequestObj.id = scheduleId;
        await makeApiCall(dispatch, "POST", "/admin/v1/billing/schedule", feeScheduleRequestObj).then(() => {
            history.push(`/admin/feeschedules/details/${scheduleId}`);
            dispatch(showNotification("Fee Schedule updated successfully.", "success", "Fee Schedule updated successfully."));
        }).catch((error: any) => {
            dispatch(showNotification("Error updating Fee Schedule", "error", error?.message));
        });
    }

    const handleCreateFeeSchedule = async () => {
        await makeApiCall(dispatch, "POST", "/admin/v1/billing/schedule", getFeeScheduleRequestObject()).then(() => {
            history.push('/admin/feeschedules');
            dispatch(showNotification("Fee Schedule created successfully.", "success", "Fee Schedule created successfully."));
        }).catch((error: any) => {
            dispatch(showNotification("Error creating new Fee Schedule", "error", error?.message));
        });
    }

    const isReady = (): boolean => {
        const isNameReady: boolean = (feeScheduleName !== "");
        const isFileReady: boolean = (!!uploadResponse?.meta?.name && uploadResponse.meta.name !== "");
        const isDateReady: boolean = (dates["effectiveDate"] !== null);
        const selectedBillingGroups = [];
        for (const [key] of Object.entries(billingGroups)) {
            if (billingGroups[key])
                selectedBillingGroups.push(key);
        }
        const isBillingGroupsReady: boolean = (selectedBillingGroups.length > 0);
        return (isNameReady && isFileReady && isDateReady && isBillingGroupsReady);
    }

    useEffect(() => {
        if (scheduleId && feeScheduleDetails) {
            setFeeScheduleName(feeScheduleDetails.name);
            setDates({
                effectiveDate: new Date(feeScheduleDetails.effectiveDate),
                expirationDate: feeScheduleDetails.expireDate ? new Date(feeScheduleDetails.expireDate) : null
            });
            if (feeScheduleDetails.billingGroupInfos && feeScheduleDetails.billingGroupInfos.length > 0) {
                const newBillingGroups = Object.assign({}, billingGroups);
                feeScheduleDetails.billingGroupInfos.forEach((billingGroup: BillingGroupInfoProps) => {
                    newBillingGroups[billingGroup.id] = true;
                });
                setBillingGroups(newBillingGroups);
            }
        }
    }, [feeScheduleDetails, scheduleId])

    return (
        <>
            <div style={{}}>
                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '32px', lineHeight: '140%', color: '#393948', margin: '0px' }} >
                    {(scheduleId && feeScheduleDetails?.name) ? feeScheduleDetails.name : `New Fee Schedule`}
                </p>
                <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '140%', color: '#565662', margin: '0px' }} >
                    {`All fields are required unless specifically noted`}
                </p>
            </div>
            <div style={{ display: 'flex', marginTop: '20px' }}>
                <div style={{ width: '50%' }}>
                    <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', margin: '0px' }} >
                        {`Fee Schedule Name`}
                    </p>
                    <TextField style={{ width: '100%', paddingRight: '20px' }}
                        variant="outlined"
                        name="fee-schedule-config-name"
                        value={feeScheduleName}
                        onChange={(event: any) => setFeeScheduleName(event.target.value)}
                        type="text"
                    />
                </div>
                <div style={{ width: '25%' }}>
                    <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', paddingLeft: '16px', margin: '0px' }} >
                        {`Effective Date`}
                    </p>
                    {getCalendar("effectiveDate")}
                </div>
                <div style={{ width: '25%' }}>
                    <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', paddingLeft: '16px', margin: '0px' }} >
                        {`Expiration Date (OPTIONAL)`}
                    </p>
                    {getCalendar("expirationDate")}
                </div>
            </div>
            <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', margin: '20px 0 0 0' }} >
                {`Select Fee Schedule File`}
            </p>
            <div style={{ display: 'flex', marginTop: '1px' }}>
                <div style={{ width: '50%' }}>
                    <FeeScheduleFileDropZone uploadResponse={setUploadResponse} />
                </div>
                <div style={{ width: '50%' }}>
                    {getFileSpecifications()}
                </div>
            </div>
            {
                feeScheduleGroups && feeScheduleGroups.length > 0 && (
                    <div style={{ marginTop: '20px' }}>
                        <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', margin: '0px' }} >
                            {`Select Applicable Groups`}
                        </p>
                        {getDisplayGroupsTable()}
                    </div>)
            }
            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                <Button id="btnCancel" variant="outlined" color="inherit" style={{ marginRight: '20px' }}>
                    <Link to={scheduleId ? `/admin/feeschedules/details/${scheduleId}` : `/admin/feeschedules`} style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '20px', lineHeight: '140%' }}>
                        {`Cancel`}
                    </Link>
                </Button>
                {scheduleId && feeScheduleDetails &&
                    <Button id="btnUpdateFeeSchedule" variant="contained" color="primary" onClick={handleUpdateFeeSchedule} disabled={!isReady()}>
                        {'Update Fee Schedule'}
                    </Button>
                }
                {!scheduleId && !feeScheduleDetails &&
                    <Button id="btnCreateFeeSchedule" variant="contained" color="primary" onClick={handleCreateFeeSchedule} disabled={!isReady()}>
                        {'Create Fee Schedule'}
                    </Button>
                }
            </div>
        </>
    );
}

export default FeeScheduleConfig;
