import React from "react";
import { Box } from "@mui/material";
import classNames from "classnames";
import * as CSS from "./class-names";
import { useStyles } from "./styles";

export const TabContainer: React.FC<{ className?: string; boxed?: boolean }> = ({ className, children, boxed }) => {
    const classes = useStyles();

    return (
        <Box
            className={classNames(classes.tabsContainer, className, CSS.TabContainer, {
                [CSS.TabContainerBoxed]: boxed,
            })}
        >
            {children}
        </Box>
    );
};
