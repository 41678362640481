import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    question: {
        margin: '.5rem 0 0 0'
    },
    questionNumber: {
        minWidth: '26px'
    },
    questionDescription: {
        paddingLeft: '.5rem'
    },
    questionInputField: {
        marginLeft: '2.25rem'
    },
    checkBoxLabel: {
        width: '12.5rem'
    },
    radioButtonLabel: {
        width: '12.5rem'
    },
    groupedTextQuestions: {
        margin: '.25rem 0 .25rem 2.25rem' 
    },
    groupedAnswerLabel: {
        textAlign: 'center'
    },
    groupedCheckBoxLabel: {
        minWidth: '5.75rem',
        margin: 'auto'
    },
    groupedRadioButtonLabel: {
        minWidth: '5.75rem',
        margin: 'auto',
        '& .MuiIconButton-label': {
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .MuiIconButton-root': {
            margin: 'auto'
        }
    }
}));