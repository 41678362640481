import { IInputProps } from "react-dropzone-uploader";
import classNames from "classnames";
import { ReactComponent as UploadIcon } from "icon-library/SVG/Upload.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { useFileDropzoneInputContext } from "./file-dropzone-input-context";
import { getFilesFromEvent } from "./get-files-from-event";
import { useStyles } from "../styles";

const Input = ({ multiple, accept, disabled, onFiles }: IInputProps) => {
    const classes = useStyles();
    const { inputRef } = useFileDropzoneInputContext();
    return (
        <label className={classNames(classes.input, classes.Files__Preview__Size)}>
            <SVGIcon svg={UploadIcon} />
            <input
                id="QA_upload_input"
                ref={inputRef}
                type="file"
                multiple={multiple}
                accept={accept}
                disabled={disabled}
                onChange={(e) => {
                    onFiles(getFilesFromEvent(e));
                }}
            />
            <span className="background"></span>
        </label>
    );
};

export default Input;
