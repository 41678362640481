import React, { useEffect } from "react"
import { Box, Typography, Button } from "@mui/material";
import { useStyles } from "./styles";
import { setActiveModal } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { WaitingRoomDetails, startNRUMeeting, dropUserFromMeeting, endCall, pauseNRUMeeting, joinExistingMeeting, leaveNRUMeeting, resetMeeting, notifyProviders } from "store/actions";
import { MODAL_TYPES } from "constant";
import { ReactComponent as PlusCircle } from "icon-library/SVG/Plus_Circle.svg";
import { useIntl,FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { getAge } from "utils"
import { useReduxDispatch } from "store/utils"
import TwilioVideo from "components/Twilio/Twilio"
import WaitingStatus from 'components/WaitingRoom/WaitingStatus/WaitingStatus';
import { useSnackbar } from "notistack";
import WaitingRoomCard from "./WaitingRoomCard/WaitingRoomCard"
import ProviderFilter from "views/WaitingRoom/provider-filter/provider-filter"
import SVGIcon from "common/components/svg-icon/svg-icon";
import PermissionCheckErrorMessage from "png/components/PermissionCheck/PermissionCheck"

// TODO: need clean Up AND UI Development
const WaitingRoom = () => {

    const dispatch = useDispatch()
    const customDispatch = useReduxDispatch()
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const { inviteToken } = useParams<any>()
    const intl = useIntl()

    const classes = useStyles({ allSelected: true })

    const handleModal = () => {
        dispatch(setActiveModal(MODAL_TYPES.PROVIDER.PNG_INVITE))
    }

    const { waitingRoom, user } = useSelector((store: AppReduxStore) => store)
    const {currentProfileId} = user

    const { currentMeetingDetails, currentMeetingParticipants,providerFilterIds } = waitingRoom

    useEffect(() => {
        if (inviteToken) {
            customDispatch(joinExistingMeeting(inviteToken)).finally(() => {
                history.replace('/provider/waiting-room')
            })
        }
    }, [inviteToken])

    useEffect(() => {
        providerFilterIds &&dispatch(WaitingRoomDetails())
    }, [dispatch,providerFilterIds])

    useEffect(()=>{
       const { providerProfile } = user?.currentProfileRoleJoin || { providerProfile: {} };
       dispatch({ type:'UPDATE_PROVIDER_FILTER_DETAILS',payload:[providerProfile] })
    },[dispatch,user.currentProfileRoleJoin])

    const isPatientLeft = () => {
        return !currentMeetingParticipants.find((identity: string) => identity === currentMeetingDetails.twilioUsername)
    }

    const handleStartMeeting = (meetingDetails: any) => {
        dispatch(startNRUMeeting(meetingDetails))
        dispatch(notifyProviders(currentProfileId,"INCALL",null,meetingDetails?.visitReminderId))  
    }

    const handleRoomCallback = (room: any) => {
        dispatch({ type: 'UPDATE_CURRENT_ROOM', payload: room })
    }

    const handleEndCall = (meetingDetails: any) => {
        dispatch(endCall(meetingDetails))
        dispatch(notifyProviders(currentProfileId,"COMPLETE",null,meetingDetails?.visitReminderId))
    }

    const handleDropUser = (p: any) => {
        dispatch(dropUserFromMeeting(p))
        dispatch(notifyProviders(currentProfileId,"REJECTED",null,p?.visitReminderId))
    }

    const handlePauseCall = (meetingDetails: any) => {
        dispatch(pauseNRUMeeting(meetingDetails))
        dispatch(notifyProviders(currentProfileId,"PAUSED",null,meetingDetails?.visitReminderId))
    }

    const updateParticipants = (participants: any) => {
        const participantIds = Object.keys(participants);
        dispatch({ type: 'SET_CURRENT_WAITING_ROOM_PARTICIPANTS', payload: participantIds })
    }

    const handleLeaveMeeting = (meetingDetails: any) => {
        dispatch(leaveNRUMeeting(meetingDetails))
    }

    const handleTimeout = () => {
        customDispatch(resetMeeting(currentMeetingDetails)).then(() => {
            enqueueSnackbar(intl.formatMessage({ id: 'Waiting_Room.NoResponse' }), {
                variant: "error",
            })
        })
    }

    const showErrorInstruction = (type:string) =>{
        dispatch({ type:'SET_MEDIA_UPDATE_ERROR',payload:type }) 
    }


    const { dateOfBirth, genderCode } = currentMeetingDetails?.patientProfileInfo || {
        dateOfBirth: null,
        genderCode: "",
    };

    return (
        <Box className={classes.waitingRoom}>

            <Box className={classes.waitingRoomSection}>
                <div className="waiting-room-inner-wrapper">

                    <Box className={classes.inviteButtonSection} id="QA_Invite_Patient">
                        <Button variant="contained"
                            data-testid="invite-patient"
                            className={classes.invitePatientButton}
                            onClick={handleModal}
                        >
                            <Typography className={classes.invitebuttonContent} variant="button">
                                <SVGIcon trim={true} className={classes.icon1} svg={PlusCircle} size="scale450" />
                                <Typography className={classes.invitebuttonText}>
                                    <FormattedMessage id="Waiting_Start_Appointment" />
                                </Typography>
                            </Typography>
                        </Button>
                    </Box>

                    <ProviderFilter />

                    <Box className={classes.patientCard} id="QA_Waiting_patientCard">
                        {waitingRoom.participants?.map((item: any) => {
                            return (
                                <WaitingRoomCard
                                    key={item.visitReminderId}
                                    handleDropUser={handleDropUser}
                                    handleStartMeeting={handleStartMeeting}
                                    cardDetails={item}
                                />
                            )
                        })}
                    </Box>
                </div>
            </Box>
            <Box className={classes.waitingRoomCallSection}>
           { currentMeetingDetails&& <PermissionCheckErrorMessage  priority={'mic'} type="provider" />}
                {currentMeetingDetails && currentMeetingDetails.patientName && !isPatientLeft() && <div className={classes.twilioVideoHeader}>
                    <Typography className={classes.buttonText3}>
                        {currentMeetingDetails.patientName} {currentMeetingDetails.patientProfileInfo ?<span className={classes.additionalInfo}>({getAge(dateOfBirth)} {genderCode})</span>:<span className={classes.additionalInfo}>(<FormattedMessage id="Waiting_Room_Unscheduled" />)</span>}
                    </Typography>
                </div>}

                {currentMeetingDetails ?
                    <TwilioVideo
                        twilioIdentity={user.firstName}
                        userName={user.firstName}
                        roomId={currentMeetingDetails.twilioRoom}
                        patientIdentity={currentMeetingDetails.twilioUsername}
                        twilioVideoToken={currentMeetingDetails.providerTwilioVideoToken || ''}
                        roomCallback={handleRoomCallback}
                        onEndMeeting={() => handleEndCall(currentMeetingDetails)}
                        onLeaveMeeting={() => handleLeaveMeeting(currentMeetingDetails)}
                        peerName={currentMeetingDetails.patientName}
                        participantType={currentMeetingDetails.isMA ? 'MA' : 'PROVIDER'}
                        onParticipantUpdate={updateParticipants}
                        onPauseMeeting={() => handlePauseCall(currentMeetingDetails)}
                        timeoutHandler={handleTimeout}
                        showInstrcutions={showErrorInstruction}
                    />
                    :
                    <WaitingStatus data={waitingRoom}></WaitingStatus>

                }
            </Box>
        </Box>
    )
}

export default WaitingRoom