import createReducer from '../createReducer'
import {ChatState} from '../reducerTypes'
import {CONVERSATION_TYPE} from "constant";
import { ConversationGroupedMap, ConversationInfoItem } from 'store/chat-types';
import { getParticipantFullName } from 'components/messaging/utils';

const initialConversationGroups: ConversationGroupedMap = {
	[CONVERSATION_TYPE.PATIENT]: {
		datedConversations: [],
		priorityConversations: []
	},
	[CONVERSATION_TYPE.CARETEAM]: {
		datedConversations: [],
		priorityConversations: []
	}
}

const initialState: ChatState = {
	token: null,
	client: null,
	conversations: {},
	groupedConversations: initialConversationGroups,
	conversationsInfo: {},
	openConversationSid: "",
	loadedConversations: [],
	messages: [],
	messagePage: null,
	participantsTyping: {},
	conversationsFetched: false,
	searchedRecipients: [],
	searchedConversations: initialConversationGroups,
	searchText: "",
	searchLoading: false,
	recipients: [],
	filter: CONVERSATION_TYPE.PATIENT,
	pages: {
		[CONVERSATION_TYPE.PATIENT]: 1,
		[CONVERSATION_TYPE.CARETEAM]: 1
	}
}

export default createReducer(initialState, {
	'SET_TWILIO_TOKEN': (state: ChatState, payload: ChatState):ChatState => {
		return {
			...state,
			...payload
		}
	},
	'SET_CLIENT': (state: ChatState, payload: ChatState):ChatState => {
		return {
			...state,
			...payload
		}
	},
	'SET_CONVERSATION': (state: ChatState, payload: any):ChatState => {
		return {
			...state,
			conversations: {
				...state.conversations,
				[payload.conversation.sid]: payload.conversation
			},
			conversationsInfo: {
				...state.conversationsInfo,
				[payload.conversation.sid]: {
					...payload.enableData
				}
			}
		}
	},
	'SET_INITIAL_CONVERSATIONS': (state: ChatState, payload: any):ChatState => {
		return {
			...state,
			...payload,
			conversationsFetched: true
		}
	},
	'RESET_CONVERSATIONS': (state: ChatState):ChatState => {
		return {
			...state,
			conversations:{},
			groupedConversations: initialConversationGroups,
			searchedConversations: initialConversationGroups,
			conversationsInfo:{},
			conversationsFetched: false,
			loadedConversations:[],
			openConversationSid:''
		}
	},
	'SET_OPEN_CONVERSATION_SID': (state: ChatState, payload: ChatState):ChatState => {
		return {
			...state,
			...payload,
			messages: [],
			messagePage: null
		}
	},
	'SET_CONVERSATION_INFO': (state: ChatState, payload: { conversationSid: string, info: Partial<ConversationInfoItem> }): ChatState => {
		const {conversationSid, info} = payload
		return {
			...state,
			conversationsInfo: {
				...state.conversationsInfo,
				[conversationSid]: {
					...(state.conversationsInfo[conversationSid]!),
					...info
				}
			}
		}
	},
	"SET_MESSAGES": (state: ChatState, payload: ChatState): ChatState => {
		const {messagePage} = payload
		return {
			...state,
			messages: messagePage ? messagePage.items : [],
			messagePage: messagePage
		}
	},
	"ADD_MESSAGE": (state: ChatState, payload: any): ChatState => {
		const {message} = payload
		return {
			...state,
			messages: [...state.messages, message]
		}
	},
	"CLEAR_CHAT": (state: ChatState): ChatState => {
		return {
			...initialState,
			token: state.token
		}
	},
	"ADD_PREVIOUS_PAGE": (state: ChatState, payload: any): ChatState => {
		const {previousPage} = payload
		return {
			...state,
			messages: previousPage ? [...previousPage.items, ...state.messages] : state.messages,
			messagePage: previousPage
		}
	},
	"SET_CONVERSATION_LOADED": (state: ChatState, payload: any): ChatState => {
		return {
			...state,
			loadedConversations: [...state.loadedConversations, payload]
		}
	},
	"PARTICIPANT_STARTED_TYPING": (state: ChatState, payload: any): ChatState => {
		const {conversationSid, participant} = payload
		return {
            ...state,
            participantsTyping: {
                ...state.participantsTyping,
                [conversationSid]: {
                    ...state.participantsTyping[conversationSid],
                    [participant.identity]: getParticipantFullName(participant),
                },
            },
        };
	},
	"PARTICIPANT_ENDED_TYPING": (state: ChatState, payload: any): ChatState => {
		const {conversationSid, participant} = payload
		const {[participant.identity]: removed, ...participants} = state.participantsTyping[conversationSid] || {}
		return {
			...state,
			participantsTyping: {
				...state.participantsTyping,
				[conversationSid]: participants
			}
		}
	},
	"RECIPIENT_SEARCH_COMPLETE": (state: ChatState, payload: any): ChatState => {
		return {
			...state,
			...payload
		}
	},
	"SAVE_RECIPIENTS": (state: ChatState, payload: any): ChatState => {
		return {
			...state,
			...payload
		}
	},
	"SEARCHING_CONVERSATIONS": (state: ChatState): ChatState => {
		return {
			...state,
			searchLoading: true
		}
	},
	"SET_SEARCHED_CONVERSATIONS": (state: ChatState, payload: any): ChatState => {
		return {
			...state,
			...payload,
			searchLoading: false
		}
	},
	"CLEAR_SEARCHED_CONVERSATIONS": (state: ChatState): ChatState => {
		return {
			...state,
			searchedConversations: initialConversationGroups,
			searchText: "",
			searchLoading: false
		}
	},
	"SET_SEARCH_TEXT": (state: ChatState, payload: any): ChatState => {
		return {
			...state,
			...payload
		}
	},
	"SET_CONVERSATION_FILTER": (state: ChatState, payload: any): ChatState => {
		return {
			...state,
			...payload
		}
	},
	"LOAD_NEXT_PAGE": (state: ChatState): ChatState => {
		const {pages, filter} = state
		return {
			...state,
			pages: {
				...pages,
				[filter]: pages[filter]+1
			}
		}
	},
	"SET_UNREAD_MESSAGE_COUNTS": (state: ChatState, payload: any): ChatState => {
		return {
			...state,
			...payload
		}
	},
	"SET_GROUPED_CONVERSATIONS": (state: ChatState, payload: any): ChatState => {
		return {
			...state,
			...payload
		}
	}
})