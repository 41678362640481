import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { jsonFetch } from "store/api";
import { ContactUs } from "store/reducerTypes";
import { ValueOf } from "type-fest";
import { setError } from "./error";

//@ts-ignore
const { HUBSPOT_PORTAL_ID, HUBSPOT_CONTACT_US_FORM_ID } = window.env;

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILED = "CONTACT_US_FAILED";

const contactUsRequest = () =>
    ({
        type: CONTACT_US_REQUEST,
    } as const);

const contactUsSuccess = (message: string) =>
    ({
        type: CONTACT_US_SUCCESS,
        payload: message,
    } as const);

const contactUsFailed = (error: Error) =>
    ({
        type: CONTACT_US_FAILED,
        payload: error,
    } as const);

export type ActionTypes =
    | ReturnType<typeof contactUsRequest>
    | ReturnType<typeof contactUsSuccess>
    | ReturnType<typeof contactUsFailed>;

const fieldMapping = {
    firstName: "firstname",
    lastName: "lastname",
    email: "email",
    phone: "phone",
    customerType: "customer_type",
    message: "message",
} as const;

type ContactUsPayload = ContactUs & {
    consentToProcessMessage: string;
    consentToCommunicateMessage: string;
};

type ContactUsField = {
    name: ValueOf<typeof fieldMapping>;
    value: string;
};

const mapContactUsToFields = (
    contactUs: ContactUsPayload
): ContactUsField[] => {
    return Object.entries(fieldMapping).map(([modelName, hubSpotName]) => ({
        name: hubSpotName,
        value: contactUs[modelName as keyof ContactUs],
    }));
};

const createContactUsPayload = (contactUs: ContactUsPayload) => ({
    submittedAt: new Date().getTime().toString(),
    fields: mapContactUsToFields(contactUs),
    context: {
        pageUri: window.location.toString(),
    },
    legalConsentOptions: {
        consent: {
            consentToProcess: true,
            text: contactUs.consentToProcessMessage,
            communications: [
                {
                    value: true,
                    subscriptionTypeId: 16113718,
                    text: contactUs.consentToCommunicateMessage,
                },
            ],
        },
    },
});

export const sendContactUsForm = (contactUs: ContactUsPayload) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            dispatch(contactUsRequest());
            const response = await jsonFetch(
                `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_CONTACT_US_FORM_ID}`,
                {
                    method: "POST",
                    body: JSON.stringify(createContactUsPayload(contactUs)),
                    credentials: "omit",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            dispatch(contactUsSuccess(response.inlineMessage));
        } catch (e: any) {
            dispatch(setError("Error.message.contactUsFailed"));
            dispatch(contactUsFailed(e));
        }
    };
};
