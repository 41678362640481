import React from "react";
import {Box, List, ListItem} from "@mui/material";
import {map} from "underscore";
import {FormattedMessage} from "react-intl";
import {ReactComponent as ChevronUpSvg} from "icon-library/SVG/Chevron_Up.svg";
import Divider from "components/Divider/Divider";
import CustomTypography from "../CustomTypography/CustomTypography";
import {useStyles} from "./styles";
import {createSvgIcon} from "utils";
import {COLORS} from "theme";

const ChevronUp = createSvgIcon(ChevronUpSvg)

// DropdownSelector is used for custom dropdowns primarily in the Side Navigation. The 'options' prop is an array of
// components which will be rendered inside a list item.
const DropdownSelector = ({open, title, style={}, onClose, options}: any) => {
    const classes = useStyles({open})

    // Hide component when open is set to false
    if (!open) return null

    // renderOption renders a single component from the 'options' props. For all components but the first, a Divider line
    // will be rendered to visually separate the components
    const renderOption = (option: any, idx: number) => {
        return (
            <ListItem className={classes.listItem} key={idx} disableGutters>
                {idx ? <Divider disableMargin color={COLORS.LGRAY} /> : null}
                {option}
            </ListItem>
        )
    }

    return (
        <Box style={style} className={classes.root}>
            <Box className={classes.header} >
                <CustomTypography variant="titleSubheader">
                    <FormattedMessage id={title} />
                </CustomTypography>
                <ChevronUp fill={COLORS.DRAWER_ICON} className={classes.menuIcon} onClick={() => onClose(false)}/>
            </Box>
            <List>
                {map(options, renderOption)}
            </List>
        </Box>
    )
}

export default DropdownSelector