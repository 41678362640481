import { Box, Button, Paper, Typography } from "@mui/material";
import { call as adminCall } from "admin/store/api";
import { getAppCurrentProfileId, getAppIdToken } from "authentication/appAuth";
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as UserGroup } from "icon-library/SVG/User_Group.svg";
import { ReactComponent as Warning } from "icon-library/SVG/Warning_Circle.svg";
import { useEnabledPermissions } from "lib/security/permission-hooks";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import { createSvgIcon } from "utils";
import { getConfig } from "views/PEPHome/pep-config";
import { AhaFooter } from "./AhaFooter";
import { AhaHeader } from "./AhaHeader";
import { useStyles } from "./styles";

type PracticeType = {
    fileName: string,
    id: string,
    npi: string,
    organizationId: string,
    practiceId: string,
    profileRoleJoinId: string,
    reportType: string,
    status: string,
    firstName: string,
    middleName: string,
    lastName: string,
    prefix: string
}

const AhaHome = () => {
    document.title = 'Clinical Performance and Quality Reports - Brown & Toland Physicians';
    const { user } = useSelector((store: AppReduxStore) => store);
    const { currentProfileRoleJoin } = user;
    const hasAdminAccess = ((currentProfileRoleJoin?.level?.name === 'PRACTICE_ADMIN') || (currentProfileRoleJoin?.level?.name === 'STAFF_2'));
    const { enqueueError } = useUserNotification();
    const [reports, setReports] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const UserGroupIcon = createSvgIcon(UserGroup);
    const WarningIcon = createSvgIcon(Warning);
    const config = getConfig("bnt");
    const grantedPermissions = useEnabledPermissions();
    const licenseOptionName = "AHA_REPORT"
    const requiredPermissionsList = [licenseOptionName];
    const hasPermission = () => {
        return requiredPermissionsList.every((permission: any) => grantedPermissions.includes(permission));
    };
    const emailBodyString = (): string => {
        const providerProfile = currentProfileRoleJoin?.providerProfile;
        const practice = currentProfileRoleJoin?.practice;
        const middleName = providerProfile?.middleName ? ` ${providerProfile.middleName} ` : ` `;
        const fullName = `${providerProfile?.firstName}${middleName}${providerProfile?.lastName}`;
        const practiceName = practice?.name;
        const npi = providerProfile?.npi;
        const phone = currentProfileRoleJoin?.profilePrimaryPhone;
        const newLine = '%0d%0a';
        const fullNameStr = (providerProfile?.firstName && providerProfile?.lastName) ? `Full Name: ${fullName} ` : "";
        const practiceNameStr = practiceName ? `${newLine}Practice Name: ${practiceName} ` : "";
        const npiStr = npi ? `${newLine}NPI: ${npi} ` : "";
        const phoneStr = phone ? `${newLine}Best Contact Phone Number: ${phone}` : "";
        const bodyStrEmpty = (fullNameStr === "") && (practiceNameStr === "") && (npiStr === "") && (phoneStr === "");
        return (bodyStrEmpty ? "" : `&body=${fullNameStr}${practiceNameStr}${npiStr}${phoneStr}`);
    };

    const getAdminReports = useCallback(async () => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const endpointURL = "/admin/v1/practice/reports";

        if (practiceId) {
            const response = await adminCall("GET", endpointURL, {}, { "x-application-practiceid": practiceId })
                .catch(() => {
                    enqueueError("aha.report.get.listing.error");
                });
            if (response) {
                setReports(response);
            }
        }
    }, [currentProfileRoleJoin?.practice, enqueueError]);

    const getProviderReports = useCallback(async () => {
        const profileRoleJoinId = currentProfileRoleJoin?.id;
        const endpointURL = `/admin/v1/provider/reports?profileRoleJoinId=${profileRoleJoinId}`;
        if (profileRoleJoinId) {
            const response = await call("GET", endpointURL, {})
                .catch(() => {
                    enqueueError("aha.report.get.listing.error");
                });
            if (response) {
                setReports(response);
            }
        }
    }, [currentProfileRoleJoin?.id, enqueueError]);
 
    type ObjectType = {
        [key: string]: any
    }
    
    const fileExtension = (fileName: string): string => {
        if (!fileName) return "";
        return fileName.substring(fileName.lastIndexOf('.') + 1);
    }
    
    const shouldOpenInNewWindow = (fileName: string): boolean => {
        const extension: string = fileExtension(fileName);
        if (extension?.toLowerCase() === "pdf") {
            return true;
        }
        return false;
    }
    
    const isFSAccessSupported = 'showSaveFilePicker' in window &&
        (() => {
            try {
                return window.self === window.top;
            } catch {
                return false;
            }
        })();
    
    const getFileTypes = (fileName: string, contentType: string): ObjectType[] => {
        const extension: string = ("." + fileExtension(fileName));
        const description = (() => {
            switch (extension?.toLowerCase()) {
                case ".pdf":
                    return "PDF file";
                case ".csv":
                    return "Comma separated value file";
                case ".doc":
                case ".docx":
                    return "Microsoft Word file";
                case ".xls":
                    return "Microsoft Excel file";
                case ".xlsx":
                    return "Microsoft Excel Open XML spreadsheet file";
            }
        })();
    
        const fileType = {
            description: description,
            accept: {
                [contentType]: extension?.toLowerCase()
            }
        }
        return [fileType];
    }
    
    const downloadUsingFSAccess = async (fileName: string, blob: any, contentType: string) => {
        if (isFSAccessSupported) {
            try {
                const windowObj: ObjectType = window;
                const filePicker = windowObj["showSaveFilePicker"];
                const acceptObj: ObjectType = {};
                acceptObj[contentType] = [("." + fileExtension(fileName))];
    
                const handle = await filePicker({
                    suggestedName: fileName,
                    types: getFileTypes(fileName, contentType)
                });
                const writable = await handle.createWritable();
                await writable.write(blob);
                await writable.close();
                return;
            } catch (error: any) {
                if (error.name !== 'AbortError') {
                    return;
                }
            }
        }
    }

    /*
    type ahaObjectType = {
        [key: string]: any
    }

    const fileExtension = (fileName: string): string => {
        if (!fileName) return "";
        return fileName.substring(fileName.lastIndexOf('.') + 1);
    }
    
    const shouldOpenInNewWindow = (fileName: string): boolean => {
        const extension: string = fileExtension(fileName);
        if (extension?.toLowerCase() === "pdf") {
            return true;
        }
        return false;
    }
    
    const isFSAccessSupported = 'showSaveFilePicker' in window &&
        (() => {
            try {
                return window.self === window.top;
            } catch {
                return false;
            }
        })();
    
    const getFileTypes = (fileName: string, contentType: string): ahaObjectType[] => {
        const extension: string = ("." + fileExtension(fileName));
        const description = (() => {
            switch (extension?.toLowerCase()) {
                case ".pdf":
                    return "PDF file";
                case ".csv":
                    return "Comma separated value file";
                case ".doc":
                case ".docx":
                    return "Microsoft Word file";
                case ".xls":
                    return "Microsoft Excel file";
                case ".xlsx":
                    return "Microsoft Excel Open XML spreadsheet file";
            }
        })();
    
        const fileType = {
            description: description,
            accept: {
                [contentType]: extension?.toLowerCase()
            }
        }
        return [fileType];
    }
    
    const downloadUsingFSAccess = async (fileName: string, blob: any, contentType: string) => {
        if (isFSAccessSupported) {
            try {
                const windowObj: ahaObjectType = window;
                const filePicker = windowObj["showSaveFilePicker"];
                const acceptObj: ahaObjectType = {};
                acceptObj[contentType] = [("." + fileExtension(fileName))];
    
                const handle = await filePicker({
                    suggestedName: fileName,
                    types: getFileTypes(fileName, contentType)
                });
                const writable = await handle.createWritable();
                await writable.write(blob);
                await writable.close();
                return;
            } catch (error: any) {
                if (error.name !== 'AbortError') {
                    return;
                }
            }
        }
    }
*/
    const handleDownloadClick = async (index?: number) => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const reportsObj = reports.filter((report: any) => { return report.reportType === licenseOptionName });
        const fileName = reportsObj[(index ? index : 0)]?.fileName;
        const fileRequest = {
            "fileName": fileName,
            "practiceId": practiceId
        };
        const endpointURL = `/admin/v1/provider/aha/download`;

        const openInNewTab = shouldOpenInNewWindow(fileName);
        const axios = require('axios');
        const { ENABLE_API_URL } = (window as any).env
        const axiosURL = ENABLE_API_URL + "/api" + endpointURL;
        await axios.post(axiosURL, fileRequest,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getAppIdToken()
                }
            }).then(async (response: { headers: any, data: any; }) => {
                if (response?.data) {
                    const responseContentType = await (response?.headers["content-type"]);
                    const contentType: string = responseContentType.split(";")[0] || ("application/pdf");
                    const blobData = new Blob([response.data], { type: contentType });
                    const url = window.URL.createObjectURL(blobData);
                    if (openInNewTab)
                        window.open(url, '_blank', 'noopener,noreferrer');
                    else if (isFSAccessSupported) {
                            downloadUsingFSAccess(fileName, blobData, contentType);
                    } else {
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = fileName;
                        link.target = "_blank";
                        link.rel = "noopener noreferrer";
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                }
            })
            .catch((error: any) => {
                enqueueError("aha.report.download.error");
            });
    };

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("aha.report.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    useEffect(() => {
        if (!!currentProfileRoleJoin?.id) {
            if (hasAdminAccess)
                getAdminReports();
            else
                getProviderReports();
        }
    }, [currentProfileRoleJoin?.id, getAdminReports, getProviderReports, hasAdminAccess])

    useEffect(() => {
        const documentRoot = document.getElementById("root");
        if (documentRoot) {
            documentRoot.style.height = "85%";
            documentRoot.style.minWidth = "768px";
        }
    }, [])

    return (<div style={{ height: '100%' }}>
        <AhaHeader providerConfig={config} />
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="125px"
            width="100%"
            style={{ paddingLeft: 'calc(10% - 80px)', paddingRight: 'calc(10% - 80px)' }}
        >
            <Box
                justifyContent="left"
                height="120px"
                width="100%"
                style={{ background: '#6e1e80' }}
            >
                <Typography className={classes.bannerLine1}>
                    <FormattedMessage id={"aha.banner.line1"} />
                </Typography>
                <Typography className={classes.bannerLine2}>
                    <FormattedMessage id={"aha.banner.line2"} />
                </Typography>
            </Box>
        </Box>
        {(isLoading || (user.username === '')) ? <Spinner /> : hasPermission() ? <Box
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            height="calc(100% - 235px)"
            width="100%"
            style={{ paddingLeft: 'calc(10% - 60px)', paddingRight: 'calc(10% - 60px)', paddingTop: '15px', overflowY: 'auto' }}
        >
            <Typography variant="h3" className={classes.paragraphStyle}>
                <FormattedMessage id={"description.paragraph.1"} />
            </Typography>
            <b style={{ marginTop: '10px' }}>Medicare Annual Health Assessments (AHA)</b>
            <Typography variant="h3" className={classes.paragraphStyle}>
                <FormattedMessage id={"description.paragraph.2"} />
            </Typography>
            <b style={{ marginTop: '10px' }}>Clinical Quality Reports</b>
            <Typography variant="h3" className={classes.paragraphStyle}>
                <FormattedMessage id={"description.paragraph.3"} />
            </Typography>
            <Typography variant="h3" className={classes.paragraphStyle}>
                <FormattedMessage id={"description.paragraph.4"} />
            </Typography>
            <Typography variant="h3" className={classes.paragraphStyle}>
                <FormattedMessage id={"description.paragraph.5"} />
            </Typography>
            <Typography variant="h3" className={classes.paragraphStyle}>
                <FormattedMessage id={"description.paragraph.6"} />
                <a href={`mailto:support@btmg.com?subject=Provider%20Report%20Support%20Request${emailBodyString()}`} target="_top">support@btmg.com.</a>
            </Typography>
            {!hasAdminAccess ? (<>
                <br />
                <Paper onClick={() => { handleDownloadClick(); }} elevation={6} style={{ width: 'calc(800px - 10%)', border: '2px solid #6E1E80', borderRadius: '10px', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '20px', cursor: 'pointer' }}>
                    <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                    >
                        <UserGroupIcon className={classes.userGroupIcon} />
                        <Box p={1}>
                            <Typography className={classes.ahaButtonTitle}>
                                <FormattedMessage id={"aha.report.button.title"} />
                            </Typography>
                            <Typography className={classes.ahaButtonContent}>
                                <FormattedMessage id={"aha.report.button.content"} />
                            </Typography>
                        </Box>
                    </Box>
                </Paper></>) : (<>
                    <Typography variant="h3" className={classes.paragraphStyleBold}>
                        <FormattedMessage id={"description.paragraph.7"} />
                    </Typography>
                    <br />
                    <div className={classes.tableContainer}>
                        <table className={classes.tableMain}>
                            <colgroup>
                                <col style={{ width: '400px' }}></col>
                                <col style={{ width: '150px' }}></col>
                            </colgroup>
                            <thead>
                                <tr className={classes.tableHeaderRow}>
                                    <th className={classes.tableHeaderRowLeft} align="left">Primary Care Physician</th>
                                    <th className={classes.tableHeaderRowRight} align="left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(reports.length > 0) ? reports.map((report: PracticeType, index: number) => {
                                    return (
                                        <tr className={classes.tableBodyRow}>
                                            <td style={{ paddingLeft: '10px', fontWeight: '700', fontSize: '14px' }}>{
                                                `${(report.prefix ? `${report.prefix} ` : ``) + report.firstName + (report.middleName ? ` report.middleName ` : ` `) + report.lastName}`
                                            }</td>
                                            <td>
                                                <Button
                                                    className={classes.tableDownloadButton}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleDownloadClick(index);
                                                    }}>
                                                    <FormattedMessage id={"aha.report.download.button.label"} />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }) : <tr className={classes.tableBodyRow}><td colSpan={2} style={{ paddingLeft: '10px' }} ><FormattedMessage id={"aha.report.download.no.reports"} /></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </>)}
            <br />
        </Box> :
            <Box
                flexGrow={1}
                justifyContent="center"
                alignItems="center"
                height="calc(100% - 235px)"
                width="100%"
                style={{ paddingLeft: 'calc(10% - 60px)', paddingRight: 'calc(10% - 60px)', paddingTop: '15px' }}
            >
                <br />
                <Paper elevation={6} style={{ minWidth: '734.4px', width: '56%', display: 'block', marginLeft: 'auto', marginRight: 'auto', border: '2px solid red', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
                    <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                    >
                        <WarningIcon className={classes.warningIcon} />
                        <Box p={1}>
                            <Typography className={classes.errorButtonTitle}>
                                <FormattedMessage id={"error.description.title"} />
                            </Typography>
                            <Typography className={classes.ahaButtonContent}>
                                <FormattedMessage id={"error.description.details.1"} />
                                <a href={`mailto:support@btmg.com?subject=Provider%20Report%20Support%20Request${emailBodyString()}`} target="_top">support@btmg.com.</a>
                            </Typography>
                            <Typography className={classes.ahaButtonContentLine2}>
                                <FormattedMessage id={"error.description.details.2"} />
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
                <br />
            </Box>
        }
        <AhaFooter providerConfig={config} />
    </div>);
}

export default AhaHome;