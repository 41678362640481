import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Typography
} from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import EditUserForm from "./EditUserForm";
import { useStyles } from "./styles";
const EditUser = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { admin } = useSelector((state: AppReduxStore) => state)
    const editUserFormRef = useRef<any>()
    const [isUnsaved, setUnsaved] = useState(false);
    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose()
    };

    const handleSaveUser = (event: any) => {

        const result = editUserFormRef.current.submitForm(event);
        if (result) {
            setTimeout(() => {
                handleClose();
            }, 1000)

        }
    };
    const onAddFormChanged = (dataChanged: boolean) => {
        setUnsaved(dataChanged)
    }
    return (
        <Modal onClose={onClose} id={MODAL_TYPES.EDIT_USER}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"EditUser.Title"} />
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {admin.sUser && `${admin.sUser.firstName} ${admin.sUser.lastName}`}
                    </Typography>
                </Box>
                <Box>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined" onClick={onClose}>
                            <FormattedMessage id={"EditUser.CancelButtonText"} />
                        </Button>
                        <Button id="btnSaveUser" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveUser}
                        disabled={!isUnsaved}>
                            <FormattedMessage id={"EditUser.SaveButtonText"} />
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <EditUserForm ref={editUserFormRef} onFormValueChanged={onAddFormChanged}/>
            </DialogContent>
        </Modal>
    )
}

export default EditUser