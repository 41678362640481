import { InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, Theme, ThemeProvider } from "@mui/material/styles";
import 'App.css';
import AdminApp from "admin/AdminApp";
import adminTheme from 'admin/theme';
import { NotFound } from "common/views";
import EvisitInviteMessageView from "components/Evisit/EvisitInviteMessage/EvisitInviteMessage";
import { addMessageViewProvider } from "components/messaging/message-view-registry";
import { ConnectedRouter } from 'connected-react-router';
import i18n from 'i18n';
import { getSecurityService } from "lib/service-manager";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IntlProvider, useIntl } from 'react-intl';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import configureStore, { history } from 'store';
import providerTheme from 'theme';
import { IsJsonString } from "utils";
import { PEPHome, PEPNewUserRequest } from "views";
import SnackbarContent from "./admin/components/SnackBars/SnackbarContent";
import { SecureRoute } from "./authentication/app-secure-route";
import { AzureProfileSelector } from "./authentication/azure/ProfileSelector/azure-profile-selector";
import { getToken } from "./authentication/azure/azure-auth-utils";
import { AzureLogin } from "./authentication/azure/azure-login";

window.Buffer = window.Buffer || require("buffer").Buffer;

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

const store = configureStore((window as any).__INITIAL_STATE__);
/* Initialize modules and libraries
 * This is a temporary place to initialize different modules and their bindings
 */
const initializeApplication = () => {
    // LIB / Security Service
    getSecurityService().initialize();
    // MODULE / EVisit
    addMessageViewProvider({
        id: "evisit",
        applyCheckerFunction: (message, conversationInfo) =>
            conversationInfo.conversationType === "EVISIT" && message.body !== null && IsJsonString(message.body),
        view: EvisitInviteMessageView,
    });
}
const destroyApplication = () => { }

const OpenRoutes = () => {
    return (
        <Switch>
            <Route path="/login" component={AzureLogin} />
            <Route path="/newuser" component={PEPNewUserRequest} />
            <SecureRoute path="/sprofile" component={AzureLogin} />
            <SecureRoute path="/pep/:providerName" component={AzureLogin} />
            <SecureRoute path="/admin" component={AzureLogin} />
            <Route path="/" exact>
                <Redirect to="/login" />
            </Route>
            <Route component={NotFound} />
        </Switch>
    )
}

const Router = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const { errorText, errorDesc, messageId, errorCount, errorType } = useSelector((store: any) => store.error);

    // handle error messaging ui
    useEffect(() => {
        if (errorCount) {
            const errorMessage = errorText || intl.formatMessage({ id: messageId });
            let errorMessage2 = errorDesc || null;
            //errorDesc can be either passed as e?.message from the server, or a local strings.json call
            if (errorMessage2 && errorMessage2.includes("error.desc")) {
                errorMessage2 = intl.formatMessage({ id: errorMessage2 });
            }
            const snackbarType = errorType || 'error';
            console.log(errorMessage);
            enqueueSnackbar(null, {
                preventDuplicate: true, content: (key) => (
                    <div role="alert">
                        <SnackbarContent
                            snackbarKey={key}
                            type={snackbarType}
                            message={errorMessage} desc={errorMessage2}
                        />
                    </div>
                ),
                autoHideDuration: (snackbarType === 'error') ? null : 10000
            })
        }
    }, [enqueueSnackbar, intl, dispatch, errorText, messageId, errorCount, errorDesc, errorType]);

    /*
    const restoreOriginalUri = useCallback(async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    }, [history]);
    */
    return (
        <Switch>
            <Route path="/login" component={AzureLogin} />
            { /* <Route path="/registeredProfiles/:practiceId" component={RegisteredProfiles} />  {/* //TODO need to remove Demo purpose */}
            { /* <Route path="/register" component={Registration} /> */ }
            <Route path="/newuser" component={PEPNewUserRequest} />
            { /* <Route path="/forgotPassword" component={ResetPassword} /> */ }
            { /* <Route path="/patient/register" component={PatientRegistration} /> */ }
            { /* <Route path="/provider/register" component={ProviderRegistration} /> */ }
            { /* <Route path="/verify-profile" component={VerifyAdditionalProfile} /> */ }
            { /* <Route path="/patient/download-app" component={DownloadApp} /> */ }
            { /* <Route path="/wr/:id" component={PNGPatientDetails} /> */ }
            { /* <Route path="/:providerId/wr/:id" component={PNGPatientDetails} /> */ }
            { /* <Route path="/wr-status" component={PNGMessages} /> */ }
            { /* <Route path="/patient" component={PatientLogin} /> */ }
            { /* <Route path="/wr" component={PNGMobileWaitingRoom} /> */ }
            <SecureRoute path="/pep/:providerName" component={PEPHome} />
            { /* <SecureRoute path="/provider/aha" component={AhaHome} /> */ }
            { /* <SecureRoute path="/patient/verify/:regToken" exact={true} component={VerifyInfo} /> */ }
            { /* <SecureRoute path="/patient/verify/:regToken/feedback" exact={true} component={Feedback} /> */ }
            <SecureRoute path="/admin" component={AdminApp} />
            <SecureRoute path="/sprofile" component={AzureProfileSelector} />
            { /* <SecureRoute path="/provider" component={ProviderRoutes} /> */ }
            { /* <SecureRoute path="/ahareport" component={AhaReport} /> */ }
            {/* redirect to profile selector if route unknown for now */}
            <Route path="/" exact>
                <Redirect to="/sprofile" />
            </Route>
            <Route component={NotFound} />
        </Switch>
    )
}

// App Handles various Providers and overall routing
const AzureApp = () => {

    const { instance, accounts, inProgress } = useMsal();

    // TODO: remove isProviderSite and resolve the discrepancies between theme files and snackbar
    const [isProviderSite, setIsProviderSite] = useState(window.location.pathname.startsWith("/provider"));
    const snackbarProps: any = !isProviderSite ? { vertical: 'top', horizontal: 'right' } : { vertical: 'bottom', horizontal: 'left' };

    history.listen((location: any) => {
        setIsProviderSite(location.pathname.startsWith("/provider"));
    });

    useEffect(() => {
        initializeApplication();
        return () => {
            destroyApplication();
        }
    }, []);

    useEffect(() => {
        if (instance.getActiveAccount() && accounts.length > 0 && inProgress === InteractionStatus.None) {
            getToken().then((response) => {
                if (response) {
/*
                    const { idToken, accessToken, account } = response;
                    if (account) {
                        const claims = account?.idTokenClaims;
                        const extensions = {
                            allUserLevels: claims?.['extension_current_levels'],
                            currentPracticeId: claims?.['extension_current_practice_id'],
                            allProducts: claims?.['extension_current_products'],
                            currentProfileId: claims?.['extension_current_profile_id'],
                            currentProfileRoleJoinId: claims?.['extension_current_profilerolejoin_id'],
                            currentUserType: claims?.['extension_current_user_type']
                        }
                        const auth = {
                            idToken: idToken,
                            accessToken: accessToken,
                            extensions: extensions,
                            account: account
                        };
                    }
*/                    
                }
            })
        }
    }, [instance, accounts, inProgress]);

    //TODO: resolve typing issue with i18n.en
    return (<div className='App'>
        <AuthenticatedTemplate>
            <Provider store={store}>
                <IntlProvider locale="en" messages={i18n.en}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={isProviderSite ? providerTheme : adminTheme}>
                            <SnackbarProvider maxSnack={4} anchorOrigin={snackbarProps}>
                                <ConnectedRouter history={history}>
                                    <CssBaseline />
                                    <Router />
                                </ConnectedRouter>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </IntlProvider>
            </Provider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Provider store={store}>
                <IntlProvider locale="en" messages={i18n.en}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={isProviderSite ? providerTheme : adminTheme}>
                            <SnackbarProvider maxSnack={4} anchorOrigin={snackbarProps}>
                                <ConnectedRouter history={history}>
                                    <CssBaseline />
                                    <OpenRoutes />
                                </ConnectedRouter>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </IntlProvider>
            </Provider>
        </UnauthenticatedTemplate>
    </div>
    );
}

export default AzureApp;
