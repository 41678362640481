import React from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./styles"

export default function DiscoverCards({ title = '', themeColor = '', Image = '', incBackColor = '', incColor, points = '' }: any) {
    const classes = useStyles({ themeColor, incColor, incBackColor })
    return (
        <div>
            <div className={classes.contentImg}>
                <img className={classes.sliderImage} alt="" src={Image}></img>
            </div>
            <div className={classes.contentList}>
                <Typography className={classes.title1} >
                    {title}
                </Typography>
                {
                    points?.map((item:any)=>{
                        return(
                            <ul>
                                <li>
                                    <div className={classes.listContent}>
                                        <Typography className={classes.listTitle}>{item.pointTitle} - <span className={classes.descript}>{item.desc}</span> </Typography>
                                    </div>
                                    </li>
                            </ul>
                        )
                    })
                }
            </div>
        </div>
    )
}