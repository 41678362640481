import React, {  useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import Popover from "components/Popover/Popover";
import { Box, Button, Typography, Paper, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Chat, VideoCallStart } from "assets";
import { COLORS } from "theme";
import { useDispatch } from "react-redux";
import { startEvistMeeting,joinEvistAgain,pushSystemMessage } from "store/actions/meeting";
import Input from "common/components/Input/Input";
import { useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";

const EvisitActionButton = ({ status,patientTwilioId }: { status: string, patientTwilioId: string }) => {
  const isEvistAlreadyStart = status==='ZOOMCALL'
  const classes = useStyles(isEvistAlreadyStart);
  const intl = useIntl();
  const dispatch = useDispatch()
  const handleStartEvisit = () => {
      if(status==='WAITINGROOM'){
        dispatch(startEvistMeeting(patientTwilioId))
      }else if(status==='ZOOMCALL'){
        dispatch(joinEvistAgain(patientTwilioId))
      }
  }
  const [message, updateMessage] = useState('')
  const [sendAttempted, setSendAttempted] = useState(false)
  const { user } = useSelector((state: AppReduxStore) => state)
  const { firstName='',lastName='' } = user

  const onChange = (onValueChange: any) => {
    return ({ target }: any) => {
      onValueChange(target.value)
      if (sendAttempted) {
        setSendAttempted(false)
      }
    }
  }



  const messageError = message.trim().length === 0

  const onSubmit = (handleClose:any) => {
    if (!messageError) {
      dispatch(pushSystemMessage(patientTwilioId, "", "SYS_EVISIT_QUICK_MESSAGE",user,null,null,{ message, from_name:`${firstName || ''} ${lastName || ''}`  }))
      handleClosePopup(handleClose)
    } else {
      setSendAttempted(true)
    }
  }

  const handleClosePopup = (handleClose:any)=>{
    handleClose()
    setSendAttempted(false);
    updateMessage('')
  }




return (
  <div className={classes.wrapper}>
    {!isEvistAlreadyStart && <Popover
      renderElement={(handleClick, handleClose,open) => (
        <Button color="primary" className={classes.button} onClick={e => handleClick(e.currentTarget)} startIcon={<Chat className={open? classes.chatIconOpen :classes.chatIcon } />}>
          <Typography variant="h6">
            <FormattedMessage id="EvisitActionButton.quickMessage" />
          </Typography>
        </Button>
      )}
      renderContent={(handleClose) => (
        <Paper className={classes.root} classes={{ root: classes.paper }}>
          <Box display="flex" flexDirection="column" p={1}>
            <Typography className="title" variant="h6" component="div">
              <FormattedMessage id={"EvisitActionButton.title"} />
            </Typography>
            <Input
              errorId={sendAttempted && messageError ? "EvisitActionButton.enterMessage" : ""}
              value={message}
              onChange={onChange(updateMessage)}
              placeholder={intl.formatMessage({ id: "EvisitActionButton.shortMessage" })}
            />
            <Typography className="content" component="div">
              <FormattedMessage id={"EvisitActionButton.content"} />
            </Typography>
            <div className="send-container">
              <Button variant="contained" color="primary" type="submit" className={classes.btnSendInstruction} onClick={() => { onSubmit(handleClose) }}>
                <FormattedMessage id={"EvisitActionButton.send"} />
              </Button>
            </div> 
          </Box>
          <IconButton className="closeIcon" onClick={e=>handleClosePopup(handleClose)} size="large">
            <CancelIcon style={{ color: COLORS.NOTIFICATION_TEXT }} />
          </IconButton>
        </Paper>
      )}
    /> }
    <Button
      color="primary"
      onClick={handleStartEvisit}
      className={`${classes.button} ${isEvistAlreadyStart?'started':''}`}
      startIcon={<VideoCallStart className={classes.startButton}
      />}>
      <Typography variant="h6" className={classes.startEvent}  >
        <FormattedMessage id={!isEvistAlreadyStart?"EvisitActionButton.startEvent":'EvisitActionButton.joinEvist'} />
      </Typography>
    </Button>
  </div>
);
}

export default EvisitActionButton;
