import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Button, DialogContent, FormControlLabel, TextField, Checkbox } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { MODAL_TYPES } from "constant";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import Modal from "components/Modal/Modal";
import withStyles from "@mui/styles/withStyles";
import { useStyles } from "./styles";
import MultiSelectItem from "components/MultiSelectPopoverInput/MultiSelectItem";
import MultiSelectPopoverInput from "components/MultiSelectPopoverInput/MultiSelectPopoverInput";
import { debounce } from "underscore";
import { PATIENT_SEARCH } from "views/People/constants";
import { setActiveModal, searchPatientBySearchString, searchRecipientsBySearchString } from "store/actions";
import DatePicker from "common/components/DatePicker/DatePicker";
import moment from "moment";
import { AppReduxStore } from "store/reducerTypes";
import { useForm } from "common/components/FormValidator/FormValidator";
import InputMask from "common/components/InputMask/InputMask";
import { COLORS } from "theme";
import ErrorMessage from "common/components/ErrorMessage/ErrorMessage";
import { invitePNGPatient } from "store/actions";
import { useSnackbar } from "notistack";
import { useReduxDispatch } from "store/utils";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import { addMinuteToCurrentDate } from "utils";
import 'moment-timezone';
const PhoneMask = (props: any) => {
    return <InputMask {...props} mask={['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholder='(###)###-####' />
}

const CustomCheckbox = withStyles({
    root: {
        color: COLORS.LBLUE,
        '&$checked': {
            color: COLORS.LBLUE,
        },
    },
    checked: {},
})((props: any) => <Checkbox color="secondary" {...props} />);


const PNGInvite: React.FC<any> = () => {
    const dispatch = useDispatch()
    const customDispatch = useReduxDispatch()
    const intl = useIntl()
    const [searchedPatientRecipients, updateSearchedPatientRecipients] = useState([])
    const [searchedProvider, updateSearchedProvider] = useState([])
    const { username,...userDetails } = useSelector((state: AppReduxStore) => state.user)
    const [searchTextPatient, setSearchTextPatient] = useState("")
    const [searchTextProvider, setSearchTextProvider] = useState("")
    const [pinnedItemsPatient, setPinnedItemsPatient] = useState<any>(null)
    const [pinnedItemsProvider, setPinnedItemsProvider] = useState<any>(null)
    const [email, updateEmail] = useState<string>('')
    const [phone, updatePhone] = useState<string>('')
    const [option, updateOptions] = useState<Array<string>>([])
    const [searchLoader, updateSearchLoader] = useState<Boolean>(false)
    const [commonLoader, updateCommonLoader] = useState<Boolean>(false)
    const [currentProvider, setcurrentProvider] = useState<Boolean>(false)
    const [date, setDate] = useState(addMinuteToCurrentDate(5))
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles({ currentProvider })
    const {activeModal} = useSelector((store: AppReduxStore) => store.modal)
    const isOpen = activeModal === MODAL_TYPES.PROVIDER.PNG_INVITE;

    const providerName = currentProvider ? userDetails?.lastName : pinnedItemsProvider?.lastName;
    const patientName = `${pinnedItemsPatient?.firstName || ""} ${pinnedItemsPatient?.lastName || ""}`;

    const {
        validateAll,
        renderMessage,
        resetForm
    } = useForm()

    const searchPatient = useMemo(() => debounce((searchStr: string) => {
        if (searchStr.length >= 2) {
            updateSearchLoader(true)
            searchPatientBySearchString(searchStr, PATIENT_SEARCH.MESSAGES).then((data: any) => {
                updateSearchLoader(false)
                updateSearchedPatientRecipients(data)
            })
        }
    }, 250), [])

    const searchProvider = useMemo(() => debounce((searchStr: string) => {
        if (searchStr.length >= 2) {
            updateSearchLoader(true)
            searchRecipientsBySearchString(searchStr, username, 'CARETEAM').then((data: any) => {
                updateSearchLoader(false)
                updateSearchedProvider(data)
            })
        }
    }, 250), [username])

    useEffect(()=>{
        isOpen&& setDate(addMinuteToCurrentDate(5))
    },[isOpen,setDate])

    useEffect(() => {
        searchPatient(encodeURIComponent(searchTextPatient.trim()))
    }, [searchPatient, searchTextPatient])

    useEffect(() => {
        searchProvider(encodeURIComponent(searchTextProvider.trim()))
    }, [searchProvider, searchTextProvider])

    useEffect(() => {
        if (pinnedItemsPatient) {
            const { emailAddress = "", cellPhoneNumber = "" } = pinnedItemsPatient || {
                emailAddress: "",
                cellPhoneNumber: "",
            };
            updatePhone(cellPhoneNumber)
            updateEmail(emailAddress)
        } else {
            updateEmail('')
            updatePhone('')
        }
    }, [pinnedItemsPatient])

    useEffect(() => {
        resetForm()
    }, [option,currentProvider])

    const clearFields = () => {
        updateSearchedPatientRecipients([])
        updateSearchedProvider([])
        setSearchTextPatient('')
        setSearchTextProvider('')
        setPinnedItemsPatient(null)
        setPinnedItemsProvider(null)
        updateEmail('')
        updatePhone('')
        updateOptions([])
        updateSearchLoader(false)
        updateCommonLoader(false)
        resetForm(true)
        setcurrentProvider(false)
    }

    const onClose = () => {
        dispatch(setActiveModal(''))
        clearFields()
    }


    const renderItem = (item: any, changePinnedItems: any, pinnedItemsDetails: any, isMultiSelect: boolean, searchString: string) => {
        const selected = !!pinnedItemsDetails && (pinnedItemsDetails.id ? pinnedItemsDetails.id === item.id : pinnedItemsDetails.profileId === item.profileId)
        return (
            <MultiSelectItem
                item={item}
                selected={selected}
                key={item.id || item.profileId}
                onChange={() => changePinnedItems(item)}
                highlightText={searchString}
                themeColor="compose"
            />
        )
    }


    const onChange = (event: any, onValueChange: any) => {
        onValueChange(event.target.value)
    }

    const handleSubmit = () => {
        if (validateAll()) {
            updateCommonLoader(true)
            const providerId = currentProvider ? userDetails.currentProfileId : pinnedItemsProvider?.profileId;
            const patientId = pinnedItemsPatient?.id;
            const startTime = date;
            
            const zone = moment.tz.guess();
            const timezone = moment.tz(zone).format("z");

            const payload = {
                providerId,
                patientId,
                mobileNumber: phone,
                emailAddress: email,
                providerName: providerName || '',
                patientName: patientName,
                optedSMS: option.includes('SMS'),
                optedEmail: option.includes('Email'),
                timezone,
                startDateTime: startTime
            }

            customDispatch(invitePNGPatient(payload)).then(() => {
                updateCommonLoader(false)
                enqueueSnackbar(intl.formatMessage({
                    id: "PNGInvite.successMessage",
                }), {
                    variant: "success",
                })
                onClose()
            }).catch(() => updateCommonLoader(false))
        }

    }

    const handleCheckbox = (event: any) => {

        const {
            target: { name, checked },
        } = event;

        const temp = option.slice(0);

        if (checked) {
            temp.push(name)
        } else {
            const index = temp.indexOf(name);

            index !== -1 && temp.splice(index, 1)
        }

        updateOptions(temp)

    }

    const handleCurrentProviderChanges = (event: any) =>{
        const {
            target: { checked },
        } = event;
        setcurrentProvider(checked)
        setPinnedItemsProvider(null)
    }
    

    const isSMSSelected = option.includes("SMS");

    const isEmailSelected = option.includes("Email");

    const phoneError = isSMSSelected && renderMessage("Phone number", "required|phoneNumber", phone);

    const emailError = isEmailSelected && renderMessage("email", "required|email", email);

    const checkboxError = renderMessage("send option", "validateEmpty", option);

    const patientInput = renderMessage("patient", "required", pinnedItemsPatient);

    const providerInput = !currentProvider && renderMessage("provider", "required", pinnedItemsProvider);

    const dateInput = renderMessage("date", "futureDateAndTime", date);

    return (
        <Modal modalWidth="660px" id={MODAL_TYPES.PROVIDER.PNG_INVITE}>
            {commonLoader && <ComponentLoader />}
            <Box data-testid="invite-patient-modal-wrapper" className={classes.headerWrapper}>
                <div className={classes.titleWrapper}>
                    <div>
                        <CustomTypography variant="modalHeader">
                            <FormattedMessage id="PNGInvite.title" />
                        </CustomTypography>
                        <Typography variant="body1" className={classes.desc}>
                            <FormattedMessage id="PNGInvite.desc" />
                        </Typography>
                    </div>
                    <Close className={classes.close} onClick={onClose} />
                </div>
                <Button variant="contained"
                    onClick={handleSubmit}
                    data-testid="sendPNGInvite"
                    color="primary" className={classes.send}>
                    <Typography variant="button">
                        <FormattedMessage id="PNGInvite.sendInvite" />
                    </Typography>
                </Button>
            </Box>
            <DialogContent dividers className={classes.contentWrapper}>
                <div className={classes.contentChild}>
                    <Typography variant="h6" className={classes.patientTitle}>
                        <FormattedMessage id="PNGInvite.findPatient" />
                    </Typography>
                    <div className={`${classes.inputWrapper} ${classes.searchIcon}`}>
                        <MultiSelectPopoverInput
                            data={searchedPatientRecipients}
                            value={searchTextPatient}
                            onChange={(event: any) => onChange(event, setSearchTextPatient)}
                            qaid="QA_includePatientDetails"
                            placeholder={intl.formatMessage({
                                id: `PNGInvite.searchPatient`,
                            })}
                            title={intl.formatMessage({
                                id: `ComposeNewMessage.patient.input.addRecipients`,
                            })}
                            pinnedItems={pinnedItemsPatient}
                            setPinnedItems={setPinnedItemsPatient}
                            renderItem={renderItem}
                            loader={searchLoader}
                            isMultiSelect={false}
                            themeColor="compose"
                            renderPinnedComponent={false}
                        />
                        <ErrorMessage message={patientInput} />
                    </div>
                </div>
                <div className={classes.divider} />
                <div className={classes.contentChild}>
                    <Typography variant="h6" >
                        <FormattedMessage id="PNGInvite.whereToSend" />
                    </Typography>

                    <div className={classes.inputWrapper}>
                        <div className={classes.checkBoxWrapper}>
                            <FormControlLabel
                                value="SMS"
                                control={<CustomCheckbox
                                    name="SMS"
                                    checked={isSMSSelected}
                                    onChange={handleCheckbox}
                                    color="primary"
                                    data-testid="sms-checkbox"
                                />}
                                label="SMS"
                            />
                            <div className="checkboxInput">
                                <TextField
                                    variant="outlined"
                                    label=""
                                    type="text"
                                    value={phone}
                                    disabled={!isSMSSelected}
                                    onChange={(event: any) => onChange(event, updatePhone)}
                                    className={classes.inputElement}
                                    InputProps={{
                                        inputComponent: PhoneMask,
                                    }}
                                />
                                <ErrorMessage message={phoneError} />
                            </div>
                        </div>
                        <div className={classes.checkBoxWrapper}>
                            <FormControlLabel
                                value="Email"
                                control={<CustomCheckbox
                                    name="Email"
                                    checked={isEmailSelected}
                                    onChange={handleCheckbox}
                                    color="primary"
                                    data-testid="email-checkbox"
                                />}
                                label="Email"
                            />
                            <div className="checkboxInput">
                                <TextField
                                    variant="outlined"
                                    label=""
                                    disabled={!isEmailSelected}
                                    type="text"
                                    placeholder="Email"
                                    onChange={(event: any) => onChange(event, updateEmail)}
                                    value={email}
                                    className={classes.inputElement}
                                />
                                <ErrorMessage message={emailError} />
                            </div>
                        </div>
                        <ErrorMessage message={checkboxError} />
                    </div>

                </div>
                <div className={classes.contentChild}>
                    <div>
                        <FormControlLabel
                            value="current_provider"
                            control={<CustomCheckbox
                                name="Current_provider"
                                checked={currentProvider}
                                onChange={handleCurrentProviderChanges}
                                color="primary"
                                data-testid="current-provider-checkbox"
                            />}
                            className={classes.providerCheckbox}
                            label={intl.formatMessage({
                                id: `PNGInvite.label.withMe`,
                            })}
                        />
                        <Typography variant="h6" className={classes.providerSelectTitle}>
                            <FormattedMessage id="PNGInvite.customProvider" />
                        </Typography>
                        <div className={`${classes.inputWrapper} ${classes.searchIcon} ${classes.providerSearch}`}>
                            <MultiSelectPopoverInput
                                data={searchedProvider}
                                value={searchTextProvider}
                                onChange={(event: any) => onChange(event, setSearchTextProvider)}
                                qaid="QA_includePatientDetails"
                                placeholder={intl.formatMessage({
                                    id: `PNGInvite.searchProvider`,
                                })}
                                title={intl.formatMessage({
                                    id: `PNGInvite.selectProvider`,
                                })}
                                pinnedItems={pinnedItemsProvider}
                                setPinnedItems={setPinnedItemsProvider}
                                renderItem={renderItem}
                                loader={searchLoader}
                                isMultiSelect={false}
                                themeColor="compose"
                                renderPinnedComponent={false}
                            />
                            <ErrorMessage message={providerInput} />
                        </div>
                    </div>

                    <Typography variant="h6" className={classes.dateSelectTitle}>
                        <FormattedMessage id="PNGInvite.dateLabel" />
                    </Typography>
                    <div className={classes.inputWrapper}>
                        <DatePicker
                            placeholderText="select date"
                            selected={date}
                            onChange={(date) => setDate(date)}
                            minDate={moment().toDate()}
                        />
                        <ErrorMessage message={dateInput} />
                    </div>

                </div>
            </DialogContent>
        </Modal>
    )
};

export default PNGInvite