import React from "react";
import Modal from "../../Modal";
import {MODAL_TYPES, USER_PROFILE, USER_TYPE} from "constant";
import {Box, DialogContent, DialogTitle, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {useStyles} from "./styles";
import {outgoingCall, setActiveModal} from "store/actions";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "store/reducerTypes";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import {getParticipantIcon, getParticipantName} from "utils";
import {COLORS, ColorValue} from "theme";

// AdhocCallParticipants shows a list of participants in a conversation that the user can initiate an adhoc call with
const AdhocCallParticipants = () => {
    const {openConversationSid, conversationsInfo} = useSelector((state: AppReduxStore) => state.chat)
    const { twilioUsername } = useSelector((state: AppReduxStore) => state.user)
    const info = conversationsInfo[openConversationSid]
    const dispatch = useDispatch()
    const classes = useStyles()

    // Filter out the current user as they cannot call themselves
    const participants = info?.participants ? info.participants.filter(p => p.identity !== twilioUsername&&p.isEnabled) : [];

    // ParticipantRow renders a single row in the list showing the participants name and type
    const ParticipantRow = ({participant}: any) => {
        const onRowClick = () => {
            dispatch(setActiveModal(""))
            let patientProfile = null
            if (participant.userType === USER_TYPE.PATIENT) {
                patientProfile = participant[USER_PROFILE[participant.userType]]
            }
            dispatch(outgoingCall(participant.identity, getParticipantName(participant), 'adhoc', patientProfile))

        }
        const Icon = getParticipantIcon(participant)

        let iconColor: ColorValue = COLORS.USER_LIST_ICON
        let textColor: ColorValue = COLORS.LIST_ITEM_TEXT
        if (participant.userType === USER_TYPE.PATIENT) {
            iconColor = COLORS.PATIENT_LIST_ICON
            textColor = COLORS.LIST_ITEM_TEXT_BLUE
        }

        return (
            <Box className={classes.participantRow} onClick={onRowClick}>
                <Icon color={iconColor} opacity="0.4" className={classes.userIcon} />
                <Box style={{color: textColor}}>
                    <Typography>
                        {getParticipantName(participant)}
                    </Typography>
                    <Typography variant="subtitle1">
                        {participant.userType}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Modal classes={{paper: classes.modal}} id={MODAL_TYPES.PROVIDER.EVISIT_PARTICIPANTS}>
            <DialogTitle>
                <Box className={classes.flexWrapper}>
                    <CustomTypography variant="modalHeader">
                        <FormattedMessage id="AdhocCallParticipants.title" values={{count: participants.length}}/>
                    </CustomTypography>
                    <Close className={classes.close} onClick={() => dispatch(setActiveModal(""))} />
                </Box>
            </DialogTitle>
            <DialogContent>
                {participants.map(p => <ParticipantRow key={p.identity} participant={p}/>)}
            </DialogContent>
        </Modal>
    )
}

export default AdhocCallParticipants