import { call } from "store/api";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AppReduxStore } from "../reducerTypes";
import { setError } from "./error";
import { AnyAction } from "redux";
import { pushSystemMessage } from "store/actions"
import { SYS_MESSAGES,EVISIT_TYPE } from "constant";
import moment from "moment"
import ApiController from "store/listener"
import { ListItem  } from "components/MultiSelectPopoverInput/types";

export const invitePNGPatient = (params: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await call("POST", `/conference/v1/evisit/invite`, params);
                resolve(result)
            } catch (e: any) {
                reject(e)
                dispatch(setError("Error.message.patientDetails", e?.message))
            }
        })
    }
}

export const WaitingRoomDetails = (): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { waitingRoom } = getState()

                const { providerFilterIds } = waitingRoom;

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                const result = await getWaitingRoomDetails(dispatch, providerFilterIds);

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })

            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const startNRUMeeting = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { twilioUsername: to, inviteToken } = meetingDetails;

                const { user } = getState()
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                const params = {
                    token: inviteToken,
                    status: 'INCALL'
                }
                //updating status
                await call("POST", `/conference/v1/evisit/invite/status`, params)

                const result = await call("GET", `/conference/v1/evisit/twilio-tokens/${inviteToken}`);

                const { twilioRoom, patientTwilioVideoToken, twilioRoomId } = result;

                const notificationPayload = {
                    roomName: twilioRoom,
                    patientTwilioVideoToken,
                    twilioRoomId
                }

                dispatch({ type: 'SET_CURRENT_WAITING_ROOM_DETAILS', payload: {...meetingDetails,...result} })

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })

                await dispatch(pushSystemMessage(to, null, SYS_MESSAGES.SYS_NRU_EVISIT_CALL_INCOMING, user, null, null, notificationPayload))
                // refresh waiting room list
                await dispatch(pushSystemMessage(user.twilioUsername, null, SYS_MESSAGES.SYS_NRU_EVISIT_REFRESH, user, null, null, {}))

            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                dispatch(setError(e?.message))
                reject(e)
            }
        })
    }
}

export const removeUserFromCall = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { twilioUsername: to } = meetingDetails;

                const { user,waitingRoom } = getState()

                const params = {
                    token: meetingDetails.inviteToken,
                    status: 'WAITINGROOM'
                }
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                await call("POST", `/conference/v1/evisit/invite/status`, params)

                dispatch({ type: 'UPDATE_CURRENT_MEETING_MERGED_PARTICIPANTS', payload: [] })

                const result = await getWaitingRoomDetails(dispatch, waitingRoom.providerFilterIds);

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })

                await dispatch(pushSystemMessage(to, null, SYS_MESSAGES.SYS_NRU_EVISIT_CALL_BACK_TO_WAITINGROOM, user, null, null, {}))

                dispatch({ type: 'UPDATE_COMMON_LOADER', payload: false })
            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const mergeNRUMeeting = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { twilioUsername: to, } = meetingDetails
                const { user, waitingRoom } = getState()
                const { currentMeetingDetails } = waitingRoom
                const { inviteToken, visitReminderId } = currentMeetingDetails;

                dispatch({ type: 'UPDATE_COMMON_LOADER', payload: true })

                const params = {
                    mergedParticipantKeys: [meetingDetails.visitReminderId],
                    status: 'MERGED',
                    visitReminderKey: visitReminderId
                }
                //updating status
                await call("POST", `/conference/v1/evisit/participants/status`, params)

                resolve()

                dispatch({ type: 'UPDATE_CURRENT_MEETING_MERGED_PARTICIPANTS', payload: [meetingDetails] })

                const result = await call("GET", `/conference/v1/evisit/twilio-tokens/${inviteToken}/${to}`);

                const { twilioRoom, patientTwilioVideoToken, twilioRoomId } = result;

                const notificationPayload = {
                    roomName: twilioRoom,
                    patientTwilioVideoToken,
                    twilioRoomId
                }

                const listDetails = await getWaitingRoomDetails(dispatch , waitingRoom.providerFilterIds)

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: listDetails })

                dispatch({ type: 'UPDATE_COMMON_LOADER', payload: false })

                await dispatch(pushSystemMessage(to, null, SYS_MESSAGES.SYS_NRU_EVISIT_CALL_INCOMING, user, null, null, notificationPayload))

            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER', payload: false })
                reject(e)
            }
        })
    }
}

export const notifyProviders = (providerId: any, status:string, practiceId : any,visitReminderId: string): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        return new Promise(async (resolve, reject) => {
            try {
           
                if(practiceId){
                    await call("POST", `/conference/v1/evisit/guest/waiting-room/notify/${practiceId}/${providerId}/${status}/${visitReminderId}`)
                }else{
                    await call("POST", `/conference/v1/evisit/waiting-room/notify/${providerId}/${status}/${visitReminderId}`)
                }

            } catch (e: any) {
                console.log(e)
            }
        })
    }
}

export const sendQuickChat = (cardDetails: any, quickMessage:string, messageFrom:string): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {
                const messagePayload = {
                    quickMessage: quickMessage,
                    messageFrom: messageFrom
                }
                const { user } = getState()
                
                // handshake to patient
               const result = await dispatch(
                   pushSystemMessage(
                       cardDetails.twilioUsername,
                       null,
                       SYS_MESSAGES.SYS_NRU_EVISIT_QUICK_CHAT,
                       user,
                       null,
                       null,
                       messagePayload
                   )
               );
                resolve(result)

            } catch (e: any) {
                reject(e)
            }
        })
    }
}

export const pauseNRUMeeting = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

    
                const { user,waitingRoom } = getState()

               const updatedMeetingDetails =
                   waitingRoom.participants.find(
                       ({ visitReminderId }: any) => visitReminderId === meetingDetails.visitReminderId
                   ) || meetingDetails;

               const { inviteToken, inviteeTwilioId, twilioUsername, mergedParticipantsInfo } = updatedMeetingDetails;

                const { currentMeetingParticipants } = waitingRoom

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                const params = {
                    token: inviteToken,
                    status: 'PAUSED'
                }
                //updating status
                await call("POST", `/conference/v1/evisit/invite/status`, params)

                //udpate previous meeting details
                dispatch({ type: 'SET_PREVIOUS_MEETING_DETAILS', payload: {...updatedMeetingDetails,status:'PAUSED'} })

                  //@ts-ignore
                  const finalParticipants = [
                      ...new Set(currentMeetingParticipants.concat([inviteeTwilioId, twilioUsername])),
                  ];

                  const promises = finalParticipants
                      .filter((finalParticipant: string) => finalParticipant !== user.twilioUsername)
                      .map(
                          (to: string) =>
                              to &&
                              dispatch(
                                  pushSystemMessage(
                                      to,
                                      null,
                                      SYS_MESSAGES.SYS_NRU_EVISIT_CALL_PAUSED,
                                      user,
                                      null,
                                      null,
                                      { inviteToken }
                                  )
                              )
                      );
  
                  await Promise.all(promises)

                  if(mergedParticipantsInfo.length){

                    const [mergedParticipant] = mergedParticipantsInfo || [];

                    const isMergedParticipantPresent =
                        finalParticipants.indexOf(mergedParticipant.twilioUsername) !== -1;
            
                    if(isMergedParticipantPresent){

                        const params = {
                            token: mergedParticipant.inviteToken,
                            status: 'PAUSED'
                        }
                        await call("POST", `/conference/v1/evisit/invite/status`, params)
                    }
                }

                const result = await getWaitingRoomDetails(dispatch, waitingRoom.providerFilterIds);

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })

                //reset current meeting details
                dispatch({ type: 'SET_CURRENT_WAITING_ROOM_DETAILS', payload: null })

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })

            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const leaveNRUMeeting = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { twilioUsername: to } = meetingDetails;

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })
                const { user,waitingRoom } = getState()
                const { currentMeetingParticipants } = waitingRoom
                
                 // handshake to patient
                 currentMeetingParticipants.length<=2&&await dispatch(pushSystemMessage(to, null, SYS_MESSAGES.SYS_NRU_EVISIT_CALL_PAUSED, user, null, null,{}))

                const result = await getWaitingRoomDetails(dispatch, waitingRoom.providerFilterIds);

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })

                //reset current meeting details
                dispatch({ type: 'SET_CURRENT_WAITING_ROOM_DETAILS', payload: null })

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })

            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const resetMeeting = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { inviteToken, isMA = false } = meetingDetails;

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                const params = {
                    token: inviteToken,
                    status: 'INACTIVE'
                }
                //updating status
                await call("POST", `/conference/v1/evisit/invite/status`, params)

                //udpate previous meeting details
                !isMA&&dispatch({ type: 'SET_PREVIOUS_MEETING_DETAILS', payload: null })

                //reset current meeting details
                dispatch({ type: 'SET_CURRENT_WAITING_ROOM_DETAILS', payload: null })

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                resolve()
            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const dropUserFromMeeting = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { twilioUsername: to } = meetingDetails;

                const { user,waitingRoom } = getState()

                const params = {
                    token: meetingDetails.inviteToken,
                    status: 'REJECTED'
                }
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                await call("POST", `/conference/v1/evisit/invite/status`, params)

                const result = await getWaitingRoomDetails(dispatch, waitingRoom.providerFilterIds);

                //udpate previous meeting details
                dispatch({ type: 'SET_PREVIOUS_MEETING_DETAILS', payload:null })

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })

                await dispatch(pushSystemMessage(to, null, SYS_MESSAGES.SYS_NRU_EVISIT_DROP, user, null, null, {}))
              
            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const refreshWaitingRoomListDetails = async (dispatch:any, providerFilterIds:ListItem[]|null)=>{
    const result = await getWaitingRoomDetails(dispatch, providerFilterIds);
    dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })
}

export const endCall = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { user, waitingRoom } = getState()

                const updatedMeetingDetails =
                    waitingRoom.participants.find(
                        ({ visitReminderId }: any) => visitReminderId === meetingDetails.visitReminderId
                    ) || meetingDetails;

                const { inviteToken, inviteeTwilioId, twilioUsername, mergedParticipantsInfo, visitReminderId } =
                    updatedMeetingDetails;

                const { currentMeetingParticipants } = waitingRoom;

                const params = {
                    token: inviteToken,
                    status: 'COMPLETE'
                }
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                await call("POST", `/conference/v1/evisit/invite/status`, params)

                const details = waitingRoom.participants.slice(0);

                //@ts-ignore
                const finalParticipants = [
                    ...new Set(currentMeetingParticipants.concat([inviteeTwilioId, twilioUsername])),
                ];

                const promises = finalParticipants
                    .filter((finalParticipant: string) => finalParticipant !== user.twilioUsername)
                    .map(
                        (to: string) =>
                            to &&
                            dispatch(
                                pushSystemMessage(to, null, SYS_MESSAGES.SYS_NRU_EVISIT_END, user, null, null, {
                                    inviteToken,
                                })
                            )
                    );

                await Promise.all(promises)

                if(mergedParticipantsInfo.length){

                    const [mergedParticipant] = mergedParticipantsInfo || [];

                    const isMergedParticipantPresent = finalParticipants.indexOf(mergedParticipant.twilioUsername) !== -1;
            
                    if(isMergedParticipantPresent){

                        const mergedPatientIndex = details.findIndex(
                            (d: any) => d.visitReminderId === mergedParticipant.visitReminderId
                        );
          
                        // only for frontend purpose based on new figma design. no need to get from API
                        details[mergedPatientIndex] = {
                            ...details[mergedPatientIndex],
                            status:'ENDED',endTime:moment()
                        }
           
                        dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: details })

                        const params = {
                            token: mergedParticipant.inviteToken,
                            status: 'COMPLETE'
                        }
                        await call("POST", `/conference/v1/evisit/invite/status`, params)
                    }
                }

                //udpate previous meeting details
                dispatch({ type: 'SET_PREVIOUS_MEETING_DETAILS', payload: {...updatedMeetingDetails,status:'ENDED',endTime:moment()} })


                const index = details.findIndex((d: any) => d.visitReminderId === visitReminderId);

                // only for frontend purpose based on new figma design. no need to get from API
                details[index] = {
                    ...details[index],
                    status:'ENDED',endTime:moment()
                }

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: details })

                dispatch({ type: 'SET_CURRENT_WAITING_ROOM_DETAILS', payload: null })

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
            } catch (e: any) {
                dispatch(setError("Error.message.pngMeetingCloseFailed", e?.message))
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const getWaitingRoomDetails = (dispatch: any , providerFilterIds:ListItem[]|null) => {
    return new Promise(async (resolve) => {
        try {
 
            const finalIds =
                providerFilterIds
                    ?.slice(0)
                    .map(({ id }: any) => id)
                    .join(",") || "all";

            const result = await call("GET", `/conference/v1/evisit/waiting-room?providers=${finalIds}`);
            resolve(result)
        } catch (e: any) {
            dispatch(setError("Error.message.pngListErrorMessage", e?.message))
        }
    })
}

export const inviteColleagueToWaitingroom = (participant: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            const {  user,waitingRoom } = getState()

            try {

                const inviteToken = waitingRoom.currentMeetingDetails?.inviteToken || "";
                const patientName = waitingRoom.currentMeetingDetails?.patientName || "";

                const message = {
                    waitingRoomInviteToken:inviteToken,
                    type:EVISIT_TYPE.NRU_EVISIT,
                    patientName,
                    inviteToken
                }

                const params = {
                    providerProfileRequests: participant.map((participantDetails: any) => ({ twilioUsername: participantDetails.twilioUsername, profileID: participantDetails.profileId })),
                    identity: user.twilioUsername,
                    message:message,
                    inviteToken,

                }

                await call("POST", `/conference/v1/meetings/twilio/evisit/invite`, params)

                resolve()

            } catch (e: any) {
                console.error(e)
                reject(e)
                dispatch(setError("Error.message.failedInviteColleague"))
            }
        })
    }
}

export const joinExistingMeeting = (inviteToken: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { user } =  getState()

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                const result = await call("GET", `/conference/v1/evisit/twilio-tokens/${inviteToken}`);

                const {
                    status,
                    patientTwilioIdentity,
                    patientTwilioVideoToken,
                    twilioRoom,
                    twilioRoomId,
                    mergedParticipantsInfos,
                } = result;
                
                resolve()

                if(status==='INCALL' || status==='PAUSED' || status==='INVITED'){
                    const notificationPayload = {
                        roomName: twilioRoom,
                        patientTwilioVideoToken,
                        twilioRoomId
                    }
                    await dispatch(pushSystemMessage(patientTwilioIdentity, null, SYS_MESSAGES.SYS_NRU_EVISIT_CALL_INCOMING, user, null, null, notificationPayload))
                    dispatch({ type: 'SET_CURRENT_WAITING_ROOM_DETAILS', payload:{...result,twilioUsername:patientTwilioIdentity,inviteToken,isMA:true,mergedParticipantsInfo:mergedParticipantsInfos} })
                }

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })

            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const getParticipantName = (participant:string[]): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            const { currentMeetingDetails } = getState().waitingRoom;

            //@ts-ignore
            const { inviteLink = "" } = getState().pngMobile.pngDetails || {};

            let practiceId:string = ''

            const getParcticeId = (link:string)=>{
                const splittedLink = link.split("/");
                if(splittedLink.length>0){
                    const inviteId = splittedLink.pop() || "";
                    return window.atob(inviteId).split("@#@")[1]
                }
                return ''
            }

                if(currentMeetingDetails){
                    practiceId=getParcticeId(currentMeetingDetails.inviteLink)
                }else{
                    practiceId=getParcticeId(inviteLink)
                }

            const payload = participant.map((key) => ({
                identity: key,
            }));

            try {
                const result = await call("POST", `/conference/v1/evisit/guest/participants/${practiceId}`, payload);
                resolve(result)
            } catch (e: any) {
                reject([])
            }
        })
    }
}


export const patientEndPingHandler = (response:any,dispatch: any,waitingRoom:AppReduxStore['waitingRoom']) =>{

    const { meetingPingDetails } = waitingRoom;

    const { visitReminderId } = response;

    meetingPingDetails[visitReminderId] =  moment()

    dispatch({ type:'UPDATE_MEETING_PING_DETAILS',payload:meetingPingDetails })

}


export const resetInactiveUserMeeting = (meetingDetails: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { waitingRoom } = getState()

                const { inviteToken } = meetingDetails;

                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })

                const params = {
                    token: inviteToken,
                    status: 'INACTIVE'
                }
                //updating status
                await call("POST", `/conference/v1/evisit/invite/status`, params)

                const result = await getWaitingRoomDetails(dispatch, waitingRoom.providerFilterIds);

                dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })
                
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
            
                resolve()
            } catch (e: any) {
                dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
                reject(e)
            }
        })
    }
}

export const providerFilterList  = (searchStr: string) => {
    return new Promise(async (resolve, reject) => {
        try {
   
            const signal =  ApiController.setEvent('waitingRoomProviderFilter')

            const searchedRecipients = await call("GET", "/conference/v1/evisit/provider/waitingRoomCount", {...(searchStr?{searchStr}:'')},signal)
            
            ApiController.removeEvent('waitingRoomProviderFilter')
            
            resolve(searchedRecipients)
        } catch (e: any) {
            if(e.name !== 'AbortError'){
                reject([])
            }
            ApiController.removeEvent('waitingRoomProviderFilter')
        }
    })

}