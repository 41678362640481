import React, {useEffect, useState, Fragment} from "react";
import {useSelector,useDispatch} from "react-redux";
import {map, first} from "underscore";
import {Box, Typography, ListSubheader} from "@mui/material";
import {FormattedMessage} from "react-intl";

import {AppReduxStore} from "store/reducerTypes";
import {ReactComponent as EnvelopeSvg} from "icon-library/SVG/eMail_Closed-Envelope.svg";
import {ReactComponent as SearchSvg} from "icon-library/SVG/Search.svg";
import {ReactComponent as InboxSvg} from "icon-library/SVG/Inbox_Filled.svg";
import EmptyContentMessage from "common/components/EmptyContentMessage/EmptyContentMessage";
import Spinner from "common/components/Spinner/Spinner"

import MessageThread from "../MessageThread/MessageThread";
import {useStyles} from "./styles";
import ThreadsHeader from "../ThreadsHeader/ThreadsHeader";
import { getConversationDate } from "store/actions/chat";
import MessageDate from "common/components/MessageDate/MessageDate";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import {createSvgIcon} from "utils";
import {CONVERSATION_TYPE, THREAD_PAGE_SIZE} from "constant";
import {loadThreadPage, searchConversations, setConversationFilter, setSearchText} from "store/actions/chat";
import {useHistory} from "react-router";

const PRIORITY = "PRIORITY"
const Envelope = createSvgIcon(EnvelopeSvg)
const Search = createSvgIcon(SearchSvg)
const Inbox = createSvgIcon(InboxSvg)

// MessageThreads renders a list of threads based and groups them by date. Threads are filtered by the conversation type.
const MessageThreads = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const history = useHistory()
    const classes = useStyles()
    const {groupedConversations, filter, pages, conversationsInfo, loadedConversations, searchedConversations = {}, searchText, conversationsFetched, client,searchLoading} = useSelector((store: AppReduxStore) => store.chat)
    const {username} = useSelector((store: AppReduxStore) => store.user)
    const conversationsToSort = searchText ? searchedConversations : groupedConversations
    const dispatch = useDispatch()


    // Search for conversations with the text in the search query param
    useEffect(() => {
        const params = new URLSearchParams(history.location.search)
        const search = params.get("search")
        if (search && username && !loading) {
            dispatch(setSearchText(search))
            dispatch(searchConversations(search, CONVERSATION_TYPE.PATIENT))
        }
    }, [dispatch, history.location.search, loading, username])

    // Only show list once all data has loaded for each conversation
    useEffect(() => {
        setLoading(!conversationsFetched)
    }, [loading, loadedConversations, conversationsFetched])

    const onScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            dispatch(loadThreadPage())
        }
    }

    const setConversationFilters = (newFilter: string) => {
        if (client) {
            dispatch(setConversationFilter(newFilter))
        }
    }

    const renderThreads = (group: any, title?: string) => {
        if (!group || !group.length) return null

        const threadList = map(group, (convo, index: number) => {
            // When the date of the current conversation is different from the previous conversation
            // render the date as a subheader
            const date = getConversationDate(convo)
            let dateDifference = false
            if (index > 0) {
                const prevDate = getConversationDate(group[index-1])
                dateDifference = prevDate?.getDate() !== date?.getDate()
            }

            const shouldRenderSubheader = index === 0 || dateDifference
            return (
                <Fragment key={index}>
                    {shouldRenderSubheader && !title && (
                        <ListSubheader className={classes.categoryTitle}>
                            <Typography  variant="h5" id="QA_messageDate">
                                <MessageDate date={date} />
                            </Typography>
                        </ListSubheader>
                    )}
                    <MessageThread key={convo.sid} conversation={convo} info={conversationsInfo[convo.sid]}/>
                </Fragment>
            )
        })

        return (
            <ul className={classes.threads}>
                {title && (
                    <li className={classes.categoryTitle}>
                        <Typography variant="h5">
                            <FormattedMessage id={title} />
                        </Typography>
                    </li>
                )}
                {first(threadList, THREAD_PAGE_SIZE * pages[filter])}
            </ul>
        )
    }


    // renderEmptyContent displays a message when there are no conversations shown either at all or from a search
    const renderEmptyContent = () => {
        return searchText ? (
            <EmptyContentMessage Icon={Search} title="MessageThreads.emptySearch.title" subtitle="MessageThreads.emptySearch.subtitle" />
        ) : (
        <EmptyContentMessage Icon={Inbox} title={`MessageThreads.empty.${filter}.title`}>
            <Box className={classes.subtitle}>
                <CustomTypography variant="contentSubtitle">
                    <FormattedMessage id={"MessageThreads.empty.subtitle1"}/>
                </CustomTypography>
                <Box display="flex" alignItems="center">
                    <FormattedMessage id={"MessageThreads.empty.subtitle2_1"}/>
                    <Envelope className={classes.subtitleIcon}/>
                    <CustomTypography component="span" variant="contentSubtitleBold">
                        <FormattedMessage id={"MessageThreads.empty.subtitle2_Compose"}/>
                    </CustomTypography>
                    <FormattedMessage id={"MessageThreads.empty.subtitle2_2"}/>
                </Box>
            </Box>
        </EmptyContentMessage>
        )
    }

    // renderThreadList handles showing the empty content if there are no conversations to display or showing the list
    // conversations by date and the priority Conversations on top
    const renderThreadList = () => {
        const {datedConversations = {}, priorityConversations} = conversationsToSort[filter]
        const empty = Object.keys(datedConversations).length
        return empty && conversationsFetched ? (
            <Box onScroll={onScroll} className={classes.innerWrapper}>
                {renderThreads(priorityConversations, PRIORITY)}
                {renderThreads(datedConversations)}
            </Box>
        ) : renderEmptyContent()
    }

    return (
        <Box position="relative" height="100%" minWidth="600px">
            <Box className={classes.threadsWrapper}>
                <ThreadsHeader filter={filter} setFilter={setConversationFilters}/>
                {(loading || searchLoading) ? <Spinner/> : renderThreadList()}
            </Box>
        </Box>
    )
}

export default MessageThreads
