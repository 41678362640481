import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {  SIZING,COLORS,BORDER_RADIUS } from "theme"

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
      borderRadius: theme.spacing(2),
    },
    chatIcon:{
      "& path":{
        "fill":COLORS.CALL_STATUS_BG
      }
    },
    chatIconOpen:{
      "& path":{
        "fill":COLORS.BADGE_BG
      }
    },
    wrapper:{
      marginLeft:'auto',
      display:'flex'
    },
    root: {
      width: theme.spacing(40),
      padding: theme.spacing(1),
      position: "relative",
      "& .MuiInputBase-input ":{
        minHeight:`${SIZING.scale1000} !important`,
        background:`${COLORS.MESSAGE_LIST_BG} !important`,
        borderRadius:BORDER_RADIUS.MEDIUM,
        fontWeight:'unset'
      },
      "& .title": {
        color: COLORS.ITEM_BADGE_BG,
        paddingBottom: theme.spacing(1),
      },
      "& .content": {
        fontSize: SIZING.scale300,
        color: COLORS.LIST_SUBHEADER_TEXT,
        fontWeight: 'normal',
        paddingBottom: "5%",
      },
      "& .send-container": {
        alignSelf: "flex-end",
        "& button":{
            "&:hover":{
              backgroundColor:COLORS.DEFAULT_BTN
            }
        }
      },
      "& .closeIcon": {
        position: "absolute",
        right: 0,
        top: 0,
        height: theme.spacing(1),
        width: theme.spacing(1),
        margin: theme.spacing(1),
      },
    },
    button:{
      height:SIZING.scale1000,
      fontSize:SIZING.scale400,
      "&:hover":{
        background:COLORS.INPUT_BG
      },
      marginLeft:SIZING.scale400

    },
    startEvent:{
      color: (isEvistAlreadyStart:any) => isEvistAlreadyStart ? COLORS.FORM_SCORE_GREEN : COLORS.SECONDARY_BUTTON
    },
    btnSendInstruction:{
        backgroundColor:COLORS.DEFAULT_BTN,
        marginTop: theme.spacing(1),
    },
    startButton:{
      "& path":{
        fill:  COLORS.FORM_SCORE_GREEN
      }
    }
  }));