import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { AppReduxStore } from "admin/store/reducerTypes";
import SnackbarContent from "admin/components/SnackBars/SnackbarContent";
import { hideNotification } from "admin/store/actions/snackbar";
import { setActiveModal, setConfigSetupFlagActive, setLoggedInUser, setWelcomeModalActive, } from "admin/store/actions";
import WelcomeConfigurationModal from "../Configurations/WelcomeConfigurationModal/WelcomeConfigurationModal";
import { MODAL_TYPES } from "admin/constant";
import Logout from "components/Modals/Logout/Logout";
import HelpCenter from "components/Modals/HelpCenter/HelpCenter";
import LearningCenter from "components/Modals/LearningCenter/LearningCenter";
import { getAppCurrentProfileId } from "authentication/appAuth";
const AppWrapper = ({ children }: any) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const { enqueueSnackbar } = useSnackbar()
    const { snackbar } = useSelector((state: AppReduxStore) => state)
    const { message, type, description, dismissed } = snackbar
    // Welcome Configuration Modal
    const { auth, license, configuration, modalAdmin } = useSelector((state: AppReduxStore) => state)
    if (auth.currentPractice?.id) {
        const { practiceLicenses } = license
        const coreLicenseCount = practiceLicenses && practiceLicenses.find((core: any) => core.product.code === "CORE")?.licenseCount
        const careTeamLicenseCount = practiceLicenses && practiceLicenses.find((core: any) => core.product.code === "CARE_TEAM")?.licenseCount
        const configSetupFlag = (coreLicenseCount > 0 && careTeamLicenseCount > 0) &&
            auth.currentPractice?.id && (configuration.visitTypes.length === 0 ||
                configuration.cancellationTypes?.length === 0 ||
                configuration.patientForms?.length === 0 ||
                Object.keys(configuration.registrationEmailPatients)?.length === 0 || configuration.registrationEmailPatients?.version === 0 ||
                Object.keys(configuration.registrationEmailProvider)?.length === 0 || configuration.registrationEmailProvider?.version === 0)
        if (!configSetupFlag && modalAdmin.activeModal === MODAL_TYPES.WELCOME_CONFIGURATION_MODAL) {
            dispatch(setActiveModal(""))
            dispatch(setConfigSetupFlagActive(false));
            dispatch(setWelcomeModalActive(false));
        }
        if (!configSetupFlag && configuration.configSetupFlagActive) {
            dispatch(setConfigSetupFlagActive(false));
        }
        if (configSetupFlag && !modalAdmin.isWelcomeModalActive) {
            dispatch(setConfigSetupFlagActive(true));
            dispatch(setWelcomeModalActive(true));
            dispatch(setActiveModal(MODAL_TYPES.WELCOME_CONFIGURATION_MODAL))
        }
    }
    const handleClose = useCallback(() => {
        dispatch(hideNotification())
    }, [dispatch])
    
    useEffect(() => {
        dispatch(setLoggedInUser(getAppCurrentProfileId()))
    }, [dispatch])

    useEffect(() => {
        if (!dismissed) {
            enqueueSnackbar(null, {
                preventDuplicate: true, content: (key) => (<div role="alert"><SnackbarContent snackbarKey={key} type={type}
                    message={message} desc={description} /></div>
                ),
                onClose: handleClose,
                autoHideDuration: (type === 'error') ? null : 10000
            })
        }
    }, [dispatch, message, intl, enqueueSnackbar, dismissed, type, description, handleClose])
    return (
        <>
            {children}
            <WelcomeConfigurationModal />
            <Logout />
            <HelpCenter />
            <LearningCenter />
        </>
    )
}

export default withRouter(AppWrapper)