import { EConsultMessagingParticipants, EConsultRequestModel } from "./types";
import { getDiffDuration } from "../../../../utils";
import useCurrentUser from "common/utils/use-current-user";

export const getDaysLeft = (deadlineDate: string | null): number | null => {
    if (!deadlineDate) {
        return null;
    }
    return Math.floor(Math.abs(getDiffDuration(deadlineDate).asDays()));
}

export const useEConsultParticipants = (eConsultRequest: EConsultRequestModel): EConsultMessagingParticipants => {
    const { currentUserId } = useCurrentUser();
    const isReferrer = currentUserId === eConsultRequest.referrer.id;
    if (isReferrer) {
        return {
            sender: {
                ...eConsultRequest.referrer,
                isReferrer,
            },
            recipients: eConsultRequest.recipients || [],
        };
    }
    const currentRecipient = eConsultRequest.recipients?.find((recipient) => recipient.id === currentUserId);
    if (!currentRecipient) {
        throw new Error('Current recipient is undefined.');
    }
    return {
        sender: {
            ...currentRecipient,
            isReferrer,
        },
        recipients: [{
            ...eConsultRequest.referrer,
            fullName: eConsultRequest.referrer.providerName,
            conversationId: currentRecipient?.conversationId,
        }],
    };
}
