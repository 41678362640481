import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_newuser_header_row: {
        width: '100%',
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        userSelect: 'none'
    },
    pep_newuser_header_container: {
        width: '100%',
        maxWidth: '1440px',
        background: '#FFFFFF',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    pep_newuser_header_left_content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        marginLeft: '16px',
        marginRight: '16px',
        maxWidth: '1040px'
    },
    pep_newuser_header_left: {
        display: 'inline-block',
        width: '100%'
    },
    pep_newuser_top: {
        width: '100%'
    },
    pep_newuser_banner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 32px',
        gap: '4px',
        width: '100%',
        height: '80px',
        background: 'linear-gradient(90deg, #F6F6F6 66.29%, rgba(246, 246, 246, 0) 100%)',
        borderRadius: '4px',
        '@media (max-width:364px) and (min-width:301px)': {
            height: '90px'
        },
        '@media (max-width:300px)': {
            height: 'unset',
            padding: 'unset'
        }
    },
    pep_newuser_banner_top: {
        fontFamily: 'BentonSansCond',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '32px',
        lineHeight: '140%',
        color: '#6E1E80',
        textAlign: 'center'
    },
    pep_newuser_banner_bottom: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#6E1E80'
    },
    pep_newuser_calendar_wrapper: {
        "& input": {
            "&::placeholder": {
                fontWeight: 'normal',
                fontStyle: 'italic'
            }
        }
    },
    pep_newuser_textfield: {
        border: '1px solid #909098',
        borderRadius: '4px',
        boxSizing: 'border-box',
        marginTop: '5px',
        padding: '6px 12px',
        gap: '10px',
        width: '100%',
        height: '38px',
        background: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#393948',
        marginBottom: '5px',
        "& input": {
            "&::placeholder": {
                fontWeight: 'normal',
                fontStyle: 'italic'
            }
        }
    },
    pep_newuser_dropdown_item: {
        textWrap: 'wrap'
    },
    pep_newuser_checkbox_ul: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: '0',
        margin: '0px'
    },
    pep_newuser_checkbox_li: {
        listStyle: 'none',
        flex: '0 0 33.333333%',
        marginTop: '5px',
        marginBottom: '5px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '140%',
        '@media (max-width:900px) and (min-width:601px)': {
            flex: '0 0 50%'
        },
        '@media (max-width:600px)': {
            flex: '0 0 100%'
        }
    },
    pep_newuser_checkbox_li_1col: {
        listStyle: 'none',
        flex: '0 0 100%',
        marginTop: '5px',
        marginBottom: '5px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '140%'
    },
    pep_newuser_initialfield: {
        border: '1px solid #909098',
        borderRadius: '4px',
        boxSizing: 'border-box',
        marginTop: '5px',
        padding: '6px 12px',
        gap: '10px',
        width: '60px',
        height: '38px',
        background: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#393948',
        marginBottom: '5px',
        "& input": {
            "&::placeholder": {
                fontWeight: 'normal',
                fontStyle: 'italic'
            }
        }
    },
    pep_newuser_submitButton: {
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: '22px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 16px',
        gap: '10px',
        width: '140px',
        height: '40px',
        background: '#0F7BBD',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)',
        borderRadius: '64px',
        color: '#FFFFFF'
    },
    pep_newuser_scroll_to_top_button: {
        display: 'block',
        position: 'fixed',
        bottom: '50px',
        right: '35px',
        zIndex: '1000',
        border: '1px solid #ffffff',
        outline: 'none',
        backgroundColor: '#6E1E80',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '12px 15px',
        borderRadius: '7px',
        textAlign: 'center'
    },
    pep_newuser_scroll_to_top_icon: {
        "& path": {
            fill: '#ffffff'
        },
        height: '30px',
        width: '30px',
        verticalAlign: 'middle'
    }
}));