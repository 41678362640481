import { DialogContent, Box, Button, DialogActions, IconButton, Typography, DialogContentText } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { FormattedMessage } from "react-intl";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useDispatch } from "react-redux";

const ChangeFormStatus = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { number, title, isEnabled } = props.row;
    const modalClasses = {
        paper: classes.wrapper
    };

    const handleClose = () => {
        dispatch(setActiveModal(""));
    }

    const handleStatusChange = () => {
        if (isEnabled) {
            props.callback('DisableForm', props.row);
        } else {
            props.callback('EnableForm', props.row);
        }
        dispatch(setActiveModal(""));
    }

    return (
        <Modal onClose={handleClose} classes={modalClasses} id={MODAL_TYPES.CHANGE_STATUS}>
            <DialogActions className={classes.dlgAction} >
                <Box flexGrow={1} >
                    <Typography variant="caption" className={classes.dialogTitle}>
                        <FormattedMessage id={isEnabled ? "ChangeStatus.DisableForm" : "ChangeStatus.EnableForm"} />
                        <br />
                        <FormattedMessage id={number + " - " + title} />
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnCloseUserModal" style={{ padding: '0px' }} size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction2} >
                <Button variant="outlined" onClick={handleClose} className="cancel">Cancel</Button>
                <Button variant="contained" color={isEnabled ? "error" : "primary"} onClick={handleStatusChange}>{`Yes, ${isEnabled ? "Disable Form" : "Enable Form"}`}</Button>
            </DialogActions>
            <DialogContent>
                <DialogContentText>
                    {isEnabled && <FormattedMessage id="ChangeStatus.DisableText">{txt => <p style={{ color: "red" }}><strong>{txt}</strong></p>}</FormattedMessage>}
                    {!isEnabled && <FormattedMessage id="ChangeStatus.EnableText">{txt => <p style={{ color: "#1976d2" }}><strong>{txt}</strong></p>}</FormattedMessage>}
                </DialogContentText>
            </DialogContent>
        </Modal>
    )
}

export default ChangeFormStatus;