import React from "react";
import { MODAL_TYPES } from "constant";
import HpiForm from "./hpi-form";
import Modal from "components/Modal/Modal";

const HpiFormModal = () => {
    return (
        <Modal id={MODAL_TYPES.PROVIDER.HPI_FORM}>
            <HpiForm />
        </Modal>
    )
}

export default HpiFormModal;
