import React from "react";
import Modal from "components/Modal/Modal"
import { MODAL_TYPES } from "constant"
import { useStyles } from "./styles";
import TranscribeMedical, { TranscribeMedicalProps } from "./TranscribeMedical"

const TranscribeMedicalModal = (props: TranscribeMedicalProps) => {
    const classes = useStyles();
    return (
        <Modal classes={{ paper: classes.root }} id={MODAL_TYPES.PROVIDER.TRANSCRIBE_MEDICAL} forceOpen={true}>
            <TranscribeMedical {...props} />
        </Modal>
    );
}

export default TranscribeMedicalModal;
