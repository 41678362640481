import { DialogTitle, DialogTitleProps } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import * as CSS from "./class-names";
import classNames from "classnames";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import ModalCloseBtn from "components/Modal/ModalCloseBtn";
import { TabContainer } from "common/components/Tabs";

type ModalHeaderProps = DialogTitleProps & {
    idPrefix: string;
    onClose: () => void;
    subTitleKey?: string;
    tabs?: JSX.Element[];
    titleKey: string;
};

const ModalHeader: React.FC<ModalHeaderProps> = ({
    children,
    idPrefix,
    onClose,
    subTitleKey,
    tabs,
    titleKey,
    ...dialogTitleProps
}) => {
    const styles = useStyles();
    return (
        <DialogTitle {...dialogTitleProps} className={classNames(styles[CSS.ModalHeader], dialogTitleProps.className)}>
            <div className={CSS.ModalHeaderTitleContainer}>
                <div>
                    <CustomTypography variant="modalHeader" className="title">
                        <FormattedMessage id={titleKey} />
                    </CustomTypography>
                    <CustomTypography variant="modalSubHeader">
                        {subTitleKey && <FormattedMessage id={subTitleKey} />}
                    </CustomTypography>
                </div>
                <ModalCloseBtn onClick={onClose} id={`${idPrefix}_close`} />
            </div>
            {children}
            {tabs && <TabContainer className={CSS.ModalHeaderTabsContainer}>{tabs}</TabContainer>}
        </DialogTitle>
    );
};

export default ModalHeader;
