import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ErrorTypes, setError } from "store/actions";
import { useDispatch } from "react-redux";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import ModalHeader from "components/Modal/ModalHeader";
import ModalContent from "components/Modal/ModalContent";
import ModalCloseBtn from "components/Modal/ModalCloseBtn";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import { TabContainer, Tab } from "common/components/Tabs";
import Providers from "./components/providers/providers";
import Patient from "./components/patient/patient";
import CaseNotes from "./components/case-notes/case-notes";
import TabContent from "./components/tab-content/tab-content";
import FileDropzoneInput from "common/components/files/file-dropzone-input";
import { FileUploadData } from "common/components/files/types";
import SubmitErrorDialog from "lib/ui-components/error-list-dialog/error-list-dialog";
import UploadingDialog from "common/components/files/uploading-dialog";
import { useCreateRequest } from "./create/use-create-request";
import { useUpdateRequest } from "./create/use-update-request";
import { useSubmitRequest } from "./create/use-submit-request";
import { convertValidationErrors } from "./create/utils";
import { CreateEConsultModalContentProps, Specialist } from "./types";
import { useStyles } from "./styles";

type TABS = "providers" | "patient" | "attachments" | "notes";

const CreateEConsultModalContent = (props: CreateEConsultModalContentProps) => {
    const { onClose } = props;
    const dispatch = useDispatch();
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const [selectedTab, setSelectedTab] = useState<TABS>("providers");
    const [selectedProviders, setSelectedProviders] = useState<Specialist[]>([]);
    const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
    const [subject, setSubject] = useState<string>("");
    const [notes, setNotes] = useState<string>("");
    const [urgent, setUrgent] = useState<boolean>(false);
    const [duration, setDuration] = useState<string>("");
    const [attachments, setAttachments] = useState<FileUploadData[]>([]);

    const classes = useStyles();

    const state = {
        selectedPatient,
        selectedProviders,
        urgent,
        subject,
        notes,
        duration,
        attachments,
    };

    const handleSuccessSubmit = () => {
        dispatch(setError("EConsult.newRequestModal.submit.success", null, null, ErrorTypes.SUCCESS));
        onClose();
        setSubmitted(true);
    };

    const requestId = useCreateRequest(state);

    const { updatedDraft } = useUpdateRequest(
        {
            id: requestId,
            version: null,
            ...state,
        },
        submitted
    );

    const { validationErrors, isUploading, submit, submitting } = useSubmitRequest(
        {
            id: requestId,
            version: updatedDraft?.version || 0,
            ...state,
        },
        handleSuccessSubmit
    );

    const handleSubmit = () => {
        setShowErrorDialog(true);
        submit();
    };

    const handleCloseErrorDialog = () => {
        setShowErrorDialog(false);
    };

    const hasProviderError = Boolean(validationErrors.providers);
    const hasSubjectError = Boolean(validationErrors.caseNotes?.subject?.error);
    const hasNotesError = Boolean(validationErrors.caseNotes?.notes?.error);
    const hasCaseNotesError = Boolean(validationErrors.caseNotes);
    const hasAttachmentError = Boolean(validationErrors.attachments);

    const hasErrors = Object.keys(validationErrors).length > 0;

    const shouldShowErrorDialog = showErrorDialog && hasErrors;

    return (
        <>
            {submitting && <ComponentLoader />}
            <ModalHeader className={classes.header}>
                <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                    <Box>
                        <CustomTypography variant="modalHeader" className="title">
                            <FormattedMessage id="EConsult.newRequestModal.title" />
                        </CustomTypography>
                        <CustomTypography variant="modalSubHeader">
                            <FormattedMessage id="EConsult.newRequestModal.subTitle" />
                        </CustomTypography>
                    </Box>
                    <ModalCloseBtn onClick={onClose} id="QA_create_e_consult_request_close_button" />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        onClick={handleSubmit}
                        id="QA_econsult_request_submit_button"
                        variant="contained"
                        color="inherit"
                        disabled={submitting}
                    >
                        <FormattedMessage id="EConsult.newRequestModal.submit" />
                    </Button>
                </Box>
                <TabContainer className={classes.tabContainer}>
                    <Tab<TABS>
                        name="providers"
                        labelId="EConsult.newRequestModal.providers"
                        selected={selectedTab === "providers"}
                        onTabSelected={setSelectedTab}
                        count={selectedProviders.length}
                        error={hasProviderError}
                    />
                    <Tab<TABS>
                        name="patient"
                        labelId="EConsult.newRequestModal.patient"
                        selected={selectedTab === "patient"}
                        onTabSelected={setSelectedTab}
                    />
                    <Tab<TABS>
                        name="attachments"
                        labelId="EConsult.newRequestModal.attachments"
                        selected={selectedTab === "attachments"}
                        onTabSelected={setSelectedTab}
                        count={attachments.length}
                        error={hasAttachmentError}
                    />
                    <Tab<TABS>
                        name="notes"
                        labelId="EConsult.newRequestModal.notes"
                        selected={selectedTab === "notes"}
                        onTabSelected={setSelectedTab}
                        error={hasCaseNotesError}
                    />
                </TabContainer>
            </ModalHeader>
            <ModalContent className={classes.content}>
                <TabContent selected={selectedTab === "providers"}>
                    <Providers
                        selectedProviders={selectedProviders}
                        onChange={setSelectedProviders}
                        error={hasProviderError}
                    />
                </TabContent>
                <TabContent selected={selectedTab === "patient"}>
                    <Patient selectedPatient={selectedPatient} onChange={setSelectedPatient} />
                </TabContent>
                <TabContent selected={selectedTab === "notes"}>
                    <CaseNotes
                        duration={duration}
                        notes={notes}
                        onDurationChange={setDuration}
                        onNotesChange={setNotes}
                        onSubjectChange={setSubject}
                        onUrgencyChange={setUrgent}
                        subject={subject}
                        urgent={urgent}
                        errors={{ notes: hasNotesError, subject: hasSubjectError }}
                    />
                </TabContent>
                <TabContent selected={selectedTab === "attachments"}>
                    <FileDropzoneInput
                        onChange={setAttachments}
                        onPercentageChange={setUploadPercentage}
                        removeDialogProps={{
                            titleId: "EConsult.newRequestModal.attachments.removing",
                            primaryMessageId: "EConsult.newRequestModal.attachments.removing.description",
                            secondaryMessageId: "EConsult.newRequestModal.attachments.removing.warning",
                        }}
                    />
                </TabContent>
            </ModalContent>
            {shouldShowErrorDialog && <SubmitErrorDialog
                contentKey="EConsult.newRequestModal.submit.error.content"
                errors={convertValidationErrors(validationErrors)}
                idPrefix="QA_econsult_validation_error_dialog"
                onClose={handleCloseErrorDialog}
                titleKey="EConsult.newRequestModal.submit.error.title"
            />}
            {isUploading && submitting && (
                <UploadingDialog
                    percentage={uploadPercentage}
                    descriptionId="EConsult.newRequestModal.attachments.uploading.description"
                />
            )}
        </>
    );
};

export default CreateEConsultModalContent;
