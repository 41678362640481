import createReducer from '../createReducer'
import { Form, PatientAlerts, PatientState } from '../reducerTypes';
import {isEmpty, map} from "underscore"
import {getAge} from "utils";
import { PatientDetails } from 'store/patient-types';

const initialState: PatientState = {
    patients: null,
    selectedPatientId: null,
    selectedPatient: null,
    selectedForm: null,
    forms: null,
    loadingForms: false,
    loadingDetails: false,
    loadingSearch: false,
    loadingPdf: false,
    pushingForm: false,
    loadingAppointments:false,
    appointmentDetails:[],
    loadingAlerts: false,
    alerts: null,
    feedBackLoop: null,
    currentAlertDetails:null
}

export default createReducer(initialState, {
    'GET_PATIENT_DETAILS': (state: PatientState, payload: string) => {
        return {
            ...state,
            selectedPatientId: payload,
            loadingDetails: true
        }
    },
    'GET_PATIENT_DETAILS_SUCCESS': (state: PatientState, payload: PatientDetails) => {
        return {
            ...state,
            selectedPatient: {
                ...payload,
                age: getAge(payload.dateOfBirth),
            },
            forms: null,
            selectedForm: null,
            loadingDetails: false,
        }
    },
    'UPDATE_PATIENT_EVSIT_DETAILS': (state: PatientState, payload: PatientDetails) => {
        return {
            ...state,
            selectedPatient: {
                ...state.selectedPatient,
                evistDetails:payload
            },
        }
    },
    'GET_PATIENT_DETAILS_FAILURE': (state: PatientState) => {
        return {
            ...state,
            selectedPatient: null,
            selectedForm: null,
            forms: null,
            loadingDetails: false,
        }
    },
    'UPDATE_PATIENT_EVIST_DETAILS': (state: PatientState, payload: PatientDetails) =>{
        return {
            ...state,
            selectedPatient: {
                ...state.selectedPatient,
                ...payload
            },
        }
    },
    'PATIENT_SEARCH': (state: PatientState, payload: PatientState) => {
        return {
            ...state,
            ...payload,
            loadingSearch: true,
        }
    },
    'PATIENT_SEARCH_SUCCESS': (state: PatientState, payload: PatientDetails[]) => {
        return {
            ...state,
            patients: isEmpty(payload) ? null : payload,
            loadingSearch: false,
        }
    },
    'PATIENT_SEARCH_FAILURE': (state: PatientState, payload: PatientState) => {
        return {
            ...state,
            ...payload,
            loadingSearch: false,
        }
    },
    'GET_PATIENT_ALERTS': (state: PatientState, payload: string) => {
        return {
            ...state,
            patientId: payload,
            loadingAlerts: true
        }
    },
    'GET_PATIENT_ALERTS_SUCCESS': (state: PatientState, payload: PatientAlerts[]) => {
        return {
            ...state,
            alerts:payload,
            loadingAlerts: false
        }
    },
    'GET_PATIENT_ALERTS_FAILURE': (state: PatientState) => {
        return {
            ...state,
            alerts: null,
            loadingAlerts: false
        }
    },
    'UPDATE_PATIENT_ALERTS_DETAILS': (state: PatientState,payload: any) => {
        return {
            ...state,
            currentAlertDetails: payload
        }
    },
    'GET_PATIENT_FEED_BACK_LOOP': (state: PatientState,payload: any) => {
        return {
            ...state,
            feedBackLoop: payload
        }
    },
    'GET_PATIENT_FORMS': (state: PatientState) => {
        return {
            ...state,
            loadingForms: true,
        }
    },
    'GET_PATIENT_FORMS_SUCCESS': (state: PatientState, payload: Form[]) => {
        const forms = map(payload, (form) => {
            if (form) {
                const progress = Math.round((form.answerResponses.length / form.questionnaire.questions.length) * 100)
                return {...form, progress}
            }
        })

        return {
            ...state,
            forms,
            loadingForms: false,
        }
    },
    'GET_PATIENT_FORMS_FAILURE': (state: PatientState) => {
        return {
            ...state,
            forms: null,
            loadingForms: false,
        }
    },
    'SET_SELECTED_FORM': (state: PatientState, payload: Form) => {
        return {
            ...state,
            selectedForm: payload
        }
    },
    'SET_FORM_PDF_LOADING': (state: PatientState, payload: boolean) => {
        return {
            ...state,
            loadingPdf: payload
        }
    },
    'SET_PUSHING_FORM_LOADING': (state: PatientState, payload: boolean) => {
        return {
            ...state,
            pushingForm: payload
        }
    },
    'GET_PATIENT_APPOINTMENTS': (state: PatientState) => {
        return {
            ...state,
            loadingAppointments: true,
        }
    },
    'GET_PATIENT_APPOINTMENTS_SUCCESS': (state: PatientState,payload: any) => {
        return {
            ...state,          
            appointmentDetails: payload,
            loadingAppointments: false 
        }
    },
    'GET_PATIENT_APPOINTMENTS_FAILURE': (state: PatientState) => {
        return {
            ...state,
            appointmentDetails: [],
            loadingAppointments: false,
        }
    },
    'CLEAR_PATIENT_STATE': (state: PatientState) => {
        return {
            ...state,
            patients: null,
            selectedPatientId: null,
            selectedPatient: null,
            selectedForm: null,
            forms: null,
            alerts: null,
            currentAlertDetails:null
        }
    }
})