import React from "react";
import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {map} from "underscore"
import {PASSWORD_VALIDATIONS, PASSWORD_VALIDATION_TYPES, PASSWORD_VALIDATION_DESCRIPTIONS} from "constant";
import {useSelector} from "react-redux";
import {AppReduxStore} from "admin/store/reducerTypes";
import {createSvgIcon} from "utils";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import {ReactComponent as CrossSvg} from "icon-library/SVG/Cross_Circle.svg";
import {ReactComponent as CheckSvg} from "icon-library/SVG/Radio_Circle.svg";
import {useStyles} from "./styles";
import {checkPasswordHasPersonal} from "../utils";
import {COLORS} from "theme";

const Cross = createSvgIcon(CrossSvg)
const Check = createSvgIcon(CheckSvg)

// Used by PatientRegistration Flow too
// PasswordValidation displays to the user if the password they are entering follows the okta guidelines
const PasswordValidation = () => {
    const {password, firstName, lastName, email} = useSelector((store: AppReduxStore) => store.registration)
    const classes = useStyles()

    const PassIcon = <Check fill={COLORS.PASS_ICON} className={classes.icon} />
    const FailIcon = <Cross fill={COLORS.FAIL_ICON} className={classes.icon} />

    return (
        <Box className={classes.validationContainer}>
            <CustomTypography className={classes.header} variant="largeHeader">
                <FormattedMessage id="PasswordValidation.heading.passwordValidation" />
            </CustomTypography>
            <CustomTypography className={classes.header} variant="largeSubheader">
                <FormattedMessage id="PasswordValidation.subheading.criteria" />
            </CustomTypography>
            <Box className={classes.validationWrapper}>
                <Box className={classes.validationRow}>
                    {checkPasswordHasPersonal(password, firstName, lastName, email) ? FailIcon : PassIcon}
                    <CustomTypography variant="largeSubheader">
                        <FormattedMessage id="PasswordValidation.description.hasPersonal" />
                    </CustomTypography>
                </Box>
                {map(PASSWORD_VALIDATION_TYPES, (type) => {
                    return (
                        <Box key={type} className={classes.validationRow}>
                            {PASSWORD_VALIDATIONS[type].test(password) ? PassIcon : FailIcon}
                            <CustomTypography variant="largeSubheader">
                                <FormattedMessage id={PASSWORD_VALIDATION_DESCRIPTIONS[type]} />
                            </CustomTypography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default PasswordValidation