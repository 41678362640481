import React, { createContext, useCallback, useContext, useRef, MutableRefObject } from "react";

export type FileDropzoneInputContextProps = {
    disabled?: boolean;
    removeDialogProps?: { titleId?: string; primaryMessageId?: string; secondaryMessageId?: string };
};

type FileDropzoneInputContextValue = {
    inputRef: MutableRefObject<HTMLInputElement | null>;
    clearInputValue: () => void;
} & FileDropzoneInputContextProps;

const FileDropzoneInputContext = createContext<FileDropzoneInputContextValue>({
    inputRef: { current: null },
    clearInputValue: () => {},
});

/**
 * Due to implementation details of Dropzone, passing down props
 * to the customized component is not practical.
 * 
 * Dropzone only accepts a ComponentType, and not a JSX.Element/React.Node
 * or a render function. Either of the 3 would allow us to
 * add props to the layout/input or preview component, but not ComponentType
 *
 * You might think the following would be possible:
 *
 * ```typescriptreact
 *      <Dropzone
 *          PreviewComponent={
 *              (dropZoneProps) =>
 *                  <UploadPreview {...dropzoneProps} myCustomProp={someValue} />
 *          }
 *      >
 * ```
 *
 * And although it would succeed in customizing  myCustomProp={someValue}
 * it would have a very bad side-effect:
 * UploadPreview will mount and unmount on every render.
 *
 * That happens because PreviewComponent (and others) expects
 * a component type and when we give it an anonymous function
 * it considers that function to be the component, therefore,
 * every time our component renders, that anonymous function
 * will have a new reference and be considered to be a different
 * component type by the react reconciliator.
 */
export const FileDropzoneInputContextProvider: React.FC<FileDropzoneInputContextProps> = ({
    children,
    removeDialogProps,
    disabled
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const clearInputValue = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    }, []);

    return (
        <FileDropzoneInputContext.Provider value={{ inputRef, clearInputValue, removeDialogProps, disabled }}>
            {children}
        </FileDropzoneInputContext.Provider>
    );
};

export const useFileDropzoneInputContext = () => {
    return useContext(FileDropzoneInputContext);
};
