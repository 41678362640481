import { useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ReactComponent as EmptyIcon } from "icon-library/SVG/User_Doctor-Stethoscope-Shoulder.svg";
import { TableList, PersonListItem } from "lib/ui-components/table-list";
import EmptyMessage from "common/components/empty-message/empty-message";
import * as CSS from "../class-names";
import { CareTeamMemberDetails } from "../types";
import { getDisplayRole, filterMembers } from "./utils";
import { DetailsSearch } from "./details-search";

const messages = defineMessages({
    ADMIN: { id: "careTeam.members.roles.admin" },
    PRIMARY: { id: "careTeam.members.roles.primary" },
});

export type CareTeamMemberListProps = {
    members: CareTeamMemberDetails[];
};
export const CareTeamMemberList = ({ members }: CareTeamMemberListProps) => {
    const intl = useIntl();
    const [filterValue, setFilterValue] = useState("");
    const [filteredMembers, setFilteredMembers] = useState(members);

    useEffect(() => {
        setFilteredMembers(filterMembers(members, filterValue));
    }, [filterValue, members]);

    return (
        <>
            <DetailsSearch
                idPrefix="QA_careTeam_details_member"
                placeholder={intl.formatMessage({ id: "careTeam.details.members.search.placeholder" })}
                onChange={setFilterValue}
            />
            <TableList
                id="QA_careTeam_details_member_list"
                testId="QA_careTeam_details_member_list"
                size="big"
                variant="alternated"
            >
                {filteredMembers.map((member) => {
                    const displayRole = getDisplayRole(member);
                    return (
                        <PersonListItem
                            key={member.providerId}
                            id={member.providerId}
                            idPrefix="QA_careTeam_details_member_"
                            name={member.fullName}
                            role={displayRole && intl.formatMessage(messages[displayRole])}
                        />
                    );
                })}
            </TableList>
            {filteredMembers.length === 0 && (
                <EmptyMessage
                    className={CSS.CareTeamDetailsContentMembersEmptyMessage}
                    variant="content"
                    Icon={<EmptyIcon />}
                    primaryMessage={{ id: "careTeam.details.search.empty.title" }}
                    secondaryMessage={{ id: "careTeam.details.search.empty.subtitle" }}
                />
            )}
        </>
    );
};
