import { makeStyles } from "@mui/styles";
import  { BORDER_RADIUS, COLORS, SIZING } from "theme";

export const useStyles = makeStyles((theme:any) => {
    return {
        footer: {
            height: '60px',
            width: "100%",
            display: 'flex',
            alignItems: 'center',
            padding: SIZING.scale400,
            color: ({ theme }:any)=>  isGreyTheme(theme)?COLORS.DBLACK : COLORS.WHITE,
            background: ({ theme }:any)=>  isGreyTheme(theme)?COLORS.DGREY : COLORS.GREY,
            "& svg ": {
                width: SIZING.scale700,
                height: SIZING.scale700,
                "& path": {
                    fill:  ({ theme }:any)=> isGreyTheme(theme)?COLORS.DBLACK : COLORS.WHITE,
                    [theme.breakpoints.down('lg')]: {
                        fill:`${COLORS.LBLUE} !important`
                    },
                }
            },
            [theme.breakpoints.down('lg')]: {
                position:'absolute',
                background:'transparent !important',
                bottom:0,
                zIndex:999
            },
        },
        optionText:{
            marginLeft:SIZING.scale100,
        },
        option: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '& .helper':{
                marginLeft:SIZING.scale100,
                [theme.breakpoints.down('lg')]: {
                    display:'none'
                },
            }
        },
        endButton: {
            marginLeft: 'auto',
            background: '#C40000',
            marginRight: SIZING.scale400,
            paddingLeft: SIZING.scale600,
            paddingRight: SIZING.scale600,
            color:COLORS.WHITE,
            "&:hover":{
            background: '#C40000',
            },
            fontSize:SIZING.scale400,
            [theme.breakpoints.down('lg')]: {
                display:'none'
            },
        },
        optionsWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            [theme.breakpoints.down('lg')]: {
                justifyContent: 'space-around',
            },
            flex:'1 auto',
            "& p":{
                border:`1px solid rgba(0, 0, 0, 0.1)`,
                borderRadius:BORDER_RADIUS.LARGE,
                padding:`${SIZING.scale100} ${SIZING.scale500}`,
                background:COLORS.WHITE,
                fontSize:SIZING.scale350,
                marginRight: SIZING.scale400,
                [theme.breakpoints.down('lg')]: {
                    minWidth:'90px',
                    color:COLORS.LBLUE,
                    padding:`${SIZING.scale100} ${SIZING.scale200}`,
            },
            }
        },
        inviteButton: {
            marginLeft: 'auto',
            background: '#FFFFFF',
            marginRight: SIZING.scale400,
            paddingLeft: SIZING.scale600,
            paddingRight: SIZING.scale600,
            color: COLORS.WHITE,
            "&:hover": {
                background: '#FFFFFF',
            },
            fontSize: SIZING.scale400,
            border: "1.2px solid #0F7BBD",
            [theme.breakpoints.down('lg')]: {
                display: 'none'
            },
        },
        pauseButton: {
            marginLeft: 'auto',
            background: '#FFFFFF',
            marginRight: SIZING.scale400,
            paddingLeft: SIZING.scale600,
            paddingRight: SIZING.scale600,
            color: COLORS.WHITE,
            "&:hover": {
                background: '#FFFFFF',
            },
            fontSize: SIZING.scale400,
            border: "1.2px solid #0F7BBD",
            [theme.breakpoints.down('lg')]: {
                display: 'none'
            },
        },

    };
})


const isGreyTheme = (theme:string) => theme==='grey'