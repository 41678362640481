import { getAppCurrentProfileId } from "authentication/appAuth";
import { useEnabledPermissions } from "lib/security/permission-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "store/actions";
import { AppReduxStore } from "store/reducerTypes";
import { getConfig } from "views/PEPHome/pep-config";
import { PEPFooter } from "views/PEPHome/pep-footer";
import { PEPHeader } from "views/PEPHome/pep-header";
import { PEPNavigation } from "views/PEPHome/pep-navigation";
import { useStyles } from "./styles";

const AhaReport = () => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const { currentProfileRoleJoin } = user;
    // const isAuthenticated = !!currentProfileRoleJoin;
    // const name = (isAuthenticated) ? ((currentProfileRoleJoin?.providerProfile) ? currentProfileRoleJoin.providerProfile.firstName : user.firstName) : "";
    const config = getConfig("bnt");
    const grantedPermissions = useEnabledPermissions();
    const dispatch = useDispatch();
    const classes = useStyles({ isPCRStatus: false });
    const reportURL = "/ahareport/report.html";
    // const URL = "https://app.powerbi.com/view?r=eyJrIjoiYTczNGZlN2MtNWMwYS00OWM4LTliZTUtZmMzNjc0N2Y2MDI0IiwidCI6ImUzOWFjNjU3LTM5MmQtNDRmNi04NDMxLWEyMjY4ZDM2ODE3NyIsImMiOjZ9";

    useEffect(() => {
        const getUser = async () => {
            dispatch(getCurrentUser(getAppCurrentProfileId()));
        }
        if (!user.username) {
            getUser();
        }
    }, [dispatch, user.username])

    return (<>
        <PEPHeader providerConfig={config} />
        <PEPNavigation providerConfig={config} permissions={grantedPermissions} profile={currentProfileRoleJoin} />
        <div className={classes.root}>
            <br />
            <iframe title={"AHA Report"} src={reportURL} width="1225" height="740" frameBorder="0" />
        </div >
        <PEPFooter providerConfig={config} />
    </>);
}

export default AhaReport;