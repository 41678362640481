import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { Box, Typography,Grid } from "@mui/material";
import Spinner from "common/components/Spinner/Spinner";
import {ReactComponent as BuildingHospital} from "icon-library/SVG/Building-Light_Hospital-H.svg";
import {ReactComponent as ChevronRight} from "icon-library/SVG/Chevron_Right.svg";
import {ReactComponent as UserSingle} from "icon-library/SVG/User_Single.svg";
import {ReactComponent as BuildingSNF} from "icon-library/SVG/Building-SNF.svg";
import {ReactComponent as Home} from "icon-library/SVG/Home.svg";
import {isEmpty, map} from "underscore";
import { useStyles } from "./styles";
import { getDischargeAlert } from "store/actions";
import {FormattedRelativeTime} from "react-intl";
import EmptyContentMessage from "common/components/EmptyContentMessage/EmptyContentMessage";
import {EmptyForms} from "assets";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { createSvgIcon } from "utils";
import { ALERT_TYPE } from "views/People/Alerts/utils";
import { PatientAlertsProps} from './types';

const BuildingHospitalIcon = createSvgIcon(BuildingHospital);
const ChevronRightIcon = createSvgIcon(ChevronRight);
const HomeIcon = createSvgIcon(Home);
const UserSingleIcon = createSvgIcon(UserSingle);
const BuildingSNFIcon = createSvgIcon(BuildingSNF);
const Alerts = ({ onAlertSelected }: PatientAlertsProps) => {
    const dispatch = useDispatch()
    const history = useHistory()

     // @ts-ignore
    const {loadingAlerts, alerts, selectedPatientId,selectedPatient} = useSelector((state: AppReduxStore) => state.patient)
    
    const details = selectedPatient?.evistDetails || {};
    const classes = useStyles(Object.keys(details).length !== 0);

    useEffect(() => {
         // @ts-ignore
        dispatch(getDischargeAlert(selectedPatientId))
    }, [dispatch, selectedPatientId])

    const handleNavigation = (id: string) =>{
        if (onAlertSelected) {
            // Use internal navigation
            onAlertSelected(id);
        } else {
            // TODO Question: Do we need page navigation for alert details, moreover do we need separated page for that?
            // Use page navigation
            history.push(`/provider/people/patients/${selectedPatientId}/alerts/${id}`);
        }
    }

    const getIcons = (type:string = '') =>{
        switch (type) {
            case ALERT_TYPE.DISCHARGE:
                return  [<BuildingHospitalIcon />,<ChevronRightIcon />,<HomeIcon/>]
            case ALERT_TYPE.TRANSFERRED:
                return  [<BuildingHospitalIcon />,<ChevronRightIcon />,<BuildingSNFIcon/>]
            case ALERT_TYPE.ADMITTED:
                return  [<UserSingleIcon />,<ChevronRightIcon />,<BuildingHospitalIcon/>]
            default:
                return '-'
        }
    }

    const getDesc = (type:string = '',alertDetails:any) =>{
        switch (type) {
            case ALERT_TYPE.DISCHARGE:
                return 'RETURNED HOME' 
            case ALERT_TYPE.TRANSFERRED:
                return  [`TRANSFERRED TO`,<span>{alertDetails?.visitLocationFacility || '-'}</span>]
            case ALERT_TYPE.ADMITTED:
                return  ['ADMITTED TO',<span>{alertDetails?.visitLocationFacility || '-'}</span>]
            default:
                return '-'
        }
    }

    const getTitle = (type:string = '') =>{
        switch (type) {
            case ALERT_TYPE.DISCHARGE:
                return 'Discharged from Hospital' 
            case ALERT_TYPE.TRANSFERRED:
                return  'transferred'
            case ALERT_TYPE.ADMITTED:
                return  'Admitted to Hospital'
            default:
                return '-'
        } 
    }

    // Show loading spinner
    if (loadingAlerts) return <Spinner/>
    // If no alerts, show appropriate message
    if (isEmpty(alerts)) return <div className={classes.empty}><EmptyContentMessage Icon={EmptyForms} title="People.alerts.none" subtitle={null}/></div>
    
    return (
        <Box className={classes.wrapper}>
            <Grid  container spacing={2} className="alert-grid-wrapper">
                {/*@ts-ignore*/}
                {map(alerts, (alert, idx) => {
                    const {visitDateTime,adtEventId='',adtEventType,dischargeDateTime} = alert
                    const dateTime = adtEventType === ALERT_TYPE.DISCHARGE ? dischargeDateTime : visitDateTime;
                    const date = new Date(dateTime)
                    const time = Math.floor((new Date(dateTime).getTime() - Date.now()) / 1000)

                    return (
                        <Grid item xs={12} >
                            <Box className={`${classes.contentWrapper} "read"`}  key={idx} id="QA_patientAlerts">
                                <div className={classes.details}>
                                    <div className="main-details-wrapper">                                        
                                        <Typography variant="body1" className="title">
                                            {getTitle(alert?.adtEventType)}
                                        </Typography>
                                        <div className="divider" />
                                        <Typography className="alert-description">
                                            {getDesc(alert?.adtEventType, alert)}
                                        </Typography>
                                        <Typography className="alert-details">
                                        <Typography variant="h6" className="date">
                                            {date ? moment(date).format('MMM DD,YYYY hh:mm A') : '-'} (<FormattedRelativeTime style={`short`} value={time} updateIntervalInSeconds={5}/>)
                                        </Typography>
                                         <Typography variant="h6" className="summaryPageLink" onClick={() => handleNavigation(adtEventId)}>See Notes</Typography>
                                        </Typography>
                                        <Typography variant="h6" className="startTime">
                                            <div className={classes.iconWrapper}>
                                                {getIcons(alert?.adtEventType)}
                                            </div>
                                        </Typography>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default Alerts