import { makeStyles } from "@mui/styles";
import { SIZING } from "admin/theme";

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        marginBottom: SIZING.scale200
    },
    dynamicFieldValues: {
        marginLeft: SIZING.scale200,
        color: '#909098'
    }
}));