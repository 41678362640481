import createReducer from '../createReducer'
import { ModalState } from '../reducerTypes'

const initialState: ModalState = {
    activeModal: "",
    isWelcomeModalActive: false,
    modalProps: null
}

export default createReducer(initialState, {
    'ADMIN_SET_ACTIVE_MODAL': (state: ModalState, payload: ModalState): ModalState => {
        return {
            ...state,
            activeModal: payload.activeModal,
            modalProps: payload.modalProps
        }
    },
    'ADMIN_SET_WELCOME_MODAL_ACTIVE': (state: ModalState, payload: ModalState): ModalState => {
        const { isWelcomeModalActive } = payload
        return {
            ...state,
            isWelcomeModalActive,
        }
    },
})