import { DialogContent, Box, Button, DialogActions, Typography, FormControl, Select, MenuItem } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { Fragment, useState } from "react";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useRouteMatch } from "react-router-dom";
import { createPicFormApproval, UpdatePicFormApproval } from "admin/store/actions";

const SendForReviewModal = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const matchId: any = useRouteMatch("/admin/configurations/patientforms/:id");
    const formId = matchId?.params?.id ? matchId?.params?.id : null;  
    const { auth, configuration } = useSelector((state: AppReduxStore) => state);
    const approverList = configuration && configuration.approverList;
    const currentForm: any = configuration && configuration.sPatientFormA ? configuration.sPatientFormA : null;
    const approverProfileId = auth && auth.currentProfiles !== null ? auth.currentProfiles[0].providerProfile !== null ? auth.currentProfiles[0].providerProfile?.id : "" : "";
    const userId = auth && auth.userDetails !== null ? auth.userDetails.profileRoleJoinInfos[0].providerProfile !== null ? auth.userDetails.profileRoleJoinInfos[0].providerProfile.id : "" : "";
    const classes = useStyles();
    const [choice, setChoice] = useState('');
    const allFormVersioning = configuration.allFormVersioning;
    
    const onClose = () => {
        dispatch(setActiveModal(''));
    }

    const onCreate = (event: any) => {
        event.preventDefault();
        const formApprovalId: any =
            allFormVersioning.length !== 0
                ? allFormVersioning.find((ele: any) => ele.id === currentForm.id)?.questionnaireApprovals[0]?.id
                : "";
        if(formApprovalId !== '' && formApprovalId !== undefined){
            const updateQuestionnaireApproval = {
                'id': formApprovalId,
                "approvalStatus": "PENDING_APPROVAL",
                "approverProfileId": approverProfileId,   //- profile id
                "createdBy": userId, //currently logged in user id
                "questionnaireId": formId, //required form id
                "reviewComments": "Please review this form for me." //optional but nice to have You do not need to supply the name fields.
            }
            dispatch(UpdatePicFormApproval(updateQuestionnaireApproval));
        }else{
            const questionnaireApproval = {
                "approvalStatus": "PENDING_APPROVAL",
                "approverProfileId": approverProfileId,   //- profile id
                "createdBy": userId, //currently logged in user id
                "questionnaireId": currentForm.id, //required form id
                "reviewComments": "Please review this form for me." //optional but nice to have You do not need to supply the name fields.
            }
            dispatch(createPicFormApproval(questionnaireApproval))
        }
        onClose();
    };

    const modalClasses = {
        paper: classes.wrapper
    };
      
    const Placeholder = ({ children } : any) => {
    return <div className={classes.placeholder}>{children}</div>;
    };

    return (
        <Modal id={MODAL_TYPES.SEND_FOR_REVIEW} onClose={onClose} classes={modalClasses} small={true}>
            <DialogActions className={classes.dlgAction1}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "SendForReviewModal.Title" })}
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {currentForm?.number && `# ${currentForm?.number} - `}
                        {`${currentForm?.title}?`}
                    </Typography>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction2}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined"
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "SendForReviewModal.CancelButton" })}
                        </Button>
                        <Button id="btnCreate" variant="contained" color="primary" disabled={choice !== "" ? false : true} style={{ marginLeft: '10px' }} onClick={onCreate}>
                            {intl.formatMessage({ id: "SendForReviewModal.Title" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Typography variant="h4" gutterBottom className={classes.title2}>
                    {intl.formatMessage({ id: "SendForReviewModal.Title2" })}
                </Typography>
                <Typography variant="h6" gutterBottom className={classes.title3}>
                    {intl.formatMessage({ id: "SendForReviewModal.Title3" })}
                </Typography>
                <FormControl sx={{width: '380px'}}>
                    <Select
                    value={choice}
                    id={intl.formatMessage({ id: "SendForReviewModal.Select" })}
                    displayEmpty
                    onChange={(event:any) => {
                        setChoice(event.target.value)
                    }}
                    renderValue={
                        choice !== "" ? undefined : () => <Placeholder>{intl.formatMessage({ id: "SendForReviewModal.Select" })}</Placeholder>
                      }
                    >
                    {approverList && approverList.map((approver : any, idx) => <MenuItem value={approver.profileId}>{approver.firstName + ' ' + approver.lastName + ' - ' + approver.levelName}</MenuItem>)}
                    </Select>
                </FormControl>
                {approverList && approverList.length === 0 && <>
                    <Typography variant="h3" gutterBottom className={classes.warning}>
                    {intl.formatMessage({ id: "SendForReviewModal.Warning" })}
                    </Typography>
                    <Typography variant="h3" gutterBottom>
                        {intl.formatMessage({ id: "SendForReviewModal.Body" })}
                    </Typography>
                </>
                }
            </DialogContent>
        </Modal>
    )
}

export default SendForReviewModal;