import { useState, useEffect, useCallback } from "react";
import { useApi } from "common/utils/use-api";
import { useUserNotification } from "common/utils/use-user-notification";
import useBatchQueue from "lib/batch-queue/use-batch-queue";
import { ThumbnailLocationsRequest, FileMetadataDetails, ThumbnailDimensions, ThumbnailLocation } from "../types";

export type useThumbnailsParams = {
    files?: FileMetadataDetails[];
};

type ThumbnailLocationMap = Record<string, string | undefined>;

export const useThumbnails = ({ files }: useThumbnailsParams) => {
    // thumbnail batch api limits to 32 thumbnails at a time
    const { currentBatch, addItems, nextBatch, isInQueue } = useBatchQueue<string>(32);
    const [thumbnailLocations, setThumbnailLocations] = useState<ThumbnailLocationMap | null>(null);
    const { enqueueError } = useUserNotification();

    const { content: thumbnailLocationsResponse, fetchInfo: thumbnailsFetchInfo} = useApi<
        ThumbnailLocationsRequest,
        ThumbnailLocation[] | null
    >(
        {
            defaultContent: null,
            endpoint: "/file/v1/files/thumbs/location",
            method: "POST",
            autoRequest: true,
            timeout: -1,
            requestDecisionMaker: (params) => {
                return Boolean(params?.keys?.length);
            },
        },
        {
            keys: currentBatch?.map((id) => ({ id, dimension: ThumbnailDimensions.x128 })) || null,
        }
    );

    useEffect(() => {
        if (files) {
            const thumbnailIds = files
                .filter((file) => {
                    // for the time being we only use x128 thumbs
                    // maybe in the future we will also try to display x64.
                    // Another change that might be interesting is the case where the
                    // image is smaller than the thumbnail, in which case the thumbnail will
                    // not exist and we might want to show the original image.
                    // We didn't implemented that so far because we agreed that small
                    // images are a unlikely use case
                    const hasThumbnailDefinition = Boolean(file.thumbs.find((t) => t === ThumbnailDimensions.x128));
                    const notYetLoaded = Boolean(!thumbnailLocations?.[file.id]);
                    return (hasThumbnailDefinition && notYetLoaded);
                })
                .map((file: FileMetadataDetails) => file.id);

            if (thumbnailIds.length) {
                addItems(thumbnailIds);
            }
        }
    }, [files, thumbnailLocations, addItems]);

    useEffect(() => {
        if (thumbnailLocationsResponse?.length) {
            const thumbnailMap = thumbnailLocationsResponse.reduce((map, item) => {
                map[item.id] = item.url;
                return map;
            }, {} as ThumbnailLocationMap);
            setThumbnailLocations((previousState) => ({
                ...previousState,
                ...thumbnailMap,
            }));
            nextBatch();
        }
    }, [thumbnailLocationsResponse, nextBatch]);

    const fetchState = thumbnailsFetchInfo.state;
    useEffect(() => {
        if (fetchState === "failed") {
            enqueueError("files.fileGridView.failedToLoadThumbnails");
        }
    }, [fetchState, enqueueError]);

    const isThumbnailLoading = useCallback((id: string) => {
        if (fetchState === "failed") {
            return false;
        }
        return isInQueue(id);
    }, [fetchState, isInQueue]);

    return { thumbnailLocations, isThumbnailLoading };
};
