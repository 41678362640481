import { makeStyles } from "@mui/styles";
import { SIZING } from "theme";

export const useStyles = makeStyles({
    ContextMenu: {
        cursor: "pointer",
        "& svg": {
            minWidth: SIZING.scale600,
            minHeight: SIZING.scale600,
        },
    },
    ContextMenu__List: {
        "& svg": {
            minWidth: SIZING.scale600,
            minHeight: SIZING.scale600,
        },
    },
});
