// TODO Cleanup is required
import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, COLORS, SIZING } from "theme";

interface useStyleProps {
  checked?: string;
  themeColor?: string;
  renderPinnedComponent?: boolean;
  dropDownPosition?: any;
}

const isComposeModal = (type: string | undefined) => type === "compose";
const isPositionTop = (dropDownPosition: string) => dropDownPosition === "top";

export const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: BORDER_RADIUS.MED_LARGE,
    backgroundColor: ({ themeColor }: useStyleProps) => {
      return (
        (isComposeModal(themeColor) ? COLORS.ITEM_BADGE_BG : COLORS.INPUT_BG) +
        "!important"
      );
    },
    cursor: "pointer",
    marginTop: SIZING.scale175,
    zIndex: 10,
  },
  root: {
    minWidth: ({ dropDownPosition }: useStyleProps) =>
      isPositionTop(dropDownPosition) ? "unset" : "100%",
    height: "300px",
    overflow: "hidden",
    padding: SIZING.scale400,
    position: "relative",
    top: ({ dropDownPosition }: useStyleProps) =>
      isPositionTop(dropDownPosition) ? "-315px" : "unset",
    width: ({ dropDownPosition }: useStyleProps) =>
      isPositionTop(dropDownPosition) ? "50%" : "auto",
    "& ::-webkit-scrollbar": {
      width: SIZING.scale50,
      height: SIZING.scale100,
      cursor: "pointer",
    },

    "& ::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0)",
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0)",
    },

    "& ::-webkit-scrollbar-thumb": {
      backgroundColor: COLORS.COMPOSE_SCROLL_BAR,
    },
    "& .closeIcon": {
      position: "absolute",
      right: SIZING.scale400,
      top: SIZING.scale400,
      height: SIZING.scale600,
      width: SIZING.scale600,
      "& g": {
        "& path": {
          fill: ({ themeColor }: useStyleProps) =>
            isComposeModal(themeColor) ? "" : COLORS.ALERTS_ICON,
        },
      },
    },
    "& .itemWrapper": {
      overflowY: "auto",
      height: "calc(100% - 1.875rem)",
    },
  },

  inputWrapper: {
    //maxWidth: "calc( 675px - 4rem )",
    width: "100%",
    overflowX: ({ renderPinnedComponent }: useStyleProps) =>
      !renderPinnedComponent ? "hidden" : "auto",
    cursor: "pointer",
    "& .MuiInput-input": {
      minWidth: "150px",
      minHeight: "2.875rem",
      textIndent: SIZING.scale200,
    },
  },

  inputPopOverWrapper: {
    "& .popover-backover": {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      zIndex: "1",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
    },
    position: "relative",
    flex: 1,
    "& .MuiPaper-root": {
      position: "absolute",
      zIndex: "9",
      overflow: "visible",
    },
    "&>.MuiBox-root": {
      borderBottomRightRadius: BORDER_RADIUS.MULTISELECT_RADIUS_RIGHT,
      borderBottomLeftRadius: BORDER_RADIUS.MULTISELECT_RADIUS_LEFT,
      overflow: "hidden",
      "& ::-webkit-scrollbar": {
        width: SIZING.scale50,
        height: SIZING.scale100,
        cursor: "pointer",
      },

      "& ::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0)",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0)",
      },

      "& ::-webkit-scrollbar-thumb": {
        backgroundColor: COLORS.COMPOSE_SCROLL_BAR,
      },
    },
  },
  popupTriangle: {
    "&.triangleLeft::before": {
      margin: `auto auto auto ${SIZING.scale250}`
    },
    "&.triangleCenter::before": {
      margin: `auto`
    },
    "&::before": {
      width: 0,
      content: "''",
      height: 0,
      borderLeft: `${SIZING.scale400} solid transparent`,
      borderRight: `${SIZING.scale400} solid transparent`,
      borderBottom: ({ themeColor }) =>
        `${SIZING.scale500} solid ${
          isComposeModal(themeColor) ? COLORS.ITEM_BADGE_BG : COLORS.INPUT_BG
        }`,
      position: "absolute",
      top: ({ dropDownPosition }: useStyleProps) =>
        isPositionTop(dropDownPosition) ? "unset" : "-15px",
      bottom: ({ dropDownPosition }: useStyleProps) =>
        !isPositionTop(dropDownPosition) ? "unset" : "-15px",
      transform: ({ dropDownPosition }: useStyleProps) =>
        isPositionTop(dropDownPosition) ? "rotate(180deg)" : "none",
      left: 0,
      right: 0,
    },
  },
  formControl: {
    display: "flex",
    alignItems: "center !important",
    backgroundColor: ({ checked, themeColor }: useStyleProps) =>
      checked
        ? isComposeModal(themeColor)
          ? COLORS.MODAL_BG
          : COLORS.SELECTED_NAV_TEXT
        : isComposeModal(themeColor)
        ? COLORS.MULTI_SELECT_BG
        : COLORS.MODAL_BG,
    margin: `${SIZING.scale200} 0 !important`,
    paddingRight: SIZING.scale200,
    borderRadius: BORDER_RADIUS.SMALL,

    "& .MuiSvgIcon-root": {
      color: ({ checked, themeColor }: useStyleProps) =>
        checked
          ? isComposeModal(themeColor)
            ? COLORS.BADGE_BG
            : COLORS.INPUT_BG
          : isComposeModal(themeColor)
          ? "rgb(240 240 242 / 24%)"
          : COLORS.BADGE_BG,
    },
    "& .name": {
      color: ({ checked, themeColor }: useStyleProps) =>
        checked
          ? isComposeModal(themeColor)
            ? COLORS.BADGE_BG
            : COLORS.INPUT_BG
          : isComposeModal(themeColor)
          ? COLORS.INPUT_BG
          : COLORS.EVIST_INVITE_BG,
    },
    "& .match": {
      color: ({ checked, themeColor }: useStyleProps) =>
        isComposeModal(themeColor)
          ? checked
            ? COLORS.ERROR_TEXT
            : COLORS.YELLOW
          : checked
          ? COLORS.YELLOW
          : COLORS.ERROR_TEXT,
      textDecoration: "underline",
    },
  },
  formControlLabel: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    color: ({ checked, themeColor }: useStyleProps) =>
      isComposeModal(themeColor)
        ? checked
          ? COLORS.LIST_SUBHEADER_TEXT
          : COLORS.INPUT_BG
        : checked
        ? COLORS.INPUT_BG
        : COLORS.EVIST_INVITE_BG,
  },
  checkBoxIcon: {
    width: SIZING.scale800,
    "& path": {
      fill: ({ themeColor }: useStyleProps) =>
        isComposeModal(themeColor)
          ? COLORS.CHECKBOX_ICON_BG
          : COLORS.DEFAULT_BTN,
    },
  },
  checkBoxIconSelected: {
    width: SIZING.scale800,
    "& path": {
      fill: ({ themeColor }: useStyleProps) =>
        isComposeModal(themeColor) ? COLORS.DEFAULT_BTN : COLORS.INPUT_BG,
    },
  },
  searchIcon: {
    marginLeft: "0.313rem",
    display: "flex",
    alignItems: "center",
  },
  customPinnedItems: {
    position: "absolute",
    left: SIZING.scale1000,
    color: COLORS.DBLACK,
    zIndex: 1,
    cursor: "text",
    background: COLORS.WHITE,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
    popoverHeader: {
        display: "flex",
        justifyContent: "space-between",
        padding: SIZING.scale400,
        "& .title": {
            color: ({ themeColor }: useStyleProps) =>
                isComposeModal(themeColor) ? COLORS.PRIMARY_BTN : COLORS.ITEM_BADGE_BG,
            fontWeight: 800,
            paddingBottom: SIZING.scale100,
            fontSize: SIZING.scale325,
            textTransform: "uppercase"
        }
    },
    popoverBody: {
      maxHeight: '22.5rem',
      overflowY: 'auto',
      padding: `0 ${SIZING.scale400} ${SIZING.scale200} ${SIZING.scale400}`
    },
    popoverLoaderWrapper: {
      position: "relative",
      height: SIZING.scale1200,
      padding: `0 ${SIZING.scale400} ${SIZING.scale200} ${SIZING.scale400}`,
      boxSizing: "content-box",
      "& > *": {
        bottom: "unset",
        height: "unset"
      }
    },
    popoverItem: {
        alignItems: "center",
        backgroundColor: COLORS.MULTI_SELECT_BG,
        borderRadius: SIZING.scale100,
        color: COLORS.WHITE,
        display: "flex",
        fontSize: SIZING.scale400,
        fontWeight: "bold",
        marginBottom: SIZING.scale200,
        "& .highlighted": {
            color: COLORS.YELLOW,
            textDecoration: "underline"
        },
        "& .popoverLabelContent": {
          marginRight: SIZING.scale400,
          textOverflow: 'ellipsis',
          overflow: "hidden",
          flexGrow: 1
        },
        "& .popoverCheckboxRoot": {
          padding: SIZING.scale125,
          "&:hover": {
            backgroundColor: "unset"
          }
        }
    }
}));
