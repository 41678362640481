import {
    Box, DialogContent, Grid, Typography
} from "@mui/material";
import { ReactComponent as CirclesHexagonGrid } from "icon-library/SVG/Circles_Hexagon-Grid.svg";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createSvgIcon } from "utils";
import CancellationTypesListing from "../../CancellationTypes/CancellationTypesListing";
import { useStyles } from "./styles";
const CirclesHexagonGridIcon = createSvgIcon(CirclesHexagonGrid);

const EvisitCancellationTypes = forwardRef((props, ref) => {
    const intl = useIntl();
    const classes = useStyles();
    const editCancellationTypeFormRef = useRef<any>();

    useImperativeHandle(
        ref,
        () => ({
            saveAction(e: any) {
                return editCancellationTypeFormRef.current.submitForm(e);
            },
            saveAndContinueLaterAction(index: number) {
                return editCancellationTypeFormRef.current.saveAndContinueLater(index);
            }
        }),
    )

    return (<>
        <Grid item className={classes.productsPageHeader}>
            <Typography className={classes.pageHeaderText}>
                <CirclesHexagonGridIcon fill="#0F7BBD" className={classes.productsIcon} />
                <FormattedMessage id="Practice.Configuration.Products.Heading" />
            </Typography>
            <Typography className={classes.pageSubHeaderText}>
                <FormattedMessage id={"Practice.Configuration.Products.SubHeading"} />
            </Typography>
        </Grid>
        <DialogContent className={classes.contentDialog}>
            <Box mb={1} className={classes.formGpHeader}>
                {intl.formatMessage({ id: "CancellationTypes.Text.CancellationTypes" })}
            </Box>
            <Box mb={1} className={classes.formGpSubHeader}>
                {intl.formatMessage({ id: "CancellationTypes.Text.CancellationTypeId" })}
            </Box>
            <CancellationTypesListing ref={editCancellationTypeFormRef} />
        </DialogContent>
    </>);
})

export default EvisitCancellationTypes