import lodash from "lodash";
 
export const cleanObject = (objToClean: Object | null | undefined, keysToDelete: string[]): Object | null | undefined => {
    if (!objToClean) {
        return objToClean;
    }
    const preparedObject = Object.create(Object.getPrototypeOf(objToClean));
    Object.keys(objToClean).forEach((key) => {
        if (!keysToDelete.includes(key)) {
            // @ts-ignore
            preparedObject[key] = objToClean[key];
        }
    });
    return preparedObject;
};

export const deepCopy = <O> (objToClone: O): O => {
    return lodash.cloneDeep(objToClone);
}
