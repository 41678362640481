import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
    continueLaterBanner: {
        backgroundColor: "#0F7BBD",
        padding: '12px',
        marginBottom: '12px'
    },
    continueLaterBannerHeading: {
        fontSize: '17px',
        fontWeight: 700,
        color: '#FFFFFF'
    },
    continueLaterBannerSubHeading: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#FFFFFF'
    }
}))