import { makeStyles } from "@mui/styles";
import { COLORS } from "theme";
import { BORDER_RADIUS } from "../../../theme";


export const useStyles = makeStyles(() =>({
    wrapper: {
        width:"1136px",
        height:"680px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogAction: {
        minHeight: '84px',
        padding: '5px 32px',
        justifyContent: "center"
    },
    dialogHeaderTitle: {
        flex: 1,
        color: '#565662',
        fontWeight:'bold'
    },
    dialogContentRoot: {
        flex: '1 1 auto',
        overflowY: 'auto',
        background:'#F6F8FA'
    },
    dialogContentDividers: {
        padding: '0 0 0 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    dialogHeader: {
        minWidth: '980px',
        display: 'flex',
        flexDirection: 'column',
        background:'#EDEDEF'
    },
    dialogHeaderTop: {
        display: 'flex'
    },
    contentArea: {
        display: 'flex',
        flex: '1 1 auto'
    },
    closeButtonIcon: {
        height: '45px',
        width: '45px',
        verticalAlign: 'middle',
        marginLeft:'20px',
        cursor:'pointer',
        "& path": {
            fill: "radial-gradient(98.44% 98.44% at 50% 1.56%, rgba(255, 255, 255, 0.2) 0%, rgba(126, 144, 179, 0) 90.63%), #565662"
        }
    },
    modalContent:{
        marginTop:'30px',
        marginBottom:'14px'
    },
    modalContainer:{
        color:'#393948',
        fontSize:'32px',
        fontWeight:600,
        textAlign:'center'
    },
    modalContainertext:{
        fontSize:'16px',
        color:'#565662',
        textAlign:'center',
        marginTop:'20px'
    },
    selectionBox:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    contentBox:{
       height:'400px',
       width:'400px',
       background:'#FFFFFF',
       borderRadius:'16px',
       border:'1px solid #DBDBDE',
       margin:'18px',
       cursor:'pointer',
       textAlign:'center',
       color:'black',
       display:'flex',
       flexDirection:'column',
       alignItems:'center',
       padding:'48px'
    },
    BuildIcon:{
        height:'100px',
        width:'100px',
        "& path": {
            fill:COLORS.DEFAULT_BTN
        },
        marginBottom:'14px'
    },
    textTitle:{
        color:'#393948',
        fontSize:'28px',
        fontWeight:600,
        padding:'15px'
    },
    textDesc:{
        fontSize:'14px',
        color:'#565662',
        paddingLeft:'16px',
        textAlign:'start'
    }
}));