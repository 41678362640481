import React, { useState } from "react";
import commonValidator from "./validationHelper"
import { useIntl } from "react-intl";

export function useForm(initialFValues?: any, customValidation: any = {}) {
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({} as any);
    const [formSubmitted, updateFormSubmitted] = useState(false)
    const intl = useIntl()
    const [fields, updateFields] = useState({})

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        //@ts-ignore
        formSubmitted && validator(name, fields[name])
    }
    const resetForm = (isHideAllMessages: boolean = false) => {
        setErrors({})
        isHideAllMessages && hideAllMessage()
    }
    const hideAllMessage = () => {
        updateFormSubmitted(false)
    }
    const updateValues = (name: string, value: any) => {
        setValues({
            ...values,
            [name]: value
        })
    }
    const validateAll = () => {
        updateFormSubmitted(true)
        return !Object.keys(errors).some((key: string) => {
            //@ts-ignore
            return errors[key]
        })
    }
    const addFields = (key: string, validation: any) => {
        //@ts-ignore
        if ((fields[key] || '') !== validation) {
            updateFields({
                ...fields,
                [key]: validation
            })
        }
    }
    const addErrors = (key: string, error: any) => {
        //@ts-ignore
        if (errors[key] !== error) {
            setErrors({
                ...errors,
                [key]: error
            })
        }
    }
    const validator = (key: string, validation: any, addFieldStatus = true, value: any = '') => {
        addFieldStatus && addFields(key, validation)
        const validationDetails = validation.split("|");
        const validators = {
            ...commonValidator,
            ...customValidation
        }
        let errorMessage = ''
       const result = validationDetails.some((validationLabel: string) => {
            const validatorFunc = validators[validationLabel];
            if (validatorFunc) {
                const r = validatorFunc(value, key, intl);
                errorMessage = r
           
                return !!r
            } else {
                console.error('unknown validator', validationLabel)
                return false
            }
        })

        addErrors(key, result)
        
        return formSubmitted ? errorMessage : ''
    }
    const renderMessage = (key: string, validation: any, value?: any) => {
       return validator(key, validation, true, value)
    }
    return {
        setValues,
        handleInputChange,
        resetForm,
        validateAll,
        renderMessage,
        updateValues
    }
}
export function Form(props: any) {
    const { children, ...other } = props;
    return (
        <form autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

