import { makeStyles } from "@mui/styles";
import { COLORS } from "theme";

export const useStyles = makeStyles(() =>({
    wrapper: {
        height: "650px",
        width: "960px",
        flexGrow: 1,
        flexBasis: "650px",
        background: COLORS.MODAL_BG,
        overflow: "hidden"
    },
    closeButtonWrapper: {
        flexGrow: 1,
        display: "flex",
        alignContent: "right"
    },
    header: {
        minHeight: "210px"
    },
    content: {
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        paddingBottom: "20px",
        flexDirection: "column",
        "& > *": {
            width: "100%",
            padding: 0,
            overflow: "unset"
        }
    },
    contentWrapper: {
        flexGrow: 1,
        overflowY: "auto",
        width: "100%"
    },
}));
