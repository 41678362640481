import { makeStyles } from "@mui/styles";
import  { BORDER_RADIUS, COLORS, SIZING } from "theme";

export const useStyles = makeStyles((theme:any) => ({
        footer: {
            height: '3.75rem',
            width: "100%",
            display: 'flex',
            position:'relative',
            alignItems: 'center',
            color: ({ theme }:any)=>  isGreyTheme(theme)?COLORS.DBLACK : COLORS.WHITE,
            background: ({ theme }:any)=>  isGreyTheme(theme)?COLORS.DGREY : COLORS.GREY,
            boxShadow:'0px -4px 4px rgb(0 0 0 / 10%)',
            "& button .MuiButton-label":{
                fontSize: 'inherit !important'
            },
            paddingRight: SIZING.scale400
        },
        optionText:{
            marginLeft:SIZING.scale100,
        },
        option: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '& .helper':{
                marginLeft:SIZING.scale100,
            }
        },
        endButton: {
            marginLeft: 'auto',
            background: COLORS.RED,
            marginRight: SIZING.scale400,
            paddingLeft: SIZING.scale600,
            paddingRight: SIZING.scale600,
            color:COLORS.WHITE,
            "&:hover":{
            background: COLORS.RED,
            },
            fontSize:SIZING.scale350,
            height:SIZING.scale1000
        },
        optionsWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            paddingLeft:SIZING.scale800,
            flex:'1 auto',
            "& p":{
                border:`1px solid rgba(0, 0, 0, 0.1)`,
                borderRadius:BORDER_RADIUS.LARGE,
                padding:`8px`,
                background:COLORS.WHITE,
                fontSize:SIZING.scale350,
                marginRight: SIZING.scale400,
                height:'40px',
                width:'40px',
                "&.screenshot":{
                    width:'auto',
                    padding: SIZING.scale250,
                    "& > svg":{
                        marginRight: SIZING.scale200
                    }
                }
            },
            "& svg ": {
                width: SIZING.scale700,
                height: SIZING.scale700,
                "& g": {
                    fill:  ({ theme }:any)=> isGreyTheme(theme)?COLORS.DBLACK : COLORS.WHITE,
                }
            }
        },
        inviteButton: {
            marginLeft: 'auto',
            background: COLORS.WHITE,
            marginRight: SIZING.scale400,
            paddingLeft: SIZING.scale600,
            paddingRight: SIZING.scale600,
            color: COLORS.LBLUE,
            "&:hover": {
                background: COLORS.WHITE,
            },
            border: `1.2px solid ${COLORS.LBLUE}`,
            fontSize: SIZING.scale350,
            height:SIZING.scale1000
        },
        pauseButton: {
            marginLeft: 'auto',
            background: COLORS.WHITE,
            marginRight: SIZING.scale400,
            paddingLeft: SIZING.scale600,
            paddingRight: SIZING.scale600,
            color: COLORS.PRIORITY_BG,
            "&:hover": {
                background: COLORS.WHITE,
            },
            border: `1.2px solid ${COLORS.PRIORITY_BG}`,
            fontSize: SIZING.scale350,
            height:SIZING.scale1000
        },
}));

export const isGreyTheme = (theme:string) => theme==='grey'