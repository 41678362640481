import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Input, InputAdornment } from "@mui/material";
import classNames from "classnames";
import { ReactComponent as SearchIcon } from "icon-library/SVG/Search.svg";
import { ReactComponent as UsersIcon } from "icon-library/SVG/User_Users.svg";
import { useApi } from "common/utils/use-api";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { PatientAutocompleteItem, PatientAutocompleteRequest, PatientSearchProps } from "./types";
import PopoverContent from "common/components/PopoverContent/PopoverContent";
import SVGIcon from "common/components/svg-icon/svg-icon";
import EmptyMessage from "common/components/empty-message/empty-message";
import { createPatientPopOverItem } from "./patient-search-item";
import { useStyles } from "./styles";

const MINIMUM_TEXT_LENGTH_FOR_FILTERING = 2;

const PatientSearch = ({ idPrefix, onChange }: PatientSearchProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const {
        content: autoCompleteValues,
        fetchInfo: { hasResult },
    } = useApi<PatientAutocompleteRequest, PatientAutocompleteItem[]>(
        {
            abortOnNewRequest: true,
            autoRequest: true,
            defaultContent: [],
            id: "eConsultPatientsAutocomplete",
            method: "POST",
            endpoint: "/patient/v1/econsult/patients/autocomplete",
            delay: 200,
            requestDecisionMaker: (requestParams) => requestParams.text.length >= MINIMUM_TEXT_LENGTH_FOR_FILTERING,
        },
        {
            limit: 10,
            text: search,
        }
    );

    const onAutocompleteClose = () => {
        setOpen(false);
    };

    const onValueChange = (event: any): void => {
        const newSearch = event.target.value;
        setSearch(newSearch);
        const isOpen = newSearch.trim().length >= MINIMUM_TEXT_LENGTH_FOR_FILTERING;
        setOpen(isOpen);
    };

    const onValueSelected = (selectedId: string): void => {
        const selected = autoCompleteValues.find((item) => item.id === selectedId);
        if (selected) {
            onChange(selected.id);
        }
    };

    const loading = !hasResult;
    return (
        <div className={classes.patientSearchArea}>
            <div className="patientInputAndPopoverWrapper">
                <label htmlFor={`${idPrefix}_textfield`}>
                    <CustomTypography variant="modalSectionHeaderBold" className="panelHeader">
                        <FormattedMessage id="EConsult.patientSearch.selectAPatient" />
                    </CustomTypography>
                </label>
                <Input
                    id={`${idPrefix}_textfield`}
                    autoComplete="off"
                    className={classes.patientInput}
                    defaultValue={search}
                    onKeyUp={onValueChange}
                    placeholder={intl.formatMessage({ id: "EConsult.patientSearch.selectAPatientPlaceholder" })}
                    startAdornment={
                        <InputAdornment position="start">
                            <SVGIcon className={classNames({ "placeholder": !search })} svg={SearchIcon} size="scale400" trim />
                        </InputAdornment>
                    }
                    disableUnderline={true}
                />
                {open && (
                    <PopoverContent
                        idPrefix={`${idPrefix}_popover`}
                        onChange={onValueSelected}
                        onClose={onAutocompleteClose}
                        className={classes.patientPopover}
                        title={intl.formatMessage({ id: "EConsult.patientSearch.selectAPatientPopoverTitle" })}
                        trianglePosition="center"
                        emptyMessage={
                            <p className="emptyContent">{intl.formatMessage({ id: "EConsult.filter.notFound" })}</p>
                        }
                        list={autoCompleteValues.map((item) => createPatientPopOverItem(item, search, intl))}
                        loader={loading}
                    />
                )}
            </div>
            <EmptyMessage
                className="emptyMessage"
                Icon={<SVGIcon svg={UsersIcon} size="scale5000" color="LGRAY" />}
                primaryMessage={{
                    id: "EConsult.patientSearch.emptyMessage.primary",
                    variant: "emptyMessageBigPrimary",
                    color: "LGRAY"
                }}
                secondaryMessage={{
                    id: "EConsult.patientSearch.emptyMessage.secondary",
                    variant: "emptyMessageBigSecondary",
                    color: "LGRAY"
                }}
            />
        </div>
    );
};

export default PatientSearch;
