import { Box, Button, Typography } from "@mui/material";
import React, { useSelector, useDispatch } from "react-redux";
import { AppReduxStore as AppReduxStoreAdmin } from "admin/store/reducerTypes";
import { useHistory } from "react-router-dom";
import RichTooltip from "admin/components/RichToolTip/RichTooltip";
import { toggleWelcomeTooltipSetting } from "admin/store/actions/organization"
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross.svg";
import { createSvgIcon } from "utils";
import { DecorationComponentProps } from "../types";

const CrossIcon = createSvgIcon(CrossSvg);

const EntitiesDecorations = ({ classes }: DecorationComponentProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const adminStore = useSelector((state: AppReduxStoreAdmin) => state);
    const toggleWelcomeTooltip = adminStore.organization?.toggleWelcomeTooltip;

    const {
        location: { pathname },
    } = history;

    const isAdminHome = pathname === "/admin/home";
    const shouldRenderTooltip = toggleWelcomeTooltip && isAdminHome;

    const handleTooltipClose = (e: any) => {
        e && e.preventDefault();
        toggleWelcomeTooltip && dispatch(toggleWelcomeTooltipSetting(false));
    };
    const goToEntities = (e: any) => {
        e.preventDefault()
        history.push('/admin/entities')
    }
    if (!shouldRenderTooltip) {
        return null;
    }

    // TODO: all these texts should come from a translation file
    return (
        <RichTooltip fade={false}
            content={
                <Box textAlign="right">
                    <Box>
                        <CrossIcon fill={"#FFFFFF"} className={classes.infoIcon} onClick={handleTooltipClose} />
                    </Box>
                    <Typography className={classes.infoTooltipText}>
                        <b>Welcome to eNable!</b> <br />
                        <br />
                        <span className={classes.infoTooltipTextDesc}>
                            Start by selecting <b>Entities</b> to add a Suborganization or Practice.
                        </span>
                    </Typography>
                    <Button variant="outlined" color="primary" className={classes.LetsGoBtn} onClick={goToEntities}>
                        Let's Go
                    </Button>
                </Box>
            }
            open={toggleWelcomeTooltip}
            onClose={() => handleTooltipClose(null)}
            placement="bottom-start"
        >
            <label></label>
        </RichTooltip>
    );
};

export default EntitiesDecorations;
