import React from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { COLORS, TYPOGRAPHY, SIZING } from "theme";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { useColor } from "common/utils/use-color";
import { useStyles } from "./styles";

type EmptyMessageVariants = "content" | "raw";
type Message = {
    /** The translation id */
    id?: string;
    variant?: keyof typeof TYPOGRAPHY;
    color?: keyof typeof COLORS;
};

type EmptyMessageProps = {
    className?: string;
    Icon?: JSX.Element;
    // We have designs with varying opacities values
    // this prop will help up to reduce custom styling everywhere
    // an in the future we may align design and safely remove this props
    opacity?: number;
    iconTextSpacing?: keyof typeof SIZING;
    primaryMessage?: Message;
    secondaryMessage?: Message;
    fluid?: boolean;
    /** Which variant of empty message should be displayed.
     *  - content: An empty content message with a large icon and text, with opacity.
     *  - raw: No predefined style is created.
     */
    variant?: EmptyMessageVariants;
};

// TODO: we need to integrate EmptyMessage and EmptyContentMessage into one, flexible, customizable and reusable component.
const EmptyMessage = ({
    Icon,
    primaryMessage,
    secondaryMessage,
    className,
    iconTextSpacing,
    opacity,
    fluid,
    variant = "raw"
}: EmptyMessageProps) => {
    const classes = useStyles();
    const primaryColorAttributes = useColor({ color: primaryMessage?.color });
    const primaryVariant = primaryMessage?.variant || variant === "content" ? "contentTitle" : "subtitle1Bold";
    const secondaryColorAttributes = useColor({ color: secondaryMessage?.color });
    const secondaryVariant = secondaryMessage?.variant || variant === "content" ? "contentSubtitle" : "subtitle1";

    return (
        <Box
            className={classNames(classes.emptyMessage, className, `variant-${variant}`, {
                "emptyMessage--fluid": fluid
            })}
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{
                opacity
            }}
        >
            {Icon && (
                <Box display="flex" className={classes.emptyIcon}>
                    {Icon}
                </Box>
            )}
            { primaryMessage && primaryMessage.id && 
                <CustomTypography
                    variant={primaryVariant}
                    style={{ ...primaryColorAttributes.style, marginTop: iconTextSpacing ? SIZING[iconTextSpacing] : null }}
                    className={primaryColorAttributes.className}
                >
                    <FormattedMessage id={primaryMessage.id} />
                </CustomTypography>
            }
            {secondaryMessage && secondaryMessage.id && (
                <CustomTypography
                    variant={secondaryVariant}
                    style={secondaryColorAttributes.style}
                    className={secondaryColorAttributes.className}
                >
                    <FormattedMessage
                        id={secondaryMessage.id}
                        values={{
                            // Support italic texts (APOLLO-718)
                            i: (chunks: JSX.Element) => <i>{chunks}</i>,
                        }}
                    />
                </CustomTypography>
            )}
        </Box>
    );
};

export default EmptyMessage;
