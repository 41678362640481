import React, { FC } from "react";
import { checkNavItemAccess, flattenNavItemCollection, requiresFeatureFlag } from "lib/security/access-utils";
import { isMenuItem, SideNavItemCollection, SideNavItemTypes } from "views/SideNav/types";
import { RouteProps } from "react-router-dom";
import { Unauthorized } from "common/views";
import { useEnabledFeatureFlagNames } from "lib/security/feature-flag-hooks";
import { useEnabledPermissions } from "lib/security/permission-hooks";
import { SecureRoute } from "authentication/app-secure-route";
import config from "views/SideNav/config";

type AccessCheckRouteOwnProps = {
    navItemKey: string;
    path: string; // For a more strict typing
};

export type AccessCheckRouteProps = AccessCheckRouteOwnProps & RouteProps;

const getNavItem = (navItems: SideNavItemCollection, navItemKey: string): SideNavItemTypes | undefined => {
    const flatNavItemCollection = flattenNavItemCollection(navItems);
    return flatNavItemCollection.find((navItem) => isMenuItem(navItem) && navItem.id === navItemKey);
};

export const AccessCheckRoute: FC<AccessCheckRouteProps> = ({ navItemKey, ...routeProps }) => {
    const grantedPermissions = useEnabledPermissions();
    const enabledFeatures = useEnabledFeatureFlagNames();

    const navItem = getNavItem(config.routes, navItemKey);
    if (navItem) {
        if (requiresFeatureFlag(navItem) && enabledFeatures === null) {
            return null;
        }
        const enabledNavItem = checkNavItemAccess(navItem, grantedPermissions, enabledFeatures || []);
        if (!enabledNavItem) {
            const { render, children, ...safeRouteProps } = routeProps;
            return <SecureRoute {...safeRouteProps} component={Unauthorized} />;
        }
    }
    return <SecureRoute {...routeProps} />;
};
