import { makeStyles } from "@mui/styles";
import {SIZING, COLORS} from "theme";

export const useStyles = makeStyles(() =>({
    headerWrapper: {
        padding: SIZING.scale500,
        display:"flex",
        justifyContent:"space-between",
        background: COLORS.CHAT_HEADER_BG,
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
        width: "100%"
    },
    wrapper: {
        display: "flex",
        alignItems: "center",
        color: COLORS.BADGE_BG,
        marginRight: SIZING.scale400
    },
    icon: {
        height: SIZING.scale500,
        width: SIZING.scale400,
        marginRight: SIZING.scale100,
        "& path": {
            fill: COLORS.BADGE_BG
        }
    },
    detailsHeader: {
        padding: SIZING.scale500,
        background: COLORS.OPENED_THREAD_BG,
        display:"flex",
        justifyContent:"space-between",
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
        width: "100%"
    },
    patientDetails: {
        display: "flex",
        width: "100%",
        lineHeight: SIZING.scale500,
        cursor:"pointer"
    },
    participantList: {
        display: "flex",
        width: "100%",
    },
    participant: {
        marginRight: SIZING.scale200,
        whiteSpace: "nowrap"
    },
    patient: {
        marginRight: SIZING.scale200,
        color: COLORS.PATIENT_TEXT,
        whiteSpace: "nowrap",
        lineHeight: SIZING.scale500
    },
    subject: {
        marginBottom: SIZING.scale400,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: COLORS.CONVERSATION_TEXT
    },
    patientDetailsHeader: {
        marginBottom: SIZING.scale400,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: COLORS.PATIENT_DETAIL_HEADER_TEXT
    },
    ellipsis: {
        height: SIZING.scale1200,
        width: SIZING.scale1200,
        "& path": {
            fill: COLORS.ITEM_BADGE_BG
        }
    }
}));