import React from "react";
import {useStyles} from "./styles";
import {Box, Button, Typography} from "@mui/material";
import {ChevronLeft} from "assets";
import {FormattedMessage, useIntl} from "react-intl";
import {SIZING} from "theme";
import {formatDate, getAge} from "utils";
import {find, map} from "underscore";
import {AnswerResponse, AppReduxStore, Requester} from "store/reducerTypes";
import {useDispatch, useSelector} from "react-redux";
import {getFormPdf, pushFormToPatient} from "store/actions";
import Spinner from "common/components/Spinner/Spinner";
import {isNumber} from "underscore";
import {QUESTIONNAIRE_TYPES} from "constant"

const RequestingProvider = ({requester}: {requester: Requester}) => {
    const {currentProfileRoleJoin} = useSelector((state: AppReduxStore) => state.user)
    const {prefix, firstName, lastName} = requester
    return (
        <Box textAlign="right">
            <Typography variant="subtitle1">
                {prefix} {firstName} {lastName}
            </Typography>
            <Typography variant="subtitle1">
                {currentProfileRoleJoin?.practice?.name}
            </Typography>
        </Box>
    )
}

const Answer = ({answer, questionnaireType}: {answer: AnswerResponse | undefined, questionnaireType: string}) => {
    const classes = useStyles()

    if (!answer) return <>-</>

    const {answerOption, answerValue} = answer
    const {score, answerText} = answerOption
    const isPositive = score > 0
    const scoreStyle = isPositive ? `${classes.score} ${classes.redScore}` : `${classes.score} ${classes.greenScore}`

    if (questionnaireType === QUESTIONNAIRE_TYPES.M_CHAT && answerOption) {
        return (
            <Box textAlign="right">
                <Typography className={scoreStyle} variant="body1">
                    {answerText}
                </Typography>
            </Box>
        )
    }

    if (answerOption) {
        return (
            <Box className={classes.answerContainer}>
                <Typography variant="body1">
                    {answerText}
                </Typography>
                <Typography className={scoreStyle} variant="body1">
                    {isPositive ? "+" : ""}{score}
                </Typography>
            </Box>
        )
    } else {
        return (
            <Box textAlign="right" marginLeft={SIZING.scale500}>
                <Typography variant="body1">
                    {answerValue}
                </Typography>
            </Box>
        )
    }
}

const FormDetails = () => {
    const { selectedForm, loadingPdf, pushingForm } = useSelector((state: AppReduxStore) => state.patient)
    const dispatch = useDispatch()
    const classes = useStyles()

    if (!selectedForm) return null

    const {title, progress, patient, requester, questionnaire, answerResponses, completed, totalScore, questionnaireSummary} = selectedForm

    const handleBack = () => dispatch({type: 'SET_SELECTED_FORM', payload: null})
    const handlePdfExport = () => dispatch(getFormPdf(selectedForm))
    const handlePushForm = () => dispatch(pushFormToPatient(selectedForm));


    const FormHeader = () => {
        return (
            <Box className={classes.formDetailsHeader}>
                <Box display="flex" alignItems="center">
                    <Box className={classes.leftChevron} onClick={handleBack}>
                        <ChevronLeft/>
                    </Box>
                    <Box>
                        <Typography variant="body1">
                            <FormattedMessage id="People.forms.details.form" values={{ title }}/>
                        </Typography>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="People.forms.details.complete" values={{ progress }}/>
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex">
                    {pushingForm && <Spinner/>}
                    <Button className={classes.exportBtn} onClick={handlePushForm} style={{marginRight: SIZING.scale200, display: "none"}}>
                        <Typography variant="button">
                            <FormattedMessage id="People.forms.details.sendToPatient"/>
                        </Typography>
                    </Button>
                    {loadingPdf && <Spinner/>}
                    <Button className={classes.exportBtn} onClick={handlePdfExport}>
                        <Typography variant="button">
                            <FormattedMessage id="People.forms.details.export"/>
                        </Typography>
                    </Button>
                </Box>
            </Box>
        )
    }

    const Details = () => {
        const intl = useIntl()
        const {firstName, lastName, genderCode, dateOfBirth, mrn} = patient

        return (
            <Box className={classes.details}>
                <Box>
                    <Box display="flex" alignItems="center">
                        <Box marginRight={SIZING.scale200}>
                            <Typography variant="body1">
                                {lastName}, {firstName}
                            </Typography>
                        </Box>
                        <Box marginRight={SIZING.scale200}>
                            <Typography variant="subtitle1">
                                ({getAge(dateOfBirth)}, {genderCode})
                            </Typography>
                        </Box>
                        <Typography variant="subtitle1">
                            {mrn}
                        </Typography>
                    </Box>
                    <Typography className={classes.completedDate} variant="subtitle1">
                        {completed ? formatDate(completed) : intl.formatMessage({id: "People.forms.pending"})}
                    </Typography>
                </Box>
                <RequestingProvider requester={requester}/>
            </Box>
        )
    }

    const QuestionnaireSummary = () => {
        if (isNumber(totalScore) && questionnaireSummary?.summary) {
            return (
                <Box className={classes.questionnaireSummary}>
                    <Typography variant="h3">
                        <FormattedMessage id="People.forms.details.points" values={{totalScore}} />
                    </Typography>
                    <Typography variant="body1">
                        {questionnaireSummary?.summary}
                    </Typography>
                </Box>
            )
        }

        return null
    }

    const Questionnaire = () => {
        const {questions, type} = questionnaire
        return (
            <>
                {map(questions, (question) => {
                    const answer = find(answerResponses, (response) => response.question.id === question.id)

                    return (
                        <Box className={classes.questionRow} key={question.questionText}>
                            <Typography variant="body1">
                                {question.questionText}
                            </Typography>
                            <Answer answer={answer} questionnaireType={type} />
                        </Box>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <FormHeader/>
            <Box className={classes.detailsContainer}>
                <Details/>
                <QuestionnaireSummary/>
                <Questionnaire/>
            </Box>
        </>
    )
}

export default FormDetails