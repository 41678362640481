import React from "react"
import { useStyles } from "./styles"
import { Input, InputAdornment } from "@mui/material";
import { useDispatch } from 'react-redux';
import { MODAL_TYPES } from "constant";
import { setActiveModal } from "store/actions";
import { ReactComponent as SearchIcon } from "icon-library/SVG/Search.svg";
import { ReactComponent as Cross_Circle } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as Plus } from "icon-library/SVG/Plus.svg";
import { useIntl, FormattedMessage } from "react-intl";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { HeaderPropsTyps, TabPropertyTypes } from "./types"
const Header: React.FC<HeaderPropsTyps> = ({ updateCurrentTab, currentTab }) => {

    const tabDetails: Array<TabPropertyTypes> = [
        {
            id: 'Rpm.tab.review',
            tabKey: 'review',
            notification:true
        },
        {
            id: 'Rpm.tab.allPatient',
            tabKey: 'allPatient',
            notification:false
        },
    ]

    const classes = useStyles()
    const intl = useIntl();
    const dispatch = useDispatch();

    const handleEnroll =()=>{
        dispatch(setActiveModal(MODAL_TYPES.PROVIDER.RPM_ENROLL))
    }

    return (
        <div className={classes.rootWrapper}>
            <div className={classes.searchWrapper}>
                <Input
                    id={`QA_EConsultSearch_textfield`}
                    autoComplete="off"
                    className={classes.searchInput}
                    placeholder={intl.formatMessage({ id: "Rpm.search.placeholder" })}
                    startAdornment={
                        <InputAdornment position="start">
                            <SVGIcon className={classes.searchIcon} svg={SearchIcon} size="scale700" />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="start">
                            <SVGIcon className={classes.closeIcon} svg={Cross_Circle} size="scale800" />
                        </InputAdornment>
                    }
                    disableUnderline={true}
                />
                <SVGIcon className={classes.plusIcon} trim={true} svg={Plus} onClick={()=>handleEnroll()} size="scale700" />
            </div>
            <div>
                <ul className={classes.rpmTab}>
                    {tabDetails.map(({ id, tabKey,notification }: TabPropertyTypes) =>
                        <li
                            onClick={e => updateCurrentTab(tabKey)}
                            key={tabKey} className={`${currentTab === tabKey ? 'active' : ''}`}
                        >
                            <FormattedMessage id={id} />
                            {notification && <div className={classes.notificationLabel}>3</div>}
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Header