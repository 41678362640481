import React from "react";
import { useStyles } from "./styles"
import { ReactComponent as User_Single } from "icon-library/SVG/User_Single.svg";
import { ReactComponent as Warning_Bell } from "icon-library/SVG/Bell.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { Box, Typography } from "@mui/material";
const PatientCard: React.FC = () => {

    const classes = useStyles()

    return (
        <div className={`${classes.rootWrapper}`}>
            <SVGIcon className={classes.userIcon} svg={User_Single} size="scale800" />
            <Box>
                <Typography className={classes.patientName}>
                    ANDERSON, Sara
                </Typography>
                <Typography className={classes.patientId}>
                    MRN-JRC-6201329
                </Typography>
            </Box>
            <Box marginLeft='auto'>
                <Typography className={classes.ageAndGender}>
                    (45 F)
                </Typography>
                <Box className={classes.alertIconWrapper}>
                <SVGIcon trim={true} svg={Warning_Bell} className={classes.alertIcon} size="scale400" />
                </Box>
            </Box>
        </div>
    )
}

export default PatientCard