import { ReactElement, JSXElementConstructor, ComponentPropsWithRef } from "react";

/**
 * @deprecated
 * Instead of using this type, import from "lib/util-types"
 */
export type ValueOf<T> = T[keyof T];

type FlatObjectOf<T> = T extends {[x: string]: any} ? {
    [P in keyof T]-?: T[P] extends {[x: string]: any}
        ? FlatObjectOf<T[P]>[keyof T[P]]
        : T[P]
} : T

/**
 * Creates a union type of all the deep values of the const type
 * 
 * Example:
 * 
 * Given following const type
 * 
 * const Foo = {
 *     a: {
 *         b: "value B"
 *     },
 *     c: {
 *         b: "value B 2",
 *         z: {
 *             w: "value W"
 *         },
 *         c: {
 *             h: "value H",
 *             c: "value C"
 *         }
 *     },
 *     d: "value D"
 * } as const;
 * 
 * // Bar accepts any of the deep defined values in Foo
 * type Bar = DeepValueOf<typeof Foo>;
 * 
 * // Bar will have type:
 * type Bar = "value B" | "value B 2" | "value W" | "value H" | "value C" | "value D"
 */
export type DeepValueOf<T> = ValueOf<FlatObjectOf<T>>;

export type ChangeEvent = {
    target: {
        value: string;
    }
}

/**
 * A counter type, returns previous value.
 * 
 * Example: 
 * 
 * type Count = Prev[20]
 * 
 * Count = 19//
 * 
 * For practicality it's only defined up to 20
 */
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

/**
 * Provides a union of possible arrays describing paths of the object,
 * for example: given the Type:
 * 
 * type Foo = {
 *     a: string,
 *     b: number,
 *     c: {
 *         d: {
 *             f: string
 *         },
 *         g: string[]
 *     },
 *     e: {
 *         h: number
 *     }
 * };
 * 
 * and
 * 
 * type Bar = Paths<Foo>;
 * 
 * Bar will be:
 * 
 * Notice that arrays indexes can be specified
 * type Bar = ["a"] | ["b"] | ["c"] | ["c", "d"] | ["c", "d", "f"] | ["c", "g"] | ["c", "g", number] | ["e"] | ["e", "h"]
 */
export type Paths<T, MaxDepth extends number = 10> = [MaxDepth] extends [never] ? [] : T extends Array<any> ? [number] : T extends object ?
    {[Key in keyof T]-?: Key extends string | number ?
        [`${Key}`] | [Key, ...Paths<T[Key], Prev[MaxDepth]>]
        : never
    }[keyof T]: []

export type PromiseFunctions = {
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
};

/**
 * Use this type whenever you need to manipulate the ref of a react element,
 * For some reason the type for ReactElement from @typed/react does not contain it. 
 */
export interface ReactElementWithRef<
    ComponentType extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
    Props = ComponentPropsWithRef<ComponentType>
> extends ReactElement<Props, ComponentType> {
    ref?: ("ref" extends keyof Props ? (Props extends { ref?: infer R | undefined } ? R : never) : never) | undefined;
}

// TODO: Extend this permissions list by checking usage of checkSideNavPermission and products
export const Permissions = {
    ETRANSCRIPT: "ETRANSCRIPT",
} as const;

/** Type for ISO 8601 dates in APIs. */
export type ISO8601Date = string;
/** Type for ISO 8601 datetimes in APIs. */
export type ISO8601DateTime = string;
