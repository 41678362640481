import React, { useEffect, useState } from "react"
import { useStyles } from "./styles"
import { Grid, Box } from "@mui/material"
import { getPatientRPMDetails } from "store/actions"
import { useReduxDispatch } from "store/utils"
import Spinner from "common/components/Spinner/Spinner";
import EmptyContentMessage from "common/components/EmptyContentMessage/EmptyContentMessage";
import { EmptyForms } from "assets"
import { useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { Line } from "react-chartjs-2";
import { SIZING } from "../../../theme";

// TODO : just a POC need to redefine this component
type rowType = {
    units: string,
    recordedTime: string,
    measurementType: string,
    value: string,
    patientDeviceMonitoringDataId: string
}


const RPM = () => {
    const [loader, updateLoader] = useState<boolean>(true)
    const { selectedPatient, selectedPatientId: patientId } = useSelector((state: AppReduxStore) => state.patient);
    const customDispatch = useReduxDispatch()
    const isEvisitBarEnabled = Object.keys(selectedPatient?.evistDetails || {}).length !== 0;
    const classes = useStyles(isEvisitBarEnabled)
    const [vitals, setVitals] = useState<any>({})
    const [dataPresent, setDataPresent] = useState<boolean>(false)

    const bpObj: { data: any, legend: any, options: any } = {
        data: undefined,
        legend: undefined,
        options: undefined
    };
    const pulseObj: { data: any, legend: any, options: any } = {
        data: undefined,
        legend: undefined,
        options: undefined
    };
    const o2SatObj: { data: any, legend: any, options: any } = {
        data: undefined,
        legend: undefined,
        options: undefined
    };
    const weightObj: { data: any, legend: any, options: any } = {
        data: undefined,
        legend: undefined,
        options: undefined
    };
    const glucoseObj: { data: any, legend: any, options: any } = {
        data: undefined,
        legend: undefined,
        options: undefined
    };

    useEffect(() => {
        if (patientId) {
            //@ts-ignore
            customDispatch(getPatientRPMDetails(patientId)).then((response: map) => {

                if (null != response.systolic || null != response.diastolic || null != response.pulse || null != response.spo2 || null != response.weight || null != response.gluco) {
                    setDataPresent(true);
                }

                // BP related info
                const sysObje = response.systolic;
                const diasObje = response.diastolic;
                const sysDataSets: string[] = [];
                const diasDataSets: string[] = [];
                if (null != response.systolic && null != response.diastolic ) {
                    sysObje?.map((bp: rowType) =>
                        sysDataSets.push(bp.value)
                    );
                    diasObje?.map((bp: rowType) =>
                        diasDataSets.push(bp.value)
                    );

                    const bpData = {
                        labels: (sysObje?.map((bp: rowType) => bp.recordedTime)).reverse(),
                        datasets: [
                            {
                                label: "Systolic(mmHg)",
                                data: sysDataSets.reverse(),
                                fill: true,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                borderColor: "rgba(75,192,192,1)"
                            },
                            {
                                label: "Diastolic(mmHg)",
                                data: diasDataSets.reverse(),
                                fill: true,
                                backgroundColor: "rgba(21,2,192,0.2)",
                                borderColor: "#742774"
                            }
                        ]
                    };

                    const bpLegend = {
                        display: true,
                        position: "bottom",
                        labels: {
                            fontColor: "#323130",
                            fontSize: 14
                        }
                    };
                    const bpOptions = {
                        responsive: true,
                        title: {
                            display: true,
                            text: "Blood Pressure"
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        suggestedMin: 0,
                                        suggestedMax: 200
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    type: 'time',
                                    time: {
                                        /* parser: "YYYY-MM-DDTHH:MM:SS.SSSZ",
                                         unit: 'minute',
                                         displayFormats: {
                                             'minute': 'HH:mm',
                                             'hour': 'HH:mm'
                                         }*/
                                        unit: 'day',
                                        displayFormats: {
                                            'minute': 'yyyy-MM-DD, HH:mm',
                                            'hour': 'HH:mm',
                                            'day': 'yyyy-MM-DD'
                                        }
                                    },
                                    ticks: {
                                        //   source: 'data'
                                    }
                                }
                            ]
                        }
                    };

                    bpObj.data = bpData
                    bpObj.legend = bpLegend
                    bpObj.options = bpOptions
                }

                const pulseObje = response.pulse;
                if (null != response.pulse ) {
                    // Pulse related info
                    const pulseDataSets: string[] = [];

                    pulseObje?.map((pulse: rowType) =>
                        pulseDataSets.push(pulse.value)
                    );

                    const pulseData = {
                        labels: (pulseObje?.map((pulse: rowType) => pulse.recordedTime)).reverse(),
                        datasets: [
                            {
                                label: "Pulse(bpm)",
                                data: pulseDataSets.reverse(),
                                fill: true,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                borderColor: "rgba(75,192,192,1)"
                            }
                        ]
                    };

                    const pulseLegend = {
                        display: true,
                        position: "bottom",
                        labels: {
                            fontColor: "#323130",
                            fontSize: 14
                        }
                    };
                    const pulseOptions = {
                        responsive: true,
                        title: {
                            display: true,
                            text: "Pulse"
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        suggestedMin: 0,
                                        suggestedMax: 200
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    type: 'time',
                                    time: {
                                        unit: 'day',
                                        displayFormats: {
                                            'minute': 'yyyy-MM-DD, HH:mm',
                                            'hour': 'HH:mm',
                                            'day': 'yyyy-MM-DD'
                                        }
                                    },
                                    ticks: {
                                        // source: 'data'
                                    }
                                }
                            ]
                        }
                    };
                    pulseObj.data = pulseData
                    pulseObj.legend = pulseLegend
                    pulseObj.options = pulseOptions
                }


                // O2Sat info
                const o2SatObje = response.spo2;
                if (null != response.spo2 ) {
                    const o2SatDataSets: string[] = [];

                    o2SatObje?.map((o2sat: rowType) =>
                        o2SatDataSets.push(o2sat.value)
                    );

                    const o2SatData = {
                        labels: (o2SatObje?.map((o2sat: rowType) => o2sat.recordedTime)).reverse(),
                        datasets: [
                            {
                                label: "O2 Saturation(%)",
                                data: o2SatDataSets.reverse(),
                                fill: true,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                borderColor: "rgba(75,192,192,1)"
                            }
                        ]
                    };

                    const o2SatLegend = {
                        display: true,
                        position: "bottom",
                        labels: {
                            fontColor: "#323130",
                            fontSize: 14
                        }
                    };
                    const o2SatOptions = {
                        responsive: true,
                        title: {
                            display: true,
                            text: "O2 Saturation"
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        suggestedMin: 0,
                                        suggestedMax: 120
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    type: 'time',
                                    time: {
                                        unit: 'day',
                                        displayFormats: {
                                            'minute': 'yyyy-MM-DD, HH:mm',
                                            'hour': 'HH:mm',
                                            'day': 'yyyy-MM-DD'
                                        }
                                    },
                                    ticks: {
                                        //source: 'data'
                                    }
                                }
                            ]
                        }
                    };
                    o2SatObj.data = o2SatData
                    o2SatObj.legend = o2SatLegend
                    o2SatObj.options = o2SatOptions
                }

                const weightObje = response.weight;
                if (null != response.weight) {
                    const weightDataSets: string[] = [];

                    weightObje.map((weight: rowType) =>
                        weightDataSets.push(weight.value)
                    );

                    const weightData = {
                        labels: weightObje?.map((weight: rowType) => weight.recordedTime).reverse(),
                        datasets: [
                            {
                                label: "Weight(kg)",
                                data: weightDataSets.reverse(),
                                fill: true,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                borderColor: "rgba(75,192,192,1)"
                            }
                        ]
                    };

                    const weightLegend = {
                        display: true,
                        position: "bottom",
                        labels: {
                            fontColor: "#323130",
                            fontSize: 14
                        }
                    };
                    const weightOptions = {
                        responsive: true,
                        title: {
                            display: true,
                            text: "Weight"
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        suggestedMin: 0,
                                        suggestedMax: 300
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    type: 'time',
                                    time: {
                                        unit: 'day',
                                        displayFormats: {
                                            'minute': 'yyyy-MM-DD, HH:mm',
                                            'hour': 'HH:mm',
                                            'day': 'yyyy-MM-DD'
                                        }
                                    },
                                    ticks: {
                                        //source: 'data'
                                    }
                                }
                            ]
                        }
                    };
                    weightObj.data = weightData
                    weightObj.legend = weightLegend
                    weightObj.options = weightOptions
                }
                const glucoseObje = response.gluco;
                if (null != response.gluco) {
                    const glucoseDataSets: string[] = [];

                    glucoseObje.map((glucose: rowType) =>
                    glucoseDataSets.push(glucose.value)
                    );

                    const glucoseData = {
                        labels: glucoseObje?.map((glucose: rowType) => glucose.recordedTime).reverse(),
                        datasets: [
                            {
                                label: "Glucose(mg_dL)",
                                data: glucoseDataSets.reverse(),
                                fill: true,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                borderColor: "rgba(75,192,192,1)"
                            }
                        ]
                    };

                    const glucoseLegend = {
                        display: true,
                        position: "bottom",
                        labels: {
                            fontColor: "#323130",
                            fontSize: 14
                        }
                    };
                    const glucoseOptions = {
                        responsive: true,
                        title: {
                            display: true,
                            text: "Glucose"
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        suggestedMin: 0,
                                        suggestedMax: 400
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    type: 'time',
                                    time: {
                                        unit: 'day',
                                        displayFormats: {
                                            'minute': 'yyyy-MM-DD, HH:mm',
                                            'hour': 'HH:mm',
                                            'day': 'yyyy-MM-DD'
                                        }
                                    },
                                    ticks: {
                                        //source: 'data'
                                    }
                                }
                            ]
                        }
                    };
                    glucoseObj.data = glucoseData
                    glucoseObj.legend = glucoseLegend
                    glucoseObj.options = glucoseOptions
                }

                // let finalData = { bp: bpObj, pulse: pulseObj, o2sat: o2SatObj, weight: weightObj }
                const finalData:any = {}

                if (null != bpObj) {
                    finalData.bp = bpObj
                }
                if (null != pulseObj) {
                    finalData.pulse = pulseObj
                }
                if (null != o2SatObj) {
                    finalData.o2sat = o2SatObj
                }
                if (null != weightObj) {
                    finalData.weight = weightObj
                }
                if (null != glucoseObj) {
                    finalData.glucose = glucoseObj
                }
                setVitals(finalData);

                updateLoader(false)
            }).catch(() => {
                updateLoader(false)
            })
        }
    }, [patientId])


    if (loader) return <Spinner />

    if (!dataPresent) return <div className={classes.empty}><EmptyContentMessage Icon={EmptyForms} title="RPM.emptyMessage" subtitle={null} /></div>


    // @ts-ignore
    return (

        <div className={classes.root}>
            <Box className={classes.graphWrapper} padding={`${SIZING.scale400} ${SIZING.scale700} ${SIZING.scale400} ${SIZING.scale400}`} id="QA_patientRPM">
                <Grid container spacing={2}>
                    {vitals.bp?.data &&
                        <Grid item xs>
                            <Line data={vitals.bp.data} legend={vitals.bp.legend} options={vitals.bp.options} />
                        </Grid>
                    }
                    {vitals.pulse?.data &&
                        <Grid item xs>
                            <Line data={vitals.pulse.data} legend={vitals.pulse.legend} options={vitals.pulse.options} />
                        </Grid>
                    }
                    {vitals.glucose?.data &&
                        <Grid item xs>
                            <Line data={vitals.glucose.data} legend={vitals.glucose.legend} options={vitals.glucose.options} />
                        </Grid>
                    }
                </Grid>

                <Grid container spacing={2}>
                    {vitals.o2sat?.data &&
                        <Grid item xs>
                            <Line data={vitals.o2sat.data} legend={vitals.o2sat.legend} options={vitals.o2sat.options} />
                        </Grid>
                    }
                    {vitals.weight?.data &&
                        <Grid item xs>
                            <Line data={vitals.weight.data} legend={vitals.weight.legend} options={vitals.weight.options} />
                        </Grid>
                    }
                </Grid>

            </Box>
        </div>
    )
}

export default RPM
