import React from "react";
import { Button, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {Form} from "admin/components/useForm";
import FormField from "common/components/FormField/FormField";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "admin/store/reducerTypes";
import {setRegistrationValues, updateUsername} from "admin/store/actions";
import { EMAIL_PATTERN} from "constant";
import {useStyles} from "./styles";

// LoginOrCreate page is simply used to start login or start creating profile
const LoginOrCreate = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {email} = useSelector((store: AppReduxStore) => store.registration)
    const emailInvalid = !email || !EMAIL_PATTERN.test(email);
    const onSubmit = (e: any) => {
        e.preventDefault()
        dispatch(updateUsername())
    }

    return (
        <>
            <Form onSubmit={onSubmit}>
                <FormField
                    labelId="Registration.label.emailAddress"
                    value={email}
                    onChange={email => dispatch(setRegistrationValues({email}))}
                    type="email"
                />
                <Button className={classes.submitButton} type="submit" disabled={emailInvalid} color="primary" fullWidth
                    onClick={onSubmit}>
                    <Typography variant="button">
                        <FormattedMessage id="Registration.button.submit" />
                    </Typography>
                </Button>
            </Form>
        </>

    )
}

export default LoginOrCreate