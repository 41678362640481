import { isCheckableMenuItem, MenuItemViewProps } from "./types";
import { ReactComponent as CheckedCheckboxSvg } from "icon-library/SVG/Checkbox_Square.svg";
import { ReactComponent as UnheckedCheckboxSvg } from "icon-library/SVG/Checkbox-Light_Square.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";

const CheckableMenuItemView = ({ labelRenderer, menuItem }: MenuItemViewProps) => {
    if (isCheckableMenuItem(menuItem)) {
        return (
            <div className="menuItem" id={menuItem.id} data-testid={menuItem.id}>
                <SVGIcon svg={menuItem.checked ? CheckedCheckboxSvg : UnheckedCheckboxSvg} className="menuItemIcon" />
                {labelRenderer({
                    className: "menuItemText",
                    labelKey: menuItem.labelKey,
                    labelText: menuItem.labelText,
                    labelValues: menuItem.labelValues,
                })}
            </div>
        );
    }
    return null;
};

export default CheckableMenuItemView;
