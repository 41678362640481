import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DialogModal from "components/Modals/dialog-modal/dialog-modal";

type CloseEditorDialogProps = {
    onClose: () => void;
    onCancel: () => void;
};

export const CloseEditorDialog = ({ onClose, onCancel }: CloseEditorDialogProps) => {
    const intl = useIntl();

    return (
        <DialogModal
            idPrefix="QA_files_remove_file_dialog"
            title={intl.formatMessage({
                id: "careTeam.editor.closeConfirmation.title",
            })}
            primaryAction={{
                label: intl.formatMessage({
                    id: "careTeam.confirm",
                }),
                danger: true,
                onClick: onClose,
            }}
            secondaryAction={{
                label: intl.formatMessage({ id: "careTeam.cancel" }),
                onClick: onCancel,
            }}
        >
            <FormattedMessage id={"careTeam.editor.closeConfirmation.message"} />
        </DialogModal>
    );
};
