import { Button, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { AppReduxStore } from "admin/store/reducerTypes";
import { Form } from "admin/components/useForm";
import FormField from "common/components/FormField/FormField";
import { setRegistrationValues, verifyOTPFactor, resendOTP, setRegistrationStep } from "admin/store/actions";
import React, { useEffect } from "react";
import { REGISTRATION_STEP } from "constant";
import { useSnackbar } from "notistack";
import SnackbarContent from "admin/components/SnackBars/SnackbarContent";
import { useStyles } from "./styles";
import { useOktaAuth } from "@okta/okta-react";
// OTPRegistration is used to handle One time password
const OTPRegistration = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const intl = useIntl()
    const { oktaAuth } = useOktaAuth()
    const { otpCode, otpValid, emailVerified, profileRoleJoinId } = useSelector((store: AppReduxStore) => store.registration)

    useEffect(() => {
        if (otpValid) {
            const nextStep = emailVerified ? REGISTRATION_STEP.PROVIDER.CREATE_PROFILE : REGISTRATION_STEP.PROVIDER.LOG_IN_OR_CREATE
            dispatch(setRegistrationStep(nextStep))
            // After validating otp reset value
            dispatch(setRegistrationValues({ otpValid: false }))
        }
    }, [dispatch, emailVerified, otpValid])

    const onSubmit = (e: any) => {
        e.preventDefault()
        dispatch(verifyOTPFactor(profileRoleJoinId ? oktaAuth : null))
    }

    // onOTPSend triggers a snackbar to show the user the otp was sent
    const onOTPSend = () => {
        const message = intl.formatMessage({ id: "OTPConfirmation.message.resend" })
        enqueueSnackbar(null, {
            preventDuplicate: true, content: (key: number) => (
                <div role="alert">
                    <SnackbarContent snackbarKey={key} type="success" message={message} />
                </div>
            ),
        })
    }

    const resend = () => dispatch(resendOTP(onOTPSend))


    // onChange checks that the otp only contains letters and saves the value
    const onChange = (code: string) => {
        if (/^\d*$/.test(code)) {
            dispatch(setRegistrationValues({ otpCode: code }))
        }
    }
    return (
        <Form onSubmit={onSubmit}>
            <FormField
                className={classes.numberField}
                labelId={`OTPConfirmation.label.codeFrom.${emailVerified ? "email" : "sms"}`}
                altLabel="OTPConfirmation.label.resend"
                value={otpCode}
                onChange={onChange}
                altFn={resend}
            />
            <Button type="submit" disabled={!otpCode} variant="outlined" color="primary" fullWidth>
                <Typography variant="button">
                    <FormattedMessage id="Registration.button.confirm" />
                </Typography>
            </Button>
        </Form>
    )
}

export default OTPRegistration