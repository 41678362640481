import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    snackbarWrapper: {
        maxWidth: "700px",
        [theme.breakpoints.down('xl')]: {
            maxWidth: "700px",
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: "600px",
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: "420px",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },
    sbSuccess: {
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 24px 80px rgba(0, 0, 0, 0.2)',
        borderRadius: '1rem',
        border: '2px solid #FFFFFF',
        paddingRight:'20px'
    },
    sbSuccessText: {
        fontSize: '16px',
        color: '#FFFFFF',
    },
    sbError: {
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 24px 80px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        border: '2px solid #FFFFFF',
    },
    sbErrorText: {
        fontSize: '1rem',
        color: '#FFFFFF',
        fontWeight: 'bold'
    },
    sbErrorDescText: {
        fontSize: '.9rem',
        fontWeight: 300,
        color: '#FFFFFF',
        marginTop: '5px'
    },
    sbWarning: {
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 24px 80px rgba(0, 0, 0, 0.2)',
        borderRadius: '1rem',
        border: '2px solid #FFFFFF',
    },
    sbWarningText: {
        fontSize: '16px',
        color: '#393948',
    },
    sbWarningDescText: {
        fontSize: '.9rem',
        fontWeight: 300,
        color: '#393948',
        marginTop: '5px',
        marginBottom: '5px'
    }
}));