import Dashboard from './assets/images/Dashboard.jpg'
import Message from './assets/images/Messages.jpg'
import eVisit from './assets/images/eVisit.jpg'
import patientSummary from './assets/images/Patient-Summary.jpg'
import HospitalDischarge from './assets/images/Hospital-Discharge.jpg'

export type ConfigType = {
    [k: string]:ConfigDetails
}

type ConfigDetails ={
    title:string,
    themeColor: string,
    description:string,
    btnText: string,
    icon: any,
    incText:string,
    incBackColor:string,
    Image:any,
    points:any
}
export const config = [
 {
        title:'Smart Landing Page',
        Image:Dashboard,
        points:[
            {
                pointTitle:"Save time",
                desc:"View aggregated, relevant data from disparate EHRs and other data sources."
          
            },
            {
                pointTitle:"Proactively care for patients",
                desc:"Leverage key data to close care gaps and focus on the whole patient."
          
            },
            {
                pointTitle:"Drive practice success",
                desc:"Act on intelligent insights to meet quality/performance incentives and optimize revenue."
          
            },
        ]
        
    },
   {
        title:'Physician-to-Patient & Physician-to-Physician Messaging',
        Image:Message,
        points:[
            {
                pointTitle:"Enhance engagement",
                desc:"Make it easy for patients, family caregivers, and care team members to connect and communicate via text, voice, or video call."
          
            },
            {
                pointTitle:"Reduce complexity and clicks",
                desc:" Eliminate the need for multiple phone calls, emails, and faxes."
          
            },
            {
                pointTitle:"Improve the experience for everyone",
                desc:"Create a positive patient/colleague experience that drives satisfaction and grows your practice."
          
            },
        ]
    },
    {
        title:'eVisit Video Call',
        Image:eVisit,
        points:[
            {
                pointTitle:"Provide convenience and accessibility",
                desc:"Support scheduled and instant visits for patients and colleagues – across disparate EHR platforms – via email and SMS text message invitation."
          
            },
            {
                pointTitle:"Improve care coordination ",
                desc:"Quickly and easily bring together and extended care team to collaborate around a patient’s care."
          
            },
            {
                pointTitle:"Optimize the patient experience",
                desc:"Provide virtual care options and improve the experience through warm hand-offs and seamless collaboration among all care team members."
          
            },
        ]
    },
    {
        title:'Patient Profile Summary',
        Image:patientSummary,
        points:[
            {
                pointTitle:"Improve quality of care and outcomes",
                desc:"Proactively manage care and improve outcomes with a holistic view of patients’ health condition."
          
            },
            {
                pointTitle:"Reduce administrative hassle",
                desc:"Save time on administrative tasks, reduce paperwork, mitigate errors."
          
            },
            {
                pointTitle:"Do more in less time",
                desc:"Review real-time and contextual patient information from disparate EHRs and other systems."
          
            },
        ]
    },
    {
        title:'ADT – Hospital Discharge Notes',
        Image:HospitalDischarge,
        points:[
            {
                pointTitle:"Optimize quality of care and improve outcomes",
                desc:"Leverage critical information and data regarding care needs outside of the physician’s direct line of sight."
          
            },
            {
                pointTitle:"Drive care-team collaboration",
                desc:" Ensure timely delivery of relevant information to the entire care team to drive engagement and collaboration."
          
            }
        ]
    }

]


