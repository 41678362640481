import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  pieContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    justifyContent: 'center'
  },
  legendContainer: {
    marginLeft: '32px'
  },
  entry: {
    display: 'flex',
    alignItems: 'center'
  },
  entryColor: {
    height: '25px',
    width: '25px',
    marginTop: '8px'
  },
  entryTextwrapper: {
    marginLeft: '16px',
  },
  entryText: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#565662'
  },
  totalUsersText: {
    color: '#393948',
    fontWeight: 700,
    fontSize: '24px',
    textAlign: 'left'
  },
  totalUserCountText: {
    color: '#0B5D8F',
    fontWeight: 600,
    fontSize: '24px'
  },
}));