import { Box } from "@mui/material";
import { call } from 'admin/store/api';
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PracticesSearch from "./locations-search";
import SelectedPractices from "./selected-locations";
import { useStyles } from "./styles";
import { AssignedPractice, PracticesProps } from "./types";

/*
const getAllLocations = async (practiceList: any[]) => {
    const allLocations: any = [];
    await Promise.all(practiceList.map(async (practice: any) => {
        try {
            return await call("GET", `/admin/v1/practice/locations?practiceId=${practice.id}`).then((payload) => {
                if (payload.length > 0) {
                    payload.forEach((item: any) => {
                        allLocations.push({
                            practice: practice,
                            locations: item
                        });
                    })
                }
            })
        } catch (err) {
            console.log(err)
        }
    }));
    return allLocations;
}

const useLocationsList = (orgId: string) => {
    const { content, fetchInfo, request } = useApi<{}, any>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: [],
        url: `/admin/v1/practice/organization/${orgId}`,
        method: HttpMethod.GET,
        responseTransformer: async (practicesList) => {
            if (practicesList?.length > 0) {
                return await getAllLocations(practicesList);
            }
        }
    });

    useEffect(() => {
        if (orgId) {
            request({});
        }
    }, [orgId, request]);

    return {
        availableLocations: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};
*/

type feeScheduleRegionProps = {
    description: string,
    id: string,
    name: string,
    organizationId: string,
    practiceId?: null
    status?: null
}

const getAllPractices = async (regionList: feeScheduleRegionProps[]) => {
    const allPractices: AssignedPractice[] = [];
    await Promise.all(regionList.map(async (region: any) => {
        try {
            return await call("GET", `/admin/v1/region/assign-practices/region/${region.id}`).then(async (assignedPractices) => {
                if (assignedPractices.length > 0) {
                    for (const assignedPractice of assignedPractices) {
                        allPractices.push({
                            practice: {
                                id: assignedPractice.id,
                                name: assignedPractice.name
                            },
                            locations: {
                                id: "",
                                name: "",
                                practiceId: assignedPractice.id,
                                address1: assignedPractice.address1 || "",
                                address2: assignedPractice.address2 || "",
                                city: assignedPractice.city || "",
                                state: assignedPractice.state || "",
                                postalCode: assignedPractice.postalCode || "",
                                country: ""
                            }
                        });
                    }
                }
            })
        } catch (err) {
            console.log(err)
        }
    }));
    return allPractices;
}

/*
const useLocationsList = (orgId: string, selectedRegions?: feeScheduleRegionProps[]) => {
    const { content, fetchInfo, request } = useApi<{}, any>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: [],
        url: `/admin/v1/region/Organization/${orgId}`,
        method: HttpMethod.GET,
        responseTransformer: async (regionList) => {
            if (regionList?.length > 0) {
                const regions: feeScheduleRegionProps[] = (selectedRegions && selectedRegions.length > 0) ? selectedRegions : regionList;
                return await getAllPractices(regions);
            }
        }
    });

    useEffect(() => {
        if (orgId && (!selectedRegions || selectedRegions.length > 0)) {
            request({});
        }
    }, [orgId, request, selectedRegions]);

    return {
        availableLocations: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};
*/

const Locations = ({ orgId, selectedRegions, selectedPractices, onChange, error }: PracticesProps) => {
    const classes = useStyles();
    const intl = useIntl();
    const [availablePractices, setAvailablePractices] = useState<AssignedPractice[]>([]);

    const addPractice = useCallback((practice: AssignedPractice) => {
        onChange((practices) => {
            return [...practices, practice];
        });
    }, []);

    const removePractice = useCallback((id: string) => {
        onChange((practices) => practices.filter((practice) => practice.practice.id !== id));
    }, []);

    const getDefaultAvailablePractices = useCallback(async (orgId: string) => {
        return await call("GET", `/admin/v1/region/Organization/${orgId}`).then(async (regions: feeScheduleRegionProps[]) => {
            if (regions.length > 0) {
                const allPractices: AssignedPractice[] = await getAllPractices(regions);
                setAvailablePractices(allPractices);
            }
        });
    }, [])

    const getAvailablePracticesByRegions = useCallback(async (orgId: string, regionList: feeScheduleRegionProps[]) => {
        const allPractices: AssignedPractice[] = await getAllPractices(regionList);
        setAvailablePractices(allPractices);
    }, [])

    useEffect(() => {
        if (orgId) {
            if (selectedRegions && selectedRegions.length > 0) {
                getAvailablePracticesByRegions(orgId, selectedRegions);
            } else {
                getDefaultAvailablePractices(orgId);
            }
        }
    }, [orgId, selectedRegions])

    return (
        <Box display="flex" className={classes.providerBox} style={{ height: '100%' }}>
            <Box flexGrow={1} className="providersLeftPanel">
                <SelectedPractices selectedPractices={selectedPractices} onRemove={removePractice} />
            </Box>
            <Box flexGrow={3} className="providersRightPanel">
                <PracticesSearch
                    selectDisabled={false}
                    disabledMessage={intl.formatMessage({ id: "EConsult.newRequestModal.recipients.maxRecipientsDisabledMessage" })}
                    selectedPractices={selectedPractices}
                    availablePractices={availablePractices}
                    onSelect={addPractice}
                    onRemove={removePractice}
                    error={error}
                />
            </Box>
        </Box>
    );
};

export default Locations;
