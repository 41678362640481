import React from "react";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Typography
} from "@mui/material";
import Modal from "components/Modal/Modal";
import { MODAL_TYPES } from "constant";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { setActiveModal } from "store/actions";
import { hideLoading, showLoading } from "admin/store/actions/loading";
import { useStyles } from "./styles";
import { createGUIDString } from "admin/common/utils";
import { signOutApp } from "authentication/appAuth";
const Logout = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose()
    };

    async function handleLogout() {
        onClose()
        const guid = createGUIDString()
        dispatch(showLoading(guid, "Logout.Auth.LoadingTitle", "Logout.Auth.LoadingDescription"))
        const postLogoutRedirectUri = window.location.origin + "/login";
        await signOutApp(postLogoutRedirectUri);
        dispatch(hideLoading(guid))
    }

    return (
        <Modal maxWidth="xs" onClose={onClose} id={MODAL_TYPES.PROVIDER.LOGOUT_USER}>
            <DialogActions>
                <Box flexGrow={1} style={{ paddingLeft: '32px', paddingTop: '25px' }}>
                    <Typography variant="body2" className={classes.dlgTitle}>
                        <FormattedMessage id={"Logout.title"} />
                    </Typography>
                    <Typography variant="h3" className={classes.dlgText}>
                        <FormattedMessage id={"Logout.ConfirmMessage"} />
                    </Typography>
                </Box>
                <Box display="inherit">
                    <Button id="btnCancel" variant="outlined" style={{ marginLeft: '10px' }} onClick={handleClose}>
                        <FormattedMessage id={"Logout.Button.CancelText"} />
                    </Button>
                    <Button id="btnLogOut" variant="outlined" color="primary" style={{ marginLeft: '10px', backgroundColor: "#E32B29", color: "#FFFFFF" }} onClick={handleLogout}>
                        <FormattedMessage id={"Logout.Button.LogoutText"} />
                    </Button>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} className={classes.dlgContent}>
                <FormattedMessage id={"Logout.BodyText"} />
            </DialogContent>
        </Modal>
    )
}

export default Logout