import createReducer from '../createReducer'
import {ErrorState} from '../reducerTypes'

const initialState: ErrorState = {
    errorText: "",
    errorDesc: "",
    messageId: "",
    errorCount: 0,
    errorType: ""
}

export default createReducer(initialState, {
    'SET_ERROR': (state: ErrorState, payload: ErrorState): ErrorState => {
        return {
            ...state,
            ...payload,
            errorCount: state.errorCount+1
        }
    }
})