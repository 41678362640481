import { Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import { ReactComponent as WaitThickSvg } from "icon-library/SVG/Wait_Thick.svg";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import { LoadingIndicatorProps } from "./types";

const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number },
) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={96} thickness={5} sx={{ color: "#FFFFFF" }} variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    style={{ fontSize: '24px', color: '#FFFFFF', fontWeight: '600', fontFamily: 'OpenSans' }}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const LoadingIndicator = ({ loadingMessage, loadingProgress, dimension }: LoadingIndicatorProps) => {
    const classes = useStyles();
    const intl = useIntl()
    return (<>
        {loadingMessage &&
            <Box className={dimension ? classes.overlay_custom_size : classes.overlay} width={dimension?.width} height={dimension?.height}>
                <Box className={dimension ? classes.overlayInner_custom_size : classes.overlayInner}>
                    <Box className={dimension ? classes.overlayContent_custom_size : classes.overlayContent}>
                        {!loadingProgress && <Box>
                            <WaitThickSvg className={classes.loadingIndicatorIcon} />
                        </Box>}
                        {loadingProgress && <Box sx={{ position: 'relative', marginTop: '35px', display: 'inline-flex' }}>
                            <CircularProgressWithLabel value={loadingProgress} />
                        </Box>}
                        <Box className={classes.loadingTextWrapper}>
                            <Typography color="inherit" variant="h2" className={classes.loaderTitle}>
                                {loadingMessage?.title ? loadingMessage.title : intl.formatMessage({ id: "LoadingIndicator.defaultTitle" })}
                            </Typography>
                            <Typography color="inherit" variant="subtitle1" className={classes.loaderDesc}>
                                {loadingMessage?.description ? ((loadingProgress && loadingProgress > 0 && loadingMessage?.description_inProgress) ? loadingMessage.description_inProgress : loadingMessage.description) : intl.formatMessage({ id: "LoadingIndicator.defaultDescription" })}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>}
    </>)
}

export default LoadingIndicator