import { makeStyles } from "@mui/styles";
import { SIZING, COLORS, BORDER_RADIUS } from "theme";

export const useStyles = makeStyles(() => ({
    rootCard: {
      background: ({ themeColor }: any) => getRgb(themeColor),
      boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 8px rgba(0, 0, 0, 0.2);',
      borderRadius: ({ isNonPCR }: any)=>isNonPCR?BORDER_RADIUS.LARGE:SIZING.scale700,
      height: 'auto',
      padding: ({ isNonPCR }: any)=>isNonPCR?SIZING.scale800:SIZING.scale100,
      paddingBottom: SIZING.scale1200,
      position: 'relative'
    },
    cardContainer: {
      display: "flex",
      height: "15.625rem",
      "& .pcr-desc":{
        marginTop: SIZING.scale200
      }
    },
    cardContents: {
      padding: SIZING.scale600,
    },
    title: {
      fontSize: SIZING.scale700,
      color: ({ themeColor }: any) => themeColor,
      fontWeight: 300
    },
    descTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems:"center",
    },
    title1: {
      fontSize: SIZING.scale600,
      color: ({ themeColor }: any) => themeColor,
      fontWeight: 300
    },
    descWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: SIZING.scale300,
      "& svg": {
        fontSize: SIZING.scale2600,
        marginRight: SIZING.scale600,
        "& path": {
          fill: ({ themeColor }: any) => themeColor
        }
      }
    },
    description: {
      fontSize: SIZING.scale400,
      color: COLORS.MULTI_SELECT_BG,
      fontWeight: 300,
      lineHeight: SIZING.scale800,
    },
    imagePCR:{
      width: "15.25rem",
      height: "100%",
      objectFit:'fill',
      borderTopLeftRadius:SIZING.scale600,
      borderBottomLeftRadius:SIZING.scale600
    },
    button: {
      background: ({ themeColor }: any) => themeColor,
      color: COLORS.WHITE,
      position: 'absolute',
      bottom: '-20px',
      width:SIZING.SIDE_NAV_OPEN_WIDTH,
      right: ({ isNonPCR }: any)=>isNonPCR?'-24px':SIZING.scale700,//note: right should be 28px in pcr landing page as per figma
      "&:hover": {
        background: ({ themeColor }: any) => themeColor,
      },
      padding: `0 ${SIZING.scale1000}`
    },
    includeButton: {
      border: "none",
      width:"5.75rem",
      height: SIZING.scale800,
      borderRadius: SIZING.scale200,
      background: ({ incBackColor }: any) => incBackColor,
      color:({ incColor }: any) => incColor,
      fontWeight:600
    }
}));

const getRgb = (key: string) => {
  switch (key) {
    case COLORS.LBLUE:
      return "linear-gradient(0deg, rgba(19, 154, 237, 0.072) 0%, rgba(255, 255, 255, 0) 50%), #FFFFFF;"
    case COLORS.LGREEN:
      return "linear-gradient(0deg, rgba(4, 198, 139, 0.063) 0%, rgba(255, 255, 255, 0) 50%), #FFFFFF;"
    case COLORS.PRIORITY_BG:
      return "linear-gradient(0deg, rgba(232, 44, 42, 0.045) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;"
    default:
      return "linear-gradient(0deg, rgba(19, 154, 237, 0.072) 0%, rgba(255, 255, 255, 0) 50%), #FFFFFF;"

  }
}