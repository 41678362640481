import React from "react";
import {Box, Typography} from "@mui/material";
import {useIntl, FormattedMessage} from 'react-intl'
import {useStyles} from "./styles";
import TransparentLogo from "../../../assets/Logo_Transparent.svg"
import IosBtn from "assets/Btn_AppStore.svg"
import AndroidBtn from "assets/Btn_GooglePlay.svg"
import PhoneImg from "assets/Hand_Phone.png"

const DownloadApp = () => {
    const classes = useStyles()
    const intl = useIntl()
    const btnIosTxt = intl.formatMessage({id: 'DownloadApp.btn.ios'})
    const btnAndroidTxt = intl.formatMessage({id: 'DownloadApp.btn.android'})

    return (
        <Box className={classes.pageWrapper} display="flex" justifyContent="center">

            <Box className={classes.contentWrapper}>

                <Box display="flex" justifyContent="center" className={classes.headRow}>
                    <img className={classes.logo} height={80} alt="altais" src={TransparentLogo} />

                    <Typography variant="h1">
                        <FormattedMessage id="DownloadApp.heading.text"/>
                    </Typography>
                </Box>

                <Box className={classes.btnWrapper}>
                    <a href="https://apps.apple.com/us/app/altais-enable/id1552803341" title={btnIosTxt}>
                        <img className={classes.btnImg} src={IosBtn} alt="" height={50}/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=io.altaishealth.enable.production"  title={btnAndroidTxt}>
                        <img className={classes.btnImg} src={AndroidBtn} alt="" height={50}/>
                    </a>
                </Box>

                <Typography variant="h2">
                    <FormattedMessage id="DownloadApp.subheading.text"/>
                </Typography>

                <img alt="" src={PhoneImg} className={classes.bgImage}/>
            </Box>
        </Box>
    )
}

export default DownloadApp