import { IPrefixNamed } from "lib/domain/names";
import { ValueOf } from "lib/util-types";
import { PageRequest, PageResponse } from "lib/domain/service-types";
import { FileUploadData, FileMetadataDetails } from "common/components/files/types";

export type Filter = {
    type: string;
    text: string;
    id: string; // Generated
};
export type FilterSet = Filter[];
export type FilterTypeSet = string[];

export const knownFilterNames = {
    HOSPITAL: "hospital",
    INSURANCE: "insurance",
    LANGUAGE: "language",
    LICENSE_STATE: "license-state",
    NETWORK: "network",
    PROVIDER_NAME: "provider-name",
    SPECIALTY: "specialty",
    SUB_SPECIALTY: "sub-specialty",
};

export type CreateEConsultModalContentProps = {
    onClose: () => void;
};

export type FilterOptionValue = {
    id: string;
    parentId: string;
    value: string;
};

export interface Specialist extends IPrefixNamed {
    id: string;
    hospitals: string[];
    insurances: string[];
    networks: string[];
    /** @deprecated instead of using the providerName, use: prefix, firstName, middleName and lastName */
    providerName: string;
    specialties: string[];
    subSpecialties: string[];
    locations?: Location[];
}

export type ProviderSearchResponse = {
    data: SpecialistDto[];
    page: PageResponse;
};

export interface SpecialistDto extends IPrefixNamed {
    id: string;
    practiceId: string;
    practiceName: string;
    /** @deprecated instead of using the providerName, use: prefix, firstName, middleName and lastName */
    providerName: string;
    credentials: string[];
    npi: string;
    specialties: FilterOptionValue[];
    subSpecialties: FilterOptionValue[];
    networks: FilterOptionValue[];
    hospitals: FilterOptionValue[];
    insurances: FilterOptionValue[];
    licenseStates: FilterOptionValue[];
    languages: FilterOptionValue[];
    locations: Location[];
}

export type Location = {
    name: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    phone: string;
    email: string;
};

export type ProviderSearchContent = {
    data: Specialist[];
    page: PageResponse;
};

export type SearchFilter = {
    type: string;
    values: string[];
};

export type SearchParameters = {
    filters: SearchFilter[];
    page: PageRequest | null;
};

export type PatientInfo = {
    id?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    dateOfBirth?: string;
    sex?: string;
    primaryLanguage?: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    zipCode?: string;
    cellPhoneNumber?: string;
    homePhoneNumber?: string;
    officePhoneNumber?: string;
    insuranceEffective?: string;
    insuranceExpiration?: string;
    insuranceMemberId?: string;
    insuranceName?: string;
    insurancePlanType?: string;
    insurancePlanName?: string;
    mrn?: string;
    ssn?: string;
};

export type EConsultRequestCreateState = {
    id: string | null;
    selectedProviders: Specialist[];
    selectedPatient: string | null;
    notes: string;
    subject: string;
    urgent: boolean;
    duration: string;
    status: EConsultRequestStatus;
    attachments: FileUploadData[];
    version: number | null;
};

export type EConsultRequestValidation = {
    providers: {
        recipientIds: string[];
    };
    patient: {
        patientId: string | null;
    };
    attachments: FileUploadData[];
    caseNotes: {
        notes: string;
        subject: string;
    };
};

export const EConsultRequestStatuses = {
    ASSIGNED: "ASSIGNED",
    CANCELLED: "CANCELLED",
    CLOSED: "CLOSED",
    DRAFT: "DRAFT",
    EXPIRED: "EXPIRED",
    REJECTED: "REJECTED",
    SUBMITTED: "SUBMITTED",
} as const;

export type EConsultRequestStatus = ValueOf<typeof EConsultRequestStatuses>;

export type EConsultRequest = {
    id: string | null;
    attachmentIds: string[];
    recipientIds: string[];
    patientId: string | null;
    referrerId: string | null;
    notes: string;
    subject: string;
    urgent: boolean;
    duration: string | null;
    status: EConsultRequestStatus;
};

export type EConsultChangeStatusRequest = {
    version: number | null;
    status: EConsultRequestStatus;
};

export type EConsultRequestDetails = {
    assigneeId: string | null;
    attachments: FileMetadataDetails[];
    deadlineDate: string | null;
    duration: string | null;
    id: string;
    lastModifiedDate: string;
    notes: string;
    overdue: boolean;
    patient: PatientInfo | null;
    recipients: EConsultRecipientDto[];
    referrer: SpecialistDto;
    status: EConsultRequestStatus;
    subject: string;
    submittedDate: string | null;
    urgent: boolean;
    version: number;
};

export interface EConsultRecipientDto extends IPrefixNamed {
    id: string;
    conversationId: string | undefined | null;
    credentials: [string] | null;
    /** @deprecated instead of using the fullName, use: prefix, firstName, middleName and lastName */
    fullName: string;
    hospitals?: FilterOptionValue[];
    lastModifiedDate: string;
    status: EConsultRecipientStatus;
}

export type EConsultRecipientStatus = "NOT_VIEWED" | "VIEWED" | "ACCEPTED" | "REJECTED";
