import { Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import { useStyles } from "./styles";

export const AhaFooter = ({ providerConfig: config }: any) => {
    const classes = useStyles();
    const ahaFooter = config.navigations.footer.links;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '15%',
                minHeight: '120px',
                width: '100%',
                minWidth: '768px',
                position: 'absolute',
                bottom: '0px',
                background: '#eeeeee'
            }}
        >
            <CssBaseline />
            <Box
                component="footer"
                sx={{
                    py: 4,
                    px: 2,
                    paddingTop: '0px',
                    paddingBottom: '25px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    display: 'block',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">
                    {ahaFooter.map((footer: any) => (
                        <Grid item xs={6} sm={3} key={footer.column}>
                            <ul className={classes.pepFooterLinks}>
                                {footer.columnLinks.map((columnLink: any) => (
                                    <li key={columnLink.name}>
                                        <Link href={columnLink.href} target={columnLink.target ? columnLink.target : "_blank"} variant="subtitle1" color="text.secondary">
                                            {columnLink.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Typography style={{ textIndent: 'calc(10% - 60px)', height: '25px', position: 'absolute', bottom: '0px', width: '100%', fontWeight: '200', background: 'white', color: '#404040', fontFamily: 'sans-serif', fontSize: '8pt', whiteSpace: 'nowrap' }}>Copyright © Brown and Toland Physicians, 2022</Typography>
        </Box>
    );
}
