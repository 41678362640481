import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING } from "admin/theme";


export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        padding: '20px',
        textAlign: 'center',
        minWidth: '800px'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    dlgAction: {
        padding: '16px 32px'
    },
    dialogAction: {
        padding: '5px 32px',
        justifyContent: "center",
        display: 'flex',
    },
    dialogHeaderTitle: {
        flex: 1,
        color: '#000000'
    },
    dialogHeaderSaveButton: {
        marginLeft: '10px'
    },
    dialogActionBar: {
        width: '1145px',
        display: 'flex'
    },
    dialogContentRoot: {
        flex: '1 1 auto',
        overflowY: 'auto',
    },
    dialogContentDividers: {
        padding: '0 0 0 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    pageHeader: {
        margin: '10px'
    },
    pageHeaderText: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
    },
    sectionHeader: {
        marginTop: '16px',
    },
    sectionHeaderText: {
        fontSize: '20px',
        fontWeight: 600,
        color: '#393948'
    },
    dialogHeaderBottom: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentArea: {
        display: 'flex',
        flex: '1 1 auto'
    },
    leftPaneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        maxWidth: '300px',
        minHeight: '494px',
        zIndex: 2,
        position: "relative",
        background: "#E4E4E6",
        borderRadius: `0 0 0 0`,
        width: SIZING.SIDE_NAV_OPEN_WIDTH,
    },
    rightContent: {
        flexGrow: 1,
        overflowY: 'hidden',
        padding: '32px 32px 0px 28px',
        background: '#F6F8FA'
    },
    editIcon: {
        height: '35px',
        width: '35px',
        verticalAlign: 'middle'
    },
    addressSectionField: {
        margin: '5px 5px 5px 5px'
    },
    closeButtonIcon: {
        height: '45px',
        width: '45px',
        verticalAlign: 'middle'
    },
    sideNavOption: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: SIZING.scale1200,
        minWidth: SIZING.scale1200,
        padding: SIZING.scale300,
        margin: "10px",
        borderRadius: BORDER_RADIUS.PILL,
        width: 'auto'
    },
    selected: {
        background: 'none !important',
        color: '#0F7BBD'
    },
    circleNumber: {
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: 400,
        letterSpacing: "0.03333em",
        textDecoration: "inherit",
        textTransform: "inherit",
        color: "white",
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        marginRight: "8px",
        background: "#565662",
        "&:selected": {
            backgroundColor: '#0F7BBD'
        },
        "&:hover": {
            backgroundColor: '#0F7BBD'
        },
        "& path": {
            fill: '#FFFFFF'
        },
    },
    verticalLine: {
        borderLeft: '1px solid #0F7BBD',
        left: "9.5%",
        content: "",
        position: 'absolute',
        height: "25px",
        top: "40px"
    },
    productTableHeader: {
        backgroundColor: '#EDEDEF'
    },
    productsPageHeader: {
        marginBottom: '20px'
    },
    pageHeaderTextProduct: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948',
    },
    pageSubHeaderText: {
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#565662'
    },
    productsIcon: {
        fontSize: '42px',
        verticalAlign: 'middle',
        marginRight: '12px'
    },
    checkMarkIcon: {
        "& path": {
            fill: '#FFFFFF'
        },
    },
    sectionHeader2: {
        margin: '35px 10px 0px'
    },
    sectionFieldRow: {
        marginLeft: '15px',
        marginTop: "10px"
    },
    marginRight10: {
        marginRight: "10px"
    },
    userExistsMsg: {
        textAlign: "left",
        fontSize: "14px",
        marginLeft: "15px"
    },
    administratorIcon: {
        fontSize: '42px',
        verticalAlign: 'middle',
        marginRight: '12px'
    },
    btnMakemeAdministrator: {
        marginLeft: '16px',
        height: '30px',
        '& .MuiButton-label': {
            fontSize: '16px',
        }
    }
}));