import Typography from '@mui/material/Typography';
import { getAppCurrentProfileId } from 'authentication/appAuth';
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as IOAForms } from "icon-library/SVG/Clipboard_Checklist.svg";
import { ReactComponent as PerformanceReports } from "icon-library/SVG/Heart_Bolt.svg";
import { ReactComponent as MembershipReports } from "icon-library/SVG/Person.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import PageImage from '../assests/images/pep-reports.jpg';
import { CardProps, ClickableCard } from '../components/clickable-card';
import { useStyles } from "./styles";

export const PEPReports = ({ onSelect: handleSelection, permissions }: { onSelect?: (menuItem: string) => void, permissions: string[] }) => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueError } = useUserNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("pep.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    let config_left: CardProps[] = [{
        name: `Clinical Performance and Quality Reports`,
        description: "Get the reports on Documentation Integrity and Quality Gap Screenings for your 2023 Network Physician Incentive Program.  The reports include the HCC Recapture open conditions, Diabetes and BP Control for the Quality Composite measure.",
        action: {
            id: "performance_reports"
        },
        icon: PerformanceReports,
        enabled: permissions.includes("AHA_REPORT")
    }, {
        name: `Membership Assignment Report`,
        description: "Get your Managed Care Membership Report. Updated monthly to ensure you have the latest information from our health plan partners regarding your new managed care patients.",
        action: {
            id: "membership_reports"
        },
        icon: MembershipReports,
        enabled: true
    }]

    let config_right: CardProps[] = [{
        name: `Annual Health Assessment Forms`,
        description: <>{`To meet the AHA program requirements and receive the incentives, use provided AHA forms to conduct Medicare Advantage patient exams and assess all care gaps and screenings identified on the assessment. `}
            {`To see Cozeva user training video, click `}<a style={{ color: 'rgb(0,0,238)' }} target={"_self"} onClick={(event) => {
                if (handleSelection) {
                    event.preventDefault();
                    event.stopPropagation();
                    handleSelection("cozeva_video_training");
                }
            }}>{`here`}</a>{`.`}
        </>,
        action: {
            id: "ioa_forms"
        },
        icon: IOAForms,
        enabled: permissions.includes("IOA_REPORT")
    }];

    if ((config_left[0].enabled === false) && (config_left[1].enabled === false) && (config_right[0].enabled === true)) {
        config_left = config_right;
        config_right = [];
    } else if ((config_left[0].enabled === true) && (config_left[1].enabled === true) && (config_right[0].enabled === false)) {
        config_right = [config_left[1]];
        config_left = [config_left[0]];
    }

    return (
        isLoading ? <Spinner /> : (
            <div id="reports_row" className={classes.pep_reports_row}>
                <div id="reports_container" className={classes.pep_reports_container}>
                    <div id="reports_top" className={classes.pep_reports_top}>
                        <div id="reports_banner" className={classes.pep_reports_banner}>
                            <Typography id="reports_banner_top" className={classes.pep_reports_banner_top}>
                                {`Reports`}
                            </Typography>
                        </div>
                        <div style={{ width: '100%', height: '360px', marginBottom: '20px' }}>
                            <img src={PageImage} alt='Reports Logo' />
                        </div>
                    </div>
                    <div id="reports_middle" style={{ display: 'flex', marginTop: '20px' }}>
                        <div id="reports_left" className={classes.pep_reports_left}>
                            {config_left.map((card: CardProps, index: number) => {
                                return <ClickableCard key={`clickable_card_left_${index}`} tall={true} config={card} onSelect={handleSelection} />
                            })}
                        </div>
                        <div id="reports_right" className={classes.pep_reports_right}>
                            {config_right.map((card: CardProps, index: number) => {
                                return <ClickableCard key={`clickable_card_right_${index}`} tall={true} config={card} onSelect={handleSelection} />
                            })}
                        </div>
                    </div>


                </div>
            </div>)
    )
}