import React from "react"
import { useStyles } from "./style"
import WarningIcon from "@mui/icons-material/Warning";
import { Typography } from "@mui/material";

export default function ErrorMessage({ message,className='' }: { message: any, className?: any }) {

    const classes = useStyles();

    if (!message) {
        return null
    }

    return (
        <Typography data-testid="validationError" className={`${classes.error} ${className}`} variant="h6">
            <WarningIcon /> {message}
        </Typography>
    )
}
