import {each} from "underscore";
import {RegistrationState} from "admin/store/reducerTypes";
import {DOB_PATTERN, PASSWORD_VALIDATIONS, REGISTRATION_STEP} from "constant";
import VerifyIdentity from "./VerifyIdentity/VerifyIdentity";
import AcceptTOS from "./AcceptTOS/AcceptTOS";
import AddEmail from "./AddEmail/AddEmail";
import OTPConfirmation from "./OTPConfirmation/OTPConfirmation";
import SetPassword from "./SetPassword/SetPassword";

export const getRegistrationStep = (registration: RegistrationState) => {
    const {registrationStep} = registration
    switch (registrationStep) {
        case REGISTRATION_STEP.PATIENT.VERIFY_IDENTITY:
            return VerifyIdentity
        case REGISTRATION_STEP.PATIENT.ACCEPT_TOS:
            return AcceptTOS
        case REGISTRATION_STEP.PATIENT.ADD_EMAIL:
            return AddEmail
        case REGISTRATION_STEP.PATIENT.OTP_CONFIRMATION_PATIENT:
            return OTPConfirmation
        case REGISTRATION_STEP.PATIENT.SET_PASSWORD:
            return SetPassword
        default:
            return VerifyIdentity
    }
}

export const getRegistrationHeading = (registration: RegistrationState) => {
    const {registrationStep} = registration
    switch (registrationStep) {
        case REGISTRATION_STEP.PATIENT.VERIFY_IDENTITY:
            return "verifyIdentity"
        case REGISTRATION_STEP.PATIENT.ACCEPT_TOS:
            return "acceptTOS"
        case REGISTRATION_STEP.PATIENT.ADD_EMAIL:
            return "addEmail"
        case REGISTRATION_STEP.PATIENT.OTP_CONFIRMATION_PATIENT:
            return "otpConfirmation"
        case REGISTRATION_STEP.PATIENT.SET_PASSWORD:
            return "setPassword"
        default:
            return "verifyIdentity"
    }
}
export const getRegistrationSubheading = (registration: RegistrationState) => {
    const {registrationStep} = registration
    switch (registrationStep) {
        case REGISTRATION_STEP.PATIENT.VERIFY_IDENTITY:
            return "verifyIdentity"
        case REGISTRATION_STEP.PATIENT.ACCEPT_TOS:
            return "acceptTOS"
        case REGISTRATION_STEP.PATIENT.ADD_EMAIL:
            return "addEmail"
        case REGISTRATION_STEP.PATIENT.OTP_CONFIRMATION_PATIENT:
            return "otpConfirmation"
        case REGISTRATION_STEP.PATIENT.SET_PASSWORD:
            return "setPassword"
        default:
            return "verifyIdentity"
    }
}

export const checkPasswordHasPersonal = (password:string, first:string, last:string, email:string) => {
    return password && (password.includes(first) || password.includes(last) || password.includes(email))
}

// validate dob format mm/dd/yyyy, plus valid month/data/year values
export const isDOBValid = (dob:string) => {
    const validDOBPattern = (DOB_PATTERN).test(dob)
    const dobValues = dob.split("/")
    const currYear = new Date().getFullYear()
    if (parseInt(dobValues[0]) < 1 || parseInt(dobValues[0]) > 12) {
        return false
    }
    if (parseInt(dobValues[1]) < 1 || parseInt(dobValues[1]) > 31) {
        return false
    }
    if (parseInt(dobValues[2]) < 1900 || parseInt(dobValues[2]) > currYear) {
        return false
    }
    return validDOBPattern
}


// uses regex to validate passwords match requirements. returns list of issues if any exist
export const validatePasswords = ({password, passwordConfirm, firstName, lastName, email}: RegistrationState) => {
    const validation: {valid: boolean, issues: string[]} = {
        valid: true,
        issues: []
    }

    const containsPersonal = checkPasswordHasPersonal(password, firstName, lastName, email)
    if (password && password === passwordConfirm && !containsPersonal) {
        each(PASSWORD_VALIDATIONS, (validationTest, type) => {
            if (!validationTest.test(password)) {
                validation.issues.push(type)
                validation.valid = false
            }
        })
    } else {
        validation.valid = false
    }
    return validation
}