import React from "react";
import { Checkbox, Typography } from "@mui/material";
import { PopoverItem, PopoverItemRenderer, PopoverItemRendererProps } from './types';
import { ReactComponent as CheckboxChecked } from "icon-library/SVG/Checkbox_Square.svg";
import { ReactComponent as CheckboxUnchecked } from "icon-library/SVG/Checkbox-Light_Square.svg";
import { useStyles } from './styles';

const DefaultPopoverItem: React.FC<PopoverItemRendererProps<PopoverItem>> = ({id, item, onChange}) => {
    const classes = useStyles({ themeColor: 'compose'});

    const onSelected = () => {
        onChange(item.id, !item.selected);
    }

    return (
        <div
            className={classes.popoverItem}
            id={id}
            onClick={onSelected}
        >
            <Checkbox id={`QA_popover_checkbox_${id}`}
                classes={{ root: "popoverCheckboxRoot"}}
                checked={item.selected ?? false}
                onChange={onSelected}
                disableRipple
                icon={<CheckboxUnchecked className={classes.checkBoxIcon} />}
                checkedIcon={<CheckboxChecked className={classes.checkBoxIconSelected} />}
            />
            <div className="popoverLabelContent" title={item.text}>
                {item.content ?? <Typography className="name label" variant="body1" noWrap>{item.text}</Typography>}
            </div>
        </div>
    )
}

const renderPopoverItem: PopoverItemRenderer<PopoverItem> = (props) => {
    return (
        <DefaultPopoverItem
            key={`PopoverItem-${props.item.id}`}
            {...props}
        />
    );
}
export default renderPopoverItem;
