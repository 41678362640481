import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
    titleWrapper: {
        padding: SIZING.scale800,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: COLORS.MODAL_BG,
    },
    modal: {
        width: "29rem",
        background: COLORS.MODAL_BG,
        borderRadius: SIZING.scale400
    },
    close: {
        cursor: 'pointer'
    },
    content: {
        display: "flex",
        flexDirection: "column",
        paddingLeft:SIZING.scale800,
        paddingRight:SIZING.scale800,
        paddingBottom:SIZING.scale400
    },
    readCard:{
       height:SIZING.scale1600,
       padding: SIZING.scale350,
       borderRadius:SIZING.scale100,
       background:COLORS.WHITE,
       marginBottom:SIZING.scale200,
       display:"flex",
       alignItems:"center",
       justifyContent:"space-between"
    },
    participantName:{
       paddingLeft:SIZING.scale200
    },
    nameText: {
       fontSize:SIZING.scale400,
       color:COLORS.LBLUE
    },
    subReadText:{
        fontSize:SIZING.scale275,
        color:COLORS.LBLUE
    },
    recepientDetails: {
       display:"flex",
       alignItems:"center"
    },
    titleUnreadWrapper: {
        paddingLeft: SIZING.scale800,
        paddingRight:SIZING.scale800,
        paddingBottom:SIZING.scale400,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    unReadCard:{
        height:SIZING.scale1600,
        padding:SIZING.scale350,
        borderRadius:SIZING.scale100,
        background:"rgba(0, 0, 0, 0.05)",
        marginBottom:SIZING.scale200,
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
     },
     unReadnameText: {
        fontSize:SIZING.scale400,
        color:COLORS.GREY
     },
     subUnReadText:{
        fontSize:SIZING.scale275,
        color:COLORS.GRAY_50
     }
   

}));