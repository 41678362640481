import React from "react";
import {Box} from "@mui/material";
import {useStyles} from "./styles";


// Divider is used to visualize the separation between list items. A child component can be added to render content
// inside the divider
const Divider = ({children, position, disableMargin = false, color}: any) => {
    const classes = useStyles({color, disableMargin})

    let leftLine = classes.line
    let rightLine = classes.line
    const longLineClass = ` ${classes.longLine}`
    // Change box classes based on position of child component
    switch (position) {
        case "left":
            rightLine += longLineClass
            break
        case "right":
            leftLine += longLineClass
            break
        case "center":
            leftLine += longLineClass
            rightLine += longLineClass
            break
        default:
            rightLine += longLineClass
            break
    }

    return (
        <Box className={classes.divider}>
            <Box className={leftLine}/>
            {children && (
                <Box className={classes.content}>
                    {children}
                </Box>
            )}
            <Box className={rightLine}/>
        </Box>
    )
}

export default Divider