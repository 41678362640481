import { makeStyles } from "@mui/styles";
import { SIZING } from "admin/theme";

export const useStyles = makeStyles(() => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        borderRadius: '24px',
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.12), inset 0px 1px 1px rgba(0, 0, 0, 0.04)'
    },
    heading: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948'
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    categoryTitle: {
        color: "rgba(126, 144, 179, 0.8)"
    },
    category: {
        marginBottom: SIZING.scale500
    },

    paper: {
        width: '100%',
        marginTop: '16px'
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableFooterRow: {
        background: '#EDEDEF',
        border: '1px solid #E4E4E6',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px -1px 0px #E7E7ED',
        borderRadius: '0px 0px 8px 8px',
        position: "sticky",
        bottom: 0,
        zIndex: 2
    },
    footerCaption: {
        fontSize: '12px',
        fontWeight: 'normal'
    },
    paginationSpacer: {
        flex: 0
    },
    whiteChevron: {
        "& path": {
            fill: '#FFFFFF'
        }
    },
    tableChevron: {
        "& path": {
            fill: '#393948'
        },
        height: '16px',
        width: '16px',
    },
    highlight: {
        background: '#E8F5FE'
    },
    infoIcon: {
        width: '17px',
        height: '17px',
        cursor: 'pointer'
    },
    infoTooltipText: {
        fontSize: '18px',
        textAlign: 'left',
        marginLeft: '12px',
        marginRight: '4px',
    },
    addBtn: {
        marginTop: '16px',
        marginRight: '16px',
        width: '60px',
        height: '42px',
        "& .MuiButton-label": {
            fontSize: '12px',
            margin: '0',
            lineHeight: '0'
        },
    },
    continueLaterBanner: {
        backgroundColor: "#0F7BBD",
        padding: '12px',
        marginBottom: '12px'
    },
    continueLaterBannerHeading: {
        fontSize: '17px',
        fontWeight: 'bold',
        color: '#FFFFFF'
    },
    continueLaterBannerSubHeading: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#FFFFFF'
    },
    orgSetupIcon: {
        height: '33px',
        cursor: 'pointer'
    },
    footerIcon: {
        right: -15
    },
    stickyHeader: {
        overflowX: "initial",
        zIndex: 2
    },
    stickyLastColumn: {
        position: "sticky",
        zIndex: 1,
        right: 0,
        background: "#ffffff"
    },
    statusActiveBox: {
        border: '2px solid #00A875',
        padding: '5px',
        borderRadius: '7px',
        width: '80px',
        textAlign: 'center',
        color: '#212131'
    }
}));