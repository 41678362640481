import {
    Box, Button, DialogActions, DialogContent, IconButton, Paper,
    //Tab,
    //Tabs,
    Typography
} from "@mui/material";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'admin/components/Modal/Modal';
import { MODAL_TYPES } from 'admin/constant';
import { setActiveModal } from 'admin/store/actions';
import { ReactComponent as Close } from 'admin/assets/common/Close.svg';
import { useStyles } from './styles';
import AddPatientForm from "./AddPatientForm";
import Spinner from "common/components/Spinner/Spinner";
import AddPatientBulk from './AddPatientBulk';
import { appendEhrSystemKeyTemplatePath } from "admin/common/utils";
import { AppReduxStore } from "admin/store/reducerTypes";
const { PATIENT_BULK_UPLOAD_CSV_TEMPLATE } = (window as any).env;

const AddPatient = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [tab/*, setTab*/] = useState(0);
    const [isBulkUploadDisabled, setIsBulkUploadDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const { auth, practice, admin } = useSelector((state: AppReduxStore) => state);
    const addPatientFormRef = useRef<any>();
    const addPatientBulkRef = useRef<any>();

    const handleClose = () => {
        dispatch(setActiveModal(""));
        setIsBulkUploadDisabled(true);
    };

    /*
    const handleChangeTab = (event: any, newTab: any) => {
        setTab(newTab);
    };
    */

    /*
    const handleSavePatient = (event: any) => {
        let result = addPatientFormRef.current.submitForm(event);
        if (result)
            handleClose();
    };

    const handleSaveAddPatient = (event: any) => {
        addPatientFormRef.current.submitForm(event);
    };
    */

    const modalClasses = {
        paper: classes.wrapper
    };


    const handleBulkUpload = () => {
        addPatientBulkRef.current.submitBulkUpload()
    }

    const handleAddedFile = () => {
        setIsBulkUploadDisabled(false);
    };
    const handleRemovedFile = () => {
        setIsBulkUploadDisabled(true);
    };

    const handleLoading = (event: any) => {
        setLoading(event)
    }

    const ehrInfo = admin.ehrSourceSystems?.find((e: any) => e.id === practice?.sPractice?.ehrSourceSystemId)

    return (
        <Modal id={MODAL_TYPES.ADD_PATIENT} classes={modalClasses} onClose={handleClose}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '16px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"AddPatient.ModalHeading"} />
                    </Typography>
                </Box>
                <Box>
                    {tab === 0 && <IconButton id="btnClosePatientModal" size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>}
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '16px' }}>
                <Box flexGrow={1}>
                    {tab === 0 && ehrInfo?.hasTemplate &&
                        <Button variant="contained"
                            aria-haspopup="true" href={appendEhrSystemKeyTemplatePath(PATIENT_BULK_UPLOAD_CSV_TEMPLATE,
                                auth.currentPractice?.ehrSourceSystemId || practice?.sPractice?.ehrSourceSystemId)}>
                            {intl.formatMessage({ id: "AddPatient.DowloadTemplate" })}
                        </Button>}
                </Box>
                <Box>
                    {/* {tab === 1 ? <Fragment>
                        <Button id="btnCancel" variant="contained" color="secondary" 
                            aria-haspopup="true" onClick={handleClose}>
                            {intl.formatMessage({ id: "AddPatient.Action.Cancel" })}
                        </Button>
                        <Button id="btnSaveAndAddAnother" variant="contained" color="primary" style={{ marginLeft: '10px' }}
                            aria-haspopup="true" onClick={handleSaveAddPatient}>
                            {intl.formatMessage({ id: "AddPatient.Action.SaveAndAddAnother" })}
                        </Button>
                        <Button id="btnSaveAndFinish" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSavePatient}>
                            {intl.formatMessage({ id: "AddPatient.Action.SaveAndFinish" })}
                        </Button>
                    </Fragment> : */}
                    {isLoading ? <Spinner /> :
                        <Button id="btnBulkUpload" variant="outlined" color="primary" aria-haspopup="true" onClick={handleBulkUpload} disabled={isBulkUploadDisabled}>
                            Upload
                        </Button>}
                    {/* } */}
                </Box>
            </DialogActions>
            {/* <DialogActions className={classes.dlgAction}>
                <Box width="100%">
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        onChange={handleChangeTab}
                        aria-label="Add Patient tab">
                        <Tab label={intl.formatMessage({ id: "AddPatient.BulkUpload" })} />
                        <Tab label={intl.formatMessage({ id: "AddPatient.Tab.AddPatient" })} />
                    </Tabs>
                </Box>
            </DialogActions> */}
            <DialogContent dividers={true} classes={tab === 1 ? { dividers: classes.dialodContentDividers } : {}}>
                <Paper square elevation={0}>
                    {tab === 1 ? <Box className={classes.tabContentWrapper}>
                        <AddPatientForm ref={addPatientFormRef} />
                    </Box> :
                        <Box className={classes.tabContentWrapper}>
                            <AddPatientBulk ref={addPatientBulkRef} handleAddedFile={handleAddedFile} handleRemovedFile={handleRemovedFile} handleLoading={handleLoading} handleClose={handleClose}></AddPatientBulk>
                        </Box>
                    }
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default AddPatient;
