import { makeStyles } from "@mui/styles";
import {SIZING} from "theme";


export const useStyles = makeStyles(() =>({
    root: {
        position:'relative',
    "& .react-draggable":{
        zIndex:9,
        height:SIZING.ZOOM_MEETING_MODAL_HEIGHT,
        position:'absolute'
    }
    }
}));