import React from "react";
import classNames from "classnames";
import { ReactComponent as CrossIcon } from "icon-library/SVG/Cross_Circle.svg";
import { Filter, GetFilterTypeTranslation } from "../types";
import { useStyles } from "../styles";
import {
    FilteredSearchInput__FilterItem,
    FilteredSearchInput__FilterItem__Text,
    FilteredSearchInput__FilterItem__Type,
} from "../class-names";

type FilterItemProps = {
    filter: Filter;
    idPrefix: string;
    onCrossClicked: (filterId: string) => void;
    getFilterTypeTranslation?: GetFilterTypeTranslation;
};

const defaultFilterTypeTranslator: GetFilterTypeTranslation = (filterType: string) => filterType;

const FilterItem: React.FC<FilterItemProps> = ({
    filter,
    idPrefix,
    onCrossClicked,
    getFilterTypeTranslation = defaultFilterTypeTranslator,
}) => {
    const classes = useStyles();

    const QAId = `${idPrefix}_${filter.type}_${filter.text}`;
    const CloseIconQAId = `${QAId}_close_icon`;

    return (
        <div
            className={classNames(classes.FilteredSearchInput__FilterItem, FilteredSearchInput__FilterItem)}
            id={QAId}
            data-testid={QAId}
        >
            <span className={FilteredSearchInput__FilterItem__Text} title={filter.text}>
                {filter.text}
            </span>
            <span className={FilteredSearchInput__FilterItem__Type}>{getFilterTypeTranslation(filter.type)}</span>
            <CrossIcon id={CloseIconQAId} data-testid={CloseIconQAId} onClick={() => onCrossClicked(filter.id)} />
        </div>
    );
};

export default FilterItem;
