import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => {
    return {
        rootWrapper: {
            height: 'calc(100% - 150px)',
            width: 'calc(100% - 432px)',

        },
        patienDetailsHeader: {
            padding: SIZING.scale450,
            width: "100%",
            height: SIZING.scale3600,
            background: COLORS.MODAL_BG,
            boxShadow: "6px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
        },
        patienDetails: {
            display: "flex",
            marginBottom: SIZING.scale200,
            height: SIZING.scale1600,
            alignItems: "center"
        },
        patientNameAndInfo: {
            marginTop: SIZING.scale100
        },
        userIcon: {
            marginRight: SIZING.scale300,
            height: `${SIZING.scale1600} !important`,
            width: `${SIZING.scale1600} !important`,
            "&> g": {
                fill: COLORS.LBLUE
            }
        },
        patientName: {
            fontSize: SIZING.scale400,
            fontWeight: 900,
            marginBottom: SIZING.scale125,
            color: COLORS.LBLUE
        },
        patientId: {
            fontSize: SIZING.scale350,
            fontWeight: 400,
            marginLeft: SIZING.scale125,
        },
        ageAndGender: {
            fontSize: SIZING.scale350,
            marginBottom: SIZING.scale125,
            marginRight: SIZING.scale125,
            fontWeight: 700,
        },
        patientInfo: {
            display: "flex"
        },
        actionButton: {
            height: SIZING.scale1200,
            width: SIZING.scale3600,
            background: COLORS.WHITE,
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)",
            fontSize: SIZING.scale450,
            color: COLORS.PRIMARY_BTN_TEXT,
            fontWeight: 600,
            display: "flex",
            marginRight: SIZING.scale550,
            justifyContent: "space-around",
            "& svg path": {
                fill: COLORS.PRIMARY_BTN_TEXT
            },
            "&:hover": {
                backgroundColor: COLORS.WHITE
            },
        },
        dlgAction: {
            paddingRight: SIZING.scale350,
        },
        patientDetailsContent: {
            width: "100%",
            height: "100%",
            background: COLORS.MODAL_BG
        }
    }
})
