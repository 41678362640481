import { IMeta, IFileWithMeta } from "react-dropzone-uploader";
import { MessageDescriptor } from "@formatjs/intl";
import { PageableRequest } from "lib/domain/service-types";
import { ValueOf } from "lib/util-types";


export const ThumbnailDimensions = {
    x32: "32x32",
    x64: "64x64",
    x128: "128x128",
    x256: "256x256",
    x512: "512x512",
} as const;

export type FileUploadData = {
    /**
     * id given by the file-service
     */
    id: string;
    status: "UPLOADING" | "UPLOADED" | "ERROR";
};

export type FileMetadataDetails = {
    height: number;
    id: string;
    mimeType: string;
    name: string;
    size: number;
    status: "UPLOADING" | "UPLOADED" | "EXPIRED" | "DELETED";
    thumbs: ValueOf<typeof ThumbnailDimensions>[];
    width: number;
};

export type FileDropzoneInputValidateFn = (
    fileWithMeta: IFileWithMeta,
    allFiles: IFileWithMeta[]
) => MessageDescriptor | undefined;

export type IMetaWithFileId = IMeta & { fileId: string };

export type FileLocation = {
    url: string;
};

export interface FileMetadataSearchRequest extends PageableRequest {
    fileIds: string[];
    // Todo: sort and text not implemented here because they are not need so far.
}

export interface FileMetadataSearch extends PageableRequest {
    data: FileMetadataDetails[];
}

export type ThumbnailKey = {
    dimension: string;
    id: string;
};

export interface ThumbnailLocationsRequest {
    keys: ThumbnailKey[] | null;
}

export type ThumbnailLocation = {
    dimension: string;
    id: string;
    url: string;
};
