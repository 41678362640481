import React from "react";
import { Button, Collapse } from "@mui/material";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { MODAL_TYPES } from "constant";
import { ReactComponent as WaitRing } from "icon-library/SVG/Wait_Ring-Thick.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { DialogModalProps } from "./types";
import { useStyles } from "./styles";

const DialogModal: React.FC<DialogModalProps> = ({
    title,
    children,
    onClose,
    className,
    idPrefix,
    loading,
    errorMessage,
    ...props
}) => {
    const classes = useStyles();
    return (
        <Modal
            classes={{
                paper: classNames(classes.root, className, {
                    "dialog--loading": loading,
                }),
            }}
            id={MODAL_TYPES.PROVIDER.GENERIC_DIALOG}
            forceOpen={true}
        >
            <div id={idPrefix} data-testid={idPrefix}>
                <div className="dialog__header">
                    <div className="dialog__title">{title}</div>
                    {props.secondaryAction && (
                        <Button
                            id={`${idPrefix}_secondary_action`}
                            data-testid={`${idPrefix}_close_icon`}
                            className="dialog__secondaryAction"
                            color="primary"
                            variant="contained"
                            onClick={props.secondaryAction.onClick}
                        >
                            {props.secondaryAction.label}
                        </Button>
                    )}
                    {props.primaryAction && (
                        <Button
                            id={`${idPrefix}_primary_action`}
                            data-testid={`${idPrefix}_close_icon`}
                            className={classNames("dialog__primaryAction", {
                                dialog__dangerAction: props.primaryAction.danger,
                            })}
                            variant="contained"
                            color="inherit"
                            onClick={props.primaryAction.onClick}
                        >
                            {props.primaryAction.label}
                        </Button>
                    )}
                    {onClose && (
                        <SVGIcon
                            id={`${idPrefix}_close_icon`}
                            data-testid={`${idPrefix}_close_icon`}
                            svg={Close}
                            className="dialog__closeIcon"
                            onClick={onClose}
                            size="scale800"
                            trim
                        />
                    )}
                </div>
                <Collapse in={Boolean(errorMessage)}><div className="dialog__error">{errorMessage}</div></Collapse>
                <div className="dialog__body">
                    <div className="dialog__content">{children}</div>
                    {loading && (
                        <div className="dialog__loaderRow">
                            <SVGIcon svg={WaitRing} size="scale1600" />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default DialogModal;
