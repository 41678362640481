import { call } from "store/api";

export const updateUserPassword = (username: string, oldPassword: string, newPassword: string) => {
    return new Promise((resolve, reject) => {
        call("POST", `/admin/v1/user/changepassword`, { username, newPassword, oldPassword }).then(() => {
            resolve({ message: 'Password changed successfully' })
        }).catch((error) => {
            reject(error)
        })

    })
}

export const forgotUserPassword = (username: string) => {
    return new Promise((resolve, reject) => {
        call("POST", `/admin/v1/user/forgotPassword`, { platform: "WEB", username }).then(() => {
            resolve({ message: 'Password change email sent successfully' })
        }).catch((error) => {
            reject(error)
        })
    })
}

export const forgotUserPasswordByAdmin = (username: string) => {
    return new Promise((resolve, reject) => {
        call("POST", `/admin/v1/user/forgotPasswordByAdmin`, { platform: "WEB", username }).then(() => {
            resolve({ message: 'Password change email sent successfully' })
        }).catch((error) => {
            reject(error)
        })
    })
}

export const updateSecretQuestions = (secretQuestionsObj: any) => {
    return new Promise((resolve, reject) => {
        call("POST", `/admin/v1/user/changeRecoveryQuestion`, { password: btoa(secretQuestionsObj.password), newRecoveryQuestion: secretQuestionsObj.newRecoveryQuestion, newRecoveryAnswer: btoa(secretQuestionsObj.newRecoveryAnswer) }).then(() => {
            resolve({ message: 'Security Question added successfully' })
        }).catch((error) => {
            reject(error)
        })

    })
}