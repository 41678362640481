import React from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { AppReduxStore } from "admin/store/reducerTypes";

import { ModalProps } from "./types";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";

const Modal = ({ id, children, onClose, classes, small }: ModalProps) => {
    const { activeModal } = useSelector((store: AppReduxStore) => store.modalAdmin)
    const dispatch = useDispatch()
    const styles = useStyles()
    const open = activeModal === id

    const onModalClose = (event: Event, reason: string) => {
        if (reason !== 'backdropClick') {
            dispatch(setActiveModal(""))
            if (onClose) {
                onClose()
            }
        }
    }

    return (
        open ? <Dialog disableEscapeKeyDown maxWidth={small ? "sm" : "lg"} classes={{ ...classes, paper: classes?.paper ? classes.paper : styles.modal }} open={open} onClose={(event: Event, reason: string) => { onModalClose(event, reason) }}>
            {children}
        </Dialog> : <></>
    )
}

export default Modal