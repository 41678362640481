import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "png/theme";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        zIndex:6,
        width:'100%'
    },
    buttonWrapper:{
        marginLeft:'auto'
    },
    initialCard: {
        height: "3.5rem",
        width: "100%",
        padding: SIZING.scale300,
        display: "flex",
        alignItems: "center",
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);',
        "& img":{
            height: SIZING.scale800
        }
    },
    practiceName: {
        fontWeight: 700,
        marginLeft: SIZING.scale300,
        lineHeight: SIZING.scale500,
        fontSize: SIZING.scale400,
        color: COLORS.WHITE
    },
    joinbutton: {
        background: COLORS.RED,
        height:SIZING.scale1000,
        padding:SIZING.scale400,
        fontSize: SIZING.scale350,
        '@media (max-width:600px)': {
            marginTop: SIZING.scale200,
          },
        marginLeft:'auto',
        "&:hover": {
            background: COLORS.RED,
        },

        "& .MuiButton-label": {
            fontWeight: 400,
            fontSize: SIZING.scale400,
            '@media (max-width:600px)': {
                fontSize: SIZING.scale300,
              },
        },
        color: COLORS.PRIMARY_BTN,
        borderRadius: SIZING.WAITING_JOIN_BTN_BOR_RAD,
        border: `1px solid ${COLORS.WHITE}`,
        [theme.breakpoints.up('lg')]: {
            display:({ isHideButton }:any)=>isHideButton?'none':'inline-flex'
        },
    },
}));