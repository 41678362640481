import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "admin/theme";


export const useStyles = makeStyles(() =>({
    wrapperAuto: {
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 'bold',
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        background: '#F6F8FA',
        textAlign: 'center',
        minWidth: '800px'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    dlgAction: {
        padding: '5px 32px'
    },
    dropzoneClass: {
        background: '#EEF9FF'
    },
    dropzoneFailClass: {
        background: 'rgba(232, 44, 42, 0.1)',
        border: '2px dashed rgba(232, 44, 42, 0.1)',
        padding: '15px',
        borderRadius: "8px"
    },
    failContent: {
        color: '#E82C2A',
        fontSize: '24px',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'24px'
    },
    failSecondContent: {
        margin: '15px'
    },
    failureSecondText: {
        color: '#E82C2A',
        fontSize: '16px'
    },
    warningIcon: {
        height: '39px',
        width: '39px',
        marginRight: '10px',
        "& path": {
            fill: '#E82C2A',
        },
    },
    failFirstContent: {
        margin: '24px'
    },
    successText: {
        margin: '3px',
        color: '#00A875',
        fontSize: '17px',
        fontWeight: 'bold',
        display:'flex',
        alignItems:'center',
    },
    duplicatesText: {
        color: '#393948',
        margin: '3px',
        fontSize: '17px',
        fontWeight: 'bold',
        display:'flex',
        alignItems:'center'
    },
    failureText: {
        color: '#E82C2A',
        margin: '3px',
        fontSize: '17px',
        fontWeight: 'bold',
        display:'flex',
        alignItems:'center',
    },
    textTitle:{
        width:'50%',
        marginRight:'5px',
        textAlign:'end',
        color:'#212131',
        fontWeight:'normal'
    },
    previewGridClasses: {
        marginTop: '10px'
    },
    autoInviteText: {
        fontWeight: 600,
        fontSize: '14px',
        color: '#393948',
        float: 'right'
    },
    bulkUploadHeading: {
        fontWeight: 600,
        fontSize: '14px',
        color: '#393948',
        textAlign: 'left'
    },
    userExistsMsg: {
        textAlign: "left",
        fontSize: "14px"
    }
}));