import {
    Box, Button, DialogActions, DialogContent, Paper,
    Typography
} from "@mui/material";
import React, { Fragment, useRef } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'admin/components/Modal/Modal';
import { MODAL_TYPES } from 'admin/constant';
import { setActiveModal } from 'admin/store/actions';
import { useStyles } from './styles';
import EditPatientForm from "./EditPatientForm";
import { AppReduxStore } from 'admin/store/reducerTypes';

const EditPatient = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { auth } = useSelector((state: AppReduxStore) => state);
    const editPatientFormRef = useRef<any>();

    const handleClose = () => {
        dispatch(setActiveModal(""));
    };
    const handleSavePatient = (event: any) => {
        const result = editPatientFormRef.current.submitForm(event);
        if (result)
            handleClose();
    };

    const handleDeletePatient = () => {
        dispatch(setActiveModal(MODAL_TYPES.DELETE_PATIENT_CONFIRMATION_MODAL));
    }
    const modalClasses = {
        paper: classes.wrapper
    };


    return (
        <Modal id={MODAL_TYPES.EDIT_PATIENT} classes={modalClasses} onClose={handleClose}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '16px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"EditPatient.ModalHeading"} />
                    </Typography>
                </Box>
                <Box>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '16px' }}>
                <Box flexGrow={1}>
                    {auth.currentPractice && !auth.currentPractice?.practiceEhrSettingId && <Button id="btnDeletePatient" variant="outlined" color="primary" style={{ zIndex: 100, background: '#E32B29' }}
                        aria-haspopup="true" onClick={handleDeletePatient}>
                        {intl.formatMessage({ id: "EditPatient.Action.DeletePatient" })}
                    </Button>}

                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnDiscardChanges" variant="outlined"
                            aria-haspopup="true" onClick={handleClose}>
                            {intl.formatMessage({ id: "EditPatient.Action.Cancel" })}
                        </Button>
                        <Button id="btnSaveAndFinish" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSavePatient}>
                            {intl.formatMessage({ id: "EditPatient.Action.Update" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ dividers: classes.dialogContentDividers }}>
                <Paper square elevation={0}>
                    <EditPatientForm ref={editPatientFormRef} />
                </Paper>
            </DialogContent>
        </Modal >
    )
}

export default EditPatient;