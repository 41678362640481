import {Box, TextField} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {useStyles} from "./styles";
import {FieldFormProps, ErrorHelpTextProps} from "./types";
import {COLORS} from "admin/theme";
import CustomTypography from "../CustomTypography/CustomTypography";
import React, {useState} from "react"
import { ReactComponent as WarningCircle } from "icon-library/SVG/Warning_Circle.svg";

export const ErrorHelpText = ({ errorId }: ErrorHelpTextProps) => {
    const intl = useIntl();
    const classes = useStyles();

    if (!errorId) {
        return null;
    }

    return (
        <>
            <WarningCircle
                className={classes.warningIcon}
                data-testid="warning-circle-icon"
                viewBox="5 5 22 22"
                width={16}
            />
            {intl.formatMessage({id: errorId})}
        </>
    );
};

// FormField is a generic input field used in forms.
const FormField = (props: FieldFormProps) => {
    const {
        value,
        onChange,           // onChange is used to update the value as well as handle validation
        labelId,            // labelId represents the text id shown above the input
        autoFocus = false,
        disabled = false,
        type = "text",      // type is the html5 type of input (eg text, password, email...)
        errorId,            // errorId represents the text id shown when there is an error in the validation of the value
        inputComponent,     // inputComponent is used to replace the default input
        altLabel,           // altLabel represents the text id shown to the right of the label id, usually has some functionality attached
        altFn = null,       // altFn is the callback function that is triggered when the altLabel is clicked on
        className = "",
        multiline,
        placeholder,
        minRows,
        maxRows,
    } = props
    const classes = useStyles()
    const [showPassword, setShowPassword] = useState(false)

    // When the input type is a password we show an additional button to toggle the type between password and text
    let endAdornment = null
    if (type === "password") {
        const id = showPassword ? "FormField.endAdornment.hide" : "FormField.endAdornment.show"

        endAdornment = (
            <CustomTypography variant="subtitle2" onClick={() => setShowPassword(!showPassword)} className={classes.showPassword}>
                <FormattedMessage id={id} />
            </CustomTypography>
        )
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                { labelId && (
                    <CustomTypography variant="description" className={classes.labelWrapper}>
                        <label htmlFor={labelId}>
                            <FormattedMessage id={labelId}/>
                        </label>
                    </CustomTypography>
                )}
                {altLabel && (
                    <CustomTypography onClick={altFn} variant="description" >
                        <label className={classes.altLabel} htmlFor={labelId}>
                            <FormattedMessage id={altLabel}/>
                        </label>
                    </CustomTypography>
                )}
            </Box>
            <TextField
                id={labelId}
                error={!!errorId}
                helperText={<ErrorHelpText errorId={errorId}/>}
                disabled={disabled}
                autoFocus={autoFocus}
                type={showPassword ? "text" : type}
                className={`${classes.inputWrapper} ${className}`}
                variant="outlined"
                value={value}
                multiline={multiline}
                placeholder={placeholder}
                minRows={minRows}
                maxRows={maxRows}
                onChange={(e: any) => onChange(e.target.value)}
                classes={{
                    root: classes.root
                }}
                InputProps={{
                    style: {
                        background: errorId ? COLORS.INPUT_ERROR_BG : COLORS.INPUT_BG
                    },
                    inputComponent,
                    endAdornment
                }}
            />
        </>
    )
}

export default FormField
