import { makeStyles } from "@mui/styles";
import { SIZING } from "theme";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        minWidth: ({ modalWidth }: any) => (modalWidth ? modalWidth : "900px"),
        minHeight: "200px",
        background: "linear-gradient(0deg, #E6EAF1, #E6EAF1)",
        borderRadius: SIZING.scale800,
        [theme.breakpoints.down("md")]: {
            minWidth: "600px",
            maxWidth: "90vw",
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "420px",
        },
        "& .Modal__Content": {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "hidden",
        },
    },
}));
