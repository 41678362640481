import { Patient } from "lib/business-components/patient-search/types";
import { getAgeWithFormat } from "utils";
import { formatName, FormatNameVariants } from "lib/domain/names";
import { ProviderProfile, UserState } from "store/reducerTypes";
import {
    CareTeamState,
    MemberRole,
    CareTeam,
    CareTeamDetailsResponse,
    CareTeamDetails,
    CareTeamPatient,
    CareTeamPatientDetailsResponse,
    CareTeamPatientDetails,
    PatientSearchResponse,
    CareTeamMemberDetails,
    CareTeamMemberDetailsResponse,
    CareTeamCreator,
    CareTeamCreatorResponse,
    SelectedProvider,
} from "../types";
import { sortMembers, sortPatients } from "./utils";

const providerRoles: MemberRole[] = ["SPECIALIST"];
const adminRoles: MemberRole[] = ["ADMIN"];
const primaryRoles: MemberRole[] = ["PRIMARY", "ADMIN", "SPECIALIST"];
const genderMapping = {
    M: ["m", "male", "man", "boy"],
    F: ["f", "female", "woman", "girl"],
    O: ["transgender", "hermaphrodite"],
    // "?": [], // any other, including null and undefined
};

const getPracticeId = (careTeamState: CareTeamState): string => {
    return careTeamState.selectedProviders.find((selectedProvider) => selectedProvider.practiceId)?.practiceId ?? "";
};

/** Exported for testing purposes only! */
export const cleanGenderCode = (genderCode: string | null | undefined) => {
    if (genderCode?.trim()) {
        const trimmedGenderCode = genderCode.trim();
        return trimmedGenderCode.length === 1
            ? trimmedGenderCode
            : Object.entries(genderMapping).reduce((mappedGenderCode, [key, values]) => {
                  if (values.includes(trimmedGenderCode.toLowerCase())) {
                      return key;
                  }
                  return mappedGenderCode;
              }, "?");
    }
    return "?";
};

export const mapToCareTeamRequest = (careTeamState: CareTeamState): CareTeam => {
    return {
        name: careTeamState.name,
        members: careTeamState.selectedProviders.map(({ primary, admin, ...selectedProvider }) => ({
            practiceId: selectedProvider.practiceId,
            providerId: selectedProvider.id,
            roles: primary ? primaryRoles : admin ? adminRoles : providerRoles,
        })),
        patients: careTeamState.selectedPatients.map(
            (selectedPatient) =>
                ({
                    ...selectedPatient,
                    patientId: selectedPatient.id,
                    practiceId: selectedPatient.practiceId ?? getPracticeId(careTeamState),
                } as CareTeamPatient)
        ),
        version: 0,
    };
};

const mapToCareTeamPatientDetails = (
    patientDetailsResponse: CareTeamPatientDetailsResponse
): CareTeamPatientDetails => {
    const displayName = formatName(patientDetailsResponse, FormatNameVariants.BY_LAST_NAME_UPPERCASE);

    return {
        ...patientDetailsResponse,
        age: getAgeWithFormat(patientDetailsResponse.dateOfBirth, "YYYY-MM-DD") || undefined,
        genderCode: cleanGenderCode(patientDetailsResponse.genderCode),
        id: patientDetailsResponse.patientId,
        displayName,
    };
};

const mapToCareTeamMemberDetails = (memberDetailsResponse: CareTeamMemberDetailsResponse): CareTeamMemberDetails => {
    const sortName = formatName({
        ...memberDetailsResponse,
        prefix: null,
    });

    const displayName = formatName(memberDetailsResponse);

    return {
        ...memberDetailsResponse,
        displayName,
        sortName,
    };
};

const mapToCareTeamCreator = (creatorResponse: CareTeamCreatorResponse): CareTeamCreator => {
    const displayName = formatName(creatorResponse);

    return {
        ...creatorResponse,
        displayName,
    };
};

export const mapToCareTeamDetails = ({
    creator,
    patients,
    members,
    ...careTeamDetailsResponse
}: CareTeamDetailsResponse): CareTeamDetails => {
    return {
        ...careTeamDetailsResponse,
        creator: mapToCareTeamCreator(creator),
        patients: sortPatients(patients.map(mapToCareTeamPatientDetails)),
        members: sortMembers(members.map(mapToCareTeamMemberDetails)),
    };
};

export const mapToPatientSearchResponseData = (data: PatientSearchResponse["data"]): PatientSearchResponse["data"] =>
    data.map(
        (patient: Patient): Patient => ({
            ...patient,
            age: getAgeWithFormat(patient.dateOfBirth, "YYYY-MM-DD") || undefined,
            displayName: formatName(patient, FormatNameVariants.BY_LAST_NAME_UPPERCASE),
            genderCode: cleanGenderCode(patient.genderCode),
        })
    );

export const mapCurrentUserToSelectedProvider = (user: UserState): SelectedProvider | null => {
    const profileInfo = user.currentProfileRoleJoin;
    const providerProfile: ProviderProfile | null = profileInfo?.providerProfile;

    if (!profileInfo || !providerProfile) return null;

    const displayName = formatName(providerProfile);
    const sortName = formatName({
        ...providerProfile,
        prefix: null,
    });

    return {
        id: providerProfile.id,
        firstName: providerProfile.firstName,
        hospitals: [],
        insurances: [],
        languages: [],
        lastName: providerProfile.lastName,
        licenseStates: [],
        locations: [],
        middleName: providerProfile.middleName,
        networks: [],
        npi: providerProfile.npi,
        practiceId: profileInfo.practice.id,
        practiceName: profileInfo.practice.name,
        prefix: providerProfile.prefix,
        providerName: displayName,
        specialties: [],
        subSpecialties: [],
        displayName,
        sortName,
    };
};
