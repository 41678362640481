import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "store/reducerTypes";
import {Box, Typography} from "@mui/material";
import {isEmpty, map} from "underscore";
import Spinner from "common/components/Spinner/Spinner";
import {useStyles} from "./styles";
import moment from "moment";
import {useIntl} from "react-intl";
import {SIZING} from "theme";
import EmptyContentMessage from "common/components/EmptyContentMessage/EmptyContentMessage";
import {EmptyForms} from "assets";
import FormDetails from "views/People/FormDetails/FormDetails";
import {getForms, pushFormToPatient} from "store/actions";
import {ReactComponent as LightClosedEnvelope} from "icon-library/SVG/eMail-Light--Closed-Envelope.svg";
import {createSvgIcon} from "utils";

const CompletedDate = ({started, completed, progress}: {started: boolean, completed: string, progress: string}) => {
    const classes = useStyles()
    const intl = useIntl()

    const isPending = !completed
    const statusKey = completed ? "People.forms.complete" : started ? "People.forms.inProgress" : "People.forms.sentNotComplete"
    const statusString = intl.formatMessage({id: statusKey});
    const formattedDate = isPending ? (progress + "%") : moment(completed).format("MMM D, Y")
    const styles = isPending ? `${classes.incomplete}` : ""

    return (
        <Typography className={styles} variant="subtitle1">
            {statusString}&nbsp;&nbsp;&nbsp;&nbsp;{formattedDate}
        </Typography>
    )
}

const PushForm = ({selectedForm} : {selectedForm:any}) => {
    const classes = useStyles();
    const PushFormIcon = createSvgIcon(LightClosedEnvelope);
    const dispatch = useDispatch();
    const handlePushForm = () => dispatch(pushFormToPatient(selectedForm));

    return (
        <Box className={classes.hidden}>
            <PushFormIcon onClick={handlePushForm}/>
        </Box>
    )
}

const Forms = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { loadingForms, forms, selectedForm } = useSelector((state: AppReduxStore) => state.patient)

    useEffect(() => {
        dispatch(getForms())
    }, [dispatch])

    if (loadingForms) return <Spinner/>

    if (selectedForm) return <FormDetails />

    if (isEmpty(forms)) return <EmptyContentMessage Icon={EmptyForms} title="People.forms.none" subtitle={null}/>

    return (
        <Box className={classes.formsOverviewBody} id="QA_patientForms">
            <Box className={classes.container}>
                {/*@ts-ignore*/}
                {map(forms, (form, idx) => {
                    const {title, completed, started, progress} = form

                    if (progress > 100) return null

                    const handleClick = () => dispatch({type: 'SET_SELECTED_FORM', payload: form})

                    return (
                        <Box className={classes.formOverview} onClick={handleClick} key={idx}>
                            <Box display="flex">
                                <EmptyForms/>
                                <Box marginLeft={SIZING.scale300}>
                                    <Typography variant="body1">
                                        {title}
                                    </Typography>
                                    <CompletedDate started={started} completed={completed} progress={progress}/>
                                </Box>
                            </Box>
                            <PushForm selectedForm={form}/>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default Forms