import { Box } from "@mui/material";
import { getAppCurrentProfileId } from "authentication/appAuth";
import IncomingCall from "components/IncomingCall/IncomingCall";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "store/actions";
import { AppReduxStore } from "store/reducerTypes";
import { useStyles } from "./styles";

// AppWrapper handles twilio client initiation, incoming calls, and fetching the current user when the auth token updates
const AppWrapper = ({children}: any) => {
    const dispatch = useDispatch()
    const {enqueueSnackbar,closeSnackbar} = useSnackbar()
    const user = useSelector((store:AppReduxStore) => store.user)
    const {meetingId} = useSelector((store:AppReduxStore) => store.meeting.incoming)
    const classes = useStyles()


    // handle incoming call ui
    useEffect(() => {
        if (meetingId) {
           enqueueSnackbar("incoming call", {
                variant: "info",
                persist: true,
                preventDuplicate: true,
                content: (key) => {
                    return ( 
                        <Box>
                            <IncomingCall snackbarKey={key} />
                        </Box>)
                }
            })
        }else{
         closeSnackbar()
        }
    }, [enqueueSnackbar, meetingId,closeSnackbar])

    // fetch the current user
    useEffect(() => {
        const getUser = async () =>  {
            dispatch(getCurrentUser(getAppCurrentProfileId()))
        }
        if (!user.username) {
            getUser()
        }
    },[dispatch, user.username])

    return (
        <Box display="flex" height="100%" className={classes.root}>
            {children}
        </Box>
    )
}

export default AppWrapper