import { Box } from "@mui/material";
import { formatDateType2, getAgeWithFormat } from "../../../../utils";
import { FormattedMessage } from "react-intl";
import { PatientInfo } from "../../types";
import { useStyles } from "./styles";
import PatientBaseInfo from "./patient-base-info";
import classNames from "classnames";

type Patient = {
    patient: PatientInfo;
    className?: string;
};

const PatientDetailsItem = ({ patient, className }: Patient) => {
    const classes = useStyles();

    const getPatientName = (firstName?: string, middleName?: string, lastName?: string): string => {
        const lastNameString = lastName ? lastName.toUpperCase().concat(", ") : "";
        const firstNameString = firstName ? firstName.concat(" ") : "";
        const middleNameString = middleName ? middleName : "";
        return getValue(lastNameString + firstNameString + middleNameString);
    };

    const getValue = (value?: string): string => {
        return value ? value : "-";
    };

    const getDobAge = (): string => {
        const dateOfBirth = patient.dateOfBirth ? formatDateType2(patient.dateOfBirth) : "-";
        const age = patient.dateOfBirth ? getAgeWithFormat(patient.dateOfBirth, "yyyy-MM-dd") : "-";
        return dateOfBirth.concat(" / ", age.toString());
    };

    const getEffective = (): string => {
        if (!patient.insuranceEffective && !patient.insuranceExpiration) {
            return "N/A";
        }
        const insuranceEffective = patient.insuranceEffective ? formatDateType2(patient.insuranceEffective) : "N/A";
        const insuranceExpiration = patient.insuranceExpiration ? formatDateType2(patient.insuranceExpiration) : "N/A";
        return insuranceEffective.concat(" - ", insuranceExpiration);
    };

    const getAddress = (): string => {
        const addressWithoutZip = [patient.address, patient.address2, patient.city, patient.state]
            .filter(Boolean)
            .join(", ");
        return [addressWithoutZip, patient.zipCode].filter(Boolean).join(" ");
    };

    if (!patient) {
        return (
            <div className={classNames(className, "withoutPatient")}>
                <span><FormattedMessage id="EConsult.request.content.noPatient" /></span>
            </div>
        );
    }

    return (
        <div className={classNames(className, "withPatient")}>
            <div className={classes.patientInfo}>
                <div className="topPatientInfoArea">
                    <div className={classes.row}>
                        <PatientBaseInfo
                            textId="EConsult.newRequestModal.patient.name"
                            value={getPatientName(patient.firstName, patient.middleName, patient.lastName)}
                            className={classes.topPatientInfo}
                        />
                    </div>
                    <div className={classes.row}>
                        <PatientBaseInfo
                            textId="EConsult.newRequestModal.patient.age"
                            value={getDobAge()}
                            className={classes.topPatientInfo}
                        />
                    </div>
                    <div className={classes.row}>
                        <PatientBaseInfo
                            textId="EConsult.newRequestModal.patient.sex"
                            value={getValue(patient.sex)}
                            className={classes.topPatientInfo}
                        />
                    </div>
                </div>

                <div className={classes.divider}/>

                <div className="additionalPatientInfoArea">
                    <div className={classes.row}>
                        <PatientBaseInfo
                            textId="EConsult.newRequestModal.patient.address"
                            value={getValue(getAddress())}
                            className={classes.patientSimpleInfo}
                        />
                    </div>

                    <div className={classes.row}>
                        <PatientBaseInfo
                            textId="EConsult.newRequestModal.patient.phone"
                            className={classes.patientSimpleInfo}
                        />
                        <Box display="inline-flex" flexDirection="column" className={classes.subInfo}>
                            <PatientBaseInfo
                                textId="EConsult.newRequestModal.patient.cellPhone"
                                value={getValue(patient.cellPhoneNumber)}
                                className={classes.patientSubInfo}
                            />
                            <PatientBaseInfo
                                textId="EConsult.newRequestModal.patient.homePhone"
                                value={getValue(patient.homePhoneNumber)}
                                className={classes.patientSubInfo}
                            />
                            <PatientBaseInfo
                                textId="EConsult.newRequestModal.patient.workPhone"
                                value={getValue(patient.officePhoneNumber)}
                                className={classes.patientSubInfo}
                            />
                        </Box>
                    </div>

                    <div className={classes.row}>
                        <PatientBaseInfo
                            textId="EConsult.newRequestModal.patient.language"
                            value={getValue(patient.primaryLanguage)}
                            className={classes.patientSimpleInfo}
                        />
                    </div>
                </div>

                <div className={classes.dividerSecond}/>

                <div className="additionalPatientInfoArea">
                    <div className={classes.row}>
                        <PatientBaseInfo
                            textId="EConsult.newRequestModal.patient.ssn"
                            value={getValue(patient.ssn)}
                            className={classes.patientSimpleInfo}
                        />
                    </div>

                    <Box className={classes.row}>
                        <PatientBaseInfo
                            textId="EConsult.newRequestModal.patient.insurance"
                            className={classes.patientSimpleInfo}
                        />
                        <Box display="inline-flex" flexDirection="column" className={classes.subInfo}>
                            <PatientBaseInfo
                                textId="EConsult.newRequestModal.patient.insurer"
                                value={getValue(patient.insuranceName)}
                                className={classes.patientSubInfo}
                            />
                            <PatientBaseInfo
                                textId="EConsult.newRequestModal.patient.plan"
                                value={`${getValue(patient.insurancePlanName)} / ${getValue(
                                    patient.insurancePlanType
                                )}`}
                                className={classes.patientSubInfo}
                            />
                            <PatientBaseInfo
                                textId="EConsult.newRequestModal.patient.memberId"
                                value={getValue(patient.insuranceMemberId)}
                                className={classes.patientSubInfo}
                            />
                            <PatientBaseInfo
                                textId="EConsult.newRequestModal.patient.effective"
                                value={getEffective()}
                                className={classes.patientSubInfo}
                            />
                        </Box>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default PatientDetailsItem;
