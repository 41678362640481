import {
    Box, Button, Checkbox, Grid, IconButton, InputBase, Paper,
    Table, TableBody, TableCell, TableContainer,
    TableFooter, TableHead,
    TablePagination, TableRow, TableSortLabel, Typography, Tooltip
} from "@mui/material";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import BulkActionMenu from "admin/components/BulkActionMenu/BulkActionMenu";
import GridContextMenu from "admin/components/GridContextMenu/GridContextMenu";
import StatusFilterMenu from "admin/components/StatusFilterMenu/StatusFilterMenu";
import UserLevelDetailsMenu from "admin/components/UserLevelDetailsMenu/UserLevelDetailsMenu";
import { MODAL_TYPES } from "admin/constant";
import {
    changeAccountStatus, getUsersByOrgOrPractice,
    sendInvites, setActiveModal, setBulkEditUserProduts, setIsAllSelectedForBulk, setNavBreadcrumbText, setSelectedPracticeId, setSelectedProfileRoleID, setSelectedUsers, setUsersFilterAndSortOptions, unlockAccountAdmin
} from "admin/store/actions";
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from "admin/store/reducerTypes";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import UserBulkActionMenu from 'admin/components/BulkActionMenuContent/UserBulkActionMenu/UserBulkActionMenu';
import UserGridContextMenu from 'admin/components/GridContextMenuContent/UserGridContextMenu/UserGridContextMenu';
import { getProductsForOrganization, getProductsForPractice } from "admin/store/actions/license";
import TablePaginationActions from "admin/components/TablePaginationActions/TablePaginationActions";
import { setSelectedOrganization } from "admin/store/actions/organization";
import ProductsFilterMenu from "admin/components/ProductsFilterMenu/ProductsFilterMenu";
import TableSelectDropdown from "admin/components/TableSelectDropdown/TableSelectDropdown";
import { canEditUsers } from "admin/Accessibility";
import { LicenseWidgets } from "admin/components/LicenseWidgets/LicenseWidgets";
import { ReactComponent as EllipsisV } from "icon-library/SVG/Ellipsis-Vertical.svg";
import { createSvgIcon } from "utils";
const EllipsisVIcon = createSvgIcon(EllipsisV);
const borders = {
    Active: '2px solid #00A875',
    Pending: '2px solid #F5A800',
    Inactive: '2px solid #909098',
    Suspend: '2px solid #E82C2A'
}

interface Data {
    userId: string
    profileRoleId: string
    profileId: string
    username: string
    firstName: string
    lastName: string
    fullName: string
    ehrUsername: string
    userLevel: string
    status: string
    lastLoginTime: string
    productCount: any
    action: string
}

const UserListing = () => {

    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    type Order = 'asc' | 'desc';

    const intl = useIntl()
    const classes = useStyles();
    const dispatch = useDispatch()

    const { auth, admin, license } = useSelector((state: AppReduxStore) => state)
    const { userLevels, users, userList, usersFilterText, usersSortBy, usersSortAsc, usersPageSize, filterHaveAnyProducts, filterProductsIds, filterAccountStatus } = admin
    const { orgLicenses, practiceLicenses } = license
    const [orderBy, setOrderBy] = useState<keyof Data>('fullName');
    const [order, setOrder] = useState<Order>('asc');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [allSelectedCheked, setAllSelectedCheked] = useState(false);
    const [contextMenuData, setContextMenuData] = useState({} as any);
    const showProductColumn = (auth.currentOrg && auth.currentOrg?.parent) || (auth.currentPractice)
    useEffect(() => {
        //componentWillUnmount
        return function cleanup() {
            dispatch(setIsAllSelectedForBulk(false))
            dispatch(setBulkEditUserProduts(false))
        }
    }, [dispatch])
    useEffect(() => {
        if (auth.currentProfileRoleJoinInfo) {
            dispatch(getUsersByOrgOrPractice())
            dispatch(setIsAllSelectedForBulk(false))
        }
    }, [auth.currentOrg?.id, auth.currentPractice?.id, auth.currentProfileRoleJoinInfo, dispatch])

    useEffect(() => {
        dispatch(getUsersByOrgOrPractice())
    }, [page, rowsPerPage, orderBy, order, filterHaveAnyProducts, filterProductsIds, filterAccountStatus, dispatch]);

    useEffect(() => {
        if (auth.currentOrg?.id) {
            dispatch(getProductsForOrganization(auth.currentOrg?.id))
        }
        if (auth.currentPractice?.id) {
            dispatch(getProductsForPractice(auth.currentPractice?.id))
        }
    }, [auth.currentOrg?.id, auth.currentPractice?.id, dispatch]);

    const handleAddUser = () => {
        dispatch(setActiveModal(MODAL_TYPES.ADD_USER))
    };

    const handleFullNameClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, profileId: any, fullName: any) => {
        dispatch(setSelectedProfileRoleID(profileId))
        dispatch(setNavBreadcrumbText(fullName))
    }

    const handleSearch = (event: any) => {
        const searchStr = event.target.value.toLowerCase()
        setPage(0);
        dispatch(setUsersFilterAndSortOptions(searchStr, 0, usersPageSize, usersSortBy, usersSortAsc, filterHaveAnyProducts, filterProductsIds, filterAccountStatus))
        dispatch(getUsersByOrgOrPractice())
    }

    const openEditUserModal = () => {
        dispatch(setActiveModal(MODAL_TYPES.EDIT_USER))
    }
    const openResetPasswordModal = () => {
        dispatch(setActiveModal(MODAL_TYPES.RESET_PASSWORD))
    }
    const onAccountUnlocked = (changed: boolean) => {
        if (changed) {
            dispatch(getUsersByOrgOrPractice())
        }
    }
    const handleRowAction = (type: string, profileRoleId: any, profileId: any, userName: any) => {
        switch (type) {
            case 'EditUser':
                dispatch(setSelectedProfileRoleID(profileId, openEditUserModal))
                break;
            case 'DeleteUser':
                dispatch(setSelectedProfileRoleID(profileId))
                dispatch(setActiveModal(MODAL_TYPES.DELETE_USER_CONFIRMATION_MODAL))
                break;
            case 'DisableAltais':
                if (profileRoleId) {
                    dispatch(changeAccountStatus([profileRoleId], "INACTIVE"))
                }
                break;
            case 'SendInvite':
                if (userName && profileRoleId) {
                    dispatch(sendInvites([profileRoleId]))
                } else {
                    dispatch(showNotification("Error", "error", intl.formatMessage({ id: "Admin.SendInvite.MissingEmail" })))
                }
                break;
            case 'EnableAccount':
                if (profileRoleId) {
                    dispatch(changeAccountStatus([profileRoleId], "ACTIVE"))
                }
                break;
            case 'ResetPassword':
                dispatch(setSelectedProfileRoleID(profileId, openResetPasswordModal))
                break;
            case 'UnlockAccount':
                dispatch(unlockAccountAdmin(userName, onAccountUnlocked))
                break;
            case 'EditProducts':
                if (profileRoleId) {
                    dispatch(setSelectedProfileRoleID(profileId))
                    dispatch(setBulkEditUserProduts(false))
                    dispatch(setActiveModal(MODAL_TYPES.EDIT_USER_PRODUCTS))
                } else {
                    dispatch(showNotification("Can't edit products", "error", "No role assigned"))
                }
                break;
            case 'SuspendAccount':
                if (profileRoleId) {
                    dispatch(changeAccountStatus([profileRoleId], "SUSPEND"))
                }
                break;

            default:
                dispatch(showNotification("TODO", "error", "Not Implemented"))
        }

    }

    const handleStatusFilterAction = (values: any) => {
        dispatch(setUsersFilterAndSortOptions(usersFilterText, 0, usersPageSize, usersSortBy, usersSortAsc, filterHaveAnyProducts, filterProductsIds, values))
    }

    const handleBulkAction = (value: any) => {
        if (!allSelectedCheked && (!selected || selected.length === 0)) {
            dispatch(showNotification("Bulk Action", "error", "No User(s) Selected"))
            return
        }
        const sProfileJoinIdsAndEmails = selected && selected.map((sProfileId: any) => {
            const u = userList && userList.find((u: any) =>
                (u.profileId === sProfileId)
            )
            return { profileRoleId: u?.profileRoleId, email: u?.email }
        })
        const sProfileJoinIds = sProfileJoinIdsAndEmails.map(u => u.profileRoleId)
        switch (value) {
            case 'EditUserLevel':
                dispatch(setSelectedUsers(selected))
                dispatch(setActiveModal(MODAL_TYPES.UPDATE_USER_LEVEL_MODAL))
                break;
            case 'Deactivate':
                dispatch(changeAccountStatus(sProfileJoinIds, "INACTIVE"))
                break;
            case 'Activate':
                dispatch(changeAccountStatus(sProfileJoinIds, "ACTIVE"))
                break;
            case 'SendInvites':
                if (allSelectedCheked) {
                    dispatch(sendInvites([]))
                }
                else {
                    const sProfileRoleIdsHaveEmails = sProfileJoinIdsAndEmails.filter(u => u.profileRoleId && u.email)
                    if (sProfileRoleIdsHaveEmails && sProfileRoleIdsHaveEmails.length > 0) {
                        dispatch(sendInvites(sProfileRoleIdsHaveEmails.map(u => u.profileRoleId)))
                    }
                    const sProfileRoleIdsDontHaveEmails = sProfileJoinIdsAndEmails.filter(u => !u.email || u.email?.trim() === "")
                    if (sProfileRoleIdsDontHaveEmails?.length > 0) {
                        dispatch(showNotification("Error", "error",
                            `${sProfileRoleIdsDontHaveEmails?.length} ${intl.formatMessage({ id: "Admin.SendInvite.MissingEmail" })}`
                        ))
                    }
                }
                break;
            case 'EditProducts':
                dispatch(setSelectedUsers(selected))
                dispatch(setBulkEditUserProduts(true))
                dispatch(setActiveModal(MODAL_TYPES.EDIT_USER_PRODUCTS))
                break;
            default:
        }
        setSelected([]);
    };
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        const sortOrder = isAsc ? 'desc' : 'asc'
        setOrder(sortOrder);
        setOrderBy(property);
        setPage(0);
        dispatch(setUsersFilterAndSortOptions(usersFilterText, 0, usersPageSize, property, isAsc, filterHaveAnyProducts, filterProductsIds, filterAccountStatus))
    }

    const handleSelectAllVisibleChanged = (isAllSelectedOnPage: boolean) => {
        setAllSelectedCheked(false)
        dispatch(setIsAllSelectedForBulk(false))
        if (isAllSelectedOnPage) {
            const newSelecteds = userList.map((n: any) => n.profileId && n.profileId.toString());
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);

    }
    const handleSelectAllChanged = (isAllSelected: boolean) => {
        setAllSelectedCheked(isAllSelected)
        dispatch(setIsAllSelectedForBulk(isAllSelected))
        setSelected([]);
    }
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    }

    const handleCheckboxClick = (event: React.MouseEvent<unknown>, profileId: string) => {
        const selectedIndex = selected.indexOf(profileId);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, profileId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        dispatch(setUsersFilterAndSortOptions(usersFilterText, newPage, usersPageSize, usersSortBy, usersSortAsc, filterHaveAnyProducts, filterProductsIds, filterAccountStatus))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rowsperPage = parseInt(event.target.value)
        setRowsPerPage(rowsperPage);
        setPage(0);
        dispatch(setUsersFilterAndSortOptions(usersFilterText, 0, rowsperPage, usersSortBy, usersSortAsc, filterHaveAnyProducts, filterProductsIds, filterAccountStatus))
    }

    const handleEditProducts = (e: any) => {
        if (auth.currentPractice && practiceLicenses && practiceLicenses?.length > 0) {
            dispatch(setSelectedPracticeId(auth.currentPractice?.id, ""))
            dispatch(getProductsForPractice(auth.currentPractice?.id, true))
            dispatch(setActiveModal(MODAL_TYPES.EDIT_PRACTICE_PRODUCTS))
        }
        else {
            dispatch(setSelectedOrganization(auth.currentOrg?.id));
            dispatch(getProductsForOrganization(auth.currentOrg?.id, true))
            dispatch(setActiveModal(MODAL_TYPES.EDIT_ORG_PRODUCTS))
        }
    }

    const handleProductsFilterAction = (e?: { haveAnyProducts: boolean, productIds: any }) => {
        e && dispatch(setUsersFilterAndSortOptions(usersFilterText, 0, rowsPerPage, usersSortBy, usersSortAsc, e.haveAnyProducts, e.productIds, filterAccountStatus))
    }
    const handleEllipsesActionClick = (event: any, row: any, index: number) => {
        event.preventDefault();
        setContextMenuData({ mouseX: event.clientX - 194, mouseY: event.clientY - 25, row, index })

    };
    const handleRowContextMenuClose = (event: any) => {
        setContextMenuData({ mouseX: 0, mouseY: 0, row: null, index: null })
    };
    const RowContextMenu = () => {
        const row = contextMenuData?.row
        if (!row) return
        return (<GridContextMenu mouseX={contextMenuData?.mouseX} mouseY={contextMenuData?.mouseY} onClose={handleRowContextMenuClose}
            callback={(type: any) => { handleRowAction(type, row.profileRoleId, row.profileId, row.username) }}
            formNumber={""} formTitle={""} handleFormRow={null}
            gridContextMenuContent={UserGridContextMenu} index={contextMenuData?.index} showActiveAccount={row.status === "INACTIVE"}
            showSendInvite={row.status === "PENDING" || row.status === "NONE" || row.status === null}
            showEditProducts={row.status === "SUSPEND" || row.status === "INACTIVE" || ["ALTAIS_ADMIN_1", "ALTAIS_ADMIN_2", "ALTAIS_ADMIN_3", "ALTAIS_ADMIN_4"].includes(row.levelName) ? false : true}
            showResetPassword={row.email?.toLowerCase() === auth.currentProfileRoleJoinInfo.profileEmail?.toLowerCase() || row.status === "ACTIVE" || row.status === "SUSPEND" || row.isAccountLocked === "true"}
            showSuspendAccount={row.status === "ACTIVE" || row.isAccountLocked === "true"}
            showRestoreAccount={row.status === "SUSPEND"}
            showUnlockAccount={row.isAccountLocked === "true"} />)
    }
    const Pagination = () => {
        return (<>
            {userList && userList.length > 0 && <TablePagination className={classes.tableFooterRow}
                rowsPerPageOptions={[25, 50, 100]}
                classes={{ selectIcon: classes.footerIcon, select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
                count={users?.totalElements}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Per Page"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            }
        </>)
    }
    const canEditUser = canEditUsers()
    return (
        <Box className={classes.usersWrapper}>
            {((orgLicenses && orgLicenses?.length > 0 && auth.currentOrg?.parent) || (auth.currentPractice && practiceLicenses && practiceLicenses?.length > 0)) && <Grid container>
                <Grid item xs>
                    <Button id="btnEditProducts" variant="contained" color="primary" style={{ float: 'right', marginBottom: '10px' }} onClick={handleEditProducts}>
                        {intl.formatMessage({ id: "Admin.Label.EditProducts" })}
                    </Button>
                </Grid>
            </Grid>}
            {auth.currentOrg && orgLicenses && orgLicenses.length > 0 &&
                <Grid container style={{ marginBottom: '56px' }}>
                    {orgLicenses && <LicenseWidgets products={orgLicenses} />}
                </Grid>}
            {auth.currentPractice && practiceLicenses && practiceLicenses.length > 0 &&
                <Grid container style={{ marginBottom: '56px' }}>
                    {practiceLicenses && <LicenseWidgets products={practiceLicenses} />}
                </Grid>}
            <Grid container>
                <Grid item xs>
                    {canEditUser && <BulkActionMenu callback={handleBulkAction} bulkActionMenuContent={UserBulkActionMenu} />}
                </Grid>
                <Grid item xs>
                    <Paper component="form" className={classes.root}>
                        <IconButton id="btnSearchUsers" className={classes.iconButton} aria-label="search" size="large">
                            <SearchIcon />
                        </IconButton>
                        <InputBase type="search" id="txtSearchUsers"
                            className={classes.input} onChange={handleSearch}
                            onKeyPress={(ev: any) => { if (ev.key === 'Enter') { ev.preventDefault() } }}
                            placeholder="Search to filter the table below"
                            inputProps={{ 'aria-label': 'Search to filter the table below' }}
                        />

                    </Paper>
                </Grid>
                <Grid item xs>
                    {canEditUser && <Button id="btnAddUsers" variant="contained" color="primary" style={{ float: 'right' }} onClick={handleAddUser}>
                        Add Users
                    </Button>}
                </Grid>
            </Grid>
            {(selected.length > 0 || allSelectedCheked) && <Typography noWrap variant="body1" style={{ fontSize: '16px', color: "#565662", paddingTop: "8px" }}>
                {allSelectedCheked ? users?.totalElements : selected.length} Selected
            </Typography>}
            <Paper className={classes.paper}>
                <TableContainer className={classes.stickyHeader}>
                    <Table id="tableUserListing"
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <TableSelectDropdown onSelectAllOnPage={handleSelectAllVisibleChanged} onSelectAll={handleSelectAllChanged} />
                                </TableCell>
                                <TableCell
                                    key={"fullName"}
                                    align={'left'}
                                    padding={'none'}
                                    sortDirection={orderBy === "fullName" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "fullName"}
                                        direction={orderBy === "fullName" ? order : 'asc'}
                                        onClick={createSortHandler("fullName")}>
                                        {'User'}
                                        {orderBy === "fullName" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"userLevel"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "userLevel" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "userLevel"}
                                        direction={orderBy === "userLevel" ? order : 'asc'}
                                        onClick={createSortHandler("userLevel")}>
                                        <strong>
                                            {'User Level'}
                                        </strong>
                                        {orderBy === "userLevel" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                    {/* <Tooltip title="Help" aria-label="Help">
                                        <IconButton>
                                            <Help />
                                        </IconButton>
                                    </Tooltip> */}
                                    <UserLevelDetailsMenu userLevels={userLevels} />
                                </TableCell>
                                <TableCell
                                    key={"status"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "status" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "status"}
                                        direction={orderBy === "status" ? order : 'asc'}>
                                        <strong onClick={createSortHandler("status")}>
                                            {'Account Status '}
                                        </strong>
                                        {orderBy === "status" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                    <StatusFilterMenu callback={handleStatusFilterAction} filterStatus={admin.filterAccountStatus} />
                                </TableCell>
                                {showProductColumn && <TableCell
                                    key={"products"}
                                    align={'left'}
                                    padding="normal">
                                    {'Products'}
                                    <ProductsFilterMenu callback={handleProductsFilterAction} />
                                </TableCell>}
                                <TableCell
                                    key={"lastLoginTime"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "lastLoginTime" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "lastLoginTime"}
                                        direction={orderBy === "lastLoginTime" ? order : 'asc'}
                                        onClick={createSortHandler("lastLoginTime")}>
                                        {'Last Log In'}
                                        {orderBy === "lastLoginTime" ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {//stableSort(userList, getComparator(order, orderBy))
                                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                userList.map((row: any, index: number) => {
                                    const isItemSelected = isSelected(row.profileId ? row.profileId.toString() : '');
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    let border = '';
                                    switch (row.status) {
                                        case "ACTIVE": border = borders['Active']; break;
                                        case "PENDING": border = borders['Pending']; break;
                                        case "SUSPEND": border = borders['Suspend']; break;
                                        default: border = borders['Inactive']; break;
                                    }
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox color="primary" onClick={(event: any) => handleCheckboxClick(event, row.profileId ? row.profileId.toString() : '')}
                                                    checked={isItemSelected || allSelectedCheked} disabled={allSelectedCheked}
                                                    inputProps={{ 'aria-labelledby': labelId }} />
                                            </TableCell>
                                            <TableCell id={labelId} scope="row" padding="none" width={250}>
                                                <Link to="/admin/users/details" onClick={(e) => handleFullNameClick(e, row.profileId, row.fullName)}>
                                                    <Tooltip arrow title={row.fullName ? row.fullName.toString() : ''}>
                                                        <Typography noWrap variant="body1" style={{ color: '#0F7BBD', width: '250px', fontSize: '14px', fontWeight: 600 }}> {row.fullName}</Typography>
                                                    </Tooltip>
                                                    <Box display="flex" justifyContent="space-between">
                                                        <Typography noWrap variant="body1" style={{ color: '#393948', width: '250px', fontSize: '13px', fontWeight: 400 }}> {row.ehrUsername}</Typography>
                                                    </Box>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="left">{row.userLevel}</TableCell>
                                            <TableCell align="center" style={{ textAlign: 'right' }}>
                                                {row.isAccountLocked === "true" ? <Box style={{ border: borders['Inactive'], padding: '5px', borderRadius: '7px', width: '100px', textAlign: 'center' }}> {"Locked"}</Box> :
                                                    <Box style={{ border: border, padding: '5px', borderRadius: '7px', width: '100px', textAlign: 'center' }}>
                                                        {row.status === "ACTIVE" ? "Registered" :
                                                            row.status === "PENDING" ? "Pending" :
                                                                row.status === "INACTIVE" ? "Deactivated" :
                                                                    row.status === "SUSPEND" ? "Suspended" :
                                                                        "None"
                                                        }
                                                    </Box>}
                                            </TableCell>
                                            {showProductColumn && <TableCell>
                                                {["ALTAIS_ADMIN_1", "ALTAIS_ADMIN_2", "ALTAIS_ADMIN_3", "ALTAIS_ADMIN_4"].includes(row.levelName) ? "" : row.productCount}
                                                {/* <UserProductsView products={row.products} /> */}
                                            </TableCell>}
                                            <TableCell align="left">{row.lastLoginTime && moment(row.lastLoginTime).format("MM/DD/YYYY")}</TableCell>
                                            <TableCell align="right" className={classes.stickyLastColumn}>
                                                {canEditUser && <IconButton id={"btnRowActionContextMenu" + index} style={{ padding: '10px' }} onClick={(e) => handleEllipsesActionClick(e, row, index)} size="large">
                                                    <EllipsisVIcon />
                                                </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {canEditUser && contextMenuData?.row && RowContextMenu()}
                        </TableBody>
                        <TableFooter>
                            {
                                userList && userList.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                ) : <TableRow>
                                    <Pagination />
                                </TableRow>
                            }
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}
export default UserListing