import React, { useState, useEffect, isValidElement, cloneElement, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DialogModal from "components/Modals/dialog-modal/dialog-modal";
import FileDropzoneInput, { FileDropzoneInputRef } from "./file-dropzone-input";
import { ReactElementWithRef } from "types";
import { useCombinedRefs } from "common/utils/use-combined-refs";

type FileDropzoneInputConfirmCloseProps = {
    children: ReactElementWithRef<typeof FileDropzoneInput>;
    onChange: (show: boolean) => void;
    messageId?: string;
    show?: boolean;
    titleId?: string;
};

const FileDropzoneInputConfirmClose = ({
    children,
    messageId,
    onChange,
    show,
    titleId,
}: FileDropzoneInputConfirmCloseProps) => {
    const intl = useIntl();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showingDropzone, setShowingDropzone] = useState(show);

    const dropzoneRef = useRef<FileDropzoneInputRef>();

    useEffect(() => {
        const hasFiles = dropzoneRef.current && dropzoneRef.current?.getFiles().length > 0;

        // hide dropzone without confirmation if there are no files
        if (!show && !hasFiles) {
            setShowingDropzone(false);
        } else {
            setShowConfirmation(!show);
        }
        if (show) {
            setShowingDropzone(true);
        }
    }, [show]);

    const combinedDropzoneRef = useCombinedRefs(dropzoneRef, isValidElement(children) ? children.ref : null);
    const dropzoneClone = isValidElement(children) && cloneElement(children, { ref: combinedDropzoneRef });

    return (
        <>
            {showingDropzone && dropzoneClone}
            {showConfirmation && (
                <DialogModal
                    idPrefix="QA_files_close_file_dropzone_input_dialog"
                    title={intl.formatMessage({
                        id: titleId || "files.fileDropzoneInputConfirmClose.title",
                    })}
                    primaryAction={{
                        label: intl.formatMessage({
                            id: "files.confirm",
                        }),
                        danger: true,
                        onClick: () => {
                            // TODO in the future we should DELETE all files form the service at this point
                            setShowingDropzone(false);
                            setShowConfirmation(false);
                        },
                    }}
                    secondaryAction={{
                        label: intl.formatMessage({
                            id: "files.cancel",
                        }),
                        onClick: () => {
                            onChange(true);
                        },
                    }}
                >
                    <FormattedMessage id={messageId || "files.fileDropzoneInputConfirmClose.message"} />
                </DialogModal>
            )}
        </>
    );
};

export default FileDropzoneInputConfirmClose;
