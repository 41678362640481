import { useState, useEffect } from "react";
import { useCareTeamDetails } from "./components/care-team-details-hooks";
import { CareTeamMemberList } from "./components/care-team-member-list";
import { CareTeamPatientList } from "./components/care-team-patient-list";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import * as CSS from "./class-names";
import { PageMain, PageMainBody, PageMainHead } from "lib/ui-components/page";
import { CareTeamDetailsParams } from "./routes";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { TabContainer, Tab } from "common/components/Tabs";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import { useUserNotification } from "common/utils/use-user-notification";
import { ValueOf } from "lib/util-types";

const TabNames = {
    MEMBERS: "members",
    PATIENTS: "patients",
} as const;

type TabName = ValueOf<typeof TabNames>;

export const CareTeamDetails = () => {
    const { id } = useParams<CareTeamDetailsParams>();
    const { careTeam, hasResult, error } = useCareTeamDetails({ id });
    const [selectedTab, setSelectedTab] = useState<TabName>(TabNames.MEMBERS);
    const { enqueueError } = useUserNotification();

    useEffect(() => {
        if (error) {
            enqueueError("careTeam.details.error");
        }
    }, [enqueueError, error]);

    if (!hasResult) {
        return (
            <PageMain>
                <ComponentLoader />
            </PageMain>
        );
    }

    if (careTeam) {
        return (
            <>
                <PageMainHead testId="QA_careTeam_details_header" className={CSS.CareTeamDetailsHeader}>
                    <CustomTypography
                        variant="titleHeader"
                        data-testid="QA_careTeam_details_name"
                        className={CSS.CareTeamDetailsHeaderTitle}
                    >
                        {careTeam.name}
                    </CustomTypography>
                    <CustomTypography
                        variant="titleHeaderLight"
                        data-testid="QA_careTeam_details_owner_name"
                        className={CSS.CareTeamDetailsHeaderSubTitle}
                    >
                        <FormattedMessage
                            id="careTeam.details.header.createdBy"
                            values={{ name: careTeam.creator.fullName }}
                        />
                    </CustomTypography>
                    <TabContainer boxed>
                        <Tab<TabName>
                            name="members"
                            labelId="careTeam.tabs.members"
                            selected={selectedTab === TabNames.MEMBERS}
                            onTabSelected={setSelectedTab}
                            downArrow
                        />
                        <Tab<TabName>
                            name="patients"
                            labelId="careTeam.tabs.patients"
                            selected={selectedTab === TabNames.PATIENTS}
                            onTabSelected={setSelectedTab}
                            downArrow
                        />
                    </TabContainer>
                </PageMainHead>
                <PageMainBody testId="QA_careTeam_details_body" className={CSS.CareTeamDetailsContent}>
                    {selectedTab === TabNames.MEMBERS && <CareTeamMemberList members={careTeam.members} />}
                    {selectedTab === TabNames.PATIENTS && <CareTeamPatientList patients={careTeam.patients} />}
                </PageMainBody>
            </>
        );
    }

    return null;
};
