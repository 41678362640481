import { AnyMenuItem } from "common/components/OptionMenu/types";
import {Box, Button, Input, Typography} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import { debounce, filter as uFilter, reduce } from "underscore";

import {ReactComponent as Envelope} from "icon-library/SVG/eMail_Closed-Envelope.svg";

import {searchConversations, setActiveModal} from "store/actions";
import { ConversationType, CONVERSATION_TYPE, MODAL_TYPES } from "constant";

import {useStyles} from "./styles";
import {ThreadsHeaderProps} from "./types";
import SearchFilterTabs from "components/SearchFilterTabs/SearchFilterTabs";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {createSvgIcon} from "utils";
import {ReactComponent as User} from "icon-library/SVG/User_Single.svg";
import {ReactComponent as Stethoscope} from "icon-library/SVG/Stethoscope.svg";
import OptionMenu from "common/components/OptionMenu/OptionMenu";
import { AppReduxStore } from "store/reducerTypes";
import { ConversationInfoMap } from "store/chat-types";
import { FilterTab } from "components/SearchFilterTabs/types";

const EnvelopeIcon = createSvgIcon(Envelope)

const filters: FilterTab[] = [
    {
        id: CONVERSATION_TYPE.PATIENT,
        labelId: "ThreadsHeader.filter.patients",
    },
    {
        id: CONVERSATION_TYPE.CARETEAM,
        labelId: "ThreadsHeader.filter.colleagues",
    },
]

// getCount returns the number of conversations of the given type that have unread messages
const getCount = (conversationsInfo: ConversationInfoMap, type: ConversationType) => {
    const filteredConversations = uFilter(conversationsInfo, {conversationType: type})
    return reduce(filteredConversations, (memo, info) => {
        return info?.unreadCount ? 1 + memo : memo
    }, 0)
}

// ThreadsHeader handles conversation search by participant name, subject and patient mrn, composing a new message, and
// Filtering by conversation type
const ThreadsHeader = ({filter, setFilter}: ThreadsHeaderProps) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const intl = useIntl()
    const buttonRef = useRef(null)
    const menuRef = useRef(null)
    const { conversationsInfo,searchLoading} = useSelector((store: AppReduxStore) => store.chat)
    const [menuOpen, setMenuOpen] = useState(false)
    const [searchInput, updateSearch] = useState('')

    const openComposeNewMessageModal = (type:string) => {
        dispatch(setActiveModal(MODAL_TYPES.PROVIDER.COMPOSE_NEW_MESSAGE, {type}));
    }

    // Fires off searchConversations at most once every 600 ms as to not send too many requests
    const debounceSearch = useMemo(() => debounce((searchStr:string) => {
        dispatch(searchConversations(searchStr, filter))
    }, 600), [filter, dispatch])

    const search = useCallback(debounceSearch, [debounceSearch])

    useEffect(() => {
        search(searchInput)
    }, [search, searchInput])

    const onTextChange = (e: any) => {
        !searchLoading&&updateSearch(e.target.value)
    }

    const onTabChangeText = () =>{
        !searchLoading&&updateSearch('')
    }

    const menuConfig: AnyMenuItem[] = [
        {
            id: "QA_ThreadsHeader.input.APatient",
            labelKey: "ThreadsHeader.input.APatient",
            icon: createSvgIcon(User),
            onClick: () => openComposeNewMessageModal("patient"),
        },
        {
            id: "QA_ThreadsHeader.input.AColleague",
            labelKey: "ThreadsHeader.input.AColleague",
            icon: createSvgIcon(Stethoscope),
            onClick: () => openComposeNewMessageModal("colleague"),
        },
    ];

    const filtersWithCount = filters.map(value => ({ ...value, count: getCount(conversationsInfo, value.id as ConversationType) }));

    return (
        <Box className={classes.headerWrapper}>
            <div className="inputRow">
                <Input id="QA_searchBox"
                    disableUnderline
                    onChange={onTextChange}
                    placeholder={intl.formatMessage({id: "ThreadsHeader.input.searchInbox"})}
                    value={searchInput}
                />
                <Box display="flex" className={classes.button}>
                    <Button id="QA_btnCompose"
                        className={classes.button}
                        color="primary"
                        onClick={() => setMenuOpen(!menuOpen)}
                        ref={buttonRef}
                        variant="text"
                        startIcon={<div className={classes.startIconWrapper} ref={menuRef}><EnvelopeIcon className={classes.startIcon} /></div>}
                    >
                        <Typography variant="button">
                            <FormattedMessage id="ThreadsHeader.button.compose"/>
                        </Typography>
                    </Button>
                    <OptionMenu
                        handleClose={() => setMenuOpen(false)}
                        menuConfig={menuConfig}
                        titleKey="ThreadsHeader.input.ComposeMessageTo"
                        containerEl={buttonRef}
                        anchorEl={menuRef}
                        placement="bottom-start"
                        variant="dark"
                        open={menuOpen}
                    />
                </Box>
            </div>
            <SearchFilterTabs tab={filter} setTab={setFilter} tabList={filtersWithCount} onTabChangeText={onTabChangeText} />
        </Box>
    )
}

export default ThreadsHeader
