import createReducer from '../createReducer'
import { RegistrationState } from '../reducerTypes'
import { REGISTRATION_STEP } from "constant";

const initialState: RegistrationState = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dateOfBirth: "",
    email: "",
    emailConfirm: "",
    emailVerified: false,
    password: "",
    passwordConfirm: "",
    registrationStep: REGISTRATION_STEP.PROVIDER.VERIFY_IDENTITY,
    regToken: "",
    otpCode: "",
    practiceSecret: "",
    practiceSecretValid: false,
    otpValid: false,
    loading: false,
    identityValid: false,
    complete: false,
    feedbackLoop: null,
    error: null,
    userInfo: {},
    registrationId: '',
    isNewAccount: false,
    passwordVerified: false,
    recoveryQuestion: "",
    recoveryAnswer: "",
    profileRoleJoinId: "",
    userType: "",
    isAddtionalProfile: false
}

export default createReducer(initialState, {
    'SET_REGISTRATION_VALUES': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload
        }
    },
    'SET_REGISTRATION_STEP': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload
        }
    },
    'SET_REGISTRATION_TOKEN': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload
        }
    },
    'SET_FEEDBACK_LOOP': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload
        }
    },
    'VERIFY_IDENTITY': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload,
            loading: true
        }
    },
    'VERIFY_IDENTITY_COMPLETE': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload,
            loading: false
        }
    },
    'ADD_EMAIL': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload,
            loading: false
        }
    },
    'VERIFY_OTP_FACTOR': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload,
            loading: true
        }
    },
    'VERIFY_OTP_FACTOR_COMPLETE': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        return {
            ...state,
            ...payload,
            loading: false,
            otpCode: ""
        }
    },
    'SET_ADDITIONAL_PROFILE_DETAILS': (state: RegistrationState, payload: RegistrationState): RegistrationState => {
        const { profileRoleJoinId, userType } = payload
        return {
            ...state,
            loading: false,
            profileRoleJoinId,
            userType,
            isAddtionalProfile: profileRoleJoinId?.length > 0 && userType?.length > 0
        }
    }
})