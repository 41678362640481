import React from "react";
import { DialogContent } from "@mui/material";
import { ModalContentProps } from "./types";
import { useStyles } from "./styles";

const ModalContent = ({ children, ...props }: ModalContentProps) => {
    const classes = useStyles();
    const propClassName = props.className || "";
    return (
        <DialogContent
            {...props}
            className={`${classes.content} ${propClassName}`}
        >
            {children}
        </DialogContent>
    );
};

export default ModalContent;
