import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getBreadcrumbName } from 'admin/common/utils';
import { Route } from "react-router";
import config from "views/SideNav/config";

const TopHeader = () => {
    const useStyles = makeStyles((theme) => ({
        infoText: {
            fontWeight: 600,
            fontSize: '40px',
            color: '#393948',
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginRight: '5px',
            width: '48px',
            height: '48px'
        }
    }));

    const classes = useStyles();
    const IconLabel = ({ item }: any) => {
        const { Icon } = item;
        return (
            <Icon className={classes.icon} />
        );
    }

    return (
        <Route>
            {({ location }) => {
                const pathnames = location.pathname.split('/').filter(p => p).slice(1, 4)
                return (<Box>
                    {pathnames.map((value, index) => {
                        const breadcrumbText = getBreadcrumbName(value);
                        return (
                            index === 0 ? <Typography color="textPrimary" key={index} className={classes.infoText}>
                                {index === 0 && <IconLabel item={config.adminRoutes[value]} />}
                                {breadcrumbText}
                            </Typography> : null
                        )
                    })}
                </Box>)
            }}
        </Route>
    )
}

export default TopHeader