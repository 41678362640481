import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setProfileDetailsVerify } from "admin/store/actions";
import { USER_TYPE } from "constant";
import Registration from "../Registration/Registration";
import ProviderRegistration from "../ProviderRegistration/ProviderRegistration";

const VerifyAdditionalProfile = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [additionlprofile, setAdditionalProfile] = useState({
        profileRoleJoinId: "",
        userType: ""
    })
    useEffect(() => {
        const params = new URLSearchParams(history.location.search)
        const profileRoleJoinId = params.get("prjId")
        const userType = params.get("type")
        if (profileRoleJoinId && userType) {
            setAdditionalProfile({ profileRoleJoinId, userType })
            dispatch(setProfileDetailsVerify(profileRoleJoinId, userType))
        }
    }, [dispatch, history.location.search])


    return (<>
        {additionlprofile.profileRoleJoinId?.length > 0
            && additionlprofile.userType === USER_TYPE.ADMIN ?
            <Registration /> : <ProviderRegistration />}
    </>)
}

export default VerifyAdditionalProfile