import { MessageAttributes, MessageView } from "../types";
import { Typography } from "@mui/material";
import FileGridView from "common/components/files/file-grid-view";
import * as ChatViewClassNames from "../class-names";

const DefaultMessageView: MessageView = ({ message }) => {
    const hasAttachments = (message?.attributes as MessageAttributes)?.attachmentIds?.length;
    const fileIds = hasAttachments ? (message.attributes as MessageAttributes).attachmentIds : [];
    return (
        <div className={ChatViewClassNames.DefaultMessageView}>
            <Typography variant="body2">{message.body}</Typography>
            {hasAttachments && <><hr/><FileGridView fileIds={fileIds} /></>}
        </div>
    );
};

export default DefaultMessageView;
