import React, { useEffect } from "react"
import useTrack from '../hooksHelper/useTrack';
import AudioTrackComponent from './AudioTrack';
import VideoTrackComponent from './VideoTrack';
import { IVideoTrack } from '../types';
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  RemoteTrackPublication,
} from 'twilio-video';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  videoOnly?: boolean;
  updateVideoState:(status:boolean)=>void,
  isMainVideo?:boolean,
  videoStyle: 'contain' | 'cover' | 'auto',
}

export default function Publication({ publication, videoOnly,updateVideoState,isMainVideo=false,videoStyle }: PublicationProps) {
    const track = useTrack(publication);
    
    const enableVideo =()=>updateVideoState(true)
    const disabledVideo =()=>updateVideoState(false)

    //@ts-ignore
    const sid = track?.kind === "video" ? track.sid : null;

    useEffect(() => {
      if(track&&track.kind==='video'){
        track.on('enabled',enableVideo);
        track.on('disabled',disabledVideo);
        track.on('started',enableVideo);
      }
      return ()=>{
        track?.off('enabled',enableVideo);
        track?.off('disabled',disabledVideo);
        track?.off('started',enableVideo);

      }
    }, [track])

    useEffect(()=>{
      if(track&&track.kind==='video'){
      updateVideoState(track?.isEnabled)
      }
    },[sid,track])
  
    if (!track) return null;
  
    switch (track.kind) {
      case 'video':
        return (
          <VideoTrackComponent
            track={track as IVideoTrack}
            videoStyle={videoStyle}
          />
        );
      case 'audio':
        return videoOnly ? null : <AudioTrackComponent track={track as IAudioTrack} />;
      default:
        return null;
    }
  }