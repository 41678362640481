import { makeStyles } from "@mui/styles";
import { SIZING } from "../../../theme";


export const useStyles = makeStyles(() =>({
    callFailedWrapper: {
        background: "#393948",
        border: "none",
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
        "& h3": {
            display: 'block'
        }
    },
    evisitFailedTitle: {
        fontSize: SIZING.scale800,
        fontWeight: 'normal',
        marginBottom: SIZING.scale700
    },
    evisitFailedDesc: {
        fontSize: SIZING.scale500,
        fontWeight: 'normal',
        marginBottom: SIZING.scale800,
        "&.w-75": {
            width: '75%',
            textAlign: 'center',
            lineHeight: SIZING.scale600,
            marginBottom: SIZING.scale600,
        }
    }
}));