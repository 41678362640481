type BatchType<T> = T[] | undefined;

class BatchQueue<T> {
    private readonly batchSize: number;
    private queue: Set<T> = new Set();
    private currentBatch: BatchType<T> = undefined;

    constructor(batchSize: number) {
        this.batchSize = batchSize;
    }

    addItems = (items: T[]) => {
        items.forEach((item) => this.queue.add(item));
    };

    private deleteCurrentBatchItems = () => {
        this.currentBatch?.forEach((item) => this.queue.delete(item));
    };

    has = (item: T): boolean => {
        return this.queue.has(item);
    }

    getCurrentBatch = (): BatchType<T> => {
        return this.currentBatch;
    };

    getNextBatch = (): BatchType<T> => {
        const newBatch: T[] = [];
        const iter = this.queue.values();

        this.deleteCurrentBatchItems();

        for (let i = 0; i < this.batchSize; i++) {
            const item = iter.next();
            if (!item.done) {
                newBatch.push(item.value);
            } else {
                break;
            }
        }

        this.currentBatch = newBatch.length ? newBatch : undefined;

        // returning a clone to protect from mutations
        return this.currentBatch && [...newBatch];
    };
}

export default BatchQueue;
