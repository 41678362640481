import {Divider, Typography, CardContent, Card} from '@mui/material';
import { Link } from 'react-router-dom';
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import {ReactComponent as Dwonload} from "icon-library/SVG/Arrow_Down-Square.svg";
import {ReactComponent as RightGo} from 'icon-library/SVG/Chevron_Right.svg';
import { createSvgIcon } from "utils";
import React, {useState, useEffect} from 'react';

const TopCard = (props : any) => {
  const intl = useIntl();
  const classes = useStyles();
  const DownIcon = createSvgIcon(Dwonload);
  const RightIcon = createSvgIcon(RightGo);
  const {patientForms} = props;
  const [pendingForm, setPendingForm] = useState([]);

  useEffect(() => {
      const tempArr = patientForms.filter((ele: any) => ele.formStatus === "PENDING_APPROVAL");
      setPendingForm(tempArr);
  }, [patientForms]);

  return (
    <>
    <div className={classes.content}>
    <Typography variant="h4" component="div" className={classes.title1}>
          <strong>{intl.formatMessage({ id: "TopCard.Title1" })}</strong>
        </Typography>
        <Typography variant="h4" component="div" className={classes.title2}>
          {pendingForm.length === 1 && pendingForm.length + intl.formatMessage({ id: "TopCard.Title3" })}
          {pendingForm.length !== 1 && pendingForm.length + intl.formatMessage({ id: "TopCard.Title2" })}
        </Typography>
    </div>
    <Card className={classes.header} >
        <Divider variant="fullWidth" />
        <CardContent className={classes.content1}>
          {pendingForm.length !== 0 && pendingForm.map((form: any, idx) => {
            return <React.Fragment>
            <div className={classes.items}>
              <div className={classes.items1}>
              <DownIcon />
              <Link
                to={`/admin/configurations/patientforms/${form.id}`}
                className={classes.items3}
              >
                <Typography noWrap variant="h2" className={classes.text1}>{form.title}
                </Typography>
              </Link>
              </div>
              <div className={classes.items2}>
                <Link 
                  to={`/admin/configurations/patientforms/${form.id}`}
                  className={classes.items4}
                >
                  <Typography noWrap variant="h2" className={classes.text3}>v{form.formVersion} Draft
                  </Typography>
                  
                </Link>
                <Link 
                  to={`/admin/configurations/patientforms/${form.id}`}
                >
                  <Typography noWrap variant="h2" className={classes.text2}>Review
                  </Typography>
                </Link>
                <RightIcon />
              </div>
            </div>
            {pendingForm.length !== 0 && pendingForm.length - 1 !== idx && <Divider variant="fullWidth" />}
            </React.Fragment>
            })
          }
          {pendingForm.length === 0 && 
            <Typography className={classes.list} color="text.secondary" gutterBottom>
              {intl.formatMessage({ id: "TopCard.Content" })}
            </Typography>
          }
      </CardContent>  
    </Card>
    </>
  );
}
export default TopCard;