import { getFilesFromEvent as dropzoneGetFilesFromEvent } from "react-dropzone-uploader";

function filterEmpty<T>(value: T | null | undefined): value is T {
    return Boolean(value);
}

export const getFilesFromEvent = (e: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>) => {
    return dropzoneGetFilesFromEvent(e)
        .map((f) => ("getAsFile" in f ? f.getAsFile() : f))
        .filter(filterEmpty)
}