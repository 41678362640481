import React from "react";
import { SVGProps} from "react";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useStyles } from "./styles";

const ModalCloseBtn = (props: SVGProps<SVGSVGElement>) => {
    const classes = useStyles();
    const propClassName = props.className || "";
    return (
        <Close
            {...props}
            className={`${classes.close} ${propClassName}`}
        />
    );
};

export default ModalCloseBtn;
