import React, { useState } from "react"
import { useStyles } from "./styles"
import { useIntl } from "react-intl";
import { ReactComponent as User_Single } from "icon-library/SVG/User_Single.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ClickAwayListener, Box, Button, Typography, Tab, Tabs, DialogActions } from "@mui/material";
import { ReactComponent as ChevronDown } from "icon-library/SVG/Chevron_Down.svg";
import ActionsDropDown from "./rpm-dropdown/actions-dropdown"
import RemoteMonitoringTab from "components/RemoteMonitoring/patient-details/remote-monitoring-tab/remote-monitoring-tab";
import CarePlanTab from "components/RemoteMonitoring/patient-details/care-plan-tab/care-plan-tab";


const PatientDetails: React.FC = () => {

    const classes = useStyles()
    const intl = useIntl();
    const [tab, setTab] = useState(0);
    const [actionOpen, setActionOpen] = useState(false);

    const handleChangeTab = (event: any, newTab: any) => {
        setTab(newTab);
    };

 

    const handleActionClose = () => {
        setActionOpen(false)
    };
    const handleActionClickAway = () => {
        handleActionClose()
    };
    return (
        <div className={classes.rootWrapper}>
            <div className={classes.patienDetailsHeader}>
                <Box className={classes.patienDetails}>
                    <SVGIcon className={classes.userIcon} svg={User_Single} size="scale800" />
                    <Box className={classes.patientNameAndInfo}>
                        <Typography className={classes.patientName}>
                            ANDERSON, Sara
                        </Typography>
                        <Box className={classes.patientInfo}>
                            <Typography className={classes.ageAndGender}>
                                (45 F)
                            </Typography>
                            <Typography className={classes.patientId}>
                                MRN-JRC-6201329
                            </Typography>
                        </Box>
                    </Box>
                    <Box marginLeft='auto'>
                        <ClickAwayListener onClickAway={handleActionClickAway}>
                            <Box style={{position:"relative"}}>
                                <Button className={classes.actionButton} onClick={() => setActionOpen(!actionOpen)}>
                                    Actions
                                    <SVGIcon svg={ChevronDown} size="scale600"/>
                                </Button>
                                {actionOpen && <ActionsDropDown />}
                            </Box>
                        </ClickAwayListener>
                    </Box>
                </Box>
                <DialogActions className={classes.dlgAction}>
                    <Box width="100%">
                        <Tabs
                            value={tab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            onChange={handleChangeTab}
                            aria-label="RPM Dashboard">
                            <Tab label={intl.formatMessage({ id: "RPM.Dashboard.Remote" })} />
                            <Tab label={intl.formatMessage({ id: "RPM.Dashboard.CarePlan" })} />
                        </Tabs>
                    </Box>
                </DialogActions>
            </div>
            <div className={classes.patientDetailsContent}>
                {tab === 0 ?
                    <RemoteMonitoringTab />
                    : <CarePlanTab />
                }
            </div>
        </div>
    )
}

export default PatientDetails