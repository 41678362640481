import { Box, IconButton, InputBase, Paper } from "@mui/material";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import classNames from "classnames";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import Dropdown from "common/components/dropdown/dropdown";
import EmptyMessage from "common/components/empty-message/empty-message";
import { Sort, SortDirect } from "lib/domain/service-types";
import { Pagination } from "lib/ui-components/pagination/pagination";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PracticesList from "./location-list/location-list";
import { useStyles } from "./styles";
import { AssignedPractice, PracticesSearchProps } from "./types";

const sortableFields = ['last-name'];
const ROWS_PER_PAGE = 10;

const PracticesSearch = ({ onSelect, onRemove, selectedPractices, availablePractices, disabledMessage, selectDisabled }: PracticesSearchProps) => {
    const classes = useStyles();
    const intl = useIntl();
    const [filter, setFilter] = useState<string>("");
    const [sorting, setSorting] = useState<Sort>({ direction: SortDirect.ASC, field: 'last-name' });
    const [page, setPage] = useState<number>(0);
    const filteredPractices = availablePractices.filter((item: AssignedPractice) => {
        const practiceName = item.practice.name;
        const location = item.locations;
        const searchStr = `${practiceName} ${location.name} ${location.address1} ${location.address2} ${location.city} ${location.state} ${location.postalCode}`;
        return searchStr?.toLowerCase().includes(filter?.toLowerCase());
    });
    const paginatedPractices = filteredPractices.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE);

    const onFilterChange = (event: any): void => {
        const filter = event.target.value;
        setFilter(filter);
        setPage(0);
    };

    const onPageChange = (value: number): void => {
        setPage(value);
    };

    const onSortChange = (sortField: string): void => {
        setSorting({
            direction: SortDirect.ASC,
            field: sortField
        });
    };

    const content = (
        <>
            <Paper
                component="form"
                elevation={2}
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, marginTop: '5px', marginBottom: '20px', marginRight: "4%", borderRadius: "50px" }}
            >
                <IconButton id="btnSerachLocations" className={classes.iconButton} aria-label="search" size="large">
                    <SearchIcon />
                </IconButton>
                <InputBase id="txtSearchLocations"
                    type="search"
                    className={classes.input}
                    onChange={onFilterChange}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                    placeholder="Find practices by name or address"
                    inputProps={{ 'aria-label': 'Find practices by name or address' }}
                />
            </Paper>
            <Box flexShrink={1} className={classes.resultsHeader}>
                <CustomTypography variant="modalSectionHeader" className="panelHeader">
                    <FormattedMessage id="fee.schedules.location.modal.location.search.header" values={{ value: `${availablePractices.length}` }} />
                </CustomTypography>
                <Dropdown
                    idPrefix="QA_econsult_provider_result_sorting"
                    data-sort-field={sorting.field}
                    data-sort-direction={sorting.direction}
                    className={classes.resultSortSelector}
                    labelId="EConsult.sortSelector.label"
                    onChange={onSortChange}
                    options={sortableFields.map((fieldName) => ({
                        id: fieldName,
                        text: intl.formatMessage({ id: `EConsult.filterType.${fieldName}` }),
                        selected: sorting.field === fieldName,
                    }))}
                    titleId="EConsult.sortSelector.title"
                />
            </Box>
            {(filteredPractices?.length === 0) ? (
                <EmptyMessage
                    primaryMessage={{ id: "fee.schedules.location.modal.empty.search.result" }}
                />
            ) : (
                <>
                    <div className="providerListScrollContainer">
                        <PracticesList
                            id="QA_provider_search_results"
                            itemIdPrefix="QA_provider"
                            disabledMessage={disabledMessage}
                            onRemove={onRemove}
                            onSelect={onSelect}
                            selectDisabled={selectDisabled}
                            selectedPractices={selectedPractices}
                            availablePractices={paginatedPractices}
                            showAddress
                        />
                    </div>
                    <Box className={classes.footer}>
                        <Pagination
                            pageNumber={page}
                            pageCount={Math.floor((filteredPractices.length - 1) / 10) + 1}
                            onChange={onPageChange}
                            totalElements={filteredPractices.length}
                            showTotalCount={true}
                            totalCountKey="fee.schedules.location.modal.location.search.result.totalCount"
                        />
                    </Box>
                </>
            )}
        </>
    );

    return (
        <Box
            className={classNames(classes.panel, classes.providersSearchBox)}
            display="flex"
            flexDirection="column"
            justifyContent="center"
        >
            {content}
        </Box>
    );
};

export default PracticesSearch;
