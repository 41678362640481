import { useEffect, useRef } from "react";
import { useApi } from "common/utils/use-api";
import { createEConsultRequest } from "./mappers";
import { shouldUpdateDraft } from "./validations";
import { EConsultRequest, EConsultRequestDetails, EConsultRequestStatuses, EConsultRequestCreateState } from "../types";
import { useBusyState } from "common/utils/progress/progress-manager-hooks";
import { useStableObjectReference } from "common/utils/use-stable-reference";
import { useUserNotification } from "common/utils/use-user-notification";
import { EConsultUpdatingBusyState } from "./constants";

export const useUpdateRequest = (state: Omit<EConsultRequestCreateState, "status">, submitted: boolean) => {
    const { setBusy } = useBusyState(EConsultUpdatingBusyState);
    const { enqueueError } = useUserNotification();

    const stateWithStatus = {
        status: EConsultRequestStatuses.DRAFT,
        ...state,
    };

    const currentRequest = createEConsultRequest(stateWithStatus);

    const params = useStableObjectReference(currentRequest);

    const { content: updatedDraft, requestFn: update} = useApi<EConsultRequest, EConsultRequestDetails, EConsultRequestDetails | null>(
        {
            id: "updateEConsultRequestDraft",
            abortOnNewRequest: true,
            defaultContent: null,
            endpoint: `/econsult/v1/requests/${currentRequest.id}`,
            method: "PUT",
            requestDecisionMaker: (request) => {
                // only send update if update validation passes.
                return shouldUpdateDraft(request) && !submitted;
            },
        }
    );

    const performUpdate = async (params: EConsultRequest) => {
        try {
            await update(params);
            setBusy(false);
        } catch (err) {
            if ((err as Error).name !== "AbortError") {
                console.error(err);
                enqueueError("EConsult.newRequestModal.update.error");
                setBusy(false);
            }
        }
    };

    const performUpdateRef = useRef(performUpdate);
    performUpdateRef.current = performUpdate;

    useEffect(() => {
        setBusy(true);
        const timer = setTimeout(() => {performUpdateRef.current(params)}, 200);
        return () => clearTimeout(timer);
    }, [params, setBusy]);

    useEffect(() => {
        return () => {
            setBusy(false);
        }
    }, []);

    return {
        updatedDraft,
    } as const;
};
