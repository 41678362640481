import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "admin/theme";


export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    productsPageHeader: {
        marginBottom: '20px'
    },
    pageHeaderTextProduct: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948',
    },
    pageSubHeaderText: {
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#565662'
    },
    productsIcon: {
        fontSize: '42px',
        verticalAlign: 'middle',
        marginRight: '12px'
    },

    pageHeaderText: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
    },
    contentDialog: {
        padding: '20px'
    },
    formGpSubHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '14px',
        color: '#393948',
        paddingBottom: '15px'
    },

    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    waitingRoomModal: {
        boxShadow: 'none'
    },
}));