import { Theme } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
      border: "1px solid #d3d4d5",
      borderRadius: theme.spacing(2),
      overflow: "visible",
      
      "& .MuiPaper-root":{
        zIndex: "101",
      },
      "& .arrow": {
        width: "0px",
        height: "0px",
        borderLeft: `${theme.spacing(2)} solid transparent`,
        borderRight: `${theme.spacing(2)} solid transparent`,
        borderBottom: `${theme.spacing(2)} solid #fff`,
        position: "absolute",
        top:arrow => arrow ?theme.spacing(-2) : "unset",
        transform:arrow => !arrow ?"rotate(180deg)":"unset",
        '@media (max-width:600px)': {
          left: "85%",
        },
        left: "50%",
        marginLeft: theme.spacing(-2),
        zIndex: "100",
        content: "''",
        filter:"drop-shadow(0 1px 2px rgba(0,0,0,.5))",
      },
      "& .left": {
        left: `calc( ${theme.spacing(3.5)} )`,
      },
      "& .right": {
        left: arrow => !arrow ?`calc( 100% - ${theme.spacing(12.5)} )`:`calc( 100% - ${theme.spacing(3.5)} )`,
      },
    },
    backdrop:{
      opacity:`0 !important`
    }
  }));