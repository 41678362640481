import { ContactUsState } from "store/reducerTypes";
import {
    ActionTypes,
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCESS,
    CONTACT_US_FAILED,
} from "store/actions/contactUs";

const initialState: ContactUsState = {
    loading: false,
    successMessage: null,
};

export default function reducer(
    state: ContactUsState = initialState,
    action: ActionTypes
): ContactUsState {
    switch (action.type) {
        case CONTACT_US_REQUEST:
            return {
                loading: true,
                successMessage: null,
            };
        case CONTACT_US_SUCCESS:
            return {
                loading: false,
                successMessage: action.payload,
            };
        case CONTACT_US_FAILED:
            return {
                loading: false,
                successMessage: null,
            };
        default:
            return state;
    }
}
