import  React, { useRef, useEffect,CSSProperties,useMemo } from "react";
import { IVideoTrack } from '../types';
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Track } from 'twilio-video';
import useVideoTrackDimensions from "../hooksHelper/useVideoTrackDimensions"


const Video = styled('video')({
  width: '100%',
  height: '100%',
});

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  videoStyle: 'contain' | 'cover' | 'auto'
}

export default function VideoTrack({ track,videoStyle }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const getStyles:CSSProperties = useMemo(() =>{
      if(videoStyle==='auto'){
        return { objectFit: isPortrait || isMobile ? 'contain':'cover' }
      }
      return { objectFit: videoStyle || ''}
  },[isPortrait,isMobile,videoStyle])

  useEffect(() => {
    const el = ref.current;
    el.muted = true;

    track.attach(el);
    return () => {
      track.detach(el);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;

    };
  }, [track]);

  const styles: CSSProperties = getStyles;

  return <Video ref={ref} style={styles} />;
}
