import {
    EConsultRequestCreateState,
    EConsultRequestValidation,
    EConsultRequest,
    EConsultRequestDetails,
} from "../types";

export const createEConsultRequest = ({
    attachments,
    duration,
    id,
    notes,
    selectedPatient,
    selectedProviders,
    status,
    subject,
    urgent,
}: EConsultRequestCreateState): EConsultRequest => ({
    attachmentIds: attachments.filter((a) => a.status === "UPLOADED").map((a) => a.id),
    duration: duration ? `PT${duration}H` : null,
    id,
    notes,
    patientId: selectedPatient,
    recipientIds: selectedProviders.map((s) => s.id),
    referrerId: null,
    status,
    subject,
    urgent,
});

export const mapEConsultRequestDetailsToEConsultRequest = ({
    attachments,
    duration,
    id,
    notes,
    subject,
    urgent,
    patient,
    recipients,
    status,
}: EConsultRequestDetails): EConsultRequest => ({
    attachmentIds: attachments.map(file => file.id),
    duration,
    id,
    notes,
    subject,
    urgent,
    patientId: patient?.id || null,
    recipientIds: recipients.map(provider => provider.id),
    // is always sent as null and populated by the JWT
    referrerId: null,
    status
});

export const createEConsultRequestValidationObject = ({
    attachments,
    notes,
    selectedPatient,
    selectedProviders,
    subject,
}: EConsultRequestCreateState): EConsultRequestValidation => ({
    providers: {
        recipientIds: selectedProviders.map((s) => s.id),
    },
    patient: {
        patientId: selectedPatient,
    },
    attachments,
    caseNotes: {
        subject,
        notes,
    },
});
