import { useState, useRef, useCallback } from "react";
import BatchQueue from "./batch-queue";

function useBatchQueue<T>(batchSize: number) {
    const queue = useRef(new BatchQueue<T>(batchSize));
    const [currentBatch, setCurrentBatch] = useState<T[]>();

    const nextBatch = useCallback(() => {
        setCurrentBatch(queue.current.getNextBatch());
    }, []);

    const addItems = useCallback(
        (items: T[]) => {
            queue.current.addItems(items);
            if (!queue.current.getCurrentBatch()) {
                nextBatch();
            }
        },
        [nextBatch]
    );

    const isInQueue = useCallback((item: T): boolean => {
        return queue.current.has(item)
    }, []);

    return {
        isInQueue,
        currentBatch,
        addItems,
        nextBatch,
    };
}

export default useBatchQueue;
