import React from "react";
import { useHistory } from "react-router-dom";
import NotificationItem, { NotificationItemName } from "./notification-item";
import { ADTNotification } from "store/notification-types";

const PatientADTAlerts = ({ createdDate, data, title = "" }: ADTNotification) => {
    const { adtEventId, patientId, patientName } = data;
    const history = useHistory();

    const handleClick = () => {
        history.push({
            pathname: `/provider/people/patients/${patientId}/alerts/${adtEventId}`,
            state: {
                dischargeId: adtEventId,
            },
        });
    };

    return (
        <NotificationItem title={title} createdDate={createdDate} onClick={handleClick}>
            <NotificationItemName>{`Patient - ${patientName}`}</NotificationItemName>
        </NotificationItem>
    );
};

export default PatientADTAlerts;
