import { CareTeamSearchItem } from "../types";
import { FormattedMessage } from "react-intl";
import * as CSS from "../class-names";

const careTeamItemRenderer = (item: CareTeamSearchItem) => {
    return (
        <div className={CSS.CareTeamSearchListItem}>
            <div className="nameRow">
                <span className={"name"}>{item.name}</span>
                <div></div>
            </div>
            <div className="participantRow">
                <span><FormattedMessage id="careTeam.search.item.members" values={{ memberCount: item.memberCount }}/></span>
                <span><FormattedMessage id="careTeam.search.item.patients" values={{ patientCount: item.patientCount }}/></span>
            </div>
        </div>
    );
}

export default careTeamItemRenderer;
