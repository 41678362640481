import { IconButton } from "@mui/material";
import GridContextMenu from "admin/components/GridContextMenu/GridContextMenu";
import FeeScheduleDetailsGridContextMenu from 'admin/components/GridContextMenuContent/FeeScheduleDetailsGridContextMenu/FeeScheduleDetailsGridContextMenu';
import { MODAL_TYPES, USER_LEVELS } from 'admin/constant';
import { setActiveModal } from 'admin/store/actions';
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from 'admin/store/reducerTypes';
import { handleDownloadAttachment } from "admin/views/FeeSchedules/components/files/download-view-attachments";
import { useFeeScheduleDetails } from "admin/views/FeeSchedules/components/files/use-fee-schedule-details";
import { BillingGroupInfoProps, RegionInfoProps } from "admin/views/FeeSchedules/types";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as EllipsisV } from "icon-library/SVG/Ellipsis-Vertical.svg";
import { ReactComponent as PreviewSvg } from "icon-library/SVG/File_Preview.svg";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createSvgIcon } from "utils";

const EllipsisVIcon = createSvgIcon(EllipsisV);
const PreviewIcon = createSvgIcon(PreviewSvg);

const getTopLevelOrgId = (org: any) => {
    let current = org;
    if (current === null || current?.parent === null)
        return null;

    while (current !== null) {
        if (current?.parent?.parent === null)
            return current?.id;
        current = current?.parent;
    }
}

const FeeScheduleDetails = () => {
    const { auth } = useSelector((state: AppReduxStore) => state);
    const { organization } = useSelector((state: AppReduxStore) => state);
    const { currentPractice, currentProfileRoleJoinInfo } = auth;
    const levelName = currentProfileRoleJoinInfo?.level?.name;
    const ALTAIS_ADMINS = [USER_LEVELS.ALTAIS_ADMIN_1, USER_LEVELS.ALTAIS_ADMIN_2, USER_LEVELS.ALTAIS_ADMIN_3];
    const ORG_ADMINS = [USER_LEVELS.ORG_ADMIN_1, USER_LEVELS.ORG_ADMIN_2];
    const BILLING_ADMINS = [USER_LEVELS.BILLING_ADMIN];
    const addFeeScheduleAllowed = (ALTAIS_ADMINS.includes(levelName) || ORG_ADMINS.includes(levelName) || BILLING_ADMINS.includes(levelName));
    const isSubOrg: boolean = (organization?.sOrganization?.parent?.parent || currentPractice?.organizationInfo?.parent?.parent) ? true : false;
    const orgId = isSubOrg ? (getTopLevelOrgId(organization?.sOrganization) || getTopLevelOrgId(currentPractice?.organizationInfo)) : (organization?.sOrganization?.id || currentProfileRoleJoinInfo?.organization?.id || currentPractice?.organizationId);
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueError } = useUserNotification();
    const matchId: any = useRouteMatch("/admin/feeschedules/details/:id");
    const scheduleId = matchId?.params?.id ? matchId?.params?.id : null;
    const { feeScheduleDetails, hasResult } = useFeeScheduleDetails(scheduleId);

    const handleDeleteFeeSchedule = () => {
        dispatch(setActiveModal(MODAL_TYPES.DELETE_FEE_SCHEDULE_CONFIRMATION_MODAL, { scheduleId: scheduleId }));
    }

    const getDisplayDate = (unixDate: string) => {
        if (unixDate) {
            const date = new Date(unixDate);
            return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
        } else {
            return "N/A";
        }
    }

    const getDisplayRegions = (regionInfos: RegionInfoProps[]) => {
        if (regionInfos.length === 0) {
            return (<p></p>)
        } else {
            const seen: string[] = [];
            return regionInfos.filter((regionInfo: RegionInfoProps) => {
                return seen.find((item: string) => { return item === regionInfo.id }) ? false : (seen.push(regionInfo.id));
            }).map((regionInfo: RegionInfoProps, index: number) => {
                return (<p key={`billing_region_${index}`} style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%', color: '#393948', paddingLeft: '30px' }}>{regionInfo.name}</p>)
            })
        }
    }

    const getDisplayGroups = (billingGroupInfos: BillingGroupInfoProps[]) => {
        return (billingGroupInfos.length === 0) ? (<p></p>) : billingGroupInfos.map((billingGroupInfo: BillingGroupInfoProps, index: number) => {
            return (<p key={`billing_group_${index}`} style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%', color: '#393948', paddingLeft: '30px' }}>{billingGroupInfo.name}</p>)
        });
    };

    const handleFilePreview = () => {
        const fileName = feeScheduleDetails?.fileName;
        if (fileName && currentProfileRoleJoinInfo) {
            handleDownloadAttachment(currentProfileRoleJoinInfo, orgId, scheduleId, fileName, (error) => {
                enqueueError("pep.fee.schedules.download.error");
            })
        }
    }

    const handleEllipsesActionClick = (event: any) => {
        event.preventDefault();
        setContextMenuData({ mouseX: event.clientX + 30, mouseY: event.clientY - 20 })
    };

    const [contextMenuData, setContextMenuData] = useState<any | undefined>(undefined);

    const FeeScheduleDetailsContextMenu = () => {
        return (<GridContextMenu mouseX={contextMenuData?.mouseX} mouseY={contextMenuData?.mouseY} onClose={handleRowContextMenuClose}
            callback={(type: any) => { handleContextMenuAction(type) }}
            formNumber={""} formTitle={""} handleFormRow={null}
            gridContextMenuContent={FeeScheduleDetailsGridContextMenu}
            hideContextMenuHeader={true}
        />)
    }

    const handleRowContextMenuClose = (event: any) => {
        setContextMenuData(undefined);
    };

    const handleContextMenuAction = (type: any) => {
        switch (type) {
            case "EditFeeSchedule":
                if (scheduleId) {
                    history.push(`/admin/feeschedules/config/${scheduleId}`);
                }
                break;
            case "DeleteFeeSchedule":
                handleDeleteFeeSchedule();
                break;
            default:
                dispatch(showNotification("TODO", "error", "Not Implemented"))
        }
    };

    return (
        <>
            {feeScheduleDetails && hasResult && <div>
                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '32px', lineHeight: '140%', color: '#393948', margin: '0px' }}>
                    {feeScheduleDetails.name}
                    {addFeeScheduleAllowed && <IconButton id={"btnFeeScheduleDetailsContextMenu"} style={{ marginLeft: '10px' }} onClick={(e) => handleEllipsesActionClick(e)} size="large">
                        <EllipsisVIcon />
                    </IconButton>}
                    {contextMenuData && FeeScheduleDetailsContextMenu()}
                </p>
                <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '140%', color: '#565662', margin: '0px' }}>{`Last modified on ${getDisplayDate(feeScheduleDetails.lastModifiedDate)}`}</p>
                <table style={{ paddingTop: '30px' }}>
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: 'top', paddingTop: '14px' }}>Effective Date:</td>
                            <td style={{ verticalAlign: 'top', paddingTop: '14px', paddingLeft: '30px' }}>{getDisplayDate(feeScheduleDetails.effectiveDate)}</td>
                            <td style={{ verticalAlign: 'top', paddingTop: '14px' }}>Expiration Date:</td>
                            <td style={{ verticalAlign: 'top', paddingTop: '14px', paddingLeft: '30px' }}>{getDisplayDate(feeScheduleDetails.expireDate)}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top', paddingTop: '14px' }}>Fee Schedule File:</td>
                            <td style={{ verticalAlign: 'top', paddingTop: '14px', paddingLeft: '30px' }}>{feeScheduleDetails.fileName}</td>
                            <td colSpan={2}><PreviewIcon style={{ marginTop: '10px', marginLeft: '20px', cursor: 'pointer' }} onClick={handleFilePreview} /></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top', paddingTop: '14px' }}>Region:</td>
                            <td>{getDisplayRegions(feeScheduleDetails.regionInfos)}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top', paddingTop: '14px' }}>Groups:</td>
                            <td>{getDisplayGroups(feeScheduleDetails.billingGroupInfos)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>}
        </>
    );
}

export default FeeScheduleDetails;
