import React, { forwardRef,useCallback,useState} from "react";
import { TextField } from "@mui/material";
import { ReactComponent as Eye } from "admin/assets/common/Eye.svg";

import { useStyles } from "../ChangePassword/styles";
 
 const PasswordBox=forwardRef(({...textfieldProps }:any, ref)=>{
    const classes=useStyles();
    const [showPassword, togglePassword]=useState<boolean>(false);

    const handleEyeClick=useCallback(()=>{
        togglePassword(!showPassword);

    },[showPassword]);

     return (<TextField
     inputRef={ref}
     variant="outlined"
     label=""
     type={showPassword?"text":"password"}
     InputProps={{endAdornment:(<span><Eye id="QA_unhide_button" className={classes.eyeBtn} onClick={handleEyeClick} /></span>) }}
     {...textfieldProps}
             />)
});

export default PasswordBox;