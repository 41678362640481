import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Icon, ClickAwayListener } from "@mui/material";
import { ReactComponent as ArrowDown } from "admin/assets/common/ArrowDown.svg";
import { useStyles } from "./styles";
import { BulkActionMenuProps } from "./types";

const BulkActionMenu = ({ callback, bulkActionMenuContent }: BulkActionMenuProps) => {
    const BulkActionMenuContent = bulkActionMenuContent;

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false)
    };
    const handleClickAway = () => {
        handleClose()
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={classes.root} >
                <Button id="btnBulkActionMenu" variant="outlined" style={{ zIndex: 100, width: '234px' }}
                    aria-haspopup="true" endIcon={<Icon><ArrowDown /></Icon>} onClick={() => setOpen(!open)}>
                    <FormattedMessage id="BulkActionMenu.HeaderText" />
                </Button>
                {open && <BulkActionMenuContent callback={(bulkAction: any) => { callback(bulkAction) }} handleClose={() => { handleClose() }} />}
            </Box>
        </ClickAwayListener>
    )
}

export default BulkActionMenu;
