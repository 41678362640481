import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as OpenOptionsIcon } from "icon-library/SVG/Chevron_Down.svg";
import { ReactComponent as SelectedItemIcon } from "icon-library/SVG/Radio_Circle.svg";
import { ReactComponent as UnselectedItemIcon } from "icon-library/SVG/Radio-Light_Circle.svg";
import { ReactComponent as WaitRing } from "icon-library/SVG/Wait_Ring-Thick.svg";
import OptionMenu from "common/components/OptionMenu/OptionMenu";
import { AnyMenuItem } from "../OptionMenu/types";
import { DropdownProps } from "./types";

const Dropdown: React.FC<DropdownProps> = ({className, idPrefix, labelId, onChange, options, titleId, renderOptionLabel, loadingOptions, ...props}) => {
    const [open, setOpen] = useState(false);
    const anchorEl = useRef(null);
    const intl = useIntl();

    const selectedOption = options.find((option) => option.selected);
    const preparedOptions: AnyMenuItem[] = options.map((option, index) => ({
        id: `${idPrefix}_option_${index}`,
        labelKey: `${idPrefix}_option_${index}`,
        labelText: option.text,
        labelValues: option.values,
        icon: option.selected ? SelectedItemIcon : UnselectedItemIcon,
        selected: option.selected,
        onClick: () => {
            if (!option.selected) {
                onChange(option.id);
            }
            setOpen(false);
        },
    }));

    const onSelectorClick = (event: MouseEvent) => {
        setOpen(!open);
    }
    const onOptionClose = () => {
        setOpen(false);
    }

    return (
        <>
            <div
                id={`${idPrefix}_dropdown`}
                className={className}
                data-testid={`${idPrefix}_dropdown`}
                onClick={onSelectorClick as any}
                {...props}
            >
                {!!labelId && <span className="label">{intl.formatMessage({ id: labelId })}</span>}
                {loadingOptions ? (
                    <WaitRing />
                ) : (
                    <span className="selectedOption">{selectedOption?.selectedText ?? selectedOption?.text}</span>
                )}
                <OpenOptionsIcon ref={anchorEl} />
            </div>
            <OptionMenu
                id={`${idPrefix}_options`}
                className="optionMenu"
                anchorEl={anchorEl}
                handleClose={onOptionClose}
                variant="light"
                menuConfig={preparedOptions}
                open={open}
                titleKey={titleId}
                placement="bottom-end"
                // TODO: in case you see this dropdown not properly aligned
                // we will have to adapt this offset to be -24 when flipped
                offset={[24, 12]}
                renderOptionLabel={renderOptionLabel}
            />
        </>
    );
}

export default Dropdown;
