import { makeStyles } from "@mui/styles";
import {  SIZING,COLORS } from "theme"
export const useStyles=makeStyles(() =>({

    formCtrl: {
        padding: `${SIZING.scale300} ${SIZING.scale600}`

    },
    labelCtrl:{
        color: COLORS.ITEM_BADGE_BG,
        fontSize: SIZING.scale300,
        fontWeight: 600,
        textAlign: 'left'
    },
    lnkCtrl:{
        color: COLORS.LIGHT_BLUE,
        fontSize: SIZING.scale300,
        float: 'right',
        lineHeight: SIZING.scale500,
        marginRight: SIZING.scale200,
        cursor: 'pointer'
    },
    formWrapper:{
        margin: `${SIZING.scale300} 0`,
        padding: `${SIZING.scale300} 0`,
        "& .MuiOutlinedInput-root":{
            //border: "1px solid #909098",

            "& input":{
                border:'none'
            },
            "&:focus, & input:focus":{
                border: 'none'
            }
        }
    },
    divider:{
        width: '100%',
        margin: `${SIZING.scale300} 0px`,
    },
    btnUpdate:{
       margin: `${SIZING.scale200} ${SIZING.scale200} ${SIZING.scale200} auto`
    },
    eyeBtn:{
            cursor: 'pointer',
            width: SIZING.scale400,
    },
    error: {
        color: COLORS.NOTIFICATION_TEXT,
        fontWeight: 'normal',
        fontSize: SIZING.scale300,
        lineHeight: SIZING.scale500,
        marginLeft: SIZING.scale100
    },
    exclamationIcon: {
        marginTop: SIZING.scale100
    },
    buttonWrapper:{
        display:'flex',
        '& button':{
            marginLeft:'auto'
        }
    }
    

}));