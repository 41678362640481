import React from "react";
import {Form} from "admin/components/useForm";
import {Button, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import FormField from "common/components/FormField/FormField";
import InputMask from "common/components/InputMask/InputMask";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "admin/store/reducerTypes";
import {setRegistrationValues, verifyPatientIdentity} from "admin/store/actions";
import {isDOBValid} from "../utils";

const DOBMask = (props: any) => {
    return <InputMask {...props} mask={[/\d/, /\d/,'/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder=' / / ' />
}

const VerifyIdentity = () => {
    const {firstName, lastName, dateOfBirth, loading} = useSelector((store: AppReduxStore) => store.registration)
    const dispatch = useDispatch()

    const onChange = (key: string) => {
        return (value: any) => {
            dispatch(setRegistrationValues({[key]: value}))
        }
    }

    const onSubmit = async (e:any) => {
        e.preventDefault()
        await dispatch(verifyPatientIdentity())
    }

    const firstNameEmpty = !firstName || (firstName.trim() === '')
    const lastNameEmpty = !lastName || (lastName.trim() === '')
    const dobEmpty = !dateOfBirth || (dateOfBirth.trim() === '') || (dateOfBirth.trim() === '__/__/____')
    const isSubmitDisabled = loading || firstNameEmpty || lastNameEmpty || dobEmpty
    const DOBErrorId = dobEmpty || isDOBValid(dateOfBirth) ? "" : "PatientRegistration.error.invalidDOB"

    return (
        <Form onSubmit={onSubmit} name="patientIdentityForm">
            <FormField labelId="PatientRegistration.label.firstName" value={firstName} onChange={onChange("firstName")}/>
            <FormField labelId="PatientRegistration.label.lastName" value={lastName} onChange={onChange("lastName")}/>
            <FormField labelId="PatientRegistration.label.dateOfBirth" value={dateOfBirth} onChange={onChange("dateOfBirth")}
                       inputComponent={DOBMask} errorId={DOBErrorId}/>
            <Button type="submit" disabled={isSubmitDisabled} variant="outlined" color="primary" fullWidth>
                <Typography variant="button">
                    <FormattedMessage id="PatientRegistration.button.confirm"/>
                </Typography>
            </Button>
        </Form>
    )
}

export default VerifyIdentity