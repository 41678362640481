import classNames from "classnames";
import * as CSS from "./class-names";
import { useStyles } from "./styles";

const QAId = "QA_modalSidePanelLayout";
const LeftPanelQAId = `${QAId}__leftPanel`;
const RightPanelQAId = `${QAId}__rightPanel`;

export type ModalSidePanelLayoutProps = {
    leftPanelChildren?: React.ReactNode
}
/**
 * ModalSidePanelLayout creates the 2 columns layout 
 *
 */
export const ModalSidePanelLayout: React.FC<ModalSidePanelLayoutProps> = ({ leftPanelChildren, children }) => {
    const classes = useStyles();

    return (
        <div data-testid={QAId} className={classNames(classes.ModalSidePanelLayout, CSS.ModalSidePanelLayout)}>
            <div data-testid={LeftPanelQAId} className={CSS.ModalSidePanelLayout__LeftPanel}>
                {leftPanelChildren}
            </div>
            <div data-testid={RightPanelQAId} className={CSS.ModalSidePanelLayout__RightPanel}>
                {children}
            </div>
        </div>
    );
};
