import { careTeamFilterTabs } from "../constants";
import { IconButton, Input, InputAdornment } from "@mui/material";
import { MODAL_TYPES } from "constant";
import { ReactComponent as CrossCircleSvg } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as PlusSvg } from "icon-library/SVG/Plus.svg";
import { ReactComponent as SearchSvg } from "icon-library/SVG/Search.svg";
import { setActiveModal } from "store/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import * as CSS from "../class-names";
import SearchFilterTabs from "components/SearchFilterTabs/SearchFilterTabs";
import SVGIcon from "common/components/svg-icon/svg-icon";

type SearchHeaderProps = {
    onSearchValueChanged: (searchValue: string) => void;
    onTabSelected: (tabName: string) => void;
    searchValue: string;
    selectedTab: string;
};

const SearchHeader = ({ onSearchValueChanged, onTabSelected, searchValue, selectedTab }: SearchHeaderProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    return (
        <div className={`${CSS.CareTeamSearchHeader}`}>
            <Input
                data-testid="QA_careTeam_search_input"
                id="QA_careTeam_search_input"
                className={CSS.CareTeamSearchHeaderSearchField}
                disableUnderline
                placeholder={intl.formatMessage({ id: "careTeam.search.input.placeholder" })}
                startAdornment={
                    <InputAdornment className={CSS.CareTeamSearchHeaderSearchFieldStartIcon} position="start">
                        <SVGIcon svg={SearchSvg} size="scale600" />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment
                        className={CSS.CareTeamSearchHeaderSearchFieldEndIcon}
                        id="QA_careTeam_search_input_clear"
                        position="end"
                        onClick={() => onSearchValueChanged("")}
                    >
                        <SVGIcon svg={CrossCircleSvg} size="scale1000" />
                    </InputAdornment>
                }
                value={searchValue}
                onInput={(e: any) => {
                    onSearchValueChanged(e.target.value);
                }}
            ></Input>
            <IconButton
                id="QA_careTeam_search_add"
                data-testid="careTeam_search_add"
                className={CSS.CareTeamSearchHeaderAdd}
                title={intl.formatMessage({ id: "careTeam.search.addButton.title" })}
                onClick={() => {
                    dispatch(setActiveModal(MODAL_TYPES.PROVIDER.CARE_TEAM_EDITOR));
                }}
            >
                <PlusSvg />
            </IconButton>
            <SearchFilterTabs
                className={CSS.CareTeamSearchHeaderTabs}
                tab={selectedTab}
                setTab={onTabSelected}
                tabList={careTeamFilterTabs}
            />
        </div>
    );
};

export default SearchHeader;
