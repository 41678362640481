import React, {useRef, useState} from "react";
import { AnyMenuItem } from "common/components/OptionMenu/types";
import {Button} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {CONVERSATION_TYPE} from "constant";
import {createSvgIcon} from "utils";
import OptionMenu from "common/components/OptionMenu/OptionMenu";
import {ReactComponent as Ellipsis} from "icon-library/SVG/Ellipsis-Horizontal_Circle.svg";
import {ReactComponent as MailSvg} from "icon-library/SVG/eMail_Closed-Envelope.svg";
import {setConversationFilter} from "store/actions";
import {AppReduxStore} from "store/reducerTypes";
import {useStyles} from "./styles";

const EllipsisIcon = createSvgIcon(Ellipsis)
const Mail = createSvgIcon(MailSvg)

const EllipsisMenu = () => {
    const [ellipsisMenuOpen, setEllipsisMenuOpen] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const ellipsisMenuRef = useRef(null)
    const classes = useStyles()
    const { selectedPatient } = useSelector((state: AppReduxStore) => state.patient)

    if (!selectedPatient) return null

    const { mrn } = selectedPatient

    const ellipsisMenuConfig: AnyMenuItem[] = [
        {
            id: "QA_PatientDetailsHeader.option.seeAllMessages",
            labelKey: "PatientDetailsHeader.option.seeAllMessages",
            icon: Mail,
            onClick: () => {
                dispatch(setConversationFilter(CONVERSATION_TYPE.PATIENT));
                history.push("/provider/messages/inbox?search=" + mrn);
            },
        },
    ];

    return (
        <>
            <Button
                ref={ellipsisMenuRef}
                className={classes.ellipsisButton}
                color="primary"
                id="QA_Ellipsis"
                onClick={() => setEllipsisMenuOpen(!ellipsisMenuOpen)}
            >
                <EllipsisIcon/>
            </Button>
            <OptionMenu
                anchorEl={ellipsisMenuRef}
                handleClose={() => setEllipsisMenuOpen(false)}
                variant="light"
                menuConfig={ellipsisMenuConfig}
                open={ellipsisMenuOpen}
            />
        </>
    )
}

export default EllipsisMenu;
