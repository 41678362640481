import React, { ReactElement, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
    Box, IconButton, Button, Dialog, DialogActions, DialogContent,
    Paper, Typography, Grid, Stepper, Step, StepLabel
} from "@mui/material";
import AddOrgInfo from "admin/views/Organizations/AddOrgInfo/AddOrgInfo";
import AddOrgAdmin from "admin/views/Organizations/AddOrgAdmin/AddOrgAdmin";
import AddOrgProducts from "admin/views/Organizations/AddOrgProducts/AddOrgProducts";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useStyles } from "./styles";
import { showNotification } from "admin/store/actions/snackbar";
import { call } from "admin/store/api";
import { createGUIDString, validateEmail, validateNoSpecialCharacters, validatePostalCode, validateRequired } from "admin/common/utils";
import { getChildOrganizationSummaries, getChildOrganizationSummariesByRootOrg } from "admin/store/actions/organization";
import { updateWebAdminCacheOrganization } from "admin/store/actions";
import { createSvgIcon } from "utils";
import { ReactComponent as CrossSvg } from "@altais/icon-library/legacy/SVG/Cross_Circle.svg";
import { ReactComponent as Buildings } from "@altais/icon-library/legacy/SVG/Buildings.svg";
import { ReactComponent as ChevronRight } from "@altais/icon-library/legacy/SVG/Chevron_Right.svg";
import { ADMIN_WEB_CACHE } from "admin/constant";
import { AddOrgProps } from "./types";
import { Permissions } from "types";

const AddOrg = ({ isAddOrgWizardOpen, closeAddOrgModal, pendingOrgTemporaryId }: AddOrgProps) => {

    const { auth, admin } = useSelector((state: AppReduxStore) => state);
    const { adminWebCacheOrganization } = admin
    const CrossIcon = createSvgIcon(CrossSvg)
    const BuildingIcon = createSvgIcon(Buildings);
    const ChevronRightIcon = createSvgIcon(ChevronRight);
    const dispatch = useDispatch();
    const intl = useIntl()
    const [errorsOrgInfo, setErrorsOrgInfo] = useState({} as any);
    const [errorsAdminInfo, setErrorsAdminInfo] = useState({} as any);
    const [isProductsSelected, setIsProductsSelected] = useState(false);
    const { license, navigation } = useSelector((state: AppReduxStore) => state);
    const { allProducts, orgLicenses } = license
    const initialOrgInfoFormData = {
        organizationName: "",
        addressOne: "",
        addressTwo: "",
        city: "",
        state: "",
        zip: "",
        nunaRole: "",
        absorbDepartmentId: auth.currentOrg?.absorbDepartmentId
    };

    const initialOrgAdminFormData = {
        firstName: "",
        middleName: "",
        lastName: "",
        mobilePhone: "",
        primaryPhone: "",
        email: "",
        adminLevelId: "",
        prefix: ""
    };
    const [selectedAddOrgStepIndex, setSelectedAddOrgStepIndex] = useState(1);
    const [orgInfoFormData, setOrgInfoFormData] = useState(initialOrgInfoFormData);
    const [orgAdminFormData, setOrgAdminFormData] = useState(initialOrgAdminFormData);
    const [orgProductsFormData, setOrgProductsFormData] = useState([...allProducts].map((p: any) => {
        const org = orgLicenses && orgLicenses.find((o: any) => o.product?.id === p.id);
        return { id: p.id, name: p.name, code: p.code, parentId: p.parentId, licenseCount: (p.unlimited) ? "Unlimited" : 0, isChecked: false, disabledSwitch: false, availableLicenseCount: (p.unlimited) ? "Unlimited" : (org?.licenseCount - org?.usedLicenseCount) }
    }));
    const classes = useStyles(selectedAddOrgStepIndex === 1);
    const modalClasses = {
        paper: classes.wrapper
    };

    const [isInputDisabled, setInputDisabled] = useState(false);
    const emailDataObj: any = {}
    const [existingUserEmailObj, setExistingUserEmailObj] = useState(emailDataObj);
    useEffect(() => {
        if (auth.currentOrg) {
            //dispatch(getAdminProfiles())
            if (pendingOrgTemporaryId) {
                const tempFormData = adminWebCacheOrganization?.formData[pendingOrgTemporaryId]?.tempFormData
                setOrgInfoFormData(tempFormData?.orgInfoFormData)
                setOrgAdminFormData(tempFormData?.orgAdminFormData)
                setOrgProductsFormData(tempFormData?.orgProductsFormData)
                setSelectedAddOrgStepIndex(4 - tempFormData?.pendingSteps);
                if (tempFormData?.orgAdminFormData?.email) {
                    handleInputBlur({
                        target: {
                            name: 'email',
                            value: tempFormData?.orgAdminFormData?.email
                        }
                    })
                }
            }
        }
    }, [adminWebCacheOrganization?.formData, auth.currentOrg, dispatch, pendingOrgTemporaryId]);

    const handleOrgProductsInputChange = (event: any, index: number) => {
        const { name, value } = event.target
        const newOrgProductsFormData = [...orgProductsFormData];
        if (name === 'productSwitch') {
            newOrgProductsFormData[index].isChecked = !newOrgProductsFormData[index].isChecked
        } else {
            newOrgProductsFormData[index].licenseCount = value;
            newOrgProductsFormData[index].isChecked = parseInt(value) > 0
        }

        setOrgProductsFormData(newOrgProductsFormData);
        validateProducts(newOrgProductsFormData, false)
        setIsProductsSelected(newOrgProductsFormData?.find((p: any) => p.isChecked) !== undefined)
    };
    const validateProducts = (updatedProducts: any[], isSaveAction: boolean): boolean => {
        let validProducts = true
        const coreProduct = updatedProducts && (updatedProducts.find((p: any) => p.code === "CORE") as any);
        const digitalFrontDoorProduct =
            updatedProducts && (updatedProducts.find((p: any) => p.code === "FRONT_DOOR") as any);
        const eTranscriptionProduct =
            updatedProducts && (updatedProducts.find((p: any) => p.code === Permissions.ETRANSCRIPT) as any);
        const careTeamProduct = updatedProducts && (updatedProducts.find((p: any) => p.code === "CARE_TEAM") as any);
        // KEPLER-954 License Rules for Org/Practice
        // If Core Platform if not assigned, then Digital Front Door, Care Team Communications, and eTranscription are Disabled
        if (coreProduct && !coreProduct.isChecked) {
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.isChecked = false
                digitalFrontDoorProduct.disabledSwitch = true
            }
            if (careTeamProduct) {
                careTeamProduct.isChecked = false
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.isChecked = false
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // When Core Platform is assigned, then Digital Front Door is also enabled and assigned
        if (coreProduct && coreProduct.isChecked) {
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.isChecked = true
                digitalFrontDoorProduct.disabledSwitch = false
            }

        }
        // When Core Platform is assigned, then Care Team Communications and eTranscription become enabled, but NOT assigned
        if (coreProduct && coreProduct.isChecked) {
            if (careTeamProduct) {
                careTeamProduct.disabledSwitch = false
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.disabledSwitch = false
            }

        }
        // If either Core Platform OR Digital Front Door are unassigned, 
        // then Care Team Communications and eTranscriptions become unassigned, 0 licenses, and disabled
        if ((coreProduct && !coreProduct.isChecked) || (digitalFrontDoorProduct && !digitalFrontDoorProduct.isChecked)) {
            if (careTeamProduct) {
                careTeamProduct["isChecked"] = false
                careTeamProduct["licenseCount"] = 0
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct["isChecked"] = false
                eTranscriptionProduct["licenseCount"] = 0
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // If Core Platform is enabled, it must have a license count of greater than 0
        if (isSaveAction && coreProduct && coreProduct.isChecked && coreProduct.licenseCount !== "Unlimited" && parseInt(coreProduct.licenseCount ? coreProduct.licenseCount.toString() : "0") === 0) {
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "OrgProductsEdit.CoreProductCountGreaterThanZero" })));
            validProducts = false
        }
        setOrgProductsFormData(updatedProducts)
        return validProducts
    }
    const clearAllForms = () => {
        setOrgInfoFormData(initialOrgInfoFormData);
        setOrgAdminFormData(initialOrgAdminFormData);
        setOrgProductsFormData([...allProducts].map((p: any) => {
            const org = orgLicenses && orgLicenses.find((o: any) => o.product?.id === p.id);
            return { id: p.id, name: p.name, code: p.code, parentId: p.parentId, licenseCount: 0, isChecked: false, disabledSwitch: false, availableLicenseCount: org?.availableLicenseCount }
        }));
        setErrorsOrgInfo({})
        setErrorsAdminInfo({})
    };

    const handleClearAndCloseModal = () => {
        clearAllForms();
        closeAddOrgModal();
    };

    const handleSaveNewOrg = async () => {
        if (isInputDisabled) {
            orgAdminFormData.primaryPhone = existingUserEmailObj.primaryPhone
            orgAdminFormData.mobilePhone = existingUserEmailObj.mobilePhone
            orgAdminFormData.prefix = existingUserEmailObj.prefix
            orgAdminFormData.firstName = existingUserEmailObj.firstName
            orgAdminFormData.middleName = existingUserEmailObj.middleName
            orgAdminFormData.lastName = existingUserEmailObj.lastName
        }
        const invalidOrgInfo = !validateOrgInfo(orgInfoFormData)
        const invalidAdminInfo = !validateAdminInfo(orgAdminFormData)
        const invalidProducts = !validateProducts(orgProductsFormData, true)
        if (invalidOrgInfo || invalidAdminInfo || invalidProducts) {
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "AddOrg.RequiredField.Missing" })));
            return
        }
        let corePlateformhasError = false
        orgProductsFormData && orgProductsFormData.forEach((p, index) => {
            if (p.code === "CORE" && p.isChecked) {
                if (parseInt(p.licenseCount ? p.licenseCount.toString() : "0") === 0) {
                    dispatch(showNotification("Error", "error", "Core Platform must have a license count of greater than zero"));
                    corePlateformhasError = true
                }
            }
        });
        if (corePlateformhasError) {
            return
        }

        if (!orgInfoFormData.organizationName || !orgInfoFormData.city
            || !orgAdminFormData.firstName || !orgAdminFormData.lastName
            || !orgAdminFormData.email || !orgAdminFormData.adminLevelId
            || !orgAdminFormData.mobilePhone) {
            dispatch(showNotification("Error", "error", "MISSING REQUIRED FIELD. PLEASE COMPLETE BEFORE SAVING"));
        } else {
            const newOrganization = {
                name: orgInfoFormData.organizationName,
                address1: orgInfoFormData.addressOne,
                address2: orgInfoFormData.addressTwo,
                city: orgInfoFormData.city,
                state: orgInfoFormData.state,
                postalCode: orgInfoFormData.zip,
                nunaRole: orgInfoFormData.nunaRole,
                absorbDepartmentId: orgInfoFormData.absorbDepartmentId,
                country: 'US',
                adminFirstName: orgAdminFormData.firstName,
                adminMiddleName: orgAdminFormData.middleName,
                adminLastName: orgAdminFormData.lastName,
                adminMobilePhone: orgAdminFormData.mobilePhone,
                adminPrimaryPhone: orgAdminFormData.primaryPhone,
                adminEmail: orgAdminFormData.email,
                adminLevelId: orgAdminFormData.adminLevelId,
                adminPrefix: orgAdminFormData.prefix
            };

            const products = {} as any;
            orgProductsFormData && orgProductsFormData.filter((element: any) => element.isChecked)
                .map((p: any) => {
                    products[p.id] = parseInt(p.licenseCount ? p.licenseCount : "0")
                    return p
                });
            if (Object.keys(products).length > 0) {
                (newOrganization as any)["productLicenses"] = products
            }
            if (auth.currentOrg?.id) {
                (newOrganization as any)["parentId"] = auth.currentOrg?.id
                if (pendingOrgTemporaryId && adminWebCacheOrganization?.formData[pendingOrgTemporaryId]?.parentOrgId) {
                    (newOrganization as any)["parentId"] = adminWebCacheOrganization?.formData[pendingOrgTemporaryId]?.parentOrgId
                }
            }
            const createOrgResult = await call("POST", "/admin/v1/organization", newOrganization)
                .then((payload) => {
                    return 200;
                })
                .catch((err) => {
                    return err;
                });

            if (createOrgResult === 200) {
                if (pendingOrgTemporaryId) {
                    const formData = adminWebCacheOrganization?.formData;
                    delete formData[pendingOrgTemporaryId]
                    dispatch(updateWebAdminCacheOrganization(ADMIN_WEB_CACHE.ADD_ORGANIZATION_WIZARD_CACHE, formData))
                }
                handleClearAndCloseModal();
                dispatch(showNotification("ORGANIZATION SUCCESSFULLY CREATED", "success", ""));
                dispatch(getChildOrganizationSummariesByRootOrg());
                dispatch(getChildOrganizationSummaries(auth.currentOrg?.id));
            } else {
                dispatch(showNotification("Error", "error", "Error creating organization"));
            }
        }
    };

    const handleOrgInfoInputChange = (event: any) => {
        const { name, value } = event.target;

        setOrgInfoFormData(state => ({
            ...state,
            [name]: value
        }));
        const fieldValue = {} as any;
        fieldValue[name] = value
        validateOrgInfo(fieldValue)
    };


    const validateOrgInfo = (fieldValues = orgInfoFormData, flag = true) => {
        const err = { ...errorsOrgInfo };
        if ('organizationName' in fieldValues) {
            err.organizationName = validateRequired(fieldValues.organizationName, intl)
            if (err.organizationName === "") err.organizationName = validateNoSpecialCharacters(fieldValues.organizationName, intl)
        }
        if ('city' in fieldValues) {
            err.city = validateRequired(fieldValues.city, intl)
        }
        if ('state' in fieldValues) {
            err.state = validateRequired(fieldValues.state, intl)
        }
        if ('zip' in fieldValues) {
            err.zip = validateRequired(fieldValues.zip, intl)
            if (err.zip === "") err.zip = validatePostalCode(fieldValues.zip, intl)
        }
        if (flag) {
            setErrorsOrgInfo({
                ...err
            })
        }
        //if (fieldValues === orgInfoFormData)
        return Object.values(err).every(x => x === "")
    }

    const handleOrgAdminInputChange = (event: any) => {
        const { name, value } = event.target;

        setOrgAdminFormData(state => ({
            ...state,
            [name]: value
        }));
        const fieldValue = {} as any;
        fieldValue[name] = value
        validateAdminInfo(fieldValue)
    };

    const validateAdminInfo = (fieldValues = orgAdminFormData, flag = true) => {
        const err = { ...errorsAdminInfo };
        if ('firstName' in fieldValues) {
            err.firstName = isInputDisabled ? "" : validateRequired(fieldValues.firstName, intl)
        }
        if ('lastName' in fieldValues) {
            err.lastName = isInputDisabled ? "" : validateRequired(fieldValues.lastName, intl)
        }
        if ('email' in fieldValues) {
            err.email = validateRequired(fieldValues.email, intl)
            if (err.email === "") err.email = validateEmail(fieldValues.email, intl)
        }
        if ('mobilePhone' in fieldValues) {
            err.mobilePhone = isInputDisabled ? "" : validateRequired(fieldValues.mobilePhone, intl)
            if (err.mobilePhone === "") err.mobilePhone = isInputDisabled ? "" : validateNoSpecialCharacters(fieldValues.mobilePhone, intl)
        }
        if ('adminLevelId' in fieldValues) {
            err.adminLevelId = validateRequired(fieldValues.adminLevelId, intl)
        }
        if (flag) {
            setErrorsAdminInfo({
                ...err
            })
        }
        //if (fieldValues === orgAdminFormData)
        return Object.values(err).every(x => x === "")
    }

    const handleAddOrgStepClick = (event: any, index: any) => {
        if (index === 2) {
            const invalidOrgInfo = !validateOrgInfo(orgInfoFormData)
            if (invalidOrgInfo) {
                dispatch(showNotification("Error", "error", intl.formatMessage({ id: "AddOrg.RequiredField.Missing" })));
                return
            }
        }
        else if (index === 3) {
            const invalidAdminInfo = !validateAdminInfo(orgAdminFormData)
            if (invalidAdminInfo) {
                dispatch(showNotification("Error", "error", intl.formatMessage({ id: "AddOrg.RequiredField.Missing" })));
                return
            }
        }
        setSelectedAddOrgStepIndex(index);
    };

    const handleSaveAndContinueLaterClick = (event: any, index: any) => {
        if (!orgInfoFormData?.organizationName || orgInfoFormData?.organizationName?.trim() === "") {
            const fieldValue = {} as any;
            fieldValue["organizationName"] = orgInfoFormData?.organizationName?.trim()
            validateOrgInfo(fieldValue)
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "AddOrg.RequiredField.Missing" })));
            return
        }
        const formData = adminWebCacheOrganization?.formData || {};
        formData[pendingOrgTemporaryId ? pendingOrgTemporaryId : createGUIDString()] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgId: auth.currentOrg?.id,
            parentOrgName: auth.currentOrg?.name,
            haveOrgParent: auth.currentOrg?.parent ? true : false,
            tempFormData: {
                orgInfoFormData,
                orgAdminFormData,
                orgProductsFormData,
                pendingSteps: (4 - index)
            }
        }
        dispatch(updateWebAdminCacheOrganization(ADMIN_WEB_CACHE.ADD_ORGANIZATION_WIZARD_CACHE, formData))
        handleClearAndCloseModal();
    };

    const AddOrgStepContent = (step: number): ReactElement => {
        let content: ReactElement;
        const haveOrgParent = (pendingOrgTemporaryId ? adminWebCacheOrganization?.formData[pendingOrgTemporaryId]?.haveOrgParent : auth.currentOrg?.parent) ? true : false
        switch (step) {
            case 1:
                content = <AddOrgInfo handleOrgInfoInputChange={handleOrgInfoInputChange} orgInfoFormData={orgInfoFormData} errorsOrgInfo={errorsOrgInfo} haveOrgParent={haveOrgParent} />
                break;
            case 2:
                content = <AddOrgAdmin handleOrgAdminInputChange={handleOrgAdminInputChange} orgAdminFormData={orgAdminFormData} errorsAdminInfo={errorsAdminInfo} isInputDisabled={isInputDisabled} handleInputBlur={handleInputBlur} haveOrgParent={haveOrgParent} />
                break;
            case 3:
                content = <AddOrgProducts handleOrgProductsInputChange={handleOrgProductsInputChange} orgProductsFormData={orgProductsFormData} showAvailableToAdd={auth.currentOrg?.parent != null} haveOrgParent={haveOrgParent} />
                break;
            default:
                content = <div>Not Implemented</div>
        }

        return content;
    };

    const handleInputBlur = async (event: any) => {
        const { value } = event.target
        if (auth && value.trim() !== "") {
            const presignedPutUrl = `/admin/v1/admin/email/${value}`;
            const existingEmailDetails = await call("GET", presignedPutUrl, {})
                .catch(error => {
                    // setLoading(false)
                });
            if (existingEmailDetails && existingEmailDetails.email !== null) {
                setExistingUserEmailObj(existingEmailDetails)
                setOrgAdminFormData({
                    adminLevelId: "",
                    email: existingEmailDetails.email,
                    primaryPhone: existingEmailDetails.primaryPhone,
                    mobilePhone: existingEmailDetails.mobilePhone,
                    prefix: existingEmailDetails.prefix,
                    firstName: existingEmailDetails.firstName,
                    middleName: existingEmailDetails.middleName,
                    lastName: existingEmailDetails.lastName
                })
                setInputDisabled(true)
            } else {
                setInputDisabled(false)
                setExistingUserEmailObj({})
            }
        } else { setInputDisabled(false); setExistingUserEmailObj({}) }
    }

    return (
        <Dialog maxWidth={"lg"} open={isAddOrgWizardOpen} onClose={handleClearAndCloseModal} classes={modalClasses}>
            <DialogActions className={classes.dialogAction}>
                <Box flexGrow={1} mt={3}>
                    <Typography className={classes.dialogHeaderTitle}>
                        <FormattedMessage id={(pendingOrgTemporaryId ? adminWebCacheOrganization?.formData[pendingOrgTemporaryId]?.haveOrgParent : auth.currentOrg?.parent) ? "AddOrg.Header.AddSubOrganization" : "AddOrg.Header.AddOrganization"} />
                    </Typography>
                    <Typography className={classes.dialogHeaderText}>
                        {navigation.breadCrumbEntityHierarchy?.length > 0 && navigation.breadCrumbEntityHierarchy.map((org: any, index: number) => {
                            if (!org) return null
                            return (<><BuildingIcon className={classes.orgIcon} />{org.organizationName}{index < navigation.breadCrumbEntityHierarchy?.length - 1 && <ChevronRightIcon color="#909098" className={classes.chevRightIcon} />}</>)
                        })}
                        {selectedAddOrgStepIndex > 1 && orgInfoFormData?.organizationName?.trim() && <><ChevronRightIcon color="#909098" className={classes.chevRightIcon} />
                            <BuildingIcon className={classes.orgIcon} />{orgInfoFormData?.organizationName?.trim()} </>}
                    </Typography>
                </Box>
                <Box>
                    <IconButton onClick={handleClearAndCloseModal} size="large">
                        <CrossIcon className={classes.closeButtonIcon} />
                    </IconButton >
                </Box>
            </DialogActions>
            <DialogActions className={classes.dialogAction}>
                <Box className={classes.dialogActionBar}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: "right" }}>
                        <Button id="btnSaveAndContinue" variant="outlined" style={{ textTransform: 'none' }} onClick={(event) => handleSaveAndContinueLaterClick(event, selectedAddOrgStepIndex)}>
                            <FormattedMessage id="AddOrg.Button.SaveAndContinueLater" />
                        </Button>
                        {(selectedAddOrgStepIndex === 1 || selectedAddOrgStepIndex === 2) ?
                            <Button
                                id="btnNextOrgStep"
                                variant="contained"
                                color="primary"
                                aria-haspopup="true"
                                onClick={(event) => handleAddOrgStepClick(event, selectedAddOrgStepIndex + 1)}
                                className={classes.dialogHeaderSaveButton}
                            >
                                <FormattedMessage id="AddOrg.Button.Next" />
                            </Button>
                            :
                            <Button
                                id="saveNewOrgBtn"
                                variant="outlined"
                                color="primary"
                                aria-haspopup="true"
                                onClick={handleSaveNewOrg}
                                className={classes.dialogHeaderSaveButton}
                            >
                                <FormattedMessage id={(pendingOrgTemporaryId ? adminWebCacheOrganization?.formData[pendingOrgTemporaryId]?.haveOrgParent : auth.currentOrg?.parent) ? "AddOrg.Button.AddSubOrganization" : "AddOrg.Button.AddOrganization"} />
                            </Button>
                        }
                    </Grid>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ root: classes.dialogContentRoot, dividers: classes.dialogContentDividers }}>
                <Paper square elevation={0}>
                    <Box className={classes.contentWrapper}>
                        <Box className={classes.contentArea}>

                            <Box className={classes.leftPaneWrapper}>
                                <Stepper activeStep={selectedAddOrgStepIndex} orientation="vertical"
                                    classes={{ root: classes.stepperRoot }}>
                                    <Step completed={selectedAddOrgStepIndex > 1 || (orgInfoFormData && validateOrgInfo(orgInfoFormData, false))} active={selectedAddOrgStepIndex === 1}>
                                        <StepLabel classes={{ completed: classes.stepperIconCompleted, active: classes.stepperIconCompleted }} onClick={(event) => handleAddOrgStepClick(event, 1)}>
                                            <Typography variant="h4" className={classes.stepperLabel}>
                                                <FormattedMessage id="AddOrg.Stepper.Information" />
                                            </Typography>
                                        </StepLabel>
                                    </Step>
                                    <Step completed={selectedAddOrgStepIndex > 2 || (orgAdminFormData && validateAdminInfo(orgAdminFormData, false))} active={selectedAddOrgStepIndex === 2}>
                                        <StepLabel classes={{ completed: classes.stepperIconCompleted, active: classes.stepperIconCompleted }} onClick={(event) => handleAddOrgStepClick(event, 2)}>
                                            <Typography variant="h4" className={classes.stepperLabel}>
                                                <FormattedMessage id="AddOrg.Stepper.Administrator" />
                                            </Typography>
                                        </StepLabel>
                                    </Step>
                                    <Step active={selectedAddOrgStepIndex === 3} completed={isProductsSelected}>
                                        <StepLabel classes={{ completed: classes.stepperIconCompleted, active: classes.stepperIconCompleted }} onClick={(event) => handleAddOrgStepClick(event, 3)}>
                                            <Typography variant="h4" className={classes.stepperLabel}>
                                                <FormattedMessage id="AddOrg.Stepper.Products" />
                                            </Typography>
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                                {/* Custom Stepper */}
                                {/* <List>
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddOrgStepIndex === 1}
                                        onClick={(event) => handleAddOrgStepClick(event, 1)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddOrgStepIndex === 1 || selectedAddOrgStepIndex === 2
                                                || selectedAddOrgStepIndex === 3) ?
                                                <div style={{ background: "#0F7BBD" }} className={classes.circleNumber}>1</div>
                                                : <div className={classes.circleNumber}>1</div>
                                            }
                                            <div className={classes.verticalLine}></div>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" >
                                                <FormattedMessage id="Organization" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddOrgStepIndex === 2}
                                        onClick={(event) => handleAddOrgStepClick(event, 2)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddOrgStepIndex === 2 || selectedAddOrgStepIndex === 3) ?
                                                <div style={{ background: "#0F7BBD" }} className={classes.circleNumber}>2</div>
                                                : <div className={classes.circleNumber}>2</div>
                                            }
                                            <div className={classes.verticalLine}></div>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" >
                                                <FormattedMessage id="Administrator" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddOrgStepIndex === 3}
                                        onClick={(event) => handleAddOrgStepClick(event, 3)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddOrgStepIndex === 3) ?
                                                <div style={{ background: "#0F7BBD" }} className={classes.circleNumber}>3</div>
                                                : <div className={classes.circleNumber}>3</div>
                                            }
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" >
                                                <FormattedMessage id="Products" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List> */}
                            </Box>
                            <Box className={classes.rightContent}>
                                {AddOrgStepContent(selectedAddOrgStepIndex)}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

export default AddOrg;
