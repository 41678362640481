import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/material";
import {FormattedTime} from "react-intl";

import ItemCount from "components/ItemCount/ItemCount";
import {AppReduxStore} from "store/reducerTypes";
import {setOpenConversationSid} from "store/actions";

import {MessageThreadProps} from "./types";
import {useStyles} from "./styles";
import {filter, map} from "underscore";
import { getParticipantFullName } from "components/messaging/utils";
import { IsJsonString } from "utils";
import CustomTypography from "common/components/CustomTypography/CustomTypography";

// MessageThread renders the information regarding a conversation such as the list of participants, subject, last read
// message and number of unread messages.
const MessageThread = ({conversation, info}: MessageThreadProps) => {
    const dispatch = useDispatch()
    const {openConversationSid} = useSelector((state: AppReduxStore) => state.chat)
    const {twilioUsername} = useSelector((state: AppReduxStore) => state.user)
    const threadSelected = openConversationSid === conversation.sid
    const classes = useStyles({
        open: threadSelected,
        unreadCount: info.unreadCount
    })

    const onThreadClick = () => {
        if (conversation.sid !== openConversationSid) {
            dispatch(setOpenConversationSid(conversation.sid))
        }
    }

    const renderThreadBody = () => {
        const isEvisitInviteMessage = info.conversationType === "EVISIT" && IsJsonString(info.lastMessage);
        // TODO static message need to change dynamic
        const lastMessage = isEvisitInviteMessage ? 'You are invited to an evisit' :info.lastMessage
        const message = info.lastMessage ? lastMessage : "Loading..."
        return (
            <Box className={classes.threadBody}>
                <Box display="flex" justifyContent="space-between">
                    <CustomTypography noWrap variant="radioText" id="QA_message_subject">{info.subject}</CustomTypography>
                    <CustomTypography className={classes.dateUpdated} variant="description">
                        <FormattedTime value={info.dateUpdated}/>
                    </CustomTypography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <CustomTypography noWrap variant="description">
                        {message}
                    </CustomTypography>
                </Box>
            </Box>
        )
    }

    const participants = filter(info.participants, p => p.identity !== twilioUsername&&p.isEnabled)
    const participantsText = participants.length ? map(participants, (p) => getParticipantFullName(p)).join(", ") : "";

    const wrapperClasses = `${classes.threadWrapper} ${threadSelected ? classes.selectedThread : ""}`
    const isNew = info.unreadCount ? "new" : ""
    const contentClasses = `${classes.content} ` + isNew

    return (
        <li className={wrapperClasses}>
            <a href={`#${conversation.sid}`} onClick={onThreadClick} className={contentClasses} role="button" tabIndex={0} id="QA_select_participant">
                <Box className={classes.header}> 
                    <Box className={classes.participants} display="flex" alignItems="center" id="QA_participantText">
                        <Box className={classes.statusBar}/>
                        <CustomTypography  noWrap variant="descriptionBold">{participantsText}</CustomTypography>
                    </Box>
                    <Box className={classes.threadInfo} id="QA_messageCounts">
                        <ItemCount count={info.unreadCount} className={classes.countWrapper}/>
                    </Box>
                </Box>
                {renderThreadBody()}
            </a>
        </li>
    )
}

MessageThread.defaultProps = {
    conversation: {},
    info: {}
}

export default MessageThread