import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import { TabProps } from "./types";

export function Tab<NameType extends string = string>({
    name,
    count,
    labelId,
    disabled,
    downArrow,
    selected,
    onTabSelected,
    error
}: TabProps<NameType>) {
    const classes = useStyles();

    const handleClick = () => {
        if (!disabled && onTabSelected) {
            onTabSelected(name);
        }
    };

    return (
        <Box className={classNames(classes.tab, { [classes.selectedTabButton]: selected, hasError: error })}>
            <Button
                name={name}
                className={classes.tabButton}
                id={"QA_" + labelId}
                disabled={disabled}
                onClick={handleClick}
            >
                <div className={classes.tabButtonContent}>
                    <Typography component="div" className={classes.tabButtonLabel}>
                        <FormattedMessage id={labelId} />
                    </Typography>
                    {typeof count !== "undefined" && (
                        <div className={classes.count}>
                            <CustomTypography variant="tabCounter">{count}</CustomTypography>
                        </div>
                    )}
                </div>
            </Button>
            {downArrow && <div className={classes.arrowDown} />}
        </Box>
    );
}
