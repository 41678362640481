import React, { memo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { ReactComponent as Key } from "icon-library/SVG/Key.svg";
import { ReactComponent as Question } from "icon-library/SVG/Question_Circle.svg";

const KeyIcon = createSvgIcon(Key);
const QuestionIcon = createSvgIcon(Question);

const SideNav = (props: any) => {
    const classes = useStyles();
    const intl = useIntl();

    const { setCurrentTab, activeTab } = props;

    return (
        <Box className={classes.SideNav}>
            <Box p={2} width="100%" id="QA_access_control">
                {(activeTab === 0) ?
                <Button variant="contained"
                    color="primary"
                    className={classes.accessControl}
                    size="small"
                    onClick={() => setCurrentTab(0)}
                    style={{backgroundColor: "#0F7BBD"}}
                >
                    <KeyIcon fill={"#FFFFFF"} className={classes.svgIcon} />
                    <Typography variant="h4">{intl.formatMessage({ id: "SideNav.accessControl" })}</Typography>
                </Button> :
                <Button variant="text"
                    className={classes.accessControlPrimary}
                    size="small"
                    onClick={() => setCurrentTab(0)}
                >
                    <Key fill={"#6E6E79"} className={classes.svgIcon} />
                    <Typography variant="h4">{intl.formatMessage({ id: "SideNav.accessControl" })}</Typography>
                </Button>
                }
            </Box>

            <Box p={2} width="100%">
                {(activeTab === 1) ?
                    <Button variant="contained"
                        color="primary"
                        className={classes.accessControl}
                        size="small"
                        onClick={() => setCurrentTab(1)}
                        style={{backgroundColor: "#0F7BBD"}}
                    >
                        <QuestionIcon fill={"#FFFFFF"} className={classes.svgIcon} />
                        <Typography variant="h4">{intl.formatMessage({ id: "SideNav.SecretQuestion" })}</Typography>
                    </Button> : 
                    <Button variant="text"
                        className={classes.accessControlPrimary}
                        size="small"
                        onClick={() => setCurrentTab(1)}
                    >
                        <QuestionIcon fill={"#6E6E79"} className={classes.svgIcon} />
                        <Typography variant="h4">{intl.formatMessage({ id: "SideNav.SecretQuestion" })}</Typography>
                    </Button>
                }
            </Box>
        </Box>
    );
}


export default memo(SideNav);
