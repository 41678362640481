import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";
import * as CSS from "./class-names";

export const useStyles = makeStyles({
    [CSS.SearchList]: {
        height: "100%",
        [`& .${CSS.SearchListItem}`]: {
            color: COLORS.GREY,
            cursor: "pointer",
            border: `1px solid ${COLORS.SELECTED_NAV_LIST_ITEM_BTN}`,
            margin: `${SIZING.scale400} ${SIZING.scale400} 0 ${SIZING.scale400}`,
            display: "grid",
            padding: SIZING.scale200,
            fontSize: SIZING.scale300,
            background: COLORS.MESSAGE_LIST_BG,
            boxSizing: "border-box",
            borderRadius: SIZING.scale200,
            height: "fit-content",
            width: `calc(100% - ${SIZING.scale800})`,
            "&--selected": {
                border: `1px solid ${COLORS.LIGHT_BLUE}`,
                background: COLORS.PAGE_MAIN_HEAD_BG,
            }
        },
        [`& .${CSS.SearchListEmptyMessage}`]: {
            margin: "0 auto",
        },
    },
});
