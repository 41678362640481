import { makeStyles } from "@mui/styles";
import { SIZING } from "../../../theme";


export const useStyles = makeStyles(() =>({
    callNoAnswerWrapper: {
        background: "#393948",
        border: "none",
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
        "& h3": {
            display: 'block'
        }
    },
    evisitNoAnswerTitle: {
        fontSize: SIZING.scale800,
        fontWeight: 'normal',
        marginBottom: SIZING.scale700
    },
    evisitNoAnswerDesc: {
        fontSize: SIZING.scale500,
        fontWeight: 'normal',
        marginBottom: '4rem'
    }
}));