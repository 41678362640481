import React from "react";
import {useStyles} from "./styles";
import { Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import ActionButtons from "../ActionButtons/ActionButtons";
 const NoAnswer = ({ handleCallback }: { handleCallback:()=> void  }) => {

    const classes = useStyles()


    return (
        <div className={`${classes.callNoAnswerWrapper}`}>
            <Typography className={classes.evisitNoAnswerTitle} >  <FormattedMessage id="ActiveZoomMeeting.text.callNoAnswer" /></Typography>
            <Typography className={classes.evisitNoAnswerDesc} >  <FormattedMessage id="ActiveZoomMeeting.status.callEndedDesc" /></Typography>
            <ActionButtons  handleCallback={handleCallback}/>         
        </div>
    )
}

export default NoAnswer
