import { ThunkDispatch,ThunkAction } from "redux-thunk";
import {AnyAction} from "redux";
import {call} from "../api";
import {setError} from "./error";
import {AppReduxStore, Form} from "store/reducerTypes";
import {programmaticDownload} from "utils";
import ApiController from "store/listener"
import { showNotification } from 'admin/store/actions/snackbar';

// getPatientDetails makes a request to fetch patient details and meeting status for the given patient
export const getPatientDetails = (patientId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            dispatch({type: 'GET_PATIENT_DETAILS', payload: patientId})
            const response = await call("GET", `/patient/v1/details/${patientId}`)

            const evistDetails = await updatePatientEvisitDetails(patientId, dispatch);

            dispatch({type: 'GET_PATIENT_DETAILS_SUCCESS', payload: {...response, evistDetails: evistDetails}})
            
        } catch (e: any) {
            dispatch({type: 'GET_PATIENT_DETAILS_FAILURE'})
            dispatch(setError("Error.message.patientDetails", e?.message))
        }
    }
}

const searchPatientsCancellationEventName = 'searchPatients';
export const searchPatients = (searchStr: string, fromModule: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        if (!searchStr) {
            dispatch({type: 'PATIENT_SEARCH_SUCCESS', payload: []});
            return
        }
        try {
            dispatch({type: 'PATIENT_SEARCH'});
            const signal = ApiController.setEvent(searchPatientsCancellationEventName);
            const response = await call("GET", '/patient/v1/search', {searchStr, fromModule}, signal);
            dispatch({type: 'PATIENT_SEARCH_SUCCESS', payload: response});
            ApiController.removeEvent(searchPatientsCancellationEventName);
        } catch (e: any) {
            dispatch({type: 'PATIENT_SEARCH_FAILURE'});
            if (e.name !== 'AbortError') {
                ApiController.removeEvent(searchPatientsCancellationEventName);
                dispatch(setError("Error.message.search", e?.message));
            }
        }
    }
}

// getForms makes a request to get all previous forms for the selected patient
export const getForms = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        try {
            const { selectedPatientId } = getState().patient
            const fromDate = new Date("1/1/2020").toISOString() // Start of patient form data
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 30 )
            const toDate = futureDate.toISOString() // 30 Days from current
            dispatch({type: 'GET_PATIENT_FORMS'})
            const response = await call("GET", '/previsit/v1/tasks/', {fromDate, toDate, patientId: selectedPatientId})
            dispatch({type: 'GET_PATIENT_FORMS_SUCCESS', payload: response})
        } catch (e: any) {
            dispatch({type: 'GET_PATIENT_FORMS_FAILURE'})
            dispatch(setError("Error.message.forms", e?.message))
        }
    }
}

// getAppointments makes a request to get a list of all appointments for the selected patient
export const getAppointments = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        try {
            const { selectedPatientId } = getState().patient
            dispatch({type: 'GET_PATIENT_APPOINTMENTS'})
            const response = await call("GET", `/patient/v1/appointments/${selectedPatientId}`)
            dispatch({type: 'GET_PATIENT_APPOINTMENTS_SUCCESS', payload: response})
        } catch (e: any) {
            dispatch({type: 'GET_PATIENT_APPOINTMENTS_FAILURE'})
            dispatch(setError("Error.message.forms", e?.message))
        }
    }
}

//todo: Change the implementation to fetch all the alerts
export const getDischargeAlert = (patientId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        if (!patientId) {
            dispatch({type: 'GET_PATIENT_ALERTS_SUCCESS', payload: []})
            return
        }

        try {
            dispatch({type: 'GET_PATIENT_ALERTS', payload: patientId})
            const response = await call("GET", `/patient/v1/adt-events/${patientId}`)
            dispatch({type: 'GET_PATIENT_ALERTS_SUCCESS', payload: response})
        } catch (e: any) {
            dispatch({type: 'GET_PATIENT_ALERTS_FAILURE'})
            dispatch(setError("Error.message.patientAlerts", e?.message))
        }
    }
}

// getFormPdf makes a request to get the downloadable patient questionnaire, packages it a blob to be downloaded by the user
export const getFormPdf = (form: Form) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            if (!form) return
            const {patient, id, title} = form
            dispatch({type: 'SET_FORM_PDF_LOADING', payload: true})
            const pdfBlob = await call("GET", `/previsit/v1/questionnaire/pdf/${id}`, {isProviderPDF: true})
            const filename = `${patient.lastName}-${patient.firstName}-${title}`
            programmaticDownload(filename, pdfBlob)
        } catch (e: any) {
            dispatch(setError("Error.message.formPdf", e?.message))
        } finally {
            dispatch({type: 'SET_FORM_PDF_LOADING', payload: false})
        }
    }
}

// pushFormToPatient will push a form to a patient
export const pushFormToPatient = (form: Form) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            if (!form)
                return;
            const {dueDate, patient, questionnaire, requester, type} = form;
            const userCreateRequest = {
                dueDate: dueDate,
                patientId: patient.id,
                questionnaireId: questionnaire.id,
                requesterId: requester.id,
                type: type,
                visitId: null
            };
            dispatch({type: 'SET_PUSHING_FORM_LOADING', payload: true});
            await call("POST", `/admin/v1/patients/tasks`, userCreateRequest)
                .then((response) => {
                    dispatch(showNotification("Patient Form added successfully", "success", ""));
                });
        } catch (e: any) {
            dispatch(setError("Error.message.sendForm", e?.message));
        } finally {
            dispatch({type: 'SET_PUSHING_FORM_LOADING', payload: false});
        }
    }
}

export const getAlertDetails = (alertId: string): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve,reject) => {
            try {
                const response = await call("GET", `/patient/v1/adt-event/${alertId}`)
                resolve()
                dispatch({ type: 'UPDATE_PATIENT_ALERTS_DETAILS', payload: response })
            } catch (e:any) {
                reject(e)
                dispatch(setError("Error.message.forms", e?.message))
            }

        })
       
    }
}

export const updatePatientEvisitDetails = (patientId: string, dispatch: any) => {

    return new Promise(async (resolve) => {
        let evistDetails = {}
        try {
            evistDetails = await call("GET", `/patient/v1/meeting/status/${patientId}`) || {}
            evistDetails = {
                ...evistDetails,
                //@ts-ignore
                visitKey: evistDetails.visitId
            }
        } catch (error: any) {
            if (error.status !== 400 && error.status !== 404) {
                dispatch(setError("Error.message.patientEvistDetails", error?.message))
            }
        }
        resolve(evistDetails)
    })

}

export const searchPatientBySearchString  = (searchStr: string,fromModule:string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const signal =  ApiController.setEvent('pngInvite')
            const response = await call("GET", '/patient/v1/search', {searchStr, fromModule: fromModule},signal)
            ApiController.removeEvent('pngInvite')
            resolve(response)
        } catch (e: any) {
            reject()
            ApiController.removeEvent('pngInvite')
        }
    })
}

export const getFeedbackLoop = (patientId: string): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        try {
            const response = await call("GET", `/patient/v1/feedback-loop/${patientId}`);
            dispatch({ type: 'GET_PATIENT_FEED_BACK_LOOP', payload: response });
        } catch (error:any) {
            if (error.status !== 404) {
                dispatch(setError("Error.message.patientEvistDetails", error?.message));
            }
        }
    }
}

export const setFeedbackLoop = (feedbackFieldId: string[]): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        try {
            const response = await call("PATCH", `/patient/v1/feedback-loop/`, { feedbackFieldList: feedbackFieldId } );
            return response;
        } catch (error:any) {
            dispatch(setError("Error.message.patientEvistDetails", error?.message));
        }
    }
}

export const getPatientRPMDetails = (patientId: string): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {
               const response = await call("GET", `/patient/v1/rpm-device/${patientId}/details`);
                resolve(response)
            } catch (e: any) {
                console.error(e)
                reject(e)
                dispatch(setError("Error.message.rpmDetails"))
            }
        })
    }
}