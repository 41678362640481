import { IconButton, Input, InputAdornment } from "@mui/material";
import { ReactComponent as CrossCircleSvg } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as SearchSvg } from "icon-library/SVG/Search.svg";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import * as CSS from "./class-names";
import SVGIcon from "common/components/svg-icon/svg-icon";

export type PatientSearchFieldProps = {
    idPrefix: string;
    onChange: (searchValue: string) => void;
    searchValue: string;
};

export const PatientSearchField = ({ idPrefix, onChange, searchValue }: PatientSearchFieldProps) => {
    const intl = useIntl();
    const styles = useStyles();

    return (
        <div className={styles.PatientSearchField}>
            <Input
                data-testid={`${idPrefix}_patientSearch_input`}
                id={`${idPrefix}_patientSearch_input`}
                className={CSS.PatientSearchFieldInput}
                disableUnderline
                placeholder={intl.formatMessage({ id: "patientSearch.input.placeholder" })}
                startAdornment={
                    <InputAdornment position="start" className={CSS.PatientSearchFieldInputIcon}>
                        <SVGIcon svg={SearchSvg} size="scale600" />
                    </InputAdornment>
                }
                value={searchValue}
                onInput={(e: any) => {
                    onChange(e.target.value);
                }}
            ></Input>
            <IconButton
                id={`${idPrefix}_patientSearch_clear`}
                data-testid={`${idPrefix}_patientSearch_clear`}
                className={CSS.PatientSearchFieldClearButton}
                title={intl.formatMessage({ id: "patientSearch.clearButton.title" })}
                onClick={() => {
                    onChange("");
                }}
                hidden={!searchValue}
            >
                <CrossCircleSvg />
            </IconButton>
        </div>
    );
};
