import { makeStyles } from "@mui/styles";
import { COLORS, SIZING, toRem } from "theme";
import {
    FileDropzoneInput,
    DropzoneLayout,
    DropzoneLayoutActive,
    DropzoneLayoutEmptyMessage,
    FileDropzoneInputSmall,
    Preview,
    PreviewGrid,
    PreviewImg,
    PreviewSize,
    PreviewImgVisible,
    PreviewContainer,
    PreviewIcon,
    PreviewLoading,
    PreviewTextLine,
    PreviewError,
} from "./class-names";

export const useStyles = makeStyles(() => ({
    [FileDropzoneInput]: {
        display: "flex",
        flexGrow: 1,
    },
    [DropzoneLayout]: {
        position: "relative",
        flexGrow: 1,
        border: ".5px solid rgba(0, 0, 0, .14)",
        boxShadow: "inset 0 1px 2px rgba(0, 0, 0, .12)",
        borderRadius: SIZING.scale200,
        backgroundColor: COLORS.WHITE,
        overflow: "auto",
        [`&.${DropzoneLayoutActive}`]: {
            backgroundColor: COLORS.DRAG_N_DROP_ON_DRAG_OVER_BG,
        },
        [`& .${DropzoneLayoutEmptyMessage}`]: {
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            [`.${FileDropzoneInputSmall} &`]: {
                display: "none"
            }
        },
        [`& .${PreviewGrid}`]: {
            padding: SIZING.scale800,
            justifyContent: "space-between",
            [`.${FileDropzoneInputSmall} &`]: {
                padding: SIZING.scale400,
            },
        },
        [`.${FileDropzoneInputSmall} &`]: {
            maxHeight: toRem(180)
        },
    },
    [PreviewGrid]: {
        position: "relative",
        zIndex: 1,
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill,${SIZING.scale2950})`,
        gridGap: SIZING.scale600,
        width: "100%",
        justifyContent: "left",
        [`.${FileDropzoneInputSmall} &`]: {
            gridGap: SIZING.scale400,
            gridTemplateColumns: `repeat(auto-fill,${SIZING.scale1600})`,
            justifyContent: "left",
        }
    },
    input: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `${toRem(2.5)} dashed ${COLORS.LINK_BLUE}`,
        borderRadius: SIZING.scale400,
        cursor: "pointer",
        backgroundColor: COLORS.WHITE,
        "& svg": {
            opacity: 0.6,
            position: "relative",
            zIndex: 2,
            width: SIZING.scale2000,
            height: SIZING.scale2000,
            "& path, & g": {
                fill: COLORS.LINK_BLUE,
            },
            [`.${FileDropzoneInputSmall} &`]: {
                width: SIZING.scale1050,
                height: SIZING.scale1050,
            }   
        },
        [`.${FileDropzoneInputSmall} &`]: {
            borderRadius: SIZING.scale200
        },
        "& .background": {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: COLORS.LINK_BLUE,
            border: `${SIZING.scale200} solid ${COLORS.WHITE}`,
            borderRadius: SIZING.scale450,
            opacity: 0.15,
            zIndex: 1,
            [`.${FileDropzoneInputSmall} &`]: {
                borderWidth: SIZING.scale100,
                borderRadius: SIZING.scale200,
                top: `calc(-${toRem(2.5)} / 2)`,
                bottom: `calc(-${toRem(2.5)} / 2)`,
                left: `calc(-${toRem(2.5)} / 2)`,
                right: `calc(-${toRem(2.5)} / 2)`,
            }
        },
        "& input": {
            display: "none",
        },
    },
    [PreviewSize]: {
        width: SIZING.scale2950,
        height: SIZING.scale2950,
        [`.${FileDropzoneInputSmall} &`]: {
            width: SIZING.scale1600,
            height: SIZING.scale1600
        }
    },
    [Preview]: {
        userSelect: "none",
        [`& .${PreviewImg}`]: {
            margin: "-1px",
            objectFit: "cover",
            visibility: "hidden",
            position: "relative",
            backgroundColor: COLORS.WHITE
        },
        [`& .${PreviewImgVisible}`]: {
            visibility: "visible",
        },
        "& svg": {
            "& g, & path": {
                fill: COLORS.WHITE,
            },
        },
        [`& .${PreviewContainer}`]: {
            position: "relative",
            border: `1px solid ${COLORS.FILE_PREVIEW_BORDER_COLOR}`,
            boxSizing: "border-box",
            borderRadius: SIZING.scale200,
            overflow: "hidden",
            "&.error": {
                opacity: 0.4,
            },
            "&.pdf": {
                backgroundColor: COLORS.PDF_ICON,
            },
            "&.other": {
                backgroundColor: COLORS.UNKNOWN_FILE_ICON,
            },
            "&.image": {
                backgroundColor: COLORS.IMAGE_ICON,
            },
            "&.video": {
                backgroundColor: COLORS.VIDEO_ICON,
            },
        },
        [`& .${PreviewIcon}`]: {
            position: "absolute",
            top: 0,
            left: 0,
        },
        [`& .${PreviewLoading}`]: {
            backgroundColor: "rgba(0, 0, 0, .4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        [`& .${PreviewTextLine}`]: {
            marginTop: SIZING.scale200,
            [`& + .${PreviewTextLine}`]: {
                marginTop: 0,
            },
            [`.${FileDropzoneInputSmall} &`]: {
                display: "none"
            },
            // TODO remove this width and use max size of the preview
            width: SIZING.scale2950,
            display: "flex",
            whiteSpace: "nowrap",
            justifyContent: "center",
            fontSize: SIZING.scale300,
            fontWeight: 600,
            lineHeight: toRem(16.8),

            "& .preview__baseName": {
                overflow: "hidden",
                textOverflow: "ellipsis",
            }
        },
        [`&.${PreviewError} .${PreviewTextLine}`]: {
            color: COLORS.ERROR_TEXT,
        }
    },
    uploadPreview: {
        userSelect: "none",
        position: "relative",
        "& .uploadPreview__actions": {
            position: "absolute",
            top: `-${SIZING.scale120}`,
            right: `-${SIZING.scale120}`,
            "& > *": {
                marginBottom: SIZING.scale100,
            },
        },
    },
    previewAction: {
        padding: 0,
        margin: 0,
        outline: 0,
        backgroundColor: COLORS.WHITE,
        border: `2px solid ${COLORS.WHITE}`,
        borderRadius: "50%",
        width: SIZING.scale550,
        height: SIZING.scale550,
        boxSizing: "content-box",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        "& > span": {
            width: SIZING.scale800,
            height: SIZING.scale800,
        },
        "&.previewAction__remove": {
            "& svg": {
                "& g, & path": {
                    fill: COLORS.ALERTS_ICON,
                },
            },
        },
        "&.previewAction__restart": {
            "& svg": {
                "& g, & path": {
                    fill: COLORS.LINK_BLUE,
                },
            },
        },
    },
    uploadingDialog: {
        width: toRem(500),
        fontWeight: 800,
        fontSize: SIZING.scale350,
        whiteSpace: "pre-wrap",
        "& .uploadingDialog__content": {
            display: "flex",
            "& svg": {
                "& g, & path": {
                    fill: COLORS.WHITE,
                },
            },
        },
        "& .uploadingDialog__text": {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            flexGrow: 1,
            paddingLeft: SIZING.scale300,
            "& div": {
                fontWeight: 600,
            },
        },
    },
    removeAttachmentDialog: {
        width: toRem(500),
        fontWeight: 800,
        fontSize: SIZING.scale350,
        whiteSpace: "pre-wrap",
        "& .removeAttachmentDialog__content": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .removeAttachmentDialog__text": {
            "& div": {
                fontWeight: 600,
            },
        },
    },
    downloadPreview: {
        position: "relative",
        "& a": {
            "&, &:active, &:visited, &:hover": {
                color: COLORS.LINK_BLUE,
            },
        },
        "& .downloadPreview__icon": {
            position: "absolute",
            right: 0,
            top: 0,
            opacity: 0.4,
            zIndex: 2,
            "& g, & path": {
                fill: COLORS.WHITE,
            },
        },
    },
    attachmentsViewEmpty: {
        display: "flex",
        alignItems: "center",
        color: COLORS.LGRAY,
        fontSize: SIZING.scale550,
        fontWeight: 700,
        justifyContent: "center",
        height: "100%",
    },
}));
