import LayoutExtr from 'admin/components/Layout/LayoutExtr';
import { Box } from "@mui/material";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "admin/store/reducerTypes";
import {getRegistrationStep, getRegistrationHeading, getRegistrationSubheading} from "./utils";
import {useHistory} from "react-router";
import React, { useEffect } from "react";
import {setRegistrationToken} from "admin/store/actions";
import { useStyles } from "./styles";


const PatientRegistration = () => {
    const patientRegistration = useSelector((store: AppReduxStore) => store.registration)
    const intl = useIntl()
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()

    useEffect(() => {
        const params = new URLSearchParams(history.location.search)
        const regToken = params.get("regToken")

        if (regToken) {
            dispatch(setRegistrationToken(regToken))
        }
    }, [dispatch, history.location.search])

    const RegistrationStep = getRegistrationStep(patientRegistration)
    const heading = getRegistrationHeading(patientRegistration)
    const subheading = getRegistrationSubheading(patientRegistration)

    return (
        <LayoutExtr heading={intl.formatMessage({ id: `PatientRegistration.heading.${heading}` })}
                    subHeading={intl.formatMessage({ id: `PatientRegistration.subheading.${subheading}` })}>
            <Box className={classes.registrationWrapper}>
                <RegistrationStep />
            </Box>
        </LayoutExtr>
    )
}

export default PatientRegistration