import { makeStyles } from "@mui/styles";
import { COLORS ,SIZING} from "theme";


export const useStyles = makeStyles(() => ({
    datePickerWrapper: {
        position:'relative',
        background: COLORS.WHITE,
        "& .react-datepicker-wrapper":{
            width:'100%',
            background: 'transparent'
        },
        "& .react-datepicker__input-container": {
            color: 'rgba(0, 0, 0, 0.4)',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            cursor: 'text',
            background: 'transparent',
            fontWeight:700,
            lineHeight:'1.1876em',
            width:'100%',
            zIndex:5,
        },
        "& .react-datepicker__triangle":{
            left:"85% !important" 
        },
        "& input":{
            font:'inherit',
            color:COLORS.DBLACK,
            border:0,
            minHeight:SIZING.scale1000,
            textIndent:SIZING.scale400,
            width:'100%',
            background: 'transparent',
        },
        "& .react-datepicker-time__input input":{
            minWidth:'8rem'
        }
    },
    icon:{
        position:'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right:SIZING.scale100,
        height:SIZING.scale800,
        width:SIZING.scale800,
        cursor:"pointer",
        zIndex:7,
        "& path":{
            fill:COLORS.LBLUE
        }
    }
}));