import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, COLORS, SIZING} from "theme";

export const useStyles = makeStyles((theme: Theme) => ({
    root:{
        position:'relative',
        marginRight: SIZING.scale250
    },
        container:{
                height:'150px !important',
                width:'200px',  
                position:'relative',
                overflow:'hidden',
                boxShadow:'1px 0px 8px rgb(0 0 0 / 10%), inset 0px -1px 0px rgb(0 0 0 / 10%)',
                border:`2px solid ${COLORS.WHITE}`,
                background:COLORS.BLACK,
                zIndex:1,
                [theme.breakpoints.down('lg')]: {
                    width:'150px',
                    top:'unset',
                    bottom:0,
                    left:0,
                    right:0,
                    margin:'0 auto',
                    height:'200px !important',
                    borderRadius:BORDER_RADIUS.MEDIUM,
                },
                borderRadius:SIZING.scale400,
                "& video":{
                    width: "100%",
                    height:'100%',
                    objectFit:'cover',
                },
                "& .firstName":{
                    [theme.breakpoints.down('lg')]: {
                        display:'none'
                    },
                }
        },
        participantName:{
            margin:0,
            position:'absolute',
            bottom:0,
            width:'100%',
            textAlign:'center',
            color:COLORS.BLACK,
            background:COLORS.WHITE,
            boxShadow:'0px -4px 8px rgb(0 0 0 / 15%)',
            fontSize:'18px',
            fontWeight:800,
        },
        participantNameFull:{
            margin:0,
            position:'absolute',
            bottom:0,
            top:0,
            width:'100%',
            height:'100%',
            alignItems:'center',
            justifyContent:'center',
            display:'flex',
            color:COLORS.WHITE,
            background:COLORS.BLACK,
            fontSize:'18px',
            fontWeight:800
        },
        camDisabled:{
            width:'100%',
            height:'100%',
            background:'rgba(171, 32, 31, 1)',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            "&>svg":{
                fontSize:'4rem'
            }
        },
        pinIcon:{
            position:'absolute',
            top: SIZING.scale900,
            right:`-${SIZING.scale250}`,
            height: SIZING.scale750,
            width: SIZING.scale750,
            background: COLORS.LIGHT_BLUE,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            borderRadius:'50%',
            cursor:'pointer',
            zIndex:9,
            "& svg g":{
                fill: COLORS.WHITE
            }
        },
        closeIcon:{
            position:'absolute',
            top: '0rem',
            right:`-${SIZING.scale250}`,
            height: SIZING.scale750,
            width: SIZING.scale750,
            background: COLORS.PRIORITY_BG,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            borderRadius:'50%',
            cursor:'pointer',
            zIndex:9,
            "& path":{
                fill: COLORS.WHITE
            },
            "& svg g":{
                fill: COLORS.WHITE
            }
        }
}));