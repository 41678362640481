import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    DialogContent,
    Button,
    FormHelperText,
    Select,
    MenuItem,
    Grid,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { MODAL_TYPES, CONTACT_US_CUSTOMER_TYPE } from "constant";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { setActiveModal } from "store/actions";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import Modal from "components/Modal/Modal";
import { AppReduxStore } from "store/reducerTypes";
import { useStyles } from "./styles";
import Spinner from "common/components/Spinner/Spinner";
import PhoneMask from "common/components/PhoneMask";
import FormField, { ErrorHelpText } from "common/components/FormField/FormField";
import { ContactUs, ContactUsCustomerType } from "store/reducerTypes";
import { sendContactUsForm } from "store/actions/contactUs";

const Options = Object.values(CONTACT_US_CUSTOMER_TYPE);

type FormErrors = Partial<Record<keyof ContactUs, string | undefined>>;
type Form = Partial<ContactUs>;

const ContactUsModal = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const intl = useIntl();
    const onClose = () => dispatch(setActiveModal(""));
    const user = useSelector((store: AppReduxStore) => store.user);
    const { activeModal } = useSelector((store: AppReduxStore) => store.modal);
    const { loading, successMessage } = useSelector(
        (store: AppReduxStore) => store.contactUs
    );

    // we are not relying on this replace for xss protection, only for visual text.
    // xss protection comes from react as we are **not** using dangerouslySetInnerHTML
    const cleanSuccessMessage = successMessage
        ? successMessage.replace(/<[^>]*>/g, "")
        : null;

    const [form, setForm] = useState<Form>({});

    const [errors, setErrors] = useState<FormErrors>({});

    const defaultFirstName = user.firstName;
    const defaultLastName = user.lastName;
    const defaultEmail = user.currentProfileRoleJoin?.providerProfile?.email;
    const defaultMobilePhone =
        user.currentProfileRoleJoin?.providerProfile?.mobilePhone;
    const defaultPrimaryPhone =
        user.currentProfileRoleJoin?.providerProfile?.primaryPhone;
    const defaultPhone = defaultMobilePhone || defaultPrimaryPhone;

    useEffect(() => {
        if (activeModal === MODAL_TYPES.PROVIDER.CONTACT) {
            setForm({
                firstName: defaultFirstName,
                lastName: defaultLastName,
                email: defaultEmail,
                phone: defaultPhone,
                customerType: "",
            });
            setErrors({});
        }
    }, [
        activeModal,
        defaultFirstName,
        defaultLastName,
        defaultEmail,
        defaultPhone,
    ]);

    function setFormValue<Key extends keyof Form>(
        key: Key,
        value: ContactUs[Key]
    ) {
        setForm((form) => ({ ...form, [key]: value }));
        setErrors((errors) => ({ ...errors, [key]: undefined }));
    }

    function validateField<Key extends keyof Form>(key: Key) {
        if (!form[key]) {
            return "FormFieldValidation.Required";
        }
    }

    const validateForm = () => {
        const currentErrors: FormErrors = {
            firstName: validateField("firstName"),
            lastName: validateField("lastName"),
            email: validateField("email"),
            phone: validateField("phone"),
            customerType: validateField("customerType"),
            message: validateField("message"),
        };

        setErrors(currentErrors);

        return Object.values(currentErrors).find((error) => Boolean(error));
    };

    const handleSubmit = () => {
        const hasErrors = validateForm();
        if (!hasErrors) {
            dispatch(
                sendContactUsForm({
                    ...(form as ContactUs),
                    consentToProcessMessage: intl.formatMessage({
                        id: "ContactUs.consentToProcessMessage",
                    }),
                    consentToCommunicateMessage: intl.formatMessage({
                        id: "ContactUs.consentToCommunicateMessage",
                    }),
                })
            );
        }
    };

    return (
        <Modal modalWidth="860px" id={MODAL_TYPES.PROVIDER.CONTACT}>
            <Box className={classes.titleWrapper}>
                <CustomTypography variant="modalHeader">
                    <FormattedMessage id="ConatctUs.title" />
                </CustomTypography>
                <Close
                    className={classes.close}
                    onClick={onClose}
                    id="QA_learning_center_close_button"
                />
            </Box>
            <DialogContent className={classes.content}>
                <Grid container spacing={3}>
                    {loading && (
                        <Grid item xs={12} className={classes.spinnerWrapper}>
                            <Spinner />
                        </Grid>
                    )}
                    {cleanSuccessMessage && (
                        <Grid item xs={12}>
                            <CustomTypography variant="contentSubtitle" className={classes.successText}>
                                {cleanSuccessMessage}
                            </CustomTypography>
                        </Grid>
                    )}
                    {!loading && !successMessage && (
                        <>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    <FormattedMessage id="ConatctUs.label.name" />
                                </label>
                                <Box display="flex">
                                    <FormField
                                        className={classes.firstNameInput}
                                        type="text"
                                        errorId={errors.firstName}
                                        value={form.firstName}
                                        onChange={(value) =>
                                            setFormValue("firstName", value)
                                        }
                                        disabled={Boolean(defaultFirstName)}
                                    />
                                    <FormField
                                        type="text"
                                        errorId={errors.lastName}
                                        value={form.lastName}
                                        onChange={(value) =>
                                            setFormValue("lastName", value)
                                        }
                                        disabled={Boolean(defaultLastName)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    <FormattedMessage id="ConatctUs.label.Iam" />
                                </label>
                                <Select
                                    variant="outlined"
                                    error={Boolean(errors.customerType)}
                                    className={classes.input}
                                    value={form.customerType}
                                    onChange={({ target }) =>
                                        setFormValue(
                                            "customerType",
                                            target.value as ContactUsCustomerType
                                        )
                                    }
                                >
                                    {Options.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText
                                    error={Boolean(errors.customerType)}
                                >
                                    <ErrorHelpText
                                        errorId={errors.customerType}
                                    />
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    <FormattedMessage id="ConatctUs.label.Email" />
                                </label>
                                <FormField
                                    type="text"
                                    className={classes.input}
                                    errorId={errors.email}
                                    value={form.email}
                                    onChange={(value) =>
                                        setFormValue("email", value)
                                    }
                                    disabled={Boolean(defaultEmail)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label className={classes.label}>
                                    <FormattedMessage id="ConatctUs.label.Phone" />
                                </label>
                                <FormField
                                    type="text"
                                    className={classes.input}
                                    errorId={errors.phone}
                                    value={form.phone}
                                    onChange={(value) =>
                                        setFormValue("phone", value)
                                    }
                                    inputComponent={PhoneMask}
                                    disabled={Boolean(defaultPhone)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <label className={classes.label}>
                                    <FormattedMessage id="ConatctUs.label.Message" />
                                </label>
                                <FormField
                                    className={classes.input}
                                    multiline
                                    rows={4}
                                    placeholder="Tell us what you’re interested in..."
                                    errorId={errors.message}
                                    value={form.message}
                                    onChange={(value) =>
                                        setFormValue("message", value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className={classes.submitBtn}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
        </Modal>
    );
};

export default ContactUsModal;
