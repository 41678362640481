import { EConsultRequest, EConsultRequestValidation } from "../types";
import { validate } from "common/validation/validation";
import { ValidationRule } from "common/validation/types";

export const shouldCreateNewDraft = (request: EConsultRequest) => {
    return !request.id && isValidDraft(request);
};

export const shouldUpdateDraft = (request: EConsultRequest) => {
    return Boolean(request.id);
};

/**
 * Returns true if the request has the minimum amount of information
 * to justify creating a new draft.
 * For example, we don't create if if the only thing changed was the urgent flag.
 */
const isValidDraft = (request: EConsultRequest) => {
    const checks = [
        areRecipientsValid(request.recipientIds),
        isPatientIdValid(request.patientId),
        areNotesValid(request.notes),
        isSubjectValid(request.subject),
        hasSomeAttachments(request.attachmentIds)
    ];

    const isAnyValidField = checks.find((valid) => valid === true);
    return Boolean(isAnyValidField);
};

const areRecipientsValid = (recipientIds: EConsultRequest["recipientIds"]) => {
    return recipientIds.length > 0;
};

const isPatientIdValid = (patientId: EConsultRequest["patientId"]) => {
    return Boolean(patientId);
};

const areNotesValid = (notes: EConsultRequest["notes"]) => {
    return Boolean(notes);
};

const isSubjectValid = (subject: EConsultRequest["subject"]) => {
    return Boolean(subject);
};

const hasSomeAttachments = (attachmentIds: EConsultRequest["attachmentIds"]) => {
    return attachmentIds.length > 0;
};

const areAttachmentsValid = (attachments: EConsultRequestValidation["attachments"]) => {
    return !attachments.some(a => a.status === "ERROR");
};

const requestChecks: ValidationRule<EConsultRequestValidation>[] = [
    {
        path: ["providers", "recipientIds"],
        errorMessageId: "EConsult.newRequestModal.submit.recipientsError",
        validationFn: (request: EConsultRequestValidation) => areRecipientsValid(request.providers.recipientIds),
    },
    {
        path: ["caseNotes", "subject"],
        errorMessageId: "EConsult.newRequestModal.submit.subjectError",
        validationFn: (request: EConsultRequestValidation) => isSubjectValid(request.caseNotes.subject),
    },
    {
        path: ["caseNotes", "notes"],
        errorMessageId: "EConsult.newRequestModal.submit.notesError",
        validationFn: (request: EConsultRequestValidation) => areNotesValid(request.caseNotes.notes),
    },
    {
        path: ["attachments"],
        errorMessageId: "EConsult.newRequestModal.submit.attachmentsError",
        validationFn: (request: EConsultRequestValidation) => areAttachmentsValid(request.attachments),
    },
];

export const isValidRequest = (request: EConsultRequestValidation) => validate<EConsultRequestValidation>(request, requestChecks);
