import { Box, Checkbox, Button, Typography } from "@mui/material";
import { ADMIN_WEB_CACHE, BULK_UPLOAD_GUIDE } from "admin/constant";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from 'notistack';
import SnackbarContent from '../../SnackBars/SnackbarContent';
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { DropzoneAreaBase, FileObject } from "mui-file-dropzone";
import { call } from "../../../store/api";
import { AppReduxStore } from "admin/store/reducerTypes";
import { ReactComponent as ArrowUpCircle } from "@altais/icon-library/legacy/SVG/Arrow_Up-Circle.svg";
import { ReactComponent as WarningCircle } from "@altais/icon-library/legacy/SVG/Warning-Light_Circle.svg";
import { createSvgIcon } from "../../../../utils";
import BulkUploadInstructions from "admin/components/BulkUploadInstructions/BulkUploadInstructions";
import { getUsersByOrgOrPractice, updateWebAdminCachePractice } from "admin/store/actions";
import moment from "moment";

const ArrowUpCircleIcon = createSvgIcon(ArrowUpCircle);
const WarningCircleIcon = createSvgIcon(WarningCircle);
const { PROVIDER_BULK_UPLOAD_CSV_TEMPLATE } = (window as any).env;

const AddUserBulk = forwardRef((props: any, ref) => {
    const { auth, admin, practice } = useSelector((state: AppReduxStore) => state);
    const { adminWebCachePractice } = admin
    const { handleAddedFile, handleRemovedFile, handleLoading } = props
    const dispatch = useDispatch()
    const classes = useStyles();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFiles] = useState<FileObject[]>([]);
    const [isAutoInvite, setAutoInvite] = useState(true);
    const [isUploadNotSuccess, setUploadNotSuccess] = useState(false);
    const [failedUpload, setFailedUpload] = useState({} as any);

    const displayErrorAlert = (errorMsg?: string, isError: boolean = true) => {
        enqueueSnackbar(null, {
            content: (key) => (
                <div role="alert">
                    <SnackbarContent
                        snackbarKey={key}
                        type={isError ? 'error' : 'success'}
                        message={intl.formatMessage({ id: errorMsg ? errorMsg : 'Error uploading file' })}
                    />
                </div>
            )
        });
    };

    useImperativeHandle(
        ref,
        () => ({
            submitBulkUpload() {
                return handleBulkUpload();
            },
            saveAndContinueLater(index: number) {
                return saveAndContinue(index);
            }
        }),
    )

    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {};
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgName: auth.currentOrg?.name,
            tempFormData: {
                name: admin.configurePracticeName,
                configurePractice: true,
                addUserDetails: null,
                selectedStep: index
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }
    const displaySuccessAlert = () => {
        enqueueSnackbar(null, {
            content: (key) => (
                <div role="alert">
                    <SnackbarContent
                        snackbarKey={key}
                        type={'success'}
                        message={intl.formatMessage({ id: 'File upload successful' })}
                    />
                </div>
            )
        });
    };

    const handleBulkUploadError = () => {
        displayErrorAlert();
        setFiles([]);
        handleRemovedFile();
        handleLoading(false)
    };

    const handleBulkUploadStatus = async (batchID: any, practiceId: any) => {
        displayErrorAlert("In progress...", false)
        const presignedGETUrl = `/admin/v1/users/bulk/upload/status?batchId=${batchID}&practiceId=${practiceId}`;
        await call("GET", presignedGETUrl).then((payload) => {
            if (payload) {
                if (payload.totalBatches === payload.processedBatches) {
                    setUploadNotSuccess(true)
                    setFailedUpload(payload)
                } else {
                    setTimeout(() => {
                        handleBulkUploadStatus(batchID, practiceId)
                    }, 3000)
                    return;
                }
            } else {
                setUploadNotSuccess(false)
            }
            handleLoading(false)
            setFiles([]);
            displaySuccessAlert();
            handleRemovedFile()
            dispatch(getUsersByOrgOrPractice())
        }).catch(err => {
            if (err?.status === 404) {
                setTimeout(() => {
                    handleBulkUploadStatus(batchID, practiceId)
                }, 3000)
            }
            else {
                handleBulkUploadError()
            }
        });
    }

    const handleBulkUpload = async () => {
        displayErrorAlert("In progress...", false)
        const ehrName = practice?.sPractice?.ehrSourceSystemInfo?.name
        const isEpicOrInteropionEhr = (ehrName?.toLowerCase() === "epic" || ehrName?.toLowerCase() === "interopion")
        if (isEpicOrInteropionEhr) {
            uploadEpicOrInteropionUserFiles()
        }
        else {
            uploadUserFiles()
        }

    };
    const uploadEpicOrInteropionUserFiles = async () => {
        handleLoading(true);
        if (admin.configurePracticeId || auth.currentPractice.id) {
            const presignedPutUrl =
                "/admin/v1/practice/bulk-upload/csv/s3-presigned-put-url?practiceId=" +
                (admin.configurePracticeId || auth.currentPractice.id) +
                "&type=USERS";
            const s3Response = await call("GET", presignedPutUrl, {})
                .catch(error => {
                    handleBulkUploadError();
                });
            if (s3Response && s3Response.s3PresignedPutObjectUrl) {
                const s3PresignedPutUrl: string = s3Response.s3PresignedPutObjectUrl;
                const dateValue: string = s3Response.dateValue;
                const reader = new FileReader();
                reader.onload = async function (evt) {
                    if (evt.target) {
                        if (evt.target.result) {
                            await fetch(s3PresignedPutUrl, {
                                method: 'PUT',
                                body: evt.target.result,
                                headers: {
                                    'Content-Type': 'text/csv; charset=UTF-8'
                                }
                            }).then(async (response) => {
                                displaySuccessAlert();
                                handleRemovedFile()
                                handleLoading(false)
                            }).catch(error => {
                                handleBulkUploadError();
                            });
                        }
                    }
                };
                reader.readAsBinaryString(files[0].file);
                handleLoading(true);
                //Meta file 
                const presignedPutUrlMeta =
                    "/admin/v1/practice/bulk-upload/meta/s3-presigned-put-url?practiceId=" +
                    (admin.configurePracticeId || auth.currentPractice.id) +
                    "&type=USERS&dateValue=" +
                    dateValue;
                const s3ResponseMeta = await call("GET", presignedPutUrlMeta, {})
                    .catch(error => {
                        handleBulkUploadError();
                    });
                if (s3ResponseMeta && s3ResponseMeta.s3PresignedPutObjectUrl) {
                    const s3PresignedPutUrlMeta: string = s3ResponseMeta.s3PresignedPutObjectUrl;
                    const readerMeta = new FileReader();
                    readerMeta.onload = async function (evt) {
                        if (evt.target) {
                            if (evt.target.result) {
                                await fetch(s3PresignedPutUrlMeta, {
                                    method: 'PUT',
                                    body: evt.target.result,
                                    headers: {
                                        'Content-Type': 'application/octet-stream; charset=UTF-8'
                                    }
                                }).then(async (response) => {
                                    displaySuccessAlert();
                                    handleLoading(false);
                                }).catch(error => {
                                    handleBulkUploadError();
                                });
                            }
                        }
                    };
                    const objMeta = {
                        "profileKey": auth.currentProfileRoleJoinInfo?.profileId,
                        "practiceKey": (admin.configurePracticeId || auth.currentPractice.id),
                        "organizationkey": auth.currentPractice?.organizationId,
                        "isAutoInvite": true,
                        "timeStamp": `${moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS[Z]")}`,
                        "csvFileName": "practitioner.csv"
                    }
                    const text = JSON.stringify(objMeta, null, 2)
                    // Create file programmatically
                    const myBlob = new Blob([text], { type: 'application/json' });
                    const metaFile = new File([myBlob], "fhir-bulk-data.meta");
                    readerMeta.readAsBinaryString(metaFile);
                }
            }
        }
        else {
            handleBulkUploadError();
        }
    }
    const uploadUserFiles = async () => {
        handleLoading(true);
        if (admin.configurePracticeId || auth.currentPractice.id) {
            const presignedPutUrl =
                "/admin/v1/users/bulk-upload/s3-presigned-put-url?practiceId=" +
                (admin.configurePracticeId || auth.currentPractice.id);

            const s3Response = await call("GET", presignedPutUrl, {})
                .catch(error => {
                    handleBulkUploadError();
                });

            if (s3Response && s3Response.s3PresignedPutObjectUrl) {
                const s3PresignedPutUrl: string = s3Response.s3PresignedPutObjectUrl;

                const reader = new FileReader();
                reader.onload = async function (evt) {
                    if (evt.target) {
                        if (evt.target.result) {
                            // using fetch since call() needs some redesign to use a dynamic base url
                            await fetch(s3PresignedPutUrl, {
                                method: 'PUT',
                                body: evt.target.result,
                                headers: {
                                    'Content-Type': 'text/csv; charset=UTF-8'
                                }
                            })
                                .then(async (response) => {
                                    let s3MqTransferUrl = "/admin/v1/users/bulk-upload/activemq-msg?practiceId=" + (admin.configurePracticeId || auth.currentPractice.id);
                                    if (isAutoInvite) {
                                        s3MqTransferUrl = "/admin/v1/users/bulk-upload/activemq-msg?isAutoInvite=true&practiceId=" + (admin.configurePracticeId || auth.currentPractice.id);
                                    }
                                    await call("PUT", s3MqTransferUrl, { "keyName": s3Response.objectKey })
                                        .then(response => {
                                            handleBulkUploadStatus(response, (admin.configurePracticeId || auth.currentPractice.id))
                                        })
                                        .catch(error => {
                                            handleBulkUploadError();
                                        });
                                })
                                .catch(error => {
                                    handleBulkUploadError();
                                });
                        }
                    }
                };

                reader.readAsBinaryString(files[0].file);
            }
        } else {
            handleBulkUploadError();
        }
    }

    const handleAddFile = (attachedFiles: any[]) => {
        setFiles(attachedFiles);
        handleAddedFile()
    };

    const handleRemoveFile = (deletedFileObject: FileObject, index: number) => {
        files.splice(index, 1);
        setFiles(files);
        handleRemovedFile()
    };
    const onAutoInviteCheck = (e: any) => {
        setAutoInvite(e.target.checked)
    }
    const handleTryAgain = () => {
        setUploadNotSuccess(false)
        handleRemovedFile()
        setFiles([]);
        handleLoading(false)
    }

    return (
        <>
            <BulkUploadInstructions downloadTemplateLink={PROVIDER_BULK_UPLOAD_CSV_TEMPLATE} webInstructionsLink={BULK_UPLOAD_GUIDE} page="healthcare providers" ehrSourceSystemName={practice?.sPractice?.ehrSourceSystemInfo?.name} />
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="h4" className={classes.bulkUploadHeading}>
                        <FormattedMessage id={"AddUser.BulkUpload"} />
                    </Typography>
                </Box>
                <Box className={classes.autoInviteText}>
                    <Checkbox color="primary" disableRipple disableFocusRipple checked={isAutoInvite} onChange={onAutoInviteCheck} id="chkAddUserAutoInvite" />
                    {intl.formatMessage({ id: "AddUserForm.SendAutoinvite.Text" })}
                </Box>
            </Box>
            {
                !isUploadNotSuccess ?
                    <DropzoneAreaBase Icon={ArrowUpCircleIcon as any}
                        acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                        onAdd={handleAddFile}
                        onDelete={handleRemoveFile}
                        filesLimit={1}
                        showAlerts={true}
                        fileObjects={files}
                        dropzoneClass={classes.dropzoneClass}
                        dropzoneText="Add file or drop files to upload"
                        useChipsForPreview={true}
                        previewGridClasses={{ container: classes.previewGridClasses }}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        previewText=""
                    /> :
                    <Box className={classes.dropzoneFailClass}>
                        <Box className={classes.failContent}>
                            <WarningCircleIcon className={classes.warningIcon}></WarningCircleIcon>
                            Upload Status
                        </Box>
                        <Box className={classes.failFirstContent}>
                            <Box className={classes.successText}><div className={classes.textTitle}>Successful:</div> <div> {failedUpload?.insertCount + failedUpload?.updateCount} rows</div></Box>
                            <Box className={classes.duplicatesText}><div className={classes.textTitle}> New:</div> <div> {failedUpload?.insertCount} rows</div></Box>
                            <Box className={classes.duplicatesText}><div className={classes.textTitle}> Duplicates:</div> <div> {failedUpload?.updateCount} rows</div></Box>
                            <Box className={classes.failureText}><div className={classes.textTitle}> Failures:</div> <div>{failedUpload?.failureCount}  rows</div></Box>
                        </Box>
                        <Button color="primary" variant="outlined" onClick={handleTryAgain} style={{ marginTop: '15px' }}>Clear</Button>
                    </Box>
            }
        </>
    )

})
export default AddUserBulk