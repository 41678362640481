import React, { useState } from "react";
import { Box, Button } from "@mui/material";

import SideNav from "./SideNav/SideNav";
import ChangePassword from "./ChangePassword/ChangePassword";
import SecretQuestions from "./SecretQuestions/SecretQuestions";
import { APP_AUTH_TYPE, appMode } from "authentication/config";
import { FormattedMessage } from "react-intl";
import { resetPassword } from "authentication/azure/azure-auth-utils";

const Settings = () => {

    const [activeTab, setActiveTab] = useState(0);

    const setCurrentTab = async (tabNo: any) => {
        setActiveTab(tabNo)
    }

    return (
        (appMode === APP_AUTH_TYPE.AZURE) ?
            <Box style={{ display: 'block' }}>
                <Button onClick={() => resetPassword()} variant="contained" color="error" type="submit" style={{ margin: '0.5rem 0.5rem 0.5rem auto' }}>
                    <FormattedMessage id={"ChangePassword.updatePassword"} />
                </Button>
            </Box> : 
        <Box position="relative" display="flex" zIndex={1}>
            <SideNav setCurrentTab={setCurrentTab} activeTab={activeTab} />
            {(activeTab === 0) ? 
                <ChangePassword />
                :
                <SecretQuestions />
            }
        
        </Box>
    )
}

export default Settings
