import {Paper, InputBase, IconButton, Button, Switch, FormControlLabel, FormControl} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {ReactComponent as Add} from "icon-library/SVG/Plus.svg";
import { createSvgIcon } from "utils";
import {COLORS} from "admin/theme";
import { useStyles } from "./styles";

const SearchForm = (props: any) =>{
    const AddIcon = createSvgIcon(Add);
    const {handleCreatNewForm, handleSearchChange} = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
        <Paper
          component="form"
          elevation={2}
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, marginRight: "4%", borderRadius: "50px" }}
        >
            <IconButton type="submit" style={{color: "#095d8f"}} sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Form by Titles"
                inputProps={{ 'aria-label': 'Search Form by Titles' }}
                autoFocus={true}
                onChange={handleSearchChange}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                }}
            />
        </Paper>
        <div>
        <FormControl>
            <FormControlLabel
            value=""
            control={<Switch color="primary" />}
            label="Show Hidden Forms"
            labelPlacement="start"
            className={classes.switch}
            disabled={true}
            />
        </FormControl>
        </div>
        <div className={classes.rightSide}>
            <Button onClick={handleCreatNewForm} variant="contained" color="primary" startIcon={<AddIcon fill={COLORS.WHITE} />}>Add a Form</Button>
        </div>
        </div>
    );
}

export default SearchForm;