import React, { useState } from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { ReactComponent as CrossIcon } from "icon-library/SVG/Cross_Circle.svg";
import classNames from "classnames";
import FilterInput from "./filter/filter-input";
import FilterItem from "./filter/filter-item";
import { useStyles } from "./styles";
import { Filter, FilterTypeSet, GetFilterTypeTranslation, FilterAutocompleteProps } from "./types";

export type FilteredSearchInputProps = {
    defaultFilterType?: string;
    error?: boolean;
    filterTypes: FilterTypeSet;
    getFilterTypeTranslation: GetFilterTypeTranslation;
    loadingFilterTypes?: boolean;
    /** Change happened in the collection of filters */
    onFiltersChanged: (filters: Filter[]) => void;
    placeholder?: string;
} & FilterAutocompleteProps;

export const FilteredSearchInput: React.FC<FilteredSearchInputProps> = ({
    autocompleteValues,
    defaultFilterType,
    error,
    filterTypes,
    getFilterTypeTranslation,
    loadingAutocomplete,
    loadingFilterTypes,
    onFilterChanged,
    onFiltersChanged,
    placeholder,
    minSearchLength
}) => {
    const [filters, setFilters] = useState<Filter[]>([]);
    const intl = useIntl();
    const classes = useStyles();

    const updateFilters = (filters: Filter[]): void => {
        setFilters(filters);
        onFiltersChanged(filters);
    };

    const onFilterCreate = (filter: Filter): void => {
        if (filters.findIndex((item) => item.text === filter.text && item.type === filter.type) === -1) {
            const newFilters = [...filters];
            newFilters.push(filter);
            updateFilters(newFilters);
        }
    };
    const onFilterDelete = (filterId: string): void => {
        const newFilters = filters.filter((item) => item.id !== filterId);
        updateFilters(newFilters);
    };
    const onFilterReset = () => {
        if (window.confirm(intl.formatMessage({ id: "FilteredSearchInput.clearAllWarning" }))) {
            updateFilters([]);
        }
    };

    return (
        <Box className={classes.filterSection}>
            <div className="searchContainer">
                <FilterInput
                    idPrefix="QA_filter_section_input"
                    autocompleteValues={autocompleteValues}
                    defaultFilterType={defaultFilterType}
                    error={error}
                    filterTypes={filterTypes}
                    getFilterTypeTranslation={getFilterTypeTranslation}
                    loadingAutocomplete={loadingAutocomplete}
                    onFilterCreated={onFilterCreate}
                    onFilterChanged={onFilterChanged}
                    placeholder={placeholder}
                    minSearchLength={minSearchLength}
                    loadingFilterTypes={loadingFilterTypes}
                />
                <CrossIcon
                    id="QA_filter_section_reset"
                    className={classNames("resetSearch", {
                        hidden: !filters.length,
                    })}
                    onClick={onFilterReset}
                />
            </div>
            <div className="filterContainer">
                {filters.map((filter, index) => (
                    <FilterItem
                        idPrefix={`QA_filter_section_item_${index}`}
                        key={filter.id}
                        filter={filter}
                        onCrossClicked={onFilterDelete}
                        getFilterTypeTranslation={getFilterTypeTranslation}
                    />
                ))}
            </div>
        </Box>
    );
};
