import React from "react";
import { Paper, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useStyles } from "./styles";
import { FormattedMessage } from "react-intl";
import { IFormInput } from "admin/views/viewsTypes";

type Props = {
  currentForm: IFormInput;
};

const PHQ9: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();
 
  return (
    <div className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
            <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.TentativeDepressionDiagnosis"} />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id={"RulesAndLogic.PHQ9.Step"} values={{count: 1 }} /> - <FormattedMessage id={"RulesAndLogic.PHQ9.QuestionRange"} values={{start: 1, end: 2 }} />:
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              <FormattedMessage id={"RulesAndLogic.PHQ9.Body1"} />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id={"RulesAndLogic.PHQ9.Step"} values={{count: 2 }} /> - <FormattedMessage id={"RulesAndLogic.PHQ9.QuestionRange"} values={{start: 1, end: 9 }} />:
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              <FormattedMessage id={"RulesAndLogic.PHQ9.Body2"} />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id={"RulesAndLogic.PHQ9.Step"} values={{count: 3 }} /> - <FormattedMessage id={"RulesAndLogic.PHQ9.Question"} values={{count: 10 }} />:
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              <FormattedMessage id={"RulesAndLogic.PHQ9.Body3"} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.ScoringRules"} />
          </Typography>
          <TableContainer className={classes.scoringTable} component={Paper}>
          <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale1"} />
              </TableCell>
              <TableCell>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale2"} />
              </TableCell>
              <TableCell>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale3"} />
              </TableCell>
              <TableCell>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale4"} />
              </TableCell>
              <TableCell>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale5"} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="h6">
                  <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale6"} />
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale7"} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale8"} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale9"} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale10"} />
                </TableCell>
              </TableRow>
          </TableBody>
          </Table>
          </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              <FormattedMessage id={"RulesAndLogic.Label.TreatmentMonitoringSelection"} />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id={"RulesAndLogic.PHQ9.Step"} values={{count: 1 }} />:
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
            <FormattedMessage id={"RulesAndLogic.PHQ9.Body4"} />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id={"RulesAndLogic.PHQ9.Step"} values={{count: 2 }} /> - <FormattedMessage id={"RulesAndLogic.PHQ9.QuestionRange"} values={{start: 1, end: 9 }} />:
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              <FormattedMessage id={"RulesAndLogic.PHQ9.Body5"} />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id={"RulesAndLogic.PHQ9.Step"} values={{count: 3 }} />:
              </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              <FormattedMessage id={"RulesAndLogic.PHQ9.Body6"} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.ScoringRules"} />
          </Typography>
          <TableContainer className={classes.scoringTable} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "20%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale11"} />
            </TableCell>
            <TableCell style={{ width: "20%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale12"} />
            </TableCell>
            <TableCell style={{ width: "20%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale13"} />
            </TableCell>
            <TableCell style={{ width: "20%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale14"} />
            </TableCell>
            <TableCell style={{ width: "20%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale15"} />
            </TableCell>
            <TableCell style={{ width: "20%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale16"} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" style={{ width: "16.66%" }}>
                <Typography variant="h6">
                  <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale17"} />
                </Typography>
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale18"} />
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale19"} />
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
              <ul style={{ paddingInlineStart: "20px" }}>
                <li><FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale20.Li1"} /></li>
                <li><FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale20.Li2"} /></li>
                <li><FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale20.Li3"} /></li>
              </ul>
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale21"} />
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale22"} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" style={{ width: "16.66%" }}>
                <Typography variant="h6">
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale23"} />
                </Typography>
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale24"} />
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale25"} />
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
              <ul style={{ paddingInlineStart: "20px" }}>
                <li><FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale26.Li1"} /></li>
                <li><FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale26.Li2"} /></li>
              </ul>
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
              <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale27"} />
              </TableCell>
              <TableCell style={{ width: "16.66%" }}>
                <FormattedMessage id={"RulesAndLogic.PHQ9.ScoreScale28"} />
              </TableCell>
            </TableRow>
        </TableBody>
        </Table>
          </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p" gutterBottom>
              <FormattedMessage id={"RulesAndLogic.PHQ9.Body7"} />
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              <FormattedMessage id={"RulesAndLogic.PHQ9.Body8"} />
            </Typography>
        </Grid>
        </Grid>
    </div>
  );
}

export default PHQ9