import React from "react";
import { Box, TextField, FormHelperText, FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as WarningIcon } from "admin/assets/common/ExclamationWarning.svg";

export default function Input(props: any) {
    const useStyles = makeStyles(() => ({
        labelCtrl: {
            fontWeight: 600,
            color: '#393948',
            fontSize: '14px',
            textAlign: 'left',
            paddingBottom: '4px'
        },
        error: {
            color: '#E32B29',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '5px'
        },
        exclamationIcon: {
            marginTop: '3px'
        },
        multilineTextFieldPadding: {
            "&>div.MuiInputBase-multiline": {
                padding: "0px",
            },
            "& textarea": {
                height: "90px",
                padding: "8px 12px"
            }
        },
        lblReadOnlyValue: {
            color: "#6E6E79",
            fontSize: '16px',
            wordBreak: 'break-all',
        },
        readOnlyLabelWrapper: {
            border: '1px solid #909098',
            background: '#FFFFFF',
            borderRadius: '4px',
            padding: "4px 8px",
            minHeight: '32px'
        }

    }));

    const classes = useStyles();
    const { name, label, value, placeholder, error = null, onChange, readOnly, maxLength, inputComponent, disabled, multiline = false, rows, readOnlyLabel = false, onBlur = null, type = 'text' } = props;
    return (
        <FormControl hiddenLabel>
            <label className={classes.labelCtrl}>{label}</label>
            {readOnlyLabel ? <Box className={classes.readOnlyLabelWrapper}>
                <label className={classes.lblReadOnlyValue}>{value}</label>
            </Box> :
                <TextField
                    variant={disabled ? "filled" : "outlined"}
                    id={`txt${name}`}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    multiline={multiline}
                    minRows={rows ?? 1}
                    maxRows={rows ?? 1}
                    type={type}
                    inputProps={{
                        maxLength: maxLength ?? 255,
                    }}
                    InputProps={{
                        readOnly: readOnly,
                        disabled: disabled,
                        inputComponent,
                    }}
                    className={classes.multilineTextFieldPadding}
                    onClick={(e: any) =>
                        inputComponent && (!value || (value && value.replaceAll('-', '').trim() === "")) && e.target.setSelectionRange(0, 0)
                    }
                    {...(error && { error: true, helperText: '' })}
                />}
            {error && <Box display="inline-flex">
                <WarningIcon className={classes.exclamationIcon} />
                <FormHelperText className={classes.error}>{error}</FormHelperText>
            </Box>}
        </FormControl>
    )
}
