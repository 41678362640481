import { makeStyles } from "@mui/styles";
import { COLORS, LAYERS, SIZING, TYPOGRAPHY, toRem } from "theme";

export const useStyles = makeStyles(() => ({
        providerList: {
            borderSpacing: 0,
            margin: 0,
            padding: 0,
            width: "100%",
            maxWidth: "100%",
            "& tr:not(:first-child) > td:not(:first-child)": {
                borderTop: `solid 1px ${COLORS.DGREY}`,
            },
            "& tr.selected > td": {
                borderBottom: `solid 1px ${COLORS.DGREY}`,
                borderTop: `solid 1px ${COLORS.DGREY}`,
            },
            "& tr.selected + tr:not(:first-child) > td": {
                borderTop: `unset`,
            },
        },
        providerItem: {
            "&.selected": {
                backgroundColor: COLORS.MODAL_CONTENT_LBLUE_BG
            },
            "& td": {
                height: "100%",
                paddingTop: SIZING.scale400,
                paddingBottom: SIZING.scale400,
            },
            "& td > div": {
                height: "100%",
            },
            "& .avatarWrapper": {
                boxSizing: "content-box",
            },
            "& .expand": {
                // table hack to prevent cells for overgrowing,
                // with maxWidth 1px and width 100% it will take as much space as it can, without overflow
                maxWidth: "1px",
                width: "100%",
            },
            "& .avatar": {
                display: "flex",
                overflow: "hidden",
                width: SIZING.scale800,
                height: SIZING.scale800,
                borderRadius: "50%",
                backgroundColor: COLORS.E_CONSULT_AVATAR_BACKGROUND,
                alignItems: "baseline",
                justifyContent: "center",
                marginRight: SIZING.scale200,
                "& svg": {
                    opacity: 0.4,
                    width: SIZING.scale1000,
                    height: SIZING.scale1000,
                    flexShrink: 0,
                    marginTop: `-${SIZING.scale25}`,
                    "& g, & path": {
                        fill: COLORS.WHITE,
                    },
                },
            },
            "& .nameTitleWrapper": {
                verticalAlign: "text-bottom",
                flexGrow: 1,
                color: COLORS.LINK_BLUE,
                cursor: "default",
                "&.isClickable": {
                    cursor: 'pointer'
                },
                "& p": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
            "& .circleRemoveWrapper": {
                display: "flex",
                paddingRight: SIZING.scale250,
                position: "relative",
                width: SIZING.scale550,
                boxSizing: "content-box",
                justifyContent: "center",
                "& > svg": {
                    opacity: 1,
                    transition: "opacity 0.3s ease 0.1s",
                },
                "& .removeWrapper": {
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    transition: "opacity 0.1s ease",
                    opacity: 0,
                    "& svg": {
                        cursor: "pointer",
                    },
                    "& path, & g": {
                        fill: COLORS.RED,
                    },
                },
            },
            "&.hasRemoveButton:hover .circleRemoveWrapper": {
                "& > svg": {
                    opacity: 0,
                    transition: "opacity 0.1s ease",
                },
                "& .removeWrapper": {
                    opacity: 1,
                    transition: "opacity 0.3s ease 0.1s",
                },
            },
            "& .specialtiesWrapper": {
                lineHeight: TYPOGRAPHY.small.lineHeight,
                minWidth: "250px",
                paddingRight: SIZING.scale100,
                "& span": {
                    verticalAlign: "middle",
                },
            },
            "& .providerItem__checkbox": {
                padding: 0,
                paddingRight: SIZING.scale200,
                "&.MuiCheckbox-root:hover": {
                    backgroundColor: "unset",
                },
                "& g, & path": {
                    fill: COLORS.LINK_BLUE,
                },
                "&.Mui-disabled": {
                    "& g, & path": {
                        fill: COLORS.LGRAY,
                    },
                }
            },
        },
        providerItemDetails: {
            backgroundColor: COLORS.LYELLOW,
            borderRadius: SIZING.scale400,
            filter: `drop-shadow(0 0 ${SIZING.scale100} rgba(0,0,0,.2))`,
            maxWidth: SIZING.ECONSULT_SPECIALIST_DETAILS_TOOLTIP_WIDTH,
            padding: SIZING.scale400,
            zIndex: LAYERS.MODAL,
            maxHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            "& .arrow": {
                left: `-${SIZING.scale200}`,
                position: 'absolute',
                margin: `${SIZING.scale300} 0`,
                '&::before': {
                    backgroundColor: COLORS.LYELLOW,
                    content: '""',
                    display: 'block',
                    height: SIZING.scale500,
                    margin: 'auto',
                    transform: 'rotate(45deg)',
                    width: SIZING.scale500,
                    position: "relative",
                    top: "-0.825rem"
                },
            },
            "& .infoHeader": {
                color: COLORS.LINK_BLUE,
                display: 'flex',
                fontSize: SIZING.scale450,
                fontWeight: 'bold',
                justifyContent: 'space-between',
                marginBottom: SIZING.scale400,
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                "& .providerName": {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
                "& .rateInfo": {
                    marginLeft: SIZING.scale400
                }
            },
            "& .infoContent": {
                fontSize: SIZING.scale350,
                overflowY: "auto",
                marginRight: `-${SIZING.scale400}`,
                paddingRight: SIZING.scale400
            },
            "& .infoFooter": {
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: SIZING.scale400,
                alignItems: 'center',
                "& button": {
                    backgroundColor: COLORS.WHITE,
                    color: COLORS.PRIMARY_BTN_TEXT,
                    fontSize: SIZING.scale400,
                    height: SIZING.scale750,
                    "&.remove": {
                        color: COLORS.ALERTS_ICON
                    }
                },
                "& svg": {
                    width: SIZING.scale600,
                    "&.add": {
                        visibility: 'hidden'
                    }
                }
            },
            "& .gridBody": {
                display: 'grid',
                gridTemplateColumns: 'minmax(120px, auto) auto'
            },
            "& .gridRow_content": {
                fontWeight: 600,
                lineHeight: toRem(19.6),
                marginBottom: SIZING.scale200,
                "& ul": {
                    margin: 0,
                    paddingInlineStart: 0
                }
            },
            "& .gridRow_label": {
                fontWeight: 'bold',
                paddingRight: SIZING.scale400
            },
            "& .separator": {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                gridColumnStart: 1,
                gridColumnEnd: 3,
                margin: `${SIZING.scale200} 0 ${SIZING.scale400} 0`
            },
            "& .specialties": {
                fontWeight: '600'
            },
            "& .subspecialties, & .locationAddress": {
                fontWeight: 400
            },
            "& .location:not(:last-child)": {
                marginBottom: SIZING.scale400
            },
            "& .loadingWrapper": {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            "&.loading .hideOnLoading": {
                visibility: "hidden"
            }
        }
}));