import React, { useState, useCallback, useEffect } from "react";
import MultiSelectPopoverInput from "components/MultiSelectPopoverInput/MultiSelectPopoverInput";
import MultiSelectItem from "components/MultiSelectPopoverInput/MultiSelectItem";
import { debounce } from "underscore";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { AppReduxStore } from "store/reducerTypes";
import { useDispatch, useSelector } from "react-redux";
import { searchRecipientsBySearchString } from "store/actions";
import { ListItem } from "components/MultiSelectPopoverInput/types";
import { setError } from "store/actions/error"
import { useStyles } from "./style";
import { Send } from "assets";
import CustomAccordion from "common/components/CustomAccordion/CustomAccordion";
import { inviteColleagueToEvisit } from "store/actions/meeting";
import { useReduxDispatch } from "store/utils";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";

const InviteColleague = () => {

    const [searchText, updateSearchText] = useState('')
    const [pinnedItems, setPinnedItems] = useState<ListItem[]>([]);
    const [searchLoader, updateSearchLoader] = useState<Boolean>(false);
    const [accordionStatus, updateAccordionStatus] = useState<Boolean>(false);
    const [inviteLoader, updateInviteLoader] = useState<Boolean>(false);
    const [searchedRecipients, updateSearchedRecipients] = useState<Array<ListItem>>([]);
    const { username } = useSelector(((state: AppReduxStore) => state.user))
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const customDispatch = useReduxDispatch()
    const intl = useIntl()
    const classes = useStyles()

    const search = useCallback(debounce((searchStr: string,username :string) => {
        updateSearchLoader(true)
        searchRecipientsBySearchString(searchStr, username, 'CARETEAM').then((data: any) => {
            updateSearchLoader(false)
            updateSearchedRecipients(data)
        }).catch(e => {
            if (e.status !== 400) {
                dispatch(setError("Error.message.fetchingParticipants"))
            }
        })
    }, 250), [])

    useEffect(() => {
        const searchString = searchText.trim();
        searchString && search(searchString,username)
    }, [search, searchText, username])

    useEffect(() => {
        updateSearchText('')
    }, [pinnedItems])

    const renderItem = (item: any, changePinnedItems: any, pinnedItemsDetails: any, isMultiSelect: boolean, searchString: string) => (
        <MultiSelectItem
            item={item}
            selected={isMultiSelect ? pinnedItemsDetails.findIndex((result: any) => result.twilioUsername === item.twilioUsername) !== -1 : !!pinnedItemsDetails && pinnedItemsDetails.twilioUsername === item.twilioUsername}
            key={item.twilioUsername}
            onChange={() => changePinnedItems(item)}
            highlightText={searchString}
            themeColor='evisit'
        />
    );

    const handleSearch = ({ target: { value } }: any) => {
        updateSearchText(value)
    }

    const renderTitle = () => {
        return (
            <div className={classes.accordionTitleWrapper}>
                {inviteLoader && <Typography className="loader-wrapper"> <CircularProgress color="inherit" />
                    <FormattedMessage id="ActiveZoomMeeting.inviteColleague.invitingColleague" />{pinnedItems.length > 1 && 's'}...
                </Typography>}
                <Typography className="title" >  <FormattedMessage id="ActiveZoomMeeting.inviteColleague.accordion" /></Typography>
            </div>
        )
    }

    const handleInvite = () =>{
        updateInviteLoader(true)
        updateAccordionStatus(false)
        customDispatch(inviteColleagueToEvisit(pinnedItems)).then(()=>{
            setPinnedItems([])
            updateSearchedRecipients([])
            updateInviteLoader(false)
            enqueueSnackbar(intl.formatMessage({
                id: "ActiveZoomMeeting.inviteColleague.success",
            }), {
                variant: "success",
            })
        }).catch(()=>{
            updateAccordionStatus(true)
        })
    }

    const toggleAccordion = () =>{
        if(inviteLoader){
            return null
        }
        updateAccordionStatus(prevState=>!prevState)
    }

    return (

        <CustomAccordion
            renderTitle={renderTitle}
            classes={classes.accordionWrapper}
            isOpen={accordionStatus}
            onChange={toggleAccordion}
        >
            <div className={classes.wrapper}>
                <MultiSelectPopoverInput
                    data={searchedRecipients}
                    value={searchText}
                    onChange={handleSearch}
                    placeholder={intl.formatMessage({
                        id: "ActiveZoomMeeting.inviteColleague.placeholder",
                    })}
                    title={intl.formatMessage({
                        id: "ActiveZoomMeeting.inviteColleague.title",
                    })}
                    pinnedItems={pinnedItems}
                    setPinnedItems={setPinnedItems}
                    renderItem={renderItem}
                    loader={searchLoader}
                    themeColor={'evisit'}
                />
                {pinnedItems.length >= 1 ?
                    <Send
                        className={classes.sendIcon}
                        onClick={handleInvite}
                    /> : ''
                }
             </div>
        </CustomAccordion>
    )
}

export default InviteColleague;
