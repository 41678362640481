import createReducer from '../createReducer'
import { AdminState } from '../reducerTypes'

const initialState: AdminState = {
	ehrSourceSystems: [],
	practiceEHRSettings: [],
	ehrManagedProperties: [],
	filterHaveAnyProducts: true,
	filterProductsIds: [],
	usersFilterText: '',
	usersSortBy: '',
	usersSortAsc: true,
	usersPageNumber: 0,
	usersPageSize: 25,
	users: [],
	userList: [],
	sUser: {},
	sRegisteredUser: {},
	sProfileIds: [],
	prefixes: [],
	allUserLevels: [],
	userLevels: [],
	sLocation: '',
	sProfile: '',
	orgs: null,
	patients: [],
	patientsContent: [],
	patientListHasUnder18Patient: false,
	selectedPatient: '',
	sPatients: [],
	sPatientForm: '',
	isBulkEditUserProducts: false,
	isAllSelectedBulk: false,
	filterAccountStatus: [],
	sSupportNetwork: {},
	adminWebCacheOrganization: null,
	adminWebCachePractice: null,
	configurePracticeId: null,
	configurePracticeName: null,
	configurePracticeProducts: [],
	totalUsersCountPieData: null,
	totalPatientsCountPieData: null
}

export default createReducer(initialState, {
	'SET_EHR_SOURCE_SYSTEMS': (state: AdminState, payload: AdminState): AdminState => {
		const { ehrSourceSystems } = payload
		return {
			...state,
			...payload,
			ehrSourceSystems
		}
	},
	'SET_PRACTICE_EHR_SETTINGS': (state: AdminState, payload: AdminState): AdminState => {
		const { practiceEHRSettings } = payload
		return {
			...state,
			...payload,
			practiceEHRSettings
		}
	},
	'SET_EHR_MANAGED_PROPERTIES': (state: AdminState, payload: AdminState): AdminState => {
		const { ehrManagedProperties } = payload
		return {
			...state,
			...payload,
			ehrManagedProperties
		}
	},
	'SET_ORGS': (state: AdminState, payload: AdminState): AdminState => {
		const { orgs } = payload
		return {
			...state,
			...payload,
			orgs
		}
	},
	'SET_TOTAL_USER_COUNTS': (state: AdminState, payload: AdminState): AdminState => {
		const { totalUsersCountPieData } = payload
		return {
			...state,
			...payload,
			totalUsersCountPieData
		}
	},
	'SET_TOTAL_PATIENT_COUNTS': (state: AdminState, payload: AdminState): AdminState => {
		const { totalPatientsCountPieData } = payload
		return {
			...state,
			...payload,
			totalPatientsCountPieData
		}
	},
	'SET_USERS_FILTER_AND_SORT_OPTIONS': (state: AdminState, payload: AdminState): AdminState => {
		const { usersFilterText, usersSortBy, usersSortAsc, usersPageNumber, usersPageSize, filterHaveAnyProducts, filterProductsIds, filterAccountStatus } = payload
		return {
			...state,
			...payload,
			usersFilterText,
			usersSortBy,
			usersSortAsc,
			usersPageNumber,
			usersPageSize,
			filterHaveAnyProducts,
			filterProductsIds,
			filterAccountStatus
		}
	},
	'SET_USERS': (state: AdminState, payload: AdminState): AdminState => {
		const { users } = payload
		return {
			...state,
			...payload,
			users
		}
	},
	'SET_USERLIST': (state: AdminState, payload: AdminState): AdminState => {
		const { userList } = payload
		return {
			...state,
			userList
		}
	},
	'SET_SELECTED_USER': (state: AdminState, payload: AdminState): AdminState => {
		const { sUser } = payload
		return {
			...state,
			...payload,
			sUser
		}
	},
	'SET_SELECTED_REGISTERED_USER': (state: AdminState, payload: AdminState): AdminState => {
		const { sRegisteredUser } = payload
		return {
			...state,
			...payload,
			sRegisteredUser
		}
	},
	'SET_SELECTED_USERS': (state: AdminState, payload: AdminState): AdminState => {
		const { sProfileIds } = payload
		return {
			...state,
			...payload,
			sProfileIds
		}
	},
	'SET_IS_ALL_SELECTED_BULK': (state: AdminState, payload: AdminState): AdminState => {
		const { isAllSelectedBulk } = payload
		return {
			...state,
			isAllSelectedBulk
		}
	},
	'SET_BULK_EDIT_USERS_PRODUCTS': (state: AdminState, payload: AdminState): AdminState => {
		const { isBulkEditUserProducts } = payload
		return {
			...state,
			...payload,
			isBulkEditUserProducts
		}
	},
	'SET_PREFIXES': (state: AdminState, payload: AdminState): AdminState => {
		const { prefixes } = payload
		return {
			...state,
			...payload,
			prefixes
		}
	},
	'SET_ALL_USER_LEVELS': (state: AdminState, payload: AdminState): AdminState => {
		const { allUserLevels } = payload
		return {
			...state,
			...payload,
			allUserLevels
		}
	},
	'SET_USER_LEVELS': (state: AdminState, payload: AdminState): AdminState => {
		const { userLevels } = payload
		return {
			...state,
			...payload,
			userLevels
		}
	},
	'SET_SELECTED_PRACTICE_LOCATION': (state: AdminState, payload: AdminState): AdminState => {
		const { sLocation } = payload
		return {
			...state,
			...payload,
			sLocation
		}
	},
	'SET_SELECTED_PROFILE': (state: AdminState, payload: AdminState): AdminState => {
		const { sProfile } = payload
		return {
			...state,
			...payload,
			sProfile
		}
	},
	'SET_SELECTED_PATIENT': (state: AdminState, payload: AdminState): AdminState => {
		const { selectedPatient } = payload;
		return {
			...state,
			...payload,
			selectedPatient
		}
	},
	'SET_SELECTED_PATIENTS': (state: AdminState, payload: AdminState): AdminState => {
		const { sPatients } = payload;
		return {
			...state,
			...payload,
			sPatients
		}
	},
	'SET_PATIENTS_FOR_PRACTICE': (state: AdminState, payload: AdminState): AdminState => {
		const { patients } = payload;

		return {
			...state,
			...payload,
			patients
		}
	},
	'SET_PATIENTS_CONTENT': (state: AdminState, payload: AdminState): AdminState => {
		const { patientsContent } = payload;

		return {
			...state,
			...payload,
			patientsContent
		}
	},
	'SET_SELECTED_PATIENT_FORM': (state: AdminState, payload: AdminState): AdminState => {
		const { sPatientForm } = payload
		return {
			...state,
			...payload,
			sPatientForm
		}
	},
	'SET_PATIENT_LIST_HAS_UNDER_18_PATIENT': (state: AdminState, payload: AdminState): AdminState => {
		const { patientListHasUnder18Patient } = payload
		return {
			...state,
			...payload,
			patientListHasUnder18Patient
		}
	},
	'SET_SELECTED_PATIENT_SUPPORT_NETWORK': (state: AdminState, payload: AdminState): AdminState => {
		const { sSupportNetwork } = payload
		return {
			...state,
			...payload,
			sSupportNetwork
		}
	},
	'SET_ADMIN_WEB_CACHE_ORGANIZATION': (state: AdminState, payload: AdminState): AdminState => {
		const { adminWebCacheOrganization } = payload
		return {
			...state,
			...payload,
			adminWebCacheOrganization
		}
	},
	'SET_ADMIN_WEB_CACHE_PRACTICE': (state: AdminState, payload: AdminState): AdminState => {
		const { adminWebCachePractice } = payload
		return {
			...state,
			...payload,
			adminWebCachePractice
		}
	},
	'SET_CONFIGURE_PRACTICE': (state: AdminState, payload: AdminState): AdminState => {
		const { configurePracticeId, configurePracticeName } = payload
		return {
			...state,
			...payload,
			configurePracticeId,
			configurePracticeName
		}
	},
	'SET_CONFIGURE_PRACTICE_PRODUCTS': (state: AdminState, payload: AdminState): AdminState => {
		const { configurePracticeProducts } = payload
		return {
			...state,
			...payload,
			configurePracticeProducts
		}
	}
})