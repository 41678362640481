import { makeStyles } from "@mui/styles";
import { SIZING } from "theme";

export const useStyles = makeStyles(() => ({
        root: {
            padding: SIZING.scale600,
            paddingRight: SIZING.scale800,
            height: evisitBar => `calc(100% - ${(SIZING.PATIENT_SUMMARY_HEADER_HEIGHT + (evisitBar ? SIZING.PATIENT_SUMMARY_EVISIT_BAR_HEIGHT : 0))}px)`,
            "& .MuiTableCell-head": {
                fontWeight: 800
            },
            "& .MuiTableContainer-root":{
                maxHeight: '100%'
            },
            overflow:'auto'
        },
        graphWrapper:{
            height:'100%',
            display:'flex',
            overflow:'auto'
        },
        empty: {
            height: evisitBar => `calc(100% - ${(SIZING.PATIENT_SUMMARY_HEADER_HEIGHT + (evisitBar ? SIZING.PATIENT_SUMMARY_EVISIT_BAR_HEIGHT : 0))}px)`,
        },
}));