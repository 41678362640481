import React from "react";
import {Box, CircularProgress} from "@mui/material"
import {useStyles} from "./styles";

// Spinner is used to show a loading spinner to indicate a process is loading data
const Spinner = () => {
    const classes = useStyles()
    return <Box className={classes.spinnerContainer}><CircularProgress/></Box>
}

export default Spinner