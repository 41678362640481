import { Checkbox, Fade, IconButton, List, ListItem, ListItemText, Menu, Box } from "@mui/material";
import { ReactComponent as FilterLight } from "icon-library/SVG/Filter-Light.svg";
import { ReactComponent as Filter } from "icon-library/SVG/Filter.svg";
import React, { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import { StatusFilterMenuProps } from "./types";
import { createSvgIcon } from "../../../utils";
const FilterIcon = createSvgIcon(Filter);
const FilterLightIcon = createSvgIcon(FilterLight);
const StatusFilterMenu = ({ callback, filterStatus }: StatusFilterMenuProps) => {
    const classes = useStyles()
    const intl = useIntl()
    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = useState(filterStatus);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleToggle = (value: any) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        callback(newChecked);
    };
    return (
        <Fragment>
            <IconButton id="btnToggleStatusFilterMenu" onClick={handleClick} size="large">
                {((checked && checked.length > 1) || (filterStatus && filterStatus.length > 0)) ? <FilterIcon fill="#139AED" /> : <FilterLightIcon />}
            </IconButton>
            <Menu
                PopoverClasses={{ paper: classes.menuContent }}
                elevation={0}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List classes={{ padding: classes.listText }}>
                    <Box className={classes.headerText}>
                        <FormattedMessage id={'StatusFilterMenu.Header'} />
                    </Box>
                    {[{ label: intl.formatMessage({ id: "StatusFilterMenu.Active" }), value: 'ACTIVE' },
                    { label: intl.formatMessage({ id: "StatusFilterMenu.Pending" }), value: 'PENDING' },
                    { label: intl.formatMessage({ id: "StatusFilterMenu.Inactive" }), value: 'INACTIVE' },
                    { label: intl.formatMessage({ id: "StatusFilterMenu.Locked" }), value: 'LOCKED' },
                    { label: intl.formatMessage({ id: "StatusFilterMenu.None" }), value: 'NONE' },
                    ]
                        .map((status) => {
                            const labelId = `checkbox-list-label-${status.value}`;

                            return (

                                <ListItem key={status.value} role={undefined} dense button onClick={handleToggle(status.value)}>
                                    <Checkbox color="primary"
                                        edge="start"
                                        checked={checked?.indexOf(status.value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                    <ListItemText primary={status.label} classes={{ primary: classes.listText }} />
                                </ListItem>
                            );
                        })}
                </List>
            </Menu>
        </Fragment >
    )
}

export default StatusFilterMenu