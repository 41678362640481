import React, { useEffect, useState, useRef } from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./styles";

export default function PauseDuration({value}: any) {
  const classes = useStyles();
  const time = Math.floor((new Date(value).getTime() - Date.now()) / 1000)
  const [formatedTime, setFormatedTime] = useState<string>("")
  const timerInterval = useRef<any>(null)

  useEffect(() => {
    return () => {
      clearInterval(timerInterval.current)
    }
  }, [])

  useEffect(() => {
    clearInterval(timerInterval.current)
    const initVal = Math.abs(time);
    setDisplayTime(initVal)
    setTimer(initVal)
  }, [time])

  function setTimer(val: number) {
    timerInterval.current = setInterval(() => {
      val = val + 1
      setDisplayTime(val)
    }, 1000);
  }

  function setDisplayTime(recValue: number) {

    if (recValue) {
      const sec = recValue % 60;

      const min = Math.floor(recValue / 60);
      const dispMin = min % 60;
      let hr = 0
      if (min) {
        hr = Math.floor(min / 60)
      }

      let dispString = ""
      if (hr) {
        dispString = hr + "h\xa0" + dispMin + "m\xa0" + sec + "s"
      } else if (min) {
        dispString = dispMin + "m\xa0" + sec + "s"
      } else {
        dispString = sec + "s"
      }
      setFormatedTime(dispString.toString())
    }
  }
  return (
    <Typography className={classes.pauseDuration}>
      PAUSED: {formatedTime}
    </Typography>
  );
}
