import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import ProvidersSearch from "./providers-search";
import SelectedProviders from "./selected-providers";
import { ProvidersProps } from "./types";
import { Specialist } from "views/EConsult/types";

const PROVIDER_LIMIT = 20;

const Providers = ({ selectedProviders, onChange, error}: ProvidersProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const addSpecialist = useCallback((provider: Specialist) => {
        onChange((providers) => {
            if (providers.length === PROVIDER_LIMIT) {
                return providers;
            }
            return [...providers, provider];
        });
    }, []);

    const removeSpecialist = useCallback((id: string) => {
        onChange((providers) => providers.filter((provider) => provider.id !== id));
    }, []);

    return (
        <Box display="flex" className={classes.providerBox}>
            <Box flexGrow={1} className="providersLeftPanel">
                <SelectedProviders selectedProviders={selectedProviders} onRemove={removeSpecialist} />
            </Box>
            <Box flexGrow={3} className="providersRightPanel">
                <ProvidersSearch
                    selectDisabled={selectedProviders.length === PROVIDER_LIMIT}
                    disabledMessage={intl.formatMessage({ id: "EConsult.newRequestModal.recipients.maxRecipientsDisabledMessage"})}
                    selectedProviders={selectedProviders}
                    onSelect={addSpecialist}
                    onRemove={removeSpecialist}
                    error={error}
                />
            </Box>
        </Box>
    );
};

export default Providers;
