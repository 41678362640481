import { makeStyles } from "@mui/styles";
import { COLORS, SIZING, toRem } from "theme";
import * as CSS from "./class-names";

export const useStyles = makeStyles(() => ({
        tabsContainer: {
            display: "flex",
            "& > div": {
                marginRight: SIZING.scale200,
                flex: `1 1 0`,
                [`.${CSS.TabContainerBoxed}&`]: {
                    minWidth: "min-content",
                    maxWidth: toRem(228)
                }
            },
            "& a": {
                marginRight: SIZING.scale200
            },
            "& div:last-child": {
                marginRight: 0
            }
        },
        linkTab: {
            display: "flex",
            flex: '1 1 0'
        },
        tabButton: {
            background: "white",
            height: SIZING.scale750,
            width: "100%",
            color: COLORS.PRIMARY_BTN_TEXT,
            boxShadow: "0 0 2px rgba(0, 0, 0, 0.25)",
            borderRadius: SIZING.scale400,
            padding: `${SIZING.scale100} ${SIZING.scale200}`,
            "&:hover": {
                backgroundColor: "white"
            }
        },
        tabButtonContent: {
            position: "relative",
            display: "flex",
            flexGrow: 1
        },
        tabButtonLabel: {
            flexGrow: 1,
            whiteSpace: "nowrap"
        },
        count: {
            "& p": {
                height: SIZING.scale500,
                borderRadius: SIZING.scale500,
                padding: `${SIZING.scale50} ${SIZING.scale300}`,
                backgroundColor: COLORS.PRIMARY_BTN_TEXT,
                color: COLORS.WHITE
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        arrowDown: {
            display: "none",
            width: 0,
            height: 0,
            borderLeft: `${SIZING.scale200} solid transparent`,
            borderRight: `${SIZING.scale200} solid transparent`,
            borderTop: `${SIZING.scale200} solid ${COLORS.PRIMARY_BTN_TEXT}`
        },
        tab: {
            flex: '1 1 0',
            display: "flex",
            flexDirection: "column" as "column", // https://github.com/frenic/csstype/issues/84
            alignItems: "center",
            "&.hasError button": {
                background: COLORS.INPUT_ERROR_BG,
                color: COLORS.TAB_ERROR_BG,
                "&:hover": {
                    background: COLORS.TAB_ERROR_BG,
                    color: COLORS.WHITE,
                    "& $count": {
                        "& p": {
                            color: COLORS.TAB_ERROR_BG,
                            backgroundColor: COLORS.WHITE
                        }
                    }
                },
                "& $count": {
                    "& p": {
                        color: COLORS.WHITE,
                        backgroundColor: COLORS.TAB_ERROR_BG
                    }
                }
            }
        },
        selectedTabButton: {
            "&:not(.hasError)": {
                "& button": {
                    background: COLORS.PRIMARY_BTN_TEXT,
                    boxShadow: "none",
                    color: COLORS.WHITE,
                    "&:hover": {
                        background: COLORS.PRIMARY_BTN_TEXT,
                        boxShadow: "none"
                    }
                },
                "& $arrowDown": {
                    display: "block"
                },
                "& $count": {
                    "& p": {
                        color: COLORS.PRIMARY_BTN_TEXT,
                        backgroundColor: COLORS.WHITE
                    }
                }
            },
            "&.hasError": {
                "& button": {
                    background: COLORS.TAB_ERROR_BG,
                    color: COLORS.WHITE,
                    "& $count": {
                        "& p": {
                            color: COLORS.TAB_ERROR_BG,
                            backgroundColor: COLORS.WHITE
                        }
                    }
                },
                "& $arrowDown": {
                    borderTopColor: COLORS.TAB_ERROR_BG,
                    display: "block"
                }
            }
        }
}));