import { makeStyles } from "@mui/styles";
import { COLORS } from "admin/theme";

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    scoringTable: {
        backgroundColor: COLORS.MODAL_BG
    },
    container: {
        background: '#FFF',
        border: '1px solid #D3D3D6'
    }
}));