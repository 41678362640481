import React, { useState, useRef } from "react";
import { Box, Paper, Typography, FormControl, Divider, Button, FormHelperText } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { useSelector,useDispatch } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { useStyles } from "./styles";
import { ReactComponent as WarningIcon } from "admin/assets/common/ExclamationWarning.svg";
import { PASSWORD_VALIDATIONS, PASSWORD_VALIDATION_TYPES } from "constant";
import { updateUserPassword,forgotUserPassword } from "store/actions";
import { useSnackbar } from "notistack";
import PasswordBox from "../PasswordBox/PasswordBox";
import Popover from "../Popover/Popover";
import PopoverContent from "../PopoverContent/PopoverContent";

const validate = (userName: string, name: string) => ({
  greaterThanEight: (v: string) => PASSWORD_VALIDATIONS[PASSWORD_VALIDATION_TYPES.HAS_MIN_CHARACTERS].test(v) || 'Atleast 8 Characters',
  hasNumber: (v: string) => PASSWORD_VALIDATIONS[PASSWORD_VALIDATION_TYPES.HAS_NUMBER].test(v) || 'Contains a number',
  hasSymbol: (v: string) => PASSWORD_VALIDATIONS[PASSWORD_VALIDATION_TYPES.HAS_SYMBOL].test(v) || 'Contains a symbol',
  hasUpperCase: (v: string) => PASSWORD_VALIDATIONS[PASSWORD_VALIDATION_TYPES.HAS_UPPERCASE].test(v) || 'Contains at least one uppercase letter',
  hasUsername: (v: any) => {
    return (v.match(/[a-z]+/ig).filter((a: string) => a.length >=3 && a.toLowerCase().includes(userName.toLowerCase())).length === 0 && v.match(/[a-z]+/ig).filter((a: string) => a.length >=3 && userName.toLowerCase().includes(a.toLowerCase())).length === 0) || 'Cannot contain your name or user name'
  },
  hasName: (v: any) => (v.match(/[a-z]+/ig).filter((a: string) => a.length >= 3 && a.toLowerCase().includes(name.toLowerCase())).length === 0 &&v.match(/[a-z]+/ig).filter((a: string) => a.length >= 3 && name.toLowerCase().includes(a.toLowerCase())).length === 0 ) || 'Cannot contain your name or user name',
})

export default function ChangePassword() {
  const intl = useIntl();
  const classes = useStyles();
  const newPassword = useRef({});
  const { enqueueSnackbar } = useSnackbar()
  const [loader, updateLoad] = useState<boolean>(false)
  const { user } = useSelector((state: AppReduxStore) => state)
  const dispatch = useDispatch()

  const userName = user.username || "-";
  const name = `${user.firstName || "-"} ${user.lastName || "-"}` || "-";

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm({ mode: "all" });

  newPassword.current = watch("newPassword", "");

  const updatePassword = (data: any) => {
    updateLoad(true)
    updateUserPassword(userName, data.oldPassword, data.newPassword).then((response: any) => {
      enqueueSnackbar(response.message, {
        variant: "success",
      })
      reset()
      newPassword.current=''
      updateLoad(false)
    }).catch((response: any) => {
      enqueueSnackbar(response.message, {
        variant: "error",
        autoHideDuration: null
      })

      updateLoad(false)
    })
  }

  const handleRestPassword = (closeOverlay: ()=> void) =>{
    closeOverlay()
    dispatch({ type: 'UPDATE_COMMON_LOADER',payload:true })
    forgotUserPassword(user.username).then((response: any) => {
      enqueueSnackbar(response.message, {
        variant: "success",
      })
    dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
    }).catch((response: any) => {
    dispatch({ type: 'UPDATE_COMMON_LOADER',payload:false })
      enqueueSnackbar(response.message, {
        variant: "error",
        autoHideDuration: null
      })
    })
  }

  return (
    <Box pl={2} pt={2}>
      <Typography variant="h4">{intl.formatMessage({ id: "ChangePassword.title" })}</Typography>
      <Paper elevation={1} >
        <form className={classes.formWrapper} onSubmit={handleSubmit(updatePassword)}> 
          <FormControl className={`${classes.formCtrl} width-100`}>
            <label className={classes.labelCtrl}>{intl.formatMessage({ id: "ChangePassword.oldPassword" })}            
            <Popover
                Button={({ onClick, close }: any) => (
                  <label id="QA_forgot_password" className={classes.lnkCtrl} onClick={onClick}>{intl.formatMessage({ id: "ChangePassword.forgotPassword" })}</label>
                )}
                Content={({ close }: any) => (
                  <PopoverContent
                    onSend={(text: string) => {                     
                      handleRestPassword(close)
                    }}
                    close={close}
                  />
                )}
              />
            </label>
            <PasswordBox
                id="QA_old_password_box"
              className="normal-input"
              {...register("oldPassword", {
                required: intl.formatMessage({ id: "ChangePassword.oldpasswordRequired" }),
                validate: validate(userName, name)
              })}
              {...((errors as any).oldPassword?.messsage && { error: true })}

            />
          {(errors as any).oldPassword && <Box display="inline-flex">
              <WarningIcon className={classes.exclamationIcon} />
              <FormHelperText className={classes.error}>{errors.oldPassword?.message}</FormHelperText>
            </Box>}
          </FormControl>


          <Divider className={classes.divider} />

          <FormControl className={`${classes.formCtrl} width-100`}>
            <label className={classes.labelCtrl}>{intl.formatMessage({ id: "ChangePassword.newPassword" })}</label>
            <PasswordBox
              id="QA_new_password_box"
              className="normal-input"
              {...((errors as any).newPassword && { error: true })}
              {...register("newPassword", {
                required: intl.formatMessage({ id: "ChangePassword.newpasswordRequired" }),
                validate: validate(userName, name)
              })}
              {...((errors as any).newPassword?.message && { error: true })}
            />
            {(errors as any).newPassword && <Box display="inline-flex">
              <WarningIcon className={classes.exclamationIcon} />
              <FormHelperText className={classes.error}>{errors.newPassword?.message}</FormHelperText>
            </Box>}
          </FormControl>

          <FormControl className={`${classes.formCtrl} width-100`}>
            <label className={classes.labelCtrl}>{intl.formatMessage({ id: "ChangePassword.repeatPassword" })}</label>
            <PasswordBox
              id="QA_repeat_password_box"
              className="normal-input"
              {...((errors as any).repeatPassword?.message && { error: true })}
              {...register("repeatPassword", {
                required: intl.formatMessage({ id: "ChangePassword.newpasswordRequired" }),
                validate: { ...validate(userName, name), ...{ passwordMatch: (v: string) => v === newPassword.current || "The passwords do not match" } },
              })}
            />
            {(errors as any).repeatPassword && <Box display="inline-flex">
              <WarningIcon className={classes.exclamationIcon} />
              <FormHelperText className={classes.error}>{errors.repeatPassword?.message}</FormHelperText>
            </Box>}
          </FormControl>
          <Divider className={classes.divider} />
          <Box className={classes.buttonWrapper}>
            <Button variant="contained" disabled={loader} color="error" type="submit" className={classes.btnUpdate} id="QA_update_password_button">
              <FormattedMessage id={"ChangePassword.updatePassword"} />
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  )
}
