import { COLORS } from 'theme';
import { ReactComponent as Chat } from "icon-library/SVG/Chat.svg";
import { ReactComponent as User_Users } from "icon-library/SVG/User_Users.svg";
import { ReactComponent as Time_Clock } from "icon-library/SVG/Time_Clock.svg";
import { ReactComponent as Altais_Star } from "icon-library/SVG/Altais-Star.svg";
import {ReactComponent as Community} from "icon-library/SVG/User_Group.svg";
import { ReactComponent as Mortarboard } from "icon-library/SVG/Mortarboard.svg";
import { ValueOf } from 'types';
import  LOGO1  from "./assests/images/eNable.png";
import LOGO3 from './assests/images/academy.png'
import LOGO4 from './assests/images/community.png'
// @ts-ignore
const {LMS_URL,ENABLE_ONLINE_COMMUNITY_URL} = window.env

export type ConfigType = {
    [k: string]:ConfigDetails
}

type ConfigDetails ={
    title:string,
    themeColor: string,
    description:string,
    btnText: string,
    callback?: () => void,
    icon: any,
    Image?: string,
    incText?:string,
    incBackColor?:string,
    incColor?:ValueOf<typeof COLORS>,
    route: string,
    type:any
}
export const getConfig = (isPCR:boolean): ConfigType =>{
    return {
        messages: {
            title:'Messages',
            themeColor:COLORS.LBLUE,
            description:'Communicate and chat securely with colleagues and patients',
            btnText:'Open Messages',
            icon:Chat,
            route:"/provider/messages/inbox",
            type:"nonPCR"
        },
        people: {
            title:'Patients List',
            themeColor:COLORS.LBLUE,
            description:'Review patient profiles, access their charts, alerts and forms',
            btnText:'Open People',
            icon:User_Users,
            route: "/provider/people/patients",
            type:"nonPCR"
        },
        waitingRoom: {
            title:'Patient Waiting Room',
            themeColor:COLORS.LBLUE,
            description:'Virtual space to greet your patients for seamless handoffs',
            btnText:'Open Waiting Room',
            icon:Time_Clock,
            route: "/provider/waiting-room",
            type:"nonPCR"
        },
        learning: {
            title:'Altais Academy',
            themeColor: isPCR ? COLORS.LIGHT_BLUE : COLORS.LGREEN,
            Image:LOGO3,
            description:!isPCR? 'Virtual library of original content that is curated for lifelong learners' : 'Our interactive knowledge center contains courses, videos, and articles curated for lifelong learners',
            btnText:'Visit Altais Academy',
            icon:Mortarboard,
            incText:'INCLUDED',
            incBackColor:"rgba(19, 154, 237, 0.1)",
            incColor:COLORS.LIGHT_BLUE,
            callback: () => {
                    window.open(LMS_URL, "_blank")
            },
            route: "",
            type:"nonPCR"
        },
        community: {
            title:'Altais Community',
            themeColor:isPCR ? COLORS.LIGHT_BLUE : COLORS.LBLUE,
            description:'A collective space for providers and staff to network, establish relationships, and share ideas and experiences',
            btnText:'Visit Altais Community',
            route:"",
            incText:'INCLUDED',
            Image:LOGO4,
            incBackColor:"rgba(19, 154, 237, 0.1)",
            incColor:COLORS.LIGHT_BLUE,
            type:"PCR",
            callback: () => {
                window.open(ENABLE_ONLINE_COMMUNITY_URL)
        },
            icon:Community
        },
        discover: {
            title:"Altais eNable\u2122",
            themeColor:COLORS.PRIORITY_BG,
            description:'Simplify your workday and enhance care quality with integrated, cross-EHR solutions, technology, and tools',
            btnText:"Discover eNable\u2122",
            route: "/provider/discover-enable",
            incText:'PREMIUM',
            Image:LOGO1,
            incBackColor:"rgba(232, 44, 42, 0.07)",
            incColor:COLORS.ALERTS_ICON,
            type:"PCR",
            icon:Altais_Star
        },
    }
}