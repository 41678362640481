import React, { useRef, useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import Dropzone, { IFileWithMeta, StatusValue, MethodValue } from "react-dropzone-uploader";
import { call } from "store/api";
import { useUserNotification } from "common/utils/use-user-notification";
import {
    FileDropzoneInputContextProvider,
    FileDropzoneInputContextProps,
    useFileDropzoneInputContext,
} from "./file-dropzone-input/file-dropzone-input-context";
import {
    getFilesForPercentageCalc,
    getTotalPercentage,
    validateUnique,
    toFileUploadDataList,
} from "./file-dropzone-input/file-with-meta";
import DropAreaLayout from "./file-dropzone-input/dropzone-layout";
import Input from "./file-dropzone-input/input";
import UploadPreview from "./file-dropzone-input/upload-preview";
import { getFilesFromEvent } from "./file-dropzone-input/get-files-from-event";
import { FileUploadData, FileDropzoneInputValidateFn } from "./types";
import { ERROR_STATES } from "./constants";
import * as FileClassNames from "./class-names";
import { useStyles } from "./styles";

export type FileDropzoneInputProps = {
    accept?: string;
    className?: string;
    disabled?: boolean;
    validate?: FileDropzoneInputValidateFn;
    /** Provides all current attachments in the parameter. */
    onChange: (attachments: FileUploadData[]) => void;
    /** Provides a cumulative uploading percentage. */
    onPercentageChange: (percentage: number) => void;
    size?: "small" | "big";
    uploadParams?: ({ file, meta }: IFileWithMeta) => Promise<{
        method: MethodValue;
        body: File;
        url: any;
        headers: any;
        meta: {
            fileId: any;
        };
    } | {
        url: string;
        method?: undefined;
        body?: undefined;
        headers?: undefined;
        meta?: undefined;
    }>;
};

type FileDropzoneInputRefObject = {
    getFiles: () => FileUploadData[];
    clearState: () => void;
};

export type FileDropzoneInputRef = FileDropzoneInputRefObject | undefined;

const FileDropzoneInputComponent = forwardRef<FileDropzoneInputRef, FileDropzoneInputProps>(
    ({ accept, className, disabled, onChange, onPercentageChange, validate, size = "big", uploadParams }, ref) => {
        const classes = useStyles();
        const intl = useIntl();
        const { enqueueError } = useUserNotification();
        const { clearInputValue } = useFileDropzoneInputContext();
        const [isUploading, setIsUploading] = useState(false);
        const allFilesRef = useRef<IFileWithMeta[]>([]);
        const uploadPercentageRef = useRef(0);
        // react-dropzone-uploader does not have a proper way to 
        // clear its state.
        // Ideally it should be controlled i.e. having "value" and "onChange" prop so we could set it's value to []
        // or it could have an imperative function to clean it.
        // since it does not have any of those, we are left with the option of unmounting it
        // and we achieve that by changing it's key
        const [dropzoneKey, setDropzoneKey] = useState(0);

        useImperativeHandle<unknown, FileDropzoneInputRef>(
            ref,
            () => ({
                getFiles: () => toFileUploadDataList(allFilesRef.current),
                clearState: () => {
                    allFilesRef.current = [];
                    uploadPercentageRef.current = 0;
                    setDropzoneKey((key) => key++);
                }
            }),
            []
        );

        const getUploadParams = async ({ file, meta }: IFileWithMeta) => {
            // for some reason, if we just bubble the error to react-dropzone-uploader
            // it will log the error and will not change the status to "error_upload_params"
            // the only way to get to error_upload_params is to send an falsely url
            try {
                const response = await call("POST", "/file/v1/files", { name: meta.name });
                return {
                    method: "PUT" as MethodValue,
                    body: file,
                    url: response.uri,
                    headers: response.signedHeaders,
                    meta: { fileId: response.fileId },
                };
            } catch (error) {
                // this error might not contain actionable information to the user
                console.error(error);
                // since we will send an empty url we will get an error state
                // and we handle the error during handleChangeStatus
            }
            return { url: "" };
        };

        const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => {
            allFilesRef.current = allFiles;

            if (ERROR_STATES.includes(status)) {
                if (fileWithMeta.meta.validationError) {
                    enqueueError(fileWithMeta.meta.validationError);
                } else {
                    enqueueError("files.fileDropzoneInput.upload.uploadParams.error");
                }
            }

            if (status === "removed") {
                clearInputValue();
            }

            const attachments = toFileUploadDataList(allFiles);

            const isCurrentlyUploading = attachments.some((a) => a.status === "UPLOADING");

            if (isCurrentlyUploading !== isUploading) {
                setIsUploading(isCurrentlyUploading);
            }

            onChange(attachments);
        };

        const handleValidate = (fileWithMeta: IFileWithMeta) => {
            const uniqueErrorMessage = validateUnique(fileWithMeta, allFilesRef.current);
            const customErrorMessage = validate?.(fileWithMeta, allFilesRef.current);
            const errorMessage = uniqueErrorMessage || customErrorMessage;

            if (errorMessage) {
                return intl.formatMessage(errorMessage);
            }
        };

        useEffect(() => {
            let interval: ReturnType<typeof setInterval>;

            if (isUploading) {
                interval = setInterval(() => {
                    const percentage = getTotalPercentage(getFilesForPercentageCalc(allFilesRef.current));
                    if (uploadPercentageRef.current !== percentage) {
                        uploadPercentageRef.current = percentage;
                        onPercentageChange(percentage);
                    }
                }, 500);
            }

            return () => {
                clearInterval(interval);
            };
        }, [onPercentageChange, isUploading]);

        return (
            <div
                className={classNames(classes.Files__FileDropzoneInput, FileClassNames.FileDropzoneInput, className, {
                    [FileClassNames.FileDropzoneInputSmall]: size === "small",
                })}
            >
                <Dropzone
                    key={dropzoneKey}
                    classNames={{
                        dropzoneActive: FileClassNames.DropzoneLayoutActive,
                    }}
                    disabled={disabled}
                    getUploadParams={uploadParams ? uploadParams : getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    LayoutComponent={DropAreaLayout}
                    InputComponent={Input}
                    PreviewComponent={UploadPreview}
                    getFilesFromEvent={getFilesFromEvent}
                    validate={handleValidate}
                    accept={accept || "*"}
                />
            </div>
        );
    }
);

const FileDropzoneInput = forwardRef<FileDropzoneInputRef, FileDropzoneInputProps & FileDropzoneInputContextProps>(
    ({ removeDialogProps, disabled, ...props }, ref) => (
        <FileDropzoneInputContextProvider removeDialogProps={removeDialogProps} disabled={disabled} >
            <FileDropzoneInputComponent {...props} disabled={disabled} ref={ref} />
        </FileDropzoneInputContextProvider>
    )
);

export default FileDropzoneInput;
