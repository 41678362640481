import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
        landingArea: {
            width: '100%',
            height: '100%',
            display: 'flex',
        },
        boxLeft: {
            width: '50%',
            clipPath: 'polygon(0 0, 100% 0, calc(100% - 10vh) 100%, 0 100%)',
            background: 'linear-gradient(101.14deg, #0F7BBD 6.82%, #0B5D8F 85.9%)',
            textAlign: 'center',
        },
        boxRight: {
            width: '50%',
            marginTop: '50px'
        },
        boxRightInner: {
            width: '360px',
            margin: 'auto',
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '140%',
            color: '#393948',
            marginTop: '52px'
        },
        subHeading: {
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '140%',
            color: '#393948'
        },
        btnLogin: {
            marginTop: '50px',
            width: '100%',
        },
        loginBtnWrapper: {
            //paddingRight: '18px'
        },
        secretQuestionHeading: {
            textAlign: "left",
            fontSize:"15px",
            fontWeight: "bold",
            pading: "5px 0"
        },
        secretQuestion: {
            textAlign: "left",
            fontSize:"15px",
            fontWeight: "normal"
        },
        loginInformationText: {
            textAlign: "center",
            fontSize:"15px",
            fontWeight: "normal"
        }
}));