import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "../../../theme";
export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.XL
    },
    dlgTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '10px'
    },
    dlgText: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '10px'
    },
    dlgContent: {
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '140%',
        color: '#E32B29',
        textAlign: 'center'
    },
}));