import { makeStyles } from "@mui/styles";
import {SIZING, COLORS, BORDER_RADIUS} from "theme";

export const useStyles = makeStyles(() => ({
        insuranceContainer: {
            padding: `${SIZING.scale400} 0 ${SIZING.scale100} ${SIZING.scale500}`,
            boxShadow: "0 1px 8px rgba(0, 0, 0, 0.05), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
            borderRadius: BORDER_RADIUS.MEDIUM
        },
        patientSummaryInsuranceTitle: {
            color: COLORS.LIST_SUBHEADER_TEXT,
            textTransform: "uppercase",
            marginBottom: SIZING.scale400
        },
        patientSummaryInsuranceLabel: {
            width: "155px",
            color: COLORS.PATIENT_DETAIL_LABEL_TEXT,
            textAlign: "right",
            marginRight: SIZING.scale600,
            textTransform: "uppercase"
        },
        patientSummaryInsuranceSecondary: {
            width: "64px",
            color: COLORS.PATIENT_DETAIL_LABEL_TEXT,
            textAlign: "left",
            textTransform: "uppercase"
        },
        patientSummaryInsuranceValue: {
            color: COLORS.PATIENT_DETAIL_VALUE_TEXT,
            textAlign: "left"
        },
        divider: {
            height: "1px",
            width: "100%",
            background: COLORS.DIVIDER_GREY,
            marginBottom: SIZING.scale400
        },
        insuranceRow: {
            display: "flex",
            marginBottom: SIZING.scale400
        },
        nestedRow: {
            display: "flex"
        }
}));
