import { Box, DialogActions, DialogContent, Typography } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as Gear } from "icon-library/SVG/Gear-Light.svg";
import { ReactComponent as PlusCircle } from "icon-library/SVG/Plus-Light_Circle.svg";
import { ReactComponent as userSingle } from "icon-library/SVG/User_Single.svg";
import { showNotification } from "admin/store/actions/snackbar";

const ConfigurePractice = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const CrossIcon = createSvgIcon(CrossSvg)
    const GearIcon = createSvgIcon(Gear)
    const PracticeIcon = createSvgIcon(PlusCircle)
    const LeaveIcon = createSvgIcon(userSingle)
    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose();
    };

    const modalClasses = {
        paper: classes.wrapper
    }

    const openAddPracticeModal = () => {
        dispatch(setActiveModal(MODAL_TYPES.ADD_PRACTICE));
    };

    const handleConfigure = () => {
        dispatch(setActiveModal(MODAL_TYPES.PRACTICE_CONFIGURATION))
    }

    const handleAddMenuItemClick = (event: any, selection: string) => {
        switch (selection) {
            case "Configure":
                handleConfigure()
                break;
            case "AddAnother":
                openAddPracticeModal();
                break;
            case "Leave":
                dispatch(setActiveModal(""));
                break;
            default:
                dispatch(showNotification("Error", "error", "Not Implemented"));
        }
    };
    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.CONFIGURE_PRACTICE}>
            <DialogActions className={classes.dialogAction}>
                <Box className={classes.dialogHeader}>
                    <Box className={classes.dialogHeaderTop}>
                        <Typography className={classes.dialogHeaderTitle}>
                            <FormattedMessage id={"Configure.Action.Heading"} />
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <CrossIcon onClick={handleClose} className={classes.closeButtonIcon} />
                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ root: classes.dialogContentRoot, dividers: classes.dialogContentDividers }}>
                <Box className={classes.modalContent}>
                    <Box className={classes.modalContainer} >
                        <FormattedMessage id={"Configure.Content.Title"} />
                    </Box>
                    <Box className={classes.modalContainertext} >
                        <FormattedMessage id={"Configure.Content.Desc"} />
                    </Box>
                </Box>
                <Box className={classes.selectionBox} >
                    <Box className={classes.contentBox} onClick={(event) => handleAddMenuItemClick(event, 'Configure')}>
                        <GearIcon className={classes.BuildIcon}></GearIcon>
                        <Box>
                            <div className={classes.textTitle}>
                                <FormattedMessage id={"Configure.title.Configure"} />
                            </div>
                            <div className={classes.textDesc}>
                                <FormattedMessage id={"Configure.title.Configure.Desc"} />
                            </div>
                        </Box>
                    </Box>
                    <Box className={classes.contentBox} onClick={(event) => handleAddMenuItemClick(event, 'AddAnother')}>
                        <PracticeIcon className={classes.BuildIcon}></PracticeIcon>
                        <Box>
                            <div className={classes.textTitle}>
                                <FormattedMessage id={"Configure.title.AddPractice"} />
                            </div>
                            <div className={classes.textDesc}>
                                <FormattedMessage id={"Configure.title.AddPractice.Desc"} />
                            </div>
                        </Box>
                    </Box>
                    <Box className={classes.contentBox} onClick={(event) => handleAddMenuItemClick(event, 'Leave')}>
                        <LeaveIcon className={classes.BuildIcon}></LeaveIcon>
                        <Box>
                            <div className={classes.textTitle}>
                                <FormattedMessage id={"Configure.title.Leave"} />
                            </div>
                            <div className={classes.textDesc}>
                                <FormattedMessage id={"Configure.title.Leave.desc"} />
                            </div>
                        </Box>
                    </Box>
                </Box>

            </DialogContent>

        </Modal>
    )
}

export default ConfigurePractice