import createReducer from '../createReducer'
import {ModalState} from '../reducerTypes'

const initialState: ModalState = {
    activeModal: "",
    activeModalProps: null
}

export default createReducer(initialState, {
    'SET_ACTIVE_MODAL': (state: ModalState, payload: ModalState):ModalState => {
        return {
            ...state,
            ...payload
        }
    }
})