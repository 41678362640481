import React, { useRef, forwardRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    Box, Typography, Button, DialogActions, 
    DialogContent } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import VisitTypesListing from "./VisitTypesListing";

const VisitTypes = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const classes = useStyles();
    const editVisitTypeFormRef = useRef<any>();

    const modalClasses = {
        paper: classes.wrapper
    };

    const handleClose = () => {
        dispatch(setActiveModal(""));
    };

    const handleSaveVisitTypesForm = (event: any) => {
        const result = editVisitTypeFormRef.current.submitForm(event);
        if (result)
            handleClose();
    };

    const handleDiscardChanges = (event: any) => {
        handleClose();
    };

    return (
        <Modal onClose={handleClose} classes={modalClasses} id={MODAL_TYPES.EDIT_EVISIT_TYPES}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"VisitTypes.ModalHeading"} />
                    </Typography>
                </Box>
                <Box>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box className={classes.dlgAction}>
                    <Box>
                        <Button id="btnDiscardChanges" variant="outlined"
                            aria-haspopup="true" onClick={handleDiscardChanges}>
                            {intl.formatMessage({ id: "VisitTypes.Action.Cancel" })}
                        </Button>
                        <Button id="btnSave" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveVisitTypesForm}>
                            {intl.formatMessage({ id: "VisitTypes.Action.Update" })}
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
            <DialogContent className={classes.contentDialog}>
                <Box mb={1} className={classes.formGpHeader}>
                    {intl.formatMessage({ id: "VisitTypes.Text.VisitTypes" })}
                </Box>
                <Box mb={1} className={classes.formGpSubHeader}>
                    {intl.formatMessage({ id: "VisitTypes.Text.VisitTypeId" })}
                </Box>

                <VisitTypesListing ref={editVisitTypeFormRef} />
            </DialogContent>
        </Modal>
    );
})

export default VisitTypes