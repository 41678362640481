import { Box, Divider } from "@mui/material";
import { COLORS } from "theme";
import { FormattedMessage } from "react-intl";
import { forwardRef, Ref } from "react";

type NewMessageDividerProps = {
    newMessageCount: number
}

const NewMessageDivider = forwardRef(({newMessageCount}: NewMessageDividerProps, newMessageRef: Ref<HTMLDivElement>) => (
    <div className="newMessageDivider" ref={newMessageRef}>
        <Divider textAlign="left" className="newMessageDivider__divider">
            <Box display="flex" color={COLORS.DIVIDER_TEXT}>
                <FormattedMessage id="messaging.divider.newMessages" values={{count: newMessageCount}} />
            </Box>
        </Divider>
    </div>
));

export default NewMessageDivider;
