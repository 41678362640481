import { DeprecatedThemeOptions, adaptV4Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import OpenSans from 'fonts/OpenSans-Regular.ttf'

export const SIZING = {
    scale25: "0.1rem", // 1.6
    scale50: "0.125rem", // 2
    scale75:"0.188rem",//3
    scale100: "0.25rem", // 4
    scale150: "0.375rem", // 6
    scale200: "0.5rem", // 8
    scale300: "0.75rem", // 12
    scale350: ".875rem", //14
    scale400: "1rem", // 16
    scale450:"1.125rem",//18
    scale500: "1.25rem", // 20
    scale600: "1.5rem", // 24
    scale700: "1.75rem", // 28
    scale800: "2rem", // 32
    scale1000: "2.5rem", // 40
    scale1100: "2.75rem", // 44
    scale1200: "3rem", // 48
    scale2800: "7rem", // 112
    WAITING_PMG_LOGO:"9.375rem",
    WAITING_PMG_LOGO_MOB:"6.25rem",
    WAITING_JOIN_BTN_BOR_RAD:"3.125rem",
    QUICK_CHAT_WIDTH:"21.75rem",//348px
    QUICK_CHAT_HEIGHT:"5.188rem"//83px
}

export const BORDER_RADIUS = {
    SMALL: SIZING.scale100,
    MEDIUM: SIZING.scale200,
    MED_LARGE: SIZING.scale400,
    LARGE: SIZING.scale600,
    XL: SIZING.scale500,
    PILL: "30rem",
    FULL: "100%",
    MULTISELECT_RADIUS_RIGHT:'1.385rem',
    MULTISELECT_RADIUS_LEFT:'1.3rem',
}

export const COLORS = {

    //Common
    WHITE: "#FFFFFF",
    LBLUE:'#0F7BBD',
    GREY:'#393948',
    DGREY:'#EDEDEF',
    RED:"#E32B29",
    BLACK:'#000000',
    PRIMARY_BTN_TEXT: "#0B5D8F",
    PRIMARY_BTN: "#FFFFFF",
    DISABLED_BTN_TEXT: "#565662",
    DISABLED_BTN: "#CACACE",
    DEFAULT_BTN: "#0F7BBD",
    DEFAULT_BUTTON_TEXT: "#FFFFFF",
    SELECTED_BTN: "#073D5E",
    SECONDARY_BUTTON:'#00875E',
    EMPTY_LIST_ICON: "rgba(57, 57, 72, 0.1)",
    DEFAULT_BG: "#0F7BBD",
    DESC_TEXT:'#565662',
    INPUT_BORDER:"#C7C7CA",
    CHAT_TEXT:"#6E6E79",
    CHAT_MESSAGE:"#212131"
}

const openSans = {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontDisplay: "swap",
    src: `
        local('OpenSans'),
        url(${OpenSans}), format('truetype')
    `
}

export default createTheme(adaptV4Theme({
    breakpoints: {
        values: {
          xxs: 0,
          xs: 400,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
          xxl:2000
        }
      } as DeprecatedThemeOptions["breakpoints"],
    typography: {
        fontFamily: 'OpenSans',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [openSans]
            }
        },
        MuiButton: {
            root: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: SIZING.scale1200,
                height: SIZING.scale1200,
                background: COLORS.DEFAULT_BTN,
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
                borderRadius: BORDER_RADIUS.PILL,
                color: COLORS.DEFAULT_BUTTON_TEXT,
                "&:hover": {
                    backgroundColor: COLORS.DEFAULT_BTN,
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.25)"
                },
                "&:disabled": {
                    background: COLORS.DISABLED_BTN,
                    color: COLORS.DISABLED_BTN_TEXT,
                    boxShadow: "none",
                    cursor: "not-allowed"
                },
                "&:active": {
                    background: COLORS.SELECTED_BTN
                }
            },
            textPrimary: {
                background: COLORS.PRIMARY_BTN,
                color: COLORS.PRIMARY_BTN_TEXT
            },
            containedSecondary: {
                backgroundColor: COLORS.SECONDARY_BUTTON,
                "&:hover": {
                    backgroundColor: COLORS.SECONDARY_BUTTON,
                }
            },
            startIcon: {
                "& path": {
                    fill: COLORS.PRIMARY_BTN_TEXT
                }
            },
            text: {
                padding: `0 ${SIZING.scale400}`,
            },

        }
     }
}))