import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  menuContent: {
    backgroundColor: '#EDEDEF',
    color: '#393948',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3))',
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      content: '""',
      position: "absolute",
      marginRight: "-0.71em",
      top: -20,
      left: '50%',
      width: 24,
      height: 24,
      background: '#EDEDEF',
      boxShadow: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3))',
      transform: "translate(-50%, 50%) rotate(315deg)",
      clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },
  listWrapper: {
    background: '#F6F6F7',
    padding: '0px 0px 10px 0px',
    borderRadius: '8px'
  },
  listRoot: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  listText: {
    color: '#393948',
    fontSize: '16px'
  },
  headerText: {
    borderRadius: '8px',
    color: '#6E6E79',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '-0.41px',
    background: '#EDEDEF'
  }
}));