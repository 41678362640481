import createReducer from '../createReducer'
import { SideNavbarState } from '../reducerTypes'

const initialState: SideNavbarState = {
	open: true,
	breadcrumbText: '',
	breadCrumbEntityHierarchy: []
}

export default createReducer(initialState, {
	'SET_OPEN_NAV': (state: SideNavbarState, payload: SideNavbarState): SideNavbarState => {
		const { open } = payload
		return {
			...state,
			...payload,
			open
		}
	},
	'SET_BREADCRUMB_TEXT': (state: SideNavbarState, payload: SideNavbarState): SideNavbarState => {
		const { breadcrumbText } = payload
		return {
			...state,
			...payload,
			breadcrumbText
		}
	},
	'SET_BREADCRUMB_ENTITY_HIERARCHY': (state: SideNavbarState, payload: SideNavbarState): SideNavbarState => {
		const { breadCrumbEntityHierarchy } = payload
		return {
			...state,
			...payload,
			breadCrumbEntityHierarchy
		}
	}
})

