import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";
import {StyleProps} from "./types";


export const useStyles = makeStyles(() => ({
    divider: {
      display: "flex",
      alignItems: "center",
      margin: ({disableMargin}: StyleProps) => disableMargin ? 0 : `${SIZING.scale400} 0`,
      width: "100%"
    },
    content: {
        padding: `0 ${SIZING.scale200}`
    },
    longLine: {
        flex: 1
    },
    line: {
        minWidth: SIZING.scale700,
        borderTop: ({color}: StyleProps) => `1px solid ${color || COLORS.DIVIDER_TEXT}`
    }
}));