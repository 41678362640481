import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";

type useStylesProps = {
    showProviderName: boolean
}

export const useStyles = makeStyles(() => ({
    cardWrapper:{
        "&.removed":{
            animation: ".5s ease-in-out .5s $removeEffect",
            animationFillMode:'forwards'
        },
        "&.hide":{
            display:'none'
        },
        transition: "transform .3s ease"
    },
    patientDetailsSection: {
        marginBottom: SIZING.scale400,
        position: "relative",
        "&.waiting, &.paused":{
            "&:hover , &.active":{
                "& .action-wrapper":{
                    display:'block '
                },
                "& $providerName": {
                    borderColor:`${COLORS.LBLUE} !important`,
                  },
                "& $cardSection": {
                    background:"linear-gradient(0deg, #139AED, #139AED), linear-gradient(0deg, #EBEBEB, #EBEBEB), #0F7BBD",
                    "& $patientIcon path, & .incall-icon path": {
                        fill: COLORS.WHITE
                    },
                    
                  },
                  "& $patientName":{
                    color: COLORS.WHITE
                  },
                  "& .helper-text":{
                    color: COLORS.WHITE,
                },
                "& $dropIcon":{
                    visibility:'visible',
                    userSelect:'all',
                    zIndex:8
                }
            }
        },
        "& .baMenu": {
            background: "linear-gradient(0deg, rgba(232, 245, 254, 0.15), rgba(232, 245, 254, 0.15)), #FFFFFF",
            marginTop: `-${SIZING.scale375}`,
            color: COLORS.LIST_SUBHEADER_TEXT,
            overflow:'hidden',
            fontSize: SIZING.scale400,
            zIndex: 3,
            left: `14.5rem !important`,
            top:({ showProviderName }:useStylesProps)=>showProviderName? `7.25rem !important`: `5.6rem !important`,
            width: SIZING.scale5000,
            position: 'absolute',
            boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
            filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2))',
            backdropFilter: 'blur(10px)',
            borderRadius: SIZING.scale400,
            padding:SIZING.scale300,
            "& .MuiListItem-gutters": {
                paddingLeft: `${SIZING.scale250} !important`,
                display: "contents"
            },
            height: SIZING.scale2000,
            "& hr": {
                margin: `0 ${SIZING.scale250}`
            },
            "& .MuiListItemIcon-root": {
                minWidth: `${SIZING.scale800} !important`
            },
            "& ::-webkit-scrollbar": {
                cursor: "pointer !important",
                background: COLORS.COMPOSE_SCROLL_BAR
            }
        },
        "& .triangle": {
            height: SIZING.scale500,
            width: SIZING.scale800,
            position: "absolute",
            right: "0.25rem",
            top: ({ showProviderName }:useStylesProps)=>showProviderName? "4.685rem" : "2.9rem",
            zIndex: 4,
            background: "linear-gradient(0deg, rgba(232, 245, 254, 0.15), rgba(232, 245, 254, 0.15)), #FFFFFF",
            boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.02)',
            transform: "translate(-50%, 50%) rotate(360deg)",
            clipPath: "polygon(50% 0, 100% 100%, 0 100%)"
        }
    },
    providerName: {
        alignItems: "center",
        border: `${SIZING.scale50} solid`,
        borderRadius: `${SIZING.scale400} ${SIZING.scale400} 0px 0px`,
        fontSize: SIZING.scale250,
        height: SIZING.scale600,
        borderBottom: 'unset',
        borderColor: COLORS.EMPTY_LIST_ICON,
        display: "flex",
        justifyContent: "center",
        "&.paused": {
            borderColor: COLORS.LRED,
        },
        "&.ended": {
            borderColor: COLORS.LGREY1,
        },
        "&.incall": {
            borderColor: COLORS.LGREEN,
        }
    },
    patientCardDetails: {
        borderRadius: ({ showProviderName }:useStylesProps)=>showProviderName? "0px 0px 1rem 1rem" :SIZING.scale350,
        overflow:'hidden'
    },
    cardSection:{
        display: "flex",
        width: "100%",
        height: "100%",
        padding: SIZING.scale400,
        alignItems:'center',
        position:'relative',
        background: "linear-gradient(0deg, #EBEBEB, #EBEBEB)",
        "&.paused":{
            background: " linear-gradient(0deg, #FFDEDE, #FFDEDE), linear-gradient(0deg, #DBDBDE, #DBDBDE), linear-gradient(0deg, #EBEBEB, #EBEBEB), #0F7BBD",
        },
        "&.ended":{
            background: COLORS.LGREY1,
            "& path": {
                fill: COLORS.WHITE
            },
            "& .helper-text":{
                color: COLORS.WHITE,
                fontWeight:'900 !important'
            }
        },
        "&.incall":{
            background: "linear-gradient(0deg, #00A875, #00A875), #0F7BBD",
            "& path": {
                fill: COLORS.WHITE
            },
            "& .helper-text":{
                color: COLORS.WHITE,
            }
        },
        "& .helper-text":{
            color: COLORS.PATIENT_DETAIL_LABEL_TEXT,
            display:"flex",
            alignItems:"center",
            "& path":{
                fill: COLORS.PATIENT_DETAIL_LABEL_TEXT
            },
            "& .incall-icon":{
                marginRight:SIZING.scale150,
                marginTop:SIZING.scale150
            }
        },
        "& .helper-text-late":{
            color: COLORS.PRIORITY_BG,
            fontSize: SIZING.scale350,
            marginLeft: SIZING.scale350,
            fontWeight:` 700 !important`
        },
        "& .action-wrapper":{
            display:'none',
            position:'absolute',
            width: "100%",
            height: "100%",
            top:0,
            left:0,
            background:'linear-gradient(270deg, #139AED 60.4%, rgba(19, 154, 237, 0) 79.83%)',
            padding:SIZING.scale450
        },
        "& .start-call":{
            display:"flex",
            justifyContent:"flex-end !important",
            alignItems:"center",
            height:"100%",
            "& .icon-bg":{
                background:COLORS.WHITE,
                alignItems:"center",
                borderRadius:SIZING.scale1600,
                boxShadow:"0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)",
                display:"flex",
                "& path": {
                    fill: COLORS.LBLUE
                },
                margin:SIZING.scale100,
                cursor:"pointer"
            },
            "& .icon-active-bg":{
                background:COLORS.LBLUE,
                alignItems:"center",
                borderRadius:SIZING.scale1600,
                boxShadow:"0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)",
                display:"flex",
                "& path": {
                    fill: COLORS.WHITE
                },
                margin:SIZING.scale100,
                cursor:"pointer",
                "& .active":{
                    background:COLORS.WHITE,
                }
            },
            "& .ellipse-wrap":{
                height:SIZING.scale800,
                width:SIZING.scale800,
                justifyContent:"center",
             },
            "& .button-wrap":{
                height:SIZING.scale800,
                minWidth:SIZING.scale2900,
                justifyContent:"space-between",
                margin:SIZING.scale100,
                fontSize:SIZING.scale400,
                fontWeight:600,
                paddingLeft: SIZING.scale200,
                paddingRight: SIZING.scale500
            },
        },
},
    patientName: {
        color: COLORS.MULTI_SELECT_BG,
        fontSize: SIZING.scale400,
        fontWeight: 700,
        marginBottom: SIZING.scale100,
        "&.incall":{
            color: COLORS.WHITE,     
        },
        "&.ended":{
            color: COLORS.WHITE,     
        },
    },
    patientIcon: {
        marginBottom: SIZING.scale200,
        alignSelf:'flex-end',
        "& > g":{
            fill: COLORS.LGREY1
        }
    },
    patientInfo: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginLeft: SIZING.scale250,
        height: "50%",
        "& .patient-name": {
            maxWidth: "14.375rem",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        "& .patient-age":{
            marginBottom: SIZING.scale100,
            textAlign: 'right'
        },
        "& .additional-info-wrap": {
            display: "flex",
            flexDirection:"column",
            alignItems: "end"
        }
    },
    dropIcon:{
        position:'absolute',
        right:`-${SIZING.scale100}`,
        top:`-${SIZING.scale100}`,
        cursor:'pointer',
        "& path":{
            fill: COLORS.DANGER
        },
        visibility:"hidden",
        userSelect:'none',
        background:COLORS.WHITE,
        borderRadius: '100%'
    },
    patientadditionalinfo: {
        display: "flex",
        alignItems:"center"
    },
    "@keyframes removeEffect":{
        "0%": {
            opacity: 1,
            heigth: "auto"
          },
          "100%":{
            opacity: 0,
            heigth: 0
          }
    },
    mergedTitle:{
        color: COLORS.WHITE,
        fontSize: SIZING.scale350
    },
    root: {
        "&>div": {
            zIndex: 1
        }
    },
    mergeBox : {
        height:SIZING.scale2000,
        width:SIZING.scale5000,
        padding: SIZING.scale200
    },
    callOptions: {
        fontSize:SIZING.scale300,
        fontWeight:700,
        color:COLORS.LGREY1
    },
    callOptionsMenu: {
        background: COLORS.MODAL_BG,
        borderRadius: SIZING.scale200,
        padding: SIZING.scale200,
        fontSize: SIZING.scale350,
        fontWeight: 700,
        color: COLORS.GREY,
        cursor: "pointer"
    }
}))

