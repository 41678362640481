import { AppReduxStore } from "store/reducerTypes";
import { setActiveModal } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

type useActiveModalHook = {
    open: boolean;
    onClose: () => void;
};

const useActiveModal = (id: string): useActiveModalHook => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { activeModal } = useSelector((store: AppReduxStore) => store.modal);
    const onClose = () => dispatch(setActiveModal(""));

    useEffect(() => {
        if (activeModal === id && !open) {
            setOpen(true);
        } else if (activeModal !== id && open) {
            setOpen(false);
        }
    }, [activeModal, id, open]);

    return {
        open,
        onClose,
    };
};

export default useActiveModal;
