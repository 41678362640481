import { makeStyles } from "@mui/styles";
import { SIZING, COLORS,BORDER_RADIUS } from "../../../theme";
import { Theme } from "@mui/material";

export const useStyles = makeStyles<Theme>(theme=>({
    wrapper: {
        position:'relative',
        width:'100%',
        "& .custom-multiselect-wrapper":{
            background: COLORS.INPUT_BG,
            borderRadius: BORDER_RADIUS.LARGE,
            "&>.MuiBox-root":{
                marginBottom:'0',
                paddingRight:'3.125rem'
            },
            "& .custom-dropdown-wrapper":{
                height:'12.5rem',
                top:'3.125rem',
                //@ts-ignore
                [theme.breakpoints.up('xxl')]: {
                    height: '20.5rem'
                  }
            },
            "& .custom-multiselect-input-wrapper":{
                border:'none',
            }
        },
        marginTop:SIZING.scale400,
    },
    sendIcon: {
        position:'absolute',
        color:COLORS.BADGE_BG,
        top:'50%',
        transform:'translateY(-50%)',
        right:SIZING.scale400,
        cursor:'pointer'
    },
    accordionWrapper:{
        background:'transparent',
        paddingTop:SIZING.scale400,
        boxShadow:'none',
        margin:'0 !important',
        "& .accordion-icon":{
            color:COLORS.LGRAY
        },
        "& .MuiAccordionSummary-root":{
            minHeight:'unset',
            height:'auto',            
        },
        "& .MuiAccordionSummary-content":{
            justifyContent:'flex-end',
            margin:'0',
            color:COLORS.LGRAY
        },
        "& .MuiAccordionSummary-expandIcon":{
            padding:0,
        },
        "& .MuiCollapse-wrapper":{
            "& .MuiAccordionDetails-root":{
                padding:0
            }
        }

    },
    accordionTitleWrapper:{
        display:'flex',
        width:'100%',
        "& .title":{
            marginLeft:'auto'
        },
        "& .MuiTypography-root":{
            color: COLORS.INPUT_BG
        },
        "& .loader-wrapper":{
            display:'flex',
            alignItems:'center',
            fontSize:SIZING.scale400,
            fontWeight:'normal',
            "& .MuiCircularProgress-root":{
                height:`${SIZING.scale400} !important`,
                width:`${SIZING.scale400} !important`,
                marginRight: SIZING.scale300
            }
        }
    }
}))