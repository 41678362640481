import { Grid, Button, Box } from "@mui/material";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import {ReactComponent as Edit} from "icon-library/SVG/Pen.svg";
import {ReactComponent as Save} from "icon-library/SVG/Disk-Light.svg";
import {ReactComponent as Enable} from "icon-library/SVG/Radio-Light_Circle.svg";
import {ReactComponent as Disable} from "icon-library/SVG/Cross-Light_Circle.svg";
import {ReactComponent as Review} from "icon-library/SVG/Paper-Plane-Light_TopRight.svg";
import {ReactComponent as Approve} from "icon-library/SVG/Checkmark.svg";
import {ReactComponent as Reject} from "icon-library/SVG/Cross.svg";
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import {COLORS} from "admin/theme";
import { useIntl } from "react-intl";

const FormActionToolbar = (props: any) => {
  const classes = useStyles();
  const EditIcon = createSvgIcon(Edit);
  /* TODO: show send to patient when implemented */
  const SaveIcon = createSvgIcon(Save);
  const EnableIcon = createSvgIcon(Enable);
  const DisableIcon = createSvgIcon(Disable);
  const ReviewIcon = createSvgIcon(Review);
  const ApproveIcon = createSvgIcon(Approve);
  const RejectIcon = createSvgIcon(Reject)
  const intl = useIntl();
  const { auth } = useSelector((state: AppReduxStore) => state);
  const approverProfileId = auth && auth.currentProfiles !== null ? auth.currentProfiles[0].providerProfile !== null ? auth.currentProfiles[0].providerProfile?.id : "" : "";
  const userId = auth && auth.userDetails !== null ? auth.userDetails.profileRoleJoinInfos[0].providerProfile !== null ? auth.userDetails.profileRoleJoinInfos[0].providerProfile.id : "" : "";
  const {handleEditModeToggle, handleReviewToggle, handleApproveToggle, handleRejectToggle, readOnlyState, isEnabled, handleToggle, currentForm} = props;

  return (
    <div className={classes.root}>
      <Box my={2}>
        {currentForm.formStatus !== "PENDING_APPROVAL" && 
            <Grid container spacing={3}>
              <Grid item xs={'auto'}>
                <Button onClick={handleEditModeToggle} size="medium" variant="outlined" startIcon={<EditIcon fill={COLORS.DEFAULT_BTN} />}>{intl.formatMessage({ id: "PatientFormConfig.Eidt" })}</Button>
              </Grid>
              <Grid item xs={'auto'}>
                <Button form="patient-form" type="submit" disabled={readOnlyState} size="medium" variant="outlined" startIcon={<SaveIcon fill={(readOnlyState ? COLORS.DISABLED_BTN : COLORS.DEFAULT_BTN)} />}>{intl.formatMessage({ id: "PatientFormConfig.Save" })}</Button>
              </Grid>
              {currentForm.formStatus === "IN_PROGRESS" && 
                <Grid item xs={'auto'}>
                  <Button onClick={handleReviewToggle} disabled={(approverProfileId === "" || userId === "") ? true : false} form="patient-form" size="medium" variant="outlined" startIcon={<ReviewIcon fill={(approverProfileId === "" || userId === "") ? COLORS.GREY : COLORS.DEFAULT_BTN} />}>{intl.formatMessage({ id: "PatientFormConfig.SendForReview" })}</Button>
                </Grid>
              }
              <Grid item xs={'auto'}>
              {isEnabled && 
                <Button onClick={handleToggle} size="medium" variant="outlined" startIcon={<DisableIcon fill={COLORS.DEFAULT_BTN} />}>{intl.formatMessage({ id: "PatientFormConfig.Disable" })}</Button>
              }
              {!isEnabled && 
                <Button onClick={handleToggle} size="medium" variant="outlined" startIcon={<EnableIcon fill={COLORS.DEFAULT_BTN} />}>{intl.formatMessage({ id: "PatientFormConfig.Enable" })}</Button>
              }
              </Grid>
            </Grid>
            }
            {currentForm.formStatus === "PENDING_APPROVAL" && 
              <Grid container spacing={3}>
                <Grid item xs={'auto'}>
                  <Button onClick={handleApproveToggle} disabled={(approverProfileId === "" || userId === "") ? true : false} form="patient-form" size="medium" variant="outlined" startIcon={<ApproveIcon fill={COLORS.DEFAULT_BTN} />}>{intl.formatMessage({ id: "PatientFormConfig.Approve" })}</Button>
                </Grid>
                <Grid item xs={'auto'}>
                  <Button onClick={handleRejectToggle} disabled={(approverProfileId === "" || userId === "") ? true : false} form="patient-form" size="medium" variant="outlined" startIcon={<RejectIcon fill={COLORS.DEFAULT_BTN} />}>{intl.formatMessage({ id: "PatientFormConfig.Reject" })}</Button>
                </Grid>
              </Grid>
            }
              {/*
              <Grid item xs={'auto'}>
              <Button size="medium" variant="outlined" startIcon={<SendIcon fill={COLORS.DISABLED_BTN_TEXT} />} disabled>Send to Patient</Button>
              </Grid>
              */}
        </Box>
    </div>
  );
}

export default FormActionToolbar