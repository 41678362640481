import { makeStyles } from "@mui/styles";
import { COLORS, SIZING, toRem } from "theme";

const descendantPlaceholders = "& input::placeholder, & textarea::placeholder";

const caseNotesPlaceholders = {
    fontWeight: 600,
    fontSize: SIZING.scale450,
    lineHeight: toRem(24.51),
    color: "rgba(0,0,0,0.2)",
    opacity: 1,
};

export const useStyles = makeStyles(() =>({
    caseNotesTab: {
        display: "flex",
        padding: SIZING.scale400,
        flexGrow: 1,
        backgroundColor: COLORS.MODAL_CONTENT_LBLUE_BG,
        flexDirection: "column",
        alignItems: "center",
        height: "fit-content",
        minHeight: "100%",
        "& .caseNoteInput": {
            "&:not(.Mui-error)": {
                border: "0.5px solid rgba(0, 0, 0, 0.14)",
            },
            boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.12)",
        },
        "& .caseNoteInputRow": {
            width: `calc(100% + ${SIZING.scale200})`
        },
        "& .subject": {
            [descendantPlaceholders]: caseNotesPlaceholders,
        },
        "& .notes": {
            flexGrow: 1,
            "& textarea": {
                minHeight: SIZING.scale4000,
            },
            [descendantPlaceholders]: caseNotesPlaceholders,
            "& .caseNoteInputRow": {
                flexGrow: 1,
                alignItems: "stretch",
            }
        },
        "& .notesIconRow": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
        },
        "& .durationDropdown": {
            alignItems: "center",
            backgroundColor: COLORS.GRAY_2,
            border: `1px solid ${COLORS.MIDDLE_GRAY}`,
            boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: SIZING.scale100,
            display: "flex",
            fontSize: SIZING.scale350,
            lineHeight: toRem(19.6),
            padding: `0 ${SIZING.scale200}`,
            width: toRem(260),
            cursor: "pointer",
            "& .selectedOption": {
                flexGrow: 1,
                whiteSpace: "pre-wrap",
                color: COLORS.MIDDLE_GRAY
            },
            "& .label": {
                fontWeight: "bold",
            },
            "& span": {
                marginRight: SIZING.scale200,
            },
            "& svg": {
                width: SIZING.scale600,
            },
        },
        "& .optionsRow": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingTop: SIZING.scale200,
            "& .optionMenu": {
                minWidth: toRem(250)
            },
            "& .optionRowLabel": {
                marginBottom: SIZING.scale125,
                color: COLORS.GREY
            }
        },
        "& .formControlLabelRoot": {
            margin: 0
        },
        "& .formControlLabelLabel": {
            fontSize: SIZING.scale350,
            lineHeight: toRem(19.6),
            marginLeft: SIZING.scale150
        },
        "& .checkboxRoot": {
            padding: 0,
            "& path, & g": {
                fill: COLORS.LBLUE
            },
            "&:hover": {
                backgroundColor: "unset"
            }
        }
    },
    durationOptionLabel: {
        lineHeight: "unset",
        fontSize: "12px",
        fontWeight: 400,
        "&:not(.selected)": {
            color: "rgba(57, 57, 72, 0.4)",
        }
    }
}));