import React from "react";
import { FormattedMessage } from "react-intl";
import { IFormInput } from "admin/views/viewsTypes";

export const getFormConfigurableString = (form:IFormInput) => {
    if (form && form.name) {
        const formName = form.name;
        const formConfigurable = form.configurable;
        if (formConfigurable) {
            return "Configurable";
        } else {
            if (formName.endsWith("-MODIFIED")) {
                return "Modified.Standard";
            } else {
                return "Standard";
            }
        }
    }
    return "";
};

export const getFormConfigurable = (form:IFormInput) => {
    if (form && form.name) {
        return (<FormattedMessage id={"PatientFormsListing.Label.Configurable." + getFormConfigurableString(form)} />);
    }
    return "";
};