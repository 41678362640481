import React from "react"
import { useStyles } from "./styles"
import { FormattedMessage } from "react-intl";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { Box, Typography, DialogContent } from "@mui/material";
import { ReactComponent as Heart } from "icon-library/SVG/Heart.svg";
import { ReactComponent as ArrowDown } from "icon-library/SVG/Arrow_Down.svg";
import { ReactComponent as ArrowUp } from "icon-library/SVG/Arrow_Top.svg";
import { ReactComponent as Scales } from "icon-library/SVG/Scales.svg";
import { ReactComponent as Walking } from "icon-library/SVG/Walking.svg";

const RemoteMonitoringTab: React.FC = () => {

    const classes = useStyles()

    return (
        <Box className={classes.patientRpmChart}>
                        <Box className={classes.rpmChartCard}>
                            <Box className={classes.rpmCardTitle}>
                                <Typography variant="body1">
                                    <FormattedMessage id={"RPM.Chart.Bloodsugar"} />
                                </Typography>
                                <Box className={classes.rpmCardDesc}>
                                    <Box className={classes.rpmLevels}>
                                    <SVGIcon svg={Heart} size="scale800" />
                                        <Typography variant="body1" className={classes.rpmLevelText}>
                                            140 mg/dL
                                        </Typography>
                                        <SVGIcon svg={ArrowDown} size="scale800" className={classes.downArrow} />
                                    </Box>
                                    <Typography variant="caption">
                                        Measured: Jan 6, 2021. 1:32 PM
                                    </Typography>
                                </Box>
                            </Box>
                            <DialogContent dividers={true} />
                           
                        </Box>
                        <Box className={classes.rpmChartCard}>
                            <Box className={classes.rpmCardTitle}>
                                <Typography variant="body1">
                                    <FormattedMessage id={"RPM.Chart.HeartRate"} />
                                </Typography>
                                <Box className={classes.rpmCardDesc}>
                                    <Box className={classes.rpmLevels}>
                                    <SVGIcon svg={Heart} size="scale800" />
                                        <Typography variant="body1" className={classes.rpmLevelText}>
                                            86 bpm
                                        </Typography>
                                        <SVGIcon className={classes.upArrow} svg={ArrowUp} size="scale800"  />
                                    </Box>
                                    <Typography variant="caption">
                                        Measured: Jan 6, 2021. 1:32 PM
                                    </Typography>
                                </Box>
                            </Box>
                            <DialogContent dividers={true} />
                           
                        </Box>
                        <Box className={classes.rpmChartCard}>
                            <Box className={classes.rpmCardTitle}>
                                <Typography variant="body1">
                                    <FormattedMessage id={"RPM.Chart.Weight"} />
                                </Typography>
                                <Box className={classes.rpmCardDesc}>
                                    <Box className={classes.rpmLevels}>
                                        <SVGIcon svg={Scales} size="scale800" />
                                        <Typography variant="body1" className={classes.rpmLevelText}>
                                            211 lbs
                                        </Typography>
                                        <SVGIcon svg={ArrowUp} size="scale800" className={classes.upArrow} />
                                    </Box>
                                    <Typography variant="caption">
                                        Measured: Jan 6, 2021. 1:32 PM
                                    </Typography>
                                </Box>
                            </Box>
                            <DialogContent dividers={true} />
                           
                        </Box>
                        <Box className={classes.rpmChartCard}>
                            <Box className={classes.rpmCardTitle}>
                                <Typography variant="body1">
                                    <FormattedMessage id={"RPM.Chart.DailySteps"} />
                                </Typography>
                                <Box className={classes.rpmCardDesc}>
                                    <Box className={classes.rpmLevels}>
                                        <SVGIcon svg={Walking} size="scale800" />
                                        <Typography variant="body1" className={classes.rpmLevelText}>
                                            3,723 <span className={classes.dataTotalCount}>/8,000 Steps</span>
                                        </Typography>
                                    </Box>
                                    <Typography variant="caption">
                                        Measured: Jan 6, 2021. 1:32 PM
                                    </Typography>
                                </Box>
                            </Box>
                            <DialogContent dividers={true} />
                           
                        </Box>
                    </Box>
    )
}

export default RemoteMonitoringTab