import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  root: {
    width: 'auto',
    fontSize: '12px',
    "& .MuiSelect-select": {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    "& .MuiInputBase-input": {
      paddingLeft: '10px',
      paddingRight: '10px',
      fontSize: '12px'
    }
  }
}));