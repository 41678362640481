import { makeStyles } from "@mui/styles";
import { SIZING, toRem } from "theme";
import * as CSS from "./class-names";

export const useStyles = makeStyles({
    [CSS.ErrorListDialog]: {
        [`&.${CSS.ErrorListDialog}`]: {
            maxWidth: toRem(500),
            fontWeight: 800,
            fontSize: SIZING.scale350,
            whiteSpace: "pre-wrap",
            [`& li .${CSS.ErrorListDialogErrorDetails}`]: {
                fontWeight: 400,
            },
            "& ul": {
                paddingLeft: SIZING.scale700,
            },
            [`& .${CSS.ErrorListDialogErrorName}`]: {
                textTransform: "uppercase",
            },
        },
    },
});
