import { IconButton, List, Menu, Box, Typography } from "@mui/material";
import { ReactComponent as ArrowDown } from "admin/assets/common/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "admin/assets/common/ArrowUp.svg";
import React, { Fragment, useState } from "react";
import { useStyles } from "./styles";
import { OrgProductsDropdownProps } from "./types";

const OrgProductsDropdown = ({ callback, heading, orgProductsDropdownContent }: OrgProductsDropdownProps) => {
    const OrgProductsDropdownContent = orgProductsDropdownContent;

    const initialState = {
        mouseX: 0,
        mouseY: 0
    };
    const [state, setState] = useState(initialState);
    const classes = useStyles({ x: state.mouseX, y: state.mouseY });


    const handleClick = (event: any) => {
        event.preventDefault();
        callback("megaMenu")
        setState({
            mouseX: event.clientX - 500,
            mouseY: event.clientY + 15
        });

    };

    const handleClose = () => {
        setState(initialState);
    };

    return (
        <Fragment>
            <Box>
                <Typography 
                    onClick={handleClick}
                    className={classes.heading}
                    variant={"caption"}
                    gutterBottom
                >
                    {heading}
                    <IconButton id="btnToggleOrgProductsDDL" classes={{ root: classes.iconBtn }} size="large">
                        {state.mouseY !== 0 ? <ArrowUp /> : <ArrowDown />}
                    </IconButton>
                </Typography>
            </Box>
            <Menu
                PopoverClasses={{ paper: classes.menuContent }}
                elevation={0}
                keepMounted
                open={state.mouseY !== 0}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <List className={classes.root}>
                    <OrgProductsDropdownContent callback={(type: any) => { callback(type) }} handleClose={() => { handleClose() }} />
                </List>
            </Menu>
        </Fragment>
    )
}

export default OrgProductsDropdown;
