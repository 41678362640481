import createReducer from "../createReducer";
import { NotificationState } from "../reducerTypes";

const initialState: NotificationState = {
    items: [],
};

export default createReducer(initialState, {
    GET_NOTIFICATIONS: (state: NotificationState, payload: NotificationState): NotificationState => {
        return {
            ...state,
            ...payload,
        };
    },
});
