import { Paper, Typography, MenuItem, MenuList, Button } from "@mui/material";
import React, { useEffect } from "react"
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import CustomPopover from "components/Popover/Popover"
import { ReactComponent as TimeClock } from "icon-library/SVG/Time_Clock.svg";
import { ReactComponent as PhoneHangup } from "icon-library/SVG/Phone_Hangup.svg";
import { ReactComponent as ChevronUp } from "icon-library/SVG/Chevron_Up.svg";
import { createSvgIcon } from "utils"
import { LeaveButtonProps, OptionsType } from "./types"
import { AppReduxStore } from "store/reducerTypes";
import {  useSelector } from "react-redux";

const TimeClockIcon = createSvgIcon(TimeClock);
const PhoneHangupIcon = createSvgIcon(PhoneHangup);
const ChevronUpIcon = createSvgIcon(ChevronUp);

const LeaveButton: React.FC<LeaveButtonProps> = ({ isInvited, participantCount, isMA, handlePause, handleEndCall, handleLeave }) => {

    const initialOptions: Array<OptionsType> = [
        { label: 'Pause Call', Icon: TimeClockIcon, desc: 'Send the patient back to the Waiting Room', type: 'pause', handler: handlePause },
        { label: 'Leave Call', Icon: PhoneHangupIcon, desc: 'Call will be active for other participants', type: 'leave', handler: handleLeave},
        { label: 'End Call...', Icon: PhoneHangupIcon, desc: 'Ends call for all participants', type: 'leave', handler: handleEndCall},
    ]
    const [options, updateOptions] = React.useState<Array<OptionsType>>(initialOptions.slice(0));
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const classes = useStyles()
    const { waitingRoom } = useSelector((store: AppReduxStore) => store)
    const { currentMeetingDetails } = waitingRoom
    const isMergedCall = !!currentMeetingDetails?.mergedParticipantsInfo.length;

    useEffect(() => {
        let tempOptions = initialOptions.slice(0)
        let finalOptions:Array<OptionsType> = []

        if(participantCount === 1){
            if(isMA){
                tempOptions = [tempOptions[2]]
            }else{
                tempOptions.splice(1,1)
            }     
        }else{
            if(isMA){
                if(isMergedCall&&participantCount===2){
                    tempOptions = [tempOptions[2]]
                }else{
                    tempOptions.splice(0,1)
                }
            }else if(isMergedCall&&participantCount===2){
                tempOptions.splice(1,1)
            }
        }
        finalOptions = tempOptions
        updateOptions(finalOptions)

    }, [participantCount, isInvited, isMA])

    const button = (handleClick: any) => {
        return <Button
            className={classes.leaveButton}
            data-testid="leave_call"
            onClick={e => {
                handleClick(anchorRef.current)
            }}
        >
            <FormattedMessage id="Twilio.footer.endCall" />
            <ChevronUpIcon />
        </Button>
    }

    const content = (closePopup: () => void) => {
        return (<Paper className={classes.paperWrapper}>
            <MenuList className={classes.menuWrapper} data-testid="menu-wrapper">
                {options.map(({ label, Icon, desc, handler, type }, index) => (
                    <MenuItem
                        onClick={e => { closePopup(); handler(); }}
                        key={index}
                        data-testid={`leave-button-menu-item-${type}`}
                        className="menu-items-leave-button"
                    >
                        <Icon />
                        <div className="menu_content_wrapper">
                            <Typography variant="body1">{label}</Typography>
                            <span >{desc}</span>
                        </div>
                    </MenuItem>
                ))}
            </MenuList>
        </Paper>)
    }

    return (
        <div ref={anchorRef} className={classes.root} >
            <CustomPopover
                renderElement={button}
                renderContent={content}
                arrowPosition='right'
                anchorOrigin={{
                    vertical: -20,
                    horizontal: -30,
                }}
                arrowDirection='bottom'
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            />
        </div>
    )
}

export default LeaveButton