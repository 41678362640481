import { useMsal } from "@azure/msal-react";
import { Login } from "./Login/azure-login";
import { loginRequest } from "./authConfig";

export const AzureLogin = () => {
    const { instance } = useMsal();

    const handleSignIn = async () => {
        await instance.loginRedirect(loginRequest);
    }

    /*
    return (<h2 style={{ marginTop: '80px', textAlign: 'center' }}>
        <Button id="btn_signIn" variant="contained" style={{ marginLeft: '10px', marginBottom: '5px', fontSize: '2rem', backgroundColor: '#6E1E80' }} onClick={handleSignIn}>
            {`Sign in `}
        </Button>
        {` to BTP Provider Engagement Platform`}
    </h2>)
    */

    return (<Login signIn={handleSignIn} />)
}