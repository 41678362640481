import React, { forwardRef, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import { SIZING } from "theme";
import { useCombinedRefs } from "common/utils/use-combined-refs";
import { useColor } from "common/utils/use-color";
import { SVGIconProps } from "./types";

const SVGIcon = forwardRef<SVGSVGElement, SVGIconProps>(
    ({ svg: Svg, trim, size, width, height, color, opacity, className, ...svgProps }, ref) => {
        const svgRef = useRef<SVGSVGElement>(null);
        const combinedRef = useCombinedRefs(ref, svgRef);
        const { style, className: colorClassName } = useColor({ svgFillColor: color });
        const [trimProps, setTrimProps] = useState<{ viewBox?: string }>({});

        // TODO: remove trim functionality. Unfortunately getBBox only works if the svg is visible, making this unreliable.
        // we use layout effect instead of useEffect because we need to synchronously update the svg viewBox
        useLayoutEffect(() => {
            if (trim && svgRef.current) {
                const bbox = svgRef.current.getBBox();
                const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(" ");
                setTrimProps({ viewBox });
            } else {
                setTrimProps({});
            }
        }, [Svg, trim]);

        const sizeValue = size ? SIZING[size] : undefined;
        const widthValue = width ? SIZING[width] : undefined;
        const heightValue = height ? SIZING[height] : undefined;

        return (
            <Svg
                ref={combinedRef}
                {...svgProps}
                {...trimProps}
                className={classNames(className, colorClassName)}
                style={{
                    opacity,
                    ...svgProps.style,
                    ...style,
                    width: widthValue || sizeValue,
                    height: heightValue || sizeValue,
                }}
            />
        );
    }
);

export default SVGIcon;
