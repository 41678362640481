import createReducer from '../createReducer'
import { LoadingMessage, LoadingState } from '../reducerTypes'

const initialState: LoadingState = {
    loadingMessages: []
}

export default createReducer(initialState, {
    'SHOW_LOADING': (state: LoadingState, payload: LoadingMessage): LoadingState => {
        const { id, title, description } = payload
        return {
            ...state,
            loadingMessages: [...state.loadingMessages, { id, title, description, timestamp: new Date() }]
        }
    },
    'HIDE_LOADING': (state: LoadingState, payload: LoadingMessage): LoadingState => {
        return {
            ...state,
            loadingMessages: state.loadingMessages.filter((m) => m.id !== payload.id)
        }
    },
})