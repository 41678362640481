import React from "react";
import { Paper, Box, Typography, IconButton, Button } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import CancelIcon from "@mui/icons-material/Cancel"
import { useStyles } from "./styles";

export default function PopoverContent({ close, Buttonid, handleSubmit }: any) {
  const intl = useIntl();
  const classes = useStyles();


  const handleButton = (type:any) =>{
    switch (type) {
      case 'Drop_call':
        return "WaitingRoom.DropContent.title";
      case 'pause_room':
        return "WaitingRoom.PauseContent.title";
      case 'Leave_call':
        return "WaitingRoom.LeaveContent.title";
      default:
        return "WaitingRoom.EndContent.title"
    }
  }
  
  return (
    <Paper className={classes.root} classes={{ root: classes.paper }}>
      <Box display="flex" flexDirection="column" p={1} width="325px">
        <Typography className="title" variant="h4" component="div">
          {intl.formatMessage({ id: handleButton(Buttonid)})}
        </Typography>
        <Box className={classes.btnContainer}>
          <Button
            id="QA_send_instruction"
            variant="contained"
            color="primary"
            type="submit"
            className={classes.btnNo}
            data-testid="confirm_button_no"
            onClick={() => { close(); }}
          >
            <FormattedMessage id={"WaitingRoom.Drop.reject"} />
          </Button>
          <Button
            id="QA_send_instruction"
            variant="contained"
            color="primary"
            type="submit"
            className={classes.btnYes}
            data-testid="confirm_button_yes"
            onClick={handleSubmit}>
            <FormattedMessage id={"WaitingRoom.Drop.confirm"} />
          </Button>
        </Box>
      </Box>
      <IconButton id="QA_cancel_button" className="closeIcon" onClick={close} size="large">
        <CancelIcon style={{ color: "#E82C2A" }} />
      </IconButton>
    </Paper>
  );
}
