import React, { createContext, useCallback, useContext, useState } from "react";
import { EConsultRequestSearchStage } from "./types";

type RequestChange = { newStage: EConsultRequestSearchStage };

type RequestContextValue = {
    requestChanges: Record<string, RequestChange>,
    changeRequestStage: (id: string, newStage: EConsultRequestSearchStage) => void;
};

const RequestContext = createContext<RequestContextValue>({
    requestChanges: {},
    changeRequestStage: () => {}
});

export const RequestContextProvider: React.FC = ({ children }) => {
    const [requestChanges, setRequestChanges] = useState<Record<string, RequestChange>>({});

    const changeRequestStage = useCallback((id: string, newStage: EConsultRequestSearchStage) => {
        setRequestChanges((prevState) => ({
            ...prevState,
            [id]: { newStage },
        }));
    }, []);


    return (
        <RequestContext.Provider value={{ requestChanges, changeRequestStage }}>
            {children}
        </RequestContext.Provider>
    );
};

export const useRequestContext = () => {
    return useContext(RequestContext);
};
