import {
    Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import Controls from "admin/components/Controls/Controls";
import { AppReduxStore } from "admin/store/reducerTypes";
import React, { useEffect, forwardRef, useImperativeHandle, useState } from "react";
import { deleteProfileRoleJoinProductLicense, getProductsForOrganization, getProductsForPractice, upsertProfileRoleJoinProductLicense } from "admin/store/actions/license";
import { getUsersByOrgOrPractice, setBulkEditUserProduts, setSelectedProfileRoleID, userProductsBulkProfileRoleJoinAll } from "admin/store/actions";
import { Permissions } from "types";
import { showNotification } from "admin/store/actions/snackbar";
import { useIntl } from "react-intl";
import { UserLicensesListingProps } from "./types";
import { isNaN } from "underscore";

interface Data {
    productId: string
    name: string
    description: string
    availableLicenseCount: number | string,
    activeInitial: boolean,
    activeNew: boolean,
    code: string,
    newTotal: any,
    parentId: any,
    disabledSwitch: boolean
}

const UserLicensesListing = forwardRef(({ profileId, onProductSelectionChanged }: UserLicensesListingProps, ref) => {
    const { license, admin, auth } = useSelector((state: AppReduxStore) => state)
    const { sUser, isBulkEditUserProducts, sProfileIds, userList, /*adminWebCachePractice*/ } = admin
    const { userLicenses, allProducts, orgLicenses, practiceLicenses } = license
    const { currentProfileRoleJoinInfo } = auth
    const [products, setProducts] = useState([] as any)
    const classes = useStyles()
    const dispatch = useDispatch();
    const intl = useIntl()

    useEffect(() => {
        const activeProducts: Data[] = [];
        let allProductsFiltered = [...allProducts]
        //As an Altais Admin or Org Admin, I can only assign the following licenses to an Org Admin: Altais Academy Altais Community BSC Value Platform
        const filterProductUserLevels = ["Altais Admin I", "Altais Admin II", "Altais Admin III", "Altais Admin IV", "Org Admin I", "Org Admin II"]
        if (isBulkEditUserProducts) {
            if (currentProfileRoleJoinInfo.type === "ADMIN") {
                if (filterProductUserLevels.includes(currentProfileRoleJoinInfo.level.description)) {
                    allProductsFiltered = allProductsFiltered.filter((p: any) => p.code === "LMS" || p.code === "COMMUNITY" || p.code === "PERFORMANCE_REPORTING" || p.code === "AHA_REPORT" || p.code === "IOA_REPORT")
                }
            }
        }
        else if (sUser.userType === "ADMIN" &&
            filterProductUserLevels.includes(sUser.userLevel)) {
            allProductsFiltered = allProductsFiltered.filter((p: any) => p.code === "LMS" || p.code === "COMMUNITY" || p.code === "PERFORMANCE_REPORTING")
        }
        allProductsFiltered && allProductsFiltered.forEach((p: any, index: number) => {
            let orgLicense = null
            let practiceLicense = null
            if (admin.configurePracticeId) {
                practiceLicense = practiceLicenses && practiceLicenses.find((o: any) => o.product.id === p.id);
            } else if (auth.currentOrg?.id) {
                orgLicense = orgLicenses && orgLicenses.find((o: any) => o.product.id === p.id);
            }
            if (auth.currentPractice?.id) {
                practiceLicense = practiceLicenses && practiceLicenses.find((o: any) => o.product.id === p.id);
            }
            const userLicense = userLicenses?.products && userLicenses?.products.find((o: any) => o.id === p.id);
            const availableLicenseCount = (orgLicense ? (orgLicense.licenseCount - orgLicense.usedLicenseCount) : (practiceLicense?.licenseCount - practiceLicense?.usedLicenseCount))
            activeProducts.push({
                productId: p.id,
                name: p.name,
                description: p.description,
                availableLicenseCount: (p?.unlimited) ? "Unlimited" : isNaN(availableLicenseCount) ? "" : availableLicenseCount,
                activeInitial: isBulkEditUserProducts ? false : (userLicense ? true : false),
                activeNew: isBulkEditUserProducts ? false : (userLicense ? true : false),
                code: p.code,
                parentId: p.parentId,
                newTotal: (p?.unlimited) ? "Unlimited" : (p.licenseCount),
                disabledSwitch: false
            })
        })
        // let tempProducts = null
        // if (admin.configurePracticeId) {
            // const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData?.userProductsDetails
            // if (tempFormData) {
                // tempProducts = profileId && tempFormData[profileId]
            // }
        // }
        validateProducts(activeProducts, false)
        // checkBillingSchedule();
    }, [allProducts, isBulkEditUserProducts, orgLicenses, practiceLicenses, userLicenses, admin.configurePracticeId])

    useImperativeHandle(
        ref,
        () => ({
            saveProducts(e: any) {
                return updateProducts();
            }
        }),
    );

    const validateProducts = (updatedProducts: Data[], isSaveAction: boolean): boolean => {
        let validProducts = true
        const coreProduct = updatedProducts && (updatedProducts.find((p: any) => p.code === "CORE") as Data);
        const digitalFrontDoorProduct =
            updatedProducts && (updatedProducts.find((p: any) => p.code === "FRONT_DOOR") as Data);
        const eTranscriptionProduct =
            updatedProducts && (updatedProducts.find((p: any) => p.code === Permissions.ETRANSCRIPT) as Data);
        const careTeamProduct = updatedProducts && (updatedProducts.find((p: any) => p.code === "CARE_TEAM") as Data);

        // KEPLER-955 License Rules for Users
        // If Core Platform if not assigned, then Digital Front Door, Care Team Communications, and eTranscription are Disabled
        if (coreProduct && !coreProduct.activeNew) {
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.activeNew = false
                digitalFrontDoorProduct.disabledSwitch = true
            }
            if (careTeamProduct) {
                careTeamProduct.activeNew = false
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.activeNew = false
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // When Core Platform is assigned, then Digital Front Door is also enabled and assigned
        if (coreProduct && coreProduct.activeNew) {
            if (digitalFrontDoorProduct) {
                digitalFrontDoorProduct.activeNew = true
                digitalFrontDoorProduct.disabledSwitch = false
            }

            // When Core Platform is assigned, then Care Team Communications and eTranscription become enabled, but NOT assigned
            if (careTeamProduct) {
                careTeamProduct.disabledSwitch = false
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct.disabledSwitch = false
            }

        }
        // If either Core Platform OR Digital Front Door are unassigned, 
        // then Care Team Communications and eTranscriptions become unassigned, 0 licenses, and disabled
        if ((coreProduct && !coreProduct.activeNew) || (digitalFrontDoorProduct && !digitalFrontDoorProduct.activeNew)) {
            if (careTeamProduct) {
                careTeamProduct["activeNew"] = false
                careTeamProduct["newTotal"] = 0
                careTeamProduct.disabledSwitch = true
            }
            if (eTranscriptionProduct) {
                eTranscriptionProduct["activeNew"] = false
                eTranscriptionProduct["newTotal"] = 0
                eTranscriptionProduct.disabledSwitch = true
            }
        }
        // If Core Platform is enabled, it must have a license count of greater than 0
        if (isSaveAction && coreProduct && coreProduct.activeNew && coreProduct.newTotal !== "Unlimited" && parseInt(coreProduct.newTotal ? coreProduct.newTotal.toString() : "0") === 0) {
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "OrgProductsEdit.CoreProductCountGreaterThanZero" })));
            validProducts = false
        }
        if (profileId) {
            onProductSelectionChanged(profileId, [...updatedProducts])
        }
        setProducts(updatedProducts)
        return validProducts
    }

    const handleToggleSwitchChange = (checked: boolean, row: any) => {
        const productsCopy = [...products];
        for (let i = 0; i < productsCopy.length; i++) {
            if (productsCopy[i].productId === row.productId) {
                productsCopy[i]["activeNew"] = checked
                break
            }
        }
        setProducts(productsCopy)
        validateProducts(productsCopy, false)
    }

    const onRefeshUserProducts = () => {
        if (auth.currentOrg?.id) {
            dispatch(getProductsForOrganization(auth.currentOrg?.id))
        }
        if (sUser?.id) {
            if (auth.currentPractice?.id) {
                dispatch(getProductsForPractice(auth.currentPractice?.id))
            }
            dispatch(setSelectedProfileRoleID(sUser?.id));
            dispatch(setBulkEditUserProduts(false));
        }
        dispatch(getUsersByOrgOrPractice())
    }
    const updateProducts = () => {
        if (isBulkEditUserProducts) {
            if (admin.isAllSelectedBulk) {
                const productsUpdated = products.filter((p: any) => p.activeNew).map((p: any) => p.productId)
                dispatch(userProductsBulkProfileRoleJoinAll(productsUpdated))
            }
            else {
                const sProfileJoinIds = sProfileIds && sProfileIds.map((sProfileId: any) => {
                    const u = userList && userList.find((u: any) =>
                        (u.profileId === sProfileId)
                    )
                    return u.profileRoleId
                })
                sProfileJoinIds.forEach((profileRoleId: any, index: number) => {
                    updateProductByProfileRoleId(profileRoleId, index + 1 === sProfileJoinIds.length)
                })
            }

        }
        else {
            updateProductByProfileRoleId(sUser.profileRoleId, true)
            // updateBillingGroups();
        }
        return false
    }
    const updateProductByProfileRoleId = (profileRoleId: string, last: boolean) => {
        const productsUpdated = products.filter((p: any) => p.activeInitial !== p.activeNew)
        productsUpdated && productsUpdated.map((p: any, index: number) => {
            if (p.activeNew) {
                dispatch(upsertProfileRoleJoinProductLicense(p.productId, profileRoleId, last && index + 1 === productsUpdated.length ? onRefeshUserProducts : null))
            } else {
                dispatch(deleteProfileRoleJoinProductLicense(p.productId, profileRoleId, last && index + 1 === productsUpdated.length ? onRefeshUserProducts : null))
            }
            return p
        })
    }

/*
    type BillingGroupStateType = {
        [key: string]: boolean
    }

    const [selectedBillingGroups, setSelectedBillingGroups] = useState<BillingGroupStateType>({});
    const hasBillingSchedule = products.some((product: any) => (product.code === 'BILLING_SCHEDULE'));
    const checkBillingSchedule = (): void => {
        const orgId = auth.currentProfileRoleJoinInfo?.organization?.id;
        const profileRoleJoinId = sUser?.profileRoleId;
        if (orgId && profileRoleJoinId && hasBillingSchedule) {
            dispatch(getAvailableFeeScheduleGroups(orgId, profileRoleJoinId));
            dispatch(getFeeScheduleGroups(undefined, profileRoleJoinId));
        }
    };

    const handleBillingGroupToggleSwitchChange = (checked: boolean, row: any) => {
        const newBillingGroups = Object.assign({}, selectedBillingGroups);
        newBillingGroups[row.id] = checked;
        setSelectedBillingGroups(newBillingGroups);
    }

    const updateBillingGroups = (): void => {
        if (hasBillingSchedule) {
            const addBillingGroupIds = Object.entries(selectedBillingGroups).filter((selectedBillingGroup) => {
                return (selectedBillingGroup[1] === true) &&
                    !(feeScheduleGroups.some((feeScheduleGroup: any) => feeScheduleGroup.id === selectedBillingGroup[0]))
            }).map((selectedBillingGroup) => { return selectedBillingGroup[0] });
            const removeBillingGroupIds = feeScheduleGroups.filter((feeScheduleGroup: any) => {
                return !(selectedBillingGroups[feeScheduleGroup.id])
            }).map((feeScheduleGroup: any) => { return feeScheduleGroup.id });
            const profileRoleJoinId = auth.currentProfileRoleJoinInfo?.id;
            if (addBillingGroupIds.length > 0) {
                dispatch(setFeeScheduleGroups(addBillingGroupIds, profileRoleJoinId));
            }
            if (removeBillingGroupIds.length > 0) {
                dispatch(deleteFeeScheduleGroups(removeBillingGroupIds));
            }
        }
    }

    useEffect(() => {
        if (feeScheduleGroups && feeScheduleGroups.length > 0) {
            const newBillingGroups: any = Object.assign({}, selectedBillingGroups);
            feeScheduleGroups.forEach((billingGroup: any) => {
                newBillingGroups[billingGroup.id] = true;
            });
            setSelectedBillingGroups(newBillingGroups);
        }
    }, [feeScheduleGroups]);
*/

    return (<>
        {products?.length > 0 && <Box>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell key={"name"} align={'left'} padding="normal">
                                    {'Products'}
                                </TableCell>
                                <TableCell key={"availableLicenses"} align={'left'} padding="normal">
                                    {'Available Licenses'}
                                </TableCell>
                                <TableCell
                                    key={"action"}
                                    align={'left'}
                                    padding="normal">
                                    {'Activate'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products && products.map((row: Data, index: number) => {
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell style={{ paddingLeft: (row.parentId ? "60px" : "") }}>
                                            <Box> {row.name}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {row.availableLicenseCount}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Controls.ToggleSwitch
                                                label={""}
                                                checked={row.activeNew}
                                                onChange={(e: any) => { handleToggleSwitchChange(e.target.checked, row) }}
                                                value={""}
                                                disabled={row.disabledSwitch}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* hasBillingSchedule && availableFeeScheduleGroups && availableFeeScheduleGroups.map((row: Data, index: number) => {
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell style={{ paddingLeft: "60px" }}>
                                            <Box> {row.name}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box> {`Unlimited`}</Box>
                                        </TableCell>
                                        <TableCell>
                                            <Controls.ToggleSwitch
                                                label={""}
                                                checked={row.activeNew}
                                                onChange={(e: any) => { handleBillingGroupToggleSwitchChange(e.target.checked, row) }}
                                                value={""}
                                                disabled={row.disabledSwitch}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>}
    </>
    )
})

export default UserLicensesListing