import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "../../../theme";

export const useStyles = makeStyles(() => ({
    root:{
      position:'relative',
      minHeight:'10rem'
    },
    button:{
        height:SIZING.scale1000,
        fontSize:SIZING.scale400,
        "&:hover":{
          background:COLORS.SECONDARY_BUTTON
        },
        background:COLORS.SECONDARY_BUTTON,
        marginRight:SIZING.scale400,
        "& svg":{
          "& path":{
            fill:COLORS.INPUT_BG
          }
        },
        marginTop:SIZING.scale1000,
        marginBottom:SIZING.scale1000,
  
      },
      startEvent:{
        color:COLORS.INPUT_BG,
        fontWeight:'normal'
      },
      unsetFontWeight:{
        fontWeight:'normal'
      },
      patientDetails:{
          color:COLORS.LIGHT_BLUE,
          marginTop:SIZING.scale200,
          fontWeight:600,
          display:'flex',
          cursor:'pointer',
          alignItems:'center',
          "& svg":{
            fontSize:SIZING.scale400,
            color:'rgba(15, 123, 189, 0.4)',
            marginRight:SIZING.scale100
          }
          
      }
}));