import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
    icon: {
        marginRight: SIZING.scale200
    },
    starIcon: {
        fontSize: SIZING.scale400,
        verticalAlign: 'middle'
    },
    preferenceText: {
        fontSize: SIZING.scale250,
        color: COLORS.DARK_ICON,
    },
    cancelDefaultProfilePreference: {
        marginLeft: SIZING.scale50,
        cursor: 'pointer'
    },
    verifyProfileText: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "140%",
        color: "#007AFF",
        cursor:"pointer"
    }
}));