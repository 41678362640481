import React from "react";
import {Box, Input as MaterialInput, Typography} from "@mui/material";
import {InputProps} from "./types";
import {useStyles} from "./styles";
import {FormattedMessage} from "react-intl";
import {SIZING} from "theme";
import WarningIcon from "@mui/icons-material/Warning";

// Input wraps material ui input with altais styling
const Input = (props: InputProps) => {
    const { isSearch, id, errorId, error, ...inputProps } = props

    // search inputs have alternate style to other text inputs.
    const classes = useStyles({
        isSearch
    })

    return (
        <Box marginBottom={SIZING.scale400}>
            <MaterialInput
                classes={{root: classes.root}}
                error={!!errorId || error}
                disableUnderline
                {...inputProps}
                id={id}
            />
            {errorId && (
                <Typography className={classes.error} data-testid="validationError" variant="h6">
                   <WarningIcon /> <FormattedMessage id={errorId} />
                </Typography>
            )}
        </Box>
    )
}

export default Input