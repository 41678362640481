import React, { useState, useEffect } from "react";
import {Box, Button, Typography, Checkbox, FormControlLabel } from "@mui/material";
import {FormattedMessage, FormattedDate} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {AppReduxStore} from "admin/store/reducerTypes";
import {fetchUserInfo, setFeedbackLoop} from "admin/store/actions";
import Spinner from "common/components/Spinner/Spinner";
import {useStyles} from "./styles";
import LayoutExtr from "admin/components/Layout/LayoutExtr";
import {MatchParams} from "./types";
import {useParams,useHistory} from "react-router-dom";
import { createSvgIcon } from "utils";
import {ReactComponent as Flagged} from "icon-library/SVG/Flag.svg";
import {ReactComponent as UnFlagged} from "icon-library/SVG/Flag-Light.svg";
import {formatPhoneNumber} from "utils";

const VerifyInfo = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const FlaggedIcon = createSvgIcon(Flagged); //#F50057
    const UnFlaggedIcon = createSvgIcon(UnFlagged); //#139AED
    const intl = useIntl();
    const {regToken} = useParams<MatchParams>();
    const classes = useStyles();
    const {userInfo, loading} = useSelector((store: AppReduxStore) => store.registration);
    const [feedbackFieldList, setFeedbackFieldList] = useState<any>([]);

    const isChecked = (name: string) => {
        return feedbackFieldList.findIndex((item: any) => item.fieldName === name) === -1 ? false : true;
    }

    const submitFeedback = () => {
        dispatch(setFeedbackLoop({
            feedbackFieldList: feedbackFieldList,
            patientProfileId: userInfo.id,
            registrationToken: regToken
        }));
        history.push(`/patient/verify/${regToken}/feedback`);
    };

    const handleChange = (checked: boolean, name: string, type: string, value: string[]) => {
        let tempArray = [...feedbackFieldList];
        const found = tempArray.find((item: any) => item.fieldName === name);
        if(found) {
            tempArray = tempArray.filter((item: any) => item.fieldName !== name); //Remove previous to un check
        } else {
            tempArray.push({
                fieldName: name,
                fieldType: type,
                fieldValue: value.join(',')
            });
            checked = true;
        }
        setFeedbackFieldList(tempArray);
    };

    const UserInfoResults = () => {
        if (loading) {
            return <Spinner/>
        } else if (userInfo) {
            const valueForLegalName = (userInfo.firstName || '--') + ' ' + (userInfo.lastName || '--')
            const valueForDOB = userInfo.dateOfBirth ? (<FormattedDate value={new Date(userInfo.dateOfBirth)} year="numeric" month="short" day="numeric" />) : '--'
            const getValueForInsurance = () => {
                const primaryInsurance = userInfo && userInfo.insurances && userInfo.insurances[0];
                const insuranceName = primaryInsurance?.planName || userInfo.insuranceName || '--';
                const insuranceType = primaryInsurance?.planType || userInfo.insuranceType || '--';
                const insuranceId = userInfo.insuranceId || '--';
                return (
                    <>
                        <FormattedMessage id="VerifyInfo.label.insuranceName"/> {insuranceName} <br/>
                        <FormattedMessage id="VerifyInfo.label.insuranceType"/> {insuranceType} <br/>
                        <FormattedMessage id="VerifyInfo.label.insuranceID"/> {insuranceId} <br/>
                    </>
                )
            }
            const getValueForAddress = () => {
                return (
                    <>
                        {userInfo.address ? (
                            <>
                                {userInfo.address}<br/>
                                {userInfo.city}, {userInfo.state}, {userInfo.zipcode}
                            </>
                        ):(
                            <>
                                --
                            </>
                        )}
                    </>
                )
            }
            const valueForPhone = formatPhoneNumber(userInfo.phoneNumber || userInfo.homePhoneNumber || userInfo.cellPhoneNumber || '--')
            const valueForGender = userInfo.sex || userInfo.genderCode || '--'
            const valueForEmail = userInfo.primaryEmailAddress || userInfo.emailAddress || '--'
            const valueForLanguage = userInfo.primaryLanguage || <FormattedMessage id="userInfo.primaryLanguage" defaultMessage="English"/>

            return (
                <>
                    <Box className={classes.infoBox}>
                        <table className={classes.infoTable} id="Demographics-PRIMARY">
                            <thead>
                            <tr>
                                <th colSpan={3}><FormattedMessage id="VerifyInfo.subject.basicInfo"/></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th><FormattedMessage id="VerifyInfo.label.LegalName"/></th>
                                <td className={classes.flagLabel}>
                                    <FormControlLabel label={valueForLegalName}
                                                      control={<Checkbox icon={<UnFlaggedIcon fill="#139AED"/>} checkedIcon={<FlaggedIcon fill="#F50057"/>}
                                                                         checked={isChecked('LegalName')}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, event.target.name, 'Basic', [ userInfo.firstName, userInfo.lastName ])}
                                                                         name="LegalName" />} />
                                </td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id="VerifyInfo.label.DOB"/></th>
                                <td className={classes.flagLabel}>
                                    <FormControlLabel label={valueForDOB}
                                                      control={<Checkbox icon={<UnFlaggedIcon fill="#139AED"/>} checkedIcon={<FlaggedIcon fill="#F50057"/>}
                                                                         checked={isChecked('DOB')}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, event.target.name, 'Basic', [ userInfo.dateOfBirth ])}
                                                                         name="DOB" />} />
                                </td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id="VerifyInfo.label.insurance"/></th>
                                <td className={classes.flagLabel}>
                                    <FormControlLabel label={getValueForInsurance()}
                                                      control={<Checkbox icon={<UnFlaggedIcon fill="#139AED"/>} checkedIcon={<FlaggedIcon fill="#F50057"/>}
                                                                         checked={isChecked('insurance')}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, event.target.name, 'Basic', [ userInfo.insuranceName, userInfo.insuranceType, userInfo.insuranceId ])}
                                                                         name="insurance" />} />
                                </td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id="VerifyInfo.label.address"/></th>
                                <td className={classes.flagLabel}>
                                    <FormControlLabel label={getValueForAddress()}
                                                      control={<Checkbox icon={<UnFlaggedIcon fill="#139AED"/>} checkedIcon={<FlaggedIcon fill="#F50057"/>}
                                                                         checked={isChecked('address')}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, event.target.name, 'Basic', [ userInfo.address,userInfo.city,userInfo.state, userInfo.zipcode ])}
                                                                         name="address" />} />
                                </td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id="VerifyInfo.label.contact"/></th>
                                <td className={classes.flagLabel}>
                                    <FormControlLabel label={valueForPhone}
                                                      control={<Checkbox icon={<UnFlaggedIcon fill="#139AED"/>} checkedIcon={<FlaggedIcon fill="#F50057"/>}
                                                                         checked={isChecked('contact')}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, event.target.name, 'Basic', [ userInfo.phoneNumber,userInfo.homePhoneNumber,userInfo.cellPhoneNumber ])}
                                                                         name="contact" />} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Box>
                    <Box className={classes.infoBox}>
                        <table className={classes.infoTable} id="Demographics-SECONDARY">
                            <thead>
                            <tr>
                                <th colSpan={2}><FormattedMessage id="VerifyInfo.subject.demoInfo"/></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th><FormattedMessage id="VerifyInfo.label.genderCode" /></th>
                                <td className={classes.flagLabel}>
                                    <FormControlLabel label={valueForGender}
                                                      control={<Checkbox icon={<UnFlaggedIcon fill="#139AED"/>} checkedIcon={<FlaggedIcon fill="#F50057"/>}
                                                                         checked={isChecked('genderCode')} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, event.target.name, 'Demographics', [ userInfo.genderCode ])}
                                                                         name="genderCode" />} />
                                </td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id="VerifyInfo.label.email" /></th>
                                <td className={classes.flagLabel}>
                                    <FormControlLabel label={valueForEmail}
                                                      control={<Checkbox icon={<UnFlaggedIcon fill="#139AED"/>} checkedIcon={<FlaggedIcon fill="#F50057"/>}
                                                                         checked={isChecked('emailAddress')}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, event.target.name, 'Demographics', [ userInfo.emailAddress ])}
                                                                         name="emailAddress" />} />
                                </td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id="VerifyInfo.label.language" /></th>
                                <td className={classes.flagLabel}>
                                    <FormControlLabel label={valueForLanguage}
                                                      control={<Checkbox icon={<UnFlaggedIcon fill="#139AED"/>} checkedIcon={<FlaggedIcon fill="#F50057"/>}
                                                                         checked={isChecked('language')}
                                                                         onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, event.target.name, 'Demographics', [ userInfo.language ])}
                                                                         name="language" />} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Box>
                </>
            )
        }
        return <Spinner/>
    }

    useEffect(() => {
        dispatch(fetchUserInfo(regToken));
    }, [dispatch, regToken]);

    return (
        <LayoutExtr heading={intl.formatMessage({ id: "VerifyInfo.heading.verifyInfo" })}
                    subHeading={intl.formatMessage({ id: "VerifyInfo.subheading.verifyInfo" })}>

                <UserInfoResults />
                <Button onClick={() => { submitFeedback() } } className={classes.navBtn} variant="outlined" color="primary" fullWidth>
                    <Typography variant="button">
                        <FormattedMessage id="VerifyInfo.button.navBtn"/>
                    </Typography>
                </Button>
        </LayoutExtr>
    )
}

export default VerifyInfo