import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import { ValueOf } from "types";
import { AppReduxStore } from "store/reducerTypes";

export const ErrorTypes = {
    ERROR: 'error',
    SUCCESS: 'success',
    WARNING: 'warning',
} as const;

// setError saves the latest
export const setError = (messageId: string, errorText?:any, errorDesc?:any, errorType?: ValueOf<typeof ErrorTypes>) => {
    // TODO AppThunk should be provided everywhere - there would be issues in different applications
    return (dispatch: ThunkDispatch<{} | AppReduxStore, {} | unknown, AnyAction>) => {
        dispatch({type: "SET_ERROR", payload: {errorText, errorDesc, messageId, errorType}})
    }
}
