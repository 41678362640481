import React from "react";
import {Box} from "@mui/material";
import {ReactComponent as Admin} from "icon-library/SVG/Shield.svg"
import {ReactComponent as Provider} from "icon-library/SVG/Stethoscope.svg"
import {ReactComponent as Patient} from "icon-library/SVG/User_Single.svg"
import {USER_PROFILE, USER_TYPE} from "constant";
import { ConversationParticipant, PatientProfile } from "store/chat-types";
import { createSvgIcon } from "utils";
import { getParticipantAdditionalInfo, getParticipantFullName } from "components/messaging/utils";
import CustomTypography from "../CustomTypography/CustomTypography";
import {useStyles} from "./styles";
import {useHistory} from "react-router-dom";
import classNames from "classnames";

type ParticipantNameProps = {
    className?: string;
    displayFullInfo?: boolean;
    hyperlink?: boolean;
    participant: ConversationParticipant | undefined;
};

const userIcon = {
    [USER_TYPE.ADMIN]: createSvgIcon(Admin),
    [USER_TYPE.PROVIDER]: createSvgIcon(Provider),
    [USER_TYPE.PATIENT]: createSvgIcon(Patient),
    [USER_TYPE.SUPPORT_CONTACT]: createSvgIcon(Patient)
}

// ParticipantName renders the name of a participant in a conversation along with additional info (eg mrn, dateOfBirth)
// for patients.
const ParticipantName = ({ className, displayFullInfo = true, hyperlink = true, participant }: ParticipantNameProps) => {
    const type = participant?.userType || ""; // TODO Temporary fix for undefined
    const classes = useStyles({type,hyperlink})
    const history = useHistory();
    if (!participant) {
        return <div className={className} />;
    }
    const Icon = userIcon[type];
    const name = getParticipantFullName(participant);
    const profile = participant[USER_PROFILE[type]];
    const additionalInfo =
        type === USER_TYPE.PATIENT && displayFullInfo ? ` (${getParticipantAdditionalInfo(participant)})` : "";

    const selectItem = () => {
        hyperlink &&
            type === USER_TYPE.PATIENT &&
            history.push(`/provider/people/patients/${(profile as PatientProfile).id}`);
    };

    return (
        <Box className={classNames(classes.wrapper, className)} onClick={selectItem} id={"QA_participant"+type}>
            <Icon className={classes.icon}/>
            <CustomTypography className={classes.name} variant="participantName">
                {name}{additionalInfo}
            </CustomTypography>
        </Box>
    )
}

export default ParticipantName;
