import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "../../../theme";


export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "960px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    dlgAction: {
        padding: '16px 32px'
    },
    confirmationBody: {
        color: '#393948',
        fontSize: '24px',
        fontWeight: 600
    },
    confirmationDesc: {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 400,
        marginTop: '40px'
    }
}));