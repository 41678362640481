import { makeStyles } from "@mui/styles";
import {COLORS} from "../../../admin/theme";

export const useStyles = makeStyles(() => ({
    pageWrapper: {
        backgroundColor: '#F0F0F2',
        margin: 0,
        padding: 0,
        userSelect: 'none',
        width: '100vw',
        height: '100vh',
        paddingTop: '5rem',
        fontSize: '16px',
        '& a:link, & a:visited': {
            color: '#007AFF',
            textDecoration: 'none',
            borderBottom: '1px solid transparent',
            transition: 'all ease 128ms'
        },
        '& a:hover': {
            borderBottom: '1px solid #00D0FF',
        },
        '& ul': {
            margin: "2.4em 0 0 0",
            color: "#CCC",
            listStyleType: "square"
        },
        '& li': {
            margin: "0 0 .6em -1em",
            padding: "0 0 0 .5em"
        },
        '& hr': {
            background: '#E0E0E2',
            border: 0,
            height: '1px',
            margin: '1.5em 0 1.5em -.5em'
        },
        '& h1, & p': {
            marginBottom: '1.2em'
        },
        '& p': {
            fontSize: '16px'
        }
    },
    contentContainer: {
        width: "600px",
        margin: "0 auto",
        padding: "2em",
        backgroundColor: "#FDFDFF",
        xOverflow: "hidden",
        position: "relative",
        borderRadius: "1em",
        boxShadow: "0 8px 32px -4px rgba(0, 0, 50, .1), 0 4px 8px -2px rgba(50, 0, 0, .05)",
        transition: "all ease 128ms",
        boxSizing: "content-box"
    },
    '@media (max-width: 700px)': {
        contentContainer: {
            margin: '0 auto',
            width: 'auto',
            borderRadius: 0
        }
    },
    title: {
        fontWeight: 100,
        color: COLORS.ERROR_TEXT,
        fontSize: '2em'
    },
    ribbon: {
        width: "108px",
        height: "108px",
        overflow: "hidden",
        position: "absolute",
        top: "-6px",
        right: "-6px",
        "& label": {
            backgroundColor: "#FFC340",
            backgroundImage: "radial-gradient(circle farthest-side, #FFC340, #F5A800)",
            color: "#FFF",
            fontWeight: "800",
            textAlign: "center",
            textTransform: "upperCase",
            transform: "rotate(45deg)",
            position: "relative",
            padding: "7px 0",
            left: "-4px",
            display: "inline-block",
            top: "26px",
            width: "150px",
            letterSpacing: ".4em",
            boxShadow: "0 5px 5px -5px rgba(0, 0, 50, .3), inset 1px 0 0 1px #FFC240",
            outline: "1px dashed rgba(255,255,255, .3)",
            outlineOffset: "-4px",
            textShadow: "0 0 10px rgba(0,0,0,.2)",
            lineHeight: '18px'
        },
        '& label:before, & label:after': {
            content: '""', // should be double quotes enclosed by single quotes, otherwise it compiles to blank.
            position: "absolute",
            top: "100%",
            zIndex: "-1"
        },
        "& label:before": {
            left: "0",
            borderLeft: "4px solid #C48700",
            borderRight: "4px solid transparent",
            borderBottom: "4px solid transparent",
            borderTop: "4px solid #C48700"
        },
        "& label:after": {
            right: "7px",
            borderLeft: "4px solid transparent",
            borderRight: "4px solid #C48700",
            borderBottom: "4px solid transparent",
            borderTop: "4px solid #C48700",
        }
    }
}));
