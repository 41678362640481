import classNames from "classnames";
import { ComponentLoaderProps } from "./types";
import {useStyles} from "./styles";
import { ReactComponent as Loader } from "icon-library/SVG/Wait_Ring-Thick.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";

// ComponentLoader is used to show a loading spinner to indicate a process is loading data
const ComponentLoader = ({ light, disableBackdrop }: ComponentLoaderProps) => {
    const classes = useStyles();

    return (
        <div
            className={classNames(classes.wrapper, "component-loader-wrapper", {
                light,
                disableBackdrop,
            })}
            data-testid="component-loader"
        >
            <SVGIcon svg={Loader}  size="scale2000" />
        </div>
    );
};

export default ComponentLoader;
