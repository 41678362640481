import { BORDER_RADIUS, COLORS, SIZING, TYPOGRAPHY } from "theme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  inputWithTranscription: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    "&.inputWithTranscription--transcribe-enabled .inputWithTranscription__input": {
      marginRight: SIZING.scale200
    },
    "& .InputWithTranscription__microphone": {
        width: SIZING.scale800,
        cursor: "pointer",
        height: SIZING.scale800,
        position: "relative",
        background: COLORS.DEFAULT_BTN,
        marginLeft: `-${SIZING.scale800}`,
        borderRadius: SIZING.scale400,
        marginBottom: SIZING.scale200
    }
  },
  inputLabel: {
    marginLeft: SIZING.scale200,
    textTransform: "uppercase",
    ...TYPOGRAPHY.conversationPatientName
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  microphone: {
    marginLeft: `-${SIZING.scale800}`,
    marginBottom: SIZING.scale200,
    cursor: "pointer",
    position: "relative",
    borderRadius: SIZING.scale400,
    background: COLORS.LBLUE,
    height: SIZING.scale800,
    width: SIZING.scale800,
    "& path": {
      fill: "rgba(255, 255, 255, .6)"
    },
    "&.active": {
        background: COLORS.PRIORITY_BG
    },
    "&.disabled": {
        background: COLORS.DISABLED_BTN,
        "& path": {
          fill: COLORS.DISABLED_BTN_TEXT
        }
    }
  },
  input: {
    margin: `0 ${SIZING.scale200} ${SIZING.scale400} 0`,
    borderRadius: BORDER_RADIUS.MEDIUM,
    alignItems: "stretch",
    padding: `${SIZING.scale175} 0`,
    "& textarea, & input": {
      padding: `${SIZING.scale100} ${SIZING.scale500} ${SIZING.scale100} ${SIZING.scale300}`,
      fontSize: SIZING.scale400,
      color: COLORS.LIST_SUBHEADER_TEXT,
      textIndent: "unset"
    },
    "& textarea": {
      minHeight: SIZING.scale500
    },
    "& input": {
      minHeight: SIZING.scale900
    },
    "&.Mui-error": {
      borderColor: COLORS.INPUT_ERROR_BORDER_COLOR
    }
  }
}));
