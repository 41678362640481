import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
        reconnectinWrapper:{
            position:'absolute',
            height:'100%',
            width:'100%',
            zIndex:1,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            fontSize:SIZING.scale500,
            color:COLORS.WHITE,
            background:COLORS.BLACK
        }
}));