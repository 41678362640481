import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "../../../theme";


export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    dlgAction: {
        padding: '16px 32px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    table: {
        minWidth: 750,
    },
    stickyHeader: {
        overflowX: "initial",
        zIndex: 2
    }
}));