import {
    Box, DialogActions, DialogContent, Grid, IconButton, Paper, Table, TableBody,
    TableCell, TableContainer, TableFooter, TableHead, TableRow,
    TableSortLabel, Typography
} from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";

type Order = 'asc' | 'desc';

type RegionInfoProps = {
    description: string,
    id: string,
    name: string,
    organizationId: string,
    practiceId?: string,
    status?: string
};

type FeeScheduleDetailsProps = {
    billingGroupInfos?: BillingGroupInfoProps[],
    effectiveDate: string
    expireDate: string
    fileName: string
    id: string
    lastModifiedDate: string
    name: string
    organizationId: string
    practiceId?: string
    regionInfos: RegionInfoProps[]
};

type BillingGroupInfoProps = {
    billingSchedules: FeeScheduleDetailsProps[]
    description: string
    id: string
    name: string
    organizationId: string
    practiceId?: string
    regionInfos?: string
    status: string
};

const useFeeSchedulesListing = (billingGroupId: string, orderBy?: keyof FeeScheduleDetailsProps, order?: Order) => {
    const sortStr = (orderBy && order) ? `?sortedBy=${orderBy}&sortAsc=${(order === 'asc') ? true : false}` : "";
    const { content, fetchInfo, request } = useApi<{}, BillingGroupInfoProps | null>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: null,
        url: `/admin/v1/billing/group/${billingGroupId}${sortStr}`,
        method: HttpMethod.GET
    });

    useEffect(() => {
        if (billingGroupId)
            request({});
    }, [billingGroupId, request, orderBy, order]);

    return {
        feeSchedulesListing: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};

const FeeSchedulesViewing = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { modalAdmin } = useSelector((state: AppReduxStore) => state);
    const billingGroupId = modalAdmin?.modalProps?.["view-fee-schedule-billingGroupId"];
    const billingGroupName = modalAdmin?.modalProps?.["view-fee-schedule-billingGroupName"];
    const [orderBy, setOrderBy] = useState<keyof FeeScheduleDetailsProps>('name');
    const [order, setOrder] = useState<Order>('asc');
    const { feeSchedulesListing, hasResult } = useFeeSchedulesListing(billingGroupId, orderBy, order);
    const feeScheduleList: FeeScheduleDetailsProps[] = feeSchedulesListing?.billingSchedules || [];

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof FeeScheduleDetailsProps) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: keyof FeeScheduleDetailsProps) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const getDisplayDate = (unixDate: string) => {
        if (unixDate) {
            const date = new Date(unixDate);
            return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
        } else {
            return "N/A";
        }
    }

    const onClose = () => {
        dispatch(setActiveModal(""));
    }

    return (
        <Modal onClose={onClose} classes={classes.wrapper} id={MODAL_TYPES.FEE_SCHEDULES_VIEWING_MODAL}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {`Fee Schedules for ${billingGroupName}`}
                    </Typography>
                </Box>
                <Box>
                    <IconButton size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper style={{ width: '900px', height: '400px' }} >
                    <TableContainer className={classes.stickyHeader}>
                        <Table id="tableFeeSchedulesListing"
                            stickyHeader
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        width={450}
                                        key={"name"}
                                        align={'left'}
                                        padding="normal"
                                        sortDirection={orderBy === "name" ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === "name"}
                                            direction={orderBy === "name" ? order : 'asc'}
                                            onClick={createSortHandler("name")}>
                                            <strong style={{ marginLeft: "41px" }}>
                                                {'Fee Schedule Name'}
                                            </strong>
                                            {orderBy === "name" ?
                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        width={225}
                                        key={"effectiveDate"}
                                        align={'left'}
                                        padding="normal"
                                        sortDirection={orderBy === "effectiveDate" ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === "effectiveDate"}
                                            direction={orderBy === "effectiveDate" ? order : 'asc'}
                                            onClick={createSortHandler("effectiveDate")}
                                        >
                                            <strong>{'Effective Date'}</strong>
                                            {orderBy === "effectiveDate" ?
                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        width={225}
                                        key={"lastModifiedDate"}
                                        align={'left'}
                                        padding="normal"
                                        sortDirection={orderBy === "lastModifiedDate" ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === "lastModifiedDate"}
                                            direction={orderBy === "lastModifiedDate" ? order : 'desc'}
                                            onClick={createSortHandler("lastModifiedDate")}
                                        >
                                            <strong>{'Update Date'}</strong>
                                            {orderBy === "lastModifiedDate" ?
                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted ascending' : 'sorted descending'}</span>) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    feeScheduleList.map((row: FeeScheduleDetailsProps, index: number) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (<>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell id={labelId} scope="row" padding="none" width={450}>
                                                    <Grid style={{ display: "flex", alignItems: "center", flexBasis: 'none' }}>
                                                        <Typography noWrap variant="body1" style={{ marginLeft: "41px", color: '#393948', width: '250px', fontSize: '14px', fontWeight: 600 }}>{row.name}</Typography>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align="left" width={225}>{`${getDisplayDate(row.effectiveDate)}`}</TableCell>
                                                <TableCell align="left" width={225}>{`${getDisplayDate(row.lastModifiedDate)}`}</TableCell>
                                            </TableRow>
                                        </>
                                        );
                                    })
                                }
                            </TableBody>
                            <TableFooter>
                                {hasResult && feeScheduleList && feeScheduleList.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default FeeSchedulesViewing