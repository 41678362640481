import { useState, useCallback } from "react";
import { Filter } from "lib/ui-components/filtered-search-input";
import { getInitialPage, PageRequest, PageResponse, Sort, SortDirect } from "lib/domain/service-types";
import { PaginationProps } from "lib/ui-components/pagination";
import { ProviderSearchContent } from "./types";
import { ProviderSearchOptions } from "./types";

const getDefaultSort = (): Sort => ({ direction: SortDirect.ASC, field: "last-name" });

export type ProviderSearchController = {
    searchProps: {
        page: PageRequest;
        filters: Filter[] | null;
        sorting: Sort[];
        options?: ProviderSearchOptions;
        onResult: (result: ProviderSearchContent) => void;
    };
    filterProps: {
        options?: ProviderSearchOptions;
        onFiltersChanged: (filters: Filter[]) => void;
    };
    paginationProps: PaginationProps;
    // TODO: complete the sort props when we start having more than one possible sort
};

/**
 * Hook to orchestrate state for ProviderSearch / ProviderSearchFilters and Pagination
 * and, in the future, sorting
 */
export const useProviderSearchController = (options?: ProviderSearchOptions): ProviderSearchController => {
    const [page, setPage] = useState<PageRequest>(getInitialPage());
    const [pageResponse, setPageResponse] = useState<PageResponse | null>(null);
    const [filters, setFilters] = useState<Filter[] | null>(null);
    const [sorting] = useState<Sort[]>([getDefaultSort()]);

    const handleFilterChange = useCallback(
        (filters: Filter[]) => {
            setFilters(filters);
            setPage(getInitialPage());
            setPageResponse(null);
        },
        [setFilters, setPage]
    );

    const handlePageChange = useCallback(
        (pageNumber: number) => {
            setPage((page) => ({ ...page, number: pageNumber }));
        },
        [setPage]
    );

    const handleResult = useCallback(
        (result: ProviderSearchContent) => {
            setPageResponse(result.page);
        },
        [setPageResponse]
    );

    return {
        searchProps: {
            page,
            filters,
            sorting,
            options,
            onResult: handleResult,
        },
        filterProps: {
            options,
            onFiltersChanged: handleFilterChange,
        },
        paginationProps: {
            pageNumber: page.number,
            pageCount: pageResponse?.totalPages || 0,
            onChange: handlePageChange,
            totalElements: pageResponse?.totalElements || null,
        },
    };
};
