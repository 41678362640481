import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross-Light_Circle.svg";
import { SearchItem } from "./types";
import { useStyles } from "./styles";
import * as CSS from "./class-names";
import classNames from "classnames";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import EmptyMessage from "common/components/empty-message/empty-message";
import SearchListItem from "./search-list-item";

type SearchListProps<ContentType extends SearchItem> = {
    className?: string;
    emptyMessage?: JSX.Element;
    itemRenderer: (item: ContentType, index: number) => JSX.Element;
    items: ContentType[] | null;
    loading: boolean;
    onItemSelect?: (id: string) => void;
    selectedItemId?: string | null;
    itemLinkGetter?: (item: ContentType) => string;
};
const SearchList = <ContentType extends SearchItem>({
    className,
    emptyMessage,
    itemRenderer,
    items,
    loading,
    onItemSelect,
    selectedItemId,
    itemLinkGetter,
}: SearchListProps<ContentType>) => {
    const styles = useStyles();

    return (
        <div className={classNames(className, styles[CSS.SearchList])}>
            {loading ? (
                <ComponentLoader />
            ) : items?.length ? (
                items.map((item, index) => (
                    <SearchListItem
                        id={`QA_searchList_item_${index}`}
                        key={`QA_searchList_item_${index}`}
                        onClick={() => onItemSelect?.(item.id)}
                        selected={Boolean(selectedItemId && item.id === selectedItemId)}
                        href={itemLinkGetter?.(item)}
                    >
                        {itemRenderer(item, index)}
                    </SearchListItem>
                ))
            ) : (
                emptyMessage ?? (
                    <EmptyMessage
                        className={CSS.SearchListEmptyMessage}
                        variant="content"
                        Icon={<CrossSvg />}
                        primaryMessage={{ id: "searchList.empty.title" }}
                    />
                )
            )}
        </div>
    );
};

export default SearchList;
