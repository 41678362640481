import createReducer from '../createReducer'
import { AuthState } from '../reducerTypes'

const initialState: AuthState = {
	userDetails: null,
	currentProfileRoleJoinInfo: null,
	currentOrg: null,
	currentPractice: null,
	currentProfiles: null
}

export default createReducer(initialState, {
	'SET_USER_DETAILS': (state: AuthState, payload: AuthState): AuthState => {
		const { userDetails } = payload
		return {
			...state,
			...payload,
			userDetails
		}
	},

	'SET_CURRENT_PROFILES': (state: AuthState, payload: AuthState): AuthState => {
		const { currentProfiles } = payload
		return {
			...state,
			...payload,
			currentProfiles
		}
	},

	'SET_CURRENT_PROFILE_ROLE_JOIN_INFO': (state: AuthState, payload: AuthState): AuthState => {
		const { currentProfileRoleJoinInfo } = payload
		return {
			...state,
			...payload,
			currentProfileRoleJoinInfo
		}
	},
	'SET_CURRENT_ORGANIZATION': (state: AuthState, payload: AuthState): AuthState => {
		const { currentOrg } = payload
		return {
			...state,
			...payload,
			currentOrg
		}
	},
	'SET_CURRENT_PRACTICE': (state: AuthState, payload: AuthState): AuthState => {
		const { currentPractice } = payload
		return {
			...state,
			...payload,
			currentPractice
		}
	}
})