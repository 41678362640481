import React from "react";
import { Typography, Grid } from "@mui/material";
import { useStyles } from "./styles";
import { IFormInput } from "admin/views/viewsTypes";
import { FormattedMessage } from "react-intl";

type Props = {
  currentForm: IFormInput;
};

const PRAPARE: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.FormLogic"} />
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            <FormattedMessage id={"RulesAndLogic.PRAPARE.Body1"} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.ScoringRules"} />
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            <FormattedMessage id={"RulesAndLogic.PRAPARE.Body2"} />
          </Typography>
        </Grid>
        </Grid>
    </div>
  );
}

export default PRAPARE