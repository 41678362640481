import { IFileWithMeta } from "react-dropzone-uploader";
import { PERCENTAGE_CALC_STATES, ERROR_STATES } from "../constants";
import { FileUploadData, FileDropzoneInputValidateFn, IMetaWithFileId } from "../types";

export const getFileUploadStatus = (file: IFileWithMeta): FileUploadData["status"] => {
    if (file.meta.status === "done") {
        return "UPLOADED";
    } else if (ERROR_STATES.includes(file.meta.status)) {
        return "ERROR";
    }
    return "UPLOADING";
};

export const getFilesForPercentageCalc = (files: IFileWithMeta[]) => {
    return files.filter((file) => PERCENTAGE_CALC_STATES.includes(file.meta.status));
};

export const getTotalPercentage = (files: IFileWithMeta[]) => {
    if (!files.length) return 0;

    const { totalSize, uploaded } = files.reduce(
        (totals, file) => {
            totals.totalSize += file.meta.size;
            totals.uploaded += file.meta.size * (file.meta.percent / 100);
            return totals;
        },
        { totalSize: 0, uploaded: 0 }
    );

    return (uploaded / totalSize) * 100;
};

export const validateUnique: FileDropzoneInputValidateFn = (fileWithMeta, allFiles) => {
    const currentFile = fileWithMeta.file;
    const match = allFiles
        .filter((f) => f.meta.status !== "preparing")
        .some(
            ({ file }) =>
                file.name === currentFile.name &&
                file.size === currentFile.size &&
                file.lastModified === currentFile.lastModified &&
                file.type === currentFile.type
        );

    if (match) {
        return { id: "files.fileDropzoneInput.validate.unique.error" };
    }
};

export const toFileUploadData = (fileWithMeta: IFileWithMeta) => ({
    id: (fileWithMeta.meta as IMetaWithFileId).fileId,
    status: getFileUploadStatus(fileWithMeta),
});

export const toFileUploadDataList = (filesWithMeta: IFileWithMeta[]) => 
    filesWithMeta
    .filter((f) => f.meta.status !== "removed")
    .map(toFileUploadData);
