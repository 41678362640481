import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "../../../theme";
export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "1278px",
        minHeight: "360px",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.XL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        padding: '20px',
        textAlign: 'center'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    dlgAction:{
        padding: '5px 10px 5px 32px'
    },
    nav:{
        textAlign:'right',
        color: "blue"
    },
    input:{
        display:'none'
    },
    fileUpload:{
        position: 'absolute',
        overflow: 'hidden',
        display: 'inline-block',
    },
    uploadBtn:{
        height:'100px',
        width: '100%',
        backgroundColor:"#E8F5FE",
        color:"#000000",
        border:'1px solid black',
        borderStyle:'dotted',
        textAlign:"center"
    },
    formControl: {
        minWidth: 120
    },
    begin:{
        marginTop:"100px",
        marginBottom:"100px"
    },
    before:{
        marginTop:"25px",
    },
    rightText:{
        float:'right'
    },
    signText:{
        fontSize: "17px",
        fontFamily: "OpenSans",
        fontWeight: "normal",
        lineHeight: "40px",
    },
    emailBtn:{
        height: '50px',
        Width: '250px',
        float:'right',
        backgroundColor:'#1978bd',
        border:'1px solid white',
        borderRadius:"25px",
        color:'white',
        fontSize: '20px',
        cursor:'pointer'
    },
    logo:{
        height:'82.88px',
        width: '220px',
        backgroundColor:"#F6F6F7",
        margin:"auto",
        color:"#000000",
        border:'1px solid black',
        borderStyle:'dotted',
        paddingTop: '30px',
        textAlign:"center"
    },
    contentDialog: {
        padding: 0
    },
    flexInline: {
        display: "inline-flex",
        lineHeight: "31px"
    },
    dropzoneClass: {
        background: '#EEF9FF',
        minHeight: "100px"
    },
    previewGridClasses: { 
        marginTop: '10px' 
    },
    logoContent: {
        width: "180px",
        border: "1px dashed #0B5D8F",
        background: "#E8F5FE",
        boxSizing: "border-box",
        minHeight: "84px",
        textAlign: "center",
        alignItems: "center",
        borderRadius: "4px",
        justifyContent: "center",
        margin:"auto"
    },
    textarea: {
        height: "70px",
        padding: "0px",
        backgroundColor:"#F6F6F7",
        width: '100%',
        color:"#000000",
        border:'1px solid black',
        borderStyle:'dotted'
    },
    body1: {
        fontSize: "17px",
        fontFamily: "OpenSans",
        fontWeight: "normal",
        lineHeight: "20px",
        height:"100px",
        overflow:"auto"
    },
    labelCtrl: {
      lineHeight: "31px"
    },
    paddingTop10: {
        paddingTop: "10px"
    }
}));