import { createGUIDString, makeApiCall } from 'admin/common/utils';
import { setConfigurePractice } from 'admin/store/actions/admin';
import { getChildOrganizationSummaries, getChildOrganizationSummariesByRootOrg } from 'admin/store/actions/organization';
import { AppReduxStore } from "admin/store/reducerTypes";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { hideLoading, showLoading } from './loading';
import { showNotification } from './snackbar';

export const fetchPractices = (searchText?: null | string) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		let orgId = ''
		if (auth.currentOrg) {
			orgId = auth.currentOrg.id
		}
		if (auth.currentPractice) {
			orgId = auth.currentPractice.organizationId
		}
		let endpoint = `/admin/v1/practice/organization/${orgId}`
		if (searchText && searchText.trim() !== "") {
			endpoint = `/admin/v1/practices?name=${searchText}`
		}
		return makeApiCall(dispatch, "GET", endpoint, {},
			"PracticeListing.Actions.FetchPractices", "PracticeListing.Actions.FetchUsersDesc").then((payload) => {
				dispatch({ type: 'SET_PRACTICES', payload: { practices: payload } })
			}).catch(err => {
				dispatch(showNotification("Get All Users", "error", String(err && err.message)))
			});
	}
}
export const insertPractice = (data: any, onPracticeInsert?: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const guid = createGUIDString();
		dispatch(showLoading(guid, "AddPractice.Progress.Title", "AddPractice.Progress.Description"));
		return await makeApiCall(dispatch, "POST", `/admin/v1/practiceWithAdmin`, data).then((payload) => {
			const { auth } = getState();
			dispatch(setConfigurePractice(payload, data?.createPracticeRequest?.name))
			dispatch(getChildOrganizationSummariesByRootOrg());
			dispatch(getChildOrganizationSummaries(auth.currentOrg?.id));
			onPracticeInsert(true);
			return payload;
		}).catch(err => {
			if (typeof onPracticeInsert === 'function') {
				onPracticeInsert(false);
			}
			dispatch(showNotification("Error", "error", String(err && err.message)));
		}).finally(() => {
			dispatch(hideLoading(guid));
		})
	}
}
// Obsolete /admin/v1/tenant api work is part of practice creation now
// export const createTenantSchema = (tenantId: string, name: string) => {
// 	let data = {
// 		tenantIds: [tenantId]
// 	}
// 	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
// 		return await call("POST", `/admin/v1/tenant/`, data).then((payload) => {
// 			const { auth } = getState();
// 			dispatch(showNotification(payload, "success", ""))
// 			dispatch(hideLoading())
// 			dispatch(setConfigurePractice(tenantId, name))
// 			dispatch(getChildOrganizationSummariesByRootOrg());
// 			dispatch(getChildOrganizationSummaries(auth.currentOrg?.id));
// 		}).catch(err => {
// 			dispatch(hideLoading())
// 			dispatch(showNotification("Error", "error", String(err && err.message)))
// 		});
// 	}
// }

export const editPractice = (data: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore) => {
		const { auth } = getState();
		const practiceId = data.id;
		data["secretCode"] = data["secretCode"] ? Buffer.from(data["secretCode"]).toString("base64") : ""
		return makeApiCall(dispatch, "PUT", `/admin/v1/practice`, data).then((payload) => {
			dispatch(showNotification("PRACTICE DETAILS SAVED SUCCESSFULLY", "success", ""))
			dispatch(setSelectedPracticeId(""))
			dispatch(fetchPractices())
			dispatch(getChildOrganizationSummaries(auth.currentOrg?.id));
			dispatch(setSelectedPracticeId(practiceId));
		}).catch(err => {
			dispatch(showNotification("Error", "error", String(err && err.message)))
		});
	}
}
export const setSelectedPracticeId = (practiceId: string, name?: string, onPracticeResponse?: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppReduxStore): Promise<any> => {
		return new Promise(async (resolve, reject) => {
			dispatch({ type: 'SET_SELECTED_PRACTICE', payload: { sPractice: null } })
			if (!practiceId) return null
			return await makeApiCall(dispatch, "GET", `/admin/v1/practice/${practiceId}`).then((payload) => {
				dispatch({ type: 'SET_SELECTED_PRACTICE', payload: { sPractice: payload } })
				if (typeof onPracticeResponse === 'function') {
					onPracticeResponse();
				}
				resolve(payload)
			}).catch(err => {
				reject(err)
				dispatch(showNotification("Error", "error", String(err && err.message)))
			})
		})
	}
}

export const setPendingPracticeDetails = (pendingPracticeDetails: any) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SET_PENDING_PRACTICE_DETAILS', payload: { pendingPracticeDetails } })
	}
}