import { Box, DialogActions, DialogContent, Typography } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as OrgBuild } from "icon-library/SVG/Buildings.svg";
import { ReactComponent as PracticeBuild } from "icon-library/SVG/Building_Wide.svg";
import { showNotification } from "admin/store/actions/snackbar";
import AddOrg from "admin/views/Organizations/AddOrg/AddOrg";


const AddEntity = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isAddOrgWizardOpen, setIsAddOrgWizardOpen] = useState(false);
    const CrossIcon = createSvgIcon(CrossSvg)
    const OrgIcon = createSvgIcon(OrgBuild)
    const PracticeIcon = createSvgIcon(PracticeBuild)
    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose();
    };

    const modalClasses = {
        paper: classes.wrapper
    }
    const openAddOrgModal = () => {
        setIsAddOrgWizardOpen(true);
    };
    const openAddPracticeModal = () => {
        dispatch(setActiveModal(MODAL_TYPES.ADD_PRACTICE));
    };

    const handleAddMenuItemClick = (event: any, selection: string) => {
        switch (selection) {
            case "Organization":
                openAddOrgModal();
                break;
            case "Practice":
                openAddPracticeModal();
                break;
            default:
                dispatch(showNotification("Error", "error", "Not Implemented"));
        }
    };
    const closeAddOrgModal = () => {
        setIsAddOrgWizardOpen(false);
        dispatch(setActiveModal(''))
    };
    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.ADD_ENTITY}>
            {isAddOrgWizardOpen &&
                <AddOrg isAddOrgWizardOpen={isAddOrgWizardOpen} closeAddOrgModal={closeAddOrgModal} />
            }
            <DialogActions className={classes.dialogAction}>
                <Box className={classes.dialogHeader}>
                    <Box className={classes.dialogHeaderTop}>
                        <Typography className={classes.dialogHeaderTitle}>
                            <FormattedMessage id={"AddEntity.Action.Heading"} />
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <CrossIcon onClick={handleClose} className={classes.closeButtonIcon} />
                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ root: classes.dialogContentRoot, dividers: classes.dialogContentDividers }}>
                <Box className={classes.modalContent}>
                    <Box className={classes.modalContainer} >
                        <FormattedMessage id={"AddEntity.Content.Title"} />
                    </Box>
                    <Box className={classes.modalContainertext} >
                        <FormattedMessage id={"AddEntity.Content.Desc"} />
                    </Box>
                </Box>
                <Box className={classes.selectionBox} >
                    <Box className={classes.contentBox} onClick={(event) => handleAddMenuItemClick(event, 'Organization')}>
                        <OrgIcon className={classes.BuildIcon}></OrgIcon>
                        <Box>
                            <div className={classes.textTitle}>
                                <FormattedMessage id={"AddEntity.title.Suborg"} />
                            </div>
                            <div className={classes.textDesc}>
                                <FormattedMessage id={"AddEntity.title.Suborg.Desc"} />
                            </div>
                        </Box>
                    </Box>
                    <Box className={classes.contentBox} onClick={(event) => handleAddMenuItemClick(event, 'Practice')}>
                        <PracticeIcon className={classes.BuildIcon}></PracticeIcon>
                        <Box>
                            <div className={classes.textTitle}>
                                <FormattedMessage id={"AddEntity.title.Practice"} />
                            </div>
                            <div className={classes.textDesc}>
                                <FormattedMessage id={"AddEntity.title.Practice.Desc"} />
                            </div>
                        </Box>
                    </Box>
                </Box>

            </DialogContent>

        </Modal>
    )
}

export default AddEntity