import AltaisLogo from './assests/images/logo-altais.png';
import BNTLogo from './assests/images/logo-bnt.png';
import PEPLogo from './assests/images/pep.svg';
import { useStyles } from "./styles";
import { ConfigProps } from "./types";

export const PEPHeader = ({ providerConfig: config, onSelect: handleSelection, useAltaisLogo }: { providerConfig?: ConfigProps, onSelect?: (menuItem: string) => void, useAltaisLogo?: boolean }) => {
    const classes = useStyles();

    return (
        <div id="header_row" className={classes.pep_header_row}>
            <div id="header_container" className={classes.pep_header_container}>
                <div id="header_left" className={classes.pep_header_left}>
                    <div id="header_left_content" className={classes.pep_header_left_content}>
                        <img className={classes.pep_header_left_image} width="364" style={{ cursor: 'pointer' }} height="100" src={PEPLogo} alt="PEP Logo" onClick={(event) => {
                            if (handleSelection) {
                                event.preventDefault();
                                event.stopPropagation();
                                handleSelection("");
                            }
                        }} />
                    </div>
                </div>
                <div id="header_center" className={classes.pep_header_center} />
                <div id="header_right" className={classes.pep_header_right}>
                    <div id="header_right_content" className={classes.pep_header_right_content}>
                        <img width="350" height="100" src={useAltaisLogo ? AltaisLogo : BNTLogo} alt="Logo" />
                    </div>
                </div>
            </div>
        </div>
    );
}
