import { ApiCommunicationContext, ApiStatusCode } from "../types";

const transformResponse = async <TRequest, TResponse, TContent>(
    ctx: ApiCommunicationContext<TRequest, TResponse, TContent>
): Promise<ApiCommunicationContext<TRequest, TResponse, TContent>> => {
    if (ctx.response?.rawContent) {
        if (typeof ctx.request.responseTransformer === "function") {
            try {
                ctx.response.content = ctx.request.responseTransformer(ctx.response.rawContent);
            } catch (error) {
                ctx.response.error = error as Error | string;
                ctx.status = ApiStatusCode.BAD_RESPONSE;
            }
        } else {
            ctx.response.content = ctx.response?.rawContent as unknown as TContent;
        }
    }
    return ctx;
};

export default transformResponse;
