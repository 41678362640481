import React from "react"
import {Dialog} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

import {AppReduxStore} from "store/reducerTypes";

import {ModalProps} from "./types";
import {setActiveModal} from "store/actions";
import {useStyles} from "./styles";
import Draggable from 'react-draggable';

// Modal renders a dialog box with some content passed in. The modal can also be draggable to allow the user to move the
// modal by clicking and dragging it across the screen
const Modal = ({id, children, onClose, classes, className, isDraggable=false,draggableProps, maxWidth="md",modalWidth='', forceOpen = false, ...dialogProps}: ModalProps) => {
    const {activeModal} = useSelector((store: AppReduxStore) => store.modal)
    const dispatch = useDispatch()
    const styles = useStyles({ modalWidth })
    const open = forceOpen || activeModal === id;

    const onModalClose = (_event: Event, reason: string) => {
        if (reason !== 'backdropClick') {
            // Close modal only if it is opened on the same way, via Redux store
            if (!forceOpen) {
                dispatch(setActiveModal(""));
            }
            if (onClose) {
                onClose();
            }
        }
    }

    if (isDraggable && open){

        const { elementHeight = 0, elementWidht = 0 } = draggableProps || {};

        const width = window.innerWidth / 2;
        const height = window.innerHeight / 2;
        const elementXCenterPoint = elementWidht / 2;
        const elementYCenterPoint = elementHeight / 2;

        return (
            <Draggable
                bounds="parent"
                axis="both"
                // position modal in center of window
                defaultPosition={{x: (width-elementXCenterPoint), y: (height-elementYCenterPoint)}}
            >
            <div>{children}</div>
            </Draggable>
        )


        }
    return (
        <Dialog className={className} {...dialogProps} maxWidth={maxWidth} classes={{...classes, paper: classes?.paper ? classes.paper : styles.modal}} open={open} onClose={(event: Event, reason: string) => { onModalClose(event, reason) }}>
            {children}
        </Dialog>
    )
}

export default Modal