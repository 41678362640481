import { DialogContent, Box, Button, DialogActions, IconButton, Paper, Typography } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment } from "react";
import { deletePatient, setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import { showNotification } from "admin/store/actions/snackbar";

const DeletePatientConfirmation = () => {
    const dispatch = useDispatch()
    const { auth, admin } = useSelector((state: AppReduxStore) => state)
    const { selectedPatient } = admin
    const intl = useIntl()
    const classes = useStyles()
    const onClose = () => {
        dispatch(setActiveModal(""))
    }

    const onDelete = (event: any) => {
        event.preventDefault()
        if (selectedPatient.id) {
            dispatch(deletePatient(selectedPatient?.id, auth.currentPractice?.id))
        }
        else {
            dispatch(showNotification("Delete Patient Error", "error", "ehrPatientId not found"))
        }
        onClose()
    };
    const modalClasses = {
        paper: classes.wrapper
    }
    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.DELETE_PATIENT_CONFIRMATION_MODAL} small={true}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "Patient.ConfirmationModal.Title" })}
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {`Delete ${selectedPatient.firstName} ${selectedPatient.lastName}?`}
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnCloseConfirmationModal" size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined" style={{ zIndex: 100 }}
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "Patient.ConfirmationModal.CancelButton" })}
                        </Button>
                        <Button id="btnDelete" variant="outlined" color="primary" style={{ marginLeft: '10px', background: '#E32B29' }} onClick={onDelete}>
                            {intl.formatMessage({ id: "Patient.ConfirmationModal.DeleteButton" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper square elevation={0}>
                    <Typography variant="body2" className={classes.confirmationBody}>
                        {intl.formatMessage({ id: "Patient.ConfirmationModal.Body" })}
                    </Typography>

                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default DeletePatientConfirmation