import { COLORS } from "theme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    sx: {
        backgroundColor: "#F6F6F7",
        borderRadius: '8px',
        overflow: "visible",
        color: "#393948",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.32))",
        padding: '16px',
        mt: 1.5,
        "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 30,
            left: -12,
            width: 24,
            height: 24,
            background: COLORS.SIDE_NAV_HEADER_BG,
            transform: "translateY(-50%) rotate(135deg)",
            zIndex: 0
        }
    },
    mb10: {
        marginBottom: '10px'
    },
    itemText: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#393948'
    }
}));
