import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as EmptyIcon } from "icon-library/SVG/User_Users.svg";
import { TableList, TableListCSS } from "lib/ui-components/table-list";
import EmptyMessage from "common/components/empty-message/empty-message";
import * as CSS from "../class-names";
import { CareTeamPatientDetails } from "../types";
import { filterPatients } from "./utils";
import { DetailsSearch } from "./details-search";
import { PatientListItem } from "lib/business-components/patient-search";

export type CareTeamMemberList = {
    patients: CareTeamPatientDetails[];
};
export const CareTeamPatientList = ({ patients }: CareTeamMemberList) => {
    const intl = useIntl();
    const [filterValue, setFilterValue] = useState("");
    const [filteredPatients, setFilteredPatients] = useState(patients);

    useEffect(() => {
        setFilteredPatients(filterPatients(patients, filterValue));
    }, [filterValue, patients]);

    return (
        <>
            <DetailsSearch
                idPrefix="QA_careTeam_details_patient"
                placeholder={intl.formatMessage({ id: "careTeam.details.patients.search.placeholder" })}
                onChange={setFilterValue}
            />
            <TableList
                id="QA_careTeam_details_patient_list"
                testId="QA_careTeam_details_patient_list"
                size="big"
                variant="alternated"
            >
                {filteredPatients.map((patient) => {
                    return (
                        <PatientListItem
                            key={patient.patientId}
                            patient={patient}
                            idPrefix="QA_careTeam_details_patient_"
                            className={TableListCSS.TableListItemBigHorizontalPadding}
                        />
                    );
                })}
            </TableList>
            {filteredPatients.length === 0 && (
                <EmptyMessage
                    className={CSS.CareTeamDetailsContentMembersEmptyMessage}
                    variant="content"
                    Icon={<EmptyIcon />}
                    primaryMessage={{ id: "careTeam.details.search.empty.title" }}
                    secondaryMessage={{ id: "careTeam.details.search.empty.subtitle" }}
                />
            )}
        </>
    );
};
