import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Box, Button, Checkbox, Grid, IconButton, InputBase, Paper, Table, TableBody,
    TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,
    TableSortLabel, Typography, Tooltip
} from "@mui/material";
import PatientBulkActionMenu from 'admin/components/BulkActionMenuContent/PatientBulkActionMenu/PatientBulkActionMenu';
import BulkActionMenu from 'admin/components/BulkActionMenu/BulkActionMenu';
import GridContextMenu from 'admin/components/GridContextMenu/GridContextMenu';
import {
    setActiveModal, setSelectedPatientsInfo, setNavBreadcrumbText, getPatientsForPractice,
    sendPatientInvite, setSelectedPatient, setUsersFilterAndSortOptions, setIsAllSelectedForBulk,
    changePatientAccountStatus, setSelectedPatientSupportNetwork
} from 'admin/store/actions';
import { MODAL_TYPES } from 'admin/constant';
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import { useStyles } from './styles';
import { Patient } from 'admin/views/Patients/types';
import { AppReduxStore } from "admin/store/reducerTypes";
import PatientGridContextMenu from 'admin/components/GridContextMenuContent/PatientGridContextMenu/PatientGridContextMenu';
import PatientSupportNetworkGridContextMenu from 'admin/components/GridContextMenuContent/PatientSupportNetworkGridContextMenu';
import { showNotification } from "admin/store/actions/snackbar";
import { ReactComponent as FlagSvg } from "icon-library/SVG/Flag.svg";
import { createSvgIcon } from "../../../../utils";
import Under18PatientConsent from '../Under18PatientConsent/Under18PatientConsent';
import { useIntl } from 'react-intl';
import TablePaginationActions from 'admin/components/TablePaginationActions/TablePaginationActions';
import TableSelectDropdown from 'admin/components/TableSelectDropdown/TableSelectDropdown';
import { ReactComponent as ChevronDown } from "icon-library/SVG/Chevron_Down.svg";
import { ReactComponent as ChevronRight } from "icon-library/SVG/Chevron_Right.svg";
import { ReactComponent as EllipsisV } from "icon-library/SVG/Ellipsis-Vertical.svg";
const EllipsisVIcon = createSvgIcon(EllipsisV);
const FlagIcon = createSvgIcon(FlagSvg);
type Order = 'asc' | 'desc';

const borders = {
    Active: '2px solid #00A875',
    Pending: '2px solid #F5A800',
    Inactive: '2px solid #565662',
    None: '2px solid #909098'
};

const ChevronDownIcon = createSvgIcon(ChevronDown);
const ChevronRightIcon = createSvgIcon(ChevronRight);

const PatientListing = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { patients, patientsContent, usersFilterText, usersSortBy, usersSortAsc, usersPageSize } = useSelector((state: AppReduxStore) => state).admin;
    const { currentPractice } = useSelector((state: AppReduxStore) => state).auth;
    const [isUnder18ConsentModalOpen, setUnder18ConsentModalOpen] = useState(false);
    const [isBulkSendInviteAction, setBulkSendInviteAction] = useState(false);
    const [currentPatientProfileId, setCurrentPatientProfileId] = useState(null);

    const [orderBy, setOrderBy] = useState<keyof Patient>('fullName');
    const [order, setOrder] = useState<Order>('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [allSelectedCheked, setAllSelectedCheked] = useState(false);
    const [selectedPatients, setSelectedPatients] = useState<string[]>([]);
    const [patientToggleState, setPatientToggleState] = useState({});
    const [supportNetworkExpanded, setSupportNetworkExpanded] = useState(false);
    const [contextMenuData, setContextMenuData] = useState({} as any);
    const [contextMenuPSNData, setContextMenuPSNData] = useState({} as any);
    const searchInput = useRef<any>();

    useEffect(() => {
        if (currentPractice && currentPractice.id) {
            dispatch(setUsersFilterAndSortOptions("", 0, 25, "", true))
            dispatch(setIsAllSelectedForBulk(false))
        }
    }, [currentPractice, dispatch]);
    useEffect(() => {
        dispatch(getPatientsForPractice(currentPractice.id));
    }, [page, rowsPerPage, orderBy, order, dispatch, currentPractice.id]);
    const isSelected = (profileId: string) => {
        return selectedPatients.indexOf(profileId) !== -1;
    };

    const handleBulkAction = (value: any) => {
        if (!allSelectedCheked && (!selectedPatients || selectedPatients.length === 0)) {
            dispatch(showNotification("Bulk Action", "error", "No Patient(s) Selected"))
            return
        }
        switch (value) {
            case 'Activate': case 'Deactivate':
                if (allSelectedCheked) {
                    dispatch(changePatientAccountStatus([], value === "Activate" ? "ACTIVE" : "INACTIVE"));
                }
                else {
                    const sProfileRoleIds = selectedPatients && selectedPatients.map((profileId: any) => {
                        const patient = patients && patients.find((p: any) =>
                            (p.profileId === profileId)
                        )
                        return patient.profileRoleId
                    }).filter(p => p !== undefined)
                    if (sProfileRoleIds.length > 0) {
                        dispatch(changePatientAccountStatus(sProfileRoleIds, value === "Activate" ? "ACTIVE" : "INACTIVE"));
                        setSelectedPatients([]);
                        dispatch(getPatientsForPractice(currentPractice.id));
                    }
                    else {
                        dispatch(showNotification("Account Status", "error", "profileRoleId not found"));
                    }
                }

                break;
            case 'SendInvites':
                if (allSelectedCheked) {
                    sendInvitesBulk([])
                } else {
                    let isUnder18PatientSelected = false
                    const sProfileIdsAndEmails = selectedPatients && selectedPatients.map((profileId: any) => {
                        const patient = patients && patients.find((p: any) =>
                            (p.profileId === profileId)
                        )
                        if (patient.age < 18) {
                            isUnder18PatientSelected = true
                        }
                        return { profileId: patient.profileId, email: patient.email }
                    })
                    if (isUnder18PatientSelected) {
                        setCurrentPatientProfileId(null)
                        setUnder18ConsentModalOpen(true)
                        setBulkSendInviteAction(true)
                    }
                    else {
                        sendInvitesBulk(sProfileIdsAndEmails)
                    }
                }
                break;
            case 'ResetPasswords':
                dispatch(setSelectedPatientsInfo(selectedPatients));
                dispatch(setActiveModal(MODAL_TYPES.RESET_PATIENT_PASSWORD));
                setSelectedPatients([]);
                break;
            default:
                dispatch(showNotification("TODO", "error", "Action Not Implemented"));
        }
    };
    interface ProfileIdsBulk {
        profileId: string
        email: string

    }
    const sendInvitesBulk = (sProfileIdsAndEmails: ProfileIdsBulk[]) => {
        if (allSelectedCheked) {
            dispatch(sendPatientInvite([], currentPractice.id));
        } else {
            const sProfileIdsHaveEmails = sProfileIdsAndEmails.filter(p => p.profileId && p.email)
            if (sProfileIdsHaveEmails && sProfileIdsHaveEmails.length > 0) {
                dispatch(sendPatientInvite(sProfileIdsHaveEmails.map(p => p.profileId), currentPractice.id));
                setSelectedPatients([]);
            }
            const sProfileIdsDontHaveEmails = sProfileIdsAndEmails.filter(p => !p.email || p.email?.trim() === "")
            if (sProfileIdsDontHaveEmails?.length > 0) {
                dispatch(showNotification("Error", "error",
                    `${sProfileIdsDontHaveEmails?.length} ${intl.formatMessage({ id: "Admin.SendInvite.MissingEmail" })}`
                ))
            }
        }

    }
    const sendInvite = (profile: ProfileIdsBulk) => {
        if (profile.profileId && profile.email) {
            dispatch(sendPatientInvite([profile.profileId], currentPractice.id))
        } else {
            dispatch(showNotification("Error", "error", intl.formatMessage({ id: "Admin.SendInvitePatient.MissingEmail" })))
        }
    }
    const handleSearch = (event: any) => {
        if (currentPractice && currentPractice.id) {
            setPage(0);
            if (event.target.value.trim() !== "") {
                setSupportNetworkExpanded(true)
            } else {
                setSupportNetworkExpanded(false)
            }

            dispatch(setUsersFilterAndSortOptions(event.target.value.trim(), 0, usersPageSize, usersSortBy, usersSortAsc))
            dispatch(getPatientsForPractice(currentPractice.id));
        }
    };

    const handleSelectAllVisibleChanged = (isAllSelectedOnPage: boolean) => {
        setAllSelectedCheked(false)
        dispatch(setIsAllSelectedForBulk(false))
        if (isAllSelectedOnPage) {
            const profileIds = patients.map((patient: any) => patient.profileId && patient.profileId.toString());
            setSelectedPatients(profileIds);
        } else {
            setSelectedPatients([]);
        }
    }

    const handleSelectAllChanged = (isAllSelected: boolean) => {
        setAllSelectedCheked(isAllSelected)
        dispatch(setIsAllSelectedForBulk(isAllSelected))
        setSelectedPatients([]);
    }

    const handleRowAction = (type: string, ehrPatientId: any, profileRoleId: any, profileId: any, age: any, email: any) => {
        switch (type) {
            case 'EditPatient':
                if (profileId) {
                    dispatch(setSelectedPatient(profileId));
                }
                dispatch(setActiveModal(MODAL_TYPES.EDIT_PATIENT));
                break;
            case 'DeletePatient':
                if (profileId) {
                    dispatch(setSelectedPatient(profileId));
                }
                dispatch(setActiveModal(MODAL_TYPES.DELETE_PATIENT_CONFIRMATION_MODAL));
                break;
            case 'Activate': case 'Deactivate':
                if (profileRoleId) {
                    dispatch(changePatientAccountStatus([profileRoleId], type === "Activate" ? "ACTIVE" : "INACTIVE"));
                }
                else {
                    dispatch(showNotification("Account Status", "error", "profileRoleId not found"));
                }
                break;
            case 'SendInvite':
                if (age < 18) {
                    setCurrentPatientProfileId(profileId)
                    setUnder18ConsentModalOpen(true)
                    setBulkSendInviteAction(false)
                }
                else {
                    sendInvite({ profileId, email })
                }
                break;
            case 'ResetPassword':
                if (profileId) {
                    dispatch(setSelectedPatientsInfo([profileId]));
                    dispatch(setActiveModal(MODAL_TYPES.RESET_PATIENT_PASSWORD));
                }
                break;
            default:
                dispatch(showNotification("TODO", "error", "Action Not Implemented"));
        }
    };

    const handleSupportNetworkRowAction = (type: string, sSupportNetwork: any) => {
        switch (type) {
            case 'ResetPassword':
                if (sSupportNetwork) {
                    dispatch(setSelectedPatientSupportNetwork(sSupportNetwork))
                    dispatch(setActiveModal(MODAL_TYPES.RESET_PATIENT_PASSWORD));
                }
                break;
            default:
                dispatch(showNotification("TODO", "error", "Action Not Implemented"));
        }
    };

    const handleAddPatient = () => {
        dispatch(setActiveModal(MODAL_TYPES.ADD_PATIENT));
    };
    const onIConfirmSendInvite = () => {
        if (isBulkSendInviteAction) {
            const sProfileIdsAndEmails = selectedPatients && selectedPatients.map((profileId: any) => {
                const patient = patients && patients.find((p: any) =>
                    (p.profileId === profileId)
                )
                return { profileId: patient?.profileId, email: patient?.email }
            })
            sendInvitesBulk(sProfileIdsAndEmails)
        }
        else {
            const patient = patients && patients.find((p: any) =>
                (p.profileId === currentPatientProfileId)
            )
            sendInvite({ profileId: patient.profileId, email: patient.email })
        }
        setSelectedPatients([]);
        setUnder18ConsentModalOpen(false)
        setBulkSendInviteAction(false)
    }
    const onSendInvitesAbove18Only = () => {
        const sProfileIdsAndEmails = selectedPatients && selectedPatients.map((profileId: any) => {
            const patient = patients && patients.find((p: any) =>
                (p.profileId === profileId && p.age >= 18)
            )
            return { profileId: patient?.profileId, email: patient?.email }
        })?.filter((p: any) => p.profileId)

        if (sProfileIdsAndEmails.length > 0) {
            sendInvitesBulk(sProfileIdsAndEmails)
            setUnder18ConsentModalOpen(false)
            setBulkSendInviteAction(false)
        }
        else {
            dispatch(showNotification("Send Invites", "error", "No over 18 patient selected"));
        }
    }
    const onUnder18ConsentModalClose = () => {
        setUnder18ConsentModalOpen(false)
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        dispatch(setUsersFilterAndSortOptions(usersFilterText, newPage, usersPageSize, usersSortBy, usersSortAsc))
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rowsperPage = parseInt(event.target.value)
        setRowsPerPage(rowsperPage);
        setPage(0);
        dispatch(setUsersFilterAndSortOptions(usersFilterText, 0, rowsperPage, usersSortBy, usersSortAsc))
    };

    const handleFullNameClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, patientId: any, fullName: any, profileId: any) => {
        dispatch(setSelectedPatient(profileId));
        dispatch(setNavBreadcrumbText(fullName));
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Patient) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
        dispatch(setUsersFilterAndSortOptions(usersFilterText, 0, usersPageSize, property, isAsc))
    };

    const createSortHandler = (property: keyof Patient) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const handleCheckboxClick = (event: React.MouseEvent<unknown>, formId: string) => {
        const selectedIndex = selectedPatients.indexOf(formId);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedPatients, formId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedPatients.slice(1));
        } else if (selectedIndex === selectedPatients.length - 1) {
            newSelected = newSelected.concat(selectedPatients.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedPatients.slice(0, selectedIndex),
                selectedPatients.slice(selectedIndex + 1),
            );
        }

        setSelectedPatients(newSelected);
    };
    const handleEllipsesActionClick = (event: any, row: any, index: number) => {
        event.preventDefault();
        setContextMenuData({ mouseX: event.clientX - 194, mouseY: event.clientY - 25, row, index })

    };
    const handleRowContextMenuClose = (event: any) => {
        setContextMenuData({ mouseX: 0, mouseY: 0, row: null, index: null })
    };
    const RowContextMenu = () => {
        const row = contextMenuData?.row
        if (!row) return
        return (<GridContextMenu mouseX={contextMenuData?.mouseX} mouseY={contextMenuData?.mouseY} onClose={handleRowContextMenuClose}
            callback={(type: any) => {
                handleRowAction(type, row.ehrPatientId, row.profileRoleId, row.profileId, row.age, row.email)
            }}
            formNumber={""} formTitle={""} handleFormRow={null}
            gridContextMenuContent={PatientGridContextMenu} index={contextMenuData?.index}
            showActiveAccount={row.status === "INACTIVE"}
            showDeActiveAccount={row.status === "ACTIVE" || row.status === "LOCKED"}
            showResetPassword={row.status === "ACTIVE" || row.status === "LOCKED"}
            showSendInvite={row.status === "PENDING" || row.status === "NONE" || row.status === null} />
        )
    }
    const handleEllipsesPSNActionClick = (event: any, row: any, index: number) => {
        event.preventDefault();
        setContextMenuPSNData({ mouseX: event.clientX - 194, mouseY: event.clientY - 25, row, index })

    };
    const handlePSNRowContextMenuClose = (event: any) => {
        setContextMenuPSNData({ mouseX: 0, mouseY: 0, row: null, index: null })
    };
    const RowPSNContextMenu = () => {
        const supportRow = contextMenuPSNData?.row
        if (!supportRow) return
        return (<GridContextMenu mouseX={contextMenuPSNData?.mouseX} mouseY={contextMenuPSNData?.mouseY} onClose={handlePSNRowContextMenuClose}
            callback={(type: any) => { handleSupportNetworkRowAction(type, supportRow) }}
            formNumber={""} formTitle={""} handleFormRow={null}
            gridContextMenuContent={PatientSupportNetworkGridContextMenu}
            index={contextMenuPSNData.index} />
        )
    }
    const Pagination = () => {
        return (<>
            {patients && patients.length > 0 && <TablePagination className={classes.tableFooterRow}
                rowsPerPageOptions={[25, 50, 100]}
                classes={{ selectIcon: classes.footerIcon, select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
                count={patientsContent.totalElements}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Per Page"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            }
        </>)
    }

    const handleClick = (children: any, patientId: any) => {
        if (supportNetworkExpanded === false) {
            const collapseValue = !(patientToggleState as any)[patientId];

            if (children.length > 0) {
                children.forEach((child: any) => {
                    collapseChildren(patientToggleState, child, collapseValue, patientId)
                });
            }
            setPatientToggleState({ ...patientToggleState, [patientId]: collapseValue })
        } else {
            setSupportNetworkExpanded(false)
        }
    }
    const collapseChildren = (collapseState: any, child: any, collapaseValue: boolean, patientId: any) => {
        collapseState[patientId] = collapaseValue
    }

    const getSupportNetworkDetails = (supportContacts: any, patientId: any, fullName: any, profileId: any) => {

        const searchInputKey = searchInput.current?.value;
        const supportNetworkList = supportContacts && supportContacts.map((supportRow: any, supportIndex: number) => {
            const labelId = `enhanced-table-support-${supportIndex}`;
            let supportBorder = '';

            switch (supportRow.status) {
                case "ACTIVE": supportBorder = borders['Active']; break;
                case "INACTIVE": supportBorder = borders['Inactive']; break;
                case "PENDING": supportBorder = borders['Pending']; break;
                default: supportBorder = borders['None']; break;
            }

            return (<>
                <TableRow
                    hover
                    tabIndex={-1}
                    key={patientId}
                    style={{ display: ((patientToggleState as any)[patientId] || supportNetworkExpanded === true) ? "table-row" : "none" }}
                    className={Object.keys(supportRow).find(key => supportRow[key]?.toLowerCase() === searchInputKey?.toLowerCase()) ? classes.highlight : ''}
                >
                    <TableCell padding="checkbox">

                    </TableCell>
                    <TableCell id={labelId} scope="supportRow" padding="checkbox" width={250}>
                        <Link to={`/admin/patients/details/#supportNetwork`} onClick={(e) => handleFullNameClick(e, patientId, fullName, profileId)}>
                            <Tooltip arrow title="">
                                <Typography noWrap variant="body1" style={{ color: '#0F7BBD', width: '200px', fontSize: '14px', fontWeight: 600, marginLeft: '50px' }}>{supportRow.firstName}</Typography>
                            </Tooltip>
                        </Link>
                    </TableCell>
                    <TableCell align="left">{supportRow.primaryEmail}</TableCell>
                    <TableCell align="left">--</TableCell>
                    <TableCell align="left">--</TableCell>
                    <TableCell align="left">{supportRow.supportRelationName}</TableCell>
                    <TableCell align="center" style={{ textAlign: 'right' }}>
                        <Box style={{ border: supportBorder, padding: '5px', borderRadius: '7px', width: '100px', textAlign: 'center' }}>{supportRow.status ? supportRow.status : "NONE"}</Box>
                    </TableCell>
                    <TableCell align="right">
                        {(supportRow.status === "ACTIVE") &&
                            <IconButton id={"btnRowActionPSNContextMenu" + supportIndex} style={{ padding: '10px' }} onClick={(e) => handleEllipsesPSNActionClick(e, supportRow, supportIndex)} size="large">
                                <EllipsisVIcon />
                            </IconButton>
                        }
                    </TableCell>
                </TableRow></>)
        });

        return supportNetworkList
    }

    return (<>
        <Box className={classes.usersWrapper}>
            <Grid container>
                <Grid item xs>
                    <BulkActionMenu callback={handleBulkAction} bulkActionMenuContent={PatientBulkActionMenu} />
                </Grid>
                <Grid item xs>
                    <Paper component="form" className={classes.root}>
                        <IconButton id="btnSerachPatients" className={classes.iconButton} aria-label="search" size="large">
                            <SearchIcon />
                        </IconButton>
                        <InputBase id="txtSearchPatients"
                            type="search" ref={searchInput}
                            className={classes.input}
                            onChange={handleSearch}
                            onKeyPress={(ev: any) => { if (ev.key === 'Enter') { ev.preventDefault() } }}
                            placeholder="Search to filter the table below"
                            inputProps={{ 'aria-label': 'Search to filter the table below' }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Button id="btnAddPatients" variant="contained" color="primary" style={{ float: 'right' }} onClick={handleAddPatient}>
                        Add Patient
                    </Button>
                </Grid>
            </Grid>
            {(selectedPatients.length > 0 || allSelectedCheked) && <Typography noWrap variant="body1" style={{ fontSize: '16px', color: "#565662", paddingTop: "8px" }}>
                {allSelectedCheked ? patientsContent.totalElements : selectedPatients.length} Selected
            </Typography>}
            <Paper className={classes.paper}>
                <TableContainer className={classes.stickyHeader}>
                    <Table id="tablePatientListing"
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" >
                                    <TableSelectDropdown onSelectAllOnPage={handleSelectAllVisibleChanged} onSelectAll={handleSelectAllChanged} />
                                </TableCell>
                                <TableCell
                                    key={"fullName"}
                                    align={'left'}
                                    padding={'none'}
                                    sortDirection={orderBy === "fullName" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "fullName"}
                                        direction={orderBy === "fullName" ? order : 'asc'}
                                        onClick={createSortHandler("fullName")}>
                                        <strong>
                                            {'Name'}
                                        </strong>
                                        {
                                            orderBy === "fullName"
                                                ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>)
                                                : null
                                        }
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"email"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "email" ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === "email"}
                                        direction={orderBy === "email" ? order : 'asc'}
                                        onClick={createSortHandler("email")}
                                    >
                                        <strong>
                                            {'Email'}
                                        </strong>
                                        {
                                            orderBy === "email"
                                                ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>)
                                                : null
                                        }
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"dateOfBirth"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "dateOfBirth" ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === "dateOfBirth"}
                                        direction={orderBy === "dateOfBirth" ? order : 'desc'}>
                                        <strong onClick={createSortHandler("dateOfBirth")}
                                        >
                                            {'Age'}
                                        </strong>
                                        {
                                            orderBy === "dateOfBirth"
                                                ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted ascending' : 'sorted descending'}</span>)
                                                : null
                                        }
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"mrn"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "mrn" ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === "mrn"}
                                        direction={orderBy === "mrn" ? order : 'asc'}>
                                        <strong onClick={createSortHandler("mrn")}
                                        >
                                            {'MRN'}
                                        </strong>
                                        {
                                            orderBy === "mrn"
                                                ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>)
                                                : null
                                        }
                                    </TableSortLabel>
                                    {/* <StatusFilterMenu callback={handleStatusFilterAction} /> */}
                                </TableCell>
                                <TableCell
                                    key={"levelName"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "levelName" ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === "levelName"}
                                        direction={orderBy === "levelName" ? order : 'asc'}
                                        onClick={createSortHandler("levelName")}
                                    >
                                        {'Relation to Patient'}
                                        {
                                            orderBy === "levelName"
                                                ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>)
                                                : null
                                        }
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={"status"}
                                    align={'left'}
                                    padding="normal"
                                    sortDirection={orderBy === "status" ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === "status"}
                                        direction={orderBy === "status" ? order : 'asc'}
                                        onClick={createSortHandler("status")}
                                    >
                                        {'Altais Status'}
                                        {
                                            orderBy === "status"
                                                ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>)
                                                : null
                                        }
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {//stableSort(patients, getComparator(order, orderBy))
                                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                patients && patients.map((row: any, index: number) => {
                                    const isItemSelected = isSelected(row.profileId ? row.profileId.toString() : '');
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    let border = '';

                                    switch (row.status) {
                                        case "ACTIVE": border = borders['Active']; break;
                                        case "INACTIVE": border = borders['Inactive']; break;
                                        case "PENDING": border = borders['Pending']; break;
                                        default: border = borders['None']; break;
                                    }

                                    return (<>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    onClick={(event: any) => handleCheckboxClick(event, row.profileId.toString())}
                                                    checked={isItemSelected || allSelectedCheked} disabled={allSelectedCheked}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell id={labelId} scope="row" padding="none" width={250}>
                                                <Grid style={{ display: "flex", alignItems: "center", flexBasis: 'none' }}>
                                                    {row.supportContacts?.length > 0 ? (
                                                        <IconButton
                                                            key={row.ehrPatientId}
                                                            onClick={() => handleClick(row.supportContacts, row.ehrPatientId)}
                                                            size="large">
                                                            {((patientToggleState as any)[row.ehrPatientId] || supportNetworkExpanded === true) ? <ChevronDownIcon className={classes.tableChevron} /> : <ChevronRightIcon className={classes.tableChevron} />}
                                                        </IconButton>
                                                    ) : null}
                                                    <Link className={(row.supportContacts?.length <= 0 ? classes.marginLeft41 : "")} to="/admin/patients/details" onClick={(e) => handleFullNameClick(e, row.ehrPatientId, row.fullName, row.profileId)}>
                                                        <Tooltip arrow title={row.fullName ? row.fullName.toString() : ''}>
                                                            <Typography noWrap variant="body1" style={{ color: '#0F7BBD', width: '250px', fontSize: '14px', fontWeight: 600 }}> {row.fullName}</Typography>
                                                        </Tooltip>
                                                    </Link>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.age}{(row.age < 18) && <FlagIcon fill={"#FF0000"} className={classes.flagIcon} />}</TableCell>
                                            <TableCell align="left">{row.mrn}</TableCell>
                                            <TableCell align="left">Patient</TableCell>{/* row.levelName === "PATIENT" ?  */}
                                            <TableCell align="center" style={{ textAlign: 'right' }}>
                                                <Box style={{ border: border, padding: '5px', borderRadius: '7px', width: '100px', textAlign: 'center' }}>
                                                    {row.status === "ACTIVE" ? "Registered" :
                                                        row.status === "PENDING" ? "Pending" :
                                                            row.status === "INACTIVE" ? "Deactivated" :
                                                                "None"
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right" className={classes.stickyLastColumn}>
                                                <IconButton id={"btnRowActionContextMenu" + index} style={{ padding: '10px' }} onClick={(e) => handleEllipsesActionClick(e, row, index)} size="large">
                                                    <EllipsisVIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        {getSupportNetworkDetails(row.supportContacts, row.ehrPatientId, row.fullName, row.profileId)}
                                    </>
                                    );
                                })
                            }
                            {contextMenuData?.row && RowContextMenu()}
                            {contextMenuPSNData?.row && RowPSNContextMenu()}
                        </TableBody>
                        <TableFooter>
                            {
                                patients && patients.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                ) : <TableRow>
                                    <Pagination />
                                </TableRow>
                            }
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
        {isUnder18ConsentModalOpen && <Under18PatientConsent isUnder18ConsentModalOpen={isUnder18ConsentModalOpen} isBulkSendInviteAction={isBulkSendInviteAction} onIConfirmSendInvite={onIConfirmSendInvite} onSendInvitesAbove18Only={onSendInvitesAbove18Only} onUnder18ConsentModalClose={onUnder18ConsentModalClose} />}
    </>
    );
}

export default PatientListing;