import { EConsultRequestValidation } from "../types";
import { ValidationErrorObject } from "lib/validation/types";
import { ValidationErrors } from "common/validation/types";

export const convertValidationErrors = (
    errors: ValidationErrors<EConsultRequestValidation>
): ValidationErrorObject[] => {
    const validationArray = [];
    if (errors.providers?.recipientIds?.error) {
        validationArray.push({
            nameKey: "EConsult.newRequestModal.submit.providerTabError",
            detailsKey: errors.providers.recipientIds.error.errorMessageId,
        });
    }
    if (errors.attachments?.error) {
        validationArray.push({
            nameKey: "EConsult.newRequestModal.submit.attachmentsTabError",
            detailsKey: errors.attachments.error.errorMessageId,
        });
    }
    if (errors.caseNotes?.subject?.error) {
        validationArray.push({
            nameKey: "EConsult.newRequestModal.submit.notesTabError",
            detailsKey: errors.caseNotes.subject.error.errorMessageId,
        });
    }
    if (errors.caseNotes?.notes?.error) {
        validationArray.push({
            nameKey: "EConsult.newRequestModal.submit.notesTabError",
            detailsKey: errors.caseNotes.notes.error.errorMessageId,
        });
    }
    return validationArray;
};
