import { ModalPanel } from "lib/ui-components/modal-panel";
import { ModalSidePanelLayout } from "lib/ui-components/modal-layouts";
import { Pagination } from "lib/ui-components/pagination";
import { Patient } from "lib/business-components/patient-search/types";
import { ReactComponent as EmptyIcon } from "icon-library/SVG/User_Single.svg";
import { ReactComponent as Trash } from "icon-library/SVG/Trash.svg";
import { SelectedPatient } from "../types";
import { TableList } from "lib/ui-components/table-list";
import { useCareTeamPatientSearch } from "./patient-search-hooks";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useUserNotification } from "common/utils/use-user-notification";
import * as CSS from "../class-names";
import ComponentLoader from "common/components/ComponentLoader/ComponentLoader";
import EmptyMessage from "common/components/empty-message/empty-message";
import { PatientListItem, PatientSearchField } from "lib/business-components/patient-search";
import SVGIcon from "common/components/svg-icon/svg-icon";

const selectedPatientsIdPrefix = "QA_CareTeam__Edit__PatientSearch__SelectedPatients";

export type PatientSearchTabProps = {
    selectedPatients: SelectedPatient[];
    onChange: (selectedPatients: SelectedPatient[]) => void;
};

const PatientSearchTab = ({ onChange, selectedPatients }: PatientSearchTabProps) => {
    const intl = useIntl();
    const [searchValue, setSearchValue] = useState("");
    const [resultPageNumber, setResultPageNumber] = useState(0);
    const { enqueueError } = useUserNotification();

    const { error, loading, result, success } = useCareTeamPatientSearch({
        page: resultPageNumber,
        searchValue,
    });
    const patients = result.data ?? [];
    const pagination = result.page;

    const addPatient = (patient: Patient) => {
        onChange([...selectedPatients, patient]);
    };
    const removePatient = (patient: Patient) => {
        onChange(selectedPatients.filter((selPatient) => selPatient.id !== patient.id));
    };

    const renderLeftPanel = (selectedPatients: Patient[]) => (
        <ModalPanel
            title={intl.formatMessage({ id: "careTeam.editor.tabs.patients.selectedPatients.title" })}
            emptyMessage={
                <EmptyMessage
                    className="emptyMessage"
                    Icon={<SVGIcon svg={EmptyIcon} size="scale2500" color="DARK_EMPTY_MESSAGE_ICON" />}
                    primaryMessage={{ id: "careTeam.editor.tabs.patients.selectedPatients.emptyMessage" }}
                />
            }
        >
            {selectedPatients.length > 0 && (
                <TableList id={selectedPatientsIdPrefix}>
                    {selectedPatients.map((selectedPatient) => (
                        <PatientListItem
                            idPrefix="QA_patient_selected"
                            patient={selectedPatient}
                            contextMenuItems={[
                                {
                                    id: `QA_patient_selected_ContextMenu_Remove_${selectedPatient.id}`,
                                    labelKey: "careTeam.editor.tabs.patients.contextMenu.remove",
                                    danger: true,
                                    icon: Trash,
                                    onClick: () => removePatient(selectedPatient),
                                },
                            ]}
                            selected={true}
                        />
                    ))}
                </TableList>
            )}
        </ModalPanel>
    );

    useEffect(() => {
        if (error) {
            enqueueError("careTeam.search.patients.apiError");
        }
    }, [enqueueError, error]);

    const primaryMessageId = error
        ? "careTeam.editor.tabs.patients.search.errorMessage"
        : success
        ? "careTeam.editor.tabs.patients.search.emptyMessage.noItemFound"
        : "careTeam.editor.tabs.patients.search.emptyMessage.notSearched";
    const onSearchValueChange = (value: string) => {
        setSearchValue(value);
        setResultPageNumber(0);
    };
    return (
        <ModalSidePanelLayout leftPanelChildren={renderLeftPanel(selectedPatients)}>
            <PatientSearchField
                idPrefix="QA_CareTeam__Edit__"
                onChange={onSearchValueChange}
                searchValue={searchValue}
            />
            <ModalPanel className={CSS.CareTeamEditorPatientsSearchList}>
                {loading ? (
                    <ComponentLoader />
                ) : (
                    <>
                        {patients.length === 0 && (
                            <EmptyMessage
                                className={CSS.CareTeamEditorPatientsEmptyMessage}
                                primaryMessage={{
                                    id: primaryMessageId,
                                }}
                            />
                        )}
                        {patients.length > 0 && (
                            <TableList id="QA_patient_search_results" testId="QA_patient_search_results" >
                                {patients.map((patient) => {
                                    const selected = !!selectedPatients.find(
                                        (selPatient) => selPatient.id === patient.id
                                    );
                                    const onSelect = (selected: boolean) => {
                                        if (selected) {
                                            addPatient(patient);
                                        } else {
                                            removePatient(patient);
                                        }
                                    };
                                    return (
                                        <PatientListItem
                                            key={patient.id}
                                            idPrefix="QA_patient_search_results"
                                            patient={patient}
                                            onSelect={onSelect}
                                            selected={selected}
                                        />
                                    );
                                })}
                            </TableList>
                        )}
                    </>
                )}
            </ModalPanel>
            <Pagination
                onChange={setResultPageNumber}
                pageCount={pagination.totalPages}
                totalElements={pagination.totalElements}
                pageNumber={pagination.number}
                showTotalCount={true}
                totalCountKey="careTeam.editor.tabs.patients.totalCount"
            />
        </ModalSidePanelLayout>
    );
};

export default PatientSearchTab;
