import React from "react";
import { FormattedMessage } from "react-intl";
import * as CSS from "./class-names";

type TotalCountNumber = {
    count?: number | null;
    messageKey?: string;
}

export const TotalCount: React.FC<TotalCountNumber> = ({ count, messageKey }) => {
    // 0 is a valid value, so we have to explicitly check for null and undefined
    if (count === undefined || count === null) {
        return null;
    }

    return (
        <div className={CSS.PaginationTotalCount}>
            <span className="fCount">{count}</span>
            <span className="fText">
                { messageKey && <FormattedMessage id={messageKey}/> }
            </span>
        </div>
    );
}
