import {
    Box, IconButton, Button, DialogActions, DialogContent,
    List, ListItem, ListItemIcon, ListItemText, Paper, Typography
} from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES, ADMIN_WEB_CACHE } from "admin/constant";
import { setActiveModal, setConfigurePractice, updateWebAdminCachePractice } from "admin/store/actions";
import { fetchAppointmentTypes, fetchCancellationTypes, fetchWaitingRoom } from "admin/store/actions/configuration";
import { useRef, ReactElement, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross_Circle.svg";
import { ReactComponent as CheckMark } from "icon-library/SVG/Checkmark.svg";
import EvisitTypes from "./Configuration/EvisitTypes";
import ConfigureWaitingRoom from "./Configuration/ConfigureWaitingRoom"
import EvisitCancellationTypes from "./Configuration/EvisitCancellationTypes"
import UserEmail from "./Configuration/UserEmail"
import PatientEmail from "./Configuration/PatientEmail";
import AddUserConfigure from "./User/AddUserConfigure";
import ConfigureLocation from "./Location/ConfigureLocation"
import AddPatientsConfigure from "./Patients/AddPatientsConfigure"
import AssignProductsConfigure from "./User/AssignProductsConfigure";
import InviteUserConfigure from "./User/InviteUserConfigure";
import { AppReduxStore } from "admin/store/reducerTypes";

const PracticeConfiguration = () => {
    const { admin, modalAdmin, auth } = useSelector((state: AppReduxStore) => state);
    const { currentProfileRoleJoinInfo } = auth
    const isAltaisAdmins = ["ALTAIS_ADMIN_1", "ALTAIS_ADMIN_2", "ALTAIS_ADMIN_3", "ALTAIS_ADMIN_4"].includes(currentProfileRoleJoinInfo?.level?.name);
    const { activeModal } = modalAdmin
    const { adminWebCachePractice, configurePracticeProducts, configurePracticeName } = admin
    const coreLicenseCount = configurePracticeProducts && configurePracticeProducts.find((core: any) => core.product.code === "CORE")?.licenseCount
    const hasCoreLicense = coreLicenseCount ? coreLicenseCount > 0 : false
    const dispatch = useDispatch()
    const intl = useIntl()
    const [selectedAddPracticeStepIndex, setSelectedAddPracticeStepIndex] = useState<any>(1);
    const classes = useStyles(selectedAddPracticeStepIndex === 1)
    const CrossIcon = createSvgIcon(CrossSvg)
    const CheckIcon = createSvgIcon(CheckMark)
    const eVisitTypesRef = useRef<any>();
    const eVisitCancellationTypesRef = useRef<any>();
    const configureWaitingRoomRef = useRef<any>();
    const userEmailRef = useRef<any>();
    const patientEmailRef = useRef<any>();
    const addUserConfigureRef = useRef<any>();
    const assignProductsConfigureRef = useRef<any>();
    const inviteUserConfigureRef = useRef<any>();
    const locationConfigureRef = useRef<any>();
    const addPatientsConfigureRef = useRef<any>();

    useEffect(() => {
        if (admin.configurePracticeId) {
            const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData
            if (tempFormData?.configurePractice) {
                setSelectedAddPracticeStepIndex(tempFormData?.selectedStep);
            }
            dispatch(fetchAppointmentTypes())
            dispatch(fetchCancellationTypes())
            dispatch(fetchWaitingRoom())
        }
    }, [admin.configurePracticeId])

    useEffect(() => {
        // Reset stepper index
        const cIndex = selectedAddPracticeStepIndex;
        setSelectedAddPracticeStepIndex(1)
        setSelectedAddPracticeStepIndex(cIndex)
    }, [hasCoreLicense])

    const AddPracticeStepContent = (step: number): ReactElement => {
        if (!hasCoreLicense) {
            switch (step) {
                case 1:
                case 2:
                case 3:
                    step = 4
                    setSelectedAddPracticeStepIndex(step)
                    break;
                case 5:
                    step = 6
                    setSelectedAddPracticeStepIndex(step)
                    break;
                case 10:
                    step = 9
                    setSelectedAddPracticeStepIndex(step)
                    break;
            }
        }
        let content: ReactElement;
        switch (step) {
            case 1:
                content = <EvisitTypes ref={eVisitTypesRef} />
                break;
            case 2:
                content = <EvisitCancellationTypes ref={eVisitCancellationTypesRef} />
                break;
            case 3:
                content = <ConfigureWaitingRoom ref={configureWaitingRoomRef} />
                break;
            case 4:
                content = <UserEmail ref={userEmailRef} />
                break;
            case 5:
                content = <PatientEmail ref={patientEmailRef} />
                break;
            case 6:
                content = <AddUserConfigure ref={addUserConfigureRef} />
                break;
            case 7:
                content = <AssignProductsConfigure ref={assignProductsConfigureRef} />
                break;
            case 8:
                content = <InviteUserConfigure ref={inviteUserConfigureRef} />
                break;
            case 9:
                content = <ConfigureLocation ref={locationConfigureRef} />
                break;
            case 10:
                content = <AddPatientsConfigure ref={addPatientsConfigureRef} />
                break;
            default:
                content = <div>Not Implemented</div>
        }

        return content;
    }
    const handleConfigureSaveAndNextAction = (event: any, index: any) => {
        const nextStep = index + 1
        switch (index) {
            case 1:
                if (eVisitTypesRef.current.saveAction(event)) setSelectedAddPracticeStepIndex(nextStep)
                break
            case 2:
                if (eVisitCancellationTypesRef.current.saveAction(event)) setSelectedAddPracticeStepIndex(nextStep)
                break
            case 3:
                if (configureWaitingRoomRef.current.saveAction(event)) setSelectedAddPracticeStepIndex(nextStep)
                break
            case 4:
                if (userEmailRef.current.saveAction(event)) setSelectedAddPracticeStepIndex(nextStep)
                break
            case 5:
                if (patientEmailRef.current.saveAction(event)) setSelectedAddPracticeStepIndex(nextStep)
                break
            case 6:
            case 7:
            case 8:
            case 9:
                setSelectedAddPracticeStepIndex(nextStep)
                break;
        }
    }

    const handleSaveAndContinueLaterClick = (event: any, index: any) => {
        switch (index) {
            case 1:
                eVisitTypesRef.current.saveAndContinueLaterAction(index)
                break
            case 2:
                eVisitCancellationTypesRef.current.saveAndContinueLaterAction(index)
                break
            case 3:
                configureWaitingRoomRef.current.saveAndContinueLaterAction(index)
                break
            case 4:
                userEmailRef.current.saveAndContinueLaterAction(index)
                break
            case 5:
                patientEmailRef.current.saveAndContinueLaterAction(index)
                break
            case 6:
                addUserConfigureRef.current.saveAndContinueLaterAction(index)
                break
            case 7:
                assignProductsConfigureRef.current.saveAndContinueLaterAction(index)
                break
            case 8:
                inviteUserConfigureRef.current.saveAndContinueLaterAction(index)
                break
            case 9:
                locationConfigureRef.current.saveAndContinueLaterAction(index)
                break;
            case 10:
                addPatientsConfigureRef.current.saveAndContinueLaterAction(index)
                break;
        }
        handleClose()
    }

    const handleSendInvite = (event: any, index: any) => {
        inviteUserConfigureRef.current.sendInvites(index)
        setSelectedAddPracticeStepIndex(index + 1)
    };

    const handlePreviousPracticeStepClick = (e: any, index: any) => {
        if (selectedAddPracticeStepIndex > index) {
            setSelectedAddPracticeStepIndex(index)
        }
    }

    const handleFinishConfigurePractice = () => {
        const formData = adminWebCachePractice?.formData;
        delete formData[admin.configurePracticeId]
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
        onClose()
    }

    const onClose = () => {
        setSelectedAddPracticeStepIndex(1);
        dispatch(setConfigurePractice(null, null))
        dispatch(setActiveModal(""))
    }

    const handleClose = () => {
        onClose()
    }

    const modalClasses = {
        paper: classes.wrapper
    }

    return (activeModal === MODAL_TYPES.PRACTICE_CONFIGURATION &&
        <Modal onClose={onClose} id={MODAL_TYPES.PRACTICE_CONFIGURATION} classes={modalClasses}>
            <DialogActions className={classes.dialogAction}>
                <Box flexGrow={1} >
                    <Typography className={classes.dialogHeaderTitle}>
                        <FormattedMessage id={"ConfigurePractice.ModalHeading"} values={{ name: configurePracticeName ? `(${configurePracticeName})` : "" }} />
                    </Typography>
                </Box>
                <Box>
                    <IconButton onClick={handleClose} size="large">
                        <CrossIcon className={classes.closeButtonIcon} />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Button id="btnSaveAndContinueLater" variant="outlined" style={{ marginLeft: '10px' }}
                        aria-haspopup="true" onClick={(event) => handleSaveAndContinueLaterClick(event, selectedAddPracticeStepIndex)}>
                        {intl.formatMessage({ id: "AddPractice.Action.SaveAndContinueLater" })}
                    </Button>
                    {(selectedAddPracticeStepIndex === 8) &&
                        <Button
                            id="btnSkipInvites"
                            variant="outlined"
                            color="primary"
                            aria-haspopup="true"
                            onClick={(event) => handleConfigureSaveAndNextAction(event, selectedAddPracticeStepIndex)}
                            className={classes.dialogHeaderSaveButton}>
                            <FormattedMessage id="Practice.Configuration.Button.Skip" />
                        </Button>
                    }
                    {
                        (selectedAddPracticeStepIndex <= (hasCoreLicense ? 9 : 8)) && !isAltaisAdmins ?
                            <Button
                                id="btnSave&Next"
                                variant="contained"
                                color="primary"
                                aria-haspopup="true"
                                onClick={(event) => { selectedAddPracticeStepIndex === 8 ? handleSendInvite(event, selectedAddPracticeStepIndex) : handleConfigureSaveAndNextAction(event, selectedAddPracticeStepIndex) }}
                                className={classes.dialogHeaderSaveButton}
                            >
                                <FormattedMessage id={selectedAddPracticeStepIndex === 8 ? "Practice.Configuration.Button.SendInvitesToUsers" : "Practice.Configuration.Button.Next"} />
                            </Button>
                            :
                            <Button
                                id="btnFinishConfigurePractice"
                                variant="outlined"
                                color="primary"
                                aria-haspopup="true"
                                onClick={handleFinishConfigurePractice}
                                className={classes.dialogHeaderSaveButton}
                            >
                                <FormattedMessage id={"Practice.Configuration.Button.FinishConfiguringParctice"} />
                            </Button>
                    }
                </Box>
            </DialogActions>
            <DialogContent dividers={true} classes={{ root: classes.dialogContentRoot, dividers: classes.dialogContentDividers }}>
                <Paper square elevation={0}>
                    <Box className={classes.contentWrapper}>
                        <Box className={classes.contentArea}>
                            <Box className={classes.leftPaneWrapper}>
                                <List>
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddPracticeStepIndex >= 1}
                                        onClick={(event) => handlePreviousPracticeStepClick(event, 1)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddPracticeStepIndex >= 1) ?
                                                <div className={classes.circleNumberSelected}>{selectedAddPracticeStepIndex >= 6 ? <CheckIcon /> : "1"}</div>
                                                : <div className={classes.circleNumber}>1</div>
                                            }
                                            <div className={classes.verticalLine}></div>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" >
                                                <FormattedMessage id="Configurations" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    {selectedAddPracticeStepIndex <= 5 ?
                                        <>
                                            {hasCoreLicense && <ListItem
                                                disableGutters
                                                classes={{
                                                    root: classes.sideNavOptionSub,
                                                    selected: classes.selected
                                                }}
                                                selected={selectedAddPracticeStepIndex >= 1}
                                                onClick={(event) => handlePreviousPracticeStepClick(event, 1)}
                                            >
                                                <ListItemIcon>
                                                    {(selectedAddPracticeStepIndex >= 1) ?
                                                        <div className={classes.circleSubNumberSelected}>{selectedAddPracticeStepIndex >= 2 ? <CheckIcon /> : "a"}</div>
                                                        : <div className={classes.circleSubNumber}>a</div>
                                                    }
                                                    {(selectedAddPracticeStepIndex > 1) ?
                                                        <div className={classes.verticalLineSub}></div>
                                                        : <div className={classes.verticalLineSubGrey}></div>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h4" >
                                                        <FormattedMessage id="VisitTypes.Text.Config.VisitTypes" />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>}
                                            {hasCoreLicense && <ListItem
                                                disableGutters
                                                classes={{
                                                    root: classes.sideNavOptionSub,
                                                    selected: classes.selected
                                                }}
                                                selected={selectedAddPracticeStepIndex >= 2}
                                                onClick={(event) => handlePreviousPracticeStepClick(event, 2)}
                                            >
                                                <ListItemIcon>
                                                    {(selectedAddPracticeStepIndex >= 2) ?
                                                        <div className={classes.circleSubNumberSelected}>{selectedAddPracticeStepIndex >= 3 ? <CheckIcon /> : "b"}</div>
                                                        : <div className={classes.circleSubNumber}>b</div>
                                                    }
                                                    {(selectedAddPracticeStepIndex > 2) ?
                                                        <div className={classes.verticalLineSub}></div>
                                                        : <div className={classes.verticalLineSubGrey}></div>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h4" >
                                                        <FormattedMessage id="VisitTypes.Cancellation" />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>}
                                            {hasCoreLicense && <ListItem
                                                disableGutters
                                                classes={{
                                                    root: classes.sideNavOptionSub,
                                                    selected: classes.selected
                                                }}
                                                selected={selectedAddPracticeStepIndex >= 3}
                                                onClick={(event) => handlePreviousPracticeStepClick(event, 3)}
                                            >
                                                <ListItemIcon>
                                                    {(selectedAddPracticeStepIndex >= 3) ?
                                                        <div className={classes.circleSubNumberSelected}>{selectedAddPracticeStepIndex >= 4 ? <CheckIcon /> : "c"}</div>
                                                        : <div className={classes.circleSubNumber}>c</div>
                                                    }
                                                    {(selectedAddPracticeStepIndex > 3) ?
                                                        <div className={classes.verticalLineSub}></div>
                                                        : <div className={classes.verticalLineSubGrey}></div>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h4" >
                                                        <FormattedMessage id="VisitTypes.Patient.Waiting.Room" />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>}
                                            <ListItem
                                                disableGutters
                                                classes={{
                                                    root: classes.sideNavOptionSub,
                                                    selected: classes.selected
                                                }}
                                                selected={selectedAddPracticeStepIndex >= 4}
                                                onClick={(event) => handlePreviousPracticeStepClick(event, 4)}
                                            >
                                                <ListItemIcon>
                                                    {(selectedAddPracticeStepIndex === 4 || selectedAddPracticeStepIndex === 5) ?
                                                        <div className={classes.circleSubNumberSelected}>{selectedAddPracticeStepIndex >= 5 ? <CheckIcon /> : hasCoreLicense ? "d" : "a"}</div>
                                                        : <div className={classes.circleSubNumber}>{hasCoreLicense ? "d" : "a"}</div>
                                                    }
                                                    {(selectedAddPracticeStepIndex > 4) ?
                                                        <div className={classes.verticalLineSub}></div>
                                                        : <div className={classes.verticalLineSubGrey}></div>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h4" >
                                                        <FormattedMessage id="VisitTypes.User.Email" />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            {hasCoreLicense && <ListItem
                                                disableGutters
                                                classes={{
                                                    root: classes.sideNavOptionSub,
                                                    selected: classes.selected
                                                }}
                                                selected={selectedAddPracticeStepIndex >= 5}
                                            >
                                                <ListItemIcon>
                                                    {(selectedAddPracticeStepIndex === 5 || selectedAddPracticeStepIndex === 6) ?
                                                        <div className={classes.circleSubNumberSelected}>{selectedAddPracticeStepIndex === 6 ? <CheckIcon /> : "e"}</div>
                                                        : <div className={classes.circleSubNumber}>e</div>
                                                    }
                                                    {(selectedAddPracticeStepIndex > 5) ?
                                                        <div className={classes.verticalLineSub}></div>
                                                        : <div className={classes.verticalLineSubGrey}></div>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h4" >
                                                        <FormattedMessage id="VisitTypes.Patient.Email" />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>}
                                        </> : ""
                                    }
                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddPracticeStepIndex >= 6}
                                        onClick={(event) => handlePreviousPracticeStepClick(event, 6)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddPracticeStepIndex >= 6) ?
                                                <div className={classes.circleNumberSelected}>{selectedAddPracticeStepIndex >= 9 ? <CheckIcon /> : "2"}</div>
                                                : <div className={classes.circleNumber}>2</div>
                                            }
                                            {(selectedAddPracticeStepIndex > 5) ?
                                                <div className={classes.verticalLine}></div>
                                                : <div className={classes.verticalLineGrey}></div>
                                            }
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" >
                                                <FormattedMessage id="User" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    {selectedAddPracticeStepIndex > 5 && (selectedAddPracticeStepIndex === 6 || selectedAddPracticeStepIndex) < 9 ?
                                        <>
                                            <ListItem
                                                disableGutters
                                                classes={{
                                                    root: classes.sideNavOptionSub,
                                                    selected: classes.selected
                                                }}
                                                selected={selectedAddPracticeStepIndex >= 6}
                                                onClick={(event) => handlePreviousPracticeStepClick(event, 6)}
                                            >
                                                <ListItemIcon>
                                                    {(selectedAddPracticeStepIndex >= 6) ?
                                                        <div className={classes.circleSubNumberSelected}>{selectedAddPracticeStepIndex >= 7 ? <CheckIcon /> : "a"}</div>
                                                        : <div className={classes.circleSubNumber}>a</div>
                                                    }
                                                    {(selectedAddPracticeStepIndex > 6) ?
                                                        <div className={classes.verticalLineSub}></div>
                                                        : <div className={classes.verticalLineSubGrey}></div>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h4" >
                                                        <FormattedMessage id="AddUser.Text.Config.Adduser" />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem
                                                disableGutters
                                                classes={{
                                                    root: classes.sideNavOptionSub,
                                                    selected: classes.selected
                                                }}
                                                selected={selectedAddPracticeStepIndex >= 7}
                                                onClick={(event) => handlePreviousPracticeStepClick(event, 7)}
                                            >
                                                <ListItemIcon>
                                                    {(selectedAddPracticeStepIndex >= 7) ?
                                                        <div className={classes.circleSubNumberSelected}>{selectedAddPracticeStepIndex >= 8 ? <CheckIcon /> : "b"}</div>
                                                        : <div className={classes.circleSubNumber}>b</div>
                                                    }
                                                    {(selectedAddPracticeStepIndex > 7) ?
                                                        <div className={classes.verticalLineSub}></div>
                                                        : <div className={classes.verticalLineSubGrey}></div>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h4" >
                                                        <FormattedMessage id="AddUser.Text.Config.EditProducts" />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem
                                                disableGutters
                                                classes={{
                                                    root: classes.sideNavOptionSub,
                                                    selected: classes.selected
                                                }}
                                                selected={selectedAddPracticeStepIndex >= 8}
                                                onClick={(event) => handlePreviousPracticeStepClick(event, 8)}
                                            >
                                                <ListItemIcon>
                                                    {(selectedAddPracticeStepIndex >= 8) ?
                                                        <div className={classes.circleSubNumberSelected}>{selectedAddPracticeStepIndex >= 9 ? <CheckIcon /> : "c"}</div>
                                                        : <div className={classes.circleSubNumber}>c</div>
                                                    }
                                                    {(selectedAddPracticeStepIndex > 8) ?
                                                        <div className={classes.verticalLineSub}></div>
                                                        : <div className={classes.verticalLineSubGrey}></div>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="h4" >
                                                        <FormattedMessage id="AddUser.Text.Config.Inite" />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </> : ""
                                    }

                                    <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddPracticeStepIndex >= 9}
                                        onClick={(event) => handlePreviousPracticeStepClick(event, 9)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddPracticeStepIndex >= 9) ?
                                                <div className={classes.circleNumberSelected}>{selectedAddPracticeStepIndex > 9 ? <CheckIcon /> : "3"}</div>
                                                : <div className={classes.circleNumber}>3</div>
                                            }
                                            {hasCoreLicense && !isAltaisAdmins && (selectedAddPracticeStepIndex > 9 ?
                                                <div className={classes.verticalLine}></div>
                                                : <div className={classes.verticalLineGrey}></div>
                                            )}
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" >
                                                <FormattedMessage id="Locations" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    {hasCoreLicense && !isAltaisAdmins && <ListItem
                                        disableGutters
                                        classes={{
                                            root: classes.sideNavOption,
                                            selected: classes.selected
                                        }}
                                        selected={selectedAddPracticeStepIndex === 10}
                                        onClick={(event) => handlePreviousPracticeStepClick(event, 10)}
                                    >
                                        <ListItemIcon>
                                            {(selectedAddPracticeStepIndex === 10) ?
                                                <div className={classes.circleNumberSelected}>4</div>
                                                : <div className={classes.circleNumber}>4</div>
                                            }
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="h4" >
                                                <FormattedMessage id="Patients" />
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>}

                                </List>
                            </Box>
                            <Box className={classes.rightContent}>
                                {AddPracticeStepContent(selectedAddPracticeStepIndex)}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </DialogContent>
        </Modal >
    )
}

export default PracticeConfiguration