import { ApiRequest, ApiStatusCode, IApiPromise } from "./types";
import { deepCopy } from "common/utils/object-utils";
import { getApiService } from "lib/service-manager";
import { hasResult } from "./api-utils";
import { useCallback, useEffect, useRef, useState } from "react";

type FetchInfo<TRequest> = {
    error: Error | null;
    hasResult: boolean;
    requestParams: TRequest | null;
    state: ApiStatusCode;
};

type UseApiHookReturnObject<TRequest, TContent> = {
    cancel: () => void;
    content: TContent;
    fetchInfo: FetchInfo<TRequest>;
    request: (requestParams: TRequest) => IApiPromise<TContent>;
    reset: () => void;
};

export const useApi = <TRequest, TResponse, TContent = TResponse>(
    req: ApiRequest<TRequest, TResponse, TContent>
): UseApiHookReturnObject<TRequest, TContent> => {
    const [content, setContent] = useState<TContent>(req.defaultContent);
    const [error, setError] = useState<Error | null>(null);
    const [fetchState, setFetchState] = useState<ApiStatusCode>(ApiStatusCode.NOT_SENT);
    const requestParamsRef = useRef<TRequest | null>(null);
    const lastApiPromiseRef = useRef<IApiPromise<TContent> | null>(null);

    const requestRef = useRef<ApiRequest<TRequest, TResponse, TContent>>(req);
    // Update internal request reference if needed (this is a dependency equality check for requestFunction useCallback)
    useEffect(() => {
        if (req.url !== requestRef.current.url) {
            requestRef.current = req;
            setContent(req.defaultContent);
            setError(null);
            setFetchState(ApiStatusCode.NOT_SENT);
        }
    }, [req]);

    const cancelFunction = useCallback(() => {
        lastApiPromiseRef.current?.abort();
    }, []);
    const requestFunction = useCallback((requestParams: TRequest) => {
        // Preparation
        const currentRequest = deepCopy(requestRef.current);
        currentRequest.params = requestParams;
        requestParamsRef.current = requestParams;
        // Using ApiService with parameterizing ApiPromise
        const requestPromise = getApiService().request<TRequest, TResponse, TContent>(currentRequest);
        lastApiPromiseRef.current = requestPromise;
        setFetchState(
            requestPromise.onFetchStatusChange((state) => {
                setFetchState(state);
            })
        );
        requestPromise.then(setContent).catch(setError);
        return requestPromise;
    }, []);
    const resetFunction = useCallback(() => {
        setContent(requestRef.current.defaultContent);
    }, []);

    return {
        cancel: cancelFunction,
        content,
        fetchInfo: {
            error,
            hasResult: hasResult(fetchState),
            requestParams: requestParamsRef.current,
            state: fetchState,
        },
        request: requestFunction,
        reset: resetFunction,
    };
};
