import { useRef } from "react";
import { useApi } from "common/utils/use-api";
import { createEConsultRequest } from "./mappers";
import { shouldCreateNewDraft } from "./validations";
import { EConsultRequest, EConsultRequestDetails, EConsultRequestStatuses } from "../types";

import { EConsultRequestCreateState } from "../types";

export const useCreateRequest = (state: Omit<EConsultRequestCreateState, "id" | "status" | "version">) => {
    const idRef = useRef<string | null>(null);

    const currentRequest = createEConsultRequest({
        id: idRef.current,
        status: EConsultRequestStatuses.DRAFT,
        version: null,
        ...state,
    });

    const { content: createdDraft } = useApi<EConsultRequest, EConsultRequestDetails, EConsultRequestDetails | null>(
        {
            id: "createEConsultRequestDraft",
            abortOnNewRequest: true,
            autoRequest: true,
            defaultContent: null,
            endpoint: "/econsult/v1/requests",
            method: "POST",
            delay: 200,
            requestDecisionMaker: (request) => {
                return shouldCreateNewDraft(request);
            },
        },
        currentRequest
    );

    if (createdDraft?.id && createdDraft.id !== idRef.current) {
        idRef.current = createdDraft.id
    }

    return idRef.current;
}

