import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, SIZING, COLORS } from "admin/theme";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
        validationContainer: {
            display: "block",
            background: "transparent",
            borderRadius: BORDER_RADIUS.MEDLARGE,
            height: "auto",
            width: "auto",
            maxWidth: "400px",
            textAlign: "left",
            [theme.breakpoints.down('md')]: {
                position: "relative",
                margin: "2rem auto",
                height: "auto",
                width: "100%",
            },
            [theme.breakpoints.between('md', 'xl')]: {
                position: "absolute",
                left: "4rem",
                top: "6rem",
            },
            [theme.breakpoints.up('lg')]: {
                position: "absolute",
                left: "6rem",
                top: "8rem",
                maxWidth: "600px",
            }
        },
        validationWrapper: {
            marginTop: SIZING.scale400,
            padding: SIZING.scale600,
            borderRadius: BORDER_RADIUS.MEDLARGE,
            background: COLORS.PW_VALIDATION_BG
        },
        header: {
            color: COLORS.PW_VALIDATION_TEXT,
            align: "left",
            "&.MuiTypography-body1": {
                [theme.breakpoints.down('md')]: {
                    display: "none"
                },
            }
        },
        validationRow: {
            display: "flex",
            alignItems: "center",
            marginBottom: SIZING.scale300
        },
        icon: {
            height: SIZING.scale1200,
            width: SIZING.scale1200,
            marginRight: SIZING.scale300,
        }
}));