import { cleanObject } from "common/utils/object-utils";
import { replaceParamsInUrl } from "common/utils/use-api-utils";
import { ApiCommunicationContext } from "../types";

const URL_PARAM_REGEX = /[/=]:/g;
const prepareUrlParams = async <TRequest, TResponse, TContent>(
    ctx: ApiCommunicationContext<TRequest, TResponse, TContent>
): Promise<ApiCommunicationContext<TRequest, TResponse, TContent>> => {
    const url = ctx.request.url;
    if (url.match(URL_PARAM_REGEX)) {
        const replaceResult = replaceParamsInUrl(ctx.request.url, ctx.request.params);
        ctx.request.url = replaceResult.url;
        ctx.request.params = (cleanObject(ctx.request.params, replaceResult.foundParamNames) ?? {}) as TRequest;
    }
    return ctx;
};

export default prepareUrlParams;
