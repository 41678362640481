import { Box, Button, Grid, Typography } from "@mui/material";
import { ADMIN_WEB_CACHE, MODAL_TYPES } from "admin/constant";
import { getWebAdminCacheOrganization, getWebAdminCachePractice, setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as ChevronRightCircleSvg } from "icon-library/SVG/Chevron_Right-Circle.svg";
import { createSvgIcon } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import AddOrg from "admin/views/Organizations/AddOrg/AddOrg";
import { setConfigurePractice, updateWebAdminCacheOrganization, updateWebAdminCachePractice } from 'admin/store/actions/admin'
import { configurePracticeStepDesc, useReduxDispatch } from "admin/common/utils";
const ChevronRightCircleIcon = createSvgIcon(ChevronRightCircleSvg);
const AppBanners = () => {
    const dispatch = useReduxDispatch()
    const intl = useIntl();
    const classes = useStyles();
    const { auth, admin } = useSelector((state: AppReduxStore) => state);
    const { adminWebCacheOrganization, adminWebCachePractice, configurePracticeProducts } = admin
    const coreLicenseCountPractice = configurePracticeProducts && configurePracticeProducts.find((core: any) => core.product.code === "CORE")?.licenseCount
    const hasCoreLicensePractice = coreLicenseCountPractice ? coreLicenseCountPractice > 0 : false
    const [isAddOrgWizardOpen, setIsAddOrgWizardOpen] = useState(false);
    useEffect(() => {
        if (auth.currentOrg?.id && auth.currentProfileRoleJoinInfo?.id) {
            dispatch(getWebAdminCacheOrganization(ADMIN_WEB_CACHE.ADD_ORGANIZATION_WIZARD_CACHE))
            dispatch(getWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE))

        }
    }, [auth.currentOrg, auth.currentProfileRoleJoinInfo, dispatch, intl]);

    const handlePendingOrgContinueBtnClick = (event: any) => {
        if (pendingOrgsLength > 1) {
            dispatch(setActiveModal(MODAL_TYPES.APP_BANNER_MODAL, { orgBanner: true }));
        } else {
            setIsAddOrgWizardOpen(true)
        }
    };
    const closeAddOrgModal = () => {
        setIsAddOrgWizardOpen(false);
    };
    const handlePendingOrgDeletetnClick = (event: any) => {
        const key = Object.keys(adminWebCachePractice?.formData)[0]
        delete adminWebCachePractice?.formData[key]
        dispatch(updateWebAdminCacheOrganization(ADMIN_WEB_CACHE.ADD_ORGANIZATION_WIZARD_CACHE, adminWebCachePractice?.formData))
    }
    const handlePendingPracticeContinueBtnClick = async (event: any) => {
        if (pendingPracticeLength > 1) {
            dispatch(setActiveModal(MODAL_TYPES.APP_BANNER_MODAL, { practiceBanner: true }));
        }
        else {
            const key = Object.keys(adminWebCachePractice?.formData)[0]
            const formData = adminWebCachePractice?.formData[key]
            if (formData?.tempFormData?.configurePractice) {
                dispatch(setConfigurePractice(key, formData?.tempFormData?.name)).then(() => {
                    dispatch(setActiveModal(MODAL_TYPES.PRACTICE_CONFIGURATION))
                }).catch(() => {
                })
            }
            else {
                dispatch(setActiveModal(MODAL_TYPES.ADD_PRACTICE, { pendingPracticeTemporaryId: key }))
            }
        }

    }
    const handlePendingPracticeDeleteBtnClick = (event: any) => {
        const key = Object.keys(adminWebCachePractice?.formData)[0]
        delete adminWebCachePractice?.formData[key]
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, adminWebCachePractice?.formData))
    }
    const pendingOrgsLength = Object.keys(adminWebCacheOrganization?.formData || {}).length
    const pendingPracticeLength = Object.keys(adminWebCachePractice?.formData || {}).length
    const formDataOrg = pendingOrgsLength === 1 ? adminWebCacheOrganization?.formData[Object.keys(adminWebCacheOrganization?.formData)[0]] : null
    const formDataPractice = pendingPracticeLength === 1 ? adminWebCachePractice?.formData[Object.keys(adminWebCachePractice?.formData)[0]] : null
    return (<>
        {pendingOrgsLength > 0 && <>
            <Grid container justifyContent="space-between" className={classes.continueLaterBanner}>
                <Grid item>
                    <ChevronRightCircleIcon fill={"#FFFFFF"} size={"2em"} />
                </Grid>
                <Grid item xs={6}>
                    <Typography color="textPrimary" className={classes.continueLaterBannerHeading}>
                        {intl.formatMessage({ id: "AppBanner.SaveAndContinue.Banner.Heading" },
                            { entityType: pendingOrgsLength === 1 ? (formDataOrg?.tempFormData?.haveOrgParent ? "Suborganization" : "Organization") : "entities" })}
                    </Typography>
                    <Typography color="textPrimary" className={classes.continueLaterBannerSubHeading}>
                        {pendingOrgsLength === 1 ? intl.formatMessage({ id: "AppBanner.SaveAndContinue.Banner.SubHeading" },
                            { step: formDataOrg?.tempFormData?.pendingSteps }) :
                            intl.formatMessage({ id: "AppBanner.SaveAndContinue.Banner.InProgressOrgs" },
                                { count: pendingOrgsLength })}

                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Box style={{ textAlign: 'right' }}>
                        {pendingOrgsLength === 1 &&
                            <Button
                                id="btnDeleteCacheOrganization" variant="outlined"
                                style={{ marginRight: "12px", color: "#0B5D8F" }} onClick={handlePendingOrgDeletetnClick} >
                                <FormattedMessage id="AppBanner.Action.Delete" />
                            </Button>}
                        <Button
                            id="btnContinueOrganization" variant="outlined"
                            style={{ color: "#0F7BBD" }} onClick={handlePendingOrgContinueBtnClick} >
                            <FormattedMessage id="AppBanner.Action.Continue" />
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {isAddOrgWizardOpen &&
                <AddOrg isAddOrgWizardOpen={isAddOrgWizardOpen} closeAddOrgModal={closeAddOrgModal} pendingOrgTemporaryId={pendingOrgsLength > 0 ? Object.keys(adminWebCacheOrganization?.formData)[0] : undefined} />
            }
        </>}
        {pendingPracticeLength > 0 && <Grid container justifyContent="space-between" className={classes.continueLaterBanner}>
            <Grid item>
                <ChevronRightCircleIcon fill={"#FFFFFF"} size={"2em"} />
            </Grid>
            <Grid item xs={6}>
                <Typography color="textPrimary" className={classes.continueLaterBannerHeading}>
                    {intl.formatMessage({ id: "AppBanner.SaveAndContinue.Banner.Practice.Heading" })}
                </Typography>
                <Typography color="textPrimary" className={classes.continueLaterBannerSubHeading}>
                    {pendingPracticeLength === 1 ?
                        configurePracticeStepDesc(intl, formDataPractice?.tempFormData?.selectedStep, hasCoreLicensePractice)
                        : intl.formatMessage({ id: "AppBanner.SaveAndContinue.Banner.InProgressOrgs" }, { count: pendingPracticeLength })}
                </Typography>
            </Grid>
            <Grid item xs={5}><Box style={{ textAlign: 'right' }}>
                {pendingPracticeLength === 1 && <Button
                    id="btnDeleteCachePractice" variant="outlined"
                    style={{ marginRight: "12px", color: "#0B5D8F" }} onClick={handlePendingPracticeDeleteBtnClick} >
                    <FormattedMessage id="AppBanner.Action.Delete" />
                </Button>}
                <Button
                    id="btnContinuePractice" variant="outlined"
                    style={{ color: "#0F7BBD" }} onClick={handlePendingPracticeContinueBtnClick}>
                    <FormattedMessage id="AppBanner.Action.Continue" />
                </Button>
            </Box>
            </Grid>

        </Grid>}
    </>)
}
export default AppBanners