import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() => {
    return {
        rootWrapper: {
            height: 'calc(100% - 150px)',
            width: 'calc(100% - 432px)',

        },
        patienDetailsHeader: {
            padding: SIZING.scale450,
            width: "100%",
            height: SIZING.scale3600,
            background: COLORS.MODAL_BG,
            boxShadow: "6px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
        },
        patienDetails: {
            display: "flex",
            marginBottom: SIZING.scale200,
            height: SIZING.scale1600,
            alignItems: "center"
        },
        patientNameAndInfo: {
            marginTop: SIZING.scale100
        },
        userIcon: {
            marginRight: SIZING.scale300,
            height: `${SIZING.scale1600} !important`,
            width: `${SIZING.scale1600} !important`,
            "&> g": {
                fill: COLORS.LBLUE
            }
        },
        patientName: {
            fontSize: SIZING.scale400,
            fontWeight: 900,
            marginBottom: SIZING.scale125,
            color: COLORS.LBLUE
        },
        patientId: {
            fontSize: SIZING.scale350,
            fontWeight: 400,
            marginLeft: SIZING.scale125,
        },
        ageAndGender: {
            fontSize: SIZING.scale350,
            marginBottom: SIZING.scale125,
            marginRight: SIZING.scale125,
            fontWeight: 700,
        },
        patientInfo: {
            display: "flex"
        },
        actionButton: {
            height: SIZING.scale1200,
            width: SIZING.scale3600,
            background: COLORS.WHITE,
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)",
            fontSize: SIZING.scale450,
            color: COLORS.PRIMARY_BTN_TEXT,
            fontWeight: 600,
            display: "flex",
            marginRight: SIZING.scale550,
            justifyContent: "space-around",
            "& svg path": {
                fill: COLORS.PRIMARY_BTN_TEXT
            },
            "&:hover": {
                backgroundColor: COLORS.WHITE
            },
        },
        dlgAction: {
            paddingRight: SIZING.scale350,
        },
        patientDetailsContent: {
            width: "100%",
            height: "100%",
            background: COLORS.MODAL_BG
        },
        patientRpmChart: {
            padding: SIZING.scale400,
            display: "flex",
            flexWrap: "wrap",
            "& .MuiDialogContent-dividers": {
                borderBottom: "none !important",
                margin: `0 ${SIZING.scale400}`
            }
        },
        rpmChartCard: {
            height: SIZING.RPM_CHART_CARD,
            width: "48%",
            margin: SIZING.scale200,
            background: COLORS.WHITE,
            boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px"
        },
        rpmCardTitle: {
            padding: SIZING.scale400,
            fontWeight: 700,
            color: COLORS.CONVERSATION_TEXT,
            "& >p":{
                fontSize:SIZING.scale350
            }
        },
        rpmCardDesc: {
            display: "flex",
            justifyContent: "space-between",
            margin: `${SIZING.scale400} 0px`,
            alignItems:'center'
        },
        rpmLevelText: {
            color: COLORS.LBLUE,
            fontWeight: 700,
            fontSize: SIZING.scale600,
            marginLeft: SIZING.scale200
        },
        dataTotalCount:{
            color:COLORS.GRAY_50,
            fontSize:SIZING.scale400
        },
        rpmLevels: {
            display: "flex",
            alignItems:'center',
            "& svg path": {
                fill: COLORS.UNKNOWN_FILE_ICON
            },
        },
        downArrow: {
            marginLeft: SIZING.scale200,
            "& svg path": {
                fill: COLORS.LGREEN
            }
        },
        upArrow: {
            marginLeft: SIZING.scale200,
            "& svg path": {
                fill: COLORS.PRIORITY_BG
            }
        },
        rpmCarePlan: {
            padding: SIZING.scale400
        },
        rpmCarePlanHead: {
            padding: SIZING.scale400,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        iconButton: {
            padding: 10,
            "& svg path": {
                fill: "rgba(86, 86, 98, .5)"
            }
        },

        input: {
            flex: 1,
            "& input":{
                textIndent:SIZING.scale200,
                color:"rgba(86, 86, 98, .5)",
                minHeight:'unset',
                height:'2.625rem !important'
            }
        },
        inputRoot: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: "65%",
            borderRadius: '32px',
            background: '#FFFFFF',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: 'none',
            "& .MuiInputBase-root": {
                border: "none !important",
                marginLeft: `-${SIZING.scale250}`,
                "&::placeholder": {
                    color: "rgba(86, 86, 98, 0.8)"
                }
            },
            "& .MuiInputBase-input": {
                "&::placeholder": {
                    color: "rgba(86, 86, 98, 0.8)"
                }
            }
        },
        ellipseIcon: {
            height: SIZING.scale1200,
            width: SIZING.scale1200,
            borderRadius: SIZING.scale1600,
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
            background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(86, 86, 98, 0.05) 100%), #FFFFFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: SIZING.scale400,
            cursor: "pointer"
        },
        rpmCarePlanDesc: {
            display: "flex",
            alignItems: "center"
        },
        addCareButton: {
            height: SIZING.scale1200,
            width: SIZING.TRANSCRIBE_MODAL_HEIGHT,
            background: COLORS.WHITE,
            boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.5), inset 0px 1px 2px rgba(0, 0, 0, 0.12)",
            fontSize: SIZING.scale450,
            color: COLORS.OUTLINED_INPUT_COLOR,
            fontWeight: 600,
            display: "flex",
            marginRight: SIZING.scale550,
            "& svg ": {
                "& path":{
                    fill: COLORS.OUTLINED_INPUT_COLOR
                },
               marginLeft:SIZING.scale200
            },
            "&:hover": {
                backgroundColor: COLORS.WHITE
            },
        },
        carePlanContent: {
            display: "flex",
            flexWrap: "wrap"
        },
        carePlanCards: {
            background: COLORS.WHITE,
            height: SIZING.scale4000,
            boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: SIZING.scale200,
            padding: SIZING.scale400,
            margin: SIZING.scale200
        },
        carePlanCardstitle: {
            display: "flex",
            justifyContent: "space-between",
            fontSize: SIZING.scale350,
            fontWeight: 700,
            color: COLORS.CONVERSATION_TEXT,
            marginBottom: SIZING.scale600,
            "& >p":{
                fontSize:SIZING.scale350
            }
        },
        carePlanCardsDesc: {
            color: COLORS.SUBTITLE_INFO_TEXT,
            fontSize: SIZING.scale600,
            marginBottom: SIZING.scale100
        },
        carePlanCardsSubDesc: {
            color: COLORS.GRAY_50,
            fontSize: SIZING.scale400
        },
        carebaMenu: {
            backgroundColor: COLORS.GRAY_2,
            marginTop: `-${SIZING.scale375}`,
            color: COLORS.GREY,
            fontSize: SIZING.scale400,
            zIndex: 3,
            right: `${SIZING.scale250} !important`,
            top: `${SIZING.scale2000} !important`,
            width: SIZING.scale7000,
            position: 'absolute',
            boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
            filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2))',
            backdropFilter: 'blur(10px)',
            borderRadius: SIZING.scale400,
            "& .MuiListItem-gutters": {
                paddingLeft: `${SIZING.scale750} !important`
            }
        },
        caretraingle: {
            height: SIZING.scale500,
            width: SIZING.scale800,
            position: "absolute",
            right: SIZING.scale200,
            top: SIZING.scale1050,
            zIndex: 4,
            background: COLORS.GRAY_2,
            boxShadow: 'inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.02)',
            transform: "translate(-50%, 50%) rotate(360deg)",
            clipPath: "polygon(50% 0, 100% 100%, 0 100%)"
        },
        listText: {
            fontSize: SIZING.scale400,
            fontWeight: 'bold',
            position: "relative",
            display: "inline-block"
        },
        ellipseIconColor:{
            "& g, & path": {
                fill: `${COLORS.CONVERSATION_TEXT} !important`,
            },
        }
    }
})
