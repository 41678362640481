import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
    titleWrapper: {
        padding: SIZING.scale800,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: COLORS.LEARNING_CENTER_HEADER_BG,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
    },
    close: {
        cursor: "pointer"
    },
    content: {
        minHeight: "520px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: SIZING.scale1000,
        background: COLORS.LEARNING_CENTER_BG,
        "& .MuiFormHelperText-contained": {
            //TODO can we apply this globally?
            marginLeft: 0,
            marginRight: 0
        }
    },
    spinnerWrapper: {
        minHeight: "500px",
        alignItems: "center"
    },
    successText: {
        textAlign: "center"
    },
    input: {
        width: "100%"
    },
    label: {
        display: "block",
        fontWeight: 600,
        color: COLORS.GREY,
        fontSize: SIZING.scale400,
        marginBottom: "10px"
    },
    submitBtn:{
        width:"109px",
        height:SIZING.scale1200,
        float:"right",
    },
    firstNameInput: {
        marginRight: "7px"
    }
}));