/**
 * TODO Revise and refactor (function names, do we need class or is module enough, documentation)
 Controller will help to keep track of one going API request which is not yet resolved. If we try to hit the same api again before it resolved then it will cancel the existing API

 Use Case
   while we hit user search API inside debounce

 Example
    export const searchPatientBySearchString  = (searchStr: string,fromModule:string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const signal =  ApiController.setEvent('pngInvite')  // regitering API
                const response = await call("GET", '/patient/v1/search', {searchStr, fromModule: fromModule},signal)
                ApiController.removeEvent('pngInvite')  // unregitering API
                reject()
            ApiController.removeEvent('recipientSearch') // unregitering API
            }
        })         resolve(response)
            } catch (e: any) {

    }

 */

class ApiController {
    private events: Record<string, AbortController> = {};

    setEvent(name: string): AbortSignal {
        const existingController = this.getEvent(name);
        if (existingController) {
            existingController.abort();
        }
        const Controller = new AbortController();
        this.events[name] = Controller;
        return Controller.signal;
    }

    getEvent(name: string): AbortController {
        return this.events[name];
    }

    removeEvent(name: string): void {
        delete this.events[name];
    }
}

export default new ApiController();
