import { MenuItem, MenuList } from "@mui/material";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import {
    OptionMenuProps,
    LabelRenderProps,
    isSeparatorItem,
    isSelectableMenuItem,
    isCheckableMenuItem,
    AnyMenuItem,
} from "./types";
import { useStyles } from "./styles";
import CustomTypography from "../CustomTypography/CustomTypography";
import MenuSeparatorView from "./menu-separator-view";
import Popover from "lib/ui-components/popover/popover";
import SelectableMenuItemView from "./selectable-menu-item-view";
import CheckableMenuItemView from "./checkable-menu-item-view";

const defaultLabelRender = ({ className, labelText, labelKey, labelValues }: LabelRenderProps) => (
    <CustomTypography className={className} variant="optionMenuItem">
        {labelText ?? <FormattedMessage id={labelKey} values={labelValues} />}
    </CustomTypography>
);

// OptionMenu is a generic menu used to show additional functionality for buttons across the app.
const OptionMenu = ({
    /** Unique identifier of the option menu */
    id,
    className,
    open, // determines whether the menu is visible to the user or not
    anchorEl, // holds the of the component that the option menu should hang off of. This is where the tail will point to
    containerEl, // holds the of the component that the option menu is contained in. This can be separate from the anchor point
    titleKey, // represents the title used for the custom configuration
    handleClose, // callback called when the user selects an item or clicks outside the menu
    menuConfig, // configuration for the menu items.
    placement = "bottom", // adjusts the location of the tail on the menu,
    renderOptionLabel = defaultLabelRender, // customize how the label is rendered
    variant = "light",
    offset,
}: OptionMenuProps) => {
    const classes = useStyles();

    // Renders individual menu item with custom icon, label and callback
    const renderMenuItem = (menuItem: AnyMenuItem, index: number) => {
        if (isSeparatorItem(menuItem)) {
            return <MenuSeparatorView />;
        }
        let menuItemModifierClasses;
        let renderedMenuItem;
        let onClick;
        if (isSelectableMenuItem(menuItem)) {
            const { onClick: onClickMenuItem, selected, danger } = menuItem;
            onClick = () => {
                handleClose();
                onClickMenuItem();
            };
            menuItemModifierClasses = {
                "optionMenu__menuItemWrapper--selected": selected,
                "optionMenu__menuItemWrapper--danger": danger,
            };
            renderedMenuItem = <SelectableMenuItemView labelRenderer={renderOptionLabel} menuItem={menuItem} />;
        }
        if (isCheckableMenuItem(menuItem)) {
            const { onClick: onClickMenuItem, checked } = menuItem;
            onClick = () => {
                onClickMenuItem();
            };
            menuItemModifierClasses = {
                "optionMenu__menuItemWrapper--checked": checked,
            }
            renderedMenuItem = <CheckableMenuItemView labelRenderer={renderOptionLabel} menuItem={menuItem} />;
        }
        return (
            <MenuItem
                className={classNames(classes.menuItemWrapper, menuItemModifierClasses)}
                disableRipple
                disableGutters
                key={index}
                onClick={onClick}
                disabled={menuItem.disabled}
            >
                {renderedMenuItem}
            </MenuItem>
        );
    };

    if (!open) {
        return null;
    }

    return (
        <Popover
            id={id ?? ""}
            anchorEl={anchorEl?.current}
            className={className}
            data-testid={id}
            offset={offset}
            onClose={handleClose}
            open={open}
            placement={placement}
            variant={variant}
        >
            <div className={classNames({ [`optionMenu--${variant}`]: variant })}>
                {titleKey && (
                    <CustomTypography variant="optionMenuHeader" className={classes.titleWrapper}>
                        <FormattedMessage id={titleKey} />
                    </CustomTypography>
                )}
                <MenuList className={classes.menuList}>{menuConfig.map(renderMenuItem)}</MenuList>
            </div>
        </Popover>
    );
};

export default OptionMenu;
