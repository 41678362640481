import { FC } from "react";
import classNames from "classnames";
import * as CSS from "./class-names";
import { TestProps } from "../types";

export type PageMainHeadProps = {
    className?: string;
    variant?: "default" | "lightBlue";
} & TestProps;
export const PageMainHead: FC<PageMainHeadProps> = ({ className, children, variant, id, testId }) => {
    return (
        <div
            id={id}
            data-testid={testId || id}
            className={classNames(className, CSS.PageMainHead, {
                [CSS.PageMainHeadLightBlue]: variant === "lightBlue",
            })}
        >
            {children}
        </div>
    );
};
