import { makeStyles } from "@mui/styles";
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    appointmentTypes: {
        minWidth: '15.6rem'
    },
    triggerTypes: {
        minWidth: '12.2rem'
    },
    checkbox: {
        "&.Mui-disabled": {
            color: grey[400]
        }
    }
}));