import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { AppReduxStore } from "png/store/reducerTypes";
import { call } from "png/store/api"
import moment from "moment"
import { Client, Message } from "@twilio/conversations";

export const validatePngLink = (token: string,providerName: string): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {
                const date = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS[Z]");

                let result:any = null

                dispatch({ type:'RESET_NAE_STORE' })

                if(providerName){
                   result = await call("GET", `/conference/v1/evisit/guest/invite/ondemand/validate?token=${token}&providerId=${providerName}&checkInTime=${date}`)
                }else{
                   result = await call("GET", `/conference/v1/evisit/guest/invite/validate?token=${token}&checkInTime=${date}`)
                } 

                sessionStorage.setItem('providerName',providerName||'')
                
                if(result.status ===207){
                    result = {
                        ...JSON.parse(result.message),
                        isUserRestricted:true
                    }
                }
               
                dispatch({ type: 'SET_PNG_MOBILE_DETAILS', payload: result })
                resolve(result)
            } catch (e: any) {
                dispatch({ type: 'SET_NAE_INVALID_INVITE' })
                reject(e)
            }
        })
    }
}

export const enterWaitingRoom = (token?: string, extraParams?: any,type?: string, practiceId?: any): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {

            const { pngDetails } = getState().pngMobile;

            try {
                const checkInTime = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS[Z]");
                const params = {
                    checkInTime,
                    token,
                    status: 'WAITINGROOM',
                    guestVisitType:type==='scheduled'?'SCHEDULED':'ONDEMAND',
                    ...(extraParams?extraParams:'')
                }
                const result = await call("POST", `/conference/v1/evisit/guest/waitingroom`, params)
                const { providerTwilioUsername } = result;

                dispatch({ type: 'SET_PNG_TWILIO_TOKEN', payload: result.twilioToken })

                dispatch({ type: 'UPDATE_NAE_DETAILS', payload: {providerTwilioUsername} })

                const providerName = sessionStorage.getItem("providerName");

                sessionStorage.clear()

                sessionStorage.setItem('meetingType',type||'')
                sessionStorage.setItem('providerTwilioId',providerTwilioUsername)
                sessionStorage.setItem('providerName',providerName||'')
                sessionStorage.setItem('practiceId',practiceId||'')

               
                if(type==='scheduled'){
                    const tokenDetails = pngDetails?.inviteLink?.split("/")[2];
                    sessionStorage.setItem('inviteDetails',JSON.stringify({ pngToken:tokenDetails ,chatToken:result.twilioToken}))
                }else{
                    const tokenDetails = pngDetails?.inviteLink?.split("/")[3];
                    sessionStorage.setItem('inviteDetails',JSON.stringify({  pngToken:tokenDetails ,chatToken:result.twilioToken,pngDetails}))
                    sessionStorage.setItem('inviteUrl',window.location.href)
                }

                if (result.twilioToken) {

                    const client = await Client.create(result.twilioToken)

                    if (client) {
                        dispatch({ type: 'SET_NAE_TWILIO_CLIENT', payload: client })
                        // @ts-ignore
                        dispatch(initClientListeners(client,type))
                    }
                }
                resolve(result)
            } catch (e: any) {
                console.log(e)
                reject(e)
            }
        })
    }
}

export const pingProvider = (token?: string, extraParams?: any,type?: string): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {

            const { pngDetails } = getState().pngMobile;
            const practiceId = sessionStorage.getItem("practiceId");

            const { visitReminderId, twilioUsername, providerTwilioUsername } = pngDetails || {};

            try {
        
                await call("POST", '/messaging/v1/guest/system-message', {
                    to:providerTwilioUsername ||'',
                    from: twilioUsername,
                    payload: {
                        type:'SYS_NRU_EVISIT_PING',
                        body: { 
                            visitReminderId
                        }
                    }
                })

                await call ("PATCH",`/conference/v1/evisit/guest/patientactivestate/${practiceId}/${visitReminderId}`)
                resolve()
            } catch (e: any) {
                console.log(e)
                reject(e)
            }
        })
    }
}

export const rejoinMeeting = (): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {

            try {
             
                const inviteDetails = sessionStorage.getItem("inviteDetails");
                const url = sessionStorage.getItem("inviteUrl");

                if(!inviteDetails && !url ){
                    reject()
                    return ''
                }

                if(inviteDetails){

                    const { pngToken = "", chatToken = "", pngDetails = "" } = JSON.parse(inviteDetails) || {};

                    const date = moment().utc().format("yyyy-MM-DDTHH:mm:ss.SSS[Z]");

                   let result = null

                   const type = sessionStorage.getItem('meetingType')
                   const providerTwilioUsername = sessionStorage.getItem('providerTwilioId')

                   if(type==='scheduled'){
                    result = await call("GET", `/conference/v1/evisit/guest/invite/validate?token=${pngToken}&checkInTime=${date}`)
                 }else{
                    if(pngDetails){
                        result = pngDetails
                    }else{
                        reject()
                    }
                    
                 } 
                

                    if(result?.visitReminderId){

                        dispatch({ type: 'SET_PNG_TWILIO_TOKEN', payload: chatToken })
    
                        dispatch({ type: 'SET_PNG_MOBILE_DETAILS', payload: {...result,providerTwilioUsername} })
    
                        dispatch({ type: 'SET_NAE_TWILIO_VIDEO_ROOM_ID', payload: sessionStorage.getItem('roomId') })
    
                        dispatch({ type: 'SET_NAE_TWILIO_VIDEO_TOKEN', payload: sessionStorage.getItem('roomToken') })
    
                        if (chatToken) {
    
                            const client = await Client.create(chatToken)
        
                            if (client) {
                                dispatch({ type: 'SET_NAE_TWILIO_CLIENT', payload: client })
                                // @ts-ignore
                                dispatch(initClientListeners(client,'scheduled'))
                            }
                        }
                    }else{
                        dispatch({ type: 'SET_NAE_INVALID_INVITE' })
                    }

                }else{
                    //@ts-ignore
                    window.location.href = sessionStorage.getItem('inviteUrl')
                    // window.location.reload()
                }
         
            } catch (e: any) {
                console.log(e)
                reject(e)
            }
        })
    }
}

export const endNRUCall = (token?: string): ThunkAction<Promise<void>, AppReduxStore, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppReduxStore) => {
        return new Promise(async (resolve, reject) => {
            try {
                const params = {
                    token,
                    status: 'COMPLETE'
                }
                const result = await call("POST", `/conference/v1/evisit/guest/waitingroom`, params)
                resolve(result)
            } catch (e: any) {
                reject(e)
            }
        })
    }
}


// initClientListeners initializers event listeners for the twilio client
export const initClientListeners = (client: Client,type: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {

        client.removeAllListeners()

        client.on("messageAdded", async (message: Message) => {
            const { body } = message || {};
            if (body === null) {
                return;
            }
            const details = JSON.parse(body);
            switch (details.type) {
                case 'SYS_NRU_EVISIT_CALL_INCOMING':
                    const { roomName = "", patientTwilioVideoToken } = details?.body || {};
                        sessionStorage.setItem('isPaused','false')
                        sessionStorage.setItem('roomId',roomName)
                        sessionStorage.setItem('roomToken',patientTwilioVideoToken)
    
                    dispatch({ type: 'SET_NAE_TWILIO_VIDEO_ROOM_ID', payload: roomName })
                    dispatch({ type: 'SYS_NRU_EVISIT_PAUSED',payload:false })
                    dispatch({ type: 'SET_NAE_TWILIO_VIDEO_TOKEN', payload: patientTwilioVideoToken })
                    break;
                case 'SYS_NRU_EVISIT_DROP':
                    dispatch({ type: 'SET_NAE_DROP_USER'})
                    sessionStorage.clear()
                    break;
                case 'SYS_NRU_EVISIT_CALL_PAUSED':
                    dispatch({ type: 'SYS_NRU_EVISIT_PAUSED',payload:true })
                    sessionStorage.setItem('isPaused','true')
                    break;
                case 'SYS_NRU_EVISIT_CALL_BACK_TO_WAITINGROOM':
                    sessionStorage.removeItem('isPaused')
                    sessionStorage.removeItem('roomId')
                    sessionStorage.removeItem('roomToken')
                    dispatch({ type: 'SET_NAE_TWILIO_VIDEO_ROOM_ID', payload: '' })
                    dispatch({ type: 'SYS_NRU_EVISIT_PAUSED',payload:false })
                    dispatch({ type: 'SET_NAE_TWILIO_VIDEO_TOKEN', payload: '' })
                    break;
                case 'SYS_NRU_EVISIT_END':
                    dispatch({ type: 'SET_NAE_CALL_ENDED' })
                    sessionStorage.clear()
                    break;
                case 'SYS_NRU_EVISIT_QUICK_CHAT':
                    const { quickMessage, messageFrom } = details?.body || {};
                    dispatch({ type: 'SET_QUICK_MESSAGE', payload: quickMessage})
                    dispatch({ type: 'SET_MESSAGE_FROM', payload: messageFrom})
                    sessionStorage.clear()
                    break;
                default:
                    break;
            }
        })
    }
}