import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";
import * as CSS from "./class-names";

export const useStyles = makeStyles(() => ({
    // For MUI Pagination configuration
    ul: {
        "& button:hover": {
            color: COLORS.WHITE,
            backgroundColor: COLORS.PAGINATION_SELECTED_PAGE_NUMBER,
        },
        "& .MuiPaginationItem-root": {
            height: SIZING.scale600,
            minWidth: SIZING.scale600,
            fontSize: SIZING.scale250,
            fontWeight: 700,
        },
        "& .MuiPaginationItem-root.Mui-selected": {
            color: COLORS.WHITE,
            backgroundColor: COLORS.PAGINATION_SELECTED_PAGE_NUMBER,
        },
        "& .MuiPaginationItem-root.Mui-selected:hover": {
            color: COLORS.WHITE,
            backgroundColor: COLORS.PAGINATION_SELECTED_PAGE_NUMBER,
        },
        "& .MuiPaginationItem-page": {
            backgroundColor: COLORS.PAGINATION_UNSELECTED_PAGE_NUMBER,
        },
        "& .MuiPaginationItem-ellipsis": {
            color: COLORS.LGRAY,
            paddingTop: SIZING.scale175,
        },
    },
    [CSS.Pagination]: {
        justifyContent: "space-between",
        display: "flex",
        marginTop: SIZING.scale175,
        [`& .${CSS.PaginationTotalCount}`]: {
            alignItems: "center",
            color: COLORS.BLACK,
            display: "inline-flex",
            height: SIZING.scale600,
            "& span": {
                marginRight: SIZING.scale120,
            },
            "& .fCount": {
                fontSize: SIZING.scale250,
                fontWeight: "bold",
            },
            "& .fText": {
                fontSize: SIZING.scale250,
                fontWeight: 600,
            },
        },
    },
}));
