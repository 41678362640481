import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    totalLicenses: {
        width: '100%',
        padding: '24px',
        background: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: 'none',
        marginBottom: '20px',
    },
    iconBtn: {
        padding: 0,
    },
    subCard: {
        width: '100%',
        padding: '16px',
        background: '#EDEDEF',
        borderRadius: '16px',
        boxShadow: 'none'
    },

    dynamicCard: {
        width: '100%',
        padding: '16px',
        marginBottom: '10px',
        background: '#FBFBFB',
        borderRadius: '16px',
        boxShadow: 'none',
    },
    dynamicListCard: {
        width: '100%',
        padding: '16px',
        marginBottom: '10px',
        background: '#F6F6F7',
        borderRadius: '16px',
        boxShadow: 'none',
    },
    licenseName: {
        color: '#393948',
        fontWeight: 700
    },
    licenseCount: {
        color: '#0B5D8F',
        fontWeight: 600
    },
    allocatedVsAvailable: {
        color: '#565662',
        fontWeight: 600
    },
    subLicenses: {
        marginTop: '12px'
    },
    productsText: {
        marginBottom: '10px'
    },
    heading: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948',
        marginBottom: '10px'
    },
    noLicensesLeft: {
        backgroundColor: '#E32B29',
        margin: '0px 8px',
        borderRadius: '50%',
        height: '8px',
        width: '8px',
        display: 'inline-block'
    }
}));