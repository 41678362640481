import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ErrorTypes, setError } from "store/actions";

export const useUserNotification = () => {
    const dispatch = useDispatch();

    const enqueueError = useCallback(
        (messageId: string, errorMessage?: string, errorDescription?: string) => {
            dispatch(setError(messageId, errorMessage, errorDescription, ErrorTypes.ERROR));
        },
        [dispatch]
    );

    const enqueueSuccess = useCallback(
        (messageId: string, errorMessage?: string, errorDescription?: string) => {
            dispatch(setError(messageId, errorMessage, errorDescription, ErrorTypes.SUCCESS));
        },
        [dispatch]
    );

    const enqueueWarning = useCallback(
        (messageId: string, errorMessage?: string, errorDescription?: string) => {
            dispatch(setError(messageId, errorMessage, errorDescription, ErrorTypes.WARNING));
        },
        [dispatch]
    );

    return {
        enqueueError,
        enqueueSuccess,
        enqueueWarning
    };
};
