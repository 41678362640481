import {
    DialogContent, Box, Button, DialogActions, IconButton, Paper, Typography
} from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment } from "react";
import { forgotUserPasswordBulkAll, setActiveModal } from "admin/store/actions";
import { useStyles } from "../ResetPassword/styles";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import { showNotification } from "admin/store/actions/snackbar";
import { forgotUserPasswordByAdmin } from "store/actions";

const ResetPatientPassword = () => {
    const dispatch = useDispatch();
    const { admin } = useSelector((state: AppReduxStore) => state);
    const intl = useIntl();
    const classes = useStyles();

    const onClose = () => {
        dispatch(setActiveModal(""));
    };

    const onResetPassword = (event: any) => {
        event.preventDefault();
        if (admin.isAllSelectedBulk) {
            dispatch(forgotUserPasswordBulkAll())
        } else {
            if (admin?.sSupportNetwork?.primaryEmail) {
                forgotUserPasswordByAdmin(admin.sSupportNetwork.primaryEmail).then((response: any) => {
                    dispatch(showNotification("Reset Password Email Sent", "success", response.message));
                }).catch((response: any) => {
                    dispatch(showNotification("Reset Password Error", "error", response.message));
                })
            } else {
                admin.sPatients && admin.sPatients.map((patient: any) => {
                    forgotUserPasswordByAdmin(patient.email).then((response: any) => {
                        dispatch(showNotification("Reset Password Email Sent", "success", response.message));
                    }).catch((response: any) => {
                        dispatch(showNotification("Reset Password Error", "error", response.message));
                    })
                    return null
                })
            }
        }
        onClose();
    };

    const modalClasses = {
        paper: classes.wrapper
    };

    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.RESET_PATIENT_PASSWORD} small={true}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "ResetPasswordModal.Title" })}
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {intl.formatMessage({ id: "ResetPasswordModal.Desc" })}
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnResetPatientPasswordModal" size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancelUL" variant="outlined" style={{ zIndex: 100 }}
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "ResetPasswordModal.CancelButton" })}
                        </Button>
                        <Button
                            id="btnSaveUL"
                            variant="outlined"
                            color="primary"
                            style={{ marginLeft: '10px', backgroundColor: '#E32B29' }}
                            onClick={onResetPassword}
                        >
                            {intl.formatMessage({ id: "ResetPasswordModal.ResetPasswordButton" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper square elevation={0}>
                    <Typography variant="body2" className={classes.confirmationBody}>
                        {intl.formatMessage({ id: "ResetPasswordModal.Body" })}
                    </Typography>
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default ResetPatientPassword;
