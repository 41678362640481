import React from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as CrossIcon } from "icon-library/SVG/Cross_Circle.svg";
// TODO: to be replaced once icon-lib deploy is fixed
import { ReactComponent as RefreshIcon } from "views/EConsult/tempIcons/Rotate_Refresh-Circle.svg";
import { useStyles } from "../styles";

const variants = {
    remove: {
        icon: CrossIcon,
        titleId: "files.preview.preview-actions.remove"
        
    },
    restart: {
        icon: RefreshIcon,
        titleId: "files.preview.preview-actions.restart"
    }
} as const;

type PreviewActionProps = {
    id: string;
    disabled?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    variant: "remove" | "restart";
};

const PreviewAction = ({ onClick, id, variant, disabled }: PreviewActionProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const { icon, titleId } = variants[variant];

    return (
        <button
            id={id}
            disabled={disabled}
            className={classNames(classes.previewAction, `previewAction__${variant}`)}
            onClick={onClick}
            title={intl.formatMessage({ id: titleId })}
        >
            <span><SVGIcon svg={icon} size="scale800" /></span>
        </button>
    );
};

export default PreviewAction;
