import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    productTableHeader: {
        backgroundColor: '#EDEDEF'
    },
    productsPageHeader: {
        marginBottom: '20px'
    },
    pageHeaderText: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948',
    },
    pageSubHeaderText: {
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#565662'
    },
    productsIcon: {
        fontSize: '42px',
        verticalAlign: 'middle',
        marginRight: '12px'
    },
}));