import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { useStyles as useStyleMobile } from "./stylesMobile";
import { Typography, Button } from "@mui/material";
import { ReactComponent as Mic } from "icon-library/SVG/Microphone.svg";
import { ReactComponent as MicDis } from "icon-library/SVG/Microphone_Disabled.svg";
import { ReactComponent as Video } from "icon-library/SVG/Camera_Video.svg";
import { ReactComponent as CamPic } from "icon-library/SVG/Camera_Photo.svg";
import { ReactComponent as VideoDis } from "icon-library/SVG/Camera_Video-Disabled.svg";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom"
import { FooterTypes } from "./type"
import Invite from "components/WaitingRoom/Invite/Invite"
import LeaveButton from "./LeaveButton/LeaveButton"
import moment from "moment"
import SVGIcon from "common/components/svg-icon/svg-icon";

export default function Footer({ room, onEndMeeting, videoAccess, showVideoError, audioAccess, handleAudio, onPauseMeeting,participants=[],onLeaveMeeting,participantType,isDomainSpeakerVideoEnabled }: FooterTypes) {
    const [muteState, updateMuteState] = useState(false)
    const [isInvited, updateInvitedStatus] = useState(false)
    const [videoState, updateVideoState] = useState(false)
    const [isMeetingEnded, updateMeetingEnded] = useState<boolean>(false)
    const [inviteOpenStatus, updatInviteOpenStatus] = useState<boolean>(false)
    const { block } = useHistory<any>()
 
    const MobileClasses = useStyleMobile({ theme: 'grey' })

    const pcClasses = useStyles({ theme: 'grey' })

    const isPatient = participantType === "PATIENT";

    const isMA = participantType === "MA";

    const classes = isPatient ? MobileClasses : pcClasses

    const handleEndCall = () => {
        disconnect()
        onEndMeeting && onEndMeeting()
    }

    const handleLeave = () => {
        disconnect()
        onLeaveMeeting && onLeaveMeeting()
    }

    useEffect(() => {
        videoAccess && videoState && updateVideoState(false)
        audioAccess && muteState && updateMuteState(false)
    }, [videoAccess, audioAccess])

    useEffect(() => {
        //@ts-ignore
        const unblock = block((location) => {
            if(!isMeetingEnded && location?.pathname!=='/wr-status'&&location?.pathname!=='/provider/waiting-room'){
                const response = window.confirm("Are you sure to leave this page? This will end ongoing call");
                if(response===true){
                    // if more than 1 participant. then we leaving the user from the call without ending the call for all
                    participants.length>1? handleLeave():  handleEndCall()
                }
                return response
            }
            return true
        });
        window.addEventListener('beforeunload', keepOnPage);
        return () => {
            unblock();
            window.removeEventListener('beforeunload', keepOnPage);
        }
    }, [handleEndCall,handleLeave,room, isMeetingEnded,participants])

    const keepOnPage = (e: any) => {
        e.preventDefault();
        const message = "Are you sure to leave this page? This will end ongoing call";
        e.returnValue = message;
        return ''
    }

    const handleMute = () => {

        if (!audioAccess) {
            handleAudio()
            return ''
        }

        room?.localParticipant?.audioTracks.forEach((publication: any) => {
            if (muteState) {
                publication.track.enable();
            } else {
                publication.track.disable();
            }
            updateMuteState(!muteState)
        });
    }

    const handlePause = () => {
        onPauseMeeting && onPauseMeeting()
        disconnect()
    }

    const handleVideo = () => {

        if (!videoAccess && !audioAccess) {
            return ''
        }

        if (!videoAccess) {
            showVideoError()
            return ''
        }
        room?.localParticipant?.videoTracks.forEach((publication: any) => {
            if (videoState) {
                publication.track.enable();
            } else {
                publication.track.disable();
            }
            updateVideoState(!videoState)
        });
    }

    const handleInvite = () => {
        updatInviteOpenStatus(prev => !prev)
    }

    const download = function (canvas: HTMLCanvasElement,name:string) {
        if (canvas) {
            const link = document.createElement('a');
            link.download = `${name}_${moment().format('YYYY-MM-DD-hhmmss')}.png`;
            link.href = canvas?.toDataURL()
            link.click();
        }
    }

    const capture = (video:HTMLVideoElement | null, scaleFactor:number | null) => {
        if(!video){
            return null
        }
        if (scaleFactor == null) {
            scaleFactor = 1;
        }
        const w = video.videoWidth * scaleFactor;
        const h = video.videoHeight * scaleFactor;
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(video, 0, 0, w, h);
        return canvas;
    }


    const disconnect = () =>{
        updateMeetingEnded(true)
        room && room.disconnect();
    }

    const handleSnapShot = () =>{
        const participantName = document.getElementById("domain-speaker-wrapper")?.getAttribute('title') || 'screenshot'
        const videoEle = document.querySelector('#domain-speaker-wrapper video') as HTMLVideoElement
        const canvas = capture(videoEle, 1.5);
        //@ts-ignore
        download(canvas,participantName)
    }


    return (
        <div className={classes.footer}>
            <Invite isOpen={inviteOpenStatus} handleClose={handleInvite}  updateInviteStatus={updateInvitedStatus}/>
            <div className={classes.optionsWrapper}>
               {room && <> <Typography variant="body1" noWrap className={classes.option} onClick={handleMute}>
                 <SVGIcon trim={true} svg={(muteState || !audioAccess) ?MicDis :Mic  } size="scale500" />
                   {isPatient && <><span className={classes.optionText}><FormattedMessage id="Twilio.footer.mute" /> </span>
                    <span className="helper"><FormattedMessage id="Twilio.footer.Audio" /> 
                    </span></>}
                </Typography>
                <Typography variant="body1" noWrap className={classes.option} onClick={handleVideo}>
                <SVGIcon trim={true} svg={(videoState || !videoAccess)?VideoDis : Video } size="scale500" />
                    {isPatient && <><span className={classes.optionText}><FormattedMessage id="Twilio.footer.pause" /> </span>
                    <span className="helper"><FormattedMessage id="Twilio.footer.Video" /></span></>}
                </Typography>
                {isDomainSpeakerVideoEnabled&&!isPatient&&<Typography variant="body1" noWrap className={`${classes.option} screenshot`} onClick={handleSnapShot}>
                <SVGIcon trim={true} svg={CamPic} size="scale500" /> Take Snapshot
                </Typography>}
                </>}
            </div>
            {(!isPatient && room) &&
                <>
                    <Button variant="contained" className={classes.inviteButton} onClick={handleInvite} >
                        <FormattedMessage id={inviteOpenStatus ? "Twilio.footer.CloseInvite" : "Twilio.footer.invite"} />
                    </Button>
                    <LeaveButton  
                       participantCount={participants.length}
                       isInvited={isInvited}
                       isMA={isMA}
                       handlePause={handlePause}
                       handleLeave={handleLeave}
                       handleEndCall={handleEndCall}
                    />
                </>}
        </div>
    )
}
