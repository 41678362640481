import { useEffect, useRef } from "react";
import { EventKeys, EventMap } from "lib/domain/events";
import { EventProvider } from "./event-provider";
import { IEventProvider } from "./types";

export const useEventListener = <K extends EventKeys>(eventType: K, listener: (details: EventMap[K]) => void) => {
    const provider = useRef(new EventProvider());
    useEffect(() => {
        const handler = (event: CustomEvent<EventMap[K]>) => {
            listener(event.detail);
        };

        provider.current.addEventListener(eventType, handler);
        const removeEventListener = provider.current.removeEventListener;
        return () => removeEventListener(eventType, handler);
    }, [eventType, listener]);
};

export const useEventDispatcher = (): IEventProvider["dispatchEvent"] => {
    const provider = useRef(new EventProvider());
    return provider.current.dispatchEvent;
};
