import { Typography, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { ReactComponent as Plus_Circle } from "icon-library/SVG/Plus_Circle.svg";
import PracticeEHRConnection from "admin/components/PracticeEHRConnection/PracticeEHRConnection";
import { forwardRef, useImperativeHandle, useRef } from "react";

const AddPracticeEHRConnection = forwardRef((props: any, ref) => {
    const classes = useStyles();
    const intl = useIntl()
    const PlusIcon = createSvgIcon(Plus_Circle);
    const refEHRConnection = useRef<any>()
    useImperativeHandle(
        ref,
        () => ({
            getEHRConnectionValues() {
                return refEHRConnection.current.getEHRConnectionValues()
            }
        }),
    )
    const { isCoreProductSelected } = props;
    return (
        <Grid container>
            <Grid item className={classes.sectionHeader}>
                <Typography className={classes.sectionHeaderText}>
                    <PlusIcon fill="#0F7BBD" className={classes.editIcon} />{intl.formatMessage({ id: "AddPracticeForm.Heading.PracticeEHR" })}
                </Typography>
                <Typography>
                    {intl.formatMessage({ id: "AddPracticeForm.Heading.PracticeEHRDesc" })}
                </Typography>
            </Grid>
            <PracticeEHRConnection ref={refEHRConnection} isCoreProductSelected={isCoreProductSelected} isSelectedPractice={false} />
        </Grid>
    )
})
export default AddPracticeEHRConnection