import { IconButton, Box, Breadcrumbs, Typography, Popover, Grid, Link, LinkProps } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Route } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { AppReduxStore } from 'admin/store/reducerTypes';
import { ReactComponent as ChevronDown } from "icon-library/SVG/Chevron_Down.svg";
import { ReactComponent as ChevronUp } from "icon-library/SVG/Chevron_Up.svg";
import { setCurrentOrganizationById, setCurrentPracticeById, setNavBreadcrumbEntityHierarchy, setNavBreadcrumbText } from 'admin/store/actions';
import { createSvgIcon } from "utils";
import { useHistory, useLocation } from 'react-router-dom';
import { getChildOrganizationSummaries } from 'admin/store/actions/organization';
import { getBreadcrumbName } from 'admin/common/utils';
import { useStyles } from "./styles";
const ChevronDownIcon = createSvgIcon(ChevronDown);
const ChevronUpIcon = createSvgIcon(ChevronUp);

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}
const AppBreadcrumbs = () => {
    const location = useLocation();
    const infoPage = location.pathname.split('/').length > 1;
    const classes = useStyles(infoPage);
    const LinkRouter = (props: LinkRouterProps) => <Link color="inherit" className={classes.link} {...props} component={RouterLink as any} />;
    const dispatch = useDispatch();
    const history = useHistory();
    const menuRef = useRef(null);
    const [menuProfileOpen, setProfileMenuOpen] = useState(false);
    const { auth, configuration, navigation, organization } = useSelector((state: AppReduxStore) => state);
    useEffect(() => {
        if (auth.currentOrg?.id || auth.currentPractice?.id) {
            dispatch(setNavBreadcrumbEntityHierarchy(navBreadcrumbEntityHierarchy(organization.profileRootOrganizationSummaries)));
        }
    }, [auth.currentOrg?.id, auth.currentPractice?.id, dispatch, organization.childOrganizationSummaries]);

    const navBreadcrumbEntityHierarchy = (listHierarchy: any[]) => {
        if (listHierarchy?.length === 0) return []
        let childNode = null as any
        const selectedEntity = listHierarchy && listHierarchy.find((e: any) => {
            return auth.currentPractice?.id ? e.practiceId === auth.currentPractice?.id : e.organizationId === auth.currentOrg?.id
        })
        const stackAncestorNode = [selectedEntity]
        const breadcrumbTree = [selectedEntity] as any;
        while (stackAncestorNode.length > 0) {
            childNode = stackAncestorNode.pop()
            // eslint-disable-next-line no-loop-func
            const nodeParent = listHierarchy && listHierarchy.find((e: any) => {
                return childNode?.parentOrgId ? e.organizationId === childNode?.parentOrgId : null
            })
            if (nodeParent !== undefined) {
                stackAncestorNode.push({ ...nodeParent });
                breadcrumbTree.push(nodeParent)
            }
        }
        breadcrumbTree.reverse()
        return breadcrumbTree
    }
    const onMenuClick = (e: any) => {
        e.preventDefault();
        setProfileMenuOpen(!menuProfileOpen);
    }
    const handleMenuClose = () => {
        setProfileMenuOpen(!menuProfileOpen);
    };
    const handleOrganizationClick = (orgName: string, orgId?: string, practiceId?: string) => {
        dispatch(setNavBreadcrumbText(orgName as string));
        if (orgId) {
            dispatch(setCurrentOrganizationById(orgId, history));
            dispatch(getChildOrganizationSummaries(orgId as string));
        }
        if (practiceId) {
            dispatch(setCurrentPracticeById(practiceId, history));
        }
    };
    const getFormNameById = (id: string) => {
        const currentForm = configuration && configuration.sPatientFormA ? configuration.sPatientFormA : {};
        if (currentForm)
            return currentForm.title;
        else
            return id;
    };
    const gerAllPrcaticesForOrg = (parentOrgId: string) => {
        const practices = organization.profileRootOrganizationSummaries.filter((e: any) => e?.practiceId && e?.parentOrgId === parentOrgId)
        return practices && practices.length > 0 ? practices : []
    }
    
    return (
        <>
            <Route>
                {({ location }) => {
                    //Exclude /admin part of the path and don't excend three levels of breadcrumb
                    const pathnames = location.pathname.split('/').filter(p => p).slice(1, 4)

                    return (

                        <Breadcrumbs aria-label="breadcrumb" separator=">" style={{ flex: 1, marginBottom: '20px' }}>
                            {
                                auth.currentOrg && navigation.breadCrumbEntityHierarchy?.length === 0 && <LinkRouter to={`/admin/home`} key={'home'}>
                                    {auth.currentOrg.name}
                                </LinkRouter>
                            }
                            {
                                auth.currentPractice && navigation.breadCrumbEntityHierarchy?.length === 0 && <LinkRouter to={`/admin/home`} key={'home'}>
                                    {auth.currentPractice.name}
                                </LinkRouter>
                            }
                            {navigation.breadCrumbEntityHierarchy && navigation.breadCrumbEntityHierarchy?.length > 0 && navigation.breadCrumbEntityHierarchy.map((e: any, index: number) => {
                                if(!e) return null
                                return (<Box display="inline-flex"><Link className={classes.link} key={'hierarchy' + index} onClick={() => handleOrganizationClick(e.organizationName, e.organizationId, e.practiceId)}>
                                    {e.organizationName}</Link>
                                    {
                                        e.practiceId && gerAllPrcaticesForOrg(navigation.breadCrumbEntityHierarchy[index - 1].organizationId).length > 1 && <div onMouseEnter={onMenuClick} onClick={onMenuClick} ref={menuRef} style={{ cursor: 'pointer' }}>
                                            <IconButton id="btnToggleOrganizationsPopover" style={{ padding: '10px' }}>
                                                {menuProfileOpen ? <ChevronUpIcon className={classes.chevronUpDownIcon} /> : <ChevronDownIcon className={classes.chevronUpDownIcon} />}
                                            </IconButton>
                                            {menuProfileOpen && <Popover elevation={8} classes={{ paper: classes.popoverRoot }} open={menuProfileOpen} anchorEl={menuRef.current}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                }}
                                                transformOrigin={{
                                                    vertical: 0,
                                                    horizontal: 100
                                                }}
                                                onClose={handleMenuClose}>
                                                <Box style={{ overflow: 'auto' }}>
                                                    {gerAllPrcaticesForOrg(navigation.breadCrumbEntityHierarchy[index - 1].organizationId)
                                                        .sort((a: any, b: any) => {
                                                            if (a.organizationName < b.organizationName) {
                                                                return -1;
                                                            } else if (a.organizationName > b.organizationName) {
                                                                return 1;
                                                            } else {
                                                                return 0;
                                                            }
                                                        })
                                                        .map((org: any, index: number) => {
                                                            const active = org.practiceId === auth.currentPractice?.id ? true : false
                                                            return (<Grid container className={active ? classes.listItemWrapperActive : classes.listItemWrapper} onClick={(e) => handleOrganizationClick(org.organizationName, org.organizationId, org.practiceId)}>
                                                                <Grid item xs style={{ padding: '4px 8px' }}>
                                                                    <Typography noWrap variant="body1" style={{ fontSize: '14px', fontWeight: 600, color: active ? "#FFFFFF" : "#393948" }}>{org.organizationName}</Typography>
                                                                </Grid>
                                                            </Grid>)
                                                        })}
                                                </Box>
                                            </Popover>}
                                        </div>
                                    }
                                </Box>)
                            })}
                            
                            {pathnames.map((value, index) => {
                                const last = index === pathnames.length - 1;
                                const to = `${pathnames.slice(0, index + 1).join('/')}`;
                                let breadcrumbText = getBreadcrumbName(value)
                                if (last && (breadcrumbText === "Details")) {
                                    breadcrumbText = navigation.breadcrumbText ?? ""
                                }
                                if (breadcrumbText === value) {
                                    breadcrumbText = getFormNameById(value);
                                }
                                return last ? (
                                    <Typography color="textPrimary" key={to} className={classes.infoText}>
                                        {breadcrumbText}
                                    </Typography>
                                ) : (
                                    <LinkRouter to={`/admin/${to}`} key={to}>
                                        {breadcrumbText}
                                    </LinkRouter>
                                );
                            })}
                        </Breadcrumbs>
                    );
                }}
            </Route>
        </>
    )
}

export default AppBreadcrumbs