import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>({
    patientTab: {
        display: "flex",
        padding: SIZING.scale400,
        flexGrow: 1,
        backgroundColor: COLORS.MODAL_CONTENT_LBLUE_BG,
        flexDirection: "column",
        alignItems: "center",
        height: "fit-content",
        minHeight: "100%"
    },
    patientSearchArea: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        "& .patientInputAndPopoverWrapper": {
            position: "relative",
        },
        "& .emptyMessage": {
            paddingBottom: SIZING.scale800,
        },
    },
    patientInput: {
        backgroundColor: COLORS.LGREY,
        color: COLORS.BLACK,
        fontWeight: 600,
        padding: `0 0 0 ${SIZING.scale400}`,
        position: "relative",
        width: "100%",
        fontSize: "16px",
        lineHeight: "21.48",
        "& svg:not(.placeholder)": {
            "& g, & path": {
                fill: COLORS.E_CONSULT_PATIENT_INPUT_TEXT,
            },
        },
        "& svg.placeholder": {
            "& g, & path": {
                fill: COLORS.E_CONSULT_PATIENT_INPUT_TEXT_HALF_OPACITY,
            },
        },
        "& input": {
            minHeight: SIZING.scale1000,
            textIndent: "unset",
            letterSpacing: "-0.24%",
            color: COLORS.E_CONSULT_PATIENT_INPUT_TEXT,
            "&::placeholder": {
                color: COLORS.E_CONSULT_PATIENT_INPUT_TEXT_HALF_OPACITY,
            },
        },
    },
    patientPopover: {
        left: 0,
        right: 0,
        position: "absolute",
        "& .emptyContent": {
            color: COLORS.WHITE,
            fontSize: SIZING.scale400,
            textAlign: "center",
        },
        "& .patientSearchResultItem": {
            display: "flex",
            justifyContent: "space-between",
        },
        "& .patientSearchResultItemName": {
            display: "flex",
            alignItems: "center",
        },
        "& .patientSearchResultItemDetails": {
            textAlign: "right",
        },
    },
    patientDetails: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "fit-content",
    },
    patientInfo: {
        marginBottom: SIZING.scale400,
        width: SIZING.PATIENT_DETAILS_INFO_WIDTH,
        maxHeight: "100%",
        backgroundColor: COLORS.WHITE,
        boxShadow: "0 1px 8px rgba(0, 0, 0, 0.05)",
        borderRadius: SIZING.scale200,
        border: "1px solid rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        "& .topPatientInfoArea": {
            backgroundColor: "#F6F6F7",
            paddingTop: SIZING.scale150,
            paddingBottom: SIZING.scale400,
        },
        "& .additionalPatientInfoArea": {
            paddingBottom: SIZING.scale400,
            "& > :last-child": {
                paddingTop: SIZING.scale200,
            },
        },
        "& > :last-child": {
            paddingBottom: SIZING.scale550,
        },
    },
    row: {
        display: "flex",
        minHeight: SIZING.scale550,
        marginRight: SIZING.scale375,
        marginLeft: SIZING.scale375,
        paddingTop: SIZING.scale400,
        fontSize: SIZING.scale400,
    },
    topPatientInfo: {
        "& .text": {
            minWidth: SIZING.scale3600,
            marginRight: SIZING.scale625,
            textAlign: "right",
            fontWeight: 600,
            textTransform: "uppercase",
            color: COLORS.SUBTITLE_INFO_TEXT,
        },
        "& .value": {
            flexGrow: 2,
            wordBreak: "break-word",
            fontWeight: 700,
            color: COLORS.PATIENT_DETAIL_VALUE_TEXT,
        },
    },
    subInfo: {
        "& > :last-child": {
            paddingBottom: "0",
        },
    },
    patientSubInfo: {
        paddingBottom: SIZING.scale200,
        "& .text": {
            minWidth: SIZING.scale2725,
            color: COLORS.PATIENT_DETAIL_LABEL_TEXT,
            textTransform: "uppercase",
        },
        "& .value": {
            flexGrow: 2,
            wordBreak: "break-word",
        },
    },
    patientSimpleInfo: {
        "& .text": {
            minWidth: SIZING.scale3600,
            marginRight: SIZING.scale625,
            textAlign: "right",
            fontWeight: 600,
            textTransform: "uppercase",
            color: COLORS.PATIENT_DETAIL_LABEL_TEXT,
        },
        "& .value": {
            flexGrow: 2,
            wordBreak: "break-word",
        },
    },
    divider: {
        height: "1px",
        background: COLORS.DIVIDER_GREY,
        mixBlendMode: "normal",
    },
    dividerSecond: {
        height: "1px",
        background: COLORS.DIVIDER_GREY,
        mixBlendMode: "normal",
        marginLeft: SIZING.scale400,
        marginRight: SIZING.scale350,
    },
    removePatient: {
        display: "flex",
        height: SIZING.scale1200,
        width: SIZING.PATIENT_DETAILS_REMOVE_REMOVE_WIDTH,
        alignItems: "center",
        justifyContent: "center",
        background: COLORS.REMOVE_BTN,
        boxShadow: "0 0 2px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(33, 33, 49, 0.24)",
        borderRadius: SIZING.scale1600,
        fontSize: SIZING.scale500,
        fontWeight: 600,
        color: COLORS.WHITE,
        cursor: "pointer",
        flexShrink: 0,
    },
    patientDetailsLoaderWrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center"
    }
}));