
import { IntlShape } from "react-intl"
import { EMAIL_PATTERN } from "constant";
import moment from "moment"

export const validateRequired = (fieldValue: string | null, name: string, intl: IntlShape) => {
    return fieldValue ? "" : intl.formatMessage({ id: "commonValidator.requiredFields" }, { name: getName(name) })
}

export const validateEmpty = (fieldValue: any | null, name: string, intl: IntlShape) => {
    return fieldValue?.length ? "" : intl.formatMessage({ id: "commonValidator.requiredFields" }, { name: getName(name) })
}
export const phoneNumber = (fieldValue: string | null, name: string, intl: IntlShape) => {
    const phoneEmpty = !fieldValue || fieldValue.trim() === "" || fieldValue.trim() === "(___)___-____";
    const phoneInvalid = fieldValue && fieldValue.replace(/[()_-]/g, "").length < 10;
    return phoneInvalid || phoneEmpty ? intl.formatMessage({ id: "commonValidator.phoneNumber" }) : ""
}

export const email = (fieldValue: string | null, name: string, intl: IntlShape) => {
    const validEmail = fieldValue && (EMAIL_PATTERN).test(fieldValue?.trim())
    return !validEmail ? intl.formatMessage({ id: "commonValidator.email" }) : ""
}

export const futureDateAndTime = (fieldValue: any | null, name: string, intl: IntlShape) => {
    const validDate = moment(fieldValue).isAfter(moment())
    return !validDate ? intl.formatMessage({ id: "commonValidator.futureDate" }) : ""
}
export const stringRequired = (fieldValue: any | null, name: string, intl: IntlShape) => {
    return fieldValue && fieldValue.trim() ? "" : intl.formatMessage({ id: "commonValidator.requiredFields" }, { name: getName(name) })
}

export const maxLength = (fieldValue: any | null, name: string, intl: IntlShape) => {
    const splitedName = name.split(":");
    const maxLengthValue = parseInt(splitedName[1], 10);
    return fieldValue.length > maxLengthValue ? intl.formatMessage({ id: "commonValidator.maxLength" }, { name: getName(name), max: maxLengthValue }) : ""
}
export const minLength = (fieldValue: any | null, name: string, intl: IntlShape) => {
    const splitedName = name.split(":");
    const minLengthValue = parseInt(splitedName[1], 10);
    return fieldValue.length < minLengthValue ? intl.formatMessage({ id: "commonValidator.minLength" }, { name: getName(name), min: minLengthValue }) : ""
}

const getName = (name: string) => {
    return name.split(':')[0]
}

const commonValidator = {
    required: validateRequired,
    phoneNumber,
    email,
    validateEmpty,
    futureDateAndTime,
    stringRequired,
    maxLength,
    minLength
}

export default commonValidator