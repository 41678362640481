import { Box, Button, Grid, IconButton, InputBase, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from "@mui/material";
import { ReactComponent as SearchIcon } from "admin/assets/common/Search.svg";
import TablePaginationActions from 'admin/components/TablePaginationActions/TablePaginationActions';
import { call } from "admin/store/api";
import { getAppCurrentProfileId } from "authentication/appAuth";
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { ReactComponent as PerformanceReports } from "icon-library/SVG/Heart-Light_Bolt.svg";
import { ReactComponent as ContactUs } from "icon-library/SVG/Phone-Light_Answer.svg";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import moment from 'moment';
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import { AdditionalResources } from "views/PEPHome/components/additional-resources";
import { handleDownload } from "../components/file-download/file-download";
import { useStyles } from "./styles";

type ReportType = {
    dateOfBirth: string,
    fileName: string,
    firstName: string,
    id: string,
    lastName: string,
    memberId: string,
    npi: string,
    organizationId: string,
    practiceId: string,
    profileRoleJoinId: string,
    reportType: string,
    status: string,
    tin: string
}

type PaginatedReportSort = {
    empty: boolean,
    sorted: boolean,
    unsorted: boolean,
}

type PaginatedReportPagable = {
    offset: number,
    pageNumber: number,
    pageSize: number,
    paged: boolean,
    sort: PaginatedReportSort,
    unpaged: boolean
}

type PaginatedReportProps = {
    content: ReportType[],
    empty: boolean,
    first: boolean,
    last: boolean,
    number: number,
    numberOfElements: number,
    pageable: PaginatedReportPagable,
    size: number,
    sort: PaginatedReportSort,
    totalElements: number,
    totalPages: number,
}

export const PEPIOAForms = ({ onSelect: handleSelection }: { onSelect?: (menuItem: string) => void }) => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const { currentProfileRoleJoin } = user;
    const practiceId = currentProfileRoleJoin?.practice?.id || "";
    const orgId = currentProfileRoleJoin?.practice?.organizationId;
    const hasAdminAccess = ((currentProfileRoleJoin?.level?.name === 'PRACTICE_ADMIN') || (currentProfileRoleJoin?.level?.name === 'STAFF_2'));
    const { enqueueError } = useUserNotification();
    const [reports,] = useState<ReportType[]>([]);
    const [providers, setProviders] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openPanel, setOpenPanel] = useState<boolean>(false);
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const licenseOptionName = "IOA_REPORT"

    const emailBodyString = (): string => {
        const providerProfile = currentProfileRoleJoin?.providerProfile;
        const practice = currentProfileRoleJoin?.practice;
        const middleName = providerProfile?.middleName ? ` ${providerProfile.middleName} ` : ` `;
        const fullName = `${providerProfile?.firstName}${middleName}${providerProfile?.lastName}`;
        const practiceName = practice?.name;
        const npi = providerProfile?.npi;
        const phone = currentProfileRoleJoin?.profilePrimaryPhone;
        const newLine = '%0d%0a';
        const fullNameStr = (providerProfile?.firstName && providerProfile?.lastName) ? `Full Name: ${fullName} ` : "";
        const practiceNameStr = practiceName ? `${newLine}Practice Name: ${practiceName} ` : "";
        const npiStr = npi ? `${newLine}NPI: ${npi} ` : "";
        const phoneStr = phone ? `${newLine}Best Contact Phone Number: ${phone}` : "";
        const bodyStrEmpty = (fullNameStr === "") && (practiceNameStr === "") && (npiStr === "") && (phoneStr === "");
        return (bodyStrEmpty ? "" : `&body=${fullNameStr}${practiceNameStr}${npiStr}${phoneStr}`);
    };

    const getProvidersList = useCallback(async () => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const endpointURL = `/admin/v1/providers?practiceId=${practiceId}&pageSizee=20000`;

        if (practiceId) {
            await call("GET", endpointURL, {}).then((response) => {
                const content = response?.content;
                const providersList: string[] = content ? content.filter((provider: any) => { return ((provider.status !== "INACTIVE") && (provider.npi !== null && provider.npi !== "")) }).sort((a: any, b: any) => {
                    if (a.firstName < b.firstName) {
                        return -1;
                    } else if (a.firstName > b.firstName) {
                        return 1;
                    } else {
                        return 0;
                    }
                }) : [];
                setProviders(providersList);
            }).catch(() => {
                enqueueError("aha.report.get.listing.error");
            });
        }
    }, [currentProfileRoleJoin?.practice, enqueueError]);

    type ObjectType = {
        [key: string]: any
    }

    const handleDownloadClick = async (index?: number) => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const reportsObj = reportsListing.filter((report: any) => { return report.reportType === licenseOptionName });
        const fileName = reportsObj[(index ? index : 0)]?.fileName;
        const fileRequest = {
            "fileName": fileName,
            "fileType": "IOA_REPORT",
            "practiceId": practiceId
        };
        const endpointURL = `/admin/v1/provider/ioa/download`;

        await handleDownload(endpointURL, fileRequest).catch(() => {
            enqueueError("report.download.error");
        });
    };

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("aha.report.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    useEffect(() => {
        const documentRoot = document.getElementById("root");
        if (documentRoot) {
            documentRoot.style.height = "85%";
            documentRoot.style.minWidth = "768px";
        }
    }, [])

    const additionalResourcesConfig = [{
        name: "performance_reports",
        action: {
            id: "performance_reports",
            name: "Clinical Performance & Quality Reports"
        },
        icon: PerformanceReports,
        enabled: true
    },
    {
        name: "contact_us",
        action: {
            id: "contact_us",
            name: intl.formatMessage({ id: "pep.fee.schedules.right.link.contact.us" }),
            href: "https://www.brownandtoland.com/contact-us/",
            target: "_blank"
        },
        icon: ContactUs,
        enabled: true
    }];

    const [selectedPCP, setSelectedPCP] = useState<string>("");

    const PCPSelect = () => {
        return (<div>
            <Typography id="ioa_forms_pcp_select_text" style={{
                fontFamily: 'OpenSans', fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', marginBottom: '10px'
            }}>
                {`Select PCP to view the panel`}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                <Select
                    defaultValue={""}
                    key={`ioa_forms_pcp_select`}
                    variant="outlined"
                    displayEmpty
                    onChange={(event: SelectChangeEvent) => {
                        setSelectedPCP(event.target.value);
                        reset();
                        setSearchStr(undefined);
                        // setReports([]);
                        setOpenPanel(false);
                        setPage(0);
                        setReportsListingTotalElements(0);
                    }}
                    value={selectedPCP}
                    disabled={false}
                    error={false}
                    fullWidth
                    style={{
                        width: '328px', height: '38px', border: '1px solid #909098', boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)', borderRadius: '4px', background: '#FFFFFF', boxSizing: 'border-box'
                    }}
                >
                    <MenuItem key={`ioa_forms_pcp_select_empty`} value="">
                        {`Select PCP`}
                    </MenuItem>
                    {providers?.map((provider: any, index: number) => (
                        <MenuItem key={`ioa_forms_pcp_select_index_${index}`} value={provider.profileRoleJoinId}>
                            {`${(provider.prefix ? `${provider.prefix} ` : ``) + provider.firstName + (provider.middleName ? ` ${provider.middleName} ` : ` `) + provider.lastName}`}
                        </MenuItem>
                    ))}
                </Select>
                <Button
                    className={classes.pep_ioa_forms_download_button}
                    variant="contained"
                    color="primary"
                    disabled={!(selectedPCP && selectedPCP !== "")}
                    onClick={() => {
                        if (selectedPCP && selectedPCP !== "") {
                            getProviderReports();
                        }
                    }}>
                    {`Get Report`}
                </Button>
            </div>
        </div>)
    }

    const [searchStr, setSearchStr] = useState<string>();

    const handleSearch = (event: any) => {
        const searchValue = event.target.value.trim();
        if (searchValue !== "") {
            setSearchStr(searchValue);
            setPage(0);
        } else {
            setSearchStr(undefined);
        }
    };

    const selectedProvider = providers.filter((provider: any) => { return (provider.profileRoleJoinId === selectedPCP) })[0];
    const selectedProviderName: string = selectedProvider ? `${(selectedProvider.prefix ? `${selectedProvider.prefix} ` : ``) + selectedProvider.firstName + (selectedProvider.middleName ? ` ${selectedProvider.middleName} ` : ` `) + selectedProvider.lastName}` : `Dr B Williams`;
    const listDisplayName = hasAdminAccess ? `${selectedProviderName}’s` : `Your`;

    type Order = 'asc' | 'desc';

    const [orderBy, setOrderBy] = useState<keyof ReportType>('lastName');
    const [order, setOrder] = useState<Order>('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const useProviderReportsListing = (practiceId: string, npi: string, orderBy: keyof ReportType, order: Order, rowsPerPage: number, page: number, searchStr?: string) => {
        const params = `&sortedBy=${orderBy}&sortAsc=${(order === 'asc') ? true : false}&pageSize=${rowsPerPage}&pageNumber=${page}${searchStr ? `&lastName=${searchStr}` : ""}`;
        const endpointURL = `/admin/v1/provider/reports?practiceId=${practiceId}`;
        const url = `${endpointURL}${params}&npi=${npi}&reportType=IOA_REPORT`
        const { content, fetchInfo, request, reset } = useApi<{}, PaginatedReportProps | null>({
            flags: {
                abortOnNewRequest: true,
            },
            defaultContent: null,
            url: url,
            method: HttpMethod.GET
        });

        return {
            paginatedReportsListing: content,
            hasResult: fetchInfo.hasResult,
            error: fetchInfo.error,
            request,
            reset
        };
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rowsperPage = parseInt(event.target.value)
        setRowsPerPage(rowsperPage);
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ReportType) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const createSortHandler = (property: keyof ReportType) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const providerProfile = currentProfileRoleJoin?.providerProfile;
    const currentProviderNpi = providerProfile?.npi;
    const npi = hasAdminAccess ? selectedProvider?.npi : currentProviderNpi;

    const { paginatedReportsListing, hasResult, request, reset } = useProviderReportsListing(practiceId, npi, orderBy, order, rowsPerPage, page, searchStr);
    const reportsListing = paginatedReportsListing?.content || [];

    const Pagination = () => {
        return (<>
            {reportsListing && reportsListing.length > 0 && <TablePagination className={classes.tableFooterRow}
                rowsPerPageOptions={[10, 25, 50]}
                classes={{ selectIcon: classes.footerIcon, select: classes.footerCaption, menuItem: classes.footerCaption, spacer: classes.paginationSpacer }}
                count={paginatedReportsListing?.totalElements || 0}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Per Page"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            }</>)
    }

    useEffect(() => {
        if (practiceId && npi && openPanel) {
            request({});
            // setOpenPanel(true);
        }
    }, [request, openPanel, practiceId, npi, orderBy, order, rowsPerPage, page, searchStr]);

    const [reportsListingTotalElements, setReportsListingTotalElements] = useState(0);

    const getProviderReports = useCallback(async () => {
        const practiceId = currentProfileRoleJoin?.practice?.id;
        const providerProfile = currentProfileRoleJoin?.providerProfile;
        const currentProviderNpi = providerProfile?.npi;
        const npi = hasAdminAccess ? selectedProvider?.npi : currentProviderNpi;
        const endpointURL = `/admin/v1/provider/reports/patient/count?practiceId=${practiceId}&npi=${npi}`;
        if (practiceId && npi) {
            request({});
            setOpenPanel(true);
            const response = await call("GET", endpointURL, {})
                .catch(() => {
                    enqueueError("aha.report.get.listing.error");
                });
            if (response) {
                setReportsListingTotalElements(response);
            }
        }
    }, [currentProfileRoleJoin?.id, selectedPCP, enqueueError]);

    const [filesAvaibility, setFilesAvaibility] = useState<ObjectType>({});

    const checkFilesAvailability = useCallback(async (reportsListing: any) => {
        const endpointURL = `/admin/v1/provider/documents/status`;
        const fileNames: string[] = reportsListing.map((report: any) => { return report.fileName });
        const fileList: string[] = fileNames.filter((fileName: string, index: number) => {
            return (fileNames.indexOf(fileName) === index);
        });
        const s3FileStatusRequest = {
            "fileNames": fileList,
            "fileType": "IOA_REPORT",
            "practiceId": practiceId,
            "orgId": orgId
        }
        const response = await call("POST", endpointURL, s3FileStatusRequest);
        if (response) {
            setFilesAvaibility(response);
        }
    }, [])

    useEffect(() => {
        if (!!currentProfileRoleJoin?.id) {
            if (hasAdminAccess) {
                getProvidersList();
            } else {
                getProviderReports();
            }
        }
    }, [currentProfileRoleJoin?.id, getProvidersList, hasAdminAccess])

    useEffect(() => {
        if (practiceId && (practiceId !== "") && reportsListing && reportsListing.length > 0) {
            checkFilesAvailability(reportsListing);
        }
    }, [reportsListing])

    const filteredReports: ReportType[] = searchStr ? reports.filter((report: any) => {
        const searchFields: string = `${report.memberId} ${report.firstName} ${report.lastName}`.toLowerCase();
        return (searchFields.search(searchStr.toLowerCase()) > -1);
    }) : reports;

    return (isLoading ? <Spinner /> : (
        <div id="ioa_forms_row" className={classes.pep_ioa_forms_row}>
            <div id="ioa_forms_container" className={classes.pep_ioa_forms_container}>
                <div id="ioa_forms_left" className={classes.pep_ioa_forms_left}>
                    <div id="ioa_forms_top" className={classes.pep_ioa_forms_top}>
                        <div id="ioa_forms_banner" className={classes.pep_ioa_forms_banner}>
                            <Typography id="ioa_forms_banner_top" className={classes.pep_ioa_forms_banner_top}>
                                {`Annual Health Assessment Forms`}
                            </Typography>
                            <Typography id="ioa_forms_banner_top" className={classes.pep_ioa_forms_banner_bottom}>
                                {`A comprehensive assessment tool for Annual Health Assessment visits`}
                            </Typography>
                        </div>
                    </div>
                    {(isLoading || (user.username === '')) ? <Spinner /> : <Box
                        flexGrow={1}
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                        {hasAdminAccess && PCPSelect()}
                        {openPanel && <>
                            <Typography id="ioa_forms_pcp_select_text" style={{
                                fontFamily: 'BentonSansCond', fontStyle: 'normal', fontWeight: '500', fontSize: '20px', lineHeight: '140%', color: '#6E1E80', marginBottom: '10px'
                            }}>
                                {`${listDisplayName} Medicare Advantage Panel - ${reportsListingTotalElements} patient${(reportsListingTotalElements) ? `` : `s`}`}
                            </Typography>
                            {((reportsListing?.length > 0) || (searchStr && searchStr !== "")) && <div>
                                <Paper component="form" className={classes.pep_ioa_forms_root}>
                                    <IconButton id="btnSearchUsers" className={classes.pep_ioa_forms_iconButton} aria-label="search" size="large">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase type="search" id="txtSearchUsers"
                                        className={classes.pep_ioa_forms_input}
                                        onChange={handleSearch}
                                        onKeyPress={(ev: any) => {
                                            if (ev.key === 'Enter') {
                                                ev.preventDefault();
                                            }
                                        }}
                                        placeholder="Find patient by last name"
                                        inputProps={{ 'aria-label': 'Find patient by last name' }}
                                    />
                                </Paper>
                                <Paper className={classes.paper}>
                                    <TableContainer className={classes.stickyHeader}>
                                        <Table id="tableFeeSchedulesListing"
                                            stickyHeader
                                            className={classes.table}
                                            aria-labelledby="tableTitle"
                                            size={'medium'}
                                            aria-label="enhanced table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        width={'30%'}
                                                        key={"lastName"}
                                                        align={'left'}
                                                        padding="normal"
                                                        sortDirection={orderBy === "lastName" ? order : false}>
                                                        <strong style={{ marginLeft: "41px" }}>{`Patient's Name`}</strong>
                                                        {false && <TableSortLabel
                                                            active={orderBy === "lastName"}
                                                            direction={orderBy === "lastName" ? order : 'asc'}
                                                            onClick={createSortHandler("lastName")}>
                                                            <strong style={{ marginLeft: "41px" }}>
                                                                {`Patient's Name`}
                                                            </strong>
                                                            {orderBy === "lastName" ?
                                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>}
                                                    </TableCell>
                                                    <TableCell
                                                        width={'25%'}
                                                        key={"dateOfBirth"}
                                                        align={'left'}
                                                        padding="normal"
                                                        sortDirection={orderBy === "dateOfBirth" ? order : false}
                                                    >
                                                        <strong>{`Date of Birth`}</strong>
                                                        {false && <TableSortLabel
                                                            active={orderBy === "dateOfBirth"}
                                                            direction={orderBy === "dateOfBirth" ? order : 'asc'}
                                                            onClick={createSortHandler("dateOfBirth")}
                                                        >
                                                            <strong>{`Date of Birth`}</strong>
                                                            {orderBy === "dateOfBirth" ?
                                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>}
                                                    </TableCell>
                                                    <TableCell
                                                        width={'25%'}
                                                        key={"memberId"}
                                                        align={'left'}
                                                        padding="normal"
                                                        sortDirection={orderBy === "memberId" ? order : false}>
                                                        <strong>{`Member ID`}</strong>
                                                        {false && <TableSortLabel
                                                            active={orderBy === "memberId"}
                                                            direction={orderBy === "memberId" ? order : 'desc'}
                                                            onClick={createSortHandler("memberId")}
                                                        >
                                                            <strong>{`Member ID`}</strong>
                                                            {orderBy === "memberId" ?
                                                                (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted ascending' : 'sorted descending'}</span>) : null}
                                                        </TableSortLabel>}
                                                    </TableCell>
                                                    <TableCell
                                                        width={'20%'}
                                                        key={"effectiveDate"}
                                                        align={'left'}
                                                        padding="normal"
                                                    >
                                                        <strong>{'Action'}</strong>
                                                    </TableCell>
                                                    <TableCell width={'5%'} />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    reportsListing.map((row: ReportType, index: number) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return (<>
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                            >
                                                                <TableCell id={labelId} scope="row" padding="none" width={'30%'}>
                                                                    <Grid style={{ display: "flex", alignItems: "center", flexBasis: 'none', marginLeft: "41px" }}>
                                                                        <Tooltip arrow title={(row.lastName && row.firstName) ? `${row.lastName.toUpperCase()}, ${row.firstName}` : ``}>
                                                                            <Typography noWrap variant="body1" style={{ color: '#0F7BBD', width: '250px', fontSize: '14px', fontWeight: 600 }}>
                                                                                {`${row.lastName.toUpperCase()}, ${row.firstName}`}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell align="left" width={'25%'}>{row.dateOfBirth ? `${moment(row.dateOfBirth).format('MM/DD/YYYY')}` : ""}</TableCell>
                                                                <TableCell align="left" width={'25%'}>{row.memberId}</TableCell>
                                                                <TableCell align="left" width={'20%'}>
                                                                    <Button
                                                                        className={classes.tableDownloadButton}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={!filesAvaibility[row["fileName"]]}
                                                                        onClick={() => {
                                                                            handleDownloadClick(index);
                                                                        }}>
                                                                        <FormattedMessage id={"aha.report.download.button.label"} />
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                            <TableFooter>
                                                {hasResult && reportsListing && reportsListing.length === 0 ? (
                                                    <TableRow>
                                                        <TableCell colSpan={6} align="center">
                                                            No Records
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (<TableRow>
                                                    <Pagination />
                                                </TableRow>)}
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>}
                            {false && ((filteredReports?.length > 0) || (searchStr && searchStr !== "")) && <div>
                                <Paper component="form" className={classes.pep_ioa_forms_root}>
                                    <IconButton id="btnSearchUsers" className={classes.pep_ioa_forms_iconButton} aria-label="search" size="large">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase type="search" id="txtSearchUsers"
                                        className={classes.pep_ioa_forms_input}
                                        onChange={handleSearch}
                                        onKeyPress={(ev: any) => {
                                            if (ev.key === 'Enter') {
                                                ev.preventDefault();
                                            }
                                        }}
                                        placeholder="Find patient by Member ID"
                                        inputProps={{ 'aria-label': 'Find patient by Member ID' }}
                                    />
                                </Paper>
                                <br />
                                <div className={classes.tableContainer}>
                                    <table className={classes.tableMain}>
                                        <colgroup>
                                            <col style={{ width: '400px' }}></col>
                                            <col style={{ width: '150px' }}></col>
                                        </colgroup>
                                        <thead>
                                            <tr className={classes.tableHeaderRow}>
                                                <th className={classes.tableHeaderRowLeft} align="left">Patient's Name</th>
                                                <th className={classes.tableHeaderRowLeft} align="left">Patient's Member ID</th>
                                                <th className={classes.tableHeaderRowRight} align="left">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(filteredReports.length > 0) ? filteredReports.map((report: ReportType, index: number) => {
                                                return (
                                                    <tr key={`pep_performance_report_table_row_${index}`} className={classes.tableBodyRow}>
                                                        <td style={{ paddingLeft: '10px', fontWeight: '700', fontSize: '14px' }}>
                                                            {`${report.firstName} ${report.lastName}`}</td>
                                                        <td style={{ paddingLeft: '10px', fontWeight: '700', fontSize: '14px' }}>
                                                            {`${report.memberId}`}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                className={classes.tableDownloadButton}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                    handleDownloadClick(index);
                                                                }}>
                                                                <FormattedMessage id={"aha.report.download.button.label"} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <tr className={classes.tableBodyRow}><td colSpan={2} style={{ paddingLeft: '10px' }} ><FormattedMessage id={"aha.report.download.no.reports"} /></td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </>}
                        <br />
                    </Box>
                    }
                    <Typography className={classes.pep_ioa_forms_footer_text}>
                        {`If you need assistance, email the Physician Relations team at `}
                        <a href={`mailto:physicianrelations@btmg.com?subject=IOA%20Forms%20Support%20Request${emailBodyString()}`} target="_top" style={{ color: '#0084D5' }}>physicianrelations@btmg.com.</a>
                    </Typography>
                </div>
                <div id="ioa_forms_right" className={classes.pep_ioa_forms_right}>
                    <AdditionalResources config={additionalResourcesConfig} onSelect={handleSelection} />
                </div>
            </div >
        </div >
    ));
}
