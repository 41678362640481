import { useApi, FetchStates } from "common/utils/use-api";
import { Filter } from "lib/ui-components/filtered-search-input";
import { PageRequest, Sort } from "lib/domain/service-types";
import { mapSearchResponseToContent, mapProviderDtoToProvider, mapFiltersToSearchFilters } from "./mappers";
import {
    SearchParameters,
    ProviderSearchResponse,
    ProviderSearchContent,
    ProviderDto,
    Provider,
    ProviderSearchOptions,
} from "./types";

export type useProviderSearchArgs = {
    filters: Filter[] | null;
    page: PageRequest;
    sorting: Sort[];
    options: ProviderSearchOptions | null;
    allowEmptySearch?: boolean;
};

export const useProviderSearch = ({ allowEmptySearch, filters, page, options, sorting }: useProviderSearchArgs) => {
    const params = { filters: mapFiltersToSearchFilters(filters || []), page: page, sort: sorting, options };

    const { content: result, fetchInfo: fetchState } = useApi<
        SearchParameters,
        ProviderSearchResponse,
        ProviderSearchContent
    >(
        {
            autoRequest: true,
            abortOnNewRequest: true,
            defaultContent: { data: [], page: { size: 0, number: 0, totalElements: 0, totalPages: 0 } },
            endpoint: "/econsult/v1/specialists/search",
            id: "providerSearchPost",
            method: "POST",
            requestDecisionMaker: (requestParams) => Boolean(requestParams.filters.length > 0 || allowEmptySearch),
            responseTransformer: mapSearchResponseToContent,
        },
        params
    );

    return {
        result,
        success: fetchState.state === FetchStates.SUCCESS,
        loading: fetchState.state === FetchStates.LOADING,
        hasResult: fetchState.hasResult,
        error: fetchState.error,
    };
};

export type useProviderDetailsArgs = {
    id?: string;
};

export const useProviderDetails = ({ id }: useProviderDetailsArgs) => {
    const { content: result, fetchInfo: fetchState } = useApi<null, ProviderDto, Provider | null>({
        abortOnNewRequest: true,
        autoRequest: true,
        defaultContent: null,
        endpoint: `/econsult/v1/specialists/${id}`,
        method: "GET",
        requestDecisionMaker: (_params, { previousContent }) => Boolean(!previousContent && id),
        responseTransformer: mapProviderDtoToProvider,
    });

    return {
        result,
        success: fetchState.state === FetchStates.SUCCESS,
        loading: fetchState.state === FetchStates.LOADING,
        hasResult: fetchState.hasResult,
        error: fetchState.error,
    };
};
