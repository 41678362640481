import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import { useStyles } from "./styles";
import { CaseNotesProps, DurationOption } from "./types";
import InputWithTranscription from "common/components/InputWithTranscription/InputWithTranscription";
import Dropdown from "common/components/dropdown/dropdown";
import { renderDurationOption } from "./duration-option-label";
import CustomTypography from "common/components/CustomTypography/CustomTypography";

const durationOptions: DurationOption[] = [
    {
        labelId: "EConsult.caseNotes.duration.options.null",
        selectedLabelId: "EConsult.caseNotes.duration.label.null",
    },
    {
        numberOfHours: 2 * 24,
        labelId: "EConsult.caseNotes.duration.options.2d",
        selectedLabelId: "EConsult.caseNotes.duration.label.2d",
    },
    {
        numberOfHours: 5 * 24,
        labelId: "EConsult.caseNotes.duration.options.5d",
        selectedLabelId: "EConsult.caseNotes.duration.label.5d",
    },
    {
        numberOfHours: 7 * 24,
        labelId: "EConsult.caseNotes.duration.options.1w",
        selectedLabelId: "EConsult.caseNotes.duration.label.1w",
    },
    {
        numberOfHours: 14 * 24,
        labelId: "EConsult.caseNotes.duration.options.2w",
        selectedLabelId: "EConsult.caseNotes.duration.label.2w",
    },
    {
        numberOfHours: 21 * 24,
        labelId: "EConsult.caseNotes.duration.options.3w",
        selectedLabelId: "EConsult.caseNotes.duration.label.3w",
    },
    {
        numberOfHours: 30 * 24,
        labelId: "EConsult.caseNotes.duration.options.1M",
        selectedLabelId: "EConsult.caseNotes.duration.label.1M",
    },
];

const CaseNotes = ({
    duration,
    notes,
    onDurationChange,
    onNotesChange,
    onSubjectChange,
    onUrgencyChange,
    subject,
    urgent,
    errors
}: CaseNotesProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.caseNotesTab}>
            <InputWithTranscription
                id={`QA_econsult_case_notes_subject_field`}
                classes={{ root: "subject", input: "caseNoteInput", inputRow: "caseNoteInputRow" }}
                placeholder={intl.formatMessage({ id: "EConsult.caseNotes.subject.placeholder" })}
                maxLength={100}
                onChange={onSubjectChange}
                value={subject}
                error={errors.subject}
            />
            <InputWithTranscription
                id={`QA_econsult_case_notes_notes_field`}
                classes={{
                    root: "notes",
                    inputRow: "caseNoteInputRow",
                    input: "caseNoteInput",
                    microphoneRow: "notesIconRow",
                }}
                placeholder={intl.formatMessage({ id: "EConsult.caseNotes.notes.placeholder" })}
                maxLength={4096}
                multiline
                onChange={onNotesChange}
                value={notes}
                error={errors.notes}
            />
            <div className="optionsRow">
                <div>
                    <CustomTypography className="optionRowLabel" variant="subtitle1">
                        <FormattedMessage id="EConsult.caseNotes.options.label" />
                    </CustomTypography>
                    <FormControlLabel
                        classes={{ root: "formControlLabelRoot", label: "formControlLabelLabel" }}
                        control={
                            <Checkbox
                                classes={{ root: "checkboxRoot" }}
                                color="primary"
                                disableRipple
                                disableFocusRipple
                                checked={urgent}
                                onChange={(_e, checked) => onUrgencyChange(checked)}
                                id="QA_econsult_case_notes_urgent"
                            />
                        }
                        label={intl.formatMessage({ id: "EConsult.caseNotes.urgent.label" })}
                    />
                </div>
                <div>
                    <CustomTypography className="optionRowLabel" variant="subtitle1">
                        <FormattedMessage id="EConsult.caseNotes.duration.label" />
                    </CustomTypography>
                    <Dropdown
                        idPrefix="QA_econsult_case_notes_duration"
                        className="durationDropdown"
                        onChange={onDurationChange}
                        options={durationOptions.map((option) => {
                            const id = option.numberOfHours?.toString() || "";
                            const date = id ? moment().add(option.numberOfHours, "hours").toDate() : null;
                            return {
                                id,
                                text: intl.formatMessage({ id: option.labelId }) as string,
                                selectedText: intl.formatMessage({ id: option.selectedLabelId }, { date }) as string,
                                values: { date },
                                selected: duration === id,
                            };
                        })}
                        titleId="EConsult.caseNotes.duration.title"
                        renderOptionLabel={renderDurationOption}
                    />
                </div>
            </div>
        </div>
    );
};

export default CaseNotes;
