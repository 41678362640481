import {
    Button, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography
} from "@mui/material";
import { ADMIN_WEB_CACHE } from "admin/constant";
import { saveCancellationTypes, updateWebAdminCachePractice } from "admin/store/actions";
import { showNotification } from "admin/store/actions/snackbar";
import { AppReduxStore } from "admin/store/reducerTypes";
import { ReactComponent as PlusSquare } from "icon-library/SVG/Plus_Square.svg";
import { ReactComponent as Delete } from "icon-library/SVG/Trash.svg";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { createSvgIcon } from "../../../../utils";
import Controls from "../../Controls/Controls";
import { useStyles } from "./styles";

interface Data {
    id: string
    name: string
    description: string
    ehrCancelReasonId: string
    uiId: string
}

const CancellationTypesListing = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const classes = useStyles();
    const { configuration, admin, auth } = useSelector((state: AppReduxStore) => state)
    const { adminWebCachePractice } = admin
    const cancellationTypesList = configuration && configuration.cancellationTypes ? configuration.cancellationTypes : []
    const DeleteIcon = createSvgIcon(Delete)
    const PlusSquareIcon = createSvgIcon(PlusSquare)

    const [cancellationTypeData, setCancellationTypeDataState] = useState(cancellationTypesList?.map((row: Data) => (
        {
            id: row.id,
            name: row.name,
            description: row.description,
            ehrCancelReasonId: row.ehrCancelReasonId,
            uiId: row.id
        }
    )));
    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            },
            saveAndContinueLater(index: number) {
                return saveAndContinue(index);
            }
        }),
    )

    useEffect(() => {
        if (admin.configurePracticeId) {
            const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData
            tempFormData?.eVisitCancellationData && setCancellationTypeDataState(tempFormData?.eVisitCancellationData);
        }
    }, [admin.configurePracticeId])


    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {};
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgName: auth.currentOrg?.name,
            tempFormData: {
                name: admin.configurePracticeName,
                configurePractice: true,
                evisitCancellation: false,
                eVisitCancellationData: [...cancellationTypeData],
                selectedStep: index
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }



    const validate = () => {
        if (!cancellationTypeData || cancellationTypeData?.length === 0) {
            dispatch(showNotification(intl.formatMessage({ id: "CancellationTypes.Error.CancellationTypeContentErrorHeading" }), "error", intl.formatMessage({ id: "CancellationTypes.Error.CancellationTypeContentPresentError" })))
            return false
        }
        const emptyCellExists = cancellationTypeData.some(
            (vt: any) => vt?.ehrVisitTypeId?.trim() === "" || vt?.name?.trim() === ""
        );
        if (emptyCellExists) {
            dispatch(showNotification(intl.formatMessage({ id: "Invalid" }), "error", intl.formatMessage({ id: "CancellationTypes.Error.FormValidationError" })))
            return false
        }
        const cancellationTypeIdArr = cancellationTypeData.map((e: any) => e?.ehrCancelReasonId)
        const hasDuplicateIds = cancellationTypeIdArr.some((x: any) => cancellationTypeIdArr.indexOf(x) !== cancellationTypeIdArr.lastIndexOf(x))
        if (hasDuplicateIds) {
            dispatch(showNotification(intl.formatMessage({ id: "CancellationTypes.Error.DuplicateCancellationTypeID" }), "error", intl.formatMessage({ id: "CancellationTypes.Error.DuplicateCancellationTypeIDDesc" })))
            return false
        }
        return true
    }
    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (validate()) {
            dispatch(saveCancellationTypes(cancellationTypeData?.map((row: Data) => (
                {
                    id: row.id,
                    name: row.name,
                    description: row.description,
                    ehrCancelReasonId: row.ehrCancelReasonId
                }
            ))))
            return true
        }

        return false
    };

    const onRowValueChange = (e: any, selectedRow: Data) => {
        const fieldValue = e.target.value;
        const fieldName = e.target.name;

        const tempDataArray = cancellationTypeData.map((row: Data) => {
            if (row.uiId === selectedRow.uiId) {
                return { ...row, [fieldName]: fieldValue };
            }
            return row;
        });

        setCancellationTypeDataState(tempDataArray);

    };

    const handleDeleteRowAction = (cancellationTypeFormId: any) => {
        const tempDataArray = [...cancellationTypeData]
        cancellationTypeData && cancellationTypeData.find((obj: any, index: number) => {
            return (obj.uiId === cancellationTypeFormId) ? tempDataArray.splice(index, 1) : false;
        })

        setCancellationTypeDataState(tempDataArray)
    };
    const handleAddBlankRow = () => {
        setCancellationTypeDataState([{
            id: "",
            name: "",
            description: "",
            ehrCancelReasonId: "",
            uiId: Math.random().toString()
        }, ...cancellationTypeData]);
    }

    return (
        <Grid container>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    key={"ehrCancelReasonId"}
                                    align={'left'}
                                    padding="normal"
                                    style={{ paddingLeft: '48px', width: '300px' }}>
                                    <strong>
                                        {'EHR Cancellation Type ID'}
                                    </strong>
                                </TableCell>
                                <TableCell style={{ paddingLeft: '48px', width: '400px' }}
                                    key={"name"}
                                    align={'left'}
                                    padding={'none'}>
                                    <strong>
                                        {'EHR Cancellation Name'}
                                    </strong>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} style={{ backgroundColor: "#0F7BBD" }}>
                                    <Link onClick={handleAddBlankRow} style={{ textDecoration: 'none' }}>
                                        <Typography className={classes.lblAddAnotherEntry}>
                                            <PlusSquareIcon fill="#FFFFFF" className={classes.noIcon} />
                                            <FormattedMessage id={"CancellationTypes.Action.AddAnotherEntry"} />
                                        </Typography>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cancellationTypeData
                                .map((row: Data) => {
                                    return (
                                        <TableRow key={row.uiId}>
                                            <Controls.EditableTableCell
                                                name="ehrCancelReasonId"
                                                row={row}
                                                placeholder="Enter Type ID here"
                                                value={row.ehrCancelReasonId}
                                                onChange={(e: any) => onRowValueChange(e, row)} />
                                            <Controls.EditableTableCell
                                                name="name"
                                                row={row}
                                                placeholder="Enter Name here"
                                                value={row.name}
                                                onChange={(e: any) => onRowValueChange(e, row)} />
                                            <TableCell align="right">
                                                <Button className={classes.iconBtn} onClick={() => { handleDeleteRowAction(row.uiId) }}><DeleteIcon fill="#E32B29" className={classes.noIcon} /></Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    );
})

export default CancellationTypesListing