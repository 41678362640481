import { MenuItem, MenuList } from "@mui/material";
import classNames from "classnames";
import Popover from "lib/ui-components/popover/popover";
import { FormattedMessage } from "react-intl";
import CheckableMenuItemView from "./checkable-menu-item-view";
import CustomTypography from "./menu-custom-typography";
import MenuSeparatorView from "./menu-separator-view";
import SelectableMenuItemView from "./selectable-menu-item-view";
import { useStyles } from "./styles";
import { AnyMenuItem, isCheckableMenuItem, isSelectableMenuItem, isSeparatorItem, LabelRenderProps, NavigationMenuProps } from "./types";

const defaultLabelRender = ({ className, labelText, labelKey, labelValues }: LabelRenderProps) => (
    <CustomTypography className={className} variant="optionMenuItem">
        {labelText ?? <FormattedMessage id={labelKey} values={labelValues} />}
    </CustomTypography>
);

const NavigationMenu = ({
    id,
    className,
    open,
    anchorEl,
    containerEl,
    fullName,
    practiceName,
    handleClose,
    menuConfig,
    placement = "bottom",
    renderOptionLabel = defaultLabelRender,
    variant = "light",
    offset,
}: NavigationMenuProps) => {
    const classes = useStyles();

    const renderMenuItem = (menuItem: AnyMenuItem, index: number) => {
        if (isSeparatorItem(menuItem)) {
            return <MenuSeparatorView />;
        }
        let menuItemModifierClasses;
        let renderedMenuItem;
        let onClick;
        if (isSelectableMenuItem(menuItem)) {
            const { onClick: onClickMenuItem, selected, danger } = menuItem;
            onClick = () => {
                handleClose();
                onClickMenuItem();
            };
            menuItemModifierClasses = {
                "optionMenu__menuItemWrapper--selected": selected,
                "optionMenu__menuItemWrapper--danger": danger,
            };
            renderedMenuItem = <SelectableMenuItemView labelRenderer={renderOptionLabel} menuItem={menuItem} />;
        }
        if (isCheckableMenuItem(menuItem)) {
            const { onClick: onClickMenuItem, checked } = menuItem;
            onClick = () => {
                onClickMenuItem();
            };
            menuItemModifierClasses = {
                "optionMenu__menuItemWrapper--checked": checked,
            }
            renderedMenuItem = <CheckableMenuItemView labelRenderer={renderOptionLabel} menuItem={menuItem} />;
        }
        return (
            <MenuItem
                className={classNames(classes.menuItemWrapper, menuItemModifierClasses)}
                disableRipple
                disableGutters
                key={index}
                onClick={onClick}
                disabled={menuItem.disabled}
            >
                {renderedMenuItem}
            </MenuItem>
        );
    };

    if (!open) {
        return null;
    }

    return (
        <Popover
            id={id ?? ""}
            anchorEl={anchorEl?.current}
            className={className}
            data-testid={id}
            offset={offset}
            onClose={handleClose}
            open={open}
            placement={placement}
            variant={variant}
        >
            <div className={classNames({ [`optionMenu--${variant}`]: variant })}>
                {fullName && (
                    <CustomTypography variant="optionMenuHeader" style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '140%', color: '#404040', marginBottom: '5px', padding: '20px 20px 0px' }}>
                        {`${fullName}`}
                    </CustomTypography>
                )}
                {practiceName && (
                    <CustomTypography variant="optionMenuHeader" style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#404040', marginBottom: '5px', padding: '0px 20px 0px' }}>
                        {`${practiceName}`}
                    </CustomTypography>
                )}
                <MenuList className={classes.menuList}>{menuConfig.map(renderMenuItem)}</MenuList>
            </div>
        </Popover>
    );
};

export default NavigationMenu;
