import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { FormattedMessage } from "react-intl";
import {
    Typography, Paper,
    DialogContent, Grid
} from "@mui/material";
import { useStyles } from "./styles";
import { ReactComponent as CirclesHexagonGrid } from "icon-library/SVG/Circles_Hexagon-Grid.svg";
import { createSvgIcon } from "utils";
import EditRegistrationProvider from "../../EditRegistration/EditRegistrationProvider";
const CirclesHexagonGridIcon = createSvgIcon(CirclesHexagonGrid);

const UserEmail = forwardRef((props, ref) => {
    const classes = useStyles();
    const editUserEmail = useRef<any>();

    useImperativeHandle(
        ref,
        () => ({
            saveAction(e: any) {
                return editUserEmail.current.submitForm(e);
            },
            saveAndContinueLaterAction(index: number) {
                return editUserEmail.current.saveAndContinueLater(index);
            }
        }),
    )

    return (
        <>

            <Grid item className={classes.productsPageHeader}>
                <Typography className={classes.pageHeaderText}>
                    <CirclesHexagonGridIcon fill="#0F7BBD" className={classes.productsIcon} />
                    <FormattedMessage id="Practice.Configuration.UserEmail.Heading" />
                </Typography>
                <Typography className={classes.pageSubHeaderText}>
                    <FormattedMessage id={"Practice.Configuration.UserEmail.SubHeading"} />
                </Typography>
            </Grid>

            <DialogContent className={classes.contentDialog}>

                <Paper className={classes.waitingRoomModal}>
                    <EditRegistrationProvider ref={editUserEmail}></EditRegistrationProvider>
                </Paper>
            </DialogContent>
        </>
    );
})

export default UserEmail