export type ReplaceParamsInUrlResult = { foundParamNames: string[]; url: string };

export class TimeoutController {
    private id: ReturnType<typeof setTimeout> | undefined;

    register(newId: ReturnType<typeof setTimeout> | undefined) {
        this.cancel();
        this.id = newId;
    }

    cancel() {
        clearTimeout(this.id as ReturnType<typeof setTimeout>);
    }
}

const ASYNC_TIMEOUT_MESSAGE = "Operation exceeded maximum timeout";

export const isAsyncTimeoutError = (error: Error | string | undefined | null) => {
    return (Error.prototype.isPrototypeOf(error as Object) && (error as Error).message === ASYNC_TIMEOUT_MESSAGE);
}

/** Support for Promise timeout.
 * @param promise Promise<T> The original promise to wrap into a timeout.
 * @param timeout number How long should we wait for the original promise. In seconds.
 * @returns Promise<T> It will reject if timeout happens.
 */
export function withAsyncTimeout<T>(promise: Promise<T>, timeout: number = 15): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const id = timeout > 0 ? setTimeout(() => {
            reject(new Error(ASYNC_TIMEOUT_MESSAGE));
        }, timeout * 1000) : undefined;

        const cancellationManager = new TimeoutController();

        cancellationManager.register(id);

        promise
            .then(resolve)
            .catch(reject)
            .finally(() => cancellationManager.cancel());
    });
}

export const replaceParamsInUrl = (url: string, parameters: Record<string, any> | undefined): ReplaceParamsInUrlResult => {
    const paramRegex = /(:([a-zA-Z0-9])*)/g;
    const foundParamNames: string[] = [];
    let preparedUrl = "";
    let result;
    let lastIndex = 0;
    while ((result = paramRegex.exec(url)) !== null) {
        const paramKey = result[0].slice(1);
        if (parameters?.[paramKey]) {
            foundParamNames.push(paramKey);
            preparedUrl += url.substring(lastIndex, paramRegex.lastIndex - (paramKey.length + 1));
            preparedUrl += parameters[paramKey].toString();
            lastIndex = paramRegex.lastIndex;
        }
    }
    preparedUrl += url.substring(lastIndex);
    return {
        url: preparedUrl,
        foundParamNames,
    };
};
