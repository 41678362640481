import { Checkbox, Fade, IconButton, List, ListItem, ListItemText, Menu, Box, Button, RadioGroup, Radio, Typography, Divider } from "@mui/material";
import { ReactComponent as FilterLight } from "icon-library/SVG/Filter-Light.svg";
import { ReactComponent as Filter } from "icon-library/SVG/Filter.svg";
import React, { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import { ProductsFilterMenuProps } from "./types";
import { AppReduxStore } from "admin/store/reducerTypes";
import { useSelector } from "react-redux";
import { map } from "underscore";
import { createSvgIcon } from "../../../utils";
const FilterIcon = createSvgIcon(Filter);
const FilterLightIcon = createSvgIcon(FilterLight);
const ProductsFilterMenu = ({ callback }: ProductsFilterMenuProps) => {
    const { allProducts } = useSelector((state: AppReduxStore) => state).license
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null);
    const intl = useIntl()
    const open = Boolean(anchorEl)
    const [checkedItems, setCheckedItems] = useState([] as any);
    const [checkedAll, setCheckedAll] = useState(false);
    const initialHasProducts = "haveProducts"
    const [haveProduct, setHaveProducts] = useState(initialHasProducts);
    const [filterApplied, setFilterApplied] = useState(false);

    const filterRadioItems = [
        { id: 'haveProducts', title: intl.formatMessage({ id: "ProductsFilterMenu.Have" }) },
        { id: 'doesNotHaveProducts', title: intl.formatMessage({ id: "ProductsFilterMenu.DoesNotHave" }) }
    ]
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAllCheked = (e: any) => {
        if (e.target.checked) {
            setCheckedItems(allProducts.map((product: any) => product.id))
        }
        else {
            setCheckedItems([])
        }
        setCheckedAll(e.target.checked)
    }
    const handleToggle = (value: any) => () => {
        const currentIndex = checkedItems.indexOf(value);
        const newChecked = [...checkedItems];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedItems(newChecked);
        const allChecked = allProducts.map((product: any) => product.id)?.every((p: any) => newChecked.includes(p));
        setCheckedAll(allChecked)
    }

    const onFilterRadioChange = (e: any) => {
        setHaveProducts(e.target.value)
    }
    const onClearAction = () => {
        setHaveProducts(initialHasProducts)
        setCheckedItems([])
        setCheckedAll(false)
        callback({ haveAnyProducts: true, productIds: [] });
        handleClose()
        setFilterApplied(false)
    }
    const onFilterAction = () => {
        callback({ haveAnyProducts: haveProduct === initialHasProducts, productIds: checkedItems });
        handleClose()
        setFilterApplied(checkedItems?.length > 0)
    }
    const renderFilterRadio = (option: any) => {
        return (
            <Box display="flex" alignItems="center" key={option.id}>
                <Radio color="primary" id={"radio_" + option.id} onChange={onFilterRadioChange} value={option.id} disableRipple disableFocusRipple checked={haveProduct === option.id} />
                <Typography>
                    {option.title}
                </Typography>
            </Box>
        )
    }
    return (<>
        <IconButton id="btnToggleProductsFilterMenu" onClick={handleClick} size="large">
            {filterApplied ? <FilterIcon fill="#139AED" /> : <FilterLightIcon />}
        </IconButton>
        <Menu classes={{ list: classes.listRoot }}
            PopoverClasses={{ paper: classes.menuContent }}
            elevation={0}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <RadioGroup className={classes.headerText}>
                {map(filterRadioItems, renderFilterRadio)}
            </RadioGroup>
            <Box className={classes.listWrapper}>
                <List dense>
                    <ListItem button >
                        <Checkbox color="primary" onClick={handleAllCheked}
                            edge="start"
                            checked={checkedAll}
                            tabIndex={-1}
                            disableRipple
                            disableTouchRipple
                        />
                        <ListItemText primary={intl.formatMessage({ id: "ProductsFilterMenu.Action.AllProducts" }).toUpperCase()} classes={{ primary: classes.listText }} />

                    </ListItem>
                    <Divider variant="middle" style={{ color: '#E4E4E6', margin: '10px' }} />
                    {allProducts && allProducts.map((product: any) => {
                        const labelId = `checkbox-list-label-${product.id}`;
                        return (
                            <ListItem key={product.id} role={undefined} dense button onClick={handleToggle(product.id)}>
                                <Checkbox color="primary"
                                    edge="start"
                                    checked={checkedItems.indexOf(product.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    disableTouchRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                                <ListItemText primary={product.name} classes={{ primary: classes.listText }} />
                            </ListItem>
                        );
                    })}
                </List>
                <Box>
                    <Button id="btnClearFilter" variant="outlined" style={{ marginLeft: '10px' }} onClick={onClearAction}>
                        {intl.formatMessage({ id: "ProductsFilterMenu.Action.Clear" })}
                    </Button>
                    <Button id="btnApplyFilter" variant="outlined" color="primary" style={{ margin: '0px 10px', float: 'right' }} onClick={onFilterAction}>
                        {intl.formatMessage({ id: "ProductsFilterMenu.Action.Apply" })}
                    </Button>

                </Box>
            </Box>
        </Menu>
    </>
    )
}

export default ProductsFilterMenu