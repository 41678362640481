import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>({
  listText: {
    color: '#393948',
    fontSize: '16px',
    fontWeight: 600
  },
  noIcon: {
    height: '25px',
    width: '25px',
    verticalAlign: 'middle'
  }
}));