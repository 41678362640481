import { makeStyles } from "@mui/styles";
import {COLORS, SIZING, BORDER_RADIUS} from "theme";

export const useStyles = makeStyles(() => ({
    SideNav: {
        display: "flex",
        zIndex: 2,
        minWidth: SIZING.SIDE_NAV_OPEN_WIDTH,
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        background: COLORS.MESSAGE_BG,
        boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.05), inset -1px 0px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: `0 ${BORDER_RADIUS.XL} ${BORDER_RADIUS.XL} 0`,
        "& .MuiButton-label":{
            "& img":{
                marginRight:SIZING.scale200
            }
        },
        "& button.accessControl":{
            backgroundColor: COLORS.DEFAULT_BTN,
            color:'white',
            "&:hover":{
                backgroundColor: COLORS.DEFAULT_BTN,
            }
        },
        "& button.accessControlPrimary":{
            backgroundColor: COLORS.PRIMARY_BTN,
            color:'#6E6E79',
            "&:hover":{
                backgroundColor: COLORS.PRIMARY_BTN,
            }
        }
    },
    accessControl: {
        color:'white',
        width: '100%',
        padding: SIZING.scale300
    },
    accessControlPrimary: {
        color:'#6E6E79',
        width: '100%',
        padding: SIZING.scale300
    },
    svgIcon: {
        width: '37px',
        height: '37px'
    }
}));