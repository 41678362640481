type GridRowProps = {
    contentText?: string;
    labelText?: string;
    separator?: boolean;
}

const GridRow: React.FC<GridRowProps> = ({ children, contentText, labelText, separator=false }) => {
    return !separator
        ? (<>
            {labelText  ? <span className="gridRow_label">{labelText}</span> : null }
            {contentText ? <span className="gridRow_content">{contentText}</span> : <div className="gridRow_content">{children}</div>}
        </>)
        : (<div className="separator"></div>);
}

export default GridRow;
