import React from "react";
import { FormattedMessage } from "react-intl";
import { COLORS } from "theme";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { useRequestInfo } from "./use-request-info";
import { EConsultRequest } from "./types";
import { useStyles } from "./styles";

const idPrefix = "QA_EConsultDetails_header";
const ReferrerOrAssigneeInfo = ({ eConsultRequest }: EConsultRequest) => {
    const classes = useStyles();
    const { isSentRequest, assignee } = useRequestInfo(eConsultRequest);

    const showAssignee = isSentRequest;

    const referrerOrAssignee = isSentRequest ? assignee : eConsultRequest.referrer;
    const locationName = referrerOrAssignee?.hospitals?.[0].value;

    const fullName =
        referrerOrAssignee &&
        ("providerName" in referrerOrAssignee ? referrerOrAssignee.providerName : referrerOrAssignee.fullName);

    return (
        <div className="eConsult__details__header__referrerOrAssigneeInfo">
            {referrerOrAssignee && (
                <>
                    <CustomTypography
                        id={`${idPrefix}${showAssignee ? "_assignee" : "_referrer"}`}
                        variant="body1"
                        color={COLORS.MODAL_TITLE_TEXT}
                        className={classes.subTitle}
                    >
                        {showAssignee && assignee && <FormattedMessage id="EConsult.request.header.claimedBy" />}
                        {fullName}
                        {referrerOrAssignee.credentials && referrerOrAssignee.credentials.length !== 0 ? (
                            <>
                                {referrerOrAssignee.credentials.map((credential) => (
                                    <>{`, ${credential}`}</>
                                ))}
                            </>
                        ) : null}
                    </CustomTypography>
                    <CustomTypography
                        id={`${idPrefix}_location`}
                        variant="eConsultHeaderSubTitle"
                        className={classes.subTitle}
                    >
                        {locationName}
                    </CustomTypography>
                </>
            )}
        </div>
    );
};

export default ReferrerOrAssigneeInfo;
