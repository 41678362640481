import { IconButton, Box, Typography, Popover, Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "icon-library/SVG/Chevron_Down.svg";
import { ReactComponent as ChevronUp } from "icon-library/SVG/Chevron_Up.svg";
import { createSvgIcon } from "utils";
import { useStyles } from "./styles";
import { TableSelectDropdownProps } from './types';
import { useIntl } from 'react-intl';
const ChevronDownIcon = createSvgIcon(ChevronDown);
const ChevronUpIcon = createSvgIcon(ChevronUp);

const TableSelectDropdown = ({ onSelectAllOnPage, onSelectAll }: TableSelectDropdownProps) => {
    const classes = useStyles()
    const menuRef = useRef(null)
    const [menuProfileOpen, setProfileMenuOpen] = useState(false)
    const [isSelectedAll, setSelectAll] = useState(false);
    const [isSelectedAllOnPage, setSelectAllOnPage] = useState(false)
    const intl = useIntl()
    const onMenuClick = (e: any) => {
        e.preventDefault();
        setProfileMenuOpen(!menuProfileOpen)
    }
    const handleMenuClose = () => {
        setProfileMenuOpen(!menuProfileOpen)
    }
    const handleSelectAll = () => {
        setSelectAll(!isSelectedAll)
        setSelectAllOnPage(false)
        onSelectAll(!isSelectedAll)
    }
    const handleSelectAllOnPage = () => {
        setSelectAllOnPage(!isSelectedAllOnPage)
        setSelectAll(false)
        onSelectAllOnPage(!isSelectedAllOnPage)
    }
    return (
        <>
        <IconButton id="btnSelectPopover" style={{ padding: '10px' }} onClick={onMenuClick} ref={menuRef} size="large">
            {menuProfileOpen ? <ChevronUpIcon className={classes.chevronUpDownIcon} /> : <ChevronDownIcon className={classes.chevronUpDownIcon} />}
        </IconButton>
        {menuProfileOpen && <Popover elevation={8} classes={{ paper: classes.popoverRoot }} open={menuProfileOpen} anchorEl={menuRef.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: -10,
                horizontal: 50
            }}
            onClose={handleMenuClose}>
            <Box style={{ overflow: 'auto' }}>
                <Grid container className={isSelectedAll ? classes.listItemWrapperActive : classes.listItemWrapper}
                    onClick={handleSelectAll}>
                    <Grid item xs style={{ padding: '4px 8px' }}>
                        <Typography noWrap variant="body1" style={{ fontSize: '16px', fontWeight: 600, color: "#393948" }}>
                            {intl.formatMessage({ id: "TableSelectDropdown.SelectAll" })}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={isSelectedAllOnPage ? classes.listItemWrapperActive : classes.listItemWrapper}
                    onClick={handleSelectAllOnPage}>
                    <Grid item xs style={{ padding: '4px 8px' }}>
                        <Typography noWrap variant="body1" style={{
                            fontSize: '14px', fontWeight: 600, color: "#393948"
                        }}>
                            {intl.formatMessage({ id: "TableSelectDropdown.SelectAllOnPage" })}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Popover>}
        </>
    )
}

export default TableSelectDropdown