import { Box, Grid } from "@mui/material";
import { fetchLocations, insertLocation } from "admin/store/actions";
import { useSnackbar } from "notistack";
import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Controls from "../../Controls/Controls";
import SnackbarContent from "../../SnackBars/SnackbarContent";
import { Form, useForm } from '../../useForm';
import { AppReduxStore } from "admin/store/reducerTypes";
import { useStyles } from "./styles";
import { PhoneMask } from 'admin/components/Controls/InputMasked';
import { US_STATES } from 'admin/constant';
import { validateEmail, validateNoSpecialCharacters, validatePostalCode, validateRequired } from 'admin/common/utils';
import { ADMIN_WEB_CACHE } from "admin/constant";
import { updateWebAdminCachePractice } from "admin/store/actions";

const initialFValues = {
    id: "",
    practiceId: "",
    name: "",
    ehrLocationId: "",
    email: "",
    phone: "",
    fax: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    status: ""
}

const LocationAddForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const intl = useIntl()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { admin, auth } = useSelector((state: AppReduxStore) => state)
    const { adminWebCachePractice } = admin

    useImperativeHandle(
        ref,
        () => ({
            submitForm(e: any) {
                return handleSubmit(e);
            },
            saveAndContinueLaterAction(index: number) {
                return saveAndContinue(index)
            }
        }),
    )

    const validate = (fieldValues = values) => {

        const err = { ...errors };
        if ('name' in fieldValues) {
            err.name = validateRequired(fieldValues.name, intl)
            if (err.name === "") err.name = validateNoSpecialCharacters(fieldValues.name, intl)
        }

        if ('ehrLocationId' in fieldValues) {
            err.ehrLocationId = validateRequired(fieldValues.ehrLocationId, intl)
            if (err.ehrLocationId === "") err.ehrLocationId = validateNoSpecialCharacters(fieldValues.ehrLocationId, intl)
        }
        if ('address1' in fieldValues) {
            err.address1 = validateRequired(fieldValues.address1, intl)
            if (err.address1 === "") err.address1 = validateNoSpecialCharacters(fieldValues.address1, intl)
        }
        if ('address2' in fieldValues) {
            err.address2 = validateNoSpecialCharacters(fieldValues.address2, intl)
        }
        if ('city' in fieldValues) {
            err.city = validateRequired(fieldValues.city, intl)
            if (err.city === "") err.city = validateNoSpecialCharacters(fieldValues.city, intl)
        }

        if ('state' in fieldValues) {
            err.state = validateRequired(fieldValues.state, intl)
            if (err.state === "") err.state = validateNoSpecialCharacters(fieldValues.state, intl)
        }
        if ('postalCode' in fieldValues) {
            err.postalCode = validateRequired(fieldValues.postalCode, intl)
            if (err.postalCode === "") err.postalCode = validatePostalCode(fieldValues.postalCode, intl)
        }

        if ('email' in fieldValues) {
            err.email = ""
            if (err.email === "") err.email = validateEmail(fieldValues.email, intl)
        }

        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }

    useEffect(() => {
        if (admin.configurePracticeId) {
            const tempFormData = adminWebCachePractice?.formData[admin.configurePracticeId]?.tempFormData?.configureLocationDetails
            if (tempFormData) {
                resetValues({
                    practiceId: tempFormData?.practiceId,
                    ehrLocationId: tempFormData?.ehrLocationId,
                    email: tempFormData?.email,
                    phone: tempFormData?.phone,
                    fax: tempFormData?.fax,
                    address1: tempFormData?.address1,
                    address2: tempFormData?.address2,
                    city: tempFormData?.city,
                    state: tempFormData?.state,
                    postalCode: tempFormData?.postalCode,
                    name: tempFormData?.name
                })
            }
        }
    }, [admin.configurePracticeId])

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        resetValues
    } = useForm(initialFValues, false, validate);

    const onLocationAddCallBack = (saved: boolean) => {
        if (saved) {
            resetForm()
            dispatch(fetchLocations())
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (validate()) {
            dispatch(insertLocation(values, onLocationAddCallBack))
            return true
        }
        enqueueSnackbar(null, {
            preventDuplicate: true, content: (key) => (<div role="alert"><SnackbarContent snackbarKey={key} type={'error'}
                message={intl.formatMessage({ id: "Invalid" })} desc="" /></div>
            ),
        })
        return false
    }

    const saveAndContinue = (index: number) => {
        const formData = adminWebCachePractice?.formData || {};
        formData[admin?.configurePracticeId] = {
            currentProfileRoleJoinId: auth?.currentProfileRoleJoinInfo?.id,
            parentOrgName: auth.currentOrg?.name,
            tempFormData: {
                name: admin.configurePracticeName,
                configurePractice: true,
                configureLocationDetails: {
                    practiceId: values.practiceId,
                    ehrLocationId: values.ehrLocationId,
                    email: values.email,
                    phone: values.phone,
                    fax: values.fax,
                    address1: values.address1,
                    address2: values.address2,
                    city: values.city,
                    state: values.state,
                    postalCode: values.postalCode,
                    name: values.name,
                },
                selectedStep: index,
            }
        }
        dispatch(updateWebAdminCachePractice(ADMIN_WEB_CACHE.ADD_PRACTICE_WIZARD_CACHE, formData))
    }

    return ( <>
        <Form onSubmit={handleSubmit}>
            <Box mb={1} className={classes.formGpHeader}>
                {intl.formatMessage({ id: "AddLocationForm.Heading.LocationInfo" })}
            </Box>
            <Grid container>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="name"
                            label="Location Name *"
                            placeholder="Enter name here"
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="ehrLocationId"
                            label="EHR Location ID *"
                            value={values.ehrLocationId}
                            onChange={handleInputChange}
                            error={errors.ehrLocationId}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '5px' }} spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="phone"
                            label="Phone Number"
                            value={values.phone}
                            onChange={handleInputChange}
                            error={errors.phone}
                            inputComponent={PhoneMask}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="fax"
                            label="Fax Number"
                            value={values.fax}
                            onChange={handleInputChange}
                            error={errors.fax}
                            inputComponent={PhoneMask}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="email"
                            label="Email"
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '5px' }} spacing={3}>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="address1"
                            label="Address 1 *"
                            placeholder="Street Name"
                            value={values.address1}
                            onChange={handleInputChange}
                            error={errors.address1}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            name="address2"
                            label="Address 2"
                            placeholder="Additional"
                            value={values.address2}
                            onChange={handleInputChange}
                            error={errors.address2}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controls.Input
                            name="city"
                            label="City *"
                            placeholder="City"
                            value={values.city}
                            onChange={handleInputChange}
                            error={errors.city}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controls.Select
                            name="state"
                            label="State *"
                            placeholder="Select"
                            value={values.state}
                            onChange={handleInputChange}
                            options={US_STATES}
                            error={errors.state}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controls.Input
                            name="postalCode"
                            label="Zip Code *"
                            value={values.postalCode}
                            onChange={handleInputChange}
                            error={errors.postalCode}
                            maxLength={10}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Form>

    </>
    )
})

export default LocationAddForm