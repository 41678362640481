import React from "react";
import { IntlShape } from "react-intl";
import { Typography } from "@mui/material";
import { PopoverItem } from "common/components/PopoverContent/types";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { PatientAutocompleteItem } from "./types";
import HighlightedText from "common/components/highlighted-text/highlighted-text";
import { getAgeWithFormat } from "utils";


const capitalize = (text?: string | null) => {
    return text ? text.charAt(0).toUpperCase() + text.toLowerCase().slice(1) : "";
};

export const createPatientPopOverItem = (
    { id, lastName, middleName, firstName, dateOfBirth, sex, mrn }: PatientAutocompleteItem,
    search: string,
    intl: IntlShape
): PopoverItem => {
    const name = intl.formatMessage(
        { id: "EConsult.patientName" },
        { lastName: lastName?.toUpperCase(), firstName: capitalize(firstName), middleName: capitalize(middleName) }
    );

    return {
        id,
        text: name,
        content: (
            <div className="patientSearchResultItem">
                <div className="patientSearchResultItemName">
                    <Typography variant="body1" noWrap>
                        <HighlightedText text={name} textToHighligh={search} />
                    </Typography>
                </div>
                <div  className="patientSearchResultItemDetails">
                    <CustomTypography variant="ageAndSex" noWrap component="div">
                        <HighlightedText text={`${getAgeWithFormat(dateOfBirth, "yyyy-MM-dd")} ${sex ? sex : ""}`} textToHighligh={search} />
                    </CustomTypography>
                    <CustomTypography variant="mrn" noWrap component="div">
                        <HighlightedText text={mrn} textToHighligh={search} />
                    </CustomTypography>
                </div>
            
            </div>
        ),
    };
};

