import React, { useRef, useState, useEffect } from "react"
import { useStyles } from "./styles"
import { TimerProps } from "./types"
const Timer = ({ shouldStartTimer }:TimerProps) => {
    const initialTime: string = "00:00";
    const [callDuration, setCallDuration] = useState<string>(initialTime)
    const callDuration2 = useRef(initialTime)
    const classes = useStyles()

    useEffect(() => {
        let timerIntervalInstance:any  = null
        if(shouldStartTimer&&callDuration===initialTime){
            timerIntervalInstance = setInterval(() => { setDuration() }, 1000);
        }
        return ()=>{
            clearInterval(timerIntervalInstance);
            setCallDuration(initialTime)
        }
    }, [shouldStartTimer])

    const setDuration = () => {
        const splitcallDuration = callDuration2.current.split(":");
        let minutes: any = +splitcallDuration[0]
        let seconds: any = +splitcallDuration[1]
        seconds = seconds + 1
        if (seconds > 59) {
            seconds = "00"
            minutes = minutes + 1
        }
        const localCallDuration = `${getSliceTime(minutes)}:${getSliceTime(seconds)}`;
        callDuration2.current = localCallDuration
        setCallDuration(localCallDuration)
    }

    const getSliceTime = (digit: any) => {
        digit = "0" + digit;
        return digit.slice(-2);
    }

    return shouldStartTimer ? <div className={classes.meetDuration}>{callDuration}</div> : null
}
export default Timer
