import React from "react";
import { useStyles } from "./styles";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
import TimeMark from "./time-mark";

type CaseNoteProps = {
    message: string,
    isUrgent: boolean,
    dueDate: string,
    lastEdited?: string,
}

const idPrefix = 'QA_EConsultDetails_caseNotes';
const CaseNotes: React.FC<CaseNoteProps> = ({
    message,
    dueDate,
    lastEdited,
    isUrgent,
    children
}) => {
    const classes = useStyles();

    return (
        <Box display='flex' flexDirection='column'>
            {children &&
                <div id={`${idPrefix}_infoMessage`} className={classes.eConsult_caseNote_infoMessage}>
                    {children}
                </div>
            }
            <div id={`${idPrefix}_notes`} className={classes.message}>
                {message}
            </div>
            <div className={classes.additionalInfo}>
                {isUrgent && (
                    <div id={`${idPrefix}_urgent`} className='urgent'>
                        <FormattedMessage id='EConsult.request.content.urgentNotice'/>
                    </div>
                )}
                {dueDate && (
                    <TimeMark id={`${idPrefix}_dueDate`} messageId='EConsult.request.content.responseDue'
                              dateTime={dueDate}/>
                )}
                {lastEdited && <div className='lastEdited'>
                    <TimeMark id={`${idPrefix}_lastEdited`} messageId='EConsult.request.content.lastEdited'
                              dateTime={lastEdited}/>
                </div>}
            </div>
        </Box>
    )
}

export default CaseNotes
