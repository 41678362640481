import { MODAL_TYPES } from "constant";
import CreateEConsultModalContent from "views/EConsult/create-e-consult-modal-content";
import Modal from "components/Modal";
import useActiveModal from "components/Modal/use-active-modal";

const CreateEConsult = () => {
    const { open, onClose } = useActiveModal(MODAL_TYPES.PROVIDER.CREATE_E_CONSULT_REQUEST);

    return (
        <Modal modalWidth="860px" id={MODAL_TYPES.PROVIDER.CREATE_E_CONSULT_REQUEST}>
            {open && <CreateEConsultModalContent onClose={onClose} />}
        </Modal>
    );
};

export default CreateEConsult;
