import { AppReduxStore } from 'admin/store/reducerTypes';
import classNames from "classnames";
import FileDropzoneInput, { FileDropzoneInputRef } from "admin/views/FeeSchedules/components/files/file-dropzone-input";
import { FileUploadData } from "admin/views/FeeSchedules/components/files/types";
import { useBusyState } from "common/utils/progress/progress-manager-hooks";
import { useCallback, useImperativeHandle, useRef, useState } from "react";
import { IFileWithMeta, MethodValue } from "react-dropzone-uploader";
import { useSelector } from 'react-redux';
import { call } from "store/api";
import { useStyles } from "./styles";
import { AttachmentChangeEvent, AttachmentSectionRef } from "./types";

const getTopLevelOrgId = (org: any) => {
    let current = org;
    if (current === null || current?.parent === null)
        return null;

    while (current !== null) {
        if (current?.parent?.parent === null)
            return current?.id;
        current = current?.parent;
    }
} 

export const FeeScheduleFileDropZone = ({ uploadResponse }: { uploadResponse?: any }) => {
    const { currentPractice, currentProfileRoleJoinInfo } = useSelector((state: AppReduxStore) => state).auth;
    const { organization } = useSelector((state: AppReduxStore) => state);
    const isSubOrg: boolean = (organization?.sOrganization?.parent?.parent || currentPractice?.organizationInfo?.parent?.parent) ? true : false;
    const orgId = isSubOrg ? (getTopLevelOrgId(organization?.sOrganization) || getTopLevelOrgId(currentPractice?.organizationInfo)) : (organization?.sOrganization?.id || currentProfileRoleJoinInfo?.organization?.id || currentPractice?.organizationId); 
    const classes = useStyles();
    const [, setUploadPercentage] = useState(0);
    const [, setUploadFileName] = useState<string>("");
    const [, setShowDropzone] = useState(false);
    const fileDropzoneInputRef = useRef<FileDropzoneInputRef>();
    const messagingConversationFetchingBusyStateName = "messaging/conversation-fetching";
    const { busyState } = useBusyState(messagingConversationFetchingBusyStateName);

    const uploadParams = async ({ file, meta }: IFileWithMeta) => {
        try {
            const fileName = meta.name;
            setUploadFileName(fileName);
            const response = await call("GET", `/admin/v1/billing/schedule/upload/s3-presigned-put-url?filename=${fileName}&orgId=${orgId}`);
            if (uploadResponse) {
                uploadResponse({
                    meta: meta,
                    response: response
                });
            }
            return {
                method: "PUT" as MethodValue,
                body: file,
                url: response.s3PresignedPutObjectUrl,
                headers: {
                    "keyName": response.objectKey,
                    "Content-Type": `${meta.type}; charset=UTF-8`
                },
                meta: { fileId: response.objectKey },
            };
        } catch (error) {
            console.error(error);
        }
        return { url: "" };
    };

    const [, setAttachmentInfo] = useState<{
        status: FileUploadData["status"];
        attachmentIds: string[] | undefined;
    }>({ status: "UPLOADED", attachmentIds: undefined });

    const hasAttachmentStatus = (attachments: FileUploadData[], statusInfo: FileUploadData["status"]): boolean => {
        return attachments.some((attachment) => attachment.status === statusInfo);
    };

    const onAttachmentChange = useCallback((attachments: AttachmentChangeEvent) => {
        setAttachmentInfo({
            status: attachments.cumulativeStatus,
            attachmentIds: attachments.attachmentIds,
        });
    }, [setAttachmentInfo]);

    const onDropzoneChange = (attachments: FileUploadData[]) => {
        const cumulativeStatus = hasAttachmentStatus(attachments, "ERROR")
            ? "ERROR"
            : hasAttachmentStatus(attachments, "UPLOADING")
                ? "UPLOADING"
                : "UPLOADED";
        const attachmentIds = attachments.map((attachment) => attachment.id);
        onAttachmentChange({ cumulativeStatus, attachmentIds });
        if (uploadResponse && attachments.length === 0) {
            uploadResponse(null);
        }
    };

    const ref = useRef<AttachmentSectionRef>();

    useImperativeHandle(ref, () => ({
        closeWithoutConfirmation: () => {
            fileDropzoneInputRef.current?.clearState();
            setShowDropzone(false);
        }
    }), []);

    return (
        <FileDropzoneInput
            ref={fileDropzoneInputRef}
            className={classNames("ComposeMessage__AttachmentSection__Dropzone", classes.fee_schedule_config_dropzone)}
            disabled={busyState}
            onChange={onDropzoneChange}
            onPercentageChange={setUploadPercentage}
            uploadParams={uploadParams}
            size="big"
        />
    );
}