import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import {SIZING} from "../../../admin/theme";

export const useStyles = makeStyles((theme: Theme) => ({
    pageWrapper: {
        padding: "2rem",
        width: "100%",
        height: "100%",
        background: "radial-gradient(198.54% 100% at 50% 0%, #0F7BBD 25.09%, rgba(19, 154, 237, 0) 100%)",
        [theme.breakpoints.down('md')]: {
            background: "radial-gradient(185.25% 100% at 50% 0%, #0F7BBD 22.87%, rgba(19, 154, 237, 0) 80%)"
        },
        [theme.breakpoints.down('sm')]: {
            background: "radial-gradient(185.25% 100% at 50% 0%, #0F7BBD 18.87%, rgba(19, 154, 237, 0) 60%);"
        }
    },
    contentWrapper: {
        color: "#fff",
        maxWidth: "800px",
        minHeight: "800px",
        margin: "auto",
        position: "relative",
        overflow: "visible",
        [theme.breakpoints.down('md')]: {
            marginTop: "2rem",
            textAlign: "center",
            flexDirection: "column",
            maxWidth: "600px",
        },
        "& .MuiTypography-h1": {
            display: "inline-block",
            height: "100%",
            width: "50%",
            maxWidth: "400px",
            fontSize: "1.9rem",
            fontWeight: "600",
            lineHeight: SIZING.scale1000,
            margin: "auto",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                maxWidth: "360px",
                textAlign: "center",
            },
        },
        "& .MuiTypography-h2": {
            lineHeight: SIZING.scale700,
            width: "70%",
            maxWidth: "460px",
            fontSize: "1.15rem",
            fontWeight: "600",
            marginTop: "1rem",
            [theme.breakpoints.down('md')]: {
                color: "#000",
                width: "auto",
                textAlign: "left",
                margin: "auto",
            },
        }
    },
    headRow: {
        display: "flex",
        flexDirection: "row",
        textAlign: "left",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            textAlign: "center",
        },
    },
    logo: {
        width: "260px",
        height: "auto",
        marginRight: "1rem",
        [theme.breakpoints.down('md')]: {
            margin: "1rem auto 2rem",
        },
    },
    bgImage: {
        height: "900px",
        width: "auto",
        position: "absolute",
        bottom: "-170px",
        right: "-160px",
        [theme.breakpoints.down('md')]: {
            position: "relative",
            bottom: "0",
            right: "auto",
            marginTop: "4rem"
        },
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
    },
    btnWrapper: {
        display: "flex",
        margin: "4rem auto 2rem",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            margin: "2rem auto",
        },
        "& > a": {
            display: "inline-block",
            marginRight: "1rem",
            [theme.breakpoints.down('md')]: {
                margin: "0 auto",
            },
            [theme.breakpoints.down('sm')]: {
                margin: "1rem auto",
            },
        }
    },
    btnImg: {
        width: "220px",
        height: "auto"
    }
}));