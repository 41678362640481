import { BORDER_RADIUS, SIZING, COLORS } from "theme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    message: {
        marginLeft: 0,
        marginRight: "150px",
        padding: SIZING.scale400,
        boxShadow: "0 1px 8px rgba(0, 0, 0, .05), inset 0 0 0 1px rgba(0, 0, 0, .1)",
        borderRadius: BORDER_RADIUS.MEDIUM,
        border: `1px solid ${COLORS.LBLUE}`,
        background: COLORS.MESSAGE_BG,
        display: "grid",
        gridTemplateAreas: `"name date"
            "body body"`,
        gridGap: SIZING.scale400,
        "&.Message--own": {
            background: COLORS.OWN_MESSAGE_BG,
            marginLeft: "150px",
            marginRight: 0,
        },
        "&.Message--own,.Message-read": {
            border: 0,
        },
        "& .Message__name": {
            gridArea: "name",
        },
        "& .Message__date": {
            gridArea: "date",
            textAlign: "right",
            paddingRight:SIZING.scale200
        },
        "& .Message__addition__info": {
            display:"flex",
            justifyContent:"end",
            alignItems:"center"
        },
        "& .Message__body": {
            borderRadius: `0 0 ${BORDER_RADIUS.MEDIUM} ${BORDER_RADIUS.MEDIUM}`,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
            gridArea: "body",
        },
    },
    root: {
        "&>div": {
            zIndex: 1
        }
    },
    readPopup:{
        padding:SIZING.scale300,
        minWidth:"16.875rem",
        height:"3.563rem",
        display:"flex",
        alignItems:"center",
        cursor:"pointer"
    },
    statusInfo:{
        display:"flex",
        alignItems:"center"
    },
    eyeIcon:{
        paddingRight:SIZING.scale200
    },
    ellipsisIcon:{
        cursor:"pointer"
    }
}));
