import React from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./styles";
import { IFormInput } from "admin/views/viewsTypes";
import PHQ2 from "./FormContent/PHQ2";
import PHQ9 from "./FormContent/PHQ9";
import GAD7 from "./FormContent/GAD7";
import MCHAT from "./FormContent/MCHAT";
import PRAPARE from "./FormContent/PRAPARE";
import CRAFFTN from "./FormContent/CRAFFTN";

type Props = {
  currentForm: IFormInput;
};

const RulesAndLogic: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();

  const getContent = (formName: string) => {
    const name = formName.replace("-MODIFIED", "");
    let content;

    switch (name.toUpperCase()) {
      case 'PHQ-2':
        content = <PHQ2 currentForm={currentForm} />;
        break;
      case 'PHQ-9':
        content = <PHQ9 currentForm={currentForm} />;
        break;
      case 'GAD-7':
        content = <GAD7 currentForm={currentForm} />;
        break;
      case 'M-CHAT':
        content = <MCHAT currentForm={currentForm} />;
        break;
      case 'PRAPARE':
        content = <PRAPARE currentForm={currentForm} />;
        break;
      case 'CRAFFTN':
        content = <CRAFFTN currentForm={currentForm} />;
        break;
      default:
        content = <Typography variant="body1" component="p" gutterBottom>
          No content found matching {name}
        </Typography>; 
    }
    return content;

  };
  return (
    <div className={classes.root}>
        {getContent(currentForm.name)}
    </div>
  );
}

export default RulesAndLogic