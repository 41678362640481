import { FeeScheduleDetailsProps } from "admin/views/FeeSchedules/types";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import { useEffect } from "react";

export const useFeeScheduleDetails = (id: string) => {
    const { content, fetchInfo, request } = useApi<{ id: string }, FeeScheduleDetailsProps | null>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: null,
        url: `/admin/v1/billing/schedule/${id}`,
        method: HttpMethod.GET
    });

    useEffect(() => {
        if (id && id !== "")
            request({ id });
    }, [id, request]);

    return {
        feeScheduleDetails: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
        request
    };
};