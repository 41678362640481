import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import { useEffect } from "react";
import { billingGroupProps } from "./types";

export const useFeeScheduleGroups = (orgId: string) => {
    const endpoint = `/admin/v1/billing/group/organization/${orgId}`;
    const { content, fetchInfo, request } = useApi<{}, billingGroupProps[] | null>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: null,
        url: endpoint,
        method: HttpMethod.GET
    });

    useEffect(() => {
        request({});
    }, [request]);

    return {
        feeScheduleGroups: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};