import React, { useEffect, useState } from "react";
import { Tooltip, Box, Collapse, ListItem, ListItemText } from "@mui/material";
import { Link, useRouteMatch } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { COLORS, SIZING } from "theme";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import SubList from "./SubList";
import { NavItemProps } from "./types";
import { useFeatureFlags } from "lib/security/feature-flag-hooks";
import { useStyles } from "./styles";

const scrollToView = (node: HTMLElement) => {
    node.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
    });
}

const NavItem = ({ item, open, isSubItem = false }: NavItemProps) => {
    const { route, defaultRoute, label, Icon, DecorationComponent, callback } = item;
    const intl = useIntl();
    const [collapseElement, setCollapseElement] = useState<HTMLElement | null>(null);
    const [currentMenuItem, setCurrentMenuItem] = useState(null);
    // narrowing down the type so we can access "items" in a type safe manner.
    const items = "items" in item ? item.items : null;

    const featureFlag = useFeatureFlags(item.access?.features || []);
    const classes = useStyles({ open });
    const dispatch = useDispatch();
    const routeMatch = useRouteMatch(route);
    const active = Boolean(route && Boolean(routeMatch));

    useEffect(() => {
        if (collapseElement && active) {
            scrollToView(collapseElement);
        }
    }, [collapseElement, active]);

    const navItemClassName = classNames({
        [classes.navItem]: !isSubItem,
        navItem: !isSubItem,
        [classes.subNavItem]: isSubItem,
        [classes.listItemActive]: items && active,
        [classes.itemActive]: !items && active,
    });
    const listItemClass = classNames(classes.listItem, {
        [classes.subListTrigger]: items,
        [classes.subListItem]: isSubItem,
    });

    const activeRootItemColor = items ? COLORS.SIDE_NAV_EXPANDED_ITEM_TEXT : COLORS.SELECTED_NAV_TEXT;
    const rootItemColor = active ? activeRootItemColor : COLORS.NAV_TEXT;
    const subItemColor = active ? COLORS.SELECTED_NAV_TEXT : COLORS.SIDE_NAV_EXPANDED_ITEM_TEXT;
    const color = isSubItem ? subItemColor : rootItemColor;

    if (!featureFlag) {
        return null;
    }

    const handleHideEduMenu = () => {
        setCurrentMenuItem(null)
    }

    const handleMenuItemClick = (e: any) => {
        setCurrentMenuItem(e.target);
        callback?.(dispatch)
    }
    const listItem = (
        <Tooltip title={open ? "" : intl.formatMessage({ id: label })}>
            <ListItem
                onClick={(e: any) => { handleMenuItemClick(e) }}
                onMouseEnter={(e: any) => { if (item?.id === "lnkEducation") { handleMenuItemClick(e) } }}
                disableGutters
                classes={{ root: listItemClass }}
                id={`QA_${label}`}
            >
                <Icon className={classes.listItemIcon} size={SIZING.scale800} fill={color} opacity={null} />
                {open && (
                    <ListItemText style={{ color }} primaryTypographyProps={{ variant: "body1", fontWeight: 700 }}>
                        <FormattedMessage id={label} />
                    </ListItemText>
                )}
                {DecorationComponent && (
                    <DecorationComponent
                        item={item}
                        color={color}
                        classes={classes}
                        open={open}
                        isSubItem={isSubItem}
                        args={currentMenuItem}
                        callback={handleHideEduMenu}
                    />
                )}
            </ListItem>
        </Tooltip>
    );

    const itemRoute = defaultRoute || route;

    return (
        <Box className={navItemClassName}>
            {itemRoute ? <Link to={itemRoute}>{listItem}</Link> : listItem}
            {items && (
                <Collapse ref={setCollapseElement} in={Boolean(active)} onEntered={(node) => {
                    scrollToView(node);
                }}>
                    <SubList items={items} open={open} NavItemComponent={NavItem} />
                </Collapse>
            )}
        </Box>
    );
};

export default NavItem;
