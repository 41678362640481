import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
        tabContent: {
            flexGrow: 1,
            display: "flex",
            "& .hideTab": {
                display: "none",
            },
        },
}));