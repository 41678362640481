import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
    buttonSection: {
        display: "flex",
        justifyContent:"center",
        marginTop:"2rem"
    },
    imageSection:{
        paddingRight:"1rem",
        cursor:"pointer"
    }
}));