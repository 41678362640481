import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { createSvgIcon } from "utils";
import { ReactComponent as timeClock } from "icon-library/SVG/Time_Clock.svg";
import { ReactComponent as Ended } from "icon-library/SVG/Phone_Hangup.svg";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { dropUserFromMeeting, startNRUMeeting, notifyProviders } from "store/actions";

const Icon1 = createSvgIcon(timeClock);
const EndedIcon = createSvgIcon(Ended);

//TODO-need to have the save waiting room details modal:"SAVE_WAITING_ROOM_DETAILS"

export default function WaitingStatus({ data }: any) {
    const classes = useStyles();
    const { waitingRoom,user } = useSelector((store: AppReduxStore) => store)
    const { previousMeetingDetails,providerFilterIds } = waitingRoom
    const { currentProfileId } = user
    const isPaused = previousMeetingDetails?.status === "PAUSED";
    const isEnded = previousMeetingDetails?.status === "ENDED";
    const noOfParticipants = data.participants.length;
    const dispatch = useDispatch()

    const getDesc  = () =>{
        switch (previousMeetingDetails?.status) {
            case 'PAUSED':
                    return 'pausedTitle'
            case 'ENDED':
                    return 'endedTitle'
            default:
                return 'invitedTitle'
        }
    }

    const handleStartMeeting = () => {
        const details = previousMeetingDetails;
        details.mergedParticipantsInfo=[]
        dispatch(startNRUMeeting(details))
        dispatch(notifyProviders(currentProfileId,"INCALL",null,previousMeetingDetails?.visitReminderId))
    }

    const handleDropUser = () => {
        dispatch(dropUserFromMeeting(previousMeetingDetails))
        dispatch(notifyProviders(currentProfileId,"REJECTED",null,previousMeetingDetails?.visitReminderId))
    }

    const getWaitingRoomDesc = () =>{
        const type = noOfParticipants <= 1 ? "Person" : "People";

        if(noOfParticipants===0){
            return <FormattedMessage id="WaitingRoom.status.emptyMessage" />
        }   

        if(providerFilterIds?.length ===1){
            const [{ id, lastName }] = providerFilterIds;
           return  <FormattedMessage id="WaitingRoom.status.message" values={{ count:noOfParticipants,type,name:id===currentProfileId ? 'my':`${lastName}'s`,extra:''  }} />
        }

        if(providerFilterIds?.length===0){
           return  <FormattedMessage id="WaitingRoom.status.message" values={{ count:noOfParticipants,type,name:'All',extra:'s'  }} />

        }

        return <FormattedMessage id="WaitingRoom.status.message" values={{ count:noOfParticipants,type,name:'',extra:'s'  }} />

    }

    const getMergedParticipantName = (mergedParticipantsInfo:any) =>{
        return mergedParticipantsInfo.reduce((acc:string,v:any)=>{
            return `${acc}${v.patientName} `
        },'')
    }

    const getPatientName = () =>{
        if(previousMeetingDetails?.mergedParticipantsInfo.length){
            return `${previousMeetingDetails?.patientName} and ${getMergedParticipantName(previousMeetingDetails?.mergedParticipantsInfo)}`
        }
        return previousMeetingDetails?.patientName
    }   


    return (
        <>
            <div className={classes.statusSection}>
                {
                    !previousMeetingDetails ? <>  <Icon1 className={classes.icon2} />
                        <Typography className={classes.callSectionText}>
                            {getWaitingRoomDesc()}
                        </Typography>
                        </> :
                        <>
                            <Box className={classes.statusSecondSection}>
                                {isEnded?<EndedIcon className={classes.icon2} /> :<Icon1 className={classes.icon2} /> }
                                <Typography className={classes.callSectionText}>
                                    <FormattedMessage id={`WaitingRoom.status.${getDesc()}`} values={{name:getPatientName()}}/>
                                </Typography>
                                {!isPaused && !isEnded && <Typography className={classes.callSectionDesc}>
                                    <FormattedMessage id="WaitingRoom.status.Desc" />
                                </Typography>}
                            </Box>
                           {!isEnded && <Box className={classes.buttonSection}>
                                {isPaused && <Button
                                    className={classes.waitingStsBtn}
                                    onClick={handleDropUser}
                                ><FormattedMessage id="WaitingRoom.status.dropFromWaitingRoom" />
                                </Button>}
                                <Button
                                    className={classes.waitingStsBtn1}
                                    onClick={handleStartMeeting}
                                ><FormattedMessage id={`WaitingRoom.status.${isPaused ? 'resumeCall' : 'returnCall'}`} />
                                </Button>
                            </Box>}

                        </>
                }
            </div>
        </>
    );
}
