import { SideNavItemCollection } from "./types";
import { USER_LEVELS } from "admin/constant";
import { checkNavItemAccess, isAccessAllowedByPermissions } from "lib/security/access-utils";
import { Permissions } from "types";
import { Product, Profile } from "store/reducerTypes";

/** @deprecated Define permissions in navigation tree instead of this place, please. */
export const permission: any = {
    messages: ['CORE', 'CARE_TEAM'],
    people: ['CORE', 'CARE_TEAM'], // FIXME Remove, replaced with config
    waitingRoom: ['CORE', 'CARE_TEAM'],
    learning: ['LMS'],
    eTranscription: ['CORE', Permissions.ETRANSCRIPT],
    community: ['COMMUNITY'],
    helpCenter: [],
    settings: [],
    home: [],
    discover: [],
    qualityIQ: ['QUALITYIQ'],
    eConsult: ['CORE', 'CARE_TEAM'], // FIXME Remove, replaced with config
    remoteMonitoring: [],
    education: ['QUALITYIQ', 'LMS']
};

export const adminPermission: any = {
    home: "",
    entities: "",
    users: "",
    patients: "",
    feeschedules: "",
    locations: "",
    configurations: "",
    settings: ""
};

export const filterNavigationByAccess = (configs: SideNavItemCollection, grantedPermissions: string[], enabledFeatureFlagNames: string[]): SideNavItemCollection => {
    return Object.entries(configs).reduce((filteredNavItemCollection: SideNavItemCollection, [navItemId, navItem]) => {
        const checkedItem = checkNavItemAccess(navItem, grantedPermissions, enabledFeatureFlagNames);
        if (checkedItem) {
            filteredNavItemCollection[navItemId] = checkedItem;
        }
        return filteredNavItemCollection;
    }, {});
};

// TODO: This should be replaced with checkNavItemAccess when possible, because this does not check feature flags
export const checkSideNavPermission = (config: string, products?: Product[]): boolean => {
    if (!products) {
        return false;
    } else {
        const codes = products.map((product) => product.code);
        return (
            permission[config] === "" ||
            isAccessAllowedByPermissions(permission[config] || [], codes, config === "education" ? "one" : "all")
        );
    }
};

// TODO: Replace all usage with a usePermission or similar
export const isPCR = (products: Array<Product>) => {
    const codes = products?.map((p) => p.code)
    return !codes.includes("CORE")

};

// Admin Permissions
export const defineAdminSideNav = (configs: any, currentProfileRoleJoin: Profile, adminStore: any) => {
    const configKeys = Object.keys(configs);
    //@ts-ignore
    return configKeys.reduce((acc: any, v: any, index: number) => {
        if (isActiveAdminRoute(v, currentProfileRoleJoin, adminStore)) {
            acc[v] = configs[v]
        }
        return acc
    }, {})
};

export const isActiveAdminRoute = (config: string, currentProfileRoleJoin: Profile, adminStore: any): boolean => {
    const altaisAdmins = [USER_LEVELS.ALTAIS_ADMIN_1, USER_LEVELS.ALTAIS_ADMIN_2, USER_LEVELS.ALTAIS_ADMIN_3]
    const practiceAdmin = [USER_LEVELS.PRACTICE_ADMIN];
    const { level } = currentProfileRoleJoin || {}
    const { name } = level || {}
    const codes = currentProfileRoleJoin?.products.map((p) => p.code)
    const isLicensed = adminPermission[config] === '' || adminPermission[config]?.every((p: string) => codes.includes(p))

    let isVisible = true
    if (adminStore?.auth?.currentPractice) {
        isVisible = altaisAdmins.includes(name) ? !(config === "patients") && !(config === "entities") : 
            (practiceAdmin.includes(name) ? (!(config === "feeschedules") && !(config === "entities")) : !(config === "entities"))
        if (adminStore?.license?.practiceLicenses) {
            const corePlatFormLicenseCount = adminStore?.license?.practiceLicenses && adminStore?.license?.practiceLicenses.find((core: any) => core.product.code === "CORE")?.licenseCount
            if (corePlatFormLicenseCount === undefined || corePlatFormLicenseCount === 0) {
                isVisible = !["entities", "patients", "locations"].includes(config)
            }
        }
        else {
            isVisible = !["entities", "patients", "locations"].includes(config)
        }
    }
    else if (adminStore?.auth?.currentOrg) {
        isVisible = !["patients", "configurations", "locations"].includes(config)
    }
    else if (currentProfileRoleJoin?.level?.name === "BILLING_ADMIN") {
        isVisible = ["home", "feeschedules", "settings"].includes(config);
    }
    else if (currentProfileRoleJoin?.level?.name === "PRACTICE_ADMIN") {
        isVisible = !["feeschedules"].includes(config);
    }
    return isLicensed && isVisible
};

export const checkAdminSideNavPermission = (config: string, currentProfileRoleJoin: Profile, adminStore: any): boolean => {
    return isActiveAdminRoute(config, currentProfileRoleJoin, adminStore);
};
