import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DialogModal from "components/Modals/dialog-modal/dialog-modal";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as WaitRing } from "icon-library/SVG/Wait_Ring-Thick.svg";
import { useStyles } from "./styles";

export type UploadingDialogProps = {
    percentage: number;
    titleId?: string;
    descriptionId?: string;
    warningId?: string;
};

const UploadingDialog = ({ percentage, titleId, descriptionId, warningId }: UploadingDialogProps) => {
    const intl = useIntl();
    const classes = useStyles();
    return (
        <DialogModal
            idPrefix="QA_files_uploading_dialog"
            title={intl.formatMessage(
                { id: titleId || "files.uploadDialog.uploading" },
                { percentage: percentage.toFixed(0) }
            )}
            className={classes.uploadingDialog}
        >
            <div className="uploadingDialog__content">
                <SVGIcon svg={WaitRing} size="scale1600"/>
                <div className="uploadingDialog__text">
                    <FormattedMessage id={ descriptionId || "files.uploadDialog.description" } />
                    <div><FormattedMessage id={ warningId || "files.uploadDialog.warning" } /></div>
                </div>
            </div>
        </DialogModal>
    );
};

export default UploadingDialog;
