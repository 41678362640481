import { Box, Grid, Typography, Button, IconButton } from "@mui/material";
import { getTotalUsersCountPieData, setNavBreadcrumbText } from "admin/store/actions";
import { getProductsForOrganization } from "admin/store/actions/license";
import { AppReduxStore } from "admin/store/reducerTypes";
import React, { Fragment, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { MODAL_TYPES } from 'admin/constant';
import { setActiveModal } from "admin/store/actions";
import { createSvgIcon } from "utils";
import { ReactComponent as Edit } from "icon-library/SVG/Pen-Light.svg";
import { canCreateOrgsAndSubOrgs } from "admin/Accessibility";
import PieChart from "admin/components/PieChart/PieChart";
import { LicenseWidgets } from "admin/components/LicenseWidgets/LicenseWidgets";

export const OrganizationDetail = () => {
    const { organization, license, auth, admin } = useSelector((state: AppReduxStore) => state)
    const { sOrganization } = organization

    const { orgLicenses } = license
    const dispatch = useDispatch()
    const classes = useStyles();
    const intl = useIntl()
    const EditIcon = createSvgIcon(Edit);

    useEffect(() => {
        if (sOrganization.id) {
            dispatch(getProductsForOrganization(sOrganization.id))
            dispatch(setNavBreadcrumbText(sOrganization.name))
            dispatch(getTotalUsersCountPieData(sOrganization.id, null, ["ADMIN"]))
        }
    }, [dispatch, sOrganization.id, sOrganization.name]);

    const handleEditOrganization = () => {
        dispatch(setActiveModal(MODAL_TYPES.EDIT_ORGANIZATION));
    };
    const handleEditProducts = (e: any) => {
        dispatch(getProductsForOrganization(sOrganization.id, true))
        dispatch(setActiveModal(MODAL_TYPES.EDIT_ORG_PRODUCTS))
    };

    const canCreateOrgAndPractice = canCreateOrgsAndSubOrgs()
    return (
        <Fragment>
            <Box className={classes.usersWrapper} style={{ position: "relative" }}>
                <Typography color="textPrimary" className={classes.heading}>
                    {sOrganization.name}
                </Typography>
                <Grid container spacing={2} className={classes.usersTopActionWrapper}>
                    <Grid item xs>
                        <Box display="flex">
                            <Typography color="textPrimary" className={classes.orgDetailsInfoText} flexGrow={1}>
                                {intl.formatMessage({ id: "OrganizationDetail.Label.OrganizationInfo" })}
                            </Typography>
                            {canCreateOrgAndPractice && <IconButton onClick={handleEditOrganization} size="large" className={classes.iconButtonEdit}><EditIcon fill="#0F7BBD" className={classes.editIcon} /></IconButton>}
                        </Box>
                        <Box className={classes.whiteBackGroundWrapper}>
                            <Typography color="textPrimary" className={classes.orgDetailsTextSize}>
                                {sOrganization.address1}
                            </Typography>
                            <Typography color="textPrimary" className={classes.orgDetailsTextSize}>
                                {sOrganization.address2}
                            </Typography>
                            <Typography color="textPrimary" className={classes.orgDetailsTextSize} style={{ marginBottom: '40px' }}>
                                {sOrganization.city} {sOrganization.state} {sOrganization.postalCode}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box>
                            <Typography color="textPrimary" className={classes.orgDetailsInfoText}>
                                {intl.formatMessage({ id: "OrganizationDetail.Label.OrganizationUsersPieHeading" })}
                            </Typography>
                        </Box>
                        <Box className={classes.whiteBackGroundWrapper}>
                            <PieChart data={admin.totalUsersCountPieData} showLabel={false} heading="Total Users" />
                        </Box>
                    </Grid>
                </Grid>
                {orgLicenses && orgLicenses?.length > 0 && canCreateOrgAndPractice && auth.currentOrg.parent && <Grid container className={classes.organizationProductsText}>
                    <Grid item xs>
                        <Button id="btnEditProducts" variant="contained" color="primary" style={{ float: 'right', marginBottom: '10px' }} onClick={handleEditProducts}>
                            {intl.formatMessage({ id: "OrganizationDetail.Button.EditProducts" })}
                        </Button>
                    </Grid>
                </Grid>}
                {orgLicenses && auth.currentOrg.parent && <LicenseWidgets products={orgLicenses} />}
            </Box>
        </Fragment>
    );
}