import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
export const showNotification = (message: string, type: string, description?: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({
            type: "SHOW_NOTIFICATION", payload: {
                key: '',
                message,
                description,
                type,
                dismissed: false
            }
        })
    }
}
export const hideNotification = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch({
            type: "HIDE_NOTIFICATION", payload: {
                dismissed: true
            }
        })
    }
}
