import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";
import * as CSS from "./class-names";

export const useStyles = makeStyles(() => ({
    [CSS.Page]: {
        display: "grid",
        height: "100vh",
        width: "100%",
        gridTemplateAreas: `"lcHead mcHead" "lcBody mcBody"`,
        background: COLORS.GRAY_4,
        [`& .${CSS.PageLeftHead}`]: {
            zIndex: 1,
            background: COLORS.SIDE_NAV_HEADER_BG,
            boxShadow: "inset -1px 0px 0px rgb(0 0 0 / 10%), inset 0px -1px 0px rgb(0 0 0 / 10%)",
            borderTopRightRadius: SIZING.scale500,
            gridArea: "lcHead",
            padding: SIZING.scale400,
        },
        [`& .${CSS.PageLeftBody}`]: {
            boxShadow: "1px 0 8px rgb(0 0 0 / 5%), inset -1px 0 0 rgb(0 0 0 / 5%)",
            gridArea: "lcBody",
            background: COLORS.WHITE,
            overflow: "hidden",
        },
        [`& .${CSS.PageMainHead}`]: {
            position: "relative",
            left: `-${SIZING.scale800}`,
            width: `calc(100% + ${SIZING.scale800})`,
            // Left has a bigger value to compensate the Left negative positioning
            padding: `${SIZING.scale450} ${SIZING.scale800} ${SIZING.scale450} ${SIZING.scale1600}`,
            gridArea: "mcHead",
            background: COLORS.GRAY_2,
            boxShadow: `calc(${SIZING.scale800} + 1px) 0 8px rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 10%)`,
            [`&.${CSS.PageMainHeadLightBlue}`]: {
                background: COLORS.PAGE_MAIN_HEAD_BG,                
            }
        },
        [`& .${CSS.PageMainBody}`]: {
            gridArea: "mcBody",
            background: COLORS.GRAY_4,
            overflow: "auto",
            // accounts for the notification drawer
            marginRight: SIZING.scale500
        },
        [`& .${CSS.PageMain}`]: {
            position: "relative",
            gridArea: "mcHead / mcHead / mcBody / mcBody",
            overflow: "auto",
        }
    },
}));
