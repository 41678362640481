import { ValidationError, ValidationErrors, ValidationRule } from "./types";

export const validate = <FormType>(form: FormType, validationRules: ValidationRule<FormType>[]): ValidationErrors<FormType> =>
    validationRules
        .map((rule) => ({
            ...rule,
            error: rule.validationFn(form),
        }))
        .filter((rule) => !rule.error)
        .reduce<Record<string | number,unknown>>((errors, rule) => {
            let errorObject: ValidationError & Record<string | number,unknown> = errors;

            for (const pathFragment of rule.path as string[]) {
                if (!errorObject[pathFragment]) {
                    errorObject[pathFragment] = {};
                }
                errorObject = errorObject[pathFragment] as ValidationError;
            }

            errorObject.error = {
                errorMessageId: rule.errorMessageId,
            };
            return errors;
        }, {}) as ValidationErrors<FormType>;
