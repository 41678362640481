import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, COLORS, SIZING} from "theme";
import {InputProps} from "./types";


export const useStyles = makeStyles(() => ({
    root: {
        borderRadius: ({isSearch}: InputProps) => {return isSearch ? BORDER_RADIUS.PILL : BORDER_RADIUS.MEDIUM},
    },
    error: {
        color: COLORS.ERROR_TEXT,
        marginLeft:SIZING.scale400,
        marginTop:SIZING.scale100,
        display:'flex',
        alignItems:'center',
        "& .MuiSvgIcon-root":{
            width:SIZING.scale400,
            marginRight:SIZING.scale100,
        }
    },
    

}));