import React, { useState } from "react";
import { Box, Input, InputAdornment } from "@mui/material";
import { EConsultFilterTab, EConsultFolderName, EConsultRequestSearchData, EConsultRequestSearchDto } from "./types";
import { ReactComponent as eConsultReceivedIcon } from "icon-library/SVG/Inbox_Down-Arrow.svg";
import { ReactComponent as eConsultSentIcon } from "icon-library/SVG/Inbox_Up-Arrow.svg";
import { ReactComponent as SearchIcon } from "icon-library/SVG/Search.svg";
import { useApi } from "common/utils/use-api";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import classNames from "classnames";
import SearchItem from "./search-item";
import EmptyMessage from "../../../../common/components/empty-message/empty-message";
import SearchFilterTabs from "components/SearchFilterTabs/SearchFilterTabs";
import Spinner from "common/components/Spinner/Spinner";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { useRequestContext } from "./request-context";
import SearchItemNotification from "./search-item-notification";
import SearchList from "lib/ui-components/search-list/search-list";

const eConsultReceivedFilters = [
    {
        id: "QA_EConsultSearch_tabs_new",
        labelId: "EConsult.request.search.new",
        stage: "NEW",
    },
    {
        id: "QA_EConsultSearch_tabs_claimed",
        labelId: "EConsult.request.search.claimed",
        stage: "CLAIMED",
    },
    {
        id: "QA_EConsultSearch_tabs_closed",
        labelId: "EConsult.request.search.closed",
        stage: "CLOSED",
    },
] as const;

const DEFAULT_DELAY_MS: number = 500;

const DEFAULT_SORT = {
    direction: "DESC",
    field: "lastModifiedDate",
};

const EMPTY_ICON = {
    sent: eConsultSentIcon,
    received: eConsultReceivedIcon,
} as const;

type EConsultFolderViewSearchProps = {
    folderName: EConsultFolderName;
    openedRequestId?: string | null;
};

const EConsultFolderViewSearch = ({ folderName, openedRequestId }: EConsultFolderViewSearchProps) => {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const [selectedEConsultRequestId, setSelectedEConsultRequestId] = useState<string | null>(openedRequestId ?? null);
    const [searchText, setSearchText] = useState("");
    const [searchFilterTab, setSearchFilterTab] = useState<EConsultFilterTab>(eConsultReceivedFilters[0]);
    const [delay, setDelay] = useState(DEFAULT_DELAY_MS);
    const { requestChanges } = useRequestContext();

    const {
        content: eConsultRequestSearchData,
        fetchInfo: { hasResult, requestParams },
    } = useApi<EConsultRequestSearchDto, EConsultRequestSearchData, EConsultRequestSearchData>(
        {
            id: "eConsultRequestsSearch",
            autoRequest: true,
            defaultContent: { data: [] },
            delay: delay,
            abortOnNewRequest: true,
            method: "POST",
            endpoint: "/econsult/v1/requests/search",
        },
        {
            classification: folderName.toUpperCase(),
            sort: [DEFAULT_SORT],
            stage: searchFilterTab.stage,
            text: searchText,
        }
    );

    const onItemSelect = (id: string): void => {
        history.push(`/provider/econsult/${folderName}/${id}`);
        setSelectedEConsultRequestId(id);
    };
    const onValueChange = (event: any): void => {
        setDelay(DEFAULT_DELAY_MS);
        setSearchText(event.target.value);
    };

    const searchItems = eConsultRequestSearchData.data.filter((item) => {
        if (
            (searchFilterTab.stage === "NEW" && requestChanges[item.id]) ||
            (searchFilterTab.stage === "CLAIMED" &&
                requestChanges[item.id] &&
                requestChanges[item.id]?.newStage !== "CLAIMED")
        ) {
            return false;
        }
        return true;
    });

    const hasSearchResults = hasResult && searchItems.length !== 0 && requestParams.stage === searchFilterTab.stage;
    const isEmpty = hasResult && searchItems.length === 0;

    return (
        <>
            {openedRequestId && <div className={classes.searchAreaBackground} />}
            <div className={classes.searchArea}>
                <div className={classes.searchInputArea}>
                    <Input
                        id="QA_EConsultSearch_textfield"
                        autoComplete="off"
                        className={classes.searchInput}
                        onChange={onValueChange}
                        placeholder={intl.formatMessage({ id: `EConsult.request.search.input.${folderName}` })}
                        startAdornment={
                            <InputAdornment position="start">
                                <SVGIcon className={classes.searchIcon} svg={SearchIcon} size="scale600" />
                            </InputAdornment>
                        }
                        disableUnderline={true}
                    />
                </div>
                <div className={classes.filterTabRootArea}>
                    <SearchFilterTabs
                        classes={{ root: classes.filterTabRoot }}
                        tab={searchFilterTab.id}
                        setTab={(id: string) => {
                            setSearchFilterTab(
                                eConsultReceivedFilters.find((filter) => filter.id === id) || eConsultReceivedFilters[0]
                            );
                        }}
                        tabList={eConsultReceivedFilters}
                    />
                </div>
            </div>

            <div
                id="QA_EConsultSearch_results_list"
                className={classNames(classes.searchItemArea, `eConsult__itemList--${folderName}`)}
            >
                {hasSearchResults ? (
                    <SearchList
                        itemRenderer={(item, index) => {
                            return (
                                <SearchItem
                                    idPrefix={`QA_EConsultSearch_result_${index}`}
                                    {...item}
                                    closed={searchFilterTab.stage === "CLOSED"}
                                >
                                    <SearchItemNotification
                                        idPrefix={`QA_EConsultSearch_result_${index}`}
                                        index={index}
                                        item={item}
                                        folderName={folderName}
                                        searchStage={searchFilterTab.stage}
                                    />
                                </SearchItem>
                            );
                        }}
                        items={searchItems}
                        loading={false}
                        onItemSelect={onItemSelect}
                        selectedItemId={selectedEConsultRequestId}
                    />
                ) : isEmpty ? (
                    <EmptyMessage
                        className="emptyMessage"
                        Icon={<SVGIcon svg={EMPTY_ICON[folderName]} opacity={0.4} size="scale5000" className="emptyIcon" color="BLACK"/>}
                        opacity={0.4}
                        primaryMessage={{
                            id: searchText
                                ? "EConsult.request.search.noFound.primary"
                                : folderName === "sent"
                                ? "EConsult.request.search.empty.primary.sent"
                                : "EConsult.request.search.empty.primary.received",
                            variant: "emptyEConsultListBigPrimary",
                            color: "E_CONSULT_TEXT"
                        }}
                        secondaryMessage={{
                            id: searchText
                                ? "EConsult.request.search.noFound.secondary"
                                : folderName === "sent"
                                ? "EConsult.request.search.empty.secondary.sent"
                                : undefined,
                            variant: "modalSectionHeaderBold",
                            color: "E_CONSULT_TEXT"
                        }}
                    />
                ) : (
                    <Box display="flex" justifyContent="center" height="100%">
                        <Spinner />
                    </Box>
                )}
            </div>
        </>
    );
};

export default EConsultFolderViewSearch;
