import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useStyles } from "./styles";
import { useSelector,useDispatch } from "react-redux";
import { AppReduxStore } from "png/store/reducerTypes";
import { useHistory } from "react-router-dom";
import HeaderController from "png/components/HeaderController/HeaderController";
import WaitingRoomLoader from "png/components/WaitingRoomLoader/WaitingRoomLoader";
import TwilioVideo from "components/Twilio/Twilio";
import { useReduxDispatch } from "png/store/utils";
import { rejoinMeeting } from "png/store/actions";
import PermissionCheckErrorMessage from "png/components/PermissionCheck/PermissionCheck"
// TODO need to clean up mediaPriority
type PriorityType = 'cam' | 'mic' | 'both'

function PatientLobby() {
    const classes = useStyles();
    const { push } = useHistory()
    const [mediaPriority] = useState<PriorityType>('both')
    const { pngDetails, twilioRoomId, twilioVideoToken, isCallDroped,isCallEnded,currentRoomObject,isMeetingPaused } = useSelector((store: AppReduxStore) => store.pngMobile)
    const [providerConnected, setproviderConnected] = useState(false)
    const dispatch = useDispatch()
    const customDispatch = useReduxDispatch()

    const { providerProfileInfo } = pngDetails || { providerProfileInfo: {} };

    const providerName = providerProfileInfo?.firstName || "";

    useEffect(()=>{
        if(sessionStorage.getItem('isPaused')==='true'){
            dispatch({ type: 'SYS_NRU_EVISIT_PAUSED',payload:true })
            resetQuickMessage()
        }
        if(sessionStorage.getItem('isBackTowaitinRoom')==='true'){
            dispatch({ type: 'SYS_NRU_EVISIT_PAUSED',payload:true })
            resetQuickMessage()
        }
    },[dispatch])

    const resetQuickMessage = () =>{
        dispatch({ type: 'SET_QUICK_MESSAGE', payload:""})
        dispatch({ type: 'SET_MESSAGE_FROM', payload: ""})
    }

    useEffect(()=>{
        isMeetingPaused && currentRoomObject && currentRoomObject.disconnect()
    },[isMeetingPaused])

    useEffect(() => {
        !pngDetails&&customDispatch(rejoinMeeting()).catch(()=>{
            push('/wr-status')
        })
    }, [pngDetails])

    useEffect(() => {
        if (twilioRoomId && twilioVideoToken) {
            setproviderConnected(true)
        }else{
            setproviderConnected(false)
            currentRoomObject && currentRoomObject.disconnect()
        }
    }, [twilioRoomId, twilioVideoToken])


    useEffect(() => {
        if (isCallDroped || isCallEnded) {
            push('/wr-status')
        }
    }, [isCallDroped,isCallEnded])

    const handleRoomCallback = (room:any) =>{
        dispatch({ type:'SET_NAE_ROOM_OBJ',payload:room })
        dispatch({ type: 'SET_QUICK_MESSAGE', payload:""})
        dispatch({ type: 'SET_MESSAGE_FROM', payload: ""})
    }

    const handleEndMeeting = () => {
        currentRoomObject && currentRoomObject.disconnect()
        if (currentRoomObject) {
            dispatch({ type: 'SET_NAE_CALL_ENDED' })
        }
        push('/wr-status')
    }

    const handleTimeOut = () =>{
        currentRoomObject && currentRoomObject.disconnect()
        dispatch({ type: 'SET_NAE_MEETING_TIMEOUT' })
        push('/wr-status')
    }
    const changePriority = (type:string) =>{
        dispatch({ type:'SET_MEDIA_UPDATE_ERROR',payload:type }) 
    }

    return (
        <Box className={classes.base} >
           {!(!providerConnected || isMeetingPaused ) && <PermissionCheckErrorMessage  priority={mediaPriority} /> }
            <Box className={classes.baseContainer}>
                <HeaderController onEndMeeting={handleEndMeeting} />
                {(!providerConnected || isMeetingPaused )? <WaitingRoomLoader isPaused={isMeetingPaused} /> :
                    <TwilioVideo
                        roomId={twilioRoomId}
                        twilioIdentity={pngDetails?.patientName||''}
                        userName={pngDetails?.patientName || ''}
                        twilioVideoToken={twilioVideoToken || ''}
                        roomCallback={handleRoomCallback}
                        peerName={providerName}
                        participantType={'PATIENT'}
                        onEndMeeting={handleEndMeeting}
                        timeoutHandler={handleTimeOut}
                        showInstrcutions={changePriority}
                    />}
            </Box>
        </Box>
    )
}

export default PatientLobby
