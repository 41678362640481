import { useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { EConsultRequestModel } from "./types";

export const useRequestInfo = (eConsultRequest: EConsultRequestModel) => {
    const user = useSelector((store: AppReduxStore) => store.user);

    const userIsReferer = user.currentProfileId === eConsultRequest.referrer.id;
    const assignee = eConsultRequest.recipients?.find((recipient) => recipient.id === eConsultRequest.assigneeId);
    const recipientStatus = !userIsReferer ? eConsultRequest.recipients?.[0].status : undefined;
    const userIsAssignee = user.currentProfileId === assignee?.id;
    const assignedByOther = !userIsReferer && !userIsAssignee && eConsultRequest.status === "ASSIGNED";

    return {
        assignedByOther,
        assignee,
        isSentRequest: userIsReferer,
        recipientStatus,
        status: eConsultRequest.status,
        userIsAssignee,
    };
};
