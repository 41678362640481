import { Button } from "@mui/material";
import { CloseEditorDialog } from "./components/close-editor-dialog";
import { mapToCareTeamRequest } from "./components/mappers";
import { FormattedMessage, useIntl } from "react-intl";
import { ProviderSearchTab } from "./components/provider-search-tab";
import { ReactComponent as Loader } from "icon-library/SVG/Wait_Ring-Thick.svg";
import { setActiveModal } from "store/actions";
import { Tab } from "common/components/Tabs";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useEventDispatcher } from "lib/events";
import { useUserNotification } from "common/utils/use-user-notification";
import { ValidationErrorObject, ValidationResult } from "lib/validation/types";
import { ValueOf } from "lib/util-types";
import * as CSS from "./class-names";
import InputWithTranscription from "common/components/InputWithTranscription/InputWithTranscription";
import MessageContainer, { MessageContainerVariants } from "lib/ui-components/message-container/message-container";
import ModalContent from "components/Modal/ModalContent";
import ModalHeader from "lib/ui-components/modal-header/modal-header";
import PatientSearchTab from "./components/patient-search-tab";
import SubmitErrorDialog from "lib/ui-components/error-list-dialog/error-list-dialog";
import useCareTeamNameValidator from "./components/use-care-team-name-validation";
import useCareTeamState from "./components/use-care-team-state";
import useCreateCareTeam from "./components/use-create-care-team";

type CareTeamEditorContentProps = {
    onClose: () => void;
};

const TabNames = {
    MEMBERS: "members",
    PATIENTS: "patients",
} as const;

type TabNameValues = ValueOf<typeof TabNames>;

const TEAM_NAME_FIELDNAME = "name";
const validationResultMappings: Record<
    string,
    { icon?: JSX.Element; id?: string; messageKey?: string; variant?: MessageContainerVariants }
> = {
    [`${TEAM_NAME_FIELDNAME}--invalid`]: {
        id: `QA_careTeam_${TEAM_NAME_FIELDNAME}--invalid`,
        messageKey: `careTeam.editor.create.errors.fields.${TEAM_NAME_FIELDNAME}.message`,
        variant: "error",
    },
    [`${TEAM_NAME_FIELDNAME}--running`]: {
        id: `QA_careTeam_${TEAM_NAME_FIELDNAME}--running`,
        icon: <Loader />,
        messageKey: "careTeam.editor.teamName.checkUniqueness",
    },
    [`${TEAM_NAME_FIELDNAME}--unset`]: {
        id: `QA_careTeam_${TEAM_NAME_FIELDNAME}--unset`,
    },
    [`${TEAM_NAME_FIELDNAME}--valid`]: {
        id: `QA_careTeam_${TEAM_NAME_FIELDNAME}--valid`,
        messageKey: "careTeam.editor.teamName.isUnique",
        variant: "info",
    },
};

const CareTeamEditorContent = ({ onClose }: CareTeamEditorContentProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { enqueueSuccess } = useUserNotification();

    const [confirmClose, setConfirmClose] = useState(false);
    const [selectedTab, setSelectedTab] = useState<TabNameValues>(TabNames.MEMBERS);
    const [foundErrors, setFoundErrors] = useState<ValidationErrorObject[]>([]);
    const [showFailedSubmitDialog, setShowFailedSubmitDialog] = useState(false);
    const { setName, setSelectedPatients, setSelectedProviders, careTeamState } = useCareTeamState();
    const { clear: clearValidation, result: validationResult } = useCareTeamNameValidator(
        careTeamState[TEAM_NAME_FIELDNAME]
    );
    const { submit, submitting } = useCreateCareTeam();
    const eventDispatcher = useEventDispatcher();

    const onSubmitClicked = () => {
        if (validationResult === ValidationResult.VALID) {
            submit(mapToCareTeamRequest(careTeamState))
                .then((careTeam) => {
                    eventDispatcher("careTeam:created", { id: careTeam?.id || "" });
                    enqueueSuccess("careTeam.create.submit.success");
                    dispatch(setActiveModal(""));
                })
                .catch((result) => {
                    const fieldRelatedErrors = result?.details?.fields;
                    if (result.httpStatus === 400 && fieldRelatedErrors) {
                        setFoundErrors(
                            Object.keys(fieldRelatedErrors).map((key) => ({
                                id: key,
                                nameKey: `careTeam.editor.create.errors.fields.${key}.name`,
                                detailsKey: `careTeam.editor.create.errors.fields.${key}.message`,
                            }))
                        );
                        setShowFailedSubmitDialog(true);
                    }
                });
        }
    };

    const addTeamNameError = () => {
        setFoundErrors((prevFoundErrors) => [
            ...prevFoundErrors,
            {
                id: TEAM_NAME_FIELDNAME,
                nameKey: `careTeam.editor.create.errors.fields.${TEAM_NAME_FIELDNAME}.name`,
                detailsKey: `careTeam.editor.create.errors.fields.${TEAM_NAME_FIELDNAME}.message`,
            },
        ]);
    };
    const removeTeamNameError = () => {
        setFoundErrors((prevFoundErrors) =>
            prevFoundErrors.filter((errorItem) => errorItem.id !== TEAM_NAME_FIELDNAME)
        );
    };
    const clearErrors = () => {
        setFoundErrors([]);
    };

    useEffect(() => {
        if (validationResult === ValidationResult.INVALID) {
            addTeamNameError();
        }
        if (validationResult === ValidationResult.VALID) {
            removeTeamNameError();
        }
    }, [validationResult]);

    const getMessageContainerPropsKey = () => {
        if (Boolean(foundErrors?.find((error) => error.id === TEAM_NAME_FIELDNAME))) {
            return "--invalid";
        } else {
            switch (validationResult) {
                case ValidationResult.RUNNING:
                    return "--running";
                case ValidationResult.VALID:
                    return "--valid";
                default:
                    return "--unset";
            }
        }
    };
    const selectedMapping = validationResultMappings[`${TEAM_NAME_FIELDNAME}${getMessageContainerPropsKey()}`];

    return (
        <>
            <div id="QA_careTeam_editor" data-testid="QA_careTeam_editor" className="Modal__Content">
                <ModalHeader
                    className={CSS.CareTeamEditorHeader}
                    idPrefix="QA_careTeam_editor"
                    onClose={() => setConfirmClose(true)}
                    tabs={[
                        <Tab<TabNameValues>
                            key={TabNames.MEMBERS}
                            name={TabNames.MEMBERS}
                            labelId={`careTeam.tabs.${TabNames.MEMBERS}`}
                            selected={selectedTab === TabNames.MEMBERS}
                            onTabSelected={setSelectedTab}
                            count={careTeamState.selectedProviders.length}
                        />,
                        <Tab<TabNameValues>
                            key={TabNames.PATIENTS}
                            name={TabNames.PATIENTS}
                            labelId={`careTeam.tabs.${TabNames.PATIENTS}`}
                            selected={selectedTab === TabNames.PATIENTS}
                            onTabSelected={setSelectedTab}
                            count={careTeamState.selectedPatients.length}
                        />,
                    ]}
                    titleKey="careTeam.editor.title"
                    subTitleKey="careTeam.editor.subtitle"
                >
                    <div className={CSS.CareTeamEditorHeaderPlaceholder}>
                        <MessageContainer
                            id={selectedMapping.id}
                            icon={selectedMapping.icon}
                            message={
                                selectedMapping.messageKey
                                    ? intl.formatMessage({ id: selectedMapping.messageKey })
                                    : undefined
                            }
                            variant={selectedMapping.variant}
                        >
                            <InputWithTranscription
                                id="QA_careTeam_editor_name"
                                className="teamNameInput"
                                error={getMessageContainerPropsKey() === "--invalid"}
                                onChange={setName}
                                onKeyPress={() => {
                                    removeTeamNameError();
                                    clearValidation();
                                }}
                                placeholder={intl.formatMessage({ id: "careTeam.editor.fields.teamName" })}
                                value={careTeamState.name}
                                classes={{
                                    microphoneRow: "teamNameInput__mic",
                                }}
                            />
                        </MessageContainer>
                        <Button
                            onClick={onSubmitClicked}
                            id="QA_careTeam_editor_create"
                            className="createButton"
                            variant="contained"
                            color="inherit"
                            disabled={
                                submitting || foundErrors.length > 0 || validationResult === ValidationResult.RUNNING
                            }
                        >
                            <FormattedMessage id="careTeam.editor.create" />
                        </Button>
                    </div>
                </ModalHeader>
                <ModalContent className={CSS.CareTeamEditorContent}>
                    {selectedTab === TabNames.MEMBERS && (
                        <ProviderSearchTab
                            onChange={(providers) => {
                                setSelectedProviders(providers);
                                clearErrors();
                            }}
                            selectedProviders={careTeamState.selectedProviders}
                        />
                    )}
                    {selectedTab === TabNames.PATIENTS && (
                        <PatientSearchTab
                            onChange={(patients) => {
                                setSelectedPatients(patients);
                                clearErrors();
                            }}
                            selectedPatients={careTeamState.selectedPatients}
                        />
                    )}
                </ModalContent>
            </div>
            {showFailedSubmitDialog && (
                <SubmitErrorDialog
                    contentKey="careTeam.editor.create.errors.description"
                    errors={foundErrors}
                    idPrefix="QA_careTeam_submitErrorDialog"
                    onClose={() => {
                        setShowFailedSubmitDialog(false);
                    }}
                    titleKey="careTeam.editor.create.errors.title"
                />
            )}
            {confirmClose && <CloseEditorDialog onCancel={() => setConfirmClose(false)} onClose={onClose} />}
        </>
    );
};

export default CareTeamEditorContent;
