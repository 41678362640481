import React from "react";
import { Paper, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useStyles } from "./styles";
import { IFormInput } from "admin/views/viewsTypes";
import { FormattedMessage } from "react-intl";

type Props = {
  currentForm: IFormInput;
};

const PHQ2: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <FormattedMessage id={"RulesAndLogic.Label.ScoringRules"} />
          </Typography>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.PHQ2.Body1"} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
            <TableContainer className={classes.scoringTable} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "33%" }}>
                    <FormattedMessage id={"RulesAndLogic.PHQ2.ScoreScale1"} />
                  </TableCell>
                  <TableCell style={{ width: "33%" }}>
                  <FormattedMessage id={"RulesAndLogic.PHQ2.ScoreScale2"} />
                  </TableCell>
                  <TableCell style={{ width: "33%" }}>
                    <FormattedMessage id={"RulesAndLogic.PHQ2.ScoreScale3"} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ width: "33%" }}>
                      <Typography variant="h6">
                      <FormattedMessage id={"RulesAndLogic.PHQ2.ScoreScale4"} />
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: "33%" }}>
                      <FormattedMessage id={"RulesAndLogic.PHQ2.ScoreScale5"} />
                    </TableCell>
                    <TableCell style={{ width: "33%" }}>
                      <FormattedMessage id={"RulesAndLogic.PHQ2.ScoreScale6"} />
                    </TableCell>
                  </TableRow>
              </TableBody>
              </Table>  
            </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            <FormattedMessage id={"RulesAndLogic.PHQ2.Body2"} />
          </Typography>
        </Grid>
        </Grid>
    </div>
  );
}

export default PHQ2