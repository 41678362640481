import Typography from '@mui/material/Typography';
import { getAppCurrentProfileId, getAppIdToken } from 'authentication/appAuth';
import Spinner from "common/components/Spinner/Spinner";
import { useUserNotification } from "common/utils/use-user-notification";
import { useEffect, useState } from "react";
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from "react-redux";
import { call } from "store/api";
import { AppReduxStore } from "store/reducerTypes";
import { find } from "underscore";
import { AdditionalResources } from "views/PEPHome/components/additional-resources";
import LoadingIndicator from '../components/loading-indicator/LoadingIndicator';
import { useStyles } from "./styles";

export const PEPCozevaTrainingVideo = () => {
    const { user } = useSelector((store: AppReduxStore) => store);
    const { currentProfileRoleJoin } = user;
    const practiceId = currentProfileRoleJoin?.practice?.id;
    const orgId = currentProfileRoleJoin?.practice?.organizationId || currentProfileRoleJoin?.organization?.id;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueError } = useUserNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number | undefined>();
    const [url, setUrl] = useState<string | undefined>(undefined);

    const handleDownloadProgress = (progress: number) => {
        if (progress) {
            setProgress(progress);
        }
    }

    const loadVideo = async (onProgressUpdate?: (progress: number) => void) => {
        const fileRequest = {
            fileName: "Cozeva with TOC.mp4",
            fileType: "DOCUMENT",
            orgId: orgId,
            practiceId: practiceId
        };
        const axios = require('axios');
        const { ENABLE_API_URL } = (window as any).env
        const axiosURL = ENABLE_API_URL + "/api/admin/v1/provider/document/download";

        await axios.post(axiosURL, fileRequest,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf,video/mp4',
                    'Authorization': 'Bearer ' + getAppIdToken()
                },
                onDownloadProgress: (progressEvent: { total: any; loaded: any; }) => {
                    const total = progressEvent.total;
                    const current = progressEvent.loaded;
                    const percentCompleted = Math.floor(current / total * 100);
                    if (onProgressUpdate) {
                        onProgressUpdate(percentCompleted);
                    }
                }
            }).then(async (response: { headers: any, data: any; }) => {
                setProgress(undefined);
                if (response?.data) {
                    const responseContentType = await (response?.headers["content-type"]);
                    const contentType: string = responseContentType.split(";")[0] || ("video/mp4");
                    const blobData = new Blob([response.data], { type: contentType });
                    const url = window.URL.createObjectURL(blobData);
                    setUrl(url);
                }
            });
    }

    useEffect(() => {
        loadVideo(handleDownloadProgress);
    }, [])

    useEffect(() => {
        const getUser = async () => {
            const currentProfileId: string | undefined | null = getAppCurrentProfileId();
            const getCurrentRoleJoin = (profileRoleJoinInfos: any, currentProfileRoleJoinId: string | undefined | null) => {
                if (!profileRoleJoinInfos && !currentProfileRoleJoinId) return null
                return find(profileRoleJoinInfos, (profileRoleJoinInfo: any) => profileRoleJoinInfo.id === currentProfileRoleJoinId)
            }
            try {
                const { user, twilioToken } = await call("GET", "/admin/v1/user/current");
                const currentProfileRoleJoin = getCurrentRoleJoin(user?.profileRoleJoinInfos, currentProfileId);
                dispatch({ type: 'SET_USER', payload: { ...user, currentProfileRoleJoin } });
                dispatch({ type: 'SET_CURRENT_PROFILE_ID', payload: { currentProfileId: currentProfileRoleJoin?.profileId, currentUserType: currentProfileRoleJoin?.type } });
                dispatch({ type: 'SET_TWILIO_TOKEN', payload: { token: twilioToken } });
            } catch (error: any) {
                enqueueError("pep.get.user.error");
            } finally {
                setIsLoading(false);
            }
        }
        if (!user.username) {
            setIsLoading(true);
            getUser();
        }
    }, [dispatch, user.username, enqueueError])

    return (
        isLoading ? <Spinner /> : (
            <div id="help_row" className={classes.pep_help_row}>
                <div id="help_container" className={classes.pep_help_container}>
                    <div id="help_left" className={classes.pep_help_left}>
                        <div id="help_banner" className={classes.pep_help_banner}>
                            <Typography id="help_banner_top" className={classes.pep_help_banner_top}>
                                {`Cozeva User Training Video`}
                            </Typography>
                        </div>
                        {false && <Typography id="help_description" className={classes.pep_help_description}>
                            {`Video Content.`}
                        </Typography>}
                        {!progress &&
                            <ReactPlayer
                                playing
                                controls
                                width="640px"
                                height="320px"
                                url={url} />}
                        {progress &&
                            <LoadingIndicator dimension={{
                                width: "640px",
                                height: "320px"
                            }} loadingMessage={{
                                title: 'Video Download',
                                description: 'Preparing the video...',
                                description_inProgress: 'Loading the video content...'
                            }} loadingProgress={progress} />
                        }
                    </div>
                    <div id="help_right" className={classes.pep_help_right}>
                        <AdditionalResources />
                    </div>
                </div>
            </div >)
    )
}