import { isMenuItem, isSeparatorItem, SideNavItemCollection, SideNavItemTypes } from "views/SideNav/types";
import { PermissionPolicy } from "./types";

export const isAccessAllowedByPermissions = (
    requiredPermissions: string[],
    availablePermissions: string[],
    permissionPolicy: PermissionPolicy
) =>
    !requiredPermissions.length ||
    (permissionPolicy === "all"
        ? requiredPermissions.every((permission) => availablePermissions.includes(permission))
        : requiredPermissions.some((permission) => availablePermissions.includes(permission)));

const isFeatureEnabled = (requiredFeatures: string[], availableFeatures: string[]) =>
    !requiredFeatures.length || requiredFeatures.every((feature) => availableFeatures.includes(feature));

export const requiresFeatureFlag = (navItem: SideNavItemTypes) =>
    Boolean(isMenuItem(navItem) && navItem.access?.features?.length);

export const checkNavItemAccess = (navItem: SideNavItemTypes, productCodes: string[], featureFlags: string[]) => {
    if (isSeparatorItem(navItem)) {
        return navItem;
    } else if (
        isMenuItem(navItem) &&
        isAccessAllowedByPermissions(navItem.access?.permissions || [], productCodes, navItem.access?.policy ?? "all") &&
        isFeatureEnabled(navItem.access?.features || [], featureFlags)
    ) {
        if (navItem.items) {
            const preparedNavItem = {
                ...navItem,
            };
            preparedNavItem.items = Object.entries(navItem.items).reduce((itemCollection, [key, item]) => {
                const checkedItem = checkNavItemAccess(item, productCodes, featureFlags);
                if (checkedItem) {
                    itemCollection[key] = checkedItem;
                }
                return itemCollection;
            }, {} as SideNavItemCollection);
            return preparedNavItem;
        } else {
            return navItem;
        }
    } else {
        return null;
    }
};

export const flattenNavItemCollection = (navItemCollection: SideNavItemCollection): SideNavItemTypes[] => {
    return Object.entries(navItemCollection).reduce((flatCollection, [key, navItem]) => {
        if (isSeparatorItem(navItem)) {
            flatCollection.push(navItem);
        } else if (isMenuItem(navItem)) {
            if ("items" in navItem) {
                flatCollection = flatCollection.concat(flattenNavItemCollection(navItem.items || {}));
            }
            flatCollection.push({
                ...navItem,
                id: key,
            });
        }
        return flatCollection;
    }, [] as SideNavItemTypes[]);
};
