import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        error: {
            color: COLORS.ERROR_TEXT,
            marginLeft:SIZING.scale400,
            marginTop:SIZING.scale100,
            display:'flex',
            alignItems:'center',
            "& .MuiSvgIcon-root":{
                width:SIZING.scale400,
                marginRight:SIZING.scale100,
            }
        }
}));