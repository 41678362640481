import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "admin/theme";


export const useStyles = makeStyles(() => ({
    wrapper: {
        minWidth: "640px",
        minHeight: "360px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogHeading: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#393948',
        marginTop: '5px'
    },
    dialogTitle: {
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '140%',
        color: '#565662'
    },
    tabContentWrapper: {
        textAlign: 'center',
        minWidth: '500px'
    },
    label: {
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left'
    },
    formGpHeader: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '24px',
        color: '#393948',
    },
    dlgAction: {
        padding: '5px 32px',
        background: 'none'
    },
    previewGridClasses: {
        marginTop: '10px'
    },
    pageHeaderTextProduct: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '140%',
        color: '#393948',
    },
    pageSubHeaderText: {
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#565662'
    },
    productsIcon: {
        fontSize: '42px',
        verticalAlign: 'middle',
        marginRight: '12px'
    },

    pageHeaderText: {
        fontWeight: 600,
        fontSize: '32px',
        color: '#393948',
        lineHeight: '140%',
    }
}));