export const ValidationResult = {
    INVALID: "INVALID",
    RUNNING: "RUNNING",
    UNSET: "UNSET",
    VALID: "VALID",
} as const;

export type ValidationErrorObject = {
    /** If there are be multiple errors with the same nameKey, then this id MUST be used to keep it unique. */
    id?: string;
    nameKey: string;
    detailsKey: string;
}