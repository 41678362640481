import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    pep_ioa_forms_row: {
        width: '100%',
        minWidth: '1440px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '140%',
        color: '#000000',
        userSelect: 'none',
        marginBottom: '20px'
    },
    pep_ioa_forms_container: {
        display: 'flex',
        width: '100%',
        maxWidth: '1440px',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    pep_ioa_forms_left: {
        marginRight: '10px',
        width: '1065px',
        paddingBottom: '10px'
    },
    pep_ioa_forms_right: {
        marginLeft: '20px',
        marginRight: '20px',
        width: '100%'
    },
    pep_ioa_forms_top: {
        width: '100%'
    },
    pep_ioa_forms_banner: {
        marginTop: '20px',
        marginBottom: '20px',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 32px',
        gap: '4px',
        width: '1065px',
        height: '111px',
        background: 'linear-gradient(90deg, #F6F6F6 66.29%, rgba(246, 246, 246, 0) 100%)',
        borderRadius: '4px'
    },
    pep_ioa_forms_banner_top: {
        fontFamily: 'BentonSansCond',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '32px',
        lineHeight: '140%',
        color: '#6E1E80'
    },
    pep_ioa_forms_banner_bottom: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#6E1E80'
    },
    pep_ioa_forms_root: {
        marginBottom: '5px',
        padding: '8px 12px',
        gap: '8px',
        display: 'flex',
        alignItems: 'center',
        width: '508px',
        height: '40px',
        borderRadius: '20px',
        background: '#FFFFFF',
        border: '1px solid #909098;',
        boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.12), inset 0px 1px 1px rgba(0, 0, 0, 0.04)'
    },
    pep_ioa_forms_input: {
        flex: 1,
    },
    pep_ioa_forms_iconButton: {
        padding: 10,
    },
    pep_ioa_forms_footer_text: {
        fontFamily: 'OpenSans',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#404040',
        paddingRight: '30px',
        marginLeft: '20px'
    },
    pep_ioa_forms_download_button: {
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 16px',
        gap: '10px',
        width: '120px',
        height: '30px',
        background: '#0084D5',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)',
        borderRadius: '64px',
        color: '#FFFFFF',
        marginLeft: '20px'
    },
    tableContainer: {
        display: 'block',
        marginBottom: '20px'
    },
    tableMain: {
        borderCollapse: 'collapse',
        width: '67%'
    },
    tableHeaderRow: {
        height: '48px',
        background: '#EDEDEF',
        boxShadow: '0 0 0 1px #E7E7ED',
        borderRadius: '8px 8px 0px 0px'
    },
    tableHeaderRowLeft: {
        borderRadius: '8px 0 0 0',
        paddingLeft: '10px',
        fontWeight: '600',
        fontSize: '14px'
    },
    tableHeaderRowRight: {
        borderRadius: '0 8px 0 0',
        paddingLeft: '10px',
        fontWeight: '600',
        fontSize: '14px'
    },
    tableBodyRow: {
        height: '64px',
        background: '#FFFFFF',
        border: '1px solid #E4E4E6'
    },
    tableDownloadButton: {
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 16px',
        gap: '10px',
        width: '111px',
        height: '30px',
        background: '#0F7BBD',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(33, 33, 49, 0.24)',
        borderRadius: '64px',
        color: '#FFFFFF'
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    paper: {
        width: '100%',
        marginTop: '16px',
        marginBotttom: '52px'
    },
    table: {
        minWidth: 750,
    },

    stickyHeader: {
        overflowX: "initial",
        zIndex: 2
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    footerIcon: {
        right: -15
    },
    footerCaption: {
        fontSize: '12px',
        fontWeight: 'normal'
    },
    tableFooterRow: {
        background: '#EDEDEF',
        border: '1px solid #E4E4E6',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px -1px 0px #E7E7ED',
        borderRadius: '0px 0px 8px 8px',
        position: "sticky",
        bottom: 0,
        zIndex: 2
    },
    paginationSpacer: {
        flex: 0
    }
}));