import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as CloseMark } from "admin/assets/common/CloseMark.svg";
import { ReactComponent as ExclamationMark } from "admin/assets/common/ExclamationMark.svg";
import { ReactComponent as SuccessMark } from "admin/assets/common/SuccessMark.svg";
import { hideNotification } from "admin/store/actions/snackbar";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { createSvgIcon } from "utils";
import { useStyles } from "./styles";
import { SnackbarsProps } from "./types";

const SnackbarContent = ({ snackbarKey, type, message, desc }: SnackbarsProps) => {
    const dispatch = useDispatch()
    const { closeSnackbar } = useSnackbar()
    const classes = useStyles()

    const ExclamationMarkIcon = createSvgIcon(ExclamationMark)
    const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        closeSnackbar(snackbarKey)
        dispatch(hideNotification())
    }
    const { user } = useSelector((store: AppReduxStore) => store);
    const { currentProfileRoleJoin } = user;
    const emailBodyString = (): string => {
        const providerProfile = currentProfileRoleJoin?.providerProfile;
        const practice = currentProfileRoleJoin?.practice;
        const middleName = providerProfile?.middleName ? ` ${providerProfile.middleName} ` : ` `;
        const fullName = `${providerProfile?.firstName}${middleName}${providerProfile?.lastName}`;
        const practiceName = practice?.name;
        const npi = providerProfile?.npi;
        const phone = currentProfileRoleJoin?.profilePrimaryPhone;
        const newLine = '%0d%0a';
        const fullNameStr = (providerProfile?.firstName && providerProfile?.lastName) ? `Full Name: ${fullName} ` : "";
        const practiceNameStr = practiceName ? `${newLine}Practice Name: ${practiceName} ` : "";
        const npiStr = npi ? `${newLine}NPI: ${npi} ` : "";
        const phoneStr = phone ? `${newLine}Best Contact Phone Number: ${phone}` : "";
        const bodyStrEmpty = (fullNameStr === "") && (practiceNameStr === "") && (npiStr === "") && (phoneStr === "");
        return (bodyStrEmpty ? "" : `&body=${fullNameStr}${practiceNameStr}${npiStr}${phoneStr}`);
    };

    return (
        <Box className={classes.snackbarWrapper}>
            {type === "error" &&
                <Box display="flex" bgcolor="#E32B29" alignItems="center" className={classes.sbError}>
                    <Box onClick={handleClose}>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="large">
                            <ExclamationMark />
                        </IconButton>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography className={classes.sbErrorText}>
                            {message}
                        </Typography>
                        <Typography className={classes.sbErrorDescText}>
                            {desc}
                        </Typography>
                        <Typography className={classes.sbErrorDescText}>
                            {`Contact Brown & Toland Service Desk for assistance at `}
                            <a href={`mailto:support@btmg.com?subject=Provider%20Report%20Issue%20Break%2FFix%20Incident${emailBodyString()}`} target="_top">support@btmg.com.</a>
                        </Typography>
                    </Box>
                    <Box onClick={handleClose}>
                        <IconButton
                            aria-label="close"
                            size="large"
                            color="inherit">
                            <CloseMark />
                        </IconButton>
                    </Box>
                </Box>
            }
            {type === "success" &&
                <Box display="flex" bgcolor="#00875E" alignItems="center" className={classes.sbSuccess}>
                    <Box onClick={handleClose}>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="large"
                        >
                            <SuccessMark />
                        </IconButton>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography className={classes.sbSuccessText}>
                            {message}
                        </Typography>
                    </Box>
                    <Box hidden={true}>
                        <IconButton
                            aria-label="close"
                            size="large"
                            color="inherit">
                            <CloseMark />
                        </IconButton>
                    </Box>
                </Box>}
            {type === "warning" &&
            <Box display="flex" bgcolor="#F5A800" alignItems="center" className={classes.sbWarning}>
                <Box onClick={handleClose}>
                    <IconButton
                        aria-label="close"
                        size="large"
                        color="inherit">
                        <ExclamationMarkIcon fill="#393948" />
                    </IconButton>
                </Box>
                <Box flexGrow={1}>
                    <Typography className={classes.sbWarningText}>
                        {message}
                    </Typography>
                    <Typography className={classes.sbWarningDescText}>
                        {desc}
                    </Typography>
                </Box>
                <Box onClick={handleClose}>
                    <IconButton
                        aria-label="close"
                        size="large"
                        color="inherit">
                        <CloseMark />
                    </IconButton>
                </Box>
            </Box>}
        </Box>
    )
}

export default SnackbarContent