import React from "react";
import { FormattedMessage } from "react-intl";
import { useRequestInfo } from "./use-request-info";
import { EConsultRequest } from "./types";

const DetailsBanner = ({ eConsultRequest }: EConsultRequest) => {
    const {status, recipientStatus, isSentRequest, assignedByOther } = useRequestInfo(eConsultRequest);

    let message: JSX.Element | undefined;

    if (recipientStatus === "REJECTED") {
        message = <FormattedMessage id="EConsult.request.content.rejectedMessage" />;
    } else if (status === "EXPIRED") {
        message = <FormattedMessage id="EConsult.request.content.expiredMessage" />;
    } else if (status === "CLOSED") {
        message = (
            <>
                <FormattedMessage id="EConsult.request.content.closedMessage" />
                {!isSentRequest && (
                    <div>
                        <FormattedMessage
                            id="EConsult.request.content.closedManuallyByMessage"
                            values={{ name: eConsultRequest.referrer.providerName }}
                        />
                    </div>
                )}
            </>
        );
    } else if (status === "CANCELLED") {
        message = (
            <>
                <FormattedMessage id="EConsult.request.content.cancelledMessage" />
                {!isSentRequest && (
                    <div>
                        <FormattedMessage
                            id="EConsult.request.content.cancelledManuallyByMessage"
                            values={{ name: eConsultRequest.referrer.providerName }}
                        />
                    </div>
                )}
            </>
        );
    } else if (assignedByOther) {
        message = <FormattedMessage id="EConsult.request.content.claimedByOtherMessage" />;
    }

    return message ? <div className="dangerInfo">{message}</div> : null;
};

export default DetailsBanner;
