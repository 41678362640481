import React from "react";
import { Grid, Typography } from "@mui/material";
import Controls from "admin/components/Controls/Controls";
import { US_STATES } from "admin/constant";
import { useStyles } from './styles';
import { FormattedMessage, useIntl } from "react-intl";
import { createSvgIcon } from "utils";
import { ReactComponent as Buildings } from "icon-library/SVG/Buildings.svg";

// @ts-ignore
const { NUNA_ROLE } = window.env;

const AddOrgInfo = (props: any) => {
    const classes = useStyles();
    const intl = useIntl()
    const nunaRoleArray = [{ id: NUNA_ROLE, title: NUNA_ROLE }]
    const BuildingIcon = createSvgIcon(Buildings);

    const { handleOrgInfoInputChange, orgInfoFormData, errorsOrgInfo, haveOrgParent } = props;
    // orgInfoFormData.nunaRole = NUNA_ROLE
    return (
        <>
            <Grid container>
                <Grid item className={classes.pageHeader}>
                    <Typography className={classes.pageHeaderText}>
                        <BuildingIcon fill="#0F7BBD" className={classes.editIcon} />
                        <FormattedMessage id={haveOrgParent ? "AddOrgInfo.Header.SubOrganizationInfo" : "AddOrgInfo.Header.OrganizationInfo"} />
                    </Typography>
                    <Typography>
                        <FormattedMessage id={haveOrgParent ? "AddOrgInfo.Header.SubOrganizationInfoDesc" : "AddOrgInfo.Header.OrganizationInfoDesc"} />
                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            <FormattedMessage id={"AddOrgInfo.Header.OrganizationName"} />
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                error={errorsOrgInfo.organizationName}
                                id="standard-required"
                                name="organizationName"
                                label={`${haveOrgParent ? "Subo" : "O"}rganization Name *`}
                                onChange={handleOrgInfoInputChange}
                                placeholder="Enter name here"
                                value={orgInfoFormData.organizationName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            <FormattedMessage id={"AddOrgInfo.Header.OrganizationAddress"} />
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item spacing={2} xs={4}>
                            <Controls.Input
                                name="addressOne"
                                label="Address 1"
                                onChange={handleOrgInfoInputChange}
                                placeholder="Enter Here"
                                value={orgInfoFormData.addressOne}
                                error={errorsOrgInfo.addressOne}
                            />
                        </Grid>
                        <Grid item spacing={2} xs={4}>
                            <Controls.Input
                                name="addressTwo"
                                label="Address 2"
                                onChange={handleOrgInfoInputChange}
                                placeholder="Enter Here"
                                value={orgInfoFormData.addressTwo}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="city"
                                label="City *"
                                onChange={handleOrgInfoInputChange}
                                placeholder="Enter Here"
                                value={orgInfoFormData.city}
                                error={errorsOrgInfo.city}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Select
                                name="state"
                                label="State *"
                                placeholder="Select"
                                onChange={handleOrgInfoInputChange}
                                options={US_STATES}
                                value={orgInfoFormData.state}
                                error={errorsOrgInfo.state}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Input
                                name="zip"
                                label="Zip *"
                                onChange={handleOrgInfoInputChange}
                                placeholder="Enter Here"
                                value={orgInfoFormData.zip}
                                error={errorsOrgInfo.zip}
                                maxLength={10}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            <FormattedMessage id={"AddOrgInfo.Header.OrganizationOtherInfo"} />
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Select
                                name="nunaRole"
                                label="Nuna Role"
                                onChange={handleOrgInfoInputChange}
                                options={nunaRoleArray}
                                value={orgInfoFormData.nunaRole}
                                error={errorsOrgInfo.nunaRole}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="absorbDepartmentId"
                                label={intl.formatMessage({ id: "AddOrgInfo.Lebel.AbsorbDepartmentID" })}
                                placeholder="Enter Here"
                                value={orgInfoFormData.absorbDepartmentId}
                                onChange={handleOrgInfoInputChange}
                                error={errorsOrgInfo.absorbDepartmentId}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AddOrgInfo;
