import {
    Box, Paper,
    Table, TableBody, TableCell, TableContainer,
    TableHead, Grid, CardContent, Card,
    TableRow, Typography, Button
} from "@mui/material";
import { fetchAppointmentTypes, fetchCancellationTypes, fetchWaitingRoom } from "admin/store/actions/configuration";
import { AppReduxStore } from "admin/store/reducerTypes";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { showNotification } from "admin/store/actions/snackbar";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import WaitingRoomTile from "admin/components/WaitingRoomTile/WaitingRoomTile";
import Controls from "admin/components/Controls/Controls";
import { ReactComponent as InfoSvg } from "icon-library/SVG/Info_Circle.svg";
import { ReactComponent as CrossSvg } from "icon-library/SVG/Cross.svg";
import { createSvgIcon } from "utils";
import RichTooltip from "admin/components/RichToolTip/RichTooltip";
import { ReactComponent as FlagSvg } from "icon-library/SVG/Flag.svg";
const FlagIcon = createSvgIcon(FlagSvg);
const InfoIcon = createSvgIcon(InfoSvg);
const CrossIcon = createSvgIcon(CrossSvg);

interface Data {
    id: string
    name: string
    displayName: string
    description: string
    durationMinutes: any
    ehrVisitTypeId: string
}

interface cancellationData {
    id: string
    name: string
    description: string
    ehrCancelReasonId: string
}

const EVisits = () => {
    const intl = useIntl()
    const { auth, configuration } = useSelector((state: AppReduxStore) => state)
    const visitTypesData = configuration && configuration.visitTypes ? configuration.visitTypes : []
    const cancellationTypesData = configuration && configuration.cancellationTypes ? configuration.cancellationTypes : []
    const waitingRoomAPIData = configuration && configuration.waitingRoom ? configuration.waitingRoom : {}
    const classes = useStyles();
    const dispatch = useDispatch()
    const [isReadyForUse, setReadyForUse] = useState(false)
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (auth.currentOrg || auth.currentPractice) {
            dispatch(fetchAppointmentTypes())
            dispatch(fetchCancellationTypes())
            dispatch(fetchWaitingRoom())
        }
    }, [auth.currentPractice, dispatch]);

    useEffect(() => {
        if (auth.currentPractice) {
            const configSetupFlag = (configuration.visitTypes?.length >= 0 && configuration.cancellationTypes?.length > 0)
            setReadyForUse(configSetupFlag)
        }
    }, [configuration.visitTypes?.length, configuration.cancellationTypes?.length]);

    const [waitingRoomFormsData] = useState([{
        title: "Fill out PHQ-9 Form",
        dueDate: "Due today",
        link: "#",
        name: "PHQ-9"
    }, {
        title: "Set previsit Agenda",
        dueDate: "Due today",
        link: "#",
        name: "Previous Agenda"
    }]);

    const [waitingroomData] = useState({
        name: "eVisit",
        waitingMessage: "While you wait, please take time to fill out the following form prior to the visit",
        waitingRoomForms: waitingRoomFormsData
    });
    const handleEditNowClickAction = () => {
        handleTooltipClose()
        if (configuration.visitTypes.length === 0) {
            handleEditAction('evisits');
        }
        else if (configuration.cancellationTypes.length === 0) {
            handleEditAction('cancellations');
        }
        else if (Object.keys(configuration.waitingRoom).length === 0) {
            handleEditAction('waitingroom');
        }
    }
    const handleEditAction = (type: string) => {
        switch (type) {
            case 'evisits':
                dispatch(setActiveModal(MODAL_TYPES.EDIT_EVISIT_TYPES))
                break;
            case 'cancellations':
                dispatch(setActiveModal(MODAL_TYPES.EDIT_CANCELLATION_TYPES))
                break;
            case 'waitingroom':
                dispatch(setActiveModal(MODAL_TYPES.EDIT_WAITING_ROOM))
                break;
            default:
                dispatch(showNotification("TODO", "error", "Not Implemented"))
        }

    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const borders = {
        Active: '2px solid #00A875',
        Pending: '2px solid #F5A800',
        Inactive: '2px solid #E32B29',

    }
    return (
        <Box>
            <Grid container>
                <Grid item xs={8}>
                    <Typography noWrap variant={"h3"} className={classes.eVisits}>{intl.formatMessage({ id: "EVisits.InfoHeader.Heading" })}
                        <label style={{ border: isReadyForUse ? borders["Active"] : borders["Inactive"], padding: '2px 4px', fontSize: '14px', fontWeight: 600, borderRadius: '4px', marginLeft: '32px', textAlign: 'center' }}> {isReadyForUse ? "Ready for use" : "Configuration Required"}</label>
                    </Typography>
                    <Typography noWrap variant={"h6"} className={classes.eVisits}>{intl.formatMessage({ id: "EVisits.InfoHeader.InfoText" })}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.textAlignRight}>
                    <Box>
                        <Box display={"inline-flex"} mb={1} mt={1} textAlign="right">
                            <Typography noWrap variant={"h3"} className={classes.infoText} style={{ marginRight: '37px' }}>{intl.formatMessage({ id: "EVisits.InfoHeader.Heading" })}
                            </Typography>

                            <RichTooltip content={
                                <Box textAlign="right">
                                    <Box>
                                        <CrossIcon fill={"#FFFFFF"} className={classes.infoIcon} onClick={handleTooltipClose} />
                                    </Box>
                                    <Typography className={classes.infoTooltipText}>
                                        Please add <b>Visit Types</b> and <b>Cancellation Types</b> before activating this product.
                                    </Typography>
                                    <Button id="btnEditVisits" variant="contained" color="primary" style={{ marginTop: '10px' }} onClick={handleEditNowClickAction}>
                                        {intl.formatMessage({ id: "EVisits.EditNow.ButtonText" })}
                                    </Button>
                                </Box>
                            }
                                open={open}
                                onClose={() => setOpen(false)}
                                placement="bottom">
                                <label><InfoIcon fill={"#0F7BBD"} className={classes.infoIcon} onClick={handleTooltipOpen} /></label>
                            </RichTooltip>
                        </Box>
                    </Box>
                    <Box display="-webkit-inline-box" mb={1} mt={1}>
                        <Controls.ToggleSwitch large={true}
                            label={""}
                            checked={isReadyForUse}
                            value={""}
                            disabled={!isReadyForUse}
                        />
                        <Typography noWrap variant={"h6"} className={classes.toggleSwitchText} style={{ color: isReadyForUse ? '#393948' : '#909098' }}>
                            {intl.formatMessage({ id: "EVisits.Text.ReadyForUse" })}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={8}>
                    <Typography noWrap variant={"h3"} className={classes.eVisits}>{intl.formatMessage({ id: "EVisits.Heading.VisitTypes" })}</Typography>
                    <Typography noWrap variant={"h6"} className={classes.eVisits}>{intl.formatMessage({ id: "EVisits.Text.VisitTypes" })}</Typography>
                    {configuration.visitTypes?.length === 0 && <Typography noWrap variant={"h2"} className={classes.infoMessage}><FlagIcon fill={"#FF0000"} className={classes.flagIcon} />Required before using!</Typography>}
                </Grid>
                <Grid item xs={4} className={classes.textAlignRight}>
                    <Button id="btnEditVisits" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => handleEditAction('evisits')}>
                        {intl.formatMessage({ id: "EVisits.Action.EditVisitTypes" })}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Paper className={classes.paper}>
                    <TableContainer>
                        <Table
                            stickyHeader
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        key={"ehrVisitTypeId"}
                                        align={'left'}
                                        padding="normal"
                                        style={{ paddingLeft: '48px' }}>
                                        <strong>
                                            {'EHR Visit Type ID'}
                                        </strong>
                                    </TableCell>
                                    <TableCell style={{ paddingLeft: '48px' }}
                                        key={"name"}
                                        align={'left'}
                                        padding={'none'}>
                                        <strong>
                                            {'EHR Visit Name'}
                                        </strong>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visitTypesData
                                    .map((row: Data) => {
                                        return (
                                            <TableRow key={row.id}>
                                                <TableCell style={{ paddingLeft: '48px' }}>
                                                    <Box> {row.ehrVisitTypeId}</Box>
                                                </TableCell>
                                                <TableCell align="left" style={{ paddingLeft: '48px' }}>
                                                    <Typography noWrap variant="body1">{row.name}</Typography>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            <Grid container>&nbsp;</Grid><Grid container>&nbsp;</Grid>
            <Grid container>
                <Grid item xs={8}>
                    <Typography noWrap variant={"h3"} className={classes.eVisits}>{intl.formatMessage({ id: "EVisits.Heading.CancellationTypes" })}</Typography>
                    <Typography noWrap variant={"h6"} className={classes.eVisits}>{intl.formatMessage({ id: "EVisits.Text.CancellationTypes" })}</Typography>
                    {configuration.cancellationTypes?.length === 0 && <Typography noWrap variant={"h2"} className={classes.infoMessage}><FlagIcon fill={"#FF0000"} className={classes.flagIcon} />Required before using!</Typography>}
                </Grid>
                <Grid item xs={4} className={classes.textAlignRight}>
                    <Button id="btnCancellationTypes" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => handleEditAction('cancellations')}>
                        {intl.formatMessage({ id: "EVisits.Action.EditCancellationTypes" })}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Paper className={classes.paper}>
                    <TableContainer>
                        <Table
                            stickyHeader
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        key={"ehrCancelReasonId"}
                                        align={'left'}
                                        padding="normal">
                                        <strong>
                                            {'EHR Cancellation Type ID'}
                                        </strong>
                                    </TableCell>
                                    <TableCell style={{ paddingLeft: '48px' }}
                                        key={"name"}
                                        align={'left'}
                                        padding={'none'}>
                                        <strong>
                                            {'EHR Cancellation Name'}
                                        </strong>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cancellationTypesData
                                    .map((row: cancellationData) => {
                                        return (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Box> {row.ehrCancelReasonId}</Box>
                                                </TableCell>
                                                <TableCell align="left" style={{ paddingLeft: '48px' }}>
                                                    <Typography noWrap variant="body1">{row.name}</Typography>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            <Grid container>&nbsp;</Grid><Grid container>&nbsp;</Grid>
            <Grid container>
                <Grid item xs={8}>
                    <Typography noWrap variant={"h3"} className={classes.eVisits}>{intl.formatMessage({ id: "EVisits.Heading.WaitingRoom" })}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.textAlignRight}>
                    <Button id="btnWaitingRoom" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={() => handleEditAction('waitingroom')}>
                        {intl.formatMessage({ id: "EVisits.Action.EditWaitingRoom" })}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <Typography noWrap variant={"h6"} className={classes.waitingRoomTitle}>{intl.formatMessage({ id: "EVisits.Heading.WaitingRoom" })}</Typography>
                    <Grid item xs={12}>
                        <WaitingRoomTile name={waitingroomData.name} practiceMessage={waitingRoomAPIData.greeting ?? intl.formatMessage({ id: "WaitingRoom.Text.DefaultPracticeMessage" })} waitingMessage={waitingroomData.waitingMessage} waitingRoomForms={waitingroomData.waitingRoomForms} />
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Typography noWrap variant={"h6"} className={classes.waitingRoomTitle}>{intl.formatMessage({ id: "EVisits.Text.EVisitStarting" })}</Typography>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                {waitingRoomAPIData.logoUrl ?
                                    <Grid container className={classes.logoContent}>
                                        <img src={`${waitingRoomAPIData.logoUrl}?${Date.now()}`} alt="Logo" style={{ maxWidth: "178px", maxHeight: "150px" }} crossOrigin="anonymous" />
                                    </Grid> :
                                    <Grid container className={classes.content}>
                                        <Typography noWrap variant={"h6"} className={classes.waitingRoomTitle}>{intl.formatMessage({ id: "EVisits.Text.LogoGoesHere" })}</Typography>
                                    </Grid>
                                }
                            </CardContent>
                            <CardContent className={classes.cardContent}>
                                <Typography noWrap variant={"h4"} className={classes.evisitsStartingContent}>{intl.formatMessage({ id: "EVisits.Text.EVisitStarting" })}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EVisits