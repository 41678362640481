import React from "react";
import { Switch, Redirect } from 'react-router-dom';
import TopHeader from "admin/components/Layout/TopHeader";
import LayoutMain from "admin/components/Layout/LayoutMain";
import { SecureRoute } from "authentication/app-secure-route";
import * as modals from "admin/components/Modals";
import { map } from 'underscore'
import LoadingIndicator from 'admin/components/LoadingIndicator/LoadingIndicator';
import AppWrapper from './views/AppWrapper/AppWrapper';
import { useSelector } from 'react-redux';
import { AppReduxStore } from 'admin/store/reducerTypes';
import { USER_LEVELS } from './constant';
import AdminAhaHome from "admin/views/AdminAhaHome/AdminAhaHome";
import UserListing from "admin/views/Users/UserListing/UserListing";
import LocationListing from "admin/views/Locations/LocationListing/LocationListing";
import PatientListing from "admin/views/Patients/PatientListing/PatientListing";
import UserDetail from "admin/views/Users/UserDetail/UserDetail";
import { OrganizationDetail } from "admin/views/Organizations/OrganizationDetail/OrganizationDetail";
import { PracticeDetail } from "admin/views/Practices/PracticeDetail/PracticeDetail";
import LocationDetail from "admin/views/Locations/LocationDetail/LocationDetail";
import PatientDetails from 'admin/views/Patients/PatientDetails/PatientDetails';
import FeeSchedulesDashboard from 'admin/views/FeeSchedules/FeeSchedulesDashboard/FeeSchedulesDashboard';
import FeeSchedulesListing from 'admin/views/FeeSchedules/FeeSchedulesListing/FeeSchedulesListing';
import FeeScheduleConfig from 'admin/views/FeeSchedules/FeeScheduleConfig/FeeScheduleConfig';
import FeeScheduleDetails from 'admin/views/FeeSchedules/FeeScheduleDetails/FeeScheduleDetails';
import FeeScheduleGroupsListing from 'admin/views/FeeSchedules/FeeScheduleGroupsListing/FeeScheduleGroupsListing';
import OrganizationListing from './views/Organizations/OrganizationListing/OrganizationListing';
import ConfigurationListing from "admin/views/Configurations/ConfigurationListing/ConfigurationListing";
import PatientFormsListing from 'admin/views/Configurations/PatientFormsListing/PatientFormsListing';
import PatientFormConfig from "admin/views/Configurations/PatientFormsListing/PatientFormConfig/PatientFormConfig";
import RegistrationList from "admin/views/Configurations/RegistrationList/RegistrationList";
import eVisits from 'admin/views/Configurations/EVisits/EVisits';
import Settings from 'views/Settings/Settings';

const AdminApp = () => {
    const { auth } = useSelector((state: AppReduxStore) => state)
    const ALTAIS_ADMINS = [USER_LEVELS.ALTAIS_ADMIN_1, USER_LEVELS.ALTAIS_ADMIN_2, USER_LEVELS.ALTAIS_ADMIN_3]
    const ORG_ADMINS = [USER_LEVELS.ORG_ADMIN_1, USER_LEVELS.ORG_ADMIN_2]
    const PRACTICE_ADMINS = [USER_LEVELS.PRACTICE_ADMIN]
    const BILLING_ADMINS = [USER_LEVELS.BILLING_ADMIN];
    const { currentProfileRoleJoinInfo } = auth
    const { level } = currentProfileRoleJoinInfo || {}
    const { name } = level || {}
    const altaisAndOrgAdmins = (ALTAIS_ADMINS.includes(name) || ORG_ADMINS.includes(name))
    const practiceAdmin = PRACTICE_ADMINS.includes(name)
    const billingAdmin = BILLING_ADMINS.includes(name);
    const authorizedAdmins = (practiceAdmin || billingAdmin || altaisAndOrgAdmins);

    return (
        <AppWrapper>
            <Switch>
                <SecureRoute exact path="/admin">
                    <Redirect exact to="/admin/home" />
                </SecureRoute>
                <SecureRoute exact path="/admin/aha" component={AdminAhaHome} />
                <LayoutMain
                    hideAppBreadcrumbs={billingAdmin}
                    rightTopHeader={(practiceAdmin || billingAdmin || altaisAndOrgAdmins) && <TopHeader />}
                    unauthorized={name && !authorizedAdmins}>
                    <SecureRoute exact path="/admin/settings" component={Settings} />
                    {auth.currentOrg && <SecureRoute exact path="/admin/entities" component={OrganizationListing} />}
                    {auth.currentOrg && <SecureRoute exact path="/admin/home" component={OrganizationDetail} />}
                    {!auth.currentOrg && billingAdmin && <SecureRoute exact path="/admin/home" component={FeeSchedulesDashboard} />}
                    {auth.currentOrg && <SecureRoute exact path="/admin/entities/details" component={OrganizationDetail} />}

                    {(practiceAdmin || altaisAndOrgAdmins) && <SecureRoute exact path="/admin/entities/practice-details" component={PracticeDetail} />}
                    {(practiceAdmin || altaisAndOrgAdmins) && <SecureRoute exact path="/admin/users" component={UserListing} />}
                    {(practiceAdmin || altaisAndOrgAdmins) && <SecureRoute exact path="/admin/users/details" component={UserDetail} />}
                    {(practiceAdmin || altaisAndOrgAdmins) && <SecureRoute exact path="/admin/locations" component={LocationListing} />}
                    {(practiceAdmin || altaisAndOrgAdmins) && <SecureRoute exact path="/admin/locations/details" component={LocationDetail} />}


                    {auth.currentPractice && <SecureRoute exact path="/admin/home" component={PracticeDetail} />}
                    {auth.currentPractice && <SecureRoute exact path="/admin/patients" component={PatientListing} />}
                    {auth.currentPractice && <SecureRoute exact path="/admin/patients/details" component={PatientDetails} />}                    
                    {(altaisAndOrgAdmins || billingAdmin || auth.currentPractice) && <SecureRoute exact path="/admin/feeschedules" component={FeeSchedulesListing} />}
                    {(altaisAndOrgAdmins || billingAdmin || auth.currentPractice) && <SecureRoute exact path="/admin/feeschedules/config" component={FeeScheduleConfig} />}
                    {(altaisAndOrgAdmins || billingAdmin || auth.currentPractice) && <SecureRoute exact path="/admin/feeschedules/config/:id" component={FeeScheduleConfig} />}
                    {(altaisAndOrgAdmins || billingAdmin || auth.currentPractice) && <SecureRoute exact path="/admin/feeschedules/details/:id" component={FeeScheduleDetails} />}
                    {(altaisAndOrgAdmins || billingAdmin || auth.currentPractice) && <SecureRoute exact path="/admin/feeschedules/groups" component={FeeScheduleGroupsListing} />}
                    {auth.currentPractice && <SecureRoute exact path="/admin/configurations" component={ConfigurationListing} />}
                    {auth.currentPractice && <SecureRoute exact path="/admin/configurations/patientforms" component={PatientFormsListing} />}
                    {auth.currentPractice && <SecureRoute path="/admin/configurations/patientforms/:id" component={PatientFormConfig} />}
                    {auth.currentPractice && <SecureRoute exact path="/admin/configurations/evisit" component={eVisits} />}
                    {auth.currentPractice && <SecureRoute exact path="/admin/configurations/registrations" component={RegistrationList} />}
                    {!auth.currentPractice && altaisAndOrgAdmins &&
                        <SecureRoute exact path="/admin/configurations/registrations">
                            <Redirect exact to="/admin/home" />
                        </SecureRoute>
                    }
                </LayoutMain>
                <Redirect to="/sprofile" />
            </Switch>
            {map(modals, (Modal: any, idx) => <Modal key={idx} />)}
            <LoadingIndicator />
        </AppWrapper>
    )
}

export default AdminApp;
