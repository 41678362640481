import { useEffect, useState, useCallback } from "react";
import ProgressManager from "./progress-manager";

type useBusyStateHook = {
    busyState: boolean;
    setBusy: (busy: boolean) => void;
};

export const useBusyState = (id: string): useBusyStateHook => {
    const [busyState, setBusyState] = useState<boolean>(ProgressManager.getBusy(id));
    const listener = (busy: boolean) => {
        setBusyState(busy);
    };
    const setBusy = useCallback(
        (busy: boolean) => {
            ProgressManager.setBusy(id, busy);
            // setBusyState is called indirectly via listener
        },
        [id]
    );

    useEffect(() => {
        ProgressManager.addEventListener(id, listener);
        setBusyState(ProgressManager.getBusy(id));
        return () => {
            ProgressManager.removeEventListener(id, listener);
        };
    }, [id]);

    return {
        busyState,
        setBusy,
    };
};
