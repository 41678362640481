import React from "react";
import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {map} from "underscore"
import {PASSWORD_VALIDATIONS, PASSWORD_VALIDATION_TYPES, PASSWORD_VALIDATION_DESCRIPTIONS} from "constant";
import {createSvgIcon} from "utils";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import {ReactComponent as CrossSvg} from "icon-library/SVG/Cross_Circle.svg";
import {ReactComponent as CheckSvg} from "icon-library/SVG/Radio_Circle.svg";
import {useStyles} from "./styles";
import {checkPasswordHasPersonal} from "../utils";
import {COLORS} from "theme";

const Cross = createSvgIcon(CrossSvg)
const Check = createSvgIcon(CheckSvg)

// PasswordValidation displays to the user if the password they are entering follows the okta guidelines
const PasswordValidation = ({ password,  email, passwordConfirm} : { password: string, email:string, passwordConfirm:string }) => {
    const classes = useStyles()

    const PassIcon = <Check fill={COLORS.PASS_ICON} className={classes.icon} />
    const FailIcon = <Cross fill={COLORS.FAIL_ICON} className={classes.icon} />

    return (
        <Box>
            <CustomTypography className={classes.header} variant="largeHeader">
                <FormattedMessage id="PasswordValidation.heading.passwordValidation" />
            </CustomTypography>
            <CustomTypography className={classes.header} variant="largeSubheader">
                <FormattedMessage id="ResetPasswordValidation.subheading.criteria" />
            </CustomTypography>
            <Box className={classes.validationWrapper}>
                <Box className={classes.validationRow}>
                    {passwordConfirm&&password&&passwordConfirm===password ? PassIcon : FailIcon  }
                    <CustomTypography variant="largeSubheader">
                        <FormattedMessage id="ResetPasswordValidation.error.passwordMatch" />
                    </CustomTypography>
                </Box>
                <Box className={classes.validationRow}>
                    {password&&email&&checkPasswordHasPersonal(password,email) ? PassIcon : FailIcon  }
                    <CustomTypography variant="largeSubheader">
                        <FormattedMessage id="ResetPasswordValidation.error.hasPersonalName" />
                    </CustomTypography>
                </Box>
                {map(PASSWORD_VALIDATION_TYPES, (type) => {
                    return (
                        <Box key={type} className={classes.validationRow}>
                            {PASSWORD_VALIDATIONS[type].test(password) ? PassIcon : FailIcon}
                            <CustomTypography variant="largeSubheader">
                                <FormattedMessage id={PASSWORD_VALIDATION_DESCRIPTIONS[type]} />
                            </CustomTypography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default PasswordValidation