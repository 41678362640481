import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    headerWrapper: {
        padding: SIZING.scale600,
        display: "flex",
        alignItems: "center",
        flexDirection:'column',
        background: COLORS.LEARNING_CENTER_HEADER_BG,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
    },
    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width:'100%'
    },
    desc:{
        fontSize:SIZING.scale400,
        fontWeight:600,
        color:COLORS.GREY,
        marginTop:SIZING.scale200
    },
    close: {
        cursor: 'pointer'
    },
    send:{
        alignSelf:'flex-end',
        marginTop:SIZING.scale300,
        background:COLORS.DEFAULT_BTN,
        "&:hover":{
        background:COLORS.DEFAULT_BTN + '!important' ,
        color:COLORS.WHITE
        },
        color:COLORS.WHITE
    },
    contentWrapper:{
        background:COLORS.PNG_MODAL_BG,
        minHeight:'600px',
        paddingLeft:'0',
        paddingRight:'0',
        "& .custom-multiselect-wrapper":{
            marginTop:SIZING.scale300,
        }
    },
    inputWrapper:{
        padding:`0 ${SIZING.scale400}`,
        "& .MuiInput-input":{
            color:"#000000",
            "&::placeholder": {
                color: "#6E6E79",
            },
        },
    },
    contentChild:{
        paddingLeft:SIZING.scale400,
        paddingRight:SIZING.scale400
    },
    patientTitle:{
       
    },
    divider:{
        height:'1px',
        width:'100%',
        background:'#999999',
        margin:`${SIZING.scale700} 0`,
    },
    inputElement:{
            width:'100%',
        "& .MuiOutlinedInput-input":{
            padding:0,
            minHeight:SIZING.scale1000,
            color:"#000000",
            "&::placeholder": {
                color: "#6E6E79",
            },

        }
    },
    providerSelectTitle:{
        marginTop:SIZING.scale100,
        opacity:({ currentProvider }:any) => currentProvider?'0.4':'unset'
    },
    dateSelectTitle:{
        marginTop:SIZING.scale700,
        marginBottom:SIZING.scale400,
    },
    checkBoxWrapper:{
        display:'flex',
        marginTop: SIZING.scale300,
        alignItems:'flex-start',
        "& .MuiFormControlLabel-root":{
            marginLeft:'unset',
            width:'5.563rem',
        },
        "& .MuiInputBase-input.Mui-disabled":{
            background:COLORS.PNG_MODAL_BG,
            color:"currentColor"
        },
        "& .checkboxInput":{
            flex:1
        }
    },
    preview:{
        fontWeight:'normal',
        marginBottom:SIZING.scale300
    },
    error: {
        color: COLORS.ERROR_TEXT,
        marginLeft:SIZING.scale400,
        marginTop:SIZING.scale100,
        display:'flex',
        alignItems:'center',
        "& .MuiSvgIcon-root":{
            width:SIZING.scale400,
            marginRight:SIZING.scale100,
        }
    },
    searchIcon:{
        "& .custom-multiselect-input-wrapper":{
            "& svg path":{
                fill:COLORS.LBLUE
            }
        }
    },
    providerSearch:{
        opacity:({ currentProvider }:any) => currentProvider?'0.4':'unset',
        pointerEvents: ({ currentProvider }:any) => currentProvider?'none':'unset'
    },
    providerCheckbox:{
        marginTop:SIZING.scale700,
       "& span":{
        fontSize:SIZING.scale350
       }
        
    },
}));