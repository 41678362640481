import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { values } from "underscore";
import { fetchMessages, loadPreviousMessagePage, markConversationRead } from "store/actions";
import { AppReduxStore } from "store/reducerTypes";
import { messagingConversationFetchingBusyStateName } from "./constants";
import ChatMessage from "views/Messages/Chat/ChatMessage/ChatMessage";
import { useBusyState } from "common/utils/progress/progress-manager-hooks";
import { useStyles } from "./styles";
import { usePrevious } from "utils";
import NewMessageDivider from "components/messaging/chat-history/new-message-divider";
import Spinner from "common/components/Spinner/Spinner";
import TypingParticipantsInfo from "components/messaging/chat-history/typing-participants-info";
import useCurrentUser from "common/utils/use-current-user";
import { Message } from "@twilio/conversations";
import MessageDate from "common/components/MessageDate/MessageDate";
import { Typography } from "@mui/material";

type ChatHistoryProps = {
    conversationId: string
}

// ChatHistory shows the list of messages for a conversation by twilio message page. As the user scrolls up a new page
// will load until the there are no more conversations
const ChatHistory = ({ conversationId }: ChatHistoryProps) => {
    const { busyState } = useBusyState(messagingConversationFetchingBusyStateName);
    const dispatch = useDispatch()
    const classes = useStyles()
    const bottomRef = useRef<HTMLDivElement>(null)
    const chatRef = useRef<HTMLDivElement>(null)
    const newMessageRef = useRef<HTMLDivElement>(null)
    const [scrollAtBottom, setScrollAtBottom] = useState(true)
    const [height, setHeight] = useState(0)
    const { twilioUsername } = useCurrentUser();
    const { conversations, conversationsInfo, messages, participantsTyping } = useSelector((state: AppReduxStore) => state.chat)
    const prevProps = usePrevious({ messages, conversationId })
    const lastReadIdx = conversations.hasOwnProperty(conversationId)
        ? conversations[conversationId].lastReadMessageIndex
        : null;
    const openConversationInfo = conversationsInfo?.[conversationId];

    // used to determine where the user is scrolled to. Loading a new page if they are at the top and locking the scroll
    // to the bottom if they are at the bottom
    const handleScroll = (e: any) => {
        const { clientHeight, scrollHeight, scrollTop } = e.target
        let atBottom = false
        // Scrolled to bottom
        if (scrollHeight - scrollTop === clientHeight) {
            atBottom = true
            // Scrolled to top
        } else if (scrollTop === 0) {
            dispatch(loadPreviousMessagePage())
        }
        if (atBottom !== scrollAtBottom) {
            setScrollAtBottom(atBottom)
        }
    }


    // Scroll to the bottom of the chat when new messages load
    useEffect(() => {
        const hasNewMessages = prevProps?.messages.length !== messages.length;
        const isDifferentConversation = prevProps?.conversationId !== conversationId;
        // FIXME: This should be checked in the messages, if the missing unified messaging model is implemented
        const isLastMessageByCurrentUser = messages.length ? messages[messages.length - 1]?.author === twilioUsername : false;
        const el = chatRef.current;

        /* APOLLO-1064: If there are new messages and last message is by the user theirself then view is scrolled down
         * to bottom to display the last message sent by the user.
         * FIXME: Even if there are still unread messages, which is caused by marking a conversation unread only when
         * navigating away. This should be revised and changed in the future. See chat.ts#markConversationRead().
         */
        if (hasNewMessages && isLastMessageByCurrentUser) {
            bottomRef.current?.scrollIntoView();
            setHeight(el?.scrollHeight || 0);
        } else
            /* If messages changed or a new conversation is loaded then update scroll position if the user is already at
             * the bottom. It can be changed to the first new message (if new message received) or to the bottom of the 
             * screen. */
            if ((hasNewMessages || isDifferentConversation) && scrollAtBottom) {
                if (openConversationInfo?.unreadCount) {
                    newMessageRef.current?.scrollIntoView();
                } else {
                    bottomRef.current?.scrollIntoView();
                }
                setHeight(el?.scrollHeight || 0);
            } else
                if (hasNewMessages) {
                    if (el) {
                        el.scrollTop += el?.scrollHeight - height;
                    }
                }
    }, [
        height,
        prevProps?.messages.length,
        prevProps?.conversationId,
        scrollAtBottom,
        messages,
        conversationId,
        openConversationInfo?.unreadCount,
        twilioUsername
    ]);


    // Load Messages; on end mark conversation as read
    useEffect(() => {
        dispatch(fetchMessages(conversationId))

        return () => {
            dispatch(markConversationRead(conversationId))
        }
    }, [dispatch, conversationId])

    if (!messages) {
        return null
    }
    if (busyState) {
        return <Spinner />;
    }
    const typingParticipants = values(participantsTyping[conversationId]);
    const separatedMessages = messages.reduce((msgs, message) => {
        const read = lastReadIdx !== null && lastReadIdx >= message.index;
        if (read) {
            msgs.read.push(message);
        } else {
            msgs.new.push(message);
        }
        return msgs;
    }, {
        read: [] as Message[],
        new: [] as Message[]
    });
    const renderMessages = (messages: Message[], read: boolean): JSX.Element[] => messages.map((message: any, index: number) => {
        const ownMessage = message.author === twilioUsername;
        const date = message?.dateCreated
        let dateDifference = false
        if (index > 0) {
            const prevDate = (messages[index - 1].dateCreated)
            dateDifference = prevDate?.getDate() !== date?.getDate()
        }

        const shouldRenderSubheader = index === 0 || dateDifference
        return (<>
            {shouldRenderSubheader &&
                <Typography className={classes.subHeader}>
                    <MessageDate date={date} />
                    <hr className={classes.hr}></hr>
                </Typography>
            }
            <ChatMessage
                key={message.sid}
                conversationInfo={openConversationInfo}
                message={message}
                read={read}
                ownMessage={ownMessage} />
        </>);
    });

    return (
        <div ref={chatRef} onScroll={handleScroll} className={classes.chatHistory}>
            {!!separatedMessages.read.length && renderMessages(separatedMessages.read, true)}
            {!!separatedMessages.new.length && (<>
                <NewMessageDivider ref={newMessageRef} newMessageCount={separatedMessages.new.length} />
                {renderMessages(separatedMessages.new, false)}
            </>)}
            <TypingParticipantsInfo typingParticipants={typingParticipants} />
            <div ref={bottomRef} />
        </div>
    )
}

export default ChatHistory;
