import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
    mainWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    listWrapper: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px rgba(33, 33, 49, 0.1)',
        borderRadius: '32px',
        marginTop: '20px',
        cursor: "pointer",
        '&:hover': {
            background: "#FEF7E6",
        },
        display: 'flex',
        width: '100%'
    },
    listItemTitle: {
        fontSize: '16px',
        color: '#393948',
        margin: '2px 0px'
    },
    listItemText: {
        fontSize: '14px',
        color: '#565662',
        margin: '2px 0px'
    },
    btnContinue: {
        width: '97%',
        marginTop: '20px',
        height: '60px',
        marginLeft: '1rem'
    },
    labelCtrl: {
        fontWeight: 600,
        color: '#393948',
        fontSize: '14px',
        textAlign: 'left',
        paddingBottom: '4px',
        marginLeft: '1.25rem'
    },
    textField: {
        textAlign: 'left',
        width: 300,
        marginLeft: '1.5rem'
    },
    newProfileIndicatorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '1.25rem',
    },
    newProfileIndicator: {
        background: '#139AED',
        width: '0.75rem',
        height: '0.75rem',
        marginTop: '1rem',
        clipPath: 'circle(50%)'
    },
    emptyBox: {
        width: '1.25rem',
    },
    profileTextWrapper: {
        marginTop: '0.2rem',
    },
    newProfileText: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '140%',
        color: '#139AED'
    },
    profileLevelIcon: {
        "& path": {
            fill: '#0F7BBD'
        },
        height: '40px',
        width: '36px',
    },
    iconButton: {
        padding: 5
    }
}));