import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "png/theme";

export const useStyles = makeStyles(() => ({
    assetsWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: '100%',
        width:'100%',
        overflow:'hidden',
        "& video":{
            height:'100%',
            objectFit:'cover',
            filter:'blur(8px)',
            transform:'matrix(-1, 0, 0, 1, 0, 0)'
        },
        background: COLORS.BLACK
    },
    mainContent:{
        fontWeight: 900,
        fontSize:'20px',
        marginBottom: SIZING.scale400,
        whiteSpace: 'break-spaces'
    },
    firstLogo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        position:'absolute',
        bottom:SIZING.scale1000
    },
    secondaryText: {
        fontWeight: 400,
        fontSize: SIZING.scale400,
        '@media (max-width:600px)': {
            fontSize: SIZING.scale400
        },
        color: COLORS.WHITE
    },
    waitingRoomSpinner: {
        marginBottom: SIZING.scale600,
        "& .MuiCircularProgress-root": {
            color: COLORS.WHITE
        }
    },
    quickChatSection: {
        width: SIZING.QUICK_CHAT_WIDTH,
        background: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(24px)",
        borderRadius: SIZING.scale400,
        minHeight: SIZING.QUICK_CHAT_HEIGHT,
        color: COLORS.CHAT_TEXT,
        marginTop:SIZING.scale600,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    chatIcon:{
        "& path": {
            fill:COLORS.CHAT_TEXT
          },
          marginRight:SIZING.scale450,
          height:SIZING.scale1000,
          width:SIZING.scale1000
    },
    quickChatFrom:{
        fontSize:SIZING.scale350,
        fontWeight:700
    },
    quickChatMessage:{
        fontSize:SIZING.scale450,
        color:COLORS.CHAT_MESSAGE,
        marginTop:SIZING.scale200,
        fontStyle:"italic",
        wordBreak:"break-all"
    },
    chatMessageSection:{
        textAlign:"initial",
        width:"74%"
    }
}));