import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppReduxStore } from "store/reducerTypes";
import { Permissions, ValueOf } from "types";

export type PermissionKeys = ValueOf<typeof Permissions>;

/** Checks that a permission is granted or not for the current user.
 * @param permissionName permission name
 * @returns boolean the permission is granted
 */
export function usePermission(permissionName: ValueOf<typeof Permissions>): boolean {
    const enabledPermissions = useEnabledPermissions();
    return enabledPermissions.includes(permissionName);
}

/** Provides all enabled permissions of the current user.
 * @returns string[] Enabled permission names or empty array.
 */
export function useEnabledPermissions(): string[] {
    const [grantedPermissionNames, setGrantedPermissionNames] = useState<string[]>([]);
    const { currentProfileRoleJoin } = useSelector((state: AppReduxStore) => state.user, (stateA, stateB) => stateA.currentProfileRoleJoin?.products.length === stateB.currentProfileRoleJoin?.products.length);
    const products = currentProfileRoleJoin?.products;
    
    useEffect(() => {
        if (products) {
            setGrantedPermissionNames(products.map((product) => product.code));
        }
    }, [products, setGrantedPermissionNames]);
    
    return grantedPermissionNames;
}
