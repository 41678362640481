import { useStyles } from "./styles";

export const PEPCopyright = ({ useAltaisName }: { useAltaisName?: boolean }) => {
    const classes = useStyles();

    return (
        <div id="copyright_row" className={classes.copyright_row}>
            <div id="copyright_container" className={classes.copyright_container}>
                {useAltaisName ? `Copyright © Altais Health Solutions 2024` : `Copyright © Brown and Toland 2024`}
            </div>
        </div>
    )
}
