import { Grid, DialogContent, Box, Button, DialogActions, IconButton, Paper, Typography } from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment } from "react";
import { setActiveModal, updateUserLevels } from "admin/store/actions";
import { useStyles } from "./styles";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AppReduxStore } from "admin/store/reducerTypes";
import Controls from "../../Controls/Controls";
import { Form, useForm } from '../../useForm';
import { showNotification } from "admin/store/actions/snackbar";
import UserLevelDetailsMenu from "admin/components/UserLevelDetailsMenu/UserLevelDetailsMenu";
const UpdateUserLevelModal = () => {
    const dispatch = useDispatch()
    const { admin, auth } = useSelector((state: AppReduxStore) => state)
    let usersLevels = admin.userLevels && admin.userLevels.filter((u: any) => u.type !== "PATIENT").map((ul: any) => {
        return { ...ul, title: ul.description }
    });
    if (auth.currentOrg?.id) {
        usersLevels = usersLevels && usersLevels.filter((u: any) => u.type === "ADMIN")
        if (auth.currentOrg?.parent) {
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.name.indexOf('ALTAIS_ADMIN') === -1)
        } else {
            usersLevels = usersLevels && usersLevels.filter((u: any) => u.name.indexOf('ALTAIS_ADMIN') !== -1)
        }
    }
    if (auth.currentPractice?.id) {
        usersLevels = usersLevels && usersLevels.filter((u: any) => u.type === "PROVIDER")
    }
    const intl = useIntl()
    const classes = useStyles()
    const onClose = () => {
        dispatch(setActiveModal(""))
    }

    const onSave = (event: any) => {
        event.preventDefault()
        if (validate()) {

            if (admin.sProfileIds) {
                const { sProfileIds } = admin
                const ul = admin.userLevels && admin.userLevels.find((ul: any) => (ul.id === values.levelId));
                const orgId = auth.currentPractice ? auth.currentPractice?.organization?.id : auth.currentOrg?.id
                dispatch(updateUserLevels(values.levelId, orgId, auth.currentPractice?.id, sProfileIds, ul?.type))
            }
            else {
                dispatch(showNotification("Update User Level Error", "profileRoleJoinIds not found"))
            }
            resetForm()
            onClose()
        }
    };
    const modalClasses = {
        paper: classes.wrapper
    }
    const validate = (fieldValues = values) => {
        const err = { ...errors };
        if ('levelId' in fieldValues)
            err.levelId = fieldValues.levelId.length !== 0 ? "" : "This is a required field"
        setErrors({
            ...err
        })

        if (fieldValues === values)
            return Object.values(err).every(x => x === "")
    }
    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm({}, false, validate);
    return (
        <Modal onClose={onClose} classes={modalClasses} id={MODAL_TYPES.UPDATE_USER_LEVEL_MODAL} small={true}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        {intl.formatMessage({ id: "UpdateUserLevelModal.Title" })}
                    </Typography>
                    <Typography variant="h3" className={classes.dialogHeading}>
                        {`${admin.sProfileIds && admin.sProfileIds.length} of users selected`}
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnCloseUserProductsModal" size="large" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancelUL" variant="outlined" style={{ zIndex: 100 }}
                            aria-haspopup="true" onClick={onClose}>
                            {intl.formatMessage({ id: "UpdateUserLevelModal.CancelButton" })}
                        </Button>
                        <Button id="btnSaveUL" variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={onSave}>
                            {intl.formatMessage({ id: "UpdateUserLevelModal.SaveButton" })}
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper square elevation={0}>
                    <Typography variant="body2" className={classes.confirmationBody}>
                        {intl.formatMessage({ id: "UpdateUserLevelModal.Body" })}
                    </Typography>
                    <Form>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={6} style={{ margin: 'auto', marginTop: '30px' }}>
                                    <Controls.Select
                                        name="levelId"
                                        label="User Level *"
                                        value={values.levelId}
                                        onChange={handleInputChange}
                                        options={usersLevels}
                                        error={errors.levelId}
                                        altContent={usersLevels ? <UserLevelDetailsMenu userLevels={usersLevels} /> : null}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default UpdateUserLevelModal