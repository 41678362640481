import React from "react";
import classNames from "classnames";
import { TableListItem, TableListItemProps } from "./table-list-item";
import { ReactComponent as EmptyIcon } from "icon-library/SVG/User_Doctor-Stethoscope-Shoulder.svg";
import * as CSS from "./class-names";

// @deprecated
export type PersonListItemProps = {
    name: string;
    info?: string;
    role?: string;
    onNameClicked?: (id: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} & TableListItemProps;

// @deprecated
export const PersonListItem: React.FC<PersonListItemProps> = ({
    children,
    id,
    idPrefix,
    info,
    name,
    onNameClicked,
    role,
    ...tableListitemProps
}) => {
    return (
        <TableListItem id={id} idPrefix={idPrefix} {...tableListitemProps}>
            <td>
                <div className={CSS.TableListPersonItemAvatarCell}>
                    <div className={CSS.TableListPersonItemAvatar} id={`${idPrefix}_item_avatar_${id}`}>
                        {/* TODO: make this icon customizable */}
                        <EmptyIcon />
                    </div>
                </div>
            </td>
            <td className={CSS.TableListItemTDExpanded}>
                <div
                    className={classNames(CSS.TableListPersonItemNameInfoCell, {
                        [CSS.TableListPersonItemNameInfoCellClickable]: !!onNameClicked,
                    })}
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        onNameClicked?.(id, event);
                    }}
                >
                    <span
                        className={CSS.TableListPersonItemName}
                        id={`${idPrefix}_item_name_${id}`}
                        data-testid={`${idPrefix}_item_name_${id}`}
                        title={name}
                    >
                        {name}
                    </span>
                    {role && (
                        <span
                            className={CSS.TableListPersonItemRole}
                            id={`${idPrefix}_item_role_${id}`}
                            data-testid={`${idPrefix}_item_role_${id}`}
                            title={role}
                        >
                            {role}
                        </span>
                    )}
                    {/* TODO: implement info */}
                </div>
            </td>
            {children}
        </TableListItem>
    );
};
