import React from "react";
import {useStyles} from "./styles";
import { Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import ActionButtons from "../ActionButtons/ActionButtons";
 const CallEnded = ({ handleCallback,isEvistMeeting }: { handleCallback:()=> void,isEvistMeeting:boolean  }) => {

    const classes = useStyles()


    return (
        <div className={`${classes.callEndedWrapper}`}>
            <Typography className={classes.evisitEndedTitle} >  <FormattedMessage id= "ActiveZoomMeeting.status.callEndedEvisit"/></Typography>
            <Typography className={classes.evisitEndedDesc} >  <FormattedMessage id="ActiveZoomMeeting.status.callEndedDesc" /></Typography>
            <ActionButtons  handleCallback={handleCallback}/>         
        </div>
    )
}

export default CallEnded
