import { PageableRequest, PageableResponse } from "lib/domain/service-types";
import { Patient, PatientApiObject } from "lib/business-components/patient-search";
import { IPrefixNamed, IDisplayNamed } from "lib/domain/names";
import { Provider } from "lib/business-components/provider-search";
import { ValueOf } from "lib/util-types";
import { ISO8601DateTime } from "types";

type MemberPermission = "MANAGE_TEAM";
export const MemberRoles = {
    ADMIN: "ADMIN",
    PRIMARY: "PRIMARY",
    SPECIALIST: "SPECIALIST",
} as const;

export type MemberRole = ValueOf<typeof MemberRoles>;

export interface CareTeamCreatorResponse extends IPrefixNamed {
    profileId: string;
    /** @deprecated instead of fullName, use prefix, firstName, middleName and lastName */
    fullName: string;
}

export interface CareTeamCreator extends CareTeamCreatorResponse, IDisplayNamed {}

export type CareTeamMember = {
    practiceId: string;
    providerId: string;
    roles: MemberRole[];
};

export interface CareTeamMemberDetailsResponse extends CareTeamMember, IPrefixNamed {
    /** @deprecated instead of fullName, use prefix, firstName, middleName and lastName */
    fullName: string;
    credentials: string;
}

export interface CareTeamPatient {
    patientId: string;
    practiceId?: string;
}

export interface CareTeamPatientDetailsResponse extends CareTeamPatient, Omit<PatientApiObject, "id"> {
    lastActionDate: ISO8601DateTime | null;
}

export interface CareTeamPatientDetails extends CareTeamPatientDetailsResponse, IDisplayNamed, Patient {}

export interface CareTeamMemberDetails extends CareTeamMemberDetailsResponse, IDisplayNamed {
    /**
     * The member name to be used for sorting purposes.
     * It should be equal displayName but omitting the prefix
     * This property is created in FE during the mapping from raw DTO
     */
    sortName: string;
}

export interface CareTeam {
    id?: string;
    name: string;
    members: CareTeamMember[];
    patients: CareTeamPatient[];
    version: number;
}

interface CareTeamInfo {
    id: string;
    name: string;
}

interface CareTeamBase extends CareTeamInfo {
    userPermissions: MemberPermission[];
    version: number;
}

export interface CareTeamSearchItem extends CareTeamBase {
    memberCount: number;
    patientCount: number;
}

export interface CareTeamDetailsResponse extends CareTeamBase {
    members: CareTeamMemberDetailsResponse[];
    patients: CareTeamPatientDetailsResponse[];
    creator: CareTeamCreatorResponse;
}

export interface CareTeamDetails extends CareTeamDetailsResponse {
    patients: CareTeamPatientDetails[];
    members: CareTeamMemberDetails[];
    creator: CareTeamCreator;
}

export type PermissionOptions = {
    admin: boolean;
    primary: boolean;
};
// TODO: probably we will have to refactor this, once requirements on owner/primary/etc get clarified
export type SelectedProvider = Partial<PermissionOptions> & Provider;
export type SelectedPatient = Patient;

export type CareTeamState = {
    selectedPatients: SelectedPatient[];
    selectedProviders: SelectedProvider[];
} & Pick<CareTeam, "name" | "version">;

export type PatientSearchRequest = {
    text: string; // search string (by team name)
} & PageableRequest;

export type PatientSearchResponse = {
    data: Patient[];
} & PageableResponse;
