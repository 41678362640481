import { Box, Button, DialogActions, DialogContent, IconButton, Typography } from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import { PracticeLocationBillingGroupProps } from "admin/views/FeeSchedules/components/locations/types";
import { FeeScheduleRegionStateType } from "admin/views/FeeSchedules/types";
import { ReactComponent as InfoCircle } from "icon-library/SVG/Info_Circle.svg";
import { ReactComponent as WarningTriangle } from "icon-library/SVG/Warning_Triangle.svg";
import { useApi } from "lib/api/api-service-hooks";
import { HttpMethod } from "lib/api/types";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { createSvgIcon } from "utils";
import { FeeScheduleGroupsRegion } from "./fee-schedule-groups-region";
import { FeeScheduleGroupsTable } from "./fee-schedule-groups-table";
import { useStyles } from "./styles";
import { BillingGroupStateType } from "./types";
import { call } from 'admin/store/api';
import { RegionPracticeLocationProps } from "admin/views/FeeSchedules/components/locations/types";

const getAllSelectedGroups = async (practiceLocationBillingGroups: PracticeLocationBillingGroupProps[]) => {
    const allSelectedGroups: any[] = [];
    await Promise.all(practiceLocationBillingGroups.map(async (practiceLocationBillingGroup: PracticeLocationBillingGroupProps) => (
        await call("GET", `/admin/v1/region/region-practice-location/practice/${practiceLocationBillingGroup.practiceId}/location/${practiceLocationBillingGroup.locationId}`).then((regionPracticeLocation: RegionPracticeLocationProps) => {
            allSelectedGroups.push({
                selectedBillingGroupId: practiceLocationBillingGroup.billingGroupInfo.id,
                selectedBillingGroupRegionId: regionPracticeLocation.regionId
            })
        })
    )))
    return allSelectedGroups;
}

const useSelectedLocationsList = (practiceId: string, locationId: string) => {
    const { content, fetchInfo, request } = useApi<{}, any>({
        flags: {
            abortOnNewRequest: true,
        },
        defaultContent: [],
        url: `/admin/v1/billing/practice-location-group/practice/${practiceId}/location/${locationId}`,
        method: HttpMethod.GET,
        responseTransformer: async (practiceLocationBillingGroups: PracticeLocationBillingGroupProps[]) => {
            return await getAllSelectedGroups(practiceLocationBillingGroups);
        }
    });

    useEffect(() => {
        if (locationId && practiceId) {
            request({});
        }
    }, [locationId, practiceId, request]);

    return {
        fetchedSelectedLocations: content,
        hasResult: fetchInfo.hasResult,
        error: fetchInfo.error,
    };
};


const FeeSchedulesLocationEdit = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { sLocationA } = useSelector((state: AppReduxStore) => state).location;
    const { currentProfileRoleJoinInfo } = useSelector((state: AppReduxStore) => state).auth;
    const orgId = currentProfileRoleJoinInfo?.practice?.organizationId;
    const [regionsState, setRegionsState] = useState<FeeScheduleRegionStateType>({});
    const [billingGroups, setBillingGroups] = useState<BillingGroupStateType>({});
    const InfoCircleIcon = createSvgIcon(InfoCircle);
    const WarningTriangleIcon = createSvgIcon(WarningTriangle);

    const locationId = sLocationA?.id;
    const practiceId = currentProfileRoleJoinInfo?.practice?.id;
    const { fetchedSelectedLocations, hasResult } = useSelectedLocationsList(practiceId, locationId);

    if (hasResult)
        console.log(fetchedSelectedLocations);

    const selectedRegionsArray = (): string[] => {
        const selectedRegions = [];
        for (const regionKey in regionsState) {
            if (regionsState[regionKey]) {
                selectedRegions.push(regionKey);
            }
        }
        return selectedRegions;
    }

    const onClose = () => {
        dispatch(setActiveModal(""))
        setBillingGroups({});
        setRegionsState({});
    }

    const handleClose = () => {
        onClose()
    };

    const handleSaveChanges = (event: any) => {
    };

    const initialFeeScheduleNote = () => {
        return (
            <div style={{ boxSizing: 'border-box', width: '730px', height: '96px', background: '#E8F5FE', border: '1px solid #139AED', borderRadius: '8px', display: 'flex' }}>
                <div style={{ padding: '10px' }}>
                    <InfoCircleIcon fill={"#139AED"} style={{ width: '40px', height: '40px' }} />
                </div>
                <div>
                    <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '17px', lineHeight: '140%', color: '#0B5D8F', margin: '0px', marginTop: '10px', marginLeft: '10px' }}>
                        {`Note: Changing region selection after the initial setup is not advised!`}
                    </p>
                    <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '140%', color: '#565662', margin: '0px', marginTop: '5px', marginLeft: '10px', marginRight: '30px' }}>
                        {`Changing selected region and/or groups may affect you users’ ability to access fee schedules, and may require manual adjustments of user’s access permissions.`}
                    </p>
                </div>
            </div>
        )
    };

    const existingFeeScheduleNote = () => {
        return (
            <div style={{ boxSizing: 'border-box', width: '730px', height: '96px', background: '#FEF7E6', border: '1px solid #F5A800', borderRadius: '8px', display: 'flex' }}>
                <div style={{ padding: '10px' }}>
                    <WarningTriangleIcon fill={"#F5A800"} style={{ width: '40px', height: '40px' }} />
                </div>
                <div><p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '17px', lineHeight: '140%', color: '#393948', margin: '0px', marginTop: '10px', marginLeft: '10px' }}>
                    {`Note: Changing region selection is not advised!`}
                </p>
                    <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '16px', lineHeight: '140%', color: '#565662', margin: '0px', marginTop: '5px', marginLeft: '10px', marginRight: '30px' }}>
                        {`Changing the region will affect you users’ ability to access fee schedules. Each group change will require manual adjustments of user’s access permissions.`}
                    </p>
                </div>
            </div>
        )
    };

    return (
        <Modal onClose={onClose} id={MODAL_TYPES.FEE_SCHEDULES_LOCATION_EDIT}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"EditLocation.Title"} values={{ name: sLocationA.name }} />
                        {`Edit `}
                    </Typography>
                </Box>
                <Box>
                    <IconButton id="btnCloseLocationModal" size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <Box flexGrow={1}>
                </Box>
                <Box>
                    <Button id="btnSaveChanges" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveChanges}>
                        {`Save Changes`}
                    </Button>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} className={classes.dlgAction} style={{ paddingBottom: '32px' }}>
                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '24px', lineHeight: '140%', color: '#393948', margin: '0px' }}>
                    {`Fee Schedule Groups`}
                </p>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '320px', marginTop: '10px' }}>
                        <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '140%', color: '#393948', margin: '0px' }}>
                            {`Region`}
                        </p>
                        <FeeScheduleGroupsRegion orgId={orgId} regionsState={regionsState} onRegionsChange={setRegionsState} />
                    </div>
                    {true ? initialFeeScheduleNote() : existingFeeScheduleNote()}
                </div>
                <p style={{ fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '140%', color: '#393948', margin: '0px', marginTop: '30px' }}>
                    {`Fee Schedule Groups`}
                </p>
                <FeeScheduleGroupsTable orgId={orgId} billingGroups={billingGroups} onBillingGroupsChange={setBillingGroups} disabled={true} enabledRegions={selectedRegionsArray()} />
            </DialogContent>
        </Modal >
    )
}

export default FeeSchedulesLocationEdit