import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    chat: {
        "& .ComposeMessage": {
            background: COLORS.CHAT_HEADER_BG,
            boxShadow: "1px 0px 8px rgb(0 0 0 / 5%), inset 0px 1px 0px rgb(0 0 0 / 10%)",
            padding: `${SIZING.scale800} ${SIZING.scale500} ${SIZING.scale500} ${SIZING.scale500}`,
        },
    },
}));
