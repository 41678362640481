import { ListItem } from "@mui/material";
import { useStyles } from "./styles";
import { StyleProps } from "./types";

const SubListSeparator = ({ open }: StyleProps) => {
    const classes = useStyles({ open });
    return <ListItem className={classes.subListSeparator} disableGutters />;
};

export default SubListSeparator;
