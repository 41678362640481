import { makeStyles } from "@mui/styles";
import { COLORS, LAYERS, SIZING, toRem } from "theme";

// TODO: Change all classnames, this was copied from EConsult
export const useStyles = makeStyles(() => ({
    providerItemDetails: {
        backgroundColor: COLORS.LYELLOW,
        borderRadius: SIZING.scale400,
        filter: `drop-shadow(0 0 ${SIZING.scale100} rgba(0,0,0,.2))`,
        maxWidth: SIZING.ECONSULT_SPECIALIST_DETAILS_TOOLTIP_WIDTH,
        padding: SIZING.scale400,
        zIndex: LAYERS.MODAL,
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        "& .arrow": {
            left: `-${SIZING.scale200}`,
            position: "absolute",
            margin: `${SIZING.scale300} 0`,
            "&::before": {
                backgroundColor: COLORS.LYELLOW,
                content: '""',
                display: "block",
                height: SIZING.scale500,
                margin: "auto",
                transform: "rotate(45deg)",
                width: SIZING.scale500,
                position: "relative",
                top: "-0.825rem",
            },
        },
        "& .infoHeader": {
            color: COLORS.LINK_BLUE,
            display: "flex",
            fontSize: SIZING.scale450,
            fontWeight: "bold",
            justifyContent: "space-between",
            marginBottom: SIZING.scale400,
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            "& .providerName": {
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
            "& .rateInfo": {
                marginLeft: SIZING.scale400,
            },
        },
        "& .infoContent": {
            fontSize: SIZING.scale350,
            overflowY: "auto",
            marginRight: `-${SIZING.scale400}`,
            paddingRight: SIZING.scale400,
        },
        "& .infoFooter": {
            display: "flex",
            justifyContent: "space-between",
            marginTop: SIZING.scale400,
            alignItems: "center",
            "& button": {
                backgroundColor: COLORS.WHITE,
                color: COLORS.PRIMARY_BTN_TEXT,
                fontSize: SIZING.scale400,
                height: SIZING.scale750,
                "&.remove": {
                    color: COLORS.ALERTS_ICON,
                },
            },
            "& svg": {
                width: SIZING.scale600,
                "&.add": {
                    visibility: "hidden",
                },
            },
        },
        "& .gridBody": {
            display: "grid",
            gridTemplateColumns: "minmax(120px, auto) auto",
        },
        "& .gridRow_content": {
            fontWeight: 600,
            lineHeight: toRem(19.6),
            marginBottom: SIZING.scale200,
            "& ul": {
                margin: 0,
                paddingInlineStart: 0,
            },
        },
        "& .gridRow_label": {
            fontWeight: "bold",
            paddingRight: SIZING.scale400,
        },
        "& .separator": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            gridColumnStart: 1,
            gridColumnEnd: 3,
            margin: `${SIZING.scale200} 0 ${SIZING.scale400} 0`,
        },
        "& .specialties": {
            fontWeight: "600",
        },
        "& .subspecialties, & .locationAddress": {
            fontWeight: 400,
        },
        "& .location:not(:last-child)": {
            marginBottom: SIZING.scale400,
        },
        "& .loadingWrapper": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "&.loading .hideOnLoading": {
            visibility: "hidden",
        },
    },
}));
