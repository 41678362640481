import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, SIZING,COLORS} from "theme";

export const useStyles = makeStyles(() => ({
        wrapper: {
            padding: SIZING.scale600,
            height: evisitBar=>`calc(100% - ${(SIZING.PATIENT_SUMMARY_HEADER_HEIGHT + (evisitBar ?SIZING.PATIENT_SUMMARY_EVISIT_BAR_HEIGHT :0))}px)`,
            overflow: "auto",
            "& .alert-grid-wrapper":{
                width:'60%'
            }
        },
        contentWrapper:{
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
            padding: `${SIZING.scale400}`,
            border: '0.5px solid rgba(86, 86, 98, 0.2)',
            display: 'flex',
            borderRadius: BORDER_RADIUS.MEDIUM,
            marginBottom: SIZING.scale600,
            "&.read": {
                opacity: '0.5'
            },
            position: 'relative',
        },
        details:{
            marginLeft: SIZING.scale100,
            display: 'flex',
            "& .date": {
                    fontWeight: 'normal !important',
                    fontSize: SIZING.scale400,
                    color:COLORS.GREY,
                    opacity:'0.4',
                    marginTop: SIZING.scale400,
            },
            "& .main-details-wrapper": {
                flex: 1
            },
            "& .divider": {
                height: '1px',
                width: '100%',
                marginBottom: SIZING.scale500,
                marginTop: SIZING.scale500,
                background: 'rgba(0, 0, 0, 0.1)'
            },
            "& .title": {
                marginBottom: SIZING.scale200,
                textTransform:'uppercase'
            },
            "& .alert-description":{
                fontWeight:700,
                fontSize:SIZING.scale400,
                "& span":{
                    fontWeight: 300,
                    paddingLeft: SIZING.scale200
                }
            },
            "& .alert-details": {
                fontSize: SIZING.scale400,
                fontWeight: 'unset',
                display:'flex',
                alignItems:'center',
                width:'100%'
            },
            "& .summaryPageLink":{
                fontWeight:400,
                paddingLeft:SIZING.scale100,
                color:COLORS.LBLUE,
                cursor:'pointer',
                marginLeft:'auto',
                fontSize: SIZING.scale400,
                alignSelf:'end',
            },
            "& .startTime": {
                marginLeft: 'auto',
                fontWeight: 'normal',
                position: 'absolute',
                top: SIZING.scale400,
                right: SIZING.scale400,
            },
            flex: 1
        },
        empty: {
            height: evisitBar=>`calc(100% - ${(SIZING.PATIENT_SUMMARY_HEADER_HEIGHT + (evisitBar ?SIZING.PATIENT_SUMMARY_EVISIT_BAR_HEIGHT :0))}px)`,
        },
        iconWrapper:{
            display:'flex',
            alignItems:'center',
            "& svg":{
                fontSize: SIZING.scale700,
                "& path:first-child":{
                    fill: COLORS.BLACK
                },
                "&:nth-child(2)":{
                    "& path":{
                        fill: COLORS.LGRAY
                    },
                }
            }
        }
}))
