import { ValueOf } from "lib/util-types";

export type PermissionPolicy = "all" | "one";

/** If defined, then it tells a menu item should be displayed or not.
 *  This is consist of the following parts:
 *  - Permission names which are required for this particular menu item (evaluated by AND connection), if empty or
 *    undefined then no restriction is applied
 *  - Permission policy: how permissions should be checked: all permissions are required or only one is enough to
 *    access
 *  - Feature switches applied to this item (evaluated by AND connection), if empty or undefined then no switch is
 *    in place for this item
 */
export type AccessControlConfig = {
    permissions?: string[];
    features?: FeatureFlagNameValues[];
    policy?: PermissionPolicy;
};

export const FeatureFlagNames = {
    /** Is Remote Patient Monitoring related Care Team management enabled at all? */
    E_RPM_CARETEAM: "enable-rpm-careteam",
    /** Is eConsult enabled at all? */
    E_CONSULT: "enable-econsult",
    /** Is transcribeService (AWS MedicalTranscribe) enabled? */
    E_TRANSCRIPTION: "enable-etranscription",
    /** Is HPI Form for appointments enabled at all? */
    HPI_FORM: "enable-hpi-form",
} as const;

export type FeatureFlagNameValues = ValueOf<typeof FeatureFlagNames>;

export type FeatureFlagSet = Record<FeatureFlagNameValues, boolean>;

export const defaultFeatureFlags: FeatureFlagSet = {
    [FeatureFlagNames.E_RPM_CARETEAM]: false,
    [FeatureFlagNames.E_CONSULT]: false,
    [FeatureFlagNames.E_TRANSCRIPTION]: false,
    [FeatureFlagNames.HPI_FORM]: false,
};
