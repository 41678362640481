import { makeStyles } from "@mui/styles";
import {BORDER_RADIUS, SIZING, COLORS} from "theme";
import {StyleProps} from "./types";

export const useStyles = makeStyles(() => ({
        header: {
            padding: `${SIZING.scale400} 0`,
            background: COLORS.SIDE_NAV_HEADER_BG,
            boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.05), inset -1px 0px 0px rgba(0, 0, 0, 0.1)",
            borderTopRightRadius: BORDER_RADIUS.XL,
            width:({open}: StyleProps) => open ? SIZING.SIDE_NAV_OPEN_WIDTH : SIZING.SIDE_NAV_CLOSED_WIDTH,
            height: SIZING.HEADER_HEIGHT
            
        },
        headerWrapper: {
            height: SIZING.HEADER_HEIGHT,
            background: COLORS.SIDE_NAV_HEADER_BG,
            width:({open}: StyleProps) => open ? SIZING.SIDE_NAV_OPEN_WIDTH : SIZING.SIDE_NAV_CLOSED_WIDTH,
            
        },
        row: {
            display: "flex",
            justifyContent: ({open}: StyleProps) => open ? "space-between" : "center",
            position: "relative"
        },
        icon: {
            fontSize: SIZING.scale1200,
        },
        orgIcon: {
            marginLeft: ({open}: StyleProps) => open ? SIZING.scale300 : 0,
            fontSize: ({open}: StyleProps) => open ? SIZING.scale600 : SIZING.scale1200
        },
        menuIcon: {
            fontSize: SIZING.scale500,
            cursor: "pointer"
        },
        logout: {
            width: "100%",
            padding: `${SIZING.scale200} 0 0 ${SIZING.scale400}`,
            cursor: "pointer"
        },
        infoIcon: {
            width: '17px',
            height: '17px',
            cursor: 'pointer'
        },
        infoTooltipText: {
            fontSize: '18px',
            textAlign: 'left',
            marginLeft: '12px',
            marginRight: '4px',
            fontWeight: 'normal'
        },
        infoTooltipTextDesc: {
            fontSize: '15px',
            textAlign:'left'
        },
        LetsGoBtn: {
            marginTop: '10px',
            marginRight: '8px',
            height: '32px',
            "& .MuiButton-label": {
                fontSize: '11px',
                margin: '0',
                lineHeight: '0'
            },
        },
        addressContainer: {
            marginLeft:SIZING.scale400,
            marginRight:SIZING.scale400,
            paddingRight:SIZING.scale400,
            overflow:"hidden",
            textOverflow:"ellipsis",
            display:"block",
            whiteSpace:"nowrap"
        },
        addressText: {
            textOverflow:"ellipsis",
            overflow:"hidden",
            whiteSpace:"nowrap"
        }
}));