import { makeStyles } from "@mui/styles";
import { COLORS, SIZING } from "theme";
import * as CSS from "./class-names";

export const useStyles = makeStyles({
    [CSS.MessageContainer]: {
        [`&.${CSS.MessageContainer}`]: {
            width: "100%",
        },
        [`& .${CSS.MessageContainerMessage}`]: {
            display: "flex",
            "& svg": {
                width: SIZING.scale400,
            },
            "&--error": {
                color: COLORS.INPUT_ERROR_BORDER_COLOR,
            },
            "&--info": {
                color: COLORS.SECONDARY_BUTTON,
            },
        },
    },
});
