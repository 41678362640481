export type DetailsPopoverItemProps = {
    contentText?: string;
    labelText?: string;
    separator?: boolean;
}

export const DetailsPopoverItem: React.FC<DetailsPopoverItemProps> = ({ children, contentText, labelText, separator=false }) => {
    return !separator
        ? (<>
            {labelText  ? <span className="DetailsPopoverItem_label">{labelText}</span> : null }
            {contentText ? <span className="DetailsPopoverItem_content">{contentText}</span> : <div className="DetailsPopoverItem_content">{children}</div>}
        </>)
        : (<div className="separator"></div>);
}
