import React, { useMemo, useEffect, useState } from "react";
import Modal from "components/Modal/Modal";
import { MODAL_TYPES } from "constant";
import {
    Box,
    Button,
    Typography,
    Tabs,
    Tab,
    DialogActions,
    Checkbox
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import { useDispatch } from "react-redux";
import { setActiveModal, searchPatientBySearchString } from "store/actions";
import { SIZING } from "theme"
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import MultiSelectPopoverInput from "components/MultiSelectPopoverInput/MultiSelectPopoverInput";
import MultiSelectItem from "components/MultiSelectPopoverInput/MultiSelectItem";
import ErrorMessage from "common/components/ErrorMessage/ErrorMessage";
import { debounce } from "underscore";
import { useForm } from "common/components/FormValidator/FormValidator";
import { PATIENT_SEARCH } from "views/People/constants";
import { ReactComponent as Users } from "icon-library/SVG/User_Users.svg";
import { createSvgIcon } from "utils";
const UsersIcon = createSvgIcon(Users)

const RpmEnroll = () => {
    const classes = useStyles()
    const intl = useIntl()
    const dispatch = useDispatch()
    const [tab, setTab] = useState(0);
    const [searchedPatientRecipients, updateSearchedPatientRecipients] = useState([])
    const [searchTextPatient, setSearchTextPatient] = useState("")
    const [pinnedItemsPatient, setPinnedItemsPatient] = useState<any>(null)
    const [searchLoader, updateSearchLoader] = useState<Boolean>(false)
    const [checkedDevices, updateCheckedDevices] = useState<any>([])
    const [DevicesCount, updateDevicesCount] = useState<any>(0)

    const devices = [
        { name: "Blood Pressure Monitor" },
        { name: "Glucometer" },
        { name: "Pulse Oximiter" },
        { name: "Spirometer" },
        { name: "Thermometer" },
        { name: "Weight Scale" }
    ]

    useEffect(() => {
        updateCheckedDevices(devices)
    }, [])

    const {
        renderMessage,
    } = useForm()

    const searchPatient = useMemo(() => debounce((searchStr: string) => {
        if (searchStr.length >= 2) {
            updateSearchLoader(true)
            searchPatientBySearchString(searchStr, PATIENT_SEARCH.MESSAGES).then((data: any) => {
                updateSearchLoader(false)
                updateSearchedPatientRecipients(data)
            })
        }
    }, 250), [])

    useEffect(() => {
        searchPatient(encodeURIComponent(searchTextPatient.trim()))
    }, [searchPatient, searchTextPatient])

    const onClose = () => {
        setPinnedItemsPatient(null)
        dispatch(setActiveModal(""))
        setSearchTextPatient("")
        updateCheckedDevices(devices)
        updateDevicesCount(0)
    }

    const handleChangeTab = (event: any, newTab: any) => {
        setTab(newTab);
    };
    const onChange = (event: any, onValueChange: any) => {
        onValueChange(event.target.value)
    }

    const renderItem = (item: any, changePinnedItems: any, pinnedItemsDetails: any, isMultiSelect: boolean, searchString: string) => {
        const selected = !!pinnedItemsDetails && (pinnedItemsDetails.id ? pinnedItemsDetails.id === item.id : pinnedItemsDetails.profileId === item.profileId)
        return (
            <MultiSelectItem
                item={item}
                selected={selected}
                key={item.id || item.profileId}
                onChange={() => changePinnedItems(item)}
                highlightText={searchString}
                themeColor="compose"
            />
        )
    }

    const handleDeviceCheck = (event: any) => {
        const { name, checked } = event.target
        const checkedDeviceClick = checkedDevices?.map((item: any) =>
            item.name === name ? { ...item, isChecked: checked } : item)
        updateCheckedDevices(checkedDeviceClick)

    }

    useEffect(() => {
        const checkedCount = checkedDevices?.filter((item: any) => item.isChecked).length;
        updateDevicesCount(checkedCount)
    }, [checkedDevices])

    const handleEnrollPatient = () => {
        if (pinnedItemsPatient) {
            // let associatedDevices = checkedDevices?.filter((item: any) => item.isChecked)
            // let enorlldetails = {
            //     patientId: pinnedItemsPatient?.id,
            //     devices: associatedDevices
            // }
        }
    }

    const patientInput = renderMessage("patient", "required", pinnedItemsPatient);

    return (
        <Modal id={MODAL_TYPES.PROVIDER.RPM_ENROLL} modalWidth={SIZING.ENROLL_PATIENT_MODAL_WIDTH}>
            <Box className={classes.header}>
                <Box marginBottom={SIZING.scale400} className={classes.flexWrapper}>
                    <Box>
                        <Typography variant="body1" className={classes.headText} >
                            <FormattedMessage id={`Rpm.Enroll.Heading`} />
                        </Typography>
                        <Typography variant="body1" className={classes.desc}>
                            <FormattedMessage id={`Rpm.Enroll.Second.Heading`} />
                        </Typography>
                    </Box>
                    <Close className={classes.close} onClick={onClose} id="QA_btnClose" />
                </Box>
                <Box className={classes.flexWrapper}>
                    <Button variant="contained"
                        color="primary" className={classes.send} onClick={handleEnrollPatient}>
                        <Typography variant="button">
                            <FormattedMessage id={`Rpm.Enroll.Button`} />
                        </Typography>
                    </Button>
                </Box>
                <DialogActions className={classes.dlgAction}>
                    <Box width="100%">
                        <Tabs
                            value={tab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            onChange={handleChangeTab}
                            className={classes.tabWrapper}
                            aria-label="RPM Enroll Tab">
                            <Tab className={classes.tabTitle} label={intl.formatMessage({ id: "RPM.Patient" })} />
                            <Tab className={classes.tabTitle} label={<React.Fragment>
                                <Box className={classes.deviceTab}>
                                    <span className={classes.deviceCountLabelone}>{intl.formatMessage({ id: "RPM.Associated.Devices" })}</span>
                                    {tab===0&&<div className={classes.deviceCountLabel}><span className={classes.deviceCount}>{DevicesCount}</span></div>}
                                </Box>
                            </React.Fragment>} />
                        </Tabs>
                    </Box>
                </DialogActions>
            </Box>
            <div className={classes.content}>
                {tab === 0 ?
                    <Box>
                        <Typography variant="body1" className={classes.contentText} >
                            <FormattedMessage id={`Rpm.Enroll.Content.Patient`} />
                        </Typography>
                        <div className={`${classes.inputWrapper} ${classes.searchIcon}`}>
                            <MultiSelectPopoverInput
                                data={searchedPatientRecipients}
                                value={searchTextPatient}
                                onChange={(event: any) => onChange(event, setSearchTextPatient)}
                                qaid="QA_RPM_includePatientDetails"
                                placeholder={intl.formatMessage({
                                    id: `RPM.searchPatient`,
                                })}
                                title="RPM Enroll"
                                pinnedItems={pinnedItemsPatient}
                                setPinnedItems={setPinnedItemsPatient}
                                renderItem={renderItem}
                                loader={searchLoader}
                                isMultiSelect={false}
                                themeColor="compose"
                                renderPinnedComponent={false}
                            />
                            <ErrorMessage message={patientInput} />
                        </div>
                        <Box className={classes.usersIcon}>
                            <UsersIcon size="200px" />
                            <Typography variant="body1" className={classes.contentTextIcon} >
                                <FormattedMessage id={`RPM.Second.searchPatient`} />
                            </Typography>
                            <div className={classes.patientDesc}>
                                <Typography className={classes.contentTextPatient} >
                                    <FormattedMessage id={`RPM.Content.Text`} />
                                </Typography>
                            </div>
                        </Box>
                    </Box> :
                    <Box>
                        <Typography variant="body1" className={classes.contentText} >
                            <FormattedMessage id={`Rpm.Enroll.Content.SelectDevice`} />
                        </Typography>
                        <Box className={classes.devicesWrapper}>
                            {checkedDevices?.map((item: any) => {
                                return (
                                    <Box className={classes.deviceBox}>
                                        <Checkbox
                                            checked={item?.isChecked || false}
                                            onChange={handleDeviceCheck}
                                            name={item.name}
                                            color="primary"
                                        />
                                        {item.name}
                                    </Box>
                                )
                            })
                            }
                        </Box>
                    </Box>
                }
            </div>
        </Modal>
    )
}

export default RpmEnroll;
