import { makeStyles } from "@mui/styles";
import {COLORS, SIZING} from "theme";

export const useStyles = makeStyles(() => ({
        detailsHeader: {
            height: "auto",
            padding: `${SIZING.scale400}`,
            backgroundColor: COLORS.BADGE_BG,
            color:COLORS.INPUT_BG,
            boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)",
            display:'flex',
            alignItems:'center',
            "&.started":{
                backgroundColor: COLORS.FORM_SCORE_GREEN
            }
        },
        textCapitalize:{
            textTransform:"uppercase"
        },
        iconWrapper:{
            marginRight:SIZING.scale400
        }
}));