//IE 11 compatibility
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import '@okta/okta-auth-js/polyfill';
//
import { AuthError, AuthenticationResult, EventMessage, EventType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from 'App';
import AzureApp from 'AzureApp';
import 'index.css';
import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import { b2cPolicies, msalConfig } from './authentication/azure/authConfig';
import { appMode } from './authentication/config';
import './fonts/OpenSans-Regular.ttf';

//@ts-ignore
const { SENTRY_DSN, ENV } = window.env

const { REACT_APP_VERSION } = process.env

const isDeployed = process.env.NODE_ENV === 'production'

if (isDeployed) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        release: `enable-web-app@${REACT_APP_VERSION}`,
        environment: ENV,
        tracesSampleRate: 1.0,
    })
}

export const msalInstance: IPublicClientApplication = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (!msalInstance.getActiveAccount() && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
    if (event.eventType === EventType.LOGIN_FAILURE) {
        const error: AuthError = event.error as AuthError;
        if (error && error.errorMessage.includes('AADB2C90118')) {
            const resetPasswordRequest = {
                authority: b2cPolicies.authorities.forgotPassword.authority,
                scopes: [],
            };
            msalInstance.loginRedirect(resetPasswordRequest);
        }
    }
    if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        const error: AuthError = event.error as AuthError;
        if (error && error.errorMessage.includes('AADB2C90091')) {
            ;
        }
    }
});

const azureApp =
    <MsalProvider instance={msalInstance}>
        <BrowserRouter basename="/">
            <AzureApp />
        </BrowserRouter>
    </MsalProvider>

const app = (appMode === 'azure') ? azureApp : <App />

ReactDOM.render(
    <React.StrictMode>
        {app}
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
