import { Grid, Typography } from "@mui/material";
import { TinMask } from 'admin/components/Controls/InputMasked';
import { US_STATES } from 'admin/constant';
import { AppReduxStore } from 'admin/store/reducerTypes';
import Spinner from "common/components/Spinner/Spinner";
import { ReactComponent as Building_Wide } from "icon-library/SVG/Building_Wide.svg";
import { useState } from 'react';
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { createSvgIcon } from "utils";
import Controls from "../../Controls/Controls";
import { useStyles } from "./styles";

const AddPracticeForm = (props: any) => {
    const { auth } = useSelector((state: AppReduxStore) => state);
    const classes = useStyles();
    const intl = useIntl()
    const [states] = useState(US_STATES)
    // TODO need to clean up isLoading
    const [isLoading] = useState(false)
    const BuildingIcon = createSvgIcon(Building_Wide);
    const { handlePracticeInfoInputChange, practiceInfoFormData, errorsPracticeInfo } = props;
    if (!practiceInfoFormData?.absorbDepartmentId && auth?.currentOrg?.absorbDepartmentId) {
        handlePracticeInfoInputChange({
            target: {
                name: 'absorbDepartmentId',
                value: auth?.currentOrg?.absorbDepartmentId
            }
        })
    }

    return (
        <>
            {isLoading && <Spinner />}
            <Grid container>
                <Grid item className={classes.pageHeader}>
                    <Typography className={classes.pageHeaderText}>
                        <BuildingIcon fill="#0F7BBD" className={classes.editIcon} />
                        {intl.formatMessage({ id: "AddPracticeForm.Heading.Practice" })}
                    </Typography>
                    <Typography>
                        {intl.formatMessage({ id: "AddPracticeForm.Heading.PracticeDesc" })}
                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            {intl.formatMessage({ id: "AddPracticeForm.Heading.PracticeInfo" })}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item spacing={2} xs={4}>
                            <Controls.Input
                                name="name"
                                label="Name *"
                                value={practiceInfoFormData?.name}
                                onChange={handlePracticeInfoInputChange}
                                error={errorsPracticeInfo.name}
                            />
                        </Grid>
                        <Grid item spacing={2} xs={4}>
                            <Controls.Input
                                name="tin"
                                label="Tax ID Number (TIN)"
                                value={practiceInfoFormData?.tin}
                                onChange={handlePracticeInfoInputChange}
                                error={errorsPracticeInfo.tin}
                                inputComponent={TinMask}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            {intl.formatMessage({ id: "AddPracticeForm.Heading.PracticeAddress" })}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item spacing={2} xs={4}>
                            <Controls.Input
                                name="address1"
                                label="Address 1"
                                value={practiceInfoFormData?.address1}
                                onChange={handlePracticeInfoInputChange}
                                error={errorsPracticeInfo.address1}
                            />
                        </Grid>
                        <Grid item spacing={2} xs={4}>
                            <Controls.Input
                                name="address2"
                                label="Address 2"
                                placeholder="Additional"
                                value={practiceInfoFormData?.address2}
                                onChange={handlePracticeInfoInputChange}
                                error={errorsPracticeInfo.address2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="city"
                                label="City"
                                placeholder="City"
                                value={practiceInfoFormData.city}
                                onChange={handlePracticeInfoInputChange}
                                error={errorsPracticeInfo.city}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Select
                                name="state"
                                label="State"
                                placeholder="Select"
                                value={practiceInfoFormData.state}
                                onChange={handlePracticeInfoInputChange}
                                options={states}
                                error={errorsPracticeInfo.state}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.Input
                                name="postalCode"
                                label="Zip Code"
                                value={practiceInfoFormData.postalCode}
                                onChange={handlePracticeInfoInputChange}
                                error={errorsPracticeInfo.postalCode}
                                maxLength={10}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column" style={{ marginBottom: '50px' }}>
                    <Grid item className={classes.sectionHeader}>
                        <Typography className={classes.sectionHeaderText}>
                            {intl.formatMessage({ id: "AddPracticeForm.Heading.PracticeSecretCode" })}
                        </Typography>
                        <Typography>
                            {intl.formatMessage({ id: "AddPracticeForm.Heading.PracticeSecretCodeDesc" })}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" item spacing={2} className={classes.addressSectionField}>
                        <Grid item xs={4}>
                            <Controls.Input
                                name="secretCode"
                                label="Secret Code *"
                                placeholder="Enter Code"
                                value={practiceInfoFormData.secretCode}
                                onChange={handlePracticeInfoInputChange}
                                error={errorsPracticeInfo.secretCode}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}
export default AddPracticeForm