import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
        spinnerContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
        },
}));