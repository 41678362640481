import {Box} from "@mui/material";
import React, {useEffect} from "react";
import PatientSummary from "./PatientSummary/PatientSummary";
import PatientDetailsHeader from "./PatientDetailsHeader";
import {useDispatch, useSelector} from "react-redux";
import {AppReduxStore} from "store/reducerTypes";
import PeopleSearch from "./PeopleSearch/PeopleSearch";
import EvisitStatusBar from "./evisitStatusBar/evisitStatusBar";
import Alerts from  "./Alerts/PatientAlerts";
import Forms from "./Forms/Forms";
import Appointments from "./Appointments/Appointments";
import AlertDetails from "./AlertDetails/AlertDetails";
import RPM from "./RPM/RPM";
import Spinner from "common/components/Spinner/Spinner";
import {ReactComponent as Person} from "icon-library/SVG/User_Single.svg";
import {createSvgIcon} from "utils";
import {useStyles} from "./styles";
import {Switch, useParams} from "react-router-dom";
import { SecureRoute } from "authentication/app-secure-route";
import {getPatientDetails, getFeedbackLoop} from "store/actions";
import {MatchParams} from "./types";

const PersonIcon = createSvgIcon(Person)
    //@ts-ignore
const AlertRoutes = ({ match:{ path } }) => {
    return (
        <Switch>
            <SecureRoute exact path={path} component={Alerts}/>
            <SecureRoute path={`${path}/:id`} component={AlertDetails}/>
        </Switch>
    )
}

const PatientDetailsBody = () => {
    const { loadingDetails, selectedPatient } = useSelector((state: AppReduxStore) => state.patient)
    
    if (loadingDetails) return <Spinner/>

    if (!selectedPatient) return null

    return (
        <Switch>
            <SecureRoute path="/provider/people/patients/:patientId/appointments" component={Appointments}/>
            <SecureRoute path="/provider/people/patients/:patientId/rpm" component={RPM}/>
            <SecureRoute path="/provider/people/patients/:patientId/alerts" component={AlertRoutes}/>
            <SecureRoute path="/provider/people/patients/:patientId/forms" component={Forms}/>
            <SecureRoute exact path="/provider/people/patients/:patientId" component={PatientSummary}/>
        </Switch>
    )
}

const NoPatientSelected = () => {
    const classes = useStyles()
    return (
        <Box className={classes.noPatientSelected}>
            <PersonIcon/>
        </Box>
    )
}

const DetailsRoutes = () => {
    return (
        <Switch>
            <SecureRoute exact path="/provider/people/patients" component={NoPatientSelected}/>
            <SecureRoute path="/provider/people/patients/:patientId" component={PatientDetails}/>
        </Switch>
    )
}

const PatientDetails = () => {
    const dispatch = useDispatch()
    const {patientId} = useParams<MatchParams>()

    useEffect(() => {
        dispatch(getPatientDetails(patientId));
        dispatch(getFeedbackLoop(patientId));
    }, [dispatch, patientId])

    return (
        <>
            <PatientDetailsHeader />
            <EvisitStatusBar />
            <PatientDetailsBody />
        </>
    )
}

const People = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        return () => { dispatch({type: "CLEAR_PATIENT_STATE"}) }
    }, [dispatch])

    return (
        <Box marginRight="10px" display="flex" width="100%" height="100vh">
            <Box position="relative" height="100%">
                <PeopleSearch/>
            </Box>
            <Box width={"100%"}>
                <DetailsRoutes/>
            </Box>
        </Box>
    )
}

export default People
