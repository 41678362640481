import { FormattedMessage } from "react-intl";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import keyboardGif from "assets/Keyboard.gif";

type TypingParticipantsInfoProps = {
    typingParticipants: string[]
}

// Returns text depending on how many people are typing in a conversation at once
const getTypingMessageLocalizationKey = (typingParticipantsCount: number) => {
    if (typingParticipantsCount === 1) {
        return "messaging.typing.single";
    } else if (typingParticipantsCount <= 3) {
        return "messaging.typing.multi";
    } else {
        return "messaging.typing.several";
    }
}

const TypingParticipantsInfo = ({typingParticipants}: TypingParticipantsInfoProps) => {
    const typingMessageType = getTypingMessageLocalizationKey(typingParticipants.length);

    if (!typingParticipants.length) {
        return null;
    }

    return (
        <div className="typingIndicator">
            <img src={keyboardGif} className="keyboard" alt="typing" />
            <CustomTypography variant="typingIndicator">
                <FormattedMessage id={typingMessageType} values={{participants: typingParticipants.join(", ")}} />
            </CustomTypography>
        </div>
    );
};

export default TypingParticipantsInfo;
