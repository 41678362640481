import { makeStyles } from "@mui/styles";
import {COLORS, SIZING, BORDER_RADIUS} from "theme";

export const useStyles = makeStyles(() => ({
        completedDate: {
            textTransform: "uppercase",
        },
        formDetailsHeader: {
            height: SIZING.FORM_DETAILS_HEADER_HEIGHT,
            display: "flex",
            justifyContent: "space-between",
            background: COLORS.OPENED_THREAD_BG,
            padding: SIZING.scale400,
            boxShadow: "1px 0px 8px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
        },
        leftChevron: {
            width: SIZING.scale800,
            cursor: "pointer"
        },
        exportBtn: {
            flexShrink: 0,
            background: "white",
            color: COLORS.PRIMARY_BTN_TEXT,
            height: SIZING.scale1000,
            "&:hover": {
                backgroundColor: "white",
            }
        },
        detailsContainer: {
            padding: SIZING.scale400,
            height: `calc(100% - (${SIZING.FORM_DETAILS_HEADER_HEIGHT} + ${SIZING.PATIENT_SUMMARY_HEADER_HEIGHT}))`,
            overflow: "auto"
        },
        details: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: COLORS.SIDE_NAV_BG,
            borderRadius: BORDER_RADIUS.MEDIUM,
            padding: SIZING.scale400
        },
        questionnaireSummary: {
            borderRadius: BORDER_RADIUS.MEDIUM,
            background: COLORS.FORM_SCORE_GREEN,
            color: "white",
            padding: SIZING.scale400,
            margin: `${SIZING.scale300} 0`,
            "& :first-child": {
                marginBottom: SIZING.scale200
            }
        },
        questionRow: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: `2px solid ${COLORS.SELECTED_NAV_LIST_ITEM_BTN}`,
            padding: SIZING.scale300,
            "& :first-child": {
                width: "80%"
            }
        },
        answerContainer: {
            display: "flex",
            justifyContent: "flex-end",
            textAlign: "right",
            marginLeft: SIZING.scale500,
        },
        score: {
            width: SIZING.scale1000,
        },
        redScore: {
            color: COLORS.FORM_SCORE_RED
        },
        greenScore: {
            color: COLORS.FORM_SCORE_GREEN
        }
}));