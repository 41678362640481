import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS } from "../../../theme";

export const useStyles = makeStyles(() => ({
    wrapper: {
        width: "1136px",
        height: "680px",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.MODAL
    },
    dialogAction: {
        minHeight: '84px',
        padding: '5px 32px',
        justifyContent: "center"
    },
    dialogHeaderTitle: {
        flex: 1,
        color: '#565662',
        fontWeight: 700,
        fontSize: '17px'
    },
    dialogContentRoot: {
        flex: '1 1 auto',
        overflowY: 'auto',
        background: '#F6F8FA'
    },
    dialogContentDividers: {
        padding: '0 0 0 0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    dialogHeader: {
        minWidth: '980px',
        display: 'flex',
        flexDirection: 'column',
        background: '#EDEDEF'
    },
    dialogHeaderTop: {
        display: 'flex'
    },
    contentArea: {
        display: 'flex',
        flex: '1 1 auto'
    },
    closeButtonIcon: {
        height: '45px',
        width: '45px',
        verticalAlign: 'middle',
        marginLeft: '20px',
        cursor: 'pointer',
        "& path": {
            fill: "radial-gradient(98.44% 98.44% at 50% 1.56%, rgba(255, 255, 255, 0.2) 0%, rgba(126, 144, 179, 0) 90.63%), #565662"
        }
    },
    trashButtonIcon: {
        verticalAlign: 'middle',
        marginLeft: '20px',
        cursor: 'pointer'
    },
    modalContent: {
        marginTop: '30px',
        marginBottom: '14px'
    },
    modalContainer: {
        color: '#393948',
        fontSize: '24px',
        fontWeight: 600,
        textAlign: 'center'
    },
    paper: {
        marginTop: '16px',
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    tableCellName: {
        color: '#393948',
        fontSize: '14px',
        fontWeight: 700,
    }, 
    tableFooterRow: {
        background: '#EDEDEF',
        border: '1px solid #E4E4E6',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px -1px 0px #E7E7ED',
        borderRadius: '0px 0px 8px 8px',
        position: "sticky",
        bottom: 0,
        zIndex: 2
    },
    footerCaption: {
        fontSize: '12px',
        fontWeight: 'normal'
    },
    paginationSpacer: {
        flex: 0
    },
    footerIcon: {
        right: -15
    },
}));