import React, { Fragment, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Tabs, Tab, Box, Button,
  DialogActions, DialogContent, Typography
} from "@mui/material";
import { useStyles } from './styles';
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { useSelector } from "react-redux";
import { AppReduxStore } from "admin/store/reducerTypes";
import { setActiveModal } from "admin/store/actions";
import EditRegistrationPatients from "./EditRegistrationPatients";
import EditRegistrationProvider from "./EditRegistrationProvider";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EditRegistration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { license } = useSelector((state: AppReduxStore) => state)
  const corePlatFormLicenseCount = license?.practiceLicenses && license?.practiceLicenses.find((core: any) => core.product.code === "CORE")?.licenseCount
  const hidePatientEmailUI = !corePlatFormLicenseCount || corePlatFormLicenseCount === 0
  const editRegistrationFormProviderRef = useRef<any>();
  const editRegistrationFormPatientRef = useRef<any>();

  const [value, setValue] = React.useState(0);
  const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const result =
        value === 0
            ? editRegistrationFormProviderRef.current.saveFormData(event)
            : editRegistrationFormPatientRef.current.saveFormData(event);
    if (result)
      setValue(newValue);
  };

  const modalClasses = {
    paper: classes.wrapper
  };

  const handleClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSaveEditRegistration = (event: any) => {
    const result =
        value === 0
            ? editRegistrationFormProviderRef.current.submitForm(event)
            : editRegistrationFormPatientRef.current.submitForm(event);
    if (!hidePatientEmailUI && value === 0) {
      editRegistrationFormProviderRef.current.submitFormDataProvider(event);
    }
    else if (!hidePatientEmailUI && value === 1) {
      editRegistrationFormPatientRef.current.submitFormDataPatient(event);
    }

    if (result)
      handleClose();
  };

  const handleDiscardChanges = (event: any) => {
    handleClose();
  };

  return (
    <Modal onClose={handleClose} classes={modalClasses} id={MODAL_TYPES.EDIT_REGISTRATION}>
      <DialogActions className={classes.dlgAction}>
        <Box flexGrow={1} >
          <Typography variant="body2" className={classes.dialogTitle}>
            <FormattedMessage id={"EditRegistration.ModalHeading"} />
          </Typography>
        </Box>
        <Box>
        </Box>
      </DialogActions>
      <DialogActions className={classes.dlgAction}>
        <Box className={classes.dlgAction}>
          <Box>
            <Fragment>
              <Button id="btnDiscardChanges" variant="outlined"
                aria-haspopup="true" onClick={handleDiscardChanges}>
                {intl.formatMessage({ id: "EditRegistration.Action.Cancel" })}
              </Button>
              <Button id="btnSave" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveEditRegistration}>
                {intl.formatMessage({ id: "EditRegistration.Action.Update" })}
              </Button>
            </Fragment>
          </Box>
        </Box>
      </DialogActions>
      <DialogActions className={classes.dlgAction}>
        {!hidePatientEmailUI &&
          <Tabs style={{ width: '100%' }} value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={tabChange}>
            <Tab label="Clinical Staff" />
            <Tab label="Patient" />
          </Tabs>
        }
      </DialogActions>
      <DialogContent className={classes.contentDialog}>
        <div>
          {
            value === 0 ?
              <TabPanel value={value} index={0}>
                <EditRegistrationProvider ref={editRegistrationFormProviderRef} />
              </TabPanel> :
              <TabPanel value={value} index={1}>
                <EditRegistrationPatients ref={editRegistrationFormPatientRef} />
              </TabPanel>
          }
        </div>
      </DialogContent>
    </Modal>
  );
}

export default EditRegistration