import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    IconButton,
    Typography
} from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
const ProviderPortal = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory();
    const onClose = () => {
        dispatch(setActiveModal(""))
    }
    const handleClose = () => {
        onClose()
    };

    async function handleProviderPortal() {
        onClose()
        history.push('/');
    }

    return (
        <Modal onClose={onClose} id={MODAL_TYPES.PROVIDER_PORTAL}>
            <DialogActions>
                <Box flexGrow={1} style={{ paddingLeft: '32px', paddingTop: '25px' }}>
                    <Typography variant="body2" className={classes.dlgTitle}>
                        <FormattedMessage id={"ProviderPortal.title"} />
                    </Typography>
                    <Typography variant="h3" className={classes.dlgText}>
                        <FormattedMessage id={"ProviderPortal.ConfirmMessage"} />
                    </Typography>
                </Box>
                <Box style={{ marginLeft: '250px' }}>
                    <IconButton id="btnCloseProviderNavigationModal" size="large" onClick={handleClose} >
                        <Close />
                    </IconButton>
                </Box>
            </DialogActions>
            <DialogActions>
                <Box flexGrow={1} >
                </Box>
                <Box>
                    <Fragment>
                        <Button id="btnCancel" variant="outlined" style={{ marginLeft: '10px' }} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button id="btnLaunch" variant="outlined" color="primary" style={{ marginLeft: '10px', backgroundColor: "#E32B29" }} onClick={handleProviderPortal}>
                            Launch
                        </Button>
                    </Fragment>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} className={classes.dlgContent}>
                <FormattedMessage id={"ProviderPortal.BodyText"} />
            </DialogContent>
        </Modal>
    )
}

export default ProviderPortal