import React,{useState} from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { List, ListItem, ClickAwayListener, ListItemText, Box, Typography } from "@mui/material";
import { ReactComponent as ChevronRight } from 'icon-library/SVG/Chevron_Right.svg';
import { useStyles } from './styles';

const CarePlanDropDown = () => {
    const classes = useStyles();
    const intl = useIntl();
    const [templateOpen, setTemplateOpen] = useState(false);

    const handleTemplateClickAway =() => {
        setTemplateOpen(false)
    }

    return (
        <Box>
            <ClickAwayListener onClickAway={handleTemplateClickAway}>
                <Box >
                    <Box >
                        <List className={classes.carebaMenu}>
                            <ListItem button>
                                <ListItemText primary={intl.formatMessage({ id: "RPM.Care.Plan.Save.Template" })} classes={{ primary: classes.listText }} />
                            </ListItem>
                            <ListItem button onClick={() => setTemplateOpen(!templateOpen)}>
                                <ListItemText primary={intl.formatMessage({ id: "RPM.Care.Plan.Load.Template" })} classes={{ primary: classes.listText }} />
                                <ChevronRight className={classes.chevronIcon} />
                            </ListItem>
                        </List>
                        <Box className={classes.caretraingle} />
                    </Box>
                    {
                        templateOpen &&
                        <Box >
                            <List className={classes.tempbaMenu} >
                                <Typography className={classes.tamplateTitle}>
                                    <FormattedMessage id={"RPM.Template.Title"} />
                                </Typography>
                                <ListItem button>
                                    <ListItemText primary={intl.formatMessage({ id: "RPM.Template.Diabetes" })} classes={{ primary: classes.listText }} />
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary={intl.formatMessage({ id: "RPM.Template.plan" })} classes={{ primary: classes.listText }} />
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary={intl.formatMessage({ id: "RPM.Template.Irregular.Heartbeat" })} classes={{ primary: classes.listText }} />
                                </ListItem>
                            </List>
                        </Box>
                    }
                </Box>
            </ClickAwayListener>
        </Box>
    );
}

export default CarePlanDropDown;