import {
    Box,
    ClickAwayListener,
    Fade,
    Paper,
    Popper,
    PopperPlacementType
} from "@mui/material";
import React, { ReactElement } from "react";
import { useStyles } from "./styles";

interface Props {
    content: ReactElement;
    children: ReactElement;
    open: boolean;
    onClose?: () => void;
    arrow?: boolean;
    placement?: PopperPlacementType;
    fade?: boolean,
    offset?: any
}

const RichTooltip = ({
    placement = "top",
    arrow = true,
    open,
    onClose = () => { },
    content,
    children,
    fade = true,
    offset

}: Props) => {
    const classes = useStyles();
    const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
    const [childNode, setChildNode] = React.useState<HTMLElement | null>(null);

    return (
        <div>
            {React.cloneElement(children, { ...children.props, ref: setChildNode })}
            <Popper
                open={open}
                anchorEl={childNode}
                placement={placement}
                transition
                className={classes.popper}
                modifiers={[{
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        rootBoundary: 'document'
                    }
                }, {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: arrowRef
                    }

                },
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: offset ? offset : [0, 0],
                    }
                }
                ]}
            >
                {({ TransitionProps }) => (
                    fade ? <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener onClickAway={onClose}>
                                <Paper className={classes.popoverRoot}>
                                    {arrow ? (
                                        <span className={classes.arrow} ref={setArrowRef} />
                                    ) : null}
                                    <Box className={classes.content}>{content}</Box>
                                </Paper>
                            </ClickAwayListener>
                        </Paper>
                    </Fade> : <Paper>
                        <ClickAwayListener onClickAway={onClose}>
                            <Paper className={classes.popoverRoot}>
                                {arrow ? (
                                    <span className={classes.arrow} ref={setArrowRef} />
                                ) : null}
                                <Box className={classes.content}>{content}</Box>
                            </Paper>
                        </ClickAwayListener>
                    </Paper>
                )}
            </Popper>
        </div>
    );
};

export default RichTooltip;
