import { Checkbox, Tooltip } from "@mui/material";
import classNames from "classnames";
import { FunctionalMenuItem } from "common/components/OptionMenu/types";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as CheckboxUnchecked } from "icon-library/SVG/Checkbox-Light_Square.svg";
import { ReactComponent as CheckboxChecked } from "icon-library/SVG/Checkbox_Square.svg";
import { ReactComponent as RemoveIcon } from "icon-library/SVG/Cross_Circle.svg";
import ContextMenu from "lib/ui-components/context-menu/context-menu";
import React, { MouseEvent as ReactMouseEvent } from "react";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import { PracticeListItemProps } from "./types";

const PracticeListItem = (props: PracticeListItemProps) => {
    const {
        onNameClicked,
        onRemove,
        onSelect,
        practice,
        selected,
        selectDisabled,
        disabledMessage = "",
        idPrefix,
        showAddress
    } = props;

    const classes = useStyles();
    const intl = useIntl();
    const practiceName = practice.practice.name;
    const id = practice.practice.id;

    const handleSelect = () => {
        if (onSelect) {
            onSelect(practice);
        }
    };

    const handleRemove = () => onRemove(id);

    const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            handleSelect();
        } else {
            handleRemove();
        }
    };

    const showRemoveButton = !onSelect;

    const selectLabel = intl.formatMessage(
        { id: "EConsult.newRequestModal.providers.providerItem.select" },
        { name: practiceName }
    );
    const unselectLabel = intl.formatMessage(
        { id: "EConsult.newRequestModal.providers.providerItem.unselect" },
        { name: practiceName }
    );
    const removeLabel = intl.formatMessage(
        { id: "EConsult.newRequestModal.providers.providerItem.remove" },
        { name: practiceName }
    );

    const contextMenu: FunctionalMenuItem[] = [];
    if (!onSelect) {
        contextMenu.push({
            id: `${idPrefix}_item_remove_${id}`,
            onClick: handleRemove,
            icon: () => <SVGIcon svg={RemoveIcon} />,
            labelText: removeLabel,
        });
    }

    return (
        <tr
            key={`providerListItemOf${id}`}
            className={classNames(classes.providerItem, { hasRemoveButton: showRemoveButton, selected })}
        >
            {onSelect && (
                <td>
                    <Tooltip title={selectDisabled && disabledMessage !== "" && !selected ? disabledMessage : ""}>
                        {/* this extra span is necessary, because tooltip will not appear when the inner element is disabled */}
                        <span>
                            <Checkbox
                                id={`${idPrefix}_item_checkbox_${id}`}
                                aria-label={selected ? unselectLabel : selectLabel}
                                checked={selected}
                                onChange={handleChange}
                                disabled={selectDisabled && !selected}
                                color="primary"
                                classes={{ root: "providerItem__checkbox" }}
                                disableRipple
                                disableFocusRipple
                                icon={<SVGIcon svg={CheckboxUnchecked} size="scale800" />}
                                checkedIcon={<SVGIcon svg={CheckboxChecked} size="scale800" />}
                            />
                        </span>
                    </Tooltip>
                </td>
            )}
            <td>
                <div className="avatarWrapper">
                </div>
            </td>
            <td className="expand">
                <div
                    className={classNames("nameTitleWrapper", {
                        isClickable: !!onNameClicked,
                    })}
                    onClick={(event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
                        onNameClicked?.(id, event.target as any);
                    }}
                >
                    <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%', color: '#565662', margin: '0px' }} id={`${idPrefix}_item_location_line2_${id}`} >
                        {practiceName}
                    </p>
                </div>
            </td>
            <td>
                <div className="circleRemoveWrapper">
                    <ContextMenu menuItems={contextMenu} />
                </div>
            </td>
            {showAddress && (<td>
                <div className="specialtiesWrapper">
                    <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%', color: '#565662', margin: '0px' }} id={`${idPrefix}_item_location_line1_${id}`} >
                        {`${practice.locations.address1} ${practice.locations.address2}`}
                    </p>
                    <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '140%', color: '#565662', margin: '0px' }} id={`${idPrefix}_item_location_line2_${id}`} >
                        {`${practice.locations.city} ${practice.locations.state} ${practice.locations.postalCode}`}
                    </p>
                </div>
            </td>)}
        </tr>
    );
};

export default PracticeListItem;
