import React, { useRef, forwardRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    Paper, Box, Typography, Button, DialogActions,
    DialogContent
} from "@mui/material";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import { useStyles } from "./styles";
import WaitingRoomAddEdit from "./WaitingRoomAddEdit";

const WaitingRoom = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const classes = useStyles();
    const editWaitingRoom = useRef<any>();

    const modalClasses = {
        paper: classes.wrapper
    };

    const handleClose = () => {
        dispatch(setActiveModal(""));
    };

    const handleSaveVisitTypesForm = (event: any) => {
        const result = editWaitingRoom.current.submitForm(event);
        if (result)
            handleClose();
    };

    return (
        <Modal onClose={handleClose} classes={modalClasses} id={MODAL_TYPES.EDIT_WAITING_ROOM}>
            <DialogActions className={classes.dlgAction}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"WaitingRoom.ModalHeading"} />
                    </Typography>
                </Box>
                <Box>
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box className={classes.dlgAction}>
                    <Box>
                        <Button id="btnDiscardChanges" variant="outlined"
                            aria-haspopup="true" onClick={handleClose}>
                            {intl.formatMessage({ id: "WaitingRoom.Action.Cancel" })}
                        </Button>
                        <Button id="btnSave" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveVisitTypesForm}>
                            {intl.formatMessage({ id: "WaitingRoom.Action.Update" })}
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
            <DialogContent className={classes.contentDialog}>
                <Paper className={classes.waitingRoomModal}>
                    <WaitingRoomAddEdit ref={editWaitingRoom} />
                </Paper>
            </DialogContent>
        </Modal>
    );
})

export default WaitingRoom