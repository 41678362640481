import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme"

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: theme.spacing(2),
  },
  root:{
    '@media (max-width:600px)': {
      width: "240px",
      height: "140px"
    },
    "&  .MuiBox-root": {
      '@media (max-width:600px)': {
        width: "190px",
      },
      width: "230px"
    },
    width: "280px",
    height: "135px",
    padding: theme.spacing(1),
    position: "relative",
    "& .title": {
      color: COLORS.ITEM_BADGE_BG,
      paddingBottom: theme.spacing(1),
      fontSize: SIZING.scale350,
      '@media (max-width:600px)': {
        width: "180px",
      },
    },
    "& .content": {
      fontSize: SIZING.scale300,
      color: COLORS.LIST_SUBHEADER_TEXT,
      fontWeight: 'normal',
      paddingBottom: "5%",
    },
    "& .MuiButton-label": {
      fontSize: SIZING.scale350,
    },
    "& .send-container": {
      alignSelf: "center",
      "& button": {
        "&:hover": {
          backgroundColor: COLORS.DEFAULT_BTN
        }
      }
    },
    "& .closeIcon": {
      position: "absolute",
      right: 0,
      top: 0,
      height: theme.spacing(1),
      width: theme.spacing(1),
      margin: SIZING.scale400
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  btnYes: {
    backgroundColor: COLORS.PRIORITY_BG,
    margin: theme.spacing(1),
    width: SIZING.scale1200,
    fontSize: SIZING.scale350,
    height: SIZING.scale800,
    left: SIZING.scale600,
    color: COLORS.WHITE,
    "&:hover": {
      background: COLORS.PRIORITY_BG,
      color: COLORS.WHITE,
    },
    '@media (max-width:600px)': {
      width: SIZING.scale800,
      fontSize: SIZING.scale350,
      height: SIZING.scale800,
    },
  },
  btnNo: {
    backgroundColor: "#FFFFFF",
    margin: theme.spacing(1),
    width: SIZING.scale1200,
    height: SIZING.scale800,
    color: COLORS.LBLUE,
    fontSize: SIZING.scale350,
    left: SIZING.scale600,
    "&:hover": {
      background: "#FFFFFF"
    },
    '@media (max-width:600px)': {
      width: SIZING.scale600,
      fontSize: SIZING.scale200
    },

  }
}));