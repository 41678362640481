import React from "react";
import { FormattedMessage } from "react-intl";
import { EConsultRequestPreviewDto, EConsultFolderName, EConsultRequestSearchStage } from "./types";
import { ReactComponent as WarningCircle } from "icon-library/SVG/Warning_Circle.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";

export interface SearchItemProps {
    searchStage: EConsultRequestSearchStage;
    folderName: EConsultFolderName;
    item: EConsultRequestPreviewDto;
    // TODO this index is used by QA only. we should talk with QA and change the index to the requestId
    index: number;
    idPrefix: string;
}

const ClosedReceivedNotifications = ({ item }: SearchItemProps) => {
    switch (item.status) {
        case "CLOSED":
            return <FormattedMessage id="EConsult.request.item.closedByReferrer" />;
        case "CANCELLED":
            return <FormattedMessage id="EConsult.request.item.cancelledByReferrer" />;
        case "EXPIRED":
            return <FormattedMessage id="EConsult.request.item.expired" />;
        // if it's submitted it means this recipient rejected
        // but would be better to have access to the recipient status here
        case "SUBMITTED":
            return <FormattedMessage id="EConsult.request.item.rejectedByYou" />;
        case "ASSIGNED":
            return <FormattedMessage id="EConsult.request.item.claimedByOther" />;            
        default:
            return null;
    }
};

const ClaimedReceivedNotifications = (props: SearchItemProps) => {
    // TODO: message count notification goes here
    return null;
};

const NewReceivedNotifications = ({ item }: SearchItemProps) => {
    if (item.recipientsCount > 1) {
        return (
            <span className="eConsult__folderItem--warning">
                <FormattedMessage id="EConsult.request.item.reviewedBy" values={{ count: item.recipientsCount - 1 }} />
            </span>
        );
    }
    return null;
};

const ReceivedFolderNotifications = (props: SearchItemProps) => {
    switch (props.searchStage) {
        case "NEW":
            return <NewReceivedNotifications {...props} />;
        case "CLAIMED":
            return <ClaimedReceivedNotifications {...props} />;
        case "CLOSED":
            return <ClosedReceivedNotifications {...props} />;
        default:
            return null;
    }
};

const SentFolderNotifications = ({ index, searchStage, item }: SearchItemProps) => {
    if (searchStage === "NEW" && item.recipientsCount === 0) {
        return (
            <div className="eConsult__folderItem__reject eConsult__folderItem--warning" id={`QA_EConsultSearch_result_${index}_reject`}>
                <SVGIcon svg={WarningCircle} size="scale500" />
                <span>
                    <FormattedMessage id="EConsult.request.content.rejectedByAll" />
                </span>
            </div>
        );
    }

    return null;
};

const SearchItemNotification = (props: SearchItemProps) => {
    return (
        <div id={`${props.idPrefix}_notification`} className="eConsult__folderItem__notification fontWeight--bold">
            {props.folderName === "sent" ? (
                <SentFolderNotifications {...props} />
            ) : (
                <ReceivedFolderNotifications {...props} />
            )}
        </div>
    );
};

export default SearchItemNotification;
