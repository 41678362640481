class EventListenerManager<ListenerType extends Function, ListenerArgsType> {
    private eventListeners: Set<ListenerType> = new Set();

    addEventListener = (listener: ListenerType): void => {
        this.eventListeners.add(listener);
    };
    notifyEventListeners = (argument: ListenerArgsType) => {
        this.eventListeners.forEach((l) => l.call(null, argument));
    };
    removeEventListener = (listener: ListenerType): void => {
        this.eventListeners.delete(listener);
    };
}

export default EventListenerManager;
