import { EventStreamMarshaller, Message } from "@aws-sdk/eventstream-marshaller";
import { fromUtf8, toUtf8 } from "@aws-sdk/util-utf8-node";
import MicrophoneStream from "microphone-stream";
import { downsampleBuffer, pcmEncode } from "./audioUtils";
import { call } from "store/api";

export type TranscriptionListenerFn = {
    (trascription: string, isPartial: boolean): void;
};

type TranscriptionManager = {
    start: () => Promise<void>;
    stop: () => Promise<void>;
};

type ConnectionContext = {
    micStream: MicrophoneStream;
    socket: WebSocket;
};

const eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8);

function createAudioEventMessage(buffer: Buffer): Message {
    return {
        headers: {
            ":message-type": {
                type: "string",
                value: "event",
            },
            ":event-type": {
                type: "string",
                value: "AudioEvent",
            },
        },
        body: buffer,
    };
}

function convertAudioToBinaryMessage(audioChunk: Buffer, inputSampleRate: number): Uint8Array | undefined {
    const raw = MicrophoneStream.toRaw(audioChunk);

    if (raw === null) {
        return;
    }

    const downsampledBuffer = downsampleBuffer(raw, inputSampleRate, 16000);
    const pcmEncodedBuffer = pcmEncode(downsampledBuffer);
    const audioEventMessage = createAudioEventMessage(Buffer.from(pcmEncodedBuffer));
    const binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
}

const getMediaMediaStream = (): Promise<MediaStream> => {
    return window.navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
    });
};

function handleEventStreamMessage(messageJson: any, transcriptionListener: TranscriptionListenerFn) {
    const results = messageJson.Transcript.Results;
    if (results.length > 0) {
        if (results[0].Alternatives.length > 0) {
            let transcript = results[0].Alternatives[0].Transcript;
            // fix encoding for accented characters
            transcript = decodeURIComponent(escape(transcript));
            const isPartial = results[0].IsPartial;
            transcriptionListener(transcript, isPartial);
        }
    }
}

function closeSocket({ micStream, socket }: ConnectionContext): void {
    if (socket && socket.readyState === socket.OPEN) {
      micStream.stop();
      const emptyMessage = createAudioEventMessage(Buffer.from(new Buffer([])));
      const emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
      socket.send(emptyBuffer);
      socket.close();
    }
}

function getPresignedUrl() {
    return call("GET", "/provider/v1/transcribe/predefineUri", {
        dialogType: "DICTATION",
        specialty: "PRIMARYCARE",
    });
}

const createWebSocketStreamer = (transcriptionListener: TranscriptionListenerFn, url: string) =>
    function streamAudioToWebSocket(userMediaStream: MediaStream): Promise<ConnectionContext> {
        return new Promise((resolve, reject) => {
            try {
                let inputSampleRate: number;
                let socketError = false;
                let transcribeException = false;
                const micStream = new MicrophoneStream();

                // @ts-ignore
                micStream.on("format", function (data: AudioContext) {
                    inputSampleRate = data.sampleRate;
                });

                micStream.setStream(userMediaStream);
                const socket = new WebSocket(url);
                socket.binaryType = "arraybuffer";

                socket.onopen = function () {
                    // @ts-ignore
                    micStream.on('data', function (rawAudioChunk: any) {
                        const binary = convertAudioToBinaryMessage(rawAudioChunk, inputSampleRate);
                        if (binary && socket.readyState === socket.OPEN) {
                        socket.send(binary);
                        }
                    });
                    resolve({
                        micStream,
                        socket
                    });
                };
                socket.onmessage = function (message: MessageEvent) {
                    const messageWrapper = eventStreamMarshaller.unmarshall(Buffer.from(message.data));
                    const messageBody = JSON.parse(String.fromCharCode.apply(String, Array.from(messageWrapper.body)));
                    if (messageWrapper.headers[":message-type"].value === "event") {
                        handleEventStreamMessage(messageBody, transcriptionListener);
                    } else {
                        transcribeException = true;
                        console.error(messageBody.Message);
                    }
                };
                socket.onerror = function () {
                    reject('Connection error. Try again.');
                    socketError = true;
                };
                socket.onclose = function (closeEvent: any) {
                    micStream.stop();
                    if (!socketError && !transcribeException) {
                        if (closeEvent.code !== 1000) {
                        console.error(closeEvent.reason);
                        }
                    }
                };
            } catch (error) {
                reject(error);
            }
        });
    };

export const createTranscriptionManager = (transcriptionListener: TranscriptionListenerFn): TranscriptionManager => {
    let connectionContext: ConnectionContext | null;
    return {
        start: async () => {
            const presignedUrl = await getPresignedUrl();
            const mediaStream = await getMediaMediaStream();
            const webSocketStreamer = await createWebSocketStreamer(transcriptionListener, presignedUrl);
            const createdConnectionContext = await webSocketStreamer(mediaStream);
            connectionContext = createdConnectionContext;
        },
        stop: () => {
            if (connectionContext) {
                closeSocket(connectionContext);
                connectionContext = null;
            }
            return Promise.resolve();
        },
    };
};
