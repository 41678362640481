import React from "react";
import { Box } from "@mui/material";
import { FormattedRelativeTime } from "react-intl";
import { ReactComponent as BellSvg } from "icon-library/SVG/Bell.svg";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { createSvgIcon } from "utils";
import { useStyles } from "./styles";

const Bell = createSvgIcon(BellSvg);

type NotificationItemProps = {
    createdDate: string;
    title: string;
    onClick: () => void;
};

export const NotificationItemInfo: React.FC = ({ children }) => {
    return (
        <CustomTypography className="notificationItem__info" variant="subtitle2">
            {children}
        </CustomTypography>
    );
};

export const NotificationItemName: React.FC = ({ children }) => {
    return (
        <CustomTypography className="notificationItem__name" variant="subtitle1">
            {children}
        </CustomTypography>
    );
};

const NotificationItem: React.FC<NotificationItemProps> = ({ createdDate, title = "", onClick, children }) => {
    const classes = useStyles();
    const time = Math.floor((new Date(createdDate).getTime() - Date.now()) / 1000);

    return (
        <Box className={classes.notificationItem} onClick={onClick}>
            <Box width={1}>
                <Box className="notificationItem__titleRow">
                    <Box display="flex" alignItems="center">
                        <Bell className="notificationItem__bellIcon" />
                        <CustomTypography className="notificationItem__title" variant="notificationTitle">
                            {title}
                        </CustomTypography>
                    </Box>
                    <CustomTypography className="notificationItem__timePast" variant="subtitle2">
                        <FormattedRelativeTime style={`short`} value={time} updateIntervalInSeconds={5} />
                    </CustomTypography>
                </Box>
                <Box className="notificationItem__content">{children}</Box>
            </Box>
        </Box>
    );
};

export default NotificationItem;
