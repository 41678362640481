import { Box, Divider, List, Tooltip } from "@mui/material";
import { AppReduxStore as AppReduxStoreAdmin } from "admin/store/reducerTypes";
import FullLogo from "assets/FullLogo.svg";
import classNames from "classnames";
import { ReactComponent as ChevronUpLeft } from "icon-library/SVG/Chevron_Left.svg";
import { ReactComponent as ChevronUpRight } from "icon-library/SVG/Chevron_Right.svg";
import { useEnabledFeatureFlagNames } from "lib/security/feature-flag-hooks";
import { useEnabledPermissions } from "lib/security/permission-hooks";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { AppReduxStore } from "store/reducerTypes";
import { map } from "underscore";
import config from "./config";
import NavItem from "./NavItem";
import { defineAdminSideNav, filterNavigationByAccess } from "./sideNavGuard";
import SideNavHeader from "./SideNavHeader/SideNavHeader";
import { useStyles } from "./styles";

// SideNav displays possible routes and functionalities based on provided config.
const SideNav = () => {
    const [open, setOpen] = useState(true);
    const [localConfig, updateLocalConfig] = useState<any>({ routes: {}, actions: {}, adminRoutes: {} });
    const [showTooltip, setShowTooltip] = useState(false);
    const { currentProfileRoleJoin } = useSelector((store: AppReduxStore) => store.user);
    const adminStore = useSelector((state: AppReduxStoreAdmin) => state);
    const adminRouteMatch = useRouteMatch("/admin");
    const classes = useStyles({ open });
    const enabledFeatureFlagNames = useEnabledFeatureFlagNames();
    const grantedPermissions = useEnabledPermissions();
    const dispatch = useDispatch()

    useEffect(() => {
        if (currentProfileRoleJoin?.products) {
            updateLocalConfig({
                routes: filterNavigationByAccess(config.routes, grantedPermissions, enabledFeatureFlagNames || []),
                actions: filterNavigationByAccess(config.actions, grantedPermissions, enabledFeatureFlagNames || []),
                adminRoutes: defineAdminSideNav(config.adminRoutes, currentProfileRoleJoin, adminStore),
            });
        }
    }, [currentProfileRoleJoin, adminStore.auth, adminStore.license, adminStore, enabledFeatureFlagNames, grantedPermissions]);

    const isAdminSite = Boolean(adminRouteMatch);
    const routeItems = map(isAdminSite ? localConfig.adminRoutes : localConfig.routes, (i, key) => (
        <NavItem item={i} key={key} open={open} />
    ));
    const actionItems = map(localConfig.actions, (i, key) => <NavItem item={i} key={key} open={open} />).filter((item: any) => {
        return item;
    });
    const isOverflownListItems = (): boolean => {
        const appLinks = document.getElementById("navLinks") as HTMLElement;
        if (appLinks) {
            return appLinks.scrollHeight > appLinks.clientHeight
        }
        return false
    }
    return (
        <Box id="divSideNav" className={classes.sideNav}>
            <Box display="flex" flexDirection="column" width={1} flex={1}>
                <SideNavHeader open={open} />
                <Box flex={1} display="flex" justifyContent="space-between" flexDirection="column">
                    <List id="navLinks" classes={{ root: classNames(classes.listRoot, "listRoot--grow") }}>
                        <Box>
                            {routeItems}
                        </Box>
                        <Box className={classes.actionContainer}>
                            {isOverflownListItems() && <Divider light className={classes.navDivider} />}
                            {actionItems}
                        </Box>
                    </List>
                </Box>
            </Box>
            <Tooltip
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                title={<FormattedMessage id={`SideNav.menuItem.${!open ? "expand" : "collapse"}`} />}
            >
                <Box
                    className={classes.collapseButton}
                    onClick={() => {
                        setOpen(!open);
                        setShowTooltip(false);
                        dispatch({ type: 'UPDATE_NAV_COLLAPSE_FLAG',payload:open })
                    }}
                    // TODO: a more expressive id should be used, but we need to align with QA first
                    id="QA_collapseButton"
                >
                    {open ? (
                        <ChevronUpLeft className={classes.collapseIcon} onMouseEnter={() => setShowTooltip(true)} />
                    ) : (
                        <ChevronUpRight className={classes.collapseIcon} onMouseEnter={() => setShowTooltip(true)} />
                    )}
                </Box>
            </Tooltip>
            <Box className={classes.fullLogoWrapper} id="QA_altaisIcon">
                <img
                    className={open ? classes.fullLogo : classes.fullLogoRotated}
                    height="40"
                    width="124"
                    alt="altais"
                    src={FullLogo}
                />
            </Box>
        </Box>
    );
};

export default SideNav;
