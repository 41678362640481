import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import PMG from '../../../png/assets/images/PMG Logo 1.png';
import {Box, Button} from "@mui/material";
import { useParams } from "react-router-dom";
// @ts-ignore
const {ENABLE_API_URL} = window.env

//TODO need to remove Demo purpose
const RegisteredProfiles = () => {
    const classes = useStyles()
    const [practiceName, setPracticeName] = useState<any>([])
    const { practiceId } = useParams<any>()
   
    useEffect(()=>{
        const url = `${ENABLE_API_URL}/api/conference/v1/evisit/guest/findAllRegisteredProfilesInPractice/${practiceId}`;
        fetch(url,
            {
                method: 'GET',
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                   }
            })
        .then(response => response.json())
        .then((data:any) => {
            //@ts-ignore
            Array.isArray(data)&&setPracticeName(data)
        })
    },[])

    const handleConnect = (url:string) =>{
        window.open(url)
    }
    
    return (
        <Box className={classes.contentWrapper}>
            <img className={classes.image} src={PMG} alt="PMG Logo" />
            <h2>Our Providers for your care</h2>
            {
                practiceName.map((item:any)=>{
                    return(
                        <Box className={classes.practicename}>
                        <h3>{item.providerProfileInfo.firstName} {item.providerProfileInfo.lastName}</h3>
                        <Button 
                          className={classes.clickBtn}
                          onClick={e=>handleConnect(item?.providerProfileInfo?.visitUrl)}
                          >Connect</Button>
                        </Box>
                    )
                    
                })
            }
            
        </Box>
        
    )
}

export default RegisteredProfiles