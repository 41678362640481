import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { MODAL_TYPES } from "constant";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { setActiveModal } from "store/actions";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import Modal from "components/Modal/Modal";
import { useStyles } from "./styles";
import { AppReduxStore } from "store/reducerTypes";
import { Props } from "./types";
import SVGIcon from "common/components/svg-icon/svg-icon";
import { ReactComponent as Provider } from "icon-library/SVG/Stethoscope.svg"
import { ReactComponent as Patient } from "icon-library/SVG/User_Single.svg"


const MessageReadStatus = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const onClose = () => dispatch(setActiveModal(''))
    const { activeModalProps } = useSelector((store: AppReduxStore) => store.modal);
    const messageDetails = (activeModalProps as Props)?.messageDetails;

    return (
        <Modal classes={{ paper: classes.modal }} id={MODAL_TYPES.PROVIDER.MESSAGE_READ_STATUS}>
            {messageDetails?.read?.length !== 0 &&
                <>
                    <Box className={classes.titleWrapper}>
                        <CustomTypography variant="modalHeader">
                            <FormattedMessage id={messageDetails?.participants?.length > 2 ? "Message_status_read":"Message_status_read_single"} />
                        </CustomTypography>
                        <Close className={classes.close} onClick={onClose} id="QA_Message_Read_Status_close_button" />
                    </Box>
                    <Box className={classes.content}>
                        {
                            messageDetails?.read?.map((item: any) => {
                                return (
                                    <Box className={classes.readCard}>
                                        <Box className={classes.recepientDetails}>
                                            <SVGIcon svg={item?.recepientsDetails?.userType === "PROVIDER" ? Provider : Patient} size="scale800" color={item?.recepientsDetails?.userType === "PATIENT" ? "LBLUE" : "GRAY_50"} />
                                            <Box className={classes.participantName}>
                                                <Typography className={item?.recepientsDetails?.userType === "PATIENT" ? classes.nameText : classes.unReadnameText}>
                                                    {item?.recepientsDetails?.userType === "PROVIDER" ? `${item?.recepientsDetails?.providerProfile.lastName}` : `${item?.recepientsDetails?.patientProfile.lastName}`},
                                                    &nbsp;<span>{item?.recepientsDetails?.userType === "PROVIDER" ? `${item?.recepientsDetails?.providerProfile.firstName}` : `${item?.recepientsDetails?.patientProfile.firstName}`}</span>
                                                </Typography>
                                                <Typography className={item?.recepientsDetails?.userType === "PATIENT" ? classes.subReadText : classes.subUnReadText}>{item?.recepientsDetails?.userType}</Typography>
                                            </Box>
                                        </Box>
                                        <Typography className={item?.recepientsDetails?.userType === "PATIENT" ? classes.subReadText : classes.subUnReadText}>
                                            READ
                                        </Typography>
                                    </Box>
                                )
                            })
                        }

                    </Box>
                </>
            }
            {messageDetails?.unRead?.length !== 0 &&
                <>
                    <Box className={messageDetails?.read?.length === 0?classes.titleWrapper:classes.titleUnreadWrapper}>
                        <CustomTypography variant="modalHeader">
                            <FormattedMessage id={messageDetails?.participants?.length > 2 ?"Message_status_Unread":"Message_status_Unread_single" }/>
                        </CustomTypography>
                        {messageDetails?.read?.length === 0 && <Close className={classes.close} onClick={onClose} id="QA_Message_Read_Status_close_button" />}
                    </Box>
                    <Box className={classes.content}>
                        {
                            messageDetails?.unRead?.map((item: any) => {
                                return (
                                    <Box className={classes.unReadCard}>
                                        <Box className={classes.recepientDetails}>
                                            <SVGIcon svg={item?.recepientsDetails?.userType === "PROVIDER" ? Provider : Patient} size="scale800" color={item?.recepientsDetails?.userType === "PATIENT" ? "LBLUE" : "GRAY_50"}  />
                                            <Box className={classes.participantName}>
                                                <Typography className={item?.recepientsDetails?.userType === "PATIENT" ?classes.nameText: classes.unReadnameText}>
                                                    {item?.recepientsDetails?.userType === "PROVIDER" ? `${item?.recepientsDetails?.providerProfile.lastName}` : `${item?.recepientsDetails?.patientProfile.lastName}`},
                                                    &nbsp;<span>{item?.recepientsDetails?.userType === "PROVIDER" ? `${item?.recepientsDetails?.providerProfile.firstName}` : `${item?.recepientsDetails?.patientProfile.firstName}`}</span>
                                                </Typography>
                                                <Typography className={item?.recepientsDetails?.userType === "PATIENT" ? classes.subReadText : classes.subUnReadText}>{item?.recepientsDetails?.userType}</Typography>
                                            </Box>
                                        </Box>
                                        <Typography className={item?.recepientsDetails?.userType === "PATIENT" ? classes.subReadText : classes.subUnReadText}>
                                            NOT READ
                                        </Typography>
                                    </Box>
                                )
                            })
                        }

                    </Box>
                </>
            }
        </Modal>
    )
}

export default MessageReadStatus