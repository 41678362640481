import React, { useMemo, useState } from "react";
import { SelectChangeEvent, Select, Typography, Box, Grid, FormControlLabel, FormLabel, FormGroup, Checkbox, Divider, MenuItem, FormControl } from "@mui/material";
import { useStyles } from "./styles";
import { ITriggerConfig, ITAppointmentTypeConfig, ITriggerConfigOptions, IExistingTriggerConfig } from "./types";
import { useDispatch, useSelector } from 'react-redux';
import { AppReduxStore } from 'admin/store/reducerTypes';
import { IFormInput } from "admin/views/viewsTypes";
import { savePatientForm,createNewQuestionOptions, fetchQuestionnaireAppointmentTypes, fetchQuestionnaireTriggers, saveQuestionnaireAppointmentTypes, saveQuestionnaireTriggers, fetchQuestionnaireNotificationTriggers, createQuestionnaireNotificationTriggers, updateQuestionnaireNotificationTriggers, deleteQuestionnaireNotificationTriggerById } from 'admin/store/actions/configuration';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

type Props = {
    currentForm: any;
};

  const Triggers: React.FC<Props> = ({currentForm}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { configuration } = useSelector((state: AppReduxStore) => state);
  const isFormReadOnly: boolean = configuration && configuration.isFormReadOnly;
  const appointmentTypes = configuration && configuration.visitTypes ? configuration.visitTypes : null;
  const notificationTriggers = configuration && configuration.notificationTriggers ? configuration.notificationTriggers : null;
  const selectedNotificationTriggers = configuration && configuration.questionnaireNotificationTriggers ? configuration.questionnaireNotificationTriggers : null;
  const rules = configuration && configuration.rules ? configuration.rules : null;
  const [whenToSendFormState, setWhenToSendFormState] = useState<number>(-1);
  const [formFilledOutTimeRangeVal, setFormFilledOutTimeRangeVal] = useState<string>('');
  const [state, setState] = useState<any>({ type: {}, gender: {}, age: {}, visitTypes: {}, didNotFillForm: {}, notificationTriggers: {} });
  const [allState, setAllState] = useState<any>({ type: false, gender: false, age: false, visitTypes: false });
  const { handleSubmit, setValue } = useForm<IFormInput>();

  const getStateTriggerMap = (str: string) => {
    switch(str) {
        case 'PATIENT_AGE':
        return 'age';
        case 'PATIENT_GENDER':
            return 'gender';
        case 'PATIENT_TYPE':
            return 'type';
        case 'DID_NOT_FILL_FORM':
            return 'didNotFillForm';
        default:
            return '';
    }
  }

  useMemo(() => {
    setValue('description', currentForm.description);
    setValue('type', currentForm.type);
  }, [currentForm.description, currentForm.type, setValue])

  useMemo(() => {
    if (currentForm?.id && (configuration?.sPatientFormAAppointmentTypes === null)) {
        dispatch(fetchQuestionnaireAppointmentTypes(currentForm.id));
    }
  }, [dispatch, configuration.sPatientFormAAppointmentTypes, currentForm.id]);

  useMemo(() => {
    if (state?.visitTypes && Object.keys(state?.visitTypes).length === 0 && configuration.sPatientFormAAppointmentTypes?.length > 0) {
        const stateObj: any = { visitTypes: {} };
        const stateAllObj: any = { visitTypes: false };
        configuration.sPatientFormAAppointmentTypes?.forEach( (e: any) => {
            stateObj.visitTypes[e.appointmentTypeInfo.id] = e.isEnabled;
            let currentValueState: boolean[] = Object.values(stateObj.visitTypes);
            currentValueState = currentValueState.filter((e: boolean) => e === true);
            if(currentValueState?.length !== 0 && appointmentTypes?.length === currentValueState?.length) {
                stateAllObj.visitTypes = true;
            } else {
                stateAllObj.visitTypes = false;
            }
        } );
        setAllState({ ...allState, ...stateAllObj });
        setState({ ...state, ...stateObj });
    }
  }, [configuration.sPatientFormAAppointmentTypes, appointmentTypes, state, setState, allState, setAllState]);

  useMemo(() => {
    if (currentForm?.id && (configuration?.sPatientFormATriggers === null)) {
        dispatch(fetchQuestionnaireTriggers(currentForm.id));
    }
  }, [dispatch, configuration.sPatientFormATriggers, currentForm.id]);

  useMemo(() => {
    if(configuration?.sPatientFormATriggers?.length > 0) {
        if(state && Object.keys(state.type).length === 0 && Object.keys(state.age).length === 0 && Object.keys(state.gender).length === 0 && Object.keys(state.didNotFillForm).length === 0) {
            const triggerTypes: string[] = Object.keys(state).filter(
                (e: string) => e !== "visitTypes" && e !== "notificationTriggers"
            );
            const stateObj: any = { type: {}, gender: {}, age: {}, didNotFillForm: {} };
            const stateAllObj: any = { type: false, gender: false, age: false };
            triggerTypes.forEach( (triggerType: string ) => {
                configuration.sPatientFormATriggers?.forEach( (e: IExistingTriggerConfig) => {
                    if(getStateTriggerMap(e.rule.ruleType) === triggerType) {
                        stateObj[triggerType][e.rule.id] = e.isEnabled;
                        if(triggerType !== 'didNotFillForm') {
                            let currentValueState: boolean[] = Object.values(stateObj[triggerType]);
                            currentValueState = currentValueState.filter((e: boolean) => e === true);
                            if(currentValueState?.length !== 0 && rules[e.rule.ruleType]?.length === currentValueState?.length ) {
                                stateAllObj[triggerType] = true;
                            } else {
                                stateAllObj[triggerType] = false;
                            }
                        }
                        if(e.isEnabled && e.rule.ruleType === 'DID_NOT_FILL_FORM') {
                            setFormFilledOutTimeRangeVal(e.rule.id);
                        }
                    }
                });
            });
            setAllState({ ...allState, ...stateAllObj });
            setState({ ...state, ...stateObj });
        }
    }
  }, [configuration.sPatientFormATriggers, rules, state, setState, allState, setAllState]);

  useMemo(() => {
      if (currentForm?.id && (selectedNotificationTriggers === null)) {
        dispatch(fetchQuestionnaireNotificationTriggers(currentForm.id));
      }
  }, [dispatch, selectedNotificationTriggers, currentForm.id]);

  useMemo(() => {
    if (state && Object.keys(state.notificationTriggers).length === 0 && selectedNotificationTriggers?.length > 0) {
        const stateObj: any = {};
        selectedNotificationTriggers.forEach((selectedNotificationTrigger:any) => {
            const triggerInfo = selectedNotificationTrigger.notificationTriggerInfo;
            stateObj[triggerInfo.id] = selectedNotificationTrigger.isEnabled;
            if ((triggerInfo.notificationType === "TASK_CREATE") && (selectedNotificationTrigger.isEnabled)) {
                setWhenToSendFormState(triggerInfo.minutesUntilAppointment);
            }
        });
        setState({ ...state, notificationTriggers: stateObj });
    }
  }, [selectedNotificationTriggers, state, setState]);

  const resetFormFilledOutTimeRangeVal = () => {
    const stateObj: any = {};
    for (const [key] of Object.entries(state?.didNotFillForm)) {
        stateObj[key] = false;
    }
    return { didNotFillForm: stateObj };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stateAllObj: any = {};
    const newState = {...state[(event.target as HTMLInputElement).name], [(event.target as HTMLInputElement).value]: (event.target as HTMLInputElement).checked}
    setState({ ...state, [(event.target as HTMLInputElement).name]: newState });
    const currentState: boolean[] = Object.values(newState);
    stateAllObj[(event.target as HTMLInputElement).name] = currentState.every((i: boolean) => i === true);
    setAllState({ ...allState, ...stateAllObj });
  };

  const handleNotFilledChange = (event: SelectChangeEvent) => {
    const stateObj: any = resetFormFilledOutTimeRangeVal();
    if((event.target.value as string) !== '') {
        stateObj.didNotFillForm[event.target.value as string] = true;
        setState({ ...state, didNotFillForm: stateObj.didNotFillForm });
        setFormFilledOutTimeRangeVal(event.target.value as string);
    } else {
        setState({ ...state, didNotFillForm: stateObj.didNotFillForm });
    }
  };

  const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>, checkBoxes: any[]) => {
    const newState: any = {};
    const stateAllObj: any = {};
    checkBoxes.forEach( (e: any) => {
        newState[e.id] = (event.target as HTMLInputElement).checked
    } );
    stateAllObj[(event.target as HTMLInputElement).name] = (event.target as HTMLInputElement).checked
    setAllState({ ...allState, ...stateAllObj });
    setState({ ...state, [(event.target as HTMLInputElement).name]: newState })
  };

  const handleSelect = (id: string, event: SelectChangeEvent) => {
    const selectedValue = (event.target.value as string) as unknown as number;
    switch(id) {
        case 'send-form-notification':
            setWhenToSendFormState(selectedValue);
            const newState: any = Object.assign({}, state.notificationTriggers);
            notificationTriggers.filter((notificationTrigger:any) => {
                return (notificationTrigger.notificationType === "TASK_CREATE")
            }).forEach((notificationTrigger:any) => {
                 if (notificationTrigger.minutesUntilAppointment === selectedValue) {
                    newState[notificationTrigger.id] = true;
                 } else {
                    newState[notificationTrigger.id] = false;
                 }
            });
            notificationTriggers.filter((notificationTrigger:any) => {
                return (notificationTrigger.notificationType === "TASK_REMINDER")
            }).forEach((notificationTrigger:any) => {
                const checkValue = notificationTrigger.minutesUntilAppointment;
                if ((checkValue >= selectedValue) && (selectedValue !== -1)) {
                    newState[notificationTrigger.id] = false;
                }
            });
            setState({ ...state, notificationTriggers: newState });
            break;
    }
  };

  const shouldBeDisabled = (id: string) => {
    const checkValueObj = notificationTriggers.filter((notificationTrigger:any) => {
        return (notificationTrigger.id === id);
    })[0];
    const checkValue = checkValueObj.minutesUntilAppointment;
    if ((checkValue < whenToSendFormState) || (whenToSendFormState === -1))
        return false;
    else {
        return true;
    }
  };

  const processNotificationTriggers = () => {
      const initialTriggers = selectedNotificationTriggers;
      const currentTriggers: any[] = [];
      for (const [key, value] of Object.entries(state?.notificationTriggers)) {
          currentTriggers.push({
              isEnabled: value as boolean,
              notificationTriggerId: key as string,
              questionnaireId: currentForm.id as string
          });
      }

      const insertTriggers: any[] = [];
      const modifyTriggers: any[] = [];
      const deleteTriggers: string[] = [];

      currentTriggers.forEach((current:any) => {
          let initialized = false;
          initialTriggers.forEach((initial:any) => {
              if (current.notificationTriggerId === initial.notificationTriggerInfo.id) {
                  initialized = true;
                  if (current.isEnabled !== initial.isEnabled) {
                      // Modify existing trigger notifications...
                      modifyTriggers.push({
                          id: initial.id as string,
                          isEnabled: current.isEnabled as boolean,
                          notificationTriggerId: initial.notificationTriggerInfo.id as string,
                          questionnaireId: initial.questionnaireId as string
                      });
                  }
              }
          });
          if (!initialized) {
              // Create new trigger notifications...
              insertTriggers.push(current);
          }
      });

      initialTriggers.forEach((initial:any) => {
          let exist = false;
          currentTriggers.forEach((current:any) => {
              if (current.notificationTriggerId === initial.notificationTriggerInfo.id) {
                  exist = true;
              }
          });
          if (!exist) {
              // Delete trigger notifications...
              deleteTriggers.push(initial.notificationTriggerInfo.id);
          }
      });

      if (insertTriggers.length > 0)
        dispatch(createQuestionnaireNotificationTriggers(insertTriggers));
      if (modifyTriggers.length > 0)
        dispatch(updateQuestionnaireNotificationTriggers(modifyTriggers));
      if (deleteTriggers.length > 0) {
        deleteTriggers.forEach((deleteTrigger:string) => {
            dispatch(deleteQuestionnaireNotificationTriggerById(deleteTrigger));
        });
      }
  };

  const onSubmit = ( data: IFormInput) => {
    // Update Appointment Types
    const appointmentTypeValues: ITAppointmentTypeConfig[] = [];
    for (const [key, value] of Object.entries(state?.visitTypes)) {
        appointmentTypeValues.push({
            appointmentTypeId: key,
            isEnabled: value as boolean,
            questionnaireId: currentForm.id
        });
    }

    // Update Triggers
    const triggerTypes: string[] = Object.keys(state);
    const triggerValues: ITriggerConfig[] = [];
    triggerTypes.forEach( (triggerType: string ) => {
        if ((triggerType !== 'visitTypes') && (triggerType !== 'notificationTriggers')) {
            for (const [key, value] of Object.entries(state[triggerType])) {
                const foundIndex = triggerValues.findIndex((e) => e.ruleId === key);
                if(foundIndex === -1) {
                    const found: IExistingTriggerConfig = configuration.sPatientFormATriggers.find( (e: IExistingTriggerConfig) => e.rule.id === key );
                    triggerValues.push({
                        ruleId: key,
                        questionnaireRuleId: found?.id,
                        isEnabled: value as boolean,
                        questionnaireId: currentForm.id
                    });
                } else {
                    triggerValues[foundIndex].isEnabled = value as boolean;
                }
            }
        }
    });

    dispatch(saveQuestionnaireTriggers(triggerValues));
    dispatch(saveQuestionnaireAppointmentTypes(appointmentTypeValues));
    dispatch(createNewQuestionOptions(currentForm));
    dispatch(savePatientForm(currentForm));
    processNotificationTriggers();
  };

  return (
    <div className={classes.root}>
        
        <Typography variant="body1" component="p" gutterBottom>
        <FormattedMessage id="PatientFormConfig.Trigger.Instructions"/>
        </Typography>
        <Box mt={3} p={2} style={{background: '#FFF', border: '1px solid #D3D3D6'}}>
        <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2}>
        <Grid item xs={12}>
            <Typography color="textPrimary" variant="h4"><FormattedMessage id="PatientFormConfig.Target.SectionHeading" /></Typography>
            <Box pt={2} pl={2}>
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={3}><FormLabel><FormattedMessage id="PatientFormConfig.Target.Label.Type" />:</FormLabel></Grid>
                    <Grid item xs={9}>
                        <FormGroup row>
                        <FormControlLabel
                            disabled={isFormReadOnly}
                            control={<Checkbox checked={allState?.type ? allState?.type as boolean : false} 
                                color="primary"
                                onChange={(event) => { handleChangeAll(event, rules?.PATIENT_TYPE) }}
                                name="type"
                                className={classes.checkbox}
                            />}
                            label={intl.formatMessage({ id: "PatientFormConfig.Target.Label.Type.All" })}
                        />
                        </FormGroup>
                        <FormGroup row>
                        {rules?.PATIENT_TYPE?.map((type: ITriggerConfigOptions) => (
                        <FormControlLabel
                            className={classes.triggerTypes}
                            disabled={isFormReadOnly}
                            control={<Checkbox checked={state?.type[type?.id] ? state?.type[type?.id] as boolean : false}
                                color="primary"        
                                onChange={handleChange}
                                name="type"
                                value={type?.id}
                                className={classes.checkbox}
                            />}
                            key={type?.id}
                            label={type?.displayName}
                        />
                        ))}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={3}><FormLabel><FormattedMessage id="PatientFormConfig.Target.Label.Gender" />:</FormLabel></Grid>
                    <Grid item xs={9}>
                        <FormGroup row>
                        <FormControlLabel
                            disabled={isFormReadOnly}
                            control={<Checkbox checked={allState?.gender ? allState?.gender as boolean : false } 
                                color="primary" 
                                onChange={(event) => { handleChangeAll(event, rules?.PATIENT_GENDER ) } } 
                                name="gender"
                                className={classes.checkbox}
                            />}
                            label={intl.formatMessage({ id: "PatientFormConfig.Target.Label.Gender.All" })}
                        />
                        </FormGroup>
                        <FormGroup row>
                            {rules?.PATIENT_GENDER?.map((gender: ITriggerConfigOptions) => (
                            <FormControlLabel
                                className={classes.triggerTypes}
                                disabled={isFormReadOnly}
                                control={<Checkbox checked={state?.gender[gender?.id] ? state?.gender[gender?.id] as boolean : false } 
                                    color="primary"    
                                    onChange={handleChange} 
                                    name="gender" 
                                    value={gender?.id}
                                    className={classes.checkbox}
                                />}
                                key={gender?.id}
                                label={gender?.displayName}
                            />
                            ))}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={3}><FormLabel><FormattedMessage id="PatientFormConfig.Target.Label.Age" />:</FormLabel></Grid>
                    <Grid item xs={9}>
                        <FormGroup row>
                        <FormControlLabel
                            disabled={isFormReadOnly}
                            control={<Checkbox checked={allState?.age ? allState?.age as boolean : false } 
                                color="primary" 
                                onChange={(event) => { handleChangeAll(event, rules?.PATIENT_AGE ) } }
                                name="age"
                                className={classes.checkbox}
                            />}
                            label={intl.formatMessage({ id: "PatientFormConfig.Target.Label.Age.All" })}
                        />
                        </FormGroup>
                        <FormGroup row>
                        {rules?.PATIENT_AGE?.map((age: ITriggerConfigOptions) => (
                        <FormControlLabel
                            className={classes.triggerTypes}
                            disabled={isFormReadOnly}
                            control={<Checkbox checked={state?.age[age?.id] ? state?.age[age?.id] as boolean : false } 
                                color="primary"    
                                onChange={handleChange}                                  
                                name="age" 
                                value={age?.id}
                                className={classes.checkbox}
                            />}
                            key={age?.id}
                            label={age?.displayName}
                        />
                        ))}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={3}><FormLabel><FormattedMessage id="PatientFormConfig.Target.Label.PreviouslyFilledForm.FilledForm" />:</FormLabel></Grid>
                    <Grid item xs={4}>
                    <FormControl>
                    <Select
                            value={formFilledOutTimeRangeVal}
                            disabled={isFormReadOnly}
                            variant="outlined"
                            onChange={(event: SelectChangeEvent) => { handleNotFilledChange(event) } }
                            displayEmpty
                            >
                            <MenuItem value="">
                                <FormattedMessage id="PatientFormConfig.Target.Label.PreviouslyFilledForm.FormNotFilled.Any" />
                            </MenuItem>
                            {rules?.DID_NOT_FILL_FORM?.sort((a:ITriggerConfigOptions, b:ITriggerConfigOptions) => {
                                return (a.orderIndex - b.orderIndex)
                            })?.map((formFilledOutTimeRange: ITriggerConfigOptions) => (
                            <MenuItem key={formFilledOutTimeRange.id} value={formFilledOutTimeRange.id}>
                                {formFilledOutTimeRange.displayName}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={5}></Grid>
                </Grid>
            </Box>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={12}>
            <Typography color="textPrimary" variant="h4"><FormattedMessage id="PatientFormConfig.FrequencyReminders.SectionHeading" /></Typography>
            <Box pt={2} pl={2}>
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={3}>
                        <FormLabel><FormattedMessage id="PatientFormConfig.FrequencyReminders.Label.Prior" />:</FormLabel>
                    </Grid>
                    <Grid item xs={9}>
                    <FormGroup row>
                        <FormControlLabel
                            disabled={isFormReadOnly}
                            control={<Checkbox checked={allState?.visitTypes ? allState?.visitTypes as boolean : false } 
                                color="primary"
                                onChange={(event) => { handleChangeAll(event, appointmentTypes ) } }
                                name="visitTypes" 
                                className={classes.checkbox}
                            />}
                            label={intl.formatMessage({ id: "PatientFormConfig.FrequencyReminders.Label.Prior.VisitType.All" })}
                        />
                    </FormGroup>
                    <FormGroup row>
                    {appointmentTypes?.map((appointmentType: any) => (
                        <FormControlLabel
                            className={classes.appointmentTypes}
                            key={appointmentType.id}
                            disabled={isFormReadOnly}
                            control={<Checkbox checked={state?.visitTypes[appointmentType.id] ? state?.visitTypes[appointmentType.id] as boolean : false } 
                                color="primary"    
                                onChange={handleChange}
                                name="visitTypes"
                                value={appointmentType.id}                                 
                                className={classes.checkbox}
                            />}
                            label={appointmentType.name}
                        />
                        ))}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={3}><FormLabel><FormattedMessage id="PatientFormConfig.FrequencyReminders.Label.Prior.WhenToSend" /></FormLabel></Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <Select
                                value={(whenToSendFormState as unknown) as string}
                                variant="outlined"
                                disabled={isFormReadOnly}
                                displayEmpty
                                id="send-form-notification"
                                onChange={(event: SelectChangeEvent) : void => handleSelect("send-form-notification", event)}
                                >
                            <MenuItem value={-1}>
                                <FormattedMessage id="PatientFormConfig.SendForm.Label.SelectOne" />
                            </MenuItem>
                            {notificationTriggers.sort((a:any, b:any)=>{
                                return (b.orderIndex - a.orderIndex)
                            }).filter((notificationTrigger:any)=>{
                                return (notificationTrigger.notificationType === "TASK_CREATE")
                            }).map((notificationTrigger: any) => (
                                <MenuItem key={notificationTrigger.id} value={notificationTrigger.minutesUntilAppointment}>
                                {notificationTrigger.displayName}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={3}><FormLabel><FormattedMessage id="PatientFormConfig.FrequencyReminders.Label.Prior.WhenToRemind" /></FormLabel></Grid>
                    <Grid item xs={9}>
                    <FormGroup row>
                        {notificationTriggers?.sort((a:any, b:any)=>{
                            return (b.orderIndex - a.orderIndex)
                        }).filter((notificationTrigger:any, index:number)=>{
                            return ((index !== 0) && (notificationTrigger.notificationType === "TASK_REMINDER"))
                        }).map((notificationTrigger:any) => (
                            <FormControlLabel
                                className={classes.appointmentTypes}
                                key={notificationTrigger.id}
                                label={notificationTrigger.displayName}
                                name="notificationTriggers"
                                disabled={isFormReadOnly || shouldBeDisabled(notificationTrigger.id)}
                                control={<Checkbox checked={state?.notificationTriggers[notificationTrigger.id] ? state?.notificationTriggers[notificationTrigger.id] as boolean : false }
                                    color="primary"
                                    onChange={handleChange}
                                    value={notificationTrigger.id}
                                    className={classes.checkbox}
                                />}
                            />
                        ))}
                    </FormGroup>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
        </Grid>
        </form>
        </Box>
    </div>
  );
}

export default Triggers