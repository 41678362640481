import { makeStyles } from "@mui/styles";
import { BORDER_RADIUS, COLORS, SIZING } from "theme";

export const useStyles = makeStyles(() => ({
        headerWrapper: {
            width: "100%",
            padding: SIZING.scale300,
            paddingLeft: SIZING.scale800,
            background: "#F0F0F2",
            boxShadow: "inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)"
        },
        tabsRoot: {
            background: COLORS.SEARCH_FILTER_TAB_BG,
            borderRadius: BORDER_RADIUS.PILL,
            minHeight: 0,
        },
        tabRoot: {
            display: "flex",
            alignItems: "center",
            minHeight: 0,
            minWidth: 0,
            padding: SIZING.scale200,
            backgroundColor: "transparent",
            flexDirection: "row",
            boxShadow: "unset",
            color: COLORS.BODY_TEXT,
            marginRight: "unset",
            "&.tabSelected": {
                color: COLORS.BLACK,
            }
        },
        indicator: {
            display: "none"
        },
        count: {
            marginLeft: SIZING.scale200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& span": {
                display: "flex",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 700
            }
        },
        tabSelected: {
            background: COLORS.SELECTED_TAB_BG,
            borderRadius: BORDER_RADIUS.PILL,
            border: `2px solid rgba(0, 0, 0, 0.05)`
        }
}));