import { ApiCommunicationContext, ApiStatusCode, HttpMethod } from "../types";
import { navigatorFetch } from "lib/browser-wrapper";

const KNOWN_CONTENT_TYPES = {
    "blob": ["application/pdf"],
    "json": ["application/json"],
    "text": ["plain/text"]
} as const;
type ResponseContentType = keyof typeof KNOWN_CONTENT_TYPES;

const getResponseContentType = (response: Response): ResponseContentType => {
    const headerContent = response.headers.get("Content-Type") ?? "application/json";
    return Object.keys(KNOWN_CONTENT_TYPES).reduce((contentType, knownContentType) =>
        // @ts-expect-error ts2345 headerContent is always defined
        KNOWN_CONTENT_TYPES[knownContentType as ResponseContentType].includes(headerContent) ? knownContentType : contentType
    , "") as ResponseContentType;
}

const getResponseContent = async <TResponse> (response: Response): Promise<TResponse> => {
    const contentType = getResponseContentType(response);
    switch (contentType) {
        case "blob":
            return (await response.blob()) as unknown as TResponse;
        case "text":
            return (await response.text()) as unknown as TResponse;
        default:
            return (await response.json()) as unknown as TResponse;
    }
}

const prepareRequestInit = <TRequest, TResponse, TContent> (ctx: ApiCommunicationContext<TRequest, TResponse, TContent>) => {
    const apiRequest = ctx.request;
    const abortController = new AbortController();

    ctx.registerAbortListener(() => {
        abortController.abort();
        ctx.status = ApiStatusCode.ABORTED;
        return ctx;
    });
    
    const requestInit: RequestInit = {
        method: HttpMethod.GET,
        ...apiRequest,
        credentials: "omit",
        signal: abortController.signal,
    };

    // @ts-expect-error ts2345: method is always defined
    if ([HttpMethod.POST, HttpMethod.PUT].includes(requestInit.method)) {
        requestInit.body = (apiRequest.params instanceof window.FormData) ? apiRequest.params : JSON.stringify(apiRequest.params);
        requestInit.headers = {
            "Content-Type": "application/json",
            ...(apiRequest.headers ?? {})
        }
    }

    return requestInit;
}

const fetchViaBrowser = async <TRequest, TResponse, TContent> (ctx: ApiCommunicationContext<TRequest, TResponse, TContent>): Promise<ApiCommunicationContext<TRequest, TResponse, TContent>> => {
    const apiRequest = ctx.request;
    const requestInit = prepareRequestInit(ctx);
    
    ctx.response = {};
    try {
        const response = await navigatorFetch(apiRequest.url, requestInit);
        ctx.status = response.status;
        if (response.status === 204) {
            ctx.response.rawContent = null;
        } else {
            ctx.response.rawContent = await getResponseContent<TResponse>(response);
        }
        if (response.status >= 400) {
            ctx.response.error = `${response.status} ${response.statusText}`;
        }
    } catch (error: any) {
        ctx.response.error = Object.getPrototypeOf(error) === "Error" ? (error as Error) : new Error(error.toString());
        ctx.status = error?.name === "AbortError" ? ApiStatusCode.ABORTED : ApiStatusCode.NETWORK_ERROR;
    }
    return ctx;
}

export default fetchViaBrowser;
