import React, {useEffect, useState} from "react";
import Modal from "components/Modal/Modal";
import {MODAL_TYPES, ZOOM_IFRAME_MESSAGES} from "constant";
import {useSelector, useDispatch} from "react-redux";
import {Box, Button, DialogContent, DialogTitle, Typography} from "@mui/material";
import {AppReduxStore} from "store/reducerTypes";
import {joinCall, setActiveModal, closeEvistMeeting, closeMeeting,endCalling} from "store/actions";
import {FormattedMessage} from "react-intl";
import InviteColleague from "components/Evisit/InviteColleague/InviteColleague";
import {ReactComponent as Cross} from "icon-library/SVG/Cross.svg";
import {createSvgIcon, getAge} from "utils";
import {SIZING} from "theme";
import {useStyles} from "./styles";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import { outgoingCall } from "store/actions";
import NoAnswer  from "components/ZoomInfo/NoAnswer/NoAnswer";
import CallFailed  from "components/ZoomInfo/CallFailed/CallFailed";
import CallEnded  from "components/ZoomInfo/CallEnded/CallEnded";

const Leave = createSvgIcon(Cross)

// ActiveZoomMeeting displays a window with a zoom call. Based on the current state of the zoom call different screens
// will be shown to the user to inform them how they can interact with the meeting
const ActiveZoomMeeting = () => {
    const { firstName, lastName } = useSelector((state: AppReduxStore) => state.user)
    const { toName, fromName,from,to,calling, meetingId, pin, isHost, declined,waiting, ended,joinAgainStatus,isEvistMeeting, profile,currentEvisitDetails,error } = useSelector((state: AppReduxStore) => state.meeting)
    const { patientFirstName = "", patientTwilioId } = currentEvisitDetails || {};
    const meetingURL = "/zoom/index.html"
    const dispatch = useDispatch()
    const classes = useStyles({isHost, calling, ended})
    const renderedName = isHost ? toName : fromName
    const [peerNoResponse,updatePeerNoResponse] = useState(false)
    const [timeoutInstance,updateTimeoutInstance] = useState('')

    // List for messages coming from the zoom iframe when the user interacts or when the meeting state has changes
    useEffect(() => {
        const joinedMeetingListener = (e: MessageEvent) => {
            if (e.origin !== window.location.origin) return
            switch (e.data.type) {
                case ZOOM_IFRAME_MESSAGES.INIT:
                    const initZoom = {
                        meetingId,
                        isHost,
                        pin,
                        username: `${firstName} ${lastName}`,
                        joinAgainStatus,
                        isEvistMeeting
                    }
                       // @ts-ignore
                       if (e.source) e.source.postMessage(initZoom, e.origin)
                    break
                case ZOOM_IFRAME_MESSAGES.MEETING_JOINED:
                    if (isHost) dispatch(joinCall())
                    break
                case ZOOM_IFRAME_MESSAGES.MEETING_ENDED:
                    dispatch({type: 'CALL_ENDED'})
                    updatePeerNoResponse(false)
                    break
                case ZOOM_IFRAME_MESSAGES.MEETING_END_FOR_ALL:
                    dispatch(closeEvistMeeting())
                    break
                case ZOOM_IFRAME_MESSAGES.MEETING_ERROR:
                    // TODO: add call cancelled message to avoid infinite waiting - send end meeting message
                    dispatch({ type:'UPDATE_MEETING_ERROR' })
                    //console.error(e.data.error)
                    break
                default:
                    //console.error(e,"unrecognized message from zoom iframe")
            }
        }

        // the host must be the first to join so we wait for message
        // from child iframe indicating that the host has joined successfully
        window.addEventListener("message", joinedMeetingListener, false)
        return () => window.removeEventListener("message", joinedMeetingListener)
    }, [meetingId, pin, isHost, dispatch, joinAgainStatus, isEvistMeeting, firstName, lastName])

    useEffect(() => {
        if(calling&&!isEvistMeeting&&isHost&&!timeoutInstance){
             // @ts-ignore
             updateTimeoutInstance(setTimeout(() => {
                updatePeerNoResponse(true)
            }, 120000))
        }else if(!calling&&timeoutInstance){
                updatePeerNoResponse(false);
                //@ts-ignore
                clearTimeout(timeoutInstance)
                updateTimeoutInstance('')
        }

    }, [calling, isEvistMeeting, isHost, timeoutInstance])

    const onClose = (event: Event, reason: string) => {
        if (reason !== 'backdropClick') {
            const patientFirstNameValue = (patientFirstName?patientFirstName: isHost? toName: fromName) ?? '';
            calling&&isHost&&!ended&&dispatch(endCalling((to || ''),patientFirstNameValue))
            dispatch(closeMeeting())
            dispatch(setActiveModal(""))
        }
    }

    const handleCallback = async () =>{
        updatePeerNoResponse(false)
        updateTimeoutInstance('')
        const patientId = ((currentEvisitDetails) ? patientTwilioId : isHost ? to : from);
        const patientFirstNameValue = (patientFirstName?patientFirstName: isHost? toName: fromName) ?? '';
        if (patientId) {
            dispatch(outgoingCall(patientId, patientFirstNameValue, 'adhoc', profile));
        }
    }

    const Body = () => {

        if (error) {
            return <CallFailed handleCallback={handleCallback} name={renderedName || ''} />
        }

        if (peerNoResponse) {
            return <NoAnswer handleCallback={handleCallback}  />
        }

        if (ended) {
            return <CallEnded isEvistMeeting={isEvistMeeting} handleCallback={handleCallback} />
        }

        if (waiting&&!isEvistMeeting) {
            return (
                <Typography className={classes.iframe} variant="h1">
                    <FormattedMessage id="ActiveZoomMeeting.status.waitingToJoin"/>
                </Typography>
            )
        }

        if (declined) {
            return <NoAnswer handleCallback={handleCallback}  />
        }

        if (calling && isHost) {
            return (
                <Typography className={classes.iframe} variant="h1">
                    <FormattedMessage id="ActiveZoomMeeting.status.calling" values={{to:isEvistMeeting?patientFirstName:toName}} />
                </Typography>
            )
        }

        return null
    }
    const modalClasses = {
        paper: classes.wrapper
    }
    const contentClasses = {
        root: classes.dialogRoot
    }

    const additionalInfo = profile&&profile.genderCode ? `${getAge(profile.dateOfBirth)} ${profile.genderCode}` : ""

    return (
        <Modal isDraggable={true} draggableProps={{ elementHeight:500, elementWidht:647}} classes={modalClasses} id={MODAL_TYPES.PROVIDER.ACTIVE_ZOOM_MEETING}>
            <div className={classes.wrapper}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <CustomTypography style={{ marginRight: SIZING.scale400 }} variant="callHeader">
                                {isEvistMeeting ? patientFirstName : renderedName}
                            </CustomTypography>
                            {additionalInfo && (
                                <CustomTypography variant="description" className={classes.patientInfoSection}>
                                    {additionalInfo}
                                </CustomTypography>
                            )}
                        </Box>
                    { ended||peerNoResponse||waiting||error||declined||calling ?
                    <Box display="flex">
                        <Button onClick={(event: any) => { onClose(event, 'Close') }} className={classes.closeButton} id="QA_zoom_close_button">
                            <Leave className={classes.icon}/>
                        </Button>
                    </Box>
                    : null
                    }
                </Box>
            </DialogTitle>
            <DialogContent classes={contentClasses}>
                <div className={classes.iframeWrapper}>
                <Body/>
                {!ended&&meetingId&&!peerNoResponse&&!waiting&&!error&&!declined&&!calling&&<iframe className={classes.iframe} title={MODAL_TYPES.PROVIDER.ACTIVE_ZOOM_MEETING} src={meetingURL} allow={"microphone *; camera *"}/>}
                </div>
                    { isEvistMeeting && !ended && <InviteColleague  />}
            </DialogContent>
            </div>
        </Modal>
    )
}

export default ActiveZoomMeeting;