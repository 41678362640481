import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import {BORDER_RADIUS, COLORS, SIZING} from "theme";

const drawerWidth = 350
const drawerClosedWidth = 20

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawerWrapper: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawer: {
            background: COLORS.SIDE_NAV_BG,
            boxShadow: "-1px 0px 8px rgba(0, 0, 0, 0.05), inset 1px 0px 0px rgba(0, 0, 0, 0.1)",
            borderTopLeftRadius: BORDER_RADIUS.LARGE,
            borderBottomLeftRadius: BORDER_RADIUS.LARGE,
            overflowY: "initial",
            width: drawerWidth,
        },
        drawerOpen: {
            right: 0,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            right: drawerClosedWidth - drawerWidth,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        toggleWrapper: {
            position: "absolute",
            top: SIZING.scale800,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        drawerToggle: {
            height: SIZING.scale600,
            width: SIZING.scale600,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: BORDER_RADIUS.FULL,
            cursor: "pointer",
            zIndex: 1
        },
        toggle: {
            background: COLORS.BUTTON_ICON,
            boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
            "& path": {
                fill: COLORS.DRAWER_ICON
            }
        },
        drawerToggleIcon: {
            height: SIZING.scale300,
            width: SIZING.scale300,
            "& path": {
                fill: COLORS.BLACK
            }
        },
        bellIcon: {
            animation: "$rock 0.8s linear 2s 2"
        },
        ring:{
            content:'',
            display:"block",
            position:"absolute",
            top:0, right:0, bottom:0, left:0,
            borderRadius:"50%",
            border: `1.2px solid ${COLORS.NOTIFICATION_BORDER}`,
            opacity: 0,
            animation: `$ripple 1s linear 2`
        },
        notification: {
            background: COLORS.PRIORITY_BG,
            border: "1px solid rgba(0, 0, 0, 0.06)",
            boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.15), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
            "& path": {
                fill: COLORS.BUTTON_ICON,
            }
        },
        list: {
            padding: SIZING.scale300,
            paddingLeft: SIZING.scale500,
            overflow:'auto'
        },
        listItem: {
            marginBottom: SIZING.scale300,
            borderRadius: BORDER_RADIUS.MEDIUM,
            background: COLORS.LIST_ITEM_BG,
            border: "0.5px solid rgba(86, 86, 98, 0.2)",
            boxShadow: "-1px 0px 8px rgba(0, 0, 0, 0.05), inset 1px 0px 0px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            "&:hover, &:focus": {
                border: "1.5px solid #139AED",
            }
        },
        emptyList: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
        },
        emptyListIcon: {
            height: "140px",
            width: "140px",
            marginBottom: SIZING.scale800,
            "& path": {
                fill: COLORS.DRAWER_ICON
            }
        },
        "@keyframes ripple": {
            "0%": {transform:"scale(1.2)", opacity:0},
            "20%": {transform:"scale(1.2)", opacity:1},
            "50%": {transform:"scale(2)", opacity:0.3,},
            "100%": {transform:"scale(3)", opacity:0,}
        },
        "@keyframes rock": {
            "0%, 48%, 100%": {transform: "rotate(0deg)"},
            "12%": {transform: "rotate(15deg)"},
            "24%": {transform: "rotate(30deg)"},
            "36%": {transform: "rotate(15deg)"},
            "60%": {transform: "rotate(-15deg)"},
            "72%": {transform: "rotate(-30deg)"},
            "84%": {transform: "rotate(-15deg)"},
        },
    }),
);