import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "theme";

export const useStyles = makeStyles(() => {
    return {
        patientRpmChart: {
            padding: SIZING.scale400,
            display: "flex",
            flexWrap: "wrap",
            "& .MuiDialogContent-dividers": {
                borderBottom: "none !important",
                margin: `0 ${SIZING.scale400}`
            }
        },
        rpmChartCard: {
            height: SIZING.RPM_CHART_CARD,
            width: "48%",
            margin: SIZING.scale200,
            background: COLORS.WHITE,
            boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px"
        },
        rpmCardTitle: {
            padding: SIZING.scale400,
            fontWeight: 700,
            color: COLORS.CONVERSATION_TEXT,
            "& >p":{
                fontSize:SIZING.scale350
            }
        },
        rpmCardDesc: {
            display: "flex",
            justifyContent: "space-between",
            margin: `${SIZING.scale400} 0px`,
            alignItems:'center',
           
        },
        rpmLevelText: {
            color: COLORS.LBLUE,
            fontWeight: 700,
            fontSize: SIZING.scale600,
            marginLeft: SIZING.scale200
        },
        dataTotalCount:{
            color:COLORS.GRAY_50,
            fontSize:SIZING.scale400
        },
        rpmLevels: {
            display: "flex",
            alignItems:'center',
            "& svg path": {
                fill: COLORS.UNKNOWN_FILE_ICON
            },
        },
        downArrow: {
            marginLeft: SIZING.scale200,
            "& g, & path": {
                fill: `${COLORS.LGREEN} !important`,
            },
        },
        upArrow: {
            marginLeft: SIZING.scale200,
            "& g, & path": {
                fill: `${COLORS.PRIORITY_BG} !important`,
            },
        }
    }
})