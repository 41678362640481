import { COLORS, SIZING } from "theme";
import { makeStyles } from "@mui/styles";
import * as CSS from "./class-names";

export const useStyles = makeStyles({
    [CSS.PatientSearchField]: {
        display: "flex",
        flexDirection: "row",
        [`& .${CSS.PatientSearchFieldInput}`]: {
            color: COLORS.LIST_SUBHEADER_TEXT,
        },
        [`& .${CSS.PatientSearchFieldInputIcon}`]: {
            marginLeft: SIZING.scale200,
        },
        [`& .${CSS.PatientSearchFieldClearButton}`]: {
            "&[hidden]": {
                visibility: "hidden",
            },
            "& svg": {
                width: SIZING.scale800,
                "& path": {
                    fill: COLORS.REMOVE_BTN,
                },
            },
        },
    },
});
