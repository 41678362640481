import { makeStyles } from "@mui/styles";
import { COLORS, SIZING, TYPOGRAPHY } from "theme";

export const useStyles = makeStyles(() => ({
    close: {
        cursor: 'pointer',
        position: 'absolute',
        right: SIZING.scale800
    },
    hpiForm__content: {
        padding: `${SIZING.scale500} ${SIZING.scale1000} ${SIZING.scale500} ${SIZING.scale200}`,
        "&.hpiForm__content--loading": {
            display: "flex",
            minHeight: "20rem",
            alignItems: "center"
        },
        "&.hpiForm__content--readonly": {
            marginBottom: 0,
            marginTop: '-5px',
            "& .hpiForm__contentItem:not(:first-child)": {
                paddingTop: SIZING.scale25
            }
        },
        "& .hpiForm__content__container": {
            margin: 0
        },
        "& .hpiForm__contentItem": {
            "& span": {
                display: 'block',
                margin: `${SIZING.scale200} ${SIZING.scale200} 0 ${SIZING.scale200}`,
            },
            "&:not(first-child)" : {
                paddingTop: SIZING.scale100
            }
        },
        "& .hpiForm__contentItem__label": {
            fontSize: SIZING.scale350,
            fontWeight: 600,
            marginTop: 0,
            textTransform: 'uppercase'
        },
        "& .hpiForm__contentItem__text": {
            color: COLORS.SUBTITLE_INFO_TEXT,
            fontSize: SIZING.scale375,
            fontWeight: 400
        }
    },
    hpiForm__header: {
        background: COLORS.DGREY,
        // Copied from Figma
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
        display: "grid",
        gridTemplateColumns: "auto auto",
        padding: SIZING.scale800,
        paddingBottom: SIZING.scale400,
        "& .headerCell": {
            "& > span": {
                display: 'block'
            },
            "& .pushedState": {
                ...TYPOGRAPHY.desktopPatientDetailsBaseBold,
                gridArea: "state",
                "&.pushedState--single": {
                    marginTop: SIZING.scale400
                }
            },
            "& .lastModifiedBy": {
                gridArea: "by",
                marginTop: SIZING.scale100,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            },
            "& .lastModifiedDate": {
                gridArea: "date",
                marginTop: SIZING.scale100,
                marginLeft: SIZING.scale200
            },
            "&.statusDetails": {
                ...TYPOGRAPHY.desktopPatientDetailsBase,
                display: "grid",
                gridTemplateAreas: `
                    "state state"
                    "by date"
                `,
                gridTemplateColumns: "fit-content(95%) auto"
            }
        },
        "& .patientDetails": {
            ...TYPOGRAPHY.patientSummaryOverviewValue,
            display: "flex",
            margin: `${SIZING.scale200} 0`,
            whiteSpace: "nowrap",
            "& > span": {
                marginRight: SIZING.scale200
            }
        },
        "& .fullName": {
            ...TYPOGRAPHY.desktopPatientDetailsBaseBold,
            maxWidth: "500px",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        '& .details': {
            ...TYPOGRAPHY.patientSummaryOverviewLabel
        },
        '& .buttonBar': {
            textAlign: 'right',
            '& button': {
                display: 'inline-flex',
                marginLeft: SIZING.scale200,
                padding: `0 ${SIZING.scale600}`
            }
        }
    },
    notes: {
        '& .inputWithTranscription__input': {
            minHeight: SIZING.scale4000
        }
    },
    saveDraft: {
        background:COLORS.LGREEN,
        '&:hover': {
            background:COLORS.LGREEN
        }
    },
    hpiSaveDialog: {
        "& .body":{
            lineHeight: SIZING.scale750
        }
    }
}));
