import React, { useState } from "react"
import { useIntl } from "react-intl";
import LayoutExtr from 'admin/components/Layout/LayoutExtr';
import LoginContent from "common/components/LoginContent/LoginContent";
import LoadingIndicator from "admin/components/LoadingIndicator/LoadingIndicator";
const Login = () => {
    const intl = useIntl()
    const [resetPasswordView, setresetPasswordView] = useState(false)
    const [secretQuestionEnabled, setSecretQuestionEnabled] = useState(false)

    const setSecretQuestion = (value: boolean) => {
        setSecretQuestionEnabled(value)
    }
    return (
        <LayoutExtr heading={intl.formatMessage({ id: resetPasswordView? "Login.resetTitle": (secretQuestionEnabled? "ForgotPassword.AnswerSecretQuestion": "Login.heading") })} subHeading={intl.formatMessage({ id: resetPasswordView? "Login.resetDescription": (secretQuestionEnabled? "ForgotPassword.EnterPassword": "Login.subHeading") })}>
            <LoginContent resetViewHandler={setresetPasswordView} resetPasswordView={resetPasswordView} secretQuestionEnabled={secretQuestionEnabled} setSecretQuestion={setSecretQuestion} />
            <LoadingIndicator />
        </LayoutExtr>
    )
}

export default Login