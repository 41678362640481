import { call } from "store/api";
import { cleanObject } from "./object-utils";
import { debounce } from "./function-utils";
import { FetchState, FetchStates, FetchStateUtils } from "./types";
import { replaceParamsInUrl, withAsyncTimeout } from "./use-api-utils";
import { useCallback, useEffect, useRef, useState } from "react";
import ApiController from "store/listener";
import isEqualWith from "lodash/isEqualWith";

export { FetchStates, FetchStateUtils } from "./types";

const isDeepEqualWithIgnoreFunctions = (itemA: any, itemB: any) => {
    return isEqualWith(itemA, itemB, (itemA, itemB) => {
        if (typeof itemA === "function" && typeof itemB === "function") {
            return true;
        }
    });
};

type ApiConfig<TRequest, TResponse, TContent> = {
    /** This flag tells that the previous, ongoing request should be aborted if a new is sent. */
    abortOnNewRequest?: boolean;
    /** Run request after initialization and every time a parameter is changing. It is false by default. */
    autoRequest?: boolean;
    /** Default content to be provided until response is received. */
    defaultContent: TContent;
    /** Debounce delay. If not set, then no debounce will be applied. */
    delay?: number;
    /** Path of the endpoint, without domain name and /api prefix. */
    endpoint: string;
    /**
     * The identifier of the API call. This should be unique for an endpoint (URL and method combination).
     *  It is required if you want to abort the request when a new is sent to the same endpoint.
     */
    id?: string;
    /** HTTP method */
    method: string;
    /** Decides if a request should be sent or not. This can limit the requests based on parameter based rules. */
    requestDecisionMaker?: (
        requestParams: TRequest,
        context: { previousContent: TContent; previousRequestParams: TRequest | null }
    ) => boolean;
    /** Transforms response to an internal model, if there are differences. */
    responseTransformer?: (response: TResponse) => TContent;
    /** A specific timeout can be set for a request. Default timeout is 15 seconds. */
    timeout?: number;
    /** TEMPORARY: this will be removed once we have the ability to execute the call at will.
     * Change this property to retrigger a request.
     * this is not really used by the hook, but will be compared against previous configured and registered as a change
     * */
    key?: unknown;
};

// Exported for testing purposes only
export type FetchInfo<TRequest> = {
    /** Current error  */
    error: Error | null;
    /** Fetch returned content or error */
    hasResult: boolean;
    /**
     * Latest request parameters.
     * */
    requestParams: TRequest;
    state: FetchState;
};

// Exported for testing purposes only
export type RequestFunction<TRequest, TContent> = {
    (data: TRequest): Promise<TContent>;
};

export type CancelRequestFunction = {
    (): void;
};

export type ResetFunction = {
    (): void;
};

export type UseApiHookReturnObject<TRequest, TContent> = {
    cancelFn: CancelRequestFunction;
    content: TContent;
    fetchInfo: FetchInfo<TRequest>;
    requestFn: RequestFunction<TRequest, TContent>;
    resetFn: ResetFunction;
};

const shouldPerformRequest = <TRequest, TResponse, TContent>(
    params: TRequest,
    previousContent: TContent,
    previousRequestParams: TRequest | null,
    requestDecisionMaker: ApiConfig<TRequest, TResponse, TContent>["requestDecisionMaker"]
) => {
    return (
        typeof requestDecisionMaker !== "function" ||
        requestDecisionMaker(params, { previousContent, previousRequestParams })
    );
};
const performRequest = async <TRequest, TResponse, TContent>(
    config: ApiConfig<TRequest, TResponse, TContent>,
    params: TRequest,
    callback: (fetchState: FetchState, result: any) => void
): Promise<TContent> => {
    const { abortOnNewRequest, endpoint, id, method, responseTransformer, timeout } = config;
    const replaceResult = replaceParamsInUrl(endpoint, params);
    const cleanedParams = cleanObject(params, replaceResult.foundParamNames) ?? {};
    callback(FetchStates.LOADING, null);

    let signal: AbortSignal | undefined;
    if (abortOnNewRequest && id) {
        signal = ApiController.setEvent(id);
    }

    try {
        const response = await withAsyncTimeout(call(method, replaceResult.url, cleanedParams, signal), timeout);
        const responseContent = typeof responseTransformer === "function" ? responseTransformer(response) : response;

        callback(FetchStates.SUCCESS, responseContent);
        if (abortOnNewRequest && id) {
            ApiController.removeEvent(id);
        }
        return responseContent;
    } catch (callError) {
        // We ignore abort errors, because it is only aborted when we are doing a new one.
        // so the relevant status continues to be LOADING
        if ((callError as Error).name !== "AbortError") {
            callback(FetchStates.FAILED, callError as Error);
        }
        if (abortOnNewRequest && id) {
            ApiController.removeEvent(id);
        }
        return Promise.reject(callError);
    }
};

/**
 * Handle API requests.
 *
 * Generics:
 * - RequestType: request object
 * - ResponseType: raw response from API
 * - ContentType: transformed or raw response, if raw then it is optional
 *
 * Returns an object:
 * - cancelFn: to be able to cancel a request
 * - content: API response or content (ContentType)
 * - fetchInfo: Fetching process related information object (including fetching state, possible error)
 * - requestFn: to send request on demand
 * - resetFn: to reset content to default
 */
export function useApi<TRequest, TResponse, TContent = TResponse>(
    apiConfig: ApiConfig<TRequest, TResponse, TContent>,
    requestParams: TRequest = {} as TRequest
): UseApiHookReturnObject<TRequest, TContent> {
    const [fetchState, setFetchState] = useState<FetchState>(FetchStates.NOT_SENT);
    const [content, setContent] = useState<TContent>(apiConfig.defaultContent);
    const [error, setError] = useState<Error | null>(null);
    const lastApiConfigRef = useRef<ApiConfig<TRequest, TResponse, TContent>>();
    const lastRequestParamsOfHookRef = useRef<TRequest>();
    /** This is updated only when the request will be actually performed.
     *  Therefore it stays in sync with the request state.
     */
    const [lastRequestParamsSent, setLastRequestParamsSent] = useState<TRequest>();

    const delay = apiConfig.delay;

    const callbackFn = (requestParams: TRequest): Promise<TContent> => {
        return performRequest<TRequest, TResponse, TContent>(
            lastApiConfigRef.current as ApiConfig<TRequest, TResponse, TContent>,
            requestParams,
            (fetchState, content) => {
                setFetchState(fetchState);
                if (fetchState === FetchStates.LOADING) {
                    setLastRequestParamsSent(requestParams);
                    setError(null);
                } else if (fetchState === FetchStates.SUCCESS) {
                    setContent(content);
                } else {
                    setError(content);
                }
            }
        );
    };

    const callPerformDebouncedRequest = useCallback(delay !== undefined ? debounce(callbackFn, delay) : callbackFn, [
        delay,
    ]);

    useEffect(() => {
        const configWasUpdated = !isDeepEqualWithIgnoreFunctions(lastApiConfigRef.current, apiConfig);
        const paramsWereUpdated = !isDeepEqualWithIgnoreFunctions(lastRequestParamsOfHookRef.current, requestParams);
        const { autoRequest, requestDecisionMaker } = apiConfig;
        const performAutomatically = autoRequest ?? false;

        if (configWasUpdated) {
            lastApiConfigRef.current = apiConfig;
        }
        if (paramsWereUpdated) {
            lastRequestParamsOfHookRef.current = requestParams;
        }
        if (
            performAutomatically &&
            (configWasUpdated || paramsWereUpdated) &&
            shouldPerformRequest(requestParams, content, lastRequestParamsSent || null, requestDecisionMaker)
        ) {
            callPerformDebouncedRequest(requestParams).catch(() => {
                // Supress errors, no direct return
            });
        }
    }, [apiConfig, callPerformDebouncedRequest, content, requestParams]);

    const requestFunction = (newRequestParams: TRequest): Promise<TContent> => {
        const { requestDecisionMaker } = lastApiConfigRef.current ?? {};
        if (shouldPerformRequest(newRequestParams, content, lastRequestParamsSent ?? null, requestDecisionMaker)) {
            return callPerformDebouncedRequest(newRequestParams);
        }
        return Promise.resolve(content);
    };
    const cancelRequestFunction = (): void => {
        // TODO `any` is a temporary solution for typing of cancel
        (callPerformDebouncedRequest as any)?.cancel();
    };
    const defaultContent = apiConfig.defaultContent;
    const resetFunction = useCallback(() => {
        setContent(defaultContent);
        setError(null);
        setFetchState(FetchStates.NOT_SENT);
    }, [defaultContent]);
    return {
        content,
        fetchInfo: {
            state: fetchState,
            error,
            requestParams: lastRequestParamsSent || requestParams,
            hasResult: FetchStateUtils.hasResult(fetchState),
        },
        requestFn: requestFunction,
        cancelFn: cancelRequestFunction,
        resetFn: resetFunction,
    };
}
