import React, { Fragment } from "react";
import { useIntl } from 'react-intl';
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as AltaisStarIcon } from 'icon-library/SVG/Altais-Star.svg';
import { ReactComponent as PenIcon } from 'icon-library/SVG/Pen.svg';
import { ReactComponent as EyeIcon } from 'icon-library/SVG/Eye.svg';
import { ReactComponent as CancelIcon } from 'icon-library/SVG/Cancel.svg';
import { GridContextMenuContentProps } from 'admin/components/GridContextMenuContent/types';
import { useStyles } from './styles';
import { canCreateOrgsAndSubOrgs } from "admin/Accessibility";

const OrganizationGridContextMenu = ({ callback, handleClose }: GridContextMenuContentProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const canCreateOrgAndPractice = canCreateOrgsAndSubOrgs()
    return (
        <Fragment>
            <ListItem button>
                <ListItemIcon>
                    <EyeIcon width={24} />
                </ListItemIcon>
                <ListItemText id="rowActionViewDetails" primary={intl.formatMessage({ id: "GridContextMenu.ViewDetails" })} classes={{ primary: classes.listText }} onClick={() => { callback("ViewDetails"); handleClose() }} />
            </ListItem>
            {canCreateOrgAndPractice && <ListItem button>
                <ListItemIcon>
                    <PenIcon width={24} />
                </ListItemIcon>
                <ListItemText id="rowActionEditInfo" primary={intl.formatMessage({ id: "GridContextMenu.EditInfo" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditInfo"); handleClose() }} />
            </ListItem>}
            {canCreateOrgAndPractice && <ListItem button>
                <ListItemIcon>
                    <AltaisStarIcon width={24} />
                </ListItemIcon>
                <ListItemText id="rowActionEditProducts" primary={intl.formatMessage({ id: "GridContextMenu.EditProducts" })} classes={{ primary: classes.listText }} onClick={() => { callback("EditProducts"); handleClose() }} />
            </ListItem>}
            <ListItem button>
                <ListItemIcon>
                    <CancelIcon width={24} />
                </ListItemIcon>
                <ListItemText id="baDeactivateAccount" primary={intl.formatMessage({ id: "GridContextMenu.DeactivateItemText" })} classes={{ primary: classes.listText }} onClick={() => { callback("Deactivate"); handleClose() }} />
            </ListItem>
        </Fragment>
    );
}

export default OrganizationGridContextMenu;
