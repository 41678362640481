import React from "react";
import useParticipantReconnect from "../../hooksHelper/useParticipantReconnect"
import { Participant } from 'twilio-video';
import { useStyles } from './styles'
export default function Reconnect({ data }: { data: Participant }) {
    const isReconnecting = useParticipantReconnect(data);
    const classes = useStyles()
    if (!isReconnecting) {
        return null
    }
    return (
        <div className={classes.reconnectinWrapper}>
            <span>Reconnecting...</span>
        </div>
    )
}
