import React, { useState } from "react";
import { useStyles } from "./styles";
import { createSvgIcon } from "utils";
import { Grid, Box, Button, Typography } from "@mui/material";
import {ReactComponent as AlertSvg} from "icon-library/SVG/Warning_Triangle.svg";
import {COLORS} from "theme";
import { AppReduxStore } from "store/reducerTypes";
import { setFeedbackLoop } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import {FormattedDate, FormattedMessage, FormatDateOptions, useIntl} from "react-intl";

const IncorrectInfo = () => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const AlertIcon = createSvgIcon(AlertSvg);
    const dateOptions: FormatDateOptions = {year: "numeric", month: "short", day:"2-digit", hour: "numeric", minute: "numeric", second: "numeric", hour12: true };

    const {feedBackLoop} = useSelector((state: AppReduxStore) => state.patient);
    const [feedBackLoopData, setFeedBackLoopData] = useState(feedBackLoop && feedBackLoop.feedbackFieldList ? feedBackLoop.feedbackFieldList : []);

    const handleDismiss = (feedbackFieldId: string) => {
        let tempArray = [...feedBackLoopData];
        dispatch(setFeedbackLoop([feedbackFieldId]));
        tempArray = tempArray.filter((item: any) => item.feedbackFieldId !== feedbackFieldId);
        setFeedBackLoopData(tempArray);
    };

    const handleDismissAll = () => {
        const tempArray = [...feedBackLoopData];
        dispatch(setFeedbackLoop(tempArray.map((o: any) => o.feedbackFieldId)));
        setFeedBackLoopData([]);
    };

    const mapFieldAliases = (fieldName: string) => {
        let alias = "";
        switch(fieldName.trim()) {
            case "firstName,lastName":
            case "firsName,lastName":
                alias = "People.summary.label.name";
                break;
            case "dateOfBirth":
            case "age":
                alias = "People.summary.label.dobAge";
                break;
            case "address":
                alias = "People.summary.label.address";
                break;
            case "language":
                alias = "People.summary.label.language";
                break;
            case "sex":
                alias = "People.summary.label.sex";
                break;
            case "emailAddress":
                alias = "People.summary.label.emailAddress";
                break;
            case "homePhoneNumber":
            case "phoneNumber,homePhoneNumber,cellPhoneNumber":
                alias = "People.summary.label.phone";
                break;
            case "citizenship":
                alias = "People.summary.label.citizenship";
                break;
            case "insuranceName,insuranceType,insuranceId":
                alias = "People.summary.label.planNameType";
                break;
            default: 
                return fieldName; // Not currently mapped
        }
        return intl.formatMessage({ id: alias });
    }

    if(feedBackLoopData.length) {
        return (
            <Box className={classes.contentWrapper}>
                <div className={classes.details}>
                    <div className="main-details-wrapper">
                        <Grid alignItems={"center"} container spacing={2}>
                        <Grid item xs={"auto"}>
                            <AlertIcon fill={COLORS.FAIL_ICON} className="iconProp"></AlertIcon>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1" className="title">
                                <FormattedMessage id="IncorrectInfo.title" />
                            </Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                            <Button onClick={() => handleDismissAll()} className={classes.dismissBtn} size="small" variant="contained">
                                <FormattedMessage id="IncorrectInfo.btn.label.dismissAll" />
                            </Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5} className="alert-heading">
                            <FormattedMessage id="IncorrectInfo.fieldValuesHeading" />
                        </Grid>
                        <Grid item xs={3} className="alert-heading">
                            <FormattedMessage id="IncorrectInfo.lastUpdated" />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        {feedBackLoopData.map((row: any) => { return(
                        <React.Fragment key={row.feedbackFieldId}>
                            <Grid item xs={2} className="alert-section-details">{mapFieldAliases(row.feedbackFieldInfo.fieldName)}</Grid>
                            <Grid item xs={5}>{row.feedbackFieldInfo.fieldValue}</Grid>
                            <Grid item xs={3}> <FormattedDate value={row.lastModifiedDate} {...dateOptions} /></Grid>
                            <Grid item xs={2}>
                                <Button onClick={() => handleDismiss(row.feedbackFieldId)} className={classes.dismissBtn} size="small" variant="contained">
                                    <FormattedMessage id="IncorrectInfo.btn.label.dismiss" />
                                </Button>
                            </Grid>
                        </React.Fragment>
                        )
                        })}
                        </Grid>
                    </div>
                </div>
            </Box>
        )
    } else {
        return(<></>);
    }
}

export default IncorrectInfo