import createReducer from '../createReducer'
import {UserState} from '../reducerTypes'

const initialState: UserState = {
	username: "",
	userId: "",
	firstName: "",
	lastName: "",
	zoomUsername: "",
	twilioUsername: "",
	organizationsIds: null,
	practicesIds: null,
	roleIds: null,
	privilegeIds: null,
	profileRoleJoinInfos: null,
	loading: false,
	profiles: [],
	registeredUserProfileInfo: null,
	currentProfileRoleJoinId: null,
	currentProfileRoleJoin: null,
	currentProfileId: null,
	currentUserType: null
}

export default createReducer(initialState, {
	'SET_USER': (state: UserState, payload: UserState): UserState => {
		return {
			...state,
			...payload,
		}
	},
	'FETCHING_USER_PROFILES':  (state: UserState): UserState => {
		return {
			...state,
			loading: true
		}
	},
	'SET_USER_PROFILES': (state: UserState, payload: UserState): UserState => {
		return {
			...state,
			...payload,
			loading: false
		}
	},
	'USER_PROFILE_FETCH_ERROR':  (state: UserState): UserState => {
		return {
			...state,
			loading: false
		}
	},
	'SET_CURRENT_PROFILE_ID': (state: UserState, payload: UserState): UserState => {
		return {
			...state,
			...payload,
		}
	}
})