import { ValueOf } from "lib/util-types";

export const SortDirect = {
    ASC: "ASC",
    DESC: "DESC",
} as const;

export type Sort = {
    direction: ValueOf<typeof SortDirect>;
    field: string;
};

export interface SortableRequest {
    sort: Sort[];
}
