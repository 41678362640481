import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

export const showLoading = (id: string, title?: null | string, description?: null | string) => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'SHOW_LOADING', payload: { id, title, description } })
	}
}
export const hideLoading = (id: string) => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch({ type: 'HIDE_LOADING', payload: { id } })
	}
}