import {ThunkDispatch} from 'redux-thunk'
import {AnyAction} from 'redux'
import { Client, Conversation, ConversationUpdateReason, Message, Participant } from "@twilio/conversations";
import { AppReduxStore, AppThunk } from "store/reducerTypes";
import {call} from "store/api";
import {
    ConversationGroupedMap,
    ConversationGroupOfCategory,
    ConversationInfoItem,
    ConversationInfoLastMessageDetails,
    ConversationInfoMap,
    ConversationMap,
} from "store/chat-types";
import { each, map, sortBy } from "underscore";
import {
    conversationCategoryToConversationTypeMapping,
    CONVERSATION_CATEGORY,
    CONVERSATION_TYPE,
    SYS_MESSAGES,
} from "constant";
import {setError} from "./error";
import {getNotifications} from "./notification";
import { validateZoomHandshake,validateTwilioHandshake,validateWaitingRoomHandshake } from "../utils"
import ApiController from "store/listener"
import { getConversation } from 'components/messaging/conversation-api';
import { getWaitingRoomDetails,refreshWaitingRoomListDetails,patientEndPingHandler } from "./waitingRoom"
import { ListItem  } from "components/MultiSelectPopoverInput/types";
import { messagingConversationFetchingBusyStateName } from 'components/messaging/constants';
import { ValueOf } from "lib/util-types";
import ProgressManager from 'common/utils/progress/progress-manager';

type TwilioConversationUpdatedEvent = {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
};

const getLastMessageInfo = async (
    twilioConversation: Conversation,
    twilioUsername?: string
): Promise<ConversationInfoLastMessageDetails | null> => {
    const lastMessagePage = await twilioConversation.getMessages(1);
    if (lastMessagePage.items.length) {
        const lastMessage = lastMessagePage.items[0];
        return {
            lastMessage: lastMessage.body || "",
            dateUpdated: lastMessage.dateCreated,
            isLastMessageByCurrentUser: twilioUsername ? lastMessage.author === twilioUsername : false,
        };
    } else {
        return null;
    }
};

/** Creates Twilio client, sets up client event listeners and fetches initial conversations. */
export const initClient = (): AppThunk => {
    return async (dispatch, getState) => {
        const {chat,  user} = getState()
        const {token} = chat
        const {currentProfileId} = user

        ProgressManager.setBusy(messagingConversationFetchingBusyStateName, true);

        if (token) {
            try {
                // TODO Replace deprecated client creation
                const client = await Client.create(token)
                if (client && currentProfileId) {
                    // TODO Do not place third party client into Redux (it should be encapsulated)
                    dispatch({type: 'SET_CLIENT', payload: {client} })
                    await dispatch(getEnableConversations(currentProfileId, client))
                    // TODO Initialize listeners immediately, without thunk
                    dispatch(initClientListeners(client))
                }
            } catch (e: any) {
                dispatch(setError("Error.message.startingTwilioClient"))
            }
        }
    }
}

// onConversationAdded handles listener when new conversation is added from the twilio client
export const onConversationAdded = (conversation: Conversation): AppThunk => {
    return async (dispatch, getState) => {
        const {chat, user} = getState()
        const {conversations} = chat
        const {twilioUsername} = user

        try {
            // No need to fetch system conversation from db
            if (conversation.uniqueName !== `${twilioUsername}-sys`) {
                const enableData = await getConversation(conversation.sid);
                if (!conversations[conversation.sid]) {
                    if (enableData) {
                        // @ts-ignore
                        await dispatch(setConversation(conversation, enableData))
                    }
                }
            }
        } catch (e: any) {
            // When conversationAdded is first initialized all subscribed conversations from twilio are loaded here.
            // Because development dbs are often in flux and do not match what is in twilio we prevent error messages
            // being shown outside production env.
            if (process.env.NODE_ENV === 'production') {
                dispatch(setError("Error.message.fetchingConversation"))
            }
        }
    }
}

const onConversationUpdated = (event: TwilioConversationUpdatedEvent): AppThunk => {
    return async (dispatch) => {
        // Reacts for conversation state change (mostly active -> inactive) with updating the conversation model
        if (event.updateReasons.includes("state")) {
            const conversationInfo = await getConversation(event.conversation.sid);
            if (conversationInfo) {
                dispatch(setConversation(event.conversation, conversationInfo));
            }
        }
    }
}

// onMessageAdded handles new messages from the twilio client.
export const onMessageAdded = (message: Message): AppThunk => {
    return async (dispatch, getState) => {
        const { conversation } = message;
        const { user, chat, meeting, waitingRoom,png } = getState();
        const { twilioUsername } = user;
        const { openConversationSid } = chat;

        const { read, unread } = await getMessageCounts(conversation, twilioUsername, message).catch(() => {
            dispatch(setError("Error.message.fetchingUnreadMessages"));
            return { all: 0, read: 0, unread: 0 };
        });
        // Add the message to the current list of message if its conversation is open
        if (conversation.sid === openConversationSid) {
            dispatch({ type: "ADD_MESSAGE", payload: { message } });
        }

        // @ts-ignore
        if (conversation.channelState.uniqueName === `${twilioUsername}-sys`) {
            await dispatch(handleSystemMessage(message, { meeting, waitingRoom, user,png }));
            return;
        } else {
            dispatch(
                setConversationInfo(conversation.sid, {
                    lastMessage: message.body,
                    dateUpdated: message.dateCreated,
                    isLastMessageByCurrentUser: message.author === twilioUsername,
                    unreadCount: unread,
                    readCount: read,
                })
            );
            const { conversations, conversationsInfo } = getState().chat;
            dispatch({
                type: "SET_GROUPED_CONVERSATIONS",
                payload: { groupedConversations: groupConversations(conversations, conversationsInfo) },
            });
        }
    };
};

// onParticipantJoined adds new participants to the conversation
export const onParticipantJoined = (participant: Participant): AppThunk => {
    return async (dispatch) => {
        const { conversation } = participant
        const { participants } = await call("GET", `/messaging/v1/conversations/${conversation.sid}`)
        dispatch(setConversationInfo(conversation.sid, {
            participants
        }))
    }
}

// onParticipantLeft removes provided participant from the list in a conversation
export const onParticipantLeft = (participant: Participant): AppThunk => {
    return async (dispatch) => {
        const { conversation } = participant
        const { participants } = await call("GET", `/messaging/v1/conversations/${conversation.sid}`)
        dispatch(setConversationInfo(conversation.sid, {
            participants: participants
        }))
    }
}

// onTokenAboutToExpire refreshes token when receiving this event from the twilio client
export const onTokenAboutToExpire = (client: Client): AppThunk => {
    return async (dispatch, getState) => {
        const {twilioUsername} = getState().user
        const payload = await call("GET", `/messaging/v1/token/${twilioUsername}`)
        dispatch({type: 'SET_TWILIO_TOKEN', payload: {token: payload.twilioToken}})
        await client.updateToken(payload.twilioToken)
    }
}

/** Initializes event listeners of the Twilio client. */
export const initClientListeners = (client: Client): AppThunk => {
    return (dispatch) => {
        client.removeAllListeners();
        // When new conversations are added to twilio we check to see if they have enable data.
        client.on("conversationAdded", async (conversation: Conversation) => {
            await dispatch(onConversationAdded(conversation));
        });
        client.on("conversationUpdated", async (event) => {
            dispatch(onConversationUpdated(event));
        });
        client.on("messageAdded", async (message: Message) => {
            dispatch(onMessageAdded(message));
        });
        client.on("participantJoined", (participant: Participant) => {
            dispatch(onParticipantJoined(participant));
        });
        client.on("participantLeft", (participant: Participant) => {
            dispatch(onParticipantLeft(participant));
        });
        client.on("typingStarted", (participant: Participant) => {
            dispatch(onParticipantStartedTyping(participant));
        });
        client.on("typingEnded", (participant: Participant) => {
            dispatch(onParticipantEndedTyping(participant));
        });
        client.on("tokenAboutToExpire", async () => {
            await dispatch(onTokenAboutToExpire(client));
        });
    };
};

// handleSystemMessage dispatches some action when receiving a new system message from the client
export const handleSystemMessage = (message: Message, state: any): AppThunk => {
    return async (dispatch) => {
        if (message.body === null) {
            return;
        }
        const { meeting, png, waitingRoom } = state;
        const { meetingId } = meeting || {};
        const { meetingId: incomingCallMeetingId } = meeting.incoming;
        const { inviteToken = "" } = waitingRoom?.currentMeetingDetails || {};
        const previousMeetingDetails = waitingRoom?.previousMeetingDetails
        const participants = waitingRoom?.participants;
        const systemMessage: {
            type: ValueOf<typeof SYS_MESSAGES>;
            body: Object;
        } = JSON.parse(message.body);

        switch (systemMessage.type) {
            case SYS_MESSAGES.SYS_CALL_INCOMING: // Handle incoming adhoc call
                dispatch({ type: "SYS_CALL_INCOMING", payload: { ...systemMessage.body } });
                // @ts-ignore
                break;
            // TODO this will be removed only for demo purpose
            case SYS_MESSAGES.SYS_EVISIT_CALL_INCOMING: // Handle incoming eVisit call
                dispatch({ type: "SYS_CALL_INCOMING", payload: { ...systemMessage.body } });
                // @ts-ignore
                break;
            case SYS_MESSAGES.SYS_CALL_END:
                validateZoomHandshake(incomingCallMeetingId, systemMessage.body) && dispatch({ type: "CALL_DECLINED" });
                // @ts-ignore
                break;
            case SYS_MESSAGES.SYS_CALL_ACCEPTED:
                validateZoomHandshake(meetingId, systemMessage.body) && dispatch({ type: "SYS_CALL_ACCEPTED" }); // Handle when user accepts adhoc call
                break;
            case SYS_MESSAGES.SYS_NRU_EVISIT_END:
            case SYS_MESSAGES.SYS_NRU_EVISIT_CALL_PAUSED:
            case SYS_MESSAGES.SYS_NRU_EVISIT_CALL_BACK_TO_WAITINGROOM:
                handleTwilioEndCall(
                    inviteToken,
                    systemMessage,
                    previousMeetingDetails,
                    dispatch,
                    waitingRoom.providerFilterIds,
                    png
                );
                // Handle when user accepts adhoc call
                break;
            case SYS_MESSAGES.SYS_EVISIT_CALL_ACCEPTED:
                validateZoomHandshake(meetingId, systemMessage.body) && dispatch({ type: "SYS_CALL_ACCEPTED" }); // Handle when user accepts eVisit call
                break;
            case SYS_MESSAGES.SYS_CALL_DECLINED:
                validateZoomHandshake(meetingId, systemMessage.body) && dispatch({ type: "SYS_CALL_DECLINED" }); // Handle when user declines adhoc call
                break;
            case SYS_MESSAGES.SYS_CALL_JOINED:
                validateZoomHandshake(meetingId, systemMessage.body) && dispatch({ type: "SYS_CALL_JOINED" }); // Handle when user joins adhoc call
                break;
            case SYS_MESSAGES.SYS_EVISIT_CALL_JOINED:
                validateZoomHandshake(meetingId, systemMessage.body) && dispatch({ type: "SYS_CALL_JOINED" }); // Handle when user joins eVisit call
                break;
            case SYS_MESSAGES.SYS_NRU_EVISIT_REFRESH:
                validateWaitingRoomHandshake(waitingRoom.providerFilterIds,systemMessage.body,participants)&&refreshWaitingRoomListDetails(dispatch, waitingRoom.providerFilterIds);
                break;
            case SYS_MESSAGES.SYS_NRU_EVISIT_PING:
                patientEndPingHandler(systemMessage.body, dispatch, waitingRoom);
                break;
            case SYS_MESSAGES.SYS_NOTIFICATION: // Handles when new notification is available to be fetched
                // @ts-ignore
                await dispatch(getNotifications());
                break;
            default:
                console.error("unrecognized system message type");
        }
    };
};

/** Exported for testing purposes only */
export const getMessageCounts = async (
    conversation: Conversation,
    twilioUsername: string,
    lastMessage?: Message
): Promise<{ all: number; read: number; unread: number }> => {
    const unreadCountPromise =
        lastMessage?.author === twilioUsername
            ? conversation.advanceLastReadMessageIndex(lastMessage.index)
            : conversation.getUnreadMessagesCount();
    const messagesCountPromise = conversation.getMessagesCount();

    const counts = await Promise.all([messagesCountPromise, unreadCountPromise]);
    const messagesCount = counts[0] || 0;
    let unreadCount = counts[1];

    /** Conversations that have never been read have a read horizon null.
     *  This gives us the unread count before the read horizon has been set
     */
    if (unreadCount === null) {
        if (!lastMessage) {
            const messagePage = await conversation.getMessages(1).catch(() => null);
            if (messagePage?.items.length) {
                lastMessage = messagePage.items[0];
            }
        }
        unreadCount = await (lastMessage?.author === twilioUsername
            ? conversation.advanceLastReadMessageIndex(lastMessage?.index)
            : Promise.resolve(messagesCount));
    }

    return {
        read: messagesCount - unreadCount,
        unread: unreadCount,
        all: messagesCount,
    };
};

// initConversation initializes conversation event handlers and requests additional conversation info
export const initConversation = (conversation: Conversation): AppThunk => {
    return async (dispatch, getState) => {
        const { twilioUsername } = getState().user;
        const { read, unread } = await getMessageCounts(conversation, twilioUsername).catch(() => {
            dispatch(setError("Error.message.fetchingUnreadMessages"));
            return { read: 0, unread: 0 };
        });
        const lastMessageInfo = await getLastMessageInfo(conversation, twilioUsername).catch(() => {
            dispatch(setError("Error.message.fetchingLastMessage"));
            return null;
        });;
        const lastMessage = lastMessageInfo?.lastMessage ?? "";
        const dateUpdated = lastMessageInfo?.dateUpdated ?? conversation.dateCreated;
        const isLastMessageByCurrentUser = lastMessageInfo?.isLastMessageByCurrentUser ?? false;

        dispatch(
            setConversationInfo(conversation.sid, {
                unreadCount: unread,
                readCount: read,
                lastMessage,
                dateUpdated,
                isLastMessageByCurrentUser,
            })
        );
        dispatch({ type: "SET_CONVERSATION_LOADED", payload: conversation.sid });
    };
};

// resetConversation clears conversations from store
export const resetConversation = () => {
    return async (dispatch:ThunkDispatch<{},{},AnyAction>) => {
        dispatch({ type:'RESET_CONVERSATIONS' })
    }
}

const getTwilioConversations = async (client: Client): Promise<Conversation[]> => {
    let twilioConversations: Conversation[] = [];
    let page = null;
    do {
        page = await (page === null ? client.getSubscribedConversations() : page.nextPage());
        twilioConversations = [...twilioConversations, ...page.items];
    } while (page.hasNextPage);
    return twilioConversations;
};

// getEnableConversations fetches conversation data from altais db and kicks off conversation initialization
export const getEnableConversations = (profileId: string, client: Client): AppThunk => {
    return async (dispatch, getState) => {
        const { twilioUsername } = getState().user;
        try {
            // Fetch all subscribed conversations from twilio
            const twilioConversations: Conversation[] = await getTwilioConversations(client);

            // Fetch Enable conversations
            const enableConversations = await call("GET", `/messaging/v1/conversations/profile/${profileId}?allTypes=true`);
            
            // Initialize conversations
            const conversations: ConversationMap = {};
            const conversationsInfo: ConversationInfoMap = {};

            for (const enableConversation of enableConversations) {
                const eConversationId = enableConversation.id;
                const twilioConversation = twilioConversations.find(
                    (twConversation) => twConversation.sid === eConversationId
                );
                if (twilioConversation) {
                    conversations[eConversationId] = twilioConversation;
                    const { read, unread } = await getMessageCounts(twilioConversation, twilioUsername);
                    enableConversation.readCount = read;
                    enableConversation.unreadCount = unread;
                    const lastMessage = await getLastMessageInfo(twilioConversation, twilioUsername);
                    if (lastMessage) {
                        Object.keys(lastMessage).forEach((key) => {
                            enableConversation[key] = lastMessage[key as keyof ConversationInfoLastMessageDetails];
                        });
                    }
                    conversationsInfo[eConversationId] = enableConversation;
                } else {
                    console.log("No Twilio info found.", eConversationId);
                }
            }

            // Group conversations by date, type and priority
            const groupedConversations = groupConversations(conversations, conversationsInfo);
            dispatch({
                type: "SET_INITIAL_CONVERSATIONS",
                payload: { conversations, groupedConversations, conversationsInfo },
            });
            ProgressManager.setBusy(messagingConversationFetchingBusyStateName, false);
        } catch (e: any) {
            console.log(e);
            dispatch(setError("Error.message.fetchingConversations"));
        }
    };
};

// setConversation adds conversation to store and kicks off conversation initialization
export const setConversation = (conversation: Conversation, enableData: ConversationInfoItem): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({type: 'SET_CONVERSATION', payload: {conversation, enableData}})
        // @ts-ignore
        await dispatch(initConversation(conversation))

        const {conversations, conversationsInfo} = getState().chat
        const newConversations = {
            ...conversations,
            [conversation.sid]: conversation
        }
        const newConversationsInfo = {
            ...conversationsInfo,
            [enableData.id]: enableData
        }

        dispatch({type: "SET_GROUPED_CONVERSATIONS", payload: {groupedConversations: groupConversations(newConversations, newConversationsInfo)}})
        ProgressManager.setBusy(messagingConversationFetchingBusyStateName, false);
    }
}

// setOpenConversationSid sets conversation as "open" to get chat history
export const setOpenConversationSid = (openConversationSid: any) => {
    return (dispatch:ThunkDispatch<{},{},AnyAction>) => {
        dispatch({type: 'SET_OPEN_CONVERSATION_SID', payload: {openConversationSid}})
    }
}

// setConversationInfo saves additional data for the given conversation
export const setConversationInfo = (conversationSid: string, info: any): AppThunk => {
    return (dispatch) => {
        dispatch({type: 'SET_CONVERSATION_INFO', payload: {conversationSid, info}})
    }
}

// terminateClient removes client from store and disables all listeners
export const terminateClient = () => {
    return async (dispatch:ThunkDispatch<{},{},AnyAction>, getState: () => AppReduxStore) => {
        const {client} = getState().chat
        client?.removeAllListeners()
        dispatch({ type: "CLEAR_CHAT"})
    }
}

// composeNewMessage creates a new conversation and message
export const composeNewMessage = (subject: string, message: string,participantsList:any='',patientProfileId:string | undefined, type: string): AppThunk => {
    return async (dispatch, getState) => {
        const {chat, user} = getState()
        const {recipients} = chat
        const {currentProfileId, currentUserType} = user

        let participants
        if(participantsList && type === 'patient'){
            participants = [{
                    profileId:participantsList.profileId,
                    userType:participantsList.userType,
                    identity:participantsList.twilioUsername
                },{
                    profileId: currentProfileId,
                    userType: currentUserType,
                    identity: user.twilioUsername,
                }]
        } else {
            participants = map(participantsList ? participantsList : recipients, (r) => {
                return {
                    profileId: r.profileId,
                    identity: r.twilioUsername,
                    userType: r.userType
                }
            })
            participants.push({
                profileId: currentProfileId,
                userType: currentUserType,
                identity: user.twilioUsername,
            })
        }


        try {
            await call("POST", "/messaging/v1/conversations", {
                participants,
                subject,
                from: user.twilioUsername,
                message,
                conversationType: type === 'colleague' ? "CARETEAM" : 'PATIENT',
                ...(patientProfileId ? {patient: patientProfileId} : ''),
                host: currentProfileId
            })
        } catch (e: any) {
            dispatch(setError("Error.message.composingNewMessage", e?.message))
        }
    }
}

// fetchMessages makes request to twilio to get the first page of 30 messages
export const fetchMessages = (conversationSid: string): AppThunk => {
    return async (dispatch,  getState) => {
        const {conversations} = getState().chat
        try {
            const messagePage = await conversations[conversationSid]?.getMessages(30)
            dispatch({type: 'SET_MESSAGES', payload: {messagePage}})
        } catch (e: any) {
            dispatch(setError("Error.message.fetchingMessages"))
        }
    }
}

// loadPreviousMessagePage makes request to twilio to fetch the previous page of messages
export const loadPreviousMessagePage = (): AppThunk => {
    return async (dispatch,  getState) => {
        const {messagePage} = getState().chat
        if (messagePage?.hasPrevPage) {
            try {
                const previousPage = await messagePage.prevPage()
                dispatch({type: 'ADD_PREVIOUS_PAGE', payload: {previousPage}})
            } catch (e: any) {
                dispatch(setError("Error.message.fetchingMessages"))
            }
        }
    }
}

/**
 * Mark a conversation read (in Twilio).
 * 
 * FIXME: Now marking read happens only when user lefts the conversation view. This might be too late, so it should be
 * revised by business.
 * 
 * @param conversationSid ID of the conversation
 * @returns A thunk function.
 */
export const markConversationRead = (conversationSid: string): AppThunk => {
    return async (dispatch,  getState) => {
        const {conversations} = getState().chat
        if (conversations[conversationSid] && conversations[conversationSid]?.lastMessage) {
            try {
                // @ts-ignore
                const unreadCount = await conversations[conversationSid]?.advanceLastReadMessageIndex(conversations[conversationSid].lastMessage.index ?? 0)
                const messagesCount = await conversations[conversationSid]?.getMessagesCount();
                dispatch(setConversationInfo(conversationSid, {readCount: messagesCount - unreadCount, unreadCount}));
            } catch (e: any) {
                dispatch(setError("Error.message.markingConversationRead"))
            }
        }
    }
}

// onParticipantStartedTyping saves participant info when another user starts typing
export const onParticipantStartedTyping = (participant: Participant): AppThunk => {
    return (dispatch, getState) => {
        const {conversationsInfo} = getState().chat
        const {conversation} = participant
        const conversationInfo = conversationsInfo[conversation.sid];
        if (conversationInfo) {
            const participantInfo = conversationInfo.participants.find(p => p.identity === participant.identity)
            if (participantInfo) {
                dispatch({type: "PARTICIPANT_STARTED_TYPING", payload: {conversationSid: conversation.sid, participant: participantInfo}})
            }
        }
    }
}

// onParticipantEndedTyping removes participant info when another user stops typing
export const onParticipantEndedTyping = (participant: Participant): AppThunk => {
    return (dispatch) => {
        const {conversation} = participant
        dispatch({type: "PARTICIPANT_ENDED_TYPING", payload: {conversationSid: conversation.sid, participant}})
    }
}

// searchRecipients makes a request to search for recipients that can be added to a conversation
export const searchRecipients = (searchStr: string): AppThunk => {
    return async (dispatch, getState) => {
        if (!searchStr.trim()) {
            dispatch({type: "RECIPIENT_SEARCH_COMPLETE", payload: {searchedRecipients: []}})
            return
        }
        const { username } = getState().user
        try {
            const searchedRecipients = await call("GET", "/messaging/v1/conversation/recipients", {searchStr, conversationType: "CARETEAM", username})

            dispatch({type: "RECIPIENT_SEARCH_COMPLETE", payload: {searchedRecipients}})
        } catch (e: any) {
            dispatch(setError("Error.message.searchRecipients"))
        }
    }
}

export const searchRecipientsBySearchString  = (searchStr: string,username:string,type:string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const signal =  ApiController.setEvent('recipientSearch')

            const searchedRecipients = await call("GET", "/messaging/v1/conversation/recipients", {searchStr, conversationType: type, username},signal)

            ApiController.removeEvent('recipientSearch')

            resolve(searchedRecipients)
        } catch (e: any) {
            if(e.name !== 'AbortError'){
                reject([])
            }
            ApiController.removeEvent('recipientSearch')
        }
    })

}

// setSearchText saves search text in store
export const setSearchText = (searchText: string) => {
    return async (dispatch:ThunkDispatch<{},{},AnyAction>) => {
        dispatch({type: "SET_SEARCH_TEXT", payload: {searchText}})
    }
}

// searchConversations makes a request to search for conversation with the searchStr and type by participant name,
// subject or patient mrn
export const searchConversations = (searchStr: string,type:string): AppThunk => {
    return async (dispatch, getState) => {
        const {user, chat} = getState()
        const {username} = user
        const {conversations} = chat
        if (searchStr) {
            dispatch({type: "SEARCHING_CONVERSATIONS"})
            try {
                const conversationsList = await call("GET", "/messaging/v1/conversation/messages/search", {conversationType: type, searchStr: encodeURIComponent(searchStr), userName: username })
                const searchedConversations: any = {}
                each(conversationsList, (c) => {
                    searchedConversations[c.id] = c
                })
                dispatch({type: "SET_SEARCHED_CONVERSATIONS", payload: {searchedConversations: groupConversations(conversations, searchedConversations), searchText: searchStr}})
            } catch (e: any) {
                dispatch(setError("Error.message.searchConversations"))
            }
            
        } else {
            // clear searched convos
            dispatch({type: "CLEAR_SEARCHED_CONVERSATIONS"})
        }
    }
}

// setConversationFilter saves a new filter to the store
export const setConversationFilter = (filter: string) => {
    return async (dispatch:ThunkDispatch<{},{},AnyAction>) => {
        dispatch({type: "SET_CONVERSATION_FILTER", payload: {filter}})
    }
}

export const loadThreadPage = () => {
    return (dispatch:ThunkDispatch<{},{},AnyAction>) => {
        dispatch({type: "LOAD_NEXT_PAGE"})
    }
}

// returns the conversation date. Either the last messages date create or the conversations date created
export const getConversationDate = (conversation: Conversation): Date | null | undefined => {
    return conversation.lastMessage ? conversation.lastMessage.dateCreated : conversation.dateCreated
}

// pulls priority messages out and sorts conversations by date. newest first
export const groupConversations = (conversations: ConversationMap, conversationsInfo: ConversationInfoMap) => {
    const groupedConversations: ConversationGroupedMap = {
        [CONVERSATION_CATEGORY.PATIENT]: {} as ConversationGroupOfCategory,
        [CONVERSATION_CATEGORY.CARETEAM]: {} as ConversationGroupOfCategory,
    };

    Object.values(conversationsInfo).forEach((ci) => {
        if (!ci) { return; }
        if (!conversations[ci.id] || ci.conversationType === CONVERSATION_TYPE.ECONSULT) return;

        let { priorityConversations = [], datedConversations = [] } =
            groupedConversations[conversationCategoryToConversationTypeMapping[ci.conversationType]] || {};

        // Priority conversations will be sorted into a separate list
        if (ci.priority) {
            priorityConversations = [...priorityConversations, conversations[ci.id]]
        } else {
            datedConversations = [...datedConversations, conversations[ci.id]]
        }

        groupedConversations[conversationCategoryToConversationTypeMapping[ci.conversationType]] = {
            priorityConversations,
            datedConversations
        }
    })

    each(groupedConversations, (group) => {
        group.priorityConversations = sortBy(group.priorityConversations, getConversationDate).reverse()
        group.datedConversations = sortBy(group.datedConversations, getConversationDate).reverse()
    })

    return groupedConversations
}

const handleTwilioEndCall = async (currentInviteToken:string,systemMessage:any,previousMeetingDetails:any,dispatch:any,providerFilterIds:ListItem[]|null,png:any) =>{
    const { inviteToken = null } = systemMessage.body || {}
	if(previousMeetingDetails?.inviteToken === inviteToken){
        png.currentRoom &&  png.currentRoom.disconnect()
		const result = await getWaitingRoomDetails(dispatch,providerFilterIds)
		dispatch({ type: 'SET_WAITING_ROOM_PATIENT_DETAILS', payload: result })
		dispatch({ type: 'SET_PREVIOUS_MEETING_DETAILS', payload: null })
		dispatch({type: 'SYS_NRU_EVISIT_END',payload:inviteToken})
	}else if(validateTwilioHandshake(currentInviteToken, systemMessage.body)){
        png.currentRoom &&  png.currentRoom.disconnect()
		dispatch({type: 'SYS_NRU_EVISIT_END',payload:inviteToken})
	}
}