import {each} from "underscore";
import {RegistrationState} from "admin/store/reducerTypes";
import {EMAIL_PATTERN, PASSWORD_VALIDATIONS, REGISTRATION_STEP} from "constant";
import VerifyIdentity from "./VerifyIdentity/VerifyIdentity";
import LoginOrCreate from "./LoginOrCreate/LoginOrCreate";
import CreateProfile from "./CreateProfile/CreateProfile";
import OTPConfirmation from "./OTPConfirmation/OTPConfirmation";
import LoginContent from "common/components/LoginContent/LoginContent";
import AddSecretQuestions from "./AddSecretQuestions/AddSecretQuestions";

// getRegistrationStep returns the component corresponding to the current step in the store
export const getRegistrationStep = (registration: RegistrationState) => {
    const {registrationStep} = registration
    switch (registrationStep) {
        case REGISTRATION_STEP.PROVIDER.VERIFY_IDENTITY:
            return VerifyIdentity
        case REGISTRATION_STEP.PROVIDER.LOG_IN_OR_CREATE:
            return LoginOrCreate
        case REGISTRATION_STEP.PROVIDER.CREATE_PROFILE:
            return CreateProfile
        case REGISTRATION_STEP.PROVIDER.OTP_CONFIRMATION:
            return OTPConfirmation
        case REGISTRATION_STEP.PROVIDER.EXISTING_USER_LOGIN:
            return LoginContent
        case REGISTRATION_STEP.PROVIDER.ADD_SECRET_QUESTIONS:
            return AddSecretQuestions
        default:
            return VerifyIdentity
    }
}

// getRegistrationHeading returns the heading substring corresponding to the current step in the store
export const getRegistrationHeading = (registration: RegistrationState) => {
    const {registrationStep} = registration
    switch (registrationStep) {
        case REGISTRATION_STEP.PROVIDER.VERIFY_IDENTITY:
            return "verifyIdentity"
        case REGISTRATION_STEP.PROVIDER.LOG_IN_OR_CREATE:
            return "loginOrCreate"
        case REGISTRATION_STEP.PROVIDER.CREATE_PROFILE:
            return "createProfile"
        case REGISTRATION_STEP.PROVIDER.OTP_CONFIRMATION:
            return "otpConfirmation"
        case REGISTRATION_STEP.PROVIDER.ADD_SECRET_QUESTIONS:
                return "addSecretQuestions"
        case REGISTRATION_STEP.PROVIDER.EXISTING_USER_LOGIN:
            return "existingUserLogin"
        default:
            return "verifyIdentity"
    }
}

// getRegistrationHeading returns the subheading substring corresponding to the current step in the store
export const getRegistrationSubheading = (registration: RegistrationState) => {
    const {registrationStep, emailVerified} = registration
    switch (registrationStep) {
        case REGISTRATION_STEP.PROVIDER.VERIFY_IDENTITY:
            return "verifyIdentity"
        case REGISTRATION_STEP.PROVIDER.LOG_IN_OR_CREATE:
            return "loginOrCreate"
        case REGISTRATION_STEP.PROVIDER.CREATE_PROFILE:
            return emailVerified ? "createProfilePassword": "createProfile"
        case REGISTRATION_STEP.PROVIDER.OTP_CONFIRMATION:
            return emailVerified ? "otpConfirmation.email": "otpConfirmation.sms"
        case REGISTRATION_STEP.PROVIDER.ADD_SECRET_QUESTIONS:
            return "addSecretQuestions"
        case REGISTRATION_STEP.PROVIDER.EXISTING_USER_LOGIN:
            return "existingUserLogin"
        default:
            return "verifyIdentity"
    }
}

// validateEmails checks that both emails fields match and follow the email pattern
export const validateEmails = (email1:string, email2:string) => {
    const trimmedEmail1 = email1.trim()
    const trimmedEmail2 = email2.trim()
    const validEmailPattern = (EMAIL_PATTERN).test(trimmedEmail1) && (EMAIL_PATTERN).test(trimmedEmail2)
    return trimmedEmail1 && trimmedEmail1.toUpperCase() === trimmedEmail2.toUpperCase() && validEmailPattern
}

export const validateSpecialCharacter = (pass:any) =>{
    const trimPassword = pass.trim()
    const regex = /^[a-zA-Z0-9!@#$%^&*)(+=_-]*$/
    const value = regex.test(pass)
    return trimPassword && value

}

// validateFirstEmail checks the first email field match and follow the email pattern
export  const  validateFirstEmail = (email1:string) => {
    if((EMAIL_PATTERN).test(email1) ) {
        return "" 
    } else if(email1 === ""){
        return ""
    } else{
        return "Registration.error.emailIncorrectFormat"
    }
    
}

// checkPasswordHasPersonal checks to see if the given password contains any of the first name, last name, or email as as substring
export const checkPasswordHasPersonal = (password:string, first:string, last:string, email:string) => {
    if (password) {
        const uPassword = password.toUpperCase()
        const uFirst = first.toUpperCase()
        const uLast = last.toUpperCase()
        const uEmail = email.toUpperCase()
        return (uPassword.includes(uFirst) || uPassword.includes(uLast) || uPassword.includes(uEmail))
    }
    return false
}

// uses regex to validate passwords match requirements. returns list of issues if any exist
export const validatePasswords = ({password, passwordConfirm, firstName, lastName, email}: RegistrationState) => {
    const validation: {valid: boolean, issues: string[]} = {
        valid: true,
        issues: []
    }

    const containsPersonal = checkPasswordHasPersonal(password, firstName, lastName, email)
    if (password && password === passwordConfirm && !containsPersonal) {
        each(PASSWORD_VALIDATIONS, (validationTest, type) => {
            if (!validationTest.test(password)) {
                validation.issues.push(type)
                validation.valid = false
            }
        })
    } else {
        validation.valid = false
    }
    return validation
}
