import { BORDER_RADIUS, COLORS, SIZING } from "../../../theme";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>({
    root: {
        position: 'absolute',
        borderRadius: BORDER_RADIUS.XL

    },

    wrapper: {
        minWidth: "640px",
        minHeight: "337px",
        background: "#6E6E79",
        color: "white",
        border: "1px solid rgba(57, 57, 72, 0.6)",
        borderRadius: BORDER_RADIUS.XL,
        overflow: 'visible',
        position: 'relative',
    },
    wrapperHeader: {
        background: "#393948",
        borderTopLeftRadius: BORDER_RADIUS.XL,
        borderTopRightRadius: BORDER_RADIUS.XL,
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0"
    },
    headerText: {
        marginRight: SIZING.scale400
    },
    buttonSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    pauseBtn: {
        height: SIZING.scale700,
        width: "79px",
        color: COLORS.LBLUE,
        background: "#FFFFFF",
        fontSize: SIZING.scale350,
        "&:hover": {
            background: "white",
        },
        marginRight: "10px"
    },

    close: {
        cursor: 'pointer',
        height: SIZING.scale700,
        width: "130px",
        color: "#FFFFFF",
        background: COLORS.LBLUE,
        fontSize: SIZING.scale350,
        "&:hover": {
            background: COLORS.LBLUE,
        },
    },
    textField: {
        margin: "20px",
        color: "orange",
        borderRadius: SIZING.scale200,
        maxHeight: "450px",
        overflow: "hidden scroll",
        "& .MuiOutlinedInput-multiline": {
            height: "14rem",

        },
        "& .MuiOutlinedInput-inputMultiline": {
            height: "100%",
            overflow: "auto",
            textIndent:'unset',
            "&::placeholder":{
                lineHeight:'11rem'
            }
        },
        "&:focus": {
            outline: "none"
        }
    },
    input: {
        width: SIZING.TRANSCRIBE_MODAL_WIDTH,
        color: COLORS.PRIMARY_BTN_TEXT,
        fontSize: SIZING.scale450,
        fontWeight: 600,
        border: "none",
        minHeight: "12rem",
        height: "12rem",
        "&::placeholder": {
            color: "rgba(153, 153, 153, 0.4)",
            fontSize: SIZING.scale1200,
            textAlign: "center",

        },


    }
}));