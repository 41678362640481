import {each} from "underscore"
import { PASSWORD_VALIDATIONS} from "constant";

// checkPasswordHasPersonal checks to see if the given password contains any of the first name, last name, or email as as substring
export const checkPasswordHasPersonal = (password:string, email:string) => {
    if (password) {
        const uPassword = password.toUpperCase()
        const uEmail = email.toUpperCase()
        return  !uPassword.includes(uEmail)
    }
    return false
}

// uses regex to validate passwords match requirements. returns list of issues if any exist
export const validatePasswords = ({password, passwordConfirm, email}: any) => {
    const validation: {valid: boolean, issues: string[]} = {
        valid: true,
        issues: []
    }

    const containsPersonal = password&&checkPasswordHasPersonal(password,email)
    if (password && password === passwordConfirm && containsPersonal) {
        each(PASSWORD_VALIDATIONS, (validationTest, type) => {
            if (!validationTest.test(password)) {
                validation.issues.push(type)
                validation.valid = false
            }
        })
    } else {
        validation.valid = false
    }
    return validation
}

export const validateSpecialCharacter = (pass:any) =>{
    const trimPassword = pass?.trim() || ''
    const regex = /^[a-zA-Z0-9!@#$%^&*)(+=_-]*$/
    const value = regex.test(pass)
    return trimPassword && value

}