import { AppReduxStore } from "store/reducerTypes";
import { Conversation } from "@twilio/conversations";
import { ConversationHandler } from "./types";
import { ConversationInfoItem } from "store/chat-types";
import { MESSAGE_ATTACHMENT_IDS } from "./constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useConversation = (conversationId: string): ConversationHandler => {
    const { conversations, messages } = useSelector((state: AppReduxStore) => state.chat);
    return {
        id: conversationId,
        conversation: conversations[conversationId],
        messages,
        onTyping: () => {
            conversations[conversationId]?.typing();
        },
        onSendMessage: (message: string, attachmentIds: string[] | undefined) => {
            const attributes = attachmentIds?.length ? { [MESSAGE_ATTACHMENT_IDS]: attachmentIds } : undefined;
            if (message?.trim().length) {
                return conversations[conversationId].sendMessage(message, attributes);
            }
            return Promise.reject();
        },
    };
};

// TODO This is a temporary solution. There should be a unified conversation model, which is searchable.
//      At that point a general filtering could be used instead of this specific one.
export const useConversations = (eConsultRequestId: string): { conversations: Conversation[], conversationsInfo: ConversationInfoItem[]} => {
    const { conversations, conversationsInfo } = useSelector((state: AppReduxStore) => state.chat);
    const [collectedConversations, setCollectedConversations] = useState<{ conversations: Conversation[], conversationsInfo: ConversationInfoItem[]}>({
        conversations: [],
        conversationsInfo: [],
    });

    const filterEconsultConversation = (
        conversationInfo: ConversationInfoItem | undefined
    ): conversationInfo is ConversationInfoItem => {
        return Boolean(
            conversationInfo &&
                conversationInfo.conversationType === "ECONSULT" &&
                conversationInfo.attributes.econsultRequestId === eConsultRequestId
        );
    };

    useEffect(() => {
        const eConsultEnableConversations = Object.values(conversationsInfo).filter(filterEconsultConversation);
        const filteredConversationIds = eConsultEnableConversations.map((convInfo) => convInfo!.id);
        const eConsultTwilioConversations = Object.values(conversations).filter((conv) =>
            filteredConversationIds.includes(conv.sid)
        );
        setCollectedConversations({conversations: eConsultTwilioConversations, conversationsInfo: eConsultEnableConversations});
    }, [conversations, conversationsInfo]);
    return collectedConversations;
};
