import { ProviderSearchOptions } from "../types";

export type ProviderFilterAutocompleteRequest = {
    limit: number;
    text: string;
    type: string;
    options: ProviderSearchOptions | null;
};

export const knownFilterNames = {
    HOSPITAL: "hospital",
    INSURANCE: "insurance",
    LANGUAGE: "language",
    LICENSE_STATE: "license-state",
    NETWORK: "network",
    PROVIDER_NAME: "provider-name",
    SPECIALTY: "specialty",
    SUB_SPECIALTY: "sub-specialty",
};
