import { Box, Button, FormControl, Typography } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import Controls from "admin/components/Controls/Controls";
import { Form } from "admin/components/useForm";
import { secretQuestions } from 'admin/constant';
import { completeRegistration, setRegistrationValues, updateUsername } from "admin/store/actions";
import { AppReduxStore } from "admin/store/reducerTypes";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import FormField from "common/components/FormField/FormField";
import Spinner from "common/components/Spinner/Spinner";
import { SESSION_STORAGE_DEFAULT_PROFILE_REDIRECT } from "constant";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setError } from "store/actions/error";
import { delay } from "underscore";
import { useStyles } from "./styles";
import { signInApp } from "authentication/appAuth";

// AddSecretQuestions is used to update the Secret Questions and answers to the user profile
const AddSecretQuestions = () => {
    const {oktaAuth} = useOktaAuth()
    const dispatch = useDispatch()
    const registration = useSelector((store: AppReduxStore) => store.registration)
    const {email, emailConfirm, emailVerified, complete, password, recoveryQuestion, recoveryAnswer} = registration
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const history = useHistory()
    const onChange = (e: any) => {
        dispatch(setRegistrationValues({[e.target.name]: e.target.value}))
    }

    // Once the email and password have been set, log the user in  and route to the profile page
    useEffect(() => {
        if (complete) {
            setLoading(true)
            loginAfterRegistration(3)
        }
    }, [complete, dispatch, email, oktaAuth, password])

    const loginAfterRegistration = (retries: number) => {
        // get session token using email and password
        signInApp({ username: email, password })
            .then((res: any) => {
                const sessionToken = res.sessionToken;
                // route to profile page
                oktaAuth.setOriginalUri('/sprofile')
                oktaAuth.token.getWithRedirect({
                    sessionToken: sessionToken,
                    responseType: 'token'
                }).then(() => {
                    setLoading(false)
                    sessionStorage.setItem(SESSION_STORAGE_DEFAULT_PROFILE_REDIRECT, "YES")

                }).catch((err: any) => {
                    setLoading(false)
                    dispatch(setError("",err.errorSummary))
                });
            })
            .catch((err: any) => {
                if (retries > 0) {
                    delay(loginAfterRegistration, 1000, retries - 1)
                } else {
                    dispatch(setError("", err.errorSummary))
                    history.push('/login');
                }
                setLoading(false)

            });
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        setLoading(true);
        if (emailVerified) {
            dispatch(completeRegistration())
        } else {
            dispatch(updateUsername())
        }
    }

    const buttonText = emailVerified ? "createProfile" : "submit"
    const submitDisabled = loading || (recoveryQuestion && recoveryAnswer && recoveryAnswer.trim().length > 3) ? false : true
    return (
        <Form onSubmit={onSubmit}>
            {emailVerified ? (
                <>
                    <FormControl className={classes.paddingTop}>
                        <Controls.Select
                            name="recoveryQuestion"
                            label="Security Question *"
                            placeholder="Select"
                            value={recoveryQuestion}
                            onChange={onChange}
                            options={secretQuestions}
                        />
                    </FormControl>
                    <FormControl className={classes.paddingTop}>
                        <Controls.Input
                            name="recoveryAnswer"
                            label="Answer"
                            placeholder="Enter Here"
                            value={recoveryAnswer}
                            onChange={onChange}
                        />
                    </FormControl>
                </>
            ):(
                <FormField labelId="Registration.label.emailConfirm" value={emailConfirm} onChange={onChange} type="email" />
            )}

            { emailVerified &&
                <Box className={classes.secretQuestionContainer}>
                    <CustomTypography className={classes.header} variant="largeHeader">
                        <FormattedMessage id="AddSecretQuestion.heading.SecurityAnswers" />
                    </CustomTypography>
                    <CustomTypography className={classes.header} variant="largeSubheader">
                        <FormattedMessage id="AddSecretQuestion.subheading.CaseSensitive" />
                    </CustomTypography>
                    <CustomTypography className={classes.header} variant="largeSubheader">
                        <FormattedMessage id="AddSecretQuestion.subheading.Characters" />
                    </CustomTypography>
                </Box>
            }
            {loading && <Spinner />}
            <Button type="submit" disabled={submitDisabled} variant="outlined" color="primary" fullWidth style={{"background": (submitDisabled) ? "#CACACE": "#0F7BBD", "color": (submitDisabled) ? "#565662": "#FFF"}}>
                <Typography variant="button">
                    <FormattedMessage id={`Registration.button.${buttonText}`} />
                </Typography>
            </Button>
        </Form>
    )
}

export default AddSecretQuestions