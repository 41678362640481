import React from "react";
import { useDispatch } from "react-redux";
import { Box, Link, DialogContent, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { MODAL_TYPES } from "constant";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import { setActiveModal } from "store/actions";
import CustomTypography from "common/components/CustomTypography/CustomTypography";
import Modal from "components/Modal/Modal";
import { useStyles } from "./styles";

const FAQ_LINK = "https://academy.altais.health/#/resources/b7c4b417-1995-4d7c-a6e8-90b1f9fc65c3";

// HelpCenter renders an info modal with a button link to the Altais help Center website. Users can choose to
// opt out of seeing this modal in the future
const HelpCenter = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const onClose = () => dispatch(setActiveModal(''))

    // onAccessClick opens a new tab in the users browser to the FAQ website and closes the modal
    const onFAQClick = () => {
        window.open(FAQ_LINK, "_blank");
        onClose();
    };
    const isProduction = window.location.hostname.toLowerCase().indexOf('app.altais.health') !== -1
    return (
        <Modal maxWidth="xs" id={MODAL_TYPES.PROVIDER.HELP_CENTER}>
            <Box className={classes.titleWrapper}>
                <CustomTypography variant="modalHeader">
                    <FormattedMessage id="HelpCenter.title" />
                </CustomTypography>
                <Close className={classes.close} onClick={onClose} id="QA_learning_center_close_button" />
            </Box>
            <DialogContent className={classes.content}>
                <Box className={classes.insideBox}>
                    <Box className={classes.insideTextBox}>
                        <CustomTypography variant="descriptionBold">
                            <FormattedMessage id="HelpCenter.helpDocuments" />
                        </CustomTypography>
                        <CustomTypography className={classes.insideText}>
                            <Link className={classes.links} onClick={onFAQClick}>
                                <FormattedMessage id="HelpCenter.FAQ" />
                            </Link>
                        </CustomTypography>
                    </Box>
                    <Box className={classes.insideTextBox1}>
                        <CustomTypography variant="descriptionBold">
                            <FormattedMessage id="HelpCenter.customer.support" />
                        </CustomTypography>
                        <ul>
                            <li><FormattedMessage id="HelpCenter.number" /></li>
                            <li>
                                <Link className={classes.links1} href={
                                    isProduction ?
                                        "mailto:help@altais.com" :
                                        "mailto:support@altaishealth1638497758.zendesk.com"} >
                                    <FormattedMessage id={isProduction ?
                                        "HelpCenter.emailProd" : "HelpCenter.email"} />
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <Box className={classes.insideTextBox3}>
                        <CustomTypography variant="descriptionBold">
                            <FormattedMessage id="HelpCenter.head.office" />
                        </CustomTypography>
                        <CustomTypography className={classes.insideText} >
                            <FormattedMessage id="HelpCenter.product" />
                        </CustomTypography>
                        <Typography className={classes.insideText1} variant="body2">
                            <FormattedMessage id={"HelpCenter.address"} />
                        </Typography>
                    </Box>

                </Box>
            </DialogContent>
        </Modal>
    )
}

export default HelpCenter
