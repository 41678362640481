import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "png/theme";

export const useStyles = makeStyles(() =>({
    wrapper: {
        background: COLORS.RED,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
        minHeight: "64px",
        animationName: '$BannerAudioVideoPermissionRed',
        animationDuration: "2s",
        animationIterationCount: "infinite",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        fontSize: '14px',
        color: COLORS.WHITE,
        flexDirection: 'column',
        padding: SIZING.scale200,
        "& p": {
            fontWeight: 900,
            marginBottom: '5px',
            marginTop: '5px',
            wordWrap:'break-word',
            whiteSpace:'break-spaces',
            textAlign:'center'
        },
        "& span": {
            fontWeight: 600,
            textDecoration: 'underline',
            cursor: 'pointer'
        },
    },
    "@keyframes BannerAudioVideoPermissionRed": {
        "0%": {
            backgroundColor: "#AB201F"
        },
        "50%": {
            backgroundColor: "#E32B29"
        },
        "100%": {
            backgroundColor: "#AB201F"
        }
    },
}));