import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { ModalPanel } from "lib/ui-components/modal-panel";
import { ModalSidePanelLayout } from "lib/ui-components/modal-layouts";
import { Pagination } from "lib/ui-components/pagination";
import { PermissionOptions, SelectedProvider } from "../types";
import {
    Provider,
    ProviderSearch,
    ProviderSearchFilters,
    useProviderSearchController,
} from "lib/business-components/provider-search";
import { ProviderListItem } from "lib/business-components/provider-search/provider-list-item";
import { ReactComponent as EmptyIcon } from "icon-library/SVG/User_Doctor-Stethoscope-Shoulder.svg";
import { ReactComponent as Trash } from "icon-library/SVG/Trash.svg";
import { TableList } from "lib/ui-components/table-list";
import { useIntl } from "react-intl";
import * as CSS from "../class-names";
import EmptyMessage from "common/components/empty-message/empty-message";
import SVGIcon from "common/components/svg-icon/svg-icon";

const selectedProvidersIdPrefix = "QA_CareTeam__Edit__ProviderSearch__SelectedProviders";

export type ProviderSearchTabProps = {
    selectedProviders: SelectedProvider[];
    onChange: Dispatch<SetStateAction<SelectedProvider[]>>;
};

export const ProviderSearchTab = ({ selectedProviders, onChange }: ProviderSearchTabProps) => {
    const intl = useIntl();
    const [portalEl, setPortalEl] = useState<HTMLDivElement | null>(null);
    const { filterProps, searchProps, paginationProps } = useProviderSearchController({
        withEnabledCareTeam: true,
        withinMyPractice: true,
    });

    const selectProvider = useCallback(
        (provider: Provider) => {
            onChange((selection) => [...selection, provider]);
        },
        [onChange]
    );

    const removeProvider = useCallback(
        (provider: Provider) => {
            onChange((selection) => selection.filter((selection) => selection.id !== provider.id));
        },
        [onChange]
    );

    const toggleProviderPermission = useCallback(
        (provider: SelectedProvider, permission: keyof PermissionOptions) => {
            onChange((selection) =>
                selection.map((s) => (provider.id === s.id ? { ...s, [permission]: !s[permission] } : s))
            );
        },
        [onChange]
    );

    const handleSelection = useCallback(
        (provider: Provider, selected: boolean) => {
            if (selected) {
                selectProvider(provider);
            } else {
                removeProvider(provider);
            }
        },
        [selectProvider, removeProvider]
    );

    return (
        <ModalSidePanelLayout
            leftPanelChildren={
                <ModalPanel
                    title={intl.formatMessage({ id: "careTeam.editor.tabs.members.selectedProviders.title" })}
                    emptyMessage={
                        <EmptyMessage
                            className="emptyMessage"
                            Icon={<SVGIcon svg={EmptyIcon} size="scale2500" color="DARK_EMPTY_MESSAGE_ICON" />}
                            primaryMessage={{ id: "careTeam.editor.tabs.members.selectedProviders.emptyMessage" }}
                        />
                    }
                >
                    {selectedProviders.length > 0 && (
                        <>
                            <TableList id={selectedProvidersIdPrefix}>
                                {selectedProviders.map((provider) => {
                                    const selected = Boolean(
                                        selectedProviders?.find((selProvider) => selProvider.id === provider.id)
                                    );
                                    const contextMenuItems = [
                                        {
                                            id: `${selectedProvidersIdPrefix}_ContextMenu_Remove_${provider.id}`,
                                            disabled: provider.primary,
                                            labelKey: "careTeam.editor.tabs.members.contextMenu.remove",
                                            danger: true,
                                            icon: Trash,
                                            onClick: () => removeProvider(provider),
                                        },
                                        {
                                            id: `${selectedProvidersIdPrefix}_ContextMenu_Admin_${provider.id}`,
                                            disabled: provider.primary,
                                            labelKey: "careTeam.editor.tabs.members.contextMenu.admin",
                                            onClick: () => toggleProviderPermission(provider, "admin"),
                                            checked: Boolean(provider.admin),
                                        },
                                    ];
                                    return (
                                        <ProviderListItem
                                            key={provider.id}
                                            idPrefix={selectedProvidersIdPrefix}
                                            contextMenuItems={contextMenuItems}
                                            provider={provider}
                                            selected={selected}
                                            portalEl={portalEl}
                                            tag={provider.primary ? intl.formatMessage({ id: "careTeam.members.roles.primary" }) : undefined}
                                        />
                                    );
                                })}
                            </TableList>
                            <div ref={setPortalEl}></div>
                        </>
                    )}
                </ModalPanel>
            }
        >
            <ProviderSearchFilters {...filterProps} />
            <ModalPanel title={intl.formatMessage({ id: "careTeam.editor.tabs.members.peopleInYourPractice" })}>
                <ProviderSearch
                    {...searchProps}
                    onSelectionChange={handleSelection}
                    selectedProviders={selectedProviders.map((s) => s.id)}
                    allowEmptySearch
                    emptyMessage={
                        <EmptyMessage
                            className={CSS.CareTeamEditorProviderSearchEmptyMessage}
                            primaryMessage={{ id: "careTeam.editor.tabs.members.emptyResultsMessage" }}
                        />
                    }
                    errorMessage={
                        <EmptyMessage
                            className={CSS.CareTeamEditorProviderSearchEmptyMessage}
                            primaryMessage={{ id: "careTeam.editor.tabs.members.errorMessage" }}
                        />
                    }
                />
            </ModalPanel>
            <Pagination
                {...paginationProps}
                showTotalCount={true}
                totalCountKey="careTeam.editor.tabs.members.totalCount"
            />
        </ModalSidePanelLayout>
    );
};
