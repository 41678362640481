import { ListItemIcon, ListItemText, MenuItem, Popover } from "@mui/material";
import { MODAL_TYPES } from "constant";
import { ReactComponent as ArrowRightSquare } from "icon-library/SVG/Arrow_Top-Right-Square.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "store/actions";
import { AppReduxStore } from "store/reducerTypes";
import { createSvgIcon } from "utils";
import { DecorationComponentProps } from "../types";
import { unescape } from "underscore";
import { checkSideNavPermission } from "../sideNavGuard";
import { useStyles } from "./styles";
const ArrowRightSquareIcon = createSvgIcon(ArrowRightSquare);

const EducationMenuDecorations = ({ args, callback }: DecorationComponentProps) => {
    const { LMS_URL } = window.env;
    const { registeredUserProfileInfo, currentProfileRoleJoin } = useSelector((store: AppReduxStore) => store.user);
    const products = currentProfileRoleJoin?.products;
    const qualityURL = unescape(currentProfileRoleJoin?.providerProfile?.qualityiqUrl)
    const showLmsModal = registeredUserProfileInfo?.showLmsModal;
    const anchorEl = args
    const open = Boolean(anchorEl?.tagName === "LI" ? anchorEl : null);
    const styles = useStyles();
    const dispatch = useDispatch()
    const handleClose = () => {
        callback && callback(false)
    };
    const handleAcademyMenuItemClick = () => {
        if (showLmsModal) {
            dispatch(setActiveModal(MODAL_TYPES.PROVIDER.LEARNING_CENTER))
        } else {
            window.open(LMS_URL, "_blank")
        }
    }
    const handleQualityIQMenuItemClick = () => {
        if (qualityURL) {
            if (qualityURL.search(/^http[s]?:\/\//) === -1) {
                window.open("http://" + qualityURL, "_blank")
            } else {
                window.open(qualityURL, "_blank")
            }
        }
    }
    return (
        <Popover hideBackdrop={true}
            anchorEl={anchorEl}
            id="education-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            PaperProps={{
                elevation: 0,
                classes: { root: styles.sx }
            }}
            disableEnforceFocus={true}
            transformOrigin={{ horizontal: -5, vertical: 10 }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
            {checkSideNavPermission('learning', products) && <MenuItem onClick={handleAcademyMenuItemClick} className={styles.mb10}>
                <ListItemIcon>
                    <ArrowRightSquareIcon fill="#565662" fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: "body1", className: styles.itemText }}>
                    <FormattedMessage id={"SideNav.menuItem.learning"} />
                </ListItemText>
            </MenuItem>}
            {checkSideNavPermission('qualityIQ', products) && <MenuItem onClick={handleQualityIQMenuItemClick}>
                <ListItemIcon>
                    <ArrowRightSquareIcon fill="#565662" fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: "body1", className: styles.itemText }}>
                    <FormattedMessage id={"SideNav.menuItem.quailityIq"} />
                </ListItemText>
            </MenuItem>}
        </Popover>
    );
}
export default EducationMenuDecorations;
