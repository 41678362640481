import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TextField, Typography, Tab, Tabs, Box } from "@mui/material";
import { useStyles } from "./styles";
import FormActionToolbar from './FormActionToolbar/FormActionToolbar';
import Overview from "./Overview/Overview";
import RulesAndLogic from "./RulesAndLogic/RulesAndLogic";
import Triggers from "./Triggers/Triggers";
import PanelMetrics from "./PanelMetrics/PanelMetrics";
import { useDispatch, useSelector } from 'react-redux';
import { AppReduxStore } from 'admin/store/reducerTypes';
import { IFormInput } from "admin/views/viewsTypes";
import { SecureRoute } from "authentication/app-secure-route";
import { Link, Switch, BrowserRouter, useRouteMatch } from "react-router-dom";
import { setQuestionnaireNotificationTriggers, setQuestionnaireTriggers, setQuestionnaireAppointmentTypes, fetchPatientForm, setPatientFormReadOnly, setSelectedPatientForm, createNewVersionNumber, getAllVersionOfForm, setFormPublished} from 'admin/store/actions/configuration';
import Versioning from './Version/Versioning';
import { setNavBreadcrumbText, setFormErrors, setActiveModal } from 'admin/store/actions';
import { MODAL_TYPES } from "admin/constant";
import { getApproverList } from "admin/store/actions";
import { showNotification } from 'admin/store/actions/snackbar';

const PatientFormConfig = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { auth, configuration,modalAdmin } = useSelector((state: AppReduxStore) => state);
  const currentForm: IFormInput = configuration && configuration.sPatientFormA ? configuration.sPatientFormA : null;
  const isFormReadOnly: boolean = configuration && configuration.isFormReadOnly;
  const textFieldVariant: any = isFormReadOnly ? 'standard' as 'standard' : 'outlined' as 'outlined';
  const inputProps: any = isFormReadOnly
  ? { disableUnderline: true, disabled: isFormReadOnly, readOnly: isFormReadOnly }
  : { disabled: isFormReadOnly, readOnly: isFormReadOnly };
  const dispatch = useDispatch();
  const formErrors = configuration && configuration.formErrors;
  const matchId: any = useRouteMatch("/admin/configurations/patientforms/:id");
  const matchTab: any = useRouteMatch("/admin/configurations/patientforms/:id/:tab");
  const formId = matchId?.params?.id ? matchId?.params?.id : null;
  const baseUrl = `/admin/configurations/patientforms/${formId}`;
  const tabName = matchTab?.params?.tab ? '/' + matchTab?.params?.tab : '/';
  const [tab, setTab] = useState(tabName);
  const [currentFormState, setCurrentFormState] = useState<IFormInput>(currentForm);
  const userType = auth.currentProfileRoleJoinInfo?.level.type;
  const userID = auth.currentProfileRoleJoinInfo?.level.id;
  const practiceId = auth.currentProfileRoleJoinInfo?.practice.id;
  const formStateMap : any = {
    "IN_PROGRESS" : 'In Progress',
    "PROVISIONED" : 'Provisioned',
    "ARCHIVED" : 'Archived',
    "PUBLISHED" : 'Published',
    "APPROVED" : 'Approved',
    "null" : '',
    "PENDING_APPROVAL" : "Pending Approval",
    "REJECTED" : "Rejected",
  }

  useEffect(() => {
    if (auth.currentOrg || auth.currentPractice) {
      if(formId !== 'createNewForm') {
        //Load existing form
        dispatch(setQuestionnaireAppointmentTypes(null));
        dispatch(setQuestionnaireTriggers(null));
        dispatch(setQuestionnaireNotificationTriggers(null));
        dispatch(setSelectedPatientForm(null));
        dispatch(setPatientFormReadOnly(true));
        dispatch(fetchPatientForm(formId));
      } else {
        //Create new form
        const newForm: IFormInput = {
          id: '',
          title: '',
          description: '',
          details: '',
          duration: '',
          hasScore: false,
          type: 'CustomForm',
          name: 'customForm-' + new Date().valueOf(),
          isEnabled: false,
          number: '',
          formType: 'Non-Clinical',
          publishToEhr: false,
          configurable: true,
          questions: [],
          formVersion: '',
          formStatus: 'IN_PROGRESS',
          lastModifiedDate: new Date().toISOString().slice(0, 10)
        };
        dispatch(setSelectedPatientForm(newForm));
        dispatch(setPatientFormReadOnly(false));
      }
    }
  }, [auth.currentOrg, auth.currentPractice, dispatch, formId]);

  useEffect(() => {
    if (currentForm && Object.keys(currentForm).length) {
      dispatch(getAllVersionOfForm(currentForm.name));
      dispatch(setNavBreadcrumbText(currentForm?.name));
    }
  }, [dispatch, currentForm]);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  const allTabs = ['/', '/rulesandlogic', '/triggers', '/panelmetrics'];

  const handleToggle = () => {
    dispatch(setFormPublished({id: currentForm.id}));
    // dispatch(togglePatientFormStatus(currentForm));
    // dispatch(setSelectedPatientForm(currentForm.id));
  };

  const handleEditModeToggle = () => {
    const newReadOnlyState = !isFormReadOnly;
    if(currentForm.formStatus === "PUBLISHED" || currentForm.formStatus === "PROVISIONED"){
      dispatch(createNewVersionNumber(currentForm.id));
    }
    if(currentForm.formStatus === "APPROVED" || currentForm.formStatus === "ARCHIVED"){
      dispatch(showNotification(`Can Not Make Form Editable Due to Form Status is ${currentForm.formStatus}`, "error"));
    }
    if(currentForm.formStatus === "IN_PROGRESS"){
      dispatch(setPatientFormReadOnly(newReadOnlyState));
    }
  };

  const handleReviewToggle = () => {
    dispatch(getApproverList(userID + ' ' + userType + ' ' + practiceId))
    dispatch(setActiveModal(MODAL_TYPES.SEND_FOR_REVIEW));
  }

  const handleApproveToggle = () => {
    dispatch(getAllVersionOfForm(currentForm.name))
    dispatch(setActiveModal(MODAL_TYPES.APPROVAL_MODAL));
  }

  const handleRejectToggle = () => {
    dispatch(getAllVersionOfForm(currentForm.name))
    dispatch(setActiveModal(MODAL_TYPES.REJECT_MODAL));
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.name === "title") {
      currentForm.title = event.target.value;
      if (currentForm.title === "") {
        dispatch(setFormErrors({
          type: "missingFormTitle",
          value: true
        }));
      } else {
        dispatch(setFormErrors({
          type: "missingFormTitle",
          value: false
        }));
      }
    } else {
      currentForm.number = event.target.value;
    }
    setCurrentFormState(currentForm);
    dispatch(setSelectedPatientForm(currentForm));
  };

  const handleModalClick = () =>{
    dispatch(getAllVersionOfForm(currentForm.name))
    if(modalAdmin.activeModal === "Form Versioning"){
      dispatch(setActiveModal(""));
    }else{
      dispatch(setActiveModal("Form Versioning"));
    }
  }

  useEffect(() => {
    if (currentForm && Object.keys(currentForm).length > 0) {
      setCurrentFormState(currentForm);
    }
  }, [currentForm, setCurrentFormState]);

  return (
    <div className={classes.root}>
      {currentForm && (currentForm.id || currentForm.id === '') && 
        <>
        <div className={classes.row1}>
        <div>
          {formId !== 'createNewForm' &&
            <Typography color="textPrimary" variant="h3">{currentForm.title}</Typography>
          }
          <form className={classes.root} noValidate autoComplete="off">
            {formId === 'createNewForm' &&
            <Box display="flex" flexDirection="row" my={1}>
              <Box alignSelf="center">
                <label className={classes.label} htmlFor={"formConfig-title-txtField"}>
                  {intl.formatMessage({ id: "PatientFormConfig.Label.Title" })}
                </label>
              </Box>
              <Box mx={2}>
              <TextField
                autoFocus={formId === 'createNewForm' ? true : false}
                error={formErrors["missingFormTitle"]}
                helperText={(formErrors["missingFormTitle"]) ? intl.formatMessage({ id: "PatientFormConfig.HelperText.Title.Missing" }) : null}
                id={"formConfig-title-txtField"}
                name="title"
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "PatientFormConfig.Placeholder.EnterTitle" })}
                value={currentFormState.title ? currentFormState.title : ''}
                variant={textFieldVariant as any}
                InputProps={inputProps}
              />
            </Box>
            </Box>}
            <Box display="flex" flexDirection="row" my={1}>
              <Box alignSelf="center">
                <label className={classes.label} htmlFor={"formConfig-formNumber-txtField"}>
                  {intl.formatMessage({ id: "PatientFormConfig.Label.FormNumber" })}
                </label>
              </Box>
              <Box mx={2}>
                <TextField name="number" value={currentFormState.number ? currentFormState.number : ''} onChange={handleChange} id={"formConfig-formNumber-txtField"} variant={textFieldVariant as any} InputProps={inputProps} autoFocus={formId !== 'createNewForm' ? true : false} placeholder="Enter Form Number" />
              </Box>
            </Box>
          </form>
        </div>
        {formId !== 'createNewForm' && <div className={classes.version} onClick={handleModalClick}>
            <span className={classes.bold}>{currentForm.formStatus === "IN_PROGRESS" ? `Version: ${currentForm.formVersion} Draft` : `Version: ${currentForm.formVersion}`}<br/></span>
            <span className={classes.timeSize}>({formStateMap[currentForm.formStatus]}, {new Date(currentForm.lastModifiedDate).toLocaleDateString()} )</span>
          </div>}
        {modalAdmin.activeModal === "Form Versioning" && <Versioning open={modalAdmin.activeModal === "Form Versioning"} onCloseClick={handleModalClick} title={currentFormState.title}/>}
        </div>
        <FormActionToolbar currentForm={currentForm} handleApproveToggle={handleApproveToggle} handleRejectToggle={handleRejectToggle} readOnlyState={isFormReadOnly} handleEditModeToggle={handleEditModeToggle} handleReviewToggle={handleReviewToggle} isEnabled={currentForm.isEnabled} handleToggle={handleToggle} ></FormActionToolbar>
        <BrowserRouter>
              <Tabs
                value={tab}
                indicatorColor="primary" 
                textColor="primary"
                onChange={handleChangeTab}
                variant="fullWidth"
              >
                <Tab label="Overview" component={Link} to={`${baseUrl}${allTabs[0]}`} value={allTabs[0]} />
                <Tab label="Rules &amp; Logic" component={Link} to={`${baseUrl}${allTabs[1]}`} value={allTabs[1]} />
                <Tab label="Triggers" component={Link} to={`${baseUrl}${allTabs[2]}`} value={allTabs[2]} />
                <Tab label="Panel Metrics" disabled component={Link} to={`${baseUrl}${allTabs[3]}`} value={allTabs[3]} />
              </Tabs>
              <Box p={1}>
                <Switch>
                    <SecureRoute exact path={`${baseUrl}${allTabs[0]}`} render={() => <Overview currentForm={currentForm} />} />
                    <SecureRoute path={`${baseUrl}${allTabs[1]}`} render={() => <RulesAndLogic currentForm={currentForm} />} />
                    <SecureRoute path={`${baseUrl}${allTabs[2]}`} render={() => <Triggers currentForm={currentForm} />} />
                    <SecureRoute path={`${baseUrl}${allTabs[3]}`} render={() => <PanelMetrics currentForm={currentForm} />} />
                </Switch>
              </Box>
        </BrowserRouter>
        </>
      }
    </div>
  );
}

export default PatientFormConfig;
