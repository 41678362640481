import React, { useEffect } from "react";
import Spinner from "common/components/Spinner/Spinner";
import { PatientInfo } from "views/EConsult/types";
import { useApi } from "common/utils/use-api";
import PatientDetailsItem from "./patient-details-item";
import { useStyles } from "./styles";

type PatientId = {
    id: string;
    onStateChange: (hasResult: boolean) => void;
};

const PatientDetails = ({ id, onStateChange }: PatientId) => {
    const classes = useStyles();

    const {
        content: patient,
        fetchInfo: { hasResult },
    } = useApi<null, PatientInfo, PatientInfo | null>(
        {
            autoRequest: true,
            defaultContent: null,
            endpoint: `/patient/v1/econsult/patients/autocomplete/${id}`,
            method: "GET",
        },
        null
    );

    const hasPatient = hasResult && patient;

    useEffect(() => {
        onStateChange(hasResult);
    }, [hasResult, onStateChange]);

    return (
        <>
            {hasPatient ? (
                <PatientDetailsItem patient={patient} className={classes.patientDetails}/>
            ) : (
                <div className={classes.patientDetailsLoaderWrapper}>
                    <Spinner/>
                </div>
            )}
        </>
    );
};

export default PatientDetails;
