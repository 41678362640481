import React, { useState } from "react";

export function useForm(initialFValues: any, validateOnChange = false, validate: any) {

    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({} as any);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value?.trim() === "" ? null : value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }
    const resetValues = (values: any) => {
        setValues(values);
    }
    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }
    const trimValues = () => {
        Object.keys(values).map(k => values[k] = typeof values[k] == 'string' ? values[k].trim() : values[k])
    }
    return {
        values,
        resetValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        trimValues
    }
}

export function Form(props: any) {
    const { children, ...other } = props;
    return (
        <form autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

