import React, { Fragment, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, DialogActions, DialogContent, IconButton, Paper, Tab, Tabs, Typography } from "@mui/material";
import { ReactComponent as Close } from "admin/assets/common/Close.svg";
import Modal from "admin/components/Modal/Modal";
import { MODAL_TYPES } from "admin/constant";
import { setActiveModal } from "admin/store/actions";
import LocationAddForm from "./LocationAddForm";
import { useStyles } from "./styles";
import { COLORS } from "admin/theme";
import LocationAddBulk from "./LocationAddBulk";
import { appendEhrSystemKeyTemplatePath } from "admin/common/utils";
import { AppReduxStore } from "admin/store/reducerTypes";
import Spinner from "common/components/Spinner/Spinner";
const { LOCATION_BULK_UPLOAD_CSV_TEMPLATE } = (window as any).env;

const LocationAdd = () => {
    const { auth, admin, practice } = useSelector((state: AppReduxStore) => state);
    const dispatch = useDispatch();
    const intl = useIntl();
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [isBulkUploadDisabled, setIsBulkUploadDisabled] = useState(true);
    const [isUploading, setIsUploading] = useState(false);

    const addLocationFormRef = useRef<any>();
    const addLocationBulkRef = useRef<any>();

    const handleClose = () => {
        dispatch(setActiveModal(""))
        setIsBulkUploadDisabled(true);
    };

    const handleChangeTab = (event: any, newTab: any) => {
        setTab(newTab);
    };

    const handleSaveLocation = (event: any) => {
        const result = addLocationFormRef.current.submitForm(event);
        if (result)
            handleClose();
    };

    const handleSaveAddLocation = (event: any) => {
        addLocationFormRef.current.submitForm(event);
    };

    const modalClasses = {
        paper: classes.wrapper
    };

    const handleAddedFile = () => {
        setIsBulkUploadDisabled(false);
    };

    const handleRemovedFile = () => {
        setIsBulkUploadDisabled(true);
    };

    const handleBulkUpload = () => {
        addLocationBulkRef.current.submitLocationAdd()
    }
    const handleLoading = (flag: boolean) => {
        setIsUploading(flag)
    }
    const ehrInfo = admin.ehrSourceSystems?.find((e: any) => e.id === practice?.sPractice?.ehrSourceSystemId)
    return (
        <Modal onClose={handleClose} classes={modalClasses} id={MODAL_TYPES.ADD_LOCATION}>
            <DialogActions className={classes.dlgAction} style={{ paddingTop: '32px' }}>
                <Box flexGrow={1} >
                    <Typography variant="body2" className={classes.dialogTitle}>
                        <FormattedMessage id={"AddLocation.ModalHeading"} />
                    </Typography>
                </Box>
                <Box>
                    {tab === 0 && <IconButton id="btnCloseLocationModal" size="large" onClick={handleClose}>
                        <Close />
                    </IconButton>}
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction} style={{ paddingBottom: '16px' }}>
                <Box flexGrow={1}>
                    {tab === 0 && ehrInfo?.hasTemplate && <Button id="btnDownloadTemplate" variant="outlined" style={{ color: COLORS.PRIMARY_BTN_TEXT }}
                        aria-haspopup="true" href={appendEhrSystemKeyTemplatePath(LOCATION_BULK_UPLOAD_CSV_TEMPLATE,
                            auth.currentPractice?.ehrSourceSystemId || practice?.sPractice?.ehrSourceSystemId)}>
                        {intl.formatMessage({ id: "LocationAdd.DowloadTemplate" })}
                    </Button>}
                </Box>
                <Box>
                    {tab === 1 ? <Fragment>
                        <Button id="btnCancel" variant="outlined"
                            aria-haspopup="true" onClick={handleClose}>
                            {intl.formatMessage({ id: "AddLocation.Action.Cancel" })}
                        </Button>
                        <Button id="btnSaveAndAddAnother" variant="contained" color="primary" style={{ marginLeft: '10px' }}
                            aria-haspopup="true" onClick={handleSaveAddLocation}>
                            {intl.formatMessage({ id: "AddLocation.Action.SaveAndAddAnother" })}
                        </Button>
                        <Button id="btnSaveAndFinish" variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={handleSaveLocation}>
                            {intl.formatMessage({ id: "AddLocation.Action.SaveAndFinish" })}
                        </Button>
                    </Fragment> : isUploading ? <Spinner /> :
                        <Button id="btnUploadCSV" variant="outlined" style={{ backgroundColor: '#C7C7CB' }}
                            aria-haspopup="true" onClick={handleBulkUpload} disabled={isBulkUploadDisabled}
                        >
                            Upload
                        </Button>}
                </Box>
            </DialogActions>
            <DialogActions className={classes.dlgAction}>
                <Box width="100%">
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        onChange={handleChangeTab}
                        aria-label="Add Location tab">
                        <Tab label={intl.formatMessage({ id: "LocationAdd.BulkUpload" })} />
                        <Tab label={intl.formatMessage({ id: "LocationAdd.Tab.AddLocation" })} />
                    </Tabs>
                </Box>
            </DialogActions>
            <DialogContent dividers={true} >
                <Paper square elevation={0}>
                    {tab === 1 ? <Box className={classes.tabContentWrapper}>
                        <LocationAddForm ref={addLocationFormRef} />
                    </Box> :
                        <Box className={classes.tabContentWrapper}>
                            <LocationAddBulk ref={addLocationBulkRef} handleAddedFile={handleAddedFile} handleRemovedFile={handleRemovedFile} handleClose={handleClose} handleLoading={handleLoading}></LocationAddBulk>
                        </Box>}
                </Paper>
            </DialogContent>
        </Modal>
    )
}

export default LocationAdd;
