import { FunctionalMenuItem, isSelectableMenuItem } from "common/components/OptionMenu/types";
import { ReactComponent as SnowmanSvg } from "icon-library/SVG/Ellipsis-Vertical.svg";
import { useIntl } from "react-intl";
import { useRef, useState } from "react";
import { useStyles } from "./styles";
import OptionMenu from "common/components/OptionMenu/OptionMenu";
import classNames from "classnames";

export type ContextMenuProps = {
    className?: string;
    id?: string;
    menuItems: FunctionalMenuItem[];
};

const ContextMenu = ({ className, id = "QA_contextMenu", menuItems }: ContextMenuProps) => {
    const styles = useStyles();
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const snowmanRef = useRef<SVGSVGElement | null>(null);
    const onSnowmanClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const renderZeroItems = () => {
        return null;
    };
    const renderSingleItem = (menuItem: FunctionalMenuItem): JSX.Element | null => {
        if (isSelectableMenuItem(menuItem)) {
            const Icon = menuItem.icon;
            return (
                <div
                    id={menuItem.id}
                    data-testid={`${id}_icon`}
                    className={classNames(styles.ContextMenu, "ContextMenu", className)}
                    title={
                        menuItem.labelText ?? intl.formatMessage({ id: menuItem.labelKey }, menuItem.labelValues ?? {})
                    }
                    onClick={menuItem.onClick}
                >
                    {typeof menuItem.icon === "function"
                        ? menuItem.icon?.({ title: menuItem.labelText ?? "" })
                        : Icon && <Icon />}
                </div>
            );
        }
        return null;
    };
    const renderMultiItems = (menuItems: FunctionalMenuItem[]): JSX.Element => (
        <div className={classNames(styles.ContextMenu, "ContextMenu", className)}>
            <div id={`${id}_icon`} data-testid={`${id}_icon`} onClick={onSnowmanClick}>
                <SnowmanSvg ref={snowmanRef} />
            </div>
            <OptionMenu
                id={`${id}_list`}
                className={styles.ContextMenu__List}
                handleClose={() => {
                    setOpen(false);
                }}
                open={open}
                anchorEl={snowmanRef}
                menuConfig={menuItems}
                offset={[0, 12]}
            />
        </div>
    );

    const renderedContextMenu = !menuItems.length
        ? renderZeroItems()
        : menuItems.length === 1
        ? renderSingleItem(menuItems[0])
        : renderMultiItems(menuItems);

    return <>{renderedContextMenu}</>;
};

export default ContextMenu;
