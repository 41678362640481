export const createReducer = (initialState: any, reducerMap: any) => {
	return (state = initialState, action: any) => {
		const reducer = reducerMap[action.type]
		if (!reducer) {
			return state
		}
		return reducer(state, action.payload)
	}
}

export default createReducer
