import { makeStyles } from "@mui/styles";
import { SIZING, COLORS } from "png/theme";

export const useStyles = makeStyles(() =>({
    base: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100vh',
        flexDirection: 'column',
        paddingBottom: `${SIZING.scale600} 0`,
        background: 'linear-gradient(180deg, rgba(232,245,254,1) 15%, rgba(255,255,255,1) 100%, rgba(0,0,0,1) 100%)'
    },
    desc: {
        marginBottom: SIZING.scale1000,
        textAlign: 'center'
    },
    docName:{
        fontWeight:'bold'
    },
    patientName:{
        fontWeight:900
    },
    date:{
        fontSize:SIZING.scale400,
        padding: `0 ${SIZING.scale600}`,
        textAlign:'center',
        color: COLORS.BLACK
    },
    waitingRoomBtn: {
        background: COLORS.SECONDARY_BUTTON,
        color: COLORS.WHITE,
        "&:hover": {
            background: COLORS.SECONDARY_BUTTON,
        },
        "& span": {
            fontSize: SIZING.scale400,
        },
        marginTop: SIZING.scale1000,
        padding: SIZING.scale400

    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    mainLogo: {
        height: '130px',
        width: '130px',
        marginBottom: SIZING.scale1000,

    },
    footer: {
        marginTop: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        "& p": {
            fontSize: SIZING.scale300
        }
    },
    input: {
        marginTop: SIZING.scale800,
        width: '100%',
        "& label": {
            fontSize: SIZING.scale300,
            fontWeight: 800,
            color: COLORS.GREY
        },
        "& .MuiBox-root": {
            width: '100%',
            height: SIZING.scale1100,
            border: `2px solid ${COLORS.INPUT_BORDER}`,
            marginBottom:0,
            borderRadius:SIZING.scale75
        },
        "& .MuiInputBase-root": {
            width: '100%',
            height: '100%',
            "& input": {
                padding: `${SIZING.scale200} ${SIZING.scale150} !important`,
                background: COLORS.WHITE,
                color: COLORS.BLACK
            }
        }
    }
}));