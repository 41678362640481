import { Input, InputAdornment } from "@mui/material";
import { ReactComponent as SearchSvg } from "icon-library/SVG/Search.svg";
import SVGIcon from "common/components/svg-icon/svg-icon";
import * as CSS from "../class-names";

export type DetailsSearchProps = {
    idPrefix: string;
    placeholder?: string;
    onChange: (value: string) => void
};
export const DetailsSearch = ({ idPrefix, placeholder, onChange }: DetailsSearchProps) => {
    const testId = `${idPrefix}_search_input`;

    return (
            <Input
                data-testid={testId}
                id={testId}
                className={CSS.CareTeamDetailsContentSearchField}
                disableUnderline
                placeholder={placeholder}
                startAdornment={
                    <InputAdornment className={CSS.CareTeamDetailsContentSearchFieldStartIcon} position="start">
                        <SVGIcon svg={SearchSvg} size="scale600" />
                    </InputAdornment>
                }
                onChange={(e) => {
                    onChange(e.target.value)
                }}
            />
    );
};
