import { AppReduxStore } from "store/reducerTypes";
import { EConsultRequestStatus, RecipientDetails, SenderDetails } from "../requests/types";
import { ExtendedRecipientDetails } from "./types";
import { setOpenConversationSid } from "store/actions";
import { useConversations } from "components/messaging/messaging-hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import Breadcrumb from "common/components/breadcrumb/breadcrumb";
import ChatView from "./chat-view";
import RecipientSelectorView from "./recipient-selector-view";
import Spinner from "common/components/Spinner/Spinner";

type MessagesViewProps = {
    eConsultRequestId: string;
    recipients: RecipientDetails[] | null;
    requestStatus: EConsultRequestStatus;
    sender: SenderDetails;
};

const MessagesView = ({ eConsultRequestId, recipients, requestStatus, sender }: MessagesViewProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { openConversationSid } = useSelector((state: AppReduxStore) => state.chat);
    const { conversationsInfo } = useConversations(eConsultRequestId);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [conversationId, setConversationId] = useState<string | null>();
    /* TODO
     * This is a temporary solution to have all information needed to display a conversation.
     * It should be on a higher level, therefore a model change is required */
    const [extendedRecipients, setExtendedRecipients] = useState<ExtendedRecipientDetails[]>([]);
    const [selectedRecipient, setSelectedRecipient] = useState<ExtendedRecipientDetails>();
    /* TODO
     * This is also a temporary solution. To be sure, that the conversation is updated when a new
     * message is received, Redux should be updated with the open conversation ID. BTW, the open
     * conversation ID should be removed from Redux, if possible.
     */
    const updateConversationId = useCallback((conversationId: string | undefined | null): void => {
        if (openConversationSid !== conversationId) {
            dispatch(setOpenConversationSid(conversationId));
        }
        setConversationId(conversationId);
    }, [dispatch, openConversationSid]);

    useEffect(() => {
        setExtendedRecipients(() => {
            if (recipients === null) {
                return [];
            }
            return recipients.map((recipient) => {
                const relatedConversation = conversationsInfo.find((convInfo) => {
                    const participant = convInfo.participants.find(
                        (participant) => participant.profileId === recipient.id
                    );
                    return !!participant;
                });
                return {
                    ...recipient,
                    conversationId: relatedConversation?.id || "new",
                    messagesCount: relatedConversation?.readCount || 0,
                    unreadMessagesCount: relatedConversation?.unreadCount || 0,
                };
            });
        });
    }, [conversationsInfo, recipients]);

    useEffect(() => {
        if (extendedRecipients?.length === 1) {
            updateConversationId(extendedRecipients[0].conversationId);
            setSelectedRecipient(extendedRecipients[0]);
        } else {
            setSelectedRecipient((prevSelectedRecipient) => {
                if (prevSelectedRecipient) {
                    const updatedRecipient = extendedRecipients.find(
                        (eRecipient) => eRecipient.id === prevSelectedRecipient.id
                    );
                    if (updatedRecipient) {
                        updateConversationId(updatedRecipient.conversationId);
                        return updatedRecipient;
                    }
                }
            });
        }
    }, [extendedRecipients, updateConversationId]);

    const onRecipientSelect = (recipientId: string): void => {
        setLoading(true);
        const recipient = extendedRecipients.find((recipient) => recipient.id === recipientId);
        if (recipient?.conversationId && recipient.conversationId !== "new") {
            updateConversationId(recipient.conversationId);
        } else {
            updateConversationId("new");
        }
        setSelectedRecipient(recipient);
        setLoading(false);
    };

    if (!eConsultRequestId || !extendedRecipients.length) {
        return null;
    }

    return (
        <div className={classes.EConsult__messages}>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {!conversationId && extendedRecipients.length > 1 && (
                        <RecipientSelectorView onSelect={onRecipientSelect} recipients={extendedRecipients} />
                    )}
                    {conversationId && selectedRecipient && (
                        <div className="EConsult__messages__chat">
                            {extendedRecipients.length > 1 && (
                                <Breadcrumb
                                    backLinkTitle={intl.formatMessage({ id: "EConsult.messages.chat.backLink" })}
                                    idPrefix="EConsult__messages__chat"
                                    currentTitle={selectedRecipient.fullName}
                                    onBackLinkClick={() => {
                                        setConversationId(undefined);
                                        setSelectedRecipient(undefined);
                                    }}
                                />
                            )}
                            <ChatView
                                conversationId={conversationId}
                                sender={sender}
                                recipient={selectedRecipient}
                                requestId={eConsultRequestId}
                                requestStatus={requestStatus}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default MessagesView;
